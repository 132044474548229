// Wrap Menu1, add arrow icon on right side + slant

import React, { useState } from 'react';
import Menu1 from '../Menu1';
import Icons from '../../Icons';
//import { urlFor } from '../../../utils/sanityHelper';

const Menu3 = ({
  companyConfig,
  chosenPortfolio,
  setTopic,
  Topic,
  setHasCurr,
  setHasPrev,
  hasCurr,
  hasPrev
}) => {
  const [displayArrow, setDisplayArrow] = useState(1);

  const hideDisplayArrow = () => {
    setDisplayArrow(0);
  };

  const showDisplayArrow = () => {
    setDisplayArrow(1);
  };

  // const SetTopicArray = (title, level) => {
  //   let topicalarray = []
  //   try {
  //     topicalarray = [...Topic];
  //   }
  //   catch {

  //   }
  //   topicalarray.splice(level, 11111, title);
  //   setTopic(topicalarray);
  // }

  return (
    <div
      className="portfolio-menu-slant-container"
      style={{
        backgroundColor: chosenPortfolio.primary_color
          ? chosenPortfolio.primary_color
          : companyConfig.primary_color,
        borderRight: chosenPortfolio.accent_color
          ? `2px solid ${chosenPortfolio.accent_color}`
          : null
      }}
      onMouseEnter={() => hideDisplayArrow()}
      onMouseLeave={() => showDisplayArrow()}
    >
      <div className="right-arrow-container" style={{ opacity: displayArrow }}>
        <Icons.ChevronRight />
      </div>
      {/* {chosenPortfolio.portfolio_logo.asset._ref ?
        <div className="menu-3-logo">
          <img
          style={{width:"100%",objectFit:"contain"}}
            src={
              urlFor(chosenPortfolio.portfolio_logo.asset._ref)
              .auto('format')
              .width(400)
              .url()
            }
            alt="logo"
          />
        </div>
      : null} */}
      <Menu1
        hasCurr={hasCurr}
        hasPrev={hasPrev}
        setHasCurr={setHasCurr}
        setHasPrev={setHasPrev}
      />
    </div>
  );
};
export default Menu3;
