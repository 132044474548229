import { connect } from 'react-redux';
import Briefcase from './Briefcase';
import {
  SetSessionMasterCollection,
  SetFilteredSession,
  SetGuestPassMasterCollection,
  SetFilteredGuestpass,
  SetGuestpassSessionMasterCollection,
  SetFilteredGuestPassSession,
  SetFilteredGuestPassSessionWithoutGPID
} from '../../../../actions';
const mapStateToProps = (state) => ({
  // member: state.sanityContentReducer.member,
  // userInfo: state.firestore.ordered.users,
  Dash: state.DashboardReducer,
  userInfo: state.firestore.ordered.users,
  GuestPasses: state.DashboardReducer.GuestpassFilteredCollection,
  GuestPassResults: state.DashboardReducer.GuestpassFilteredCollection,
  sessions: state.DashboardReducer.GuestpassSessionFilteredCollection,
  Size: state.portfolioReducer.Size,
  selectedUser: state.DashboardReducer.selectedUser
});
const mapDispatchToProps = (dispatch) => {
  return {
    SetFilteredGuestPassSession: (GPID) =>
      dispatch(SetFilteredGuestPassSession(GPID)),
    SetSessionMasterCollection: (UI, Firestore) =>
      dispatch(SetSessionMasterCollection(UI, Firestore)),
    SetFilteredSession: (UI) => dispatch(SetFilteredSession(UI)),
    SGPMC: (UI, firestore) =>
      dispatch(SetGuestPassMasterCollection(UI, firestore)),
    SFGPS: (UI) => dispatch(SetFilteredGuestPassSessionWithoutGPID(UI)),
    SGPFC: (UI) => dispatch(SetFilteredGuestpass(UI)),
    SGPSMC: (UI, firestore) => {
      dispatch(SetGuestpassSessionMasterCollection(UI, firestore));
    }
  };
};

const BriefcaseContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Briefcase);

export default BriefcaseContainer;
