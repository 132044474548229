import { connect } from 'react-redux';
import GP from './NotificationList';
import {
  SetGuestPassMasterCollection,
  SetFilteredGuestpass,
  SetSessionMasterCollection,
  SetFilteredSession,
  SetGuestpassSessionMasterCollection,
  SetFilteredGuestPassSessionWithoutGPID,
  setMasterGuestPassSessionDispatch,
  SetLeadsGuestpass,
  SetScrollableCount,
  setLeadViewer
} from '../../../../actions';

const mapStateToProps = (state) => ({
  Firestore: state.firestore,
  portfolios: state.sanityContentReducer.portfolios,
  Dash: state.DashboardReducer,
  userInfo: state.firestore.ordered.users,
  views: state.DashboardReducer.views,
  Size: state.portfolioReducer.Size,
  selectedUser: state.DashboardReducer.selectedUser
});

const mapDispatchToProps = (dispatch) => {
  return {
    SGPMC: (UI, firestore) =>
      dispatch(SetGuestPassMasterCollection(UI, firestore)),
    SGPFC: (UI) => dispatch(SetFilteredGuestpass(UI)),
    SSMC: (UI, firestore) =>
      dispatch(SetSessionMasterCollection(UI, firestore)),
    SGPSMC: (UI, firestore) => {
      dispatch(SetGuestpassSessionMasterCollection(UI, firestore));
    },
    SFS: (UI) => {
      dispatch(SetFilteredSession(UI));
    },
    SFGPS: (UI) => dispatch(SetFilteredGuestPassSessionWithoutGPID(UI)),
    SLGP: (UI, F) => dispatch(SetLeadsGuestpass(UI, F)),
    SLV: (LV) => dispatch(setLeadViewer(LV)),
    SetMasterGuestPassSessionList: (List) => {
      dispatch(setMasterGuestPassSessionDispatch(List));
    },
    SetScrollableCount: (num) => {
      dispatch(SetScrollableCount(num));
    }
  };
};

const DashboardGP2 = connect(mapStateToProps, mapDispatchToProps)(GP);

export default DashboardGP2;
