/* eslint-disable react/no-unknown-property */
import { React } from 'react';
const Shared = ({ Fill, Rotation, onClick, className, id, style }) => {
  let string = `.clsShared-1{fill:none;stroke:${Fill};stroke-linecap:round;stroke-linejoin:round;stroke-width:1.98px;}`;
  return (
    <svg
      style={{ ...style }}
      className={className}
      onClick={() => {
        onClick();
      }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 41.7 51.015"
    >
      {/* 1.5scaled */}
      <defs>
        <style>{string}</style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="design" transform={`translate(${13.9 / 2},${17.005 / 2})`}>
          <path
            class="clsShared-1"
            d="M1.49,17V29.41a3.1,3.1,0,0,0,3.1,3.11H23.21a3.1,3.1,0,0,0,3.1-3.11V17"
          />
          <polyline class="clsShared-1" points="20.11 7.7 13.9 1.49 7.7 7.7" />
          <line class="clsShared-1" x1="13.9" y1="1.49" x2="13.9" y2="21.66" />
        </g>
      </g>
    </svg>
  );
};
export default Shared;
