import React from 'react';
import Menu11 from '../../../containers/PageMenus/Menu11-Catalog';
// import { urlFor } from '../../../utils/sanityHelper';
// import ViewHeadlineRoundedIcon from '@material-ui/icons/ViewHeadlineRounded';
// import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
// import Icons from '../../../containers/Icons';
// import { useFirestore } from 'react-redux-firebase';

const MasterMenu = ({
  companyConfig,
  chosenPortfolio,
  setTopic,
  Topic,
  extendMenu,
  setExtendMenu,
  setCurrentNavItem,
  toolbarMenuOpen,
  Size,
  transitionedToTopLevel,
  isGuestMode,
  liked,
  currentGuestContentHelper,
  sessionId,
  setLiked,
  currentContent,
  toggleNotepad
}) => {
  return (
    <div className="portfolio-mastermenu-container">
      <Menu11 setCurrentNavItem={setCurrentNavItem} />
    </div>
  );
};
export default MasterMenu;
