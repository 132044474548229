import { connect } from 'react-redux';
import KeyTopics from './KeyTopics.js';
import {
  SetSessionMasterCollection,
  SetFilteredSession,
  SetGuestPassMasterCollection,
  SetFilteredGuestpass,
  SetGuestpassSessionMasterCollection,
  setUsersPool,
  SetFilteredGuestPassSession
} from '../../../actions';

const mapStateToProps = (state) => ({
  Dash: state.DashboardReducer,
  userInfo: state.firestore.ordered.users,
  selectedUser: state.DashboardReducer.selectedUser,
  Size: state.portfolioReducer.Size
});

const mapDispatchToProps = (dispatch) => {
  return {
    SetFilteredGuestPassSession: (GPID) =>
      dispatch(SetFilteredGuestPassSession(GPID)),
    SetSessionMasterCollection: (UI, Firestore) =>
      dispatch(SetSessionMasterCollection(UI, Firestore)),
    setUsersPool: (UI, Firestore) => dispatch(setUsersPool(UI, Firestore)),
    SetFilteredSession: (UI) => dispatch(SetFilteredSession(UI)),
    SGPMC: (UI, firestore) =>
      dispatch(SetGuestPassMasterCollection(UI, firestore)),
    SGPFC: (UI) => dispatch(SetFilteredGuestpass(UI)),
    SGPSMC: (UI, firestore) => {
      dispatch(SetGuestpassSessionMasterCollection(UI, firestore));
    }
  };
};

const KeyTopicsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(KeyTopics);

export default KeyTopicsContainer;
