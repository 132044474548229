import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/rootReducer';
import { loadPortfolioState } from './utils/localStorageHelper';
import { CLEAR_STORE } from './constants/actionTypes';

const enhancedRootReducer = (state, action) => {
  if (action.type === CLEAR_STORE) {
    return rootReducer(undefined, action);
  }
  return rootReducer(state, action);
};
export default function configureStore() {
  return createStore(
    enhancedRootReducer,
    loadPortfolioState(),
    applyMiddleware(thunk)
  );
  //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
}
