import React, { useState } from 'react';
import styled from 'styled-components';
import * as Scale from '../../../utils/Scalars';

const DateContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .date-select {
    border-radius: 12px;
    box-shadow: 1px 1px 4px black;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    font-size: 1em;

    @media (max-width: 1380px) {
      font-size: 0.7em;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid #e6e6e6;
      border-right: 1px solid #e6e6e6;
      font-weight: 600;
      font-family: 'Newsreader', serif;
      transition: all 0.1s ease;
    }
  }
`;

const DateSelect = ({
  setEfficiencyAggregateSelected,
  efficiencyAggregateSelected,
  Size,
  efficiencyCompanyToggle,
  setEfficiencyCompanyToggle
}) => {
  const [hoverChosen, setHoverChosen] = useState('');

  const times = [
    { value: '6m', id: '6m' },
    { value: '3m', id: '3m' },
    { value: '1m', id: '1m' },
    { value: '2w', id: '2w' },
    { value: '1w', id: '1w' },
    { value: '1d', id: '1d' }
  ];

  const resetCompanyToggle = (time) => {
    if (
      efficiencyCompanyToggle === 'off' &&
      time !== efficiencyAggregateSelected
    ) {
      setEfficiencyCompanyToggle('on');
    }
  };

  const timeMapping = times.map((time, i) => {
    if (time.value === '6m') {
      return (
        <div
          onClick={() => {
            setEfficiencyAggregateSelected(time.value);
            resetCompanyToggle(time.value);
          }}
          onMouseEnter={() => setHoverChosen(time.id)}
          onMouseLeave={() => setHoverChosen('')}
          value={time.value}
          id={time.id}
          key={time.id}
          style={{
            backgroundColor:
              efficiencyAggregateSelected === time.id
                ? '#F7983D'
                : hoverChosen === time.id
                ? '#E6E6E6'
                : 'rgb(252, 250, 250)',
            color: efficiencyAggregateSelected === time.id ? 'white' : 'black',
            borderTopLeftRadius: '12px',
            borderBottomLeftRadius: '12px',
            cursor: 'pointer',
            borderLeft: 'none'
          }}
        >
          {time.value}
        </div>
      );
    } else if (time.value === '1d') {
      return (
        <div
          onClick={() => {
            setEfficiencyAggregateSelected(time.value);
            resetCompanyToggle(time.value);
          }}
          onMouseEnter={() => setHoverChosen(time.id)}
          onMouseLeave={() => setHoverChosen('')}
          value={time.value}
          id={time.id}
          key={time.id}
          style={{
            backgroundColor:
              efficiencyAggregateSelected === time.id
                ? '#F7983D'
                : hoverChosen === time.id
                ? '#E6E6E6'
                : 'rgb(252, 250, 250)',
            color: efficiencyAggregateSelected === time.id ? 'white' : 'black',
            borderTopRightRadius: '12px',
            borderBottomRightRadius: '12px',
            cursor: 'pointer',
            borderRight: 'none'
          }}
        >
          {time.value}
        </div>
      );
    } else {
      return (
        <div
          onClick={() => {
            setEfficiencyAggregateSelected(time.value);
            resetCompanyToggle(time.value);
          }}
          onMouseEnter={() => setHoverChosen(time.id)}
          onMouseLeave={() => setHoverChosen('')}
          value={time.value}
          id={time.id}
          key={time.id}
          style={{
            backgroundColor:
              efficiencyAggregateSelected === time.id
                ? '#F7983D'
                : hoverChosen === time.id
                ? '#E6E6E6'
                : 'rgb(252, 250, 250)',
            color: efficiencyAggregateSelected === time.id ? 'white' : 'black',
            cursor: 'pointer'
          }}
        >
          {time.value}
        </div>
      );
    }
  });

  return (
    <>
      <DateContainer>
        <div
          style={{
            width: `${Scale.Width(Size, 275)}px`,
            height: `${Scale.Width(Size, 30)}px`
          }}
          className="date-select"
        >
          {timeMapping}
        </div>
      </DateContainer>
    </>
  );
};

export default DateSelect;
