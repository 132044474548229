import { connect } from 'react-redux';
import Efficiency from './Efficiency.js';
import {
  SetGuestPassMasterCollection,
  SetFilteredGuestpass,
  SetSessionMasterCollection,
  SetFilteredSession,
  setEfficiencyUserToggle,
  setEfficiencyCompanyToggle,
  setEfficiencyPastToggle,
  setEfficiencyParallelToggle,
  setSecondaryUserSessionToggle,
  setSecondaryUserAggregateToggle,
  setDefaultVisit,
  setAggregateSelected,
  setSessionDateString
} from '../../../actions';

const mapStateToProps = (state) => ({
  Firestore: state.firestore,
  Dash: state.DashboardReducer,
  userInfo: state.firestore.ordered.users,
  GuestPassMasterCollection: state.DashboardReducer.GuestPassMasterCollection,
  GuestpassFilteredCollection:
    state.DashboardReducer.GuestpassFilteredCollection,
  SessionMasterCollection: state.DashboardReducer.SessionMasterCollection,
  SessionFilteredCollection: state.DashboardReducer.SessionFilteredCollection,
  portfolios: state.sanityContentReducer.portfolios,
  aggregateSelected: state.DashboardReducer.aggregateSelected,
  selectedPortfolioId: state.DashboardReducer.selectedPortfolioId,
  defaultVisit: state.DashboardReducer.defaultVisit,
  efficiencyAggregateSelected:
    state.DashboardReducer.efficiencyAggregateSelected,
  userToggle: state.DashboardReducer.efficiencyUserToggle,
  companyToggle: state.DashboardReducer.efficiencyCompanyToggle,
  pastToggle: state.DashboardReducer.efficiencyPastToggle,
  parallelSession: state.DashboardReducer.parallelSession,
  parallelToggle: state.DashboardReducer.efficiencyParallelToggle,
  secondaryUserSessionToggle: state.DashboardReducer.secondaryUserSessionToggle,
  secondaryUserAggregateToggle:
    state.DashboardReducer.secondaryUserAggregateToggle,
  sessionList: state.DashboardReducer.sessionList,
  selectedUser: state.DashboardReducer.selectedUser,
  Size: state.portfolioReducer.Size
});

const mapDispatchToProps = (dispatch) => {
  return {
    SetGuestPassMasterCollection: (UI, firestore) =>
      dispatch(SetGuestPassMasterCollection(UI, firestore)),
    SetFilteredGuestPass: (UI) => dispatch(SetFilteredGuestpass(UI)),
    SetSessionMasterCollection: (UI, firestore) =>
      dispatch(SetSessionMasterCollection(UI, firestore)),
    SetFilteredSession: (UI) => dispatch(SetFilteredSession(UI)),
    setEfficiencyUserToggle: (toggle) =>
      dispatch(setEfficiencyUserToggle(toggle)),
    setEfficiencyCompanyToggle: (toggle) =>
      dispatch(setEfficiencyCompanyToggle(toggle)),
    setEfficiencyPastToggle: (toggle) =>
      dispatch(setEfficiencyPastToggle(toggle)),
    setEfficiencyParallelToggle: (toggle) =>
      dispatch(setEfficiencyParallelToggle(toggle)),
    setSecondaryUserSessionToggle: (toggle) =>
      dispatch(setSecondaryUserSessionToggle(toggle)),
    setSecondaryUserAggregateToggle: (toggle) =>
      dispatch(setSecondaryUserAggregateToggle(toggle)),
    setDefaultVisit: (visit) => dispatch(setDefaultVisit(visit)),
    setAggregateSelected: (time) => dispatch(setAggregateSelected(time)),
    setSessionDateString: (string) => dispatch(setSessionDateString(string))
  };
};

const EfficiencyContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Efficiency);

export default EfficiencyContainer;
