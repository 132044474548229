import React, { useState, Fragment } from 'react';
import styled from 'styled-components';
import InputBox from '../../InputBox';
import Button from '../../Button';
const StyledPasswordReset = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledPasswordForm = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  input {
    margin-bottom: 20px;
  }
  button {
    margin-bottom: 10px;
  }

  p {
    color: grey;
    text-align: center;
    font-size: 14px;
    margin: 0;
    padding: 0;
  }
`;

const StyledPasswordStatusMsg = styled.div`
  color: ${(props) => props.color};
  margin-top: 25px;
`;

const PasswordResetPage = ({
  email,
  updateSessionKey,
  params,
  firebase,
  history
}) => {
  const [password, setPassword] = useState('');
  const [passwordCheck, setPasswordCheck] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');

  // handle form submit
  const handleSubmit = (event) => {
    event.preventDefault();

    //check min password req
    if (password === passwordCheck && password.length > 7) {
      firebase
        .auth()
        .confirmPasswordReset(params.oobCode, password)
        .then(() => {
          setErrorMsg('');
          setSuccessMsg('Password reset success');

          //log out
          firebase.logout();
          // clear local storage and redux session key
          updateSessionKey('');

          // push user back to login
          history.push('/');
        })
        .catch((error) => {
          //////////onsole.log('Error:', error);
          setErrorMsg('Error: Password reset failed');
        });
    } else {
      // set error message
      if (password.length < 8) {
        setErrorMsg('Error: Password needs to be at least 8 characters');
      }
      //////////onsole.log('error');
    }
  };
  return (
    <StyledPasswordReset>
      {successMsg === '' ? (
        <Fragment>
          <h2>Password reset for {email}</h2>
          <StyledPasswordForm onSubmit={handleSubmit}>
            <InputBox
              name="password"
              placeholder="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <InputBox
              name="password-check"
              placeholder="Reinput password"
              type="password"
              value={passwordCheck}
              onChange={(e) => {
                // check if password matches
                if (password !== e.target.value) {
                  setErrorMsg('Error: mismatch password');
                } else {
                  setErrorMsg('');
                }
                setPasswordCheck(e.target.value);
              }}
            />
            <Button type="submit" text="Submit" />
          </StyledPasswordForm>
          <StyledPasswordStatusMsg color="red">
            {errorMsg}
          </StyledPasswordStatusMsg>
        </Fragment>
      ) : (
        <Fragment>
          <StyledPasswordStatusMsg color="black">
            {successMsg}
          </StyledPasswordStatusMsg>
        </Fragment>
      )}
    </StyledPasswordReset>
  );
};

export default PasswordResetPage;
