import React, { useEffect, useState } from 'react'; //useRef
import ReactQuill from 'react-quill'; //, {Quill}

import T3 from './Test';

const EditBox = React.forwardRef((NoteSeed, Ref) => {
  const [EditValue, SetEditValue] = useState(NoteSeed);
  return (
    <>
      <textarea
        cols={1000}
        style={{
          width: '100%',
          resize: 'none',
          height: '100%',
          userSelect: 'text'
        }}
        ref={Ref}
        onChange={(eve) => {
          SetEditValue(eve.currentTarget.value);
        }}
        value={EditValue}
      ></textarea>
    </>
  );
});
EditBox.displayName = 'Edit Box';

// TODO: Unsure on codebase. forwardRef render functions accept exactly two parameters: props and ref.
const NoteArea = React.forwardRef(({ L1, L2, Copied, EditMode, Ref }) => {
  //const reftt = useRef();
  const [text, setText] = useState('');
  const [L3, SetL3] = useState([]);
  // const [PostNote,SetPostNote] =  useState("") TO RETURN TO FOR APPEND RATHER THAN EDIT
  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  async function TestForDate(Data, Count) {
    if (Count > 100) return;
    await sleep(100);
    try {
      document.getElementById(Data.id).textContent = new Date(Data.date);
    } catch {
      TestForDate(Data, Count++);
    }
    return;
  }
  useEffect(() => {
    let L3T = [];
    if (L1) L3T.push(...L1);
    if (L2) L3T.push(...L2);
    L3T.sort((a, b) => {
      return b.session_date.seconds - a.session_date.seconds;
    });
    // L3T.map((ele)=>{
    //     let temp = ele.notes.split('<scirpt>')
    // })
    SetL3(L3T);
    setText(L3T[0].notes);
  }, [L1, L2]);
  useEffect(() => {
    if (EditMode) setText('');
  }, [EditMode]);
  //////onsole.log("!!!T!!!",T3);
  return (
    <div
      style={{
        position: 'relative',
        height: '100%',
        width: '100%',
        overflow: 'auto',
        boxSizing: 'border-box',
        padding: '12px'
      }}
    >
      {/* justifyContent:"center", */}
      {L3.map((ele) => {
        if (!ele.notes) return <></>;
        else if (!EditMode) {
          let temparr = ele.notes.split('<script>');

          let temparr2 = temparr.map((ele2, i) => {
            try {
              let tempitem = ele2.split('</script>');
              console.log('tempitem[0]', tempitem[0]);
              let data = JSON.parse(tempitem[0]);
              return data;
            } catch {
              return ele2;
            }
          });
          temparr2 = temparr2.filter((ele) => {
            return ele && typeof ele !== typeof '';
          });
          console.log(temparr2);
          temparr2.forEach((element) => {
            try {
              document.getElementById(element.id).textContent = new Date(
                element.date
              );
            } catch {
              TestForDate(element, 1);
              // sleep(5000).then(()=>{
              //     document.getElementById(element.id).textContent = new Date(element.date);
              // })
            }
          });

          let endstr = ele.notes;
          //ele.notes.includes('<p>')
          return (
            <>
              {/* <style>{`div.NoteContain > p{
                    margin: 0px;
                }
                div.NoteContain > ul{
                    margin: 0px;
                }
                div.NoteContain > ol{
                    margin: 0px;
                }
                `}</style> */}
              <style dangerouslySetInnerHTML={{ __html: T3 }}></style>
              <div
                style={{
                  position: 'absolute',
                  width: 'calc(100% - 24px)',
                  height: 'calc(100% - 24px)'
                }}
                className={`NoteContain qlviewer`}
                ref={Ref}
                dangerouslySetInnerHTML={{ __html: endstr }}
              ></div>
              {/* <hr></hr> */}
            </>
          );
        } else if (
          EditMode &&
          !ele.notes.includes('<p>') &&
          !ele.notes.includes('<li>')
        )
          return (
            <>
              {/* <EditBox NoteSeed={ele.notes} Ref={Ref}></EditBox> */}
              <EditBox NoteSeed={ele.notes} Ref={Ref}></EditBox>

              {/* <hr></hr> */}
            </>
          );
        else {
          return (
            <>
              <style>{`div.ql-container{
      height: 100%;
      overflow: auto;
      }
      div.quill{
        color: black;
        max-height: 100%;
        display: inline-flex;
        flex-direction: column;
        width: 100%;
      }
      div.ql-editor{
        word-wrap: anywhere;
      }
      button.ql-link{
        display: none !important;
      }
      `}</style>
              <div
                style={{
                  background: 'white',
                  height: '100%',
                  display: 'inline-flex',
                  width: '100%'
                }}
              >
                <ReactQuill
                  ref={Ref}
                  theme={'snow'}
                  value={text}
                  onChange={(e, Delta, Source, Editor) => {
                    //////onsole.log("!!SELECTION!!",e);
                    //////onsole.log("!!SELECTION!!",Editor.getContents());
                    setText(e);
                  }}
                ></ReactQuill>
              </div>
            </>
          );
        }
      })}
      <div
        style={{
          position: 'absolute',
          zIndex: 1,
          visibility: Copied === true ? 'visible' : 'hidden',
          backgroundColor: '#3b3b3b',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '28px',
          width: '140px',
          color: 'white',
          boxShadow: '2px 2px 3px #808080',
          borderRadius: '3px',
          left: 'calc(50% - 70px)',
          top: 'calc(50% - 14px)',
          paddingRight: '1px'
        }}
      >
        <p
          style={{
            color: 'white',
            marginBlockStart: '0px',
            marginBlockEnd: '0px',
            fontWeight: 400,
            fontFamily: "'Open Sans', serif",
            fontSize: '12px',
            fontStyle: 'italic'
          }}
        >
          copied to clipboard
        </p>
      </div>
    </div>
  );
});
NoteArea.displayName = 'Note Area';
export default NoteArea;
//ref={reftt}
//setText(e)
//value={text}
