import { connect } from 'react-redux';
import HeaderBar from './HeaderBar';
import {
  SetSessionMasterCollection,
  SetFilteredSession,
  setSelectedPortfolioId,
  setDefaultVisit,
  setBodyRender,
  setSessionList,
  setSelectedPortfolioName,
  SetGuestPassMasterCollection,
  SetFilteredGuestpass,
  filterHeaderGuestPass,
  setUsersPool,
  setSelectedUser,
  setParallelSession
} from '../../../actions';

const mapStateToProps = (state) => ({
  member: state.sanityContentReducer.member,
  userInfo: state.firestore.ordered.users,
  Dash: state.DashboardReducer,
  SessionMasterCollection: state.DashboardReducer.SessionMasterCollection,
  SessionFilteredCollection: state.DashboardReducer.SessionFilteredCollection,
  portfolios: state.sanityContentReducer.portfolios,
  firestore: state.firestore,
  selectedPortfolioId: state.DashboardReducer.selectedPortfolioId,
  headerAggregateSelected: state.DashboardReducer.headerAggregateSelected,
  sessionList: state.DashboardReducer.sessionList,
  guestPassHeaderFiltered: state.DashboardReducer.guestPassHeaderFiltered,
  bodyRender: state.DashboardReducer.bodyRender,
  selectedUser: state.DashboardReducer.selectedUser,
  Size: state.portfolioReducer.Size
});
const mapDispatchToProps = (dispatch) => {
  return {
    SetSessionMasterCollection: (UI, firestore) =>
      dispatch(SetSessionMasterCollection(UI, firestore)),
    SetFilteredSession: (UI) => dispatch(SetFilteredSession(UI)),
    setSelectedPortfolioId: (id) => dispatch(setSelectedPortfolioId(id)),
    setDefaultVisit: (visit) => dispatch(setDefaultVisit(visit)),
    setBodyRender: (page) => dispatch(setBodyRender(page)),
    setSessionList: (list) => dispatch(setSessionList(list)),
    setSelectedPortfolioName: (string) =>
      dispatch(setSelectedPortfolioName(string)),
    setGuestPassMasterCollection: (userInfo, firestore) =>
      dispatch(SetGuestPassMasterCollection(userInfo, firestore)),
    setGuestPassFilteredCollection: (userInfo) =>
      dispatch(SetFilteredGuestpass(userInfo)),
    filterHeaderGuestPass: (userInfo) =>
      dispatch(filterHeaderGuestPass(userInfo)),
    setUsersPool: (userInfo, firestore) =>
      dispatch(setUsersPool(userInfo, firestore)),
    setSelectedUser: (user) => dispatch(setSelectedUser(user)),
    setParallelSession: (session) => dispatch(setParallelSession(session))
  };
};

const HeaderBarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderBar);

export default HeaderBarContainer;
