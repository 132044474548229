import {
  GET_SANITY_CONTENT,
  GET_SANITY_SESSION,
  ADD_SESSION,
  GET_SANITY_USER,
  ADD_GUEST_PASS
} from '../constants/actionTypes';
import { arrayToObject } from '../utils/arrayHelper';

const initialState = {
  contentStatus: '',
  status: '',
  videos: [],
  pdfs: [],
  portfolios: [],
  images: [],
  companyConfig: {},
  member: {},
  members: {},
  sessionLogs: [],
  GuestPasses: [],
  screensavers: []
};
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_SESSION.BEGIN:
    case ADD_GUEST_PASS.BEGIN:
    case GET_SANITY_CONTENT.BEGIN:
      return {
        ...state,
        status: 'request'
      };
    case ADD_GUEST_PASS.SUCCESS:
    case ADD_SESSION.SUCCESS:
      return {
        ...state,
        status: 'success'
      };
    case GET_SANITY_CONTENT.SUCCESS: {
      let company = { ...action.data.company };
      // traverse company
      for (let key in company) {
        if (key.includes('_color') && company[key] === '#') {
          // change '#' in _color to undefined
          company[key] = undefined;
        }
      }
      return {
        ...state,
        GuestPasses: [...action.data.GuestPasses],
        portfolios: [...action.data.portfolios],
        videos: arrayToObject(action.data.videos, '_id'),
        pdfs: arrayToObject(action.data.pdfs, '_id'),
        images: arrayToObject(action.data.images, '_id'),
        companyConfig: company,
        member: { ...action.data.member },
        members: action.data.members
          ? arrayToObject(action.data.members, '_id')
          : [],
        sessionLogs: [...action.data.sessionLogs],
        screensavers: arrayToObject(action.data.screensavers, '_id'),
        status: 'success',
        contentStatus: 'ready'
      };
    }
    case GET_SANITY_SESSION.SUCCESS:
      return {
        ...state,
        sessionLogs: [...action.data.sessionLogs]
      };
    case ADD_GUEST_PASS.FAILURE:
    case ADD_SESSION.FAILURE:
    case GET_SANITY_CONTENT.FAILURE:
      return {
        ...state,
        status: 'fail'
      };
    case GET_SANITY_USER:
      return {
        ...state,
        member: { ...action.data.member }
      };
    default:
      return state;
  }
};
