import { connect } from 'react-redux';
import Notepad from './Notepad';
import {
  toggleNotepad,
  updateNotepad,
  updateSessionInSanity
} from '../../actions';

const mapStateToProps = (state) => ({
  companyConfig: state.sanityContentReducer.companyConfig,
  chosenPortfolio: state.portfolioReducer.chosenPortfolio,
  notepadText: state.notepadReducer.notepadText,
  isGuestMode: state.guestReducer.isGuestMode,
  showNotepad: state.notepadReducer.showNotepad
});

const mapDispatchToProps = (dispatch) => {
  return {
    toggleNotepad: () => dispatch(toggleNotepad()),
    updateNotepad: (string) => dispatch(updateNotepad(string)),
    updateSessionInSanity: (obj) => dispatch(updateSessionInSanity(obj))
  };
};

const NotepadContainer = connect(mapStateToProps, mapDispatchToProps)(Notepad);

export default NotepadContainer;
