import React, { useState, useEffect, Fragment } from 'react';
import Button from '../../Button';
// import IMG from '../../../assets/images/MenuMask.png';
import X from '../../../assets/images/X-Icon.png';
import FontUtil from '../../../utils/FontUtility';

const BeUniqueBriefcase = ({
  sendClick,
  shareClick,
  companyConfig,
  chosenPortfolio,
  userInfo,
  showBriefcase,
  isGuestMode,
  liked,
  briefcaseContent,
  toggleBriefcase,
  removeFromBriefcase,
  clearBriefcase,
  customerInfo
}) => {
  const [isAblePortfolioGuestPass, setIsAblePortfolioGuestPass] =
    useState(false);
  useEffect(() => {
    ////////////onsole.log("!!UI!!",userInfo);
    ////////////onsole.log("!!UI4!!",userInfo.length > 0);

    if (userInfo && userInfo.length > 0) {
      // TODO: Why is this constant condition?
      //////////onsole.log('UI3', userInfo[0].is_able_to_create_guest_pass);
      // check if user is able create portfolio level guest pass
      // userInfo[0].is_able_to_create_guest_pass
      if (true) {
        // user is able to create normal guest pass, aka able to add item to briefcase
        setIsAblePortfolioGuestPass(true);
      }
    }
  }, [userInfo]);
  ////////////onsole.log("!!UI2!!",isAblePortfolioGuestPass)
  let BorderColor = chosenPortfolio.primary_color
    ? chosenPortfolio.primary_color
    : companyConfig.primary_color;

  // let secondaryColor = chosenPortfolio.secondary_color
  // ? chosenPortfolio.secondary_color
  // : companyConfig.primary_color;

  let primaryColor = chosenPortfolio.primary_color
    ? chosenPortfolio.primary_color
    : companyConfig.primary_color;

  let accentColor = chosenPortfolio.accent_color
    ? chosenPortfolio.accent_color
    : 'white';

  //////////onsole.log("!!!!CUSTOMER INFO!!!! ", customerInfo);
  //////////onsole.log("!!!!User INFO!!!! ", userInfo);
  //////////onsole.log("!!LIKED!!", liked);
  //////////onsole.log("!!briefcaseContent!!", briefcaseContent);
  let PS = 0;
  briefcaseContent.forEach((element) => {
    //////////onsole.log("WORKING: ",element)
    //////////onsole.log("WORKING2: ",PS)
    if (!isNaN(parseInt(element.PagesSaved)))
      PS = PS + parseInt(element.PagesSaved);
    else PS = PS + 1;
  });
  return (
    <div
      style={{ borderColor: BorderColor, zIndex: 5 }}
      className={`portfolio-briefcase-container ${
        showBriefcase ? 'active' : 'hidden'
      }`}
    >
      <div className="portfolio-briefcase-label">
        {/* <div onClick={toggle} style={{backgroundColor:'black', maskImage:`url(${IMG})`,maskSize:"100% 100%", maskRepeat:"no-repeat", WebkitMaskImage:`url(${IMG})`,WebkitMaskRepeat:'no-repeat', WebkitMaskSize:"100% 100%",Height:"96px",Width:"96px", cursor:`pointer`, alignSelf:"flex-end", float:"left",objectFit:"contain",objectPosition:"left", margin:"16px", marginBottom:'0px'}}/> */}
        {/* <img onClick={() => showBriefcase = false} src={this.props.Icons.icons.find(ele => ele.type =="close") != null?this.props.Icons.icons.find(ele => ele.type =="close").file.url:X} style={{maxHeight:"96px",maxWidth:"96px", cursor:`pointer`, alignSelf:"flex-end", float:"left",objectFit:"contain",objectPosition:"left", margin:"16px", marginBottom:'0px'}}></img>color:`${this.props.portfolio.color?this.props.portfolio.color:null}`, */}
        <div className="close-container">
          <div
            style={{
              backgroundColor: `${chosenPortfolio.primary_color}`,
              borderRadius: '50%'
            }}
            className="expansion"
          >
            <div
              onClick={toggleBriefcase}
              style={{
                maskImage: `url(${X})`,
                maskSize: '100% 100%',
                maskRepeat: 'no-repeat',
                WebkitMaskImage: `url(${X})`,
                WebkitMaskRepeat: 'no-repeat',
                WebkitMaskSize: '100% 100%',
                backgroundColor: chosenPortfolio.text_color,
                height: '48px',
                width: '48px',
                cursor: `pointer`,
                objectFit: 'contain'
              }}
            ></div>
          </div>
        </div>
        <div className="intro-box">
          <p
            style={{
              fontSize: '36px',
              marginBlockStart: '0px',
              marginBlockEnd: '0px',
              color: BorderColor,
              textShadow: `-1px 0 ${accentColor}, 0 1px ${accentColor}, 1px 0 ${accentColor}, 0 -1px ${accentColor}`,
              fontFamily: FontUtil(chosenPortfolio.SystemText)
            }}
          >
            {!isGuestMode ? 'Your Briefcase' : 'Your Liked content'}
          </p>
        </div>
      </div>
      <div className="portfolio-briefcase briefcase-content-list" style={{}}>
        <div
          style={{
            backgroundColor: 'transparent',
            marginBottom: '10px',
            display: 'flex',
            position: 'relative',
            width: '100%'
          }}
        ></div>
        {/* <hr style={{ width: 'calc(100% - 24px)' }} /> */}
        {!isGuestMode
          ? briefcaseContent.map((content, index) => {
              //////////onsole.log(content);
              return (
                <div style={{ width: 'calc(100% - 24px)' }} key={`k` + index}>
                  <div
                    className="briefcase-content-list-item"
                    key={`briefcase-content-${content.id}-${index}`}
                    style={{ border: `2px solid ${BorderColor}` }}
                  >
                    <p
                      style={{
                        marginLeft: 24 + 'px',
                        fontSize: '18px',
                        fontWeight: 'bold',
                        //fontFamily: "'Oswald', sans-serif",
                        textOverflow: 'ellipsis',
                        fontFamily: FontUtil(chosenPortfolio.SystemText)
                      }}
                    >
                      {content.title}
                    </p>
                    <p
                      style={{
                        marginRight: '0px',
                        objectFit: 'contain',
                        fontSize: '18px',
                        cursor: `pointer`,
                        fontWeight: 'bold',
                        paddingRight: '10px',
                        fontFamily: FontUtil(chosenPortfolio.SystemText)
                      }}
                      onClick={() => removeFromBriefcase(content.id)}
                    >
                      X
                    </p>
                  </div>
                  {/* <hr /> */}
                </div>
              );
            })
          : liked.map((content, index) => {
              //////////onsole.log(content);
              return (
                <div style={{ width: 'calc(100% - 24px)' }} key={`k` + index}>
                  <div
                    className="briefcase-content-list-item"
                    key={`briefcase-content-${content.id}-${index}`}
                    style={{ border: `2px solid ${BorderColor}` }}
                  >
                    <p
                      style={{
                        marginLeft: 24 + 'px',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        fontFamily: FontUtil(chosenPortfolio.SystemText)
                      }}
                    >
                      {content.title}
                    </p>
                  </div>
                </div>
              );
            })}
        <div
          style={{
            backgroundColor: 'transparent',
            marginBottom: '64px',
            display: 'flex',
            position: 'relative',
            width: '100%'
          }}
        ></div>
      </div>

      <div className="briefcase-action-button-container">
        {isGuestMode && (
          <Button
            additionalClassName="briefcase-send-button"
            height="50px"
            backgroundColor={primaryColor}
            color={
              chosenPortfolio.text_color ? chosenPortfolio.text_color : 'white'
            }
            text="Share Guest Pass"
            onClick={() => {
              shareClick();
              // clearBriefcase();
              toggleBriefcase();
            }}
            style={{ borderColor: `${accentColor}` }}
            fontSize="14px"
          />
        )}
        {isAblePortfolioGuestPass && (
          <Button
            additionalClassName="briefcase-send-button"
            height="50px"
            backgroundColor={primaryColor}
            color={
              chosenPortfolio.text_color ? chosenPortfolio.text_color : 'white'
            }
            text="Send Guest Pass"
            onClick={() => {
              sendClick(true);
              clearBriefcase();
              toggleBriefcase();
            }}
            style={{ borderColor: `${accentColor}` }}
            fontSize="14px"
          />
        )}
        {briefcaseContent.length !== 0 && ( // only show there is content
          <Fragment>
            <Button
              additionalClassName="briefcase-send-button"
              height="50px"
              backgroundColor={primaryColor}
              color={
                chosenPortfolio.text_color
                  ? chosenPortfolio.text_color
                  : 'white'
              }
              text="Send Briefcase"
              onClick={() => {
                sendClick(false);
                clearBriefcase();
                toggleBriefcase();
              }}
              style={{
                borderColor: `${accentColor}`,
                fontFamily: FontUtil(chosenPortfolio.SystemText)
              }}
              fontSize="14px"
            />
            <Button
              additionalClassName="briefcase-send-button"
              height="50px"
              backgroundColor={primaryColor}
              color={
                chosenPortfolio.text_color
                  ? chosenPortfolio.text_color
                  : 'white'
              }
              text="Clear all"
              onClick={clearBriefcase}
              style={{
                borderColor: `${accentColor}`,
                fontFamily: FontUtil(chosenPortfolio.SystemText)
              }}
              fontSize="14px"
            />
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default BeUniqueBriefcase;
