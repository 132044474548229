import { connect } from 'react-redux';
import Scriptnote from './Scriptnote';
import {
  toggleScriptnote,
  setToolbarMenuVisibility,
  setToolbarMenuOpen
} from '../../../actions';

const mapStateToProps = (state) => ({
  companyConfig: state.sanityContentReducer.companyConfig,
  chosenPortfolio: state.portfolioReducer.chosenPortfolio,
  pages: state.portfolioReducer.pages,
  chosenPageId: state.pageMenuReducer.chosenPageId,
  chosenContentPageId: state.pageMenuReducer.chosenContentPageId,
  showScriptnote: state.scriptnoteReducer.showScriptnote,
  toolbarMenuVisible: state.portfolioReducer.toolbarMenuVisible
});

const mapDispatchToProps = (dispatch) => {
  return {
    toggleScriptnote: () => dispatch(toggleScriptnote()),
    setToolbarMenuVisibility: (bool) =>
      dispatch(setToolbarMenuVisibility(bool)),
    setToolbarMenuOpen: (bool) => dispatch(setToolbarMenuOpen(bool))
  };
};

const ScriptnoteContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Scriptnote);

export default ScriptnoteContainer;
