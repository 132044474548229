import { connect } from 'react-redux';
import Profile from './ProfileUploadControl';
import { getUpdatedUser } from '../../../../../actions/sanityContentActions';

const mapStateToProps = (state) => ({
  member: state.sanityContentReducer.member,
  userInfo: state.firestore.ordered.users,
  Size: state.portfolioReducer.Size
});
const mapDispatchToProps = (dispatch) => {
  return {
    getUpdatedUser: (member) => dispatch(getUpdatedUser(member))
  };
};

const ProfileContainer = connect(mapStateToProps, mapDispatchToProps)(Profile);

export default ProfileContainer;
