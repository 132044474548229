import { connect } from 'react-redux';
import CoreLayout from './CoreLayout';
// import {
// //   SetGuestPassMasterCollection,
// //   SetFilteredGuestpass,
// //   SetSessionMasterCollection,
// //   SetFilteredSession,
// //   SetGuestpassSessionMasterCollection,
// //   SetFilteredGuestPassSessionWithoutGPID,
// //   SetLeadsGuestpass,
//   setLeadViewer
// } from '../../../../../actions';

const mapStateToProps = (state) => ({
  //   Firestore:state.firestore,
  Dash: state.DashboardReducer,
  DashGL: state.DashboardReducer.ActiveGPL
  //   userInfo: state.firestore.ordered.users,
  //   views: state.DashboardReducer.views,
  //   Size:state.portfolioReducer.Size,
  //   selectedUser: state.DashboardReducer.selectedUser
});

const mapDispatchToProps = (dispatch) => {
  return {
    // SGPMC: (UI,firestore) => dispatch(SetGuestPassMasterCollection(UI,firestore)),
    // SGPFC: (UI) =>dispatch(SetFilteredGuestpass(UI)),
    // SSMC: (UI,firestore) =>dispatch(SetSessionMasterCollection(UI,firestore)),
    // SGPSMC: (UI,firestore) =>{dispatch(SetGuestpassSessionMasterCollection(UI,firestore))},
    // SFS: (UI) => {dispatch(SetFilteredSession(UI))},
    // SFGPS: (UI) =>dispatch(SetFilteredGuestPassSessionWithoutGPID(UI)),
    // SLGP: (UI,F)=>dispatch(SetLeadsGuestpass(UI,F)),
    // SLV:(LV)=>dispatch(setLeadViewer(LV))
  };
};

const DashboardGP2 = connect(mapStateToProps, mapDispatchToProps)(CoreLayout);

export default DashboardGP2;
