import { connect } from 'react-redux';
import PortfolioContentParent from './PortfolioContentParent';
import {
  setContentPageId,
  setMenuHide,
  SetTopic,
  setCurrentContentHelper
} from '../../../actions';

const mapStateToProps = (state) => ({
  images: state.sanityContentReducer.images,
  videos: state.sanityContentReducer.videos,
  pdfs: state.sanityContentReducer.pdfs,
  companyConfig: state.sanityContentReducer.companyConfig,
  chosenPortfolio: state.portfolioReducer.chosenPortfolio,
  pages: state.portfolioReducer.pages,
  nav: state.portfolioReducer.nav,
  pageBackgroundImageUrl: state.portfolioReducer.pageBackgroundImageUrl,
  isGuestMode: state.guestReducer.isGuestMode,
  isPortfolioView: state.guestReducer.isPortfolioView,
  guestPages: state.guestReducer.guestPages,
  guestContentHelper: state.guestReducer.guestContentHelper,
  currentContentHelper: state.guestReducer.currentContentHelper,
  isAtEnd: state.pageMenuReducer.isAtEnd,
  currentLevel: state.pageMenuReducer.currentLevel,
  chosenPageId: state.pageMenuReducer.chosenPageId,
  chosenContentPageId: state.pageMenuReducer.chosenContentPageId,
  menuHide: state.pageMenuReducer.menuHide,
  navHistory: state.pageMenuReducer.navHistory
});

const mapDispatchToProps = (dispatch) => {
  return {
    setContentPageId: (id) => dispatch(setContentPageId(id)),
    setMenuHide: (bool) => dispatch(setMenuHide(bool)),
    setTopic: (Topic) => dispatch(SetTopic(Topic)),
    setCurrentContentHelper: (id) => dispatch(setCurrentContentHelper(id)) // helps guest content
  };
};

const PortfolioContentParentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PortfolioContentParent);

export default PortfolioContentParentContainer;
