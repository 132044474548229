import React, { useState, useEffect } from 'react'; //useRef useCallback
// import useAsyncReference2 from '../../../../utils/Ref-State-hook'
// import { useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import styled from 'styled-components';
import PortfolioContentOrThumbnail from '../../../../utils/PortfolioContentOrThumbnail/';
import { arrayToObject } from '../../../../utils/arrayHelper';
// import {DateRangeEnum} from '../../../../reducers/DashboardRevampReducer'
import LikeOutline from '../../../../assets/RootSVGS/Likeoutline';
import Comments from '../../../../assets/RootSVGS/Comments';
import ExitApp from '../../../../assets/Icons/Icons V1 V1 V1-19.png';

const UnviewedNotificationCircle = styled.div`
  border-radius: 50%;
  height: 24px;
  width: 24px;
  background-color: #0769a9;
  grid-column: 1;
  grid-row: 1;
  position: relative;
  top: 24px;
  left: 24px;
`;
const NotificationItemBlock = styled.div`
  display: grid;
  grid-template-columns: 48px 1fr;
  grid-template-rows: 1fr 0.5fr 1.5fr;
  flex-shrink: 0;
  width: 100%;
  height: 176px;
  background-color: ${(props) => {
    return props.Viewed ? '#fcfafa' : '#a3a2a2';
  }};
  border-width: 1px 2px;
  border-style: solid;
  border-color: black;
  &:hover {
    background-color: #e6e6e6;
  }
`;
const NoteContainer = styled.div`
  position: relative;
  display: block;
  grid-column: 2;
  grid-row: 3;
  overflow: clip;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  & > * {
    flex-shrink: 0;
    max-width: 85%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const NotificationItems = ({
  BG,
  item,
  Dash,
  portfolios,
  SLV,
  SetSubSet,
  SetSSI2
}) => {
  //Problem Area
  ////onsole.log(item);
  const firestore = useFirestore();

  const [Port] = useState(
    portfolios.find((val) => {
      return val._id === Dash.selectedPortfolioId;
    })
  );
  const [Pages, SetPages] = useState({});
  useEffect(() => {
    Port?.pages ? SetPages(arrayToObject(Port?.pages, '_key')) : SetPages({});
  }, [Port]);
  const ClickHandler = (Type) => {
    if (Type === 'Start') {
      SLV(
        Dash.GPLeads.find((ele) => {
          return (
            ele.Name.toLowerCase() === item.Name.toLowerCase() &&
            ele.Email.toLowerCase() === item.Email.toLowerCase()
          );
        })
      );
      SetSubSet(undefined);
      // let Session =Dash.GuestpassSessionMasterCollection.find((ele)=>{return ele.id === item.SessionId});
      firestore
        .collection('sessions')
        .doc(item.SessionId)
        .update({ Viewed: true, updated_at: firestore.Timestamp.now() })
        .then(() => {
          item.Viewed = true;
        });
    } else {
      let temp = Dash.GPLeads.find((ele) => {
        return (
          ele.Name.toLowerCase() === item.Name.toLowerCase() &&
          ele.Email.toLowerCase() === item.Email.toLowerCase()
        );
      });
      SLV(temp);
      let Session = Dash.GuestpassSessionMasterCollection.find((ele) => {
        return ele.id === item.SessionId;
      });
      //onsole.log("Session.notifications.likes",Session.notifications.likes,Session.notifications["likes"],Session.notifications[Type],Type)
      let NotificationItem = Session.notifications[Type].find((ele) => {
        return ele.id === item.id && ele.gallery_index === item.gallery_index;
      });
      let NotificationItemIndex = Session.notifications[Type].findIndex(
        (ele) => {
          return ele.id === item.id && ele.gallery_index === item.gallery_index;
        }
      );
      if (Type === 'likes') {
        NotificationItem = Session.notifications[Type].find((ele) => {
          return ele.id === item.id && ele.gallery_index === item.gallery_index;
        });
        NotificationItemIndex = Session.notifications[Type].findIndex((ele) => {
          return ele.id === item.id && ele.gallery_index === item.gallery_index;
        });
        SetSubSet('Likes');
        SetSSI2(
          temp?.Sessions.findIndex((ele) => {
            return ele?.id === item.SessionId;
          })
        );
      } else if (Type === 'Notes') {
        NotificationItem = Session.notifications.Notes.find((ele) => {
          return ele.id === item.id && ele.Text === item.Text;
        });
        NotificationItemIndex = Session.notifications.Notes.findIndex((ele) => {
          return ele.id === item.id && ele.Text === item.Text;
        });
        SetSubSet('Comments');
        SetSSI2(
          temp?.Sessions.findIndex((ele) => {
            return ele?.id === item.SessionId;
          })
        );
      }
      NotificationItem = { ...NotificationItem, Viewed: true };
      Session.notifications[Type][NotificationItemIndex] = NotificationItem;
      firestore
        .collection('sessions')
        .doc(item.SessionId)
        .update({
          notifications: Session.notifications,
          updated_at: firestore.Timestamp.now()
        })
        .then(() => {
          item = {
            ...item,
            ...NotificationItem,
            updated_at: firestore.Timestamp.now()
          };
        });
    }
  };

  useEffect(() => {
    //onsole.log("SETHIDDENHANDLER")
    const HideHandler = () => {
      if (!item.Viewed)
        //onsole.log("Sessiosn Marked1", item)
        new Promise((Resolve) => {
          // //onsole.log("Sessiosn Marked2")
          if (item.Viewed) return;
          let Type = item.type;
          //onsole.log("HIDE");
          if (Type === 'Start') {
            //onsole.log("HIDEStart");
            firestore
              .collection('sessions')
              .doc(item.SessionId)
              .update({ Viewed: true, updated_at: firestore.Timestamp.now() });
          } else {
            //onsole.log("HIDEElse");
            let Session = Dash.GuestpassSessionMasterCollection.find((ele) => {
              return ele.id === item.SessionId;
            });
            //onsole.log("Session.notifications.likes",Session.notifications.likes,Session.notifications["likes"],Session.notifications[Type],Type)
            let NotificationItem = Session.notifications[Type].find((ele) => {
              return (
                ele.id === item.id && ele.gallery_index === item.gallery_index
              );
            });
            let NotificationItemIndex = Session.notifications[Type].findIndex(
              (ele) => {
                return (
                  ele.id === item.id && ele.gallery_index === item.gallery_index
                );
              }
            );
            if (Type === 'likes') {
              //onsole.log("HIDELikes");
              NotificationItem = Session.notifications[Type].find((ele) => {
                return (
                  ele.id === item.id && ele.gallery_index === item.gallery_index
                );
              });
              NotificationItemIndex = Session.notifications[Type].findIndex(
                (ele) => {
                  return (
                    ele.id === item.id &&
                    ele.gallery_index === item.gallery_index
                  );
                }
              );
            } else if (Type === 'Notes') {
              //onsole.log("HIDENotes");
              NotificationItem = Session.notifications.Notes.find((ele) => {
                return ele.id === item.id && ele.Text === item.Text;
              });
              NotificationItemIndex = Session.notifications.Notes.findIndex(
                (ele) => {
                  return ele.id === item.id && ele.Text === item.Text;
                }
              );
            }
            NotificationItem = { ...NotificationItem, Viewed: true };
            //onsole.log("HIDELASTSTEP")
            Session.notifications[Type][NotificationItemIndex] =
              NotificationItem;
            //onsole.log("HIDEPostLASTSTEP", Session.notifications[Type][NotificationItemIndex])
            firestore.collection('sessions').doc(item.SessionId).update({
              notifications: Session.notifications,
              updated_at: firestore.Timestamp.now()
            });
          }
          Resolve(true);
        }).then(() => {
          console.log('Sessiosn Marked');
        });
    };
    window.addEventListener('Hidden', HideHandler);
    return () => {
      window.removeEventListener('Hidden', HideHandler);
    };
  }, [item, firestore, Dash.GuestpassSessionMasterCollection]);
  // eslint-disable-next-line react-hooks/exhaustive-deps

  let temp = new Date(item.time.seconds * 1000);
  switch (item.type) {
    case 'Start':
      return (
        <NotificationItemBlock
          {...item}
          onClick={() => {
            //onsole.log("Check",Dash.GPLeads,item,Dash.GPLeads.find((ele)=>{return ele.Name.toLowerCase() === item.Name.toLowerCase() && ele.Email.toLowerCase() === item.Email.toLowerCase()}));
            ClickHandler(item.type);
          }}
        >
          {item.Viewed ? <></> : <UnviewedNotificationCircle />}
          <div
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gridColumn: '2',
              gridRow: '1'
            }}
          >
            <div
              style={{
                width: '48px',
                height: '48px',
                background: 'black',
                maskImage: `url(${ExitApp})`,
                WebkitMaskImage: `url(${ExitApp})`,
                maskSize: '100% 100%',
                WebkitMaskSize: '100% 100%',
                transform: 'rotate(180deg)'
              }}
            ></div>
            <p style={{ marginLeft: '4px' }}>Session started</p>
            <p
              style={{
                position: 'absolute',
                fontSize: '12px',
                top: '0px',
                right: '0px'
              }}
            >
              {temp.getMonth() + 1}/{temp.getDate()}/{temp.getFullYear()} |{' '}
              {temp.getHours() % 12}:{temp.getMinutes()}{' '}
              {temp.getHours() / 12 >= 1 ? 'PM' : 'AM'}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </p>
          </div>
          <div
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '85%',
              gridColumn: '2',
              gridRow: '2/4',
              backgroundColor: 'white',
              paddingRight: '15%',
              borderRadius: '12px 0% 0% 12px'
            }}
          >
            <p>{item.Name}</p>
            <p>
              <b>{item.Company}</b>
            </p>
          </div>
          <div
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gridColumn: '2',
              gridRow: '3'
            }}
          ></div>
        </NotificationItemBlock>
      );
    case 'likes':
      //onsole.log("Liked", item, Pages[item.page_id]?Pages[item.page_id].title:"")
      return (
        <NotificationItemBlock
          {...item}
          onClick={() => {
            ClickHandler(item.type);
          }}
        >
          {item.Viewed ? <></> : <UnviewedNotificationCircle />}
          <div
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gridColumn: '2',
              gridRow: '1'
            }}
          >
            {/*<div style={{width:"48px", height:"48px", background:"black",}}> </div>*/}
            <LikeOutline
              Fill="#000"
              style={{ height: '48px', width: '48px' }}
            />
            <p style={{ marginLeft: '4px' }}>Likes</p>
            <p
              style={{
                position: 'absolute',
                fontSize: '12px',
                top: '0px',
                right: '0px'
              }}
            >
              {temp.getMonth() + 1}/{temp.getDate()}/{temp.getFullYear()} |{' '}
              {temp.getHours() % 12}:{temp.getMinutes()}{' '}
              {temp.getHours() / 12 >= 1 ? 'PM' : 'AM'}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </p>
          </div>
          <div
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '85%',
              gridColumn: '2',
              gridRow: '2'
            }}
          >
            <p>{item.Name}</p>
            <p>
              <b>{item.Company}</b>
            </p>
          </div>
          <div
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gridColumn: '2',
              gridRow: '3',
              overflow: 'hidden',
              backgroundColor: 'white',
              borderRadius: '12px 0% 0% 12px'
            }}
          >
            <div style={{ overflow: 'hidden', width: '72px', height: '100%' }}>
              <PortfolioContentOrThumbnail
                key={
                  item.SessionId + item.session_date + item.type + item.page_id
                    ? item.page_id
                    : '' + item.Viewed
                    ? item.Viewed
                    : '' + item.gallery_index
                    ? item.gallery_index
                    : ''
                }
                Ele={item}
                chosenPortfolio={Port}
                Pages={Pages}
                Thumb={true}
              />
            </div>
            <p style={{ marginLeft: '8px' }}>
              {Pages[item.page_id]
                ? Pages[item.page_id]?.title
                : item.page_id.includes('splash')
                ? 'Splash'
                : ''}
            </p>
          </div>
        </NotificationItemBlock>
      );
    case 'Notes':
      return (
        <NotificationItemBlock
          {...item}
          onClick={() => {
            ClickHandler(item.type);
          }}
        >
          {item.Viewed ? <></> : <UnviewedNotificationCircle />}
          <div
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gridColumn: '2',
              gridRow: '1'
            }}
          >
            <Comments Fill="#000" style={{ height: '48px', width: '48px' }} />
            <p style={{ marginLeft: '4px' }}>Comments</p>
            <p
              style={{
                position: 'absolute',
                fontSize: '12px',
                top: '0px',
                right: '0px'
              }}
            >
              {temp.getMonth() + 1}/{temp.getDate()}/{temp.getFullYear()} |{' '}
              {temp.getHours() % 12}:{temp.getMinutes()}{' '}
              {temp.getHours() / 12 >= 1 ? 'PM' : 'AM'}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </p>
          </div>
          <div
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '85%',
              gridColumn: '2',
              gridRow: '2'
            }}
          >
            <p>{item.Name}</p>
            <p>
              <b>{item.Company}</b>
            </p>
          </div>
          <div
            style={{
              gridColumn: '2',
              gridRow: '3',
              backgroundColor: 'white',
              borderRadius: '12px 0% 0% 12px',
              width: '100%',
              height: '100%'
            }}
          >
            <NoteContainer
              dangerouslySetInnerHTML={{ __html: item.Text }}
            ></NoteContainer>
          </div>
        </NotificationItemBlock>
      );
    default:
      return <></>;
  }

  //Viewed={true}
};

const NT = ({
  userInfo,
  SetMasterGuestPassSessionList,
  Dash,
  style,
  portfolios,
  SLV,
  SetSubSet,
  SetSSI2,
  SetNotifications,
  SetScrollableCount
}) => {
  // const [NotificationsList,SetNL] = useState([]);
  // const [PreProcessingNotificationsList,SetPrePNL] = useState([]);
  // const [PostProcessingNotificationsList,SetPPNL] = useState([]);
  // const [ScrollableCount,SetScrollableCount] = useState(10);

  // const [itterationCount, SetItterationCount] = useState(0);
  // const firestore = useFirestore();
  // let NEODASH = useRef(useSelector(state=>state.DashboardReducer.GuestpassSessionMasterCollection))
  //onsole.log("NEODASH",NEODASH.current)
  // const QSHANDLER = useCallback((QS)=>{
  //     new Promise((Resolve)=>{//onsole.log("UpdateNotifications",NotificationsList,Dash.GuestpassSessionMasterCollection, QS.docChanges(),itterationCount)

  //     let TempNL = [...NotificationsList]//
  //     let Changes = []

  //     QS.docChanges().forEach((change)=>{
  //         //onsole.log("CHANGE",change.type,{...change.doc.data(),id:change.doc.id})
  //         if (change.type === "added") {
  //             TempNL = [...TempNL, {...change.doc.data(),id:change.doc.id}];
  //             Changes.push({...change.doc.data(),id:change.doc.id})
  //             //SetNL([...NotificationsList,change.doc.data()])
  //             // //onsole.log("New city: ", change.doc.data());
  //         }
  //         if (change.type === "modified") {

  //             let tempNewObj = {...change.doc.data,id:change.doc.id}
  //             let Index = TempNL.findIndex((val)=>{return val.id === tempNewObj.id});
  //             if(Index > -1)
  //             TempNL[Index] = tempNewObj
  //             //SetNL([...NotificationsList,change.doc.data()])
  //             Changes.push({...change.doc.data(),id:change.doc.id})
  //             //onsole.log("Modified city: ", {...change.doc.data(),id:change.doc.id});
  //         }
  //     })
  //     if(QS.docChanges().length>0){

  //         let tempGPMSL = [...Dash.GuestpassSessionMasterCollection];
  //         Changes.forEach((val)=>{
  //             let index = tempGPMSL.findIndex((val2)=>{return val2.id === val.id})
  //             if(index === -1){
  //                 tempGPMSL.unshift(val)
  //             }
  //             else{
  //                 tempGPMSL[index] = val
  //             }
  //             //onsole.log("Dash.GuestpassSessionMasterCollection",Dash.GuestpassSessionMasterCollection,tempGPMSL)
  //         })
  //         //onsole.log("Updating","GuestpassSessionMasterCollection")
  //         SetMasterGuestPassSessionList(tempGPMSL)
  //         SetPrePNL(tempGPMSL)
  //     }
  //     else{
  //         SetPrePNL(Dash.GuestpassSessionMasterCollection)
  //     }
  //     SetNL(TempNL);
  //     SetItterationCount(itterationCount +1)
  //     Resolve(true);
  // })

  // },[NotificationsList, SetMasterGuestPassSessionList, Dash.GuestpassSessionMasterCollection, itterationCount])

  // useEffect(()=>{
  //     let date = new Date(Date.now())
  //     // date = new Date(date.setHours(0))
  //     // date = new Date(date.setMonth(-6))
  //     // date = new Date(date.setDate(-1))

  //     console.log("START SUB")
  //     // let PID = Dash.selectedPortfolioId
  //     //let count = 0
  //     // SetItterationCount(0);
  //     const UnSub = firestore.collection("sessions").where("company_id", '==', userInfo[0].company_id).where("updated_at",">=", date ).where("type","==","guest").orderBy("updated_at","desc").onSnapshot(QSHANDLER)
  //     return (()=>{SetNL([]);//onsole.log("End SUB");
  //     UnSub();})
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[Dash.GuestpassSessionMasterCollection])

  //
  //
  //
  // useEffect(()=>{
  //     //onsole.log("START SUB Testing",PreProcessingNotificationsList, NEODASH.current,Dash.GuestpassSessionMasterCollection )
  //     SetMasterGuestPassSessionList(PreProcessingNotificationsList)
  // },[PreProcessingNotificationsList, SetMasterGuestPassSessionList, Dash.GuestpassSessionMasterCollection])
  // useEffect(()=>{
  //     //onsole.log("NEODASH.current Testing", NEODASH.current,Dash.GuestpassSessionMasterCollection )
  //     // SetMasterGuestPassSessionList(PreProcessingNotificationsList)
  // },[PreProcessingNotificationsList, SetMasterGuestPassSessionList,Dash.GuestpassSessionMasterCollection])

  // useEffect(()=>{
  //     new Promise((resolve)=>{
  //         let TempNL = [...PreProcessingNotificationsList]
  //         // TempNL = TempNL.filter((val)=>{return val.portfolio_id === PID});
  //         let dateCut = DateRangeEnum[4];
  //         TempNL = TempNL.filter((val)=>{return val.session_date.seconds *1000 > dateCut()})
  //         //onsole.log("UpdateNotifications2",TempNL,"test4")
  //         let TempNLLiked = []
  //         let TempNLNote = []
  //         let TempNLStarted = [];
  //         TempNLStarted = TempNL.map((val)=>{
  //             return {
  //                 ...val,
  //                 SessionId:val.id,
  //                 session_date:val.session_date,
  //                 time:val.session_date,
  //                 portfolio_id:val.portfolio_id,
  //                 Name:val.gp_name,
  //                 Email:val.gp_email,
  //                 Company:val.gp_email.split('@')[1].split('.')[0],
  //                 type:"Start"
  //             }
  //         })
  //         //onsole.log('UpdateNotifications3',"PostStart")
  //         //onsole.log("TempNL1",TempNLLiked,TempNL)
  //         TempNLLiked = TempNL.map((val)=>{
  //             if(val?.notifications?.likes)
  //             return val?.notifications?.likes?.map((ele)=>{
  //                 if(ele.gallery_index !== undefined)
  //             return {...ele,
  //                 page_id:ele.id,
  //             SessionId:val.id,
  //             session_date:val.session_date,
  //             portfolio_id:val.portfolio_id,
  //             Name:val.gp_name,
  //             Email:val.gp_email,
  //             Company:val.gp_email.split('@')[1].split('.')[0],
  //             type:"likes"
  //         }
  //         else
  //         return null;
  //     })

  //         else
  //         {
  //             return null
  //         }
  //     })
  //     //onsole.log('UpdateNotifications4',"PostLike")
  //     TempNLNote = TempNL.map((val)=>{
  //         if(val?.notifications?.Notes)
  //         return(val?.notifications?.Notes?.map((ele)=>{
  //             return {...ele,

  //             SessionId:val.id,
  //             session_date:val.session_date,
  //             portfolio_id:val.portfolio_id,
  //             Name:val.gp_name,
  //             Email:val.gp_email,
  //             Company:val.gp_email.split('@')[1].split('.')[0],
  //             type:"Notes"
  //         }}))
  //         else
  //         return null
  //     })
  //     //onsole.log('UpdateNotifications5',"PostNote")
  //     TempNLNote = TempNLNote.filter((ele)=>{return ele })//&& ele?.length >0
  //     TempNLLiked = TempNLLiked.filter((ele)=>{return ele })
  //         //TempNLLiked.flat(1);
  //         //onsole.log("TempNL2",TempNLLiked,TempNLNote,TempNL)
  //         let end = [...TempNLLiked,...TempNLNote,...TempNLStarted].flat(3)
  //         end = end.filter((ele)=>{return ele})
  //         //onsole.log('UpdateNotificationsEnd',end)
  //         // SetPPNL(end);
  //         resolve(end)
  //     }).then((end)=>{SetPPNL(end);})

  // },[PreProcessingNotificationsList])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    SetScrollableCount(10);
    return () => {
      SetScrollableCount(10);
    };
  }, [SetScrollableCount]);
  // useEffect(()=>{//onsole.log("NotificationsList",NotificationsList,NotificationsList.flat(3))
  // },[NotificationsList])
  // const test = ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",]
  //onsole.log("NotificationsList",NotificationsList)
  // useEffect(()=>{ console.log("SetNotifications",SetNotifications); if(!SetNotifications) return; SetNotifications( PostProcessingNotificationsList?PostProcessingNotificationsList.filter((val)=>{return Dash.selectedPortfolioId === val.portfolio_id}).sort((a,b)=>{if(b.time.seconds > a.time.seconds) return 1; if((b.time.seconds < a.time.seconds)) return -1; return 0}).slice(0,ScrollableCount).filter((val)=>{return !val.Viewed}).length :0)},
  // [Dash.selectedPortfolioId, PostProcessingNotificationsList, ScrollableCount, SetNotifications])
  return (
    // <div style={{gridColumn:'3/4', overflow:"scroll", display:"flex", flexDirection:"row", flexWrap:"wrap" }}>
    <div
      style={{ ...style }}
      onScroll={(eve) => {
        //onsole.log("eve.currentTarget",eve.currentTarget);
        const value = Dash.PostProcessingNotificationsList
          ? Dash.PostProcessingNotificationsList.filter((val) => {
              return Dash.selectedPortfolioId === val.portfolio_id;
            }).length
          : 0;
        //onsole.log("PostProcessingNotificationsList",PostProcessingNotificationsList?PostProcessingNotificationsList.filter((val)=>{return Dash.selectedPortfolioId === val.portfolio_id}).length:0,ScrollableCount,value,(ScrollableCount <value))
        if (
          eve.currentTarget.scrollTop + eve.currentTarget.offsetHeight >
            eve.currentTarget.scrollHeight * 0.9 &&
          Dash.ScrollableCount < value
        )
          SetScrollableCount(Dash.ScrollableCount + 10);
      }}
    >
      {Dash.PostProcessingNotificationsList ? (
        Dash.PostProcessingNotificationsList.filter((val) => {
          return Dash.selectedPortfolioId === val.portfolio_id;
        })
          .sort((a, b) => {
            if (b.time.seconds > a.time.seconds) return 1;
            if (b.time.seconds < a.time.seconds) return -1;
            return 0;
          })
          .map((val, I) => {
            // if(I < 10)
            // //onsole.log("INSPECT",val)
            // let beta = `rgba(${Math.random()*255},${Math.random()*255},${Math.random()*255},1)`
            if (I > Dash.ScrollableCount) return null;
            //onsole.log(beta);BG={beta} key={val.SessionId+val.session_date+val.type+val.page_id?val.page_id:""+val.Viewed?val.Viewed:""}
            return (
              <NotificationItems
                key={`k` + I}
                SetSSI2={SetSSI2}
                SetSubSet={SetSubSet}
                SLV={SLV}
                Dash={Dash}
                item={val}
                portfolios={portfolios}
              ></NotificationItems>
            );
          })
      ) : (
        <></>
      )}
    </div>
  );
};
export default NT;
