import React, { useEffect, useRef, useState } from 'react';
// import { useStore } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';
// import { useFirebase, useFirestore } from 'react-redux-firebase';
// import IN from '../../components/InputBox'

const SendSelfEmail = ({
  chosenPortfolio,
  companyConfig,
  SetGuestData,
  onExit,
  shareClick,
  isVideoPlaying,
  guestPass
}) => {
  const handleOnIdle = (event) => {
    if (!isVideoPlaying) {
      console.log('user is idle', event);
      console.log('last active', getLastActiveTime());
      console.log('Cause TimerElapsed.current');
      TimerElapsed.current = true;
      setBit(!bit);
    }
  };

  const handleOnActive = (event) => {
    console.log('user is active', event);
    console.log('time remaining', getRemainingTime());
  };
  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 3,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    debounce: 500
  });

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  //const firestore = useFirestore();
  const [bit, setBit] = useState(false); // Unsure on purpose of this useState
  // const [TimerElapsed,SetTimerElapse] = useState(false);
  const TimerElapsed = useRef(false);

  // Removing due to infinite loop, unsure on purpose of this
  // useEffect(() => {
  //   console.log('MIX EFFECT TimerElapsed.current');
  //   setBit(!bit);
  // }, [
  //   //TimerElapsed.current
  //   bit
  // ]);

  useEffect(() => {
    SetResolved(false);
  }, [bit]);

  const [Resolved, SetResolved] = useState(false);
  // const store = useStore();
  // let data = store.getState().guestReducer.GP_Data
  // const FiveMinuteTime = async()=>{
  //     await sleep(1000*60*1);
  //     SetTimerElapse(true);
  // }
  // const ThreeMinuteTime = async()=>{
  //     await sleep(1000*60*3);
  //     SetTimerElapse(true);
  // }
  const SendClick = () => {
    TimerElapsed.current = false;
    console.log('TimerElapsed.current ==false');
    SetTimerRunning(false);
    setBit(!bit);
    // SetResolved(true);
    // SETGP();
  };
  // const SETGP = ()=>{
  //     let guestpass2 = guestPass[0];
  //     //Send Req here
  //     //Update Guest viewer to show email link has been sent.
  //     let NV = [...guestpass2.Views]

  //     let NewViewDefine = {...NV[NV.findIndex((ele)=>{return ele.Name.trim().toLowerCase() === data.Name.trim().toLowerCase() && ele.Email.trim().toLowerCase() === data.Email.trim().toLowerCase()})],SelfEmail:true}
  //     NV.splice(NV[NV.findIndex((ele)=>{return ele.Name.trim().toLowerCase() === data.Name.trim().toLowerCase() && ele.Email.trim().toLowerCase() === data.Email.trim().toLowerCase()})],1,NewViewDefine);
  //     firestore.collection("guest_passes").doc(guestpass2.id).set({Views:[...NV]},{merge:true})

  // }
  // useEffect(()=>{
  //     let guestpass2 = guestPass[0];
  //     let NV = [...guestpass2.Views]

  //     if(!NV[NV.findIndex((ele)=>{return ele.Name.trim().toLowerCase() === data.Name.trim().toLowerCase() && ele.Email.trim().toLowerCase() === data.Email.trim().toLowerCase()})].SelfEmail)
  //     //FiveMinuteTime()
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[])
  const CHECKTE = () => {
    return TimerElapsed.current;
  };
  const [TIMERRUNNING, SetTimerRunning] = useState(false);
  const [TimeRemaining, SetTimeRemaining] = useState(10);
  const EndSessionCaller = async function (TE) {
    await sleep(10 * 1000);
    console.log('This', CHECKTE(), TE);
    if (CHECKTE()) {
      console.log('EXIT');
      SetTimerRunning(false);

      onExit(true);
      await sleep(1500);
      SetTimeRemaining(10);
    } else console.log('NOTEXIT');
    await sleep(1500);
    SetTimeRemaining(10);
  };

  const SecondsRemaining = async (TimeRemaining) => {
    let Temp = TimeRemaining;
    if (Temp - 1 < 0) return;
    await sleep(1000);

    SetTimeRemaining(Temp - 1);
    SecondsRemaining(Temp - 1);
  };
  if (!Resolved && TimerElapsed.current) {
    if (!TIMERRUNNING) {
      EndSessionCaller(TimerElapsed);
      SetTimerRunning(true);
      SecondsRemaining(TimeRemaining);
    }
    return (
      // <div className='portfolio-template'>
      <div
        className="send-email-popup"
        //style={{height:"100%", width:"100%", background:"rgba(0,0,0,0.5)", display:'flex', flexDirection:"column",justifyContent:"center", alignItems:"center", position:"absolute", top:0, left:0}}
      >
        <div
          className="send-email-popup-box"
          style={{
            padding: 'calc(25px * 1.43333)',
            textAlign: 'center',
            alignItems: 'unset',
            height: 'min-content',
            width: 'min-content',
            minWidth: `${96 * 2}px`,
            minheight: `${96 * 2}px`,
            display: 'flex',
            flexDirection: 'column',
            background: 'white',
            maxHeight: `${96 * 2}px`,
            justifyContent: 'space-evenly',
            border: '4px solid',
            borderColor: chosenPortfolio?.primary_color
              ? chosenPortfolio?.primary_color
              : companyConfig?.primary_color
              ? companyConfig?.primary_color
              : 'black',
            borderRadius: '8px'
          }}
        >
          <p>
            Does not seem like you have done anything for a while, if you would
            like to continue, please click below, otherwise we will be clouse
            out of this session in {TimeRemaining} seconds.
          </p>
          <div
            className="row-button-container"
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <div
              onClick={() => {
                SendClick();
              }}
              className="button"
              style={{
                height: 'unset',
                padding: '0.35em',
                width: 'min-content',
                border: chosenPortfolio?.primary_color
                  ? `1px solid ${chosenPortfolio?.primary_color}`
                  : '1px solid black',
                backgroundColor: 'white'
              }}
            >
              <p>continue</p>
            </div>
          </div>

          {/* </div> */}
        </div>
      </div>
      // </div>
    );
  } else return <></>;
};
export default SendSelfEmail;

//<p>Hey, we see that you've been browsing this portfolio for a while. Would you like us to send an email to <span onClick={()=>{if(!bit)setBit(true)}}>{Bit?
//                <IN onChange={
//                    (eve)=>{SetGuestData({...data,Email:eve.target.value})}
//                    /* FILL IN WITH SetGuestData */} value={data.Email} placeholder="Email" style={{display:'inline'}} Pos={{display:"inline"}}
//            type="email" name='email'/>:`${data.Email} (click to revise)`}</span> with the link so that you can come back later if you need to?</p>
//                {/* <div className='row-button-container' style={{overflowY:'scroll'}}> */}
//                {/* minWidth:"592px" */}
//                    <div  className='row-button-container' style={{display:'flex', flexDirection:"row", justifyContent:"space-around", alignItems:"center", }}>
//                        <div onClick={()=>{SendClick();}} className='button' style={{height:"unset", padding:'0.35em', width:"min-content",border: chosenPortfolio?.primary_color ? `1px solid ${chosenPortfolio?.primary_color}`: '1px solid black', backgroundColor: 'white'}}><p>Yes</p></div>
//                        <div onClick={()=>{shareClick(); SetResolved(true); SETGP();}} className='button' style={{height:"unset",padding:'0.35em', width:"30%",border: chosenPortfolio?.primary_color ? `1px solid ${chosenPortfolio?.primary_color}`: '1px solid black', backgroundColor: 'white'}}><p>Share this portfolio</p></div>
//                        <div onClick={()=>{SetTimerElapse(false); }} className='button' style={{height:"unset",padding:'0.35em', width:"30%",border: chosenPortfolio?.primary_color ? `1px solid ${chosenPortfolio?.primary_color}`: '1px solid black', backgroundColor: 'white'}}><p>Check in three minutes</p></div>
//                        {/* ThreeMinuteTime(); */}
//                        <div onClick={()=>{SetResolved(true);}} className='button' style={{height:"unset",padding:'0.35em', width:"min-content",border: chosenPortfolio?.primary_color ? `1px solid ${chosenPortfolio?.primary_color}`: '1px solid black', backgroundColor: 'white'}}><p>No</p></div>
//                    </div>
