import { connect } from 'react-redux';
import PortfolioPage from './PortfolioPage';
import {
  addGuestPassToSanity,
  setChosenPageId,
  setChosenMainPageButtonId,
  setMenuHide,
  clearAll,
  lastPrepPortfolio,
  resetPortfolioMidSession,
  setPortfolioSessionId,
  addSessionToSanity,
  setCurrentLevel,
  setContentPageId,
  updateNotepad2,
  kmReset,
  setNavHistory,
  setHasCurr,
  setHasPrev,
  setToolbarMenuOpen,
  setPortfolioCustomerInfo,
  hideModal,
  endPortfolio,
  updateSessionInSanity,
  setActiveSessionNotifications
} from '../../actions';

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  userInfo: state.firestore.ordered.users,
  portfolio: state.sanityContentReducer.portfolios,
  member: state.sanityContentReducer.member,
  companyConfig: state.sanityContentReducer.companyConfig,
  mode: state.portfolioReducer.mode,
  sessionId: state.portfolioReducer.sessionId,
  chosenPortfolioId: state.portfolioReducer.chosenPortfolioId,
  chosenPortfolio: state.portfolioReducer.chosenPortfolio,
  pages: state.portfolioReducer.pages,
  nav: state.portfolioReducer.nav,
  customerInfo: state.portfolioReducer.customerInfo,
  backgroundImageUrl: state.portfolioReducer.backgroundImageUrl,
  portfoliolaunchImageUrl: state.portfolioReducer.portfoliolaunchImageUrl,
  showBriefcase: state.briefcaseReducer.showBriefcase,
  briefcaseContent: state.briefcaseReducer.briefcaseContent,
  pageMenuReducer: state.pageMenuReducer,
  isGuestMode: state.guestReducer.isGuestMode,
  Topics: state.pageMenuReducer.Topic,
  Topics2: state.pageMenuReducer,
  subPageId: state.portfolioReducer.chosenSubPageId,
  isAtEnd: state.pageMenuReducer.isAtEnd,
  notepadText: state.notepadReducer.notepadText,
  notepadText2: state.notepadReducer.notepadText2,
  hasCurr: state.pageMenuReducer.hasCurr,
  hasPrev: state.pageMenuReducer.hasPrev,
  portfolioTopLevelImageUrl: state.portfolioReducer.portfolioTopLevelImageUrl,
  portfolioSubMenuImageUrl: state.portfolioReducer.portfolioSubMenuImageUrl,
  portfolioTermMenuImageUrl: state.portfolioReducer.portfolioTermMenuImageUrl,
  Size: state.portfolioReducer.Size,
  toolbarMenuOpen: state.portfolioReducer.toolbarMenuOpen,
  isModalOpen: state.modalReducer.isModalOpen,
  liked: state.portfolioReducer.liked,
  videos: state.sanityContentReducer.videos,
  activeSessionNotifications: state.portfolioReducer.activeSessionNotifications
});

const mapDispatchToProps = (dispatch) => {
  return {
    // toggleBriefcase: () => dispatch(toggleBriefcase()),
    // addToBriefcase: (obj) => dispatch(addToBriefcase(obj)),
    // removeFromBriefcase: (id) => dispatch(removeFromBriefcase(id)),
    // clearBriefcase: () => dispatch(clearBriefcase()),
    //setPortfolioSubpageId: (id) => dispatch(setPortfolioSubpageId(id)),
    updateNotepad2: (OldText, NewText, ContentTitle) =>
      dispatch(updateNotepad2(OldText, NewText, ContentTitle)),
    setActiveSessionNotifications: (payload) =>
      dispatch(setActiveSessionNotifications(payload)),
    endPortfolio: () => dispatch(endPortfolio()),
    addGuestPassToSanity: (obj) => dispatch(addGuestPassToSanity(obj)),
    setChosenPageId: (id) => dispatch(setChosenPageId(id)),
    setChosenMainPageButtonId: (id) => dispatch(setChosenMainPageButtonId(id)),
    kmReset: () => dispatch(kmReset()),
    setMenuHide: (bool) => dispatch(setMenuHide(bool)),
    clearAll: () => dispatch(clearAll()),
    RPMS: () => dispatch(resetPortfolioMidSession()),
    lastPrepPortfolio: (form) => dispatch(lastPrepPortfolio(form)),
    setPortfolioSessionId: (id) => dispatch(setPortfolioSessionId(id)),
    addSessionToSanity: (session) => dispatch(addSessionToSanity(session)),
    setNavHistory: (array) => dispatch(setNavHistory(array)),
    setContentPageId: (id) => dispatch(setContentPageId(id)),
    setCurrentLevel: (number) => dispatch(setCurrentLevel(number)),
    setHasCurr: (bool) => dispatch(setHasCurr(bool)),
    setHasPrev: (bool) => dispatch(setHasPrev(bool)),
    setToolbarMenuOpen: (bool) => dispatch(setToolbarMenuOpen(bool)),
    setPortfolioCustomerInfo: (obj) => dispatch(setPortfolioCustomerInfo(obj)),
    hideModal: () => dispatch(hideModal()),
    updateSessionInSanity: (obj) => dispatch(updateSessionInSanity(obj))
  };
};

const PortfolioPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PortfolioPage);

/* testing withFirestore
const PortfolioPageContainer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFirestore,
  withHandlers({
    createSession: (props) => () => {
      const session = {
        title: 'TEST',
        session_id: '123',
        creater: 'test',
        page_visted: [{ page_id: '098' }],
        session_date: props.firebase.firestore.Timestamp.now()
      };
      return props.firestore.add({ collection: 'sessions' }, session);
    }
  })
)(PortfolioPage);
*/
export default PortfolioPageContainer;

// let ID = v4();
// //onsole.log(OldText)
// while(OldText.includes(ID))
//   ID = v4();
// let NT = `<p>${ContentTitle}</p>`+`<p id='${ID}'></p>`+NewText+`<script>var date = new Date(${new Date().getTime()}); document.getElementById('${ID}').textContent += \`\${date}\`</script>`
// let RT = OldText + NT;
// //onsole.log("RT1",RT);
