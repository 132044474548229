import React from 'react';
import { urlFor } from '../../../utils/sanityHelper';
import SanityMuxPlayer from '../../../containers/MuxPlayer';
import FontUtil from '../../../utils/FontUtility';

const SubLevelThumb = ({
  chosenPortfolio,
  foundPage,
  child,
  index,
  onNavClick,
  videos,
  pageContents,
  isTopLevel,
  isAtEnd,
  onBackClick,
  isAtTerm,
  clickedTerminusId,
  companyConfig
}) => {
  // FLAG FONT FAMILY
  //////onsole.log("FOUND PAGE", foundPage);
  //////onsole.log("CHILD", child)

  let iframeOverlay = null;
  if (isAtTerm === true) {
    iframeOverlay = (
      <div
        style={{
          display: 'block',
          position: 'absolute',
          width: '100%',
          height: '100%'
        }}
      ></div>
    );
  }

  let titleOverlay = null;
  if (isAtTerm === true) {
    titleOverlay = (
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '50px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          bottom: 0,
          left: 0,
          backgroundColor: chosenPortfolio?.primary_color
            ? `${chosenPortfolio.primary_color}80`
            : '#000000BF',
          overflow: 'hidden'
        }}
      >
        <p
          style={{
            width: '100%',
            marginBlockStart: '0px',
            marginBlockEnd: '0px',
            fontWeight: 600,
            fontSize: '16px',
            color: foundPage.text_color // if text_color exists in foundPage use that, if not use either chosenPortfolio.text_color or white
              ? foundPage.text_color
              : chosenPortfolio?.text_color
              ? chosenPortfolio.text_color
              : 'white',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            paddingLeft: '7px',
            paddingRight: '2px',
            fontFamily: FontUtil(
              chosenPortfolio.ContentText,
              null,
              "'Roboto', serif"
            )
          }}
        >
          {foundPage.title}
        </p>
        <p
          style={{
            width: '100%',
            marginBlockStart: '0px',
            marginBlockEnd: '0px',
            fontWeight: 600,
            fontSize: '13px',
            color: foundPage.text_color // if text_color exists in foundPage use that, if not use either chosenPortfolio.text_color or white
              ? foundPage.text_color
              : chosenPortfolio?.text_color
              ? chosenPortfolio.text_color
              : 'white',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            paddingLeft: '7px',
            paddingRight: '2px',
            fontFamily: FontUtil(
              chosenPortfolio.ContentText,
              null,
              "'Roboto', serif"
            )
          }}
        >
          {foundPage.subtitle}
        </p>
      </div>
    );
  }

  let thumbnailTile = null;
  let htmlThumb = foundPage.content
    ? foundPage.content.find((piece) => piece._type === 'imageLinks')
    : null;
  let galImgThumb = foundPage.content
    ? foundPage.content.find((piece) => piece._type === 'galleryImage')
    : null;
  let pdfThumb = foundPage.content
    ? foundPage.content.find(
        (piece) =>
          piece._type === 'galleryPdf' || piece._type === 'galleryFullWidthPdf'
      )
    : null;
  let videoThumb = foundPage.content
    ? foundPage.content.find((piece) => piece.video)
    : null;
  let toggleThumb = foundPage.content
    ? foundPage.content.find((piece) => piece._type === 'toggleMenu')
    : null;
  let sliderThumb = foundPage.content
    ? foundPage.content.find((piece) => piece._type === 'galleryBASlider')
    : null;

  let webUrl = foundPage._type === 'website' ? foundPage.url : null;
  let webThumb =
    webUrl && foundPage?.thumbnail?.asset?._ref
      ? foundPage.thumbnail.asset._ref
      : null;
  let videoPreview =
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    foundPage.content &&
    foundPage.content.find((piece) => piece.video) !== undefined
      ? videoThumb.video.asset._ref
      : null;
  let pdfThumbnail =
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    foundPage.content &&
    (foundPage.content.find((piece) => piece._type === 'galleryPdf') !==
      undefined ||
      foundPage.content.find(
        (piece) => piece._type === 'galleryFullWidthPdf'
      ) !== undefined)
      ? pdfThumb.thumbnail.asset._ref
      : null;
  let galleryImage =
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    foundPage.content &&
    foundPage.content.find((piece) => piece._type === 'galleryImage') !==
      undefined
      ? galImgThumb.image.asset._ref
      : null;
  let sliderThumbnail =
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    foundPage.content &&
    foundPage.content.find((piece) => piece._type === 'galleryBASlider') !==
      undefined
      ? sliderThumb.Bimage.asset._ref
      : null;
  let toggleThumbnail;
  if (
    toggleThumb === undefined ||
    toggleThumb === null ||
    (foundPage._type !== 'gallery' && foundPage._type !== 'sliderGallery') ||
    !foundPage?.content
  ) {
    toggleThumbnail = undefined;
  } else if (toggleThumb.content[0]._type === 'galleryImage') {
    toggleThumbnail = toggleThumb.content[0].image.asset._ref;
  } else if (
    toggleThumb.content[0]._type === 'galleryPDF' ||
    toggleThumb.content[0]._type === 'galleryFullWidthPdf'
  ) {
    toggleThumbnail = toggleThumb.content[0].thumbnail.asset._ref;
  } else if (toggleThumb.content[0]._type === 'galleryVideo') {
    let videoRef = toggleThumb.content[0].video.asset._ref;
    toggleThumbnail = videos[videoRef];
  } else if (toggleThumb.content[0]._type === 'galleryBASlider') {
    toggleThumbnail = toggleThumb.content[0].Bimage.asset._ref;
  } else {
    toggleThumbnail = undefined;
  }
  let htmlContentImage =
    foundPage._type === 'htmlContent' &&
    foundPage.content &&
    foundPage.content.find((piece) => piece._type === 'imageLinks') !==
      undefined
      ? htmlThumb.image.asset._ref
      : null;
  let finalVideo = videoPreview !== null ? videos[videoPreview] : null;

  if (
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    galleryImage !== null
  ) {
    thumbnailTile = (
      <div
        className="big-nav"
        // onClick={onBackClick !== null ? () => onBackClick() : null}
        style={
          isAtEnd ? { height: '100%', width: '100%', borderRadius: '0px' } : {}
        }
      >
        <div className="portfolio-content group-content">
          <img
            src={urlFor(galleryImage).auto('format').url()}
            alt="logo"
            className="portfolio-single-image"
            style={{
              height: '100%',
              width: '100%'
            }}
          />
        </div>
        {titleOverlay}
      </div>
    );
  } else if (
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    pdfThumbnail !== null
  ) {
    thumbnailTile = (
      <div
        className="big-nav"
        // onClick={onBackClick !== null ? () => onBackClick() : null}
        style={
          isAtEnd ? { height: '100%', width: '100%', borderRadius: '0px' } : {}
        }
      >
        <div className="portfolio-content group-content">
          <img
            src={urlFor(pdfThumbnail).auto('format').url()}
            alt="logo"
            className="portfolio-single-image"
            style={{
              height: '100%',
              width: '100%'
            }}
          />
        </div>
        {titleOverlay}
      </div>
    );
  } else if (
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    videoPreview !== null
  ) {
    thumbnailTile = (
      <div
        className="big-nav"
        // onClick={onBackClick !== null ? () => onBackClick() : null}
        style={
          isAtEnd ? { height: '100%', width: '100%', borderRadius: '0px' } : {}
        }
      >
        <div className="portfolio-content group-content">
          <SanityMuxPlayer
            className="mux-player"
            assetDocument={finalVideo}
            autoload={false}
            autoplay={false}
            loop={false}
            muted={true}
            SIG={false}
            SIP={false}
            showControls={false}
            style={{}}
            posterurl={`https://image.mux.com/${finalVideo.playbackId}/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=1`}
            type="galleryVideo"
            isSelected={false}
          />
          {iframeOverlay}
          {titleOverlay}
        </div>
      </div>
    );
  } else if (foundPage._type === 'htmlContent' && htmlContentImage !== null) {
    thumbnailTile = (
      <div
        className="big-nav"
        // onClick={onBackClick !== null ? () => onBackClick() : null}
        style={
          isAtEnd ? { height: '100%', width: '100%', borderRadius: '0px' } : {}
        }
      >
        <div className="portfolio-content group-content">
          <img
            src={urlFor(htmlContentImage).auto('format').url()}
            alt="logo"
            className="portfolio-single-image"
            style={{
              height: '100%',
              width: '100%'
            }}
          />
        </div>
        {titleOverlay}
      </div>
    );
  } else if (foundPage._type === 'website' && webUrl !== null) {
    if (webThumb) {
      thumbnailTile = (
        <div
          className="big-nav"
          // onClick={onBackClick !== null ? () => onBackClick() : null}
          style={
            isAtEnd
              ? { height: '100%', width: '100%', borderRadius: '0px' }
              : {}
          }
        >
          <div className="portfolio-content group-content">
            <img
              src={urlFor(webThumb).auto('format').url()}
              alt="logo"
              className="portfolio-single-image"
              style={{
                height: '100%',
                width: '100%'
              }}
            />
          </div>
          {titleOverlay}
        </div>
      );
    } else {
      thumbnailTile = (
        <div
          className="big-nav"
          // onClick={onBackClick !== null ? () => onBackClick() : null}
          style={
            isAtEnd
              ? { height: '100%', width: '100%', borderRadius: '0px' }
              : {}
          }
        >
          <div className="portfolio-content group-content">
            <div className="iframe-container">
              <iframe
                scrolling="no"
                title="iframe"
                allowFullScreen
                src={webUrl}
              ></iframe>
            </div>
            {iframeOverlay}
            {titleOverlay}
          </div>
        </div>
      );
    }
  } else if (
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    sliderThumbnail !== undefined &&
    sliderThumbnail !== null
  ) {
    thumbnailTile = (
      <div
        className="big-nav"
        // onClick={onBackClick !== null ? () => onBackClick() : null}
        style={
          isAtEnd ? { height: '100%', width: '100%', borderRadius: '0px' } : {}
        }
      >
        <div className="portfolio-content group-content">
          <img
            src={urlFor(sliderThumbnail).auto('format').url()}
            alt="logo"
            className="portfolio-single-image"
            style={{
              height: '100%',
              width: '100%'
            }}
          />
          {titleOverlay}
        </div>
      </div>
    );
  } else if (
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    toggleThumb !== undefined &&
    toggleThumb !== null
  ) {
    if (toggleThumb.content[0]._type === 'galleryImage') {
      thumbnailTile = (
        <div
          className="big-nav"
          // onClick={onBackClick !== null ? () => onBackClick() : null}
          style={
            isAtEnd
              ? { height: '100%', width: '100%', borderRadius: '0px' }
              : {}
          }
        >
          <div className="portfolio-content group-content">
            <img
              src={urlFor(toggleThumbnail).auto('format').url()}
              alt="logo"
              className="portfolio-single-image"
              style={{
                height: '100%',
                width: '100%'
              }}
            />
          </div>
          {titleOverlay}
        </div>
      );
    } else if (
      toggleThumb.content[0]._type === 'galleryPDF' ||
      toggleThumb.content[0]._type === 'galleryFullWidthPdf'
    ) {
      thumbnailTile = (
        <div
          className="big-nav"
          // onClick={onBackClick !== null ? () => onBackClick() : null}
          style={
            isAtEnd
              ? { height: '100%', width: '100%', borderRadius: '0px' }
              : {}
          }
        >
          <div className="portfolio-content group-content">
            <img
              src={urlFor(toggleThumbnail).auto('format').url()}
              alt="logo"
              className="portfolio-single-image"
              style={{
                height: '100%',
                width: '100%'
              }}
            />
          </div>
          {titleOverlay}
        </div>
      );
    } else if (toggleThumb.content[0]._type === 'galleryVideo') {
      thumbnailTile = (
        <div
          className="big-nav"
          // onClick={onBackClick !== null ? () => onBackClick() : null}
          style={
            isAtEnd
              ? { height: '100%', width: '100%', borderRadius: '0px' }
              : {}
          }
        >
          <div className="portfolio-content group-content">
            <SanityMuxPlayer
              className="mux-player"
              assetDocument={toggleThumbnail}
              autoload={false}
              autoplay={false}
              loop={false}
              muted={true}
              SIG={false}
              SIP={false}
              showControls={false}
              style={{}}
              posterurl={`https://image.mux.com/${toggleThumbnail.playbackId}/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=1`}
              type="galleryVideo"
              isSelected={false}
            />
            {iframeOverlay}
            {titleOverlay}
          </div>
        </div>
      );
    } else if (toggleThumb.content[0]._type === 'galleryBASlider') {
      thumbnailTile = (
        <div
          className="big-nav"
          // onClick={onBackClick !== null ? () => onBackClick() : null}
          style={
            isAtEnd
              ? { height: '100%', width: '100%', borderRadius: '0px' }
              : {}
          }
        >
          <div className="portfolio-content group-content">
            <img
              src={urlFor(toggleThumbnail).auto('format').url()}
              alt="logo"
              className="portfolio-single-image"
              style={{
                height: '100%',
                width: '100%'
              }}
            />
            {titleOverlay}
          </div>
        </div>
      );
    } else {
      thumbnailTile = (
        <div
          className="big-nav"
          // onClick={onBackClick !== null ? () => onBackClick() : null}
          style={
            isAtEnd
              ? { height: '100%', width: '100%', borderRadius: '0px' }
              : {}
          }
        >
          {chosenPortfolio?.portfolio_logo.asset?._ref ? (
            <div className="logo-thumb">
              <img
                src={urlFor(chosenPortfolio.portfolio_logo.asset._ref)
                  .auto('format')
                  .width(400)
                  .url()}
                alt="logo"
                style={{
                  height: '100%',
                  width: '100%'
                }}
              />
            </div>
          ) : null}
          {titleOverlay}
        </div>
      );
    }
  } else {
    thumbnailTile = (
      <div
        className="big-nav"
        // onClick={onBackClick !== null ? () => onBackClick() : null}
        style={
          isAtEnd ? { height: '100%', width: '100%', borderRadius: '0px' } : {}
        }
      >
        {chosenPortfolio?.portfolio_logo.asset?._ref ? (
          <div className="logo-thumb">
            <img
              src={urlFor(chosenPortfolio.portfolio_logo.asset._ref)
                .auto('format')
                .width(400)
                .url()}
              alt="logo"
              style={{
                height: '100%',
                width: '100%'
              }}
            />
          </div>
        ) : null}
        {titleOverlay}
      </div>
    );
  }

  return thumbnailTile;
};

export default SubLevelThumb;
