import React from 'react';
import ArrowBackRounded from '@material-ui/icons/ArrowBackRounded';

const Modal = (props) => {
  const handleToggle = () => {
    props.setToolbarMenuOpen(true);
  };

  return (
    <div
      style={{
        backgroundColor: props.chosenPortfolio?.action_menu_color
          ? `${props.chosenPortfolio.action_menu_color}`
          : '#E2DFDD'
      }}
      className={`modal ${props.isModalOpen ? 'active' : 'hidden'}`}
    >
      <div className="modal-close">
        <div className="svg-box">
          <ArrowBackRounded
            onClick={() => {
              handleToggle();
              props.hideModal();
              props.setToolbarMenuVisibility(true);
            }}
            style={{
              color: props.chosenPortfolio?.bulk_icon_override
                ? props.chosenPortfolio.bulk_icon_override
                : '#3b3b3bBF'
            }}
          />
        </div>
        <div className="text-box">
          <p
            style={{
              color: props.chosenPortfolio?.bulk_icon_override
                ? props.chosenPortfolio.bulk_icon_override
                : '#3b3b3bBF'
            }}
          >
            Customer Information
          </p>
        </div>
      </div>
      <div className="modal-body">
        <div className="modal-container">{props.children}</div>
      </div>
    </div>
  );
};

export default Modal;
