// Custom hook to add to template

import { useState, useEffect } from 'react';

const useTemplate = ({
  setChosenMainPageButtonId,
  setChosenPageId,
  pages,
  chosenPortfolio,
  pageMenuReducer,
  setCurrentLevel
}) => {
  const [currentMainButtonId, setCurrentMainButtonId] = useState('');

  useEffect(() => {
    if (chosenPortfolio.length > 0) {
      //set chosen id to first menu button
      setCurrentMainButtonId(chosenPortfolio.pages[0]._ref);
    }
  }, [chosenPortfolio]);

  // given page id, return true or false whether next page/menu group contains content_group
  const doesNextContainGroup = (id) => {
    let doesContain = false;

    // check if next set of content_group has content_group
    doesContain = pages[id].tabs.content_group.some((obj) => {
      const foundPage = pages[obj._ref];
      // if found return true immediately instead of looking through whole array
      return foundPage.content_type === 'content_group';
    });

    return doesContain;
  };

  // return bool if user is at the end of content
  const checkIfContentEnd = (id) => {
    // check if next set of content_group has content_group
    let doesContain = doesNextContainGroup(id);

    let b = false;
    // Set whether it is at the end of content
    if (doesContain) {
      b = false;
      //setIsAtEnd(false);
    } else {
      b = true;
      //setIsAtEnd(true);
    }

    return b;
  };

  // on Menu button click
  const onButtonClick = (id, level) => {
    if (level === 0) {
      setChosenMainPageButtonId(id);
    }
    setChosenPageId(id);
    setCurrentLevel(level);
  };

  // active or not logic
  const getActiveString = (isActive, level) => {
    //isActive && level === currentLevel
    return isActive ? 'active' : 'hidden';
  };

  const getIsAtEndString = () => {
    // return string add 'isAtEnd' to all button and grouping className
    return pageMenuReducer.isAtEnd ? 'isAtEnd' : '';
  };

  return {
    currentMainButtonId,
    setCurrentMainButtonId,
    getActiveString,
    getIsAtEndString,
    onButtonClick,
    checkIfContentEnd,
    doesNextContainGroup
  };
};

export default useTemplate;
