import React, { useEffect, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components';
import PortfolioContentOrThumbnail from '../../../utils/PortfolioContentOrThumbnail';
import { Slider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const StyledEnumerationSlider = styled.div`
  height: 600px;
  width: 20%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const useStyles = makeStyles({
  root: {
    height: 400
  }
});

const SliderGallery = ({
  gallery,
  renderSingleContent,
  prepareCurrentGalleryContent,
  chosenPortfolio,
  page,
  Size
}) => {
  //////onsole.log("GALLERY", gallery);

  const [carouselPosition, setCarouselPosition] = useState(0);
  // const [IsRendering, SetIsRendering] = useState([]);
  // const [Gall, SetGall] = useState([]);
  let toggleCount = 1;

  useEffect(() => {
    setCarouselPosition(0);
  }, [gallery]);

  let valuetext = (value) => {
    return `${value}`;
  };

  function valueLabelFormat(value) {
    return marks.label;
  }

  const handleSliderChange = (event, newValue) => {
    let value;
    //////onsole.log("NEW VALUE in SLIDER", newValue);

    if (gallery.length === 5) {
      if (newValue === 96) {
        value = 0;
      } else if (newValue === 75) {
        value = 1;
      } else if (newValue === 50) {
        value = 2;
      } else if (newValue === 25) {
        value = 3;
      } else {
        value = 4;
      }
    } else if (gallery.length === 4) {
      if (newValue === 96) {
        value = 0;
      } else if (newValue === 67) {
        value = 1;
      } else if (newValue === 34) {
        value = 2;
      } else {
        value = 3;
      }
    } else if (gallery.length === 3) {
      if (newValue === 96) {
        value = 0;
      } else if (newValue === 50) {
        value = 1;
      } else {
        value = 2;
      }
    } else if (gallery.length === 2) {
      if (newValue === 96) {
        value = 0;
      } else {
        value = 1;
      }
    } else {
      return;
    }

    prepareCurrentGalleryContent(value);
    setCarouselPosition(value);
  };

  const marks = gallery.map((page, index) => {
    let quotient = 100 / (gallery.length - 1);
    let internalValue = Math.ceil(100 - quotient * index);
    if (internalValue === 100) {
      internalValue = 96;
    }
    let pageTitle;

    //////onsole.log("PAGE", page)
    if (page?.title && page?.title?.length <= 15) {
      pageTitle = page.title.toUpperCase();
    } else if (page?.title && page?.title?.length > 15) {
      pageTitle = `${page.title.slice(0, 13).toUpperCase()}..`;
    } else {
      pageTitle = `Toggle Menu ${toggleCount}`;
      toggleCount++;
    }

    return {
      value: internalValue,
      label: pageTitle
    };
  });

  const classes = useStyles();

  return (
    <>
      <StyledEnumerationSlider>
        <div className={classes.root}>
          <Slider
            defaultValue={96}
            valueLabelFormat={valueLabelFormat}
            getAriaValueText={valuetext}
            aria-labelledby="discrete-slider-restrict"
            step={null}
            valueLabelDisplay="off"
            marks={marks}
            orientation="vertical"
            onChange={handleSliderChange}
            color="primary"
          />
        </div>
      </StyledEnumerationSlider>
      <div
        className="portfolio-gallery-view"
        style={{
          width: '80%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Carousel
          swipeable={false}
          selectedItem={carouselPosition}
          renderArrowNext={(a, b, c) => {
            return null;
          }}
          renderArrowPrev={(a, b, c) => {
            return null;
          }}
          showIndicators={false}
          showStatus={false}
          autoplay={false}
          swipeScrollTolerance={100}
          preventMovementUntilSwipeScrollTolerance
          onChange={(index) => {
            if (carouselPosition !== index) {
              prepareCurrentGalleryContent(index);
              setCarouselPosition(index);
            }
          }}
          showThumbs={false}
        >
          {gallery.map((content, index) => {
            let isSelected = index === carouselPosition;
            return (
              <div key={'gallery-content-' + content._key + index}>
                {/* {renderSingleContent(content._type, index, isSelected, true, 'gallery-content-' + content._key + index, [SetGall, SetIsRendering], Gall, IsRendering, index === carouselPosition)} */}
                <PortfolioContentOrThumbnail
                  Ele={{ gallery_index: index, page_id: page._key, id: '' }}
                  isSelected={isSelected}
                  chosenPortfolio={chosenPortfolio}
                  Thumb={false}
                />
              </div>
            );
          })}
        </Carousel>
      </div>
    </>
  );
};
export default SliderGallery;
