import {
  SHOW_MODAL,
  HIDE_MODAL,
  RESET_PORTFOLIO_INFO,
  END_PORTFOLIO
} from '../constants/actionTypes';

const initialState = {
  isModalOpen: false
};
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        ...state,
        isModalOpen: true
      };
    case HIDE_MODAL:
      return {
        ...state,
        isModalOpen: false
      };
    case RESET_PORTFOLIO_INFO:
    case END_PORTFOLIO:
      return initialState;
    default:
      return state;
  }
};
