import React, { useState, useEffect, Fragment } from 'react';
import Button from '../../Button';
import IMG from '../../../assets/images/MenuMask.png';
import X from '../../../assets/images/X-Icon.png';
import FontUtil from '../../../utils/FontUtility';
const PortfolioBriefcase = ({
  sendClick,
  shareClick,
  companyConfig,
  chosenPortfolio,
  userInfo,
  showBriefcase,
  isGuestMode,
  liked,
  briefcaseContent,
  toggleBriefcase,
  removeFromBriefcase,
  clearBriefcase,
  customerInfo
}) => {
  const [isAblePortfolioGuestPass, setIsAblePortfolioGuestPass] =
    useState(false);
  useEffect(() => {
    ////////////onsole.log("!!UI!!",userInfo);
    ////////////onsole.log("!!UI4!!",userInfo.length > 0);

    if (userInfo && userInfo.length > 0) {
      //////////onsole.log('UI3', userInfo[0].is_able_to_create_guest_pass);
      // check if user is able create portfolio level guest pass
      // userInfo[0].is_able_to_create_guest_pass
      // TODO: Why is this constant condition?
      if (true) {
        // user is able to create normal guest pass, aka able to add item to briefcase
        setIsAblePortfolioGuestPass(true);
      }
    }
    if (userInfo[0]?.company_id === 'ENR6EWxLdKdPD0pCM374')
      setIsAblePortfolioGuestPass(true);
  }, [userInfo]);
  ////////////onsole.log("!!UI2!!",isAblePortfolioGuestPass)
  let BorderColor = chosenPortfolio.primary_color
    ? chosenPortfolio.primary_color
    : companyConfig.primary_color;

  //////////onsole.log("!!!!CUSTOMER INFO!!!! ", customerInfo);
  //////////onsole.log("!!!!User INFO!!!! ", userInfo);
  //////////onsole.log("!!LIKED!!", liked);
  //////////onsole.log("!!briefcaseContent!!", briefcaseContent);
  let PS = 0;
  briefcaseContent.forEach((element) => {
    //////////onsole.log("WORKING: ",element)
    //////////onsole.log("WORKING2: ",PS)
    if (!isNaN(parseInt(element?.PagesSaved)))
      PS = PS + parseInt(element?.PagesSaved);
    else PS = PS + 1;
  });
  return (
    <div
      style={{ borderColor: BorderColor, zIndex: 5, backgroundColor: 'white' }}
      className={`portfolio-briefcase-container ${
        showBriefcase ? 'active' : 'hidden'
      }`}
    >
      <div className="portfolio-briefcase-label">
        {/* <div onClick={toggle} style={{backgroundColor:'black', maskImage:`url(${IMG})`,maskSize:"100% 100%", maskRepeat:"no-repeat", WebkitMaskImage:`url(${IMG})`,WebkitMaskRepeat:'no-repeat', WebkitMaskSize:"100% 100%",Height:"96px",Width:"96px", cursor:`pointer`, alignSelf:"flex-end", float:"left",objectFit:"contain",objectPosition:"left", margin:"16px", marginBottom:'0px'}}/> */}
        {/* <img onClick={() => showBriefcase = false} src={this.props.Icons.icons.find(ele => ele.type =="close") != null?this.props.Icons.icons.find(ele => ele.type =="close").file.url:X} style={{maxHeight:"96px",maxWidth:"96px", cursor:`pointer`, alignSelf:"flex-end", float:"left",objectFit:"contain",objectPosition:"left", margin:"16px", marginBottom:'0px'}}></img>color:`${this.props.portfolio.color?this.props.portfolio.color:null}`, */}
        <div
          onClick={toggleBriefcase}
          style={{
            maskImage: `url(${X})`,
            maskSize: '100% 100%',
            maskRepeat: 'no-repeat',
            WebkitMaskImage: `url(${X})`,
            WebkitMaskRepeat: 'no-repeat',
            WebkitMaskSize: '100% 100%',
            backgroundColor: BorderColor,
            height: '96px',
            width: '96px',
            cursor: `pointer`,
            alignSelf: 'flex-end',
            float: 'right',
            objectFit: 'contain',
            objectPosition: 'right',
            margin: '16px',
            marginBottom: '0px'
          }}
        ></div>
        <p
          style={{
            fontSize: '33px',
            fontWeight: 'bold',
            marginBlockStart: '0px',
            marginBlockEnd: '36px',
            margin: '0px',
            marginLeft: '23px',
            position: 'relative',
            marginBottom: '16px',
            fontFamily: FontUtil(chosenPortfolio.SystemText)
          }}
        >
          {!isGuestMode
            ? customerInfo.firstName
              ? customerInfo.firstName
              : 'User'
            : ''}
        </p>
        <p
          style={{
            fontSize: '18px',
            marginBlockStart: '0px',
            marginBlockEnd: '0px',
            color: 'black',
            margin: '0px',
            marginLeft: '23px',
            position: 'relative',
            marginBottom: '16px',
            fontFamily: FontUtil(chosenPortfolio.SystemText)
          }}
        >
          {!isGuestMode ? 'Your Briefcase' : 'Your Liked content'}
        </p>
      </div>
      <div
        className="portfolio-briefcase briefcase-content-list"
        style={{
          maskImage: `url(${IMG})`,
          maskSize: '100% 100%',
          maskRepeat: 'no-repeat',
          WebkitMaskImage: `url(${IMG})`,
          WebkitMaskRepeat: 'no-repeat',
          WebkitMaskSize: '100% 100%'
        }}
      >
        <div
          className="filler-container"
          style={{
            backgroundColor: 'transparent',
            marginBottom: '64px',
            display: 'flex',
            position: 'relative',
            width: '100%'
          }}
        ></div>
        <hr style={{ width: 'calc(100% - 24px)' }} />
        {!isGuestMode
          ? briefcaseContent.map((content, index) => {
              //////////onsole.log(content);
              return (
                <div style={{ width: 'calc(100% - 24px)' }} key={`k` + index}>
                  <div
                    className="briefcase-content-list-item"
                    key={`briefcase-content-${content.id}-${index}`}
                  >
                    <p
                      style={{
                        marginLeft: 24 + 'px',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        fontFamily: FontUtil(chosenPortfolio.SystemText)
                      }}
                    >
                      {content.title}
                    </p>
                    <p
                      style={{
                        marginRight: '0px',
                        objectFit: 'contain',
                        fontSize: '14px',
                        cursor: `pointer`,
                        fontWeight: 'bold',
                        fontFamily: FontUtil(chosenPortfolio.SystemText)
                      }}
                      onClick={() => removeFromBriefcase(content.id)}
                    >
                      X
                    </p>
                  </div>
                  <hr />
                </div>
              );
            })
          : liked.map((content, index) => {
              return (
                <div style={{ width: 'calc(100% - 24px)' }} key={`k` + index}>
                  <div
                    className="briefcase-content-list-item"
                    key={`briefcase-content-${content.id}-${index}`}
                  >
                    <p
                      style={{
                        marginLeft: 24 + 'px',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        fontFamily: FontUtil(chosenPortfolio.SystemText)
                      }}
                    >
                      {content.title}
                    </p>
                  </div>
                  <hr />
                </div>
              );
            })}
        <div
          style={{
            backgroundColor: 'transparent',
            marginBottom: '64px',
            display: 'flex',
            position: 'relative',
            width: '100%'
          }}
        ></div>
      </div>

      <div
        className="page-saved-holder"
        style={{
          gridRow: '3/4',
          gridColumn: '1/2',
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          paddingTop: '16px',
          paddingBottom: '16px'
        }}
      >
        {' '}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around'
          }}
        >
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '20%' }}
          ></div>
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '20%' }}
          >
            <p
              style={{
                marginBlockEnd: '4px',
                marginBlockStart: '4px',
                fontFamily: FontUtil(chosenPortfolio.SystemText)
              }}
            >
              {!isGuestMode ? briefcaseContent.length : liked.length}
            </p>
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '20%' }}
          >
            <p
              style={{
                color: 'black',
                marginBlockEnd: '4px',
                marginBlockStart: '4px',
                whiteSpace: 'nowrap',
                transform: `translateX(${-30}px)`,
                fontFamily: FontUtil(chosenPortfolio.SystemText)
              }}
            >
              {!isGuestMode ? 'Total items in briefcase' : 'Total liked items'}
            </p>
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '20%' }}
          ></div>
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '20%' }}
          ></div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around'
          }}
        >
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '20%' }}
          ></div>
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '20%' }}
          >
            {!isGuestMode && (
              <p
                style={{
                  color: '#228B22',
                  marginBlockEnd: '4px',
                  marginBlockStart: '4px',
                  fontFamily: FontUtil(chosenPortfolio.SystemText)
                }}
              >
                {PS}
              </p>
            )}
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '20%' }}
          >
            {!isGuestMode && (
              <p
                style={{
                  marginBlockEnd: '4px',
                  marginBlockStart: '4px',
                  color: '#228B22',
                  whiteSpace: 'nowrap',
                  transform: `translateX(${-30}px)`,
                  fontFamily: FontUtil(chosenPortfolio.SystemText)
                }}
              >
                Pages saved from print{' '}
                <span role="img" aria-label="tree">
                  🌳
                </span>
              </p>
            )}
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '20%' }}
          ></div>
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '20%' }}
          ></div>
        </div>
      </div>

      <div
        style={{ backgroundColor: BorderColor }}
        className="briefcase-action-button-container"
      >
        {isGuestMode && (
          <Button
            enlarge={true}
            additionalClassName="briefcase-portfolio-send-button"
            width="100%"
            height="50px"
            backgroundColor={BorderColor}
            color={
              chosenPortfolio.text_color
                ? chosenPortfolio.text_color
                : companyConfig.text_color
            }
            text="Share Guest Pass"
            onClick={() => {
              shareClick(true);
              toggleBriefcase();
            }}
          />
        )}
        {isAblePortfolioGuestPass && (
          <Button
            enlarge={true}
            additionalClassName="briefcase-portfolio-send-button"
            width="100%"
            height="50px"
            backgroundColor={BorderColor}
            color={
              chosenPortfolio.text_color
                ? chosenPortfolio.text_color
                : companyConfig.text_color
            }
            text="Send Guest Pass"
            onClick={() => {
              sendClick(true);
              toggleBriefcase();
            }}
          />
        )}
        {briefcaseContent.length !== 0 && ( // only show there is content
          <Fragment>
            <Button
              enlarge={true}
              additionalClassName="briefcase-send-button"
              width="100%"
              height="50px"
              backgroundColor={BorderColor}
              color={
                chosenPortfolio.text_color
                  ? chosenPortfolio.text_color
                  : companyConfig.text_color
              }
              text="Send Briefcase"
              onClick={() => {
                sendClick(false);
                clearBriefcase();
                toggleBriefcase();
              }}
            />
            <Button
              enlarge={true}
              additionalClassName="briefcase-clear-button"
              width="100%"
              height="50px"
              backgroundColor={BorderColor}
              color={
                chosenPortfolio.text_color
                  ? chosenPortfolio.text_color
                  : companyConfig.text_color
              }
              text="Clear All"
              onClick={clearBriefcase}
            />
          </Fragment>
        )}
      </div>
      <div
        style={{ backgroundColor: 'white', gridRow: '1/3', gridColumn: '1/2' }}
      ></div>
    </div>
  );
};

export default PortfolioBriefcase;
