import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import sessionReducer from './sessionReducer';
import portfolioReducer from './portfolioReducer';
import sanityContentReducer from './sanityContentReducer';
import briefcaseReducer from './briefcaseReducer';
import modalReducer from './modalReducer';
import pageMenuReducer from './pageMenuReducer';
import guestReducer from './guestReducer';
import adminReducer from './adminReducer';
import notepadReducer from './notepadReducer';
import scriptnoteReducer from './scriptnoteReducer';
import videoReducer from './videoReducer';
import DashboardReducer from './DashboardRevampReducer';

export default combineReducers({
  sessionReducer,
  portfolioReducer,
  sanityContentReducer,
  briefcaseReducer,
  modalReducer,
  pageMenuReducer,
  guestReducer,
  adminReducer,
  notepadReducer,
  DashboardReducer,
  scriptnoteReducer,
  videoReducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer
});
