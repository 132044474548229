import React from 'react';
import X from '../../assets/images/X-Icon.png';

const Modal = (props) => {
  return (
    <div className="modal">
      <div
        className="modal-container"
        style={{ border: `4px solid ${props.chosenPortfolio.primary_color}` }}
      >
        <div className="modal-close">
          <div
            onClick={props.hideModal}
            style={{
              maskImage: `url(${X})`,
              maskSize: '100% 100%',
              maskRepeat: 'no-repeat',
              WebkitMaskImage: `url(${X})`,
              WebkitMaskRepeat: 'no-repeat',
              WebkitMaskSize: '100% 100%',
              backgroundColor:
                props.chosenPortfolio.primary_color &&
                (props.chosenPortfolio.primary_color === 'white' ||
                  props.chosenPortfolio.primary_color === '#FFFFFF')
                  ? 'black'
                  : props.chosenPortfolio.primary_color
                  ? props.chosenPortfolio.primary_color
                  : 'black',
              height: '80px',
              width: '80px',
              cursor: `pointer`,
              alignSelf: 'flex-end',
              float: 'right',
              objectFit: 'contain',
              objectPosition: 'right',
              margin: '16px',
              marginBottom: '0px'
            }}
            className="modal-close-button"
          />
        </div>
        {props.children}
      </div>
    </div>
  );
};

export default Modal;
