import { connect } from 'react-redux';
import MenuSection from './MenuSection';
import { setCurrentContentHelper, setSavedContentPageId } from '../../actions';

const mapStateToProps = (state) => ({
  isGuestMode: state.guestReducer.isGuestMode,
  isPortfolioView: state.guestReducer.isPortfolioView,
  guestContentHelper: state.guestReducer.guestContentHelper,
  companyConfig: state.sanityContentReducer.companyConfig,
  chosenPortfolio: state.portfolioReducer.chosenPortfolio,
  pages: state.portfolioReducer.pages,
  nav: state.portfolioReducer.nav,
  pageMenuReducer: state.pageMenuReducer,
  Size: state.portfolioReducer.Size
});

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentContentHelper: (id) => dispatch(setCurrentContentHelper(id)), // helps guest content
    setSavedContentPageId: (id) => dispatch(setSavedContentPageId(id))
  };
};

const MenuSectionContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuSection);

export default MenuSectionContainer;
