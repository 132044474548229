import React from 'react'; //{ useEffect, useState }
//import styled from "styled-components";

const LI = ({ EE, index }) => {
  return (
    <>
      <div
        style={{
          display: 'grid',
          columnGap: '3%',
          gridTemplateColumns: '58% 19.5% 12.5%',
          overflow: 'hidden',
          backgroundColor: index % 2 === 1 ? '#eeeeee' : 'rgb(222, 222, 222)'
        }}
      >
        <div
          style={{
            gridColumn: '1/1',
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center'
          }}
        >
          <p
            style={{
              gridColumn: '1/1',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              paddingLeft: '20px',
              fontFamily: `'Newsreader', serif`,
              fontWeight: '600',
              color: '#3b3b3b'
            }}
          >
            {EE.Email}
          </p>
        </div>
        <div
          style={{
            gridColumn: '2/2',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <p
            style={{
              gridColumn: '2/2',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              margin: 'auto',
              fontFamily: `'Open Sans', serif`,
              fontWeight: '600',
              color: '#3b3b3b'
            }}
          >
            {EE.New ? '*' : ''}
          </p>
        </div>
        <div
          style={{
            gridColumn: '3/3',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <p
            style={{
              gridColumn: '3/3',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontFamily: `'Open Sans', serif`,
              fontWeight: '600',
              color: '#3b3b3b'
            }}
          >
            {EE.Opens}
          </p>
        </div>
      </div>
    </>
  );
};
export default LI;
