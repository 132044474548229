import T from './TMGController';
import { connect } from 'react-redux';
import {
  endPortfolio,
  toggleNotepad,
  toggleBriefcase,
  clearAll,
  setPortfolioCustomerInfo,
  setLiked,
  setBooleanNewItems,
  addToBriefcase,
  updateSessionInSanity
} from '../../../../actions';
const mapDispatchToProps = (dispatch) => {
  return {
    endPortfolio: () => dispatch(endPortfolio()),
    toggleNotepad: () => dispatch(toggleNotepad()),
    toggleBriefcase: () => dispatch(toggleBriefcase()),
    clearAll: () => dispatch(clearAll()),
    setPortfolioCustomerInfo: (obj) => dispatch(setPortfolioCustomerInfo(obj)),
    setLiked: (array) => dispatch(setLiked(array)),
    setBooleanNewItems: (bool) => dispatch(setBooleanNewItems(bool)),
    addToBriefcase: (obj) => dispatch(addToBriefcase(obj)),
    updateSessionInSanity: (obj) => dispatch(updateSessionInSanity(obj)),
    SET_ANNOTATOR: (t) => dispatch({ type: 'SET_ANNOTATOR', payload: t })
  };
};
const mapStateToProps = (state) => ({
  chosenContentPageId: state.pageMenuReducer.chosenContentPageId,
  pages: state.portfolioReducer.pages,
  images: state.sanityContentReducer.images,
  videos: state.sanityContentReducer.videos,
  pdfs: state.sanityContentReducer.pdfs,
  showNotepad: state.notepadReducer.showNotepad,
  showBriefcase: state.briefcaseReducer.showBriefcase,
  mode: state.portfolioReducer.mode,
  pages2: state.sanityContentReducer.pages,
  sessionId: state.portfolioReducer.sessionId,
  briefcaseContent: state.briefcaseReducer.briefcaseContent,
  isModalOpen: state.modalReducer.isModalOpen,
  chosenPortfolio: state.portfolioReducer.chosenPortfolio,
  menuHide: state.pageMenuReducer.menuHide,
  isGuestMode: state.guestReducer.isGuestMode,
  currentGuestContentHelper: state.guestReducer.currentContentHelper,
  liked: state.portfolioReducer.liked,
  userInfo: state.firestore.ordered.users,
  Size: state.portfolioReducer.Size,
  booleanNewItems: state.briefcaseReducer.booleanNewItems,
  ANNOTATOR2: state.portfolioReducer.aNNOTATOR
});
export default connect(mapStateToProps, mapDispatchToProps)(T);
