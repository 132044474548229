import { React } from 'react';

const CloseIcon = ({ Fill, Rotation, onClick, className, id, style }) => {
  Fill = '#c1c0c0';
  return (
    <svg
      className={className}
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => {
        onClick();
      }}
      width={100}
      height={100}
      style={{ ...style, transform: `rotate(${Rotation}deg)` }}
    >
      <defs>
        <mask id={`cross${id}`}>
          <rect
            x={'0'}
            y={'0'}
            width={'100'}
            height={'100'}
            fill="white"
          ></rect>
          <polyline
            points="25,25 75,75"
            fill="black"
            stroke="black"
            strokeWidth={'9px'}
          ></polyline>
          <polyline
            points="25,75 75,25"
            fill="black"
            stroke="black"
            strokeWidth={'9px'}
          ></polyline>
        </mask>
      </defs>
      <circle
        cx={50}
        cy={50}
        r={50}
        fill={Fill}
        mask={`url(#cross${id})`}
      ></circle>

      {/*  filter='blur(2px)' */}
    </svg>
  );
};
export default CloseIcon;
