import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Notes from './Comments/Notes/';
import Graph from './TotalTimeSpentGraph';
import LT from './Likes/';
const SessionItem = styled.div`
  width: 100%;
  flex-shrink: 0;
  height: 72px;
  background-color: #fcfafa;
  &.Active {
    background-color: #a3a2a2;
  }
  &:hover {
    background-color: #e6e6e6;
  }
`;
const HoverUnderline = styled.p`
  display: inline;
  &:hover {
    text-decoration: underline;
  }
`;
const BaseGrid1 = styled.div`
  display: grid;
  grid-template-columns: 48px calc(100% - 120px) 72px;
  height: 100%;
`;
const BaseGrid2 = styled.div`
  grid-column: 2/2;
  display: grid;
  grid-template-columns: 20% 80%;
  grid-template-rows: 12% minmax(0, calc(88% - 48px)) 48px;
  height: 100%;
`;
const CoreItem = ({
  SubSet,
  DashGL,
  Dash,
  SetSubSet,
  SelectedSessionIndex2,
  SetSSI2
}) => {
  const [SelectedSessionIndex, SetSSI] = useState(undefined);
  const [SessionSubset, SetSS] = useState([]);
  const [Core] = useState(); //setCore
  useEffect(() => {
    SetSSI(undefined);
    switch (SubSet) {
      case 'Sessions':
        SetSS(DashGL?.Sessions);
        // setCore(<Graph width={ref.current?.offsetWidth} height={ref.current?.offsetheight} sessions={SessionSubset} SingleSession={SelectedSessionIndex !== undefined?SessionSubset[SelectedSessionIndex]:undefined} ></Graph>)
        break;
      case 'Comments': {
        let temp = DashGL?.Sessions.filter((ele) => {
          return ele.notes;
        });
        // setCore()
        SetSS(temp);
        break;
      }
      case 'Likes': {
        let temp = DashGL?.Sessions.filter((ele) => {
          return ele.liked;
        });
        SetSS(temp);
        break;
      }

      default:
        break;
    }
  }, [SubSet, DashGL]);
  const ref = useRef();
  useEffect(() => {
    if (SelectedSessionIndex2 !== undefined) SetSSI(SelectedSessionIndex2);
    SetSSI2(undefined);
  }, [SelectedSessionIndex2, SetSSI2]);

  //onsole.log(DashGL)
  return (
    <BaseGrid1>
      <BaseGrid2>
        <div style={{ textAlign: 'center', fontSize: '20px' }}>
          <p>{SubSet}</p>
        </div>
        {/* backgroundColor:"#ace" */}
        <div style={{}}>
          <p style={{ textAlign: 'center', color: 'black' }}>
            <span style={{ fontSize: '1.3em' }}>
              {Dash.selectedPortfolioName} |{' '}
              {new Intl.DateTimeFormat(undefined, {
                month: 'long',
                day: 'numeric',
                year: 'numeric'
              }).format(
                new Date(
                  SessionSubset[SelectedSessionIndex]?.session_date.seconds *
                  1000
                    ? SessionSubset[SelectedSessionIndex]?.session_date
                        .seconds * 1000
                    : DashGL.Sessions[0]?.session_date.seconds * 1000
                )
              )}{' '}
              |{' '}
              {new Intl.DateTimeFormat(undefined, {
                hour12: 'true',
                hour: 'numeric',
                minute: 'numeric'
              }).format(
                new Date(
                  SessionSubset[SelectedSessionIndex]?.session_date.seconds *
                  1000
                    ? SessionSubset[SelectedSessionIndex]?.session_date
                        .seconds * 1000
                    : DashGL.Sessions[0]?.session_date.seconds * 1000
                )
              )}
            </span>
            {/* backgroundColor:"#eca" */}
            <br />
            <span style={{ color: '#268fcc', fontSize: '1.1em' }}>
              {SubSet === 'Sessions' ? (
                <b>
                  <HoverUnderline>Sessions</HoverUnderline>
                </b>
              ) : (
                <HoverUnderline
                  onClick={() => {
                    SetSubSet('Sessions');
                  }}
                >
                  Sessions
                </HoverUnderline>
              )}{' '}
              |{' '}
              {SubSet === 'Likes' ? (
                <b>
                  <HoverUnderline>Likes</HoverUnderline>
                </b>
              ) : (
                <HoverUnderline
                  onClick={() => {
                    SetSubSet('Likes');
                  }}
                >
                  Likes
                </HoverUnderline>
              )}{' '}
              |{' '}
              {SubSet === 'Comments' ? (
                <b>
                  <HoverUnderline>Comments</HoverUnderline>
                </b>
              ) : (
                <HoverUnderline
                  onClick={() => {
                    SetSubSet('Comments');
                  }}
                >
                  Comments
                </HoverUnderline>
              )}
            </span>
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            overflowY: 'auto',
            maxHeight: 'calc(100vh * 552.35/1080)',
            height: 'min-content'
          }}
        >
          {/* backgroundColor:"#cae", */}
          {SessionSubset?.map((ele, i) => {
            //Sort and manage above based on area of interest.
            let temp = new Date(ele.session_date.seconds * 1000);
            let temp2 =
              (new Date(
                ele.page_visited[ele.page_visited.length - 1].time_visited
                  .seconds * 1000
              ) -
                new Date(ele.session_date.seconds * 1000)) /
              1000;
            let Duration = temp2;
            const Min = Math.floor(Duration / 60);
            const Seconds = Duration % 60;
            let Duration2;
            if (Min > 0) Duration2 = `${Min}min ${Seconds}sec`;
            else Duration2 = `${Seconds}sec`;
            //{temp.getMonth}/{temp.getDate}/{temp.getFullYear} | {temp.toLocaleTimeString()} //{temp.toDateString()} | {temp.toLocaleTimeString()}
            return (
              <SessionItem
                key={`k` + i}
                className={`${i === SelectedSessionIndex ? 'Active' : ''}`}
                style={{ width: '100%', flexShrink: 0, height: '72px' }}
                onClick={() => {
                  if (i !== SelectedSessionIndex) SetSSI(i);
                  else SetSSI(undefined);
                }}
              >
                {/* <p style={{textAlign:"center"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{temp.getMonth()}/{temp.getDate()}/{temp.getFullYear()} | {temp.getHours()%12}:{temp.getMinutes()} {temp.getHours()/12>=1?"PM":"AM"}</p> */}
                <p style={{ marginBlock: '0px', textAlign: 'end' }}>
                  {temp.getMonth() + 1}/{temp.getDate()}/{temp.getFullYear()} |{' '}
                  {temp.getHours() % 12}:{temp.getMinutes()}{' '}
                  {temp.getHours() / 12 >= 1 ? 'PM' : 'AM'}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </p>
                <p style={{ marginBlock: '0px' }}>
                  Opend: {Dash.selectedPortfolioName}
                </p>
                <p style={{ marginBlock: '0px' }}>Duration: {Duration2}</p>
              </SessionItem>
            );
          })}
        </div>
        {/* backgroundColor:"#cea", */}
        {/* <div ref={ref} style={{ filter:SubSet !== "Comments"?"drop-shadow(3px 3px 4px #808080)":"none", boxShadow:SubSet === "Comments"?'5px 5px 5px 5px rgba(0,0,0,0.4)':'none', maxHeight:"calc(100vh * 552.35/1080)", maxWidth:'100%', overflowY:"hidden"}} > */}
        <div
          ref={ref}
          style={{
            boxShadow: '5px 5px 5px 5px rgba(0,0,0,0.4)',
            maxHeight: 'calc(100vh * 552.35/1080)',
            maxWidth: '100%',
            overflowY: 'hidden',
            position: 'relative'
          }}
        >
          {/* MAIN AREA TO SWAP AROUND */}
          {SubSet === 'Comments' ? (
            <Notes
              Sessions={SessionSubset}
              selectedNotesSessionObj={SessionSubset[SelectedSessionIndex]}
            ></Notes>
          ) : null}
          {SubSet === 'Sessions' ? (
            <Graph
              width={ref.current?.offsetWidth}
              height={ref.current?.offsetheight}
              sessions={SessionSubset}
              SingleSession={
                SelectedSessionIndex !== undefined
                  ? SessionSubset[SelectedSessionIndex]
                  : undefined
              }
            ></Graph>
          ) : null}
          {SubSet === 'Likes' ? (
            <LT
              Sessions={SessionSubset}
              Session={SessionSubset[SelectedSessionIndex]}
            ></LT>
          ) : null}
          {Core}
        </div>
      </BaseGrid2>
    </BaseGrid1>
  );
};
export default CoreItem;
