import React from 'react';
import styled from 'styled-components';
import BlockContent from '@sanity/block-content-to-react';
import { urlFor } from '../../../utils/sanityHelper';
import CustomerInfoForm from '../../../containers/CustomerInfoForm';

const StyledEngageCustomerBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 900px;
  width: 1421px;
  height: 725px;
  border-radius: 50px;
  background-color: transparent;

  @media (max-width: 1367px) {
    min-width: 0;
  }

  @media (max-width: 450px) and (orientation: portrait) {
    flex-direction: column;
    padding-top: 0px;
  }
`;

const StyledEngageCustomerBoxLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 50%;
  padding: 25px;

  img {
    width: calc(100% - 48px);
    height: auto;
  }

  @media (max-width: 450px) and (orientation: portrait) {
    padding: 0px;
  }
`;

const StyledEngageRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 50%;
  border-radius: 24px;
  background-color: white;
  height: ${(props) => (props.hasMessage ? '100%' : '66%')};

  @media (max-width: 1367px) {
    margin-right: 50px;
    height: ${(props) => (props.hasMessage ? '100%' : '75%')};
  }

  @media (max-width: 450px) and (orientation: portrait) {
    justify-content: flex-start;
    margin-right: 0px;
    width: 90%;
    height: 500px;

    h1 {
      text-align: center;
      font-size: 24px;
    }
  }
`;

const StyledEngageRightMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 30px;
  width: 400px;

  @media (max-width: 1367px) {
    bottom: 100px;
  }

  @media (max-width: 450px) and (orientation: portrait) {
    justify-content: center;
    bottom: 100px;
  }
`;

// TODO make reusuable component of menu texts
const StyledMenuText = styled.div`
  font-size: 20px;
  color: ${(props) => (props.disabled ? 'grey' : 'black')};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};

  &:hover {
    text-decoration: ${(props) => (props.disabled ? 'none' : 'underline')};
  }

  @media (max-width: 450px) and (orientation: portrait) {
    margin-right: 10px;
    margin-left: 10px;
  }
`;

const EngageCustomerBox = ({
  setMode,
  logout,
  preparePortfolio,
  companyConfig,
  images,
  mode,
  userInfo,
  portfolioIntroImageUrl,
  RPS,
  chosenPortfolio,
  setLoginInputSelected,
  setLoginInputSelectedCount,
  loginInputSelected,
  loginInputSelectedCount,
  Size
}) => {
  ////////////onsole.log("!!!!TestIMAGE!!!!",portfolioIntroImageUrl);
  return (
    <StyledEngageCustomerBox
      style={{
        marginBottom: loginInputSelected && Size.W >= 451 ? '200px' : '0px'
      }}
    >
      <StyledEngageCustomerBoxLogo>
        {portfolioIntroImageUrl ? (
          <img src={portfolioIntroImageUrl} alt="logo" type="image" />
        ) : (
          companyConfig.logo !== undefined && (
            <img
              src={urlFor(images[companyConfig.logo.asset._ref])
                .auto('format')
                .width(600)
                .url()}
              alt="logo"
              type="image"
            />
          )
        )}
      </StyledEngageCustomerBoxLogo>
      <StyledEngageRight hasMessage={chosenPortfolio?.message?.welcome_message}>
        {chosenPortfolio?.message && (
          <BlockContent blocks={chosenPortfolio?.message?.welcome_message} />
        )}
        <CustomerInfoForm
          Seed={
            mode === 'training'
              ? { Name: userInfo[0].name, Email: userInfo[0].email }
              : null
          }
          handleSubmit={preparePortfolio}
          submitText="Start"
          setLoginInputSelected={setLoginInputSelected}
          loginInputSelected={loginInputSelected}
          loginInputSelectedCount={loginInputSelectedCount}
          setLoginInputSelectedCount={setLoginInputSelectedCount}
          Size={Size}
          engage={true}
        />
        <StyledEngageRightMenu>
          <StyledMenuText
            onClick={() => {
              setMode('');
              RPS();
            }}
          >
            Back
          </StyledMenuText>
          <StyledMenuText onClick={logout}>Logout</StyledMenuText>
        </StyledEngageRightMenu>
      </StyledEngageRight>
    </StyledEngageCustomerBox>
  );
};

export default EngageCustomerBox;
