import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Progress } from 'react-sweet-progress';
import 'react-sweet-progress/lib/style.css';
import { useFirestore } from 'react-redux-firebase';
import { createBarData } from '../../../utils/chartHelper';
import DateSelect from '../DateSelect/index.js';
import SessionSelect from '../SessionSelect/index.js';
import DateSelectEfficiency from '../DateSelectEfficiency/index.js';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';
import * as Scale from '../../../utils/Scalars';

const StretchLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 2 / span 1;

  @media (max-width: 1380px) {
    grid-column: 1 / span 3;
  }
`;

const CompletionCenterContainer = styled.div`
  width: 100%;
  // height: 100%;
  height: -webkit-fill-available;
  max-height: 100%;
  display: grid;
  grid-template-rows: 80px 1fr;

  .top-center-container {
    grid-row: 1 / span 1;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const CompletionMain = styled.div`
  grid-row: 2 / span 1;
  padding: 0px 40px 40px 40px;
  display: grid;
  grid-template-rows: 300px 300px;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 35px;

  .page-container {
    grid-row: 1 / span 1;
    grid-column: 2 / span 2;
    background-color: #3b3b3b;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 3fr 0.05fr 4fr;

    .page-count {
      grid-column: 1 / span 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-weight: 600;
      color: white;

      .holder {
        height: 175px;
        width: 175px;
      }

      @media (max-width: 1650px) {
        .holder {
          height: 150px;
          width: 150px;
        }
      }
    }

    .aggregate {
      grid-column: 3 / span 1;
      display: grid;
      grid-template-rows: 1fr 3fr;
      grid-template-columns: 1fr 1fr;
      padding: 10px 30px 30px 30px;
      position: relative;

      .sort-box {
        position: absolute;
        top: 0;
        right: 0;
        height: 35px;
        width: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .title {
        grid-row: 1 / span 1;
        grid-column: 1 / span 1;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          margin-block-start: 0px;
          margin-block-end: 0px;
          font-size: 1.3em;
          font-weight: 600;
          color: white;
          text-shadow: 1px 1px 1px #3b3b3b;
        }
      }

      .count {
        grid-row: 1 / span 1;
        grid-column: 2 / span 1;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          margin-block-start: 0px;
          margin-block-end: 0px;
          font-size: 1.3em;
          font-weight: 600;
          color: white;
          text-shadow: 1px 1px 1px #3b3b3b;
        }
      }

      .mapping {
        grid-row: 2 / span 1;
        grid-column: 1 / span 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 200px;
        min-height: 200px;
        background-color: white;
        border-radius: 4px;

        @media (max-width: 1380px) {
          max-height: 150px;
          min-height: 150px;
        }

        .no-pages-missed-text {
          font-size: 1.2em;
          font-weight: 600;
        }

        .missed-page {
          height: 50px;
          width: 100%;
          display: grid;
          grid-template-columns: 1fr 1fr;

          div {
            display: flex;
            align-items: center;
            padding-left: 8%;
            overflow: hidden;

            p {
              margin-block-start: 0px;
              margin-block-end: 0px;
              font-size: 0.9em;
              font-weight: 600;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }

  .adherence-container {
    grid-row: 1 / span 1;
    grid-column: 1 / span 1;
    background-color: #3b3b3b;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    border-radius: 10px;

    .symbol {
      color: white;
    }
  }

  .company-container {
    grid-row: 2 / span 1;
    grid-column: 2 / span 2;
    background-color: #3b3b3b;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 3fr 0.05fr 4fr;

    .page-count {
      grid-column: 1 / span 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-weight: 600;
      color: white;

      .holder {
        height: 175px;
        width: 175px;
      }

      @media (max-width: 1650px) {
        .holder {
          height: 150px;
          width: 150px;
        }
      }
    }

    .aggregate {
      grid-column: 3 / span 1;
      display: grid;
      grid-template-rows: 1fr 3fr;
      grid-template-columns: 1fr 1fr;
      padding: 10px 30px 30px 30px;
      position: relative;

      .sort-box {
        position: absolute;
        top: 0;
        right: 0;
        height: 35px;
        width: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .title {
        grid-row: 1 / span 1;
        grid-column: 1 / span 1;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          margin-block-start: 0px;
          margin-block-end: 0px;
          font-size: 1.3em;
          font-weight: 600;
          color: white;
          text-shadow: 1px 1px 1px #3b3b3b;
        }
      }

      .count {
        grid-row: 1 / span 1;
        grid-column: 2 / span 1;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          margin-block-start: 0px;
          margin-block-end: 0px;
          font-size: 1.3em;
          font-weight: 600;
          color: white;
          text-shadow: 1px 1px 1px #3b3b3b;
        }
      }

      .mapping {
        grid-row: 2 / span 1;
        grid-column: 1 / span 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 200px;
        min-height: 200px;
        background-color: white;
        border-radius: 4px;

        @media (max-width: 1380px) {
          max-height: 150px;
          min-height: 150px;
        }

        .no-pages-missed-text {
          font-size: 1.2em;
          font-weight: 600;
        }

        .missed-page {
          height: 50px;
          width: 100%;
          display: grid;
          grid-template-columns: 1fr 1fr;

          div {
            display: flex;
            align-items: center;
            padding-left: 8%;
            overflow: hidden;

            p {
              margin-block-start: 0px;
              margin-block-end: 0px;
              font-size: 0.9em;
              font-weight: 600;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }

  .adherence-company {
    grid-row: 2 / span 1;
    grid-column: 1 / span 1;
    background-color: #3b3b3b;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    border-radius: 10px;

    .symbol {
      color: white;
    }
  }
`;

const CompletionRightContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 15%;

  .date-select {
    width: 80%;
    height: 30px;
    border-radius: 40px;
    border: 1px solid black;
    display: grid;
    grid-template-columns: 1fr 1fr;

    div {
      font-size: 1em;
      font-size: 500;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const Completion = ({
  Dash,
  SetSessionMasterCollection,
  portfolios,
  setCompletionAdherence,
  completionAdherence,
  aggregateSelected,
  selectedPortfolioId,
  defaultVisit,
  efficiencyAggregateSelected,
  selectedUser,
  Size
}) => {
  const [viewedPerPageData, setViewedPerPageData] = useState([]);
  const [allPagesList, setAllPagesList] = useState([]);

  // user data
  const [pagesMissed, setPagesMissed] = useState([]);
  const [aggregatePagesMissed, setAggregatePagesMissed] = useState([]);
  const [pagesMissedCount, setPagesMissedCount] = useState(0);
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const [noPagesText, setNoPagesText] = useState('');
  const [sortOrder, setSortOrder] = useState('HighLow');

  // company data
  const [companyPagesMissed, setCompanyPagesMissed] = useState([]);
  const [aggregateCompanyPagesMissed, setAggregateCompanyPagesMissed] =
    useState([]);
  const [companyPagesMissedCount, setCompanyPagesMissedCount] = useState(0);
  const [totalCompanyPagesCount, setCompanyTotalPagesCount] = useState(0);
  const [noCompanyPagesText, setNoCompanyPagesText] = useState('');
  const [companyAdherence, setCompanyAdherence] = useState(0);
  const [companySortOrder, setCompanySortOrder] = useState('HighLow');

  const firestore = useFirestore();

  useEffect(() => {
    ////////onsole.log("!!!DASH SESSION MASTER!!!", Dash.SessionMasterCollection);
    if (
      Dash.SessionMasterCollection === null ||
      Dash.SessionMasterCollection === undefined
    ) {
      return;
    }

    if (Dash.SessionMasterCollection.length === 0) {
      SetSessionMasterCollection(selectedUser, firestore);
    }
  }, [
    Dash.SessionMasterCollection,
    SetSessionMasterCollection,
    firestore,
    selectedUser
  ]);

  // will populate the list of total available pages
  useEffect(() => {
    if (
      Dash.SessionMasterCollection === null ||
      Dash.SessionMasterCollection === undefined ||
      selectedPortfolioId === 0
    ) {
      return;
    }

    if (Dash.SessionMasterCollection.length !== 0) {
      let chosenPortfolio = portfolios.find(
        (portfolio) => portfolio._id === selectedPortfolioId
      );
      if (chosenPortfolio) setAllPagesList(chosenPortfolio.pages);
    }
  }, [Dash.SessionMasterCollection, portfolios, selectedPortfolioId]);

  // sets the user page data for an initial aggregateSelected of 1m
  useEffect(() => {
    if (
      Dash.SessionMasterCollection === null ||
      Dash.SessionMasterCollection === undefined
    ) {
      return;
    }

    if (
      allPagesList !== undefined &&
      allPagesList !== null &&
      allPagesList.length
    ) {
      let today = new Date();
      let newDate = new Date();
      newDate.setDate(today.getDate() - 30);

      let idFilteredPorts = Dash.SessionMasterCollection.filter(
        (port) => port.portfolio_id === selectedPortfolioId
      );
      let userFilteredPorts = idFilteredPorts.filter(
        (port) => port.user_id === selectedUser[0].id
      );

      let dateFilteredPorts = userFilteredPorts.filter((port) => {
        return (
          port.session_date.seconds * 1000 > newDate.getTime() &&
          port.session_date.seconds * 1000 < today.getTime()
        );
      });

      if (!dateFilteredPorts.length) {
        setNoPagesText('No visits for this time period.');
        setPagesMissedCount(0);
        setTotalPagesCount(0);
        setCompletionAdherence(0);
        return;
      } else {
        setNoPagesText('');
      }

      //////onsole.log("DATE FILTERED VISITS FOR CHOSEN USER: ", dateFilteredPorts);
      let seenPages = [];
      let missedPagesTotal = [];
      for (let i = 0; i < dateFilteredPorts.length; i++) {
        let obj = createBarData(dateFilteredPorts[i].page_visited);
        for (let page of allPagesList) {
          let search = obj.viewedDatasTrimmed.find(
            (clicked) => clicked.name === page.title
          );
          if (search === undefined && seenPages.includes(page.title)) {
            let existingPage = missedPagesTotal.find(
              (foundPage) => foundPage.title === page.title
            );
            existingPage.count = existingPage.count + 1;
          } else if (search === undefined && !seenPages.includes(page.title)) {
            seenPages.push(page.title);
            let pageObj = { title: page.title, count: 1 };
            missedPagesTotal.push(pageObj);
          }
        }
      }
      //////onsole.log("FINAL MISSED PAGES LIST: ", missedPagesTotal);

      if (!missedPagesTotal.length) {
        setNoPagesText('No missed pages in this period - well done!');
      } else {
        setNoPagesText('');
      }

      setPagesMissed(missedPagesTotal);
      let count = 0;
      for (let page of missedPagesTotal) {
        count += page.count;
      }
      setPagesMissedCount(count);
      let totalPossiblePagesAggregate =
        allPagesList.length * dateFilteredPorts.length;
      setTotalPagesCount(totalPossiblePagesAggregate);
      //////onsole.log(`PAGES MISSED: ${count} out of ${totalPossiblePagesAggregate} total.`);
      let percentageMissed = (count / totalPossiblePagesAggregate) * 100;
      let adherence = 100 - percentageMissed;
      //////onsole.log(`ADHERENCE PERCENTAGE: ${adherence}%`);
      setCompletionAdherence(adherence);
    }
  }, [
    Dash.SessionMasterCollection,
    allPagesList,
    setCompletionAdherence,
    selectedUser,
    selectedPortfolioId
  ]);

  // sets the company page data for an initial aggregateSelected of 1m
  useEffect(() => {
    if (
      Dash.SessionMasterCollection === null ||
      Dash.SessionMasterCollection === undefined
    ) {
      return;
    }

    if (
      allPagesList !== undefined &&
      allPagesList !== null &&
      allPagesList.length
    ) {
      let today = new Date();
      let newDate = new Date();
      newDate.setDate(today.getDate() - 30);

      let idFilteredPorts = Dash.SessionMasterCollection.filter(
        (port) => port.portfolio_id === selectedPortfolioId
      );

      let dateFilteredPorts = idFilteredPorts.filter((port) => {
        return (
          port.session_date.seconds * 1000 > newDate.getTime() &&
          port.session_date.seconds * 1000 < today.getTime()
        );
      });

      if (!dateFilteredPorts.length) {
        setNoCompanyPagesText('No visits for this time period.');
        setCompanyPagesMissedCount(0);
        setCompanyTotalPagesCount(0);
        setCompanyAdherence(0);
        return;
      } else {
        setNoCompanyPagesText('');
      }

      //////onsole.log("DATE FILTERED VISITS FOR CHOSEN COMPANY: ", dateFilteredPorts);
      let seenPages = [];
      let missedPagesTotal = [];
      for (let i = 0; i < dateFilteredPorts.length; i++) {
        let obj = createBarData(dateFilteredPorts[i].page_visited);
        for (let page of allPagesList) {
          let search = obj.viewedDatasTrimmed.find(
            (clicked) => clicked.name === page.title
          );
          if (search === undefined && seenPages.includes(page.title)) {
            let existingPage = missedPagesTotal.find(
              (foundPage) => foundPage.title === page.title
            );
            existingPage.count = existingPage.count + 1;
          } else if (search === undefined && !seenPages.includes(page.title)) {
            seenPages.push(page.title);
            let pageObj = { title: page.title, count: 1 };
            missedPagesTotal.push(pageObj);
          }
        }
      }
      //////onsole.log("FINAL MISSED PAGES LIST: ", missedPagesTotal);

      if (!missedPagesTotal.length) {
        setNoCompanyPagesText('No missed pages in this period - well done!');
      } else {
        setNoCompanyPagesText('');
      }

      setCompanyPagesMissed(missedPagesTotal);
      let count = 0;
      for (let page of missedPagesTotal) {
        count += page.count;
      }
      setCompanyPagesMissedCount(count);
      let totalPossiblePagesAggregate =
        allPagesList.length * dateFilteredPorts.length;
      setCompanyTotalPagesCount(totalPossiblePagesAggregate);
      //////onsole.log(`PAGES MISSED: ${count} out of ${totalPossiblePagesAggregate} total.`);
      let percentageMissed = (count / totalPossiblePagesAggregate) * 100;
      let adherence = 100 - percentageMissed;
      //////onsole.log(`ADHERENCE PERCENTAGE: ${adherence}%`);
      setCompanyAdherence(adherence);
    }
  }, [Dash.SessionMasterCollection, allPagesList, selectedPortfolioId]);

  // part of useSelect for change in session - sets viewedPerPageData
  useEffect(() => {
    if (
      Dash.SessionMasterCollection === null ||
      Dash.SessionMasterCollection === undefined ||
      defaultVisit === undefined ||
      Dash.SessionMasterCollection.length === 0
    ) {
      return;
    }

    if (
      allPagesList !== undefined &&
      allPagesList !== null &&
      allPagesList.length
    ) {
      let obj = createBarData(defaultVisit.page_visited);
      setViewedPerPageData(obj.viewedDatasTrimmed);
    }
  }, [
    Dash.SessionMasterCollection,
    setViewedPerPageData,
    allPagesList,
    defaultVisit
  ]);

  // sets the page data for a change in session
  useEffect(() => {
    if (
      Dash.SessionMasterCollection === null ||
      Dash.SessionMasterCollection === undefined ||
      defaultVisit === undefined ||
      Dash.SessionMasterCollection.length === 0
    ) {
      return;
    }

    if (
      allPagesList !== undefined &&
      allPagesList !== null &&
      allPagesList.length
    ) {
      let missedPages = [];
      //////onsole.log("ALL PAGES - DEFAULT VISIT", allPagesList);
      //////onsole.log("CLICKED PAGES - DEFAULT VISIT", viewedPerPageData);
      let count = 0;
      for (let i = 0; i < allPagesList.length; i++) {
        let search = viewedPerPageData.find(
          (clicked) => clicked.name.trim() === allPagesList[i].title.trim()
        );
        if (search === undefined) {
          let pageObj = { title: allPagesList[i].title, count: 1 };
          missedPages.push(pageObj);
          count++;
        }
      }

      if (!missedPages.length) {
        setNoPagesText('No missed pages this session - well done!');
      } else {
        setNoPagesText('');
      }

      setAggregatePagesMissed(missedPages);
      setPagesMissedCount(count);
      //////onsole.log(`PAGES MISSED: ${count} out of ${allPagesList.length} total.`);
      setTotalPagesCount(allPagesList.length);
      let percentageMissed = (count / allPagesList.length) * 100;
      let adherence = 100 - percentageMissed;
      //////onsole.log(`VISIT ADHERENCE PERCENTAGE: ${adherence}%`);
      setCompletionAdherence(adherence);
    }
  }, [
    Dash.SessionMasterCollection,
    defaultVisit,
    allPagesList,
    setCompletionAdherence,
    viewedPerPageData
  ]);

  //sets the user page data whenever the User DateSelect is changed
  useEffect(() => {
    if (
      Dash.SessionMasterCollection === null ||
      Dash.SessionMasterCollection === undefined ||
      selectedPortfolioId === 0 ||
      aggregateSelected === 0
    ) {
      return;
    }

    let today = new Date();
    let newDate = new Date();
    ////////onsole.log("SELECTED TIME FRAME: ", aggregateSelected);
    if (aggregateSelected === '1w') {
      newDate.setDate(today.getDate() - 7);
      ////////onsole.log("ONE WEEK AGO: ", newDate);
    } else if (aggregateSelected === '2w') {
      newDate.setDate(today.getDate() - 14);
      ////////onsole.log("TWO WEEKS AGO: ", newDate);
    } else if (aggregateSelected === '1m') {
      newDate.setDate(today.getDate() - 30);
      ////////onsole.log("30 DAYS AGO: ", newDate);
    } else if (aggregateSelected === '3m') {
      newDate.setDate(today.getDate() - 90);
      ////////onsole.log("90 DAYS AGO: ", newDate);
    } else if (aggregateSelected === '6m') {
      newDate.setDate(today.getDate() - 180);
      ////////onsole.log("180 DAYS AGO: ", newDate);
    } else if (aggregateSelected === '1d') {
      newDate = new Date(
        new Date(
          new Date(new Date(new Date(new Date())).setSeconds(0)).setMinutes(0)
        ).setHours(0)
      );
      ////////onsole.log("TODAY: ", newDate);
    } else {
      return;
    }

    let idFilteredPorts = Dash.SessionMasterCollection.filter(
      (port) => port.portfolio_id === selectedPortfolioId
    );
    let userFilteredPorts = idFilteredPorts.filter(
      (port) => port.user_id === selectedUser[0].id
    );

    let dateFilteredPorts = userFilteredPorts.filter((port) => {
      return (
        port.session_date.seconds * 1000 > newDate.getTime() &&
        port.session_date.seconds * 1000 < today.getTime()
      );
    });

    if (!dateFilteredPorts.length) {
      setNoPagesText('No visits for this time period.');
      setPagesMissedCount(0);
      setCompletionAdherence(0);
      setTotalPagesCount(0);
      return;
    } else {
      setNoPagesText('');
    }

    //////onsole.log("DATE FILTERED VISITS FOR CHOSEN USER: ", dateFilteredPorts);
    let seenPages = [];
    let missedPagesTotal = [];
    for (let i = 0; i < dateFilteredPorts.length; i++) {
      let obj = createBarData(dateFilteredPorts[i].page_visited);
      for (let page of allPagesList) {
        let search = obj.viewedDatasTrimmed.find(
          (clicked) => clicked.name === page.title
        );
        if (search === undefined && seenPages.includes(page.title)) {
          let existingPage = missedPagesTotal.find(
            (foundPage) => foundPage.title === page.title
          );
          existingPage.count = existingPage.count + 1;
        } else if (search === undefined && !seenPages.includes(page.title)) {
          seenPages.push(page.title);
          let pageObj = { title: page.title, count: 1 };
          missedPagesTotal.push(pageObj);
        }
      }
    }
    //////onsole.log("FINAL MISSED PAGES LIST: ", missedPagesTotal);

    if (!missedPagesTotal.length) {
      setNoPagesText('No missed pages in this period - well done!');
    } else {
      setNoPagesText('');
    }

    setAggregatePagesMissed(missedPagesTotal);
    let count = 0;
    for (let page of missedPagesTotal) {
      count += page.count;
    }
    setPagesMissedCount(count);
    let totalPossiblePagesAggregate =
      allPagesList.length * dateFilteredPorts.length;
    //////onsole.log(`PAGES MISSED: ${count} out of ${totalPossiblePagesAggregate} total.`);
    setTotalPagesCount(totalPossiblePagesAggregate);
    let percentageMissed = (count / totalPossiblePagesAggregate) * 100;
    let adherence = 100 - percentageMissed;
    ////////onsole.log(`ADHERENCE PERCENTAGE: ${adherence}%`);
    setCompletionAdherence(adherence);
  }, [
    Dash.SessionMasterCollection,
    portfolios,
    allPagesList,
    setCompletionAdherence,
    aggregateSelected,
    selectedUser,
    selectedPortfolioId
  ]);

  //sets the user page data whenever the Company DateSelect is changed
  useEffect(() => {
    if (
      Dash.SessionMasterCollection === null ||
      Dash.SessionMasterCollection === undefined ||
      selectedPortfolioId === 0 ||
      efficiencyAggregateSelected === 0
    ) {
      return;
    }

    let today = new Date();
    let newDate = new Date();
    if (efficiencyAggregateSelected === '1w') {
      newDate.setDate(today.getDate() - 7);
    } else if (efficiencyAggregateSelected === '2w') {
      newDate.setDate(today.getDate() - 14);
    } else if (efficiencyAggregateSelected === '1m') {
      newDate.setDate(today.getDate() - 30);
    } else if (efficiencyAggregateSelected === '3m') {
      newDate.setDate(today.getDate() - 90);
    } else if (efficiencyAggregateSelected === '6m') {
      newDate.setDate(today.getDate() - 180);
    } else if (efficiencyAggregateSelected === '1d') {
      newDate = new Date(
        new Date(
          new Date(new Date(new Date(new Date())).setSeconds(0)).setMinutes(0)
        ).setHours(0)
      );
    } else {
      return;
    }

    let idFilteredPorts = Dash.SessionMasterCollection.filter(
      (port) => port.portfolio_id === selectedPortfolioId
    );

    let dateFilteredPorts = idFilteredPorts.filter((port) => {
      return (
        port.session_date.seconds * 1000 > newDate.getTime() &&
        port.session_date.seconds * 1000 < today.getTime()
      );
    });

    if (!dateFilteredPorts.length) {
      setNoCompanyPagesText('No visits for this time period.');
      setCompanyPagesMissedCount(0);
      setCompanyAdherence(0);
      setCompanyTotalPagesCount(0);
      return;
    } else {
      setNoCompanyPagesText('');
    }

    //////onsole.log("DATE FILTERED VISITS FOR CHOSEN USER: ", dateFilteredPorts);
    let seenPages = [];
    let missedPagesTotal = [];
    for (let i = 0; i < dateFilteredPorts.length; i++) {
      let obj = createBarData(dateFilteredPorts[i].page_visited);
      for (let page of allPagesList) {
        let search = obj.viewedDatasTrimmed.find(
          (clicked) => clicked.name === page.title
        );
        if (search === undefined && seenPages.includes(page.title)) {
          let existingPage = missedPagesTotal.find(
            (foundPage) => foundPage.title === page.title
          );
          existingPage.count = existingPage.count + 1;
        } else if (search === undefined && !seenPages.includes(page.title)) {
          seenPages.push(page.title);
          let pageObj = { title: page.title, count: 1 };
          missedPagesTotal.push(pageObj);
        }
      }
    }
    //////onsole.log("FINAL MISSED PAGES LIST: ", missedPagesTotal);

    if (!missedPagesTotal.length) {
      setNoCompanyPagesText('No missed pages in this period - well done!');
    } else {
      setNoCompanyPagesText('');
    }

    setAggregateCompanyPagesMissed(missedPagesTotal);
    let count = 0;
    for (let page of missedPagesTotal) {
      count += page.count;
    }
    setCompanyPagesMissedCount(count);
    let totalPossiblePagesAggregate =
      allPagesList.length * dateFilteredPorts.length;
    //////onsole.log(`PAGES MISSED: ${count} out of ${totalPossiblePagesAggregate} total.`);
    setCompanyTotalPagesCount(totalPossiblePagesAggregate);
    let percentageMissed = (count / totalPossiblePagesAggregate) * 100;
    let adherence = 100 - percentageMissed;
    ////////onsole.log(`ADHERENCE PERCENTAGE: ${adherence}%`);
    setCompanyAdherence(adherence);
  }, [
    Dash.SessionMasterCollection,
    portfolios,
    allPagesList,
    efficiencyAggregateSelected,
    selectedPortfolioId
  ]);

  const sortHighToLow = (a, b) => {
    return b.count - a.count || a.title - b.title;
  };

  const sortLowToHigh = (a, b) => {
    return a.count - b.count || a.title - b.title;
  };

  // user missed page mapping
  let pagesToMap = aggregatePagesMissed.length
    ? aggregatePagesMissed
    : pagesMissed;
  let missedPageMapping = pagesToMap
    .sort((a, b) => {
      return sortOrder === 'HighLow'
        ? sortHighToLow(a, b)
        : sortLowToHigh(a, b);
    })
    .map((page, i) => {
      return (
        <div className="missed-page" key={i}>
          <div
            style={{
              justifyContent: 'flex-start',
              borderRight: '1px dashed black'
            }}
          >
            <p style={{ fontSize: `${Scale.Height(Size, 16)}px` }}>
              {page.title}
            </p>
          </div>
          <div style={{ justifyContent: 'center' }}>
            <p style={{ fontSize: `${Scale.Height(Size, 16)}px` }}>
              {page.count}
            </p>
          </div>
        </div>
      );
    });
  // will display a message indicating lack of data if an aggregate is selected that contains no sessions within that range - user
  let missedPageDisplay;
  if (noPagesText !== '') {
    missedPageDisplay = (
      <p
        style={{ fontSize: `${Scale.Height(Size, 20)}px` }}
        className="no-pages-missed-text"
      >
        {noPagesText}
      </p>
    );
  } else {
    missedPageDisplay = missedPageMapping;
  }

  // company missed page mapping
  let companyPagesToMap = aggregateCompanyPagesMissed.length
    ? aggregateCompanyPagesMissed
    : companyPagesMissed;
  let companyMissedPageMapping = companyPagesToMap
    .sort((a, b) => {
      return companySortOrder === 'HighLow'
        ? sortHighToLow(a, b)
        : sortLowToHigh(a, b);
    })
    .map((page, i) => {
      return (
        <div className="missed-page" key={i}>
          <div
            style={{
              justifyContent: 'flex-start',
              borderRight: '1px dashed black'
            }}
          >
            <p style={{ fontSize: `${Scale.Height(Size, 16)}px` }}>
              {page.title}
            </p>
          </div>
          <div style={{ justifyContent: 'center' }}>
            <p style={{ fontSize: `${Scale.Height(Size, 16)}px` }}>
              {page.count}
            </p>
          </div>
        </div>
      );
    });
  // will display a message indicating lack of data if an aggregate is selected that contains no sessions within that range - company
  let companyMissedPageDisplay;
  if (noCompanyPagesText !== '') {
    companyMissedPageDisplay = (
      <p
        style={{ fontSize: `${Scale.Height(Size, 20)}px` }}
        className="no-pages-missed-text"
      >
        {noCompanyPagesText}
      </p>
    );
  } else {
    companyMissedPageDisplay = companyMissedPageMapping;
  }

  // formatting
  let symbolValue = isNaN(completionAdherence)
    ? '0%'
    : `${Math.round(completionAdherence)}%`;
  let percentValue = isNaN(completionAdherence)
    ? 0
    : Math.round(completionAdherence);
  let symbolValueCompany = isNaN(companyAdherence)
    ? '0%'
    : `${Math.round(companyAdherence)}%`;
  let percentValueCompany = isNaN(companyAdherence)
    ? 0
    : Math.round(companyAdherence);

  return (
    <>
      <StretchLeft className="dashboard-body-main">
        <CompletionCenterContainer
          style={{ gridTemplateRows: `${Scale.Height(Size, 90)}px auto` }}
        >
          <div className="top-center-container">
            <div
              style={{
                gridColumn: '1 / span 1',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <SessionSelect positioning={285} />
            </div>
            <div
              style={{
                gridColumn: '2 / span 1',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <DateSelect />
            </div>
            <div
              style={{
                gridColumn: '3 / span 1',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <DateSelectEfficiency />
            </div>
          </div>
          <CompletionMain
            style={{
              gridTemplateRows: `${Scale.Height(Size, 330)}px ${Scale.Height(
                Size,
                330
              )}px`
            }}
          >
            <div className="page-container">
              <div className="page-count">
                <div>
                  <p
                    style={{
                      marginBlockEnd: '0px',
                      marginBlockStart: '0px',
                      fontSize: `${Scale.Height(Size, 24)}px`,
                      letterSpacing: '0.03em',
                      fontWeight: 600,
                      color: 'white',
                      textShadow: '1px 1px 1px #3b3b3b'
                    }}
                  >
                    Pages Skipped
                  </p>
                </div>
                <div
                  className="holder"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor: 'rgb(5, 106, 175)',
                    borderRadius: '100%',
                    marginTop: `${Scale.Height(Size, 24)}px`,
                    height: `${Scale.Height(Size, 200)}px`,
                    width: `${Scale.Height(Size, 200)}px`
                  }}
                >
                  <p
                    style={{
                      marginBlockEnd: '0px',
                      marginBlockStart: '0px',
                      fontSize: `${Scale.Height(Size, 40)}px`
                    }}
                  >
                    {pagesMissedCount}
                  </p>
                  <p
                    style={{
                      marginBlockEnd: '0px',
                      marginBlockStart: '0px',
                      fontSize: `${Scale.Height(Size, 16)}px`
                    }}
                  >
                    of
                  </p>
                  <p
                    style={{
                      marginBlockEnd: '0px',
                      marginBlockStart: '0px',
                      fontSize: `${Scale.Height(Size, 40)}px`
                    }}
                  >
                    {totalPagesCount}
                  </p>
                </div>
              </div>
              <div
                style={{ gridColumn: '2 / span 1', backgroundColor: 'white' }}
              ></div>
              <div className="aggregate">
                <div className="sort-box">
                  {sortOrder === 'HighLow' ? (
                    <ArrowUpwardRoundedIcon
                      style={{
                        color: 'white',
                        fontSize: '1.5em',
                        cursor: 'pointer'
                      }}
                      onClick={() => setSortOrder('LowHigh')}
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      style={{
                        color: 'white',
                        fontSize: '1.5em',
                        cursor: 'pointer'
                      }}
                      onClick={() => setSortOrder('HighLow')}
                    />
                  )}
                </div>
                <div
                  className="title"
                  style={{ fontSize: `${Scale.Height(Size, 18)}px` }}
                >
                  <p>Page Title</p>
                </div>
                <div
                  className="count"
                  style={{ fontSize: `${Scale.Height(Size, 18)}px` }}
                >
                  <p>Skip Count</p>
                </div>
                <div
                  className="mapping"
                  style={{
                    justifyContent: noPagesText !== '' ? 'center' : 'flex-start'
                  }}
                >
                  {missedPageDisplay}
                </div>
              </div>
            </div>
            <div className="adherence-container">
              <p
                style={{
                  marginBlockEnd: '0px',
                  marginBlockStart: '0px',
                  fontSize: `${Scale.Height(Size, 24)}px`,
                  letterSpacing: '0.03em',
                  fontWeight: 600
                }}
              >
                User Adherence
              </p>
              <Progress
                type="circle"
                percent={percentValue}
                status="success"
                width={Scale.Height(Size, 190)}
                style={{ marginTop: '16px' }}
                symbolClassName={'symbol'}
                theme={{
                  success: {
                    symbol: symbolValue,
                    trailColor: 'white',
                    color: 'rgb(5, 106, 175)'
                  }
                }}
              />
            </div>
            <div className="company-container">
              <div className="page-count">
                <div>
                  <p
                    style={{
                      textAlign: 'center',
                      marginBlockEnd: '0px',
                      marginBlockStart: '0px',
                      fontSize: `${Scale.Height(Size, 24)}px`,
                      letterSpacing: '0.03em',
                      fontWeight: 600,
                      color: 'white',
                      textShadow: '1px 1px 1px #3b3b3b'
                    }}
                  >
                    Pages Skipped
                  </p>
                </div>
                <div
                  className="holder"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor: 'rgb(247, 152, 61)',
                    borderRadius: '100%',
                    marginTop: `${Scale.Height(Size, 24)}px`,
                    height: `${Scale.Height(Size, 200)}px`,
                    width: `${Scale.Height(Size, 200)}px`
                  }}
                >
                  <p
                    style={{
                      marginBlockEnd: '0px',
                      marginBlockStart: '0px',
                      fontSize: `${Scale.Height(Size, 40)}px`
                    }}
                  >
                    {companyPagesMissedCount}
                  </p>
                  <p
                    style={{
                      marginBlockEnd: '0px',
                      marginBlockStart: '0px',
                      fontSize: `${Scale.Height(Size, 16)}px`
                    }}
                  >
                    of
                  </p>
                  <p
                    style={{
                      marginBlockEnd: '0px',
                      marginBlockStart: '0px',
                      fontSize: `${Scale.Height(Size, 40)}px`
                    }}
                  >
                    {totalCompanyPagesCount}
                  </p>
                </div>
              </div>
              <div
                style={{ gridColumn: '2 / span 1', backgroundColor: 'white' }}
              ></div>
              <div className="aggregate">
                <div className="sort-box">
                  {companySortOrder === 'HighLow' ? (
                    <ArrowUpwardRoundedIcon
                      style={{
                        color: 'white',
                        fontSize: '1.5em',
                        cursor: 'pointer'
                      }}
                      onClick={() => setCompanySortOrder('LowHigh')}
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      style={{
                        color: 'white',
                        fontSize: '1.5em',
                        cursor: 'pointer'
                      }}
                      onClick={() => setCompanySortOrder('HighLow')}
                    />
                  )}
                </div>
                <div
                  className="title"
                  style={{ fontSize: `${Scale.Height(Size, 18)}px` }}
                >
                  <p>Page Title</p>
                </div>
                <div
                  className="count"
                  style={{ fontSize: `${Scale.Height(Size, 18)}px` }}
                >
                  <p>Skip Count</p>
                </div>
                <div
                  className="mapping"
                  style={{
                    justifyContent:
                      noCompanyPagesText !== '' ? 'center' : 'flex-start'
                  }}
                >
                  {companyMissedPageDisplay}
                </div>
              </div>
            </div>
            <div className="adherence-company">
              <p
                style={{
                  marginBlockEnd: '0px',
                  marginBlockStart: '0px',
                  fontSize: `${Scale.Height(Size, 24)}px`,
                  letterSpacing: '0.03em',
                  fontWeight: 600,
                  textAlign: 'center'
                }}
              >
                Company Adherence
              </p>
              <Progress
                type="circle"
                percent={percentValueCompany}
                status="success"
                width={Scale.Height(Size, 190)}
                style={{ marginTop: '16px' }}
                symbolClassName={'symbol'}
                theme={{
                  success: {
                    symbol: symbolValueCompany,
                    trailColor: 'white',
                    color: 'rgb(247, 152, 61)'
                  }
                }}
              />
            </div>
            {/* <div className="message-container">
                            <textarea></textarea>
                        </div> */}
          </CompletionMain>
        </CompletionCenterContainer>
      </StretchLeft>
      <div className="dashboard-body-right">
        <CompletionRightContainer></CompletionRightContainer>
      </div>
    </>
  );
};

export default Completion;
