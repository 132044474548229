import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
//import FontUtil from '../../../utils/FontUtility';
import Button from '../../Button';

const StyledAdminWelcome = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 900px;
  width: 1421px;
  height: 725px;
  border-radius: 50px;
  background-color: white;
`;

const StyledAdminWelcomeRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 50%;
  height: 100%;

  h3 {
    text-align: center;
  }

  button {
    margin-top: 34px;
  }
`;

const StyledLogoutText = styled.div`
  position: absolute;
  bottom: 25px;
  font-size: 20px;
  color: ${(props) => (props.disabled ? 'grey' : 'black')};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  :hover {
    text-decoration: ${(props) => (props.disabled ? 'none' : 'underline')};
  }
`;

const StyledAdminWelcomeLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 50%;
  padding: 25px;

  img {
    width: calc(100% - 48px);
    height: auto;
  }
`;

const AdminWelcome = ({ companyConfig, logo, logout }) => {
  const history = useHistory();

  return (
    <StyledAdminWelcome>
      <StyledAdminWelcomeLogo>
        {logo !== undefined && <img src={logo} alt="logo" type="image" />}
      </StyledAdminWelcomeLogo>
      <StyledAdminWelcomeRight>
        <Button
          text="Portal"
          // color='white'
          color={companyConfig.text_color}
          // backgroundColor={companyConfig.accent_color}
          backgroundColor="transparent"
          onClick={() => {
            history.push('/admin/portal');
          }}
          style={{
            borderStyle: 'solid',
            borderColor: companyConfig.text_color,
            borderRadius: '24px'
          }}
        />
        {/* fontFamily:FontUtil(chosenPortfolio.SystemText) */}
        <Button
          text="Portfolio"
          // color='white'
          color={companyConfig.text_color}
          // backgroundColor={companyConfig.accent_color}
          backgroundColor="transparent"
          onClick={() => {
            history.push('/engage');
          }}
          style={{
            borderStyle: 'solid',
            borderColor: companyConfig.text_color,
            borderRadius: '24px'
          }}
        />
        {/* fontFamily:FontUtil(chosenPortfolio.SystemText) */}
        <StyledLogoutText onClick={logout}>Logout</StyledLogoutText>
      </StyledAdminWelcomeRight>
    </StyledAdminWelcome>
  );
};
/* <Button
          text="Portal"
          color={companyConfig.text_color}
          backgroundColor={companyConfig.accent_color}
          onClick={() => {
            history.push('/admin/portal');
          }}
        />
        <Button
          text="Portfolio"
          color={companyConfig.text_color}
          backgroundColor={companyConfig.accent_color}
          onClick={() => {
            history.push('/engage');
          }}
        /> */

export default AdminWelcome;
