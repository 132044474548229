import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DateSelect from '../DateSelect/index.js';
import { useFirestore } from 'react-redux-firebase';
import SessionSelect from '../SessionSelect';
import { convertSecondsToText } from '../../../utils/timeHelper';
import Topic from './Topic/Topic';
import * as Scale from '../../../utils/Scalars';
const CenterContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 80px auto;

  .date-container {
    grid-row: 1 / span 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const LeftContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 15%;
  position: relative;

  .date-select {
    width: 80%;
    height: 30px;
    border-radius: 40px;
    border: 1px solid black;
    display: grid;
    grid-template-columns: 1fr 1fr;

    div {
      font-size: 1em;
      font-size: 500;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .scroll-arrow-container {
    position: absolute;
    width: 100%;
    height: 20%;
    top: 42%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(-1, 1);
    .arrow {
      transition: all 0.15s ease;
    }

    .arrow:hover {
      transform: scale(1.075, 1.075);
      filter: brightness(130%);
    }
  }
`;

const RightContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 15%;
  position: relative;

  .date-select {
    width: 80%;
    height: 30px;
    border-radius: 40px;
    border: 1px solid black;
    display: grid;
    grid-template-columns: 1fr 1fr;

    div {
      font-size: 1em;
      font-size: 500;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .scroll-arrow-container {
    position: absolute;
    width: 100%;
    height: 20%;
    top: 42%;
    display: flex;
    justify-content: center;
    align-items: center;

    .arrow {
      transition: all 0.15s ease;
    }

    .arrow:hover {
      transform: scale(1.075, 1.075);
      filter: brightness(130%);
    }
  }
`;

const StyledMain = styled.div`
  grid-row: 2 / span 1;
  display: flex;
  justify-content: center;
`;

const StyledCard = styled.div`
  display: grid;
  flex-basis: 30%;
  grid-template-rows: 1fr 5fr;
  box-shadow: 8px 8px 10px #808080;
  border-radius: 8px;
  height: 80%;
  margin-top: 2%;
  margin-left: 1%;
  margin-right: 1%;

  .card-header {
    grid-row: 1 / span 1;
    background-color: #2b2b2b;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;
    padding-left: 20px;
    padding-right: 20px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;

    @media (max-width: 1380px) {
      padding-left: 10px;
      padding-right: 10px;
    }

    h3 {
      margin-block-start: 0px;
      margin-block-end: 0px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: white;
      margin: 5px;
    }

    p {
      margin-block-start: 0px;
      margin-block-end: 0px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: white;
      margin: 5px;
    }
  }

  .card-body {
    grid-row: 2 / span 1;
    background-color: #eeeeee;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 3%;
    overflow-y: auto;
    overflow-x: hidden;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;

    .topic {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 98%;
      height: 12%;
      margin: 1% 0px 1% 0px;
      padding-left: 12px;
      padding-right: 0px;
      overflow: hidden;

      p {
        margin-block-start: 0px;
        margin-block-end: 0px;
        font-size: 0.9em;
        font-weight: 600;
        letter-spacing: 0.01em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .topic-timer {
        //display: flex;
        display: grid;
        //flex-direction: row;
        //justify-content: flex-start;
        //align-items: center;
        //
        grid-template-columns: 1fr 6px ${96 * 1.3}px;
        width: 100%;
        margin-top: 2px;

        .time-bar {
          background-color: #3269a8;
          //height: 100%;
          height: ${(props) => Scale.Height(props.Size, 19)}px;
          border-top-right-radius: 25px;
          border-bottom-right-radius: 25px;
          margin-right: 10px;
        }
      }
    }
  }
`;

const KeyTopics = ({
  SetSessionMasterCollection,
  Dash,
  userInfo,
  Size,
  SetFilteredSession,
  setUsersPool,
  selectedUser
}) => {
  const firestore = useFirestore();

  //MasterCollection Population pattern
  useEffect(() => {
    ////////////onsole.log("!!!DASHS!!!",Dash.SessionMasterCollection)
    if (Dash.SessionMasterCollection === null) return;
    if (Dash.SessionMasterCollection.length === 0) {
      setUsersPool(selectedUser, firestore);
      SetSessionMasterCollection(selectedUser, firestore);
    }
  }, [
    Dash.SessionMasterCollection,
    SetSessionMasterCollection,
    firestore,
    selectedUser,
    setUsersPool
  ]);

  useEffect(() => {
    ////////////onsole.log("!!!DASHGP2!!!",Dash.SessionFilteredCollection)
    if (Dash.SessionMasterCollection === null) {
      ////////////onsole.log("MASTERLENGTH === null")
      return;
    }
    // if(Dash.SessionFilteredCollection === null){
    //return
    //}
    if (Dash.SessionMasterCollection.length === 0) {
      ////////////onsole.log("MASTERLENGTH === 0")
      return;
    }
    // else if(Dash.SessionFilteredCollection.length === 0){
    SetFilteredSession(selectedUser);
    // }
    ////////////onsole.log("!!!DASHGP2End!!!",Dash.SessionFilteredCollection)
  }, [
    Dash.SessionMasterCollection,
    SetFilteredSession,
    selectedUser,
    Dash.solo,
    Dash.selectedPortfolioId,
    Dash.DateRange
  ]); //Dash.SessionFilteredCollection

  const [TopicTimes, SetTopicTimes] = useState([]);
  const [SelectedIndex, SetSelectedIndex] = useState(0);
  //Dash.SessionFilteredCollection
  useEffect(() => {
    SetSelectedIndex(0);
    if (Dash.defaultVisit) {
      let TempArrayOfObjects = [];
      // let isValid1 = false
      // let whileLoop1 = 0
      // while(whileLoop1< Dash.defaultVisit.page_visited.length){
      //     if(typeof(Dash.defaultVisit.page_visited[whileLoop1].Topic[0].title) === typeof("") && typeof(Dash.defaultVisit.page_visited[whileLoop1].Topic[0].id) === typeof("")){
      //     isValid1 = true;
      //     break;
      //     }
      //     whileLoop1 +=1;
      // }
      // if(isValid1){
      Dash.defaultVisit.page_visited.map((PageV, i) => {
        //onsole.log("PAGE VISITS", Dash.defaultVisit.page_visited);
        if (i + 1 < Dash.defaultVisit.page_visited.length) {
          PageV.TimeSpent =
            Dash.defaultVisit.page_visited[i + 1]?.time_visited.seconds -
            PageV.time_visited.seconds;
          if (!PageV.Topic) return PageV;
          if (!PageV.Topic[0]) return PageV;
          if (PageV.Topic[0] === 'SplashPage')
            PageV.Topic[0] = { title: 'SplashPage', id: 'SplashPage' };
          ////////onsole.log("!!!PAGEV!!!",PageV.Topic)
          if (PageV.Topic[0]?.id)
            PageV.Topic.map((Topic, i) => {
              if (!TempArrayOfObjects[i]) TempArrayOfObjects.splice(i, 0, {});
              TempArrayOfObjects[i][Topic.id]
                ? (TempArrayOfObjects[i][Topic.id].Time += PageV.TimeSpent)
                : (TempArrayOfObjects[i][Topic.id] = {
                    id: Topic.id,
                    Title: Topic.title,
                    Time: PageV.TimeSpent,
                    Chain: PageV.Topic.slice(
                      0,
                      PageV.Topic.findIndex((ele) => {
                        return ele === Topic.title;
                      })
                    )
                  }); //Requires a preprocessing stage...
              //TempArrayOfObjects[i][Topic].Topic = PageV.Topic.splice(i,999);
              return Topic;
            });
        }
        return PageV;
      });
      ////////onsole.log("!!!TempArrayOfObjects!!!",TempArrayOfObjects)
      let TempArrayValuesMaster = [];
      TempArrayOfObjects.map((ele) => {
        let TempArrayValues = [];
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(ele)) {
          if (value.Title !== null && value.Title !== 'SplashPage') {
            ////////onsole.log("!!!TempArrayOfObjects!!!", value)
            TempArrayValues.push(value);
          }
        }
        TempArrayValues = TempArrayValues.sort((a, b) => {
          return b.Time - a.Time;
        });
        let TimeTotal = 0;
        TempArrayValues = TempArrayValues.map((value) => {
          value.TimeStr = convertSecondsToText(value.Time, false);
          TimeTotal += value.Time;
          return value;
        });
        let InfoComposite = {};
        InfoComposite.Array = TempArrayValues;
        InfoComposite.TotalTimes = convertSecondsToText(TimeTotal, false);
        TempArrayValuesMaster.push(InfoComposite);
        return ele;
      });
      //FindTerminals
      //////onsole.log("!!!!TAVM",TempArrayValuesMaster)
      let TempTermDetect = [...TempArrayValuesMaster];
      TempTermDetect = TempTermDetect.reverse();
      //////onsole.log("!!!!TAVM",TempTermDetect);
      let ChainCheck = [];
      let End = TempTermDetect.map((ele, i) => {
        ChainCheck.push([]);
        if (i === 0) {
          ele.Array = ele.Array.map((ele) => {
            ele.IsTerm = true;
            if (ele.Chain)
              if (
                !ChainCheck[i].some((ele2) => {
                  return ele2 === ele.Chain[ele.Chain?.length - 1];
                })
              )
                ChainCheck[i].push(ele.Chain[ele.Chain?.length - 1]);
            return ele;
          });
          return ele;
        } else if (i === TempTermDetect.length - 1) {
          return ele;
        } else {
          ele.Array = ele.Array.map((ele) => {
            if (
              !ChainCheck[i - 1].some((ele2) => {
                return ele2.id === ele.id;
              })
            )
              ele.IsTerm = true;
            if (ele.Chain)
              if (
                !ChainCheck[i].some((ele2) => {
                  return ele2 === ele.Chain[ele.Chain?.length - 1];
                })
              )
                ChainCheck[i].push(ele.Chain[ele.Chain?.length - 1]);
            return ele;
          });
          return ele;
        }
      });
      // eslint-disable-next-line no-unused-vars
      End = End.reverse();
      //onsole.log("!!!!TAVM", End);
      //////onsole.log("!!!!TAVM",TempArrayValuesMaster)
      if (TempArrayValuesMaster[0]?.TotalTimes !== '0 sec')
        SetTopicTimes(TempArrayValuesMaster);
      else SetTopicTimes([]);
      return;
    }

    ////////onsole.log("FC",Dash.SessionFilteredCollection)
    let TempArrayOfObjects = [];
    Dash.SessionFilteredCollection.map((ele) => {
      ele.page_visited.map((PageV, i) => {
        if (i + 1 < ele.page_visited.length) {
          PageV.TimeSpent =
            ele.page_visited[i + 1]?.time_visited.seconds -
            PageV.time_visited.seconds;
          ////////onsole.log("!!!PAGEV!!!",PageV.Topic)
          if (!PageV.Topic) return PageV;
          if (!PageV.Topic[0]) return PageV;
          if (PageV.Topic[0] === 'SplashPage')
            PageV.Topic[0] = { title: 'SplashPage', id: 'SplashPage' };
          ////////onsole.log("!!!PAGEV!!!",PageV.Topic)
          if (PageV.Topic[0]?.id)
            PageV.Topic.map((Topic, i) => {
              if (!TempArrayOfObjects[i]) TempArrayOfObjects.splice(i, 0, {});
              TempArrayOfObjects[i][Topic.id]
                ? (TempArrayOfObjects[i][Topic.id].Time += PageV.TimeSpent)
                : (TempArrayOfObjects[i][Topic.id] = {
                    id: Topic.id,
                    Title: Topic.title,
                    Time: PageV.TimeSpent,
                    Chain: PageV.Topic.slice(
                      0,
                      PageV.Topic.findIndex((ele) => {
                        return ele === Topic.title;
                      })
                    )
                  }); //Requires a preprocessing stage...
              //TempArrayOfObjects[i][Topic].Topic = PageV.Topic.splice(i,999);
              return Topic;
            });
        }
        return PageV;
      });
      return ele;
    });
    ////////onsole.log("!!!TempArrayOfObjects!!!",TempArrayOfObjects)
    let TempArrayValuesMaster = [];
    TempArrayOfObjects.map((ele) => {
      let TempArrayValues = [];
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(ele)) {
        if (value.Title !== null && value.Title !== 'SplashPage') {
          ////////onsole.log("!!!TempArrayOfObjects!!!", value)
          TempArrayValues.push(value);
        }
      }
      TempArrayValues = TempArrayValues.sort((a, b) => {
        return b.Time - a.Time;
      });
      let TimeTotal = 0;
      TempArrayValues = TempArrayValues.map((value) => {
        value.TimeStr = convertSecondsToText(value.Time, false);
        TimeTotal += value.Time;
        return value;
      });
      let InfoComposite = {};
      InfoComposite.Array = TempArrayValues;
      InfoComposite.TotalTimes = convertSecondsToText(TimeTotal, false);
      TempArrayValuesMaster.push(InfoComposite);
      return ele;
    });
    //FindTerminals
    //////onsole.log("!!!!TAVM",TempArrayValuesMaster)
    let TempTermDetect = [...TempArrayValuesMaster];
    TempTermDetect = TempTermDetect.reverse();
    //////onsole.log("!!!!TAVM",TempTermDetect)
    let ChainCheck = [];
    let End = TempTermDetect.map((ele, i) => {
      ChainCheck.push([]);
      if (i === 0) {
        ele.Array = ele.Array.map((ele) => {
          ele.IsTerm = true;
          if (ele.Chain)
            if (
              !ChainCheck[i].some((ele2) => {
                return ele2 === ele.Chain[ele.Chain.length - 1];
              })
            )
              ChainCheck[i].push(ele.Chain[ele.Chain?.length - 1]);
          return ele;
        });
        return ele;
      }
      // else if( i === TempTermDetect.length-1){
      //     return ele
      // }
      else {
        ele.Array = ele.Array.map((ele) => {
          if (
            !ChainCheck[i - 1].some((ele2) => {
              return ele2.id === ele.id;
            })
          )
            ele.IsTerm = true;
          if (ele.Chain)
            if (
              !ChainCheck[i].some((ele2) => {
                return ele2 === ele.Chain[ele.Chain.length - 1];
              })
            )
              ChainCheck[i].push(ele.Chain[ele.Chain?.length - 1]);
          return ele;
        });
        return ele;
      }
    });
    // eslint-disable-next-line no-unused-vars
    End = End.reverse();
    //onsole.log("!!!!TAVM", End);
    ////onsole.log("!!!!TAVM",TempArrayValuesMaster)
    if (TempArrayValuesMaster[0]?.TotalTimes !== '0 sec')
      SetTopicTimes(TempArrayValuesMaster);
    else SetTopicTimes([]);
  }, [Dash.SessionFilteredCollection, Dash.defaultVisit]);

  useEffect(() => {
    ////////onsole.log("!!SPTT!!",TopicTimes)
    let ArrayOpen = [];
    let ArrayExhaustive = [];
    //////onsole.log("TopicTimes",TopicTimes)
    TopicTimes.map((ele, i2) => {
      if (i2 === 0) {
        if (ele && ele?.Array?.length > 0 && ele?.Array[0]?.id) {
          ArrayOpen.push(ele.Array[0].id);
          ArrayExhaustive.push(ele.Array[0]);
        }
      } else {
        ele.Array.every((ele2, i) => {
          let chainCheck = true;
          ele2.Chain.every((ele, i) => {
            if (ele.id === ArrayOpen[i]) {
              ////////onsole.log("!!!!ELEID",ele,ArrayOpen[i])
              return true;
            } else if (ele.id === ele2.id) {
              //////onsole.log("!!!!ELEID2",ele,ArrayOpen[i])
              return true;
            } else {
              ////////onsole.log("!!!!ELEFALSE",ele,ArrayOpen[i])
              chainCheck = false;
              return false;
            }
          });
          if (chainCheck) {
            //////onsole.log("!!!!ELETRUE",ele2,i,ArrayOpen[i],ele)
            ArrayOpen.push(ele2.id);
            ArrayExhaustive.push(ele2);
            if (ele2.IsTerm) return false;
            if (ArrayOpen.length >= i2) return false;
          }
          if (ArrayOpen.length < i) return false;
          return true;
        });
      }
      return ele;
    });
    ////////onsole.log("!!SPTT!!",ArrayOpen)
    SetSelectedPath(ArrayOpen);
    SetSelectedExhaustive(ArrayExhaustive);
  }, [TopicTimes]);
  const CardTitle = (i) => {
    switch (i) {
      case 0:
        return 'Key Topics';
      case 1:
        return 'Categories';
      case 2:
        return 'Sub Categories';
      default:
        return 'Sub Pages';
    }
  };
  const [SelectedPath, SetSelectedPath] = useState([]);
  // useEffect(()=>{//////onsole.log("!!SPTT!!",SelectedPath)},[SelectedPath])

  const [SelectedExhaustive, SetSelectedExhaustive] = useState([]);
  // useEffect(()=>{//////onsole.log("!!SPTT!!",SelectedExhaustive)},[SelectedExhaustive])
  function isEqual(arr1, arr2) {
    return JSON.stringify(arr1) === JSON.stringify(arr2);
  }

  return (
    <>
      <LeftContainer className="dashboard-body-left">
        <div className="scroll-arrow-container">
          {SelectedIndex > 0 ? (
            <ArrowForwardIcon
              style={{
                color: 'white',
                backgroundColor: '#3269a8',
                borderRadius: '50%',
                padding: '3px',
                fontSize: '4em',
                cursor: 'pointer'
              }}
              className="arrow"
              onClick={() => {
                SetSelectedIndex(SelectedIndex - 1);
              }}
            />
          ) : null}
        </div>
      </LeftContainer>
      <div
        className="dashboard-body-main"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CenterContainer
          style={{ gridTemplateRows: `${Scale.Height(Size, 90)}px auto` }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start'
            }}
          >
            <div>
              <SessionSelect
                additionalStyle={{ marginRight: '40px' }}
                positioning={285}
              />
            </div>
            <div className="date-container">
              <DateSelect />
            </div>
          </div>
          <StyledMain>
            {TopicTimes.map((Card, i) => {
              if (i > SelectedPath.length) return null;
              if (i < SelectedIndex || i > SelectedIndex + 2) return null;
              let Time = 0;
              let Test = Card.Array
                ? Card.Array.map((Topics, index) => {
                    ////////onsole.log("TOPICS1",SelectedPath[i-1]);
                    ////////onsole.log("TOPICS2",Topics.Chain[i-1],i-1);
                    if (
                      Topics.Chain.length === 0 ||
                      isEqual(Topics.Chain[i - 1]?.id, SelectedPath[i - 1])
                    ) {
                      Time += Topics.Time;
                      return (
                        <Topic
                          key={`k` + index}
                          onClick={(eve) => {
                            let test = [...SelectedPath];
                            //////onsole.log("TEST",test)
                            test.splice(i, 99, Topics.id);
                            //////onsole.log("TEST",test)
                            SetSelectedPath(test);
                            let test2 = [...SelectedExhaustive];
                            test2.splice(i, 99, Topics);
                            SetSelectedExhaustive(test2);
                          }}
                          IsSelected={SelectedPath.some((ele) => {
                            return Topics.id === ele;
                          })}
                          ele={Topics}
                          MaxTime={
                            Card.Array[
                              Card.Array.findIndex((ele) => {
                                return isEqual(
                                  ele.Chain[i - 1]?.id,
                                  SelectedPath[i - 1]
                                );
                              })
                            ]?.Time
                          }
                          index={index}
                          Size={Size}
                        ></Topic>
                      );
                    }
                    return <></>;
                  })
                : [<></>];
              //////onsole.log(Test);
              if (Time !== 0)
                return (
                  <StyledCard Size={Size}>
                    <div className="card-header">
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent:
                            i > 0 ? 'space-between' : 'flex-start',
                          width: '100%'
                        }}
                      >
                        {i === 0 ? (
                          <p
                            style={{
                              marginBlockStart: '0px',
                              marginBlockEnd: '0px',
                              fontWeight: 600,
                              fontSize: `${Scale.Height(Size, 23)}px`,
                              fontFamily: "'Open Sans', sans-serif",
                              letterSpacing: '0.0`em'
                            }}
                          >
                            Key Topics
                          </p>
                        ) : null}
                        <p
                          style={{
                            marginBlockStart: '0px',
                            marginBlockEnd: '0px',
                            fontWeight: 600,
                            fontSize: `${Scale.Height(Size, 19)}px`,
                            fontFamily: "'Open Sans', sans-serif",
                            textDecoration: 'underline'
                          }}
                        >
                          {i > 0
                            ? `${SelectedExhaustive[i - 1].Title.trim()}`
                            : null}
                        </p>
                        <p
                          style={{
                            marginBlockStart: '0px',
                            marginBlockEnd: '0px',
                            fontWeight: 600,
                            fontSize: `${Scale.Height(Size, 18)}px`,
                            fontFamily: "'Open Sans', sans-serif"
                          }}
                        >
                          {
                            i > 0 ? CardTitle(i) : null
                            /* {
                                                `${
                                                    switch(i){
                                                        case 1:
                                                        case 2:
                                                        case 3:
                                                        default:
                                                    }
                                                }`
                                            // switch(i){
                                            // case 1:
                                            //     break;
                                        //}
                                        //i > 0 ? `Sub-Topics Total` : null */
                          }
                        </p>
                      </div>
                      {/* <h3>{Card.TotalTimes}</h3> */}
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent:
                            i > 0 ? 'space-between' : 'flex-start',
                          width: '100%'
                        }}
                      >
                        {i > 0 ? (
                          <h3
                            style={{
                              marginBlockStart: '0px',
                              marginBlockEnd: '0px',
                              fontSize: `${Scale.Height(Size, 16)}px`,
                              fontFamily: "'Open Sans', sans-serif"
                            }}
                          >
                            {i > 0
                              ? `This Page: ${convertSecondsToText(
                                  SelectedExhaustive[i - 1].Time - Time
                                )}`
                              : null}
                          </h3>
                        ) : null}
                        <h3
                          style={{
                            marginBlockStart: '0px',
                            marginBlockEnd: '0px',
                            fontSize: `${Scale.Height(Size, 16)}px`,
                            fontFamily: "'Open Sans', sans-serif"
                          }}
                        >
                          {convertSecondsToText(Time, false)}
                        </h3>
                      </div>
                    </div>
                    <div className="card-body">{Test}</div>
                  </StyledCard>
                );
              else {
                return <></>;
              }
            })}
            {TopicTimes.length === 0 && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <p
                  style={{
                    fontSize: `${Scale.Width(Size, 40)}px`,
                    fontFamily: "'Open Sans', serif",
                    fontWeight: 600
                  }}
                >
                  No User portfolio sessions within the selected range...
                </p>
              </div>
            )}
          </StyledMain>
        </CenterContainer>
      </div>
      <div className="dashboard-body-right">
        <RightContainer>
          {/* <div className="date-select">
                        <div style={{borderTopLeftRadius: '40px', borderBottomLeftRadius: '40px'}} value="solo">Solo</div>
                        <div style={{borderTopRightRadius: '40px', borderBottomRightRadius: '40px'}} value="h2h">H2H</div>
                    </div> */}
          <div className="scroll-arrow-container">
            {SelectedIndex + 3 < TopicTimes.length &&
            SelectedIndex + 2 < SelectedPath.length ? (
              <ArrowForwardIcon
                style={{
                  color: 'white',
                  backgroundColor: '#3269a8',
                  borderRadius: '50%',
                  padding: '3px',
                  fontSize: '4em',
                  cursor: 'pointer'
                }}
                className="arrow"
                onClick={() => {
                  SetSelectedIndex(SelectedIndex + 1);
                }}
              />
            ) : null}
          </div>
        </RightContainer>
      </div>
    </>
  );
};

export default KeyTopics;

// {(TopicTimes.map((ele,i)=>{//Need to be set per card in advance
//     return(<div className="topic">
//     <p>{ele.Title}</p>
//     <div className="topic-timer">
//     <div className="time-bar" style={{width:`${ele.Time/TopicTimes[0].Time}%`}}></div>{/*TopicTimes[cardNumber][item]/TopicTimes[cardNumber][largestTime] */}
//         <p>7 min, 1 sec</p>
//     </div>
// </div>)}))}

/* <StyledCard>
                            <div className="card-header">
                                <p>Total</p>
                                <h3>45 minutes, 30 seconds</h3>
                            </div>
                            <div className="card-body">
                                
                                <div className="topic"> 
                                    <p>Features</p> 
                                    <div className="topic-timer">
                                        <div className="time-bar" style={{width: '70%'}}></div>
                                        <p>7 min, 1 sec</p>
                                    </div>
                                </div>
                                <div className="topic">
                                    <p>Topic 2</p>
                                    <div className="topic-timer">
                                        <div className="time-bar" style={{width: '67%'}}></div>
                                        <p>6 min, 45 sec</p>
                                    </div>
                                </div>
                                <div className="topic">
                                    <p>Topic X</p>
                                    <div className="topic-timer">
                                        <div className="time-bar" style={{width: '50%'}}></div>
                                        <p>XX min, XX sec</p>
                                    </div>
                                </div>
                            </div>
                        </StyledCard>
                        <StyledCard>
                            <div className="card-header">
                                <p></p>
                                <h3>Topic Title</h3>
                            </div>
                            <div className="card-body">
                                <div className="topic">
                                    <p>ZUI</p>
                                    <div className="topic-timer">
                                        <div className="time-bar" style={{width: '70%'}}></div>
                                        <p>5 min, 1 sec</p>
                                    </div>
                                </div>
                                <div className="topic">
                                    <p>Multimedia</p>
                                    <div className="topic-timer">
                                        <div className="time-bar" style={{width: '25%'}}></div>
                                        <p>1 min, 30 sec</p>
                                    </div>
                                </div>
                                <div className="topic">
                                    <p>Subcategory Title</p>
                                    <div className="topic-timer">
                                        <div className="time-bar" style={{width: '15%'}}></div>
                                        <p>XX min, XX sec</p>
                                    </div>
                                </div>
                            </div>
                        </StyledCard>
                        <StyledCard>
                            <div className="card-header">
                                <p></p>
                                <h3>Topic Title</h3>
                            </div>
                            <div className="card-body">
                                <div className="topic">
                                    <p>Features</p>
                                    <div className="topic-timer">
                                        <div className="time-bar" style={{width: '70%'}}></div>
                                        <p>7 min, 1 sec</p>
                                    </div>
                                </div>
                                <div className="topic">
                                    <p>Topic 2</p>
                                    <div className="topic-timer">
                                        <div className="time-bar" style={{width: '67%'}}></div>
                                        <p>6 min, 45 sec</p>
                                    </div>
                                </div>
                                <div className="topic">
                                    <p>Topic X</p>
                                    <div className="topic-timer">
                                        <div className="time-bar" style={{width: '50%'}}></div>
                                        <p>XX min, XX sec</p>
                                    </div>
                                </div>
                            </div>
                        </StyledCard> */
