import React, { useState, useEffect } from 'react';
import PortfolioContent from '../../../components/_Portfolio/PortfolioContent';
import Button from '../../../components/Button';
import Icons from '../../Icons';
import { isEmpty } from 'lodash';
import FontUtil from '../../../utils/FontUtility';

const PortfolioContentParent = ({
  createSetCurrentContent,
  images,
  videos,
  pdfs,
  companyConfig,
  chosenPortfolio,
  pages,
  nav,
  pageBackgroundImageUrl,
  isGuestMode,
  isPortfolioView,
  guestPages,
  guestContentHelper,
  currentContentHelper,
  isAtEnd,
  currentLevel,
  setTopic,
  chosenPageId,
  chosenContentPageId,
  menuHide,
  navHistory,
  setMenuHide,
  setContentPageId,
  setCurrentContentHelper
}) => {
  const [currentNavItem, setCurrentNavItem] = useState({});

  // first load
  /*
  useEffect(() => {
    setContentPageId(chosenPageId);
    // eslint-disable-next-line
  }, []);
*/
  // automatically select first item
  //Add SetTopic
  useEffect(() => {
    let splashKeyId = `splashkey-${chosenPortfolio._id}`;
    if (chosenPageId !== 0 && chosenPageId !== splashKeyId) {
      // get current nav item
      let tempNavItem = {};

      // traverse nav history to find current nav
      navHistory.forEach((number, index) => {
        // first
        if (index === 0) {
          tempNavItem = nav.items[number];
        } else {
          tempNavItem = tempNavItem.children[number];
        }
      });
      setCurrentNavItem(tempNavItem);
      setContentPageId(tempNavItem.referenceId);
    }
    if (chosenPageId === splashKeyId) {
      prepareSplash();
    }
    // eslint-disable-next-line
  }, [chosenPageId, pages, navHistory, chosenPortfolio]);

  // run everytime chosenContentPageId updates
  useEffect(() => {
    if (chosenContentPageId) {
      //check if clicked page was gallery with multiple items
      if (
        pages[chosenContentPageId]?.content &&
        pages[chosenContentPageId]?._type === 'gallery' &&
        pages[chosenContentPageId]?.content.length > 1
      ) {
        // automatically set first item of gallery to current content viewed
        let obj = {
          type: 'gallery',
          index: 0,
          id: chosenContentPageId
        };
        createSetCurrentContent(obj);
      } else {
        // else do normal
        let obj = {
          type: 'pageSub',
          id: chosenContentPageId
        };
        createSetCurrentContent(obj);
      }
    } else {
      // show splash when chosenContentPageId is undefined
      //////////onsole.log('chosenContentPageId is undefined, show splash.....');
      prepareSplash();
    }
    // eslint-disable-next-line
  }, [
    chosenContentPageId,
    pages,
    guestContentHelper,
    isGuestMode,
    isPortfolioView
  ]);

  // function used when user changes gallery index
  const prepareCurrentGalleryContent = (index) => {
    let obj = {
      type: 'gallery',
      index: index,
      id: chosenContentPageId
    };
    createSetCurrentContent(obj);
  };

  // prepare splash to display
  //Add SetTopic
  const prepareSplash = () => {
    let splashKeyId = `splashkey-${chosenPortfolio._id}`;
    // create nav specifically for splash
    setCurrentNavItem({
      children: [],
      referenceField: 'pages',
      referenceId: splashKeyId,
      title: 'Splash',
      type: 'gallery',
      _key: 'splashnav'
    });
    setContentPageId(splashKeyId);
    // check if guest
    if (isGuestMode && !isPortfolioView) {
      // find index
      let index = guestContentHelper.findIndex((x) => x.id === splashKeyId);
      setCurrentContentHelper(guestContentHelper[index].id);
    }
    setTopic(['SplashPage']);
  };

  if (chosenContentPageId === 0) {
    return null;
  }

  const contentData = () => {
    let tabData = {};
    // check if in guest mode
    if (
      isGuestMode &&
      chosenContentPageId !== 0 &&
      !isEmpty(currentContentHelper) &&
      pages[chosenContentPageId]?._type === 'gallery' &&
      pages[chosenContentPageId]?._key === currentContentHelper.page_id &&
      currentContentHelper.is_gallery_item
    ) {
      //////////onsole.log('MODIFY FOR GALLERY CONTENT');
      // create obj to only display gallery item chosen by guest
      let obj =
        pages[chosenContentPageId]?.content[currentContentHelper.gallery_index];
      tabData = [{ ...obj }];
      return tabData;
    }
    return pages[chosenContentPageId]?.content;
  };

  if (!chosenContentPageId) {
    return null;
  }

  return (
    <div
      className="portfolio-content-parent"
      style={{
        backgroundImage: isAtEnd ? `url(${pageBackgroundImageUrl})` : ''
      }}
    >
      {!menuHide ? (
        <div className="portfolio-content-menu-container">
          <Button
            width="280px"
            height="80px"
            fontFamily={FontUtil(
              pages[currentNavItem.referenceId]?.TitleText,
              chosenPortfolio.ContentText
            )}
            fontFamily2={FontUtil(
              pages[currentNavItem.referenceId]?.SubtitleText,
              chosenPortfolio.ContentText
            )}
            text={currentNavItem.title}
            color={
              pages[currentNavItem.referenceId]?.text_color
                ? pages[currentNavItem.referenceId]?.text_color
                : chosenPortfolio.text_color
            }
            backgroundColor={
              chosenPortfolio.accent_color ? chosenPortfolio.accent_color : ''
            }
          />
          <div className="portfolio-content-menu">
            {currentNavItem.children &&
              currentNavItem.children.length > 0 &&
              isAtEnd &&
              currentNavItem.children.map((child, index) => {
                // get color for each specific page....
                let accentColor = pages[child.referenceId].accent_color;
                let textColor = pages[child.referenceId].text_color;

                // set to chosenPortfolio color if it doesn't exists
                accentColor = accentColor
                  ? accentColor
                  : chosenPortfolio.accent_color;
                textColor = textColor ? textColor : chosenPortfolio.text_color;

                // set to companyConfig color if it doesn't exists
                accentColor = accentColor
                  ? accentColor
                  : companyConfig.accent_color;
                textColor = textColor ? textColor : companyConfig.text_color;

                return (
                  <Button
                    fontFamily={FontUtil(
                      pages[child.referenceId].TitleText,
                      chosenPortfolio.ContentText
                    )}
                    fontFamily2={FontUtil(
                      pages[child.referenceId].SubtitleText,
                      chosenPortfolio.ContentText
                    )}
                    key={child._key + '-' + index}
                    width="280px"
                    height="60px"
                    text={child.title}
                    onClick={() => {
                      // only update if different
                      if (chosenContentPageId !== child.referenceId) {
                        setContentPageId(child.referenceId);
                      }
                    }}
                    backgroundColor={accentColor}
                    color={textColor}
                  />
                );
              })}
          </div>
        </div>
      ) : (
        <div className="exit-fullscreen-button">
          <Icons.FullscreenExit onClick={() => setMenuHide(false)} />
        </div>
      )}
      <div className="portfolio-content-container">
        {chosenContentPageId !== 0 &&
          pages[chosenContentPageId]?._type !== false && (
            <PortfolioContent
              contentType={pages[chosenContentPageId]?._type}
              images={images}
              videos={videos}
              pdfs={pdfs}
              contents={contentData()}
              page={pages[chosenContentPageId]}
              prepareCurrentGalleryContent={prepareCurrentGalleryContent}
            />
          )}
      </div>
    </div>
  );
};
export default PortfolioContentParent;
