import { connect } from 'react-redux';
import Dashboard from './Dashboard';
import {
  SetSessionMasterCollection,
  SetGuestPassMasterCollection,
  SetGuestpassSessionMasterCollection,
  setUsersPool,
  setMasterGuestPassSessionDispatch,
  setSelectedUser,
  setLeadViewer,
  SetPreProcessingNotificationsList,
  SetNotificationsCount,
  SetPostProcessingNotificationsList,
  SetNotificationsList
} from '../../actions';

const mapStateToProps = (state) => ({
  userInfo: state.firestore.ordered.users,
  Dash: state.DashboardReducer
});

const mapDispatchToProps = (dispatch) => {
  return {
    SetGuestpassSessionMasterCollection: (UI, firestore) =>
      dispatch(SetGuestpassSessionMasterCollection(UI, firestore)),
    setSessionMasterCollection: (UI, firestore) =>
      dispatch(SetSessionMasterCollection(UI, firestore)),
    setGuestPassMasterCollection: (userInfo, firestore) =>
      dispatch(SetGuestPassMasterCollection(userInfo, firestore)),
    setUsersPool: (userInfo, firestore) =>
      dispatch(setUsersPool(userInfo, firestore)),
    setSelectedUser: (user) => dispatch(setSelectedUser(user)),
    SLV: (LV) => dispatch(setLeadViewer(LV)),
    SetMasterGuestPassSessionList: (List) => {
      dispatch(setMasterGuestPassSessionDispatch(List));
    },
    SetNL: (List) => {
      dispatch(SetNotificationsList(List));
    },
    SetPrePNL: (List) => {
      dispatch(SetPreProcessingNotificationsList(List));
    },
    SetPPNL: (List) => {
      dispatch(SetPostProcessingNotificationsList(List));
    },
    SetNotificationsCount: (int) => {
      dispatch(SetNotificationsCount(int));
    }
  };
};

const DashboardRevamp = connect(mapStateToProps, mapDispatchToProps)(Dashboard);

export default DashboardRevamp;
