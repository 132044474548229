import React from 'react';
import PortfolioSelect from '../../../PortfolioSelect';
import styled from 'styled-components';
import TestSearch from '../LeadCore/LeadSearch/';
const BackButton = styled.div`
  position: relative;
  top: 24px;
  max-width: ${96 * 3}px;
  left: 48px;
  border-radius: 24px;
  background-color: #0769a9;
  color: #ffffff;
  text-align: center;

  &:hover {
    background-color: #268fcc;
  }
  > p {
    margin-block: 4px;
  }
`;
const Header = ({ Dash, SLV }) => {
  return (
    <div style={{ gridRow: '1/1', position: 'relative' }}>
      <div
        style={{
          width: `${96 * 4}px`,
          flexDirection: 'column',
          display: 'flex',
          position: 'absolute',
          left: '12px'
        }}
      >
        {Dash.ActiveGPL ? (
          <BackButton
            onClick={() => {
              SLV(null);
            }}
          >
            <p>Back To List</p>
          </BackButton>
        ) : (
          <>
            <p>Select Portfolio</p>
            <PortfolioSelect></PortfolioSelect>
          </>
        )}
      </div>
      <div
        style={{
          width: `${96 * 4}px`,
          flexDirection: 'column',
          display: 'flex',
          position: 'absolute',
          right: '96px'
        }}
      >
        {/* {!Dash.ActiveGPL?<><p>&nbsp;</p><TestSearch style={{ width:"288px", height:"24px"}}></TestSearch></>:null} */}
        {/* {Dash.ActiveGPL?<><TestSearch style={{ width:"288px", height:"24px", top:"24px"}}></TestSearch></>:null} */}
        {
          <>
            <TestSearch
              style={{ width: '384px', height: '24px', top: '24px' }}
            ></TestSearch>
          </>
        }
      </div>
      {/* position:'absolute', bottom:'12px', right:"12px", */}
    </div>
  );
};
export default Header;
