import { connect } from 'react-redux';
import UserInput from './UserInformationInput';
import { getUpdatedUser } from '../../../../../actions/sanityContentActions';
const mapStateToProps = (state) => ({
  member: state.sanityContentReducer.member,
  userInfo: state.firestore.ordered.users
});
const mapDispatchToProps = (dispatch) => {
  return {
    getUpdatedUser: (Mem) => dispatch(getUpdatedUser(Mem))
  };
};

const UserInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserInput);

export default UserInputContainer;
