/* eslint-disable react/no-unknown-property */
import { React } from 'react';
const Comments = ({
  Fill = '#FFF',
  Rotation,
  onClick,
  className,
  id,
  style
}) => {
  let string = `.clsComments-1{fill:transparent;}.clsComments-2{fill:none;stroke:${Fill};stroke-linecap:round;stroke-linejoin:round;stroke-width:20px;}.clsComments-3{fill:${Fill};}`;
  return (
    <svg
      style={{ ...style }}
      onClick={onClick}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
    >
      <defs>
        <style>{string}</style>
      </defs>
      <path
        class="clsComments-1"
        d="M133,95H357.51A28.49,28.49,0,0,1,386,123.49V411a0,0,0,0,1,0,0H122a0,0,0,0,1,0,0V106a11,11,0,0,1,11-11Z"
      />
      <line
        class="clsComments-2"
        x1="293.2"
        y1="232.72"
        x2="172.24"
        y2="232.72"
      />
      <line
        class="clsComments-2"
        x1="327.76"
        y1="198.16"
        x2="172.24"
        y2="198.16"
      />
      <line
        class="clsComments-2"
        x1="327.76"
        y1="267.28"
        x2="172.24"
        y2="267.28"
      />
      <line
        class="clsComments-2"
        x1="293.2"
        y1="301.84"
        x2="172.24"
        y2="301.84"
      />
      <path
        class="clsComments-3"
        d="M149.06,94.57c0-3.15-.19-5.74,0-8.28A7.71,7.71,0,0,1,156.7,79a7.31,7.31,0,0,1,7.71,7.11c.27,10.88.25,21.77,0,32.64-.1,4.29-3.65,7.06-7.79,7a7.42,7.42,0,0,1-7.52-7.27c-.2-2.54,0-5.11,0-8.06-6.23,0-12.07-.48-17.79.15-4.76.53-5.61,4.72-5.61,8.85q0,20.83,0,41.68V392.23c0,1.58-.05,3.15,0,4.73.25,6,2.83,8.5,8.9,8.51q36.74,0,73.46,0,77.53,0,155.08,0c9.35,0,11.26-1.84,11.26-11.35q0-136,0-272a42.08,42.08,0,0,0-.16-5.15c-.56-4.36-3-6.61-7.39-6.87-2.41-.14-4.84,0-7.66,0v-15c16.64-3.89,30.76,6.86,30.76,23.5q0,139,0,278c0,14.66-9.59,24.26-24.15,24.34-7.45,0-14.89,0-22.34,0Q239.22,421,135,421c-15.56,0-24.93-9.39-24.94-24.87q0-138.36,0-276.72c0-15.24,9.4-24.7,24.58-24.82C139.24,94.54,143.8,94.57,149.06,94.57Z"
      />
      <path
        class="clsComments-3"
        d="M335.53,94.72c0-3.39-.21-6,.05-8.52C336,81.81,339.19,79,343.2,79a7.46,7.46,0,0,1,7.64,7.2q.36,16.29,0,32.6c-.09,4.16-3.37,6.84-7.35,6.94a7.47,7.47,0,0,1-7.83-6.46,27.52,27.52,0,0,1-.26-4.27c0-1.41,0-2.82,0-4.79H312.31V94.72Z"
      />
      <path
        class="clsComments-3"
        d="M242.26,110.22h-23V94.67h22.94c0-3-.11-5.53,0-8A7.78,7.78,0,0,1,250,79a7.65,7.65,0,0,1,7.76,7.55q.23,15.85,0,31.73a7.37,7.37,0,0,1-7.75,7.45,7.51,7.51,0,0,1-7.67-7.57C242.16,115.63,242.26,113.09,242.26,110.22Z"
      />
      <path
        class="clsComments-3"
        d="M195.69,110.11H172.63V94.6h23.1c0-2.92-.11-5.45,0-8,.23-4.39,3.14-7.38,7.2-7.61a7.48,7.48,0,0,1,8.11,7.14c.29,10.72.29,21.46.07,32.18a7.55,7.55,0,0,1-7.88,7.44c-4.22-.08-7.29-3.16-7.54-7.74C195.58,115.64,195.69,113.23,195.69,110.11Z"
      />
      <path
        class="clsComments-3"
        d="M288.77,110.14H265.82V94.62h23c0-2.9-.12-5.29,0-7.67.29-4.78,3.54-8,7.88-7.93s7.49,3.23,7.55,8.15c.11,10.16.14,20.32,0,30.48-.05,4.87-3.23,8.07-7.59,8.13s-7.59-3.11-7.91-7.92C288.64,115.47,288.77,113.06,288.77,110.14Z"
      />
    </svg>
  );
};
export default Comments;
