//import { StepLabel } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as Scale from '../../../utils/Scalars';

const SessionSelectContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .session-select-chosen {
    width: 250px;
    height: 30px;
    border-radius: 12px;
    display: grid;
    grid-template-columns: 8.5fr 1.5fr;
    cursor: pointer;
    background-color: #fcfafa;

    .text-box {
      grid-column: 1 / span 1;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      input {
        width: 100%;
        height: 100%;
        padding-left: 10px;
        padding-right: 10px;
        border: none;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        font-family: 'Newsreader', serif;
        font-size: 14px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        @media (max-width: 1380px) {
          font-size: 12px;
        }
      }

      p {
        margin-block-start: 0px;
        margin-block-end: 0px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 600;
        letter-spacing: 0.05em;
        font-size: 0.9em;
        font-family: 'Newsreader', serif;
      }
    }

    .arrow-box {
      grid-column: 2 / span 1;
      display: flex;
      justify-content: center;
      align-items: center;
      border-left: 1px solid #e6e6e6;

      div {
        height: 70%;
        width: 70%;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          margin-block-start: 0px;
          margin-block-end: 0px;
          font-weight: 400;
          font-size: 20px;
          font-family: 'Newsreader', serif;
          transform: scale(1.5, 0.75) rotate(90deg);
        }
      }
    }
  }

  .session-select-list {
    position: absolute;
    bottom: -260px;
    width: 242px;
    height: 272px;
    max-height: 300px;
    border-top: none;
    z-index: 10;
    background-color: white;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 2px 2px 4px black;

    .reset-container {
      height: 35px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgb(59, 59, 59);
      cursor: pointer;

      p {
        margin-block-end: 0px;
        margin-block-start: 0px;
        font-weight: 600;
        font-size: 1em;
        letter-spacing: 0.1em;
        font-family: 'Newsreader', serif;
        color: white;
      }
    }

    .list-container {
      width: 100%;
      height: 238px;
      max-height: 238px;
      overflow-y: auto;
      overflow-x: hidden;

      .list-item {
        height: 60px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        cursor: pointer;

        div {
          height: 49%;
          width: 100%;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-left: 5px;
          padding-right: 5px;

          p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 0.9em;
            font-weight: 600;
            margin-block-start: 0px;
            margin-block-end: 0px;
            font-family: 'Newsreader', serif;

            @media (max-width: 1380px) {
              font-size: 0.6em;
            }
          }
        }
      }
    }
  }
`;

const SessionSelect = ({
  setShowSessionSelect,
  showSessionSelect,
  sessionList,
  setDefaultVisit,
  setSessionDateString,
  sessionDateString,
  setAggregateSelected,
  SetDaterangeDispatch,
  setShowPortfolioSelect,
  efficiencyUserToggle,
  setEfficiencyUserToggle,
  additionalStyle,
  setSecondaryUserSessionToggle,
  setSecondaryUserAggregateToggle,
  Size,
  positioning
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterClicked, setFilterClicked] = useState(false);
  const [currentPlaceholder, setCurrentPlaceholder] = useState(
    'Filter by Company or User'
  );
  let positioningValue = positioning ? positioning : 300;

  const closeDrop = (event) => {
    if (event.target.className !== 'session-select-input') {
      closeAct();
    }
  };

  const closeAct = () => {
    setShowSessionSelect(false);
    window.removeEventListener('click', closeDrop);
  };

  const toggleDropdown = () => {
    if (!showSessionSelect) {
      window.setTimeout(() => {
        window.addEventListener('click', closeDrop);
      }, 50);
    }
    setShowSessionSelect(!showSessionSelect);
  };

  const closeFilter = (event) => {
    if (event.target.id !== 'session-select-input-id') {
      closeActFilter();
    }
  };

  const closeActFilter = () => {
    setFilterClicked(false);
    setSearchTerm('');
    window.removeEventListener('click', closeFilter);
  };

  const toggleFilter = () => {
    if (!filterClicked) {
      window.setTimeout(() => {
        window.addEventListener('click', closeFilter);
      }, 50);
    }
    setFilterClicked(!filterClicked);
  };

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
    if (event.target.value !== '') {
      setCurrentPlaceholder('');
    } else {
      setCurrentPlaceholder('Filter by Company or User');
    }
  };

  const resetUserToggle = () => {
    if (efficiencyUserToggle === 'off') {
      setEfficiencyUserToggle('on');
    }
    setSecondaryUserSessionToggle('on');
    setSecondaryUserAggregateToggle('off');
  };

  const [filteredSessions, SetfilteredSessions] = useState([]);

  useEffect(() => {
    // //////onsole.log("SESSION LIST", sessionList);
    let tfilteredSessions = sessionList.filter((session) => {
      return (
        session?.customer_company
          ?.toLowerCase()
          .indexOf(searchTerm.toLowerCase()) !== -1 ||
        session?.customer_name
          ?.toLowerCase()
          .indexOf(searchTerm.toLowerCase()) !== -1
      );
    });
    SetfilteredSessions(tfilteredSessions);
  }, [sessionList, searchTerm]);

  let listMapping = filteredSessions.map((session, index) => {
    let sessionDate = new Date(
      session.session_date.seconds * 1000
    ).toDateString();
    let sessionTime = new Date(
      session.session_date.seconds * 1000
    ).toLocaleTimeString();
    let timeString = `${sessionDate}, ${sessionTime}`;
    let infoString = `${session.customer_company} - ${session.customer_name}`;

    return (
      <div
        className="list-item"
        onClick={() => {
          setDefaultVisit(session);
          setSessionDateString(infoString);
          setShowSessionSelect(false);
          setAggregateSelected('0');
          resetUserToggle();
          setFilterClicked(false);
        }}
        key={session.id}
        style={{
          backgroundColor: index % 2 === 1 ? 'white' : 'rgb(222, 222, 222)',
          height: `${Scale.Width(Size, 65)}px`,
          maxHeight: `${Scale.Width(Size, 65)}px`
        }}
      >
        <div style={{ alignItems: 'flex-end' }}>
          <p>{timeString}</p>
        </div>
        <div style={{ alignItems: 'flex-start' }}>
          <p>{infoString}</p>
        </div>
      </div>
    );
  });

  return (
    <SessionSelectContainer style={{ ...additionalStyle }}>
      <div
        className="session-select-chosen"
        onClick={() => {
          toggleDropdown();
          setShowPortfolioSelect(false);
        }}
        style={{
          boxShadow: '1px 1px 3px black',
          backgroundColor: showSessionSelect ? '#3b3b3b' : '#fcfafa',
          color: showSessionSelect ? '#fcfafa' : '#3b3b3b',
          height: `${Scale.Width(Size, 30)}px`,
          width: `${Scale.Width(Size, 275)}px`
        }}
      >
        <div className="text-box">
          <input
            onClick={() => toggleFilter()}
            value={searchTerm}
            onChange={handleInputChange}
            type="text"
            placeholder={filterClicked ? currentPlaceholder : sessionDateString}
            style={{ textAlign: 'center' }}
            id="session-select-input-id"
          />
        </div>
        <div className="arrow-box">
          <div>
            <p style={{ fontSize: `${Scale.Width(Size, 22)}px` }}>{'>'}</p>
          </div>
        </div>
      </div>
      <div
        className="session-select-list"
        style={{
          visibility: showSessionSelect === true ? 'visible' : 'hidden',
          left: `calc(50% - ${Scale.Width(Size, 300)})`,
          width: `${Scale.Width(Size, 280)}px`,
          height: `${Scale.Width(Size, 300)}px`,
          maxHeight: `${Scale.Width(Size, 300)}px`,
          bottom: `-${Scale.Width(Size, positioningValue)}px`
        }}
      >
        <div
          className="reset-container"
          style={{
            height: `${Scale.Width(Size, 40)}px`,
            maxHeight: `${Scale.Width(Size, 40)}px`
          }}
          onClick={() => {
            setDefaultVisit(undefined);
            setSessionDateString('Select a Portfolio Session');
            setShowSessionSelect(false);
            setAggregateSelected('1m');
            SetDaterangeDispatch(3);
            setSecondaryUserSessionToggle('off');
            setSecondaryUserAggregateToggle('on');
          }}
        >
          <p style={{ fontSize: `${Scale.Width(Size, 18)}px` }}>Reset</p>
        </div>
        <div
          className="list-container"
          style={{
            height: `${Scale.Width(Size, 260)}px`,
            maxHeight: `${Scale.Width(Size, 260)}px`
          }}
        >
          {listMapping}
        </div>
      </div>
    </SessionSelectContainer>
  );
};

export default SessionSelect;
