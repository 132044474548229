import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { useFirebase } from 'react-redux-firebase';
import styled from 'styled-components';
import PasswordReset from '../../components/_Auth/PasswordReset';

const StyledAuthPage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(7, 104, 176);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledAuthPageBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 900px;
  width: 1021px;
  height: 725px;
  border-radius: 50px;
  background-color: white;
`;

const StyledAuthPageStatusMsg = styled.div`
  color: ${(props) => props.color};
  margin-top: 25px;
`;

const AuthPage = (props) => {
  const [params, setParems] = useState({});
  const [email, setEmail] = useState('pend');
  const [mode, setMode] = useState('');
  const firebase = useFirebase();

  useEffect(() => {
    //let query = new URLSearchParams(useLocation().search);
    let params = queryString.parse(props.location.search);
    setParems(params);
    setMode(params.mode);

    // verify code is valid
    firebase
      .auth()
      .verifyPasswordResetCode(params.oobCode)
      .then((email) => {
        // Display a "new password" form with the user's email address
        setEmail(email);
      })
      .catch(() => {
        // Invalid code
        console.error('ERROR: invalid code');
        setEmail('');
      });
  }, [props.location.search, firebase]);

  return (
    <StyledAuthPage>
      {email === '' ? (
        <StyledAuthPageBox>
          <StyledAuthPageStatusMsg color="red">
            <h2>Error: invalid code</h2>
          </StyledAuthPageStatusMsg>
        </StyledAuthPageBox>
      ) : email === 'pend' ? (
        <StyledAuthPageBox>
          <StyledAuthPageStatusMsg color="black">
            <h2>Verifying Email...</h2>
          </StyledAuthPageStatusMsg>
        </StyledAuthPageBox>
      ) : (
        <StyledAuthPageBox>
          {mode === 'resetPassword' && (
            <PasswordReset
              updateSessionKey={props.updateSessionKey}
              email={email}
              params={params}
              firebase={firebase}
              history={props.history}
            />
          )}
        </StyledAuthPageBox>
      )}
    </StyledAuthPage>
  );
};

export default AuthPage;
