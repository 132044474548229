import React, { useState, useEffect } from 'react';
// import { useHistory } from 'react-router-dom';
import { useFirestore } from 'react-redux-firebase'; //useFirebase,
import ContentParent from '../../../../components/_Portfolio/PortfolioContentParentTemplate9';
//import ContentParent from '../../../../components/_Portfolio/PortfolioContentParentTemplate9'
import { urlFor } from '../../../../utils/sanityHelper';
import PDFViewer2 from '../../../../components/FullWidthPDFViewer';
import BASlider from '../../../../components/BeforeAfterSlider';
import BlockContent from '@sanity/block-content-to-react';
import ColorUtil from '../../../../utils/ColorUtility';
import FontUtil from '../../../../utils/FontUtility';
import serializers from '../../../../utils/scriptSerializers';
import Like from '../../../../utils/Likes';
// import GalThumb from '../../../../components/_PortfolioContentParent3/GalleryThumbsContent'
// import ToolCross from './Shape.png'
import exit from './Exclude.png';
import CloseIcon from '../CloseSVG';
import Icons from '../../../../containers/Icons';

const TMG = ({
  createSetCurrentContent,
  chosenContentPageId,
  pages,
  pages2,
  chosenPortfolio,
  images,
  videos,
  pdfs,
  back,
  isGuestMode,
  sessionId,
  liked,
  setBooleanNewItems,
  setLiked,
  currentGuestContentHelper,
  addToBriefcase,
  showBriefcase,
  currentContent,
  endPortfolio,
  toggleNotepad,
  toggleBriefcase,
  clearAll,
  setPortfolioCustomerInfo,
  toggleScriptnote,

  updateSessionInSanity,
  SET_ANNOTATOR,

  showNotepad,

  mode,

  briefcaseContent,
  isModalOpen,

  menuHide,

  userInfo,
  Size,
  booleanNewItems,
  ANNOTATOR2,
  setToolbarMenuOpen,
  toolbarMenuOpen,
  sendClick,
  shareClick
}) => {
  // const handleToggle = () => {
  //   let anti = !toolbarMenuOpen;
  //   setToolbarMenuOpen(anti);
  // }
  //Flag Font Family
  const [isAuthBriefcaseShow, setIsAuthBriefcaseShow] = useState(false); // is this user authorized to see briefcase?
  useEffect(() => {
    if (userInfo && userInfo.length > 0) {
      // TODO: Why is this constant condition?
      // check if user is able to create guest pass
      if (true) {
        // userInfo[0].is_able_to_create_briefcase
        // user is able to create normal guest pass, aka able to add item to briefcase
        setIsAuthToAdd(true);
      }
      // check if user is able create portfolio level guest pass
      if (true) {
        // userInfo[0].is_able_to_create_guest_pass
        // user is able to create normal guest pass, aka able to add item to briefcase
        setIsAuthBriefcaseShow(true);
      }
    }
  }, [userInfo]);
  const [isAblePortfolioGuestPass, setIsAblePortfolioGuestPass] =
    useState(false);
  useEffect(() => {
    if (userInfo && userInfo.length > 0) {
      // TODO: Why is this constant condition?
      if (true) {
        // userInfo[0].is_able_to_create_guest_pass
        setIsAblePortfolioGuestPass(true);
      }
    }
  }, [userInfo]);
  const [isAddable, setIsAddable] = useState(true); // bool to keep track of if it is addable to briefcase
  const [isAuthToAdd, setIsAuthToAdd] = useState(false); // is this user authorized to add item?
  //const [isAuthBriefcaseShow, setIsAuthBriefcaseShow] = useState(false); // is this user authorized to see briefcase?
  const [isLiked2, setIsLiked2] = useState(null); // is this user authorized to see briefcase?
  // const history = useHistory();
  const firestore = useFirestore();
  // const firebase = useFirebase();
  //Landing Area

  // get userinfo auth for viewing sending briefcase
  useEffect(() => {
    if (userInfo && userInfo.length > 0) {
      // TODO: Why is this constant condition?
      // check if user is able to create guest pass
      // userInfo[0].is_able_to_create_briefcase
      if (true) {
        // user is able to create normal guest pass, aka able to add item to briefcase
        setIsAuthToAdd(true);
      }
      // check if user is able create portfolio level guest pass
      if (true) {
        // userInfo[0].is_able_to_create_guest_pass
        // user is able to create normal guest pass, aka able to add item to briefcase
        //setIsAuthBriefcaseShow(true);
      }
    }
  }, [userInfo]);
  useEffect(() => {
    // check if briefcase contains current content user is looking at
    if (
      (Object.entries(currentContent).length !== 0 &&
        briefcaseContent.filter((e) => e.id === currentContent.id).length >
          0) ||
      currentContent.page_id === `splashkey-${chosenPortfolio._id}`
    ) {
      setIsAddable(false);
    } else {
      setIsAddable(true);
    }
  }, [currentContent, briefcaseContent, chosenPortfolio]);
  const updateFavorite = (op) => {
    Like(
      op,
      isGuestMode,
      sessionId,
      liked,
      currentGuestContentHelper,
      currentContent,
      chosenPortfolio,
      setIsLiked2,
      setLiked,
      firestore
    );
    // // do not log if mode is not guest or no sessionId
    // if (!isGuestMode || sessionId === '') {
    //   return null;
    // }

    // // copy liked from redux store
    // let likedArrCopy = [...liked];
    // //////////onsole.log("!!CC!!",currentContent)
    // let id = currentGuestContentHelper.id;
    // let content = currentContent;
    // // check if currentGuestContentHelper id is undefined
    // if (!id) {
    //   //////////onsole.log('like in full portfolio guest-pass view...');
    //   id = currentContent.id;
    //   // if undefined it means that guest is in full portfolio view and not in briefcase guest view
    // } else {
    //   // user in briefcase guest view
    //   //////////onsole.log('like in Briefcase guest view...');
    //   // set content from currentGuestContentHelper, if not splash
    //   if (id !== `splashkey-${chosenPortfolio._id}`) {
    //     content = currentGuestContentHelper;
    //   }
    // }
    // let content2 = {...content, time_liked:firestore.Timestamp.now()}
    // if(content2.PagesSaved === undefined){
    //   content2.PagesSaved = 1;
    // }
    // // if op is add then append currentContent obj to likedArrCopy,
    // if (op === 'add') {
    //   likedArrCopy.push(content2);
    // } else {
    //   // if not filter out
    //   likedArrCopy = likedArrCopy.filter((obj) => obj.id !== id);
    // }
    // //////////onsole.log("LIKED: ", likedArrCopy);
    // setIsLiked2(likedArrCopy)
    // // replace the liked array
    // firestore
    //   .collection('sessions')
    //   .doc(sessionId)
    //   .update({
    //     liked: likedArrCopy
    //   })
    //   .then(() => {
    //     // replace liked in store
    //     setLiked(likedArrCopy);
    //   })
    //   .catch((error) => console.error('ERROR: ', error));
  };

  // check if current page is liked already or not
  const isLiked = () => {
    let currentGuestContentId = currentGuestContentHelper.id;
    // check if currentGuestContentHelper id is undefined
    if (!currentGuestContentId) {
      //////////onsole.log('Liked check in full portfolio guest-pass view...');
      // check if liked contains currentContent id
      return liked.some((obj) => obj.id === currentContent.id);
    } else {
      // user in briefcase guest view
      //////////onsole.log('Liked check in Briefcase guest view...');
      if (currentGuestContentId === `splashkey-${chosenPortfolio._id}`) {
        // check if liked contains currentContent id for splash
        return liked.some((obj) => obj.id === currentContent.id);
      } else {
        // check if liked contains currentGuestContentId
        return liked.some((obj) => obj.id === currentGuestContentId);
      }
    }
  };

  //////onsole.log("!!!!TESTpages!!!!",pages);
  const [contents] = useState(pages[chosenContentPageId]?.content); //setContents
  const SubRef = React.useRef();
  const prepareCurrentGalleryContent = (index) => {
    let obj = {
      type: 'gallery',
      index: index,
      id: chosenContentPageId
    };
    createSetCurrentContent(obj);
  };
  const renderSingleThumb = (type, galleryNum, isSelected) => {
    switch (type) {
      case 'galleryImage': {
        let image = contents[galleryNum].image;
        return (
          <img
            className="portfolio-single-image"
            src={urlFor(image).auto('format').width(300).url()} // auto converts to webp if browser supports it
            alt="mainImage"
            type="galleryImage"
            style={{ height: '100%', width: '100%', objectFit: 'contain' }}
            // style={{
            //   opacity: chosenPortfolio && chosenPortfolio.theme === 'theme2' ? opacityBack : 1,
            //   width: imageWidth,
            //   height: imageHeight
            // }}
          />
        );
      }
      case 'galleryVideo': {
        let video = videos[contents[galleryNum].video.asset._ref];
        //let videoSettings = contents[galleryNum];
        return (
          <img
            style={{ height: '100%', width: '100%', objectFit: 'contain' }}
            src={`https://image.mux.com/${
              video.playbackId
            }/thumbnail.png?width=200&fit_mode=preserve&time=${
              video.thumbTime !== 0 ? video.thumbTime : 1
            }`}
            alt="poster"
          />
          // <SanityMuxPlayer
          //   assetDocument={video}
          //   autoload={true}
          //   autoplay={videoSettings.autoplay}
          //   loop={videoSettings.loop}
          //   muted={false}
          //   showControls={true}
          //   style={{}}
          //   posterurl={`https://image.mux.com/${video.playbackId}/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=1`}
          //   type="galleryVideo"
          //   isSelected={isSelected}
          // />
        );
      }
      case 'galleryPdf': {
        let url2 = pdfs[contents[galleryNum].file.asset._ref].url;
        let thumbNailRef2 = contents[galleryNum].thumbnail.asset._ref;
        let thumbNailUrl2 = urlFor(thumbNailRef2)
          .auto('format')
          .width(300)
          .url();
        return (
          <PDFViewer2
            url={url2}
            isThumbnail={true}
            type="galleryFullWidthPdf"
            thumbnailUrl={thumbNailUrl2}
          />
        );
      }
      case 'galleryBASlider': {
        let Aimage = contents[galleryNum].Aimage;
        let Bimage = contents[galleryNum].Bimage;
        let LabelA = contents[galleryNum].AimageL;
        let LabelB = contents[galleryNum].BimageL;
        let Vert = contents[galleryNum].Vert;
        let Color = contents[galleryNum].SlideColor;
        //////onsole.log('!!!!BA!!!!', contents[galleryNum])
        return (
          <BASlider
            Thumb
            LabelB={LabelB}
            LabelA={LabelA}
            Color={Color}
            Vert={Vert}
            ImageA={Aimage}
            ImageB={Bimage}
          />
        );
      }
      case 'galleryFullWidthPdf': {
        let url3 = pdfs[contents[galleryNum].file.asset._ref].url;
        let thumbNailRef3 = contents[galleryNum].thumbnail.asset._ref;
        let thumbNailUrl3 = urlFor(thumbNailRef3)
          .auto('format')
          .width(300)
          .url();
        return (
          <PDFViewer2
            url={url3}
            isThumbnail={true}
            type="galleryFullWidthPdf"
            thumbnailUrl={thumbNailUrl3}
          />
        );
      }
      default:
        return <div />;
    }
  };
  const [Pos, SetPos] = React.useState(0);
  // const KillEve = (eve)=>{
  //     //eve.preventDefault();
  //     //eve.stopPropagation();
  //     return;
  //   }
  // const [EL,SetEL] = React.useState();
  // React.useEffect(()=>{
  //     let temp = document.getElementById("TerminalMenuATemplate9");
  //     try{
  //     EL.current.removeEventListener('wheel',KillEve,{passive:false, capture:false});
  //     }
  //     catch{}
  //     temp.addEventListener('wheel',KillEve,{passive:false, capture:false});
  //     SetEL(temp);
  //     return ()=>{
  //         temp.removeEventListener('wheel',KillEve,{passive:false, capture:false});
  //         try{
  //         EL.current.removeEventListener('wheel',KillEve,{passive:false, capture:false});
  //     }
  //     catch{}
  // }
  // },[])
  // let temptest = pages[chosenContentPageId]?.content;
  // //////onsole.log("TT",temptest);
  // temptest = temptest.push(temptest[0]);
  // temptest = temptest.push(temptest[0]);
  // temptest = temptest.push(temptest[0]);
  // temptest = temptest.push(temptest[0]);
  //////onsole.log("TMG",createSetCurrentContent,pages[chosenContentPageId])
  let foundPage = pages[chosenContentPageId];
  let htmlThumb =
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    foundPage.content
      ? foundPage.content.find((piece) => piece._type === 'imageLinks')
      : null;
  const [Display] = useState(
    (pages[chosenContentPageId]?.content
      ? pages[chosenContentPageId]?.content[Pos]?.DescriptionBool &&
        pages[chosenContentPageId]?.content[Pos]?.descriptionHeader
      : false) ||
      (pages[chosenContentPageId]?.DescriptionBool &&
        pages[chosenContentPageId]?.descriptionHeader)
  ); //,SetDisplay
  const [DesHeadSize] = useState(
    pages[chosenContentPageId]?.StoryBookObjects?.descriptionHeaderFontsize
      ? pages[chosenContentPageId]?.StoryBookObjects
          ?.descriptionHeaderFontsize + 'px'
      : '56px'
  );
  const [DesHeadSizeT] = useState(
    pages[chosenContentPageId]?.StoryBookObjects?.descriptionHeaderFontsize
      ? pages[chosenContentPageId]?.StoryBookObjects
          ?.descriptionHeaderFontsize *
          (36 / 56) +
          'px'
      : '36px'
  );
  const [DesSize] = useState(
    pages[chosenContentPageId]?.StoryBookObjects?.descriptionFontsize
      ? pages[chosenContentPageId]?.StoryBookObjects?.descriptionFontsize + 'px'
      : '20px'
  );
  const [DesSizeT] = useState(
    pages[chosenContentPageId]?.StoryBookObjects?.descriptionFontsize
      ? pages[chosenContentPageId]?.StoryBookObjects?.descriptionFontsize *
          (20 / 20) +
          'px'
      : '20px'
  );
  const [Anno, SetAnno] = useState(
    window.innerWidth > 450 && window.innerHeight > 515
  );
  //onsole.log("SIZE",Size, !(Size.W < 880 && Size.H < 1100), (Size.W > 450 && !(window.innerWidth<=815 && window.innerHeight<=550) && !(Size.W < 880 && Size.H < 1100)))
  useEffect(() => {
    SetAnno(Size.W > 450 && Size.H > 515);
  }, [Size.H, Size.W]);
  //onsole.log("pages[chosenContentPageId]?.content[Pos]",pages[chosenContentPageId]?.content[Pos])
  return (
    <div
      className="TerminalMenu"
      onPointerDown={(eve) => {
        eve.preventDefault();
        eve.stopPropagation();
      }}
      id="TerminalMenuATemplate9"
      ref={SubRef}
      style={{
        backgroundColor: pages[chosenContentPageId]?.terminal_menu_color
          ? pages[chosenContentPageId]?.terminal_menu_color
          : chosenPortfolio.terminal_menu_color
          ? chosenPortfolio.terminal_menu_color
          : null
      }}
    >
      <CloseIcon
        className={'Exit'}
        src={exit}
        onClick={back}
        alt="Exit Terminal View"
        id={'TMG1'}
      />
      {/* <p className="title" style={{overflow:"hidden", color:ColorUtil(pages[chosenContentPageId].text_color,chosenPortfolio.text_color,'black')}}>{pages[chosenContentPageId]?.title}</p> */}
      {Size.W > 450 &&
      !(window.innerWidth <= 815 && window.innerHeight <= 550) &&
      !(Size.W < 880 && Size.H < 1100) ? (
        <div className="GallerySlider">
          <p
            className="title GallerySet"
            style={{
              color: ColorUtil(
                pages[chosenContentPageId].text_color,
                chosenPortfolio.text_color,
                'black'
              )
            }}
          >
            {pages[chosenContentPageId]?.title}
          </p>
          <div
            className="ScrollWrap"
            style={{ height: '100%', width: '100%', overflowY: 'scroll' }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {pages[chosenContentPageId]?._type === 'gallery' ||
              pages[chosenContentPageId]?._type === 'gallery' ? (
                pages[chosenContentPageId]?.content.map((item, i) => {
                  return (
                    <div
                      key={`k` + i}
                      className="TMGThumb"
                      style={{
                        marginLeft:
                          Size.H > Size.W ? (i > 0 ? '54px' : '0px') : '0px',
                        marginTop:
                          Size.W > Size.H ? (i > 0 ? '54px' : '0px') : '0px',
                        border:
                          Pos === i && chosenPortfolio?.active_terminal_button
                            ? `3px solid ${chosenPortfolio.active_terminal_button}`
                            : Pos === i
                            ? '3px solid #449FF5'
                            : '3px solid transparent'
                      }}
                      onClick={() => {
                        prepareCurrentGalleryContent(i);
                        SetPos(i);
                      }}
                    >
                      {renderSingleThumb(item._type, i, false)}
                    </div>
                  );
                })
              ) : pages[chosenContentPageId]?._type === 'website' ? (
                <div
                  className="TMGThumb"
                  style={{
                    marginTop: '0px',
                    position: 'relative',
                    border: chosenPortfolio?.active_terminal_button
                      ? `3px solid ${chosenPortfolio.active_terminal_button}`
                      : '3px solid #449FF5'
                  }}
                  //onClick={()=>{prepareCurrentGalleryContent(i); SetPos(i)}}
                >
                  <iframe
                    scrolling="no"
                    title="iframe"
                    allowFullScreen
                    src={pages[chosenContentPageId]?.url}
                    style={{
                      height: '100%',
                      width: '100%',
                      border: 'none',
                      backgroundColor: 'white'
                    }}
                  ></iframe>
                  <div
                    className="TMGThumb"
                    style={{
                      marginTop: '0px',
                      position: 'absolute',
                      top: '0px'
                    }}
                  />
                </div>
              ) : pages[chosenContentPageId]?._type === 'htmlContent' ? (
                <div
                  className="TMGThumb"
                  style={{
                    marginTop: '0px',
                    position: 'relative',
                    border: chosenPortfolio?.active_terminal_button
                      ? `3px solid ${chosenPortfolio.active_terminal_button}`
                      : '3px solid #449FF5'
                  }}
                  //onClick={()=>{prepareCurrentGalleryContent(i); SetPos(i)}}
                >
                  <img
                    src={urlFor(htmlThumb.image.asset._ref)
                      .auto('format')
                      .url()}
                    alt="logo"
                    className="portfolio-single-image"
                    style={
                      {
                        // opacity: chosenPortfolio && chosenPortfolio.theme === 'theme2' ? opacityBack : 1,
                        // width: imageWidth,
                        // height: imageHeight
                      }
                    }
                  />
                  <div
                    className="TMGThumb"
                    style={{
                      marginTop: '0px',
                      position: 'absolute',
                      top: '0px',
                      border: chosenPortfolio?.active_terminal_button
                        ? `3px solid ${chosenPortfolio.active_terminal_button}`
                        : '3px solid #449FF5'
                    }}
                    //onClick={()=>{prepareCurrentGalleryContent(i); SetPos(i)}}
                  />
                </div>
              ) : null}
              {/* <GalThumb 
                    videos={videos} 
                    chosenPortfolio={chosenPortfolio} 
                    foundPage={pages[chosenContentPageId]} 
                    index={i} 
                    onNavClick={()=>{prepareCurrentGalleryContent(i); SetPos(i)}} 
                    //currentNavItem={}
                     /> */}
            </div>
          </div>
        </div>
      ) : (
        <>
          <p
            className="title GallerySet"
            style={{
              color: ColorUtil(
                pages[chosenContentPageId].text_color,
                chosenPortfolio.text_color,
                'black'
              )
            }}
          >
            {pages[chosenContentPageId]?.title}
          </p>
          {/* <img className={"Exit"} src={exit} onClick={back} alt="Exit Terminal View" /> */}
          <CloseIcon
            className={'Exit'}
            src={exit}
            onClick={back}
            alt="Exit Terminal View"
            id={'TMG1'}
          />
          {/* <div className="GallerySlider"> */}

          <div
            className="ScrollWrap"
            style={{ height: '100%', width: '100%', overflowY: 'scroll' }}
          >
            {/* <div style={{display:"flex",flexDirection:"column", justifyContent:"center", alignItems:"center"}}> */}

            {pages[chosenContentPageId]?._type === 'gallery' ||
            pages[chosenContentPageId]?._type === 'gallery' ? (
              pages[chosenContentPageId]?.content.map((item, i) => {
                return (
                  <div
                    key={`k` + i}
                    className="TMGThumb"
                    style={{
                      marginLeft: i > 0 ? '20px' : '0px',
                      border:
                        Pos === i && chosenPortfolio?.active_terminal_button
                          ? `3px solid ${chosenPortfolio.active_terminal_button}`
                          : Pos === i
                          ? '3px solid #449FF5'
                          : '3px solid transparent'
                    }}
                    onClick={() => {
                      prepareCurrentGalleryContent(i);
                      SetPos(i);
                    }}
                  >
                    {renderSingleThumb(item._type, i, false)}
                  </div>
                );
              })
            ) : pages[chosenContentPageId]?._type === 'website' ? (
              <div
                className="TMGThumb"
                style={{
                  marginTop: '0px',
                  position: 'relative',
                  border: chosenPortfolio?.active_terminal_button
                    ? `3px solid ${chosenPortfolio.active_terminal_button}`
                    : '3px solid #449FF5'
                }}
                //onClick={()=>{prepareCurrentGalleryContent(i); SetPos(i)}}
              >
                <iframe
                  scrolling="no"
                  title="iframe"
                  allowFullScreen
                  src={pages[chosenContentPageId]?.url}
                  style={{
                    height: '100%',
                    width: '100%',
                    border: 'none',
                    backgroundColor: 'white'
                  }}
                ></iframe>
                <div
                  className="TMGThumb"
                  style={{
                    marginTop: '0px',
                    position: 'absolute',
                    top: '0px',
                    border: chosenPortfolio?.active_terminal_button
                      ? `3px solid ${chosenPortfolio.active_terminal_button}`
                      : '3px solid #449FF5'
                  }}
                />
              </div>
            ) : pages[chosenContentPageId]?._type === 'htmlContent' ? (
              <div
                className="TMGThumb"
                style={{
                  marginTop: '0px',
                  position: 'relative',
                  border: chosenPortfolio?.active_terminal_button
                    ? `3px solid ${chosenPortfolio.active_terminal_button}`
                    : '3px solid #449FF5'
                }}
                //onClick={()=>{prepareCurrentGalleryContent(i); SetPos(i)}}
              >
                <img
                  src={urlFor(htmlThumb.image.asset._ref).auto('format').url()}
                  alt="logo"
                  className="portfolio-single-image"
                  style={
                    {
                      // opacity: chosenPortfolio && chosenPortfolio.theme === 'theme2' ? opacityBack : 1,
                      // width: imageWidth,
                      // height: imageHeight
                    }
                  }
                />
                <div
                  className="TMGThumb"
                  style={{
                    marginTop: '0px',
                    position: 'absolute',
                    top: '0px',
                    border: chosenPortfolio?.active_terminal_button
                      ? `3px solid ${chosenPortfolio.active_terminal_button}`
                      : '3px solid #449FF5'
                  }}
                  //onClick={()=>{prepareCurrentGalleryContent(i); SetPos(i)}}
                />
              </div>
            ) : null}
            {/* <GalThumb 
                    videos={videos} 
                    chosenPortfolio={chosenPortfolio} 
                    foundPage={pages[chosenContentPageId]} 
                    index={i} 
                    onNavClick={()=>{prepareCurrentGalleryContent(i); SetPos(i)}} 
                    //currentNavItem={}
                     /> */}
          </div>
          {/* </div> */}
          {/* </div> */}
        </>
      )}
      <div className="Tools">
        <CloseIcon className={'Exit'} src={exit} onClick={back} />
        {/* <img className={"Exit"} src={exit} onClick={back} alt="Exit Terminal View"/> */}
        <div className="OnMediaTools">
          {/* {isAddable && isAuthToAdd && 
        (<Icons.AddShoppingCart id="Bypass"
  className="icon"
  onClick={() => {
    addToBriefcase(currentContent);
    // if (!showBriefcase) {
    //   toggleBriefcase();
    // }
    if (showBriefcase === true) {
      setBooleanNewItems(false);
    } else {
      setBooleanNewItems(true);
    }
  }}
  data-tip="Add to Briefcase"
/>
)} */}
          <Icons.ShoppingCart
            id="Bypass"
            className="icon"
            onClick={() => {
              // toggleBriefcase();
              // setBooleanNewItems(false);
              // handleVisibility();
              if (
                isAblePortfolioGuestPass &&
                isAuthToAdd &&
                isAuthBriefcaseShow
              ) {
                sendClick(true);
              } else if (isGuestMode) {
                shareClick(true);
              }
            }}
            chosenPortfolio={chosenPortfolio}
            Size={Size}
            briefcaseContent={briefcaseContent}
            // booleanNewItems={booleanNewItems}
          />
          <div className="test-absolute-text">
            <p
              onClick={() => {
                // toggleBriefcase();
                // setBooleanNewItems(false);
                // handleVisibility();
                if (isAblePortfolioGuestPass) {
                  sendClick(true);
                } else if (isGuestMode) {
                  shareClick(true);
                }
              }}
              style={{
                color: chosenPortfolio?.bulk_icon_override
                  ? chosenPortfolio.bulk_icon_override
                  : '#3b3b3bBF'
              }}
            >
              GUEST PASS
            </p>
          </div>
          {isAddable && isAuthToAdd && <p>Add to Briefcase</p>}
          {isGuestMode ? (
            isLiked() && isLiked2 ? (
              <Icons.Favorite
                id="Bypass"
                onClick={() => {
                  updateFavorite('remove');
                }}
              />
            ) : (
              <Icons.FavoriteBorder
                id="Bypass"
                onClick={() => {
                  updateFavorite('add');
                }}
              />
            )
          ) : null}
          {chosenPortfolio.AnnotatorAvial !== false && Anno && (
            <>
              <Icons.AnnotatorIcon onClick={() => SET_ANNOTATOR(!ANNOTATOR2)} />
              {Anno ? <p>Annotator</p> : <></>}{' '}
            </>
          )}
          <Icons.NoteAdd
            Overlay={isGuestMode ? 'Contact' : 'Notepad'}
            onClick={() => {
              toggleNotepad();
              //handleToggle();
            }}
          ></Icons.NoteAdd>
          {mode === 'training' && (
            <div className="icon-and-text-parent">
              <Icons.SpeakerNotes
                id="Bypass"
                className="icon"
                chosenPortfolio={chosenPortfolio}
                Size={Size}
                onClick={() => {
                  // handleToggle();
                  toggleScriptnote();
                  // handleVisibility();
                }}
                data-tip={Size.W > 450 ? 'Speaker Notes' : null}
              />
              <div className="test-absolute-text">
                <p
                  onClick={() => {
                    // handleToggle();
                    toggleScriptnote();
                    // handleVisibility();
                  }}
                  style={{
                    color: chosenPortfolio?.bulk_icon_override
                      ? chosenPortfolio.bulk_icon_override
                      : '#3b3b3bBF'
                  }}
                >
                  SPEAKER NOTES
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* <div className="ToolsIcon"><img src={ToolCross} style={{height:"calc(100% * 42/115)",width:"calc(100% * 48/350)", position:"relative", left:"calc(100% * 36/350)", top:"calc(100% * 32/115)"}} /></div> */}
      <div
        className="ContentArea TMG"
        style={{ maxHeight: !Display ? '100%' : null }}
      >
        <ContentParent
          Position={Pos}
          createSetCurrentContent={createSetCurrentContent}
        />
        {Display ? (
          <div id="TESTICONS">
            <div
              className="SubpageBlockContentWrapper header TMG OnGray"
              style={{
                fontSize: window.innerWidth < 1367 ? DesHeadSizeT : DesHeadSize,
                marginTop: 'calc(100% * 26/1080)',
                fontFamily: FontUtil(
                  pages[chosenContentPageId]?.StoryBookObjects
                    ?.descriptionHeaderFont,
                  chosenPortfolio.ContentText
                )
              }}
            >
              {/* width:"calc(100% * 773/1159)", */}
              {pages[chosenContentPageId]?.StoryBookObjects
                .descriptionHeader && (
                <BlockContent
                  blocks={
                    pages[chosenContentPageId]?.content[Pos].descriptionHeader
                      ? pages[chosenContentPageId]?.content[Pos]
                          .descriptionHeader
                      : pages[chosenContentPageId]?.StoryBookObjects
                          .descriptionHeader
                  }
                  serializers={serializers}
                ></BlockContent>
              )}
            </div>
            {/* FLAG FONT FAMILY */}
            <div
              className="SubpageBlockContentWrapper description OnGray"
              style={{
                fontSize: window.innerWidth < 1367 ? DesSizeT : DesSize,
                fontFamily: FontUtil(
                  pages[chosenContentPageId]?.StoryBookObjects?.descriptionFont,
                  chosenPortfolio.ContentText
                )
              }}
            >
              {pages[chosenContentPageId]?.StoryBookObjects.description && (
                <BlockContent
                  blocks={
                    pages[chosenContentPageId]?.content[Pos].description
                      ? pages[chosenContentPageId]?.content[Pos].description
                      : pages[chosenContentPageId]?.content[Pos]
                          .descriptionHeader
                      ? null
                      : pages[chosenContentPageId]?.StoryBookObjects.description
                  }
                  serializers={serializers}
                ></BlockContent>
              )}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default TMG;
