/* eslint-disable react/no-unknown-property */
import { React } from 'react';
const Cursor = ({ style }) => {
  return (
    <svg
      style={{ ...style }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50.22 50.22"
    >
      <defs>
        <style>{`.clscursor-1{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:2.98px;}`}</style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="design">
          <path
            class="clscursor-1"
            d="M1.49,1.49,21.17,48.73l7-20.57,20.57-7Z"
          />
          <path class="clscursor-1" d="M29.33,29.33,46,46" />
        </g>
      </g>
    </svg>
  );
};
export default Cursor;
