import { connect } from 'react-redux';
import P9 from './PII';
import {
  toggleScriptnote,
  toggleBriefcase,
  toggleNotepad,
  endPortfolio,
  setPortfolioCustomerInfo,
  addToBriefcase,
  clearAll,
  // showModal,
  hideModal,
  setBooleanNewItems,
  updateSessionInSanity,
  setLiked
} from '../../../../../actions';

const mapStateToProps = (state) => ({
  showNotepad: state.notepadReducer.showNotepad,
  showBriefcase: state.briefcaseReducer.showBriefcase,
  mode: state.portfolioReducer.mode,
  pages: state.sanityContentReducer.pages,
  sessionId: state.portfolioReducer.sessionId,
  briefcaseContent: state.briefcaseReducer.briefcaseContent,
  isModalOpen: state.modalReducer.isModalOpen,
  chosenPortfolio: state.portfolioReducer.chosenPortfolio,
  menuHide: state.pageMenuReducer.menuHide,
  isGuestMode: state.guestReducer.isGuestMode,
  currentGuestContentHelper: state.guestReducer.currentContentHelper,
  liked: state.portfolioReducer.liked,
  userInfo: state.firestore.ordered.users,
  Size: state.portfolioReducer.Size,
  booleanNewItems: state.briefcaseReducer.booleanNewItems,
  ANNOTATOR2: state.portfolioReducer.aNNOTATOR,
  showScriptnote: state.scriptnoteReducer.showScriptnote
});
const mapDispatchToProps = (dispatch) => {
  return {
    //updateSessionInSanity: (obj) => dispatch(updateSessionInSanity(obj)),
    endPortfolio: () => dispatch(endPortfolio()),
    toggleNotepad: () => dispatch(toggleNotepad()),
    toggleBriefcase: () => dispatch(toggleBriefcase()),
    clearAll: () => dispatch(clearAll()),
    setPortfolioCustomerInfo: (obj) => dispatch(setPortfolioCustomerInfo(obj)),
    setLiked: (array) => dispatch(setLiked(array)),
    setBooleanNewItems: (bool) => dispatch(setBooleanNewItems(bool)),
    addToBriefcase: (obj) => dispatch(addToBriefcase(obj)),
    toggleScriptnote: () => dispatch(toggleScriptnote()),
    updateSessionInSanity: (obj) => dispatch(updateSessionInSanity(obj)),
    SET_ANNOTATOR: (t) => dispatch({ type: 'SET_ANNOTATOR', payload: t }),
    hideModal: () => dispatch(hideModal())
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(P9);
