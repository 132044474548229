import React, { useState } from 'react';
import InputBox from '../../components/InputBox';
import Button from '../../components/Button';
import styled from 'styled-components';
//import { urlFor } from '../../utils/sanityHelper';
import { customerValidate } from '../../utils/validateHelper';
//import sortByKey, {sortStringLiteral} from '../../utils/alphabetizer';
import FontUtil from '../../utils/FontUtility';

const CustomerInfoForm = ({
  companyConfig,
  customerInfo,
  handleSubmit,
  submitText,
  Seed,
  images,
  setLoginInputSelected,
  setLoginInputSelectedCount,
  loginInputSelected,
  loginInputSelectedCount,
  Size,
  engage,
  chosenPortfolio
}) => {
  // const HoverDarkenDiv = styled.div`
  // &:hover{
  //   // filter: brightness(90%);
  // }
  // `;
  //font-family:${FontUtil(chosenPortfolio.SystemText)}
  const HoverDarkenP = styled.p`
    &:hover {
      filter: brightness(80%);
    }
  `;
  const initialForm = {
    firstName: '',
    lastName: '',
    company: '',
    email: '',
    ccEmails: []
  };
  const [formData, updateFormData] = useState({
    ...initialForm,
    ...customerInfo
  });
  const [isError, setIsError] = useState(false);

  // Update cc emails in array
  const udpateCCEmail = (index, email) => {
    // create new array with ccEmails content
    let newArr = [...formData.ccEmails];

    // update ccEmail at certain index
    newArr[index] = email;
    //newArr = sortStringLiteral(newArr)
    updateFormData({
      ...formData,
      ccEmails: newArr
    });
  };

  const addNewCCEmail = () => {
    let newArr = [...formData.ccEmails, ''];
    //newArr = sortStringLiteral(newArr)
    updateFormData({
      ...formData,
      ccEmails: newArr
    });
  };

  const handleChange = (e) => {
    // update formData
    //////////onsole.log(e.target.name);
    if (e.target.name !== 'company')
      updateFormData({
        ...formData,
        [e.target.name]: e.target.value.trim()
      });
    else
      updateFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
  };

  const beforeSubmit = () => {
    // validate
    let isValid = customerValidate(formData);
    if (isValid) {
      // valid do submit
      handleSubmit({ ...formData });
    } else {
      // show error
      setIsError(true);
    }
  };
  if (Seed) {
    //////onsole.log("!!SEED!!",Seed)
    let NArray = Seed.Name.split(' ');
    let E = Seed.Email;
    formData.email = E;
    if (NArray[0]) formData.firstName = NArray[0];
    else formData.firstName = 'FN';
    if (NArray[1]) formData.lastName = NArray[1];
    else formData.lastName = 'LN';
  }
  // if(Seed && customerValidate(formData)){
  //   //window.setTimeout(()=>{handleSubmit({...formData });},1)
  //   window.setTimeout(()=>{handleSubmit({...formData });},250)
  // }

  const closeFilter = (event) => {
    if (event.target && event.target.id !== 'bypass') {
      closeActFilter();
    } else {
      setLoginInputSelected(true);
    }
  };

  const closeActFilter = () => {
    setLoginInputSelected(false);
    //////onsole.log("SELECTED: ", loginInputSelected);
    window.removeEventListener('click', closeFilter);
  };

  const toggleFilter = () => {
    if (Size.W >= 1367 || engage !== true) {
      return;
    }
    if (loginInputSelected === false) {
      window.setTimeout(() => {
        window.addEventListener('click', closeFilter);
      }, 50);
    }
    setLoginInputSelected(true);
    //////onsole.log("SELECTED: ", loginInputSelected);
  };

  //Ping Font Family
  return (
    <form className="customer-info-form">
      {/* {companyConfig.logo !== undefined && (
        <img
          src={urlFor(images[companyConfig.logo.asset._ref])
            .auto('format')
            .width(600)
            .url()}
          alt="logo"
          type="image"
        />
      )} */}

      {isError && (
        <div
          className="customer-form-error"
          style={{
            fontFamily: FontUtil(chosenPortfolio.SystemText),
            textAlign: 'center'
          }}
        >
          Error: Please make sure that forms are filled in correctly
        </div>
      )}
      <InputBox
        name="firstName"
        width={engage && Size.W < 451 ? '300px' : '426px'}
        height="32px"
        placeholder="First Name"
        type="text"
        onChange={handleChange}
        value={formData.firstName}
        isValid={!isError}
        required={true}
        style={{
          borderTop: 'none',
          borderRight: 'none',
          borderLeft: 'none',
          borderRadius: '0px',
          margin: '1%',
          borderColor: 'gray',
          fontFamily: FontUtil(chosenPortfolio.SystemText)
        }}
        id="bypass"
        onClick={() => toggleFilter()}
      />
      <InputBox
        name="lastName"
        width={engage && Size.W < 451 ? '300px' : '426px'}
        height="32px"
        placeholder="Last Name"
        type="text"
        onChange={handleChange}
        value={formData.lastName}
        isValid={!isError}
        required={true}
        style={{
          borderTop: 'none',
          borderRight: 'none',
          borderLeft: 'none',
          borderRadius: '0px',
          margin: '1%',
          borderColor: 'gray',
          fontFamily: FontUtil(chosenPortfolio.SystemText)
        }}
        id="bypass"
        onClick={() => toggleFilter()}
      />
      <InputBox
        name="company"
        width={engage && Size.W < 451 ? '300px' : '426px'}
        height="32px"
        placeholder="Company"
        type="text"
        onChange={handleChange}
        value={formData.company}
        style={{
          borderTop: 'none',
          borderRight: 'none',
          borderLeft: 'none',
          borderRadius: '0px',
          margin: '1%',
          borderColor: 'gray',
          fontFamily: FontUtil(chosenPortfolio.SystemText)
        }}
        id="bypass"
        onClick={() => toggleFilter()}
      />
      <InputBox
        name="email"
        width={engage && Size.W < 451 ? '300px' : '426px'}
        height="32px"
        placeholder="Email"
        type="email"
        onChange={handleChange}
        value={formData.email}
        isValid={!isError}
        required={true}
        style={{
          borderTop: 'none',
          borderRight: 'none',
          borderLeft: 'none',
          borderRadius: '0px',
          margin: '1%',
          borderColor: 'gray',
          fontFamily: FontUtil(chosenPortfolio.SystemText)
        }}
        id="bypass"
        onClick={() => toggleFilter()}
      />
      <p
        style={{ fontFamily: FontUtil(chosenPortfolio.SystemText) }}
        className="add-cc-email-text"
        onClick={addNewCCEmail}
      >
        Add Cc
      </p>
      <div className="cc-email">
        {formData.ccEmails.map((emails, index) => (
          // <HoverDarkenDiv key={'email-cc-' + index} style={{display:"flex", boxSizing:'border-box', padding:"2px", margin:"10px", marginTop:"0px", backgroundColor:"rgb(245,245,245)"}}>
          <div
            key={'email-cc-' + index}
            style={{
              display: 'flex',
              boxSizing: 'border-box',
              padding: '2px',
              margin: '10px',
              marginTop: '0px',
              backgroundColor: 'rgb(245,245,245)'
            }}
          >
            <InputBox
              Pos={{ padding: '12px' }}
              key={'email-cc-' + index}
              name="ccemail"
              width={engage && Size.W < 451 ? '275px' : '340px'}
              height="32px"
              placeholder="Email"
              type="email"
              onChange={(e) => udpateCCEmail(index, e.target.value.trim())}
              //onChange={(e)=> {em}}
              value={formData.ccEmails[index]}
              style={{
                borderTop: 'none',
                borderRight: 'none',
                borderLeft: 'none',
                borderRadius: '0px',
                margin: '1%',
                borderColor: 'gray',
                fontFamily: FontUtil(chosenPortfolio.SystemText)
              }}
            />
            <HoverDarkenP
              style={{
                fontSize: '18px',
                cursor: 'pointer',
                alignSelf: 'center',
                color: 'rgb(200,200,200)',
                position: 'relative',
                fontFamily: FontUtil(chosenPortfolio.SystemText)
              }}
              onClick={() => {
                formData.ccEmails.splice(index, 1);
                updateFormData({ ...formData });
              }}
            >
              X
            </HoverDarkenP>
          </div>
          //
          // </HoverDarkenDiv>
        ))}
      </div>
      <Button
        darken={true}
        // backgroundColor='transparent'
        //color='white'
        color={companyConfig.text_color}
        text={submitText}
        height="50px"
        onClick={beforeSubmit}
        disabled={false}
        style={{
          borderStyle: 'solid',
          fontSize: '18px',
          borderColor: companyConfig.text_color,
          borderRadius: '24px',
          marginTop: '24px',
          fontFamily: FontUtil(chosenPortfolio.SystemText)
        }}
      />
    </form>
  );
};
/* <Button
backgroundColor={companyConfig.accent_color}
color={companyConfig.text_color}
text={submitText}
height="50px"
onClick={beforeSubmit}
disabled={false}
/> */
export default CustomerInfoForm;
