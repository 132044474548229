import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import EngageChoiceBox from '../../components/_Engage/EngageChoiceBox';
import EngageCustomerBox from '../../components/_Engage/EngageCustomerBox';
import { urlFor } from '../../utils/sanityHelper';
import { sortByKey } from '../../utils/alphabetizer';

const StyledEngagePage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : 'rgb(7, 104, 176)'};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EngagePage = ({
  auth,
  userInfo,
  member,
  companyConfig,
  portfolio,
  images,
  mode,
  chosenPortfolioId,
  updateSessionKey,
  clearAll,
  setPortfolioId,
  setPortfolioMode,
  setPortfolioSessionId,
  RPMS,
  RPS,
  addSessionToSanity,
  lastPrepPortfolio,
  PIIBackgroundImageUrl,
  portfolioIntroImageUrl,
  Dash,
  setSessionMasterCollection,
  setGuestPassMasterCollection,
  SetGuestpassSessionMasterCollection,
  setUsersPool,
  setSelectedUser,
  setLoginInputSelected,
  setLoginInputSelectedCount,
  loginInputSelected,
  loginInputSelectedCount,
  Size
}) => {
  //Refer to app.js page for load priming

  const [dropdownOptions, setDropdownOptions] = useState([]);
  const firebase = useFirebase();
  const firestore = useFirestore();
  const history = useHistory();

  // set Session Master Collection
  //   useEffect(()=>{
  //     if (Dash.SessionMasterCollection === null || Dash.SessionMasterCollection === undefined) {
  //         return;
  //     }

  //     if (Dash.SessionMasterCollection.length === 0) {
  //         setSessionMasterCollection(userInfo, firestore);
  //     }

  // }, [Dash.SessionMasterCollection, setSessionMasterCollection, firestore, userInfo]);

  //   // set GuestPass Master Collection
  //   useEffect(() => {
  //     if (Dash.GuestpassMasterCollection === null) {
  //         return;
  //     }

  //     if (Dash.GuestpassMasterCollection.length === 0) {
  //         setGuestPassMasterCollection(userInfo, firestore);
  //     }

  // }, [Dash.GuestpassMasterCollection, setGuestPassMasterCollection, firestore, userInfo]);

  //  //set user pool for use in user select
  // useEffect(()=>{
  //     if (Dash.UserPool === null || Dash.UserPool === undefined) {
  //       return;
  //     }

  //     if (Dash.UserPool.length === 0) {
  //       setUsersPool(userInfo, firestore);
  //     }

  // }, [Dash.UserPool, setUsersPool, firestore, userInfo]);

  // //sets Selected User to default to the logged-in user
  // useEffect(()=>{
  //     if (userInfo === null || userInfo === undefined) {
  //       return;
  //     }

  //     setSelectedUser([userInfo[0]]);

  // }, [userInfo, setSelectedUser]);

  useEffect(() => {
    setSessionMasterCollection(false, firestore);
  }, [firestore, setSessionMasterCollection]);
  useEffect(() => {
    setGuestPassMasterCollection(false, firestore);
  }, [firestore, setGuestPassMasterCollection]);
  useEffect(() => {
    SetGuestpassSessionMasterCollection(false, firestore);
  }, [firestore, SetGuestpassSessionMasterCollection]);

  useEffect(() => {
    // Create dropdown content
    let options = [];
    portfolio.forEach((obj) => {
      if (userInfo[0].portfolio.includes(obj._id)) {
        options.push({ value: obj._id, label: obj.title });
      }
    });
    options = sortByKey(options, 'label');

    // options = options.sort((a,b)=>{
    //   let Ca = a.label.toUpperCase();
    //   let Cb = b.label.toUpperCase();
    //   if(Ca < Cb)
    //   return -1;
    //   else if(Ca > Cb)
    //   return 1;
    //   return 0;
    // })
    setDropdownOptions(options);
  }, [portfolio, userInfo]);

  const logout = () => {
    //////////onsole.log('LOGOUT');
    firebase.logout();
    // clear sessionkey in local storage and redux session key
    updateSessionKey('');
    clearAll();
  };

  // prep portfolio, create session
  const preparePortfolio = (formData) => {
    RPMS();
    lastPrepPortfolio(formData);
    //////onsole.log("!!FD!!",formData)
    createSession(formData);
  };

  // Create session, store to firestore
  const createSession = (newCustomerInfo) => {
    // console.log(
    //   '[web] EngagePage.js createSession newCustomerInfo',
    //   newCustomerInfo
    // );
    // check mode
    if (mode !== '') {
      // console.log(
      //   '[web] EngagePage.js createSession IF  mode !=; creator',
      //   member.name
      // );
      // console.log('[web] EngagePage.js member', member);
      const session = {
        creator: member.name, // member/user name from sanity
        customer_name:
          newCustomerInfo.firstName + ' ' + newCustomerInfo.lastName,
        customer_company: newCustomerInfo.company,
        page_visited: [],
        user_id: auth.uid,
        user_sanity_id: member._id,
        portfolio_id: chosenPortfolioId,
        session_date: firestore.Timestamp.now(),
        customer_email: newCustomerInfo.email,
        type: mode,
        company_id: userInfo[0].company_id,
        updated_at: firestore.Timestamp.now()
      };
      ////////////onsole.log("!!!SESSION!!",session)
      // create viewer log
      let viewerLog = [];
      let viewerTemplate = {
        _type: 'viewerLog'
      };

      // add main customer
      viewerLog.push({
        ...viewerTemplate,
        email: session.customer_email,
        name: session.customer_name,
        _key: Math.random().toString(36).substr(2, 7)
      });

      // add cc
      if (newCustomerInfo.ccEmails) {
        newCustomerInfo.ccEmails.forEach((email) => {
          viewerLog.push({
            ...viewerTemplate,
            email: email,
            _key: Math.random().toString(36).substr(2, 7)
          });
        });
      }
      // add viewer log to session.
      session['viewer_log'] = viewerLog;
      // firestore add
      firestore
        .collection('sessions')
        .add(session)
        .then((docRef) => {
          //////////onsole.log('Session create success');
          // set session_id in portfolioReducer
          setPortfolioSessionId(docRef.id);
          //add sessions to sanity with session_id created by firestore
          addSessionToSanity({
            ...session,
            session_id: docRef.id,
            session_type: mode === 'default' ? 'user' : 'training'
          });
          history.push('/portfolio');
        })
        .catch((error) => console.error('Error adding document: ' + error));
    } else {
      // // else in training so no need for session log
      // setPortfolioSessionId('');
      // history.push('/portfolio');

      // console.log(
      //   '[web] EngagePage.js createSession ELSE creator',
      //   member.name
      // );

      const session = {
        creator: member.name, // member/user name from sanity
        customer_name:
          newCustomerInfo.firstName + ' ' + newCustomerInfo.lastName,
        customer_company: newCustomerInfo.company
          ? newCustomerInfo.company
          : '',
        page_visited: [],
        user_id: auth.uid,
        user_sanity_id: member._id,
        portfolio_id: chosenPortfolioId,
        session_date: firestore.Timestamp.now(),
        customer_email: newCustomerInfo.email,
        type: 'training',
        company_id: userInfo[0].company_id,
        updated_at: firestore.Timestamp.now()
      };
      ////////////onsole.log("!!!SESSION!!",session)
      // create viewer log
      let viewerLog = [];
      let viewerTemplate = {
        _type: 'viewerLog'
      };

      // add main customer
      viewerLog.push({
        ...viewerTemplate,
        email: session.customer_email,
        name: session.customer_name,
        _key: Math.random().toString(36).substr(2, 7)
      });

      // add cc
      if (newCustomerInfo.ccEmails) {
        newCustomerInfo.ccEmails.forEach((email) => {
          viewerLog.push({
            ...viewerTemplate,
            email: email,
            _key: Math.random().toString(36).substr(2, 7)
          });
        });
      }
      // add viewer log to session.
      session['viewer_log'] = viewerLog;
      // firestore add
      firestore
        .collection('sessions')
        .add(session)
        .then((docRef) => {
          //////////onsole.log('Session create success');
          // set session_id in portfolioReducer
          setPortfolioSessionId(docRef.id);
          //add sessions to sanity with session_id created by firestore
          addSessionToSanity({
            ...session,
            session_id: docRef.id,
            session_type: mode === 'default' ? 'user' : 'training'
          });
          history.push('/portfolio');
        })
        .catch((error) => console.error('Error adding document: ' + error));
    }
  };

  let background;
  let backgroundPII;
  if (
    PIIBackgroundImageUrl === '' ||
    PIIBackgroundImageUrl === undefined ||
    PIIBackgroundImageUrl === null
  ) {
    backgroundPII = null;
  } else {
    backgroundPII = PIIBackgroundImageUrl;
  }

  let backgroundDropdown;
  if (
    companyConfig.background === '' ||
    companyConfig.background === undefined ||
    companyConfig.background === null
  ) {
    backgroundDropdown = null;
  } else {
    backgroundDropdown = urlFor(companyConfig.background.asset._ref)
      .auto('format')
      .width(1600)
      .url();
  }

  if (mode === '') {
    background = backgroundDropdown;
    //////////onsole.log('Currently in Portfolio Selection, assigning background...');
  } else if (mode === 'training' || mode === 'default') {
    background = backgroundPII;
    //////////onsole.log('Currently in PII Screen, assigning background...');
  } else {
    background = null;
  }

  //////////onsole.log('Background PII: ', backgroundPII);
  //////////onsole.log('Background Dropdown: ', backgroundDropdown);
  //////////onsole.log('BACKGROUND ASSIGNED: ', background);

  const SetTraining = (Seed) => {
    setPortfolioMode('training');
    let formData = {};
    let NArray = Seed.Name.split(' ');
    let E = Seed.Email;
    formData.email = E;
    if (NArray[0]) formData.firstName = NArray[0];
    else formData.firstName = 'FN';
    if (NArray[1]) formData.lastName = NArray[1];
    else formData.lastName = 'LN';
    preparePortfolio(formData);
  };
  // if(mode === "training"){
  //   preparePortfolio({})
  // }

  return (
    <StyledEngagePage
      backgroundColor={companyConfig.primary_color}
      style={{
        backgroundImage: `url(${background})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: `cover` // background === backgroundPII?`contain`:
      }}
      mode={mode}
    >
      {mode === '' && (
        <EngageChoiceBox
          SetTraining={SetTraining}
          dropdownOptions={dropdownOptions}
          setMode={setPortfolioMode}
          logout={logout}
          setPortfolioId={setPortfolioId}
          images={images}
          companyConfig={companyConfig}
          userInfo={userInfo}
          setLoginInputSelected={setLoginInputSelected}
          loginInputSelected={loginInputSelected}
          loginInputSelectedCount={loginInputSelectedCount}
          setLoginInputSelectedCount={setLoginInputSelectedCount}
          Size={Size}
        />
      )}
      {(mode === 'training' || mode === 'default') && ( //mode === 'training' ||
        <EngageCustomerBox
          RPS={RPS}
          setMode={setPortfolioMode}
          logout={logout}
          preparePortfolio={preparePortfolio}
          images={images}
          mode={mode}
          userInfo={userInfo}
          companyConfig={companyConfig}
          portfolioIntroImageUrl={portfolioIntroImageUrl}
          chosenPortfolio={portfolio.find(
            (obj) => obj._id === chosenPortfolioId
          )}
          setLoginInputSelected={setLoginInputSelected}
          loginInputSelected={loginInputSelected}
          loginInputSelectedCount={loginInputSelectedCount}
          setLoginInputSelectedCount={setLoginInputSelectedCount}
          Size={Size}
        />
      )}
    </StyledEngagePage>
  );
};

export default EngagePage;
