import React, { useState, useEffect } from 'react';
// import Button from '../../Button';
// import IMG from '../../../assets/images/MenuMask.png';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import FontUtil from '../../../utils/FontUtility';
const PortfolioBriefcase = ({
  sendClick,
  shareClick,
  companyConfig,
  chosenPortfolio,
  userInfo,
  showBriefcase,
  isGuestMode,
  liked,
  briefcaseContent,
  toggleBriefcase,
  removeFromBriefcase,
  clearBriefcase,
  customerInfo,
  setToolbarMenuVisibility,
  toolbarMenuVisible
}) => {
  const [isAblePortfolioGuestPass, setIsAblePortfolioGuestPass] =
    useState(false);
  useEffect(() => {
    if (userInfo && userInfo.length > 0) {
      // TODO: Why is this constant condition?
      // userInfo[0].is_able_to_create_guest_pass
      if (true) {
        setIsAblePortfolioGuestPass(true);
      }
    }
  }, [userInfo]);

  let PS = 0;
  briefcaseContent.forEach((element) => {
    if (!isNaN(parseInt(element.PagesSaved)))
      PS = PS + parseInt(element.PagesSaved);
    else PS = PS + 1;
  });
  return (
    <div
      style={{
        backgroundColor: chosenPortfolio?.primary_color
          ? `${chosenPortfolio.primary_color}BF`
          : '#3b3b3b59'
      }}
      className={`portfolio-briefcase-container ${
        showBriefcase ? 'active' : 'hidden'
      }`}
    >
      <div className="close-container">
        <CancelRoundedIcon
          onClick={() => {
            toggleBriefcase();
            setToolbarMenuVisibility(true);
          }}
          style={{
            fontSize: '3em',
            color: chosenPortfolio?.bulk_icon_override
              ? chosenPortfolio.bulk_icon_override
              : 'white',
            cursor: 'pointer'
          }}
        />
      </div>
      <div className="briefcase-body">
        <div className="briefcase-title-container">
          <p
            style={{
              color: chosenPortfolio?.text_color
                ? chosenPortfolio.text_color
                : 'white',
              fontFamily: FontUtil(
                chosenPortfolio.SystemText,
                null,
                "'Roboto Slab', serif"
              )
            }}
          >
            Your Briefcase
          </p>
        </div>
        <div
          className="briefcase-content-list"
          style={
            {
              // maskImage: `url(${IMG})`,
              // maskSize: '100% 100%',
              // maskRepeat: 'no-repeat',
              // WebkitMaskImage: `url(${IMG})`,
              // WebkitMaskRepeat: 'no-repeat',
              // WebkitMaskSize: '100% 100%'
            }
          }
        >
          {!isGuestMode
            ? briefcaseContent.map((content, index) => {
                return (
                  <div
                    className="briefcase-content-list-item"
                    key={`briefcase-content-${content.id}-${index}`}
                  >
                    <div
                      style={{
                        width: '80%',
                        flexDirection: 'column',
                        alignItems: 'flex-start'
                      }}
                    >
                      <p
                        style={{
                          fontFamily: FontUtil(chosenPortfolio.SystemText)
                        }}
                      >
                        {content.title}
                      </p>
                      <p
                        style={{
                          fontSize: '12px',
                          marginTop: '8px',
                          textDecoration: 'underline',
                          fontFamily: FontUtil(chosenPortfolio.SystemText)
                        }}
                      >
                        {`${content.content_type.toUpperCase()} ITEM`}
                      </p>
                    </div>
                    <div style={{ width: '20%' }}>
                      <CancelRoundedIcon
                        onClick={() => {
                          removeFromBriefcase(content.id);
                        }}
                        style={{
                          fontSize: '2em',
                          color: '#3b3b3b',
                          cursor: 'pointer'
                        }}
                      />
                    </div>
                  </div>
                );
              })
            : liked.map((content, index) => {
                return (
                  <div
                    className="briefcase-content-list-item"
                    key={`briefcase-content-${content.id}-${index}`}
                  >
                    <div
                      style={{
                        width: '80%',
                        flexDirection: 'column',
                        alignItems: 'flex-start'
                      }}
                    >
                      <p
                        style={{
                          fontFamily: FontUtil(chosenPortfolio.SystemText)
                        }}
                      >
                        {content.title}
                      </p>
                    </div>
                    <div style={{ width: '20%' }}>
                      <CancelRoundedIcon
                        onClick={() => {
                          removeFromBriefcase(content.id);
                        }}
                        style={{
                          fontSize: '2em',
                          color: '#3b3b3b',
                          cursor: 'pointer'
                        }}
                      />
                    </div>
                  </div>
                );
              })}
        </div>

        <div className="pages-count">
          <p
            style={{
              color: chosenPortfolio?.text_color
                ? chosenPortfolio.text_color
                : 'white',
              fontFamily: FontUtil(chosenPortfolio.SystemText)
            }}
          >
            {!isGuestMode ? briefcaseContent.length : liked.length} Total Items
          </p>
          <p
            style={{
              color: chosenPortfolio?.text_color
                ? chosenPortfolio.text_color
                : 'white',
              fontFamily: FontUtil(chosenPortfolio.SystemText)
            }}
          >
            {PS} From Print
          </p>
        </div>

        <div className="briefcase-action-button-container">
          {isGuestMode && (
            <div
              className="briefcase-send-button"
              onClick={() => {
                shareClick(true);
                toggleBriefcase();
              }}
              style={{
                backgroundColor: chosenPortfolio?.secondary_color
                  ? chosenPortfolio.secondary_color
                  : '#449FF5'
              }}
            >
              <p
                style={{
                  color: chosenPortfolio?.text_color
                    ? chosenPortfolio.text_color
                    : 'white',
                  fontFamily: FontUtil(chosenPortfolio.SystemText)
                }}
              >
                Share Guest Pass
              </p>
            </div>
          )}
          {isAblePortfolioGuestPass && (
            <div
              className="briefcase-send-button"
              onClick={() => {
                sendClick(true);
                toggleBriefcase();
                setToolbarMenuVisibility(true);
              }}
              style={{
                backgroundColor: chosenPortfolio?.secondary_color
                  ? chosenPortfolio.secondary_color
                  : '#449FF5'
              }}
            >
              <p
                style={{
                  color: chosenPortfolio?.text_color
                    ? chosenPortfolio.text_color
                    : 'white',
                  fontFamily: FontUtil(chosenPortfolio.SystemText)
                }}
              >
                Send Guest Pass
              </p>
            </div>
          )}
          {briefcaseContent.length !== 0 && (
            <>
              <div
                className="briefcase-send-button"
                onClick={() => {
                  sendClick(false);
                  clearBriefcase();
                  toggleBriefcase();
                  setToolbarMenuVisibility(true);
                }}
                style={{
                  backgroundColor: chosenPortfolio?.secondary_color
                    ? chosenPortfolio.secondary_color
                    : '#449FF5'
                }}
              >
                <p
                  style={{
                    color: chosenPortfolio?.text_color
                      ? chosenPortfolio.text_color
                      : 'white',
                    fontFamily: FontUtil(chosenPortfolio.SystemText)
                  }}
                >
                  Send Briefcase
                </p>
              </div>
              <div
                className="briefcase-send-button"
                onClick={clearBriefcase}
                style={{
                  backgroundColor: chosenPortfolio?.secondary_color
                    ? chosenPortfolio.secondary_color
                    : '#449FF5'
                }}
              >
                <p
                  style={{
                    color: chosenPortfolio?.text_color
                      ? chosenPortfolio.text_color
                      : 'white',
                    fontFamily: FontUtil(chosenPortfolio.SystemText)
                  }}
                >
                  Clear All
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PortfolioBriefcase;
