import React, { useState, useEffect, useRef } from 'react';
import BlockContent from '@sanity/block-content-to-react';
import serializers from '../../../../utils/scriptSerializers';
import { useStore } from 'react-redux';
// import { useHistory } from 'react-router-dom';
// import { useFirebase, useFirestore } from 'react-redux-firebase';
// import ContentParent from '../../../../components/_Portfolio/PortfolioContentParentTemplate9'
//import SingleItemDisplay from '../SingleItemDisplay'
//import ContentParent from '../../../../components/_Portfolio/PortfolioContentParentTemplate9'
import { urlFor } from '../../../../utils/sanityHelper';
import PDFViewer2 from '../../../../components/FullWidthPDFViewer';
import BASlider from '../../../../components/BeforeAfterSlider';
import FontUtil from '../../../../utils/FontUtility';
import ColorUtil from '../../../../utils/ColorUtility';
// import GalThumb from '../../../../components/_PortfolioContentParent3/GalleryThumbsContent'
// import ToolCross from './Shape.png'
// import exit from './Exclude.png'
// import Scrollindicator from './icon-Scroll-Drag-Right.png'
// //icon-Scroll-Drag-Right.png
// import Icons from '../../../../containers/Icons';
import styled from 'styled-components';
// import leftArrow from './leftArrow.png'
// import rightArrow from './rightArrow.png'
// import CircleBase from './Group 39-2.png'
// import Arrow from'./LeftArrow3.png'
// import Arrow1 from'./RightArrow4.png'
// import Arrow from'./LeftArrow2.png'
import ArrowIcon from '../ArrowSVG';

const HoverDivsTMG = styled.div`
  transition: transfrom, 0.2s;
  border-radius: 10px;
  overflow: hidden;
  color: #333333;
  :hover {
    transform: scale(1.122);
    z-index: 2;
    .Second {
      background-color: rgba(238, 238, 238, 1);
    }
  }
  .Second {
    p {
      color: #333333;
      font-weight: 500;
      font-size: 20px;
      line-height: 116.4%;
      letter-spacing: -0.14px;
    }
    color: #333333;
    background-color: rgba(238, 238, 238, 0.89);
  }
`;

const TMG = ({
  createSetCurrentContent,
  chosenContentPageId,
  pages,
  // pages2,
  chosenPortfolio,
  // images,
  videos,
  pdfs,
  // back,
  isGuestMode,
  sessionId,
  liked,
  // setBooleanNewItems,
  setLiked,
  currentGuestContentHelper,
  // addToBriefcase,
  // showBriefcase,
  currentContent,
  // endPortfolio,
  // toggleNotepad,
  // toggleBriefcase,
  // clearAll,
  // setPortfolioCustomerInfo,

  // updateSessionInSanity,
  // SET_ANNOTATOR,
  Background,

  // showNotepad,

  // mode,
  Single,

  briefcaseContent,
  // isModalOpen,

  // menuHide,

  userInfo
  // Size,
  // booleanNewItems,
  // ANNOTATOR2,
}) => {
  const [Color, SetColor] = React.useState(undefined);
  const store = useStore();
  const Trial = store.getState();
  //onsole.log("Store",store.getState().portfolioReducer.chosenPortfolio.bulk_icon_override)
  useEffect(() => {
    SetColor(
      store.getState().portfolioReducer.chosenPortfolio.bulk_icon_override
    );
  }, [store, Trial.portfolioReducer.chosenPortfolio.bulk_icon_override]);
  //Flag Font Family
  let OVTINT = undefined;
  if (
    chosenPortfolio.overlay_tint_color &&
    chosenPortfolio.overlay_tint_color !== '#' &&
    chosenPortfolio.overlay_tint_color !== undefined
  ) {
    OVTINT = chosenPortfolio.overlay_tint_color + '40';
  }
  let SMColor = undefined;
  if (
    chosenPortfolio.submenu_color &&
    chosenPortfolio.submenu_color !== '#' &&
    chosenPortfolio.submenu_color !== undefined
  )
    SMColor = chosenPortfolio.submenu_color;
  if (
    pages[chosenContentPageId]?.submenu_color &&
    pages[chosenContentPageId]?.submenu_color !== '#' &&
    pages[chosenContentPageId]?.submenu_color !== undefined
  )
    //pages[chosenContentPageId]
    SMColor = pages[chosenContentPageId]?.submenu_color;
  // const [isAddable, setIsAddable] = useState(true); // bool to keep track of if it is addable to briefcase
  // const [isAuthToAdd, setIsAuthToAdd] = useState(false); // is this user authorized to add item?
  // const [isAuthBriefcaseShow, setIsAuthBriefcaseShow] = useState(false); // is this user authorized to see briefcase?
  //const [isLiked2, setIsLiked2] = useState(null); // is this user authorized to see briefcase?
  //const [RightPoint,SetRightPoint] = useState("150px");
  const ScaleFactor = 0.2;
  const OpFactor = 0.4;
  //const ScrollContainer = useRef();
  // const history = useHistory();
  //const firestore = useFirestore();
  // const firebase = useFirebase();
  //Landing Area

  // get userinfo auth for viewing sending briefcase
  // useEffect(() => {
  //   if (userInfo && userInfo.length > 0) {
  //     // check if user is able to create guest pass
  //     if (userInfo[0].is_able_to_create_briefcase) {
  //       // user is able to create normal guest pass, aka able to add item to briefcase
  //       setIsAuthToAdd(true);
  //     }
  //     // check if user is able create portfolio level guest pass
  //     if (userInfo[0].is_able_to_create_guest_pass) {
  //       // user is able to create normal guest pass, aka able to add item to briefcase
  //       setIsAuthBriefcaseShow(true);
  //     }
  //   }
  // }, [userInfo]);

  // useEffect(() => {
  //   // check if briefcase contains current content user is looking at
  //   if (
  //     (Object.entries(currentContent).length !== 0 &&
  //       briefcaseContent.filter((e) => e.id === currentContent.id).length >
  //         0) ||
  //     currentContent.page_id === `splashkey-${chosenPortfolio._id}`
  //   ) {
  //     setIsAddable(false);
  //   } else {
  //     setIsAddable(true);
  //   }
  // }, [currentContent, briefcaseContent, chosenPortfolio]);

  // const updateFavorite = (op) => {
  //   // do not log if mode is not guest or no sessionId
  //   if (!isGuestMode || sessionId === '') {
  //     return null;
  //   }

  //   // copy liked from redux store
  //   let likedArrCopy = [...liked];
  //   //////////onsole.log("!!CC!!",currentContent)
  //   let id = currentGuestContentHelper.id;
  //   let content = currentContent;
  //   // check if currentGuestContentHelper id is undefined
  //   if (!id) {
  //     //////////onsole.log('like in full portfolio guest-pass view...');
  //     id = currentContent.id;
  //     // if undefined it means that guest is in full portfolio view and not in briefcase guest view
  //   } else {
  //     // user in briefcase guest view
  //     //////////onsole.log('like in Briefcase guest view...');
  //     // set content from currentGuestContentHelper, if not splash
  //     if (id !== `splashkey-${chosenPortfolio._id}`) {
  //       content = currentGuestContentHelper;
  //     }
  //   }
  //   let content2 = {...content, time_liked:firestore.Timestamp.now()}
  //   if(content2.PagesSaved === undefined){
  //     content2.PagesSaved = 1;
  //   }
  //   // if op is add then append currentContent obj to likedArrCopy,
  //   if (op === 'add') {
  //     likedArrCopy.push(content2);
  //   } else {
  //     // if not filter out
  //     likedArrCopy = likedArrCopy.filter((obj) => obj.id !== id);
  //   }
  //   //////////onsole.log("LIKED: ", likedArrCopy);
  //   setIsLiked2(likedArrCopy)
  //   // replace the liked array
  //   firestore
  //     .collection('sessions')
  //     .doc(sessionId)
  //     .update({
  //       liked: likedArrCopy
  //     })
  //     .then(() => {
  //       // replace liked in store
  //       setLiked(likedArrCopy);
  //     })
  //     .catch((error) => console.error('ERROR: ', error));
  // };

  // check if current page is liked already or not
  // const isLiked = () => {
  //   let currentGuestContentId = currentGuestContentHelper.id;
  //   // check if currentGuestContentHelper id is undefined
  //   if (!currentGuestContentId) {
  //     //////////onsole.log('Liked check in full portfolio guest-pass view...');
  //     // check if liked contains currentContent id
  //     return liked.some((obj) => obj.id === currentContent.id);
  //   } else {
  //     // user in briefcase guest view
  //     //////////onsole.log('Liked check in Briefcase guest view...');
  //     if (currentGuestContentId === `splashkey-${chosenPortfolio._id}`) {
  //       // check if liked contains currentContent id for splash
  //       return liked.some((obj) => obj.id === currentContent.id);
  //     } else {
  //       // check if liked contains currentGuestContentId
  //       return liked.some((obj) => obj.id === currentGuestContentId);
  //     }
  //   }
  // };

  ////////onsole.log("!!!!TESTpages!!!!",pages);
  const [contents, setContents] = useState(pages[chosenContentPageId]?.content);
  useEffect(() => {
    setContents(pages[chosenContentPageId]?.content);
  }, [pages, chosenContentPageId]);
  //const SubRef = React.useRef();
  const prepareCurrentGalleryContent = (index) => {
    let obj = {
      type: 'gallery',
      index: index,
      id: chosenContentPageId
    };
    createSetCurrentContent(obj);
  };
  const renderSingleThumb = (type, galleryNum, isSelected) => {
    switch (type) {
      case 'galleryImage': {
        let image = contents[galleryNum].image;
        return (
          <img
            className="portfolio-single-image"
            src={urlFor(image).auto('format').width(500).url()} // auto converts to webp if browser supports it
            alt="mainImage"
            type="galleryImage"
            style={{ height: '100%', width: '100%', objectFit: 'contain' }}
            // style={{
            //   opacity: chosenPortfolio && chosenPortfolio.theme === 'theme2' ? opacityBack : 1,
            //   width: imageWidth,
            //   height: imageHeight
            // }}
          />
        );
      }
      case 'galleryVideo': {
        let video = videos[contents[galleryNum].video.asset._ref];
        //let videoSettings = contents[galleryNum];
        return (
          <img
            style={{ height: '100%', width: '100%', objectFit: 'contain' }}
            src={`https://image.mux.com/${
              video.playbackId
            }/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=${
              video.thumbTime !== 0 ? video.thumbTime : 1
            }`}
            alt="poster"
          />
          // <SanityMuxPlayer
          //   assetDocument={video}
          //   autoload={true}
          //   autoplay={videoSettings.autoplay}
          //   loop={videoSettings.loop}
          //   muted={false}
          //   showControls={true}
          //   style={{}}
          //   posterurl={`https://image.mux.com/${video.playbackId}/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=1`}
          //   type="galleryVideo"
          //   isSelected={isSelected}
          // />
        );
      }
      case 'galleryPdf': {
        let url2 = pdfs[contents[galleryNum].file.asset._ref].url;
        let thumbNailRef2 = contents[galleryNum].thumbnail.asset._ref;
        let thumbNailUrl2 = urlFor(thumbNailRef2)
          .auto('format')
          .width(500)
          .url();
        return (
          <PDFViewer2
            url={url2}
            isThumbnail={true}
            type="galleryFullWidthPdf"
            thumbnailUrl={thumbNailUrl2}
          />
        );
      }
      case 'galleryBASlider': {
        let Aimage = contents[galleryNum].Aimage;
        let Bimage = contents[galleryNum].Bimage;
        let LabelA = contents[galleryNum].AimageL;
        let LabelB = contents[galleryNum].BimageL;
        let Vert = contents[galleryNum].Vert;
        let Color = contents[galleryNum].SlideColor;
        // //////onsole.log('!!!!BA!!!!', contents[galleryNum])
        return (
          <BASlider
            Thumb
            LabelB={LabelB}
            LabelA={LabelA}
            Color={Color}
            Vert={Vert}
            ImageA={Aimage}
            ImageB={Bimage}
          />
        );
      }
      case 'galleryFullWidthPdf': {
        let url3 = pdfs[contents[galleryNum].file.asset._ref].url;
        let thumbNailRef3 = contents[galleryNum].thumbnail.asset._ref;
        let thumbNailUrl3 = urlFor(thumbNailRef3)
          .auto('format')
          .width(500)
          .url();
        return (
          <PDFViewer2
            url={url3}
            isThumbnail={true}
            type="galleryFullWidthPdf"
            thumbnailUrl={thumbNailUrl3}
          />
        );
      }
      default:
        return <div />;
    }
  };
  const renderSingleImageExport = (type, galleryNum, contents) => {
    switch (type) {
      case 'galleryImage': {
        let image = contents[galleryNum].image;
        return (
          <img
            className="portfolio-single-image"
            src={urlFor(image).auto('format').url()} // auto converts to webp if browser supports it
            alt="mainImage"
            type="galleryImage"
            style={{
              height: '100%',
              width: '100%',
              objectFit: 'cover'
              //   opacity: chosenPortfolio && chosenPortfolio.theme === 'theme2' ? opacityBack : 1,
              //   width: imageWidth,
              //   height: imageHeight
            }}
          />
        );
      }
      case 'galleryVideo': {
        let video = videos[contents[galleryNum].video.asset._ref];
        //let videoSettings = contents[galleryNum];
        return (
          <img
            style={{ height: '100%', width: '100%', objectFit: 'contain' }}
            src={`https://image.mux.com/${
              video.playbackId
            }/thumbnail.png?fit_mode=preserve&amp;time=${
              video.thumbTime !== 0 ? video.thumbTime : 1
            }`}
            alt="poster"
          />
          // <SanityMuxPlayer
          //   assetDocument={video}
          //   autoload={true}
          //   autoplay={videoSettings.autoplay}
          //   loop={videoSettings.loop}
          //   muted={false}
          //   showControls={true}
          //   style={{}}
          //   posterurl={`https://image.mux.com/${video.playbackId}/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=1`}
          //   type="galleryVideo"
          //   isSelected={isSelected}
          // />
        );
      }
      case 'galleryPdf': {
        let url2 = pdfs[contents[galleryNum].file.asset._ref].url;
        let thumbNailRef2 = contents[galleryNum].thumbnail.asset._ref;
        let thumbNailUrl2 = urlFor(thumbNailRef2).auto('format').url();
        return (
          <PDFViewer2
            url={url2}
            isThumbnail={true}
            type="galleryFullWidthPdf"
            thumbnailUrl={thumbNailUrl2}
          />
        );
      }
      case 'galleryBASlider': {
        let Aimage = contents[galleryNum].Aimage;
        let Bimage = contents[galleryNum].Bimage;
        let LabelA = contents[galleryNum].AimageL;
        let LabelB = contents[galleryNum].BimageL;
        let Vert = contents[galleryNum].Vert;
        let Color = contents[galleryNum].SlideColor;
        ////////onsole.log('!!!!BA!!!!', contents[galleryNum])
        return (
          <BASlider
            Thumb
            LabelB={LabelB}
            LabelA={LabelA}
            Color={Color}
            Vert={Vert}
            ImageA={Aimage}
            ImageB={Bimage}
          />
        );
      }
      case 'galleryFullWidthPdf': {
        let url3 = pdfs[contents[galleryNum].file.asset._ref].url;
        let thumbNailRef3 = contents[galleryNum].thumbnail.asset._ref;
        let thumbNailUrl3 = urlFor(thumbNailRef3).auto('format').url();
        return (
          <PDFViewer2
            url={url3}
            isThumbnail={true}
            type="galleryFullWidthPdf"
            thumbnailUrl={thumbNailUrl3}
          />
        );
        //     case 'htmlContent':
        //         return(
        //             <div className="html-block-content">
        //   <BlockContent
        //     blocks={blockContent(contents)}
        //     serializers={serializers}
        //   />
        // </div>
        //         )
      }
      case 'website':
        return (
          <div
            style={{ height: '100%', width: '100%' }}
            className="iframe-container"
          >
            <iframe
              title="iframe"
              allowFullScreen
              src={contents}
              style={{
                backgroundColor: 'white',
                height: '100%',
                width: '100%'
              }}
            ></iframe>
          </div>
        );
      //   /src={page.url}
      default:
        return <div />;
    }
  };
  const ICalc = () => {
    let x = -1;
    let y = 0;
    if (
      ScrollContainer.current?.scrollLeft %
        ScrollContainer.current?.firstChild?.clientWidth ===
      0
    ) {
      do {
        x++;
        y = ScrollContainer.current?.firstChild?.clientWidth * x;
      } while (y < ScrollContainer.current?.scrollLeft);
      return x;
    }
    if (
      ScrollContainer.current?.scrollLeft %
        ScrollContainer.current?.firstChild?.clientWidth >=
      ScrollContainer.current?.firstChild?.clientWidth / 2
    ) {
      do {
        x++;
        y = ScrollContainer.current?.firstChild?.clientWidth * x;
      } while (y < ScrollContainer.current?.scrollLeft);
      return x;
    } else {
      do {
        x++;
        y = ScrollContainer.current?.firstChild?.clientWidth * x;
      } while (y < ScrollContainer.current?.scrollLeft);
      return --x;
    }
  };
  const contentDataExport = (ID) => {
    //let tabData = {};
    // if (
    //   isGuestMode &&
    //   ID !== 0 &&
    //   !isEmpty(currentContentHelper) &&
    //   pages[ID]._type === 'gallery' &&
    //   pages[ID]._key === currentContentHelper.page_id &&
    //   currentContentHelper.is_gallery_item
    // ) {
    //   //////////onsole.log('MODIFY FOR GALLERY CONTENT');
    //   // create obj to only display gallery item chosen by guest
    //   let obj =
    //     pages[ID].content[currentContentHelper.gallery_index];
    //   tabData = [{ ...obj }];
    //   //////////onsole.log(pages[ID]);
    //   //////////onsole.log('!!!!TAB DATA!!!! ', tabData);
    //   return tabData;
    // }
    //////////onsole.log('!!!!CHOSEN CONTENT PAGE ID ', ID);
    return pages[ID].content;
  };
  //const [Pos,SetPos] = React.useState(0);
  // const KillEve = (eve)=>{
  //     //eve.preventDefault();
  //     //eve.stopPropagation();
  //     return;
  //   }
  // const [EL,SetEL] = React.useState();
  // React.useEffect(()=>{
  //     let temp = document.getElementById("TerminalMenuATemplate9");
  //     try{
  //     EL.current.removeEventListener('wheel',KillEve,{passive:false, capture:false});
  //     }
  //     catch{}
  //     temp.addEventListener('wheel',KillEve,{passive:false, capture:false});
  //     SetEL(temp);
  //     return ()=>{
  //         temp.removeEventListener('wheel',KillEve,{passive:false, capture:false});
  //         try{
  //         EL.current.removeEventListener('wheel',KillEve,{passive:false, capture:false});
  //     }
  //     catch{}
  // }
  // },[])
  // let temptest = pages[chosenContentPageId]?.content;
  // //////onsole.log("TT",temptest);
  // temptest = temptest.push(temptest[0]);
  // temptest = temptest.push(temptest[0]);
  // temptest = temptest.push(temptest[0]);
  // temptest = temptest.push(temptest[0]);
  // //////onsole.log("TMG",createSetCurrentContent,pages[chosenContentPageId])

  const ScrollContainer = useRef();
  const [scrollLeft, SetScrollLeft] = useState(0);
  // useEffect(()=>{
  //   //////onsole.log("ScrollContainer.current?.scrollLeft, Parent",ScrollContainer.current?.scrollLeft)
  // },[ScrollContainer.current?.scrollLeft])
  // useEffect(()=>{
  //   //////onsole.log("ScrollContainer.current?.scrollLeft, Parent",scrollLeft.current)
  // },[scrollLeft])
  let foundPage = pages[chosenContentPageId];
  let htmlThumb =
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    foundPage.content
      ? foundPage.content.find((piece) => piece._type === 'imageLinks')
      : null;
  //onsole.log("pages[chosenContentPageId]",pages[chosenContentPageId])
  const [DesHeadSize] = useState(
    pages[chosenContentPageId]?.StoryBookObjects?.descriptionHeaderFontsize
      ? pages[chosenContentPageId]?.StoryBookObjects
          ?.descriptionHeaderFontsize + 'px'
      : '56px'
  );
  const [DesHeadSizeT] = useState(
    pages[chosenContentPageId]?.StoryBookObjects?.descriptionHeaderFontsize
      ? pages[chosenContentPageId]?.StoryBookObjects
          ?.descriptionHeaderFontsize *
          (36 / 56) +
          'px'
      : '36px'
  );
  const [DesSize] = useState(
    pages[chosenContentPageId]?.StoryBookObjects?.descriptionFontsize
      ? pages[chosenContentPageId]?.StoryBookObjects?.descriptionFontsize + 'px'
      : '20px'
  );
  const [DesSizeT] = useState(
    pages[chosenContentPageId]?.StoryBookObjects?.descriptionFontsize
      ? pages[chosenContentPageId]?.StoryBookObjects?.descriptionFontsize *
          (20 / 20) +
          'px'
      : '20px'
  );
  return (
    <>
      {/* <div className="ScrollIndicator" style={{maskImage:`url(${Scrollindicator})`,WebkitMaskImage:`url(${Scrollindicator})`, maskSize:"100% 100%", WebkitMaskSize:'100% 100%', }}></div> */}
      <div
        className="SubmenuItemBackground"
        style={{ backgroundColor: '#333' }}
      ></div>
      <div className="SubmenuItemBackground">
        {/* style={{background:"white"}} */}
        {/* style={{position:'absolute', bottom:"0px", width:"calc(100% * (1920 - 1159)/1920", height:"calc(100%)", right:'0px'}} */}
        {/* CONTENT HERE TO BE DETERMINED */}
        {pages[Background.referenceId]._type !== 'gallery'
          ? pages[Background.referenceId]._type !== 'website'
            ? renderSingleImageExport(
                pages[Background.referenceId]._type,
                0,
                contentDataExport(Background.referenceId)
              )
            : renderSingleImageExport(
                pages[Background.referenceId]._type,
                0,
                pages[Background.referenceId].url
              )
          : // //////onsole.log("WEBSITE")
            //renderSingleImageExport(pages[Background.referenceId]._type,0,contentDataExport(chosenContentPageId))
            renderSingleImageExport(
              pages[Background.referenceId].content[0]._type,
              0,
              contentDataExport(Background.referenceId)
            )}
        {/* {//////onsole.log(pages[chosenContentPageId]?._type)} */}
        <div
          style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            background: OVTINT
          }}
        ></div>
      </div>
      <div
        className="SubmenuItemMenuBackgroundTERMC"
        style={{ background: SMColor }}
      >
        {/* style={{ position:'absolute', height:"100%", width:"calc(100% * 1159/1920)",background: "radial-gradient(80.35% 80.35% at 9.45% 19.65%, #3690E4 0%, #166DC0 100%)",
boxShadow:"30px 4px 54px rgba(0, 0, 0, 0.25)",}} */}
        {/* {window.innerWidth >450 && <></>} */}
        <p
          className="SubtitleHeader"
          style={{
            color: ColorUtil(
              pages[chosenContentPageId].text_color,
              chosenPortfolio.text_color,
              'black'
            ),
            fontFamily: FontUtil(
              pages[chosenContentPageId]?.SubtitleText,
              chosenPortfolio.ContentText
            )
          }}
        >
          {pages[chosenContentPageId]?.subtitle}
        </p>
        <p
          className={`OverGhost ${pages[chosenContentPageId]?.StoryBookObjects?.oversizedTextSize}`}
          style={{
            color: pages[chosenContentPageId]?.StoryBookObjects
              ?.oversizedTextColor
              ? pages[chosenContentPageId]?.StoryBookObjects?.oversizedTextColor
              : undefined,
            fontFamily: FontUtil(
              pages[chosenContentPageId]?.StoryBookObjects?.overghostFont,
              chosenPortfolio.ContentText
            )
          }}
        >
          {pages[chosenContentPageId]?.StoryBookObjects?.oversizedText}
        </p>
        {/* <p>{Element.title}</p> TitleSegment */}
        {/* backgroundColor:"white", */}
        <div
          className="SubpageBlockContent"
          onWheel={(eve) => {
            eve.preventDefault();
            eve.stopPropagation();
          }}
          style={{
            width: '100vw',
            position: 'absolute',
            top: 'calc(100% * 159/967)',
            height: 'calc(100% * 558/967)',
            backgroundColor: ColorUtil(
              pages[chosenContentPageId]?.submenu_center_third_color,
              chosenPortfolio.Submenu_center_third_color
            )
          }}
        >
          <div
            className="ColorBar"
            style={{
              backgroundColor: chosenPortfolio.accent_color
                ? chosenPortfolio.accent_color
                : ''
            }}
          />
          <div className="TMGCTextRoot" style={{}}>
            <div
              ref={ScrollContainer}
              onScroll={() => {
                ////////onsole.log("SCEVENT")
                SetScrollLeft(ScrollContainer.current?.scrollLeft);
              }}
              className="TERMCScroll"
              style={{
                height: '100%',
                width: '100%',
                overflowX: 'scroll',
                overflowY: 'hidden'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '100%',
                  alignItems: 'center'
                }}
              >
                {
                  //ImageGallery
                  pages[chosenContentPageId]?._type === 'gallery' ? (
                    pages[chosenContentPageId]?.content.map((img, i) => {
                      return (
                        <TMGOBJECT
                          key={`k` + i}
                          chosenPortfolio={chosenPortfolio}
                          scrollLeft={scrollLeft}
                          htmlThumb={htmlThumb}
                          chosenContentPageId={chosenContentPageId}
                          pages={pages}
                          renderSingleThumb={renderSingleThumb}
                          prepareCurrentGalleryContent={
                            prepareCurrentGalleryContent
                          }
                          Single={Single}
                          ScrollContainer={ScrollContainer}
                          ScaleFactor={ScaleFactor}
                          img={img}
                          i={i}
                          OpFactor={OpFactor}
                          currentContent={currentContent}
                          createSetCurrentContent={createSetCurrentContent}
                        />
                      );
                    })
                  ) : (
                    <TMGOBJECT
                      chosenPortfolio={chosenPortfolio}
                      scrollLeft={scrollLeft}
                      htmlThumb={htmlThumb}
                      chosenContentPageId={chosenContentPageId}
                      pages={pages}
                      renderSingleThumb={renderSingleThumb}
                      prepareCurrentGalleryContent={
                        prepareCurrentGalleryContent
                      }
                      Single={Single}
                      ScrollContainer={ScrollContainer}
                      ScaleFactor={ScaleFactor}
                      img={null}
                      i={0}
                      OpFactor={OpFactor}
                      currentContent={currentContent}
                      createSetCurrentContent={createSetCurrentContent}
                    />
                  )
                }
                {/* <div style={{height:"10px", width:"calc(100vw - 450px)", flexShrink:"0"}}></div>    */}
              </div>
            </div>
            <div className="TERMCText" style={{}}>
              <div className="TERMCTextContainer" style={{}}>
                <div
                  className="SubpageBlockContentWrapper header TMG"
                  style={{
                    fontSize:
                      window.innerWidth < 1367 ? DesHeadSizeT : DesHeadSize,
                    fontFamily: FontUtil(
                      pages[chosenContentPageId]?.StoryBookObjects
                        ?.descriptionHeaderFont,
                      chosenPortfolio.ContentText
                    )
                  }}
                >
                  {pages[chosenContentPageId]?.descriptionHeader && (
                    <BlockContent
                      blocks={blockContent(
                        pages[chosenContentPageId]?.descriptionHeader
                      )}
                      serializers={serializers}
                    />
                  )}
                </div>
                {/* FLAG FONT FAMILY */}
                <div
                  className="SubpageBlockContentWrapper description"
                  style={{
                    fontSize: window.innerWidth < 1367 ? DesSizeT : DesSize,
                    fontFamily: FontUtil(
                      pages[chosenContentPageId]?.StoryBookObjects
                        ?.descriptionFont,
                      chosenPortfolio.ContentText
                    )
                  }}
                >
                  {pages[chosenContentPageId]?.description && (
                    <BlockContent
                      blocks={blockContent(
                        pages[chosenContentPageId]?.description
                      )}
                      serializers={serializers}
                    />
                  )}
                </div>
                {/* <p className="Title" style={{ marginBlock:"0px", width:"668px", fontWeight:"900", fontSize:"36px", lineHeight:"116.4%", letterSpacing:"-0.14px", textTransform:"uppercase", }}>{}</p> */}
                {/* pages[chosenContentPageId]?.descriptionHeader */}
                {/* <p className="Body" style={{width:"668px",fontWeight:"900", fontSize:"20px", lineHeight:"125%", letterSpacing:"-0.073px", }}>{}</p> */}
                {/* pages[chosenContentPageId]?.description */}
              </div>
              <div className="TERMCButtons" style={{}}>
                <ArrowIcon
                  className="LeftArrow"
                  Fill={Color ? Color : 'black'}
                  Rotation={0}
                  onClick={() => {
                    ScrollContainer.current.scrollBy({
                      top: 0,
                      left: -428,
                      behavior: 'smooth'
                    });
                  }}
                ></ArrowIcon>
                {/* <svg className="LeftArrow" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" onClick={()=>{ScrollContainer.current.scrollBy({top:0,left:-428,behavior:"smooth"})}} width={100} height={100}>
        <circle cx={50} cy={50} r={50} fill={Color?Color:'black'}></circle>
        <polyline points='57,70.5 36,49 57,27.5' fill='transparent' stroke='white' strokeWidth={6} ></polyline>
        
      </svg> */}
                {/* <div alt="Left Arrow" className="LeftArrow" onClick={()=>{ScrollContainer.current.scrollBy({top:0,left:-428,behavior:"smooth"})}}  src={leftArrow} style={{mask:`url(${CircleBase})` ,maskSize:"100% 100%", maskRepeat:"no-repeat", WebkitMask:`url(${CircleBase})`,WebkitMaskRepeat:'no-repeat', WebkitMaskSize:"100% 100%",  background:Color?Color:'black',color:Color?Color:'black', opacity:'1', display:"flex",justifyContent:"center",alignContent:'center'}} >
        <div alt="Left Arrow" onClick={()=>{ScrollContainer.current.scrollBy({top:0,left:-428,behavior:"smooth"})}}  src={Arrow} style={{mask:`url(${Arrow})` ,maskSize:"100% 100%", maskRepeat:"no-repeat", WebkitMask:`url(${Arrow})`,WebkitMaskRepeat:'no-repeat', WebkitMaskSize:"100% 100%",  background:'white',color:'white', opacity:'1', height:'100%', width:"100%"}} />
          </div> */}
                {/* <img alt="Left Arrow" className="LeftArrow" onClick={()=>{ScrollContainer.current.scrollBy({top:0,left:-428,behavior:"smooth"})}} style={{}} src={leftArrow}></img> */}
                <div className="Divider" style={{}}></div>
                <ArrowIcon
                  className="RightArrow"
                  Fill={Color ? Color : 'black'}
                  Rotation={180}
                  onClick={() => {
                    ScrollContainer.current.scrollBy({
                      top: 0,
                      left: 428,
                      behavior: 'smooth'
                    });
                  }}
                ></ArrowIcon>
                {/* <svg className="RightArrow" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" onClick={()=>{ScrollContainer.current.scrollBy({top:0,left:428,behavior:"smooth"})}} width={100} height={100} style={{transform:'rotate(180deg)'}}>
        <circle cx={50} cy={50} r={50} fill={Color?Color:'black'}></circle>
        <polyline points='57,70.5 36,49 57,27.5' fill='transparent' stroke='white' strokeWidth={6} ></polyline>
        /*  filter='blur(2px)'
      </svg> */}
                {/* <div alt="Right Arrow" className="RightArrow" onClick={()=>{ScrollContainer.current.scrollBy({top:0,left:428,behavior:"smooth"})}}  src={Arrow1} style={{mask:`url(${CircleBase})` ,maskSize:"100% 100%", maskRepeat:"no-repeat", WebkitMask:`url(${CircleBase})`,WebkitMaskRepeat:'no-repeat', WebkitMaskSize:"100% 100%",  background:Color?Color:'black',color:Color?Color:'black', opacity:'1', display:"flex",justifyContent:"center",alignContent:'center'}} >
        <div alt="Right Arrow" onClick={()=>{ScrollContainer.current.scrollBy({top:0,left:-428,behavior:"smooth"})}}  src={Arrow1} style={{mask:`url(${Arrow1})` ,maskSize:"100% 100%", maskRepeat:"no-repeat", WebkitMask:`url(${Arrow1})`,WebkitMaskRepeat:'no-repeat', WebkitMaskSize:"100% 100%",  background:'white',color:'white', opacity:'1', height:'100%', width:"100%"}} />
          </div> */}
                {/* <img alt="Right Arrow" className="RightArrow" onClick={()=>{ScrollContainer.current.scrollBy({top:0,left:428,behavior:"smooth"})}} style={{}} src={rightArrow}></img> */}
                {/* ArrowSet */}
                {/* leftArrow rightArrow */}
              </div>
            </div>
          </div>
          {window.innerWidth <= 450 && (
            <div className="TMGCFooter">
              {pages[chosenContentPageId]?.content.map((ele, i) => {
                return (
                  <div
                    key={`k` + i}
                    style={{
                      height: '12px',
                      width: '12px',
                      marginLeft: i > 0 ? '6px' : '0px',
                      borderRadius: '50%',
                      backgroundColor:
                        i === ICalc()
                          ? 'white'
                          : chosenPortfolio.accent_color
                          ? chosenPortfolio.accent_color
                          : ''
                      //  (ScrollContainer.current?.scrollLeft > ScrollContainer.current?.firstChild?.clientWidth * i || ((ScrollContainer.current?.scrollLeft === 0 && i === 0) || ScrollContainer.current?.scrollLeft ===  ScrollContainer.current?.firstChild?.clientWidth * i )) &&
                      //  ScrollContainer.current?.scrollLeft < ScrollContainer.current?.firstChild?.clientWidth * (i+1)?
                      //  'white':
                      //  chosenPortfolio.accent_color?chosenPortfolio.accent_color:""
                    }}
                    onClick={() => {
                      //////onsole.log("SCROLLDESTINATION",ScrollContainer.current?.firstChild?.children[i])
                      ScrollContainer.current.scrollTo({
                        top: '0px',
                        // left:ScrollContainer.current?.firstChild?.firstChild?.clientWidth * i,
                        left:
                          ScrollContainer.current?.firstChild?.children[i]
                            .offsetLeft -
                          ScrollContainer.current?.firstChild?.firstChild
                            ?.offsetLeft,
                        behavior: 'smooth'
                      });
                    }}
                  ></div>
                );
              })}
            </div>
          )}
        </div>
        {/* <p>BaseLIne</p> */}
      </div>
    </>
  );

  //     return(
  //     <div className="TerminalMenu" id="TerminalMenuATemplate9" ref={SubRef}>
  //         <div className="GallerySlider">
  //             <p style={{fontSize:"30px", fontWeight:"500", lineHeight:"116.4%",textAlign:"center",width:"100%", color:"#eee"}}>{pages[chosenContentPageId]?.title}</p>
  //             <div className="ScrollWrap" style={{height:"100%", width:"100%", overflowY:"scroll"}}>
  //                 <div style={{display:"flex",flexDirection:"column", justifyContent:"center", alignItems:"center"}}>

  //                     {(pages[chosenContentPageId]?._type === 'gallery' ||pages[chosenContentPageId]?._type === 'gallery')?pages[chosenContentPageId]?.content.map((item,i)=>{
  //                         return(<div style={{height:"144px", width:"266px",marginTop:i>0?"34px":"0px"}} onClick={()=>{prepareCurrentGalleryContent(i); SetPos(i)}}>{renderSingleThumb(item._type,i,false)}</div>)
  //                     }):(pages[chosenContentPageId]?._type === 'website'?(<div style={{height:"144px", width:"266px",marginTop:"0px", position:"relative"}}
  //                     //onClick={()=>{prepareCurrentGalleryContent(i); SetPos(i)}}
  //                     ><iframe scrolling="no" title="iframe" style={{backgroundColor: "white"}} allowFullScreen src={pages[chosenContentPageId]?.url} style={{height:"100%", width:"100%", border:"none"}}></iframe><div style={{height:"144px", width:"266px",marginTop:"0px", position:"absolute", top:"0px"}} /></div>):
  //                     (pages[chosenContentPageId]?._type === 'htmlContent')?(
  //                       <div style={{height:"144px", width:"266px",marginTop:"0px", position:"relative"}}
  //                       //onClick={()=>{prepareCurrentGalleryContent(i); SetPos(i)}}
  //                       >
  //                     <img
  //                       src={
  //                         urlFor(htmlThumb.image.asset._ref)
  //                         .auto('format')
  //                         .url()
  //                       }
  //                       alt="logo"
  //                       className='portfolio-single-image'
  //                       style={{
  //                         // opacity: chosenPortfolio && chosenPortfolio.theme === 'theme2' ? opacityBack : 1,
  //                         // width: imageWidth,
  //                         // height: imageHeight
  //                       }}
  //                     />
  //                     <div style={{height:"144px", width:"266px",marginTop:"0px", position:"absolute", top:"0px"}}
  //                     //onClick={()=>{prepareCurrentGalleryContent(i); SetPos(i)}}
  //                     />
  //                     </div>

  //                     ):null)}
  //                     {/* <GalThumb
  //                     videos={videos}
  //                     chosenPortfolio={chosenPortfolio}
  //                     foundPage={pages[chosenContentPageId]}
  //                     index={i}
  //                     onNavClick={()=>{prepareCurrentGalleryContent(i); SetPos(i)}}
  //                     //currentNavItem={}
  //                      /> */}
  //                 </div>
  //             </div>
  //         </div>
  //         <div className="Tools"><img src={exit} onClick={back} style={{height:"calc(100% * 43/781)",width:"calc(100% * 43/118)", marginBottom:"43px"}} /><div className="OnMediaTools">
  //         {isAddable && isAuthToAdd &&
  //         (<Icons.AddShoppingCart id="Bypass"
  //   className="icon"
  //   onClick={() => {
  //     addToBriefcase(currentContent);
  //     // if (!showBriefcase) {
  //     //   toggleBriefcase();
  //     // }
  //     if (showBriefcase === true) {
  //       setBooleanNewItems(false);
  //     } else {
  //       setBooleanNewItems(true);
  //     }
  //   }}
  //   data-tip="Add to Briefcase"
  // />
  // )}
  // {
  //         isAddable && isAuthToAdd &&
  //         (
  //         <p>Add to Briefcase</p>)}
  //                             {isGuestMode ? (
  //                       isLiked() && isLiked2 ? (
  //                         <Icons.Favorite id="Bypass"
  //                           onClick={() => {
  //                             updateFavorite('remove');
  //                           }}
  //                         />
  //                       ) : (
  //                         <Icons.FavoriteBorder id="Bypass"
  //                           onClick={() => {
  //                             updateFavorite('add');
  //                           }}
  //                         />
  //                       )
  //                     ) : null}
  //                     <Icons.AnnotatorIcon
  //                     onClick={()=>SET_ANNOTATOR(!ANNOTATOR2)}
  //                     />
  //                     <p>Annotator</p>

  //           </div></div>
  //         {/* <div className="ToolsIcon"><img src={ToolCross} style={{height:"calc(100% * 42/115)",width:"calc(100% * 48/350)", position:"relative", left:"calc(100% * 36/350)", top:"calc(100% * 32/115)"}} /></div> */}
  //         <div className="ContentArea"><ContentParent Position={Pos} createSetCurrentContent={createSetCurrentContent}/></div>
  //     </div>
  //     )
};
export default TMG;
const TMGOBJECT = ({
  scrollLeft,
  img,
  i,
  ScaleFactor,
  ScrollContainer,
  OpFactor,
  htmlThumb,
  Position,
  currentContent,
  createSetCurrentContent,
  Single,
  prepareCurrentGalleryContent,
  renderSingleThumb,
  pages,
  chosenContentPageId,
  chosenPortfolio
}) => {
  const RefImg = useRef();
  //let image = img?.image;
  ////////onsole.log("FINDBRIEFCASE",img,ScrollContainer)
  //ScrollContainer.offsetWidth + ScrollContainer.scrollLeft === visible area (VA > scrollLeft && VA < scrollLeft + offsetWidth)
  //const [Scale, SetScale] = useState(1);
  // const testAnimationCycle = setInterval(() => {
  //   Test();
  // }, 1000/60);
  // const Test = ()=>{SetScale(1 - (Math.abs(i - (ScrollContainer?.current?.scrollLeft/450)) * ScaleFactor));}
  // testAnimationCycle();
  // useEffect(()=>{
  //   const test = async()=>{
  //     while(true){
  //     await sleep(1000/60);
  //     SetScale(1 - (Math.abs(i - (ScrollContainer?.current?.scrollLeft/450)) * ScaleFactor));
  //     }
  //   }
  //   test();
  // },[])
  // useEffect(()=>{
  //   SetScale(1 - (Math.abs(i - (ScrollContainer?.current?.scrollLeft/450)) * ScaleFactor));
  // },[ScrollContainer?.current?.scrollLeft, ScaleFactor])
  ////////onsole.log("TMGOBJECT", ScrollContainer, i, i - (ScrollContainer?.current?.scrollLeft/450), (2 - ScrollContainer?.current?.scrollLeft?Math.abs(i - (ScrollContainer?.current?.scrollLeft/450))*ScaleFactor:0),Math.abs(i - (ScrollContainer?.current?.scrollLeft/450)))
  //transition:"all 0.1s",

  //(250-(450 *(1 - (Math.abs(i - (ScrollContainer?.current?.scrollLeft/450)) * ScaleFactor))/2 )+((i - (ScrollContainer?.current?.scrollLeft/450))*300))
  //Math.max(Math.min((((ScrollContainer.offsetWidth + ScrollContainer.scrollLeft) - (RefImg.current.offsetLeft))/RefImg.current.offsetWidth),1),0) //OPacity test
  ////////onsole.log(Math.min((Math.max((1 -((i - (ScrollContainer?.current?.scrollLeft/450)) *OpFactor)),0)),1))
  //450X320
  // useEffect(()=>{
  //   //////onsole.log("RefImg",RefImg.current)
  // },[RefImg.current])
  const [Active, SetActive] = useState(false);
  useEffect(() => {
    // if(Active)
    // Single(<SingleItemDisplay Ctype={true} SetActive={SetActive} Single={Single} Position={i} currentContent={currentContent} createSetCurrentContent={createSetCurrentContent}></SingleItemDisplay>)
  }, [currentContent, Active, Single, createSetCurrentContent, i]);
  // useEffect(()=>{
  //   //////onsole.log("currentContent",currentContent)
  // },[currentContent])
  //RefImg.current.offsetWidth + RefImg.current.offsetLeft = Rightextremity
  const [OP, SetOP] = useState(
    Math.max(
      Math.min(
        (ScrollContainer.current?.offsetWidth +
          ScrollContainer.current?.scrollLeft -
          RefImg.current?.offsetLeft) /
          RefImg.current?.offsetWidth,
        1
      ),
      0
    )
  );
  useEffect(() => {
    SetOP(
      Math.max(
        Math.min(
          (ScrollContainer.current?.offsetWidth +
            ScrollContainer.current?.scrollLeft -
            RefImg.current?.offsetLeft) /
            RefImg.current?.offsetWidth,
          1
        ),
        0
      )
    );
  }, [ScrollContainer, scrollLeft]); //ScrollContainer.current?.scrollLeft,RefImg.current?.offsetLeft,
  // useEffect(()=>{
  //   //////onsole.log("ScrollContainer.current?.scrollLeft",ScrollContainer.current)
  // },[ScrollContainer.current?.scrollLeft])
  return (
    <HoverDivsTMG
      className="TMGCItem"
      onClick={() => {
        SetActive(true);
        Single({
          Ctype: true,
          SetActive: SetActive,
          Single: Single,
          Position: i,
          currentContent: currentContent,
          createSetCurrentContent: createSetCurrentContent
        });
      }}
      ref={RefImg}
      style={{ marginLeft: i === 0 ? '24px' : 'unset', opacity: OP }}
    >
      {pages[chosenContentPageId]?._type === 'gallery' ? (
        renderSingleThumb(img._type, i, false)
      ) : pages[chosenContentPageId]?._type === 'website' ? (
        <div
          style={{ marginTop: '0px', position: 'relative' }}
          //onClick={()=>{prepareCurrentGalleryContent(i); SetPos(i)}}
        >
          <iframe
            scrolling="no"
            title="iframe"
            allowFullScreen
            src={pages[chosenContentPageId]?.url}
            style={{
              height: '100%',
              width: '100%',
              border: 'none',
              backgroundColor: 'white'
            }}
          ></iframe>
          <div style={{ marginTop: '0px', position: 'absolute', top: '0px' }} />
        </div>
      ) : pages[chosenContentPageId]?._type === 'htmlContent' ? (
        <div
          style={{ marginTop: '0px', position: 'relative' }}
          //onClick={()=>{prepareCurrentGalleryContent(i); SetPos(i)}}
        >
          <img
            src={urlFor(htmlThumb.image.asset._ref).auto('format').url()}
            alt="logo"
            className="portfolio-single-image"
            style={
              {
                // opacity: chosenPortfolio && chosenPortfolio.theme === 'theme2' ? opacityBack : 1,
                // width: imageWidth,
                // height: imageHeight
              }
            }
          />
          <div
            style={{ marginTop: '0px', position: 'absolute', top: '0px' }}
            //onClick={()=>{prepareCurrentGalleryContent(i); SetPos(i)}}
          />
        </div>
      ) : null}
      {/* height:"144px", width:"266px"
height:"144px", width:"266px"
height:"144px", width:"266px"
height:"144px", width:"266px" */}
      {}
      {/* <img src={urlFor(image).auto('format').width(500).url()} style={{position:"relative",top:'0px', width:'100%', objectFit:"contain"}}></img> */}
      <div
        className="Second"
        style={{
          position: 'absolute',
          bottom: '0px',
          height: window.innerWidth <= 450 ? 'calc(100% * 78/212.617)' : '98px',
          width: '100%'
        }}
      >
        <p
          style={{
            fontFamily: FontUtil(
              pages[chosenContentPageId].TitleText,
              chosenPortfolio.ContentText
            ),
            color: ColorUtil(
              pages[chosenContentPageId].text_color,
              chosenPortfolio.text_color,
              'black'
            )
          }}
        >
          {img?.title}
        </p>
      </div>
    </HoverDivsTMG>
  );
};
// function sleep(ms) {
//   return new Promise(resolve => setTimeout(resolve, ms));
// }

//*(1 - ScrollContainer?.current?.scrollLeft?Math.abs(i - (ScrollContainer?.current?.scrollLeft/450) )*ScaleFactor:0)
//*(1 - ScrollContainer?.current?.scrollLeft?Math.abs(i - (ScrollContainer?.current?.scrollLeft/450) )*ScaleFactor:0

//<div style={{position:'absolute',width:"calc(100% * (1920 - 1159)/1920", height:"100%",backgroundColor:"red",top:'0px', right:'0px'}}>
//{/* CONTENT HERE TO BE DETERMINED */}
//{
//(pages[chosenContentPageId]?._type !== "gallery"?
//(pages[chosenContentPageId]?._type !== "website"?
//renderSingleImageExport(pages[chosenContentPageId]?._type,0,contentDataExport(chosenContentPageId)):
//renderSingleImageExport(pages[chosenContentPageId]?._type,0,pages[chosenContentPageId]?.url)
// //////onsole.log("WEBSITE")
//renderSingleImageExport(pages[chosenContentPageId]?._type,0,contentDataExport(chosenContentPageId))
//):
//renderSingleImageExport(pages[chosenContentPageId]?.content[0]._type,0,contentDataExport(chosenContentPageId)))}
//{/* {//////onsole.log(pages[chosenContentPageId]?._type)} */}
//</div>

/* <div className="SubpageMenuContent" style={{backgroundColor:"rgba(255,255,255,0.7)", width:"calc(100% * 563/1920)", position:"absolute", height:"calc(100% * 558/967)",top:"calc(100% * 159/967)",left:"calc(100% * 1159/1920", display:"flex", flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
  <div onClick={()=>{if(CanGoUp())setTarget(Target-1)}} className="UpArrow" style={{ position:"absolute", backgroundColor:"#981E59", width:"calc(100% * 60/563)", height:"calc(100% * 60/558)", top:"calc(-100% * 39.64/558)",left:"calc(100% * 260/563)", display:"flex",justifyContent:"center",alignItems:"center", maskImage:`url(${Circle})`,WebkitMaskImage:`url(${Circle})`,maskSize:"100% 100%",WebkitMaskSize:"100% 100%"}}>
      <div className="arrow" style={{ backgroundColor:"white", width:"calc(100% * 25.26/60)", height:"calc(100% * 12.63/60)", maskImage:`url(${Arrow})`,WebkitMaskImage:`url(${Arrow})`,maskSize:"100% 100%",WebkitMaskSize:"100% 100%"}}></div>
  </div>
  <div onClick={()=>{if(CanGoDown())setTarget(Target+1)}} className="DownArrow" style={{position:"absolute",backgroundColor:"#981E59", width:"calc(100% * 60/563)", height:"calc(100% * 60/558)", bottom:"calc(-100% * 39.64/558)",left:"calc(100% * 260/563)", display:"flex",justifyContent:"center",alignItems:"center",maskImage:`url(${Circle})`,WebkitMaskImage:`url(${Circle})`,maskSize:"100% 100%",WebkitMaskSize:"100% 100%", transform:"rotate(180deg)"}}>
      <div className="arrow" style={{backgroundColor:"white", width:"calc(100% * 25.26/60)", height:"calc(100% * 12.63/60)", maskImage:`url(${Arrow})`,WebkitMaskImage:`url(${Arrow})`,maskSize:"100% 100%",WebkitMaskSize:"100% 100%", transform:"rotate(180)"}}></div>
  </div>
  {Val.children.map((ele,i)=>{
  ////////onsole.log("RENDERSUBITEMS",i, Target)
  if(i>Target + Limit-1 || i< Target)
  return(null)
  ////////onsole.log(pages[ele.referenceId],ele);
  let subtitle = pages[ele.referenceId].subtitle
  return(<div key={ele.referenceId} className="SubMenuItemWrapper" onClick={()=>{
    SetTopicArray(ele.title,level,ele.referenceId);
  onButtonClick(ele.referenceId,level,ele.children.length>0,ele.children.length>0,i);
  
  }
  } style={{display:"grid",gridTemplateRows:"1fr 2px", width:"calc(100% * (563 - (61 + 66)) / 563)", height:"120px", gridTemplateColumns:"1fr 90px"}}>
      <div className="SubMenuTextWrapper" style={{ display:"flex", flexDirection:"column", justifyContent:"center", gridColumn:"1/1", marginLeft:"calc(100% * 13 / (563 - (61 + 66))"}}>
          <p className="Header">{ele.title}</p>
          { subtitle && <p className="Subtitle">{subtitle}</p>}
      </div>
      <div style={{gridColumn:"2/2"}}>
           ICON HERE TO BE DETERMINED */
//       </div>
//       {i !== Target + Limit-1 &&<div style={{gridRow:"2/2", gridColumn:"1/3", backgroundColor:"white"}}></div>}
//   </div>)
// })}
// </div>
const blockContent = (blocks) => {
  // filter non valid imageLinks
  let tempBlocks = blocks.filter((obj) => {
    if (obj._type === 'imageLinks' && obj.image) {
      return true;
    }
    if (obj._type !== 'imageLinks') {
      return true;
    }
    return false;
  });

  // further filter non valid embedHtml
  tempBlocks = tempBlocks.filter((obj) => {
    if (obj._type === 'embedHTML' && obj.html) {
      return true;
    }
    if (obj._type !== 'embedHTML') {
      return true;
    }
    return false;
  });
  return tempBlocks;
};
