/* 
// Shows menus as nested
*/
import React, { useState, useEffect } from 'react';
import Button from '../../../components/Button';
import MenuSection from '../../MenuSection';
import useTemplate from '../../../components/_Portfolio/PortfolioMenu/Helper/TemplateHelper';
import { urlFor } from '../../../utils/sanityHelper';

const Menu1 = (props) => {
  const {
    // companyConfig,
    // images,
    setChosenMainPageButtonId,
    setChosenPageId,
    setIsAtEnd,
    setCurrentLevel,
    chosenPortfolio,
    setTopic,
    Topic,
    setNavHistory,
    setHasCurr,
    setHasPrev
  } = props;

  const { currentLevel, isAtEnd, menuHide, navHistory } = props.pageMenuReducer;
  const { portfolio_LogoResize } = chosenPortfolio;
  // get default functions used from hook
  const { getActiveString, getIsAtEndString } = useTemplate(props);

  const [prevId, setPrevId] = useState(''); // keep track of previous page id for back button
  const [isInNested, setIsInNested] = useState(false); // keep track if user is in nested layer
  const [LevelOfTerm, setLevelOfTerm] = useState(0); // keep track of previous page id for back button
  const [prevLevel, setPrevLevel] = useState(''); // keep track of previous page id for back button

  useEffect(() => {
    if (isAtEnd) {
      setLevelOfTerm(prevLevel + 1);
    }
  }, [isAtEnd, prevLevel]);
  // back button function
  const goBack = () => {
    BackTopicArray();
    if (prevId !== '') {
      // initiate menu button click for prevId
      setPageIdAndLevel(prevId, currentLevel);
    } else {
      // empty prevId
      setPageIdAndLevel('', 0);
    }
    setIsAtEnd(false);

    // remove last item from navHistory
    setNavHistory(navHistory.filter((_, i) => i !== navHistory.length - 1));
  };
  const BackTopicArray = () => {
    let topicalarray = [];
    try {
      topicalarray = [...Topic];
    } catch (err) {
      console.error('Error', err);
    }
    topicalarray.splice(Topic.length - 1, 111111);
    setTopic(topicalarray);
  };

  const SetTopicArray = (title, level, id) => {
    let topicalarray = [];
    try {
      topicalarray = [...Topic];
    } catch (err) {
      console.error('Error', err);
    }
    if (!isAtEnd) {
      //////onsole.log("TOPICAL ARRAY BEFORE SPLICE", topicalarray);
      topicalarray.splice(level, 11111, { title: title, id: id });
      setTopic(topicalarray);
      //////onsole.log("TOPICAL ARRAY", topicalarray);
    } else {
      //////onsole.log("TOPICAL ARRAY BEFORE SPLICE", topicalarray);
      //////onsole.log("LEVEL HERE", level);
      if (chosenPortfolio.theme !== 'theme2') {
        topicalarray.splice(LevelOfTerm, 11111, { title: title, id: id });
      } else {
        topicalarray.splice(level, 11111, { title: title, id: id });
      }
      setTopic(topicalarray);
      //////onsole.log("TOPICAL ARRAY", topicalarray);
    }
  };

  // on page button click
  const onClick = (id, level, hasChild, isNotEnd, navIndex) => {
    if (setHasCurr && setHasPrev) {
      setHasCurr(true);
      setHasPrev(true);
    }

    setPageIdAndLevel(id, level);
    setPrevLevel(level);

    // copy navhistroy
    let navHistoryCopy = [...navHistory];
    // first nav history, so parent button
    if (navHistoryCopy.length === 0) {
      navHistoryCopy.push(navIndex);
    } else {
      // check if need to replace at same level
      if (level <= navHistoryCopy.length - 1) {
        navHistoryCopy[level] = navIndex;
        // remove level higher than replaced one
        // ex if user has history [2,1,1,5] and clicked on index 3 at parent (level 0)
        // then replace  it [3,1,1,5] and remove history of any other level, so it will be [3]
        // NOTE slice caused issue, using filter for now
        navHistoryCopy = navHistoryCopy.filter((_, i) => i <= level);
      } else {
        // if not append
        navHistoryCopy.push(navIndex);
      }
    }

    // set/replace navHistory
    setNavHistory(navHistoryCopy);

    // check if clicked content contains child
    if (hasChild) {
      let b = !isNotEnd;

      // User is in nested so set isInNested to true
      setIsInNested(true);
      // set isAtEnd
      setIsAtEnd(b);
      if (!b) {
        setPrevId(id);
      } else {
        //if at end the nested ended so set isInNested to false
        setIsInNested(false);
      }
    } else {
      setIsAtEnd(true);
      if (!isInNested) {
        // if not in nested set prev id
        setPrevId(id);
      }
    }
    //setPrevId(id);
  };

  // set page and level
  const setPageIdAndLevel = (id, level) => {
    if (level === 0) {
      setChosenMainPageButtonId(id);
    }
    setChosenPageId(id);
    setCurrentLevel(level);
  };

  // return null if menuHide is true
  // if (menuHide) {
  //   return null;
  // }

  return (
    <div
      className="portfolio-menu-container"
      style={{ display: menuHide ? 'none' : '' }}
    >
      <div className="logo">
        <div className="logo-content">
          <img
            style={{
              height: `calc(100% - ${
                portfolio_LogoResize?.Compression
                  ? portfolio_LogoResize.VerticalCompression
                  : 0
              }%)`,
              width: `calc(100% - ${
                portfolio_LogoResize?.Compression
                  ? portfolio_LogoResize.HorizontalCompression
                  : 0
              }%)`
            }}
            src={urlFor(
              chosenPortfolio?.portfolio_logo?.asset?._ref
                ? chosenPortfolio.portfolio_logo.asset._ref
                : ''
            )
              .auto('format')
              .width(400)
              .url()}
            alt="logo"
          />
        </div>
      </div>
      <div className={`portfolio-menu  ${getIsAtEndString()}`}>
        <MenuSection
          isActive={true} // set all level 0 button to be active
          getActiveString={getActiveString}
          getIsAtEndString={getIsAtEndString}
          SetTopicArray={SetTopicArray}
          level={0}
          onButtonClick={onClick}
        />
      </div>
      {isAtEnd && (
        <div className="back-container">
          <Button
            width="100%"
            height="50px"
            backgroundColor="black"
            text="Back"
            onClick={goBack}
          />
        </div>
      )}
    </div>
  );
};
export default Menu1;
