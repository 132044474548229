//SET_ANNOTATOR
import { connect } from 'react-redux';
import ANMO from './ANMO';
// import {
//     SET_ANNOTATOR
// } from '../../actions';
const mapStateToProps = (state) => {
  //////onsole.log(state);
  return {
    ANNOTATOR2: state.portfolioReducer.aNNOTATOR,
    companyConfig: state.sanityContentReducer.companyConfig,
    chosenPortfolio: state.portfolioReducer.chosenPortfolio
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    SET_ANNOTATOR: (t) => dispatch({ type: 'SET_ANNOTATOR', payload: t })
  };
};

const ANMOC = connect(mapStateToProps, mapDispatchToProps)(ANMO);

export default ANMOC;
