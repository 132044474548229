const FontUtil = (primaryFont, secondaryFont, defaultFont) => {
  //onsole.log(pathToOverrideFont)
  //font-family: pathToOverrideFont.Font?pathToOverrideFont.Font:PathToFont.Font?PathToFont.Font: "1", 'Roboto', sans-serif;
  //return `${pathToOverrideFont?pathToOverrideFont+", ":""}${PathToFont?PathToFont+", ":""}"1",${Default?Default+", ":""}"Roboto", sans-serif `
  return primaryFont
    ? primaryFont // if primary font, use primary font
    : secondaryFont
    ? secondaryFont // if can't find primary and can find secondary, use secondary
    : `"1", ${defaultFont ? defaultFont + ',' : ''} "Roboto", sans-serif`; // if can't find either, use default - though "1" (company font) has priority over it if found
  // if nothing else, use Roboto, Sans-Serif
};
export default FontUtil;
