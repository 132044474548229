import {
  SET_GUEST_PAGES,
  SET_GUEST_MODE,
  SET_GUEST_CONTENT_HELPER,
  PREPARE_GUEST,
  SET_CURRENT_CONTENT_HELPER,
  GET_EXPIRE_DATE,
  SET_GUEST_DATA
} from '../constants/actionTypes';
import { urlFor } from '../utils/sanityHelper';
import { client } from '../utils/client';
import {
  setPortfolioToplevelImageUrl,
  setPortfolioTermmenuImageUrl
} from './portfolioActions';

export const setGuestPages = (array) => ({
  type: SET_GUEST_PAGES,
  array
});

export const setGuestContentHelper = (array) => ({
  type: SET_GUEST_CONTENT_HELPER,
  array
});

export const setGuestMode = (bool) => ({
  type: SET_GUEST_MODE,
  bool
});

export const setupGuest = (payload) => ({
  type: PREPARE_GUEST,
  payload
});

export const setCurrentContentHelper = (id) => ({
  type: SET_CURRENT_CONTENT_HELPER,
  id
});
export const SetGuestData = (data) => ({
  type: SET_GUEST_DATA,
  data
});

//===================
// GET
//===================
export const getExpireDateBegin = () => ({
  type: GET_EXPIRE_DATE.BEGIN
});

export const getExpireDateSuccess = () => ({
  type: GET_EXPIRE_DATE.SUCCESS
});

export const getExpireDateFailure = () => ({
  type: GET_EXPIRE_DATE.FAILURE
});

// prep portoflio for guest flow
export const guestPrepPortfolio = (guestPass, url) => {
  return (dispatch, getState) => {
    const state = getState();
    const portfolioId = guestPass[0].portfolio_id;
    const portfolios = state.sanityContentReducer.portfolios;
    const chosen = portfolios.find((obj) => obj._id === portfolioId);

    const backgroundImageRef = chosen.portfolio_bg_image
      ? chosen.portfolio_bg_image.asset._ref
      : '';

    // get page background image ref
    const backgroundPageImageRef = chosen.page_bg_image
      ? chosen.page_bg_image.asset._ref
      : '';

    // crete url for background image
    const backgroundImageUrl =
      backgroundImageRef !== ''
        ? urlFor(backgroundImageRef).auto('format').width(1600).url()
        : '';
    const backgroundPageImageUrl =
      backgroundPageImageRef !== ''
        ? urlFor(backgroundPageImageRef).auto('format').width(1600).url()
        : '';

    const terminusBackgroundImageRef = chosen.MegaMenuImages
      ?.terminus_level_background
      ? chosen.MegaMenuImages.terminus_level_background.asset._ref
      : '';

    const terminusBackgroundImageUrl =
      terminusBackgroundImageRef !== ''
        ? urlFor(terminusBackgroundImageRef).auto('format').width(1980).url()
        : '';

    const backgroundPageImageRef2 = chosen.portfolio_bg_image
      ? chosen.portfolio_bg_image.asset._ref
      : '';

    const backgroundPageImageUrl2 =
      backgroundPageImageRef2 !== ''
        ? urlFor(backgroundPageImageRef2).auto('format').width(1980).url()
        : '';

    // create guest nav
    let navTemp = {
      items: []
    };

    let navItemTemplate = {
      _key: '', // create random key for each nav, May need matching nav id from original...
      children: [],
      referenceField: 'pages',
      referenceId: '',
      title: '',
      type: ''
    };

    if (guestPass[0].is_portfolio_view) {
      navTemp = chosen.nav;
    } else {
      guestPass[0].pages.forEach((obj) => {
        // push items
        navTemp.items.push({
          ...navItemTemplate,
          _key: Math.random().toString(36).substr(2, 7),
          referenceId: obj.page_id,
          title: obj.title,
          type: obj.content_type
        });
      });
    }

    let tempSplashPage = undefined;
    // convert splash to page if exists, id and key will always be  'splashkey' + portfolio id
    if (chosen.splash) {
      tempSplashPage = {
        id: `splashkey-${chosen._id}`,
        _key: `splashkey-${chosen._id}`,
        _type: 'gallery',
        title: 'Splash',
        content: []
      };
      chosen.splash.forEach((content) => {
        tempSplashPage.content.push(content);
      });
    }
    let obj;
    if (!guestPass[0].is_portfolio_view) {
      obj = {
        backgroundImageUrl: backgroundImageUrl,
        portfolioTopLevelImageUrl: backgroundPageImageUrl2,
        portfolioTermMenuImageUrl: terminusBackgroundImageUrl,
        backgroundPageImageUrl: backgroundPageImageUrl,
        chosenPortfolio: chosen,
        chosenPortfolioId: portfolioId,
        guestNav: navTemp,
        guestContentHelper: [...guestPass[0].pages, tempSplashPage], // put in splash page in guest pages
        expireDate: null,
        isPortfolioView: guestPass[0].is_portfolio_view,
        splashPage: tempSplashPage
      };
    } else {
      obj = {
        backgroundImageUrl: backgroundImageUrl,
        portfolioTopLevelImageUrl: backgroundPageImageUrl2,
        portfolioTermMenuImageUrl: terminusBackgroundImageUrl,
        backgroundPageImageUrl: backgroundPageImageUrl,
        chosenPortfolio: chosen,
        chosenPortfolioId: portfolioId,
        guestNav: navTemp,
        guestContentHelper: [...chosen.pages, tempSplashPage], // put in splash page in guest pages
        expireDate: null,
        isPortfolioView: guestPass[0].is_portfolio_view,
        splashPage: tempSplashPage
      };
    }

    // Begin sanity GET
    dispatch(getExpireDateBegin());
    dispatch(setPortfolioToplevelImageUrl(backgroundPageImageUrl2));
    dispatch(setPortfolioTermmenuImageUrl(terminusBackgroundImageUrl));
    // get expire date from sanity by using url
    const query = `*[_type == 'guest-pass'  && share_url == '${url}']`;
    client() //cloud read
      .fetch(query, {})
      .then((data) => {
        // Success
        // check if data is found
        if (data.length === 0) {
          console.error('No data found in sanity with url');
          // dispatch status
          dispatch(getExpireDateFailure());
        } else {
          // set expireDate
          obj.expireDate = data[0].expire_date;

          // also set status to Success
          dispatch(setupGuest(obj));
        }
      })
      .catch((error) => {
        // Fail
        console.error('Error', error);
        // dispatch
        dispatch(getExpireDateFailure());
      });
  };
};
