import { connect } from 'react-redux';
import Information from './UserInformation';
import { setHeaderAggregateSelected } from '../../../../actions';

const mapStateToProps = (state) => ({
  member: state.sanityContentReducer.member,
  userInfo: state.firestore.ordered.users,
  headerAggregateSelected: state.DashboardReducer.headerAggregateSelected,
  Size: state.portfolioReducer.Size
});

const mapDispatchToProps = (dispatch) => {
  return {
    setHeaderAggregateSelected: (value) =>
      dispatch(setHeaderAggregateSelected(value))
  };
};

const UserInformationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Information);

export default UserInformationContainer;
