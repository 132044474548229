import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as Scale from '../../../../../utils/Scalars';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';

const ListItems = styled.div`
    display: grid;
    grid-template-rows: 1fr 5fr;
    height: 100%;
    border-radius: 8px;
    position: relative;

    .sort-box {
        position: absolute;
        top: 0;
        left: 0;
        height: 35px;
        width: 35px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 1380px) {
            height: 25px;
            width: 25px;
        }
    }

    .card-header {
        grid-row: 1 / span 1;
        background-color: #2b2b2b;
        color: white;
        display: grid;
        grid-template-rows: 1fr 1fr;
        font-family: 'Open Sans', sans-serif;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        .header-top {
            grid-row: 1 / span 1;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
        }

        .header-bottom {
            grid-row: 2 / span 1;
            display: grid;
            grid-template-columns: 1fr 1fr;

            .header-left {
                grid-column: 1 / span 1;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
            }

            .header-right {
                grid-column: 2 / span 1;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
            }
        }

        h3 {
            margin-block-start: 0px;
            margin-block-end: 0px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 600;
            font-size: 1.2em;
        }

        p {
            margin-block-start: 0px;
            margin-block-end: 0px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; 
            font-weight: 600;
            font-size: 1.0em;
        }
    }

    .card-body {
        grid-row: 2 / span 1;
        overflow-y: auto;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        background-color: #dedede;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        background-color: #eeeeee;

        // hide scrollbar
        // ::-webkit-scrollbar {
        //     display: none;
        // }
        // -ms-overflow-style: none; /* IE and Edge */
        // scrollbar-width: none; /* Firefox */

        .line-item {
            display: grid;
            grid-template-columns: 1fr 1fr;
            height: 12.5%;
            min-height: 12.5%;
            width: 100%;

            p {
                margin-block-start: 0px;
                margin-block-end: 0px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis; 
                color: #3b3b3b;
                font-weight: 600;
            }

            .name {
                grid-column: 1 / span 1;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                overflow: hidden;
                font-family: 'Newsreader', serif;
                padding-left: 20px;
            }

            .count {
                grid-column: 2 / span 1;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                font-family: 'Open Sans', sans-serif;
            }
        }

        .no-items {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 12.5%;
            min-height: 12.5%
            width: 100%;
            overflow: hidden;

            p {
                margin-block-start: 0px;
                margin-block-end: 0px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis; 
                color: #3b3b3b;
                font-weight: 600;
                font-family: 'Newsreader', serif;
                font-size: 1.3em;
            }
        }
    }
`;

const Sent = ({ PagesArray, barHeight, Size }) => {
  //let items = [{Name:"testName1",Sent:4},{Name:"testName2",Sent:1},{Name:"testName3",Sent:1},{Name:"testName4",Sent:9},{Name:"testName5",Sent:6}]
  const [items, SetItems] = useState([]);
  const [sortOrder, setSortOrder] = useState('HighLow');

  useEffect(() => {
    if (!PagesArray) return;
    //////////onsole.log("!!!!!PA!!!!!",PagesArray)
    let PA = [];
    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(PagesArray)) {
      //////////onsole.log("!!!!!PA!!!!!",value);
      PA.push(value);
    }
    SetItems(PA);
  }, [PagesArray]);

  const sortHighToLow = (a, b) => {
    return b.Count - a.Count || a.Name - b.Name;
  };

  const sortLowToHigh = (a, b) => {
    return a.Count - b.Count || a.Name - b.Name;
  };

  let itemMapping;

  if (items.length) {
    itemMapping = items
      .sort((a, b) => {
        return sortOrder === 'HighLow'
          ? sortHighToLow(a, b)
          : sortLowToHigh(a, b);
      })
      .map((ele, index) => {
        return (
          <div
            key={ele.Name + ele.Count}
            className="line-item"
            style={{
              backgroundColor:
                index % 2 === 1 ? '#eeeeee' : 'rgb(222, 222, 222)'
            }}
          >
            <div className="name">
              <p>{ele.Name}</p>
            </div>
            <div className="count">
              <p>{ele.Count}</p>
            </div>
          </div>
        );
      });
  } else {
    itemMapping = (
      <div className="no-items">
        <p>No items sent for this period.</p>
      </div>
    );
  }

  return (
    <ListItems>
      <div className="sort-box">
        {sortOrder === 'HighLow' ? (
          <ArrowUpwardRoundedIcon
            style={{
              color: 'white',
              fontSize: `${Scale.Height(Size, 28)}px`,
              cursor: 'pointer'
            }}
            onClick={() => setSortOrder('LowHigh')}
          />
        ) : (
          <ArrowDownwardRoundedIcon
            style={{
              color: 'white',
              fontSize: `${Scale.Height(Size, 28)}px`,
              cursor: 'pointer'
            }}
            onClick={() => setSortOrder('HighLow')}
          />
        )}
      </div>
      <div className="card-header">
        <div className="header-top">
          <h3>Items Sent</h3>
        </div>
        <div className="header-bottom">
          <div className="header-left">
            <p>Name</p>
          </div>
          <div className="header-right">
            <p># Sent</p>
          </div>
        </div>
      </div>
      <div
        className="card-body"
        style={{
          justifyContent: items.length ? 'flex-start' : 'center',
          paddingBottom: items.length ? '0px' : '53px'
        }}
      >
        {itemMapping}
      </div>
    </ListItems>
  );
};
export default Sent;
