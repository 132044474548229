import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useFirebase, useFirestore } from 'react-redux-firebase';
// import ToolCross from '../../../../containers/PageMenus/Menu9-Storybook/TerminalMenuGallery/Exclude.png'//
// import Icons from '../../../../containers/Icons';
import Script from './ScriptCopy';
import Test from './Notepad2 copy';
import PII from './PII';
// import Close from '../../../../containers/PageMenus/Menu9-Storybook/CloseSVG'
// import { IconContext } from 'react-icons';
import { connect } from 'react-redux';

// import Like from '../../../../utils/Likes'; PING

// import ShareIcon from '../../../../assets/RootSVGS/shared_v1Asset2'
// import BC from './PortfolioBriefcaseTranscribedV2'

// import FontUtil from '../../../../utils/FontUtility';
import {
  toggleScriptnote,
  toggleBriefcase,
  toggleNotepad,
  endPortfolio,
  setPortfolioCustomerInfo,
  addToBriefcase,
  clearAll,
  setBooleanNewItems,
  updateSessionInSanity,
  setLiked,
  showModal
} from '../../../../actions';
const ActionMenuToolbar = ({
  toggleScriptnote,
  toggleNotepad,
  showNotepad,
  toggleBriefcase,
  showBriefcase,
  updateSessionWithNotes,
  briefcaseContent,
  chosenPortfolio,
  currentContent,
  userInfo,
  sessionId,
  clearAll,
  isGuestMode,
  endPortfolio,
  currentGuestContentHelper,
  liked,
  Size,
  sendClick,
  shareClick,
  isModalOpen,
  updateSessionInSanity,
  showModal,
  setPortfolioCustomerInfo,
  showScriptnote,
  setLiked,
  ANNOTATOR2,
  SET_ANNOTATOR,
  booleanNewItems,
  setBooleanNewItems,
  addToBriefcase,
  mode
}) => {
  // const [isAddable, setIsAddable] = useState(true); // bool to keep track of if it is addable to briefcase
  // const [isAuthToAdd, setIsAuthToAdd] = useState(false); // is this user authorized to add item?
  // const [isAuthBriefcaseShow, setIsAuthBriefcaseShow] = useState(false); // is this user authorized to see briefcase?
  // const [isLiked2, setIsLiked2] = useState(null); // is this user authorized to see briefcase?
  const history = useHistory();
  const firestore = useFirestore();
  const firebase = useFirebase();
  const Keycheck = (eve) => {
    if (eve.key !== 'F8') return;
    onExit();
  };
  useEffect(() => {
    window.addEventListener('keydown', Keycheck);
    return () => window.removeEventListener('keydown', Keycheck);
  });
  // check each time chosenContent or briefcaseContent updates
  // useEffect(() => {
  //   // check if briefcase contains current content user is looking at
  //   if(!currentContent)
  //   return
  //   if (
  //     (Object.entries(currentContent).length !== 0 &&
  //       briefcaseContent.filter((e) => e.id === currentContent.id).length >
  //         0) ||
  //     currentContent.page_id === `splashkey-${chosenPortfolio._id}`
  //   ) {
  //     setIsAddable(false);
  //   } else {
  //     setIsAddable(true);
  //   }
  // }, [currentContent, briefcaseContent, chosenPortfolio]);

  // get userinfo auth for viewing sending briefcase
  // useEffect(() => {
  //   if (userInfo && userInfo.length > 0) {
  //     // check if user is able to create guest pass
  //     if (userInfo[0].is_able_to_create_briefcase) {
  //       // user is able to create normal guest pass, aka able to add item to briefcase
  //       setIsAuthToAdd(true);
  //     }
  //     // check if user is able create portfolio level guest pass
  //     if (userInfo[0].is_able_to_create_guest_pass) {
  //       // user is able to create normal guest pass, aka able to add item to briefcase
  //       setIsAuthBriefcaseShow(true);
  //     }
  //   }
  // }, [userInfo]);

  const endSession = () => {
    endPortfolio();
    history.push('/engage');
  };

  const logout = () => {
    //////////onsole.log('Guest user logout');
    firebase.logout();
    // clear sessionkey in local storage and redux session key
    //updateSessionKey('');
    //clear all state
    clearAll();
  };

  const onExit = () => {
    if (sessionId === '') {
      endSession();
    } else {
      // create page visit info obj
      const pageVisitInfo = {
        is_end: true,
        time_visited: firestore.Timestamp.now()
      };
      firestore
        .collection('sessions')
        .doc(sessionId)
        .update({
          page_visited: firestore.FieldValue.arrayUnion(pageVisitInfo)
        })
        .then((e) => {
          // success
          //////////onsole.log('Firestore set/update success');
          // if guest mode logout
          // check guest mode
          if (isGuestMode) {
            logout();

            // open url
            if (
              chosenPortfolio.end_demo_url &&
              chosenPortfolio.end_demo_url !== ''
            ) {
              window.open(chosenPortfolio.end_demo_url, '_self');
            }
            return;
          } else {
            // end session for user
            endSession();
          }
        })
        .catch((error) => {
          console.error(error);
          endSession();
        });
    }
  };

  // const updateFavorite = (op) => {
  // Like(op,isGuestMode,sessionId,liked,currentGuestContentHelper,currentContent,chosenPortfolio,setIsLiked2,setLiked,firestore)
  //   // do not log if mode is not guest or no sessionId
  //   if (!isGuestMode || sessionId === '') {
  //     return null;
  //   }

  //   // copy liked from redux store
  //   let likedArrCopy = [...liked];
  //   //////////onsole.log("!!CC!!",currentContent)
  //   let id = currentGuestContentHelper.id;
  //   let content = currentContent;
  //   // check if currentGuestContentHelper id is undefined
  //   if (!id) {
  //     //////////onsole.log('like in full portfolio guest-pass view...');
  //     id = currentContent.id;
  //     // if undefined it means that guest is in full portfolio view and not in briefcase guest view
  //   } else {
  //     // user in briefcase guest view
  //     //////////onsole.log('like in Briefcase guest view...');
  //     // set content from currentGuestContentHelper, if not splash
  //     if (id !== `splashkey-${chosenPortfolio._id}`) {
  //       content = currentGuestContentHelper;
  //     }
  //   }
  //   let content2 = {...content, time_liked:firestore.Timestamp.now()}
  //   if(content2.PagesSaved === undefined){
  //     content2.PagesSaved = 1;
  //   }
  //   // if op is add then append currentContent obj to likedArrCopy,
  //   if (op === 'add') {
  //     likedArrCopy.push(content2);
  //   } else {
  //     // if not filter out
  //     likedArrCopy = likedArrCopy.filter((obj) => obj.id !== id);
  //   }
  //   //////////onsole.log("LIKED: ", likedArrCopy);
  //   setIsLiked2(likedArrCopy)
  //   // replace the liked array
  //   firestore
  //     .collection('sessions')
  //     .doc(sessionId)
  //     .update({
  //       liked: likedArrCopy
  //     })
  //     .then(() => {
  //       // replace liked in store
  //       setLiked(likedArrCopy);
  //     })
  //     .catch((error) => console.error('ERROR: ', error));
  // };

  // // check if current page is liked already or not
  // const isLiked = () => {
  //   let currentGuestContentId = currentGuestContentHelper.id;
  //   // check if currentGuestContentHelper id is undefined
  //   if (!currentGuestContentId) {
  //     //////////onsole.log('Liked check in full portfolio guest-pass view...');
  //     // check if liked contains currentContent id
  //     return liked.some((obj) => obj.id === currentContent.id);
  //   } else {
  //     // user in briefcase guest view
  //     //////////onsole.log('Liked check in Briefcase guest view...');
  //     if (currentGuestContentId === `splashkey-${chosenPortfolio._id}`) {
  //       // check if liked contains currentContent id for splash
  //       return liked.some((obj) => obj.id === currentContent.id);
  //     } else {
  //       // check if liked contains currentGuestContentId
  //       return liked.some((obj) => obj.id === currentGuestContentId);
  //     }
  //   }
  // };

  // const updateCustomerInfo = (form) => {
  //   // update customer info in store
  //   setPortfolioCustomerInfo(form);

  //   if (sessionId === '') {
  //     return null;
  //   }

  //   const customerReplacement = {
  //     customer_name: form.firstName + ' ' + form.lastName,
  //     customer_email: form.email
  //   };
  //   let viewerLog = [];
  //   let viewerTemplate = {
  //     _type: 'viewerLog'
  //   };

  //   // add main customer
  //   viewerLog.push({
  //     ...viewerTemplate,
  //     email: customerReplacement.customer_email,
  //     name: customerReplacement.customer_name,
  //     _key: Math.random().toString(36).substr(2, 7)
  //   });

  //   // add cc
  //   if (form.ccEmails) {
  //     form.ccEmails.forEach((email) => {
  //       viewerLog.push({
  //         ...viewerTemplate,
  //         email: email,
  //         _key: Math.random().toString(36).substr(2, 7)
  //       });
  //     });
  //   }

  //   // add viewer log to customerReplacement.
  //   customerReplacement['viewer_log'] = viewerLog;

  //   // update customer info in sanity session and firestore
  //   firestore
  //     .collection('sessions')
  //     .doc(sessionId)
  //     .update(customerReplacement)
  //     .then(() => {
  //       // update viewer log info in sanity
  //       updateSessionInSanity({ viewer_log: customerReplacement.viewer_log });
  //       hideModal();
  //     })
  //     .catch((error) => {
  //       console.error('ERROR: ', error);
  //       hideModal();
  //     });
  // };
  // let primaryColor
  // if(chosenPortfolio.action_menu_color)
  // primaryColor =
  // chosenPortfolio.action_menu_color !== ''
  //   ? chosenPortfolio.action_menu_color
  //   : 'gray';
  //   else
  //   primaryColor =
  //   chosenPortfolio.primary_color !== ''
  //   ? chosenPortfolio.primary_color
  //   : 'gray'
  // let secondaryColor =
  // chosenPortfolio.secondary_color !== ''
  //   ? chosenPortfolio.secondary_color
  //   : 'white';
  // let accentColor =
  // chosenPortfolio.accent_color !== ''
  //   ? chosenPortfolio.accent_color
  //   : "black"

  //     let icons;
  //     icons = (
  //         <>
  //           {isAddable && isAuthToAdd && (
  //             <Icons.AddShoppingCart id="Bypass"
  //               className="icon"
  //               onClick={() => {
  //                 addToBriefcase(currentContent);
  //                 // if (!showBriefcase) {
  //                 //   toggleBriefcase();
  //                 // }
  //                 if (showBriefcase === true) {
  //                   setBooleanNewItems(false);
  //                 } else {
  //                   setBooleanNewItems(true);
  //                 }
  //               }}
  //               data-tip="Add to Briefcase"
  //             />
  //           )}
  //           {(isAuthToAdd || isAuthBriefcaseShow || isGuestMode) && (
  //             <Icons.ShoppingCart id="Bypass"
  //               className="icon"
  //               onClick={() => {
  //                 toggleBriefcase();
  //                 setBooleanNewItems(false);
  //               }}
  //               data-tip="Show Briefcase"
  //               briefcaseContent={briefcaseContent}
  //               booleanNewItems={booleanNewItems}
  //             />
  //           )}{!isGuestMode?(
  //           <Icons.PersonAdd id="Bypass"
  //             className="icon"
  //             onClick={showModal}
  //             data-tip="Change Info"
  //           />):null
  //     }
  //           <Icons.FullscreenExit id="Bypass"
  //             className="icon"
  //             onClick={() => setMenuHide(false)}
  //             data-tip="Fullscreen/Windowed Mode"
  //           />
  //           <Icons.NoteAdd
  //           id="Bypass"
  //             className="icon"
  //             onClick={() => toggleNotepad()}
  //             data-tip="Add Note"
  //           />
  //           {mode === 'training' && (
  //             <Icons.SpeakerNotes id="Bypass"
  //               className="icon"
  //               onClick={toggleScriptnote}
  //               data-tip="Speaker Notes"
  //             />
  //           )}
  //           {isGuestMode ? (
  //             isLiked() && isLiked2 ? (
  //               <Icons.Favorite id="Bypass"
  //                 onClick={() => {
  //                   updateFavorite('remove');
  //                 }}
  //               />
  //             ) : (
  //               <Icons.FavoriteBorder id="Bypass"
  //                 onClick={() => {
  //                   updateFavorite('add');
  //                 }}
  //               />
  //             )
  //           ) : null}
  //   {isGuestMode?null:(<Icons.CustomIconOne id="Bypass"
  //           className="icon"
  //           data-tip="Calendar"
  //           ></Icons.CustomIconOne>)}
  //           <Icons.AnnotatorIcon onClick={()=>SET_ANNOTATOR(!ANNOTATOR2)}/>
  //         </>
  //       );
  // const HeadRef = React.useRef();
  //eslint-disable-next-line
  const [Display, SetDisplay] = useState();
  // const [State,SetState] = useState(true);

  // useEffect(()=>{
  //   //`calc(100% - ${HeadRef.current.offsetHeight})`
  //   //////onsole.log("HeadRef.current",HeadRef.current,HeadRef.current.offsetHeight,HeadRef.current.offsetHeight?`calc(100% - ${HeadRef.current.offsetHeight})`:null);
  //   SetState(!State);
  // },[HeadRef.current])
  // const [Anno, SetAnno] = useState(window.innerWidth>450 && window.innerHeight>515)
  //     useEffect(()=>{SetAnno((Size.W>450 && Size.H>515))},[Size.H, Size.W])
  if (!(showNotepad || showScriptnote || isModalOpen)) return null;
  return (
    <>
      <div className="ActionBack">
        {showNotepad && (
          <Test
            SetDisplay={SetDisplay}
            updateSessionWithNotes={updateSessionWithNotes}
          />
        )}
        {/* {showBriefcase && <BC sendClick={sendClick} SetDisplay={SetDisplay} />} */}
        {showScriptnote && <Script SetDisplay={SetDisplay} />}
        {isModalOpen && <PII SetDisplay={SetDisplay}></PII>}
      </div>
    </>
    //  <div className="ToolsIcon" style={{position:"absolute", left:"calc(100% * 36/1920)", bottom:"calc(100% * 32/967)"}} ></div>
  );
};
const mapStateToProps = (state) => ({
  showNotepad: state.notepadReducer.showNotepad,
  showBriefcase: state.briefcaseReducer.showBriefcase,
  mode: state.portfolioReducer.mode,
  pages: state.sanityContentReducer.pages,
  sessionId: state.portfolioReducer.sessionId,
  briefcaseContent: state.briefcaseReducer.briefcaseContent,
  isModalOpen: state.modalReducer.isModalOpen,
  chosenPortfolio: state.portfolioReducer.chosenPortfolio,
  menuHide: state.pageMenuReducer.menuHide,
  isGuestMode: state.guestReducer.isGuestMode,
  currentGuestContentHelper: state.guestReducer.currentContentHelper,
  liked: state.portfolioReducer.liked,
  userInfo: state.firestore.ordered.users,
  Size: state.portfolioReducer.Size,
  booleanNewItems: state.briefcaseReducer.booleanNewItems,
  ANNOTATOR2: state.portfolioReducer.aNNOTATOR,
  showScriptnote: state.scriptnoteReducer.showScriptnote
});
const mapDispatchToProps = (dispatch) => {
  return {
    endPortfolio: () => dispatch(endPortfolio()),
    toggleNotepad: () => dispatch(toggleNotepad()),
    toggleBriefcase: () => dispatch(toggleBriefcase()),
    clearAll: () => dispatch(clearAll()),
    setPortfolioCustomerInfo: (obj) => dispatch(setPortfolioCustomerInfo(obj)),
    setLiked: (array) => dispatch(setLiked(array)),
    setBooleanNewItems: (bool) => dispatch(setBooleanNewItems(bool)),
    addToBriefcase: (obj) => dispatch(addToBriefcase(obj)),
    toggleScriptnote: () => dispatch(toggleScriptnote()),
    updateSessionInSanity: (obj) => dispatch(updateSessionInSanity(obj)),
    SET_ANNOTATOR: (t) => dispatch({ type: 'SET_ANNOTATOR', payload: t }),
    showModal: () => dispatch(showModal())
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ActionMenuToolbar);
