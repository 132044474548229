import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import FontUtil from '../../../utils/FontUtility';

const StyledSubNav = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-height: 100px;
  width: auto;
  max-width: 500px;
  cursor: pointer;
  overflow: hidden;

  @media (max-width: 450px) and (orientation: portrait) {
    max-width: 350px;
  }

  p {
    font-size: 32px;
    font-weight: 600;
    margin-block-start: 0px;
    margin-block-end: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-shadow: 1px 1px 1px #808080;

    @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
      font-size: 22px;
    }

    @media (max-width: 450px) and (orientation: portrait) {
      font-size: 24px;
    }
  }

  .sub {
    font-size: 18px;
    -webkit-line-clamp: 2;

    @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
      font-size: 12px;
    }

    @media (max-width: 450px) and (orientation: portrait) {
      font-size: 14px;
    }
  }
`;

const SubLevelContainer = ({
  divName,
  currentNavItem,
  pages,
  images,
  videos,
  pdfs,
  videoSelected,
  contentDataChoice,
  prepareCurrentGalleryContent,
  onNavClick,
  getColor,
  chosenPortfolio,
  isAtEnd,
  setHasCurr,
  setHasPrev,
  Size,
  transitionedToSubLevel,
  companyConfig
}) => {
  const [isHovered, setIsHovered] = useState(null);

  useEffect(() => {
    if (currentNavItem.children.length < 1) {
      setHasCurr(false);
      setHasPrev(false);
    }
  });
  //FLAG FONT FAMILY

  return (
    <div
      className={`${divName} submenu`}
      style={{
        opacity: transitionedToSubLevel ? 1 : 0,
        justifyContent: 'flex-start'
      }}
    >
      {currentNavItem.children.map((child, index) => {
        let foundPage = pages[child.referenceId];

        return (
          <div
            className="big-nav"
            key={child._key + '-' + index}
            onClick={() => onNavClick(child, index)}
            style={{
              width: 'auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: Size.W < 451 ? '3%' : '1%',
              flexShrink: 0
            }}
          >
            <StyledSubNav
              onMouseEnter={() => setIsHovered(foundPage._key)}
              onMouseLeave={() => setIsHovered(null)}
            >
              <p
                style={{
                  color:
                    isHovered === foundPage._key &&
                    chosenPortfolio?.secondary_color &&
                    chosenPortfolio?.text_color
                      ? `${chosenPortfolio.secondary_color}`
                      : isHovered === foundPage._key &&
                        chosenPortfolio?.text_color
                      ? 'rgb(68, 159, 245)'
                      : chosenPortfolio?.text_color
                      ? `${chosenPortfolio.text_color}`
                      : 'white',
                  fontFamily: FontUtil(
                    chosenPortfolio.ContentText,
                    null,
                    "'Roboto', serif"
                  )
                }}
              >
                {foundPage?.title}
              </p>
              <p
                className="sub"
                style={{
                  color:
                    isHovered === foundPage._key &&
                    chosenPortfolio?.secondary_color &&
                    chosenPortfolio?.text_color
                      ? `${chosenPortfolio.secondary_color}`
                      : isHovered === foundPage._key &&
                        chosenPortfolio?.text_color
                      ? 'rgb(68, 159, 245)'
                      : chosenPortfolio?.text_color
                      ? `${chosenPortfolio.text_color}`
                      : 'white',
                  fontFamily: FontUtil(
                    chosenPortfolio.ContentText,
                    null,
                    "'Roboto', serif"
                  )
                }}
              >
                {foundPage?.subtitle}
              </p>
            </StyledSubNav>
          </div>
        );
      })}
    </div>
  );
};

export default SubLevelContainer;
