import { connect } from 'react-redux';
import MenuSection from './MenuSection';
import {
  setCurrentContentHelper,
  setContentPageId,
  setSelectedTopLevelId,
  setMegamenuLevel,
  SetTopic,
  setTermWithChild,
  setShowTermChild
} from '../../../actions';

const mapStateToProps = (state) => ({
  isGuestMode: state.guestReducer.isGuestMode,
  isPortfolioView: state.guestReducer.isPortfolioView,
  guestContentHelper: state.guestReducer.guestContentHelper,
  companyConfig: state.sanityContentReducer.companyConfig,
  chosenPortfolio: state.portfolioReducer.chosenPortfolio,
  pages: state.portfolioReducer.pages,
  nav: state.portfolioReducer.nav,
  pageMenuReducer: state.pageMenuReducer,
  selectedTopLevelId: state.portfolioReducer.selectedTopLevelId,
  megamenuLevel: state.pageMenuReducer.megamenuLevel,
  Topic: state.pageMenuReducer.Topic
});

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentContentHelper: (id) => dispatch(setCurrentContentHelper(id)),
    setContentPageId: (id) => dispatch(setContentPageId(id)),
    setSelectedTopLevelId: (id) => dispatch(setSelectedTopLevelId(id)),
    setMegamenuLevel: (level) => dispatch(setMegamenuLevel(level)),
    setTopic: (Topic) => dispatch(SetTopic(Topic)),
    setTermWithChild: (bool) => dispatch(setTermWithChild(bool)),
    setShowTermChild: (bool) => dispatch(setShowTermChild(bool))
  };
};

const MenuSectionContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuSection);

export default MenuSectionContainer;
