import { connect } from 'react-redux';
import AdminPortal2 from './AdminPortal2';
import {
  updateSessionKey,
  endPortfolio,
  getUsers,
  createUser,
  patchUser,
  deleteUser,
  getTeams,
  createTeam,
  patchTeam,
  deleteTeam
} from '../../actions';

const mapStateToProps = (state) => ({
  userInfo: state.firestore.ordered.users,
  images: state.sanityContentReducer.images,
  companyConfig: state.sanityContentReducer.companyConfig,
  portfolio: state.sanityContentReducer.portfolios,
  adminReducer: state.adminReducer
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateSessionKey: (key) => dispatch(updateSessionKey(key)),
    endPortfolio: () => dispatch(endPortfolio()),
    getUsers: (companyId) => dispatch(getUsers(companyId)),
    createUser: (obj) => dispatch(createUser(obj)),
    patchUser: (obj) => dispatch(patchUser(obj)),
    deleteUser: (uid) => dispatch(deleteUser(uid)),
    getTeams: (companyId) => dispatch(getTeams(companyId)),
    createTeam: (obj) => dispatch(createTeam(obj)),
    patchTeam: (obj) => dispatch(patchTeam(obj)),
    deleteTeam: (id) => dispatch(deleteTeam(id))
  };
};

const AdminPortal2Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminPortal2);

export default AdminPortal2Container;
