/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react'; //useEffect
// import { useStore } from 'react-redux';
import List from '../MainLeadList';
import LeadCore from './LeadCore/';
import styled from 'styled-components';
import PNG52 from './52X52.png';
import Header from './Subhead';
import NT from '../Notifications';

const CenterGrid = styled.div`
  transition: grid-template-columns 0.5s;
  display: grid;
  grid-column: 1/4;
  grid-template-rows: 10% 90%;
  &.true {
    grid-template-columns: calc(100% * 7.25 / 9) calc(100% * 1.75 / 9);
  }
  &.false {
    grid-template-columns: calc(100% * 8 / 8) calc(100% * 0 / 8);
  }
`;
const ChevBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0769a9;
  position: absolute;
  top: 0.5em;
  left: -40px;
  width: 40px;
  height: 40px;
  color: #268fcc;
  &:hover {
    color: #ffffff;
  }
  mask-image: url(${PNG52});
  mask-size: 100% 100%;
`;
const NotificationBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e21616;
  position: absolute;
  top: 0.5em;
  left: -82px;
  width: 40px;
  height: 40px;
  color: #ffffff;
  &:hover {
    color: #ffffff;
  }
  border-radius: 50%;
  z-index: 2;
`;

// left: -30px;
// width: 20px;
// height:20px;

const Chevron = ({ Spin, style }) => {
  return (
    <svg
      style={{ ...style, transform: Spin ? 'rotate(180deg)' : 'unset' }}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="transparent"
      class="bi bi-chevron-right"
      viewBox="0 0 100 100"
    >
      {/* <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/> */}
      <polyline
        points="70,20 35,50 70,80"
        fill="transparent"
        stroke={'currentColor'}
        strokeWidth={'16px'}
      ></polyline>
    </svg>
  );
};

const CT = ({ DashGL, Dash }) => {
  // const store = useStore()
  ////onsole.log(store.getState().DashboardReducer.ActiveGPL)
  const [show, SetShow] = useState(Dash.NotificationsCount > 0); //SetShow
  const [SubSet, SetSubSet] = useState('');
  const [SelectedSessionIndex2, SetSSI2] = useState(undefined);
  // const[Notifications, SetNotifications] = useState(0)
  // console.log("SetNotifications",SetNotifications)
  useEffect(() => {
    console.log('NOTIFICATIONCOUNT', Dash.NotificationsCount);
  }, [Dash.NotificationsCount]);
  //const test = ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",]
  return (
    <CenterGrid className={`${show}`} style={{}}>
      <Header style={{ gridColumn: '1/1', gridRow: '1/1' }}></Header>
      {DashGL ? (
        <LeadCore
          SelectedSessionIndex2={SelectedSessionIndex2}
          SetSSI2={SetSSI2}
          SubSet={SubSet}
          SetSubSet={SetSubSet}
          style={{ gridColumn: '1/1', gridRow: '2/3' }}
        ></LeadCore>
      ) : (
        <List style={{ gridColumn: '1/1', gridRow: '2/3' }}></List>
      )}
      <div
        style={{
          height: 'calc(100vh / 8.5 * 7)',
          backgroundColor: 'black',
          gridColumn: '2',
          gridRow: '1/3',
          position: 'relative'
        }}
      >
        {Dash.NotificationsCount > 0 ? (
          <NotificationBox
            onClick={(eve) => {
              if (!show === false)
                eve.currentTarget.dispatchEvent(
                  new Event('Hidden', { bubbles: true })
                );
              SetShow(!show);
            }}
          >
            <p style={{ fontSize: '20px', marginBlock: '0px' }}>
              {Dash.NotificationsCount < 10 ? Dash.NotificationsCount : '9+'}
            </p>
          </NotificationBox>
        ) : null}
        <ChevBox
          onClick={(eve) => {
            if (!show === false)
              eve.currentTarget.dispatchEvent(
                new Event('Hidden', { bubbles: true })
              );
            SetShow(!show);
          }}
        >
          <Chevron Spin={show} style={{ position: 'relative', left: '4px' }} />
          <Chevron Spin={show} />
        </ChevBox>
        <NT
          SetSSI2={SetSSI2}
          SetSubSet={SetSubSet}
          style={{
            backgroundColor: '#ffffff',
            overflowY: 'scroll',
            height: 'calc(100vh / 8.5 * 7)',
            width: 'calc(100vw * 1.75 / 9)'
          }}
        />
      </div>
    </CenterGrid>
  );
};
export default CT;
