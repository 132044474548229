import React, { useState, useRef } from 'react'; //useEffect
import { IconContext } from 'react-icons';
//import Icons from '../Icons';
import Button from '../../../components/Button';
//import TextArea from '../../components/TextArea';
import ReactQuill from 'react-quill'; //, {Quill}
import 'react-quill/dist/quill.snow.css';
// import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
// import FontUtil from '../../../utils/FontUtility';
import ArrowBackRounded from '@material-ui/icons/ArrowBackRounded';

const Notepad = ({
  notepadText,
  showNotepad,
  toggleNotepad,
  isGuestMode,
  updateNotepad,
  updateSessionInSanity,
  updateSessionWithNotes,
  chosenPortfolio,
  companyConfig,
  toolbarMenuVisible,
  setToolbarMenuVisibility,
  toolbarMenuOpen,
  setToolbarMenuOpen,
  Size
}) => {
  const [text, setText] = useState('');
  const [saved, setSaved] = useState(false);

  const handleVisibility = () => {
    setToolbarMenuVisibility(true);
  };

  const handleToggle = () => {
    setToolbarMenuOpen(true);
  };

  // useEffect(() => {
  //   setText(notepadText);
  // }, [notepadText]);
  const [copied, setCopied] = useState(false);

  const CopyTxt = () => {
    setSaved(false);
    //////onsole.log("!!SELECTION!!",reftt)
    //////onsole.log("!!SELECTION!!",reftt.current)
    const range = document.createRange();
    ////////onsole.log("CURRENT NODE", clipboardRef.current);
    range.selectNode(reftt.current.editingArea);
    ////////onsole.log("RANGE", range);
    window.getSelection().addRange(range);
    //reftt.current.editingArea.select();

    try {
      // const successful = document.execCommand('copy');
      document.execCommand('copy');
      //const msg = successful ? 'successful' : 'unsuccessful';
      //////onsole.log('Copy email command was ' + msg);
    } catch (err) {
      //////onsole.log('Oops, unable to copy');
    }

    window.getSelection().removeAllRanges();

    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };
  // //https://stackoverflow.com/questions/44656610/download-a-string-as-txt-file-in-react/44661948
  // const downloadTxtFile = () => {
  //   const element = document.createElement('a');
  //   const file = new Blob([text], { type: 'text/plain' });
  //   element.href = URL.createObjectURL(file);
  //   element.download = 'session-notes.txt';
  //   document.body.appendChild(element); // Required for this to work in FireFox
  //   element.click();
  // };

  const handleSaveNotification = () => {
    setCopied(false);
    setSaved(true);
    setTimeout(() => {
      setSaved(false);
    }, 1500);
  };
  // chosenPortfolio.primary_color
  //           ? chosenPortfolio.primary_color
  //           : companyConfig.primary_color,
  const reftt = useRef();
  // Ping Font Family
  return (
    <>
      <style>
        {`
          div.ql-container {
            height: 100%;
            overflow: auto;
          }
          div.quill {
            color: black;
            max-height: 100%;
            display: inline-flex;
            flex-direction: column;
            width: 100%;
            user-select: text;
          }
          div.ql-editor {
            word-wrap: anywhere;
            user-select: text;
          }
          button.ql-link {
            display: none !important;
          }
        `}
      </style>
      <IconContext.Provider
        value={{
          className: 'menu-icon-md',
          style: {
            color: chosenPortfolio.accent_color
              ? chosenPortfolio.accent_color
              : companyConfig.accent_color
          }
        }}
      >
        <div
          style={{
            backgroundColor: chosenPortfolio?.action_menu_color
              ? `${chosenPortfolio.action_menu_color}`
              : '#E2DFDD'
          }}
          className={`portfolio-notepad-container ${
            showNotepad ? 'active' : 'hide'
          }`}
        >
          <div className="notepad-close-container">
            <div className="svg-box">
              <ArrowBackRounded
                onClick={() => {
                  handleToggle();
                  toggleNotepad();
                  handleVisibility();
                }}
                style={{
                  color: chosenPortfolio?.bulk_icon_override
                    ? chosenPortfolio.bulk_icon_override
                    : '#3b3b3bBF'
                }}
              />
            </div>
            <div className="text-box">
              <p
                style={{
                  color: chosenPortfolio?.bulk_icon_override
                    ? chosenPortfolio.bulk_icon_override
                    : '#3b3b3bBF'
                }}
              >
                Notepad
              </p>
            </div>
          </div>
          <div className="textarea-container">
            <div className="quill-container">
              <ReactQuill
                style={{ userSelect: 'text' }}
                ref={reftt}
                theme={'snow'}
                onChange={(e, Delta, Source, Editor) => {
                  //////onsole.log("!!SELECTION!!", e); //////onsole.log("!!SELECTION!!", Editor.getContents());
                  setText(e);
                }}
                value={text}
              ></ReactQuill>
            </div>
            <div
              className="saved-notification"
              style={{
                position: 'absolute',
                backgroundColor: chosenPortfolio?.secondary_color
                  ? '#3b3b3b40'
                  : '#3b3b3b40',
                color: chosenPortfolio?.text_color ? '#3b3b3b' : '#3b3b3b',
                zIndex: 2,
                top: '30%',
                left: 'calc(50% - 110px)',
                paddingLeft: '10px',
                paddingRight: '10px',
                paddingTop: '2px',
                paddingBottom: '2px',
                borderRadius: '5px',
                transition: 'all 0.4s ease',
                height: '50px',
                width: '200px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                visibility: saved ? 'visible' : 'hidden'
              }}
            >
              {isGuestMode ? 'Notes Sent' : 'Notes Saved'}
            </div>
            <div
              className="copied-notification"
              style={{
                position: 'absolute',
                backgroundColor: chosenPortfolio?.secondary_color
                  ? '#3b3b3b40'
                  : '#3b3b3b40',
                color: chosenPortfolio?.text_color ? '#3b3b3b' : '#3b3b3b',
                zIndex: 2,
                top: '30%',
                left: 'calc(50% - 110px)',
                paddingLeft: '10px',
                paddingRight: '10px',
                paddingTop: '2px',
                paddingBottom: '2px',
                borderRadius: '5px',
                transition: 'all 0.4s ease',
                height: '50px',
                width: '200px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                visibility: copied ? 'visible' : 'hidden'
              }}
            >
              Copied to Clipboard
            </div>
            <div className="notepad-button-container-8">
              <Button
                enlarge={true}
                additionalClassName="notepad-save-button"
                width="100%"
                height="45px"
                style={{
                  color: chosenPortfolio?.text_color
                    ? chosenPortfolio.text_color
                    : 'white',
                  backgroundColor: chosenPortfolio?.accent_color
                    ? chosenPortfolio.accent_color
                    : '#3b3b3b80'
                }}
                // backgroundColor={chosenPortfolio?.secondary_color ? chosenPortfolio.secondary_color : '#449FF5'}
                // color={chosenPortfolio?.text_color ? chosenPortfolio.text_color : 'white'}
                text={isGuestMode ? 'Send' : 'Save'}
                onClick={async () => {
                  handleSaveNotification();
                  updateNotepad(text);
                  setText('');
                  //updateSessionWithNotes(text);
                  // await updateSessionInSanity({ notes: text });
                  // await updateSessionInSanity({ needsNotesEmailSending: true });
                  if (Size.W < 451) {
                    toggleNotepad();
                    setToolbarMenuOpen(false);
                    setToolbarMenuVisibility(true);
                  }
                }}
              />
              <Button
                enlarge={true}
                additionalClassName="notepad-download-button"
                style={{
                  color: chosenPortfolio?.text_color
                    ? chosenPortfolio.text_color
                    : 'white',
                  backgroundColor: chosenPortfolio?.accent_color
                    ? chosenPortfolio.accent_color
                    : '#3b3b3b80'
                }}
                width="100%"
                height="45px"
                // backgroundColor={chosenPortfolio?.secondary_color ? chosenPortfolio.secondary_color : '#449FF5'}
                // color={chosenPortfolio?.text_color ? chosenPortfolio.text_color : 'white'}
                text="Copy"
                onClick={CopyTxt}
              />
            </div>
          </div>
        </div>
      </IconContext.Provider>
    </>
  );
};

export default Notepad;
