import { connect } from 'react-redux';
import EngagePage from './EngagePage';
import {
  updateSessionKey,
  setPortfolioMode,
  resetPortfolioMode,
  setPortfolioSessionId,
  addSessionToSanity,
  lastPrepPortfolio,
  resetPortfolioMidSession,
  clearAll,
  SetSessionMasterCollection,
  SetGuestPassMasterCollection,
  SetGuestpassSessionMasterCollection,
  setUsersPool,
  setSelectedUser,
  setLoginInputSelected,
  setLoginInputSelectedCount
} from '../../actions';
import { PrepPortfolioPIIPage } from '../../actions/portfolioActions';

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  userInfo: state.firestore.ordered.users,
  portfolio: state.sanityContentReducer.portfolios,
  companyConfig: state.sanityContentReducer.companyConfig,
  images: state.sanityContentReducer.images,
  member: state.sanityContentReducer.member,
  mode: state.portfolioReducer.mode,
  chosenPortfolioId: state.portfolioReducer.chosenPortfolioId,
  backgroundImageUrl: state.portfolioReducer.backgroundImageUrl,
  PIIBackgroundImageUrl: state.portfolioReducer.PIIBackgroundImageUrl,
  portfolioIntroImageUrl: state.portfolioReducer.portfolioIntroImageUrl,
  Dash: state.DashboardReducer,
  loginInputSelected: state.portfolioReducer.loginInputSelected,
  loginInputSelectedCount: state.portfolioReducer.selectedCount,
  Size: state.portfolioReducer.Size
});

const mapDispatchToProps = (dispatch) => {
  return {
    RPMS: () => dispatch(resetPortfolioMidSession()),
    RPS: () => dispatch(resetPortfolioMode()),
    updateSessionKey: (key) => dispatch(updateSessionKey(key)),
    setPortfolioMode: (mode) => dispatch(setPortfolioMode(mode)),
    // setPortfolioId: (id) => dispatch(setPortfolioId(id)),
    setPortfolioId: (id) => dispatch(PrepPortfolioPIIPage(id)),
    setPortfolioSessionId: (id) => dispatch(setPortfolioSessionId(id)),
    addSessionToSanity: (session) => dispatch(addSessionToSanity(session)),
    lastPrepPortfolio: (form) => dispatch(lastPrepPortfolio(form)),
    clearAll: () => dispatch(clearAll()),
    setSessionMasterCollection: (UI, firestore) =>
      dispatch(SetSessionMasterCollection(UI, firestore)),
    setGuestPassMasterCollection: (userInfo, firestore) =>
      dispatch(SetGuestPassMasterCollection(userInfo, firestore)),
    SetGuestpassSessionMasterCollection: (userInfo, firestore) =>
      dispatch(SetGuestpassSessionMasterCollection(userInfo, firestore)),
    setUsersPool: (userInfo, firestore) =>
      dispatch(setUsersPool(userInfo, firestore)),
    setSelectedUser: (user) => dispatch(setSelectedUser(user)),
    setLoginInputSelected: (bool) => dispatch(setLoginInputSelected(bool)),
    setLoginInputSelectedCount: (num) =>
      dispatch(setLoginInputSelectedCount(num))
  };
};

const EngagePageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EngagePage);

export default EngagePageContainer;
