import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import InputBox from '../../InputBox';
import Button from '../../Button';
import LoginContext from '../../../containers/LoginPage/LoginContext';

const StyledLoginForm = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  input {
    margin-bottom: 20px;
  }
  button {
    margin-bottom: 10px;
  }

  p {
    text-align: center;
    font-size: 14px;
    margin: 0;
    padding: 0;
  }
  //height:360px;
  padding: 24px;
  background-color: white;
  border-radius: 24px;
  justify-content: center;
`;

const StyledLoginError = styled.div`
  color: red;
`;

const StyledLink = styled.p`
  cursor: pointer;
  text-decoration: underline;
`;

const LoginForm = ({
  setForgotPassMode,
  setLoginInputSelected,
  setLoginInputSelectedCount,
  loginInputSelected,
  loginInputSelectedCount,
  Size
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginErrorMsg, setLoginErrorMsg] = useState('');
  const firebase = useFirebase();
  const firestore = useFirestore();

  const loginContext = useContext(LoginContext);
  // set in fireStore
  const fireStoreSet = (collection, doc, obj) => {
    // start request status
    loginContext.firestoreRequest();

    // connect firestore collection
    firestore
      .collection(collection)
      .doc(doc)
      .set(obj, { merge: true })
      .then((e) => {
        // success
        //////////onsole.log('Firestore set success');
        loginContext.firestoreSuccess();
      })
      .catch((error) => {
        console.error(error);
        setLoginErrorMsg(error);
        loginContext.firestoreFailure();
      });
  };

  // handle form submit
  const handleSubmit = (event) => {
    event.preventDefault();

    // set persistance to SESSION
    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => {
        firebase
          .login({
            email: email,
            password: password
          })
          .then((e) => {
            //get current user
            let userId = firebase.auth().currentUser.uid;
            // create random key
            let randomKey = Date.now();
            let localSessionKey = localStorage.getItem('sessionKey');
            // check if 'sessionKey' exists
            if (!localSessionKey) {
              // if doesn't exists use replace with randomKey
              localSessionKey = randomKey;
            }

            // update localStorage
            localStorage.setItem('sessionKey', localSessionKey);
            // update reducer for login
            loginContext.updateSessionKey(localSessionKey);

            // save key and udpate date to user database
            fireStoreSet('users', userId, {
              session_key: localSessionKey,
              updated_at: firebase.firestore.Timestamp.now()
            });
          })
          .catch((error) => {
            console.error(error);
            setLoginErrorMsg(error.message);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const closeFilter = (event) => {
    if (event.target && event.target.id !== 'bypass') {
      closeActFilter();
    } else {
      setLoginInputSelected(true);
    }
  };

  const closeActFilter = () => {
    let newCount = loginInputSelectedCount + 1;
    //////onsole.log("CURRENT COUNT", newCount);
    setLoginInputSelectedCount(newCount);
    setLoginInputSelected(false);
    window.removeEventListener('click', closeFilter);
  };

  const toggleFilter = () => {
    if (Size.W >= 1367) {
      return;
    }
    if (loginInputSelected === false) {
      window.setTimeout(() => {
        window.addEventListener('click', closeFilter);
      }, 50);
    }
    setLoginInputSelected(true);
  };

  return (
    <StyledLoginForm onSubmit={handleSubmit}>
      <InputBox
        name="email"
        placeholder="Email"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        onClick={() => toggleFilter()}
        style={{
          borderTop: 'none',
          borderRight: 'none',
          borderLeft: 'none',
          borderRadius: '0px',
          margin: '1%',
          borderColor: 'gray'
        }}
        id="bypass"
        autocomplete="email"
      />
      <InputBox
        name="password"
        placeholder="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        onClick={() => toggleFilter()}
        style={{
          borderTop: 'none',
          borderRight: 'none',
          borderLeft: 'none',
          borderRadius: '0px',
          margin: '1%',
          borderColor: 'gray'
        }}
        id="bypass"
        autocomplete="password"
      />
      <Button
        darken={true}
        type="submit"
        text="Login"
        backgroundColor="transparent"
        color="rgb(200,200,200)"
        style={{
          marginTop: '24px',
          borderStyle: 'solid',
          borderColor: 'rgb(200,200,200)',
          borderRadius: '24px'
        }}
      />
      <StyledLoginError>
        {loginErrorMsg === 'The email address is badly formatted.'
          ? 'Please enter a valid email.'
          : loginErrorMsg}
      </StyledLoginError>
      {/* <p>Click below to reset password</p> */}
      <StyledLink onClick={() => setForgotPassMode(true)}>
        Reset Password
      </StyledLink>
    </StyledLoginForm>
  );
};

LoginForm.propTypes = {
  setForgotPassMode: PropTypes.func
};

export default LoginForm;
