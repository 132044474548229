import React from 'react';
import TMG3 from '../TerminalMenuGalleryC';
import TMG from '../TerminalMenuGallery';

const Controller = ({
  pages,
  back,
  currentContent,
  createSetCurrentContent,
  SetSingleItem,
  chosenContentPageId,
  nav,
  navHistory,
  sendClick,
  shareClick
}) => {
  //onsole.log("!!#NAVC",pages[chosenContentPageId])
  if (pages[chosenContentPageId]?.StoryBookObjects?.AlternateGalleryStoryBook) {
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          gridRow: '2/2',
          top: '0px',
          left: '0px',
          position: 'absolute',
          overflow: 'hidden',
          overscrollBehavior: 'contain'
        }}
      >
        <TMG3
          shareClick={shareClick}
          sendClick={sendClick}
          Background={nav.items[navHistory[0]]}
          back={back}
          currentContent={currentContent}
          createSetCurrentContent={createSetCurrentContent}
          Single={SetSingleItem}
        ></TMG3>
      </div>
    );
  } else {
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          gridRow: '2/2',
          top: '0px',
          left: '0px',
          position: 'absolute',
          overflow: 'hidden',
          overscrollBehavior: 'contain'
        }}
      >
        <TMG
          shareClick={shareClick}
          sendClick={sendClick}
          back={back}
          currentContent={currentContent}
          createSetCurrentContent={createSetCurrentContent}
          Single={SetSingleItem}
        ></TMG>
      </div>
    );
  }
};
export default Controller;
