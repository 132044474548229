import React, { useEffect } from 'react'; //useEffect
import styled from 'styled-components';
// import Icons from '../../../../../containers/Icons';
import MdFavoriteBorder from '../../../../../assets/Icons/Icons V1 V1 V1-22.png';
import MdNoteAdd from '../../../../../assets/Icons/white notepad icon-32.png';
// import { Width } from '../../../../../utils/Scalars';
import ExitApp from '../../../../../assets/Icons/Icons V1 V1 V1-19.png';
import MainGrid from './MainBody/MainGrid';
import Cursor from '../../../../../assets/RootSVGS/SessionCursor';
// import TestSearch from './LeadSearch'
const NG = styled.div`
  display: grid;
  grid-template-rows: 96px calc(100% - 96px);
  height: 100%;
`;
//color:"white", margin:"24px", borderRadius:"48px", display:"grid",gridTemplateRows:"30% 70%"
const CoreBlock = styled.div`
  color: #fff;
  margin: 24px;
  border-radius: 48px;
  display: grid;
  grid-template-rows: 30% 70%;
  transition: all 0.2s;
  border: solid;
  border-color: transparent;
  border-width: 4px;
  &:hover {
    border-color: black;

    box-shadow: 2px 2px 4px #000;
  }
`;
const CoreBlockhidable = styled.div`
  color: #fff;
  margin: 24px;
  border-radius: 48px;
  display: grid;
  grid-template-rows: 30% 70%;
  transition: all 0.2s;
  border: solid;
  border-color: transparent;
  background-color: transparent;
  border-width: 4px;
  pointer-events: none;
  &:hover {
    border-color: black;

    box-shadow: 2px 2px 4px #000;
  }
`;
const Downloadico = styled.span`
  mask-size: 100% 100%;
  webkit-mask-size: 100% 100%;
  height: 44px;
  width: 44px;
  position: absolute;
  top: 17px;
  // left: 8px;
  background: black none repeat scroll 0% 0%;
  transform: rotate(90deg);
  display: inline-block;
  &:hover {
    background: #0769a9 none repeat scroll 0% 0%;
  }
`;
const Downloadico2 = styled.span`
  mask-size: 100% 100%;
  webkit-mask-size: 100% 100%;
  height: 44px;
  width: 44px;
  position: absolute;
  top: 17px;
  // left: 8px;
  background: black none repeat scroll 0% 0%;
  transform: rotate(90deg);
  display: inline-block;
`;

// color:"white", margin:"24px", borderRadius:"48px", display:"grid",gridTemplateRows:"30% 70%"
// color:"white", margin:"24px", borderRadius:"48px", display:"grid",gridTemplateRows:"30% 70%"
// color:"white", margin:"24px", borderRadius:"48px", display:"grid",gridTemplateRows:"30% 70%"

const LH = ({ DashGL }) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          left: '48px',
          width: 'calc(100% - 48px)',
          flexWrap: 'wrap'
        }}
      >
        <div
          style={{
            height: '100%',
            flexShrink: 0,
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <p style={{ fontSize: '24px', marginBlock: '0px' }}>{DashGL.Name}</p>
          <p style={{ marginBlockStart: '5px' }}>
            {DashGL.company} | {DashGL.Email}{' '}
            <Downloadico
              onClick={() => {
                let output = '';
                output = output.concat(
                  'Name,Email,Company,Sessions,Duration,Likes,Date,Comments,\n'
                );
                output = output.concat(
                  `${DashGL.Name},${DashGL.Email},${DashGL.company},${
                    DashGL.SessionsCount
                  },${DashGL.Duration},${
                    DashGL.Likes
                  },${DashGL.Date.toString()},${DashGL.Notes},\n`
                );
                const file = new Blob([output], { type: 'text/plain' });
                const element = document.createElement('a');
                element.href = URL.createObjectURL(file);
                element.download = `${DashGL.Name}.csv`;
                document.body.appendChild(element); // Required for this to work in FireFox
                element.click();
              }}
              style={{
                maskImage: `url(${ExitApp})`,
                WebkitMaskImage: `url(${ExitApp})`
              }}
            ></Downloadico>{' '}
          </p>{' '}
        </div>
        {/* <TestSearch style={{position:'absolute', top:'0px', right:"12px", width:"288px", height:"36px"}}></TestSearch> */}
        {/* <div style={{width:"180px", height:"48px", backgroundColor:"red", position:"absolute", top:'0px',right:"15px"}}></div> */}
      </div>
    </>
  );
};
const Core = ({
  style,
  DashGL,
  Dash,
  SubSet,
  SetSubSet,
  SetSSI2,
  SelectedSessionIndex2
}) => {
  // const [SubSet,SetSubSet] = useState("");
  useEffect(() => {
    console.log(DashGL);
  }, [DashGL]);
  //onsole.log(SubSet)
  let core;
  switch (SubSet) {
    case 'Sessions':
      core = (
        <MainGrid
          SelectedSessionIndex2={SelectedSessionIndex2}
          SetSSI2={SetSSI2}
          Dash={Dash}
          SubSet={SubSet}
          SetSubSet={SetSubSet}
          DashGL={DashGL}
        />
      );
      break;
    case 'Likes':
      core = (
        <MainGrid
          SelectedSessionIndex2={SelectedSessionIndex2}
          SetSSI2={SetSSI2}
          Dash={Dash}
          SubSet={SubSet}
          SetSubSet={SetSubSet}
          DashGL={DashGL}
        />
      );
      break;
    case 'Comments':
      core = (
        <MainGrid
          SelectedSessionIndex2={SelectedSessionIndex2}
          SetSSI2={SetSSI2}
          Dash={Dash}
          SubSet={SubSet}
          SetSubSet={SetSubSet}
          DashGL={DashGL}
        />
      );
      break;

    default:
      core = (
        <div
          style={{
            display: 'grid',
            gap: '2px',
            gridTemplateColumns: '50% 50%',
            gridTemplateRows: '50% 50%'
          }}
        >
          {/* <CoreBlock style={{backgroundColor:'#0769a9', gridRow:'1/3', gridColumn:"1/1" }} onClick={()=>SetSubSet("Sessions")}> */}
          {/* display:"flex", justifyContent:'center', alignItems:"center", position:"relative", flexDirection:"column" */}
          {/* <p style={{fontSize:"24px", flexShrink:0, marginBlock:"0px", placeSelf:"center"}}><Cursor style={{height:'30px',position:"relative",top:"7px",right:"7px"}}/>Sessions</p>
                    <p style={{fontSize:"96px", flexShrink:0, marginBlock:"0px", placeSelf:"center"}}>{DashGL.SessionsCount}</p> */}
          {/* </CoreBlock> */}
          <CoreBlock
            style={{
              gridRow: '1/1',
              gridColumn: '1/1',
              backgroundColor: '#0769a9'
            }}
            onClick={() => SetSubSet('Sessions')}
          >
            {/* display:"flex", justifyContent:'center', alignItems:"center", position:"relative", flexDirection:"column" */}
            <p
              style={{
                fontSize: '24px',
                flexShrink: 0,
                marginBlock: '0px',
                placeSelf: 'center'
              }}
            >
              <Cursor
                style={{
                  height: '30px',
                  position: 'relative',
                  top: '7px',
                  right: '7px'
                }}
              />
              Sessions
            </p>
            <p
              style={{
                fontSize: '96px',
                flexShrink: 0,
                marginBlock: '0px',
                placeSelf: 'center'
              }}
            >
              {DashGL.SessionsCount}
            </p>
          </CoreBlock>
          <CoreBlockhidable
            style={{
              gridRow: '2/2',
              gridColumn: '1/1',
              backgroundColor: '#a6a6a6'
            }}
          >
            {/* display:"flex", justifyContent:'center', alignItems:"center", position:"relative", flexDirection:"column" */}
            <p
              style={{
                fontSize: '24px',
                flexShrink: 0,
                marginBlock: '0px',
                placeSelf: 'center'
              }}
            >
              View Time
            </p>
            {/* <Cursor style={{height:'30px',position:"relative",top:"7px",right:"7px"}}/> */}
            <p
              style={{
                fontSize: '96px',
                flexShrink: 0,
                marginBlock: '0px',
                placeSelf: 'center'
              }}
            >
              {DashGL.Duration}
            </p>
          </CoreBlockhidable>
          <CoreBlock
            style={{
              backgroundColor: '#FF923A',
              gridRow: '1/1',
              gridColumn: '2/2'
            }}
            onClick={() => SetSubSet('Likes')}
          >
            <p
              style={{
                fontSize: '24px',
                flexShrink: 0,
                marginBlock: '0px',
                placeSelf: 'center'
              }}
            >
              <Downloadico2
                style={{
                  maskImage: `url(${MdFavoriteBorder})`,
                  WebkitMaskImage: `url(${MdFavoriteBorder})`,
                  position: 'relative',
                  transform: 'none',
                  backgroundColor: 'white',
                  top: '12px'
                }}
              />
              Likes
            </p>
            <p
              style={{
                fontSize: '96px',
                flexShrink: 0,
                marginBlock: '0px',
                placeSelf: 'center'
              }}
            >
              {DashGL.Likes}
            </p>
          </CoreBlock>
          <CoreBlock
            style={{
              backgroundColor: '#26CBB1',
              gridRow: '2/2',
              gridColumn: '2/2'
            }}
            onClick={() => SetSubSet('Comments')}
          >
            <p
              style={{
                fontSize: '24px',
                flexShrink: 0,
                marginBlock: '0px',
                placeSelf: 'center'
              }}
            >
              <Downloadico2
                style={{
                  maskImage: `url(${MdNoteAdd})`,
                  WebkitMaskImage: `url(${MdNoteAdd})`,
                  position: 'relative',
                  transform: 'none',
                  backgroundColor: 'white',
                  top: '12px'
                }}
              />
              Comments
            </p>
            <p
              style={{
                fontSize: '96px',
                flexShrink: 0,
                marginBlock: '0px',
                placeSelf: 'center'
              }}
            >
              {DashGL.Notes}
            </p>
          </CoreBlock>
        </div>
      );
      break;
  }
  return (
    <NG style={{ ...style }}>
      <LH DashGL={DashGL} />
      {core}
    </NG>
  );
};
export default Core;
