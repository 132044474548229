import React, { useState } from 'react';
import Profile from './ProfileUpdateUpload';
// import Plan from './Plan-Addon';
import UserInput from './UserInformationInput';

const Account = ({ member, userInfo }) => {
  const [PhotoUploadVis, SetPhotoUploadVis] = useState(false);

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        height: '100%'
      }}
    >
      <div
        style={{
          gridColumn: '1 / span 1',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Profile UpState={PhotoUploadVis} SetUpState={SetPhotoUploadVis} />
      </div>
      <div
        style={{
          gridColumn: '2 / span 1',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {/* <Plan/> */}
        <UserInput />
      </div>
    </div>
  );
};
export default Account;
