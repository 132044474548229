import React from 'react';
import PortfolioContent from '../../_Portfolio/PortfolioContent';
import { urlFor } from '../../../utils/sanityHelper';
import GalleryThumbsContent from '../GalleryThumbsContent';

const NavItemDisplay = ({
  divName,
  currentNavItem,
  pages,
  images,
  videos,
  pdfs,
  videoSelected,
  contentDataChoice,
  prepareCurrentGalleryContent,
  onNavClick,
  getColor,
  chosenPortfolio
}) => {
  let extraTile = null;
  if (
    currentNavItem?.children?.length === 3 ||
    currentNavItem?.children?.length === 5 ||
    currentNavItem?.children?.length === 7
  ) {
    extraTile = (
      <div className="big-nav-extra">
        {chosenPortfolio.portfolio_logo.asset._ref ? (
          <div className="logo-extra">
            <img
              src={urlFor(chosenPortfolio.portfolio_logo.asset._ref)
                .auto('format')
                .width(400)
                .url()}
              alt="logo"
            />
          </div>
        ) : null}
      </div>
    );
  } else {
    extraTile = null;
  }

  return (
    <div
      className={`${divName} content-count-${currentNavItem?.children?.length}`}
    >
      {currentNavItem?.children?.map((child, index) => {
        let foundPage = pages[child.referenceId];
        let isSingleImage =
          foundPage._type === 'gallery' &&
          foundPage.content &&
          foundPage.content.length === 1 &&
          foundPage.content[0]._type === 'galleryImage';
        let isSingleVideo =
          foundPage._type === 'gallery' &&
          foundPage.content &&
          foundPage.content.length === 1 &&
          foundPage.content[0]._type === 'galleryVideo';

        return isSingleImage || isSingleVideo ? (
          <div
            className="big-nav"
            key={child._key + '-' + index}
            onClick={() => onNavClick(child, index)}
          >
            <PortfolioContent
              contentType={pages[child.referenceId]._type}
              contents={contentDataChoice(child.referenceId)}
              page={pages[child.referenceId]}
              images={images}
              videos={videos}
              pdfs={pdfs}
              prepareCurrentGalleryContent={prepareCurrentGalleryContent}
              videoSelected={videoSelected}
              chosenPortfolio={chosenPortfolio}
              foundPage={foundPage}
              currentNavItem={currentNavItem}
            />
          </div>
        ) : (
          <GalleryThumbsContent
            key={child._key + '-' + index}
            child={child}
            index={index}
            contentType={pages[child.referenceId]._type}
            contents={contentDataChoice(child.referenceId)}
            page={pages[child.referenceId]}
            images={images}
            videos={videos}
            pdfs={pdfs}
            prepareCurrentGalleryContent={prepareCurrentGalleryContent}
            videoSelected={videoSelected}
            chosenPortfolio={chosenPortfolio}
            foundPage={foundPage}
            onNavClick={onNavClick}
            currentNavItem={currentNavItem}
          />
        );
      })}
      {extraTile}
    </div>
  );
};
export default NavItemDisplay;
