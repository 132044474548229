/**
 * Input field with adjustable width and height
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Test from '../../utils/InputBox2';

const StyleInputField = styled.input`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  font-size: 14px;
  border: solid 1px #343434;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 12px;

  // if not valid set the border color red
  ${(props) => (!props.isValid ? 'border-color: red;' : '')};

  ::placeholder {
    font-size: 14px;
  }
  &:focus {
    ::placeholder {
      font-size: 0px;
    }
  }
`;

StyleInputField.displayName = 'StyleInputField';

const InputBox = ({
  Pos,
  name,
  value,
  onChange,
  placeholder,
  type,
  width,
  height,
  isValid,
  required,
  style,
  onClick,
  autocomplete,
  id
}) => {
  //onsole.log(autocomplete)
  const Ref = React.useRef();
  return (
    <Test
      Pos={Pos}
      IRef={Ref}
      input={
        <StyleInputField
          name={name}
          value={value}
          placeholder={placeholder}
          width={width}
          height={height}
          type={type}
          onChange={onChange}
          onClick={onClick}
          isValid={isValid}
          required={required}
          style={{ ...style, paddingRight: '28px' }}
          id={id}
          ref={Ref}
          autoComplete={autocomplete}
        />
      }
    ></Test>
  );
};

InputBox.defaultProps = {
  width: '300px',
  height: '42px',
  isValid: true,
  required: false
};

InputBox.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.string,
  height: PropTypes.string,
  isValid: PropTypes.bool,
  required: PropTypes.bool,
  Pos: PropTypes.object
};

export default InputBox;
