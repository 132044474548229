import { connect } from 'react-redux';
import MasterMenu from './MasterMenu';
import {
  SetTopic,
  setMasterMenuOpen,
  setLiked,
  toggleNotepad
} from '../../../actions';

const mapStateToProps = (state) => ({
  companyConfig: state.sanityContentReducer.companyConfig,
  chosenPortfolio: state.portfolioReducer.chosenPortfolio,
  Topic: state.pageMenuReducer.Topic,
  extendMenu: state.portfolioReducer.masterMenuOpen,
  toolbarMenuOpen: state.portfolioReducer.toolbarMenuOpen,
  Size: state.portfolioReducer.Size,
  isGuestMode: state.guestReducer.isGuestMode,
  liked: state.portfolioReducer.liked,
  currentGuestContentHelper: state.guestReducer.currentContentHelper,
  sessionId: state.portfolioReducer.sessionId,
  briefcaseContent: state.briefcaseReducer.briefcaseContent,
  booleanNewItems: state.briefcaseReducer.booleanNewItems
});

const mapDispatchToProps = (dispatch) => {
  return {
    setTopic: (Topic) => dispatch(SetTopic(Topic)),
    setExtendMenu: (bool) => dispatch(setMasterMenuOpen(bool)),
    setLiked: (array) => dispatch(setLiked(array)),
    toggleNotepad: () => dispatch(toggleNotepad())
  };
};

const MasterMenuContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MasterMenu);

export default MasterMenuContainer;
