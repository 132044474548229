import React, { useEffect } from 'react'; //useState
import MaterialTable, { MTableBodyRow } from 'material-table'; //MTableEditField
import tableIcons from '../../../../utils/materialTableHelper';
// import PortfolioSelect from '../../PortfolioSelect' //'../../PortfolioSelect/index.js';
import styled from 'styled-components';
import ExitApp from '../../../../assets/Icons/Icons V1 V1 V1-19.png';
// import * as Scale from '../../../../utils/Scalars';
//import ToggleBar from './Togglebar';
// import GPD from './GuestPassSelectList';
// import Comments from './Comments';
// import Graph from './TotalTimeSpentGraph';
// import Summary from './Summary';
import { useFirestore } from 'react-redux-firebase';
// import Likes from './Likes';
// import styled from 'styled-components';
// import SoloCompany from './Togglebar/CompanySolo';
// import DateSelect from '../../DateSelect';
// import Logo from'./Portfolio_guestpassAsset-11@3x.png';
// import T3 from '../Notes/NoteReadoutTest/Test'
const Button = styled.div`
  top: 12px;
  height: 36px;
  position: relative;
  display: flex;
  justify-content: center;
  width: 288px;
  border: solid;
  border-width: 1px;
  background: #fcfafa;
  &:hover {
    background: #e6e6e6;
    filter: drop-shadow(1.5px 1.5px 2.5px #808080);
  }
  > p {
    margin-block: 5px;
  }
`;
//drop-shadow(-1.5px -1.5px 2.5px #808080);
const List = ({
  Dash,
  selectedUser,
  SGPFC,
  SGPMC,
  SSMC,
  SGPSMC,
  SFGPS,
  SFS,
  SLGP,
  SLV,
  style
}) => {
  const firestore = useFirestore();

  // const [Comment,SetComment] = useState("");
  // const [Meta,SetMeta] = useState("");

  //MasterCollection Population pattern
  //Used to initialy populate the guestpass master collection if not already populated, this is populated with Guestpass options
  useEffect(() => {
    ////////onsole.log("!!!DASHGP!!!",Dash.GuestpassMasterCollection)
    if (Dash.GuestpassMasterCollection === null) return;
    if (Dash.GuestpassMasterCollection.length === 0)
      SGPMC(selectedUser, firestore);
  }, [Dash.GuestpassMasterCollection, SGPMC, firestore, selectedUser]);

  //MasterCollection Population pattern
  //Used to initialy populate the Session master collection if not already populated, this is populated with session options
  useEffect(() => {
    ////////onsole.log("!!!DASHS!!!",Dash.SessionMasterCollection)
    if (Dash.SessionMasterCollection === null) return;
    if (Dash.SessionMasterCollection.length === 0)
      SSMC(selectedUser, firestore);
  }, [Dash.SessionMasterCollection, SSMC, firestore, selectedUser]);

  //MasterCollection Population pattern
  //Used to initialy populate the guestpass session master collection if not already populated, this is populated with session options.
  useEffect(() => {
    ////////onsole.log("!!!DASHGPS!!!",Dash.GuestpassSessionMasterCollection)
    if (Dash.GuestpassSessionMasterCollection === null) return;
    if (Dash.GuestpassSessionMasterCollection.length === 0)
      SGPSMC(selectedUser, firestore);
  }, [Dash.GuestpassSessionMasterCollection, SGPSMC, firestore, selectedUser]);

  //FilteredCollection Population pattern
  //Designed to update guestPass Filter collection if master collection updates, this is a collection og GuestPasses
  useEffect(() => {
    ////////onsole.log("!!!DASHGP2!!!",Dash.GuestpassFilteredCollection)
    if (Dash.GuestpassMasterCollection === null) return;
    // if(Dash.GuestpassFilteredCollection === null)
    // return
    if (Dash.GuestpassMasterCollection.length === 0) return;
    //else if(Dash.GuestpassFilteredCollection.length === 0){
    SGPFC(selectedUser);
    //}
  }, [Dash.GuestpassMasterCollection, SGPFC, selectedUser]); //Dash.GuestpassFilteredCollection
  //Designed to update guest pass session Filter collection if master collection updates, This contains Session objects.
  useEffect(() => {
    ////////onsole.log("!!!DASHGP2!!!",Dash.GuestpassSessionFilteredCollection)
    if (Dash.GuestpassSessionMasterCollection === null) return;
    // if(Dash.GuestpassSessionFilteredCollection === null)
    // return
    if (Dash.GuestpassSessionMasterCollection.length === 0) return;
    //else if(Dash.GuestpassSessionFilteredCollection?.length === 0){
    SFGPS(selectedUser);
    //}
  }, [Dash.GuestpassSessionMasterCollection, SFGPS, selectedUser]); //Dash.GuestpassSessionFilteredCollection

  //FilteredCollection Population pattern
  //Designed to update Filter collection if master collection updates. Filter session, This is a collection of sessions
  useEffect(() => {
    if (Dash.SessionMasterCollection === null) {
      return;
    }
    if (Dash.SessionMasterCollection.length === 0) {
      return;
    }
    // else if(Dash.SessionFilteredCollection.length === 0){
    SFS(selectedUser);
    //}
  }, [Dash.SessionMasterCollection, SFS, selectedUser]); //Dash.selectedPortfolioId,

  //FilteredCollection Population pattern
  //Designed to update Filter collection if master collection updates. Filter Guest Pass session, This is a collection of sessions
  useEffect(() => {
    if (Dash.GuestpassMasterCollection === null) {
      return;
    }
    SGPFC(selectedUser);
  }, [
    Dash.solo,
    Dash.DateRange,
    Dash.GuestpassMasterCollection,
    SGPFC,
    selectedUser
  ]);

  useEffect(() => {
    if (Dash.GuestpassSessionMasterCollection === null) {
      return;
    }
    SLGP(selectedUser, firestore);
  }, [
    Dash.solo,
    Dash.selectedPortfolioId,
    Dash.DateRange,
    Dash.GuestpassSessionMasterCollection,
    SLGP,
    selectedUser,
    firestore
  ]);
  // useEffect(()=>{},[Dash.selectedPortfolioId])
  useEffect(() => {
    //onsole.log("GPLEADS", Dash.GPLeads)
  }, [Dash.GPLeads]);
  const tableColumns = [
    { title: 'Full Name', field: 'Name' },
    { title: 'Email Address', field: 'Email' },
    { title: 'Company', field: 'company' },
    { title: 'Sessions', field: 'SessionsCount' },
    { title: 'Duration', field: 'Duration' },
    { title: 'Likes', field: 'Likes' },
    { title: 'Date', field: 'Date', type: 'date' },
    { title: 'Comments', field: 'Notes' }
  ];
  return (
    // <div style={{display:"flex", flexDirection:"column",alignItems:"ce9}}>
    <div
      style={{
        display: 'grid',
        gridTemplateRows: '90% 10%',
        ...style,
        height: 'calc((100vh / 8.5 * 7)*.9)'
      }}
    >
      {/* <div style={{gridRow:"1/1"}}>
                <div style={{width:`${96*4}px`, display:"flex", flexDirection:"column"}}>
                    <p>Select Portfolio</p>
                    <PortfolioSelect ></PortfolioSelect>
                </div>
            </div> */}
      <MaterialTable
        style={{
          gridRow: '1/2',
          height: 'calc(((100vh / 8.5 * 7)*.9)*.9)',
          overflow: 'scroll'
        }}
        title=""
        options={{
          draggable: false,
          search: false,
          filtering: false,
          sorting: true,
          actionsColumnIndex: -1,
          paging: false,
          maxBodyHeight: 'calc(((100vh / 8.5 * 7)*.9)*.9)',
          // pageSize: 10,
          // pageSizeOptions: [0, 5, 10, 20, 40],
          headerStyle: { zIndex: 3, fontSize: '1.25em', color: 'black' },
          rowStyle: (rowData) => ({
            color: rowData.disable ? '#AAA' : 'rgba(0,0,0,0.67)'
          })
        }}
        icons={tableIcons}
        editable={false}
        columns={tableColumns}
        onRowClick={(eve, RD, Num) => {
          SLV(RD);
        }} //onsole.log("ROWCLICK",RD,Num);
        components={{
          Row: (RP) => (
            <MTableBodyRow
              {...RP}
              onMouseLeave={(eve) => {
                eve.currentTarget.style.backgroundColor = 'unset';
              }}
              onMouseEnter={(eve) => {
                eve.currentTarget.style.backgroundColor = '#e6e6e6';
              }}
            ></MTableBodyRow>
          )
        }}
        localization={{
          body: {
            emptyDataSourceMessage: 'Loading...'
          }
        }}
        data={Dash.GPLeads.map((obj) => {
          return { ...obj };
        })}
        //   components={{
        //       Row
        //   }}
      ></MaterialTable>
      <div
        style={{ display: 'flex', justifyContent: 'center', gridRow: '2/3' }}
      >
        <Button
          onClick={() => {
            let output = '';
            output = output.concat(
              'Name,Email,Company,Sessions,Duration,Likes,Date,Comments,\n'
            );
            Dash.GPLeads.forEach((ele) => {
              output = output.concat(
                `${ele.Name},${ele.Email},${ele.company},${ele.SessionsCount},${
                  ele.Duration
                },${ele.Likes},${ele.Date.toString()},${ele.Notes},\n`
              );
            });
            const file = new Blob([output], { type: 'text/plain' });
            const element = document.createElement('a');
            element.href = URL.createObjectURL(file);
            element.download = 'Leads.csv';
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
          }}
        >
          <p style={{ alignSelf: 'center' }}>Download all</p>
          <div
            style={{
              maskImage: `url(${ExitApp})`,
              WebkitMaskImage: `url(${ExitApp})`,
              maskSize: '100% 100%',
              WebkitMaskSize: '100% 100%',
              height: '24px',
              width: '24px',
              position: 'absolute',
              top: '6px',
              right: '12px',
              background: 'black',
              transform: 'rotate(90deg)'
            }}
          ></div>
        </Button>
      </div>
    </div>
  );
};
export default List;
