import { connect } from 'react-redux';
import Emails from './Emails';
const mapStateToProps = (state) => ({
  // member: state.sanityContentReducer.member,
  // userInfo: state.firestore.ordered.users,
});
const mapDispatchToProps = (dispatch) => {
  return {};
};

const EmailsContainer = connect(mapStateToProps, mapDispatchToProps)(Emails);

export default EmailsContainer;
