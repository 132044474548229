import React, { useState } from 'react';
import styled from 'styled-components';
import InputBox from '../../InputBox';
import Button from '../../Button';
//import Test from '../../../utils/InputBox2'
import { useFirebase } from 'react-redux-firebase';
//import { useRef } from 'react';

const StyledForgotPasswordForm = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  input {
    //margin-bottom: 20px;
  }
  button {
    margin-bottom: 10px;
  }

  p {
    color: grey;
    font-size: 14px;
  }
`;

const StyledMsg = styled.div`
  color: ${(props) => props.color};
`;

const ForgotPasswordForm = ({ Back }) => {
  const [email, setEmail] = useState('');
  const [responseMsgObj, setResponseMsgObj] = useState({
    text: '',
    color: 'black'
  });
  const firebase = useFirebase();

  // handle form submit
  const handleSubmit = (event) => {
    event.preventDefault();

    //////////onsole.log('handle');
    firebase
      .resetPassword(email)
      .then((e) => {
        //onsole.log(e);
        setResponseMsgObj({ text: 'Email sent', color: 'black' });
      })
      .catch((error) => {
        //////////onsole.log(error);
        setResponseMsgObj({ text: error.message, color: 'red' });
      });
  };
  //const Ref = useRef();
  return (
    <StyledForgotPasswordForm onSubmit={handleSubmit}>
      <p>Enter your email to reset password</p>
      <InputBox
        name="email"
        Pos={{ marginBottom: '20px' }}
        placeholder="Email"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      {/* <Test IRef={Ref} Pos={{marginBottom:'20px'}} input={ */}

      <Button type="submit" text="Send" />
      <StyledMsg color={responseMsgObj.color}>{responseMsgObj.text}</StyledMsg>
      <Button
        text="Back"
        onClick={() => {
          Back();
        }}
      ></Button>
    </StyledForgotPasswordForm>
  );
};

export default ForgotPasswordForm;
