import React from 'react';
const InputBoxClearable = (props) => {
  //  console.log('INPUTProps',props)
  if (!props.input)
    return (
      <div
        style={{ height: 'min-content', position: 'relative', ...props.Pos }}
      >
        <input {...props}></input>
        <p style={{ position: 'absolute', left: '12px' }}></p>
      </div>
    );
  else {
    //  props.input.style = {...props.input.style, 'padding-right':'16px'}
    return (
      <div
        style={{
          height: 'min-content',
          position: 'relative',
          paddingRight: '0px',
          ...props.Pos
        }}
      >
        {props.input}
        <div
          style={{
            position: 'absolute',
            right: '0px',
            top: '0px',
            fontSize: '12px',
            userSelect: 'none',
            marginBlock: '0px',
            height: '100%',
            width: '30px',
            background: '#00000001'
          }}
          onClick={() => {
            //onsole.log(props.IRef.current,props.input.props);
            props.IRef.current.value = '';
            props.input.props.onChange({
              target: { ...props.input.props, value: '' }
            });
            props.IRef.current.focus();
          }}
        ></div>
        <p
          style={{
            position: 'absolute',
            right: '12px',
            top: 'calc(50% - 6px)',
            fontSize: '12px',
            userSelect: 'none',
            marginBlock: '0px'
          }}
          onClick={() => {
            // console.log(props.IRef.current,props.input.props);
            props.IRef.current.value = '';
            props.input.props.onChange({
              target: { ...props.input.props, value: '' }
            });
            props.IRef.current.focus();
          }}
        >
          X
        </p>
      </div>
    );
  }
};
export default InputBoxClearable;
