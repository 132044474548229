import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
// import { urlFor } from '../../../utils/sanityHelper';
// import SanityMuxPlayer from '../../../containers/MuxPlayer';
//import PDFViewer from '../../PDFViewer';
// import PDFViewer2 from '../../FullWidthPDFViewer';
// import BASlider from '../../BeforeAfterSlider'
// import GalleryView from './GalleryView';
import GalleryView from '../../_Portfolio/PortfolioContent/GalleryView';
import serializers from '../../../utils/serializers';
// import ToggleMenu from '../ToggleMenu';
// import SliderGallery from './SliderGallery';
import SliderGallery from '../../_Portfolio/PortfolioContent/SliderGallery';
import PortfolioContentOrThumbnail from '../../../utils/PortfolioContentOrThumbnail';

const PortfolioContent = ({
  contentType,
  videos,
  pdfs,
  contents,
  page,
  videoSelected,
  prepareCurrentGalleryContent,
  chosenPortfolio,
  foundPage,
  currentNavItem,
  isTopLevel,
  isAtEnd,
  isAtTerm,
  Size,
  maxed
}) => {
  //Flag Font Family
  // let imageHeight;
  // let imageWidth;

  // if (chosenPortfolio?.theme === 'theme2' && (!currentNavItem || currentNavItem?.children.length === 1)) {
  //   imageHeight = "80%";
  // } else if (chosenPortfolio?.theme === 'theme2' && currentNavItem?.children.length === 2) {
  //   imageHeight = "60%";
  // } else {
  //   imageHeight = "100%"
  // }

  // if (chosenPortfolio?.theme === 'theme2' && (!currentNavItem || currentNavItem?.children.length === 1)) {
  //   imageWidth = "80%";
  // } else {
  //   imageWidth = "100%";
  // }

  let overlay = null;
  if (isTopLevel) {
    overlay = (
      <section className="top-level-overlay">
        <div>
          <p className="overlay-title">{foundPage?.title}</p>
          <p className="overlay-subtitle">{foundPage?.subtitle}</p>
        </div>
      </section>
    );
  } else if (!isAtEnd && !isTopLevel) {
    overlay = (
      <button
        style={{
          backgroundColor: 'transparent',
          height: '100%',
          width: '100%',
          border: 'none',
          borderColor: chosenPortfolio?.text_color
            ? chosenPortfolio.text_color
            : 'white',
          cursor: 'pointer',
          position: 'absolute',
          zIndex: 2,
          overflow: 'hidden'
        }}
      >
        <p
          style={{
            color: chosenPortfolio?.text_color
              ? chosenPortfolio.text_color
              : 'white',
            marginBlockStart: '0px',
            marginBlockEnd: '0px',
            fontFamily: "'Oswald', sans-serif",
            letterSpacing: '0.1em',
            textShadow: chosenPortfolio?.secondary_color
              ? `-2px 0 ${chosenPortfolio.secondary_color}, 0 2px ${chosenPortfolio.secondary_color}, 2px 0 ${chosenPortfolio.secondary_color}, 0 -2px ${chosenPortfolio.secondary_color}`
              : `-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black`
          }}
        >
          {foundPage?.title}
        </p>
        <p
          style={{
            color: chosenPortfolio?.text_color
              ? chosenPortfolio.text_color
              : 'white',
            marginBlockStart: '0px',
            marginBlockEnd: '0px',
            fontFamily: "'Oswald', sans-serif",
            letterSpacing: '0.085em',
            textShadow: chosenPortfolio?.secondary_color
              ? `-2px 0 ${chosenPortfolio.secondary_color}, 0 2px ${chosenPortfolio.secondary_color}, 2px 0 ${chosenPortfolio.secondary_color}, 0 -2px ${chosenPortfolio.secondary_color}`
              : `-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black`
          }}
        >
          {foundPage?.subtitle}
        </p>
      </button>
    );
  } else {
    overlay = null;
  }

  // const renderSingleContent = (type, galleryNum, isSelected, IsGal,Id,SetBufferFunctions,BufferGal,BufferRender,isVis) => {
  //   switch (type) {
  //     case 'galleryImage':
  //       let image = contents[galleryNum].image;
  //       return (
  //         <img
  //           className="portfolio-single-image"
  //           src={urlFor(image).auto('format').url()} // auto converts to webp if browser supports it
  //           alt="mainImage"
  //           type="galleryImage"
  //           style={{
  //             width: imageWidth,
  //             height: imageHeight,
  //           }}
  //         />
  //       );
  //     case 'galleryVideo':
  //       let video = videos[contents[galleryNum].video.asset._ref];
  //       let videoSettings = contents[galleryNum];
  //       // let Subtitles = contents[galleryNum].subtitles
  //       ////onsole.log("VIDEO SETTINGS", videoSettings);
  //       ////onsole.log("VIDEO IS SELECTED", isSelected);
  //       return (
  //         <SanityMuxPlayer
  //           isContent={true}
  //           assetDocument={video}
  //           subtitles={videoSettings.subtitles}
  //           autoload={true}
  //           autoplay={videoSettings.autoplay}
  //           loop={videoSettings.loop}
  //           SIG={videoSettings.SIG}
  //           SIP={videoSettings.SIP}
  //           muted={false}
  //           showControls={Size?.W > 450 ? true : true}
  //           style={{}}
  //           posterurl={`https://image.mux.com/${video.playbackId}/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=1`}
  //           type="galleryVideo"
  //           isSelected={isSelected}
  //           Size={Size}
  //         />
  //       );
  //     case 'galleryPdf':
  //       let url2 = pdfs[contents[galleryNum].file.asset._ref].url;
  //       let thumbNailRef2 = contents[galleryNum].thumbnail.asset._ref;
  //       let thumbNailUrl2 = urlFor(thumbNailRef2)
  //         .auto('format')
  //         .width(100)
  //         .url();
  //         let thumbNailUrl3 = urlFor(thumbNailRef2)
  //         .auto('format')
  //         .width(1000)
  //         .url();
  //       return (
  //         <PDFViewer2
  //         isVis={isVis}
  //           BufferGal = {BufferGal}
  //           BufferRender = {BufferRender}
  //           SetBufferFunctions={SetBufferFunctions}
  //           IsGal={IsGal}
  //           url={url2}
  //           TThumb ={thumbNailUrl3}
  //           isThumbnail={false}
  //           type="galleryFullWidthPdf"
  //           thumbnailUrl={thumbNailUrl2}
  //         />
  //       );
  //     case 'galleryFullWidthPdf':
  //       let url3 = pdfs[contents[galleryNum].file.asset._ref].url;
  //       let thumbNailRef4 = contents[galleryNum].thumbnail.asset._ref;
  //       let thumbNailUrl4 = urlFor(thumbNailRef4)
  //         .auto('format')
  //         .width(100)
  //         .url();
  //         let thumbNailUrl5 = urlFor(thumbNailRef4)
  //         .auto('format')
  //         .width(1000)
  //         .url();
  //       return (
  //         <PDFViewer2
  //           isVis={isVis}
  //           BufferGal = {BufferGal}
  //           BufferRender = {BufferRender}
  //           SetBufferFunctions={SetBufferFunctions}
  //           IsGal={IsGal}
  //           url={url3}
  //           TThumb ={thumbNailUrl5}
  //           isThumbnail={false}
  //           type="galleryFullWidthPdf"
  //           thumbnailUrl={thumbNailUrl4}
  //         />
  //       );
  //     case 'galleryBASlider':
  //       {
  //         let Aimage = contents[galleryNum].Aimage;
  //         let Bimage = contents[galleryNum].Bimage;
  //         let LabelA;
  //         let LabelB;
  //         if(contents[galleryNum].Labels !== false){
  //         LabelA = contents[galleryNum].AimageL;
  //         LabelB = contents[galleryNum].BimageL;
  //         }
  //         else{
  //         LabelA = '';
  //         LabelB = '';
  //         }
  //         let Vert = contents[galleryNum].Vert;
  //         let Color = contents[galleryNum].SlideColor;
  //         //////onsole.log('!!!!BA!!!!', contents[galleryNum])
  //         return <BASlider LabelB={LabelB} LabelA={LabelA} Color={Color} Vert={Vert} ImageA={Aimage} ImageB={Bimage} override={true} Size={Size}/>
  //       }
  //     case 'toggleMenu':
  //       {
  //         let firstContent = contents[galleryNum].content[0];
  //         let secondContent = contents[galleryNum].content[1];
  //         //////onsole.log('TOGGLE MENU', contents[galleryNum]);
  //         // return;
  //         return (
  //           <ToggleMenu
  //             firstContent={firstContent}
  //             secondContent={secondContent}
  //             videos={videos}
  //             pdfs={pdfs}
  //             currentNavItem={currentNavItem}
  //             contentLength={contents.length}
  //             maxed={maxed}
  //           />
  //         );
  //       }
  //     default:
  //       return <div />;
  //   }
  // };
  // const renderSingleThumb = (type, galleryNum, isSelected) => {
  //   switch (type) {
  //     case 'galleryImage':
  //       let image = contents[galleryNum].image;
  //       return (
  //         <img
  //           className="portfolio-single-image"
  //           src={urlFor(image).auto('format').width(100).url()} // auto converts to webp if browser supports it
  //           alt="mainImage"
  //           type="galleryImage"
  //           style={{
  //             width: imageWidth,
  //             height: imageHeight
  //           }}
  //         />
  //       );
  //     case 'galleryVideo':
  //       let video = videos[contents[galleryNum].video.asset._ref];
  //       let videoSettings = contents[galleryNum];
  //       // let Subtitles = contents[galleryNum].subtitles
  //       ////onsole.log("VIDEO SETTINGS", videoSettings);
  //       return (
  //         <>
  //           <SanityMuxPlayer
  //             assetDocument={video}
  //             autoload={true}
  //             autoplay={false}
  //             subtitles={videoSettings.subtitles}
  //             loop={videoSettings.loop}
  //             muted={false}
  //             // SIG={videoSettings.SIG}
  //             // SIP={videoSettings.SIP}
  //             showControls={false}
  //             style={{}}
  //             posterurl={`https://image.mux.com/${video.playbackId}/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=1`}
  //             type="galleryVideo"
  //             isSelected={isSelected}
  //           />
  //         </>
  //       );
  //     case 'galleryPdf':
  //       let url2 = pdfs[contents[galleryNum].file.asset._ref].url;
  //       let thumbNailRef2 = contents[galleryNum].thumbnail.asset._ref;
  //       let thumbNailUrl2 = urlFor(thumbNailRef2)
  //         .auto('format')
  //         .width(100)
  //         .url();
  //       return (
  //         <PDFViewer2
  //           url={url2}
  //           isThumbnail={true}
  //           type="galleryFullWidthPdf"
  //           thumbnailUrl={thumbNailUrl2}
  //         />
  //       );
  //       case 'galleryBASlider':
  //         {
  //           let Aimage = contents[galleryNum].Aimage;
  //           let Bimage = contents[galleryNum].Bimage;
  //           let LabelA = contents[galleryNum].AimageL;
  //           let LabelB = contents[galleryNum].BimageL;
  //           let Vert = contents[galleryNum].Vert;
  //           let Color = contents[galleryNum].SlideColor;
  //           //////onsole.log('!!!!BA!!!!', contents[galleryNum])
  //           return<BASlider Thumb LabelB={LabelB} LabelA={LabelA} Color={Color} Vert={Vert} ImageA={Aimage} ImageB={Bimage}/>
  //         }
  //     case 'galleryFullWidthPdf':
  //       let url3 = pdfs[contents[galleryNum].file.asset._ref].url;
  //       let thumbNailRef3 = contents[galleryNum].thumbnail.asset._ref;
  //       let thumbNailUrl3 = urlFor(thumbNailRef3)
  //         .auto('format')
  //         .width(100)
  //         .url();
  //       return (
  //         <PDFViewer2
  //           url={url3}
  //           isThumbnail={true}
  //           type="galleryFullWidthPdf"
  //           thumbnailUrl={thumbNailUrl3}
  //         />
  //       );
  //     default:
  //       return <div />;
  //   }
  // };

  // Render thumbnail version
  // const renderThumbnail = (props) => {
  //   switch (props.type) {
  //     case 'galleryImage':
  //       return (
  //         <div className="portfolio-single-image">
  //           <img src={props.src} alt="mainImage" type="image" />
  //         </div>
  //       );
  //     case 'galleryVideo':
  //       return <img src={props.posterurl} alt="poster" />;
  //     case 'galleryFullWidthPdf':
  //       return (
  //         <PDFViewer url={props.thumbnailUrl} isThumbnail={true} type="file" />
  //       );
  //     case 'fullWidthPDF':
  //       return <PDFViewer url={props.url} isThumbnail={true} type="file" />;
  //     default:
  //       return <div />;
  //   }
  // };

  // render nothing if no content
  if (!contents && !page.url) {
    return null;
  }

  // check block content
  const blockContent = (blocks) => {
    // filter non valid imageLinks
    let tempBlocks = blocks.filter((obj) => {
      if (obj._type === 'imageLinks' && obj.image) {
        return true;
      }
      if (obj._type !== 'imageLinks') {
        return true;
      }
      return false;
    });

    // further filter non valid embedHtml
    tempBlocks = tempBlocks.filter((obj) => {
      if (obj._type === 'embedHTML' && obj.html) {
        return true;
      }
      if (obj._type !== 'embedHTML') {
        return true;
      }
      return false;
    });
    return tempBlocks;
  };

  let sliderGalleryDecision;

  if (
    contentType === 'sliderGallery' &&
    contents.length > 1 &&
    ((Size.W > 451 && Size.W < Size.H) ||
      (Size.W > 815 && Size.H > 550 && Size.W > Size.H))
  ) {
    sliderGalleryDecision = (
      <SliderGallery
        gallery={contents}
        page={page}
        chosenPortfolio={chosenPortfolio}
        // renderSingleContent={renderSingleContent}
        // renderThumbnail={renderSingleThumb}
        prepareCurrentGalleryContent={prepareCurrentGalleryContent}
      />
    );
  } else if (contentType === 'sliderGallery' && contents.length > 1) {
    sliderGalleryDecision = (
      <GalleryView
        gallery={contents}
        chosenPortfolio={chosenPortfolio}
        page={page}
        // renderSingleContent={renderSingleContent}
        // renderThumbnail={renderSingleThumb}
        prepareCurrentGalleryContent={prepareCurrentGalleryContent}
      />
    );
  } else {
    sliderGalleryDecision = null;
  }

  return (
    <div
      className={`portfolio-content single-content ${
        maxed ? 'maxed' : 'normal'
      }`}
      style={isAtTerm ? { height: '700px' } : {}}
    >
      {contentType === 'gallery' && contents.length === 1 && (
        // renderSingleContent(contents[0]._type, 0, videoSelected)
        <PortfolioContentOrThumbnail
          isSelected={videoSelected}
          Ele={{ gallery_index: 0, page_id: page._key, id: '' }}
          chosenPortfolio={chosenPortfolio}
          Thumb={false}
        />
      )}
      {contentType === 'gallery' && contents.length > 1 && (
        <GalleryView
          gallery={contents}
          page={page}
          chosenPortfolio={chosenPortfolio}
          // renderSingleContent={renderSingleContent}
          // renderThumbnail={renderSingleThumb}
          prepareCurrentGalleryContent={prepareCurrentGalleryContent}
        />
      )}
      {sliderGalleryDecision}
      {contentType === 'htmlContent' && (
        <div className="html-block-content">
          <BlockContent
            blocks={blockContent(contents)}
            serializers={serializers}
          />
        </div>
      )}
      {contentType === 'website' && (
        <div className="iframe-container">
          <iframe title="iframe" allowFullScreen src={page.url}></iframe>
        </div>
      )}
      {overlay}
    </div>
  );
};

PortfolioContent.defaultProps = {
  videoSelected: true
};

export default PortfolioContent;
