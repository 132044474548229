import {
  SET_CHOSEN_PAGE_ID,
  SET_CHOSEN_MAIN_PAGE_BUTTON,
  SET_CONTENT_PAGE_ID,
  SET_CURRENT_LEVEL,
  SET_IS_AT_END,
  SET_MENU_HIDE,
  SET_NAV_HISTORY,
  SET_SAVED_CONTENT_PAGE_ID,
  SET_HAS_CURR,
  SET_TOPIC,
  SET_HAS_PREV,
  SET_MEGAMENU_LEVEL,
  SET_TERM_WITH_CHILD,
  SET_SHOW_TERM_CHILD
} from '../constants/actionTypes';

export const setChosenPageId = (id) => ({
  type: SET_CHOSEN_PAGE_ID,
  id
});

export const setChosenMainPageButtonId = (id) => ({
  type: SET_CHOSEN_MAIN_PAGE_BUTTON,
  id
});

export const setContentPageId = (id) => ({
  type: SET_CONTENT_PAGE_ID,
  id
});

export const setSavedContentPageId = (id) => ({
  type: SET_SAVED_CONTENT_PAGE_ID,
  id
});

export const setCurrentLevel = (number) => ({
  type: SET_CURRENT_LEVEL,
  number
});

export const setIsAtEnd = (bool) => ({
  type: SET_IS_AT_END,
  bool
});

export const setMenuHide = (bool) => ({
  type: SET_MENU_HIDE,
  bool
});

export const setNavHistory = (array) => ({
  type: SET_NAV_HISTORY,
  array
});

export const setHasCurr = (bool) => ({
  type: SET_HAS_CURR,
  bool
});

export const setHasPrev = (bool) => ({
  type: SET_HAS_PREV,
  bool
});

export const SetTopic = (string) => ({
  type: SET_TOPIC,
  string
});

export const setMegamenuLevel = (level) => ({
  type: SET_MEGAMENU_LEVEL,
  data: level
});

export const setTermWithChild = (bool) => ({
  type: SET_TERM_WITH_CHILD,
  data: bool
});

export const setShowTermChild = (bool) => ({
  type: SET_SHOW_TERM_CHILD,
  data: bool
});
