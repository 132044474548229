/**
 * Input field with adjustable width and height
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyleInputField = styled.input`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  font-size: 14px;
  border: none;
  box-sizing: border-box;
  padding: 12px;
  background-color: #e3e3e3;
  margin: 1%;

  // if not valid set the border color red
  ${(props) => (!props.isValid ? 'border-color: red;' : '')};

  ::placeholder {
    font-size: 14px;
  }
  &:focus {
    ::placeholder {
      font-size: 0px;
    }
  }
`;

StyleInputField.displayName = 'StyleInputField';

const InputBox = ({
  name,
  value,
  onChange,
  placeholder,
  type,
  width,
  height,
  isValid,
  required,
  style,
  onClick,
  id,
  onFocus
}) => (
  <StyleInputField
    name={name}
    value={value}
    placeholder={placeholder}
    width={width}
    height={height}
    type={type}
    onChange={onChange}
    onClick={onClick}
    isValid={isValid}
    required={required}
    style={style}
    id={id}
    onFocus={onFocus}
  />
);

InputBox.defaultProps = {
  width: '300px',
  height: '42px',
  isValid: true,
  required: false
};

InputBox.propTypes = {
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.string,
  height: PropTypes.string,
  isValid: PropTypes.bool,
  required: PropTypes.bool
};

export default InputBox;
