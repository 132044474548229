import imageUrlBuilder from '@sanity/image-url';
import { client } from './client';
// https://www.sanity.io/docs/presenting-images
// Get a pre-configured url-builder from your sanity client
// Then we like to make a simple function like this that gives the
// builder an image and returns the builder for you to specify additional
// parameters:
export const urlFor = (source) => {
  const builder = imageUrlBuilder(client());
  return builder.image(source);
};
