import { connect } from 'react-redux';
import Modal from './Modal';
import {
  hideModal,
  setToolbarMenuOpen,
  setToolbarMenuVisibility
} from '../../../actions';

const mapStateToProps = (state) => ({
  chosenPortfolio: state.portfolioReducer.chosenPortfolio,
  isModalOpen: state.modalReducer.isModalOpen,
  toolbarMenuVisible: state.portfolioReducer.toolbarMenuVisible
});

const mapDispatchToProps = (dispatch) => {
  return {
    hideModal: () => dispatch(hideModal()),
    setToolbarMenuVisibility: (bool) =>
      dispatch(setToolbarMenuVisibility(bool)),
    setToolbarMenuOpen: (bool) => dispatch(setToolbarMenuOpen(bool))
  };
};

const ModalContainer = connect(mapStateToProps, mapDispatchToProps)(Modal);

export default ModalContainer;
