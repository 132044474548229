import React from 'react';
import Completion from '../Completion/index';
import KeyTopics from '../KeyTopics/index';
import Efficiency from '../Efficiency/index';
import Users from '../Users/index';
import Account from './Account/';
// import GP from './GuestPass';
import Notes from './Notes';
import Briefcase from './Briefcase';
// import LT from '../LikesLayoutTest/Likes-test';
// import NT from '../GP2/Notifications/NotificationList';
import CT from '../GP2/Core/';
import styled from 'styled-components';

const StretchContainer = styled.div`
  grid-column: 2 / span 1;

  @media (max-width: 1380px) {
    grid-column: 1 / span 3;
    padding-left: 30px;
    padding-right: 30px;
  }
`;

const Body = ({ bodyRender, SetSoloDispatch }) => {
  React.useEffect(() => {
    SetSoloDispatch(true);
  }, [bodyRender, SetSoloDispatch]);
  let chosenPage;
  //////onsole.log("!!BR!!",bodyRender)
  switch (bodyRender) {
    case 'Completion':
      chosenPage = <Completion />;
      break;
    case 'Efficiency':
      chosenPage = <Efficiency />;
      break;
    case 'Notes':
      chosenPage = (
        <>
          <div className="dashboard-body-left"></div>
          <div className="dashboard-body-main">
            <Notes></Notes>
          </div>
          <div className="dashboard-body-right"></div>
        </>
      );
      break;
    case 'Account':
      chosenPage = (
        <>
          <div className="dashboard-body-left"></div>
          <div className="dashboard-body-main">
            <Account></Account>
          </div>
          <div className="dashboard-body-right"></div>
        </>
      );
      break;
    case 'Briefcase':
      chosenPage = (
        <StretchContainer className="dashboard-body-main">
          <Briefcase />
        </StretchContainer>
      );
      break;
    case 'Guest Pass':
      chosenPage = (
        <CT />
        // <StretchContainer style={{height:'inherit'}} className="dashboard-body-main">
        //     <div style={{gridColumn: "2/3", height: "inherit"}}>
        //         <GP></GP>
        //     </div>
        // </StretchContainer>
      );
      break;
    case 'Users':
      chosenPage = <Users />;
      break;
    case 'Key Topics':
      chosenPage = <KeyTopics />;
      break;
    case 'Likes':
      chosenPage = <CT />;
      break;
    default:
      chosenPage = <Completion />;
      break;
  }

  return (
    <div
      className="dashboard-revamp-body"
      style={{ height: 'calc(100vh / 8.5 * 7)' }}
    >
      {chosenPage}
    </div>
  );
};

export default Body;
