import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIdleTimer } from 'react-idle-timer';
import { isEmpty } from 'lodash';
import { urlFor } from '../../utils/sanityHelper';
import SanityMuxPlayer from '../MuxPlayer';

const ScreenSaver = ({
  addPageVisit,
  images,
  videos,
  screensavers,
  screensaverResults,
  KioskModePrep,
  isVideoPlaying,
  show,
  setShow
}) => {
  // const [show, setShow] = useState(false);
  const [currentScreenSaver, setCurrentScreenSaver] = useState({});
  const [currentAssetIndex, setCurrentAssetIndex] = useState(0);
  const [currentAssetKey, setCurrentAssetKey] = useState(0);

  useEffect(() => {
    if (!isEmpty(screensavers) && !isEmpty(screensaverResults)) {
      let screenSaver = screensavers[screensaverResults.screensaver._ref];
      // set current screen saver for easy access
      setCurrentScreenSaver({
        ...screenSaver
      });
    }
  }, [screensavers, screensaverResults]);

  // set interval for image asset arry navigation
  useEffect(() => {
    if (isEmpty(currentScreenSaver) || !show) {
      return;
    }
    const interval = setInterval(() => {
      //onsole.log("currentScreenSaver", currentScreenSaver)
      //check currentScreenSaver is populated and contains image asset
      // if (currentScreenSaver.asset[0]._type === 'image') {
      let index = 0;

      // increment index if + 1 is still smaller
      if (currentAssetIndex + 1 < currentScreenSaver.asset.length) {
        index = currentAssetIndex + 1;
      }
      setCurrentAssetIndex(index);
      // //onsole.log("INDEX", index)
      // //onsole.log("INDEX - KEY", currentScreenSaver.asset[index]._key);
      setCurrentAssetKey(currentScreenSaver.asset[index]._key);
      // }
    }, 1000 * 5 * 1); // interval every 5 seconds;
    return () => clearInterval(interval);
  }, [show, currentScreenSaver, currentAssetIndex]);

  const handleOnIdle = (event) => {
    //onsole.log("HANDLE IDLE");
    //onsole.log("IDLE - VIDEO", isVideoPlaying)
    // check if video is playing
    if (!isVideoPlaying) {
      //////////onsole.log('user is idle, no video playing', event);
      console.log('last active', getLastActiveTime());

      // create page visit obj of screensaver
      const pageVisitInfo = {
        page_id: currentScreenSaver._id,
        title: currentScreenSaver.title,
        content_type: 'screensaver',
        is_gallery_item: false,
        gallery_index: -1,
        is_end: false,
        is_idle: true
      };

      addPageVisit(pageVisitInfo);
      setShow(true);
      KioskModePrep('');
    }
  };

  const handleOnActive = (event) => {
    //KioskModePrep
    // check if show is true
    //onsole.log("HANDLE ACTIVE")
    if (show) {
      //////////onsole.log('user is active', event);
      console.log('time remaining', getRemainingTime());
      setShow(false);
    }
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * screensaverResults.screensaver_timer,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    debounce: 500
  });

  const renderAssetContent = (obj) => {
    switch (obj._type) {
      case 'image': {
        let image = images[obj.asset._ref];
        return (
          <img
            className="screensaver-single-image"
            src={urlFor(image).auto('format').width(1600).url()}
            alt="mainImage"
            type="image"
          />
        );
      }
      case 'mux.video': {
        let video = videos[obj.asset._ref];
        //onsole.log("OBJ", obj._key);
        let assetKey = obj._key;
        return (
          <SanityMuxPlayer
            ScreenSaver={true}
            currentAssetKey={currentAssetKey}
            assetKey={assetKey}
            override={true}
            Screensaver={true}
            assetDocument={video}
            autoload={true}
            autoplay={true}
            SIG={false}
            SIP={currentScreenSaver.asset.length > 1 ? false : true}
            loop={true}
            // muted={currentScreenSaver.asset.length > 1 ? true : false}
            showControls={false}
            style={{}}
            posterurl={`https://image.mux.com/${video.playbackId}/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=1`}
            type="mux.video"
          />
        );
      }
      default:
        return <div />;
    }
  };

  if (isEmpty(currentScreenSaver) || !show) {
    return null;
  }
  return (
    <div className="screen-saver-container">
      {currentScreenSaver.asset.map((asset, index) => {
        return (
          <div
            key={`screen-saver-asset-${index}`}
            className={`screen-saver-asset ${
              currentAssetIndex === index ? 'active' : 'hide'
            }`}
          >
            {renderAssetContent(asset)}
          </div>
        );
      })}
    </div>
  );
};

ScreenSaver.propTypes = {
  addPageVisit: PropTypes.func.isRequired
};
export default ScreenSaver;
