import React, { useEffect, useRef, useState } from 'react';
import * as Scale from '../../../../../utils/Scalars';
import { Progress } from 'react-sweet-progress';
import 'react-sweet-progress/lib/style.css';
import styled from 'styled-components';

const ChartContainer = styled.div`
  .transparent {
    opacity: 0;
  }
`;

const Bars = ({ SendOpen, SetBarHeight, style, Size }) => {
  const [send, setSend] = useState(0);
  const [open, setOpen] = useState(0);

  useEffect(() => {
    setSend(SendOpen.send);
    setOpen(SendOpen.open);
  }, [SendOpen]);

  const Ref = useRef();

  useEffect(() => {
    //////onsole.log("BAR REF", Ref);
    if (Ref.current) {
      SetBarHeight(Ref.current.style.height);
    }
  }, [Ref, SetBarHeight]);

  return (
    <div
      ref={Ref}
      style={{
        ...style,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        color: '#26d2b8',
        height: `${Scale.Height(Size, 96 * 6)}px`
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly'
        }}
      >
        <p
          style={{
            fontWeight: 600,
            color: 'black',
            fontSize: '1.3em',
            fontFamily: "'Open Sans', sans-serif",
            textShadow: '1px 1px 2px #808080'
          }}
        >
          Opened / Shared
        </p>
        <ChartContainer
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            width: '100%'
          }}
        >
          <p
            style={{
              fontWeight: 'bold',
              marginRight: '12px',
              fontSize: '20px',
              color: 'rgb(5, 106, 175)',
              textShadow: '1px 1px 2px #808080'
            }}
          >
            {open}
          </p>
          {/* <div style={{width: open > send ? '100%' : send > 0 ? `${(open/send) * 100}%` : "0%",  backgroundColor: "rgb(5, 106, 175)", borderTopRightRadius: "15px", borderBottomRightRadius: "15px", boxShadow: '2px 2px 4px #808080'}} /> */}
          <Progress
            percent={open > send ? 100 : send > 0 ? (open / send) * 100 : 0}
            status="success"
            theme={{
              success: {
                symbol: '',
                trailColor: 'rgb(222, 222, 222)',
                color: 'rgb(5, 106, 175)'
              }
            }}
            symbolClassName="transparent"
          />
        </ChartContainer>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          color: '#ff993a'
        }}
      >
        <p
          style={{
            fontWeight: 600,
            color: 'black',
            fontSize: '1.3em',
            fontFamily: "'Open Sans', sans-serif",
            textShadow: '1px 1px 2px #808080'
          }}
        >
          Briefcases Sent
        </p>
        <ChartContainer
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            width: '100%'
          }}
        >
          <p
            style={{
              fontWeight: 'bold',
              marginRight: '12px',
              fontSize: '20px',
              color: '#F7983D',
              textShadow: '1px 1px 2px #808080'
            }}
          >
            {send}
          </p>
          {/* <div style={{width: send > open ? '100%' : open > 0 ? `${(send/open) * 100}%` : "0%",  backgroundColor: "#F7983D", borderTopRightRadius: "15px", borderBottomRightRadius: "15px", boxShadow: '2px 2px 4px #808080'}}></div> */}
          <Progress
            percent={send > open ? 100 : open > 0 ? (send / open) * 100 : 0}
            status="success"
            theme={{
              success: {
                symbol: '',
                trailColor: 'rgb(222, 222, 222)',
                color: '#F7983D'
              }
            }}
            symbolClassName="transparent"
          />
        </ChartContainer>
      </div>
    </div>
  );
};
export default Bars;
