import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useFirestore } from 'react-redux-firebase';
// import PortfolioSelect from '../../PortfolioSelect/index.js';
import * as Scale from '../../../../utils/Scalars';

const SmallerGapP = styled.p`
  margin-block: 0px;
  margin-block-start: 0px;
  margin-block-end: 0px;
  font-weight: bold;
`;

// const DateChoiceContainer = styled.div`
//     width: 100%;
//     display: flex;
//     flex-direction: row;
//     justify-content: flex-start;
//     align-items: center;
//     font-family: 'Oswald', sans-serif;
//     cursor: pointer;

//     div {
//         margin-right: 8px;
//         border-radius: 4px;
//         background-color: white;
//         display: flex;
//         justify-content: center;
//         align-items: center;

//         p {
//             margin-block-start: 0px;
//             margin-block-end: 0px;
//             font-weight: 600;
//             font-family: 'Newsreader', serif;
//         }
//     }
// `;

const UserInformationBlock = ({
  member,
  userInfo,
  headerAggregateSelected,
  setHeaderAggregateSelected,
  Size
}) => {
  const [FN, setFN] = React.useState('');
  const [LN, setLN] = React.useState('');
  useEffect(() => {
    setFN(member.name?.split(' ')[0]);
    setLN(member.name?.split(' ')[1]);
  }, [member]);
  // let FN = member.name.split(" ")[0];
  // let LN = member.name.split(" ")[1];
  // let MS = new Date(member._createdAt);

  let [CompanyName, SetCompanyName] = useState('');
  const firestore = useFirestore();

  useEffect(() => {
    let companyId = userInfo[0].company_id;
    firestore
      .collection('companies')
      .doc(companyId)
      .get()
      .then((Doc) => {
        // //////onsole.log(Doc.data());
        SetCompanyName(Doc.data().name);
      });
  });

  // let timeSelectList = [{id: 30, value: '1m'}, {id: 7, value: '1w'}, {id: 1, value: '1d'}];
  // let timeSelectMapping = timeSelectList.map((time) => {
  //     return (
  //         <div
  //             style={{
  //                 backgroundColor: headerAggregateSelected === time.id ? '#3269a8' : 'white',
  //                 color: headerAggregateSelected === time.id ? 'white' : '#3b3b3b',
  //                 height: `${Scale.Height(Size, 25)}px`,
  //                 width: `${Scale.Height(Size, 45)}px`,
  //             }}
  //             onClick={() => setHeaderAggregateSelected(time.id)}
  //             key={time.id}
  //         >
  //             <p style={{fontSize: `${Scale.Height(Size, 16)}px`}}>{time.value}</p>
  //         </div>
  //     )
  // });

  return (
    <div
      style={{
        width: '144px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        paddingLeft: '10px',
        alignContent: 'center'
      }}
      className="check"
    >
      {FN ? (
        <SmallerGapP
          style={{
            fontSize: `${Scale.Height(Size, 20)}px`,
            color: 'white',
            fontFamily: "'Open Sans', serif"
          }}
        >
          {FN}
        </SmallerGapP>
      ) : null}
      {LN ? (
        <SmallerGapP
          style={{
            fontSize: `${Scale.Height(Size, 20)}px`,
            color: 'white',
            fontFamily: "'Open Sans', serif"
          }}
        >
          {LN}
        </SmallerGapP>
      ) : null}
      {CompanyName ? (
        <SmallerGapP
          style={{
            fontSize: `${Scale.Height(Size, 14)}px`,
            color: '#5081d4',
            marginBottom: '5%',
            fontFamily: "'Open Sans', serif"
          }}
        >
          {CompanyName}
        </SmallerGapP>
      ) : null}
      {/* <SmallerGapP style={{fontSize: "14px", color: '#e6e6e6'}} >Member since {MS.getFullYear()}</SmallerGapP> */}
      {/* <div style={{height: '30px', width: '100%', style: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '5%'}}>
                <PortfolioSelect />
            </div>
            <DateChoiceContainer>
                {timeSelectMapping}
            </DateChoiceContainer> */}
    </div>
  );
};
export default UserInformationBlock;
