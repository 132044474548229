import { useRef, useState } from 'react';
const useAsyncReference2 = (value) => {
  const ref = useRef(value);
  const [, forceRender] = useState(false);

  function updateState(newState) {
    ref.current = newState;
    forceRender((s) => !s);
  }

  function GetState() {
    return ref.current;
  }
  const test = {
    valueOf() {
      return this.Value;
    },
    toString() {
      return this.Value;
    },
    get Value() {
      return GetState();
    },
    set Value(val) {
      updateState(val);
    }
  };

  return [test];
};
export default useAsyncReference2;
