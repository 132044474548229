import React, { useState } from 'react';
import styled from 'styled-components';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import FontUtil from '../../../utils/FontUtility';

const StyledSubNav = styled.section`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 20%;
  height: 80%;
  max-height: 80%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    display: none;
    background: transparent;
  }

  .text-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80%;
    margin: 1%;
    margin-bottom: 5%;
    cursor: pointer;

    p {
      font-size: 20px;
      font-weight: 600;
      margin-block-start: 0px;
      margin-block-end: 0px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      text-shadow: 1px 1px 1px #808080;
      text-align: center;

      @media (min-height: 1025px) and (orientation: portrait) {
        font-size: 28px;
        letter-spacing: 1px;
      }

      @media (max-width: 450px) and (orientation: portrait) {
        font-size: 24px;
      }
    }

    .sub {
      font-size: 13px;
      -webkit-line-clamp: 2;

      @media (min-height: 1025px) and (orientation: portrait) {
        font-size: 18px;
      }

      @media (max-width: 450px) and (orientation: portrait) {
        font-size: 13px;
      }
    }
  }
`;

const StyledSubBottom = styled.section`
  position: absolute;
  bottom: 0%;
  height: 5%;
  width: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0px -5px 3px -5px #3b3b3b80;
`;

const SubLevelModal = ({
  currentNavItem,
  pages,
  images,
  videos,
  pdfs,
  videoSelected,
  contentDataChoice,
  prepareCurrentGalleryContent,
  onNavClick2,
  getColor,
  chosenPortfolio,
  isAtEnd,
  Size,
  transitionedToSubLevel,
  hasCurr,
  hasPrev,
  foundNavItem,
  onBackClick,
  returnTopLevel,
  selectedTopLevelId,
  twolevelTerminus,
  level,
  megamenuLevel,
  setMegamenuLevel,
  backTopicArray,
  companyConfig
}) => {
  // FLAG FONT FAMILY
  const [isHovered, setIsHovered] = useState(null);

  const innerNavClick = (event, child, index, level) => {
    event.stopPropagation();
    onNavClick2(child, index, level);
  };

  const innerBackClick = (event) => {
    event.stopPropagation();
    onBackClick();
  };

  const innerReturnTop = (event) => {
    event.stopPropagation();
    returnTopLevel();
  };

  let topLevelItems = [];
  if (chosenPortfolio?.nav?.items) {
    for (let item of chosenPortfolio.nav.items) {
      topLevelItems.push(item.referenceId);
    }
  }

  ////onsole.log("TWO LEVEL TERMINUS", twolevelTerminus)

  return currentNavItem?.children?.length ? (
    <section
      className={`sublevel-modal`}
      style={{
        opacity:
          (!hasCurr && !hasPrev) ||
          Size.W < 451 ||
          selectedTopLevelId !== foundNavItem.referenceId ||
          currentNavItem.referenceId === `splashkey-${chosenPortfolio._id}`
            ? 0
            : 1,
        visibility:
          Size.W < 451 ||
          selectedTopLevelId !== foundNavItem.referenceId ||
          currentNavItem.referenceId === `splashkey-${chosenPortfolio._id}` ||
          twolevelTerminus
            ? 'hidden'
            : 'visible',
        transform:
          (!hasCurr && !hasPrev) ||
          Size.W < 451 ||
          selectedTopLevelId !== foundNavItem.referenceId ||
          currentNavItem.referenceId === `splashkey-${chosenPortfolio._id}`
            ? 'translateY(140%)'
            : 'translateY(0%)',
        backgroundColor: chosenPortfolio?.primary_color
          ? `${chosenPortfolio.primary_color}80`
          : '#3b3b3b80'
      }}
    >
      <section
        className="sublevel-modal-header"
        style={{
          backgroundColor: chosenPortfolio?.primary_color
            ? `${chosenPortfolio.primary_color}`
            : '#3b3b3b'
        }}
      >
        <section className="back-box">
          <ArrowBackRoundedIcon
            style={{
              fontSize: '24px',
              color: chosenPortfolio?.text_color
                ? chosenPortfolio.text_color
                : 'white',
              cursor: 'pointer',
              visibility: topLevelItems.includes(currentNavItem.referenceId)
                ? 'hidden'
                : 'visible'
            }}
            onClick={(event) => innerBackClick(event)}
          />
        </section>
        <section className="title-box">
          <p
            style={{
              color: chosenPortfolio?.text_color
                ? chosenPortfolio.text_color
                : 'white',
              textShadow: chosenPortfolio?.accent_color
                ? ` 1px 1px 1px ${chosenPortfolio.accent_color}40`
                : '1px 1px 1px #3b3b3b40',
              fontFamily: FontUtil(
                chosenPortfolio.SystemText,
                chosenPortfolio.ContentText,
                "'Roboto', serif"
              )
            }}
          >
            {currentNavItem.title}
          </p>
        </section>
        <section className="home-box">
          <CloseRoundedIcon
            style={{
              fontSize: '24px',
              color: chosenPortfolio?.text_color
                ? chosenPortfolio.text_color
                : 'white',
              cursor: 'pointer'
            }}
            onClick={(event) => innerReturnTop(event)}
          />
        </section>
      </section>
      <StyledSubNav style={{}}>
        {currentNavItem?.children?.length
          ? currentNavItem.children.map((child, index) => {
              let foundPage = pages[child.referenceId];

              return (
                <section
                  className="text-box"
                  key={child._key + '-' + index}
                  onMouseEnter={() => setIsHovered(foundPage._key)}
                  onMouseLeave={() => setIsHovered(null)}
                  onClick={(event) => innerNavClick(event, child, index, level)}
                >
                  <p
                    style={{
                      color:
                        isHovered === foundPage._key &&
                        chosenPortfolio?.secondary_color &&
                        chosenPortfolio?.text_color
                          ? `${chosenPortfolio.secondary_color}`
                          : isHovered === foundPage._key &&
                            chosenPortfolio?.text_color
                          ? 'rgb(68, 159, 245)'
                          : chosenPortfolio?.text_color
                          ? `${chosenPortfolio.text_color}`
                          : 'white',
                      textShadow: chosenPortfolio?.accent_color
                        ? ` 1px 1px 1px ${chosenPortfolio.accent_color}40`
                        : '1px 1px 1px #3b3b3b40',
                      fontFamily: FontUtil(
                        chosenPortfolio.ContentText,
                        null,
                        "'Roboto', serif"
                      )
                    }}
                  >
                    {foundPage?.title}
                  </p>
                  <p
                    className="sub"
                    style={{
                      color:
                        isHovered === foundPage._key &&
                        chosenPortfolio?.secondary_color &&
                        chosenPortfolio?.text_color
                          ? `${chosenPortfolio.secondary_color}`
                          : isHovered === foundPage._key &&
                            chosenPortfolio?.text_color
                          ? 'rgb(68, 159, 245)'
                          : chosenPortfolio?.text_color
                          ? `${chosenPortfolio.text_color}`
                          : 'white',
                      textShadow: chosenPortfolio?.accent_color
                        ? ` 1px 1px 1px ${chosenPortfolio.accent_color}40`
                        : '1px 1px 1px #3b3b3b40',
                      fontFamily: FontUtil(
                        chosenPortfolio.ContentText,
                        null,
                        "'Roboto', serif"
                      )
                    }}
                  >
                    {foundPage?.subtitle}
                  </p>
                </section>
              );
            })
          : null}
      </StyledSubNav>
      <StyledSubBottom
        style={{
          backgroundColor: chosenPortfolio?.primary_color
            ? `${chosenPortfolio.primary_color}`
            : '#3b3b3b'
        }}
      />
    </section>
  ) : null;
};

export default SubLevelModal;
