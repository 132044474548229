import React from 'react';
import { urlFor } from '../../../utils/sanityHelper';
import SanityMuxPlayer from '../../../containers/MuxPlayer';
import ImageRoundedIcon from '@material-ui/icons//ImageRounded';
import PlayCircleOutlineRoundedIcon from '@material-ui/icons/PlayCircleOutlineRounded';
import PictureAsPdfRoundedIcon from '@material-ui/icons/PictureAsPdfRounded';
import LanguageRoundedIcon from '@material-ui/icons/LanguageRounded';

const TerminusLevelThumb = ({
  chosenPortfolio,
  foundPage,
  child,
  index,
  onNavClick,
  videos,
  pageContents,
  isTopLevel,
  isAtEnd,
  onBackClick,
  isAtTerm,
  clickedTerminusId
}) => {
  // FLAG FONT FAMILY
  //////onsole.log("FOUND PAGE", foundPage);
  //////onsole.log("CHILD", child)
  // console.log("TEXT COLOR", chosenPortfolio.text_color)

  let iframeOverlay = null;
  iframeOverlay = (
    <div
      style={{
        display: 'block',
        position: 'absolute',
        width: '100%',
        height: '100%'
      }}
    />
  );

  let titleOverlay = null;
  titleOverlay = (
    <div
      className="title-overlay"
      style={{
        backgroundColor: chosenPortfolio?.accent_color
          ? `${chosenPortfolio.accent_color}40`
          : 'transparent'
      }}
    >
      <p
        style={{
          color: chosenPortfolio?.text_color
            ? chosenPortfolio.text_color
            : 'white'
        }}
      >
        {foundPage.title}
      </p>
      <p
        className="sub"
        style={{
          color: chosenPortfolio?.text_color
            ? chosenPortfolio.text_color
            : 'white'
        }}
      >
        {foundPage.subtitle}
      </p>
    </div>
  );

  let thumbnailTile = null;
  let htmlThumb = foundPage.content
    ? foundPage.content.find((piece) => piece._type === 'imageLinks')
    : null;
  let galImgThumb = foundPage.content
    ? foundPage.content.find((piece) => piece._type === 'galleryImage')
    : null;
  let pdfThumb = foundPage.content
    ? foundPage.content.find(
        (piece) =>
          piece._type === 'galleryPdf' || piece._type === 'galleryFullWidthPdf'
      )
    : null;
  let videoThumb = foundPage.content
    ? foundPage.content.find((piece) => piece.video)
    : null;
  let toggleThumb = foundPage.content
    ? foundPage.content.find((piece) => piece._type === 'toggleMenu')
    : null;
  let sliderThumb = foundPage.content
    ? foundPage.content.find((piece) => piece._type === 'galleryBASlider')
    : null;

  let webUrl = foundPage._type === 'website' ? foundPage.url : null;
  let webThumb =
    webUrl && foundPage?.thumbnail?.asset?._ref
      ? foundPage.thumbnail.asset._ref
      : null;
  let videoPreview =
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    foundPage.content &&
    foundPage.content.find((piece) => piece.video) !== undefined
      ? videoThumb.video.asset._ref
      : null;
  let pdfThumbnail =
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    foundPage.content &&
    (foundPage.content.find((piece) => piece._type === 'galleryPdf') !==
      undefined ||
      foundPage.content.find(
        (piece) => piece._type === 'galleryFullWidthPdf'
      ) !== undefined)
      ? pdfThumb.thumbnail.asset._ref
      : null;
  let galleryImage =
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    foundPage.content &&
    foundPage.content.find((piece) => piece._type === 'galleryImage') !==
      undefined
      ? galImgThumb.image.asset._ref
      : null;
  let sliderThumbnail =
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    foundPage.content &&
    foundPage.content.find((piece) => piece._type === 'galleryBASlider') !==
      undefined
      ? sliderThumb.Bimage.asset._ref
      : null;
  let toggleThumbnail;
  if (
    toggleThumb === undefined ||
    toggleThumb === null ||
    (foundPage._type !== 'gallery' && foundPage._type !== 'sliderGallery') ||
    !foundPage?.content
  ) {
    toggleThumbnail = undefined;
  } else if (toggleThumb.content[0]._type === 'galleryImage') {
    toggleThumbnail = toggleThumb.content[0].image.asset._ref;
  } else if (
    toggleThumb.content[0]._type === 'galleryPDF' ||
    toggleThumb.content[0]._type === 'galleryFullWidthPdf'
  ) {
    toggleThumbnail = toggleThumb.content[0].thumbnail.asset._ref;
  } else if (toggleThumb.content[0]._type === 'galleryVideo') {
    let videoRef = toggleThumb.content[0].video.asset._ref;
    toggleThumbnail = videos[videoRef];
  } else if (toggleThumb.content[0]._type === 'galleryBASlider') {
    toggleThumbnail = toggleThumb.content[0].Bimage.asset._ref;
  } else {
    toggleThumbnail = undefined;
  }
  let htmlContentImage =
    foundPage._type === 'htmlContent' &&
    foundPage.content &&
    foundPage.content.find((piece) => piece._type === 'imageLinks') !==
      undefined
      ? htmlThumb.image.asset._ref
      : null;
  let finalVideo = videoPreview !== null ? videos[videoPreview] : null;

  if (
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    galleryImage !== null
  ) {
    thumbnailTile = (
      <div className="big-nav">
        <div className="portfolio-content group-content">
          <img
            src={urlFor(galleryImage).auto('format').url()}
            alt="logo"
            className="portfolio-single-image"
            style={{
              height: '100%',
              width: '100%'
            }}
          />
        </div>
        {titleOverlay}
        <div className="icon-overlay">
          <ImageRoundedIcon />
        </div>
      </div>
    );
  } else if (
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    pdfThumbnail !== null
  ) {
    thumbnailTile = (
      <div className="big-nav">
        <div className="portfolio-content group-content">
          <img
            src={urlFor(pdfThumbnail).auto('format').url()}
            alt="logo"
            className="portfolio-single-image"
            style={{
              height: '100%',
              width: '100%'
            }}
          />
        </div>
        {titleOverlay}
        <div className="icon-overlay">
          <PictureAsPdfRoundedIcon />
        </div>
      </div>
    );
  } else if (
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    videoPreview !== null
  ) {
    thumbnailTile = (
      <div
        className="big-nav"
        style={{
          backgroundColor: chosenPortfolio?.primary_color
            ? `${chosenPortfolio.primary_color}80`
            : '#3b3b3b80'
        }}
      >
        <div className="portfolio-content group-content">
          <SanityMuxPlayer
            className="mux-player"
            assetDocument={finalVideo}
            autoload={false}
            autoplay={false}
            loop={false}
            muted={true}
            SIG={false}
            SIP={false}
            showControls={false}
            style={{}}
            posterurl={`https://image.mux.com/${finalVideo.playbackId}/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=1`}
            type="galleryVideo"
            isSelected={false}
          />
          {iframeOverlay}
          <div className="title-overlay mux">
            <p
              style={{
                color: chosenPortfolio?.text_color
                  ? chosenPortfolio.text_color
                  : 'white'
              }}
            >
              {foundPage.title}
            </p>
            <p
              className="sub"
              style={{
                color: chosenPortfolio?.text_color
                  ? chosenPortfolio.text_color
                  : 'white'
              }}
            >
              {foundPage.subtitle}
            </p>
          </div>
          <div className="icon-overlay">
            <PlayCircleOutlineRoundedIcon />
          </div>
        </div>
      </div>
    );
  } else if (foundPage._type === 'htmlContent' && htmlContentImage !== null) {
    thumbnailTile = (
      <div className="big-nav">
        <div className="portfolio-content group-content">
          <img
            src={urlFor(htmlContentImage).auto('format').url()}
            alt="logo"
            className="portfolio-single-image"
            style={{
              height: '100%',
              width: '100%'
            }}
          />
        </div>
        {titleOverlay}
        <div className="icon-overlay">
          <LanguageRoundedIcon />
        </div>
      </div>
    );
  } else if (foundPage._type === 'website' && webUrl !== null) {
    if (webThumb) {
      thumbnailTile = (
        <div className="big-nav">
          <div className="portfolio-content group-content">
            <img
              src={urlFor(webThumb).auto('format').url()}
              alt="logo"
              className="portfolio-single-image"
              style={{
                height: '100%',
                width: '100%'
              }}
            />
          </div>
          {titleOverlay}
          <div className="icon-overlay">
            <ImageRoundedIcon />
          </div>
        </div>
      );
    } else {
      thumbnailTile = (
        <div className="big-nav">
          <div className="portfolio-content group-content">
            <div
              className="iframe-container"
              style={{ height: '100%', width: '100%' }}
            >
              <iframe
                scrolling="no"
                title="iframe"
                allowFullScreen
                src={webUrl}
                style={{ height: '100%', width: '100%' }}
              ></iframe>
            </div>
            {iframeOverlay}
            {titleOverlay}
          </div>
        </div>
      );
    }
  } else if (
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    sliderThumbnail !== undefined &&
    sliderThumbnail !== null
  ) {
    thumbnailTile = (
      <div className="big-nav">
        <div className="portfolio-content group-content">
          <img
            src={urlFor(sliderThumbnail).auto('format').url()}
            alt="logo"
            className="portfolio-single-image"
            style={{
              height: '100%',
              width: '100%'
            }}
          />
          {titleOverlay}
        </div>
      </div>
    );
  } else if (
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    toggleThumb !== undefined &&
    toggleThumb !== null
  ) {
    if (toggleThumb.content[0]._type === 'galleryImage') {
      thumbnailTile = (
        <div className="big-nav">
          <div className="portfolio-content group-content">
            <img
              src={urlFor(toggleThumbnail).auto('format').url()}
              alt="logo"
              className="portfolio-single-image"
              style={{
                height: '100%',
                width: '100%'
              }}
            />
          </div>
          {titleOverlay}
        </div>
      );
    } else if (
      toggleThumb.content[0]._type === 'galleryPDF' ||
      toggleThumb.content[0]._type === 'galleryFullWidthPdf'
    ) {
      thumbnailTile = (
        <div className="big-nav">
          <div className="portfolio-content group-content">
            <img
              src={urlFor(toggleThumbnail).auto('format').url()}
              alt="logo"
              className="portfolio-single-image"
              style={{
                height: '100%',
                width: '100%'
              }}
            />
          </div>
          {titleOverlay}
        </div>
      );
    } else if (toggleThumb.content[0]._type === 'galleryVideo') {
      thumbnailTile = (
        <div className="big-nav">
          <div className="portfolio-content group-content">
            <SanityMuxPlayer
              className="mux-player"
              assetDocument={toggleThumbnail}
              autoload={false}
              autoplay={false}
              loop={false}
              muted={true}
              SIG={false}
              SIP={false}
              showControls={false}
              style={{}}
              posterurl={`https://image.mux.com/${toggleThumbnail.playbackId}/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=1`}
              type="galleryVideo"
              isSelected={false}
            />
            {iframeOverlay}
            {titleOverlay}
          </div>
        </div>
      );
    } else if (toggleThumb.content[0]._type === 'galleryBASlider') {
      thumbnailTile = (
        <div className="big-nav">
          <div className="portfolio-content group-content">
            <img
              src={urlFor(toggleThumbnail).auto('format').url()}
              alt="logo"
              className="portfolio-single-image"
              style={{
                height: '100%',
                width: '100%'
              }}
            />
            {titleOverlay}
          </div>
        </div>
      );
    } else {
      thumbnailTile = (
        <div className="big-nav">
          {chosenPortfolio?.portfolio_logo.asset?._ref ? (
            <div className="logo-thumb">
              <img
                src={urlFor(chosenPortfolio.portfolio_logo.asset._ref)
                  .auto('format')
                  .width(400)
                  .url()}
                alt="logo"
                style={{
                  height: '100%',
                  width: '100%'
                }}
              />
            </div>
          ) : null}
          {titleOverlay}
        </div>
      );
    }
  } else {
    thumbnailTile = (
      <div className="big-nav">
        {chosenPortfolio?.portfolio_logo.asset?._ref ? (
          <div className="logo-thumb">
            <img
              src={urlFor(chosenPortfolio.portfolio_logo.asset._ref)
                .auto('format')
                .width(400)
                .url()}
              alt="logo"
              style={{
                height: '100%',
                width: '100%'
              }}
            />
          </div>
        ) : null}
        {titleOverlay}
        <div className="icon-overlay">
          <ImageRoundedIcon />
        </div>
      </div>
    );
  }

  return thumbnailTile;
};

export default TerminusLevelThumb;
