import React, { useState, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import ChartBar from '../../ChartBar';
import ChartPie from '../../ChartPie';
import RadioGroup from '../../RadioGroup';

const StyledChart = styled.div`
  margin: 25px 50px 50px 50px; // margin needed bc victory svg appends 50px of initial height
`;

const StyledTitleChoice = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    margin-left: 25px;
  }
`;

const Chart = ({
  data,
  chartLabel,
  labels,
  chartWidth,
  defaultType,
  color,
  chartTypes,
  yLabelFunc,
  show
}) => {
  const [currentRadio, setCurrentRadio] = useState(defaultType);

  useEffect(() => {
    setCurrentRadio(defaultType);
  }, [defaultType, setCurrentRadio]);

  const renderChart = (type) => {
    switch (type) {
      case 'bar':
        return (
          <Fragment>
            <ChartBar
              data={data}
              chartLabel={chartLabel}
              labels={labels}
              chartWidth={chartWidth}
              //barColor="rgb(170, 204, 238)"
              barColor={color ? color : 'rgb(170, 204, 238)'}
              xAxis="x"
              yAxis="y"
              yLabelFunc={yLabelFunc}
            />
          </Fragment>
        );
      case 'pie':
        return (
          <Fragment>
            <ChartPie
              data={data}
              chartLabel={chartLabel}
              chartWidth={chartWidth}
            />
          </Fragment>
        );
      default:
        return <div />;
    }
  };

  if (!show) {
    return null;
  }
  return (
    <StyledChart>
      <StyledTitleChoice>
        <h3>{chartLabel}</h3>
        <form>
          <RadioGroup
            array={chartTypes}
            currentRadio={currentRadio}
            setCurrentRadio={setCurrentRadio}
          />
        </form>
      </StyledTitleChoice>
      {renderChart(currentRadio)}
    </StyledChart>
  );
};

export default Chart;
