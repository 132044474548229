import { React, useEffect, useState } from 'react';
import { useStore } from 'react-redux';

const BackIcon = ({ Fill, Rotation, onClick, className, id }) => {
  // Fill == undefined?Fill = "#c1c0c0":Fill = Fill
  const [Color, SetColor] = useState('#c1c0c0');
  const store = useStore();
  const Trial = store.getState();
  useEffect(() => {
    SetColor(
      store.getState().portfolioReducer.chosenPortfolio.bulk_icon_override !==
        undefined
        ? store.getState().portfolioReducer.chosenPortfolio.bulk_icon_override
        : '#c1c0c0'
    );
  }, [store, Trial.portfolioReducer.chosenPortfolio.bulk_icon_override]);
  return (
    <svg
      className={className}
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => {
        onClick();
      }}
      width={100}
      height={100}
      style={{ transform: `rotate(${Rotation}deg)` }}
    >
      {/* <defs>
        <mask id={`cross${id}`} >
          <rect x={'0'} y={'0'} width={'100'} height={"100"} fill="white"></rect>
          <polyline points='25,25 75,75' fill='black' stroke='black' strokeWidth={'9px'} ></polyline>
          <polyline points='25,75 75,25' fill='black' stroke='black' strokeWidth={'9px'} ></polyline>
        </mask>
        </defs>
      <circle cx={50} cy={50} r={50} fill={Fill} mask={`url(#cross${id})`}></circle> */}
      <polyline
        points="90,10 25,50 90,90"
        fill="transparent"
        stroke={Color}
        strokeWidth={'9px'}
      ></polyline>
      {/*  filter='blur(2px)' */}
    </svg>
  );
};
export default BackIcon;
