import React, { useEffect, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
// import Right from '../../../assets/images/Right.PNG';
// import Left from '../../../assets/images/Left.PNG';
import PortfolioContentOrThumbnail from '../../../utils/PortfolioContentOrThumbnail';
import Icons from '../../../containers/Icons';
import FontUtil from '../../../utils/FontUtility';

const GalleryView = ({
  gallery,
  renderSingleContent,
  renderThumbnail,
  chosenPortfolio,
  prepareCurrentGalleryContent,
  page,
  Size
}) => {
  const [carouselPosition, setCarouselPosition] = useState(0);
  const [GalleryWidth] = useState(5);
  const [lowestindexshown, setlowestindexshown] = useState(0);
  const [highestindexshown, sethighestindexshown] = useState(GalleryWidth);
  // const getWindowDimensions = () => {
  //   const { innerWidth: width, innerHeight: height } = window;
  //   return {
  //     width,
  //     height
  //   };
  // }

  // const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  // useEffect(() => {
  //   function handleResize() {
  //     setWindowDimensions(getWindowDimensions());
  //   }

  //   window.addEventListener('resize', handleResize);
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);

  //////onsole.log("WINDOW DIMENSIONS", windowDimensions);
  // const COMPSAFE = useMemo(() =>{
  //   prepareCurrentGalleryContent(carouselPosition);
  // },)
  //useEffect(()=>{
  //   //////////onsole.log("!!!GALLERYPREP!!!")
  //   prepareCurrentGalleryContent(carouselPosition);
  //   setCarouselPosition(carouselPosition);
  // },[carouselPosition, prepareCurrentGalleryContent])
  // detect change in content
  useEffect(() => {
    // set carousel back to position 0
    setCarouselPosition(0);
  }, [gallery]);

  let Decrement = () => {
    ////////////onsole.log("CP", carouselPosition)
    if (carouselPosition > 0) {
      prepareCurrentGalleryContent(carouselPosition - 1);
      setCarouselPosition(carouselPosition - 1);
      if (lowestindexshown > 0 && carouselPosition <= lowestindexshown) {
        sethighestindexshown(highestindexshown - 1);
        setlowestindexshown(lowestindexshown - 1);
      }
    } else {
      ////////////onsole.log("SETTING TO Gallery.length")
      prepareCurrentGalleryContent(gallery.length - 1);
      setCarouselPosition(gallery.length - 1);
      sethighestindexshown(gallery.length);
      setlowestindexshown(gallery.length - GalleryWidth);
    }
    //prepareCurrentGalleryContent(carouselPosition);
  };
  let Increment = () => {
    if (carouselPosition < gallery.length - 1) {
      prepareCurrentGalleryContent(carouselPosition + 1);
      setCarouselPosition(carouselPosition + 1);
      if (
        highestindexshown < gallery.length &&
        carouselPosition >= highestindexshown - 1
      ) {
        sethighestindexshown(highestindexshown + 1);
        setlowestindexshown(lowestindexshown + 1);
      }
    } else {
      prepareCurrentGalleryContent(0);
      setCarouselPosition(0);
      setlowestindexshown(0);
      sethighestindexshown(0 + GalleryWidth);
    }
    //prepareCurrentGalleryContent(carouselPosition);
  };
  // const [isSafari,SetIsSafari] = useState(navigator.userAgent.indexOf("Safari") > -1)
  // const [isChrome] = useState(navigator.userAgent.indexOf("Chrome") > -1)

  // if ((isChrome) && (isSafari)) {
  //   SetIsSafari(false);
  // }
  // if(!isSafari)

  //[{Index:int, IsPDF:bool?IsLessThan5:bool?HasPriority:int? IsVisible:bool}]

  // const [IsRendering, SetIsRendering] = useState([]);
  // const [Gall, SetGall] = useState([]);
  // const [clicked, setClicked] = useState(false);

  //   const touchContent = () => {
  //     // //////onsole.log("TEST")
  //     setClicked(true);
  //     setTimeout(() => {
  //       setClicked(false);
  //     }, 6000);
  // }

  // //////onsole.log(gallery[carouselPosition]?._type);

  return (
    <div className="portfolio-gallery-view">
      <div
        className="hovering-chevron"
        style={{
          left: '0%'
        }}
      >
        <Icons.ChevronLeft
          style={{
            height: '84px'
          }}
          onClick={() => Decrement()}
        />
      </div>
      <div
        className="hovering-chevron"
        style={{
          right: '0%'
        }}
      >
        <Icons.ChevronRight
          style={{
            height: '84px'
          }}
          onClick={() => Increment()}
        />
      </div>
      <Carousel
        swipeable={false} //Set Swipe to false? Gate by content somehow? galleryBASlider
        selectedItem={carouselPosition}
        renderArrowNext={(a, b, c) => {
          return null;
        }}
        renderArrowPrev={(a, b, c) => {
          return null;
        }}
        showStatus={false}
        autoplay={false}
        swipeScrollTolerance={100}
        preventMovementUntilSwipeScrollTolerance
        // onClickItem={() => touchContent()}
        onChange={(index) => {
          // only update when index is different than current position
          if (carouselPosition !== index) {
            //////////onsole.log("!!!GALLERYPREP!!!")
            prepareCurrentGalleryContent(index);
            setCarouselPosition(index);
          }
        }}
        showThumbs={false}
        emulateTouch={true}
        renderThumbs={(children) =>
          children
            .filter(({ props }) => props && !props.unique)
            .map((item, index) => {
              const { props } = item;
              console.log('props.children.props', props.children.props);
              return (
                <div key={index + '-thumbnail'}>
                  <PortfolioContentOrThumbnail
                    Ele={{ gallery_index: index, page_id: page._key, id: '' }}
                    isSelected={props.children.props.isSelected}
                    chosenPortfolio={chosenPortfolio}
                    Thumb={true}
                  />
                  {/* {renderThumbnail(props.children.props)} */}
                </div>
              );
            })
        }
      >
        {gallery.map((content, index) => {
          let isSelected = index === carouselPosition;
          // SetGall([...Gall,{Index:index,key:'gallery-content-' + content._key + index,IsPDF:content._type === 'galleryFullWidthPdf' ||content._type === 'galleryFullWidthPdf'?true:false, IsVisible:false, IsRendered:false}])
          // if(IsRendering.length<4)
          // SetIsRendering([...IsRendering,'gallery-content-' + content._key + index])
          // console.log(page, content);
          return (
            <div key={'gallery-content-' + content._key + index}>
              <PortfolioContentOrThumbnail
                Ele={{ gallery_index: index, page_id: page._key, id: '' }}
                isSelected={isSelected}
                chosenPortfolio={chosenPortfolio}
                Thumb={false}
              />
              {/* {renderSingleContent(content._type, index, isSelected, true,'gallery-content-' + content._key + index,[SetGall,SetIsRendering,],Gall,IsRendering, index === carouselPosition)} */}
            </div>
          );
        })}
        {/* index<3?false:true */}
      </Carousel>
      <div
        className="thumb-container"
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Icons.ChevronLeft
          style={{ height: '84px' }}
          onClick={() => Decrement()}
        />
        {gallery.map((content, index) => {
          //////////onsole.log('carouselPosition', carouselPosition);
          //////////onsole.log('IsSelected ', index === carouselPosition);
          let isSelected = index === carouselPosition;
          let isvisible =
            index > lowestindexshown - 1 && index < highestindexshown;
          // let isvisible = index > carouselPosition - Math.floor(GalleryWidth/2)|| index < carouselPosition + Math.floor(GalleryWidth/2)
          return isvisible ? (
            <div
              className="Gallery-Container"
              onClick={() => {
                prepareCurrentGalleryContent(index);
                setCarouselPosition(index);
              }}
              style={{
                maxWidth: '100px',
                minWidth: '100px',
                height: '100px',
                backgroundColor: 'black',
                marginLeft: '5px',
                marginRight: '5px'
              }}
              key={'gallery-content-' + content._key + index}
            >
              <PortfolioContentOrThumbnail
                Ele={{ gallery_index: index, page_id: page._key, id: '' }}
                chosenPortfolio={chosenPortfolio}
                Thumb={true}
                isSelected={isSelected}
              />
              {/* {renderThumbnail(content._type, index, isSelected)} */}
            </div>
          ) : null;
          // return (
          //   <div style={{maxWidth:"100px",minWidth:"100px", height:'100px', backgroundColor:"black", marginLeft:"5px", marginRight:"5px",}} key={'gallery-content-' + content._key + index}>
          //     {renderThumbnail(content._type, index, isSelected)}
          //   </div>
          // );
        })}
        <Icons.ChevronRight
          style={{ height: '84px' }}
          onClick={() => Increment()}
        ></Icons.ChevronRight>
      </div>
      <div
        className="page-count-container"
        style={{ display: 'flex', justifyContent: 'center', height: '2em' }}
      >
        <p>
          <input
            type="number"
            style={{
              width: `${(carouselPosition + 1).toString().length * 10 + 20}px`,
              fontSize: 'inherit',
              fontFamily: FontUtil(chosenPortfolio.SystemText)
            }}
            value={carouselPosition + 1}
            onChange={(eve) => {
              if (
                eve.target.value - 1 >= 0 &&
                eve.target.value - 1 < gallery.length
              ) {
                prepareCurrentGalleryContent(eve.target.value - 1);
                setCarouselPosition(eve.target.value - 1);
              }
              if (
                eve.target.value - 1 >= highestindexshown &&
                highestindexshown < gallery.length
              ) {
                sethighestindexshown(highestindexshown + 1);
                setlowestindexshown(lowestindexshown + 1);
              } else if (
                eve.target.value - 1 <= lowestindexshown &&
                lowestindexshown > 0
              ) {
                sethighestindexshown(highestindexshown - 1);
                setlowestindexshown(lowestindexshown - 1);
              }
            }}
          ></input>{' '}
          of {gallery.length}
        </p>
      </div>
    </div>
  );
  // else
  // {
  //   return (
  //     <div className="portfolio-gallery-view">
  //       <Carousel
  //         onChange={prepareCurrentGalleryContent}
  //         renderThumbs={(children) =>
  //           children
  //             .filter(({ props }) => props && !props.unique)
  //             .map((item, index) => {
  //               const { props } = item;
  //               return (
  //                 <div key={index + '-thumbnail'}>
  //                   {renderThumbnail(props.children.props)}
  //                 </div>
  //               );
  //             })
  //         }
  //       >
  //         {gallery.map((content, index) => {
  //           return (
  //             <div key={'gallery-content-' + index}>
  //               {renderSingleContent(content._type, index)}
  //             </div>
  //           );
  //         })}
  //       </Carousel>
  //     </div>
  //   );
  // }
};
export default GalleryView;
//(carouselPosition>0)?(setCarouselPosition(carouselPosition - 1)):(setCarouselPosition(gallery.length))
//(carouselPosition<gallery.length -1)?setCarouselPosition(carouselPosition + 1):setCarouselPosition(0)
