import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import styled from 'styled-components'; //{keyframes}
// import SlideClass from '../../../assets/modules/SlideUp.module.css';
import Icons from '../../../containers/Icons';
import '../../../assets/images/arrowUp2.png';
import { IconContext } from 'react-icons';
import Modal from '../../../containers/Modal';
import Like from '../../../utils/Likes';
import CustomerInfoForm from '../../../containers/CustomerInfoForm';

const HoverDiv = styled.div`
  transition: bottom 0.5s;
  bottom: -91px;
  &:hover {
    bottom: 0px;
  }
  &:active {
    bottom: 0px;
  }
  &:focus {
    bottom: 0px;
  }
  @media (max-width: 1367px) and (min-width: 1025px) {
    height: 50px;
    transform: translateY(0px);
    bottom: 0px;
  }

  @media (max-width: 1024px) and (max-height: 1024px) {
    height: 45px;
    transform: translateY(0px);
    bottom: 0px;
  }

  @media (max-width: 1367px) and (min-height: 1025px) {
    height: 120px;
    transform: translateY(0px);
    bottom: 0px;
  }

  @media (max-width: 1024px) and (min-width: 769px) and (min-height: 769px) {
    height: 150px;
    transform: translateY(0px);
    bottom: 0px;
  }

  @media (max-width: 768px) and (max-height: 1024px) {
    height: 90px;
    transform: translateY(0px);
    bottom: 0px;
  }

  @media (max-width: 450px) and (orientation: portrait) {
    position: absolute;
    height: 70px;
    bottom: 140px;
  }

  @media (max-width: 380px) and (orientation: portrait) {
    position: absolute;
    height: 70px;
    bottom: 140px;
  }
`;

const HoverDivKiosk = styled.div`
  height: 50px;
  transition: bottom 0.5s;
  bottom: 0px;
  &:hover {
    bottom: 0px;
  }
  &:active {
    bottom: 0px;
  }
  &:focus {
    bottom: 0px;
  }
  @media (max-width: 1367px) and (min-width: 1025px) {
    height: 50px;
    transform: translateY(0px);
    bottom: 0px;
  }

  @media (max-width: 1024px) and (max-height: 1024px) {
    height: 45px;
    transform: translateY(0px);
    bottom: 0px;
  }

  @media (max-width: 1367px) and (min-height: 1025px) {
    height: 50px;
    transform: translateY(0px);
    bottom: 0px;
  }

  @media (max-width: 1024px) and (min-width: 769px) and (min-height: 769px) {
    height: 50px;
    transform: translateY(0px);
    bottom: 0px;
  }

  @media (max-width: 768px) and (max-height: 1024px) {
    height: 50px;
    transform: translateY(0px);
    bottom: 0px;
  }

  @media (max-width: 450px) and (orientation: portrait) {
    position: absolute;
    height: 70px;
    bottom: 140px;
  }

  @media (max-width: 380px) and (orientation: portrait) {
    position: absolute;
    height: 70px;
    bottom: 140px;
  }
  .menu-icon-custom {
    .containDiv {
      height: 50px !important;
      width: 50px !important;
    }
  }
`;

const IconContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  top: 0px;
  .icon {
    height: 50px;
    width: 50px;
  }
`;

const PortfolioSlideUpMenu2 = ({
  setMenuHide,
  currentContent,
  userInfo,
  mode,
  sessionId,
  showBriefcase,
  briefcaseContent,
  isModalOpen,
  chosenPortfolio,
  companyConfig,
  isGuestMode,
  currentGuestContentHelper,
  liked,
  toggleBriefcase,
  addToBriefcase,
  endPortfolio,
  setPortfolioCustomerInfo,
  showModal,
  hideModal,
  toggleNotepad,
  toggleScriptnote,
  setLiked,
  menuHide,
  updateSessionInSanity,
  clearAll,
  Size,
  booleanNewItems,
  setBooleanNewItems,
  SET_ANNOTATOR,
  ANNOTATOR2
}) => {
  // const [isAddable, setIsAddable] = useState(true); // bool to keep track of if it is addable to briefcase
  const [isAddable, setIsAddable] = useState(true); // bool to keep track of if it is addable to briefcase
  const [isAuthToAdd, setIsAuthToAdd] = useState(false); // is this user authorized to add item?
  const [isAuthBriefcaseShow, setIsAuthBriefcaseShow] = useState(false); // is this user authorized to see briefcase?
  const [isLiked2, setIsLiked2] = useState(null); // is this user authorized to see briefcase?
  const history = useHistory();
  const firestore = useFirestore();
  const firebase = useFirebase();
  const Keycheck = (eve) => {
    if (eve.key !== 'F8') return;
    onExit();
  };

  useEffect(() => {
    window.addEventListener('keydown', Keycheck);
    return () => window.removeEventListener('keydown', Keycheck);
  });
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  };

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // check each time chosenContent or briefcaseContent updates
  useEffect(() => {
    // check if briefcase contains current content user is looking at
    if (
      (Object.entries(currentContent).length !== 0 &&
        briefcaseContent.filter((e) => e.id === currentContent.id).length >
          0) ||
      currentContent.page_id === `splashkey-${chosenPortfolio._id}`
    ) {
      setIsAddable(false);
    } else {
      setIsAddable(true);
    }
  }, [currentContent, briefcaseContent, chosenPortfolio]);

  // get userinfo auth for viewing sending briefcase
  useEffect(() => {
    if (userInfo && userInfo.length > 0) {
      // check if user is able to create guest pass
      // TODO: Why is this constant condition?
      if (true) {
        // userInfo[0].is_able_to_create_briefcase
        // user is able to create normal guest pass, aka able to add item to briefcase
        setIsAuthToAdd(true);
      }
      // check if user is able create portfolio level guest pass
      if (true) {
        // userInfo[0].is_able_to_create_guest_pass
        // user is able to create normal guest pass, aka able to add item to briefcase
        setIsAuthBriefcaseShow(true);
      }
    }
  }, [userInfo]);

  const endSession = () => {
    endPortfolio();
    history.push('/engage');
  };

  const logout = () => {
    //////////onsole.log('Guest user logout');
    firebase.logout();
    // clear sessionkey in local storage and redux session key
    //updateSessionKey('');
    //clear all state
    clearAll();
  };

  const onExit = () => {
    if (sessionId === '') {
      endSession();
    } else {
      // create page visit info obj
      const pageVisitInfo = {
        is_end: true,
        time_visited: firestore.Timestamp.now()
      };
      firestore
        .collection('sessions')
        .doc(sessionId)
        .update({
          page_visited: firestore.FieldValue.arrayUnion(pageVisitInfo)
        })
        .then((e) => {
          // success
          //////////onsole.log('Firestore set/update success');
          // if guest mode logout
          // check guest mode
          if (isGuestMode) {
            logout();

            // open url
            if (
              chosenPortfolio.end_demo_url &&
              chosenPortfolio.end_demo_url !== ''
            ) {
              window.open(chosenPortfolio.end_demo_url, '_self');
            }
            return;
          } else {
            // end session for user
            endSession();
          }
        })
        .catch((error) => {
          console.error(error);
          endSession();
        });
    }
  };

  const updateFavorite = (op) => {
    Like(
      op,
      isGuestMode,
      sessionId,
      liked,
      currentGuestContentHelper,
      currentContent,
      chosenPortfolio,
      setIsLiked2,
      setLiked,
      firestore
    );
  };

  // check if current page is liked already or not
  const isLiked = () => {
    let currentGuestContentId = currentGuestContentHelper.id;
    // check if currentGuestContentHelper id is undefined
    if (!currentGuestContentId) {
      //////////onsole.log('Liked check in full portfolio guest-pass view...');
      // check if liked contains currentContent id
      return liked.some((obj) => obj.id === currentContent.id);
    } else {
      // user in briefcase guest view
      //////////onsole.log('Liked check in Briefcase guest view...');
      if (currentGuestContentId === `splashkey-${chosenPortfolio._id}`) {
        // check if liked contains currentContent id for splash
        return liked.some((obj) => obj.id === currentContent.id);
      } else {
        // check if liked contains currentGuestContentId
        return liked.some((obj) => obj.id === currentGuestContentId);
      }
    }
  };

  const updateCustomerInfo = (form) => {
    // update customer info in store
    setPortfolioCustomerInfo(form);

    if (sessionId === '') {
      return null;
    }

    const customerReplacement = {
      customer_name: form.firstName + ' ' + form.lastName,
      customer_email: form.email
    };
    let viewerLog = [];
    let viewerTemplate = {
      _type: 'viewerLog'
    };

    // add main customer
    viewerLog.push({
      ...viewerTemplate,
      email: customerReplacement.customer_email,
      name: customerReplacement.customer_name,
      _key: Math.random().toString(36).substr(2, 7)
    });

    // add cc
    if (form.ccEmails) {
      form.ccEmails.forEach((email) => {
        viewerLog.push({
          ...viewerTemplate,
          email: email,
          _key: Math.random().toString(36).substr(2, 7)
        });
      });
    }

    // add viewer log to customerReplacement.
    customerReplacement['viewer_log'] = viewerLog;

    // update customer info in sanity session and firestore
    firestore
      .collection('sessions')
      .doc(sessionId)
      .update(customerReplacement)
      .then(() => {
        // update viewer log info in sanity
        updateSessionInSanity({ viewer_log: customerReplacement.viewer_log });
        hideModal();
      })
      .catch((error) => {
        console.error('ERROR: ', error);
        hideModal();
      });
  };
  let primaryColor;
  if (chosenPortfolio.action_menu_color)
    primaryColor =
      chosenPortfolio.action_menu_color !== ''
        ? chosenPortfolio.action_menu_color
        : 'gray';
  else
    primaryColor =
      chosenPortfolio.primary_color !== ''
        ? chosenPortfolio.primary_color
        : 'gray';
  // let secondaryColor =
  // chosenPortfolio.secondary_color !== ''
  //   ? chosenPortfolio.secondary_color
  //   : 'white';
  let accentColor =
    chosenPortfolio.accent_color !== ''
      ? chosenPortfolio.accent_color
      : 'black';

  const [displayArrow, setDisplayArrow] = useState(1);

  const hideDisplayArrow = () => {
    //////onsole.log("TRIGGERING HIDE DISPLAY ARROW");
    //////onsole.log(testRef?.current);
    setDisplayArrow(0);
    window.setTimeout(() => {
      window.addEventListener('click', CloseDrop);
    }, 50);
    window.setTimeout(() => {
      SetDeployed(true);
    }, 750);
  };

  const showDisplayArrow = () => {
    ////////onsole.log(testRef?.current);
    //////onsole.log("TRIGGERING SHOW DISPLAY ARROW");
    setDisplayArrow(1);
    SetDeployed(false);
    window.removeEventListener('click', CloseDrop);
  };

  //////////onsole.log("!?CURRENT ARROW DISPLAY: ", displayArrow);
  //////onsole.log("ANI",chosenPortfolio.icon_set?.annotator_icon);

  let icons;

  if (menuHide === true) {
    icons = (
      <>
        {isAddable && isAuthToAdd && (
          <Icons.AddShoppingCart
            id="Bypass"
            className="icon"
            onClick={() => {
              addToBriefcase(currentContent);
              // if (!showBriefcase) {
              //   toggleBriefcase();
              // }
              if (showBriefcase === true) {
                setBooleanNewItems(false);
              } else {
                setBooleanNewItems(true);
              }
            }}
            data-tip="Add to Briefcase"
          />
        )}
        {(isAuthToAdd || isAuthBriefcaseShow || isGuestMode) && (
          <Icons.ShoppingCart
            id="Bypass"
            className="icon"
            onClick={() => {
              toggleBriefcase();
              setBooleanNewItems(false);
            }}
            data-tip="Show Briefcase"
            briefcaseContent={briefcaseContent}
            booleanNewItems={booleanNewItems}
          />
        )}
        {!isGuestMode ? (
          <Icons.PersonAdd
            id="Bypass"
            className="icon"
            onClick={showModal}
            data-tip="Change Info"
          />
        ) : null}
        {Size.W > 450 ? (
          <Icons.FullscreenExit
            id="Bypass"
            className="icon"
            onClick={() => setMenuHide(false)}
            data-tip="Fullscreen/Windowed Mode"
          />
        ) : null}
        <Icons.NoteAdd
          id="Bypass"
          className="icon"
          onClick={() => toggleNotepad()}
          data-tip="Add Note"
        />
        {mode === 'training' && (
          <Icons.SpeakerNotes
            id="Bypass"
            className="icon"
            onClick={toggleScriptnote}
            data-tip="Speaker Notes"
          />
        )}
        {isGuestMode ? (
          isLiked() && isLiked2 ? (
            <Icons.Favorite
              id="Bypass"
              onClick={() => {
                updateFavorite('remove');
              }}
            />
          ) : (
            <Icons.FavoriteBorder
              id="Bypass"
              onClick={() => {
                updateFavorite('add');
              }}
            />
          )
        ) : null}
        {isGuestMode ? null : (
          <Icons.CustomIconOne
            id="Bypass"
            className="icon"
            data-tip="Calendar"
          ></Icons.CustomIconOne>
        )}
        {
          /*props.chosenPortfolio.icon_set?.annotator_icon &&*/ <Icons.AnnotatorIcon
            onClick={() => SET_ANNOTATOR(!ANNOTATOR2)}
          />
        }
      </>
    );
  } else {
    icons = (
      <>
        {isAddable && isAuthToAdd && (
          <Icons.AddShoppingCart
            id="Bypass"
            className="icon"
            onClick={() => {
              addToBriefcase(currentContent);
              // if (!showBriefcase) {
              //   toggleBriefcase();
              // }
              if (showBriefcase === true) {
                setBooleanNewItems(false);
              } else {
                setBooleanNewItems(true);
              }
            }}
            data-tip="Add to Briefcase"
          />
        )}
        {(isAuthToAdd || isAuthBriefcaseShow || isGuestMode) && (
          <Icons.ShoppingCart
            data-tip="Show Briefcase"
            id="Bypass"
            className="icon"
            onClick={() => {
              toggleBriefcase();
              setBooleanNewItems(false);
            }}
            briefcaseContent={briefcaseContent}
            booleanNewItems={booleanNewItems}
          />
        )}
        {!isGuestMode ? (
          <Icons.PersonAdd
            id="Bypass"
            className="icon"
            onClick={showModal}
            data-tip="Change Info"
          />
        ) : null}
        {Size.W > 450 ? (
          <Icons.Fullscreen
            id="Bypass"
            className="icon"
            onClick={() => setMenuHide(true)}
            data-tip="Fullscreen/Windowed Mode"
          />
        ) : null}
        <Icons.NoteAdd
          id="Bypass"
          className="icon"
          onClick={toggleNotepad}
          data-tip="Add Note"
        />
        {mode === 'training' && (
          <Icons.SpeakerNotes
            id="Bypass"
            className="icon"
            onClick={toggleScriptnote}
            data-tip="Speaker Notes"
          />
        )}
        {isGuestMode ? (
          isLiked() && isLiked2 ? (
            <Icons.Favorite
              id="Bypass"
              onClick={() => {
                updateFavorite('remove');
              }}
            />
          ) : (
            <Icons.FavoriteBorder
              id="Bypass"
              onClick={() => {
                updateFavorite('add');
              }}
            />
          )
        ) : null}
        {isGuestMode ? null : (
          <Icons.CustomIconOne
            id="Bypass"
            className="icon"
            data-tip="Calendar"
          ></Icons.CustomIconOne>
        )}
        {
          /*props.chosenPortfolio.icon_set?.annotator_icon &&*/ <Icons.AnnotatorIcon
            onClick={() => SET_ANNOTATOR(!ANNOTATOR2)}
          />
        }
      </>
    );
  }
  let testRef = React.useRef();
  const CloseDrop = (e) => {
    //////onsole.log(e);
    if (e.srcElement.id === 'Bypass2' && displayArrow === 0) CloseAct();
    else if (e.srcElement.id !== 'Bypass' && e.srcElement.id !== 'Bypass2')
      CloseAct();
    //CloseAct();
  };
  const CloseAct = () => {
    showDisplayArrow();
    window.removeEventListener('click', CloseDrop);
  };
  const [deployed, SetDeployed] = useState(false);
  // const toggling = () => {
  //   if(!isOpen){

  //     window.setTimeout(()=>{window.addEventListener("click",CloseDrop);},50)
  //   }
  //   setIsOpen(!isOpen);}
  if (!chosenPortfolio.KioskMode)
    return (
      <>
        <div className="portfolio-action-menu">
          {isModalOpen && (
            <Modal>
              <CustomerInfoForm
                handleSubmit={updateCustomerInfo}
                submitText="Update"
              />
            </Modal>
          )}
        </div>

        <HoverDiv
          //onTransitionEnd={(eve)=>{if(eve.currentTarget.id !== "Bypass") return; if(eve.propertyName !== "transform") return; //////onsole.log("!!TRANS", eve.currentTarget, eve.currentTarget.firstChild)}}
          Arrow={displayArrow}
          height={windowDimensions.height}
          width={windowDimensions.width}
          id="Bypass"
          className={'MobileToolbar'}
          style={{
            borderTop: `1px solid ${accentColor}`,
            backgroundColor: `${primaryColor}`,
            position: 'fixed',

            height:
              windowDimensions.height < 450 || windowDimensions.width < 450
                ? undefined
                : '90px',
            width: '100%'
            // bottom:Size.W < 450?`${window.outerheight - window.innerHeight}`:""
          }}
          onMouseEnter={() =>
            (windowDimensions.width <= 1366 &&
              windowDimensions.height <= 1024) ||
            (windowDimensions.width <= 1024 && windowDimensions.height >= 1025)
              ? null
              : hideDisplayArrow()
          }
          onMouseLeave={() =>
            (windowDimensions.width <= 1366 &&
              windowDimensions.height <= 1024) ||
            (windowDimensions.width <= 1024 && windowDimensions.height >= 1025)
              ? null
              : showDisplayArrow()
          }
          ref={testRef}
        >
          {/* //id="Bypass" 
        else{
                //showDisplayArrow()
              }
        */}
          {Size.W > 450 ? (
            <Icons.SlideUpArrow
              style={{
                height: '100%',
                width: '100%',
                visibility:
                  windowDimensions.width < windowDimensions.height ||
                  windowDimensions.width <= 1366
                    ? 'hidden'
                    : 'visible',
                top: '-70px'
                // visibility: (windowDimensions.width <= 1366 && windowDimensions.height <= 1024) || (windowDimensions.width <= 1024  && windowDimensions.height >= 1025) ? 'hidden' : 'visible'
                //opacity:testRef?.current?.style?.Transform === "translateY(125px)" ? 0 : 1,
              }}
              onClick={(eve) => {
                if (displayArrow === 1) {
                  hideDisplayArrow();
                } else if (deployed) {
                  if (
                    (windowDimensions.width <= 1366 &&
                      windowDimensions.height <= 1024) ||
                    (windowDimensions.width <= 1024 &&
                      windowDimensions.height >= 1025)
                  ) {
                    return;
                  } else {
                    showDisplayArrow();
                  }
                }
              }}
              id="Bypass2"
              opacity={displayArrow}
            />
          ) : null}
          <IconContainer id="Bypass">
            <IconContext.Provider value={{ className: 'menu-icon-md' }}>
              {icons}
            </IconContext.Provider>
            {(!chosenPortfolio.KioskMode || isGuestMode) && (
              <Icons.EndDemo id="Bypass" onClick={onExit} />
            )}
          </IconContainer>
        </HoverDiv>
      </>
    );
  else
    return (
      <>
        <div className="portfolio-action-menu">
          {isModalOpen && (
            <Modal>
              <CustomerInfoForm
                handleSubmit={updateCustomerInfo}
                submitText="Update"
              />
            </Modal>
          )}
        </div>

        <HoverDivKiosk
          //onTransitionEnd={(eve)=>{if(eve.currentTarget.id !== "Bypass") return; if(eve.propertyName !== "transform") return; //////onsole.log("!!TRANS", eve.currentTarget, eve.currentTarget.firstChild)}}
          Arrow={displayArrow}
          height={windowDimensions.height}
          width={windowDimensions.width}
          id="Bypass"
          className={'MobileToolbar'}
          style={{
            borderTop: `1px solid ${accentColor}`,
            backgroundColor: `${primaryColor}`,
            position: 'fixed',

            //  height:windowDimensions.height<450||windowDimensions.width<450?undefined:"90px",
            width: '100%'
            // bottom:Size.W < 450?`${window.outerheight - window.innerHeight}`:""
          }}
          onMouseEnter={() =>
            (windowDimensions.width <= 1366 &&
              windowDimensions.height <= 1024) ||
            (windowDimensions.width <= 1024 && windowDimensions.height >= 1025)
              ? null
              : hideDisplayArrow()
          }
          onMouseLeave={() =>
            (windowDimensions.width <= 1366 &&
              windowDimensions.height <= 1024) ||
            (windowDimensions.width <= 1024 && windowDimensions.height >= 1025)
              ? null
              : showDisplayArrow()
          }
          ref={testRef}
        >
          {/* //id="Bypass" 
        else{
                //showDisplayArrow()
              }
        */}
          {/* {Size.W > 450 ? <Icons.SlideUpArrow
            style={{
              display:"none",
              height: "100%",
              width: "100%",
              visibility: (windowDimensions.width < windowDimensions.height) || (windowDimensions.width <= 1366) ? 'hidden' : 'visible',
              top:'-70px'
              // visibility: (windowDimensions.width <= 1366 && windowDimensions.height <= 1024) || (windowDimensions.width <= 1024  && windowDimensions.height >= 1025) ? 'hidden' : 'visible'
              //opacity:testRef?.current?.style?.Transform === "translateY(125px)" ? 0 : 1,
            }}
            onClick={(eve)=>{
              if (displayArrow === 1) {
                hideDisplayArrow();
              } else if (deployed) {
                if ((windowDimensions.width <= 1366 && windowDimensions.height <= 1024) || (windowDimensions.width <= 1024  && windowDimensions.height >= 1025)) {
                  return;
                } else {
                  showDisplayArrow();
                }
              }
              
          }}
          id="Bypass2"
          opacity={displayArrow}
        /> : null} */}
          <IconContainer id="Bypass">
            <IconContext.Provider value={{ className: 'menu-icon-md' }}>
              {icons}
            </IconContext.Provider>
            {(!chosenPortfolio.KioskMode || isGuestMode) && (
              <Icons.EndDemo id="Bypass" onClick={onExit} />
            )}
          </IconContainer>
        </HoverDivKiosk>
      </>
    );
};
//CustomIconOne
export default PortfolioSlideUpMenu2;
