import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import PortfolioContentParent from '../PortfolioContentParent/PortfolioContentParent1';
import PortfolioContentParent2 from '../../components/_Portfolio/PortfolioContentParentTemplateX';
import PortfolioContentParent3 from '../PortfolioContentParent/PortfolioContentParent3';
import PortfolioContentParent8 from '../PortfolioContentParent/PortfolioContentParent8';
import PortfolioContentParent11 from '../PortfolioContentParent/PortfolioContentParent11';
// import PortfolioContentParentBeMobile from '../PortfolioContentParent/PortfolioContentParentBeMobile'
import PortfolioMenu from '../../components/_Portfolio/PortfolioMenu';
import PortfolioActionMenu from '../PortfolioActionMenu';
import PortfolioActionMenuGrid from '../PortfolioActionMenuGridFriendly';
// import PortfolioSlideUpMenu from '../../components/_Portfolio/PortfolioSlideUpMenu';
import PortfolioSlideUpMenu2 from '../../components/_Portfolio/PortfolioSlideUpMenu2';
import Select from 'react-select';
import ScreenSaver from '../ScreenSaver';
import Cover from '../../components/Theme9';
import { useStore } from 'react-redux';
import _ from 'lodash';
// import ActionMenuT9 from  '../../components/_Portfolio/PortfolioTheme9/BottomLeftToolbar'
import ActionMenuT92 from '../../components/_Portfolio/PortfolioTheme9/BottomLeftToolbar2';
import ActionMenuT93 from '../../components/_Portfolio/PortfolioTheme9/ActionMenuToolbar/ActionMenuContents';
import { useIdleTimer } from 'react-idle-timer';
import PortfolioBriefcase2 from '../../components/_Portfolio/PortfolioBriefcaseTranscribedV2';
import { lastPrepPortfolio } from '../../actions';
//setPortfolioSubpageId
import Notepad from '../Notepad';
import Notepad2 from '../Notepad2';
import ScriptNote from '../Scriptnote';
import ScriptNote2 from '../../components/_PortfolioContentParent8/Scriptnote';
import Briefcase from '../Briefcase';
import BeUniqueBriefcase from '../../components/_Portfolio/PortfolioBriefcaseBeUnique';
import ANNO from '../Annotatormodal';
import QRCodec from 'qrcode';
import { GUEST_BASE_URL } from '../../constants/constants';
import TopRightToolbar from '../../components/TopRightToolbar';
import BuildRoundedIcon from '@material-ui/icons/BuildRounded';
import Modal from '../Modal';
import CustomerInfoForm from '../CustomerInfoForm';
import { sortByKey } from '../../utils/alphabetizer';
//import IN from '../../components/InputBox'
import { emailIsValid } from '../../utils/validateHelper';
// import Button from '../../components/Button';
import PortfolioActionMenuPopup from '../PortfolioActionMenuPopup';
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';
import GuestPassSelfSend from '../GuestPassPageSendSelfDialogue';
// import FileCopyIcon from '@material-ui/icons/FileCopy';
// import Copy from '../../assets/images/copy.png';
// import Clipboard from '../../assets/images/clipboard.svg';
import { urlFor } from '../../utils/sanityHelper';
import FontUtil from '../../utils/FontUtility';

const PortfolioPage = ({
  kmReset,
  endPortfolio,
  RPMS,
  auth,
  userInfo,
  setPortfolioSessionId,
  addSessionToSanity,
  portfolio,
  member,
  companyConfig,
  mode,
  chosenPortfolioId,
  chosenPortfolio,
  pages,
  nav,
  customerInfo,
  backgroundImageUrl,
  setCurrentLevel,
  sessionId,
  briefcaseContent,
  pageMenuReducer,
  isGuestMode,
  addGuestPassToSanity,
  setChosenPageId,
  setChosenMainPageButtonId,
  subPageId,
  portfoliolaunchImageUrl,
  setNavHistory,
  setContentPageId,
  Topics,
  Topics2,
  clearAll,
  hasCurr,
  hasPrev,
  isAtEnd,
  portfolioTopLevelImageUrl,
  portfolioSubMenuImageUrl,
  portfolioTermMenuImageUrl,
  Size,
  toolbarMenuOpen,
  setToolbarMenuOpen,
  isModalOpen,
  setPortfolioCustomerInfo,
  hideModal,
  updateSessionInSanity,
  videos,
  notepadText,
  notepadText2,
  liked,
  activeSessionNotifications,
  setActiveSessionNotifications,
  updateNotepad2
}) => {
  //Flag Font Family
  const [DocRef, SetDocRef] = useState('');
  const [isPortfolio, SetisPortfolio] = useState('');

  const [dropdownOptions, setDropdownOptions] = useState([]);
  //const [EmailValid, SetEmailValid] = useState(true);
  //const [select, setSelect] = useState(null);
  const [Email, setEmail] = useState('');
  const [currentContent, setCurrentContent] = useState({}); // current viewed content
  const [showSendEmailPopup, setShowSendEmailPopup] = useState(false); // popup/modal for email send notification
  //const [sendEmailPopupText, setSendEmailPopupText] = useState(''); // text for email send notification
  const [urlContent, setUrlContent] = useState('');
  const [copied, setCopied] = useState(false);
  const clipboardRef = useRef();
  const imgRef = useRef();
  const history = useHistory();
  const firebase = useFirebase();
  const firestore = useFirestore();
  const store = useStore();

  const styles = {
    control: (base) => ({
      ...base,
      width:
        DivRef.current && Size.W > 450
          ? DivRef.current.offsetWidth
          : Size.W > 450
          ? '384px'
          : '275px',
      //height:DivRef.current? DivRef.current.offsetHeight:'36px',
      borderColor: chosenPortfolio.primary_color + '!important',
      borderWidth: '3px',
      borderRadius: '0px', // PING
      borderTopRightRadius: '10px',
      borderBottomRightRadius: '10px'
      //WebkitOverflowScrolling:"touch"
    }),
    valueContainer: (base) => ({
      ...base,
      borderColor: chosenPortfolio.primary_color
    }),
    container: (base) => ({
      ...base,
      //color: chosenPortfolio.primary_color,
      borderColor: chosenPortfolio.primary_color,
      borderWidth: '3px',
      borderRadius: '0px', // PING
      borderTopRightRadius: '10px',
      borderBottomRightRadius: '10px',
      textAlign: 'left',
      fontSize: '22px'
      //WebkitOverflowScrolling:"touch"
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0
    }),
    clearIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0
    })
  };

  // Add page visit to session, for firestore
  // Fix to resolve infinite render using useCallback
  const addPageVisit = useCallback(
    (obj) => {
      // const addPageVisit = (obj) => {
      // do not log if mode is not default or no sessionId
      if (sessionId === '') {
        return null;
      }
      // create page visit info obj
      const pageVisitInfo = {
        id: obj.page_id,
        title: obj.title,
        content_type: obj.content_type,
        is_gallery_item: obj.is_gallery_item,
        gallery_index: obj.gallery_index ? obj.gallery_index : -1,
        is_end: obj.is_end ? obj.is_end : false,
        is_idle: obj.is_idle ? obj.is_idle : false,
        time_visited: firestore.Timestamp.now(),
        Topic: store.getState().pageMenuReducer.Topic
          ? store.getState().pageMenuReducer.Topic
          : null
      };
      ////onsole.log(pageVisitInfo);
      //try{
      return firestore
        .collection('sessions')
        .doc(sessionId)
        .update({
          page_visited: firestore.FieldValue.arrayUnion(pageVisitInfo)
        });
      //  }
      //  catch{
      //   //////onsole.log("IN ERROR",pageVisitInfo)
      //    return null;
      //  }
    },
    [firestore, sessionId, store]
  );

  useEffect(() => {
    // Create dropdown content
    let options = [];
    //onsole.log("PORTFOLIO", portfolio);
    if (portfolio !== undefined) {
      portfolio.forEach((obj) => {
        if (userInfo[0]?.portfolio?.includes(obj._id)) {
          options.push({ value: obj._id, label: obj.title });
        }
      });
      options = sortByKey(options, 'label');
      // options = options.sort((a,b)=>{
      //   let Ca = a.label.toUpperCase();
      //   let Cb = b.label.toUpperCase();
      //   if(Ca < Cb)
      //   return -1;
      //   else if(Ca > Cb)
      //   return 1;
      //   return 0;
      // })
      setDropdownOptions(options);
    }
  }, [portfolio, userInfo]);

  // check if customer info, mode, chosenPortfolioId exists
  useEffect(() => {
    if (mode === '' || chosenPortfolioId === '') {
      console.error('Error, missing necessary info to load portfolio page');
      history.push('/engage');
    }
  }, [mode, chosenPortfolioId, customerInfo, history]);

  useEffect(() => {
    if (isGuestMode) {
      document.addEventListener('visibilitychange', GPReset);

      return () => {
        document.removeEventListener('visibilitychange', GPReset);
      };
    }
  });

  const onExit = (VisTrigger) => {
    if (sessionId === '') {
      endSession();
    } else {
      // create page visit info obj
      const pageVisitInfo = {
        is_end: true,
        time_visited: firestore.Timestamp.now()
      };
      firestore
        .collection('sessions')
        .doc(sessionId)
        .update({
          page_visited: firestore.FieldValue.arrayUnion(pageVisitInfo)
        })
        .then((e) => {
          // success
          //////////onsole.log('Firestore set/update success');
          // if guest mode logout
          // check guest mode
          if (isGuestMode) {
            let data = store.getState().guestReducer.GP_Data;
            logout();

            // open url
            if (
              chosenPortfolio.end_demo_url &&
              chosenPortfolio.end_demo_url !== '' &&
              !VisTrigger
            ) {
              window.open(chosenPortfolio.end_demo_url, '_self');
            } else if (VisTrigger) {
              //onsole.log(window.location.pathname,window.location.pathname.split('/').length,store.getState());
              window.location =
                window.location.origin +
                `/${data.GuestMode}/${data.ID}/${data.Email}/${data.Name}`;
              // console.log(data)
            }
            return;
          } else {
            // end session for user
            endSession();
          }
        })
        .catch((error) => {
          console.error('ExitError', error);
          endSession();
        });
    }
  };

  const endSession = () => {
    endPortfolio();
    history.push('/engage');
  };
  const GPReset = (eve) => {
    if (document.visibilityState === 'hidden') onExit(true);
  };
  // check if guest url but not in guestmode
  useEffect(() => {
    if (
      history.location.pathname.includes('/portfolio/guest') &&
      !isGuestMode
    ) {
      console.error(
        'Error, accessing guest portfolio without being in guest mode'
      );
      history.push('/');
    }
  }, [isGuestMode, history]);

  const [KToggle, SetKToggle] = useState(false);

  // Fix to resolve infinite render using useCallback
  const createSetCurrentContent = useCallback(
    (obj) => {
      // const createSetCurrentContent = (obj) => {
      let page = pages[obj.id];
      let defaultObj;
      //onsole.log("PAGE: ", page);
      //onsole.log("OBJ: ", obj);
      try {
        defaultObj = {
          id: page._key,
          page_id: page._key,
          title: page.title,
          is_gallery_item: false,
          gallery_index: -1,
          content_type: page._type
        };
      } catch (ex) {
        //////onsole.log('ERROR createSetCurrentContent:defualtObj: ', ex);
        return;
      }
      //////onsole.log("DefaultOBJ: ", defaultObj);
      let modifiedObj = {};
      try {
        switch (obj.type) {
          case 'gallery':
            //////onsole.log("G")
            modifiedObj = {
              ...defaultObj,
              ...{
                id: `${defaultObj.id}-gallery-${obj.index}`,
                title: page.content[obj.index].title
                  ? page.content[obj.index].title
                  : `${defaultObj.title} ${obj.index + 1}`,
                is_gallery_item: true,
                gallery_index: obj.index,
                PagesSaved: page.content
                  ? page.content[obj.index]
                    ? page.content[obj.index].pagesSaved
                    : null
                  : null
              }
            };
            break;
          case 'STgallery':
            //////onsole.log("G")
            modifiedObj = {
              ...defaultObj,
              ...{
                id: `${defaultObj.id}-gallery-${obj.index}`,
                title: page.StoryBookgallery?.STcontent[obj.index].title
                  ? page.StoryBookgallery?.STcontent[obj.index].title
                  : `${defaultObj.title} ${obj.index + 1}`,
                is_gallery_item: true,
                gallery_index: obj.index,
                PagesSaved: page.StoryBookgallery?.STcontent
                  ? page.StoryBookgallery?.STcontent[obj.index]
                    ? page.StoryBookgallery?.STcontent[obj.index].pagesSaved
                    : null
                  : null
              }
            };
            break;
          case 'page':
            //////onsole.log("P")
            modifiedObj = {
              ...defaultObj,
              ...{
                id: `${defaultObj.id}-page`,
                PagesSaved:
                  page._type === 'gallery'
                    ? page.content
                      ? page?.content[0]
                        ? page?.content[0].pagesSaved
                        : null
                      : null
                    : page?.pagesSaved
              }
            };
            break;
          case 'pageSub':
            //////onsole.log("PS")
            modifiedObj = {
              ...defaultObj,
              ...{
                id: `${defaultObj.id}-page-sub`,
                PagesSaved:
                  page._type === 'gallery'
                    ? page.content
                      ? page?.content[0]
                        ? page?.content[0].pagesSaved
                        : null
                      : null
                    : page?.pagesSaved
              }
            };
            break;
          default:
            modifiedObj = { ...defaultObj };
        }
      } catch (ex) {
        //////onsole.log('ERROR createSetCurrentContent:ModifiedObj: ', ex);
      }
      // console.log("ModifiedOBJ: ", modifiedObj);
      setCurrentContent(modifiedObj);
      // add to page visit data
      addPageVisit(modifiedObj);
    },
    [addPageVisit, pages]
  );

  useEffect(() => {
    if (portfolio.length > 0 && chosenPortfolioId !== '') {
      let splashKeyId = `splashkey-${chosenPortfolio._id}`;
      if (chosenPortfolio === undefined) {
        console.error('Error, cant find page with given portfolio id');
        history.push('/engage');
      } else {
        // check if splash exists
        if (chosenPortfolio.splash) {
          //////onsole.log('Set splash screen as first');
          // set as splashKeyId as chosen pageid
          // NOTE splash page id will always be `splashkey-${chosenPortfolio._id}`
          setChosenMainPageButtonId(splashKeyId);
          setChosenPageId(splashKeyId);
          setContentPageId(splashKeyId);
          createSetCurrentContent({ id: splashKeyId });
        }
      }
    }
  }, [
    createSetCurrentContent,
    setChosenMainPageButtonId,
    setChosenPageId,
    setContentPageId,
    portfolio,
    chosenPortfolioId,
    chosenPortfolio,
    pages,
    nav,
    history,
    KToggle
  ]);
  const [DV, SetDV] = useState();
  useEffect(() => {
    SetDV(dropdownOptions.filter((V) => V.value === chosenPortfolioId)[0]);
  }, [dropdownOptions, chosenPortfolioId]);
  const DivRef = useRef();
  const HOCRef = useRef();
  const HOCScroll = (WE) => {
    HOCRef.current.scrollBy({ top: window.innerHeight, behavior: 'smooth' });
    setTimeout(() => {
      CoverAllowed.current = false;
      SetAltStateCover(false);
    }, 1000);
  };
  let CoverAllowed = useRef(true);
  let [AltStateCover, SetAltStateCover] = useState(true);
  useEffect(() => {
    CoverAllowed.current = !(HOCRef.current?.scrollTop > 0);
    if (!CoverAllowed.current) SetAltStateCover(false);
  }, []);
  const coreRef = useRef();
  // handle logout on idle
  const handleOnIdle = (event) => {
    if (chosenPortfolio.KioskMode) return;
    //////onsole.log('idle, logging out');
    console.log('last active', getLastActiveTime());
    let obj = {
      page_id: '',
      title: '',
      content_type: '',
      is_gallery_item: false,
      is_end: true
    };
    if (sessionId !== '') {
      addPageVisit(obj)
        .then(() => {
          // success
          //////onsole.log('end portfolio');
          logout();
        })
        .catch((error) => console.error(error));
    } else {
      //////onsole.log('end portfolio........');
      logout();
    }
  };

  // set timeout for aut log out
  const { getLastActiveTime } = useIdleTimer({
    timeout: chosenPortfolio.logout_timer
      ? 1000 * 60 * chosenPortfolio.logout_timer
      : undefined,
    onIdle: handleOnIdle,
    debounce: 500
  });

  const logout = () => {
    //////onsole.log('LOGOUT');
    firebase.logout();
    clearAll();
  };

  const handleToggle = () => {
    setToolbarMenuOpen(true);
  };

  useEffect(() => {
    console.log(liked);
    if (!sessionId) return;
    console.log('activeSessionNotifications1', activeSessionNotifications);
    let likedArrCopy = [...liked];
    firestore
      .collection('sessions')
      .doc(sessionId)
      .get()
      .then((val) => {
        let end = val;
        let NotificationsArrCopy =
          end.data() && end.data().notifications
            ? { ...end.data().notifications }
            : { likes: [], Notes: [] };
        NotificationsArrCopy.likes = likedArrCopy.map((val) => {
          return {
            time: val.time_liked,
            id: val.page_id,
            gallery_index: val.gallery_index,
            Viewed: NotificationsArrCopy.likes.some((val2) => {
              return (
                val2.gallery_index === val.gallery_index &&
                val2.id === val.page_id &&
                val2.Viewed
              );
            })
          };
        });
        setActiveSessionNotifications(NotificationsArrCopy);
        firestore
          .collection('sessions')
          .doc(sessionId)
          .update({
            liked: likedArrCopy,
            notifications: NotificationsArrCopy,
            updated_at: firestore.Timestamp.now()
          })
          .catch((error) => console.error('ERROR: ', error));
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liked]);
  //setActiveSessionNotifications
  //Store Notes on firestore
  const updateSessionWithNotes = async (Notes, PreviewText) => {
    let end = await firestore.collection('sessions').doc(sessionId).get();

    let NotificationsArrCopy = { ...end.data().notifications };

    NotificationsArrCopy.Notes = NotificationsArrCopy.Notes
      ? [
          ...NotificationsArrCopy.Notes,
          { time: firestore.Timestamp.now(), Text: PreviewText }
        ]
      : [{ time: firestore.Timestamp.now(), Text: PreviewText }];
    setActiveSessionNotifications(NotificationsArrCopy);
    firestore.collection('sessions').doc(sessionId).update({
      notes: Notes,
      notifications: NotificationsArrCopy,
      NoteTime: firestore.Timestamp.now(),
      updated_at: firestore.Timestamp.now()
    });
  };

  useEffect(() => {
    let temp = currentContent;
    if (notepadText === '' || notepadText === null || notepadText === undefined)
      return;
    updateNotepad2(
      notepadText2,
      notepadText,
      temp.title +
        ' Content item number: ' +
        Math.max(temp.gallery_index + 1, 1)
    );
    //eslint-disable-next-line
  }, [notepadText]);

  useEffect(() => {
    if (
      notepadText2 === '' ||
      notepadText2 === null ||
      notepadText2 === undefined
    )
      return;
    updateSessionWithNotes(notepadText2, notepadText);
    updateSessionInSanity({ notes: notepadText2 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notepadText2]);

  const updateCustomerInfo = (form) => {
    // update customer info in store
    setPortfolioCustomerInfo(form);

    if (sessionId === '') {
      return null;
    }

    const customerReplacement = {
      customer_name: form.firstName + ' ' + form.lastName,
      customer_email: form.email
    };
    let viewerLog = [];
    let viewerTemplate = {
      _type: 'viewerLog'
    };

    // add main customer
    viewerLog.push({
      ...viewerTemplate,
      email: customerReplacement.customer_email,
      name: customerReplacement.customer_name,
      _key: Math.random().toString(36).substr(2, 7)
    });

    // add cc
    if (form.ccEmails) {
      form.ccEmails.forEach((email) => {
        viewerLog.push({
          ...viewerTemplate,
          email: email,
          _key: Math.random().toString(36).substr(2, 7)
        });
      });
    }

    // add viewer log to customerReplacement.
    customerReplacement['viewer_log'] = viewerLog;

    // update customer info in sanity session and firestore
    firestore
      .collection('sessions')
      .doc(sessionId)
      .update(customerReplacement)
      .then(() => {
        // update viewer log info in sanity
        updateSessionInSanity({ viewer_log: customerReplacement.viewer_log });
        hideModal();
      })
      .catch((error) => {
        console.error('ERROR: ', error);
        hideModal();
      });
  };

  // add briefcase to current session, for firestore
  const addBriefcase = () => {
    // do not log if mode is not default or no sessionId
    if (sessionId === '') {
      return null;
    }

    // create briefcase info from briefcaseContent
    const briefcaseInfo = {
      pages: briefcaseContent,
      time_sent: firestore.Timestamp.now()
    };

    return firestore
      .collection('sessions')
      .doc(sessionId)
      .update({
        briefcase: firestore.FieldValue.arrayUnion(briefcaseInfo)
      });
  };

  // button click on menu, NOTE this may not be used anymore...  PING
  const menuButtonClicked = (id) => {
    //Ping
    try {
      if (
        pages[id].content &&
        pages[id]._type === 'gallery' &&
        pages[id].content.length > 1
      ) {
        // automatically set first item of gallery to current content viewed
        let obj = {
          type: 'gallery',
          index: 0,
          id: id
        };
        createSetCurrentContent(obj);
      } else {
        // else do normal
        let obj = {
          type: 'pageSub',
          id: id
        };
        createSetCurrentContent(obj);
      }
    } catch (err) {
      console.error('Error:', err);
    }
  };

  const [EmailTarget, SetEmailTarget] = useState('');

  const GuestPassPortSelection = (ID) => {
    firestore
      .collection('guest_passes')
      .doc(DocRef)
      .update({ portfolio_id: ID })
      .then((V) => {
        ////onsole.log(V)
      });
  };

  const KioskModePrep = (email) => {
    //Called in every circumstance which would be a break in a kiosk experience, if not kiosk mode, this returns promptly.
    if (!chosenPortfolio.KioskMode) {
      return;
    }
    const pageVisitInfo = {
      is_end: true,
      time_visited: firestore.Timestamp.now()
    };
    firestore
      .collection('sessions')
      .doc(sessionId)
      .update({
        customer_name: 'KM ' + email,
        page_visited: firestore.FieldValue.arrayUnion(pageVisitInfo),
        customer_email: email
      })
      .then((e) => {
        kmReset();
        //RPMS();
        lastPrepPortfolio({
          firstName: 'Kiosk',
          lastName: 'Mode',
          email: member.email
        });
        const session = {
          creator: member.name, // member/user name from sanity
          customer_name: 'Kiosk Mode',
          customer_company: '',
          page_visited: [],
          user_id: auth.uid,
          user_sanity_id: member._id,
          portfolio_id: chosenPortfolioId,
          session_date: firestore.Timestamp.now(),
          customer_email: member.email,
          type: mode,
          company_id: userInfo[0].company_id,
          updated_at: firestore.Timestamp.now()
        };
        ////////onsole.log("!!!SESSION!!",session)
        // create viewer log
        let viewerLog = [];
        let viewerTemplate = {
          _type: 'viewerLog'
        };

        // add main customer
        viewerLog.push({
          ...viewerTemplate,
          email: session.customer_email,
          name: session.customer_name,
          _key: Math.random().toString(36).substr(2, 7)
        });

        // add cc
        // add viewer log to session.
        session['viewer_log'] = viewerLog;
        // firestore add
        firestore
          .collection('sessions')
          .add(session)
          .then((docRef) => {
            //////onsole.log('Session create success');
            // set session_id in portfolioReducer
            setPortfolioSessionId(docRef.id);
            //add sessions to sanity with session_id created by firestore
            addSessionToSanity({
              ...session,
              session_id: docRef.id,
              session_type: mode === 'default' ? 'user' : 'training'
            });
            // history.push('/portfolio');
          });
        // let splashKeyId = `splashkey-${chosenPortfolio._id}`;
        setEmail('');
        SetKToggle(!KToggle);
      });
  };

  const shareClick = () => {
    let data = store.getState().guestReducer.GP_Data;
    //window.location.origin + `/${data.GuestMode}/${data.ID}/${data.Email}/${data.Name}`
    setUrlContent(window.location.origin + `/${data.GuestMode}/${data.ID}/`);
    //onsole.log(window.location)

    QRCodec.toDataURL(
      window.location.origin + `/${data.GuestMode}/${data.ID}/`
    ).then((R) => {
      //QRCodec.toDataURL(`mailto:?subject=${subject}&body=${body}`).then((R)=>{
      //imgRef.current.src = `${R}`
      imgRef.current.src = `${R}`;
    });
    if (chosenPortfolio.message) {
      let subject = chosenPortfolio.message?.email_subject
        ? chosenPortfolio.message?.email_subject
        : chosenPortfolio.message.email_subject_BC;
      if (subject === undefined || subject === null) subject = '';
      let body = '';
      body += `%0D%0A${
        window.location.origin + `/${data.GuestMode}/${data.ID}/`
      }`;
      SetEmailTarget(
        `mailto:${data.Email}?subject=${subject}&body=${body}`.replaceAll(
          ' ',
          '%20'
        )
      );
      setShowSendEmailPopup(true);
    } else {
      let subject = '';
      let body = '';
      body += `%0D%0A${
        window.location.origin + `/${data.GuestMode}/${data.ID}/`
      }`;
      SetEmailTarget(
        `mailto:${data.Email}?subject=${subject}&body=${body}`.replaceAll(
          ' ',
          '%20'
        )
      );
      setShowSendEmailPopup(true);
    }
  };
  // function sleep(ms) {
  //   return new Promise(resolve => setTimeout(resolve, ms));
  // }
  const shareClick2 = async () => {
    let data = store.getState().guestReducer.GP_Data;
    //window.location.origin + `/${data.GuestMode}/${data.ID}/${data.Email}/${data.Name}`
    setUrlContent(window.location.origin + `/${data.GuestMode}/${data.ID}/`);
    console.log(window.location);

    QRCodec.toDataURL(
      window.location.origin + `/${data.GuestMode}/${data.ID}/`
    ).then((R) => {});
    if (chosenPortfolio.message) {
      let subject = chosenPortfolio.message?.email_subject
        ? chosenPortfolio.message?.email_subject
        : chosenPortfolio.message?.email_subject_BC;
      if (subject === undefined || subject === null) subject = '';
      let body = '';
      body += `%0D%0A${
        window.location.origin + `/${data.GuestMode}/${data.ID}/`
      }`;
      SetEmailTarget(
        `mailto:${data.Email}?subject=${subject}&body=${body}`.replaceAll(
          ' ',
          '%20'
        )
      );
      OpenEmail(
        `mailto:${data.Email}?subject=${subject}&body=${body}`.replaceAll(
          ' ',
          '%20'
        )
      );
      //setShowSendEmailPopup(true);
    } else {
      let subject = '';
      let body = '';
      body += `%0D%0A${
        window.location.origin + `/${data.GuestMode}/${data.ID}/`
      }`;
      SetEmailTarget(
        `mailto:${data.Email}?subject=${subject}&body=${body}`.replaceAll(
          ' ',
          '%20'
        )
      );
      OpenEmail(
        `mailto:${data.Email}?subject=${subject}&body=${body}`.replaceAll(
          ' ',
          '%20'
        )
      );
      //setShowSendEmailPopup(true);
    }
  };
  // NOTE isPortfolioSend is for whether this guest pass is for viewing whole portfolio
  const sendClick = (isPortfolioSend) => {
    SetisPortfolio(isPortfolioSend);
    let ccEmailArr = customerInfo.ccEmails ? customerInfo.ccEmails : [];
    let pages = [];
    let ccEmails = '?cc=';
    // create list of cc
    ccEmailArr.forEach((email, index) => {
      ccEmails += email;
      if (index < ccEmailArr.length) {
        ccEmails += ',';
      }
    });

    if (!isPortfolioSend) {
      briefcaseContent.forEach((obj) => {
        pages.push(obj);
      });
    }

    let subject = '';
    let message = '';
    // check if portoflio contains subject
    if (
      chosenPortfolio.message &&
      chosenPortfolio.message.email_subject &&
      isPortfolioSend
    ) {
      subject = chosenPortfolio.message.email_subject
        ? chosenPortfolio.message.email_subject
        : chosenPortfolio.message.email_subject_BC;
    } else if (
      chosenPortfolio.message_BC &&
      chosenPortfolio.message.email_subject_BC &&
      !isPortfolioSend
    ) {
      subject = chosenPortfolio.message.email_subject_BC
        ? chosenPortfolio.message.email_subject_BC
        : chosenPortfolio.message.email_subject;
    }

    // check if sending a full GP
    if (isPortfolioSend) {
      // check if portoflio contains message
      if (chosenPortfolio.message && chosenPortfolio.message.email_message) {
        message = chosenPortfolio.message.email_message;
      } else if (
        chosenPortfolio.message &&
        chosenPortfolio.message.email_message_BC
      ) {
        message = chosenPortfolio.message.email_message_BC;
      }
    } else {
      if (chosenPortfolio.message && chosenPortfolio.message.email_message_BC) {
        message = chosenPortfolio.message.email_message_BC;
      } else if (
        chosenPortfolio.message &&
        chosenPortfolio.message.email_message
      ) {
        message = chosenPortfolio.message.email_message;
      }
    }

    // replace all \n with %0D%0A
    let body = message ? message.replace(/\n/g, '%0D%0A') : '';
    pages.forEach((ele) => {
      delete ele.PagesSaved;
    });
    const guestPass = {
      company_id: userInfo[0].company_id,
      portfolio_id: chosenPortfolio._id,
      is_portfolio_view: isPortfolioSend,
      pages: pages,
      user_id: auth.uid,
      user_sanity_id: member._id,
      creation_date: firestore.Timestamp.now(),
      user_name: member.name,
      customer_name: customerInfo.firstName + ' ' + customerInfo.lastName,
      customer_email: customerInfo.email,
      RootSessionID: sessionId
    };
    //////onsole.log("SENDCLICK1: ",guestPass)
    // create viewer log
    let viewerLog = [];
    let viewerTemplate = {
      _type: 'viewerLog'
    };

    // add main customer
    viewerLog.push({
      ...viewerTemplate,
      email: guestPass.customer_email,
      name: guestPass.customer_name,
      _key: Math.random().toString(36).substr(2, 7)
    });

    // add cc
    if (customerInfo.ccEmails) {
      customerInfo.ccEmails.forEach((email) => {
        viewerLog.push({
          ...viewerTemplate,
          email: email,
          _key: Math.random().toString(36).substr(2, 7)
        });
      });
    }

    // add viewer log to guest pass
    guestPass['viewer_log'] = viewerLog;

    // firestore add
    firestore
      .collection('guest_passes')
      .add(guestPass)
      .then((docRef) => {
        SetDocRef(docRef.id);
        let url = `${GUEST_BASE_URL}${docRef.id}`;
        firestore
          .collection('sessions')
          .doc(sessionId)
          .update({
            Sentlinks: firestore.FieldValue.arrayUnion(docRef.id)
          });
        setUrlContent(url);
        //add guestPass to sanity with url
        addGuestPassToSanity({ ...guestPass, viewer_log: [], url: url });

        // Only do this when it is not portfolio guest pass creation
        if (!isPortfolioSend) {
          //////onsole.log(guestPass)
          // add briefcase content to send to firestore
          addBriefcase(guestPass).catch((error) => {
            console.error(error);
          });
        }

        // show popup
        setShowSendEmailPopup(true);
        // add url to email
        body += `%0D%0A${url}`;
        //SetEmailTarget(`mailto:${customerInfo.email}?cc=${ccEmails}&subject=${subject}&body=${body}`)
        //SetEmailTarget(`mailto:${data.Email}?subject=Check this out&body=${body}`.replaceAll(' ','%20'))
        SetEmailTarget(
          `mailto:${customerInfo.email}${ccEmails}&subject=${subject}&body=${body}`.replaceAll(
            ' ',
            '%20'
          )
        );
        //onsole.log("SendClick",`mailto:${customerInfo.email}${ccEmails}&subject=${subject}&body=${body}`)
        if (chosenPortfolio.KioskMode)
          QRCodec.toDataURL(url).then((R) => {
            //QRCodec.toDataURL(`mailto:?subject=${subject}&body=${body}`).then((R)=>{
            //imgRef.current.src = `${R}`
            imgRef.current.src = `${R}`;
          });
        else
          QRCodec.toDataURL(url).then((R) => {
            //QRCodec.toDataURL(`mailto:${customerInfo.email}${ccEmails}&subject=${subject}&body=${body}`).then((R)=>{
            imgRef.current.src = `${R}`;
          });

        // // open email
        // window.open(
        //   `mailto:${customerInfo.email}${ccEmails}&subject=${subject}&body=${body}`,
        //   '_blank'
        // );
        //OpenEmail();
      })
      .catch((error) => {
        console.error('Error adding document: ' + error);
        // Show popup notifying user
        setShowSendEmailPopup(true);
        // setSendEmailPopupText('ERROR: Connection error...');
      });
  };
  useEffect(() => {
    // let subject = '';
    // let message = '';
    // check if portoflio contains subject
    if (
      chosenPortfolio.message &&
      chosenPortfolio.message.email_subject &&
      isPortfolio
    ) {
      // subject = chosenPortfolio.message.email_subject?chosenPortfolio.message.email_subject:chosenPortfolio.message.email_subject_BC;
    } else if (
      chosenPortfolio.message_BC &&
      chosenPortfolio.message.email_subject_BC &&
      !isPortfolio
    ) {
      // subject = chosenPortfolio.message.email_subject_BC?chosenPortfolio.message.email_subject_BC:chosenPortfolio.message.email_subject;
    }

    // check if sending a full GP
    if (isPortfolio) {
      // check if portoflio contains message
      if (chosenPortfolio.message && chosenPortfolio.message.email_message) {
        //message = chosenPortfolio.message.email_message;
      } else if (
        chosenPortfolio.message &&
        chosenPortfolio.message.email_message_BC
      ) {
        //message = chosenPortfolio.message.email_message_BC;
      }
    } else {
      if (chosenPortfolio.message && chosenPortfolio.message.email_message_BC) {
        //message = chosenPortfolio.message.email_message_BC;
      } else if (
        chosenPortfolio.message &&
        chosenPortfolio.message.email_message
      ) {
        //message = chosenPortfolio.message.email_message;
      }
    }

    // replace all \n with %0D%0A
    // let body = message ? message.replace(/\n/g, '%0D%0A') : '';
    // body += `%0D%0A${urlContent}`;
    if (emailIsValid(Email))
      if (chosenPortfolio.KioskMode) {
        // QRCodec.toDataURL(`mailto:${Email}?subject=${subject}&body=${body}`).then((R)=>{
        //   if(imgRef.current)
        //   imgRef.current.src = `${R}`
        // })
        // QRCodec.toDataURL(url).then((R)=>{
        //   if(imgRef.current)
        //   imgRef.current.src = `${R}`
        // })
        if (DocRef !== '')
          firestore
            .collection('guest_passes')
            .doc(DocRef)
            .update({
              viewer_log: [
                {
                  _type: 'viewerLog',
                  email: Email,
                  _key: Math.random().toString(36).substr(2, 7)
                }
              ]
            });
      }
  }, [
    chosenPortfolio.KioskMode,
    chosenPortfolio,
    Email,
    imgRef,
    firestore,
    isPortfolio,
    DocRef,
    urlContent
  ]);

  const OpenEmail = (Target) => {
    let EmailTarget2 = Target;

    console.log(EmailTarget);

    const element = document.createElement('a');
    element.target = '_blank';

    // element.href = (`data:text/html,<body>TEST</body><script>
    // const element = document.createElement('a');
    // element.target ='_blank';
    // element.href = EmailTarget
    // document.body.appendChild(element); // Required for this to work in FireFox
    // element.click();
    // </script`)
    if (EmailTarget2 !== null && EmailTarget2 !== undefined)
      element.href = EmailTarget2;
    else element.href = EmailTarget;

    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  // example, theme2 should be used for sectioned style
  // theme className example, manually adding menu-template
  let theme = `portfolio-template ${chosenPortfolio.theme}`;
  let briefcase;
  let contentArea;
  let actionMenuChoice;

  //console.log('CHOSEN THEME', chosenPortfolio?.theme);
  // set according to theme
  switch (chosenPortfolio.theme) {
    case 'theme2':
      actionMenuChoice = !pageMenuReducer.menuHide && (
        <PortfolioActionMenuPopup currentContent={currentContent} />
      );
      briefcase = mode !== 'guest' && (
        <BeUniqueBriefcase
          sendClick={sendClick}
          shareClick={shareClick}
          BorderColor={chosenPortfolio.primary_color}
        />
      );
      contentArea = pageMenuReducer.chosenPageId !== 0 && (
        <PortfolioContentParent3
          createSetCurrentContent={createSetCurrentContent}
        />
      );
      break;
    // case 'theme7':
    //     actionMenuChoice = !pageMenuReducer.menuHide && (
    //       <PortfolioActionMenuPopup currentContent={currentContent} />
    //     );
    //     briefcase = mode !== 'guest' &&
    //       <BeUniqueBriefcase
    //         sendClick={sendClick}
    //         shareClick={shareClick}
    //         BorderColor={chosenPortfolio.primary_color}
    //       />;
    //     contentArea = pageMenuReducer.chosenPageId !== 0 && (
    //       <PortfolioContentParentBeMobile
    //         createSetCurrentContent={createSetCurrentContent}
    //       />
    //     );
    //     break;
    case 'theme4':
      actionMenuChoice =
        Size.W > 450 && Size.H > 450 ? (
          <PortfolioSlideUpMenu2 currentContent={currentContent} />
        ) : (
          <TopRightToolbar
            shareClick={shareClick}
            currentContent={currentContent}
          />
        );
      briefcase = (
        <PortfolioBriefcase2
          sendClick={sendClick}
          shareClick={shareClick}
          BorderColor={chosenPortfolio.primary_color}
        />
      );
      contentArea = (
        <PortfolioContentParent2
          createSetCurrentContent={createSetCurrentContent}
        />
      );
      break;
    case 'theme5':
      actionMenuChoice = (
        <PortfolioSlideUpMenu2 currentContent={currentContent} />
      );
      briefcase = (
        <PortfolioBriefcase2
          sendClick={sendClick}
          shareClick={shareClick}
          BorderColor={chosenPortfolio.primary_color}
        />
      );
      contentArea = (
        <PortfolioContentParent2
          createSetCurrentContent={createSetCurrentContent}
        />
      );
      break;

    case 'theme3': {
      actionMenuChoice = !pageMenuReducer.menuHide && (
        <PortfolioActionMenuGrid currentContent={currentContent} />
      );
      briefcase = (
        <PortfolioBriefcase2
          sendClick={sendClick}
          shareClick={shareClick}
          BorderColor={chosenPortfolio.primary_color}
        />
      );
      contentArea = (
        <PortfolioContentParent2
          createSetCurrentContent={createSetCurrentContent}
        />
      );
      break;
    }

    case 'theme8':
      actionMenuChoice = null;
      briefcase = null;
      contentArea = pageMenuReducer.chosenPageId !== 0 && (
        <PortfolioContentParent8
          createSetCurrentContent={createSetCurrentContent}
          currentContent={currentContent}
          updateSessionWithNotes={updateSessionWithNotes}
          sendClick={sendClick}
          shareClick={shareClick}
          mode={mode}
        />
      );
      break;
    case 'theme9':
      actionMenuChoice = (
        //Size.W<451 || (Size.W < 816 && Size.H < 551)?(<ActionMenuT9 sendClick={sendClick} shareClick={shareClick} currentContent={currentContent} updateSessionWithNotes={updateSessionWithNotes}/>)
        //:
        <>
          <ActionMenuT92
            sendClick={sendClick}
            shareClick={shareClick}
            currentContent={currentContent}
            updateSessionWithNotes={updateSessionWithNotes}
          />
          <ActionMenuT93
            sendClick={sendClick}
            shareClick={shareClick}
            currentContent={currentContent}
            updateSessionWithNotes={updateSessionWithNotes}
          />
        </>
        // null
        // <ActionMenuT9 sendClick={sendClick} shareClick={shareClick} currentContent={currentContent} updateSessionWithNotes={updateSessionWithNotes}/>
      );
      contentArea = null;
      briefcase = false;
      break;
    case 'theme10':
      actionMenuChoice = (
        <></>
        // Size.W > 450
        //   ?
        //     <PortfolioSlideUpMenu2 currentContent={currentContent} />
        //   :
        //     <TopRightToolbar currentContent={currentContent} />
      );
      briefcase = (
        <></>
        // <PortfolioBriefcase2
        //   sendClick={sendClick}
        //   BorderColor={chosenPortfolio.primary_color}
        // />
      );
      contentArea = (
        <PortfolioContentParent2
          createSetCurrentContent={createSetCurrentContent}
        />
      );
      break;
    case 'theme11':
      actionMenuChoice = null;
      briefcase = null;
      contentArea = pageMenuReducer.chosenPageId !== 0 && (
        <PortfolioContentParent11
          createSetCurrentContent={createSetCurrentContent}
          currentContent={currentContent}
          updateSessionWithNotes={updateSessionWithNotes}
          sendClick={sendClick}
          mode={mode}
          backgroundImageUrl={backgroundImageUrl}
          shareClick={shareClick}
        />
      );
      break;
    case 'theme1':
    default:
      actionMenuChoice = !pageMenuReducer.menuHide && (
        <PortfolioActionMenu currentContent={currentContent} />
      );
      briefcase = mode !== 'guest' && (
        <Briefcase
          isGuestMode={isGuestMode}
          sendClick={sendClick}
          shareClick={shareClick}
        />
      );
      contentArea = pageMenuReducer.chosenPageId !== 0 && (
        <PortfolioContentParent
          createSetCurrentContent={createSetCurrentContent}
        />
      );
      break;
  }

  const copyToClipboard = () => {
    const range = document.createRange();
    ////onsole.log("CURRENT NODE", clipboardRef.current);
    range.selectNode(clipboardRef.current);
    ////onsole.log("RANGE", range);
    window.getSelection().addRange(range);
    clipboardRef.current.select();

    try {
      const successful = document.execCommand('copy');
      const msg = successful ? 'successful' : 'unsuccessful';
      console.log('Copy email command was ' + msg);
    } catch (err) {
      console.log('Oops, unable to copy');
    }

    window.getSelection().removeAllRanges();

    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  //onsole.log("!!!CHOSEN PORTFOLIO IN PF PAGE!!!", chosenPortfolio);

  let decidedBackgroundUrl;
  if (!hasCurr && !hasPrev && !isAtEnd) {
    decidedBackgroundUrl = portfolioTopLevelImageUrl;
  } else if ((hasCurr || hasPrev) && !isAtEnd) {
    decidedBackgroundUrl = portfolioTopLevelImageUrl; // portfolioSubMenuImageUrl if we reinstitute submenu
  } else if (isAtEnd) {
    decidedBackgroundUrl = portfolioTermMenuImageUrl;
  } else {
    decidedBackgroundUrl = portfolioTopLevelImageUrl;
  }

  //onsole.log("CHOSEN PORTFOLIO",chosenPortfolio.splash?.image?.asset?._ref,chosenPortfolio.splash?.image?.asset,chosenPortfolio.splash?.image,chosenPortfolio.splash, urlFor(chosenPortfolio.splash?.image?.asset?._ref).auto('format').width(1600).url(),urlFor(chosenPortfolio.splash?.image?.asset?._ref), chosenPortfolio, pageMenuReducer.chosenPageId, (chosenPortfolio.theme === 'theme4'|| chosenPortfolio.theme === 'theme10') && Size.W < 451, Size.W);
  //onsole.log("SelectOption",dropdownOptions,chosenPortfolioId,dropdownOptions.filter((V)=>V.value === chosenPortfolioId)[0]);

  //onsole.log("TOOLBAR OPEN", toolbarMenuOpen);

  let Core = (
    <div
      ref={coreRef}
      id={'Core'}
      className={`portfolio-page ${theme} ${
        Size.W < 450 || Size.H < 450 ? 'mobile' : ''
      }`}
      style={
        chosenPortfolio.theme === 'theme8' ||
        chosenPortfolio.theme === 'theme11'
          ? {
              backgroundImage: null
            }
          : {
              backgroundImage: `url(${backgroundImageUrl}`
            }
      }
    >
      {chosenPortfolio.theme === 'theme8' ? (
        <div
          style={{
            height: '100%',
            width: '100%',
            backgroundImage: `url(${decidedBackgroundUrl}`,
            opacity: 1,
            zIndex: 0,
            position: 'absolute',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center'
          }}
        />
      ) : null}
      {chosenPortfolio.theme === 'theme8' ? (
        <div
          style={{
            height: '100%',
            width: '100%',
            backgroundColor: chosenPortfolio?.overlay_tint_color
              ? `${chosenPortfolio.overlay_tint_color}40` // TODO: find what the 40 is for
              : 'initial', // NOTE: This was #69353F40 before causing tint to the background (Changed 08/30/2023)
            zIndex: 1,
            position: 'absolute'
          }}
        />
      ) : null}
      <ANNO></ANNO>
      {chosenPortfolio.theme ===
        'theme4' /*|| chosenPortfolio.theme === 'theme10'*/ &&
      (Size.W < 451 || Size.H < 451) ? (
        <div
          className="toggle-mobile-toolbar"
          onClick={() => handleToggle()}
          style={{
            backgroundColor: chosenPortfolio?.primary_color
              ? chosenPortfolio.primary_color
              : '#3b3b3b',
            visibility: toolbarMenuOpen ? 'hidden' : 'visible'
          }}
        >
          <BuildRoundedIcon
            style={{
              color: chosenPortfolio?.bulk_icon_override
                ? chosenPortfolio.bulk_icon_override
                : 'white',
              fontSize: '2em'
            }}
          />
        </div>
      ) : null}
      {chosenPortfolio.theme ===
        'theme4' /*|| chosenPortfolio.theme === 'theme10'*/ &&
      (Size.W < 451 || Size.H < 451) ? (
        <div className="portfolio-action-menu">
          {isModalOpen && (
            <Modal>
              <CustomerInfoForm
                handleSubmit={updateCustomerInfo}
                submitText="Update"
              />
            </Modal>
          )}
        </div>
      ) : null}
      {chosenPortfolio.theme ===
        'theme4' /*|| chosenPortfolio.theme === 'theme10'*/ && Size.W < 451 ? ( //|| Size.H < 451
        <div
          style={{
            width: '50px',
            height: '50px',
            position: 'absolute',
            objectFit: 'contain',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            justifyContent: 'center',
            alignitems: 'center',
            left: 'calc(50% - 25px)',
            top: '5px'
          }}
        >
          <img
            style={{ objectFit: 'contain' }}
            src={urlFor(chosenPortfolio.portfolio_logo.asset._ref)
              .auto('format')
              .width(200)
              .url()}
            alt="logo"
          />
        </div>
      ) : null}
      <PortfolioMenu
        theme={chosenPortfolio.theme}
        menuButtonClicked={menuButtonClicked}
        ActionMenu={actionMenuChoice}
        createSetCurrentContent={createSetCurrentContent}
        currentContent={currentContent}
        shareClick={shareClick}
        sendClick={sendClick}
      />
      {pageMenuReducer.chosenPageId !== 0 && contentArea}
      {chosenPortfolio.theme !== 'theme3' ? actionMenuChoice : null}
      {briefcase}
      {/* {!isGuestMode && <Briefcase sendClick={sendClick} />} */}
      {chosenPortfolio.screensaver_active && ( //KioskModePrep
        <ScreenSaver
          KioskModePrep={KioskModePrep}
          addPageVisit={addPageVisit}
        />
      )}
      {chosenPortfolio.theme === 'theme2' ||
      chosenPortfolio.theme === 'theme7' ? (
        <Notepad2 updateSessionWithNotes={updateSessionWithNotes} />
      ) : chosenPortfolio.theme === 'theme8' ||
        chosenPortfolio.theme === 'theme11' ? null : (
        <Notepad updateSessionWithNotes={updateSessionWithNotes} />
      )}
      {mode === 'training' &&
      chosenPortfolio.theme !== 'theme8' &&
      chosenPortfolio.theme !== 'theme11' ? (
        <ScriptNote />
      ) : mode === 'training' && chosenPortfolio.theme === 'theme8' ? (
        <ScriptNote2 />
      ) : null}
      {showSendEmailPopup && (
        <div className="send-email-popup">
          <div
            className="send-email-popup-box"
            style={{
              border: '4px solid',
              borderColor: chosenPortfolio.primary_color
                ? chosenPortfolio.primary_color
                : companyConfig.primary_color,
              borderRadius: '8px'
            }}
          >
            <p
              style={{
                marginBlock: '0px',
                fontWeight: 600,
                fontSize: '20px',
                fontFamily: FontUtil(chosenPortfolio.SystemText)
              }}
            >
              Share Link:
            </p>
            <div className="send-email-popup-text" ref={DivRef}>
              <div
                className="email-notification"
                style={{
                  visibility: copied === true ? 'visible' : 'hidden',
                  backgroundColor: chosenPortfolio.primary_color
                    ? chosenPortfolio.primary_color
                    : '#949494',
                  color: chosenPortfolio.text_color
                    ? chosenPortfolio.text_color
                    : 'white'
                }}
              >
                <p
                  style={{
                    color: chosenPortfolio.text_color
                      ? chosenPortfolio.text_color
                      : 'white',
                    marginBlockStart: '0px',
                    marginBlockEnd: '0px',
                    fontWeight: 600
                  }}
                >
                  copied to clipboard
                </p>
              </div>
              <input
                ref={clipboardRef}
                defaultValue={urlContent}
                style={{
                  resize: 'none',
                  textAlign: 'center',
                  width: '340px',
                  height: '43px',
                  fontSize: '20px',
                  borderRight: 'none',
                  fontFamily: FontUtil(chosenPortfolio.SystemText)
                }}
              />
              <div
                style={{
                  marginTop: '0px',
                  height: '49px',
                  width: '40px',
                  borderTopRightRadius: '10px',
                  borderBottomRightRadius: '10px',
                  border: 'none',
                  cursor: 'pointer',
                  backgroundColor: chosenPortfolio.primary_color
                    ? chosenPortfolio.primary_color
                    : 'black',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
                onClick={() => copyToClipboard()}
              >
                <AssignmentRoundedIcon
                  style={{
                    color: chosenPortfolio.text_color
                      ? chosenPortfolio.text_color
                      : 'white',
                    fontSize: '21.6px'
                  }}
                />
              </div>
            </div>
            {/* {'\n\n'} */}
            {/* {sendEmailPopupText} PING */}
            {!chosenPortfolio.KioskMode && isPortfolio && (
              <div style={{ marginTop: 'calc(5% * 1.4333)' }}>
                {/* <h1 onPointerDown={(eve)=>{//onsole.log('pointerDown')}} >{select === null ? 'Choose Portfolio' : select.label}</h1> */}
                <Select
                  //PING
                  //menuShouldBlockScroll={true}
                  //menuShouldScrollIntoView={true}
                  //onMenuOpen ={()=>{setTimeout(()=>{document.body.scrollBy({top:999,left:0,behavior:'auto'})},350)}}
                  isSearchable={true}
                  classNamePrefix={'is-momentumScrollable '}
                  styles={styles}
                  onChange={(select) => {
                    //onsole.log('SELECT',select);
                    GuestPassPortSelection(
                      select.value
                    ); /*setSelect(select); setPortfolioId(select.value)*/
                  }}
                  options={dropdownOptions}
                  defaultValue={DV} ////onsole.log("SelectOption",dropdownOptions,chosenPortfolioId,dropdownOptions.filter((V)=>V.value === chosenPortfolioId)[0]);
                  placeholder="Choose Portfolio"
                  id="bypass"
                  //onFocus={() => toggleFilter()}
                />
              </div>
            )}
            <div
              className="row-button-container"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'row'
              }}
            >
              {!chosenPortfolio.KioskMode && (
                <div
                  className="button"
                  style={{
                    border: chosenPortfolio.primary_color
                      ? `1px solid ${chosenPortfolio.primary_color}`
                      : '1px solid black',
                    backgroundColor: 'white'
                  }}
                  onClick={() => OpenEmail()}
                >
                  <p
                    style={{
                      fontFamily: FontUtil(chosenPortfolio.SystemText),
                      color:
                        chosenPortfolio.primary_color &&
                        (chosenPortfolio.primary_color === 'white' ||
                          chosenPortfolio.primary_color === '#FFFFFF')
                          ? 'black'
                          : chosenPortfolio.primary_color
                          ? chosenPortfolio.primary_color
                          : 'black'
                    }}
                  >
                    Open Email
                  </p>
                </div>
              )}
              <div
                className="button"
                style={{
                  backgroundColor: chosenPortfolio.primary_color
                    ? chosenPortfolio.primary_color
                    : 'black',
                  width: '152px'
                }}
                onClick={() => {
                  SetDocRef('');
                  if (!isGuestMode) KioskModePrep(Email);
                  setShowSendEmailPopup(false);
                }} //KioskModePrep
              >
                <p
                  style={{
                    color: chosenPortfolio.text_color
                      ? chosenPortfolio.text_color
                      : 'white',
                    fontFamily: FontUtil(chosenPortfolio.SystemText)
                  }}
                >
                  Close
                </p>
              </div>
            </div>
            {/* {(chosenPortfolio.KioskMode)?(<div>
                <p>Please Enter your Email Address</p>
                <IN 
                style={{borderTop: "none", borderRight: "none", borderLeft: "none", borderRadius: "0px", margin: "1%", }}
                name="email"
                width="350px"
                height="32px"
                placeholder={"Email"}
                type="text"
                onChange={(e)=>{setEmail(e.target.value); SetEmailValid(emailIsValid(e.target.value))}}
                value={Email}        
                isValid={EmailValid}
                required={true}>
              </IN>

              </div>):null} */}
            {/* <img alt="" ref={imgRef} style={{display:chosenPortfolio.KioskMode?'block':'none'} /> */}
            <img alt="" ref={imgRef} style={{ display: 'block' }} />
          </div>
        </div>
      )}
      {isGuestMode && (
        <GuestPassSelfSend onExit={onExit} shareClick={shareClick2} />
      )}
    </div>
  );
  //eslint-disable-next-line
  const ScrollDebounce = useCallback(
    _.debounce((eve) => {
      HOCScroll(eve);
    }, 500),
    []
  );
  let hoc;
  if (theme.includes('theme9'))
    hoc = (
      <div
        ref={HOCRef}
        onScroll={(eve) => {
          ScrollDebounce(eve);
        }}
        id={'Test'}
        style={{
          display: 'flex',
          flexDirection: 'column',
          overflowY: AltStateCover ? 'scroll' : '',
          height: '100%'
        }}
      >
        {
          CoverAllowed.current && (
            <Cover
              SetAltStateCover={SetAltStateCover}
              ScrollTunn={(eve) => {
                HOCScroll(eve);
              }}
            ></Cover>
          ) /*  */
        }
        {Core}
      </div>
    );
  if (hoc) {
    return hoc;
  }
  return Core;
};

export default PortfolioPage;

//{/* <img style={{position:"absolute",zIndex:6,objectFit:"cover",width:"100%",height:"100%"}} src={portfoliolaunchImageUrl} onClick={(eve)=>eve.currentTarget.style.display = "none"}></img> */}
