import React from 'react'; //{ useState }
import BlockContent from '@sanity/block-content-to-react';
// import { urlFor } from '../../../utils/sanityHelper';
// import SanityMuxPlayer from '../../../containers/MuxPlayer';
//import PDFViewer from '../../PDFViewer';
// import PDFViewer2 from '../../FullWidthPDFViewer';
// import BASlider from '../../BeforeAfterSlider'
import PortfolioContentOrThumbnail from '../../../utils/PortfolioContentOrThumbnail';
//import GalleryView from './GalleryView';
import serializers from '../../../utils/serializers';
// import ToggleMenu from '../ToggleMenu';
//import SliderGallery from './SliderGallery';

const PortfolioContent = ({
  Position,
  contentType,
  videos,
  pdfs,
  contents,
  page,
  videoSelected,
  //prepareCurrentGalleryContent,
  chosenPortfolio,
  foundPage,
  currentNavItem
}) => {
  // const [opacityBack, setOpacityBack] = useState(1);
  // // eslint-disable-next-line
  // const [isVisible, setIsVisible] = useState('hidden');
  //////////onsole.log("-CHOSENPORTFOLIO", chosenPortfolio)

  // let imageHeight;
  // let imageWidth;

  // if (chosenPortfolio?.theme === 'theme2' && (!currentNavItem || currentNavItem?.children.length === 1)) {
  //   imageHeight = "80%";
  // } else if (chosenPortfolio?.theme === 'theme2' && currentNavItem?.children.length === 2) {
  //   imageHeight = "60%";
  // } else {
  //   imageHeight = "100%"
  // }

  // if (chosenPortfolio?.theme === 'theme2' && (!currentNavItem || currentNavItem?.children.length === 1)) {
  //   imageWidth = "80%";
  // } else {
  //   imageWidth = "100%";
  // }

  // const renderSingleContent = (type, galleryNum, isSelected, IsGal,Id,SetBufferFunctions,BufferGal,BufferRender,isVis) => {
  //   switch (type) {
  //     case 'galleryImage':
  //       let image = contents[galleryNum].image;
  //       return (
  //         <img
  //           className="portfolio-single-image"
  //           src={urlFor(image).auto('format').url()} // auto converts to webp if browser supports it
  //           alt="mainImage"
  //           type="galleryImage"
  //           style={{
  //             opacity: chosenPortfolio && chosenPortfolio.theme === 'theme2' ? opacityBack : 1,
  //             maxWidth: imageWidth,
  //             maxHeight: imageHeight,
  //             width:"100%",
  //             height:"100%"
  //           }}
  //         />
  //       );
  //     case 'galleryVideo':
  //       let video = videos[contents[galleryNum].video.asset._ref];
  //       let videoSettings = contents[galleryNum];
  //       // let Subtitles = contents[galleryNum].subtitles
  //       ////////onsole.log(videoSettings);
  //       return (
  //         <SanityMuxPlayer
  //           assetDocument={video}
  //           subtitles={videoSettings.subtitles}
  //           autoload={true}
  //           autoplay={videoSettings.autoplay}
  //           loop={videoSettings.loop}
  //           SIG={videoSettings.SIG}
  //           SIP={videoSettings.SIP}
  //           muted={false}
  //           showControls={true}
  //           style={{}}
  //           posterurl={`https://image.mux.com/${video.playbackId}/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=1`}
  //           type="galleryVideo"
  //           isSelected={isSelected}
  //         />
  //       );
  //     case 'galleryPdf':
  //       //////onsole.log("!!TITLE!!",contents[galleryNum].title);
  //       let url2 = pdfs[contents[galleryNum].file.asset._ref].url;
  //       let thumbNailRef2 = contents[galleryNum].thumbnail.asset._ref;
  //       let thumbNailUrl2 = urlFor(thumbNailRef2)
  //         .auto('format')
  //         .width(100)
  //         .url();
  //         let thumbNailUrl3 = urlFor(thumbNailRef2)
  //         .auto('format')
  //         .width(1000)
  //         .url();
  //       return (
  //         <PDFViewer2
  //         isVis={isVis}
  //           BufferGal ={BufferGal}
  //           BufferRender = {BufferRender}
  //           SetBufferFunctions={SetBufferFunctions}
  //           ID={Id}
  //           IsGal={IsGal}
  //           url={url2}
  //           isThumbnail={false}
  //           TThumb ={thumbNailUrl3}
  //           type="galleryFullWidthPdf"
  //           thumbnailUrl={thumbNailUrl2}
  //           title={contents[galleryNum].title}
  //         />
  //       );
  //     case 'galleryFullWidthPdf':
  //       //////onsole.log("!!TITLE!!",contents[galleryNum].title);
  //       let url3 = pdfs[contents[galleryNum].file.asset._ref].url;
  //       let thumbNailRef3 = contents[galleryNum].thumbnail.asset._ref;
  //       let thumbNailUrl4 = urlFor(thumbNailRef3)
  //         .auto('format')
  //         .width(100)
  //         .url();
  //         let thumbNailUrl5 = urlFor(thumbNailRef3)
  //         .auto('format')
  //         .width(1000)
  //         .url();
  //       return (
  //         <PDFViewer2
  //         isVis={isVis}
  //           BufferGal ={BufferGal}
  //           BufferRender = {BufferRender}
  //           SetBufferFunctions={SetBufferFunctions}
  //           ID={Id}
  //           IsGal={IsGal}
  //           url={url3}
  //           isThumbnail={false}
  //           TThumb ={thumbNailUrl5}
  //           type="galleryFullWidthPdf"
  //           thumbnailUrl={thumbNailUrl4}
  //           title={contents[galleryNum].title}
  //         />
  //       );
  //     case 'galleryBASlider':
  //       {
  //         let Aimage = contents[galleryNum].Aimage;
  //         let Bimage = contents[galleryNum].Bimage;
  //         let LabelA;
  //         let LabelB;
  //         if(contents[galleryNum].Labels !== false){
  //         LabelA = contents[galleryNum].AimageL;
  //         LabelB = contents[galleryNum].BimageL;
  //         }
  //         else{
  //         LabelA = '';
  //         LabelB = '';
  //         }
  //         let Vert = contents[galleryNum].Vert;
  //         let Color = contents[galleryNum].SlideColor;
  //         //////onsole.log('!!!!BA!!!!', contents[galleryNum])
  //         return<BASlider LabelB={LabelB} LabelA={LabelA} Color={Color} Vert={Vert} ImageA={Aimage} ImageB={Bimage}/>
  //       }
  //     case 'toggleMenu':
  //       {
  //         let firstContent = contents[galleryNum].content[0];
  //         let secondContent = contents[galleryNum].content[1];
  //         //////onsole.log('TOGGLE MENU', contents[galleryNum]);
  //         // return;
  //         return (
  //           <ToggleMenu
  //             firstContent={firstContent}
  //             secondContent={secondContent}
  //             videos={videos}
  //             pdfs={pdfs}
  //             currentNavItem={currentNavItem}
  //             contentLength={contents.length}
  //           />
  //         );
  //       }
  //     // case 'sliderGallery':
  //     //   {
  //         // //////onsole.log('TOGGLE MENU', contents[galleryNum]);
  //         // return;
  //         // return (
  //         //   <ToggleMenu
  //         //     firstContent={firstContent}
  //         //     secondContent={secondContent}
  //         //     videos={videos}
  //         //     pdfs={pdfs}
  //         //     currentNavItem={currentNavItem}
  //         //     contentLength={contents.length}
  //         //   />
  //         // );
  //       // }
  //     default:
  //       return <div />;
  //   }
  // };
  // const renderSingleThumb = (type, galleryNum, isSelected) => {
  //   switch (type) {
  //     case 'galleryImage':
  //       let image = contents[galleryNum].image;
  //       return (
  //         <img
  //           className="portfolio-single-image"
  //           src={urlFor(image).auto('format').width(100).url()} // auto converts to webp if browser supports it
  //           alt="mainImage"
  //           type="galleryImage"
  //           style={{
  //             opacity: chosenPortfolio && chosenPortfolio.theme === 'theme2' ? opacityBack : 1,
  //             width: imageWidth,
  //             height: imageHeight
  //           }}
  //         />
  //       );
  //     case 'galleryVideo':
  //       let video = videos[contents[galleryNum].video.asset._ref];
  //       //let videoSettings = contents[galleryNum];
  //       return (
  //         <img style={{height:"100%", width:"100%", objectFit:"contain"}} src={`https://image.mux.com/${video.playbackId}/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=${video.thumbTime !==0?video.thumbTime:1}`} alt="poster" />
  //         // <SanityMuxPlayer
  //         //   assetDocument={video}
  //         //   autoload={true}
  //         //   autoplay={videoSettings.autoplay}
  //         //   loop={videoSettings.loop}
  //         //   muted={false}
  //         //   showControls={true}
  //         //   style={{}}
  //         //   posterurl={`https://image.mux.com/${video.playbackId}/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=1`}
  //         //   type="galleryVideo"
  //         //   isSelected={isSelected}
  //         // />
  //       );
  //     case 'galleryPdf':
  //       let url2 = pdfs[contents[galleryNum].file.asset._ref].url;
  //       let thumbNailRef2 = contents[galleryNum].thumbnail.asset._ref;
  //       let thumbNailUrl2 = urlFor(thumbNailRef2)
  //         .auto('format')
  //         .width(100)
  //         .url();
  //       return (
  //         <PDFViewer2
  //           url={url2}
  //           isThumbnail={true}
  //           type="galleryFullWidthPdf"
  //           thumbnailUrl={thumbNailUrl2}
  //         />
  //       );
  //       case 'galleryBASlider':
  //         {
  //           let Aimage = contents[galleryNum].Aimage;
  //           let Bimage = contents[galleryNum].Bimage;
  //           let LabelA = contents[galleryNum].AimageL;
  //           let LabelB = contents[galleryNum].BimageL;
  //           let Vert = contents[galleryNum].Vert;
  //           let Color = contents[galleryNum].SlideColor;
  //           //////onsole.log('!!!!BA!!!!', contents[galleryNum])
  //           return<BASlider Thumb LabelB={LabelB} LabelA={LabelA} Color={Color} Vert={Vert} ImageA={Aimage} ImageB={Bimage}/>
  //         }
  //     case 'galleryFullWidthPdf':
  //       let url3 = pdfs[contents[galleryNum].file.asset._ref].url;
  //       let thumbNailRef3 = contents[galleryNum].thumbnail.asset._ref;
  //       let thumbNailUrl3 = urlFor(thumbNailRef3)
  //         .auto('format')
  //         .width(100)
  //         .url();
  //       return (
  //         <PDFViewer2
  //           url={url3}
  //           isThumbnail={true}
  //           type="galleryFullWidthPdf"
  //           thumbnailUrl={thumbNailUrl3}
  //         />
  //       );
  //     default:
  //       return <div />;
  //   }
  // };

  // Render thumbnail version
  // const renderThumbnail = (props) => {
  //   switch (props.type) {
  //     case 'galleryImage':
  //       return (
  //         <div className="portfolio-single-image">
  //           <img src={props.src} alt="mainImage" type="image" />
  //         </div>
  //       );
  //     case 'galleryVideo':
  //       return <img src={props.posterurl} alt="poster" />;
  //     case 'galleryFullWidthPdf':
  //       return (
  //         <PDFViewer url={props.thumbnailUrl} isThumbnail={true} type="file" />
  //       );
  //     case 'fullWidthPDF':
  //       return <PDFViewer url={props.url} isThumbnail={true} type="file" />;
  //     default:
  //       return <div />;
  //   }
  // };

  // render nothing if no content
  if (!contents && !page.url) {
    return null;
  }

  // check block content
  const blockContent = (blocks) => {
    // filter non valid imageLinks
    let tempBlocks = blocks.filter((obj) => {
      if (obj._type === 'imageLinks' && obj.image) {
        return true;
      }
      if (obj._type !== 'imageLinks') {
        return true;
      }
      return false;
    });

    // further filter non valid embedHtml
    tempBlocks = tempBlocks.filter((obj) => {
      if (obj._type === 'embedHTML' && obj.html) {
        return true;
      }
      if (obj._type !== 'embedHTML') {
        return true;
      }
      return false;
    });
    return tempBlocks;
  };

  // const enterContent = () => {
  //   setOpacityBack(0.4);
  //   setIsVisible('visible');
  // }

  // const leaveContent = () => {
  //   setOpacityBack(1);
  //   setIsVisible('hidden');
  // }

  //PING MENU FLASH
  return (
    <div className="portfolio-content">
      {/* onMouseOver={chosenPortfolio?.theme === 'theme2' && currentNavItem ? () => enterContent() : null} onMouseLeave={chosenPortfolio?.theme === 'theme2' && currentNavItem ? () => leaveContent() : null} */}
      {(contentType === 'gallery' ||
        contentType === 'sliderGallery' ||
        contentType === 'STgallery') && (
        // renderSingleContent(contents[Position]?._type, Position, videoSelected)
        <PortfolioContentOrThumbnail
          Ele={{ gallery_index: Position, page_id: page._key, id: '' }}
          isSelected={videoSelected}
          chosenPortfolio={chosenPortfolio}
          Thumb={false}
        />
      )}
      {/* {contentType === 'gallery' && contents.length > 1 && (
        <GalleryView
          gallery={contents}
          renderSingleContent={renderSingleContent}
          renderThumbnail={renderSingleThumb}
          prepareCurrentGalleryContent={prepareCurrentGalleryContent}
        />
      )}
      {contentType === 'sliderGallery' && contents.length > 1 && (
        <SliderGallery
          gallery={contents}
          renderSingleContent={renderSingleContent}
          renderThumbnail={renderSingleThumb}
          prepareCurrentGalleryContent={prepareCurrentGalleryContent}
        />
      )} */}
      {contentType === 'htmlContent' && (
        <div className="html-block-content">
          <BlockContent
            blocks={blockContent(contents)}
            serializers={serializers}
          />
        </div>
      )}
      {contentType === 'website' && (
        <div className="iframe-container">
          <embed
            title="embed"
            allowFullScreen
            src={page.url}
            style={{ backgroundColor: 'white' }}
          ></embed>
        </div>
      )}
      {/* {chosenPortfolio && (chosenPortfolio.theme === 'theme2' || chosenPortfolio.theme === 'theme7') && currentNavItem && (
        <button 
          style={{
            backgroundColor: "transparent",
            height: "200px",
            width: "200px",
            borderRadius: "12px",
            borderWidth: "5px",
            borderColor: chosenPortfolio.text_color ? chosenPortfolio.text_color : "white",
            cursor: "pointer",
            visibility: isVisible,
            position: 'absolute',
            zIndex: 2,
            overflow: 'hidden'
          }}
        >
          <p 
            style={{
              color: chosenPortfolio.text_color ? chosenPortfolio.text_color : "white",
              marginBlockStart: "0px",
              marginBlockEnd: "0px",
              fontFamily: "'Oswald', sans-serif",
              fontSize: '1.5em',
              letterSpacing: "0.1em",
              textShadow: chosenPortfolio.accent_color ? `-2px 0 ${chosenPortfolio.accent_color}, 0 2px ${chosenPortfolio.accent_color}, 2px 0 ${chosenPortfolio.accent_color}, 0 -2px ${chosenPortfolio.accent_color}` : `-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black`
            }}
          >
            {foundPage?.title}
          </p>
          <p 
            style={{
              color: chosenPortfolio.text_color ? chosenPortfolio.text_color : "white",
              marginBlockStart: "0px",
              marginBlockEnd: "0px",
              fontFamily: "'Oswald', sans-serif",
              fontSize: '1.3em',
              letterSpacing: "0.085em",
              textShadow: chosenPortfolio.accent_color ? `-2px 0 ${chosenPortfolio.accent_color}, 0 2px ${chosenPortfolio.accent_color}, 2px 0 ${chosenPortfolio.accent_color}, 0 -2px ${chosenPortfolio.accent_color}` : `-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black`
            }}
          >
            {foundPage?.subtitle}
          </p>
        </button>
      )} */}
    </div>
  );
};

PortfolioContent.defaultProps = {
  videoSelected: true
};

export default PortfolioContent;
