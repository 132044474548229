import React, { Fragment } from 'react';
import Menu1 from '../../../containers/PageMenus/Menu1';
import Menu2 from '../../../containers/PageMenus/Menu2-BeMethodical';
import Menu3 from '../../../containers/PageMenus/Menu3-BeUnique'; // template-z menu
import Menu5 from '../../../containers/PageMenus/Menu5-NovaOriginale';
import Menu4 from '../../../containers/PageMenus/Menu4-2BeMethodical'; //X^2
import Menu9 from '../../../containers/PageMenus/Menu9-Storybook'; //Storybook
import Menu10 from '../../../containers/PageMenus/Menu10BeContent'; //Storybook

const PortfolioMenu = (props) => {
  // change menu component according to theme
  const renderMenu = () => {
    switch (props.theme) {
      case 'theme1':
        return <Menu1 />;
      case 'theme2':
        return <Menu3 />;
      case 'theme3':
        return <Menu5 AC={props.ActionMenu} />;
      case 'theme4':
        return <Menu2 />;
      case 'theme5':
        return <Menu4 />;
      case 'theme7':
        return null;
      case 'theme8':
        return null;
      case 'theme9':
        return (
          <Menu9
            sendClick={props.sendClick}
            shareClick={props.shareClick}
            currentContent={props.currentContent}
            menuButtonClicked={props.menuButtonClicked}
            createSetCurrentContent={props.createSetCurrentContent}
          />
        );
      case 'theme10':
        return <Menu10 />;
      case 'theme11':
        return null;
      default:
        return <Menu1 />;
    }
  };
  return <Fragment>{renderMenu()}</Fragment>;
};
export default PortfolioMenu;
