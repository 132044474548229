import {
  SET_PORTFOLIO_CUSTOMER_INFO,
  SET_PORTFOLIO_ID,
  SET_CHOSEN_PORTFOLIO,
  SET_PORTFOLIO_MODE,
  RESET_PORTFOLIO_INFO,
  RESET_PORTFOLIO_INFO_MidSession,
  SET_PORTFOLIO_SESSION_ID,
  END_PORTFOLIO,
  SET_BACKGROUND_IMAGE_URL,
  SET_PAGE_BACKGROUND_IMAGE_URL,
  SET_PORTFOLIO_SUBPAGE_ID,
  SET_PII_BACKGROUND_IMAGE_URL,
  SET_PORTFOLIO_INTRO_IMAGE_URL,
  SET_PORTFOLIO_LAUNCH_IMAGE_URL,
  SET_LIKED,
  SET_LOGIN_INPUT_SELECTED,
  SET_LOGIN_INPUT_SELECTED_COUNT,
  KM_RESET,
  SET_TOOLBAR_MENU_OPEN,
  SET_TOOLBAR_MENU_VISIBILITY,
  SET_MASTER_MENU_OPEN,
  SET_PORTFOLIO_SUBMENU_IMAGE_URL,
  SET_PORTFOLIO_TERMMENU_IMAGE_URL,
  SET_PORTFOLIO_TOPLEVEL_IMAGE_URL,
  SET_SELECTED_TOP_LEVEL_ID,
  SET_TWOLEVEL_TERMINUS,
  SET_SCREENSAVER_SHOW,
  SET_NOTIFICATION
  // SET_ANNOTATOR
} from '../constants/actionTypes';
import { batch } from 'react-redux';
import { urlFor } from '../utils/sanityHelper';

export const setPortfolioCustomerInfo = (obj) => ({
  type: SET_PORTFOLIO_CUSTOMER_INFO,
  obj
});

export const setActiveSessionNotifications = (payload) => ({
  type: SET_NOTIFICATION,
  payload
});

export const kmReset = () => ({
  type: KM_RESET
});

export const setLoginInputSelected = (bool) => ({
  type: SET_LOGIN_INPUT_SELECTED,
  data: bool
});

export const setScreensaverShow = (bool) => ({
  type: SET_SCREENSAVER_SHOW,
  data: bool
});

export const setTwolevelTerminus = (bool) => ({
  type: SET_TWOLEVEL_TERMINUS,
  data: bool
});

export const setLoginInputSelectedCount = (num) => ({
  type: SET_LOGIN_INPUT_SELECTED_COUNT,
  data: num
});

export const setMasterMenuOpen = (bool) => ({
  type: SET_MASTER_MENU_OPEN,
  data: bool
});

export const setToolbarMenuOpen = (bool) => ({
  type: SET_TOOLBAR_MENU_OPEN,
  data: bool
});

export const setToolbarMenuVisibility = (bool) => ({
  type: SET_TOOLBAR_MENU_VISIBILITY,
  data: bool
});

export const setPortfolioId = (id) => ({
  type: SET_PORTFOLIO_ID,
  id
});

export const setChosenPortfolio = (obj) => ({
  type: SET_CHOSEN_PORTFOLIO,
  obj
});

export const setPortfolioMode = (mode) => ({
  type: SET_PORTFOLIO_MODE,
  mode
});

export const setPortfolioSessionId = (id) => ({
  type: SET_PORTFOLIO_SESSION_ID,
  id
});

export const setPortfolioSubpageId = (id) => ({
  type: SET_PORTFOLIO_SUBPAGE_ID,
  id
});

export const resetPortfolioMode = () => ({
  type: RESET_PORTFOLIO_INFO
});

export const resetPortfolioMidSession = () => ({
  type: RESET_PORTFOLIO_INFO_MidSession
});

export const endPortfolio = () => ({
  type: END_PORTFOLIO
});

export const setBackgroundImageURL = (url) => ({
  type: SET_BACKGROUND_IMAGE_URL,
  url
});

export const setPageBackgroundImageURL = (url) => ({
  type: SET_PAGE_BACKGROUND_IMAGE_URL,
  url
});

export const setPIIBackgroundImageURL = (url) => ({
  type: SET_PII_BACKGROUND_IMAGE_URL,
  url
});

export const setSelectedTopLevelId = (id) => ({
  type: SET_SELECTED_TOP_LEVEL_ID,
  data: id
});

export const setPortfolioSubmenuImageUrl = (url) => ({
  type: SET_PORTFOLIO_SUBMENU_IMAGE_URL,
  data: url
});

export const setPortfolioTermmenuImageUrl = (url) => ({
  type: SET_PORTFOLIO_TERMMENU_IMAGE_URL,
  data: url
});

export const setPortfolioToplevelImageUrl = (url) => ({
  type: SET_PORTFOLIO_TOPLEVEL_IMAGE_URL,
  data: url
});

export const setPortfolioIntroImageURL = (url) => ({
  type: SET_PORTFOLIO_INTRO_IMAGE_URL,
  url
});
export const setPortfolioLaunchImageURL = (url) => ({
  type: SET_PORTFOLIO_LAUNCH_IMAGE_URL,
  url
});

export const PrepPortfolioPIIPage = (Id) => {
  return (dispatch, getState) => {
    const state = getState();
    const portfolios = state.sanityContentReducer.portfolios;
    const chosen = portfolios.find((obj) => obj._id === Id);
    const backgroundPageImageRef = chosen.portfolio_intro_image
      ? chosen.portfolio_intro_image.asset._ref
      : '';
    const backgroundPageImageUrl =
      backgroundPageImageRef !== ''
        ? urlFor(backgroundPageImageRef).auto('format').width(1600).url()
        : '';

    const portfolio_cover_ref = chosen.portfolio_cover
      ? chosen.portfolio_cover.asset._ref
      : '';
    const portfolio_cover_URL =
      portfolio_cover_ref !== ''
        ? urlFor(portfolio_cover_ref).auto('format').width(1600).url()
        : '';

    const subBackgroundImageRef = chosen.sub_level_background
      ? chosen.sub_level_background.asset._ref
      : '';
    const subBackgroundImageUrl =
      subBackgroundImageRef !== ''
        ? urlFor(subBackgroundImageRef).auto('format').width(1980).url()
        : '';

    const terminusBackgroundImageRef = chosen.terminus_level_background
      ? chosen.terminus_level_background.asset._ref
      : '';
    const terminusBackgroundImageUrl =
      terminusBackgroundImageRef !== ''
        ? urlFor(terminusBackgroundImageRef).auto('format').width(1980).url()
        : '';

    const backgroundPageImageRef2 = chosen.portfolio_bg_image
      ? chosen.portfolio_bg_image.asset._ref
      : '';
    const backgroundPageImageUrl2 =
      backgroundPageImageRef2 !== ''
        ? urlFor(backgroundPageImageRef2).auto('format').width(1980).url()
        : '';

    dispatch(setPortfolioToplevelImageUrl(backgroundPageImageUrl2));
    dispatch(setPortfolioSubmenuImageUrl(subBackgroundImageUrl));
    dispatch(setPortfolioTermmenuImageUrl(terminusBackgroundImageUrl));
    dispatch(setPortfolioIntroImageURL(backgroundPageImageUrl));
    dispatch(setPIIBackgroundImageURL(portfolio_cover_URL));
    dispatch(setPortfolioId(Id));
  };
};

export const setLiked = (array) => ({
  type: SET_LIKED,
  array
});

export const lastPrepPortfolio = (form) => {
  return (dispatch, getState) => {
    const state = getState();
    const portfolios = state.sanityContentReducer.portfolios;
    const id = state.portfolioReducer.chosenPortfolioId;
    const chosen = portfolios.find((obj) => obj._id === id);

    ////////////onsole.log("!!!Chosen!!!",chosen)
    // const LaunchingImageRef = chosen.portfolio_launching
    //   ? chosen.portfolio_launching.asset._ref
    //   : '';

    const backgroundImageRef = chosen.portfolio_bg_image
      ? chosen.portfolio_bg_image.asset._ref
      : '';

    // get page background image ref
    const backgroundPageImageRef = chosen.page_bg_image
      ? chosen.page_bg_image.asset._ref
      : '';

    // crete url for background image
    const backgroundImageUrl =
      backgroundImageRef !== ''
        ? urlFor(backgroundImageRef).auto('format').width(1600).url()
        : '';
    const backgroundPageImageUrl =
      backgroundPageImageRef !== ''
        ? urlFor(backgroundPageImageRef).auto('format').width(1600).url()
        : '';

    // const subBackgroundImageRef = chosen.MegaMenuImages.sub_level_background
    // ? chosen.MegaMenuImages?.sub_level_background.asset._ref
    // : '';
    // const subBackgroundImageUrl =
    // subBackgroundImageRef !== ''
    //   ? urlFor(subBackgroundImageRef).auto('format').width(1980).url()
    //   : '';

    const terminusBackgroundImageRef = chosen.MegaMenuImages
      ?.terminus_level_background
      ? chosen.MegaMenuImages.terminus_level_background.asset._ref
      : '';
    const terminusBackgroundImageUrl =
      terminusBackgroundImageRef !== ''
        ? urlFor(terminusBackgroundImageRef).auto('format').width(1980).url()
        : '';

    const backgroundPageImageRef2 = chosen.portfolio_bg_image
      ? chosen.portfolio_bg_image.asset._ref
      : '';
    const backgroundPageImageUrl2 =
      backgroundPageImageRef2 !== ''
        ? urlFor(backgroundPageImageRef2).auto('format').width(1980).url()
        : '';

    // const LaunchingImageUrl =
    //   LaunchingImageRef !== ''
    //     ? urlFor(LaunchingImageRef).auto('format').width(1600).url()
    //     : '';

    let tempSplashPage = undefined;
    // convert splash to page if exists
    if (chosen.splash) {
      tempSplashPage = {
        id: `splashkey-${chosen._id}`,
        _key: `splashkey-${chosen._id}`,
        _type: 'gallery',
        title: 'Splash',
        content: []
      };
      chosen.splash.forEach((content) => {
        tempSplashPage.content.push(content);
      });
    }
    batch(() => {
      dispatch(setPortfolioCustomerInfo(form));
      dispatch(setPortfolioToplevelImageUrl(backgroundPageImageUrl2));
      // dispatch(setPortfolioSubmenuImageUrl(subBackgroundImageUrl));
      dispatch(setPortfolioTermmenuImageUrl(terminusBackgroundImageUrl));
      dispatch(setBackgroundImageURL(backgroundImageUrl));
      dispatch(setPageBackgroundImageURL(backgroundPageImageUrl));
      dispatch(setChosenPortfolio({ ...chosen, splashPage: tempSplashPage }));
    });
  };
};
