import { convertSecondsToText } from './timeHelper';

// converts chart data to csv
export const convertChartDataToCSV = (data) => {
  let csvData = [];

  csvData.push(['Content Viewings'], [''], ['Name', 'Duration']);

  // put content viewings (aka Duration per page visit) data
  data.victoryTimeSpent.forEach((obj) => {
    //replace new line with space for name
    let newName = obj.name.replace(/\n/g, ' ');
    let spentText = convertSecondsToText(obj.y, true);
    csvData.push([newName, `Spent ${spentText}`]);
  });

  csvData.push(
    [''],
    [''],
    ['Summation of content view times'],
    [''],
    ['Name', 'Duration']
  );

  // put summation of content view times (aka Summation of duartion)
  data.totalTimeSpendDatas.forEach((obj) => {
    //replace new line with space for name
    let newName = obj.name.replace(/\n/g, ' ');
    let spentText = convertSecondsToText(obj.y, true);
    csvData.push([newName, `Total ${spentText}`]);
  });

  csvData.push(
    [''],
    [''],
    ['Summation of Content Viewings'],
    [''],
    ['Name', 'Count']
  );

  // put summation of content viewings (aka How many clicks to a page)
  data.viewedDatas.forEach((obj) => {
    //replace new line with space for name
    let newName = obj.name.replace(/\n/g, ' ');
    let clicks = obj.y;
    csvData.push([newName, clicks]);
  });

  csvData.push([''], [''], ['Cart Information'], ['']);

  // filter to count how many cart was sent
  let filtered = [];
  if (data.cartSentRow) {
    filtered = data.cartSentRow.filter((obj) => obj.index);
  }

  //////////onsole.log('filtered', filtered);
  csvData.push(
    [`Times a cart was sent ${filtered.length}`],
    [''],
    ['Page Name', 'Times page was sent']
  );

  if (data.totalPageSentRow) {
    // put times page was sent (briefcase/cart)
    data.totalPageSentRow.forEach((obj) => {
      csvData.push([obj.title, obj.count]);
    });
  }

  csvData.push([''], [''], ['Carts Sent'], [''], ['Cart send time', 'pages']);

  if (data.cartSentRow) {
    // put cart sent time, with associated pages(briefcase/cart)
    data.cartSentRow.forEach((obj) => {
      if (obj.index) {
        csvData.push([`Cart Sent on ${obj.date}`]);
      } else {
        csvData.push(['', obj.title]);
      }
    });
  }

  // Likes
  if (data.liked.length > 0) {
    csvData.push([''], [''], ['Liked Pages'], ['']);

    data.liked.forEach((obj) => {
      csvData.push([obj.title]);
    });
  }

  // Notes
  if (data.notes !== '') {
    csvData.push(
      [''],
      [''],
      ['Notes taken during session'],
      [''],
      [data.notes]
    );
  }

  csvData.push(
    [''],
    [''],
    ['Client Information'],
    [''],
    ['Name', 'Company', 'Email', 'EmailCC']
  );

  // put client info
  if (data.client_info.viewerLog.length > 0) {
    let viewerLog = data.client_info.viewerLog;
    // viewerLog[0] always carries the main customer info
    let clientInfoArr = [
      viewerLog[0].name,
      data.client_info.customerCompany,
      viewerLog[0].email
    ];
    let ccEmails = '';
    // append cc emails
    viewerLog.forEach((obj, index) => {
      let comma = ',';
      if (index === viewerLog.length - 1) {
        // last item no need for comma
        comma = '';
      }

      // ignore first obj
      if (index > 0) {
        ccEmails += `${obj.email}${comma}`;
      }
    });
    // add ccemail
    clientInfoArr.push(ccEmails);
    // add client info to csv
    csvData.push(clientInfoArr);
  }

  return csvData;
};

// converts aggregated data to csv
export const convertAggregatedDataToCSV = (data) => {
  let csvData = [];

  ////////////////////////
  // All / Total
  ////////////////////////
  csvData.push(
    [''],
    ['Total Summation of Content Viewings'],
    ['Name', 'Count']
  );

  // put summation of content viewings (aka How many clicks to a page)
  data.all.page_views.forEach((obj) => {
    //replace new line with space for name
    let newName = obj.name.replace(/\n/g, ' ');
    let clicks = obj.y;
    csvData.push([newName, clicks]);
  });

  csvData.push(
    [''],
    ['Total Summation of Content View Times'],
    ['Name', 'Duration']
  );

  // put summation of content view times (aka Summation of duartion)
  data.all.view_duration.forEach((obj) => {
    //replace new line with space for name
    let newName = obj.name.replace(/\n/g, ' ');
    let spentText = convertSecondsToText(obj.y, true);
    csvData.push([newName, `Total ${spentText}`]);
  });

  if (data.briefcase && data.briefcase.length > 0) {
    // briefcase page sent
    csvData.push([''], ['Total Briefcase pages sent'], ['Name', 'Total']);
    // put times page was sent (briefcase/cart)
    data.briefcase.forEach((obj) => {
      csvData.push([obj.title, obj.count]);
    });
  }

  ////////////////////////
  // Guest
  ////////////////////////

  csvData.push(
    [''],
    [''],
    ['Guest Summation of Content Viewings'],
    ['Name', 'Count']
  );

  // put summation of content viewings (aka How many clicks to a page)
  data.guests.page_views.forEach((obj) => {
    //replace new line with space for name
    let newName = obj.name.replace(/\n/g, ' ');
    let clicks = obj.y;
    csvData.push([newName, clicks]);
  });

  csvData.push(
    [''],
    ['Guest Summation of Content View Times'],
    ['Name', 'Duration']
  );

  // put summation of content view times (aka Summation of duartion)
  data.all.view_duration.forEach((obj) => {
    //replace new line with space for name
    let newName = obj.name.replace(/\n/g, ' ');
    let spentText = convertSecondsToText(obj.y, true);
    csvData.push([newName, `Total ${spentText}`]);
  });

  // Likes
  if (data.liked && data.liked.length > 0) {
    csvData.push([''], [''], ['Guest Liked Pages'], ['Name', 'Total']);

    data.liked.forEach((obj) => {
      csvData.push([obj.title, obj.total]);
    });
  }

  ////////////////////////
  // Teams
  ////////////////////////
  csvData.push([''], [''], ['TEAMS']);

  let teamKeys = [];
  for (let key in data.teams) {
    teamKeys.push(key);
  }

  // add data for every team
  teamKeys.forEach((key) => {
    csvData.push(
      [''],
      [`${data.teams[key].name} Summation of Content Viewings`],
      ['Name', 'Count']
    );

    // put summation of content viewings (aka How many clicks to a page)
    data.teams[key].page_views.forEach((obj) => {
      //replace new line with space for name
      let newName = obj.name.replace(/\n/g, ' ');
      let clicks = obj.y;
      csvData.push([newName, clicks]);
    });

    csvData.push(
      [''],
      [`${data.teams[key].name} Total summation of content view times`],
      ['Name', 'Duration']
    );

    // put summation of content view times (aka Summation of duartion)
    data.teams[key].view_duration.forEach((obj) => {
      //replace new line with space for name
      let newName = obj.name.replace(/\n/g, ' ');
      let spentText = convertSecondsToText(obj.y, true);
      csvData.push([newName, `Total ${spentText}`]);
    });
  });

  ////////////////////////
  // Users
  ////////////////////////
  csvData.push([''], [''], ['USERS']);
  let userKeys = [];
  for (let key in data.users) {
    userKeys.push(key);
  }

  // add data for every team
  userKeys.forEach((key) => {
    csvData.push(
      [''],
      [`${data.users[key].name} Summation of Content Viewings`],
      ['Name', 'Count']
    );

    // put summation of content viewings (aka How many clicks to a page)
    data.users[key].page_views.forEach((obj) => {
      //replace new line with space for name
      let newName = obj.name.replace(/\n/g, ' ');
      let clicks = obj.y;
      csvData.push([newName, clicks]);
    });

    csvData.push(
      [''],
      [`${data.users[key].name} Total summation of content view times`],
      ['Name', 'Duration']
    );

    // put summation of content view times (aka Summation of duartion)
    data.users[key].view_duration.forEach((obj) => {
      //replace new line with space for name
      let newName = obj.name.replace(/\n/g, ' ');
      let spentText = convertSecondsToText(obj.y, true);
      csvData.push([newName, `Total ${spentText}`]);
    });
  });

  return csvData;
};
