import React, { useState, useEffect } from 'react';
import BuildRoundedIcon from '@material-ui/icons/BuildRounded';
import { useHistory } from 'react-router-dom';
import { useFirebase, useFirestore } from 'react-redux-firebase';
// import styled, {keyframes} from 'styled-components';
import Icons from '../../../../containers/Icons';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import '../../../../assets/images/arrowUp2.png';
import FontUtil from '../../../../utils/FontUtility';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import Like from '../../../../utils/Likes';
// import Script from '../ActionMenuToolbar/ScriptCopy'
// import Test from '../ActionMenuToolbar/Notepad2 copy'
// import PII from '../ActionMenuToolbar/PII'
// import { IconContext } from 'react-icons';

const BottomLeftToolbar = ({
  setMenuHide,
  currentContent,
  userInfo,
  mode,
  sessionId,
  showBriefcase,
  briefcaseContent,
  isModalOpen,
  chosenPortfolio,
  companyConfig,
  isGuestMode,
  currentGuestContentHelper,
  liked,
  toggleBriefcase,
  addToBriefcase,
  endPortfolio,
  setPortfolioCustomerInfo,
  showModal,
  hideModal,
  toggleNotepad,
  toggleScriptnote,
  setLiked,
  menuHide,
  updateSessionInSanity,
  clearAll,
  Size,
  booleanNewItems,
  setBooleanNewItems,
  SET_ANNOTATOR,
  ANNOTATOR2,
  toolbarMenuOpen,
  setToolbarMenuOpen,
  toolbarMenuVisible,
  setToolbarMenuVisibility,
  masterMenuOpen,
  transitionedToTopLevel,
  sendClick,
  shareClick
}) => {
  // const [isAddable, setIsAddable] = useState(true); // bool to keep track of if it is addable to briefcase
  const [isAuthToAdd, setIsAuthToAdd] = useState(false); // is this user authorized to add item?
  const [isAuthBriefcaseShow, setIsAuthBriefcaseShow] = useState(false); // is this user authorized to see briefcase?
  const [isLiked2, setIsLiked2] = useState(null); // is this user authorized to see briefcase?
  const [isAblePortfolioGuestPass, setIsAblePortfolioGuestPass] =
    useState(false);
  const history = useHistory();
  const firestore = useFirestore();
  const firebase = useFirebase();

  const handleToggle = () => {
    let anti = !toolbarMenuOpen;
    setToolbarMenuOpen(anti);
  };

  const handleVisibility = () => {
    if (chosenPortfolio.theme === 'theme8') {
      setToolbarMenuVisibility(false);
    }
  };

  const Keycheck = (eve) => {
    if (eve.key !== 'F8') return;
    onExit();
  };

  useEffect(() => {
    window.addEventListener('keydown', Keycheck);
    return () => window.removeEventListener('keydown', Keycheck);
  });

  useEffect(() => {
    if (userInfo && userInfo.length > 0) {
      // TODO: Why is this constant condition?
      // userInfo[0].is_able_to_create_guest_pass
      if (true) {
        setIsAblePortfolioGuestPass(true);
      }
    }
  }, [userInfo]);
  // const getWindowDimensions = () => {
  //   const { innerWidth: width, innerHeight: height } = window;
  //   return {
  //     width,
  //     height
  //   };
  // }

  // const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  // useEffect(() => {
  //   function handleResize() {
  //     setWindowDimensions(getWindowDimensions());
  //   }

  //   window.addEventListener('resize', handleResize);
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);

  // check each time chosenContent or briefcaseContent updates
  // useEffect(() => {
  //   // check if briefcase contains current content user is looking at
  //   if (
  //     (Object.entries(currentContent)?.length !== 0 &&
  //       briefcaseContent.filter((e) => e.id === currentContent.id).length >
  //         0) ||
  //     currentContent.page_id === `splashkey-${chosenPortfolio._id}`
  //   ) {
  //     setIsAddable(false);
  //   } else {
  //     setIsAddable(true);
  //   }
  // }, [currentContent, briefcaseContent, chosenPortfolio]);

  // get userinfo auth for viewing sending briefcase
  useEffect(() => {
    if (userInfo && userInfo.length > 0) {
      // TODO: Why is this constant condition?
      // check if user is able to create guest pass
      if (true) {
        // userInfo[0].is_able_to_create_briefcase
        // user is able to create normal guest pass, aka able to add item to briefcase
        setIsAuthToAdd(true);
      }
      // check if user is able create portfolio level guest pass
      if (true) {
        //userInfo[0].is_able_to_create_guest_pass
        // user is able to create normal guest pass, aka able to add item to briefcase
        setIsAuthBriefcaseShow(true);
      }
    }
  }, [userInfo]);

  const endSession = () => {
    endPortfolio();
    history.push('/engage');
  };

  const logout = () => {
    //////////onsole.log('Guest user logout');
    firebase.logout();
    // clear sessionkey in local storage and redux session key
    //updateSessionKey('');
    //clear all state
    clearAll();
  };

  const onExit = () => {
    if (sessionId === '') {
      endSession();
    } else {
      // create page visit info obj
      const pageVisitInfo = {
        is_end: true,
        time_visited: firestore.Timestamp.now()
      };
      firestore
        .collection('sessions')
        .doc(sessionId)
        .update({
          page_visited: firestore.FieldValue.arrayUnion(pageVisitInfo)
        })
        .then((e) => {
          // success
          //////////onsole.log('Firestore set/update success');
          // if guest mode logout
          // check guest mode
          if (isGuestMode) {
            logout();

            // open url
            if (
              chosenPortfolio.end_demo_url &&
              chosenPortfolio.end_demo_url !== ''
            ) {
              window.open(chosenPortfolio.end_demo_url, '_self');
            }
            return;
          } else {
            // end session for user
            endSession();
          }
        })
        .catch((error) => {
          console.error(error);
          endSession();
        });
    }
  };

  const updateFavorite = (op) => {
    Like(
      op,
      isGuestMode,
      sessionId,
      liked,
      currentGuestContentHelper,
      currentContent,
      chosenPortfolio,
      setIsLiked2,
      setLiked,
      firestore
    );
    // // do not log if mode is not guest or no sessionId
    // if (!isGuestMode || sessionId === '') {
    //   return null;
    // }

    // // copy liked from redux store
    // let likedArrCopy = [...liked];
    // //////////onsole.log("!!CC!!",currentContent)
    // let id = currentGuestContentHelper.id;
    // let content = currentContent;
    // // check if currentGuestContentHelper id is undefined
    // if (!id) {
    //   //////////onsole.log('like in full portfolio guest-pass view...');
    //   id = currentContent.id;
    //   // if undefined it means that guest is in full portfolio view and not in briefcase guest view
    // } else {
    //   // user in briefcase guest view
    //   //////////onsole.log('like in Briefcase guest view...');
    //   // set content from currentGuestContentHelper, if not splash
    //   if (id !== `splashkey-${chosenPortfolio._id}`) {
    //     content = currentGuestContentHelper;
    //   }
    // }
    // let content2 = {...content, time_liked:firestore.Timestamp.now()}
    // if(content2.PagesSaved === undefined){
    //   content2.PagesSaved = 1;
    // }
    // // if op is add then append currentContent obj to likedArrCopy,
    // if (op === 'add') {
    //   likedArrCopy.push(content2);
    // } else {
    //   // if not filter out
    //   likedArrCopy = likedArrCopy.filter((obj) => obj.id !== id);
    // }
    // //////////onsole.log("LIKED: ", likedArrCopy);
    // setIsLiked2(likedArrCopy)
    // // replace the liked array
    // firestore
    //   .collection('sessions')
    //   .doc(sessionId)
    //   .update({
    //     liked: likedArrCopy
    //   })
    //   .then(() => {
    //     // replace liked in store
    //     setLiked(likedArrCopy);
    //   })
    //   .catch((error) => console.error('ERROR: ', error));
  };

  // check if current page is liked already or not
  const isLiked = () => {
    let currentGuestContentId = currentGuestContentHelper.id;
    // check if currentGuestContentHelper id is undefined
    if (!currentGuestContentId) {
      //////////onsole.log('Liked check in full portfolio guest-pass view...');
      // check if liked contains currentContent id
      return liked.some((obj) => obj.id === currentContent.id);
    } else {
      // user in briefcase guest view
      //////////onsole.log('Liked check in Briefcase guest view...');
      if (currentGuestContentId === `splashkey-${chosenPortfolio._id}`) {
        // check if liked contains currentContent id for splash
        return liked.some((obj) => obj.id === currentContent.id);
      } else {
        // check if liked contains currentGuestContentId
        return liked.some((obj) => obj.id === currentGuestContentId);
      }
    }
  };

  //onsole.log("WINDOW DIMENSIONS", windowDimensions);

  return (
    <>
      <div
        className={`portfolio-toolbar2wrapper ${
          toolbarMenuOpen ? 'expanded-toolbar' : 'retracted-toolbar'
        } ${isGuestMode ? 'guest' : 'user'} `}
      >
        <div
          className={`toolbar-icon ${
            toolbarMenuOpen ? 'expanded-toolbar' : 'retracted-toolbar'
          } ${isGuestMode ? 'guest' : 'user'}`}
        >
          {toolbarMenuOpen ? (
            <CancelRoundedIcon
              onClick={() => handleToggle()}
              style={{
                color: chosenPortfolio?.bulk_icon_override
                  ? chosenPortfolio.bulk_icon_override
                  : '#3b3b3bBF'
              }}
            />
          ) : (
            <CancelRoundedIcon
              onClick={() => handleToggle()}
              style={{
                color: chosenPortfolio?.bulk_icon_override
                  ? chosenPortfolio.bulk_icon_override
                  : '#3b3b3bBF'
              }}
            />
          )}
        </div>
        <div
          className={`toolbar-icon2 ${
            toolbarMenuOpen ? 'expanded-toolbar' : 'retracted-toolbar'
          } ${isGuestMode ? 'guest' : 'user'}`}
        >
          {toolbarMenuOpen ? (
            <Tooltip
              placement="top"
              trigger="hover"
              overlay={'Toolkit'}
              mouseEnterDelay={0.2}
              overlayInnerStyle={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontWeight: 600
              }}
            >
              <div className="DeployBackground" onClick={() => handleToggle()}>
                {/* <BuildRoundedIcon
            onClick={() => handleToggle()}
            style={{color: chosenPortfolio?.bulk_icon_override ? chosenPortfolio.bulk_icon_override : '#3b3b3bBF'}}
          />  */}
              </div>
            </Tooltip>
          ) : (
            <Tooltip
              placement="top"
              trigger="hover"
              overlay={'Toolkit'}
              mouseEnterDelay={0.2}
              overlayInnerStyle={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontWeight: 600
              }}
            >
              <div className="DeployBackground" onClick={() => handleToggle()}>
                <div
                  className="Dot"
                  style={{
                    backgroundColor: chosenPortfolio?.bulk_icon_override
                      ? chosenPortfolio.bulk_icon_override
                      : '#3b3b3bBF'
                  }}
                ></div>
                <div
                  className="Dot"
                  style={{
                    backgroundColor: chosenPortfolio?.bulk_icon_override
                      ? chosenPortfolio.bulk_icon_override
                      : '#3b3b3bBF'
                  }}
                ></div>
                <div
                  className="Dot"
                  style={{
                    backgroundColor: chosenPortfolio?.bulk_icon_override
                      ? chosenPortfolio.bulk_icon_override
                      : '#3b3b3bBF'
                  }}
                ></div>
                {/* <BuildRoundedIcon
            onClick={() => handleToggle()}
            style={{color: chosenPortfolio?.bulk_icon_override ? chosenPortfolio.bulk_icon_override : '#3b3b3bBF'}}
          />  */}
              </div>
            </Tooltip>
          )}
        </div>
        <div
          className={`portfolio-toolbar2 ${
            toolbarMenuOpen ? 'expanded-toolbar' : 'retracted-toolbar'
          } ${isGuestMode ? 'guest' : 'user'}`}
          // style={{backgroundColor: toolbarMenuOpen?chosenPortfolio?.action_menu_color ? chosenPortfolio.action_menu_color : '#E2DFDD':'#00000000'}}
        >
          {/* <div className={`toolbar-title ${toolbarMenuOpen ? 'expanded-toolbar' : 'retracted-toolbar'} ${isGuestMode ? 'guest' : 'user'}`}> */}
          {/* <p
          style={{color: chosenPortfolio?.bulk_icon_override ? chosenPortfolio.bulk_icon_override : '#3b3b3bBF'}}
        >Toolbox</p> */}
          {/* </div> */}
          {isGuestMode && chosenPortfolio?.theme === 'theme8' ? (
            <div className="icon-flex-container guest">
              {/* {Size.W < 450 && Size.W > Size.H ?  */}
              {isLiked() && isLiked2 ? (
                <div className="icon-and-text-parent guest note">
                  <Icons.Favorite
                    id="Bypass"
                    chosenPortfolio={chosenPortfolio}
                    Size={Size}
                    onClick={() => {
                      updateFavorite('remove');
                    }}
                  />
                </div>
              ) : (
                <div className="icon-and-text-parent guest">
                  <Icons.FavoriteBorder
                    id="Bypass"
                    chosenPortfolio={chosenPortfolio}
                    Size={Size}
                    onClick={() => {
                      updateFavorite('add');
                    }}
                  />
                </div>
              )}
              {/* : null} */}
              <div className="icon-and-text-parent guest">
                <Icons.NoteAdd
                  OColor={
                    chosenPortfolio.bulk_icon_override
                      ? chosenPortfolio.bulk_icon_override
                      : undefined
                  }
                  id="Bypass"
                  className="icon"
                  Overlay={isGuestMode ? 'Contact' : 'Notes'}
                  chosenPortfolio={chosenPortfolio}
                  Size={Size}
                  onClick={() => {
                    toggleNotepad();
                    handleVisibility();
                  }}
                  data-tip={Size.W > 450 ? 'Add Note' : null}
                />
              </div>
            </div>
          ) : null}
          <div
            className="icon-flex-container"
            style={{
              transition: 'opacity 0.5s',
              opacity: toolbarMenuOpen ? '1' : '0',
              pointerEvents: toolbarMenuOpen ? 'auto' : 'none'
            }}
          >
            {(chosenPortfolio.theme === 'theme4' ||
              chosenPortfolio.theme === 'theme10') &&
              (Size.W < 451 || Size.H < 451) && (
                <div className="icon-and-text-parent">
                  <div className="test-absolute-text">
                    <p
                      onClick={() => {
                        handleToggle();
                      }}
                      style={{
                        color: chosenPortfolio?.bulk_icon_override
                          ? chosenPortfolio.bulk_icon_override
                          : '#3b3b3bBF',
                        fontFamily: FontUtil(chosenPortfolio.SystemText)
                      }}
                    >
                      CLOSE TOOLBAR
                    </p>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '60px',
                      width: '60px'
                    }}
                  >
                    <BuildRoundedIcon
                      id="Bypass"
                      className="icon"
                      style={{
                        fontSize: '38px',
                        color: chosenPortfolio?.bulk_icon_override
                          ? chosenPortfolio.bulk_icon_override
                          : '#3b3b3bBF'
                      }}
                      onClick={() => {
                        handleToggle();
                      }}
                    />
                  </div>
                </div>
              )}
            {/* {isAddable && isAuthToAdd && (!isGuestMode || chosenPortfolio.theme !== 'theme4') && (
          <div className='icon-and-text-parent'>
            <Icons.AddShoppingCart id="Bypass"
              className="icon"
              chosenPortfolio={chosenPortfolio}
              Size={Size}
              onClick={() => {
                addToBriefcase(currentContent);
                if (showBriefcase === true) {
                  setBooleanNewItems(false);
                } else {
                  setBooleanNewItems(true);
                }
              }}
              overlay={Size.W > 450 ? "Add to Briefcase" : null}
            />
            <div className='test-absolute-text'>
              <p
                onClick={() => {
                  addToBriefcase(currentContent);
                  if (showBriefcase === true) {
                    setBooleanNewItems(false);
                  } else {
                    setBooleanNewItems(true);
                  }
                }}
              >ADD TO BRIEFCASE</p>
            </div>
          </div>
        )} */}
            <div className="icon-and-text-parent">
              <Icons.ShoppingCart
                id="Bypass"
                className="icon"
                onClick={() => {
                  // toggleBriefcase();
                  // setBooleanNewItems(false);
                  // handleVisibility();
                  if (
                    isAblePortfolioGuestPass &&
                    isAuthToAdd &&
                    isAuthBriefcaseShow
                  ) {
                    sendClick(true);
                  } else if (isGuestMode) {
                    shareClick(true);
                  }
                }}
                chosenPortfolio={chosenPortfolio}
                Size={Size}
                briefcaseContent={briefcaseContent}
                booleanNewItems={booleanNewItems}
              />
              <div className="test-absolute-text">
                <p
                  onClick={() => {
                    // toggleBriefcase();
                    // setBooleanNewItems(false);
                    // handleVisibility();
                    if (isAblePortfolioGuestPass) {
                      sendClick(true);
                    } else if (isGuestMode) {
                      shareClick(true);
                    }
                  }}
                  style={{
                    color: chosenPortfolio?.bulk_icon_override
                      ? chosenPortfolio.bulk_icon_override
                      : '#3b3b3bBF'
                  }}
                >
                  GUEST PASS
                </p>
              </div>
            </div>
            {!isGuestMode ? (
              <div className="icon-and-text-parent">
                <Icons.PersonAdd
                  id="Bypass"
                  className="icon"
                  chosenPortfolio={chosenPortfolio}
                  Size={Size}
                  onClick={() => {
                    isModalOpen ? hideModal() : showModal();
                    handleVisibility();
                    handleToggle();
                  }}
                  data-tip={Size.W > 450 ? 'Change Info' : null}
                />
                <div className="test-absolute-text">
                  <p
                    onClick={() => {
                      isModalOpen ? hideModal() : showModal();
                      handleVisibility();
                      handleToggle();
                    }}
                    style={{
                      color: chosenPortfolio?.bulk_icon_override
                        ? chosenPortfolio.bulk_icon_override
                        : '#3b3b3bBF'
                    }}
                  >
                    EDIT GUEST INFO
                  </p>
                </div>
              </div>
            ) : null}
            <div className="icon-and-text-parent">
              <Icons.NoteAdd
                id="Bypass"
                className="icon"
                chosenPortfolio={chosenPortfolio}
                Overlay={isGuestMode ? 'Contact' : 'Notepad'}
                Size={Size}
                onClick={() => {
                  toggleNotepad();
                  handleToggle();
                  handleVisibility();
                }}
                data-tip={Size.W > 450 ? 'Add Note' : null}
              />
              <div className="test-absolute-text">
                <p
                  onClick={() => {
                    handleToggle();
                    toggleNotepad();
                    handleVisibility();
                  }}
                  style={{
                    color: chosenPortfolio?.bulk_icon_override
                      ? chosenPortfolio.bulk_icon_override
                      : '#3b3b3bBF'
                  }}
                >
                  {isGuestMode ? 'CONTACT' : 'NOTEPAD'}
                </p>
              </div>
            </div>
            {mode === 'training' && (
              <div className="icon-and-text-parent">
                <Icons.SpeakerNotes
                  id="Bypass"
                  className="icon"
                  chosenPortfolio={chosenPortfolio}
                  Size={Size}
                  onClick={() => {
                    handleToggle();
                    toggleScriptnote();
                    handleVisibility();
                  }}
                  data-tip={Size.W > 450 ? 'Speaker Notes' : null}
                />
                <div className="test-absolute-text">
                  <p
                    onClick={() => {
                      handleToggle();
                      toggleScriptnote();
                      handleVisibility();
                    }}
                    style={{
                      color: chosenPortfolio?.bulk_icon_override
                        ? chosenPortfolio.bulk_icon_override
                        : '#3b3b3bBF'
                    }}
                  >
                    SPEAKER NOTES
                  </p>
                </div>
              </div>
            )}
            {isGuestMode ? (
              isLiked() && isLiked2 ? (
                <div className="icon-and-text-parent">
                  <Icons.Favorite
                    id="Bypass"
                    chosenPortfolio={chosenPortfolio}
                    Size={Size}
                    onClick={() => {
                      updateFavorite('remove');
                    }}
                  />
                  <div className="test-absolute-text">
                    <p
                      onClick={() => {
                        updateFavorite('remove');
                      }}
                      style={{
                        color: chosenPortfolio?.bulk_icon_override
                          ? chosenPortfolio.bulk_icon_override
                          : '#3b3b3bBF'
                      }}
                    >
                      LIKE
                    </p>
                  </div>
                </div>
              ) : (
                <div className="icon-and-text-parent">
                  <Icons.FavoriteBorder
                    id="Bypass"
                    chosenPortfolio={chosenPortfolio}
                    Size={Size}
                    onClick={() => {
                      updateFavorite('add');
                    }}
                  />
                  <div className="test-absolute-text">
                    <p
                      onClick={() => {
                        updateFavorite('add');
                      }}
                      style={{
                        color: chosenPortfolio?.bulk_icon_override
                          ? chosenPortfolio.bulk_icon_override
                          : '#3b3b3bBF'
                      }}
                    >
                      LIKE
                    </p>
                  </div>
                </div>
              )
            ) : null}
            {isGuestMode ||
            !chosenPortfolio?.icon_set?.custom_icon_1?.web_link ? null : (
              <div className="icon-and-text-parent">
                <Icons.CustomIconOne
                  id="Bypass"
                  className="icon"
                  chosenPortfolio={chosenPortfolio}
                  Size={Size}
                  data-tip={Size.W > 450 ? 'Calendar' : null}
                />
                <div className="test-absolute-text">
                  <p
                    onClick={() => {
                      let a = document.createElement('a');
                      a.href =
                        chosenPortfolio.icon_set?.custom_icon_1?.web_link;
                      a.target = '_blank';
                      const clickHandler = (ele) => {
                        setTimeout(() => {
                          ele.removeEventListener('click', clickHandler);
                        }, 150);
                      };
                      a.addEventListener('click', clickHandler(a), false);
                      a.click();
                      return;
                    }}
                    style={{
                      color: chosenPortfolio?.bulk_icon_override
                        ? chosenPortfolio.bulk_icon_override
                        : '#3b3b3bBF'
                    }}
                  >
                    WEB LINK
                  </p>
                </div>
              </div>
            )}
            <div
              className="icon-and-text-parent annotator"
              style={{
                display:
                  Size.W > 450 &&
                  Size.H > 450 &&
                  chosenPortfolio.icon_set?.annotator_icon?.IconFeatureToggle
                    ? 'flex'
                    : 'none'
              }}
            >
              {Size.W > 450 &&
              Size.H > 450 &&
              chosenPortfolio.icon_set?.annotator_icon?.IconFeatureToggle ? (
                <>
                  <Icons.AnnotatorIcon
                    onClick={() => {
                      SET_ANNOTATOR(!ANNOTATOR2);
                      if (chosenPortfolio.theme === 'theme8') {
                        handleToggle();
                      }
                    }}
                  />
                  <div className="test-absolute-text">
                    <p
                      onClick={() => {
                        SET_ANNOTATOR(!ANNOTATOR2);
                        if (chosenPortfolio.theme === 'theme8') {
                          handleToggle();
                        }
                      }}
                      style={{
                        color: chosenPortfolio?.bulk_icon_override
                          ? chosenPortfolio.bulk_icon_override
                          : '#3b3b3bBF'
                      }}
                    >
                      ANNOTATOR
                    </p>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            {(!chosenPortfolio.KioskMode || isGuestMode || Size.W < 451) && (
              <div className="icon-and-text-parent">
                <Icons.EndDemo id="Bypass" onClick={onExit} />
                <div className="test-absolute-text">
                  <p
                    onClick={onExit}
                    style={{
                      color: chosenPortfolio?.bulk_icon_override
                        ? chosenPortfolio.bulk_icon_override
                        : '#3b3b3bBF'
                    }}
                  >
                    EXIT
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default BottomLeftToolbar;
