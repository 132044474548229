import React, { useState } from 'react';
import styled from 'styled-components';
import { sortByKey } from '../../../utils/alphabetizer';
// import * as Scale from '../../../utils/Scalars';

const PortfolioSelectContainer = styled.div`
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  .portfolio-select-chosen {
    width: 90%;
    height: 100%;
    border-radius: 12px;
    display: grid;
    grid-template-columns: 8fr 2fr;
    overflow: hidden;
    cursor: pointer;
    background-color: #fcfafa;

    .text-box {
      grid-column: 1 / span 1;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      input {
        width: 100%;
        height: 100%;
        padding-left: 8px;
        padding-right: 8px;
        border: none;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        font-family: 'Newsreader', serif;
        font-size: 12px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      p {
        margin-block-start: 0px;
        margin-block-end: 0px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 600;
        letter-spacing: 0.05em;
        font-size: 0.9em;
        font-family: 'Newsreader', serif;
      }
    }

    .arrow-box {
      grid-column: 2 / span 1;
      display: flex;
      justify-content: center;
      align-items: center;
      border-left: 1px solid #e6e6e6;
      background-color: #eeeeee;
      color: #3b3b3b;

      div {
        height: 70%;
        width: 70%;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          margin-block-start: 0px;
          margin-block-end: 0px;
          font-weight: 400;
          font-size: 20px;
          font-family: 'Newsreader', serif;
          transform: scale(1.5, 0.75) rotate(90deg);
        }
      }
    }
  }

  .portfolio-select-list {
    position: absolute;
    bottom: -210px;
    // left: calc(50% - 72px);

    width: 90%;
    height: 199px;
    max-height: 199px;
    border-top: none;
    z-index: 11;
    background-color: white;
    overflow-x: hidden;
    border-radius: 8px;
    box-shadow: 3px 3px 5px #3b3b3b;

    .list-item {
      padding-left: 2px;
      height: 39px;
      width: 100%;
      border-bottom: 1px dotted black;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      background-color: white;
      :hover {
        background-color: #e6e6e6;
      }
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 0.7em;
        font-weight: 600;
        margin-block-start: 0px;
        margin-block-end: 0px;
      }
    }
  }
`;

const PortfolioSelect = ({
  portfolios,
  setSelectedPortfolioId,
  setSelectedPortfolioName,
  selectedPortfolioName,
  setShowPortfolioSelect,
  showPortfolioSelect,
  setShowSessionSelect,
  setDefaultVisit,
  SetDaterangeDispatch,
  setAggregateSelected,
  userInfo,
  setSelectedNotesSession,
  selectedUser,
  setEfficiencyAggregateSelected,
  Size
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterClicked, setFilterClicked] = useState(false);
  const [currentPlaceholder, setCurrentPlaceholder] =
    useState('Filter by Name');

  const closeDrop = (event) => {
    //////onsole.log('EVENT TARGET', event.target.id);
    if (event.target && event.target.id !== 'bypass') {
      closeAct();
    } else {
      setShowPortfolioSelect(true);
    }
  };

  const closeAct = () => {
    setShowPortfolioSelect(false);
    window.removeEventListener('click', closeDrop);
  };

  const toggleDropdown = () => {
    if (!showPortfolioSelect) {
      window.setTimeout(() => {
        window.addEventListener('click', closeDrop);
      }, 50);
    }
    setShowPortfolioSelect(!showPortfolioSelect);
  };

  const closeFilter = (event) => {
    if (event.target.className !== 'portfolio-select-id') {
      closeActFilter();
    }
  };

  const closeActFilter = () => {
    setFilterClicked(false);
    setSearchTerm('');
    window.removeEventListener('click', closeFilter);
  };

  const toggleFilter = () => {
    if (!filterClicked) {
      window.setTimeout(() => {
        window.addEventListener('click', closeFilter);
      }, 50);
    }
    setFilterClicked(!filterClicked);
  };

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
    if (event.target.value !== '') {
      setCurrentPlaceholder('');
    } else {
      setCurrentPlaceholder('Filter by Name');
    }
  };

  let allowedPortfolioIds;
  if (selectedUser) {
    allowedPortfolioIds = selectedUser[0].portfolio;
  } else {
    allowedPortfolioIds = userInfo[0].portfolio;
  }

  let allowedPortfolios = [];

  for (let id of allowedPortfolioIds) {
    let foundPortfolio = portfolios.find((port) => port._id === id);
    if (foundPortfolio !== undefined) {
      allowedPortfolios.push(foundPortfolio);
    }
  }
  allowedPortfolios = sortByKey(allowedPortfolios, 'title');
  // //onsole.log("ALLOWED PORTFOLIOS", allowedPortfolios);
  let filteredPortfolios = allowedPortfolios.filter((portfolio) => {
    return (
      portfolio?.title?.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
    );
  });

  let listMapping = filteredPortfolios.map((portfolio) => {
    return (
      <div
        className="list-item"
        onClick={() => {
          setSelectedPortfolioId(portfolio._id);
          setSelectedPortfolioName(portfolio.title);
          setShowPortfolioSelect(false);
          setAggregateSelected('1m');
          SetDaterangeDispatch(3);
          setDefaultVisit(undefined);
          setSelectedNotesSession(undefined);
          setEfficiencyAggregateSelected('1m');
        }}
        key={portfolio._id}
        title={portfolio.title}
      >
        <p>{portfolio.title}</p>
      </div>
    );
  });

  return (
    <PortfolioSelectContainer>
      <div
        className="portfolio-select-chosen"
        onClick={() => {
          toggleDropdown();
          setShowSessionSelect(false);
        }}
        style={{
          border: showPortfolioSelect ? '1px solid white' : 'none',
          backgroundColor: showPortfolioSelect ? '#E6E6E6' : '#fcfafa',
          color: showPortfolioSelect ? 'white' : '#3b3b3b'
        }}
      >
        <div className="text-box">
          <input
            onClick={() => toggleFilter()}
            value={searchTerm}
            onChange={handleInputChange}
            type="text"
            placeholder={
              filterClicked ? currentPlaceholder : selectedPortfolioName
            }
            style={{ textAlign: 'center' }}
            className="portfolio-select-id"
            id="bypass"
          />
        </div>
        <div className="arrow-box">
          <div>
            <p>{'>'}</p>
          </div>
        </div>
      </div>
      <div
        className="portfolio-select-list"
        style={{
          visibility: showPortfolioSelect === true ? 'visible' : 'hidden'
        }}
      >
        {listMapping}
      </div>
    </PortfolioSelectContainer>
  );
};

export default PortfolioSelect;
