// Creates menu parents button groups with nested child buttons

import React, { useState, useEffect, Fragment } from 'react';
import Button from '../../components/Button';
import FontUtil from '../../utils/FontUtility';
// Menu Buttons with child group
const MenuButton = (props) => {
  const {
    navIndex,
    BackTopicArray,
    foundNavItem,
    title,
    subtitle,
    level,
    pages,
    chosenPortfolio,
    onButtonClick,
    SubRef,
    additionalButtonClassName,
    backgroundColor,
    //color,
    setGuestContent,
    SetTopicArray,
    setSavedContentPageId
  } = props;

  const {
    chosenMainPageButtonId, // chosen parent nav button id
    navHistory
  } = props.pageMenuReducer;

  const { currentLevel, chosenPageId } = props.pageMenuReducer;
  const [isSubActive, setIsSubActive] = useState(false); // keep track of if this sub/child button is active
  const id = foundNavItem.referenceId;
  const hasChild = foundNavItem.children.length > 0;

  // Listen for changes to change active state of this button
  useEffect(() => {
    // check if user clicked on different main/parent button
    /* NOTE currently using id of page to separate the logic.
    // This is assuming that pages are always unique and nav does not contain
    // any duplicate pages.
    */
    if (level === 0 && chosenMainPageButtonId !== id && isSubActive) {
      setIsSubActive(false);
    }
    if (
      level !== 0 &&
      currentLevel === level &&
      id !== chosenPageId &&
      isSubActive
    ) {
      // check if user clicked different button on same level
      setIsSubActive(false);
    } else if (currentLevel < level && isSubActive) {
      //disable anything lower than current level
      setIsSubActive(false);
    }
  }, [level, chosenPageId]); // eslint-disable-line react-hooks/exhaustive-deps
  //const [HState,SetHSate] = useState(false);
  return (
    <Fragment>
      {/* onMouseEnter={(eve)=>{SetHSate(true)}} onMouseLeave={(eve)=>{SetHSate(false)}} */}
      <li style={{ pointerEvents: 'auto' }}>
        <Button
          additionalClassName={additionalButtonClassName}
          height={chosenPortfolio.theme !== 'theme2' ? '93px' : '100%'}
          text={title}
          subtitle={
            chosenPortfolio.theme === 'theme4' ||
            chosenPortfolio.theme === 'theme2'
              ? subtitle
              : null
          }
          //backgroundColor={backgroundColor !== '' ? backgroundColor : undefined}
          backgroundColor={'transparent'}
          // color={color}
          color={'#333'}
          isSubActive={isSubActive}
          chosenPortfolio={chosenPortfolio}
          fontFamily={FontUtil(
            pages[foundNavItem.referenceId].TitleText,
            chosenPortfolio.ContentText
          )}
          onClick={(eve) => {
            // //////onsole.log("!!TEST!!",eve)
            // //////onsole.log("MODSTATE",eve.getModifierState("Shift"))
            if (eve.getModifierState('Shift')) {
              //////onsole.log("!!MOD--!!","!!--CONTROL!!")
            }
            if (chosenPortfolio?.theme === 'theme2') {
              setSavedContentPageId(0);
            }

            let isNotEnd = false;

            // check if next child doesnt contain another child
            if (hasChild) {
              isNotEnd = foundNavItem.children.some((childArray) => {
                return childArray.children.length !== 0;
              });
            }
            // let chosenPortfolio = new Object();
            // chosenPortfolio.theme = "theme5";
            //////////onsole.log("PortCheck",chosenPortfolio)
            if (
              (isNotEnd && isSubActive === false) ||
              chosenPortfolio.theme !== 'theme4'
            )
              setIsSubActive(true);
            else {
              BackTopicArray();
              setIsSubActive(false);
            }
            SetTopicArray(title, level, id);
            onButtonClick(id, level, hasChild, isNotEnd, navIndex);
            SubRef.current.scrollTo({
              left: window.innerWidth * navIndex,
              behavior: 'smooth'
            });
            //////onsole.log("!!!PAGEID!!!", id);

            // set setCurrentContentHelper
            if (setGuestContent) {
              setGuestContent();
            }
          }}
        />
      </li>
      {/* HState|| */}
      <div
        className={'hoveredMark'}
        style={{
          position: 'absolute',
          backgroundColor: backgroundColor ? backgroundColor : 'black',
          height: '100%',
          top: '0px',
          transform:
            isSubActive || navHistory[0] === navIndex
              ? 'translate(0%,90%)'
              : 'translate(0%,101%)'
        }}
      ></div>
      {/* {hasChild && (
        <MenuSection
          {...props}
          isActive={isSubActive}
          level={level + 1}
          chosenNavItem={foundNavItem.children}
          SetTopicArray={SetTopicArray}
        />
      )} */}
    </Fragment>
  );
};

const MenuSection = (props) => {
  const [foundNavItems, setFoundNavItems] = useState([]);
  //const [containsContentGroup, setContainsContentGroup] = useState(false);
  const {
    isGuestMode,
    BackTopicArray,
    isPortfolioView,
    guestContentHelper,
    chosenNavItem,
    pages,
    nav,
    level,
    isActive,
    getActiveString,
    getIsAtEndString,
    companyConfig,
    chosenPortfolio,
    //SubRef,
    SetTopicArray,
    setCurrentContentHelper,
    setSavedContentPageId
  } = props;

  useEffect(() => {
    // create page buttons, chosenNavItem is undefined at first load
    if (!chosenNavItem) {
      if (nav) {
        // parent pages buttons
        setFoundNavItems(nav.items);
      }
    } else {
      // child page buttons
      setFoundNavItems(chosenNavItem);
    }
  }, [chosenNavItem, isGuestMode, isPortfolioView, nav]);

  if (!nav || foundNavItems.length === 0) {
    return null;
  }

  let isActiveString = getActiveString(isActive, level);
  let isAtEndString = getIsAtEndString();

  const menuSection = foundNavItems.map((foundNavItem, index) => {
    // get color for each specific page....
    let accentColor;

    let textColor;
    //let subtitle;
    let title;
    if (level !== 0) {
      try {
        accentColor = pages[foundNavItem.referenceId].accent_color;
        textColor = pages[foundNavItem.referenceId].text_color;
        //subtitle = pages[foundNavItem.referenceId].subtitle;
        title = pages[foundNavItem.referenceId].title;
      } catch (ex) {
        //////////onsole.log("MenuSection ERR:", ex);
      }

      // set to chosenPortfolio color if it doesn't exists
      accentColor = accentColor ? accentColor : chosenPortfolio.secondary_color;
      textColor = textColor ? textColor : chosenPortfolio.text_color;

      // set to companyConfig color if it doesn't exists
      accentColor = accentColor ? accentColor : companyConfig.secondary_color;
      textColor = textColor ? textColor : companyConfig.text_color;
    } else {
      try {
        accentColor = pages[foundNavItem.referenceId].accent_color;
        textColor = pages[foundNavItem.referenceId].text_color;
        //subtitle = pages[foundNavItem.referenceId].subtitle;
        title = pages[foundNavItem.referenceId].title;
      } catch (ex) {
        //////////onsole.log("MenuSection ERR:", ex);
      }

      // set to chosenPortfolio color if it doesn't exists
      accentColor = accentColor ? accentColor : chosenPortfolio.primary_color;
      textColor = textColor ? textColor : chosenPortfolio.text_color;

      // set to companyConfig color if it doesn't exists
      accentColor = accentColor ? accentColor : companyConfig.primary_color;
      textColor = textColor ? textColor : companyConfig.text_color;
    }
    const menuButton = (
      <MenuButton
        {...props}
        navIndex={index}
        foundNavItem={foundNavItem}
        title={title}
        SetTopicArray={SetTopicArray}
        BackTopicArray={BackTopicArray}
        // subtitle={
        //   chosenPortfolio.theme === 'theme4' || chosenPortfolio.theme === 'theme2' ? subtitle : null
        // }
        isActiveString={isActiveString}
        isAtEndString={isAtEndString}
        backgroundColor={accentColor}
        color={textColor}
        setGuestContent={
          isGuestMode && !isPortfolioView
            ? () => setCurrentContentHelper(guestContentHelper[index].id)
            : undefined
        }
        additionalButtonClassName={`level-${level} ${isActiveString} ${isAtEndString}`}
        setSavedContentPageId={setSavedContentPageId} // additional className to buttons
      />
    );
    // if level 0, group those into div button-group-level-0
    return (
      <Fragment key={foundNavItem._id + '-' + index}>
        {level === 0 ? (
          <ul
            className={`group level-${level} ${isActiveString} ${isAtEndString}`}
          >
            {menuButton}
          </ul>
        ) : (
          menuButton
        )}
      </Fragment>
    );
  });

  // if not level 0, group those into div button-group-level-<n>
  return (
    <Fragment>
      {level === 0 ? (
        menuSection
      ) : (
        <ul
          className={`group level-${level} ${isActiveString} ${isAtEndString}`}
        >
          {menuSection}
        </ul>
      )}
    </Fragment>
  );
};

export default MenuSection;
