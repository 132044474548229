import React, { Fragment, useEffect, useState } from 'react';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import BlockContent from '@sanity/block-content-to-react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { CLOUD_API_URL, GUEST_BASE_URL } from '../../constants/constants';
import Button from '../../components/Button';
import IN from '../../components/InputBox';
import PortfolioPage from '../PortfolioPage';
import { urlFor } from '../../utils/sanityHelper';
import { emailIsValid } from '../../utils/validateHelper';
// import axios from 'axios';
import FontUtil from '../../utils/FontUtility';
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
// const IMG2 = ('https://f2bportfolio.com/styledloadwheel.html');

const StyledGuestPage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to bottom right, #0769af, white);

  @media (max-width: 450px) and (orientation: portrait) {
    background-image: linear-gradient(to bottom right, #0769af, #2695d2, white);
  }
`;
// font-family: ${FontUtil(chosenPortfolio.SystemText)}
//fontFamily:FontUtil(chosenPortfolio.SystemText)
const StyledGuestPageBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // min-width: 900px;
  width: 600px;
  height: 100%;
  border-radius: 50px;
  background-color: transparent;

  @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
    flex-direction: row;
  }

  @media (max-width: 450px) and (orientation: portrait) {
    height: 100%;
    width: 100%;
    border-radius: 0px;
  }

  .bottom-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .input-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2%;
    margin-bottom: 2%;

    .icon-container {
      width: 45px;
      height: 44px;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid black;
      cursor: text;
    }
  }

  .GuestBox {
    width: 100%;
    max-height: 100px;
    padding: 8px;
    padding-top: 15px;
    padding-bottom: 15px;
    overflow: hidden;
    width: 334px;
    background-color: white;
    margin-bottom: 3%;
    border-radius: 2px;
    background-color: #3b3b3b4d;

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      margin-block: 0px;
      font-size: 18px;
      font-family: 'Eczar', serif;
      text-align: center;
      color: white;
      // font-style: italic;

      // &::before {
      //   content: '"';
      // }

      // &::after {
      //   content: '"';
      // }
    }

    @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
      padding: 0px;
    }

    @media (max-width: 450px) and (orientation: portrait) {
      max-height: 100px;
    }
  }

  .button-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2%;
    margin-bottom: 2%;
    width: 100%;

    @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
      margin-top: 0%;
      margin-bottom: 10%;
    }

    @media (max-width: 450px) and (orientation: portrait) {
      margin-bottom: 25%;
    }
  }
`;

const StyledGuestLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 300px;
  height: 300px;
  padding: 10px;
  padding-bottom: 30px;
  object-fit: contain;

  @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
    height: 175px;
    width: 175px;
    padding-right: 40px;
  }

  @media (max-width: 450px) and (orientation: portrait) {
    width: 225px;
    height: 225px;
  }

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
`;

const StyledLoadingPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: transparent;

  .centering-div {
    height: 500px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .text-box {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-block: 30px;

      p {
        text-align: center;
        font-family: 'Fredoka One', cursive;
        font-size: 28px;
        color: white;
        margin-block: 0px;
        text-shadow: 1px 1px 1px #3b3b3b80;
      }

      .dot-one {
        opacity: 0;
        -webkit-animation: dot 1.3s infinite;
        -webkit-animation-delay: 0s;
        animation: dot 1.3s infinite;
        animation-delay: 0s;
      }

      .dot-two {
        opacity: 0;
        -webkit-animation: dot 1.3s infinite;
        -webkit-animation-delay: 0.2s;
        animation: dot 1.3s infinite;
        animation-delay: 0.2s;
      }

      .dot-three {
        opacity: 0;
        -webkit-animation: dot 1.3s infinite;
        -webkit-animation-delay: 0.3s;
        animation: dot 1.3s infinite;
        animation-delay: 0.3s;
      }

      @-webkit-keyframes dot {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }

      @keyframes dot {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }

    .loading-box {
      .bar {
        width: 10px;
        height: 70px;
        margin: 1px;
        background: #fff;
        display: inline-block;
        transform-origin: bottom center;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        animation: loader 1.2s linear infinite;
      }

      .bar1 {
        animation-delay: 0.1s;
      }

      .bar2 {
        animation-delay: 0.2s;
      }

      .bar3 {
        animation-delay: 0.3s;
      }

      .bar4 {
        animation-delay: 0.4s;
      }

      .bar5 {
        animation-delay: 0.5s;
      }

      .bar6 {
        animation-delay: 0.6s;
      }

      .bar7 {
        animation-delay: 0.7s;
      }

      .bar8 {
        animation-delay: 0.8s;
      }

      @keyframes loader {
        0% {
          transform: scaleY(0.1);
          background: ;
        }
        50% {
          transform: scaleY(1);
          background: #ff993a;
        }
        100% {
          transform: scaleY(0.1);
          background: white;
        }
      }
    }
  }
`;

const StyledErrorMessage = styled.div`
  position: absolute;
  height: 50px;
  width: 300px;
  background-color: #3b3b3b80;
  bottom: 75px;
  transition: 0.3s ease all;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;

  .icon-box {
    height: 100%;
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .text-box {
    height: 100%;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    p {
      padding-left: 5px;
      padding-right: 5px;
      margin-block: 0px;
      font-size: 14px;
      font-family: 'Fredoka One', serif;
      color: white;
    }
  }
`;

const GuestPage = ({
  auth,
  history,
  match,
  guestPass,
  chosenPortfolio,
  sanityContentStatus,
  companyConfig,
  expireDate,
  guestPrepPortfolio,
  resetPortfolioMode,
  setPortfolioSessionId,
  addSessionToSanity,
  SetGuestData,
  expireDateStatus
}) => {
  const [isLocalError, setIsLocalError] = useState(false);
  const [SessionStarted, setSessionStarted] = useState(false);
  const [Numberexcess, setNumberexcess] = useState(false);
  const [Name, setName] = useState(String(''));
  const [IsGuest, setIsGuest] = useState(undefined);
  const [Email, setEmail] = useState(String(''));
  const [NameValid, SetNameValid] = useState(true);
  const [EmailValid, SetEmailValid] = useState(true);
  const firebase = useFirebase();
  const firestore = useFirestore();
  const currentDate = new Date();

  // first run
  useEffect(() => {
    // log out signed in user if exists
    setEmail(match.params.em);
    setName(match.params.fn);
    firebase.logout();
    // also clear portfolio incase it was saved before (ex valid user testing guest pass)
    resetPortfolioMode();

    // fetch
    fetch(`${CLOUD_API_URL}/guest/${match.params.id}`, {})
      .then((response) => {
        if (!response.ok) {
          // handle response error
          console.error(response.statusText);
        } else {
          return response.json();
        }
      })
      .then((data) => {
        //////////onsole.log(data);
        // success
        if (data.exists) {
          //////////onsole.log('sucess sign in Anonymously');
          // guest pass exists
          // sign in anonymously
          firebase
            .auth()
            .signInAnonymously()
            .catch(function (error) {
              console.error(error);
              setIsLocalError(true);
            });
        } else {
          setIsLocalError(true);
        }
      })
      .catch((error) => {
        // handle error
        console.error(error);
        setIsLocalError(true);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      //eslint-disable-next-line
      (auth.isAnonymous, //ErrLine?
      sanityContentStatus === 'ready' &&
        isEmpty(chosenPortfolio) &&
        !isEmpty(guestPass[0]))
    ) {
      // get id from url
      let guestPassId = match.params.id;
      //////////onsole.log("GP: ",guestPass)
      setIsGuest(guestPass[0].is_portfolio_view);
      // pass guestPass and url
      guestPrepPortfolio(guestPass, `${GUEST_BASE_URL}${guestPassId}`);
    }
  }, [auth, sanityContentStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  const EmailValidforSend = async (guestpass2, EmailBool, companyhelp) => {
    if (
      !guestpass2.Views?.filter((ele) => {
        //////onsole.log(ele); //////onsole.log(Name);//////onsole.log(Email);
        return (
          ele.Name.trim().toLowerCase() === Name.trim().toLowerCase() &&
          ele.Email.trim().toLowerCase() === Email.trim().toLowerCase()
        );
      }).length > 0
    ) {
      try {
        let temp = await firestore
          .collection('companies')
          .doc(guestPass[0].company_id)
          .get();
        console.log('Company', temp.data());
        EmailBool = true;
        companyhelp = temp.data().name;
        //JSON.stringify
      } catch (err) {
        console.error('Company', err);
      }
      // firestore.collection("companies").doc(guestPass[0].company_id).get().then((res)=>{
      //   console.log("Company",res.data());
      //   EmailBool = true;
      //   companyhelp = res.data().name
      // //JSON.stringify
      // },(err)=>{console.error("Company",err)})
    }
    return { EmailBool, companyhelp };
  };
  // Create session, store to firestore
  const createSession = async () => {
    let guestpass2 = guestPass[0];
    // let EmailBool = false;
    // let companyhelp = ""
    //Send Req here
    //TESTIU2/Subroutines/FirebaseConnect.js -- GMAIL setup, Use automation to start?
    console.log('PRE', guestpass2, false, '');
    let { EmailBool, companyhelp } = await EmailValidforSend(
      guestpass2,
      false,
      ''
    );
    console.log('POST', guestpass2, EmailBool, companyhelp);

    //GPID,NAME,EMAIL,CompanyName
    SetGuestData({
      Name: Name,
      Email: Email,
      GuestMode: 'guest',
      ID: match.params.id
    });
    const session = {
      creator: guestPass[0].user_name,
      customer_name: guestPass[0].customer_name,
      gp_name: Name.length > 0 ? Name : '',
      gp_email: Email.length > 0 ? Email : '',
      gp_id: match.params.id,
      page_visited: [],
      user_id: guestPass[0].user_id,
      user_sanity_id: guestPass[0].user_sanity_id,
      portfolio_id: guestPass[0].portfolio_id,
      session_date: firestore.Timestamp.now(),
      customer_email: guestPass[0].customer_email,
      viewer_log: guestPass[0].viewer_log ? guestPass[0].viewer_log : [],
      type: 'guest',
      company_id: guestPass[0].company_id,
      needsEmailSending: true,
      GPEmailRequired: EmailBool,
      updated_at: firestore.Timestamp.now()
    };

    const VALIDATION_ENDPOINT =
      'https://stable---portfolio-relay-send-lnovtafojq-uc.a.run.app/guestpass/send';
    const OLD_VALIDATION_ENDPOINT =
      'https://gprentryfirestoreaccessor-7sqo2ajhyq-uc.a.run.app/AGPU/g/';
    console.log('> VALIDATION_ENDPOINT', VALIDATION_ENDPOINT);
    console.log('> OLD_VALIDATION_ENDPOINT', OLD_VALIDATION_ENDPOINT);

    //////////onsole.log("GP Session: ",guestpass2)
    //guestpass2.viewer_log?.length
    if (
      guestpass2.Views?.length > Number.MAX_SAFE_INTEGER - 100 &&
      match.params.id !== 'M14JyPkTS6OYwt999Io6'
    ) {
      return;
    } else if (
      !guestpass2.Views?.filter((ele) => {
        //////onsole.log(ele); //////onsole.log(Name);//////onsole.log(Email);
        return (
          ele.Name.trim().toLowerCase() === Name.trim().toLowerCase() &&
          ele.Email.trim().toLowerCase() === Email.trim().toLowerCase()
        );
      }).length > 0
    ) {
      if (
        guestpass2.Views?.length === Number.MAX_SAFE_INTEGER - 100 &&
        match.params.id !== 'M14JyPkTS6OYwt999Io6'
      ) {
        setNumberexcess(true);
        return;
      }
      //Update Guest viewer to show email link has been sent.
      let NV = guestpass2.Views
        ? [...guestpass2.Views, { Name: Name, Email: Email }]
        : [{ Name: Name, Email: Email }];
      firestore
        .collection('guest_passes')
        .doc(guestpass2.id)
        .set({ Views: [...NV] }, { merge: true });
      firestore
        .collection('guest_passes')
        .doc(guestpass2.id)
        .set({ needsEmailSending: true }, { merge: true });
    }
    firestore
      .collection('sessions')
      .add(session)
      .then((docRef) => {
        //////////onsole.log('Guest session create success firestore');
        // set session_id in portfolioReducer
        setPortfolioSessionId(docRef.id);
        if (EmailBool) {
          const emailSubject = chosenPortfolio?.message?.email_subject;
          const emailBody = chosenPortfolio?.message?.email_message;
          const json = {
            session_id: docRef.id,
            id: match.params.id,
            name: Name,
            email: Email,
            company: companyhelp,
            subject: emailSubject,
            body: emailBody
          };
          fetch(VALIDATION_ENDPOINT, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(json)
          })
            .then((response) =>
              console.log('Fetch: Response', response.data, response)
            )
            .catch((err) => console.log('Fetch: err', err.message, err));
        }
        //add sessions to sanity with session_id created by firestore
        try {
          addSessionToSanity({
            ...session,
            session_id: docRef.id,
            session_type: 'guest'
          });
        } catch (error) {
          console.error('!!SANITY ISSUE!!');
        }
        //history.push('/portfolio/guest');
        setSessionStarted(true);
      })
      .catch((error) => {
        setIsLocalError(true);
        console.error('Error adding document: ' + error);
      });
  };

  if (expireDateStatus === 'fail' || isLocalError) {
    return (
      <StyledGuestPage>
        <StyledGuestPageBox>
          <h2 style={{ fontFamily: FontUtil(chosenPortfolio.SystemText) }}>
            Error with guest pass
          </h2>
        </StyledGuestPageBox>
      </StyledGuestPage>
    );
  }
  //Sorry! The Guest Pass Limit has been reached. Please reach out to your Guest Pass provider to receive another link.
  if (Numberexcess) {
    return (
      <StyledGuestPage>
        <StyledGuestPageBox>
          <h2
            style={{
              textAlign: 'center',
              fontFamily: FontUtil(chosenPortfolio.SystemText)
            }}
          >
            Sorry! The Guest Pass Limit has been reached. Please reach out to
            your Guest Pass provider to receive another link.
          </h2>
        </StyledGuestPageBox>
      </StyledGuestPage>
    );
  }

  //////////onsole.log(match.params);
  //////////onsole.log("CHOSEN PORT: ",chosenPortfolio)
  //////////onsole.log("IsName Empty: ",Name === "")
  //////////onsole.log(Name);
  // try {
  //////onsole.log("EXPIREDATE",expireDate && expireDate.getTime() < currentDate.getTime(),expireDate,expireDate.getTime(), currentDate.getTime())
  // } catch {}

  // //onsole.log("CHOSEN PORTFOLIO", chosenPortfolio);
  return !SessionStarted ? (
    <StyledGuestPage backgroundColor={companyConfig.primary_color}>
      <img
        style={{ position: 'absolute' }}
        alt=""
        src={
          chosenPortfolio?.message?.GPBackground?.asset
            ? urlFor(chosenPortfolio?.message?.GPBackground?.asset)
                .auto('format')
                .width(1920)
                .url()
            : null
        }
      />
      {isEmpty(chosenPortfolio) ? (
        <StyledLoadingPage>
          <div className="centering-div">
            <div className="loading-box">
              <div className="bar bar1" />
              <div className="bar bar2" />
              <div className="bar bar3" />
              <div className="bar bar4" />
              <div className="bar bar5" />
              <div className="bar bar6" />
              <div className="bar bar7" />
              <div className="bar bar8" />
            </div>
            <div className="text-box">
              <p>
                this will just take a few seconds
                <span className="dot-one">.</span>
                <span className="dot-two">.</span>
                <span className="dot-three">.</span>
              </p>
            </div>
          </div>
        </StyledLoadingPage>
      ) : (
        <StyledGuestPageBox style={{ position: 'relative' }}>
          {expireDate && expireDate.getTime() > currentDate.getTime() ? (
            <Fragment>
              {chosenPortfolio?.message?.GPLogo?.asset ? (
                <StyledGuestLogo>
                  <img
                    src={
                      chosenPortfolio?.message?.GPLogo?.asset
                        ? urlFor(chosenPortfolio?.message?.GPLogo?.asset)
                            .auto('format')
                            .width(300)
                            .url()
                        : urlFor(companyConfig.logo.asset)
                            .auto('format')
                            .width(300)
                            .url()
                    }
                    alt="logo"
                    type="image"
                  />
                </StyledGuestLogo>
              ) : null}
              <div className="bottom-container">
                {chosenPortfolio?.message?.guest_pass_message && (
                  <div
                    className="GuestBox"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      overflow: 'hidden'
                    }}
                  >
                    <BlockContent
                      blocks={
                        IsGuest
                          ? chosenPortfolio.message.guest_pass_message
                            ? chosenPortfolio.message.guest_pass_message
                            : chosenPortfolio.message.Briefcase_message
                            ? chosenPortfolio.message.Briefcase_message
                            : ''
                          : chosenPortfolio.message.Briefcase_message
                          ? chosenPortfolio.message.Briefcase_message
                          : chosenPortfolio.message.guest_pass_message
                          ? chosenPortfolio.message.guest_pass_message
                          : ''
                      }
                    />
                  </div>
                )}
                <div className="input-wrapper">
                  <div className="icon-container">
                    <AccountCircleRoundedIcon
                      style={{
                        fontSize: '32px',
                        color: '#3b3b3b'
                      }}
                    />
                  </div>
                  <IN
                    style={{
                      borderTop: 'none',
                      borderRight: 'none',
                      borderLeft: 'none',
                      borderRadius: '0px',
                      fontFamily: "'Fredoka One', cursive"
                    }}
                    name="name"
                    width="305px"
                    height="45px"
                    placeholder={'What should we call you?'}
                    type="name"
                    onChange={(e) => {
                      setName(e.target.value); //////////onsole.log(e.target.value);
                      SetNameValid(
                        e.target.value !== '' && e.target.value !== undefined
                      );
                    }}
                    value={Name}
                    isValid={NameValid}
                    required={true}
                    autocomplete={'name'}
                  ></IN>
                </div>
                <div className="input-wrapper">
                  <div className="icon-container">
                    <MailOutlineRoundedIcon
                      style={{
                        fontSize: '32px',
                        color: '#3b3b3b'
                      }}
                    />
                  </div>
                  <IN
                    style={{
                      borderTop: 'none',
                      borderRight: 'none',
                      borderLeft: 'none',
                      borderRadius: '0px',
                      marginTop: '2%',
                      marginBottom: '2%',
                      fontFamily: "'Fredoka One', cursive"
                    }}
                    name="email"
                    width="305px"
                    height="45px"
                    placeholder={'What is the best email to reach you at?'}
                    type="email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                      SetEmailValid(emailIsValid(e.target.value));
                    }}
                    value={Email}
                    isValid={EmailValid}
                    required={true}
                    autocomplete={'email'}
                  ></IN>
                </div>
                <div
                  className="GuestBox"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden'
                  }}
                >
                  <p style={{ fontSize: '10px' }}>
                    By entering, for the first time, you agree to recieve an
                    email with a link for reentry. Changing your submitted
                    information can result in an additional email.
                  </p>
                </div>
                <div className="button-box">
                  <Button
                    style={{
                      borderRadius: '2px',
                      fontFamily: "'Merriweather', serif",
                      boxShadow: chosenPortfolio?.accent_color
                        ? `0px 0px 3px 2px ${chosenPortfolio.accent_color}80`
                        : '0px 0px 3px 2px #3b3b3b80'
                    }}
                    additionalClassName="guest-start-button"
                    width="350px"
                    height="45px"
                    text="Enter"
                    color={
                      chosenPortfolio.text_color
                        ? chosenPortfolio.text_color
                        : 'white'
                    }
                    backgroundColor={
                      chosenPortfolio.primary_color
                        ? chosenPortfolio.primary_color
                        : '#3b3b3b'
                    }
                    onClick={() => {
                      SetNameValid(Name !== '' && Name !== undefined);
                      SetEmailValid(emailIsValid(Email));

                      if (
                        EmailValid &&
                        NameValid &&
                        emailIsValid(Email) &&
                        Name !== '' &&
                        Name !== undefined
                      ) {
                        //////////onsole.log("Creating Session");
                        createSession();
                      }
                    }}
                  />
                </div>
              </div>
            </Fragment>
          ) : expireDate && expireDate.getTime() < currentDate.getTime() ? (
            <div>Guest pass is expired</div>
          ) : (
            <StyledLoadingPage>
              <div className="centering-div">
                <div className="loading-box">
                  <div className="bar bar1" />
                  <div className="bar bar2" />
                  <div className="bar bar3" />
                  <div className="bar bar4" />
                  <div className="bar bar5" />
                  <div className="bar bar6" />
                  <div className="bar bar7" />
                  <div className="bar bar8" />
                </div>
                <div className="text-box">
                  <p>
                    this will just take a few seconds
                    <span className="dot-one">.</span>
                    <span className="dot-two">.</span>
                    <span className="dot-three">.</span>
                  </p>
                </div>
              </div>
            </StyledLoadingPage>
          )}
          <StyledErrorMessage
            style={{
              transform: EmailValid ? 'translateY(150px)' : 'translateX(0%)',
              opacity: EmailValid ? 1 : 1
            }}
          >
            <div className="icon-box">
              <ErrorOutlineRoundedIcon
                style={{
                  fontSize: '36px',
                  color: '#f01000'
                }}
              />
            </div>
            <div className="text-box">
              <p>Oops! Please input a valid email.</p>
            </div>
          </StyledErrorMessage>
        </StyledGuestPageBox>
      )}
    </StyledGuestPage>
  ) : (
    <PortfolioPage />
  );
};

export default GuestPage;
