import React, { useState, useRef } from 'react'; //useEffect
import { IconContext } from 'react-icons';
//import Icons from '../Icons';
import Button from '../../components/Button';
//import TextArea from '../../components/TextArea';
import ReactQuill from 'react-quill'; //, {Quill}
import 'react-quill/dist/quill.snow.css';
import X from '../../assets/images/X-Icon.png';
import FontUtil from '../../utils/FontUtility';

const Notepad = ({
  notepadText,
  showNotepad,
  toggleNotepad,
  isGuestMode,
  updateNotepad,
  updateSessionInSanity,
  updateSessionWithNotes,
  chosenPortfolio,
  companyConfig
}) => {
  const [text, setText] = useState('');
  //const [ptext, setpText] = useState('');
  const [saved, setSaved] = useState(false);

  // useEffect(() => {
  //   setText(notepadText);
  // }, [notepadText]);
  const [copied, setCopied] = useState(false);
  const CopyTxt = () => {
    //////onsole.log("!!SELECTION!!",reftt)
    //////onsole.log("!!SELECTION!!",reftt.current)
    const range = document.createRange();
    ////////onsole.log("CURRENT NODE", clipboardRef.current);
    range.selectNode(reftt.current.editingArea);
    ////////onsole.log("RANGE", range);
    window.getSelection().addRange(range);
    //reftt.current.editingArea.select();

    try {
      // const successful = document.execCommand('copy');
      document.execCommand('copy');
      //const msg = successful ? 'successful' : 'unsuccessful';
      //////onsole.log('Copy email command was ' + msg);
    } catch (err) {
      //////onsole.log('Oops, unable to copy');
    }

    window.getSelection().removeAllRanges();

    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };
  // //https://stackoverflow.com/questions/44656610/download-a-string-as-txt-file-in-react/44661948
  // const downloadTxtFile = () => {
  //   const element = document.createElement('a');
  //   const file = new Blob([text], { type: 'text/plain' });
  //   element.href = URL.createObjectURL(file);
  //   element.download = 'session-notes.txt';
  //   document.body.appendChild(element); // Required for this to work in FireFox
  //   element.click();
  // };

  const handleSaveNotification = () => {
    setSaved(true);
    setTimeout(() => {
      setSaved(false);
    }, 1500);
  };
  // chosenPortfolio.primary_color
  //           ? chosenPortfolio.primary_color
  //           : companyConfig.primary_color,
  const reftt = useRef();
  //Ping Font Family
  return (
    <>
      <style>{`div.ql-container{
      height: 100%;
      overflow: auto;
      }
      div.quill{
        color: black;
        max-height: 100%;
        display: inline-flex;
        flex-direction: column;
        width: 100%;
        user-select: text;
      }
      div.ql-editor{
        word-wrap: anywhere;
        user-select: text;
      }
      button.ql-link{
        display: none !important;
      }
      `}</style>
      <IconContext.Provider
        value={{
          className: 'menu-icon-md',
          style: {
            color: chosenPortfolio.accent_color
              ? chosenPortfolio.accent_color
              : companyConfig.accent_color
          }
        }}
      >
        <div
          className={`portfolio-notepad-container ${
            showNotepad ? 'active' : 'hide'
          }`}
          style={{
            backgroundColor: 'white',
            color: chosenPortfolio.text_color
              ? chosenPortfolio.text_color
              : companyConfig.text_color,
            border: `4px solid ${
              chosenPortfolio.primary_color
                ? chosenPortfolio.primary_color
                : 'white'
            }`,
            borderLeft: `8px solid ${
              chosenPortfolio.primary_color
                ? chosenPortfolio.primary_color
                : 'white'
            }`
          }}
        >
          <div
            onClick={toggleNotepad}
            className="notepad-close-button"
            style={{
              maskImage: `url(${X})`,
              maskSize: '100% 100%',
              maskRepeat: 'no-repeat',
              WebkitMaskImage: `url(${X})`,
              WebkitMaskRepeat: 'no-repeat',
              WebkitMaskSize: '100% 100%',
              backgroundColor: `${
                chosenPortfolio.primary_color
                  ? chosenPortfolio.primary_color
                  : 'white'
              }`,
              height: '96px',
              width: '96px',
              cursor: `pointer`,
              alignSelf: 'flex-end',
              float: 'right',
              objectFit: 'contain',
              objectPosition: 'right',
              margin: '16px',
              marginBottom: '0px'
            }}
          ></div>
          {/* <div className="notepad-close">
          <Icons.Close onClick={toggleNotepad} />
        </div> */}
          <div
            className="textarea-container"
            style={{
              position: 'relative',
              width: '93%',
              left: 'calc(50% - 46.5%)'
            }}
          >
            <h1 style={{ width: '100%' }} className="notes-title">
              {!isGuestMode ? 'Notes' : 'Comments'}
            </h1>
            <div
              style={{
                background: 'white',
                height: '90%',
                display: 'inline-flex',
                width: '100%'
              }}
            >
              <ReactQuill
                style={{ userSelect: 'text' }}
                ref={reftt}
                theme={'snow'}
                onChange={(e, Delta, Source, Editor) => {
                  //////onsole.log("!!SELECTION!!",e);
                  //////onsole.log("!!SELECTION!!",Editor.getContents());
                  setText(e);
                }}
                value={text}
              ></ReactQuill>
            </div>
            {/* style={{background:"white"}} */}
            {/* <TextArea
            name={'notepad-textarea'}
            value={text}
            onChange={(e) => setText(e.target.value)}
            disabled={false}
            ref={reftt}
          /> */}
            <div
              className="saved-notification"
              style={{
                position: 'absolute',
                backgroundColor: chosenPortfolio.primary_color
                  ? chosenPortfolio.primary_color
                  : '#0768b0',
                color: chosenPortfolio.text_color
                  ? chosenPortfolio.text_color
                  : 'white',
                textShadow: chosenPortfolio.accent_color
                  ? `1px 1px 1px ${chosenPortfolio.accent_color}`
                  : '1px 1px 1px black',
                zIndex: 2,
                top: '45%',
                left: 'calc(50% - 110px)',
                paddingLeft: '10px',
                paddingRight: '10px',
                paddingTop: '2px',
                paddingBottom: '2px',
                borderRadius: '5px',
                //fontFamily: "'Oswald', sans-serif",
                transition: 'all 0.4s ease',
                height: '50px',
                width: '200px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                visibility: saved ? 'visible' : 'hidden',
                fontFamily: FontUtil(chosenPortfolio.SystemText)
              }}
            >
              NOTES SAVED
            </div>
            <div
              className="copied-notification"
              style={{
                position: 'absolute',
                backgroundColor: chosenPortfolio.primary_color
                  ? chosenPortfolio.primary_color
                  : '#0768b0',
                color: chosenPortfolio.text_color
                  ? chosenPortfolio.text_color
                  : 'white',
                textShadow: chosenPortfolio.accent_color
                  ? `1px 1px 1px ${chosenPortfolio.accent_color}`
                  : '1px 1px 1px black',
                zIndex: 2,
                top: '45%',
                left: 'calc(50% - 110px)',
                paddingLeft: '10px',
                paddingRight: '10px',
                paddingTop: '2px',
                paddingBottom: '2px',
                borderRadius: '5px',
                //fontFamily: "'Oswald', sans-serif",
                transition: 'all 0.4s ease',
                height: '50px',
                width: '200px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                visibility: copied ? 'visible' : 'hidden',
                fontFamily: FontUtil(chosenPortfolio.SystemText)
              }}
            >
              Copied to Clipboard
            </div>
          </div>
          <div className="notepad-button-container">
            <Button
              enlarge={true}
              additionalClassName="notepad-save-button"
              width="100%"
              height="50px"
              backgroundColor={
                chosenPortfolio.primary_color
                  ? chosenPortfolio.primary_color
                  : companyConfig.primary_color
              }
              color={
                chosenPortfolio.text_color
                  ? chosenPortfolio.text_color
                  : companyConfig.text_color
              }
              text="Save"
              onClick={async () => {
                handleSaveNotification();
                updateNotepad(text);
                setText('');
                //updateSessionWithNotes(text);
                // await updateSessionInSanity({ notes: text });
                // await updateSessionInSanity({ needsNotesEmailSending: true });
                // alert("Success");
                // else
                // alert("Fail")
              }}
            />
            <Button
              enlarge={true}
              additionalClassName="notepad-download-button"
              width="100%"
              height="50px"
              backgroundColor={
                chosenPortfolio.primary_color
                  ? chosenPortfolio.primary_color
                  : companyConfig.primary_color
              }
              color={
                chosenPortfolio.text_color
                  ? chosenPortfolio.text_color
                  : companyConfig.text_color
              }
              text="Copy"
              onClick={CopyTxt}
            />
          </div>
        </div>
      </IconContext.Provider>
    </>
  );
};

export default Notepad;
