import React, { useState, useEffect, Fragment } from 'react';
import PortfolioContent from '../../../components/_PortfolioContentParent11/PortfolioContent';
import SubLevelContainer from '../../../components/_PortfolioContentParent11/SubLevelContainer';
import TopLevelContainer from '../../../components/_PortfolioContentParent11/TopLevelContainer';
import { isEmpty } from 'lodash';
// import Icons from '../../Icons';
import MasterMenu from '../../../components/_PortfolioContentParent11/MasterMenu';
import BottomRightToolbar from '../../../components/_PortfolioContentParent11/BottomRightToolbar';
import CatalogNotepad from '../../../components/_PortfolioContentParent11/Notepad';
import ScriptNote from '../../../components/_PortfolioContentParent11/Scriptnote';
import PIIModal from '../../../components/_PortfolioContentParent11/PII';
// import Briefcase from '../../../components/_Portfolio/PortfolioBriefcaseMegaMenu';
// import ArrowRightAltRoundedIcon from '@material-ui/icons/ArrowRightAltRounded';
// import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
// import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import TerminusLevelThumb from '../../../components/_PortfolioContentParent11/TerminusLevelThumb';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import { urlFor } from '../../../utils/sanityHelper';
// import { useFirestore } from 'react-redux-firebase';
// import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import ArrowBackRounded from '@material-ui/icons/ArrowBackRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';

const PortfolioContentParent11 = ({
  createSetCurrentContent,
  images,
  videos,
  pdfs,
  companyConfig,
  chosenPortfolio,
  pages,
  nav,
  pageBackgroundImageUrl,
  isGuestMode,
  isPortfolioView,
  guestContentHelper,
  currentContentHelper,
  isAtEnd,
  chosenPageId,
  chosenContentPageId,
  navHistory,
  setContentPageId,
  setCurrentContentHelper,
  setIsAtEnd,
  setNavHistory,
  setSavedContentPageId,
  clickedTerminusId,
  hasCurr,
  hasPrev,
  setHasCurr,
  setHasPrev,
  currentContent,
  updateSessionWithNotes,
  sendClick,
  masterMenuOpen,
  toolbarMenuOpen,
  Size,
  addToBriefcase,
  showBriefcase,
  setBooleanNewItems,
  briefcaseContent,
  userInfo,
  twolevelTerminus,
  setTwolevelTerminus,
  screensaverShow,
  Topic,
  setTopic,
  setMegamenuLevel,
  megamenuLevel,
  setTermWithChild,
  termWithChild,
  backgroundImageUrl,
  setSelectedTopLevelId,
  setShowTermChild,
  showTermChild,
  shareClick
}) => {
  //Flag Font Family
  const [currentNavItem, setCurrentNavItem] = useState({});
  const [prevNavItem, setPrevNavItem] = useState({});
  const [currentTerminusSiblings, setCurrentTerminusSiblings] = useState([]);
  const [isAddable, setIsAddable] = useState(true); // bool to keep track of if it is addable to briefcase
  const [isAuthToAdd, setIsAuthToAdd] = useState(false); // is this user authorized to add item?
  const [isAuthBriefcaseShow, setIsAuthBriefcaseShow] = useState(false); // is this user authorized to see briefcase?
  const [LevelOfTerm, setLevelOfTerm] = useState(0); // keep track of previous page id for back button
  const [prevLevel, setPrevLevel] = useState(''); // keep track of previous page id for back button
  // const [endTerminusOpen, setEndTerminusOpen] = useState(false); // tracks if you're in a terminus child

  // animation state
  const [transitionedToTopLevel, setTransitionedToTopLevel] = useState(false);
  const [transitionedToSubLevel, setTransitionedToSubLevel] = useState(false);
  const [transitionedToEnd, setTransitionedToEnd] = useState(false);
  const [headerExtend, setHeaderExtend] = useState(true);

  useEffect(() => {
    if (isAtEnd) {
      setLevelOfTerm(prevLevel + 1);
    }
  }, [isAtEnd, prevLevel]);

  useEffect(() => {}, [
    currentTerminusSiblings,
    isAddable,
    isAuthToAdd,
    isAuthBriefcaseShow,
    transitionedToEnd,
    headerExtend
  ]);

  useEffect(() => {
    let splashKeyId = `splashkey-${chosenPortfolio._id}`;
    if (chosenPageId !== 0 && chosenPageId !== splashKeyId) {
      // get current nav item
      let tempNavItem = {};
      let tempPrevItem = {};
      // traverse nav history to find current nav
      navHistory.forEach((number, index) => {
        // first
        if (index === 0) {
          tempNavItem = nav.items[number];
        } else {
          tempNavItem = tempNavItem.children[number];
        }

        // check if index is one item before last
        if (index === navHistory.length - 1) {
          tempPrevItem = tempNavItem;
        }
      });
      setCurrentNavItem(tempNavItem);
      setPrevNavItem(tempPrevItem);
      if (tempNavItem.children && tempNavItem.children.length > 0) {
        setHasCurr(true);
      }
      if (tempPrevItem.children && tempPrevItem.children.length > 0) {
        setHasPrev(true);
      }
      // UNCOMMENT THIS IF WE HAVE NAV PROBLEMS AND CHECK AGAIN.
      setContentPageId(tempNavItem.referenceId);
    }
    if (chosenPageId === splashKeyId) {
      prepareSplash();
    }
    // eslint-disable-next-line
  }, [chosenPageId, pages, navHistory, chosenPortfolio]);

  // run everytime chosenContentPageId updates
  useEffect(() => {
    if (chosenContentPageId) {
      //check if clicked page was gallery with multiple items
      //////////onsole.log("??UPDATING CHOSEN CONTENT PAGE ID AND CREATING OBJECT")
      if (
        pages[chosenContentPageId]?.content &&
        pages[chosenContentPageId]?._type === 'gallery' &&
        pages[chosenContentPageId]?.content.length > 1
      ) {
        // automatically set first item of gallery to current content viewed
        let obj = {
          type: 'gallery',
          index: 0,
          id: chosenContentPageId
        };
        createSetCurrentContent(obj);
      } else {
        // else do normal
        let obj = {
          type: 'pageSub',
          id: chosenContentPageId
        };
        createSetCurrentContent(obj);
      }
    } else {
      // show splash when chosenContentPageId is undefined
      //////////onsole.log('chosenContentPageId is undefined, show splash.....');
      prepareSplash();
    }
    // eslint-disable-next-line
  }, [
    chosenContentPageId,
    pages,
    guestContentHelper,
    isGuestMode,
    isPortfolioView
  ]);

  // provides an array of sibling elements when we're in a terminus menu. Currently used for checking animation state
  useEffect(() => {
    if (currentNavItem?.children && isAtEnd) {
      let arr = [];
      for (let child of currentNavItem.children) {
        arr.push(child.referenceId);
      }
      setCurrentTerminusSiblings(arr);
    } else {
      setCurrentTerminusSiblings([]);
    }
  }, [currentNavItem, isAtEnd]);

  // handle transition animations between menu levels
  // useEffect(() => {

  //   // sub level for mobile
  //   if (chosenContentPageId === `splashkey-${chosenPortfolio._id}`) {
  //     setTransitionedToSubLevel(false);
  //   } else {
  //     setTimeout(() => {
  //       setTransitionedToSubLevel(true);
  //     }, 200);
  //   }

  //   // between top and terminus levels
  //   if (isAtEnd && Size.W >= 451) {
  //     if (currentTerminusSiblings.includes(chosenContentPageId)) {
  //       return;
  //     }
  //     setTransitionedToEnd(false);
  //     setHeaderExtend(false);
  //     setTimeout(() => {
  //       setTransitionedToEnd(true);
  //       setHeaderExtend(true);
  //     }, 100)
  //   } else if (isAtEnd && Size.W < 451) {
  //     setTransitionedToEnd(true);
  //     setHeaderExtend(true);
  //   } else {
  //     setTransitionedToEnd(false);
  //     setHeaderExtend(false);
  //   }

  // }, [isAtEnd, Size.W, chosenContentPageId, chosenPortfolio, currentTerminusSiblings]);

  useEffect(() => {
    // check if briefcase contains current content user is looking at
    if (
      (Object.entries(currentContent)?.length !== 0 &&
        briefcaseContent.filter((e) => e.id === currentContent.id).length >
          0) ||
      currentContent.page_id === `splashkey-${chosenPortfolio._id}`
    ) {
      setIsAddable(false);
    } else {
      setIsAddable(true);
    }
  }, [currentContent, briefcaseContent, chosenPortfolio]);

  // get userinfo auth for viewing sending briefcase
  useEffect(() => {
    if (userInfo && userInfo.length > 0) {
      // TODO: Why is this constant condition?
      // check if user is able to create guest pass
      if (true) {
        //userInfo[0].is_able_to_create_briefcase
        // user is able to create normal guest pass, aka able to add item to briefcase
        setIsAuthToAdd(true);
      }
      // check if user is able create portfolio level guest pass
      if (true) {
        // userInfo[0].is_able_to_create_guest_pass
        // user is able to create normal guest pass, aka able to add item to briefcase
        setIsAuthBriefcaseShow(true);
      }
    }
  }, [userInfo]);

  // if screensaver shows, reset navigation to top-level
  // useEffect(() => {
  //   if (screensaverShow) {
  //     setTransitionedToEnd(false);
  //     setHeaderExtend(false);
  //     setTransitionedToTopLevel(false);
  //     setTransitionedToSubLevel(false);
  //     setTimeout(() => {
  //       setHasCurr(false);
  //       setHasPrev(false);
  //       setIsAtEnd(false);
  //       let splashKeyId = `splashkey-${chosenPortfolio._id}`;
  //       setContentPageId(splashKeyId);
  //       setTransitionedToTopLevel(true);
  //     }, 300);
  //   }
  // }, [screensaverShow, chosenPortfolio._id, setHasCurr, setContentPageId, setHasPrev, setIsAtEnd]);

  // function used when user changes gallery index
  const prepareCurrentGalleryContent = (index) => {
    let obj = {
      type: 'gallery',
      index: index,
      id: chosenContentPageId
    };
    createSetCurrentContent(obj);
  };

  // prepare splash to display
  const prepareSplash = () => {
    let splashKeyId = `splashkey-${chosenPortfolio._id}`;
    // create nav specifically for splash
    setCurrentNavItem({
      children: [],
      referenceField: 'pages',
      referenceId: splashKeyId,
      title: 'Splash',
      type: 'gallery',
      _key: 'splashnav'
    });
    setContentPageId(splashKeyId);
    // check if guest
    if (isGuestMode && !isPortfolioView) {
      // find index
      let index = guestContentHelper.findIndex((x) => x.id === splashKeyId);
      setCurrentContentHelper(guestContentHelper[index].id);
    }
    if (Topic.length === 0 || Topic === undefined || Topic === null) {
      setTopic(['SplashPage']);
    }
  };

  if (chosenContentPageId === 0) {
    return null;
  }

  const contentData = () => {
    let tabData = {};
    // check if in guest mode
    if (
      isGuestMode &&
      chosenContentPageId !== 0 &&
      !isEmpty(currentContentHelper) &&
      pages[chosenContentPageId]?._type === 'gallery' &&
      pages[chosenContentPageId]?._key === currentContentHelper.page_id &&
      currentContentHelper.is_gallery_item
    ) {
      //////////onsole.log('MODIFY FOR GALLERY CONTENT');
      // create obj to only display gallery item chosen by guest
      let obj =
        pages[chosenContentPageId]?.content[currentContentHelper.gallery_index];
      tabData = [{ ...obj }];
      return tabData;
    }
    return pages[chosenContentPageId]?.content;
  };

  // custom content data choice - May not be needed
  const contentDataChoice = (id) => {
    let tabData = {};
    // check if in guest mode
    if (
      isGuestMode &&
      id !== 0 &&
      !isEmpty(currentContentHelper) &&
      pages[id]._type === 'gallery' &&
      pages[id]._key === currentContentHelper.page_id &&
      currentContentHelper.is_gallery_item
    ) {
      //////////onsole.log('MODIFY FOR GALLERY CONTENT');
      // create obj to only display gallery item chosen by guest
      let obj = pages[id].content[currentContentHelper.gallery_index];
      tabData = [{ ...obj }];
      return tabData;
    }
    return pages[id].content;
  };

  if (!chosenContentPageId) {
    return null;
  }

  // color for background
  const getColor = (child) => {
    // get color for each specific page....
    let accentColor = pages[child.referenceId].accent_color;
    let textColor = pages[child.referenceId].text_color;

    // set to chosenPortfolio color if it doesn't exists
    accentColor = accentColor ? accentColor : chosenPortfolio.accent_color;
    textColor = textColor ? textColor : chosenPortfolio.text_color;
    // set to companyConfig color if it doesn't exists
    accentColor = accentColor ? accentColor : companyConfig.accent_color;
    textColor = textColor ? textColor : companyConfig.text_color;

    return { accentColor: accentColor, textColor: textColor };
  };

  // nav click / grid nav click
  const onNavClick = (child, index) => {
    //////onsole.log("HITTING NAV CLICK");
    //////onsole.log("NAV CLICK CHILD", child);
    //////onsole.log("NAV CLICK INDEX", index);
    const hasChild = child.children.length > 0;
    let isNotEnd = false;
    // check if child cnotains more child
    if (hasChild) {
      isNotEnd = child.children.some((childArray) => {
        return childArray.children.length !== 0;
      });
    }

    // setIsVideoPlaying(true);
    setIsAtEnd(!isNotEnd);

    if (!isNotEnd) {
      // is end display content
      // select first children if exists
      if (child?.children[0]) {
        setContentPageId(child.referenceId);
        setSavedContentPageId(child.referenceId);
        setCurrentNavItem(child);
        setTermWithChild(true);
        let topicalarray = [...Topic];
        //onsole.log("TOPIC ARRAY TRY BLOCK", Topic)
        //onsole.log("TOPIC ARRAY CURRENTLY - end", topicalarray);
        //onsole.log(`ADDING TOPIC ARRAY PARENT END: ${child.title}, ${child.referenceId}`);
        topicalarray.splice(megamenuLevel, 11111, {
          title: child.title,
          id: child.referenceId
        });
        //onsole.log("TOPIC ARRAY CURRENTLY PLUS PARENT END", topicalarray);
        setTopic(topicalarray);
        let newLevel = megamenuLevel + 1;
        setMegamenuLevel(newLevel);
        setTimeout(() => {
          let finalTopicArray = [...topicalarray];
          //onsole.log(`ADDING TOPIC ARRAY CHILD END: ${child.children[0].title}, ${child.children[0].referenceId}`);
          finalTopicArray.splice(newLevel, 11111, {
            title: child.children[0].title,
            id: child.children[0].referenceId
          });
          //onsole.log("TOPIC ARRAY CURRENTLY PLUS CHILD END", finalTopicArray);
          setTopic(finalTopicArray);
          setContentPageId(child.children[0].referenceId);
          setSavedContentPageId(child.children[0].referenceId);
        }, 501);
      } else {
        setShowTermChild(true);
        setContentPageId(child.referenceId);
        setSavedContentPageId(child.referenceId);
        setCurrentNavItem(child);
        //onsole.log(`SETTING TOPIC ARRAY IN NAV CLICK, NO CHILD - end: ${child.title}, ${megamenuLevel}, ${child.referenceId}`);
        SetTopicArray(child.title, megamenuLevel, child.referenceId);
        setMegamenuLevel(megamenuLevel + 1);
      }
      // setContentPageId(
      //   child.children[0] ? child.children[0].referenceId : child.referenceId
      // );
      // setSavedContentPageId(child.children[0] ? child.children[0].referenceId : child.referenceId);
      // setCurrentNavItem(child);
    } else {
      //not end, set pageId
      //setChosenPageId(child.referenceId);
      // update navHistory
      //onsole.log(`SETTING TOPIC ARRAY IN NAV CLICK - not end: ${child.title}, ${megamenuLevel}, ${child.referenceId}`);
      SetTopicArray(child.title, megamenuLevel, child.referenceId);
      setMegamenuLevel(megamenuLevel + 1);
      setNavHistory([...navHistory, index]);
    }
  };

  const onBackClick = () => {
    //////onsole.log("HITTING BACK CLICK");
    //////onsole.log('prevNavItem', prevNavItem);
    //////onsole.log('currentNavItem', currentNavItem);
    let tempNavHistory = [...navHistory];
    if (navHistory.length > 0 && !isAtEnd) {
      // remove one item from navHistory
      tempNavHistory = navHistory.filter((x, i) => i !== navHistory.length - 1);
      setNavHistory(tempNavHistory);
      if (!tempNavHistory.length) {
        setSelectedTopLevelId(0);
      }
    }

    //set topic array
    BackTopicArray();
    setContentPageId(prevNavItem.referenceId);

    // back from terminal
    if (isAtEnd) {
      setIsAtEnd(false);
      setCurrentNavItem(prevNavItem);
      setContentPageId(prevNavItem.referenceId);
    }
  };

  // const onBackClickTerminus = () => {
  //   let tempNavHistory = [...navHistory];
  //   if (navHistory.length > 0 && !isAtEnd) {
  //     // remove one item from navHistory
  //     tempNavHistory = navHistory.filter((x, i) => i !== navHistory.length - 1);
  //     setNavHistory(tempNavHistory);
  //   }

  //   //set topic array
  //   BackTopicArray();

  //   setTransitionedToEnd(false);
  //   setHeaderExtend(false);
  //   setTransitionedToTopLevel(false);
  //   setTimeout(() => {
  //     setIsAtEnd(false);
  //     setCurrentNavItem(prevNavItem);
  //     setContentPageId(prevNavItem.referenceId);
  //     setTimeout(() => {
  //       setTransitionedToTopLevel(true);
  //     }, 100);
  //   }, 300)
  // }

  const returnTopLevel = () => {
    setTransitionedToEnd(false);
    setHeaderExtend(false);
    setTransitionedToTopLevel(false);
    setTransitionedToSubLevel(false);
    setTopic(['SplashPage']);
    // setTimeout(() => {
    setHasCurr(false);
    setHasPrev(false);
    setIsAtEnd(false);
    setSelectedTopLevelId(0);
    let splashKeyId = `splashkey-${chosenPortfolio._id}`;
    // create nav specifically for splash
    setCurrentNavItem({
      children: [],
      referenceField: 'pages',
      referenceId: splashKeyId,
      title: 'Splash',
      type: 'gallery',
      _key: 'splashnav'
    });
    setContentPageId(splashKeyId);
    setTransitionedToTopLevel(true);
    // }, 300);
  };

  // const checkBackClick = () => {
  //   if (Size.W < 451) {
  //     onBackClick()
  //   } else {
  //     onBackClickTerminus();
  //   }
  //   setSavedContentPageId(0);
  // }

  const BackTopicArray = () => {
    let topicalarray = [];
    try {
      topicalarray = [...Topic];
    } catch (err) {
      console.error('Error', err);
    }
    //onsole.log("TOPIC ARRAY", topicalarray);
    if (termWithChild) {
      topicalarray.splice(Topic.length - 2, 111111);
      //onsole.log("SPLICING TOPIC ARRAY x2 BECAUSE OF TERM", topicalarray);
      setMegamenuLevel(megamenuLevel - 1);
      setTermWithChild(false);
    } else {
      //onsole.log("SPLICING TOPIC ARRAY x1", topicalarray);
      topicalarray.splice(Topic.length - 1, 111111);
      if (megamenuLevel >= 0) {
        setMegamenuLevel(megamenuLevel - 1);
      }
    }

    setTopic(topicalarray);
  };

  const SetTopicArray = (title, level, id) => {
    //onsole.log("ENTERING SET TOPIC ARRAY - LEVEL: ", level);
    let topicalarray = [];
    try {
      topicalarray = [...Topic];
    } catch (err) {
      console.error('Error', err);
    }

    if (!isAtEnd) {
      topicalarray.splice(level, 11111, { title: title, id: id });
      //onsole.log("TOPIC ARRAY NOT END", topicalarray);
      setTopic(topicalarray);
    } else {
      topicalarray.splice(level, 11111, { title: title, id: id });
      //onsole.log("TOPIC ARRAY END", topicalarray);
      setTopic(topicalarray);
    }
  };

  // //onsole.log("HAS CURR OR PREV - CURR: ", hasCurr);
  // //onsole.log("HAS CURR OR PREV - PREV: ", hasPrev);
  // //onsole.log("CURRENT NAV ITEM", currentNavItem)
  // //onsole.log("CHOSEN PORTFOLIO", chosenPortfolio)
  // //onsole.log("PAGE", pages[chosenContentPageId]);

  return (
    <div className="app-container">
      {/*
      <Briefcase 
        sendClick={sendClick}
      /> */}
      <div
        className="portfolio-background-image"
        style={{
          backgroundImage: backgroundImageUrl
            ? `url(${backgroundImageUrl}`
            : null
        }}
      />
      <div
        className="portfolio-header"
        style={{
          backgroundColor: chosenPortfolio?.primary_color
            ? chosenPortfolio.primary_color
            : '#EEEEEE',
          boxShadow: chosenPortfolio?.accent_color
            ? `0px 1px 3px -1px ${chosenPortfolio.accent_color}80`
            : '0px 1px 3px -1px #3b3b3b80'
        }}
      >
        {hasCurr || hasPrev || isAtEnd ? (
          <div className="back-button" onClick={() => onBackClick()}>
            <ArrowBackRounded
              style={
                chosenPortfolio?.text_color
                  ? { color: chosenPortfolio.text_color }
                  : {}
              }
            />
          </div>
        ) : null}
        {hasCurr || hasPrev || isAtEnd ? (
          <div className="home-button" onClick={() => returnTopLevel()}>
            <HomeRoundedIcon
              style={
                chosenPortfolio?.text_color
                  ? { color: chosenPortfolio.text_color }
                  : {}
              }
            />
          </div>
        ) : null}
        {chosenPortfolio.portfolio_logo.asset._ref ? (
          <div className="portfolio-logo">
            <img
              src={urlFor(chosenPortfolio.portfolio_logo.asset._ref)
                .auto('format')
                .width(70)
                .url()}
              alt="logo"
            />
          </div>
        ) : null}
        <div
          className="show-current-page"
          style={{
            color: chosenPortfolio?.text_color
              ? chosenPortfolio.text_color
              : '#3b3b3b'
          }}
        >
          <p>{currentNavItem?.title}</p>
        </div>
      </div>
      <div className="portfolio-content-parent">
        <div className="portfolio-opacity-layer" />
        <div className="portfolio-main-content">
          {hasCurr || hasPrev || isAtEnd ? null : (
            <>
              <TopLevelContainer
                pages={pages}
                images={images}
                videos={videos}
                pdfs={pdfs}
                contentDataChoice={contentDataChoice}
                prepareCurrentGalleryContent={prepareCurrentGalleryContent}
                onNavClick={onNavClick}
                isAtEnd={isAtEnd}
                setCurrentNavItem={setCurrentNavItem}
                prevNavItem={prevNavItem}
                setContentPageId={setContentPageId}
                currentNavItem={isAtEnd ? prevNavItem : currentNavItem}
                chosenPortfolio={chosenPortfolio}
                onNavClick2={isAtEnd ? undefined : onNavClick}
                getColor={getColor}
                videoSelected={isAtEnd ? false : true}
                setHasCurr={setHasCurr}
                setHasPrev={setHasPrev}
                Size={Size}
                transitionedToSubLevel={transitionedToSubLevel}
                hasCurr={hasCurr}
                hasPrev={hasPrev}
                onBackClick={onBackClick}
                setSavedContentPageId={setSavedContentPageId}
                twolevelTerminus={twolevelTerminus}
                setTwolevelTerminus={setTwolevelTerminus}
                setPrevLevel={setPrevLevel}
                setTopicArray={SetTopicArray}
                backTopicArray={BackTopicArray}
                LevelOfTerm={LevelOfTerm}
              />
            </>
          )}
          {(hasCurr || hasPrev) && !isAtEnd && (
            <>
              <SubLevelContainer
                divName={'portfolio-big-nav-prev-container'}
                currentNavItem={isAtEnd ? prevNavItem : currentNavItem}
                chosenPortfolio={chosenPortfolio}
                pages={pages}
                images={images}
                videos={videos}
                pdfs={pdfs}
                contentDataChoice={contentDataChoice}
                prepareCurrentGalleryContent={prepareCurrentGalleryContent}
                onNavClick={isAtEnd ? undefined : onNavClick}
                getColor={getColor}
                videoSelected={isAtEnd ? false : true}
                isAtEnd={isAtEnd}
                setHasCurr={setHasCurr}
                setHasPrev={setHasPrev}
                Size={Size}
                transitionedToSubLevel={transitionedToSubLevel}
              />
              {hasCurr || hasPrev || isAtEnd ? (
                <div className="back-button" onClick={() => onBackClick()}>
                  <ArrowBackRounded />
                </div>
              ) : null}
              {hasCurr || hasPrev || isAtEnd ? (
                <div className="home-button" onClick={() => returnTopLevel()}>
                  <HomeRoundedIcon />
                </div>
              ) : null}
            </>
          )}
          {isAtEnd && (
            <Fragment>
              {hasCurr || hasPrev || isAtEnd ? (
                <div className="back-button" onClick={() => onBackClick()}>
                  <ArrowBackRounded />
                </div>
              ) : null}
              {hasCurr || hasPrev || isAtEnd ? (
                <div className="home-button" onClick={() => returnTopLevel()}>
                  <HomeRoundedIcon />
                </div>
              ) : null}
              <div className="portfolio-terminus-nav-container">
                {currentNavItem.children && currentNavItem.children.length > 0 && (
                  <>
                    {currentNavItem.children.map((child, i) => {
                      let foundPage = undefined;

                      try {
                        foundPage = pages[child.referenceId];
                      } catch (err) {
                        console.error('Error', err);
                      }

                      return (
                        <div
                          key={`k` + i}
                          className="terminus-level-thumb"
                          onClick={(e) => {
                            e.stopPropagation();
                            //onsole.log(`SETTING TOPIC ARRAY: ${child.title}, ${megamenuLevel}, ${child.referenceId}`);
                            SetTopicArray(
                              child.title,
                              megamenuLevel,
                              child.referenceId
                            );
                            setSavedContentPageId(child.referenceId);
                            setShowTermChild(true);
                            // only update if different
                            if (chosenContentPageId !== child.referenceId) {
                              setContentPageId(child.referenceId);
                            }
                          }}
                          // style={{
                          //   border: clickedTerminusId === child?.referenceId && chosenPortfolio?.active_terminal_button
                          //     ? `3px solid ${chosenPortfolio.active_terminal_button}`
                          //     : clickedTerminusId === child?.referenceId && !chosenPortfolio?.active_terminal_button
                          //       ? '3px solid #449FF5'
                          //       : '3px solid transparent'
                          // }}
                        >
                          <TerminusLevelThumb
                            contentType={foundPage._type}
                            contents={contentData()}
                            foundPage={foundPage}
                            images={images}
                            videos={videos}
                            pdfs={pdfs}
                            prepareCurrentGalleryContent={
                              prepareCurrentGalleryContent
                            }
                            isTopLevel={false}
                            isAtEnd={isAtEnd}
                            onBackClick={null}
                            clickedTerminusId={clickedTerminusId}
                            isAtTerm={true}
                            child={child}
                            chosenPortfolio={chosenPortfolio}
                          />
                        </div>
                      );
                    })}
                  </>
                )}
                <div
                  className={`portfolio-content-container-background ${
                    showTermChild ? 'terminus' : 'submenu'
                  }`}
                  onClick={() => {
                    setShowTermChild(false);
                    if (
                      !currentNavItem?.children ||
                      !currentNavItem?.children?.length
                    ) {
                      onBackClick();
                    }
                  }}
                />
                <div
                  className={`portfolio-content-container ${
                    showTermChild ? 'terminus' : 'submenu'
                  }`}
                  // onClick={onBackClick}
                >
                  <div className="portfolio-title-wrapper">
                    <div className="title-box">
                      <p>{pages[chosenContentPageId].title}</p>
                    </div>
                    <div className="close-button">
                      <CancelRoundedIcon
                        onClick={() => {
                          setShowTermChild(false);
                          if (
                            !currentNavItem?.children ||
                            !currentNavItem?.children?.length
                          ) {
                            onBackClick();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className={`portfolio-content-wrapper ${
                      currentNavItem.children && currentNavItem.children.length
                        ? 'active'
                        : 'hidden'
                    }`}
                    style={{ visibility: 'visible' }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {chosenContentPageId !== 0 &&
                      pages[chosenContentPageId]?._type !== false && (
                        <PortfolioContent
                          chosenPortfolio={chosenPortfolio}
                          contentType={pages[chosenContentPageId]?._type}
                          contents={contentData()}
                          page={pages[chosenContentPageId]}
                          images={images}
                          videos={videos}
                          pdfs={pdfs}
                          prepareCurrentGalleryContent={
                            prepareCurrentGalleryContent
                          }
                          isTopLevel={false}
                          isAtEnd={isAtEnd}
                          isAtTerm={true}
                          Size={Size}
                          maxed={
                            !currentNavItem.children ||
                            !currentNavItem.children.length
                              ? true
                              : false
                          }
                        />
                      )}
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
      <div
        className="portfolio-footer"
        style={{
          backgroundColor: chosenPortfolio?.primary_color
            ? chosenPortfolio.primary_color
            : '#EEEEEE',
          zIndex: showTermChild ? 0 : 1
          // boxShadow: chosenPortfolio?.accent_color ? `0px -1px 3px -1px ${chosenPortfolio.accent_color}80` : '0px -1px 3px -1px #3b3b3b80'
        }}
      >
        <BottomRightToolbar
          sendClick={sendClick}
          shareClick={shareClick}
          currentContent={currentContent}
          setCurrentNavItem={setCurrentNavItem}
          transitionedToTopLevel={transitionedToTopLevel}
        />
        <CatalogNotepad updateSessionWithNotes={updateSessionWithNotes} />
        <PIIModal />
        <ScriptNote />
        <MasterMenu
          currentContent={currentContent}
          setCurrentNavItem={setCurrentNavItem}
          transitionedToTopLevel={transitionedToTopLevel}
        />
      </div>
    </div>
  );
};

export default PortfolioContentParent11;
