import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import * as V from 'victory';
// import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { useFirestore } from 'react-redux-firebase';
import { createBarData } from '../../../utils/chartHelper';
import filterStandardDeviation from '../../../utils/filterStandardDeviation';
import DateSelect from '../DateSelect/index.js';
import DateSelectEfficiency from '../DateSelectEfficiency/index.js';
import SessionSelect from '../SessionSelect/index.js';
import ParallelSelect from '../ParallelSelect/index.js';
// import DateRangeRoundedIcon from '@material-ui/icons/DateRangeRounded';
import * as Scale from '../../../utils/Scalars';

const StretchContainer = styled.div`
  grid-column: 2 / span 1;

  @media (max-width: 1380px) {
    grid-column: 1 / span 3;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const CenterContainer = styled.div`
  width: 100%;
  height: -webkit-fill-available;
  display: grid;
  grid-template-rows: 150px 1fr;

  .top-center-container {
    grid-row: 1 / span 1;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;

    .top-box {
      display: grid;
      grid-template-rows: 1fr 1fr 1fr;

      .split {
        display: flex;
        justify-content: center;
        align-items: center;

        .togglebar {
          height: 23px;
          width: 50px;
          margin-left: 10px;
          border-radius: 12px;
          box-shadow: 1px 1px 3px #808080;
          cursor: pointer;
          position: relative;

          .toggle-circle {
            height: 75%;
            width: 35%;
            background-color: white;
            border-radius: 50%;
            box-shadow: 1px 1px 2px #3b3b3b;
            position: absolute;
            top: 12%;
            transition: all 0.2s ease;
          }
        }

        .legend {
          height: 23px;
          width: 150px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
          cursor: default;
          transition: all 0.2s ease;
          box-shadow: 1px 1px 3px #808080;

          p {
            margin-block-start: 0px;
            margin-block-end: 0px;
            font-weight: 600;
            font-size: 0.8em;
            color: white;
          }
        }

        .time-tab {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 23px;
          width: 60%;
          background-color: #808080;
          border-radius: 5px;
          overflow: hidden;
          cursor: default;

          p {
            margin-block-start: 0px;
            margin-block-end: 0px;
            font-weight: 600;
            font-size: 0.8em;
            color: white;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
`;

const Main = styled.div`
  grid-row: 2 / span 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  transition: 0.8s all ease;
  overflow-x: auto;
  overflow-y: hidden;

  .chart-container {
    width: 100%;
    height: 100%;
    background-color: white;
    cursor: move;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }
`;

const Efficiency = ({
  Dash,
  SetSessionMasterCollection,
  aggregateSelected,
  selectedPortfolioId,
  defaultVisit,
  efficiencyAggregateSelected,
  userToggle,
  companyToggle,
  pastToggle,
  parallelToggle,
  setEfficiencyUserToggle,
  setEfficiencyCompanyToggle,
  setEfficiencyPastToggle,
  setEfficiencyParallelToggle,
  parallelSession,
  secondaryUserSessionToggle,
  secondaryUserAggregateToggle,
  setSecondaryUserSessionToggle,
  setSecondaryUserAggregateToggle,
  setDefaultVisit,
  setAggregateSelected,
  sessionList,
  setSessionDateString,
  selectedUser,
  Size
}) => {
  const [animate, setAnimate] = useState(false);
  const [uniqueLabels, setUniqueLabels] = useState([]);
  const [finalLabels, setFinalLabels] = useState([]);
  const [totalTimePerPageFormatted, setTotalTimePerPageFormatted] = useState(
    []
  );
  const [
    companyAverageTimePerPageFormatted,
    setCompanyAverageTimePerPageFormatted
  ] = useState([]);
  // const [userPastAverageTimePerPageFormatted, setUserPastAverageTimePerPageFormatted] = useState([]);
  const [parallelSessionFormatted, setParallelSessionFormatted] = useState([]);
  const [userTimeString, setUserTimeString] = useState('0s');
  const [companyTimeString, setCompanyTimeString] = useState('0s');
  const [parallelSessionTimeString, setParallelSessionTimeString] =
    useState('0s');
  const [maxRange, setMaxRange] = useState(10);
  // const [userPastDate, setUserPastDate] = useState(null);
  const [userTotalTimePerVisit, setUserTotalTimePerVisit] = useState(0);
  const [companyTotalTimePerVisit, setCompanyTotalTimePerVisit] = useState(0);
  const [
    parallelSessionTotalTimePerVisit,
    setParallelSessionTotalTimePerVisit
  ] = useState(0);
  const [latestSession, setLatestSession] = useState(undefined);
  const [latestSessionString, setLatestSessionString] = useState('');
  const [zoomDomain, setZoomDomain] = useState({ x: [0, 12] });

  //const [brushDomain, setBrushDomain] = useState({x: [0, 12]});

  useEffect(() => {
    setTimeout(() => {
      setAnimate(true);
    }, 50);
  });

  const firestore = useFirestore();

  useEffect(() => {
    //////onsole.log("!!!DASH SESSION MASTER!!!", Dash.SessionMasterCollection)
    if (
      Dash.SessionMasterCollection === null ||
      Dash.SessionMasterCollection === undefined
    ) {
      return;
    }

    if (Dash.SessionMasterCollection.length === 0) {
      SetSessionMasterCollection(selectedUser, firestore);
    }
  }, [
    Dash.SessionMasterCollection,
    SetSessionMasterCollection,
    firestore,
    selectedUser
  ]);

  // resets list of chart labels on portfolio change
  useEffect(() => {
    if (
      Dash.SessionMasterCollection === null ||
      Dash.SessionMasterCollection === undefined ||
      Dash.SessionMasterCollection.length === 0
    ) {
      return;
    }

    if (selectedPortfolioId) {
      setUniqueLabels([]);
    }
  }, [Dash.SessionMasterCollection, selectedPortfolioId]);

  // sets value of most recent session (this is only used for the userToggle button, to set the default session if secondaryUserSessionToggle is off and then the session button toggle is clicked)
  useEffect(() => {
    if (
      Dash.SessionMasterCollection === null ||
      Dash.SessionMasterCollection === undefined ||
      Dash.SessionMasterCollection.length === 0 ||
      !sessionList.length
    ) {
      return;
    }

    let session = sessionList[0];
    let infoString = `${session.customer_company} - ${session.customer_name}`;

    setLatestSession(session);
    setLatestSessionString(infoString);
  }, [Dash.SessionMasterCollection, sessionList]);

  // sets data for a change in session - this will change the user bar (blue) to show session times per page
  useEffect(() => {
    if (
      Dash.SessionMasterCollection === null ||
      Dash.SessionMasterCollection === undefined ||
      defaultVisit === undefined
    ) {
      return;
    }

    if (Dash.SessionMasterCollection.length !== 0) {
      //////onsole.log("!!!TRIGGERING SESSION CHOICE!!!", defaultVisit);
      let obj = createBarData(defaultVisit.page_visited);
      //////onsole.log("CHART DATA FOR DEFAULT VISIT", obj);
      if (
        (obj?.totalTimeSpendDatas?.length === 1 &&
          obj?.totalTimeSpendDatas[0]?.name === 'Splash') ||
        !obj?.totalTimeSpendDatas?.length
      ) {
        setTotalTimePerPageFormatted([{ x: ' ', y: 0 }]);
        setUserTotalTimePerVisit(0);
        return;
      }
      setUniqueLabels(obj.uniquePageLabels);
      // let labelList = [];
      let arr = [];
      let count = 0;
      for (let page of obj.totalTimeSpendDatas) {
        if (page.name !== 'Splash') {
          let data = { x: page.name, y: page.y, label: `${page.y}s` };
          count += page.y;
          arr.push(data);
        }
      }
      setUserTotalTimePerVisit(Math.round(count));
      // //////onsole.log("INITIAL CHART TIME DATA FOR DEFAULT VISIT", arr);
      setTotalTimePerPageFormatted(arr);
    }
  }, [Dash.SessionMasterCollection, defaultVisit, userToggle]);

  // sets data for a change in parallel session - this will change the parallel bar (black) to show session times per page
  useEffect(() => {
    if (
      Dash.SessionMasterCollection === null ||
      Dash.SessionMasterCollection === undefined ||
      parallelSession === undefined
    ) {
      return;
    }

    if (Dash.SessionMasterCollection.length !== 0) {
      //////onsole.log("!!!TRIGGERING PARALLEL SESSION CHOICE!!!");
      let obj = createBarData(parallelSession.page_visited);
      // //////onsole.log("CHART DATA FOR DEFAULT VISIT", obj);
      setUniqueLabels(obj.uniquePageLabels);
      // let labelList = [];
      let arr = [];
      let count = 0;
      for (let page of obj.totalTimeSpendDatas) {
        if (page.name !== 'Splash') {
          let data = { x: page.name, y: page.y, label: `${page.y}s` };
          count += page.y;
          arr.push(data);
        }
      }
      setParallelSessionTotalTimePerVisit(Math.round(count));
      // //////onsole.log("INITIAL CHART TIME DATA FOR DEFAULT VISIT", arr);
      setParallelSessionFormatted(arr);
    }
  }, [Dash.SessionMasterCollection, parallelSession, parallelToggle]);

  // sets data for a change in parallel session - this will change the parallel bar (black) to show session times per page
  useEffect(() => {
    if (
      Dash.SessionMasterCollection === null ||
      Dash.SessionMasterCollection === undefined
    ) {
      return;
    }

    if (parallelSession === undefined) {
      setParallelSessionFormatted([]);
      setParallelSessionTotalTimePerVisit(0);
      return;
    }

    if (Dash.SessionMasterCollection.length !== 0) {
      //////onsole.log("!!!TRIGGERING PARALLEL SESSION CHOICE!!!");
      let obj = createBarData(parallelSession.page_visited);
      // //////onsole.log("CHART DATA FOR DEFAULT VISIT", obj);
      setUniqueLabels(obj.uniquePageLabels);
      // let labelList = [];
      let arr = [];
      let count = 0;
      for (let page of obj.totalTimeSpendDatas) {
        if (page.name !== 'Splash') {
          let data = { x: page.name, y: page.y, label: `${page.y}s` };
          count += page.y;
          arr.push(data);
        }
      }
      setParallelSessionTotalTimePerVisit(Math.round(count));
      // //////onsole.log("INITIAL CHART TIME DATA FOR DEFAULT VISIT", arr);
      setParallelSessionFormatted(arr);
    }
  }, [Dash.SessionMasterCollection, parallelSession]);

  const createAverageUseEffect = useCallback(
    (filteredSessions, type, isAggregate) => {
      let arr = [];
      for (let session of filteredSessions) {
        let obj = createBarData(session.page_visited);
        arr.push(obj);
      }
      let allPagesArr = [];
      // //////onsole.log("CHART DATA FOR FILTERED VISITS - CREATEAVERAGE USEEFFECT: ", arr);

      for (let visit of arr) {
        for (let page of visit.totalTimeSpendDatas) {
          if (page.name !== 'Splash') {
            allPagesArr.push(page);
          }
        }
      }
      // //////onsole.log("CHART DATA FOR ALL PAGES EVER VISITED IN THIS RANGE", allPagesArr);

      let pagesFilteredBySD = filterStandardDeviation(allPagesArr);
      //////onsole.log("ALL PAGES FILTERED BY STANDARD DEVIATION", pagesFilteredBySD);

      for (let page of pagesFilteredBySD) {
        let existingPages = uniqueLabels;
        if (existingPages.includes(page.name)) {
          continue;
        } else {
          existingPages.push(page.name);
          setUniqueLabels(existingPages);
        }
      }

      let pagesVisitedArr = [];
      let unaverageArr = [];
      for (let pageVisit of pagesFilteredBySD) {
        if (pagesVisitedArr.includes(pageVisit.name)) {
          let found = unaverageArr.find((page) => pageVisit.name === page.x);
          let newData = {
            x: pageVisit.name,
            y: pageVisit.y + found.y,
            label: `${pageVisit.y + found.y}s`
          };
          let index = unaverageArr.findIndex(
            (page) => pageVisit.name === page.x
          );
          unaverageArr.splice(index, 1, newData);
        } else {
          pagesVisitedArr.push(pageVisit.name);
          let data = {
            x: pageVisit.name,
            y: pageVisit.y,
            label: `${pageVisit.y}s`
          };
          unaverageArr.push(data);
        }
      }

      let averageArr = [];
      for (let i = 0; i < unaverageArr.length; i++) {
        let averagedData = {
          x: unaverageArr[i].x,
          y: Math.ceil(unaverageArr[i].y / filteredSessions.length),
          label: `${Math.ceil(unaverageArr[i].y / filteredSessions.length)}s`
        };
        averageArr.push(averagedData);
      }
      ////////onsole.log("FORMATTED, FINAL DATA FOR CHART ", averageArr);
      if (type === 'user') {
        if (!averageArr.length) {
          // if no data for this bar, set a placeholder so that it is not entirely removed from the chart
          setTotalTimePerPageFormatted([{ x: ' ', y: 0 }]);
        } else {
          setTotalTimePerPageFormatted(averageArr);
        }
        if (isAggregate && unaverageArr.length) {
          let count = 0;
          for (let page of unaverageArr) {
            count += page.y;
          }
          let averagePerSession = count / filteredSessions.length;
          setUserTotalTimePerVisit(Math.round(averagePerSession));
        } else {
          setUserTotalTimePerVisit(0);
        }
      } else if (type === 'company') {
        if (!averageArr.length) {
          // if no data for this bar, set a placeholder so that it is not entirely removed from the chart
          setCompanyAverageTimePerPageFormatted([{ x: ' ', y: 0 }]);
        } else {
          setCompanyAverageTimePerPageFormatted(averageArr);
        }
        if (isAggregate && unaverageArr.length) {
          //////onsole.log("UNAVERAGE ARR", unaverageArr);
          let count = 0;
          for (let page of unaverageArr) {
            count += page.y;
          }
          let averagePerSession = count / filteredSessions.length;
          setCompanyTotalTimePerVisit(Math.round(averagePerSession));
        } else {
          //////onsole.log("NO UNAVERAGE ARR");
          setCompanyTotalTimePerVisit(0);
        }
      } else {
        return;
      }
    },
    [uniqueLabels]
  );

  // sets default view to 1m
  useEffect(() => {
    if (
      Dash.SessionMasterCollection === null ||
      Dash.SessionMasterCollection === undefined ||
      aggregateSelected !== '1m' ||
      defaultVisit !== undefined
    ) {
      return;
    } else {
      //////onsole.log("SETTING DEFAULT 30 DAY AGGREGATE");
      let today = new Date();
      let newDate = new Date();
      newDate.setDate(today.getDate() - 30);

      let idFilteredPorts = Dash.SessionMasterCollection.filter(
        (port) => port.portfolio_id === selectedPortfolioId
      );
      let userFilteredPorts = idFilteredPorts.filter(
        (port) => port.user_id === selectedUser[0].id
      );

      let dateFilteredPortsUsers = userFilteredPorts.filter((port) => {
        return (
          port.session_date.seconds * 1000 > newDate.getTime() &&
          port.session_date.seconds * 1000 < today.getTime()
        );
      });
      let dateFilteredPortsCompany = idFilteredPorts.filter((port) => {
        return (
          port.session_date.seconds * 1000 > newDate.getTime() &&
          port.session_date.seconds * 1000 < today.getTime()
        );
      });

      createAverageUseEffect(dateFilteredPortsUsers, 'user', true);
      createAverageUseEffect(dateFilteredPortsCompany, 'company', true);
    }
  }, [
    Dash.SessionMasterCollection,
    selectedUser,
    createAverageUseEffect,
    selectedPortfolioId,
    aggregateSelected,
    defaultVisit
  ]);

  // updates the current user bar data on aggregate change
  useEffect(() => {
    if (
      Dash.SessionMasterCollection === null ||
      Dash.SessionMasterCollection === undefined ||
      aggregateSelected === 0
    ) {
      return;
    } else {
      let today = new Date();
      let newDate = new Date();
      //////onsole.log("SELECTED TIME FRAME: ", aggregateSelected);
      if (aggregateSelected === '1w') {
        newDate.setDate(today.getDate() - 7);
      } else if (aggregateSelected === '2w') {
        newDate.setDate(today.getDate() - 14);
      } else if (aggregateSelected === '1m') {
        newDate.setDate(today.getDate() - 30);
      } else if (aggregateSelected === '3m') {
        newDate.setDate(today.getDate() - 90);
      } else if (aggregateSelected === '6m') {
        newDate.setDate(today.getDate() - 180);
      } else if (aggregateSelected === '1d') {
        newDate = new Date(
          new Date(
            new Date(new Date(new Date(new Date())).setSeconds(0)).setMinutes(0)
          ).setHours(0)
        );
      } else {
        return;
      }

      let idFilteredPorts = Dash.SessionMasterCollection.filter(
        (port) => port.portfolio_id === selectedPortfolioId
      );
      let userFilteredPorts = idFilteredPorts.filter(
        (port) => port.user_id === selectedUser[0].id
      );

      let dateFilteredPortsUsers = userFilteredPorts.filter((port) => {
        return (
          port.session_date.seconds * 1000 > newDate.getTime() &&
          port.session_date.seconds * 1000 < today.getTime()
        );
      });

      ////////onsole.log("DATE FILTERED VISITS FOR CHOSEN USER: ", dateFilteredPortsUsers);
      createAverageUseEffect(dateFilteredPortsUsers, 'user', true);
    }
  }, [
    Dash.SessionMasterCollection,
    aggregateSelected,
    selectedUser,
    createAverageUseEffect,
    selectedPortfolioId
  ]);

  // updates the current company bar data on aggregate change
  useEffect(() => {
    if (
      Dash.SessionMasterCollection === null ||
      Dash.SessionMasterCollection === undefined ||
      efficiencyAggregateSelected === 0 ||
      efficiencyAggregateSelected === '0'
    ) {
      return;
    } else {
      let today = new Date();
      let newDate = new Date();
      //////onsole.log("SELECTED TIME FRAME: ", efficiencyAggregateSelected);
      if (efficiencyAggregateSelected === '1w') {
        newDate.setDate(today.getDate() - 7);
      } else if (efficiencyAggregateSelected === '2w') {
        newDate.setDate(today.getDate() - 14);
      } else if (efficiencyAggregateSelected === '1m') {
        newDate.setDate(today.getDate() - 30);
      } else if (efficiencyAggregateSelected === '3m') {
        newDate.setDate(today.getDate() - 90);
      } else if (efficiencyAggregateSelected === '6m') {
        newDate.setDate(today.getDate() - 180);
      } else if (efficiencyAggregateSelected === '1d') {
        newDate = new Date(
          new Date(
            new Date(new Date(new Date(new Date())).setSeconds(0)).setMinutes(0)
          ).setHours(0)
        );
      } else {
        return;
      }

      let idFilteredPorts = Dash.SessionMasterCollection.filter(
        (port) => port.portfolio_id === selectedPortfolioId
      );
      //////onsole.log("SELECTED PORTFOLIO ID", selectedPortfolioId);

      let dateFilteredPortsCompany = idFilteredPorts.filter((port) => {
        return (
          port.session_date.seconds * 1000 > newDate.getTime() &&
          port.session_date.seconds * 1000 < today.getTime()
        );
      });

      ////////onsole.log("DATE FILTERED VISITS FOR WHOLE COMPANY: ", dateFilteredPortsCompany);
      createAverageUseEffect(dateFilteredPortsCompany, 'company', true);
    }
  }, [
    Dash.SessionMasterCollection,
    efficiencyAggregateSelected,
    createAverageUseEffect,
    selectedPortfolioId
  ]);

  // pairs down final label usage and remove title labels (x-axis) with no y value + updates max range + formats the time string for the data tooltips
  useEffect(() => {
    if (
      Dash.SessionMasterCollection === null ||
      Dash.SessionMasterCollection === undefined ||
      !uniqueLabels.length
    ) {
      return;
    }

    let usedLabels = [];
    let maxRangeValue = 10;

    if (companyAverageTimePerPageFormatted.length) {
      for (let page of companyAverageTimePerPageFormatted) {
        // setting labels
        let foundLabel = uniqueLabels.find(
          (label) => label === page.x && page.y > 0
        );
        if (foundLabel !== undefined) {
          usedLabels.push(foundLabel);
        }

        // update max range
        if (page.y > maxRangeValue) {
          maxRangeValue = page.y;
        }

        // format time string
        if (page.y >= 3600) {
          page.label = `${Math.floor(page.y / 3600)}hr ${Math.floor(
            (page.y / 60) % 60
          )}m ${Math.round(page.y % 60).toString()}s`;
        } else if (page.y >= 60 && page.y < 3600) {
          page.label = `${Math.floor(page.y / 60)}m ${Math.round(
            page.y % 60
          ).toString()}s`;
        } else {
          page.label = `${Math.round(page.y % 60).toString()}s`;
        }
      }
    }

    if (totalTimePerPageFormatted.length) {
      for (let page of totalTimePerPageFormatted) {
        // setting labels
        let foundLabel = uniqueLabels.find(
          (label) => label === page.x && page.y > 0
        );
        if (foundLabel !== undefined && !usedLabels.includes(foundLabel)) {
          usedLabels.push(foundLabel);
        }

        // update max range
        if (page.y > maxRangeValue) {
          maxRangeValue = page.y;
        }

        // format time string
        if (page.y >= 3600) {
          page.label = `${Math.floor(page.y / 3600)}hr ${Math.floor(
            Math.floor((page.y / 60) % 60)
          )}m ${Math.round(page.y % 60).toString()}s`;
        } else if (page.y >= 60 && page.y < 3600) {
          page.label = `${Math.floor(page.y / 60)}m ${Math.round(
            page.y % 60
          ).toString()}s`;
        } else {
          page.label = `${Math.round(page.y % 60).toString()}s`;
        }
      }
    }

    // if (userPastAverageTimePerPageFormatted.length) {
    //     for (let page of userPastAverageTimePerPageFormatted) {

    //         // setting labels
    //         let foundLabel = uniqueLabels.find((label) => (label === page.x) && (page.y > 0));
    //         if (foundLabel !== undefined && (!usedLabels.includes(foundLabel))) {
    //             usedLabels.push(foundLabel);
    //         }

    //         // update max range
    //         if (page.y > maxRangeValue) {
    //             maxRangeValue = page.y
    //         }

    //         // format time string for tooltip
    //         if (page.y >= 3600) {
    //             page.label = `${Math.floor(page.y / 3600)}hr ${Math.floor(page.y / 60)}m ${Math.round(page.y % 60).toString()}s`
    //         } else if (page.y >= 60 && page.y < 3600) {
    //             page.label = `${Math.floor(page.y / 60)}m ${Math.round(page.y % 60).toString()}s`
    //         } else {
    //             page.label = `${Math.round(page.y % 60).toString()}s`
    //         }
    //     }
    // }

    if (parallelSessionFormatted.length) {
      for (let page of parallelSessionFormatted) {
        // setting labels
        let foundLabel = uniqueLabels.find(
          (label) => label === page.x && page.y > 0
        );
        if (foundLabel !== undefined && !usedLabels.includes(foundLabel)) {
          usedLabels.push(foundLabel);
        }

        // update max range
        if (page.y > maxRangeValue) {
          maxRangeValue = page.y;
        }

        // format time string for tooltip
        if (page.y >= 3600) {
          page.label = `${Math.floor(page.y / 3600)}hr ${Math.floor(
            (page.y / 60) % 60
          )}m ${Math.round(page.y % 60).toString()}s`;
        } else if (page.y >= 60 && page.y < 3600) {
          page.label = `${Math.floor(page.y / 60)}m ${Math.round(
            page.y % 60
          ).toString()}s`;
        } else {
          page.label = `${Math.round(page.y % 60).toString()}s`;
        }
      }
    }

    setFinalLabels(usedLabels);
    setMaxRange(maxRangeValue);
  }, [
    Dash.SessionMasterCollection,
    companyAverageTimePerPageFormatted,
    totalTimePerPageFormatted,
    parallelSessionFormatted,
    uniqueLabels
  ]); // userPastAverageTimePerPageFormatted

  // sets the time string for use with average user time per visit
  useEffect(() => {
    if (
      Dash.SessionMasterCollection === null ||
      Dash.SessionMasterCollection === undefined ||
      Dash.SessionMasterCollection.length === 0
    ) {
      return;
    }

    if (userTotalTimePerVisit === 0) {
      setUserTimeString(`0s`);
      return;
    }

    let hours = Math.floor(userTotalTimePerVisit / 3600);
    let minutes = Math.floor((userTotalTimePerVisit / 60) % 60);
    let seconds = userTotalTimePerVisit % 60;
    // //////onsole.log(`Average time per visit within range- USER: ${hours} hours, ${minutes} minutes, ${seconds} seconds`);

    if (hours > 0) {
      let string = `${hours}hr ${minutes}m ${seconds}s`;
      setUserTimeString(string);
      return;
    } else if (minutes > 0) {
      let string = `${minutes}m ${seconds}s`;
      setUserTimeString(string);
      return;
    } else if (seconds > 0) {
      let string = `${seconds}s`;
      setUserTimeString(string);
      return;
    } else {
      //////onsole.log("NO AVERAGE TIME AVAILABLE FOR THIS RANGE");
      let string = `0s`;
      setUserTimeString(string);
      return;
    }
  }, [Dash.SessionMasterCollection, userTotalTimePerVisit]);

  // sets the time string for use with average company time per visit
  useEffect(() => {
    if (
      Dash.SessionMasterCollection === null ||
      Dash.SessionMasterCollection === undefined ||
      Dash.SessionMasterCollection.length === 0
    ) {
      return;
    }

    if (companyTotalTimePerVisit === 0) {
      setCompanyTimeString(`0s`);
      return;
    }

    let hours = Math.floor(companyTotalTimePerVisit / 3600);
    let minutes = Math.floor((companyTotalTimePerVisit / 60) % 60);
    let seconds = companyTotalTimePerVisit % 60;
    // //////onsole.log(`Average time per visit within range - COMPANY: ${hours} hours, ${minutes} minutes, ${seconds} seconds`);

    if (hours > 0) {
      let string = `${hours}hr ${minutes}m ${seconds}s`;
      setCompanyTimeString(string);
      return;
    } else if (minutes > 0) {
      let string = `${minutes}m ${seconds}s`;
      setCompanyTimeString(string);
      return;
    } else if (seconds > 0) {
      let string = `${seconds}s`;
      setCompanyTimeString(string);
      return;
    } else {
      //////onsole.log("NO AVERAGE TIME AVAILABLE FOR THIS RANGE");
      let string = `0s`;
      setCompanyTimeString(string);
      return;
    }
  }, [Dash.SessionMasterCollection, companyTotalTimePerVisit]);

  // sets the parallel session time string
  useEffect(() => {
    if (
      Dash.SessionMasterCollection === null ||
      Dash.SessionMasterCollection === undefined ||
      Dash.SessionMasterCollection.length === 0
    ) {
      return;
    }

    let hours = Math.floor(parallelSessionTotalTimePerVisit / 3600);
    let minutes = Math.floor((parallelSessionTotalTimePerVisit / 60) % 60);
    let seconds = parallelSessionTotalTimePerVisit % 60;
    // //////onsole.log(`Average time per visit within range - COMPANY: ${hours} hours, ${minutes} minutes, ${seconds} seconds`);

    if (hours > 0) {
      let string = `${hours}hr ${minutes}m ${seconds}s`;
      setParallelSessionTimeString(string);
      return;
    } else if (minutes > 0) {
      let string = `${minutes}m ${seconds}s`;
      setParallelSessionTimeString(string);
      return;
    } else if (seconds > 0) {
      let string = `${seconds}s`;
      setParallelSessionTimeString(string);
      return;
    } else {
      //////onsole.log("NO AVERAGE TIME AVAILABLE FOR THIS RANGE");
      let string = `0s`;
      setParallelSessionTimeString(string);
      return;
    }
  }, [Dash.SessionMasterCollection, parallelSessionTotalTimePerVisit]);

  // const createAverage = (filteredSessions) => {
  //     let arr = [];
  //         for (let port of filteredSessions) {
  //             let obj = createBarData(port.page_visited);
  //             arr.push(obj);
  //         }
  //         let allPagesArr = [];

  //         for (let visit of arr) {
  //             for (let page of visit.totalTimeSpendDatas) {
  //                 if (page.name !== "Splash") {
  //                     allPagesArr.push(page);
  //                 }
  //             }
  //         }
  //         // //////onsole.log("ALL PAGES DATA FOR FILTERED VISITS - PAST DATE: ", allPagesArr);

  //         let pagesFilteredBySD = filterStandardDeviation(allPagesArr);
  //         // //////onsole.log("CHART DATA FOR FILTERED VISITS PAST AFTER SD FILTER: ", pagesFilteredBySD);

  //         for (let page of pagesFilteredBySD) {
  //             let existingPages = uniqueLabels;
  //             if (existingPages.includes(page.name)) {
  //                 continue;
  //             } else {
  //                 existingPages.push(page.name);
  //                 setUniqueLabels(existingPages);
  //             }
  //         }

  //         let pagesVisitedArr = [];
  //         let unaverageArr = [];
  //         for (let pageVisit of pagesFilteredBySD) {
  //             if (pagesVisitedArr.includes(pageVisit.name)) {
  //                 let found = unaverageArr.find(page => pageVisit.name === page.x);
  //                 let newData = {x: pageVisit.name, y: pageVisit.y + found.y, label: `${pageVisit.y + found.y}s`};
  //                 let index = unaverageArr.findIndex(page => pageVisit.name === page.x);
  //                 unaverageArr.splice(index, 1, newData);
  //             } else {
  //                 pagesVisitedArr.push(pageVisit.name);
  //                 let data = {x: pageVisit.name, y: pageVisit.y, label: `${pageVisit.y}s`}
  //                 unaverageArr.push(data);
  //             }
  //         }

  //         let averageArr = [];
  //         for (let i = 0; i < unaverageArr.length; i++) {
  //             let averagedData = {x: unaverageArr[i].x, y: Math.ceil((unaverageArr[i].y / filteredSessions.length)), label: `${Math.ceil(unaverageArr[i].y / filteredSessions.length)}s`}
  //             averageArr.push(averagedData);
  //         }
  //         //////onsole.log("USER PAST FINAL DATA", averageArr);
  //         setUserPastAverageTimePerPageFormatted(averageArr);

  // }

  // const handleUserPastDateChange = (date) => {
  //     if (Dash.SessionMasterCollection === null || Dash.SessionMasterCollection === undefined || !Dash.SessionMasterCollection.length) {
  //         return;
  //     } else {
  //         setUserPastAverageTimePerPageFormatted([]);
  //         setEfficiencyPastToggle('on');
  //         let formattedDatePick = new Date(new Date(new Date(date.setSeconds(0)).setMinutes(0)).setHours(0));
  //         let idFilteredPorts = Dash.SessionMasterCollection.filter(port => port.portfolio_id === selectedPortfolioId);
  //         let userFilteredPorts = idFilteredPorts.filter(port => port.user_id === selectedUser[0].id);
  //         let dateFilteredPorts = userFilteredPorts.filter(port => {
  //             let formattedPortDate = new Date(new Date(new Date(new Date(new Date(port.session_date.seconds * 1000)).setSeconds(0)).setMinutes(0)).setHours(0));
  //             return `${formattedPortDate}` === `${formattedDatePick}`;
  //         });
  //         // //////onsole.log("VISITS FILTERED BY SELECTED DATE AND RESPECTIVE PORTFOLIO + USER ID: ", dateFilteredPorts);
  //         if (!dateFilteredPorts.length) {
  //             //////onsole.log("USER PAST- NO VISITS FOR THIS TIME PERIOD");
  //             setUserPastAverageTimePerPageFormatted([]);
  //             return;
  //         }
  //         createAverage(dateFilteredPorts);
  //     }
  // }

  const decideUserToggleSettings = (type) => {
    if (type === 'aggregate') {
      if (aggregateSelected !== '0') {
        let today = new Date();
        let newDate = new Date();
        if (aggregateSelected === '1w') {
          newDate.setDate(today.getDate() - 7);
        } else if (aggregateSelected === '2w') {
          newDate.setDate(today.getDate() - 14);
        } else if (aggregateSelected === '1m') {
          newDate.setDate(today.getDate() - 30);
        } else if (aggregateSelected === '3m') {
          newDate.setDate(today.getDate() - 90);
        } else if (aggregateSelected === '6m') {
          newDate.setDate(today.getDate() - 180);
        } else if (aggregateSelected === '1d') {
          newDate = new Date(
            new Date(
              new Date(new Date(new Date(new Date())).setSeconds(0)).setMinutes(
                0
              )
            ).setHours(0)
          );
        } else {
          return;
        }

        let idFilteredPorts = Dash.SessionMasterCollection.filter(
          (port) => port.portfolio_id === selectedPortfolioId
        );
        let userFilteredPorts = idFilteredPorts.filter(
          (port) => port.user_id === selectedUser[0].id
        );

        let filteredSessions = userFilteredPorts.filter((port) => {
          return (
            port.session_date.seconds * 1000 > newDate.getTime() &&
            port.session_date.seconds * 1000 < today.getTime()
          );
        });

        let arr = [];
        for (let port of filteredSessions) {
          let obj = createBarData(port.page_visited);
          arr.push(obj);
        }
        let allPagesArr = [];

        for (let visit of arr) {
          for (let page of visit.totalTimeSpendDatas) {
            if (page.name !== 'Splash') {
              allPagesArr.push(page);
            }
          }
        }

        let pagesFilteredBySD = filterStandardDeviation(allPagesArr);

        for (let page of pagesFilteredBySD) {
          let existingPages = uniqueLabels;
          if (existingPages.includes(page.name)) {
            continue;
          } else {
            existingPages.push(page.name);
            setUniqueLabels(existingPages);
          }
        }

        let pagesVisitedArr = [];
        let unaverageArr = [];
        for (let pageVisit of pagesFilteredBySD) {
          if (pagesVisitedArr.includes(pageVisit.name)) {
            let found = unaverageArr.find((page) => pageVisit.name === page.x);
            let newData = {
              x: pageVisit.name,
              y: pageVisit.y + found.y,
              label: `${pageVisit.y + found.y}s`
            };
            let index = unaverageArr.findIndex(
              (page) => pageVisit.name === page.x
            );
            unaverageArr.splice(index, 1, newData);
          } else {
            pagesVisitedArr.push(pageVisit.name);
            let data = {
              x: pageVisit.name,
              y: pageVisit.y,
              label: `${pageVisit.y}s`
            };
            unaverageArr.push(data);
          }
        }

        let averageArr = [];
        for (let i = 0; i < unaverageArr.length; i++) {
          let averagedData = {
            x: unaverageArr[i].x,
            y: Math.ceil(unaverageArr[i].y / filteredSessions.length),
            label: `${Math.ceil(unaverageArr[i].y / filteredSessions.length)}s`
          };
          averageArr.push(averagedData);
        }
        if (!averageArr.length) {
          setTotalTimePerPageFormatted([{ x: ' ', y: 0 }]);
        } else {
          setTotalTimePerPageFormatted(averageArr);
        }
      } else {
        setAggregateSelected('1m');
        setSecondaryUserSessionToggle('off');
        setSecondaryUserAggregateToggle('on');
        setDefaultVisit(undefined);
        setSessionDateString('Select a Portfolio Session');
      }
    } else if (type === 'session') {
      if (defaultVisit !== undefined) {
        let obj = createBarData(defaultVisit.page_visited);
        setUniqueLabels(obj.uniquePageLabels);
        let arr = [];
        // let count = 0;
        for (let page of obj.totalTimeSpendDatas) {
          if (page.name !== 'Splash') {
            let data = { x: page.name, y: page.y, label: `${page.y}s` };
            // count += page.y;
            arr.push(data);
          }
        }
        // let averageTime = Math.ceil(count / obj.uniquePageLabels.length);
        // setAverageTimePerPageOverall(averageTime);
        setTotalTimePerPageFormatted(arr);
      } else {
        setAggregateSelected('0');
        setSecondaryUserSessionToggle('on');
        setSecondaryUserAggregateToggle('off');
        setDefaultVisit(latestSession);
        setSessionDateString(latestSessionString);
      }
    }
  };

  const toggleUser = (type) => {
    if (
      Dash.SessionMasterCollection === null ||
      Dash.SessionMasterCollection === undefined ||
      !Dash.SessionMasterCollection.length
    ) {
      return;
    }

    if (userToggle === 'on') {
      if (
        (type === 'session' && secondaryUserSessionToggle === 'on') ||
        (type === 'aggregate' && secondaryUserAggregateToggle === 'on')
      ) {
        setEfficiencyUserToggle('off');
        setTotalTimePerPageFormatted([{ x: ' ', y: 0 }]);
      } else {
        if (type === 'aggregate') {
          decideUserToggleSettings('aggregate');
        } else if (type === 'session') {
          decideUserToggleSettings('session');
        } else {
          return;
        }
      }
    } else if (userToggle === 'off') {
      setEfficiencyUserToggle('on');
      if (type === 'aggregate') {
        decideUserToggleSettings('aggregate');
      } else if (type === 'session') {
        decideUserToggleSettings('session');
      } else {
        return;
      }
    } else {
      return;
    }
  };

  const toggleParallelSession = () => {
    if (
      Dash.SessionMasterCollection === null ||
      Dash.SessionMasterCollection === undefined ||
      !Dash.SessionMasterCollection.length
    ) {
      return;
    }

    if (parallelToggle === 'on') {
      setEfficiencyParallelToggle('off');
      setParallelSessionFormatted([{ x: ' ', y: 0 }]);
    } else if (parallelToggle === 'off') {
      setEfficiencyParallelToggle('on');
      if (parallelSession !== undefined) {
        let obj = createBarData(parallelSession.page_visited);
        // //////onsole.log("CHART DATA FOR DEFAULT VISIT", obj);
        setUniqueLabels(obj.uniquePageLabels);
        let arr = [];
        let count = 0;
        for (let page of obj.totalTimeSpendDatas) {
          if (page.name !== 'Splash') {
            let data = { x: page.name, y: page.y, label: `${page.y}s` };
            count += page.y;
            arr.push(data);
          }
        }
        setParallelSessionTotalTimePerVisit(Math.round(count));
        // //////onsole.log("INITIAL CHART TIME DATA FOR DEFAULT VISIT", arr);
        setParallelSessionFormatted(arr);
      } else {
        return;
      }
    } else {
      return;
    }
  };

  const toggleCompany = () => {
    if (
      Dash.SessionMasterCollection === null ||
      Dash.SessionMasterCollection === undefined ||
      !Dash.SessionMasterCollection.length
    ) {
      return;
    }

    if (companyToggle === 'on') {
      setEfficiencyCompanyToggle('off');
      setCompanyAverageTimePerPageFormatted([{ x: ' ', y: 0 }]);
    } else if (companyToggle === 'off') {
      setEfficiencyCompanyToggle('on');
      if (efficiencyAggregateSelected !== '0') {
        let today = new Date();
        let newDate = new Date();
        if (efficiencyAggregateSelected === '1w') {
          newDate.setDate(today.getDate() - 7);
        } else if (efficiencyAggregateSelected === '2w') {
          newDate.setDate(today.getDate() - 14);
        } else if (efficiencyAggregateSelected === '1m') {
          newDate.setDate(today.getDate() - 30);
        } else if (efficiencyAggregateSelected === '3m') {
          newDate.setDate(today.getDate() - 90);
        } else if (efficiencyAggregateSelected === '6m') {
          newDate.setDate(today.getDate() - 180);
        } else if (efficiencyAggregateSelected === '1d') {
          newDate = new Date(
            new Date(
              new Date(new Date(new Date(new Date())).setSeconds(0)).setMinutes(
                0
              )
            ).setHours(0)
          );
        } else {
          return;
        }

        let idFilteredPorts = Dash.SessionMasterCollection.filter(
          (port) => port.portfolio_id === selectedPortfolioId
        );
        let filteredSessions = idFilteredPorts.filter((port) => {
          return (
            port.session_date.seconds * 1000 > newDate.getTime() &&
            port.session_date.seconds * 1000 < today.getTime()
          );
        });

        let arr = [];
        for (let port of filteredSessions) {
          let obj = createBarData(port.page_visited);
          arr.push(obj);
        }
        let allPagesArr = [];

        for (let visit of arr) {
          for (let page of visit.totalTimeSpendDatas) {
            if (page.name !== 'Splash') {
              allPagesArr.push(page);
            }
          }
        }

        let pagesFilteredBySD = filterStandardDeviation(allPagesArr);

        for (let page of pagesFilteredBySD) {
          let existingPages = uniqueLabels;
          if (existingPages.includes(page.name)) {
            continue;
          } else {
            existingPages.push(page.name);
            setUniqueLabels(existingPages);
          }
        }

        let pagesVisitedArr = [];
        let unaverageArr = [];
        for (let pageVisit of pagesFilteredBySD) {
          if (pagesVisitedArr.includes(pageVisit.name)) {
            let found = unaverageArr.find((page) => pageVisit.name === page.x);
            let newData = {
              x: pageVisit.name,
              y: pageVisit.y + found.y,
              label: `${pageVisit.y + found.y}s`
            };
            let index = unaverageArr.findIndex(
              (page) => pageVisit.name === page.x
            );
            unaverageArr.splice(index, 1, newData);
          } else {
            pagesVisitedArr.push(pageVisit.name);
            let data = {
              x: pageVisit.name,
              y: pageVisit.y,
              label: `${pageVisit.y}s`
            };
            unaverageArr.push(data);
          }
        }

        let averageArr = [];
        for (let i = 0; i < unaverageArr.length; i++) {
          let averagedData = {
            x: unaverageArr[i].x,
            y: Math.ceil(unaverageArr[i].y / filteredSessions.length),
            label: `${Math.ceil(unaverageArr[i].y / filteredSessions.length)}s`
          };
          averageArr.push(averagedData);
        }
        if (!averageArr.length) {
          setCompanyAverageTimePerPageFormatted([{ x: ' ', y: 0 }]);
        } else {
          setCompanyAverageTimePerPageFormatted(averageArr);
        }
      } else {
        return;
      }
    } else {
      return;
    }
  };

  // const handleZoom = (domain) => {
  //     setBrushDomain(domain);
  // }

  const handleBrush = (domain) => {
    setZoomDomain(domain);
  };

  // //////onsole.log("USER SESSION TOGGLE NOW:", secondaryUserSessionToggle);
  // //////onsole.log("USER AGGREGATE TOGGLE NOW", secondaryUserAggregateToggle);

  // const togglePast = () => {
  //     if (Dash.SessionMasterCollection === null || Dash.SessionMasterCollection === undefined || !Dash.SessionMasterCollection.length) {
  //         return;
  //     }

  //     if (pastToggle === 'on') {
  //         setEfficiencyPastToggle("off");
  //         setUserPastAverageTimePerPageFormatted([]);
  //     } else if (pastToggle === 'off') {
  //         setEfficiencyPastToggle("on");
  //         let formattedDatePick = new Date(new Date(new Date(userPastDate.setSeconds(0)).setMinutes(0)).setHours(0));
  //         let idFilteredPorts = Dash.SessionMasterCollection.filter(port => port.portfolio_id === selectedPortfolioId);
  //         let userFilteredPorts = idFilteredPorts.filter(port => port.user_id === selectedUser[0].id);
  //         let dateFilteredPorts = userFilteredPorts.filter(port => {
  //             let formattedPortDate = new Date(new Date(new Date(new Date(new Date(port.session_date.seconds * 1000)).setSeconds(0)).setMinutes(0)).setHours(0));
  //             return `${formattedPortDate}` === `${formattedDatePick}`;
  //         });
  //         if (!dateFilteredPorts.length) {
  //             //////onsole.log("USER PAST- NO VISITS FOR THIS TIME PERIOD");
  //             setUserPastAverageTimePerPageFormatted([]);
  //             return;
  //         }
  //         createAverage(dateFilteredPorts);
  //     }
  // }

  return (
    <>
      <div className="dashboard-body-left"></div>
      <StretchContainer
        className="dashboard-body-main"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderLeft: 'none'
        }}
      >
        <CenterContainer
          style={{ gridTemplateRows: `${Scale.Height(Size, 150)}px 1fr` }}
        >
          <div className="top-center-container">
            <div className="top-box" style={{ gridColumn: '1 / span 1' }}>
              <div className="split" style={{ alignItems: 'flex-end' }}>
                <div
                  className="legend"
                  style={{
                    backgroundColor:
                      userToggle === 'on' && secondaryUserSessionToggle === 'on'
                        ? '#3269a8'
                        : '#c2c2c2',
                    height: `${Scale.Height(Size, 25)}px`,
                    width: `${Scale.Height(Size, 170)}px`
                  }}
                >
                  <p style={{ fontSize: `${Scale.Height(Size, 14)}px` }}>
                    Session Select
                  </p>
                </div>
                <div
                  className="togglebar"
                  style={{
                    backgroundColor:
                      userToggle === 'on' && secondaryUserSessionToggle === 'on'
                        ? '#66D754'
                        : '#eeeeee',
                    height: `${Scale.Height(Size, 25)}px`,
                    width: `${Scale.Height(Size, 60)}px`
                  }}
                  onClick={() => toggleUser('session')}
                >
                  <div
                    className="toggle-circle"
                    style={
                      userToggle === 'on' && secondaryUserSessionToggle === 'on'
                        ? { right: '7%' }
                        : { left: '7%' }
                    }
                  />
                </div>
              </div>
              <div className="split">
                <SessionSelect positioning={300} />
              </div>
              <div className="split" style={{ alignItems: 'flex-start' }}>
                <div
                  className="time-tab"
                  style={{
                    backgroundColor:
                      userToggle === 'on' && secondaryUserSessionToggle === 'on'
                        ? '#3269a8'
                        : '#c2c2c2',
                    height: `${Scale.Height(Size, 25)}px`,
                    width: `${Scale.Height(Size, 240)}px`
                  }}
                >
                  <p style={{ fontSize: `${Scale.Height(Size, 13)}px` }}>
                    {secondaryUserSessionToggle === 'on'
                      ? `Session Total: ${userTimeString}`
                      : 'N/A'}
                  </p>
                </div>
              </div>
            </div>
            <div className="top-box" style={{ gridColumn: '2 / span 1' }}>
              <div className="split" style={{ alignItems: 'flex-end' }}>
                <div
                  className="legend"
                  style={{
                    backgroundColor:
                      userToggle === 'on' &&
                      secondaryUserAggregateToggle === 'on'
                        ? '#3269a8'
                        : '#c2c2c2',
                    height: `${Scale.Height(Size, 25)}px`,
                    width: `${Scale.Height(Size, 170)}px`
                  }}
                >
                  <p style={{ fontSize: `${Scale.Height(Size, 14)}px` }}>
                    User Aggregate
                  </p>
                </div>
                <div
                  className="togglebar"
                  style={{
                    backgroundColor:
                      userToggle === 'on' &&
                      secondaryUserAggregateToggle === 'on'
                        ? '#66D754'
                        : '#eeeeee',
                    height: `${Scale.Height(Size, 25)}px`,
                    width: `${Scale.Height(Size, 60)}px`
                  }}
                  onClick={() => toggleUser('aggregate')}
                >
                  <div
                    className="toggle-circle"
                    style={
                      userToggle === 'on' &&
                      secondaryUserAggregateToggle === 'on'
                        ? { right: '7%' }
                        : { left: '7%' }
                    }
                  />
                </div>
              </div>
              <div className="split">
                <DateSelect />
              </div>
              <div className="split" style={{ alignItems: 'flex-start' }}>
                <div
                  className="time-tab"
                  style={{
                    backgroundColor:
                      userToggle === 'on' &&
                      secondaryUserAggregateToggle === 'on'
                        ? '#3269a8'
                        : '#c2c2c2',
                    height: `${Scale.Height(Size, 25)}px`,
                    width: `${Scale.Height(Size, 240)}px`
                  }}
                >
                  <p style={{ fontSize: `${Scale.Height(Size, 13)}px` }}>
                    {secondaryUserAggregateToggle === 'on'
                      ? `Average session: ${userTimeString}`
                      : 'N/A'}
                  </p>
                </div>
              </div>
            </div>
            <div className="top-box" style={{ gridColumn: '3 / span 1' }}>
              <div className="split" style={{ alignItems: 'flex-end' }}>
                <div
                  className="legend"
                  style={{
                    backgroundColor:
                      companyToggle === 'on' ? 'rgb(247, 152, 61)' : '#c2c2c2',
                    height: `${Scale.Height(Size, 25)}px`,
                    width: `${Scale.Height(Size, 170)}px`
                  }}
                >
                  <p style={{ fontSize: `${Scale.Height(Size, 14)}px` }}>
                    Company Aggregate
                  </p>
                </div>
                <div
                  className="togglebar"
                  style={{
                    backgroundColor:
                      companyToggle === 'on' ? '#66D754' : '#eeeeee',
                    height: `${Scale.Height(Size, 25)}px`,
                    width: `${Scale.Height(Size, 60)}px`
                  }}
                  onClick={() => toggleCompany()}
                >
                  <div
                    className="toggle-circle"
                    style={
                      companyToggle === 'on' ? { right: '7%' } : { left: '7%' }
                    }
                  />
                </div>
              </div>
              <div className="split">
                <DateSelectEfficiency />
              </div>
              <div className="split" style={{ alignItems: 'flex-start' }}>
                <div
                  className="time-tab"
                  style={{
                    backgroundColor:
                      companyToggle === 'on' ? 'rgb(247, 152, 61)' : '#c2c2c2',
                    height: `${Scale.Height(Size, 25)}px`,
                    width: `${Scale.Height(Size, 240)}px`
                  }}
                >
                  <p style={{ fontSize: `${Scale.Height(Size, 13)}px` }}>
                    Average session: {companyTimeString}
                  </p>
                </div>
              </div>
            </div>
            <div className="top-box" style={{ gridColumn: '4 / span 1' }}>
              <div className="split" style={{ alignItems: 'flex-end' }}>
                <div
                  className="legend"
                  style={{
                    backgroundColor:
                      parallelToggle === 'on' ? '#3b3b3b' : '#c2c2c2',
                    height: `${Scale.Height(Size, 25)}px`,
                    width: `${Scale.Height(Size, 170)}px`
                  }}
                >
                  <p style={{ fontSize: `${Scale.Height(Size, 13)}px` }}>
                    Head-to-Head Session
                  </p>
                </div>
                <div
                  className="togglebar"
                  style={{
                    backgroundColor:
                      parallelToggle === 'on' ? '#66D754' : '#eeeeee',
                    height: `${Scale.Height(Size, 25)}px`,
                    width: `${Scale.Height(Size, 60)}px`
                  }}
                  onClick={() => toggleParallelSession()}
                >
                  <div
                    className="toggle-circle"
                    style={
                      parallelToggle === 'on' ? { right: '7%' } : { left: '7%' }
                    }
                  />
                </div>
              </div>
              <div className="split">
                {/* <DatePicker 
                                    selected={userPastDate} 
                                    onChange={(date) => {handleUserPastDateChange(date); setUserPastDate(date)}}
                                />
                                <div style ={{
                                    backgroundColor: '#3b3b3b',
                                    border: '1px solid #3b3b3b',
                                    borderTopRightRadius: '5px',
                                    borderBottomRightRadius: '5px',
                                    width: '20px',
                                    height: '19px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <DateRangeRoundedIcon 
                                        style={{
                                            fontSize: '16px',
                                            color: 'white'
                                        }}
                                    />
                                </div> */}
                <ParallelSelect />
              </div>
              <div className="split" style={{ alignItems: 'flex-start' }}>
                <div
                  className="time-tab"
                  style={{
                    backgroundColor:
                      parallelToggle === 'on' ? '#3b3b3b' : '#c2c2c2',
                    height: `${Scale.Height(Size, 25)}px`,
                    width: `${Scale.Height(Size, 240)}px`
                  }}
                >
                  <p style={{ fontSize: `${Scale.Height(Size, 13)}px` }}>
                    Session Total: {parallelSessionTimeString}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Main style={{ opacity: animate ? 1 : 0 }}>
            {/* <style>{`
                        div.Chart-Wrapp
                            div.VictoryContainer{
                                background-color: #eee;
                            }
                        
                        `}</style> */}
            <div className="chart-container">
              <div className={'Chart-Wrapp'}>
                <V.VictoryChart
                  theme={V.VictoryTheme.material}
                  domainPadding={20}
                  animate={{ duration: 100, easing: 'circle', delay: 0 }}
                  height={Scale.Height(Size, 600)}
                  width={Scale.Height(Size, 1400)}
                  padding={{
                    top: 100,
                    bottom: Scale.Height(Size, 100),
                    left: 100,
                    right: 100
                  }}
                  backgroundComponent={
                    <V.Background id={'Prime'}></V.Background>
                  }
                  style={{ background: { fill: '#eeeeee' } }}
                  containerComponent={
                    <V.VictoryZoomContainer
                      containerId={'Prime'}
                      zoomDomain={zoomDomain}
                      //zoomDomain={{ x: [0, 12], y: [0, maxRange] }}
                      onZoomDomainChange={handleBrush}
                      zoomDimension="x"
                      responsive={false}
                    />
                  }
                >
                  <V.VictoryLegend
                    x={Scale.Height(Size, 550)}
                    y={10}
                    title="Avg. Time Per Page"
                    centerTitle
                    orientation="horizontal"
                    // padding={Scale.Height(Size, 100)}
                    gutter={20}
                    style={{
                      border: { stroke: '#b3b3b3', strokeWidth: 2 },
                      title: {
                        fontSize: Scale.Height(Size, 17),
                        fontWeight: 600,
                        fill: '#3b3b3b'
                      },
                      labels: { fontSize: Scale.Height(Size, 17) }
                    }}
                    data={[
                      { name: 'User', symbol: { fill: '#3269a8' } },
                      { name: 'Company', symbol: { fill: '#F5AD3B' } },
                      { name: 'H2H', symbol: { fill: '#3b3b3b' } }
                    ]}
                  />
                  <V.VictoryAxis
                    tickFormat={finalLabels}
                    // label="Page Name"
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    tickFormat={(text) =>
                      text.length > 20 ? `${text.slice(0, 20)} ...` : `${text}`
                    }
                    style={{
                      ticks: { stroke: 'grey', size: 5 },
                      // tickLabels: {fontSize: 3, padding: 5, fill: '3b3b3b', angle: 45}
                      grid: { stroke: '#bfbfbf' },
                      tickLabels: {
                        fontSize: 13,
                        padding: 22,
                        fill: '3b3b3b',
                        angle: 45
                      },
                      axisLabel: {
                        fontSize: 16,
                        padding: 80,
                        fill: '#3b3b3b',
                        fontWeight: 600
                      }
                      // axis: {stroke: "#808080"}
                    }}
                  />
                  <V.VictoryAxis
                    dependentAxis
                    tickFormat={(seconds) =>
                      seconds >= 3600
                        ? `${Math.floor(seconds / 3600)}hr ${Math.floor(
                            (seconds / 60) % 60
                          )}m ${Math.round(seconds % 60).toString()}s`
                        : seconds >= 60
                        ? `${Math.floor(seconds / 60)}m ${Math.round(
                            seconds % 60
                          ).toString()}s`
                        : `${Math.round(seconds % 60).toString()}s`
                    }
                    tickCount={10}
                    domain={[0, maxRange]}
                    style={{
                      ticks: { stroke: 'grey', size: 5 },
                      grid: { stroke: '#bfbfbf' },
                      // tickLabels: {fontSize: 4, padding: 5, fill: '3b3b3b'}
                      tickLabels: { fontSize: 15, padding: 10, fill: '3b3b3b' }
                    }}
                    fixLabelOverlap
                  />
                  <V.VictoryGroup
                    offset={Scale.Height(Size, 24)}
                    style={{ data: { width: Scale.Height(Size, 24) } }}
                  >
                    <V.VictoryBar
                      labelComponent={
                        <V.VictoryTooltip
                          centerOffset={{ y: 0 }}
                          pointerWidth={0}
                          pointerHeight={0}
                          flyoutPadding={20}
                          flyoutStyle={{
                            stroke: '#3269a8',
                            strokeWidth: 4,
                            fill: '#3b3b3b'
                          }}
                          style={{
                            fontSize: '14px',
                            fontWeight: '600',
                            fill: 'white'
                          }}
                        />
                      }
                      style={{ data: { fill: '#3269a8' } }}
                      data={
                        userToggle === 'on'
                          ? totalTimePerPageFormatted
                          : [{ x: ' ', y: 0 }]
                      }
                      // events={[
                      //     {
                      //       target: "data",
                      //       eventHandlers: {
                      //         onMouseEnter: () => {
                      //             return [{
                      //                 target: "data",
                      //                 mutation: (props) => {
                      //                     const stroke = props.style.fill;
                      //                     return stroke === "#3269a8BF" ? null : {style: {fill: "#3269a8BF"}}
                      //                 }
                      //             }];
                      //         },
                      //         onMouseLeave: () => {
                      //             return [{
                      //                 target: "data",
                      //                 mutation: (props) => {
                      //                     const fill = props.style.stroke;
                      //                     return fill === "#3269a8" ? null : {style: {fill: "#3269a8"}}
                      //                 }
                      //             }];
                      //         }
                      //       }
                      //     }
                      // ]}
                    />
                    <V.VictoryBar
                      labelComponent={
                        <V.VictoryTooltip
                          centerOffset={{ y: 0 }}
                          pointerWidth={0}
                          pointerHeight={0}
                          flyoutPadding={20}
                          flyoutStyle={{
                            stroke: '#F5AD3B',
                            strokeWidth: 4,
                            fill: '#3b3b3b'
                          }}
                          style={{
                            fontSize: '14px',
                            fontWeight: '600',
                            fill: 'white'
                          }}
                        />
                      }
                      style={{ data: { fill: '#F5AD3B' } }}
                      data={
                        companyToggle === 'on'
                          ? companyAverageTimePerPageFormatted
                          : [{ x: ' ', y: 0 }]
                      }
                      // events={[
                      //     {
                      //       target: "data",
                      //       eventHandlers: {
                      //         onMouseEnter: () => {
                      //             return [{
                      //                 target: "data",
                      //                 mutation: (props) => {
                      //                     const stroke = props.style.fill;
                      //                     return stroke === "#F5AD3BBF" ? null : {style: {fill: "#F5AD3BBF"}}
                      //                 }
                      //             }];
                      //         },
                      //         onMouseLeave: () => {
                      //             return [{
                      //                 target: "data",
                      //                 mutation: (props) => {
                      //                     const fill = props.style.stroke;
                      //                     return fill === "#F5AD3B" ? null : {style: {fill: "#F5AD3B"}}
                      //                 }
                      //             }];
                      //         }
                      //       }
                      //     }
                      // ]}
                    />
                    <V.VictoryBar
                      labelComponent={
                        <V.VictoryTooltip
                          centerOffset={{ y: 0 }}
                          pointerWidth={0}
                          pointerHeight={0}
                          flyoutPadding={20}
                          flyoutStyle={{
                            stroke: '#3b3b3b',
                            strokeWidth: 4,
                            fill: '#3b3b3b'
                          }}
                          style={{
                            fontSize: '14px',
                            fontWeight: '600',
                            fill: 'white'
                          }}
                        />
                      }
                      style={{ data: { fill: '#3b3b3b' } }}
                      data={
                        parallelToggle === 'on'
                          ? parallelSessionFormatted
                          : [{ x: ' ', y: 0 }]
                      }
                      // events={[
                      //     {
                      //       target: "data",
                      //       eventHandlers: {
                      //         onMouseEnter: () => {
                      //             return [{
                      //                 target: "data",
                      //                 mutation: (props) => {
                      //                     const stroke = props.style.fill;
                      //                     return stroke === "#808080" ? null : {style: {fill: "#808080"}}
                      //                 }
                      //             }];
                      //         },
                      //         onMouseLeave: () => {
                      //             return [{
                      //                 target: "data",
                      //                 mutation: (props) => {
                      //                     const fill = props.style.stroke;
                      //                     return fill === "#3b3b3b" ? null : {style: {fill: "#3b3b3b"}}
                      //                 }
                      //             }];
                      //         }
                      //       }
                      //     }
                      // ]}
                    />
                  </V.VictoryGroup>
                </V.VictoryChart>
              </div>

              {/* brush container */}
              <V.VictoryChart
                theme={V.VictoryTheme.material}
                domainPadding={20}
                animate={{ duration: 100, easing: 'circle', delay: 0 }}
                padding={0}
                height={Scale.Height(Size, 90)}
                width={Scale.Height(Size, 1000)}
                containerComponent={
                  <V.VictoryBrushContainer
                    responsive={false}
                    brushDimension="x"
                    brushDomain={zoomDomain}
                    onBrushDomainChange={handleBrush}
                    allowResize={false}
                    defaultBrushArea={'move'}
                  />
                }
              >
                <V.VictoryAxis
                  style={{
                    ticks: { stroke: 'white', size: 0 },
                    tickLabels: { fontSize: 0, fill: 'white' },
                    axisLabel: { fontSize: 0, fill: 'white' }
                  }}
                />
                <V.VictoryAxis
                  dependentAxis
                  domain={[0, maxRange]}
                  style={{
                    ticks: { stroke: 'white', size: 0 },
                    tickLabels: { fontSize: 0, fill: 'white' }
                  }}
                  fixLabelOverlap
                />
                <V.VictoryGroup
                  offset={Scale.Height(Size, 16)}
                  style={{ data: { width: Scale.Height(Size, 16) } }}
                >
                  <V.VictoryBar
                    labelComponent={
                      <V.VictoryTooltip
                        centerOffset={{ y: 0 }}
                        pointerWidth={0}
                        pointerHeight={0}
                        flyoutPadding={0}
                        flyoutStyle={{
                          stroke: 'white',
                          strokeWidth: 0,
                          fill: 'white'
                        }}
                        style={{
                          fontSize: '0px',
                          fontWeight: '600',
                          fill: 'white'
                        }}
                      />
                    }
                    style={{ data: { fill: '#3269a8' } }}
                    data={
                      userToggle === 'on'
                        ? totalTimePerPageFormatted
                        : [{ x: ' ', y: 0 }]
                    }
                  />
                  <V.VictoryBar
                    labelComponent={
                      <V.VictoryTooltip
                        centerOffset={{ y: 0 }}
                        pointerWidth={0}
                        pointerHeight={0}
                        flyoutPadding={0}
                        flyoutStyle={{
                          stroke: 'white',
                          strokeWidth: 0,
                          fill: 'white'
                        }}
                        style={{
                          fontSize: '0px',
                          fontWeight: '600',
                          fill: 'white'
                        }}
                      />
                    }
                    style={{ data: { fill: '#F5AD3B' } }}
                    data={
                      companyToggle === 'on'
                        ? companyAverageTimePerPageFormatted
                        : [{ x: ' ', y: 0 }]
                    }
                  />
                  <V.VictoryBar
                    labelComponent={
                      <V.VictoryTooltip
                        centerOffset={{ y: 0 }}
                        pointerWidth={0}
                        pointerHeight={0}
                        flyoutPadding={0}
                        flyoutStyle={{
                          stroke: 'white',
                          strokeWidth: 0,
                          fill: 'white'
                        }}
                        style={{
                          fontSize: '0px',
                          fontWeight: '600',
                          fill: 'white'
                        }}
                      />
                    }
                    style={{ data: { fill: '#3b3b3b' } }}
                    data={
                      parallelToggle === 'on'
                        ? parallelSessionFormatted
                        : [{ x: ' ', y: 0 }]
                    }
                  />
                </V.VictoryGroup>
              </V.VictoryChart>
            </div>
          </Main>
        </CenterContainer>
      </StretchContainer>
      <div className="dashboard-body-right"></div>
    </>
  );
};

export default Efficiency;
