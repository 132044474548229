import React, { useState, useEffect, useRef } from 'react';
// import { useHistory } from 'react-router-dom';
import { useFirestore } from 'react-redux-firebase'; //useFirebase,
import ContentParent from '../../../../components/_Portfolio/PortfolioContentParentTemplate9';
//import ContentParent from '../../../../components/_Portfolio/PortfolioContentParentTemplate9'
// import {urlFor} from '../../../../utils/sanityHelper'
// import PDFViewer2 from '../../../../components/FullWidthPDFViewer';
import BlockContent from '@sanity/block-content-to-react';
import serializers from '../../../../utils/scriptSerializers';
import ColorUtil from '../../../../utils/ColorUtility';
import FontUtil from '../../../../utils/FontUtility';
// import BASlider from '../../../../components/BeforeAfterSlider'
// import GalThumb from '../../../../components/_PortfolioContentParent3/GalleryThumbsContent'
// import ToolCross from './Shape.png'
// import exit from './Exclude.png'
import CloseIcon from '../CloseSVG';
import Like from '../../../../utils/Likes';
import Icons from '../../../../containers/Icons';
const SingleItemDisplay = ({
  // Flag Font Family
  //createSetCurrentContent,
  chosenContentPageId,
  pages,
  //pages2,
  chosenPortfolio,
  Size,
  //images,
  videos,
  pdfs,
  //back,
  isGuestMode,
  sessionId,
  liked,
  setBooleanNewItems,
  setLiked,
  currentGuestContentHelper,
  addToBriefcase,
  showBriefcase,
  toggleScriptnote,
  //currentContent,
  // endPortfolio,
  toggleNotepad,
  // toggleBriefcase,
  // clearAll,
  // setPortfolioCustomerInfo,
  //Position,
  //Single,
  //SetActive,
  // updateSessionInSanity,
  SET_ANNOTATOR,

  // showNotepad,

  mode,

  briefcaseContent,
  // isModalOpen,

  // menuHide,

  //Ctype,

  userInfo,
  // Size,
  // booleanNewItems,
  ANNOTATOR2,
  // Package,
  setToolbarMenuOpen,
  toolbarMenuOpen,
  //Package
  Ctype,
  Position,
  Single,
  SetActive,
  createSetCurrentContent,
  currentContent,
  sendClick,
  shareClick
}) => {
  // const {
  //   createSetCurrentContent,
  //   currentContent,
  //   // endPortfolio,
  //   // toggleNotepad,
  //   // toggleBriefcase,
  //   // clearAll,
  //   // setPortfolioCustomerInfo,
  //   Position,
  //   Single,
  //   SetActive,
  //   // showNotepad,

  //   // mode,

  //   //briefcaseContent,
  //   // isModalOpen,

  //   // menuHide,

  //   Ctype,

  //   // Size,
  //   // booleanNewItems,

  // }= Package
  // const handleToggle = () => {
  //   let anti = !toolbarMenuOpen;
  //   setToolbarMenuOpen(anti);
  // }
  //     const test = pages[chosenContentPageId]?.content?pages[chosenContentPageId]?.content[Position]?.StoryBookObjects?.descriptionHeaderFontsize?
  // pages[chosenContentPageId].content[Position].StoryBookObjects?.descriptionHeaderFontsize+'px':'56px':pages[chosenContentPageId]?.StoryBookObjects?.descriptionHeaderFontsize?pages[chosenContentPageId]?.StoryBookObjects?.descriptionHeaderFontsize:'56px'
  const [isAuthBriefcaseShow, setIsAuthBriefcaseShow] = useState(false); // is this user authorized to see briefcase?
  useEffect(() => {
    if (userInfo && userInfo.length > 0) {
      // TODO: Why is this constant condition?
      // check if user is able to create guest pass
      if (true) {
        //userInfo[0].is_able_to_create_briefcase
        // user is able to create normal guest pass, aka able to add item to briefcase
        setIsAuthToAdd(true);
      }
      // check if user is able create portfolio level guest pass
      if (true) {
        // userInfo[0].is_able_to_create_guest_pass
        // user is able to create normal guest pass, aka able to add item to briefcase
        setIsAuthBriefcaseShow(true);
      }
    }
  }, [userInfo]);
  const [isAblePortfolioGuestPass, setIsAblePortfolioGuestPass] =
    useState(false);
  useEffect(() => {
    if (userInfo && userInfo.length > 0) {
      // TODO: Why is this constant condition?
      if (true) {
        //userInfo[0].is_able_to_create_guest_pass
        setIsAblePortfolioGuestPass(true);
      }
    }
  }, [userInfo]);
  const [DesHeadSize] = useState(
    pages[chosenContentPageId]?.content
      ? pages[chosenContentPageId]?.content[Position]?.StoryBookObjects
          ?.descriptionHeaderFontsize
        ? pages[chosenContentPageId].content[Position].StoryBookObjects
            ?.descriptionHeaderFontsize + 'px'
        : '56px'
      : pages[chosenContentPageId]?.StoryBookObjects?.descriptionHeaderFontsize
      ? pages[chosenContentPageId]?.StoryBookObjects?.descriptionHeaderFontsize
      : '56px'
  );

  const SubRef = useRef();
  const [Display] = useState(
    (pages[chosenContentPageId]?.content
      ? pages[chosenContentPageId]?.content[Position]?.DescriptionBool &&
        pages[chosenContentPageId]?.content[Position]?.descriptionHeader
      : false) ||
      (pages[chosenContentPageId]?.StoryBookObjects
        ? pages[chosenContentPageId]?.StoryBookObjects?.DescriptionBool &&
          pages[chosenContentPageId]?.StoryBookObjects?.descriptionHeader
        : false)
  );
  const [isAddable, setIsAddable] = useState(true); // bool to keep track of if it is addable to briefcase
  const [isAuthToAdd, setIsAuthToAdd] = useState(false); // is this user authorized to add item?
  // const [isAuthBriefcaseShow, setIsAuthBriefcaseShow] = useState(false); // is this user authorized to see briefcase?
  const [isLiked2, setIsLiked2] = useState(null); // is this user authorized to see briefcase?
  // const history = useHistory();
  const firestore = useFirestore();
  // const [Display] = useState((pages[chosenContentPageId]?.content?pages[chosenContentPageId]?.content[Position]?.DescriptionBool&&pages[chosenContentPageId]?.content[Position]?.descriptionHeader:false) || (pages[chosenContentPageId]?.StoryBookObjects?.DescriptionBool && pages[chosenContentPageId]?.StoryBookObjects?.descriptionHeader) )

  const [Anno, SetAnno] = useState(
    window.innerWidth > 450 && window.innerHeight > 515
  );
  useEffect(() => {
    SetAnno(Size.W > 450 && Size.H > 515);
  }, [Size.H, Size.W]);

  // const test2 =
  // (
  //   (
  //   pages[chosenContentPageId]?.content?
  //     pages[chosenContentPageId]?.content[Position]?.DescriptionBool
  //     &&
  //     pages[chosenContentPageId]?.content[Position]?.descriptionHeader
  //     :false
  //   )
  //   ||
  //   (pages[chosenContentPageId]?.StoryBookObjects? pages[chosenContentPageId]?.StoryBookObjects?.DescriptionBool && pages[chosenContentPageId]?.StoryBookObjects?.descriptionHeader:false)
  //   )

  const [DesHeadSizeT] = useState(
    pages[chosenContentPageId]?.content
      ? pages[chosenContentPageId]?.content[Position]?.StoryBookObjects
          ?.descriptionHeaderFontsize
        ? pages[chosenContentPageId].content[Position].StoryBookObjects
            ?.descriptionHeaderFontsize *
            (36 / 56) +
          'px'
        : '36px'
      : pages[chosenContentPageId]?.StoryBookObjects?.descriptionHeaderFontsize
      ? pages[chosenContentPageId]?.StoryBookObjects
          ?.descriptionHeaderFontsize *
          (36 / 56) +
        'px'
      : '36px'
  );
  const [DesSize] = useState(
    pages[chosenContentPageId]?.content
      ? pages[chosenContentPageId]?.content[Position]?.StoryBookObjects
          ?.descriptionFontsize
        ? pages[chosenContentPageId].content[Position].StoryBookObjects
            ?.descriptionFontsize + 'px'
        : '20px'
      : pages[chosenContentPageId]?.StoryBookObjects?.descriptionFontsize
      ? pages[chosenContentPageId]?.StoryBookObjects?.descriptionFontsize
      : '20px'
  );

  // const firebase = useFirebase();
  // //onsole.log("!!CURRENTCONTENT",currentContent)

  useEffect(() => {
    if (userInfo && userInfo.length > 0) {
      // TODO: Why is this constant condition?
      // check if user is able to create guest pass
      if (true) {
        //userInfo[0].is_able_to_create_briefcase
        // user is able to create normal guest pass, aka able to add item to briefcase
        setIsAuthToAdd(true);
      }
      // check if user is able create portfolio level guest pass
      if (true) {
        // userInfo[0].is_able_to_create_guest_pass
        // user is able to create normal guest pass, aka able to add item to briefcase
        // setIsAuthBriefcaseShow(true);
      }
    }
  }, [userInfo]);
  useEffect(() => {
    // check if briefcase contains current content user is looking at
    if (
      (Object.entries(currentContent).length !== 0 &&
        briefcaseContent.filter((e) => e.id === currentContent.id).length >
          0) ||
      currentContent.page_id === `splashkey-${chosenPortfolio._id}`
    ) {
      setIsAddable(false);
    } else {
      setIsAddable(true);
    }
  }, [currentContent, briefcaseContent, chosenPortfolio]);
  const updateFavorite = (op) => {
    Like(
      op,
      isGuestMode,
      sessionId,
      liked,
      currentGuestContentHelper,
      currentContent,
      chosenPortfolio,
      setIsLiked2,
      setLiked,
      firestore
    );
    // // do not log if mode is not guest or no sessionId
    // if (!isGuestMode || sessionId === '') {
    //   return null;
    // }

    // // copy liked from redux store
    // let likedArrCopy = [...liked];
    // //////////onsole.log("!!CC!!",currentContent)
    // let id = currentGuestContentHelper.id;
    // let content = currentContent;
    // // check if currentGuestContentHelper id is undefined
    // if (!id) {
    //   //////////onsole.log('like in full portfolio guest-pass view...');
    //   id = currentContent.id;
    //   // if undefined it means that guest is in full portfolio view and not in briefcase guest view
    // } else {
    //   // user in briefcase guest view
    //   //////////onsole.log('like in Briefcase guest view...');
    //   // set content from currentGuestContentHelper, if not splash
    //   if (id !== `splashkey-${chosenPortfolio._id}`) {
    //     content = currentGuestContentHelper;
    //   }
    // }
    // let content2 = {...content, time_liked:firestore.Timestamp.now()}
    // if(content2.PagesSaved === undefined){
    //   content2.PagesSaved = 1;
    // }
    // // if op is add then append currentContent obj to likedArrCopy,
    // if (op === 'add') {
    //   likedArrCopy.push(content2);
    // } else {
    //   // if not filter out
    //   likedArrCopy = likedArrCopy.filter((obj) => obj.id !== id);
    // }
    // //////////onsole.log("LIKED: ", likedArrCopy);
    // setIsLiked2(likedArrCopy)
    // // replace the liked array
    // firestore
    //   .collection('sessions')
    //   .doc(sessionId)
    //   .update({
    //     liked: likedArrCopy
    //   })
    //   .then(() => {
    //     // replace liked in store
    //     setLiked(likedArrCopy);
    //   })
    //   .catch((error) => console.error('ERROR: ', error));
  };

  // check if current page is liked already or not
  const isLiked = () => {
    let currentGuestContentId = currentGuestContentHelper.id;
    // check if currentGuestContentHelper id is undefined
    if (!currentGuestContentId) {
      //////////onsole.log('Liked check in full portfolio guest-pass view...');
      // check if liked contains currentContent id
      return liked.some((obj) => obj.id === currentContent.id);
    } else {
      // user in briefcase guest view
      //////////onsole.log('Liked check in Briefcase guest view...');
      if (currentGuestContentId === `splashkey-${chosenPortfolio._id}`) {
        // check if liked contains currentContent id for splash
        return liked.some((obj) => obj.id === currentContent.id);
      } else {
        // check if liked contains currentGuestContentId
        return liked.some((obj) => obj.id === currentGuestContentId);
      }
    }
  };

  //////onsole.log("!!!!TESTpages!!!!",pages);
  //const [contents] = useState(pages[chosenContentPageId]?.content);//,setContents

  ////onsole.log("pages[chosenContentPageId]?.content[Pos]",pages[chosenContentPageId]?.content[Position],pages[chosenContentPageId]?.content[Position]?.StoryBookObjects?.descriptionHeaderFont)
  // const prepareCurrentGalleryContent = (index) => {
  //   let obj = {
  //     type: 'gallery',
  //     index: index,
  //     id: chosenContentPageId
  //   };
  //   createSetCurrentContent(obj);
  // };

  // const renderSingleThumb = (type, galleryNum, isSelected) => {
  //     switch (type) {
  //       case 'galleryImage':
  //         let image = contents[galleryNum].image;
  //         return (
  //           <img
  //             className="portfolio-single-image"
  //             src={urlFor(image).auto('format').width(300).url()} // auto converts to webp if browser supports it
  //             alt="mainImage"
  //             type="galleryImage"
  //             style={{height:"100%", width:"100%", objectFit:"contain"}}
  //             // style={{
  //             //   opacity: chosenPortfolio && chosenPortfolio.theme === 'theme2' ? opacityBack : 1,
  //             //   width: imageWidth,
  //             //   height: imageHeight
  //             // }}
  //           />
  //         );
  //       case 'galleryVideo':
  //         let video = videos[contents[galleryNum].video.asset._ref];
  //         //let videoSettings = contents[galleryNum];
  //         return (
  //           <img style={{height:"100%", width:"100%", objectFit:"contain"}} src={`https://image.mux.com/${video.playbackId}/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=${video.thumbTime !==0?video.thumbTime:1}`} alt="poster" />
  //           // <SanityMuxPlayer
  //           //   assetDocument={video}
  //           //   autoload={true}
  //           //   autoplay={videoSettings.autoplay}
  //           //   loop={videoSettings.loop}
  //           //   muted={false}
  //           //   showControls={true}
  //           //   style={{}}
  //           //   posterurl={`https://image.mux.com/${video.playbackId}/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=1`}
  //           //   type="galleryVideo"
  //           //   isSelected={isSelected}
  //           // />
  //         );
  //       case 'galleryPdf':
  //         let url2 = pdfs[contents[galleryNum].file.asset._ref].url;
  //         let thumbNailRef2 = contents[galleryNum].thumbnail.asset._ref;
  //         let thumbNailUrl2 = urlFor(thumbNailRef2)
  //           .auto('format')
  //           .width(300)
  //           .url();
  //         return (
  //           <PDFViewer2
  //             url={url2}
  //             isThumbnail={true}
  //             type="galleryFullWidthPdf"
  //             thumbnailUrl={thumbNailUrl2}
  //           />
  //         );
  //         case 'galleryBASlider':
  //           {
  //             let Aimage = contents[galleryNum].Aimage;
  //             let Bimage = contents[galleryNum].Bimage;
  //             let LabelA = contents[galleryNum].AimageL;
  //             let LabelB = contents[galleryNum].BimageL;
  //             let Vert = contents[galleryNum].Vert;
  //             let Color = contents[galleryNum].SlideColor;
  //             //////onsole.log('!!!!BA!!!!', contents[galleryNum])
  //             return<BASlider Thumb LabelB={LabelB} LabelA={LabelA} Color={Color} Vert={Vert} ImageA={Aimage} ImageB={Bimage}/>
  //           }
  //       case 'galleryFullWidthPdf':
  //         let url3 = pdfs[contents[galleryNum].file.asset._ref].url;
  //         let thumbNailRef3 = contents[galleryNum].thumbnail.asset._ref;
  //         let thumbNailUrl3 = urlFor(thumbNailRef3)
  //           .auto('format')
  //           .width(300)
  //           .url();
  //         return (
  //           <PDFViewer2
  //             url={url3}
  //             isThumbnail={true}
  //             type="galleryFullWidthPdf"
  //             thumbnailUrl={thumbNailUrl3}
  //           />
  //         );
  //       default:
  //         return <div />;
  //     }
  //   };

  //const [Pos,SetPos] = React.useState(0);

  const [DesSizeT] = pages[chosenContentPageId]?.content
    ? pages[chosenContentPageId]?.content[Position]?.StoryBookObjects
        ?.descriptionFontsize
      ? pages[chosenContentPageId]?.content[Position]?.StoryBookObjects
          ?.descriptionFontSize *
          (20 / 20) +
        'px'
      : '20px'
    : pages[chosenContentPageId]?.StoryBookObjects?.descriptionHeaderFontsize
    ? pages[chosenContentPageId]?.StoryBookObjects?.descriptionFontsize *
        (20 / 20) +
      'px'
    : '20px';
  //onsole.log('pages[chosenContentPageId]',pages[chosenContentPageId])

  ////onsole.log("pages[chosenContentPageId]?.content?pages[chosenContentPageId]?.content[Position]?.StoryBookObjects",pages[chosenContentPageId].content[Position].StoryBookObjects?.descriptionHeaderFontsize,DesHeadSizeT)
  return (
    //terminal_menu_color
    <div
      style={{
        zIndex: '2',
        backgroundColor: pages[chosenContentPageId]?.terminal_menu_color
          ? pages[chosenContentPageId]?.terminal_menu_color
          : chosenPortfolio.terminal_menu_color
          ? chosenPortfolio.terminal_menu_color
          : null
      }}
      onPointerDown={(eve) => {
        eve.preventDefault();
        eve.stopPropagation();
      }}
      className={`TerminalMenu ${Ctype ? 'Ctype' : ''} Single`}
      id="TerminalMenuATemplate9"
      ref={SubRef}
    >
      <p
        className={`title ${
          !Display && pages[chosenContentPageId]?._type === 'STgallery'
            ? 'Single'
            : ''
        }`}
        style={{
          color: ColorUtil(
            pages[chosenContentPageId].text_color,
            chosenPortfolio.text_color,
            'black'
          )
        }}
      >
        {pages[chosenContentPageId]?.title}
      </p>
      <CloseIcon
        className={'Exit'}
        onClick={() => {
          Single(null);
          try {
            SetActive(false);
          } catch (err) {
            console.error('Error', err);
          }
        }}
        id={'SID1'}
      />
      {/* <img alt="Exit View" className={"Exit"} src={exit} onClick={()=>{Single(null); try{SetActive(false)}catch{}}}  /> */}
      <div
        className={`Tools ${Ctype ? 'Ctype' : ''} SingleItemTMG ${
          !Display && pages[chosenContentPageId]?._type === 'STgallery'
            ? 'Single'
            : ''
        }`}
      >
        <CloseIcon
          onClick={() => {
            Single(null);
            try {
              SetActive(false);
            } catch (err) {
              console.error('Error', err);
            }
          }}
          id={'SID2'}
          className={`Exit ${Ctype ? 'Ctype' : ''}`}
        />
        {/* <img alt="Exit View" src={exit} onClick={()=>{Single(null); try{SetActive(false)}catch{}}} className={`Exit ${Ctype?"Ctype":""}`}  /> */}
        <div className={`OnMediaTools ${Ctype ? 'Ctype' : ''}`}>
          {/* {isAddable && isAuthToAdd && 
            (<Icons.AddShoppingCart id="Bypass"
      className="icon"
      onClick={() => {
        addToBriefcase(currentContent);
        // if (!showBriefcase) {
        //   toggleBriefcase();
        // }
        if (showBriefcase === true) {
          setBooleanNewItems(false);
        } else {
          setBooleanNewItems(true);
        }
      }}
      data-tip="Add to Briefcase"
    />
    )
    } */}
          <Icons.ShoppingCart
            id="Bypass"
            className="icon"
            onClick={() => {
              // toggleBriefcase();
              // setBooleanNewItems(false);
              // handleVisibility();
              if (
                isAblePortfolioGuestPass &&
                isAuthToAdd &&
                isAuthBriefcaseShow
              ) {
                sendClick(true);
              } else if (isGuestMode) {
                shareClick(true);
              }
            }}
            chosenPortfolio={chosenPortfolio}
            Size={Size}
            briefcaseContent={briefcaseContent}
            // booleanNewItems={booleanNewItems}
          />
          <div className="test-absolute-text">
            <p
              onClick={() => {
                // toggleBriefcase();
                // setBooleanNewItems(false);
                // handleVisibility();
                if (isAblePortfolioGuestPass) {
                  sendClick(true);
                } else if (isGuestMode) {
                  shareClick(true);
                }
              }}
              style={{
                color: chosenPortfolio?.bulk_icon_override
                  ? chosenPortfolio.bulk_icon_override
                  : '#3b3b3bBF'
              }}
            >
              GUEST PASS
            </p>
          </div>
          {isAddable && isAuthToAdd && <p>Add to Briefcase</p>}
          {isGuestMode ? (
            isLiked() && isLiked2 ? (
              <Icons.Favorite
                id="Bypass"
                onClick={() => {
                  updateFavorite('remove');
                }}
              />
            ) : (
              <Icons.FavoriteBorder
                id="Bypass"
                onClick={() => {
                  updateFavorite('add');
                }}
              />
            )
          ) : null}
          {chosenPortfolio.AnnotatorAvial !== false && Anno && (
            <>
              <Icons.AnnotatorIcon onClick={() => SET_ANNOTATOR(!ANNOTATOR2)} />
              {Anno ? <p>Annotator</p> : <></>}{' '}
            </>
          )}
          <Icons.NoteAdd
            Overlay={isGuestMode ? 'Contact' : 'Notepad'}
            onClick={() => {
              toggleNotepad();
              //handleToggle();
            }}
          ></Icons.NoteAdd>
          {mode === 'training' && (
            <div className="icon-and-text-parent">
              <Icons.SpeakerNotes
                id="Bypass"
                className="icon"
                chosenPortfolio={chosenPortfolio}
                Size={Size}
                onClick={() => {
                  // handleToggle();
                  toggleScriptnote();
                  // handleVisibility();
                }}
                data-tip={Size.W > 450 ? 'Speaker Notes' : null}
              />
              <div className="test-absolute-text">
                <p
                  onClick={() => {
                    // handleToggle();
                    toggleScriptnote();
                    // handleVisibility();
                  }}
                  style={{
                    color: chosenPortfolio?.bulk_icon_override
                      ? chosenPortfolio.bulk_icon_override
                      : '#3b3b3bBF'
                  }}
                >
                  SPEAKER NOTES
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* <div className="ToolsIcon"><img src={ToolCross} style={{height:"calc(100% * 42/115)",width:"calc(100% * 48/350)", position:"relative", left:"calc(100% * 36/350)", top:"calc(100% * 32/115)"}} /></div> */}
      {/* gridRow:(!Display&&pages[chosenContentPageId]?._type === "STgallery")?"s":null, */}
      <div
        className={`ContentArea SingleItemTMG ${
          !Display && pages[chosenContentPageId]?._type === 'STgallery'
            ? 'Single'
            : ''
        }`}
        style={{ maxHeight: !Display ? '100%' : null }}
      >
        <ContentParent
          Position={Position}
          createSetCurrentContent={createSetCurrentContent}
        />
      </div>
      {Display && window.innerWidth > 450 && window.innerHeight > 550 ? (
        <div
          className="textBox"
          style={{ overflowY: 'scroll', gridColumn: '2' }}
        >
          {/* <div style={{height:"100%", position:"relative", overflow:"scroll"}}> */}
          <div
            className="SubpageBlockContentWrapper header TMG OnGray"
            style={{
              textAlign: 'center',
              fontSize: window.innerWidth < 1367 ? DesHeadSizeT : DesHeadSize,
              fontFamily: FontUtil(
                pages[chosenContentPageId]?.content
                  ? pages[chosenContentPageId]?.content[Position]
                      ?.StoryBookObjects?.descriptionHeaderFont
                    ? pages[chosenContentPageId]?.content[Position]
                        ?.StoryBookObjects?.descriptionHeaderFont
                    : pages[chosenContentPageId]?.StoryBookObjects
                        ?.descriptionHeaderFont
                  : pages[chosenContentPageId]?.StoryBookObjects
                      ?.descriptionHeaderFont,
                chosenPortfolio.ContentText
              )
            }}
          >
            {/* width:"calc(100% * 773/1159)", */}
            {(pages[chosenContentPageId]?.StoryBookObjects?.descriptionHeader ||
              pages[chosenContentPageId]?.content[Position]?.StoryBookObjects
                ?.descriptionHeader) && (
              <BlockContent
                blocks={
                  pages[chosenContentPageId]?.content
                    ? pages[chosenContentPageId]?.content[Position]
                        ?.StoryBookObjects?.descriptionHeader
                      ? pages[chosenContentPageId]?.content[Position]
                          ?.StoryBookObjects?.descriptionHeader
                      : pages[chosenContentPageId]?.StoryBookObjects
                          .descriptionHeader
                    : pages[chosenContentPageId]?.StoryBookObjects
                        .descriptionHeader
                }
                serializers={serializers}
              ></BlockContent>
            )}
          </div>
          <div
            className="SubpageBlockContentWrapper description OnGray"
            style={{
              fontSize: window.innerWidth < 1367 ? DesSizeT : DesSize,
              fontFamily: FontUtil(
                pages[chosenContentPageId]?.content
                  ? pages[chosenContentPageId]?.content[Position]
                      ?.StoryBookObjects?.descriptionFont
                    ? pages[chosenContentPageId]?.content[Position]
                        ?.StoryBookObjects?.descriptionFont
                    : pages[chosenContentPageId]?.StoryBookObjects
                        ?.descriptionFont
                  : pages[chosenContentPageId]?.StoryBookObjects
                      ?.descriptionFont,
                chosenPortfolio.ContentText
              )
            }}
          >
            {(pages[chosenContentPageId]?.StoryBookObjects?.description ||
              pages[chosenContentPageId]?.content[Position]?.StoryBookObjects
                ?.description) && (
              <BlockContent
                blocks={
                  pages[chosenContentPageId]?.content
                    ? pages[chosenContentPageId]?.content[Position]
                        ?.StoryBookObjects?.description
                      ? pages[chosenContentPageId]?.content[Position]
                          ?.StoryBookObjects?.description
                      : pages[chosenContentPageId]?.content[Position]
                          .StoryBookObjects?.descriptionHeader
                      ? null
                      : pages[chosenContentPageId]?.StoryBookObjects.description
                    : pages[chosenContentPageId]?.StoryBookObjects.description
                }
                serializers={serializers}
              ></BlockContent>
            )}
          </div>
          {/* </div> */}
        </div>
      ) : null}

      {(Display && window.innerWidth <= 450) ||
      (window.innerWidth <= 815 && window.innerHeight <= 550) ? (
        <div
          className="textBox"
          style={{ overflowY: 'scroll', gridColumn: '2', textAlign: 'center' }}
        >
          <div className={'SubpageBlockContentWrapper SingleItem'}>
            <div
              className="SubpageBlockContentWrapper header TMG OnGray"
              style={{
                marginTop: 'calc(100% * 26/1080)',
                fontFamily: FontUtil(
                  pages[chosenContentPageId]?.content
                    ? pages[chosenContentPageId]?.content[Position]
                        ?.StoryBookObjects?.descriptionHeaderFont
                      ? pages[chosenContentPageId]?.content[Position]
                          ?.StoryBookObjects?.descriptionHeaderFont
                      : pages[chosenContentPageId]?.StoryBookObjects
                          ?.descriptionHeaderFont
                    : pages[chosenContentPageId]?.StoryBookObjects
                        ?.descriptionHeaderFont,
                  chosenPortfolio.ContentText
                )
              }}
            >
              {/* width:"calc(100% * 773/1159)", */}
              {(pages[chosenContentPageId]?.StoryBookObjects
                ?.descriptionHeader ||
                pages[chosenContentPageId]?.content[Position]?.StoryBookObjects
                  ?.descriptionHeader) && (
                <BlockContent
                  blocks={
                    pages[chosenContentPageId]?.content
                      ? pages[chosenContentPageId]?.content[Position]
                          ?.StoryBookObjects?.descriptionHeader
                        ? pages[chosenContentPageId]?.content[Position]
                            ?.StoryBookObjects?.descriptionHeader
                        : pages[chosenContentPageId]?.StoryBookObjects
                            .descriptionHeader
                      : pages[chosenContentPageId]?.StoryBookObjects
                          .descriptionHeader
                  }
                  serializers={serializers}
                ></BlockContent>
              )}
            </div>
            {/* FLAG FONT FAMILY */}
            <div
              className="SubpageBlockContentWrapper description OnGray"
              style={{
                fontFamily: FontUtil(
                  pages[chosenContentPageId]?.content
                    ? pages[chosenContentPageId]?.content[Position]
                        ?.StoryBookObjects?.descriptionHeaderFont
                      ? pages[chosenContentPageId]?.content[Position]
                          ?.StoryBookObjects?.descriptionHeaderFont
                      : pages[chosenContentPageId]?.StoryBookObjects
                          ?.descriptionHeaderFont
                    : pages[chosenContentPageId]?.StoryBookObjects
                        ?.descriptionHeaderFont,
                  chosenPortfolio.ContentText
                )
              }}
            >
              {(pages[chosenContentPageId]?.StoryBookObjects?.description ||
                pages[chosenContentPageId]?.content[Position]?.StoryBookObjects
                  ?.description) && (
                <BlockContent
                  blocks={
                    pages[chosenContentPageId]?.content
                      ? pages[chosenContentPageId]?.content[Position]
                          ?.StoryBookObjects?.description
                        ? pages[chosenContentPageId]?.content[Position]
                            ?.StoryBookObjects?.description
                        : pages[chosenContentPageId]?.content[Position]
                            .StoryBookObjects?.descriptionHeader
                        ? null
                        : pages[chosenContentPageId]?.StoryBookObjects
                            .description
                      : pages[chosenContentPageId]?.StoryBookObjects.description
                  }
                  serializers={serializers}
                ></BlockContent>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default SingleItemDisplay;
