import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Hls from 'hls.js';
import FontUtil from '../../utils/FontUtility';
// import omit from 'lodash/omit';
import getPosterSrc from '../../utils/getPosterSrc';

import PlayCircleFilledRoundedIcon from '@material-ui/icons/PlayCircleFilledRounded';
import PauseCircleFilledRoundedIcon from '@material-ui/icons/PauseCircleFilledRounded';

const propTypes = {
  assetDocument: PropTypes.object.isRequired,
  autoload: PropTypes.bool,
  autoplay: PropTypes.bool,
  loop: PropTypes.bool,
  showControls: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object,
  className: PropTypes.string,
  poster: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
  videoRef: PropTypes.ref,
  Size: PropTypes.object,
  override: PropTypes.bool,
  currentAssetKey: PropTypes.string,
  assetKey: PropTypes.string,
  chosenContentPageId: PropTypes.string,
  screensaverShow: PropTypes.bool,
  subtitles: PropTypes.array
};

// const handledPropNames = [
//   'assetDocument',
//   'autoload',
//   'autoplay',
//   'muted',
//   'showControls',
//   'style',
//   'className',
//   'poster',
//   'onClick',
//   'children',
//   'setIsVideoPlaying',
//   'isGuestMode',
//   'chosenPortfolio',
//   'SIP',
//   'SIG',
//   'isSelected',
//   'videoRef',
//   'isContent',
//   'Size',
//   'override'
// ];

class SanityMuxPlayer extends Component {
  state = {
    posterUrl: null,
    source: null,
    isLoading: true,
    error: null,
    isVisible: true,
    isPlaying: false,
    firstHover: false,
    autoPlayEnded: false,
    firstAutoPlay: false // NOTE: keeps track of whether if video autoplayed for gallery mode
  };

  static defaultProps = {
    autoload: true,
    autoplay: false,
    className: '',
    height: '',
    loop: false,
    muted: false,
    showControls: true,
    style: { width: '100%', height: 'auto' },
    width: '100%',
    poster: true,
    isContent: false,
    Size: undefined,
    override: false,
    chosenContentPageId: '0',
    screensaverShow: false,
    subtitles: []
  };

  videoContainer = React.createRef();
  hls = null;
  externalControls = false;

  // eslint-disable-next-line complexity
  static getDerivedStateFromProps(nextProps) {
    let source = null;
    let posterUrl = null;
    let isLoading = true;
    const { assetDocument, poster } = nextProps;
    if (assetDocument && assetDocument.status === 'preparing') {
      isLoading = 'MUX is processing the video';
    }
    if (assetDocument && assetDocument.status === 'ready') {
      isLoading = false;
    }
    if (assetDocument && assetDocument.playbackId) {
      source = `https://stream.mux.com/${assetDocument.playbackId}.m3u8`;
      // Load video poster only if explictly requested.
      if (poster === true) {
        posterUrl = getPosterSrc(assetDocument.playbackId, {
          time: assetDocument.thumbTime || 1,
          fitMode: 'preserve'
        });
      }
    }
    if (assetDocument && typeof assetDocument.status === 'undefined') {
      isLoading = false;
    }
    if (typeof poster === 'string') {
      posterUrl = poster;
    }
    return { isLoading, source, posterUrl };
  }

  componentDidMount() {
    if (!this.props.autoplay && !this.state.firstHover) {
      this.setState({ firstHover: true });
    }

    if (!this.props.autoplay) {
      this.setState({ autoPlayEnded: true });
    }

    this.video = React.createRef();
    this.PromiseRef = React.createRef();
    this.setState(SanityMuxPlayer.getDerivedStateFromProps(this.props));
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.source !== null &&
      this.video.current &&
      !this.video.current.src
    ) {
      this.setState({ error: null });
      this.attachVideo();
    }
    if (this.state.source !== null && this.state.source !== prevState.source) {
      this.setState({ error: null, showControls: false });
      if (this.hls) {
        this.hls.destroy();
      }
      this.attachVideo();
    }
    if (this.props.Screensaver) {
      if (this.props.autoplay && this.video.current.paused) {
        //this.video.current.muted = false;
        this.video.current.play();
      }
      return;
    }
    // check if current video is Selected in gallery
    if (this.video.current && !this.props.isSelected) {
      // if not (and not overriden through external props (like screensaver)) then pause video)
      if (!this.props.override) {
        this.video.current.pause();
      }
    }
    if (this.props.isSelected) {
      //console.log(this.props);
    }
    // BELOW WAS COMMENTED OUT DUE TO PERCEIVED REDUNDANCY. IT TRIGGERS AUTOPLAY OF VIDEOS - BUT AUTOPLAY IS A NATIVE HTML PROPERTY WITH NO NEED FOR JS TRIGGER
    // regardless, it causes the issue wherein attempting to hover off of an autoplay video in BeMethodical (so no external controls) will play a video even if video is paused
    // NOTE: below code was for autoplay to work within gallery mode so that when user changes slide it has the ability to automatically play video. That error happened because of addition of onPointerEnter and onPointerLeave causing the component to update on hover
    // NOTE: The `firstAutoPlay` state will keep track to only activitate the below code once.

    // autoplay video if autoplay and isSelected is true, also check if video was paused
    if (
      this.video.current &&
      this.props.isSelected &&
      this.props.autoplay &&
      this.video.current.paused &&
      this.props.chosenContentPageId === prevProps.chosenContentPageId &&
      !this.state.firstAutoPlay
    ) {
      //   //////onsole.log("prevProps.chosenContentPageId",prevProps)
      //   // play video
      try {
        this.PromiseRef.current = this.video.current
          .play()
          .then(() => {
            // NOTE: set firstAutoPlay false so that video won't play again automatically on hover off
            this.setState({ firstAutoPlay: true });
          })
          .catch(() => {
            console.error('ERROR: video autoplay');
          });
      } catch {
        //this.video.current.play();
      }
      if (this.externalControls) {
        this.video.current.pause();
      }
    } else if (
      this.props.autoplay &&
      !this.props.isSelected &&
      this.state.firstAutoPlay
    ) {
      // reset firstAutoPlay to false so that video will autoplay again
      // NOTE: this is needed so that video autoplays when user cycles the gallery the second time
      this.setState({ firstAutoPlay: false });
    }
  }

  componentWillUnmount() {
    // set video is playing to false
    if (this.video?.current) {
      this.video.current.muted = true;
      //this.video.current.pause();
      this.video.current.src = '';
    }
    this.props.setIsVideoPlaying(false);
  }

  getVideoElement() {
    return this.video && this.video.current;
  }

  play() {
    console.log('play video');
    ////onsole.log("!!!CallingPlay!")
    this.setState({ isPlaying: true });
    this.setState({ isVisible: false });
    this.setState({ autoPlayEnded: true });
    this.video.current.play();
  }

  pause() {
    this.externalControls = true;
    ////onsole.log("TRIGGERING PAUSE");
    this.setState({ isPlaying: false });
    this.setState({ autoPlayEnded: true });
    this.video.current.pause();
  }

  enterContent() {
    // //onsole.log("ENTERING VIDEO DIV");
    // TODO: Figure out why hover detection is needed (09/01/23)
    if (!this.state.firstHover) {
      this.setState({ firstHover: true });
    }
    this.setState({ isVisible: true });
  }

  leaveContent() {
    // //onsole.log("LEAVING VIDEO DIV");
    // //onsole.log("VIDEO DIV - playing?", this.state.isPlaying);
    if (
      (this.state.isPlaying || this.props.autoplay) &&
      this.props.Size?.W > 450
    ) {
      this.setState({ isVisible: false });
    }
  }

  clickContentPhone() {
    // TODO: Unsure purpose
    if (this.state.isVisible) {
      console.log('clickContentPhone', this.state.isVisible);
    }
  }

  attachVideo() {
    console.log('ATTACHVIDEO');
    const { autoload } = this.props;
    if (Hls.isSupported()) {
      this.hls = new Hls({ autoStartLoad: autoload });
      this.hls.loadSource(this.state.source);
      this.hls.attachMedia(this.video.current);
      this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
        if (this?.videoContainer?.current?.style) {
          this.videoContainer.current.style.display = 'flex';
        }
      });
      this.hls.on(Hls.Events.ERROR, (event, data) => {
        switch (data.type) {
          case Hls.ErrorTypes.NETWORK_ERROR:
            if (this?.videoContainer?.current?.style)
              this.videoContainer.current.style.display = 'none';
            this.setState({ error: data });
            break;
          case Hls.ErrorTypes.MEDIA_ERROR:
            // Don't output anything visible as these mostly are non-fatal
            break;
          default:
            if (this?.videoContainer?.current?.style)
              this.videoContainer.current.style.display = 'none';
            this.setState({ error: data });
        }
        console.error(data); // eslint-disable-line no-console
      });
    } else if (
      this.video.current.canPlayType('application/vnd.apple.mpegurl')
    ) {
      this.video.current.src = this.state.source;
      this.video.current.addEventListener('loadedmetadata', () => {
        this.videoContainer.current.style.display = 'flex';
      });
      this.video.current.addEventListener('error', () => {
        this.videoContainer.current.style.display = 'none';
        this.setState({
          error: {
            type: `${this.video.current.error.constructor.name} code ${this.video.current.error.code}`
          }
        });
        console.error(this.video.current.error); // eslint-disable-line no-console
      });
    }
  }

  handleVideoClick = (event) => {
    const { autoload } = this.props;
    if (!autoload) {
      this.setState({ showControls: true });
      if (this.hls) {
        this.hls.startLoad(0);
      }
    }
    if (this.props.onClick) {
      this.props.onClick(event);
    }
  };

  // eslint-disable-next-line complexity
  render() {
    // console.log('this.props.chosenPortfolio', this.props.chosenPortfolio);
    ////onsole.log("VISIBILITY VIDEO DIV", this.state.isVisible);

    const { posterUrl, isLoading, error } = this.state;
    const { assetDocument, autoload, children, isGuestMode, SIG, SIP } =
      this.props;
    let Mute = true;
    // NOTE: SIG means sound in guest pass, SIP is sound in presentation
    if (isGuestMode) {
      Mute = SIG !== undefined ? !SIG : false;
    } else {
      Mute = SIP !== undefined ? !SIP : true;
    }

    if (!assetDocument || !assetDocument.status) {
      return null;
    }
    // PING Font Family
    if (isLoading) {
      return (
        <div className={this.props.className} style={this.props.style}>
          <div
            style={{
              fontFamily: FontUtil(this.props.chosenPortfolio.SystemText)
            }}
            className="SanityMuxPlayerInfoContainer"
          >
            Waiting for MUX to complete the file...
          </div>
        </div>
      );
    }

    let showControls = autoload || this.state.showControls;
    if (this.props.showControls === false) {
      showControls = false;
    }

    let buttonRender;

    if (this.state.isPlaying || !this.state.autoPlayEnded) {
      buttonRender = (
        <PauseCircleFilledRoundedIcon
          style={{
            color: '#3b3b3b380',
            fontSize: '96px'
          }}
          onClick={() => this.pause()}
        />
      );
    } else {
      buttonRender = (
        <PlayCircleFilledRoundedIcon
          style={{
            color: '#3b3b3b380',
            fontSize: '96px'
          }}
          onClick={() => this.play()}
        />
      );
    }
    // console.log("!!!VTT",this.props,this.props.subtitles,this.props.chosenContentPageId, this.props.chosenPortfolio)
    // //onsole.log("!?size", this.props.Size.W);
    // //onsole.log("!?theme", this.props.chosenPortfolio.theme)
    // //onsole.log("!?autoplay", this.props.autoplay);
    // //onsole.log("!?override", this.props.override);
    // //onsole.log("!?SOUND", this.props.SIP);
    // //onsole.log("!?MUTE", Mute);
    // //onsole.log("!?IS CONTENT", this.props.isContent)
    // const videoProps = omit(this.props, handledPropNames);

    return (
      <div
        key={this.props.subtitles[0]?.label + this.state.source}
        className={this.props.className}
        style={{
          ...this.props.style,
          height: this.props.heightOverride
            ? this.props.heightOverride
            : '100%',
          width: '100%'
        }}
      >
        <div
          ref={this.videoContainer}
          style={{
            display: 'flex',
            height: this.props.objectFitOverride ? '100%' : '100%',
            justifyContent: 'center',
            alignContent: 'center'
          }}
          onPointerEnter={
            this.props.chosenPortfolio.theme === 'theme8' ||
            this.props.chosenPortfolio.theme === 'theme4'
              ? () => this.enterContent()
              : null
          }
          onPointerLeave={
            this.props.chosenPortfolio.theme === 'theme8' ||
            this.props.chosenPortfolio.theme === 'theme4'
              ? () => this.leaveContent()
              : null
          }
        >
          {(this.props.chosenPortfolio.theme === 'themeX' &&
            this.props.Size?.W > 450) ||
          (this.props.chosenPortfolio.theme === 'theme4' &&
            this.props.Size?.W <= 450) ? (
            <div
              className="overlay-play-button"
              style={{
                visibility:
                  this.state.isVisible &&
                  this.props.isContent &&
                  this.state.firstHover
                    ? 'visible'
                    : 'hidden'
              }}
            >
              {buttonRender}
            </div>
          ) : null}
          <video
            crossOrigin="anonymous"
            playsInline={true}
            style={{
              display: 'block',
              alignSelf: 'center',
              justifySelf: 'center',
              height:
                this.props.chosenPortfolio.theme === 'theme10'
                  ? 'auto'
                  : '100%',
              objectFit: this.props.objectFitOverride ? 'cover' : 'contain'
            }} // Needs to be here to avoid 1px gap in the bottom of controls
            onClick={this.handleVideoClick}
            controls={showControls}
            // controlsList="noplay"
            muted={Mute} // Force mute if autoplay (or it might not even work at all)this.props.autoplay ||
            autoPlay={this.props.autoplay}
            ref={this.video}
            poster={posterUrl}
            height={'100%'}
            width={'100%'}
            onEnded={() => {
              //////////onsole.log('ended');
              this.props.setIsVideoPlaying(false);
            }}
            onPlay={() => {
              ////onsole.log('!!!play2');
              this.props.setIsVideoPlaying(true);
            }}
            onPause={() => {
              ////onsole.log('!!!play2pause');
              this.props.setIsVideoPlaying(false);
            }}
            loop={
              this.props.chosenPortfolio?.KioskMode && !this.props.ScreenSaver
                ? false
                : this.props.loop
            }
            // {...videoProps}
          >
            {this.props.subtitles?.map((val, i) => {
              // console.log("!!!VTT",val,this.props.pdfs,this.props.pdfs[val.asset._ref].url)
              // console.log("!!!VTT",val)
              // return null
              return (
                <track
                  key={`k` + i}
                  default={val.default}
                  kind={val.kind}
                  label={val.label}
                  srcLang={val.srclng}
                  src={this.props.pdfs[val.file.asset._ref].url}
                />
              );
              // /default={val.default}
            })}
          </video>
        </div>
        {error && (
          <div className="SanityMuxPlayerInfoContainer SanityMuxPlayerError">
            There was an error loading this video ({error.type}).
          </div>
        )}
        {children}
      </div>
    );
  }
}

SanityMuxPlayer.propTypes = propTypes;

export default SanityMuxPlayer;
