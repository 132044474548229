import React from 'react';
import serializers from '../../../utils/scriptSerializers';
import { IconContext } from 'react-icons';
import BlockContent from '@sanity/block-content-to-react';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import FontUtil from '../../../utils/FontUtility';
const Scriptnote = ({
  companyConfig,
  chosenPortfolio,
  pages,
  chosenPageId,
  chosenContentPageId,
  showScriptnote,
  toggleScriptnote,
  toolbarMenuVisible,
  setToolbarMenuVisibility
}) => {
  const handleVisibility = () => {
    setToolbarMenuVisibility(true);
  };

  return (
    <>
      <style>
        {`
          div.ql-container {
            height: 100%;
            overflow: auto;
          }
          div.quill {
            color: black;
            max-height: 100%;
            display: inline-flex;
            flex-direction: column;
            width: 100%;
          }
          div.ql-editor {
            word-wrap: anywhere;
          }
          button.ql-link {
            display: none !important;
          }
        `}
      </style>
      <IconContext.Provider
        value={{
          className: 'menu-icon-md',
          style: {
            color: chosenPortfolio.accent_color
              ? chosenPortfolio.accent_color
              : companyConfig.accent_color
          }
        }}
      >
        <div
          style={{
            backgroundColor: chosenPortfolio?.primary_color
              ? `${chosenPortfolio.primary_color}BF`
              : '#3b3b3b59'
          }}
          className={`portfolio-notepad-container ${
            showScriptnote ? 'active' : 'hide'
          }`}
        >
          <div className="notepad-close-container">
            <CancelRoundedIcon
              onClick={() => {
                toggleScriptnote();
                handleVisibility();
              }}
              style={{
                fontSize: '3em',
                color: chosenPortfolio?.bulk_icon_override
                  ? chosenPortfolio.bulk_icon_override
                  : 'white',
                cursor: 'pointer'
              }}
            />
          </div>
          <div className="textarea-container">
            <div className="note-title-container">
              <p
                style={{
                  fontFamily: FontUtil(
                    chosenPortfolio.SystemText,
                    null,
                    "'Roboto Slab', serif"
                  )
                }}
              >
                Script
              </p>
            </div>
            {pages[chosenContentPageId] ? (
              <div className="page-title">
                <p
                  style={{
                    fontFamily: FontUtil(
                      chosenPortfolio.SystemText,
                      null,
                      "'Roboto Slab', serif"
                    )
                  }}
                >
                  {pages[chosenContentPageId]?.title}
                </p>
              </div>
            ) : null}
            <div className="scriptnote-text-container">
              {pages[chosenContentPageId] ? (
                typeof pages[chosenContentPageId]?.script === 'string' ? (
                  <p
                    style={{ fontFamily: FontUtil(chosenPortfolio.SystemText) }}
                  >
                    {pages[chosenContentPageId]
                      ? pages[chosenContentPageId]?.script
                      : ''}
                  </p>
                ) : (
                  <BlockContent
                    blocks={pages[chosenContentPageId]?.script}
                    serializers={serializers}
                  />
                )
              ) : null}
            </div>
          </div>
        </div>
      </IconContext.Provider>
    </>
  );
};

export default Scriptnote;
// <div
//   className={`portfolio-scriptnote-container ${
//     showScriptnote ? 'active' : 'hide'
//   }`}
//   style={{
//     border: `4px solid ${
//       chosenPortfolio.primary_color
//         ? chosenPortfolio.primary_color
//         : 'white'
//     }`
//   }}
// >
//   <div className="scriptnote-close">
//   <div
//       onClick={toggleScriptnote}
//       style={{
//         maskImage: `url(${X})`,
//         maskSize: '100% 100%',
//         maskRepeat: 'no-repeat',
//         WebkitMaskImage: `url(${X})`,
//         WebkitMaskRepeat: 'no-repeat',
//         WebkitMaskSize: '100% 100%',
//         backgroundColor: chosenPortfolio.primary_color ? chosenPortfolio.primary_color : companyConfig.primary_color,
//         height: '80px',
//         width: '80px',
//         cursor: `pointer`,
//         alignSelf: 'flex-end',
//         float: 'right',
//         objectFit: 'contain',
//         objectPosition: 'right',
//         margin: '16px',
//         marginBottom: '0px'
//       }}
//       className='scriptnote-close-button'
//     ></div>
//   </div>
// </div>
