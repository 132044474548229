import { connect } from 'react-redux';
import Image from './ProfileImage';
import {
  setShowUserSelect,
  setSelectedUser,
  setDefaultVisit,
  setAggregateSelected,
  setSessionDateString,
  setParallelDateString,
  setParallelSession,
  setSecondaryUserAggregateToggle,
  setSecondaryUserSessionToggle,
  setSelectedNotesSession,
  setSelectedNotesString,
  setSelectedNotesSessionObj,
  setEfficiencyAggregateSelected,
  setEfficiencyCompanyToggle,
  SetDaterangeDispatch
} from '../../../../actions';

const mapStateToProps = (state) => ({
  member: state.sanityContentReducer.member,
  showUserSelect: state.DashboardReducer.showUserSelect,
  userInfo: state.firestore.ordered.users,
  UserPool: state.DashboardReducer.UserPool,
  selectedUser: state.DashboardReducer.selectedUser,
  Size: state.portfolioReducer.Size
});

const mapDispatchToProps = (dispatch) => {
  return {
    setShowUserSelect: (bool) => dispatch(setShowUserSelect(bool)),
    setSelectedUser: (user) => dispatch(setSelectedUser(user)),
    setDefaultVisit: (visit) => dispatch(setDefaultVisit(visit)),
    setAggregateSelected: (value) => dispatch(setAggregateSelected(value)),
    setSessionDateString: (string) => dispatch(setSessionDateString(string)),
    setParallelDateString: (string) => dispatch(setParallelDateString(string)),
    setParallelSession: (visit) => dispatch(setParallelSession(visit)),
    setSecondaryUserSessionToggle: (toggle) =>
      dispatch(setSecondaryUserSessionToggle(toggle)),
    setSecondaryUserAggregateToggle: (toggle) =>
      dispatch(setSecondaryUserAggregateToggle(toggle)),
    setSelectedNotesSession: (id) => dispatch(setSelectedNotesSession(id)),
    setSelectedNotesString: (obj) => dispatch(setSelectedNotesString(obj)),
    setSelectedNotesSessionObj: (obj) =>
      dispatch(setSelectedNotesSessionObj(obj)),
    setEfficiencyAggregateSelected: (value) =>
      dispatch(setEfficiencyAggregateSelected(value)),
    setEfficiencyCompanyToggle: (toggle) =>
      dispatch(setEfficiencyCompanyToggle(toggle)),
    SetDaterangeDispatch: (value) => dispatch(SetDaterangeDispatch(value))
  };
};

const HeaderImageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Image);

export default HeaderImageContainer;
