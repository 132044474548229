import React, { useState } from 'react';
import { urlFor } from '../../../utils/sanityHelper';
import SanityMuxPlayer from '../../../containers/MuxPlayer';

const GalleryThumbsContent = ({
  chosenPortfolio,
  foundPage,
  child,
  index,
  onNavClick,
  videos,
  currentNavItem
}) => {
  //Flag Font Family
  const [opacityBack, setOpacityBack] = useState(1);
  const [isVisible, setIsVisible] = useState('hidden');

  const enterContent = () => {
    setOpacityBack(0.4);
    setIsVisible('visible');
  };

  const leaveContent = () => {
    setOpacityBack(1);
    setIsVisible('hidden');
  };

  let overlay = null;
  if (
    chosenPortfolio &&
    (chosenPortfolio.theme === 'theme2' || chosenPortfolio.theme === 'theme7')
  ) {
    overlay = (
      <button
        style={{
          backgroundColor: 'transparent',
          height: '200px',
          width: '200px',
          borderRadius: '12px',
          borderWidth: '5px',
          borderColor: chosenPortfolio.text_color
            ? chosenPortfolio.text_color
            : 'white',
          cursor: 'pointer',
          visibility: isVisible,
          position: 'absolute',
          zIndex: 2,
          overflow: 'hidden'
        }}
      >
        <p
          style={{
            color: chosenPortfolio.text_color
              ? chosenPortfolio.text_color
              : 'white',
            marginBlockStart: '0px',
            marginBlockEnd: '0px',
            fontFamily: "'Oswald', sans-serif",
            fontSize: '1.5em',
            letterSpacing: '0.1em',
            textShadow: chosenPortfolio.accent_color
              ? `-2px 0 ${chosenPortfolio.accent_color}, 0 2px ${chosenPortfolio.accent_color}, 2px 0 ${chosenPortfolio.accent_color}, 0 -2px ${chosenPortfolio.accent_color}`
              : `-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black`
          }}
        >
          {foundPage.title}
        </p>
        <p
          style={{
            color: chosenPortfolio.text_color
              ? chosenPortfolio.text_color
              : 'white',
            marginBlockStart: '0px',
            marginBlockEnd: '0px',
            fontFamily: "'Oswald', sans-serif",
            fontSize: '1.3em',
            letterSpacing: '0.085em',
            textShadow: chosenPortfolio.accent_color
              ? `-2px 0 ${chosenPortfolio.accent_color}, 0 2px ${chosenPortfolio.accent_color}, 2px 0 ${chosenPortfolio.accent_color}, 0 -2px ${chosenPortfolio.accent_color}`
              : `-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black`
          }}
        >
          {foundPage.subtitle}
        </p>
      </button>
    );
  }

  let iframeOverlay = null;
  if (
    chosenPortfolio &&
    (chosenPortfolio.theme === 'theme2' || chosenPortfolio.theme === 'theme7')
  ) {
    iframeOverlay = (
      <div
        style={{
          display: 'block',
          position: 'absolute',
          width: '100%',
          height: '100%'
        }}
      ></div>
    );
  }

  //////onsole.log("FOUND PAGE UPPER", foundPage);
  //////onsole.log("FOUND PAGE", foundPage.content);

  // ultimate tile displayed
  let thumbnailTile = null;
  // conditional assignment value for holding asset of a toggle menu
  let toggleThumbnail;

  // initial setup for thumbnails
  let htmlThumb =
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    foundPage.content
      ? foundPage.content.find((piece) => piece._type === 'imageLinks')
      : null;
  let galImgThumb =
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    foundPage.content
      ? foundPage.content.find((piece) => piece._type === 'galleryImage')
      : null;
  let pdfThumb =
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    foundPage.content
      ? foundPage.content.find(
          (piece) =>
            piece._type === 'galleryPdf' ||
            piece._type === 'galleryFullWidthPdf'
        )
      : null;
  let videoThumb =
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    foundPage.content
      ? foundPage.content.find((piece) => piece.video)
      : null;
  let toggleThumb =
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    foundPage.content
      ? foundPage.content.find((piece) => piece._type === 'toggleMenu')
      : null;
  let sliderThumb =
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    foundPage.content
      ? foundPage.content.find((piece) => piece._type === 'galleryBASlider')
      : null;

  // asset setup for later conditional render
  let videoPreview =
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    foundPage.content &&
    foundPage.content.find((piece) => piece.video) !== undefined
      ? videoThumb.video.asset._ref
      : null;
  let pdfThumbnail =
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    foundPage.content &&
    foundPage.content.find(
      (piece) =>
        piece._type === 'galleryPdf' || piece._type === 'galleryFullWidthPdf'
    ) !== undefined
      ? pdfThumb.thumbnail.asset._ref
      : null;
  let galleryImage =
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    foundPage.content &&
    foundPage.content.find((piece) => piece._type === 'galleryImage') !==
      undefined
      ? galImgThumb.image.asset._ref
      : null;
  let sliderThumbnail =
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    foundPage.content &&
    foundPage.content.find((piece) => piece._type === 'galleryBASlider') !==
      undefined
      ? sliderThumb.Bimage.asset._ref
      : null;
  if (
    toggleThumb === undefined ||
    toggleThumb === null ||
    (foundPage?._type !== 'gallery' && foundPage?._type !== 'sliderGallery') ||
    !foundPage?.content
  ) {
    toggleThumbnail = undefined;
  } else if (toggleThumb.content[0]._type === 'galleryImage') {
    toggleThumbnail = toggleThumb.content[0].image.asset._ref;
  } else if (
    toggleThumb.content[0]._type === 'galleryPDF' ||
    toggleThumb.content[0]._type === 'galleryFullWidthPdf'
  ) {
    toggleThumbnail = toggleThumb.content[0].thumbnail.asset._ref;
  } else if (toggleThumb.content[0]._type === 'galleryVideo') {
    let videoRef = toggleThumb.content[0].video.asset._ref;
    toggleThumbnail = videos[videoRef];
  } else if (toggleThumb.content[0]._type === 'galleryBASlider') {
    toggleThumbnail = toggleThumb.content[0].Bimage.asset._ref;
  } else {
    toggleThumbnail = undefined;
  }
  // iframe content + final video setup
  let webUrl = foundPage._type === 'website' ? foundPage.url : null;
  let htmlContentImage =
    foundPage._type === 'htmlContent' &&
    foundPage.content &&
    foundPage.content.find((piece) => piece._type === 'imageLinks') !==
      undefined
      ? htmlThumb.image.asset._ref
      : null;
  let finalVideo = videoPreview !== null ? videos[videoPreview] : null;

  // resizing algo for theme 2 (BeUnique)
  let imageHeight;
  let imageWidth;

  if (
    chosenPortfolio?.theme === 'theme2' &&
    (!currentNavItem || currentNavItem?.children.length === 1)
  ) {
    imageHeight = '80%';
  } else if (
    chosenPortfolio?.theme === 'theme2' &&
    currentNavItem?.children.length === 2
  ) {
    imageHeight = '60%';
  } else {
    imageHeight = '100%';
  }

  if (
    chosenPortfolio?.theme === 'theme2' &&
    (!currentNavItem || currentNavItem?.children.length === 1)
  ) {
    imageWidth = '80%';
  } else {
    imageWidth = '100%';
  }

  // begin conditional render
  if (
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    galleryImage !== null
  ) {
    thumbnailTile = (
      <div className="big-nav" onClick={() => onNavClick(child, index)}>
        <div
          className="portfolio-content"
          onMouseOver={
            chosenPortfolio?.theme === 'theme2' ||
            chosenPortfolio?.theme === 'theme7'
              ? () => enterContent()
              : null
          }
          onMouseLeave={
            chosenPortfolio?.theme === 'theme2' ||
            chosenPortfolio?.theme === 'theme7'
              ? () => leaveContent()
              : null
          }
        >
          <img
            src={urlFor(galleryImage).auto('format').url()}
            alt="logo"
            className="portfolio-single-image"
            style={{
              opacity:
                chosenPortfolio && chosenPortfolio.theme === 'theme2'
                  ? opacityBack
                  : 1,
              width: imageWidth,
              height: imageHeight
            }}
          />
          {overlay}
        </div>
      </div>
    );
  } else if (
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    pdfThumbnail !== null
  ) {
    thumbnailTile = (
      <div className="big-nav" onClick={() => onNavClick(child, index)}>
        <div
          className="portfolio-content"
          onMouseOver={
            chosenPortfolio?.theme === 'theme2' ||
            chosenPortfolio?.theme === 'theme7'
              ? () => enterContent()
              : null
          }
          onMouseLeave={
            chosenPortfolio?.theme === 'theme2' ||
            chosenPortfolio?.theme === 'theme7'
              ? () => leaveContent()
              : null
          }
        >
          <img
            src={urlFor(pdfThumbnail).auto('format').url()}
            alt="logo"
            className="portfolio-single-image"
            style={{
              opacity:
                chosenPortfolio && chosenPortfolio.theme === 'theme2'
                  ? opacityBack
                  : 1,
              width: imageWidth,
              height: imageHeight
            }}
          />
          {overlay}
        </div>
      </div>
    );
  } else if (
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    videoPreview !== null
  ) {
    thumbnailTile = (
      <div className="big-nav" onClick={() => onNavClick(child, index)}>
        <div
          className="portfolio-content"
          onMouseOver={
            chosenPortfolio?.theme === 'theme2' ||
            chosenPortfolio?.theme === 'theme7'
              ? () => enterContent()
              : null
          }
          onMouseLeave={
            chosenPortfolio?.theme === 'theme2' ||
            chosenPortfolio?.theme === 'theme7'
              ? () => leaveContent()
              : null
          }
        >
          <SanityMuxPlayer
            assetDocument={finalVideo}
            autoload={true}
            autoplay={false}
            loop={false}
            muted={false}
            SIG={false}
            SIP={false}
            showControls={false}
            style={{}}
            posterurl={`https://image.mux.com/${finalVideo.playbackId}/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=1`}
            type="galleryVideo"
            isSelected={false}
          />
          {overlay}
        </div>
      </div>
    );
  } else if (
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    htmlContentImage !== null
  ) {
    thumbnailTile = (
      <div className="big-nav" onClick={() => onNavClick(child, index)}>
        <div
          className="portfolio-content"
          onMouseOver={
            chosenPortfolio?.theme === 'theme2' ||
            chosenPortfolio?.theme === 'theme7'
              ? () => enterContent()
              : null
          }
          onMouseLeave={
            chosenPortfolio?.theme === 'theme2' ||
            chosenPortfolio?.theme === 'theme7'
              ? () => leaveContent()
              : null
          }
        >
          <img
            src={urlFor(htmlContentImage).auto('format').url()}
            alt="logo"
            className="portfolio-single-image"
            style={{
              opacity:
                chosenPortfolio && chosenPortfolio.theme === 'theme2'
                  ? opacityBack
                  : 1,
              width: imageWidth,
              height: imageHeight
            }}
          />
          {overlay}
        </div>
      </div>
    );
  } else if (foundPage._type === 'website' && webUrl !== null) {
    thumbnailTile = (
      <div className="big-nav" onClick={() => onNavClick(child, index)}>
        <div
          className="portfolio-content"
          onMouseOver={
            chosenPortfolio?.theme === 'theme2' ||
            chosenPortfolio?.theme === 'theme7'
              ? () => enterContent()
              : null
          }
          onMouseLeave={
            chosenPortfolio?.theme === 'theme2' ||
            chosenPortfolio?.theme === 'theme7'
              ? () => leaveContent()
              : null
          }
        >
          <div
            className="iframe-container"
            style={{
              opacity:
                chosenPortfolio &&
                (chosenPortfolio.theme === 'theme2' ||
                  chosenPortfolio.theme === 'theme7')
                  ? opacityBack
                  : 1,
              width: imageWidth,
              height: imageHeight
            }}
          >
            <iframe
              scrolling="no"
              title="iframe"
              style={{ backgroundColor: 'white' }}
              allowFullScreen
              src={webUrl}
            ></iframe>
          </div>
          {overlay}
          {iframeOverlay}
        </div>
      </div>
    );
  } else if (
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    sliderThumbnail !== undefined &&
    sliderThumbnail !== null
  ) {
    thumbnailTile = (
      <div className="big-nav" onClick={() => onNavClick(child, index)}>
        <div
          className="portfolio-content"
          onMouseOver={
            chosenPortfolio?.theme === 'theme2' ||
            chosenPortfolio?.theme === 'theme7'
              ? () => enterContent()
              : null
          }
          onMouseLeave={
            chosenPortfolio?.theme === 'theme2' ||
            chosenPortfolio?.theme === 'theme7'
              ? () => leaveContent()
              : null
          }
        >
          <img
            src={urlFor(sliderThumbnail).auto('format').url()}
            alt="logo"
            className="portfolio-single-image"
            style={{
              opacity:
                chosenPortfolio && chosenPortfolio.theme === 'theme2'
                  ? opacityBack
                  : 1,
              width: imageWidth,
              height: imageHeight
            }}
          />
          {overlay}
        </div>
      </div>
    );
  } else if (
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    toggleThumb !== undefined &&
    toggleThumb !== null
  ) {
    if (toggleThumb.content[0]._type === 'galleryImage') {
      thumbnailTile = (
        <div className="big-nav" onClick={() => onNavClick(child, index)}>
          <div
            className="portfolio-content"
            onMouseOver={
              chosenPortfolio?.theme === 'theme2' ||
              chosenPortfolio?.theme === 'theme7'
                ? () => enterContent()
                : null
            }
            onMouseLeave={
              chosenPortfolio?.theme === 'theme2' ||
              chosenPortfolio?.theme === 'theme7'
                ? () => leaveContent()
                : null
            }
          >
            <img
              src={urlFor(toggleThumbnail).auto('format').url()}
              alt="logo"
              className="portfolio-single-image"
              style={{
                opacity:
                  chosenPortfolio && chosenPortfolio.theme === 'theme2'
                    ? opacityBack
                    : 1,
                width: imageWidth,
                height: imageHeight
              }}
            />
            {overlay}
          </div>
        </div>
      );
    } else if (
      toggleThumb.content[0]._type === 'galleryPDF' ||
      toggleThumb.content[0]._type === 'galleryFullWidthPdf'
    ) {
      thumbnailTile = (
        <div className="big-nav" onClick={() => onNavClick(child, index)}>
          <div
            className="portfolio-content"
            onMouseOver={
              chosenPortfolio?.theme === 'theme2' ||
              chosenPortfolio?.theme === 'theme7'
                ? () => enterContent()
                : null
            }
            onMouseLeave={
              chosenPortfolio?.theme === 'theme2' ||
              chosenPortfolio?.theme === 'theme7'
                ? () => leaveContent()
                : null
            }
          >
            <img
              src={urlFor(toggleThumbnail).auto('format').url()}
              alt="logo"
              className="portfolio-single-image"
              style={{
                opacity:
                  chosenPortfolio && chosenPortfolio.theme === 'theme2'
                    ? opacityBack
                    : 1,
                width: imageWidth,
                height: imageHeight
              }}
            />
            {overlay}
          </div>
        </div>
      );
    } else if (toggleThumb.content[0]._type === 'galleryVideo') {
      thumbnailTile = (
        <div className="big-nav" onClick={() => onNavClick(child, index)}>
          <div
            className="portfolio-content"
            onMouseOver={
              chosenPortfolio?.theme === 'theme2' ||
              chosenPortfolio?.theme === 'theme7'
                ? () => enterContent()
                : null
            }
            onMouseLeave={
              chosenPortfolio?.theme === 'theme2' ||
              chosenPortfolio?.theme === 'theme7'
                ? () => leaveContent()
                : null
            }
          >
            <SanityMuxPlayer
              assetDocument={toggleThumbnail}
              autoload={true}
              autoplay={false}
              loop={false}
              muted={false}
              SIG={false}
              SIP={false}
              showControls={false}
              style={{}}
              posterurl={`https://image.mux.com/${toggleThumbnail.playbackId}/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=1`}
              type="galleryVideo"
              isSelected={false}
            />
            {overlay}
          </div>
        </div>
      );
    } else if (toggleThumb.content[0]._type === 'galleryBASlider') {
      thumbnailTile = (
        <div className="big-nav" onClick={() => onNavClick(child, index)}>
          <div
            className="portfolio-content"
            onMouseOver={
              chosenPortfolio?.theme === 'theme2' ||
              chosenPortfolio?.theme === 'theme7'
                ? () => enterContent()
                : null
            }
            onMouseLeave={
              chosenPortfolio?.theme === 'theme2' ||
              chosenPortfolio?.theme === 'theme7'
                ? () => leaveContent()
                : null
            }
          >
            <img
              src={urlFor(toggleThumbnail).auto('format').url()}
              alt="logo"
              className="portfolio-single-image"
              style={{
                opacity:
                  chosenPortfolio && chosenPortfolio.theme === 'theme2'
                    ? opacityBack
                    : 1,
                width: imageWidth,
                height: imageHeight
              }}
            />
            {overlay}
          </div>
        </div>
      );
    } else {
      thumbnailTile = (
        <div
          className="big-nav-thumb"
          onClick={() => onNavClick(child, index)}
          onMouseOver={
            chosenPortfolio?.theme === 'theme2' ||
            chosenPortfolio?.theme === 'theme7'
              ? () => enterContent()
              : null
          }
          onMouseLeave={
            chosenPortfolio?.theme === 'theme2' ||
            chosenPortfolio?.theme === 'theme7'
              ? () => leaveContent()
              : null
          }
        >
          {chosenPortfolio.portfolio_logo.asset._ref ? (
            <div className="logo-thumb">
              <img
                src={urlFor(chosenPortfolio.portfolio_logo.asset._ref)
                  .auto('format')
                  .width(400)
                  .url()}
                alt="logo"
                style={{
                  opacity:
                    chosenPortfolio &&
                    (chosenPortfolio.theme === 'theme2' ||
                      chosenPortfolio.theme === 'theme7')
                      ? opacityBack
                      : 1
                }}
              />
            </div>
          ) : null}
          {overlay}
        </div>
      );
    }
  } else {
    thumbnailTile = (
      <div
        className="big-nav-thumb"
        onClick={() => onNavClick(child, index)}
        onMouseOver={
          chosenPortfolio?.theme === 'theme2' ||
          chosenPortfolio?.theme === 'theme7'
            ? () => enterContent()
            : null
        }
        onMouseLeave={
          chosenPortfolio?.theme === 'theme2' ||
          chosenPortfolio?.theme === 'theme7'
            ? () => leaveContent()
            : null
        }
      >
        {chosenPortfolio.portfolio_logo.asset._ref ? (
          <div className="logo-thumb">
            <img
              src={urlFor(chosenPortfolio.portfolio_logo.asset._ref)
                .auto('format')
                .width(400)
                .url()}
              alt="logo"
              style={{
                opacity:
                  chosenPortfolio &&
                  (chosenPortfolio.theme === 'theme2' ||
                    chosenPortfolio.theme === 'theme7')
                    ? opacityBack
                    : 1
              }}
            />
          </div>
        ) : null}
        {overlay}
      </div>
    );
  }

  return thumbnailTile;
};

export default GalleryThumbsContent;
