import {
  TOGGLE_BRIEFCASE,
  ADD_TO_BRIEFCASE,
  REMOVE_FROM_BRIEFCASE,
  CLEAR_BRIEFCASE,
  SET_BOOLEAN_NEW_ITEMS
} from '../constants/actionTypes';

export const toggleBriefcase = () => ({
  type: TOGGLE_BRIEFCASE
});

export const addToBriefcase = (obj) => ({
  type: ADD_TO_BRIEFCASE,
  item: obj
});

export const removeFromBriefcase = (id) => ({
  type: REMOVE_FROM_BRIEFCASE,
  id
});

export const clearBriefcase = () => ({
  type: CLEAR_BRIEFCASE
});

export const setBooleanNewItems = (bool) => ({
  type: SET_BOOLEAN_NEW_ITEMS,
  data: bool
});
