import { connect } from 'react-redux';
import {
  AddShoppingCart,
  ShoppingCart,
  PersonAdd,
  Fullscreen,
  FullscreenExit,
  NoteAdd,
  SpeakerNotes,
  Favorite,
  FavoriteBorder,
  Close,
  ChevronRight,
  ChevronLeft,
  SlideUpArrow,
  BackButton,
  EndDemo,
  CustomIconOne,
  ZoomInIcon,
  ZoomOutIcon,
  DownloadIcon,
  AnnotatorIcon
} from './Icons';

const mapStateToProps = (state) => {
  return {
    chosenPortfolio: state.portfolioReducer.chosenPortfolio,
    userInfo: state.firestore.ordered.users,
    companyConfig: state.sanityContentReducer.companyConfig,
    images: state.sanityContentReducer.images,
    isGuestMode: state.guestReducer.isGuestMode,
    Size: state.portfolioReducer.Size
  };
};
// eslint-disable-next-line
export default {
  AddShoppingCart: connect(mapStateToProps)(AddShoppingCart),
  ShoppingCart: connect(mapStateToProps)(ShoppingCart),
  PersonAdd: connect(mapStateToProps)(PersonAdd),
  Fullscreen: connect(mapStateToProps)(Fullscreen),
  FullscreenExit: connect(mapStateToProps)(FullscreenExit),
  NoteAdd: connect(mapStateToProps)(NoteAdd),
  SpeakerNotes: connect(mapStateToProps)(SpeakerNotes),
  Close: connect(mapStateToProps)(Close),
  Favorite: connect(mapStateToProps)(Favorite),
  FavoriteBorder: connect(mapStateToProps)(FavoriteBorder),
  ChevronRight: connect(mapStateToProps)(ChevronRight),
  ChevronLeft: connect(mapStateToProps)(ChevronLeft),
  SlideUpArrow: connect(mapStateToProps)(SlideUpArrow),
  BackButton: connect(mapStateToProps)(BackButton),
  EndDemo: connect(mapStateToProps)(EndDemo),
  CustomIconOne: connect(mapStateToProps)(CustomIconOne),
  ZoomInIcon: connect(mapStateToProps)(ZoomInIcon),
  ZoomOutIcon: connect(mapStateToProps)(ZoomOutIcon),
  DownloadIcon: connect(mapStateToProps)(DownloadIcon),
  AnnotatorIcon: connect(mapStateToProps)(AnnotatorIcon)
};
