import { connect } from 'react-redux';
import { compose } from 'redux';

import { isLoaded, isEmpty, firestoreConnect } from 'react-redux-firebase';
import EmbedPage from './EmbedPage';
import {
  guestPrepPortfolio,
  resetPortfolioMode,
  setPortfolioSessionId,
  addSessionToSanity,
  SetGuestData
} from '../../actions';

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  guestPass: state.firestore.ordered.embed_links,
  Order: state.firestore.ordered,
  chosenPortfolio: state.portfolioReducer.chosenPortfolio,
  sanityContentStatus: state.sanityContentReducer.contentStatus,
  companyConfig: state.sanityContentReducer.companyConfig,
  expireDate: state.guestReducer.expireDate,
  expireDateStatus: state.guestReducer.status
});

const mapDispatchToProps = (dispatch) => {
  return {
    guestPrepPortfolio: (id, url) => dispatch(guestPrepPortfolio(id, url)),
    resetPortfolioMode: () => dispatch(resetPortfolioMode()),
    setPortfolioSessionId: (id) => dispatch(setPortfolioSessionId(id)),
    addSessionToSanity: (obj) => dispatch(addSessionToSanity(obj)),
    SetGuestData: (data) => dispatch(SetGuestData(data))
  };
};

const EmbedPageContainer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    let id = props.match.params.id;
    let collection = [];
    let authReady = isLoaded(props.auth) && !isEmpty(props.auth);
    //////////onsole.log("Order: ",props.Order)
    // Return empty if there are no auth
    if (!authReady || !id) return collection;
    else {
      collection = [{ collection: 'embed_links', doc: id }];
    }
    //////////onsole.log("Collection: ",collection);
    // grab company id once guestPass is filled
    if (
      props.guestPass !== undefined &&
      props.guestPass[0]?.company_id !== undefined
    ) {
      collection = [
        { collection: 'embed_links', doc: id },
        {
          collection: 'companies',
          doc: props.guestPass[0].company_id
        }
      ];
    }
    return collection;
  })
)(EmbedPage);

export default EmbedPageContainer;
