import { connect } from 'react-redux';
import Bars from './Bars';
const mapStateToProps = (state) => ({
  // member: state.sanityContentReducer.member,
  // userInfo: state.firestore.ordered.users,
});
const mapDispatchToProps = (dispatch) => {
  return {};
};

const BarsContainer = connect(mapStateToProps, mapDispatchToProps)(Bars);

export default BarsContainer;
