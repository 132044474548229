import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import styled from 'styled-components';
import Button from '../../Button';
import { urlFor } from '../../../utils/sanityHelper';
//import { BorderColor } from '@material-ui/icons';

const StyledEngageChoiceBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 900px;
  width: 1421px;
  height: 725px;
  border-radius: 50px;
  background-color: transparent;
  flex-direction: row;

  @media (max-width: 1367px) {
    min-width: 0;
    height: 100%;
  }

  @media (max-width: 450px) and (orientation: portrait) {
    flex-direction: column;
  }
`;

const StyledEngageChoiceLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 50%;
  padding: 25px;

  img {
    width: calc(100% - 48px);
    height: auto;
  }
`;

const StyledEngageRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 50%;
  height: 348px;
  border-radius: 24px;
  background-color: white;

  @media (max-width: 450px) and (orientation: portrait) {
    width: 90%;
  }

  h1 {
    @media (max-width: 450px) and (orientation: portrait) {
      font-size: 1.5em;
      text-align: center;
    }
  }

  h3 {
    text-align: center;
  }

  button {
    margin-top: 34px;
  }
`;

const StyledEngageRightMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 30px;
  //margin-top:24px;
  width: 80%;
  @media (max-width: 450px) and (orientation: portrait) {
    width: 100vw;
    justify-content: center;
  }

  div {
    @media (max-width: 450px) and (orientation: portrait) {
      margin-right: 20px;
    }
  }
`;

const StyledMenuText = styled.div`
  font-size: 20px;
  color: ${(props) => (props.disabled ? 'grey' : 'black')};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};

  :hover {
    text-decoration: ${(props) => (props.disabled ? 'none' : 'underline')};
  }
`;
//font-family: ${FontUtil(chosenPortfolio.SystemText)};

const EngageChoose = ({
  SetTraining,
  dropdownOptions,
  select,
  setSelect,
  setMode,
  setPortfolioId,
  companyConfig,
  setSelectedUser,
  setLoginInputSelected,
  setLoginInputSelectedCount,
  loginInputSelected,
  loginInputSelectedCount,
  Size
}) => {
  //////onsole.log("LOGIN INPUT", loginInputSelected)
  // react-select styles
  const styles = {
    control: (base) => ({
      ...base,
      width: '300px'
      //WebkitOverflowScrolling:"touch"
    }),
    container: (base) => ({
      ...base,
      textAlign: 'left',
      fontSize: '22px'
      //WebkitOverflowScrolling:"touch"
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0
    }),
    clearIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0
    })
  };

  const closeFilter = (event) => {
    if (event.target && event.target.id !== 'bypass') {
      closeActFilter();
    } else {
      setLoginInputSelected(true);
    }
  };

  const closeActFilter = () => {
    setLoginInputSelected(false);
    //////onsole.log("SELECTED: ", loginInputSelected);
    window.removeEventListener('click', closeFilter);
  };

  const toggleFilter = () => {
    //////onsole.log("BEGIN TOGGLE");
    if (Size.W >= 1367) {
      return;
    }
    if (loginInputSelected === false) {
      window.setTimeout(() => {
        window.addEventListener('click', closeFilter);
      }, 50);
    }
    setLoginInputSelected(true);
    //////onsole.log("SELECTED: ", loginInputSelected);
  };

  return (
    <Fragment>
      <h1
        style={{}}
        onPointerDown={(eve) => {
          //////onsole.log('pointerDown') fontFamily:FontUtil(chosenPortfolio.SystemText)
        }}
      >
        {select === null ? 'Choose Portfolio' : select.label}
      </h1>
      <Select
        //PING
        //menuShouldBlockScroll={true}
        //menuShouldScrollIntoView={true}
        //onMenuOpen ={()=>{setTimeout(()=>{document.body.scrollBy({top:999,left:0,behavior:'auto'})},350)}}
        isSearchable={true}
        classNamePrefix={'is-momentumScrollable '}
        styles={styles}
        onChange={(select) => {
          setSelect(select);
          setPortfolioId(select.value);
        }}
        options={dropdownOptions}
        placeholder="Choose Portfolio"
        id="bypass"
        onFocus={() => toggleFilter()}
      />
      <Button
        text="Start"
        onClick={() => {
          setPortfolioId(select.value);
          setMode('default');
        }}
        darken={true}
        color={
          companyConfig?.text_color === '#ffffff'
            ? '#3b3b3b'
            : companyConfig.text_color
        }
        // backgroundColor={companyConfig.accent_color}
        backgroundColor="transparent"
        disabled={select === null}
        style={{
          borderStyle: 'solid',
          borderColor:
            companyConfig?.text_color === '#ffffff'
              ? '#3b3b3b'
              : companyConfig.text_color,
          borderRadius: '24px',
          marginTop: '24px'
          //fontFamily:FontUtil(chosenPortfolio.SystemText)
        }}
      />
    </Fragment>
  );
};

const EngageChoiceBox = ({
  dropdownOptions,
  error,
  setMode,
  logout,
  setPortfolioId,
  SetTraining,
  userInfo,
  companyConfig,
  images,
  setLoginInputSelected,
  setLoginInputSelectedCount,
  loginInputSelected,
  loginInputSelectedCount,
  Size
}) => {
  const [select, setSelect] = useState(null);
  const history = useHistory();

  return (
    <StyledEngageChoiceBox
      style={{
        alignItems: loginInputSelected && Size.W > 451 ? 'flex-start' : 'center'
        //        fontFamily:FontUtil(chosenPortfolio.SystemText)
      }}
    >
      <StyledEngageChoiceLogo>
        {companyConfig.logo !== undefined && (
          <img
            src={urlFor(images[companyConfig.logo.asset._ref])
              .auto('format')
              .width(600)
              .url()}
            alt="logo"
            type="image"
          />
        )}
      </StyledEngageChoiceLogo>
      <StyledEngageRight
        style={{
          height: loginInputSelected ? 'auto' : '348px'
          //fontFamily:FontUtil(chosenPortfolio.SystemText)
        }}
      >
        <EngageChoose
          dropdownOptions={dropdownOptions}
          error={error}
          setMode={setMode}
          select={select}
          setSelect={setSelect}
          setPortfolioId={setPortfolioId}
          companyConfig={companyConfig}
          setLoginInputSelected={setLoginInputSelected}
          loginInputSelected={loginInputSelected}
          loginInputSelectedCount={loginInputSelectedCount}
          setLoginInputSelectedCount={setLoginInputSelectedCount}
          Size={Size}
        />
        <StyledEngageRightMenu>
          {loginInputSelected ? null : (
            <StyledMenuText
              onClick={() => {
                setPortfolioId(select.value);
                setMode('training');
                //setTimeout(()=>{SetTraining({Name:userInfo[0].name,Email:userInfo[0].email})},5)
                SetTraining({
                  Name: userInfo[0].name,
                  Email: userInfo[0].email
                });
              }}
              disabled={select === null}
            >
              PortfolioU
            </StyledMenuText>
          )}
          {loginInputSelected ? null : (
            <StyledMenuText onClick={logout}>Logout</StyledMenuText>
          )}
          {loginInputSelected ? null : (
            <StyledMenuText
              onClick={() => {
                history.push('/dashboard');
              }}
            >
              Portal
            </StyledMenuText>
          )}
        </StyledEngageRightMenu>
      </StyledEngageRight>
    </StyledEngageChoiceBox>
  );
};

export default EngageChoiceBox;
