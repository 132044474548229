import React, { useState } from 'react';
import * as Scale from '../../../../utils/Scalars';

const Topics = ({ ele, MaxTime, onClick, IsSelected, Size }) => {
  //////onsole.log("TOPIC ELEMENT", ele)
  // style={{gridRow:"3/3"}}
  // //////onsole.log("Topics",MaxTime,ele.Time, ele)

  const [hoverChosen, setHoverChosen] = useState('');
  //onsole.log("TOPIC", ele);

  return (
    <div
      className="topic"
      style={
        IsSelected
          ? {
              backgroundColor: 'white',
              boxShadow: '3px 3px 5px #808080',
              cursor: 'pointer'
            }
          : hoverChosen === ele.id
          ? { backgroundColor: '#d4d4d4', cursor: 'pointer' }
          : { cursor: 'pointer' }
      }
      onClick={(eve) => {
        onClick(eve);
      }}
      onMouseEnter={() => setHoverChosen(ele.id)}
      onMouseLeave={() => setHoverChosen('')}
    >
      <p style={{ fontSize: `${Scale.Height(Size, 16)}px` }}>
        {ele.Title?.title ? ele.Title.title : ele.Title}
      </p>
      <div className="topic-timer">
        <div
          className="time-bar"
          style={{
            width: `${(ele.Time / MaxTime) * 100}%`,
            backgroundColor: ele.IsTerm ? '#a83632' : '#3269a8'
          }}
        ></div>
        {/*TopicTimes[cardNumber][item]/TopicTimes[cardNumber][largestTime] */}
        <p
          style={{ gridColumn: '3/3', fontSize: `${Scale.Height(Size, 16)}px` }}
        >
          {ele.TimeStr}
        </p>
      </div>
    </div>
  );
};
export default Topics;
