import React, { useState, useEffect } from 'react';
import HImage from './Image';
import HInfo from './UserInformation';
import styled from 'styled-components';
// import MenuIconRounded from '@material-ui/icons/MenuRounded';
// import {Link} from 'react-router-dom'
// import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { Progress } from 'react-sweet-progress';
import 'react-sweet-progress/lib/style.css';
import { useFirestore } from 'react-redux-firebase';
import { createBarData } from '../../../utils/chartHelper';
import { useHistory } from 'react-router';
import { convertSecondsToText } from '../../../utils/timeHelper';
import filterStandardDeviation from '../../../utils/filterStandardDeviation';
import ExitApp from '../../../assets/Icons/Icons V1 V1 V1-19.png';
import * as Scale from '../../../utils/Scalars';
//import { setParallelSession } from '../../../actions';
const NotificationBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e21616;
  position: absolute;
  top: -5px;
  right: -5px;
  width: 24px;
  height: 24px;
  color: #ffffff;
  &:hover {
    color: #ffffff;
  }
  border-radius: 50%;
  z-index: 2;
`;
const StyledHeaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
`;

const StyledInnerContainer = styled.div`
  height: 95%;
  width: calc(99% + 5px);
  display: grid;
  grid-template-columns: 1fr 2fr 6fr;
  background-color: #3b3b3b;
  box-shadow: 2px 2px 4px #808080;
  border-radius: 3px;
  z-index: 5;
`;

const StyledChartContainer = styled.div`
  grid-column: 3 / span 1;
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 2fr 2fr 0.5fr;

  .section {
    display: grid;
    grid-template-rows: 3fr 1fr;
    grid-template-columns: 1fr 4fr 1fr;
    padding-top: 10px;
    padding-bottom: 10px;

    .chart {
      grid-row: 1 / span 1;
      grid-column: 2 / span 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      cursor: pointer;

      .circle {
        background-color: #4dbfae;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 100%;
        height: 100px;
        width: 100px;
        transition: 0.3s all ease;

        p {
          color: white;
          margin-block-start: 0px;
          margin-block-end: 0px;
          font-size: 1em;
          font-weight: 600;
          text-shadow: 1px 1px 1px #3b3b3b;
        }
      }

      .symbol {
        color: white;
        font-weight: 600;
        text-shadow: 1px 1px 1px #3b3b3b;
      }

      .briefcase {
        color: white;
        font-weight: 600;
        font-size: 15px;
        text-align: center;
      }

      .topic {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin-bottom: 5px;
        transition: 0.3s all ease;

        .number {
          margin-right: 5%;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 3px solid rgb(5, 106, 175);
          border-radius: 50%;
          height: 100%;
          width: 10%;
          min-width: 10%;
          max-width: 10%;
          color: white;
          font-weight: 600;

          @media (max-width: 1380px) {
            width: 8%;
            min-width: 8%;
            max-width: 8%;
          }
        }

        .name {
          height: 100%;
          width: 80%;
          min-width: 80%;
          max-width: 80%;
          padding-left: 5px;
          padding-right: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgb(5, 106, 175);
          overflow: hidden;
          border-radius: 10px;

          @media (max-width: 1380px) {
            width: 60%;
            min-width: 60%;
            max-width: 60%;
          }

          p {
            margin-block-start: 0px;
            margin-block-end: 0px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 12px;
            color: white;
            font-weight: 600;
            transition: 0.3s all ease;
          }
        }
      }
    }

    .title {
      grid-row: 2 / span 1;
      grid-column: 2 / span 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
      cursor: pointer;

      p {
        margin-block-start: 0px;
        margin-block-end: 0px;
        text-align: center;
        font-weight: 600;
        color: white;
        transition: all 0.3s ease;
        text-shadow: 1px 1px 1px #808080;
      }
    }
  }
`;

const ExitHolder = styled.div`
  transition: all 0.1s ease;

  .mask {
    background-color: white;
    width: 50px;
    height: 50px;
    webkit-mask-size: 100% 100%;
    mask-size: 100% 100%;
    mask-repeat: no-repeat;
    webkit-mask-repeat: no-repeat;
    transition: all 0.1s ease;
    border: none;

    @media (max-width: 1380px) {
      height: 30px;
      width: 30px;
    }
  }

  p {
    transition: all 0.1s ease;
  }
`;

const HeaderBar = ({
  Dash,
  userInfo,
  SetSessionMasterCollection,
  SetFilteredSession,
  portfolios,
  setSelectedPortfolioId,
  selectedPortfolioId,
  headerAggregateSelected,
  setBodyRender,
  setSessionList,
  setSelectedPortfolioName,
  sessionList,
  setGuestPassMasterCollection,
  setGuestPassFilteredCollection,
  filterHeaderGuestPass,
  guestPassHeaderFiltered,
  bodyRender,
  setUsersPool,
  setSelectedUser,
  selectedUser,
  setDefaultVisit,
  setParallelSession,
  Size,
  setGP
}) => {
  // const [menuDisplay, setMenuDisplay] = useState('hamburger');
  const [menuDisplay] = useState('dropdown'); //setMenuDisplay
  const [adherence, setAdherence] = useState(0);
  const [averageTimePerVisit, setAverageTimePerVisit] = useState(0);
  const [efficiencyTimeString, setEfficiencyTimeString] = useState('0s');
  const [efficiencyStringFontSize, setEfficiencyStringFontSize] = useState(20);
  const [visitsToMap, setVisitsToMap] = useState([]);
  const [allPagesList, setAllPagesList] = useState([]);
  const [allUserVisits, setAllUserVisits] = useState([]);
  const [BriefcaseGPFC, SetbriefcaseGPFC] = useState([]);
  const [SendOpen, setSendOpen] = useState({ send: 0, open: 0, Addresses: [] });
  // const [send, setSend] = useState(0);
  const [open, setOpen] = useState(0);
  const [TopicTimes, SetTopicTimes] = useState([]);
  const [effHovered, setEffHovered] = useState(false);
  const [compHovered, setCompHovered] = useState(false);
  const [keyHovered, setKeyHovered] = useState(false);
  const [briefHovered, setBriefHovered] = useState(false);
  const [notesHovered, setNotesHovered] = useState(false);
  const [notesInbox, setNotesInbox] = useState(0);
  const [animating] = useState(false); //setAnimating
  const [hoveringExit, setHoveringExit] = useState(false);

  const firestore = useFirestore();
  const history = useHistory();

  // set Session Master Collection
  useEffect(() => {
    ////////onsole.log("!!!DASH SESSION MASTER IN HEADER!!!", Dash.SessionMasterCollection);
    //if (Dash.SessionMasterCollection === null || Dash.SessionMasterCollection === undefined) {
    //return;
    //}

    //if (Dash.SessionMasterCollection.length === 0) {
    SetSessionMasterCollection(userInfo, firestore);
    //}
  }, [SetSessionMasterCollection, firestore, userInfo]); //Dash.SessionMasterCollection

  // sets user pool for use in user select
  useEffect(() => {
    //if (Dash.UserPool === null || Dash.UserPool === undefined) {
    //  return;
    // }

    //if (Dash.UserPool.length === 0) {
    setUsersPool(userInfo, firestore);
    //}
  }, [setUsersPool, firestore, userInfo]); //Dash.UserPool

  // sets Selected User to default to the logged-in user
  useEffect(() => {
    setSelectedUser([userInfo[0]]);
    //////onsole.log(setAnimating);
  }, [setSelectedUser, userInfo]); //selectedUser

  // reset Session if user is ever changed
  useEffect(() => {
    setDefaultVisit(undefined);
    setParallelSession(undefined);
  }, [setDefaultVisit, selectedUser, setParallelSession]);

  // triggers cascade animation for header on page load
  // useEffect(() => {
  //     setTimeout(() => {
  //         setAnimating(true);
  //         setTimeout(() => {
  //             setAnimating(false);
  //         }, 2500);
  //     }, 750);
  // }, []);

  // set Session Filtered Collection
  useEffect(() => {
    ////////onsole.log("!!!DASH SESSION FILTERED IN HEADER!!!", Dash.SessionFilteredCollection);
    // if (Dash.SessionFilteredCollection === null || Dash.SessionFilteredCollection === undefined) {
    //     return;
    // }

    if (
      Dash.SessionMasterCollection?.length === 0 ||
      Dash.SessionMasterCollection === null
    ) {
      return;
    } //else if (Dash.SessionFilteredCollection.length === 0) {
    SetFilteredSession(selectedUser);
    //}
  }, [Dash.SessionMasterCollection, SetFilteredSession, selectedUser]);

  // set GuestPass Master Collection
  useEffect(() => {
    // if (Dash.GuestpassMasterCollection === null) {
    //     return;
    // }

    //if (Dash.GuestpassMasterCollection.length === 0) {
    setGuestPassMasterCollection(selectedUser, firestore);
    //}
  }, [setGuestPassMasterCollection, firestore, selectedUser]); //Dash.GuestpassMasterCollection

  // set GuestPass Filtered Collection
  useEffect(() => {
    if (Dash.GuestpassMasterCollection === null) {
      return;
    }

    // if(Dash.GuestpassFilteredCollection === null)
    //return
    // if(Dash.GuestpassMasterCollection.length === 0)
    // return;
    //if(Dash.GuestpassFilteredCollection.length === 0)
    else {
      setGuestPassFilteredCollection(selectedUser);
    }
  }, [
    Dash.GuestpassMasterCollection,
    setGuestPassFilteredCollection,
    selectedUser,
    Dash.solo,
    Dash.DateRange,
    selectedPortfolioId
  ]); //Dash.GuestpassFilteredCollection

  useEffect(() => {
    if (Dash.GuestpassMasterCollection === null) {
      return;
    }

    // if(Dash.GuestpassFilteredCollection === null)
    //return
    // if(Dash.GuestpassMasterCollection.length === 0)
    // return;
    //if(Dash.GuestpassFilteredCollection.length === 0)
    else {
      filterHeaderGuestPass(selectedUser);
    }
  }, [
    Dash.GuestpassMasterCollection,
    filterHeaderGuestPass,
    selectedUser,
    headerAggregateSelected,
    selectedPortfolioId
  ]);

  // useEffect(()=>{
  //     //////onsole.log("!!!CHECKSELECTEDUSER!!!",selectedUser)
  // },[selectedUser])

  // this will spin up on page load (DEFAULT), populating header information by selecting the users latest session and starting up the other useEffects with that info
  useEffect(() => {
    if (
      Dash.SessionMasterCollection === null ||
      Dash.SessionMasterCollection === undefined ||
      Dash.SessionMasterCollection.length === 0
    ) {
      return;
    }

    // this might be a problem if users ever load a portfolio with no pages...would seemingly then just reset the portfolio to the latest session
    //if (allPagesList.length === 0 || selectedPortfolioId === 0 || sessionList === []) {
    //////onsole.log("!!!CHECKSELECTEDUSER!!!", selectedUser)
    let userFilteredPorts = Dash.SessionMasterCollection.filter(
      (visit) => visit.user_id === selectedUser[0].id
    ).sort((a, b) =>
      a.session_date.seconds < b.session_date.seconds ? -1 : 1
    );
    setAllUserVisits(userFilteredPorts);

    if (
      selectedPortfolioId === 0 ||
      selectedUser[0].id !== userInfo[0].id ||
      !selectedUser[0].portfolio.includes(selectedPortfolioId)
    ) {
      //////onsole.log("PROPAGATING SUCCESSFULLY");
      // let userFilteredPorts = Dash.SessionMasterCollection.filter(visit => visit.user_id === selectedUser[0].id).sort((a, b) => (a.session_date.seconds < b.session_date.seconds) ? -1 : 1);
      //setAllUserVisits(userFilteredPorts);
      let lastVisit = userFilteredPorts[userFilteredPorts.length - 1];
      if (!lastVisit) {
        setSelectedPortfolioId('No data');
        setSelectedPortfolioName('No Portfolios have been viewed');
        return;
      }
      let portfolioId = lastVisit.portfolio_id;
      let chosenPortfolio = portfolios.find((port) => port._id === portfolioId);
      setSelectedPortfolioId(portfolioId);
      setSelectedPortfolioName(chosenPortfolio.title);
    } else {
      //////onsole.log("NOT PROPAGATING SUCCESSFULLY");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    Dash.SessionMasterCollection,
    portfolios,
    selectedUser,
    setSelectedPortfolioId,
    setSelectedPortfolioName,
    setSessionList,
    userInfo
  ]);

  // will update pages list and session list if portfolio selected (or eventually, portfolios and total visits) is updated
  useEffect(() => {
    if (
      Dash.SessionMasterCollection === null ||
      Dash.SessionMasterCollection === undefined ||
      Dash.SessionMasterCollection.length === 0 ||
      selectedPortfolioId === 0
    ) {
      return;
    }

    let chosenPortfolio = portfolios.find(
      (port) => port._id === selectedPortfolioId
    );
    if (chosenPortfolio) setAllPagesList(chosenPortfolio.pages);
    let portFilteredVisits = allUserVisits
      .filter((visit) => visit.portfolio_id === selectedPortfolioId)
      .sort((a, b) =>
        a.session_date.seconds < b.session_date.seconds ? 1 : -1
      );
    if (portFilteredVisits) setSessionList(portFilteredVisits);
  }, [
    Dash.SessionMasterCollection,
    portfolios,
    setAllPagesList,
    setSessionList,
    allUserVisits,
    selectedPortfolioId,
    selectedUser
  ]);

  // will reset header aggregate visit list (visitsToMap) when portfolioId or headerAggregate is updated, and start the below useEffects
  useEffect(() => {
    if (
      Dash.SessionMasterCollection === null ||
      Dash.SessionMasterCollection === undefined ||
      Dash.SessionMasterCollection.length === 0 ||
      selectedPortfolioId === 0
    ) {
      return;
    }

    let today = new Date();
    let newDate = new Date();
    if (headerAggregateSelected === 30 || headerAggregateSelected === 7) {
      newDate.setDate(today.getDate() - headerAggregateSelected);
    } else {
      newDate = new Date(
        new Date(
          new Date(new Date(new Date(new Date())).setSeconds(0)).setMinutes(0)
        ).setHours(0)
      );
    }

    let dateFilteredPorts = allUserVisits.filter((port) => {
      return (
        port.session_date.seconds * 1000 > newDate.getTime() &&
        port.session_date.seconds * 1000 < today.getTime() &&
        port.portfolio_id === selectedPortfolioId
      );
    });

    //////onsole.log("HERE'S YOUR AGGREGATE YOU NICE PERSON", dateFilteredPorts);
    setVisitsToMap(dateFilteredPorts);
  }, [
    Dash.SessionMasterCollection,
    headerAggregateSelected,
    selectedPortfolioId,
    allUserVisits
  ]);

  // set completion chart
  useEffect(() => {
    if (
      Dash.SessionMasterCollection === null ||
      Dash.SessionMasterCollection === undefined ||
      Dash.SessionMasterCollection.length === 0 ||
      visitsToMap === [] ||
      allPagesList === []
    ) {
      return;
    }

    let seenPages = [];
    let missedPagesTotal = [];
    for (let i = 0; i < visitsToMap.length; i++) {
      let obj = createBarData(visitsToMap[i].page_visited);
      for (let page of allPagesList) {
        let search = obj.viewedDatasTrimmed.find(
          (clicked) => clicked.name === page.title
        );
        if (search === undefined && seenPages.includes(page.title)) {
          let existingPage = missedPagesTotal.find(
            (foundPage) => foundPage.title === page.title
          );
          existingPage.count = existingPage.count + 1;
        } else if (search === undefined && !seenPages.includes(page.title)) {
          seenPages.push(page.title);
          let pageObj = { title: page.title, count: 1 };
          missedPagesTotal.push(pageObj);
        } else {
          console.log('Else unknown');
        }
      }
    }
    let count = 0;
    for (let page of missedPagesTotal) {
      count += page.count;
    }
    let totalPossiblePagesAggregate = allPagesList.length * visitsToMap.length;
    let percentageMissed = (count / totalPossiblePagesAggregate) * 100;
    let adherence = 100 - percentageMissed;
    setAdherence(adherence);
  }, [Dash.SessionMasterCollection, visitsToMap, allPagesList]);

  // sets efficiency chart
  useEffect(() => {
    if (
      Dash.SessionMasterCollection === null ||
      Dash.SessionMasterCollection === undefined ||
      Dash.SessionMasterCollection.length === 0 ||
      visitsToMap === []
    ) {
      return;
    }

    let arr = [];
    for (let visit of visitsToMap) {
      let obj = createBarData(visit.page_visited);
      arr.push(obj);
    }

    let allPagesArr = [];
    for (let visit of arr) {
      for (let page of visit.totalTimeSpendDatas) {
        if (page.name !== 'Splash') {
          allPagesArr.push(page);
        }
      }
    }

    let pagesFilteredBySD = filterStandardDeviation(allPagesArr);

    let pagesVisitedArr = [];
    let unaverageArr = [];
    for (let pageVisit of pagesFilteredBySD) {
      if (pagesVisitedArr.includes(pageVisit.name)) {
        let found = unaverageArr.find((page) => pageVisit.name === page.x);
        let newData = {
          x: pageVisit.name,
          y: pageVisit.y + found.y,
          label: `${pageVisit.y + found.y}s`
        };
        let index = unaverageArr.findIndex((page) => pageVisit.name === page.x);
        unaverageArr.splice(index, 1, newData);
      } else {
        pagesVisitedArr.push(pageVisit.name);
        let data = {
          x: pageVisit.name,
          y: pageVisit.y,
          label: `${pageVisit.y}s`
        };
        unaverageArr.push(data);
      }
    }

    if (unaverageArr.length) {
      let count = 0;
      for (let page of unaverageArr) {
        count += page.y;
      }
      let averageTime = Math.round(count / visitsToMap.length);
      setAverageTimePerVisit(averageTime);
    } else {
      setAverageTimePerVisit(0);
    }
  }, [Dash.SessionMasterCollection, visitsToMap]);

  // updates Efficiency time bubble
  useEffect(() => {
    if (
      Dash.SessionMasterCollection === null ||
      Dash.SessionMasterCollection === undefined ||
      Dash.SessionMasterCollection.length === 0
    ) {
      return;
    }

    let hours = Math.floor(averageTimePerVisit / 3600);
    let minutes = Math.floor((averageTimePerVisit / 60) % 60);
    let seconds = averageTimePerVisit % 60;
    let secondsFraction = seconds / 60;
    let formattedMinutes;
    if (secondsFraction > 0 && secondsFraction < 0.25) {
      formattedMinutes = 0;
    } else if (secondsFraction >= 0.25 && secondsFraction < 0.75) {
      formattedMinutes = 0.5;
    } else if (secondsFraction >= 0.75) {
      formattedMinutes = 1;
    } else {
      formattedMinutes = 0;
    }
    let minutesSeconds = minutes + formattedMinutes;
    //////onsole.log(`Average time per visit within range: ${hours} hours, ${minutes} minutes, ${seconds} seconds - TOTAL ${averageTimePerVisit}`);

    if (hours > 0) {
      // let string = `${hours}hr ${minutes}m ${seconds}s`;
      let string = `${hours}hr ${minutesSeconds}m`;
      setEfficiencyTimeString(string);
      if (bodyRender === 'Efficiency') {
        if (animating) {
          setEfficiencyStringFontSize(Scale.Height(Size, 15));
        } else {
          setEfficiencyStringFontSize(Scale.Height(Size, 14));
        }
      } else {
        if (animating) {
          setEfficiencyStringFontSize(Scale.Height(Size, 16));
        } else {
          setEfficiencyStringFontSize(Scale.Height(Size, 15));
        }
      }
      return;
    } else if (minutes > 0) {
      // let string = `${minutes}m ${seconds}s`;
      let string = `${minutesSeconds}m`;
      setEfficiencyTimeString(string);
      if (bodyRender === 'Efficiency') {
        if (animating) {
          setEfficiencyStringFontSize(Scale.Height(Size, 17));
        } else {
          setEfficiencyStringFontSize(Scale.Height(Size, 16));
        }
      } else {
        if (animating) {
          setEfficiencyStringFontSize(Scale.Height(Size, 18));
        } else {
          setEfficiencyStringFontSize(Scale.Height(Size, 17));
        }
      }
      return;
    } else if (seconds > 0) {
      let string = `${seconds}s`;
      setEfficiencyTimeString(string);
      if (bodyRender === 'Efficiency') {
        if (animating) {
          setEfficiencyStringFontSize(Scale.Height(Size, 20));
        } else {
          setEfficiencyStringFontSize(Scale.Height(Size, 19));
        }
      } else {
        if (animating) {
          setEfficiencyStringFontSize(Scale.Height(Size, 21));
        } else {
          setEfficiencyStringFontSize(Scale.Height(Size, 20));
        }
      }
      return;
    } else {
      //////onsole.log("NO AVERAGE TIME AVAILABLE FOR THIS RANGE");
      let string = `0s`;
      setEfficiencyTimeString(string);
      if (bodyRender === 'Efficiency') {
        if (animating) {
          setEfficiencyStringFontSize(Scale.Height(Size, 20));
        } else {
          setEfficiencyStringFontSize(Scale.Height(Size, 19));
        }
      } else {
        if (animating) {
          setEfficiencyStringFontSize(Scale.Height(Size, 21));
        } else {
          setEfficiencyStringFontSize(Scale.Height(Size, 20));
        }
      }
      return;
    }
  }, [
    Dash.SessionMasterCollection,
    averageTimePerVisit,
    bodyRender,
    animating,
    Size
  ]);

  // set briefcase filtered collection based on guestpass and current visit
  useEffect(() => {
    if (Dash.defaultVisit) {
      //////onsole.log("DEFAULT VISIT", Dash.defaultVisit);
      let GPFC = [];
      guestPassHeaderFiltered.map((GP) => {
        if (
          !GP.is_portfolio_view &&
          GP.RootSessionID === Dash.defaultVisit.id
        ) {
          GPFC.push(GP);
        }
        return GP;
      });
      ////////onsole.log("BriefcaseGPFC",GPFC)
      SetbriefcaseGPFC(GPFC);
      return;
    }

    if (guestPassHeaderFiltered === null) {
      return;
    }

    if (guestPassHeaderFiltered.length === 0) {
      SetbriefcaseGPFC([]);
    } else {
      let GPFC = [];
      guestPassHeaderFiltered.map((GP) => {
        if (!GP.is_portfolio_view) {
          GPFC.push(GP);
        }
        return GP;
      });
      ////////onsole.log("BriefcaseGPFC",GPFC)
      SetbriefcaseGPFC(GPFC);
    }
  }, [guestPassHeaderFiltered, Dash.defaultVisit]);

  // will update the briefcase chart
  useEffect(() => {
    let sendopen = { send: 0, open: 0, Addresses: [] };
    BriefcaseGPFC.map((ele) => {
      sendopen.send += ele.viewer_log?.length;

      if (ele.viewer_log) {
        ele.viewer_log.map((ele) => {
          if (
            sendopen.Addresses.some((address) => {
              return (
                address.trim().toLowerCase() === ele.email.trim().toLowerCase()
              );
            })
          ) {
            return ele;
          } else {
            sendopen.Addresses.push(ele.email);
            return ele;
          }
        });
      }

      if (ele.Views) {
        sendopen.open += ele.Views.length;
        ele.Views.map((ele) => {
          if (
            sendopen.Addresses.some((address) => {
              return (
                address.trim().toLowerCase() === ele.Email.trim().toLowerCase()
              );
            })
          ) {
            return ele;
          } else {
            sendopen.Addresses.push(ele.Email);
            return ele;
          }
        });
      }

      return ele;
    });

    setSendOpen(sendopen);
  }, [BriefcaseGPFC]);

  useEffect(() => {
    // setSend(SendOpen.send);
    setOpen(SendOpen.open);
  }, [SendOpen]);

  // sets key topics
  useEffect(() => {
    if (
      Dash.SessionMasterCollection === null ||
      Dash.SessionMasterCollection === undefined ||
      Dash.SessionMasterCollection.length === 0 ||
      selectedPortfolioId === 0
    ) {
      return;
    }

    let TempArrayOfObjects = [];
    //////onsole.log("DASH FILTERED SESSION IN KEY TOPICS UPDATE", visitsToMap);
    for (let ele of visitsToMap) {
      ele.page_visited.forEach((PageV, i) => {
        if (i + 1 < ele.page_visited.length) {
          if (!PageV.Topic) return;
          if (!PageV.Topic[0]) return;
          PageV.TimeSpent =
            ele.page_visited[i + 1]?.time_visited.seconds -
            PageV.time_visited.seconds;
          if (PageV.Topic[0] === 'SplashPage')
            PageV.Topic[0] = { title: 'SplashPage', id: 'SplashPage' };
          //////onsole.log("!!!PAGEV!!!",PageV.Topic)
          if (PageV?.Topic[0]?.id)
            PageV.Topic.forEach((Topic, i) => {
              if (!TempArrayOfObjects[i]) TempArrayOfObjects.splice(i, 0, {});
              TempArrayOfObjects[i][Topic.id]
                ? (TempArrayOfObjects[i][Topic.id].Time += PageV.TimeSpent)
                : (TempArrayOfObjects[i][Topic.id] = {
                    id: Topic.id,
                    Title: Topic.title,
                    Time: PageV.TimeSpent,
                    Chain: PageV.Topic.slice(
                      0,
                      PageV.Topic.findIndex((ele) => {
                        return ele === Topic.title;
                      })
                    )
                  }); //Requires a preprocessing stage...
              //TempArrayOfObjects[i][Topic].Topic = PageV.Topic.splice(i,999);
            });
        }
      });
    }

    let TempArrayValuesMaster = [];
    for (let ele of TempArrayOfObjects) {
      let TempArrayValues = [];
      // eslint-disable-next-line
      for (const [key, value] of Object.entries(ele)) {
        if (value.Title !== null && value.Title !== 'SplashPage') {
          TempArrayValues.push(value);
          //////onsole.log(key);
        }
      }
      TempArrayValues = TempArrayValues.sort((a, b) => {
        return b.Time - a.Time;
      });
      let TimeTotal = 0;
      TempArrayValues = TempArrayValues.map((value) => {
        value.TimeStr = convertSecondsToText(value.Time, false);
        TimeTotal += value.Time;
        return value;
      });
      let InfoComposite = {};
      InfoComposite.Array = TempArrayValues;
      InfoComposite.TotalTimes = convertSecondsToText(TimeTotal, false);
      TempArrayValuesMaster.push(InfoComposite);
    }

    //////onsole.log("AVAILABLE TOPICS", TempArrayValuesMaster);
    SetTopicTimes(TempArrayValuesMaster);
  }, [Dash.SessionMasterCollection, visitsToMap, selectedPortfolioId]);

  // sets notes count
  useEffect(() => {
    if (
      Dash.SessionMasterCollection === null ||
      Dash.SessionMasterCollection === undefined ||
      Dash.SessionMasterCollection.length === 0
    ) {
      return;
    }

    let sessionsWithNotes = visitsToMap.filter(
      (filt) => filt?.notes && filt?.notes !== ''
    );
    // //////onsole.log("ALL SESSIONS WITH NOTES", sessionsWithNotes);
    if (sessionsWithNotes?.length) {
      setNotesInbox(sessionsWithNotes.length);
    } else {
      setNotesInbox(0);
    }
  }, [Dash.SessionMasterCollection, visitsToMap]);

  const buttonList = [
    {
      name: 'Account',
      id: 'Account'
    },
    {
      name: 'Users',
      id: 'Users'
    },
    {
      name: 'Guest Pass',
      id: 'Guest Pass'
    }
  ];

  let buttonListMapping = buttonList.map((button) => {
    return (
      <div
        style={{
          height: `${Scale.Height(Size, 30)}px`,
          width: '85%',
          boxShadow: '2px 2px 3px #4D4D4D',
          position: 'relative',
          borderRadius: '4px',
          marginTop: '1.5%',
          marginBottom: '1.5%',
          backgroundColor:
            bodyRender === button.id ? 'rgb(5, 106, 175)' : 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer'
        }}
        onClick={() => {
          setBodyRender(button.id);
          if (button.id === 'Guest Pass') setGP(true);
          else setGP(false);
        }}
        key={button.id}
      >
        <p
          style={{
            marginBlockStart: '0px',
            marginBlockEnd: '0px',
            fontSize: `${Scale.Height(Size, 20)}px`,
            color: bodyRender === button.id ? 'white' : '#3b3b3b',
            fontWeight: 600,
            textShadow: '1px 1px 1px #808080',
            fontFamily: "'Open Sans', sans-serif",
            textAlign: 'center'
          }}
        >
          {button.name}
        </p>
        {button.name === 'Guest Pass' && Dash.NotificationsCount > 0 ? (
          <NotificationBox>
            <p>
              {Dash.NotificationsCount > 9 ? '9+' : Dash.NotificationsCount}
            </p>
          </NotificationBox>
        ) : null}
      </div>
    );
  });

  let hamburgerDisplay = null;
  if (menuDisplay === 'hamburger') {
    hamburgerDisplay = (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          padding: '5%',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        {/* <MenuIconRounded
                    style={{
                        color: "white",
                        fontSize: `${Scale.Height(Size, 45)}px`,
                        cursor: 'pointer'
                    }}
                    onClick={() => setMenuDisplay('dropdown')}
                /> */}
      </div>
    );
  } else if (menuDisplay === 'dropdown') {
    hamburgerDisplay = (
      <div
        style={{
          display: 'grid',
          gridTemplateRows: '1.5fr 3.5fr',
          backgroundColor: '#dedede',
          height: '100%'
        }}
      >
        <div
          style={{
            gridRow: '1 / span 1',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center'
          }}
        >
          {/* <CloseRoundedIcon 
                        style={{
                            color: '#3b3b3b',
                            fontSize: `${Scale.Height(Size, 30)}px`,
                            cursor: 'pointer'
                        }}
                        onClick={() => setMenuDisplay('hamburger')}
                    /> */}
        </div>
        <div
          style={{
            gridRow: '2 / span 1',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          {buttonListMapping}
        </div>
      </div>
    );
  }

  let topicList = [{ name: 'No Key Topics' }];
  let topicMapping;
  if (TopicTimes[0]?.Array?.length) {
    topicMapping = TopicTimes[0].Array.slice(0, 3).map((topic, i) => {
      // //////onsole.log("!!!TOPIC!!!",topic);
      return (
        <div
          className="topic"
          key={topic.Title.title ? topic.Title.title : topic.Title}
          style={{
            height: animating
              ? `${Scale.Height(Size, 22)}px`
              : bodyRender === 'Key Topics'
              ? `${Scale.Height(Size, 18)}px`
              : `${Scale.Height(Size, 20)}px`,
            width: animating
              ? `${Scale.Height(Size, 143)}px`
              : bodyRender === 'Key Topics'
              ? `${Scale.Height(Size, 117)}px`
              : `${Scale.Height(Size, 130)}px`
          }}
        >
          <div
            className="number"
            style={{
              fontSize: animating
                ? `${Scale.Height(Size, 15)}px`
                : bodyRender === 'Key Topics'
                ? `${Scale.Height(Size, 13)}px`
                : `${Scale.Height(Size, 14)}px`
            }}
          >
            {i + 1}
          </div>
          <div
            className="name"
            style={{
              fontSize: animating
                ? `${Scale.Height(Size, 15)}px`
                : bodyRender === 'Key Topics'
                ? `${Scale.Height(Size, 13)}px`
                : `${Scale.Height(Size, 14)}px`
            }}
          >
            <p>{topic.Title.title ? topic.Title.title : topic.Title}</p>
          </div>
        </div>
      );
    });
  } else {
    topicMapping = topicList.map((topic, i) => {
      return (
        <div
          className="topic"
          key={topic.name}
          style={{
            height: animating
              ? `${Scale.Height(Size, 40)}px`
              : bodyRender === 'Key Topics'
              ? `${Scale.Height(Size, 32)}px`
              : `${Scale.Height(Size, 34)}px`,
            width: animating
              ? `${Scale.Height(Size, 180)}px`
              : bodyRender === 'Key Topics'
              ? `${Scale.Height(Size, 160)}px`
              : `${Scale.Height(Size, 150)}px`,
            marginBottom: '0px'
          }}
        >
          <div
            className="name"
            style={{
              fontSize: animating
                ? `${Scale.Height(Size, 15)}px`
                : bodyRender === 'Key Topics'
                ? `${Scale.Height(Size, 13)}px`
                : `${Scale.Height(Size, 14)}px`,
              width: '80%',
              minWidth: '80%',
              maxWidth: '80%'
            }}
          >
            <p>{topic.name}</p>
          </div>
        </div>
      );
    });
  }

  let adherenceValue = isNaN(adherence) ? '0%' : `${Math.round(adherence)}%`;
  let adherencePercent = isNaN(adherence) ? 0 : Math.round(adherence);
  // let briefcasePercentFormatted;
  // if (isNaN((open / send)) === true) {
  //     briefcasePercentFormatted = 0.1;
  // } else if ((open / send) === 0) {
  //     briefcasePercentFormatted = 0.1;
  // } else if ((open / send) > 1) {
  //     briefcasePercentFormatted = 100;
  // } else {
  //     briefcasePercentFormatted = Math.round((open / send) * 100);
  // }

  // //////onsole.log("SELECTED USER", selectedUser);

  return (
    <StyledHeaderContainer>
      <StyledInnerContainer>
        {hamburgerDisplay}
        <div
          style={{
            gridColumn: 2,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'top'
          }}
        >
          <HImage></HImage>
          <HInfo></HInfo>
        </div>
        <StyledChartContainer>
          <div
            className="section"
            onClick={() => {
              setBodyRender('Key Topics');
              setKeyHovered(false);
            }}
            onMouseEnter={
              bodyRender !== 'Key Topics' ? () => setKeyHovered(true) : null
            }
            onMouseLeave={() => setKeyHovered(false)}
          >
            <div
              className="chart"
              style={{
                borderLeft: animating
                  ? 'none'
                  : bodyRender === 'Key Topics'
                  ? '4px solid white'
                  : 'none',
                borderRight: animating
                  ? 'none'
                  : bodyRender === 'Key Topics'
                  ? '4px solid white'
                  : 'none',
                borderTop: animating
                  ? 'none'
                  : bodyRender === 'Key Topics'
                  ? '4px solid white'
                  : 'none',
                boxShadow: animating
                  ? 'none'
                  : bodyRender === 'Key Topics'
                  ? '2px 2px 3px #808080'
                  : 'none'
              }}
            >
              {topicMapping}
            </div>
            <div
              className="title"
              style={{
                borderLeft: animating
                  ? 'none'
                  : bodyRender === 'Key Topics'
                  ? '4px solid white'
                  : 'none',
                borderRight: animating
                  ? 'none'
                  : bodyRender === 'Key Topics'
                  ? '4px solid white'
                  : 'none',
                backgroundColor: animating
                  ? 'transparent'
                  : bodyRender === 'Key Topics'
                  ? 'white'
                  : 'transparent',
                boxShadow: animating
                  ? 'none'
                  : bodyRender === 'Key Topics'
                  ? '2px 2px 3px #808080'
                  : 'none',
                justifyContent:
                  bodyRender === 'Key Topics' || animating
                    ? 'center'
                    : 'flex-start'
              }}
            >
              <p
                style={{
                  fontSize: animating
                    ? '1.0em'
                    : keyHovered
                    ? '1.0em'
                    : '0.8em',
                  color: animating
                    ? 'white'
                    : bodyRender === 'Key Topics'
                    ? '#3b3b3b'
                    : 'white'
                }}
              >
                Key Topics
              </p>
            </div>
          </div>
          <div
            className="section"
            onClick={() => {
              setBodyRender('Efficiency');
              setEffHovered(false);
            }}
            onMouseEnter={
              bodyRender !== 'Efficiency' ? () => setEffHovered(true) : null
            }
            onMouseLeave={() => setEffHovered(false)}
          >
            <div
              className="chart"
              style={{
                borderLeft: animating
                  ? 'none'
                  : bodyRender === 'Efficiency'
                  ? '4px solid white'
                  : 'none',
                borderRight: animating
                  ? 'none'
                  : bodyRender === 'Efficiency'
                  ? '4px solid white'
                  : 'none',
                borderTop: animating
                  ? 'none'
                  : bodyRender === 'Efficiency'
                  ? '4px solid white'
                  : 'none',
                boxShadow: animating
                  ? 'none'
                  : bodyRender === 'Efficiency'
                  ? '2px 2px 3px #808080'
                  : 'none'
              }}
            >
              <div
                className="circle"
                style={{
                  height: animating
                    ? `${Scale.Height(Size, 120)}px`
                    : bodyRender === 'Efficiency'
                    ? `${Scale.Height(Size, 90)}px`
                    : `${Scale.Height(Size, 100)}px`,
                  width: animating
                    ? `${Scale.Height(Size, 120)}px`
                    : bodyRender === 'Efficiency'
                    ? `${Scale.Height(Size, 90)}px`
                    : `${Scale.Height(Size, 100)}px`,
                  backgroundColor: 'rgb(247, 152, 61)'
                }}
              >
                <p style={{ fontSize: `${efficiencyStringFontSize}px` }}>
                  {efficiencyTimeString}
                </p>
                <p
                  style={{
                    fontSize: `calc(${efficiencyStringFontSize}px - ${Scale.Height(
                      Size,
                      4
                    )}px)`,
                    fontWeight: 400
                  }}
                >
                  Avg.
                </p>
                <p
                  style={{
                    fontSize: `calc(${efficiencyStringFontSize}px - ${Scale.Height(
                      Size,
                      4
                    )}px)`,
                    fontWeight: 400
                  }}
                >
                  Session
                </p>
              </div>
            </div>
            <div
              className="title"
              style={{
                borderLeft: animating
                  ? 'none'
                  : bodyRender === 'Efficiency'
                  ? '4px solid white'
                  : 'none',
                borderRight: animating
                  ? 'none'
                  : bodyRender === 'Efficiency'
                  ? '4px solid white'
                  : 'none',
                backgroundColor: animating
                  ? 'transparent'
                  : bodyRender === 'Efficiency'
                  ? 'white'
                  : 'transparent',
                boxShadow: animating
                  ? 'none'
                  : bodyRender === 'Efficiency'
                  ? '2px 2px 3px #808080'
                  : 'none',
                justifyContent:
                  bodyRender === 'Efficiency' || animating
                    ? 'center'
                    : 'flex-start'
              }}
            >
              <p
                style={{
                  fontSize: animating
                    ? '1.1em'
                    : effHovered
                    ? '1.1em'
                    : '0.9em',
                  color: animating
                    ? 'white'
                    : bodyRender === 'Efficiency'
                    ? '#3b3b3b'
                    : 'white'
                }}
              >
                Duration
              </p>
            </div>
          </div>
          <div
            className="section"
            onClick={() => {
              setBodyRender('Completion');
              setCompHovered(false);
            }}
            onMouseEnter={
              bodyRender !== 'Completion' ? () => setCompHovered(true) : null
            }
            onMouseLeave={() => setCompHovered(false)}
          >
            <div
              className="chart"
              style={{
                borderLeft: animating
                  ? 'none'
                  : bodyRender === 'Completion'
                  ? '4px solid white'
                  : 'none',
                borderRight: animating
                  ? 'none'
                  : bodyRender === 'Completion'
                  ? '4px solid white'
                  : 'none',
                borderTop: animating
                  ? 'none'
                  : bodyRender === 'Completion'
                  ? '4px solid white'
                  : 'none',
                boxShadow: animating
                  ? 'none'
                  : bodyRender === 'Completion'
                  ? '2px 2px 3px #808080'
                  : 'none'
              }}
            >
              <Progress
                type="circle"
                percent={adherencePercent}
                status="success"
                width={
                  animating
                    ? Scale.Height(Size, 120)
                    : bodyRender === 'Completion'
                    ? Scale.Height(Size, 90)
                    : Scale.Height(Size, 100)
                }
                symbolClassName={'symbol'}
                theme={{
                  success: {
                    symbol: adherenceValue,
                    trailColor: 'white',
                    color: 'rgb(5, 106, 175)'
                  }
                }}
                style={{ cursor: 'pointer', transition: '0.3s all ease' }}
              />
            </div>
            <div
              className="title"
              style={{
                borderLeft: animating
                  ? 'none'
                  : bodyRender === 'Completion'
                  ? '4px solid white'
                  : 'none',
                borderRight: animating
                  ? 'none'
                  : bodyRender === 'Completion'
                  ? '4px solid white'
                  : 'none',
                backgroundColor: animating
                  ? 'transparent'
                  : bodyRender === 'Completion'
                  ? 'white'
                  : 'transparent',
                boxShadow: animating
                  ? 'none'
                  : bodyRender === 'Completion'
                  ? '2px 2px 3px #808080'
                  : 'none',
                justifyContent:
                  bodyRender === 'Completion' || animating
                    ? 'center'
                    : 'flex-start'
              }}
            >
              <p
                style={{
                  fontSize: animating
                    ? '1.1em'
                    : compHovered
                    ? '1.1em'
                    : '0.9em',
                  color: animating
                    ? 'white'
                    : bodyRender === 'Completion'
                    ? '#3b3b3b'
                    : 'white'
                }}
              >
                Adherence
              </p>
            </div>
          </div>
          <div
            className="section"
            onClick={() => {
              setBodyRender('Briefcase');
              setBriefHovered(false);
            }}
            onMouseEnter={
              bodyRender !== 'Briefcase' ? () => setBriefHovered(true) : null
            }
            onMouseLeave={() => setBriefHovered(false)}
          >
            <div
              className="chart"
              style={{
                borderLeft: animating
                  ? 'none'
                  : bodyRender === 'Briefcase'
                  ? '4px solid white'
                  : 'none',
                borderRight: animating
                  ? 'none'
                  : bodyRender === 'Briefcase'
                  ? '4px solid white'
                  : 'none',
                borderTop: animating
                  ? 'none'
                  : bodyRender === 'Briefcase'
                  ? '4px solid white'
                  : 'none',
                boxShadow: animating
                  ? 'none'
                  : bodyRender === 'Briefcase'
                  ? '2px 2px 3px #808080'
                  : 'none'
              }}
            >
              <div
                className="circle"
                style={{
                  backgroundColor: 'rgb(247, 152, 61)',
                  height: animating
                    ? `${Scale.Height(Size, 120)}px`
                    : bodyRender === 'Briefcase'
                    ? `${Scale.Height(Size, 90)}px`
                    : `${Scale.Height(Size, 100)}px`,
                  width: animating
                    ? `${Scale.Height(Size, 120)}px`
                    : bodyRender === 'Briefcase'
                    ? `${Scale.Height(Size, 90)}px`
                    : `${Scale.Height(Size, 100)}px`
                }}
              >
                <p
                  style={{
                    fontSize: animating
                      ? `${Scale.Height(Size, 19)}px`
                      : bodyRender === 'Briefcase'
                      ? `${Scale.Height(Size, 16)}px`
                      : `${Scale.Height(Size, 17)}px`
                  }}
                >
                  {open}
                </p>
                <p
                  style={{
                    fontSize: animating
                      ? `${Scale.Height(Size, 19)}px`
                      : bodyRender === 'Briefcase'
                      ? `${Scale.Height(Size, 16)}px`
                      : `${Scale.Height(Size, 17)}px`
                  }}
                >
                  Opened
                </p>
              </div>
            </div>
            <div
              className="title"
              style={{
                borderLeft: animating
                  ? 'none'
                  : bodyRender === 'Briefcase'
                  ? '4px solid white'
                  : 'none',
                borderRight: animating
                  ? 'none'
                  : bodyRender === 'Briefcase'
                  ? '4px solid white'
                  : 'none',
                backgroundColor: animating
                  ? 'transparent'
                  : bodyRender === 'Briefcase'
                  ? 'white'
                  : 'transparent',
                boxShadow: animating
                  ? 'none'
                  : bodyRender === 'Briefcase'
                  ? '2px 2px 3px #808080'
                  : 'none',
                justifyContent:
                  bodyRender === 'Briefcase' || animating
                    ? 'center'
                    : 'flex-start'
              }}
            >
              <p
                style={{
                  fontSize: animating
                    ? '1.1em'
                    : briefHovered
                    ? '1.1em'
                    : '0.9em',
                  color: animating
                    ? 'white'
                    : bodyRender === 'Briefcase'
                    ? '#3b3b3b'
                    : 'white'
                }}
              >
                Briefcase
              </p>
            </div>
          </div>
          <div
            className="section"
            onClick={() => {
              setBodyRender('Notes');
              setNotesHovered(false);
            }}
            onMouseEnter={
              bodyRender !== 'Notes' ? () => setNotesHovered(true) : null
            }
            onMouseLeave={() => setNotesHovered(false)}
          >
            <div
              className="chart"
              style={{
                borderLeft: animating
                  ? 'none'
                  : bodyRender === 'Notes'
                  ? '4px solid white'
                  : 'none',
                borderRight: animating
                  ? 'none'
                  : bodyRender === 'Notes'
                  ? '4px solid white'
                  : 'none',
                borderTop: animating
                  ? 'none'
                  : bodyRender === 'Notes'
                  ? '4px solid white'
                  : 'none',
                boxShadow: animating
                  ? 'none'
                  : bodyRender === 'Notes'
                  ? '2px 2px 3px #808080'
                  : 'none'
              }}
            >
              <div
                className="circle"
                style={{
                  backgroundColor: 'rgb(5, 106, 175)',
                  height: animating
                    ? `${Scale.Height(Size, 120)}px`
                    : bodyRender === 'Notes'
                    ? `${Scale.Height(Size, 90)}px`
                    : `${Scale.Height(Size, 100)}px`,
                  width: animating
                    ? `${Scale.Height(Size, 120)}px`
                    : bodyRender === 'Notes'
                    ? `${Scale.Height(Size, 90)}px`
                    : `${Scale.Height(Size, 100)}px`
                }}
              >
                <p
                  style={{
                    fontSize: animating
                      ? `${Scale.Height(Size, 19)}px`
                      : bodyRender === 'Notes'
                      ? `${Scale.Height(Size, 16)}px`
                      : `${Scale.Height(Size, 17)}px`
                  }}
                >
                  {notesInbox}
                </p>
                <p
                  style={{
                    fontSize: animating
                      ? `${Scale.Height(Size, 19)}px`
                      : bodyRender === 'Notes'
                      ? `${Scale.Height(Size, 16)}px`
                      : `${Scale.Height(Size, 17)}px`
                  }}
                >
                  {notesInbox === 1 ? 'Session' : 'Sessions'}
                </p>
              </div>
            </div>
            <div
              className="title"
              style={{
                borderLeft: animating
                  ? 'none'
                  : bodyRender === 'Notes'
                  ? '4px solid white'
                  : 'none',
                borderRight: animating
                  ? 'none'
                  : bodyRender === 'Notes'
                  ? '4px solid white'
                  : 'none',
                backgroundColor: animating
                  ? 'transparent'
                  : bodyRender === 'Notes'
                  ? 'white'
                  : 'transparent',
                boxShadow: animating
                  ? 'none'
                  : bodyRender === 'Notes'
                  ? '2px 2px 3px #808080'
                  : 'none',
                justifyContent:
                  bodyRender === 'Notes' || animating ? 'center' : 'flex-start'
              }}
            >
              <p
                style={{
                  fontSize: animating
                    ? '1.1em'
                    : notesHovered
                    ? '1.1em'
                    : '0.9em',
                  color: animating
                    ? 'white'
                    : bodyRender === 'Notes'
                    ? '#3b3b3b'
                    : 'white'
                }}
              >
                Notes
              </p>
            </div>
          </div>
          <div
            style={{
              gridColumn: '6 / span 1',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <ExitHolder
              style={{
                padding: '10px',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexDirection: 'column',
                cursor: 'pointer'
              }}
              onClick={() =>
                history.push({
                  pathname: '/engage'
                })
              }
              onMouseOver={() => setHoveringExit(true)}
              onMouseLeave={() => setHoveringExit(false)}
            >
              <div
                className="mask"
                style={{
                  maskImage: `url(${ExitApp})`,
                  WebkitMaskImage: `url(${ExitApp})`
                  // transform: hoveringExit ? 'scale(1.2, 1.2)' : 'scale(1.0, 1.0)'
                }}
              />
              <p
                style={{
                  color: 'white',
                  marginBlockStart: '0px',
                  marginBlockEnd: '0px',
                  fontSize: hoveringExit
                    ? `${Scale.Height(Size, 18)}px`
                    : `${Scale.Height(Size, 16)}px`,
                  fontWeight: 600
                }}
              >
                Exit
              </p>
            </ExitHolder>
          </div>
        </StyledChartContainer>
      </StyledInnerContainer>
    </StyledHeaderContainer>
  );
};
export default HeaderBar;
