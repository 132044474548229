import React, { useRef } from 'react'; //useState, useEffect,
import FontUtil from '../../utils/FontUtility';
import serializers from '../../utils/scriptSerializers';
import BlockContent from '@sanity/block-content-to-react';
import SanityMuxPlayer from '../../containers/MuxPlayer/';
// import SRC from './artur-tumasjan-qLzWvcQq-V8-unsplash 1.png'
// import SRC2 from "./image 2.png"
import { urlFor } from '../../utils/sanityHelper';
const CoverSheet = ({
  chosenPortfolio,
  ScrollTunn,
  SetAltStateCover,
  videos,
  Size
}) => {
  //FLAG FONT FAMILY
  //////onsole.log("chosenPortfolioCover",chosenPortfolio);
  // let L = chosenPortfolio?.portfolio_logo
  let S = chosenPortfolio?.splash[0]?.image;
  const { portfolio_LogoResize } = chosenPortfolio;
  //onsole.log("Flag",chosenPortfolio?.StoryBookObject?.StorybookQuote)
  const RootRef = useRef();
  const LogoCopy = (
    <div
      className="logo"
      id="logo9"
      style={{
        width: 'calc(100% * 563/877)',
        left: 'calc(100% * (239)/877)',
        top: '-4%'
      }}
    >
      <div
        className="logo-content"
        style={{ height: '100%', maxWidth: '100%', maxHeight: '100%' }}
      >
        <img
          style={{
            objectFit: 'contain',
            height: `calc(100% - ${
              portfolio_LogoResize?.Compression
                ? portfolio_LogoResize.VerticalCompression
                : 0
            }%)`,
            width: `calc(100% - ${
              portfolio_LogoResize?.Compression
                ? portfolio_LogoResize.HorizontalCompression
                : 0
            }%)`
          }}
          //style={{height:"100%",width:"100%"}}
          src={urlFor(
            chosenPortfolio?.portfolio_logo?.asset?._ref
              ? chosenPortfolio.portfolio_logo.asset._ref
              : ''
          )
            .auto('format')
            .width(400)
            .url()}
          // src={SRC2}
          alt="logo"
        />
      </div>
    </div>
  );
  // /chosenPortfolio.ContentText
  return (
    <div
      className={'CoverRoot'}
      ref={RootRef}
      onClick={(eve) => {
        if (ScrollTunn) ScrollTunn(eve);
        setTimeout(() => {
          try {
            RootRef.current.style.height = '0px';
            RootRef.current.style.display = 'none';
            SetAltStateCover(false);
          } catch (err) {
            console.error('Error', err);
          }
        }, 1000);
      }}
      onScroll={(eve) => {
        if (ScrollTunn) ScrollTunn(eve);
        setTimeout(() => {
          try {
            RootRef.current.style.height = '0px';
            RootRef.current.style.display = 'none';
            SetAltStateCover(false);
          } catch (err) {
            console.error('Error', err);
          }
        }, 1000);
      }}
      onWheel={(eve) => {
        if (ScrollTunn) ScrollTunn(eve);
        setTimeout(() => {
          try {
            RootRef.current.style.height = '0px';
            RootRef.current.style.display = 'none';
            SetAltStateCover(false);
          } catch (err) {
            console.error('Error', err);
          }
        }, 1000);
      }}
      style={{ height: '100vh', width: '100vw', flexShrink: '0' }}
    >
      {chosenPortfolio?.splash[0]?.image ||
      chosenPortfolio?.splash[0]?.video ? (
        chosenPortfolio.splash[0].image ? (
          <img
            alt="Background"
            //src={SRC}
            src={urlFor(S?.asset?._ref ? S?.asset?._ref : '')
              .auto('format')
              .url()}
            style={{
              position: 'absolute',
              height: '100%',
              width: '100%',
              objectFit: 'cover'
            }}
          ></img>
        ) : chosenPortfolio.splash[0].video ? (
          // && Size.W > 450
          <div
            className="splash-page-video-box"
            style={{
              position: 'absolute',
              height: '100%',
              width: '100%',
              objectFit: 'cover',
              backgroundColor: chosenPortfolio.primary_color
                ? chosenPortfolio.primary_color
                : 'white'
            }}
          >
            <SanityMuxPlayer
              className="mux-player"
              assetDocument={videos[chosenPortfolio.splash[0].video.asset._ref]}
              autoload={true}
              autoplay={true}
              loop={true}
              muted={true}
              SIG={false}
              SIP={false}
              showControls={false}
              style={{}}
              posterurl={`https://image.mux.com/${
                videos[chosenPortfolio.splash[0].video.asset._ref].playbackId
              }/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=1`}
              type="galleryVideo"
              isSelected={false}
              // heightOverride={'90%'}
              objectFitOverride={true}
              override={true}
            />
          </div>
        ) : null
      ) : null}

      <div
        className={'CoverQuote'}
        style={{
          fontFamily: FontUtil(
            chosenPortfolio?.StoryBookObject?.StorybookQuoteText,
            chosenPortfolio.ContentText
          )
        }}
      >
        <BlockContent
          blocks={chosenPortfolio?.StoryBookObject?.StorybookQuote}
          serializers={serializers}
        />
      </div>
      {/* <p className={"CoverQuote"} style={{fontFamily:FontUtil(chosenPortfolio?.StoryBookObject?.StorybookQuoteText,chosenPortfolio.ContentText)}}>{chosenPortfolio?.StoryBookObject?.StorybookQuote}</p> */}
      {/*  “Big quote goes here to make you think” */}
      <div
        className="CoverBaseBack"
        style={{ background: chosenPortfolio?.splash_color }}
      >
        <div
          className={'CoverSubtitle'}
          style={{
            fontFamily: FontUtil(
              chosenPortfolio?.StoryBookObject?.StorybookSubtitleText,
              chosenPortfolio.ContentText
            )
          }}
        >
          <BlockContent
            blocks={chosenPortfolio?.StoryBookObject?.StorybookSubtitle}
            serializers={serializers}
          />
        </div>
        {/* <p className={"CoverSubtitle"} style={{fontFamily:FontUtil(chosenPortfolio?.StoryBookObject?.StorybookSubtitleText,chosenPortfolio.ContentText)}}>{chosenPortfolio?.StoryBookObject?.StorybookSubtitle}</p> */}
        {/* Diagnostics and Therapudics Since 1898 */}
      </div>
      <div
        className="CoverLogoBack"
        style={{ background: chosenPortfolio?.splash_bg_color }}
      >
        <div
          className="CoverColorBar"
          style={{
            backgroundColor: chosenPortfolio.secondary_color
              ? chosenPortfolio.secondary_color
              : '#D8C5AA',
            width: '100%',
            height: '4%',
            top: '96%',
            display: 'block'
          }}
        ></div>
        {LogoCopy}
      </div>
    </div>
  );
};
export default CoverSheet;
