/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from 'react';
import { urlFor } from '../../utils/sanityHelper';
import Arrows from '../../assets/Icons/triangles_v1Asset 5.png';
//import styled ftouchAction:'none', userSelect:'none',rom 'styled-ctouchAction:'none', userSelect:'none',omponents';

const BASlider = ({
  ImageA,
  ImageB,
  Vert,
  LabelA,
  LabelB,
  Color,
  Thumb,
  override,
  Size,
  style
}) => {
  //FLAG FONT FAMILY
  const [SliderColor, SetSliderColor] = useState('white');
  let [GripLoc, SetGripLoc] = useState(null);
  useEffect(() => {
    if (Color) SetSliderColor(Color);
  }, [Color]);
  let PointRef = useRef();
  const EndDrag = () => {
    try {
      PointRef.current.onpointermove = null;
    } catch (err) {
      console.error('Error', err);
    }
  };
  useEffect(() => {
    window.addEventListener('pointerup', EndDrag);
    return () => {
      window.removeEventListener('pointerup', EndDrag);
    };
  });
  useEffect(() => {
    SetGripLoc(null);
  }, [ImageA, ImageB, Vert, LabelA, LabelB, Color, Thumb]);

  let OnPointermove = (eve) => {
    eve.stopPropagation();
    // if(eve.pressure === 0) //Not actually a touch or mouse event.
    //     return;

    if (!Vert) {
      if (eve.currentTarget.clientWidth - eve.offsetX <= 24) {
        //////onsole.log('Less',eve.currentTarget.clientWidth, eve.currentTarget.clientWidth - eve.offsetX)
        SetGripLoc(eve.currentTarget.clientWidth);
        //eve.currentTarget.onpointermove = null;
      } else if (eve.offsetX <= 24) {
        SetGripLoc(0);
        //eve.currentTarget.onpointermove = null;
      } else {
        SetGripLoc(eve.offsetX);
      }
    } else {
      if (eve.currentTarget.clientHeight - eve.offsetY <= 24) {
        //////onsole.log('Less',eve.currentTarget.clientHeight, eve.currentTarget.clientHeight - eve.offsetY)
        SetGripLoc(eve.currentTarget.clientHeight);
        //eve.currentTarget.onpointermove = null;
      } else if (eve.offsetY <= 24) {
        SetGripLoc(0);
        //eve.currentTarget.onpointermove = null;
      } else {
        SetGripLoc(eve.offsetY);
      }
    }
  };
  if (!Thumb)
    if (!Vert)
      return (
        <div
          style={{
            touchAction: 'none',
            userSelect: 'none',
            height: '100%',
            width: '100%',
            position: 'relative',
            ...style
          }}
          draggable={false}
          className="ba-slider-container"
        >
          <div
            draggable={false}
            style={{
              touchAction: 'none',
              userSelect: 'none',
              height: '100%',
              width: '100%',
              position: 'absolute',
              clipPath:
                GripLoc !== null
                  ? `polygon(calc(0% + ${GripLoc}px) 0%, 100% 0%, 100% 100%, calc(0% + ${GripLoc}px) 100%)`
                  : 'polygon(50% 0%, 100% 0%, 100% 100%, 50% 100%)'
            }}
          >
            <img
              draggable={false}
              style={{
                touchAction: 'none',
                userSelect: 'none',
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: '0px',
                left: '0px'
              }}
              src={urlFor(ImageA).auto('format').url()}
            ></img>
            <div
              draggable={false}
              style={{
                touchAction: 'none',
                userSelect: 'none',
                position: 'relative',
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-end'
              }}
            >
              {LabelA && LabelA !== '' && (
                <>
                  {' '}
                  <div
                    draggable={false}
                    style={{
                      touchAction: 'none',
                      userSelect: 'none',
                      position: 'relative',
                      zIndex: 1,
                      background: 'rgba(0,0,0,0.5)',
                      height: '48px',
                      maxWidth: '25%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <p
                      draggable={false}
                      style={{
                        touchAction: 'none',
                        userSelect: 'none',
                        marginBlock: '0px',
                        color: '#fff',
                        margin: '0px 12px',
                        height: 'auto'
                      }}
                    >
                      {LabelA}
                    </p>
                  </div>
                  <div style={{ height: '1px', width: '144px' }}></div>
                </>
              )}
            </div>
          </div>
          <div
            draggable={false}
            style={{
              touchAction: 'none',
              userSelect: 'none',
              height: '100%',
              width: '100%',
              position: 'absolute',
              clipPath:
                GripLoc !== null
                  ? `polygon(0% 0%, calc(0% + ${GripLoc}px) 0%, calc(0% + ${GripLoc}px) 100%, 0% 100%)`
                  : 'polygon(0% 0%, 50% 0%, 50% 100%, 0% 100%)'
            }}
          >
            <img
              draggable={false}
              style={{
                touchAction: 'none',
                userSelect: 'none',
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: '0px',
                left: '0px'
              }}
              src={urlFor(ImageB).auto('format').url()}
            ></img>
            <div
              draggable={false}
              style={{
                touchAction: 'none',
                userSelect: 'none',
                position: 'relative',
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-end'
              }}
            >
              {LabelB && LabelB !== '' && (
                <>
                  <div style={{ height: '1px', width: '144px' }}></div>
                  <div
                    draggable={false}
                    style={{
                      touchAction: 'none',
                      userSelect: 'none',
                      position: 'relative',
                      zIndex: 1,
                      background: 'rgba(0,0,0,0.5)',
                      height: '48px',
                      maxWidth: '25%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <p
                      draggable={false}
                      style={{
                        touchAction: 'none',
                        userSelect: 'none',
                        marginBlock: '0px',
                        color: '#fff',
                        margin: '0px 12px',
                        height: 'auto'
                      }}
                    >
                      {LabelB}
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
          <div
            draggable={false}
            style={{
              touchAction: 'none',
              userSelect: 'none',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              filter:
                'drop-shadow(3px 3px 5px #808080) drop-shadow(-3px -3px 5px #808080)',
              zIndex: 2,
              width: '100px',
              height: '100px',
              background: 'transparent',
              top: 'calc(50% - 50px)',
              left:
                GripLoc !== null ? `${GripLoc - 50}px` : `calc(50% - ${50}px)`
            }}
          >
            <div
              draggable={false}
              style={{
                touchAction: 'none',
                userSelect: 'none',
                maskImage: `url(${Arrows})`,
                maskRepeat: 'no-repeat',
                maskSize: 'contain',
                maskPosition: 'center',
                WebkitMaskImage: `url(${Arrows})`,
                WebkitMaskRepeat: 'no-repeat',
                WebkitMaskSize: 'contain',
                WebkitMaskPosition: 'center',
                zIndex: 2,
                width: '60px',
                height: '60px',
                background: SliderColor,
                top: 'calc(50% - 30px)',
                left:
                  GripLoc !== null ? `${GripLoc - 30}px` : `calc(50% - ${30}px)`
              }}
            ></div>
          </div>
          <div
            draggable={false}
            style={{
              touchAction: 'none',
              userSelect: 'none',
              position: 'absolute',
              boxShadow: '3px 3px 5px #808080, -3px -3px 5px #808080',
              zIndex: 2,
              width: '7.2px',
              height: '100%',
              background: SliderColor,
              top: '0px',
              left:
                GripLoc !== null ? `${GripLoc - 3.6}px` : `calc(50% - ${3.6}px)`
            }}
          ></div>
          <div
            draggable={false}
            onPointerUp={(eve) => {
              eve.stopPropagation();
              eve.currentTarget.onpointermove = null;
            }}
            onPointerDown={(eve) => {
              eve.stopPropagation(); //////onsole.log('down',eve.clientX);
              eve.currentTarget.onpointermove = OnPointermove;
            }}
            ref={PointRef}
            style={{
              touchAction: 'none',
              userSelect: 'none',
              position: 'absolute',
              cursor: 'grab',
              top: '0px',
              zIndex: '3',
              height: '100%',
              width: '100%'
            }}
          ></div>
          {/* <div style={{touchAction:'none', userSelect:'none',zIndex:1}}></div>
                <div></div> */}
        </div>
      );
    //First two "polygon(0% 0%, 100% 0%, 100% 50%, 0% 50%)"
    else
      return (
        <div
          draggable={false}
          style={{
            touchAction: 'none',
            userSelect: 'none',
            height: '100%',
            width: '100%',
            position: 'relative'
          }}
        >
          <div
            draggable={false}
            style={{
              touchAction: 'none',
              userSelect: 'none',
              height: '100%',
              width: '100%',
              position: 'absolute',
              clipPath:
                GripLoc !== null
                  ? `polygon(0% 0%, 100% 0%, 100% calc(0% + ${GripLoc}px), 0% calc(0% + ${GripLoc}px)`
                  : 'polygon(0% 0%, 100% 0%, 100% 50%, 0% 50%)'
            }}
          >
            <img
              draggable={false}
              style={{
                touchAction: 'none',
                userSelect: 'none',
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: '0px',
                left: '0px'
              }}
              src={urlFor(ImageB).auto('format').url()}
            ></img>
            <div
              draggable={false}
              style={{
                touchAction: 'none',
                userSelect: 'none',
                position: 'relative',
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start'
              }}
            >
              {LabelB && LabelB !== '' && (
                <div
                  draggable={false}
                  style={{
                    touchAction: 'none',
                    userSelect: 'none',
                    position: 'relative',
                    zIndex: 1,
                    background: 'rgba(0,0,0,0.5)',
                    height: '48px',
                    maxWidth: '25%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <p
                    draggable={false}
                    style={{
                      touchAction: 'none',
                      userSelect: 'none',
                      marginBlock: '0px',
                      color: '#fff',
                      margin: '0px 12px',
                      height: 'auto'
                    }}
                  >
                    {LabelB}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div
            draggable={false}
            style={{
              touchAction: 'none',
              userSelect: 'none',
              height: '100%',
              width: '100%',
              position: 'absolute',
              clipPath:
                GripLoc !== null
                  ? `polygon(0% calc(0% + ${GripLoc}px), 100% calc(0% + ${GripLoc}px), 100% 100%, 0% 100%)`
                  : 'polygon(0% 50%, 100% 50%, 100% 100%, 0% 100%)'
            }}
          >
            <img
              draggable={false}
              style={{
                touchAction: 'none',
                userSelect: 'none',
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: '0px',
                left: '0px'
              }}
              src={urlFor(ImageA).auto('format').url()}
            ></img>
            <div
              draggable={false}
              style={{
                touchAction: 'none',
                userSelect: 'none',
                position: 'relative',
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end'
              }}
            >
              {LabelA && LabelA !== '' && (
                <div
                  draggable={false}
                  style={{
                    touchAction: 'none',
                    userSelect: 'none',
                    position: 'relative',
                    zIndex: 1,
                    background: 'rgba(0,0,0,0.5)',
                    height: '48px',
                    maxWidth: '25%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <p
                    draggable={false}
                    style={{
                      touchAction: 'none',
                      userSelect: 'none',
                      marginBlock: '0px',
                      color: '#fff',
                      margin: '0px 12px',
                      height: 'auto'
                    }}
                  >
                    {LabelA}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div
            draggable={false}
            style={{
              touchAction: 'none',
              userSelect: 'none',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              transform: 'rotate(90deg)',
              filter:
                'drop-shadow(3px 3px 5px #808080) drop-shadow(-3px -3px 5px #808080)',
              zIndex: 2,
              width: '100px',
              height: '100px',
              background: 'transparent',
              top:
                GripLoc !== null ? `${GripLoc - 50}px` : `calc(50% - ${50}px)`,
              left: 'calc(50% - 50px)'
            }}
          >
            <div
              draggable={false}
              style={{
                touchAction: 'none',
                userSelect: 'none',
                maskImage: `url(${Arrows})`,
                maskRepeat: 'no-repeat',
                maskSize: 'contain',
                maskPosition: 'center',
                WebkitMaskImage: `url(${Arrows})`,
                WebkitMaskRepeat: 'no-repeat',
                WebkitMaskSize: 'contain',
                WebkitMaskPosition: 'center',
                zIndex: 2,
                width: '60px',
                height: '60px',
                background: SliderColor,
                top: 'calc(50% - 30px)',
                left:
                  GripLoc !== null ? `${GripLoc - 30}px` : `calc(50% - ${30}px)`
              }}
            ></div>
          </div>
          <div
            draggable={false}
            style={{
              touchAction: 'none',
              userSelect: 'none',
              position: 'absolute',
              boxShadow: '3px 3px 5px #808080, -3px -3px 5px #808080',
              zIndex: 2,
              width: '100%',
              height: '7.2px',
              background: SliderColor,
              top:
                GripLoc !== null
                  ? `${GripLoc - 3.6}px`
                  : `calc(50% - ${3.6}px)`,
              left: '0px'
            }}
          ></div>
          <div
            draggable={false}
            onPointerUp={(eve) => {
              eve.stopPropagation();
              eve.currentTarget.onpointermove = null;
            }}
            onPointerDown={(eve) => {
              eve.stopPropagation(); //////onsole.log('down',eve.clientX);
              eve.currentTarget.onpointermove = OnPointermove;
            }}
            ref={PointRef}
            style={{
              touchAction: 'none',
              userSelect: 'none',
              position: 'absolute',
              cursor: 'grab',
              top: '0px',
              zIndex: '3',
              height: '100%',
              width: '100%'
            }}
          ></div>
          {/* <div style={{touchAction:'none', userSelect:'none',zIndex:1}}></div>
                <div></div> */}
        </div>
      );
  else {
    if (!Vert)
      return (
        <div
          style={{
            touchAction: 'none',
            userSelect: 'none',
            height: '100%',
            width: '100%',
            position: 'relative'
          }}
        >
          <div
            style={{
              touchAction: 'none',
              userSelect: 'none',
              height: '100%',
              width: '100%',
              position: 'absolute',
              clipPath:
                GripLoc !== null
                  ? `polygon(calc(0% + ${GripLoc}px) 0%, 100% 0%, 100% 100%, calc(0% + ${GripLoc}px) 100%)`
                  : 'polygon(50% 0%, 100% 0%, 100% 100%, 50% 100%)'
            }}
          >
            <img
              draggable={false}
              style={{
                touchAction: 'none',
                userSelect: 'none',
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: '0px',
                left: '0px'
              }}
              src={urlFor(ImageA).auto('format').url()}
            ></img>
            {/* <div style={{touchAction:'none', userSelect:'none',position:'relative', height:"100%",width:"100%", display:"flex", justifyContent:"flex-end", alignItems:"flex-end"}} >
                        <div style={{touchAction:'none', userSelect:'none',position:'relative', zIndex:1,  background:"rgba(0,0,0,0.5)", height:"48px", maxWidth:"25%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                            <p style={{touchAction:'none', userSelect:'none',marginBlock:'0px', color:"#fff",margin:"0px 12px",height:"auto"}}>{LabelA}</p>
                        </div>
                    </div> */}
          </div>
          <div
            style={{
              touchAction: 'none',
              userSelect: 'none',
              height: '100%',
              width: '100%',
              position: 'absolute',
              clipPath:
                GripLoc !== null
                  ? `polygon(0% 0%, calc(0% + ${GripLoc}px) 0%, calc(0% + ${GripLoc}px) 100%, 0% 100%)`
                  : 'polygon(0% 0%, 50% 0%, 50% 100%, 0% 100%)'
            }}
          >
            <img
              draggable={false}
              style={{
                touchAction: 'none',
                userSelect: 'none',
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: '0px',
                left: '0px'
              }}
              src={urlFor(ImageB).auto('format').url()}
            ></img>
            {/* <div style={{touchAction:'none', userSelect:'none',position:'relative', height:"100%",width:"100%", display:"flex", justifyContent:"flex-start", alignItems:"flex-end"}} >
                        <div style={{touchAction:'none', userSelect:'none',position:'relative',  zIndex:1,  background:"rgba(0,0,0,0.5)", height:"48px", maxWidth:"25%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                            <p style={{touchAction:'none', userSelect:'none',marginBlock:'0px', color:"#fff",margin:"0px 12px",height:"auto"}}>{LabelB}</p>
                        </div>
                    </div> */}
          </div>
          {/* <div  style={{touchAction:'none', userSelect:'none',position:"relative", maskImage:`url(${Arrows})`, maskRepeat:"no-repeat", maskSize:"contain", maskPosition:"center", zIndex:2,width:'100px',height:'100px',background:SliderColor,top:"calc(50% - 50px)",left:GripLoc !== null?`${GripLoc - 50}px`:`calc(50% - ${50}px)`}}></div>
                <div  style={{touchAction:'none', userSelect:'none',position:"absolute", zIndex:2,width:'12px',height:'100%',background:SliderColor,top:'0px',left:GripLoc !== null?`${GripLoc - 6}px`:`calc(50% - ${6}px)`}}></div>
                <div onPointerUp={(eve)=>{eve.stopPropagation();eve.currentTarget.onPointermove= null;}} onPointerDown={(eve)=>{eve.stopPropagation();//////onsole.log('down',eve.clientX); eve.currentTarget.onPointermove=OnPointermove}} style={{touchAction:'none', userSelect:'none',position:'absolute', top:'0px',zIndex:'3',height:'100%',width:"100%"}}></div> */}
          {/* <div style={{touchAction:'none', userSelect:'none',zIndex:1}}></div>
                <div></div> */}
        </div>
      );
    // clipPath:GripLoc !== null?`polygon(0% calc(0% + ${GripLoc}px), 100% calc(0% + ${GripLoc}px), 100% 100%, 0% 100%)`:"polygon(0% 50%, 100% 50%, 100% 100%, 0% 100%)"
    //clipPath:GripLoc !== null?`polygon(0% 0%, 100% 0%, 100% calc(0% + ${GripLoc}px), 0% calc(0% + ${GripLoc}px)`:"polygon(0% 0%, 100% 0%, 100% 50%, 0% 50%)"
    //First two "polygon(0% 0%, 100% 0%, 100% 50%, 0% 50%)"
    else
      return (
        <div
          style={{
            touchAction: 'none',
            userSelect: 'none',
            height: '100%',
            width: '100%',
            position: 'relative'
          }}
        >
          <div
            style={{
              touchAction: 'none',
              userSelect: 'none',
              height: '100%',
              width: '100%',
              position: 'absolute',
              clipPath:
                GripLoc !== null
                  ? `polygon(0% 0%, 100% 0%, 100% calc(0% + ${GripLoc}px), 0% calc(0% + ${GripLoc}px)`
                  : 'polygon(0% 0%, 100% 0%, 100% 50%, 0% 50%)'
            }}
          >
            <img
              draggable={false}
              style={{
                touchAction: 'none',
                userSelect: 'none',
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: '0px',
                left: '0px'
              }}
              src={urlFor(ImageB).auto('format').url()}
            ></img>
            {/* <div style={{touchAction:'none', userSelect:'none',position:'relative', height:"100%",width:"100%", display:"flex", justifyContent:"center", alignItems:"flex-start"}} >
                        <div style={{touchAction:'none', userSelect:'none',position:'relative', zIndex:1,  background:"rgba(0,0,0,0.5)", height:"48px", maxWidth:"25%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                            <p style={{touchAction:'none', userSelect:'none',marginBlock:'0px', color:"#fff",margin:"0px 12px",height:"auto"}}>{LabelA}</p>
                        </div>
                    </div> */}
          </div>
          <div
            style={{
              touchAction: 'none',
              userSelect: 'none',
              height: '100%',
              width: '100%',
              position: 'absolute',
              clipPath:
                GripLoc !== null
                  ? `polygon(0% calc(0% + ${GripLoc}px), 100% calc(0% + ${GripLoc}px), 100% 100%, 0% 100%)`
                  : 'polygon(0% 50%, 100% 50%, 100% 100%, 0% 100%)'
            }}
          >
            <img
              draggable={false}
              style={{
                touchAction: 'none',
                userSelect: 'none',
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: '0px',
                left: '0px'
              }}
              src={urlFor(ImageA).auto('format').url()}
            ></img>
            {/* <div style={{touchAction:'none', userSelect:'none',position:'relative', height:"100%",width:"100%", display:"flex", justifyContent:"center", alignItems:"flex-end"}} >
                        <div style={{touchAction:'none', userSelect:'none',position:'relative',  zIndex:1,  background:"rgba(0,0,0,0.5)", height:"48px", maxWidth:"25%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                            <p style={{touchAction:'none', userSelect:'none',marginBlock:'0px', color:"#fff",margin:"0px 12px",height:"auto"}}>{LabelB}</p>
                        </div>
                    </div> */}
          </div>
          {/* <div  style={{touchAction:'none', userSelect:'none',position:"relative", transform:"rotate(90deg)", maskImage:`url(${Arrows})`, maskRepeat:"no-repeat", maskSize:"contain", maskPosition:"center", zIndex:2,width:'100px',height:'100px',background:SliderColor,top:GripLoc !== null?`${GripLoc - 50}px`:`calc(50% - ${50}px)`,left:"calc(50% - 50px)"}}></div>
                <div  style={{touchAction:'none', userSelect:'none',position:"absolute", zIndex:2,width:'100%',height:'12px',background:SliderColor,top:GripLoc !== null?`${GripLoc - 6}px`:`calc(50% - ${6}px)`,left:'0px'}}></div>
                <div onPointerUp={(eve)=>{eve.stopPropagation();eve.currentTarget.onPointermove= null;}} onPointerDown={(eve)=>{eve.stopPropagation();//////onsole.log('down',eve.clientX); eve.currentTarget.onPointermove=OnPointermove}} style={{touchAction:'none', userSelect:'none',position:'absolute', top:'0px',zIndex:'3',height:'100%',width:"100%"}}></div> */}
          {/* <div style={{touchAction:'none', userSelect:'none',zIndex:1}}></div>
                <div></div> */}
        </div>
      );
  }
};
export default BASlider;
