/* 
// Shows menus as nested
*/
import React, { useState, useRef } from 'react';
import MenuSection from '../CatalogMenuSection';
import useTemplate from '../../_Portfolio/PortfolioMenu/Helper/TemplateHelper';
// import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';

const TopLevelContainer = (props) => {
  const {
    setChosenMainPageButtonId,
    setChosenPageId,
    setIsAtEnd,
    setCurrentLevel,
    setNavHistory,
    images,
    videos,
    pdfs,
    pages,
    contentDataChoice,
    prepareCurrentGalleryContent,
    onNavClick,
    setCurrentNavItem,
    currentNavItem,
    chosenPortfolio,
    onNavClick2,
    getColor,
    videoSelected,
    isAtEnd,
    setHasCurr,
    setHasPrev,
    Size,
    transitionedToSubLevel,
    hasCurr,
    hasPrev,
    onBackClick,
    returnTopLevel,
    setSavedContentPageId,
    setContentPageId,
    setTwolevelTerminus,
    twolevelTerminus,
    setPrevLevel,
    setTopicArray,
    backTopicArray,
    nav
  } = props;

  const { navHistory } = props.pageMenuReducer;
  const scrollRef = useRef();
  // get default functions used from hook
  const { getActiveString, getIsAtEndString } = useTemplate(props);

  // const [prevId, setPrevId] = useState(''); // keep track of previous page id for back button
  const [isInNested, setIsInNested] = useState(false); // keep track if user is in nested layer

  // back button function
  // const goBack = () => {
  //   if (prevId !== '') {
  //     // initiate menu button click for prevId
  //     setPageIdAndLevel(prevId, currentLevel);
  //   } else {
  //     // empty prevId
  //     setPageIdAndLevel('', 0);
  //   }
  //   setIsAtEnd(false);

  //   // remove last item from navHistory
  //   setNavHistory(navHistory.filter((_, i) => i !== navHistory.length - 1));
  // };

  // on page button click
  const onClick = (id, level, hasChild, isNotEnd, navIndex) => {
    //////onsole.log(`ID: ${id}, level: ${level}, hasChild: ${hasChild}, isNotEnd: ${isNotEnd}, navIndex: ${navIndex}`);
    //////onsole.log("NAV CLICK - TOP LEVEL");
    setPrevLevel(level);
    setPageIdAndLevel(id, level);

    // copy navhistroy
    let navHistoryCopy = [...navHistory];
    //////onsole.log("NAV HISTORY", navHistoryCopy);
    // first nav history, so parent button
    if (navHistoryCopy.length === 0) {
      //////onsole.log("NAV HISTORY LENGTH IS 0");
      //////onsole.log("NAV INDEX", navIndex);
      navHistoryCopy.push(navIndex);
    } else {
      //////onsole.log("NAV HISTORY LENGTH GREATER THAN 0");
      // check if need to replace at same level
      if (level <= navHistoryCopy.length - 1) {
        navHistoryCopy[level] = navIndex;
        // remove level higher than replaced one
        // ex if user has history [2,1,1,5] and clicked on index 3 at parent (level 0)
        // then replace  it [3,1,1,5] and remove history of any other level, so it will be [3]
        // NOTE slice caused issue, using filter for now
        navHistoryCopy = navHistoryCopy.filter((_, i) => i <= level);
        //////onsole.log("NAV HISTORY WITHIN FIRST IF ELSE", navHistoryCopy)
      } else {
        // if not append
        navHistoryCopy.push(navIndex);
      }
    }

    //////onsole.log("nav History now", navHistoryCopy);
    // set/replace navHistory
    setNavHistory(navHistoryCopy);

    // check if clicked content contains child
    if (hasChild) {
      let b = !isNotEnd;

      // User is in nested so set isInNested to true
      setIsInNested(true);
      // set isAtEnd
      setIsAtEnd(b);
      if (!b) {
        //////////onsole.log(id);
      } else {
        //if at end the nested ended so set isInNested to false
        setIsInNested(false);
      }
    } else {
      setIsAtEnd(true);
      if (!isInNested) {
        // if not in nested set prev id
        //////////onsole.log(id);
      }
    }
    // setContentPageId(prevNavItem.referenceId);
  };

  // set page and level
  const setPageIdAndLevel = (id, level) => {
    if (level === 0) {
      setChosenMainPageButtonId(id);
    }
    setChosenPageId(id);
    setCurrentLevel(level);
  };

  let length = nav.items.length;

  let menuSectionWidthDecider;
  if (length === 1) {
    menuSectionWidthDecider = 'one';
  } else if (length === 2) {
    menuSectionWidthDecider = 'two';
  } else if (length === 3) {
    menuSectionWidthDecider = 'three';
  } else if (length >= 4) {
    menuSectionWidthDecider = 'four-plus';
  } else {
    menuSectionWidthDecider = 'four-plus';
  }

  return (
    <div
      className={`portfolio-big-nav-container ${getIsAtEndString()} ${menuSectionWidthDecider}`}
      id={`portfolio-big-nav-container`}
      onWheel={(eve) => {
        eve.preventDefault();
        let container = document.getElementById('portfolio-big-nav-container');
        let containerScrollPosition = document.getElementById(
          'portfolio-big-nav-container'
        ).scrollLeft;
        //onsole.log("SCROLL POSITION", containerScrollPosition)
        container.scrollTo({
          top: 0,
          left: containerScrollPosition + eve.deltaY * 3,
          behaviour: 'smooth' //if you want smooth scrolling
        });
      }}
      onScroll={() => {
        let container = document.getElementById('portfolio-big-nav-container');
        if (container.scrollLeft >= 500) {
          if (scrollRef?.current?.style) {
            scrollRef.current.style.display = 'flex';
            setTimeout(() => {
              scrollRef.current.style.opacity = 1;
            }, 500);
          }
        } else {
          if (scrollRef?.current?.style) {
            scrollRef.current.style.opacity = 0;
            setTimeout(() => {
              scrollRef.current.style.display = 'none';
            }, 300);
          }
        }
      }}
    >
      {/* <div 
        className='scrollback-container'
        ref={scrollRef}
        style={{
          backgroundColor: chosenPortfolio?.primary_color ? `${chosenPortfolio.primary_color}BF` : '#3b3b3bBF',
          color: chosenPortfolio?.text_color ? chosenPortfolio?.text_color : '#FFFFFF'
        }}
        onClick={() => {
          let container = document.getElementById('portfolio-big-nav-container');
          container.scrollTo({
              top: 0,
              left: 0,
              behaviour: 'smooth' //if you want smooth scrolling
          }) 
        }}
      >
        <div className='icon-box'>
          <ChevronLeftRoundedIcon 
            style={{
              fontSize: '36px',
              color: chosenPortfolio?.text_color ? chosenPortfolio?.text_color : '#FFFFFF'
            }}
          />
        </div>
        <div className='text-box'>
          <p>Scroll Left</p>
        </div>
      </div> */}
      <MenuSection
        isActive={true} // set all level 0 button to be active
        getActiveString={getActiveString}
        getIsAtEndString={getIsAtEndString}
        level={0}
        onButtonClick={onClick}
        pages={pages}
        images={images}
        videos={videos}
        pdfs={pdfs}
        contentDataChoice={contentDataChoice}
        prepareCurrentGalleryContent={prepareCurrentGalleryContent}
        onNavClick={onNavClick}
        isAtEnd={isAtEnd}
        setCurrentNavItem={setCurrentNavItem}
        currentNavItem={currentNavItem}
        chosenPortfolio={chosenPortfolio}
        onNavClick2={onNavClick2}
        getColor={getColor}
        videoSelected={videoSelected}
        setHasCurr={setHasCurr}
        setHasPrev={setHasPrev}
        Size={Size}
        transitionedToSubLevel={transitionedToSubLevel}
        hasCurr={hasCurr}
        hasPrev={hasPrev}
        onBackClick={onBackClick}
        returnTopLevel={returnTopLevel}
        setSavedContentPageId={setSavedContentPageId}
        setChosenPageId={setChosenPageId}
        setContentPageId={setContentPageId}
        twolevelTerminus={twolevelTerminus}
        setTwolevelTerminus={setTwolevelTerminus}
        setTopicArray={setTopicArray}
        backTopicArray={backTopicArray}
      />
    </div>
  );
};
export default TopLevelContainer;
