import React, { Fragment } from 'react';
import { IconContext } from 'react-icons';
import { MdClose } from 'react-icons/md';
import Annotator from '../../assets/Icons/annotator_v3-41.png';
import MdSpeakerNotes from '../../assets/Icons/script-33.png';
import MdNoteAdd from '../../assets/Icons/white notepad icon-32.png';
import MdExitToApp from '../../assets/Icons/Icons V1 V1 V1-19.png';
import MdFavoriteBorder from '../../assets/Icons/Icons V1 V1 V1-22.png';
import MdFavorite from '../../assets/Icons/Icons V1 V1 V1-24.png';
import MdFullscreenExit from '../../assets/Icons/Icons V1_white trans-06.png';
import MdFullscreen from '../../assets/Icons/Icons V1_white trans-03.png';
import MdPersonAdd from '../../assets/Icons/Icons V1_white trans-04.png';
import Calendar from '../../assets/Icons/Calendar.png';
import MdShoppingCart from '../../assets/Icons/white_trans_briefcase-15.png'; //triangles_v1Asset 5.png
import SliderArrows from '../../assets/images/arrowUp2.png';
//import SliderArrows from '../../assets/Icons/triangles_v1Asset 5.png'//triangles_v1Asset 5.png

import ZI from '../../assets/Icons/Zoom In2.png';
import ZO from '../../assets/Icons/Zoom Out2.png';
import D from '../../assets/Icons/Download.png';

import MdAddShoppingCart from '../../assets/Icons/white_trans_briefcase-16.png';
import MdChevronRight from '../../assets/Icons/V1_arrow_white_trans-12.png';
import MdChevronLeft from '../../assets/Icons/V1_arrow_white_trans-11.png';
import { urlFor } from '../../utils/sanityHelper';

import ArrowLeft from '../../assets/images/arrowLeft.png';
//import styled from 'styled-components';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import FontUtil from '../../utils/FontUtility';

// const Arrow = styled.img`
//   position: relative;
//   top: -71%;
//   left: calc(50% - 35px);
//   z-index: 3;
//   height: 70px;
//   width: 70px;
//   opacity: 0.7;
// `;

//Helper for Ocolor/Universal Color
const Ocolorasay = (props) => {
  if (props.chosenPortfolio.bulk_icon_override === '#')
    props.chosenPortfolio.bulk_icon_override = undefined;
  if (props.OColor === '#') props.OColor = undefined;
  if (!props.OColor && props.chosenPortfolio.bulk_icon_override)
    props.OColor = props.chosenPortfolio.bulk_icon_override;
  return props;
};

// helper for custom icons
const IconHelper = (props) => {
  let Ptemp = { ...props };
  props = { ...Ocolorasay(Ptemp) };
  //////////onsole.log("ON CLICK: ", props.onClick);
  if (props.OColor === '#') props.OColor = undefined;
  //onsole.log("CHOSEN PORT IN ICON HELPER", props.chosenPortfolio);
  //onsole.log("SIZE", props.Size);

  //onsole.log("O COLOR", props.OColor)
  return !props.OColor ? (
    <div
      className="menu-icon-custom"
      id={props.id}
      onClick={props.onClick}
      style={{
        border:
          props.chosenPortfolio.theme === 'theme2'
            ? `2px solid ${props.chosenPortfolio.secondary_color}`
            : 'none'
      }}
    >
      {props.briefcaseContent?.length ? (
        <div
          className="notification"
          style={{
            backgroundColor: props.booleanNewItems ? '#e21616' : 'transparent'
          }}
        >
          <p
            style={{
              color: props.booleanNewItems ? 'white' : '#3b3b3b',
              fontSize: props.booleanNewItems ? '0.9em' : '1.1em',
              fontFamily: FontUtil(props.chosenPortfolio.SystemText)
            }}
          >
            {props.briefcaseContent?.length <= 999
              ? props.briefcaseContent?.length
              : '999+'}
          </p>
        </div>
      ) : null}
      {props.chosenPortfolio.theme === 'theme8' ||
      props.chosenPortfolio.theme === 'theme11' ||
      (props.chosenPortfolio.theme === 'theme4' && props.Size?.W < 451) ||
      props.overlay === undefined ? (
        // <Tooltip placement="top" id={props.id} trigger='hover' visible={false} overlay={props.overlay} mouseEnterDelay={0.2} overlayInnerStyle={{display: "flex", justifyContent: "center", alignItems: "center", fontWeight: 600}}>
        <img
          id={props.id}
          src={
            props.images[props.assetId]
              ? urlFor(
                  props.images[props.assetId] ? props.images[props.assetId] : ''
                )
                  .auto('format')
                  .width(140)
                  .url()
              : props.src
          }
          alt={props.imgAlt}
          style={{
            padding: props.chosenPortfolio.theme === 'theme2' ? '2px' : '10px',
            cursor: 'pointer'
          }}
        />
      ) : (
        <Tooltip
          placement="top"
          id={props.id}
          trigger="hover"
          overlay={props.overlay}
          mouseEnterDelay={0.2}
          overlayInnerStyle={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 600
          }}
        >
          <img
            id={props.id}
            src={
              props.images[props.assetId]
                ? urlFor(
                    props.images[props.assetId]
                      ? props.images[props.assetId]
                      : ''
                  )
                    .auto('format')
                    .width(140)
                    .url()
                : props.src
            }
            alt={props.imgAlt}
            style={{
              padding:
                props.chosenPortfolio.theme === 'theme2' ? '2px' : '10px',
              cursor: 'pointer'
            }}
          />
        </Tooltip>
      )}
    </div>
  ) : (
    <div
      className="menu-icon-custom"
      id={props.id}
      onClick={props.onClick}
      style={{
        border:
          props.chosenPortfolio.theme === 'theme2'
            ? `2px solid ${props.OColor}`
            : 'none'
      }}
    >
      {props.briefcaseContent?.length ? (
        <div
          className="notification"
          style={{
            backgroundColor: props.booleanNewItems ? '#e21616' : 'transparent',
            boxShadow: props.booleanNewItems
              ? '1px 1px 2px 1px #3b3b3b'
              : 'none'
          }}
        >
          <p
            style={{
              color: props.booleanNewItems ? 'white' : props.OColor,
              fontSize: props.booleanNewItems ? '0.9em' : '1.1em',
              fontFamily: FontUtil(props.chosenPortfolio.SystemText)
            }}
          >
            {props.briefcaseContent?.length <= 999
              ? props.briefcaseContent?.length
              : '999+'}
          </p>
        </div>
      ) : null}
      {props.chosenPortfolio.theme === 'theme8' ||
      props.chosenPortfolio.theme === 'theme11' ||
      (props.chosenPortfolio.theme === 'theme4' && props.Size?.W < 451) ||
      props.overlay === undefined ? (
        // <Tooltip placement="top" id={props.id} trigger='hover'  visible={false} overlay={props.overlay} mouseEnterDelay={0.2} overlayInnerStyle={{display: "flex", justifyContent: "center", alignItems: "center", fontWeight: 600}}>
        <div
          className="containDiv"
          id={props.id}
          style={{
            height: '70px',
            cursor: 'pointer',
            width: '70px',
            backgroundColor: props.OColor,
            maskImage: `url(${
              props.images[props.assetId]
                ? urlFor(
                    props.images[props.assetId]
                      ? props.images[props.assetId]
                      : ''
                  )
                    .auto('format')
                    .width(140)
                    .url()
                : props.src
            })`,
            maskSize: '100% 100%',
            maskRepeat: 'no-repeat',
            WebkitMaskImage: `url(${
              props.images[props.assetId]
                ? urlFor(
                    props.images[props.assetId]
                      ? props.images[props.assetId]
                      : ''
                  )
                    .auto('format')
                    .width(140)
                    .url()
                : props.src
            })`,
            WebkitMaskRepeat: 'no-repeat',
            WebkitMaskSize: '100% 100%'
          }}
        />
      ) : (
        <Tooltip
          placement="top"
          id={props.id}
          trigger="hover"
          overlay={props.overlay}
          mouseEnterDelay={0.2}
          overlayInnerStyle={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 600
          }}
        >
          <div
            className="containDiv"
            id={props.id}
            style={{
              height: '70px',
              cursor: 'pointer',
              width: '70px',
              backgroundColor: props.OColor,
              maskImage: `url(${
                props.images[props.assetId]
                  ? urlFor(
                      props.images[props.assetId]
                        ? props.images[props.assetId]
                        : ''
                    )
                      .auto('format')
                      .width(140)
                      .url()
                  : props.src
              })`,
              maskSize: '100% 100%',
              maskRepeat: 'no-repeat',
              WebkitMaskImage: `url(${
                props.images[props.assetId]
                  ? urlFor(
                      props.images[props.assetId]
                        ? props.images[props.assetId]
                        : ''
                    )
                      .auto('format')
                      .width(140)
                      .url()
                  : props.src
              })`,
              WebkitMaskRepeat: 'no-repeat',
              WebkitMaskSize: '100% 100%'
            }}
          />
        </Tooltip>
      )}
    </div>
  );
};

// helper for custom icons
const FWPDFToolHelper = (props) => {
  let Ptemp = { ...props };
  props = { ...Ocolorasay(Ptemp) };
  //////////onsole.log("ON CLICK: ", props.onClick);
  if (props.OColor === '#') props.OColor = undefined;
  return !props.OColor ? (
    <section
      className="menu-icon-custom"
      id={props.id}
      onClick={props.onClick}
      style={{
        border:
          props.chosenPortfolio.theme === 'theme2'
            ? `2px solid ${props.chosenPortfolio.secondary_color}`
            : 'none'
      }}
    >
      {props.chosenPortfolio.theme === 'theme8' ||
      props.chosenPortfolio.theme === 'theme11' ? (
        <Tooltip
          placement="top"
          id={props.id}
          trigger="hover"
          visible={false}
          overlay={props.overlay}
          mouseEnterDelay={0.2}
          overlayInnerStyle={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 600
          }}
        >
          <img
            id={props.id}
            src={
              props.images[props.assetId]
                ? urlFor(
                    props.images[props.assetId]
                      ? props.images[props.assetId]
                      : ''
                  )
                    .auto('format')
                    .width(140)
                    .url()
                : props.src
            }
            alt={props.imgAlt}
            style={{
              padding:
                props.chosenPortfolio.theme === 'theme2' ? '2px' : '10px',
              cursor: 'pointer'
            }}
          />
        </Tooltip>
      ) : (
        <Tooltip
          placement="top"
          id={props.id}
          trigger="hover"
          visible={false}
          overlay={props.overlay}
          mouseEnterDelay={0.2}
          overlayInnerStyle={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 600
          }}
        >
          <img
            id={props.id}
            src={
              props.images[props.assetId]
                ? urlFor(
                    props.images[props.assetId]
                      ? props.images[props.assetId]
                      : ''
                  )
                    .auto('format')
                    .width(140)
                    .url()
                : props.src
            }
            alt={props.imgAlt}
            style={{
              padding:
                props.chosenPortfolio.theme === 'theme2' ? '2px' : '10px',
              cursor: 'pointer'
            }}
          />
        </Tooltip>
      )}
    </section>
  ) : (
    <section
      className="menu-icon-custom"
      id={props.id}
      onClick={props.onClick}
      style={{
        border:
          props.chosenPortfolio.theme === 'theme2'
            ? `2px solid ${props.OColor}`
            : 'none'
      }}
    >
      {props.chosenPortfolio.theme === 'theme8' ||
      props.chosenPortfolio.theme === 'theme11' ? (
        <Tooltip
          placement="top"
          id={props.id}
          trigger="hover"
          visible={false}
          overlay={props.overlay}
          mouseEnterDelay={0.2}
          overlayInnerStyle={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 600
          }}
        >
          <div
            className="containDivPDF"
            id={props.id}
            style={{
              height: '35px',
              cursor: 'pointer',
              width: '35px',
              backgroundColor: props.OColor,
              maskImage: `url(${
                props.images[props.assetId]
                  ? urlFor(
                      props.images[props.assetId]
                        ? props.images[props.assetId]
                        : ''
                    )
                      .auto('format')
                      .width(140)
                      .url()
                  : props.src
              })`,
              maskSize: '100% 100%',
              maskRepeat: 'no-repeat',
              WebkitMaskImage: `url(${
                props.images[props.assetId]
                  ? urlFor(
                      props.images[props.assetId]
                        ? props.images[props.assetId]
                        : ''
                    )
                      .auto('format')
                      .width(140)
                      .url()
                  : props.src
              })`,
              WebkitMaskRepeat: 'no-repeat',
              WebkitMaskSize: '100% 100%'
            }}
          />
        </Tooltip>
      ) : (
        <Tooltip
          placement="top"
          id={props.id}
          trigger="hover"
          overlay={props.overlay}
          mouseEnterDelay={0.2}
          overlayInnerStyle={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 600
          }}
        >
          <section
            className="containDivPDF"
            id={props.id}
            style={{
              height: '35px',
              cursor: 'pointer',
              width: '35px',
              backgroundColor: props.OColor,
              maskImage: `url(${
                props.images[props.assetId]
                  ? urlFor(
                      props.images[props.assetId]
                        ? props.images[props.assetId]
                        : ''
                    )
                      .auto('format')
                      .width(140)
                      .url()
                  : props.src
              })`,
              maskSize: '100% 100%',
              maskRepeat: 'no-repeat',
              WebkitMaskImage: `url(${
                props.images[props.assetId]
                  ? urlFor(
                      props.images[props.assetId]
                        ? props.images[props.assetId]
                        : ''
                    )
                      .auto('format')
                      .width(140)
                      .url()
                  : props.src
              })`,
              WebkitMaskRepeat: 'no-repeat',
              WebkitMaskSize: '100% 100%'
            }}
          />
        </Tooltip>
      )}
    </section>
  );
};

const ChevronHelper = (props) => {
  let Ptemp = { ...props };
  props = { ...Ocolorasay(Ptemp) };
  if (props.OColor === '#') props.OColor = undefined;
  return !props.OColor ? (
    <div
      className="menu-icon-custom"
      id={props.id}
      style={{ cursor: 'pointer' }}
      onClick={props.onClick}
    >
      <img
        id={props.id}
        src={
          props.images[props.assetId]
            ? urlFor(
                props.images[props.assetId] ? props.images[props.assetId] : ''
              )
                .auto('format')
                .width(140)
                .url()
            : props.src
        }
        alt={props.imgAlt}
      />
    </div>
  ) : (
    <div
      className="menu-icon-custom"
      id={props.id}
      style={{ cursor: 'pointer' }}
      onClick={props.onClick}
    >
      {/* // <Tooltip placement="top" trigger='hover' overlay={props.overlay} mouseEnterDelay={0.2} overlayInnerStyle={{display: "flex", justifyContent: "center", alignItems: "center", fontWeight: 600}}> */}
      <div
        id={props.id}
        className="containDiv"
        style={{
          ...props.style,
          height: '70px',
          cursor: 'pointer',
          width: '70px',
          backgroundColor: props.OColor,
          maskImage: `url(${
            props.images[props.assetId]
              ? urlFor(
                  props.images[props.assetId] ? props.images[props.assetId] : ''
                )
                  .auto('format')
                  .width(140)
                  .url()
              : props.src
          })`,
          maskSize: '100% 100%',
          maskRepeat: 'no-repeat',
          WebkitMaskImage: `url(${
            props.images[props.assetId]
              ? urlFor(
                  props.images[props.assetId] ? props.images[props.assetId] : ''
                )
                  .auto('format')
                  .width(140)
                  .url()
              : props.src
          })`,
          WebkitMaskRepeat: 'no-repeat',
          WebkitMaskSize: '100% 100%'
        }}
      />
      {/* // </Tooltip> */}
    </div>
  );
};

const BackHelper = (props) => {
  let Ptemp = { ...props };
  props = { ...Ocolorasay(Ptemp) };
  if (props.OColor === '#') props.OColor = undefined;
  return !props.OColor ? (
    <div
      className="menu-icon-custom"
      id={props.id}
      style={{ ...props.style, cursor: 'pointer' }}
      onClick={props.onClick}
    >
      <img
        id={props.id}
        src={
          props.images[props.assetId]
            ? urlFor(
                props.images[props.assetId] ? props.images[props.assetId] : ''
              )
                .auto('format')
                .width(140)
                .url()
            : props.src
        }
        alt={props.imgAlt}
      />
    </div>
  ) : (
    <div
      id={props.id}
      className="menu-icon-custom"
      style={{ ...props.style, cursor: 'pointer' }}
      onClick={props.onClick}
    >
      <div
        id={props.id}
        className="containDiv"
        style={{
          height: '70px',
          cursor: 'pointer',
          width: '70px',
          backgroundColor: props.OColor,
          maskImage: `url(${
            props.images[props.assetId]
              ? urlFor(
                  props.images[props.assetId] ? props.images[props.assetId] : ''
                )
                  .auto('format')
                  .width(140)
                  .url()
              : props.src
          })`,
          maskSize: '100% 100%',
          maskRepeat: 'no-repeat',
          WebkitMaskImage: `url(${
            props.images[props.assetId]
              ? urlFor(
                  props.images[props.assetId] ? props.images[props.assetId] : ''
                )
                  .auto('format')
                  .width(140)
                  .url()
              : props.src
          })`,
          WebkitMaskRepeat: 'no-repeat',
          WebkitMaskSize: '100% 100%'
        }}
      />
      {/* // </Tooltip> */}
    </div>
  );
};

const ArrowHelper = (props) => {
  let Ptemp = { ...props };
  props = { ...Ocolorasay(Ptemp) };
  ////////onsole.log("ArrowHelper", props)
  //////////onsole.log("ArrowHelper", props)
  if (props.OColor === '#') props.OColor = undefined;
  return !props.OColor ? (
    <div
      id={props.id}
      className="menu-icon-custom"
      style={{
        top: '0px',
        ...props.style,
        cursor: 'pointer',
        opacity: props.opacity
      }}
      onClick={props.onClick}
    >
      <img
        id={props.id}
        style={{ padding: '0px' }}
        src={
          props.images[props.assetId]
            ? urlFor(
                props.images[props.assetId] ? props.images[props.assetId] : ''
              )
                .auto('format')
                .width(140)
                .url()
            : props.src
        }
        alt={props.imgAlt}
      />
    </div>
  ) : (
    <div
      id={props.id}
      className="menu-icon-custom"
      style={{ ...props.style, cursor: 'pointer', opacity: props.opacity }}
      onClick={props.onClick}
    >
      {/* // <Tooltip placement="top" trigger='hover' overlay={props.overlay} mouseEnterDelay={0.2} overlayInnerStyle={{display: "flex", justifyContent: "center", alignItems: "center", fontWeight: 600}}> */}
      <div
        id={props.id}
        className="containDiv"
        style={{
          height: '70px',
          cursor: 'pointer',
          width: '70px',
          top: '0px',
          ...props.style,
          backgroundColor: props.OColor,
          maskImage: `url(${
            props.images[props.assetId]
              ? urlFor(
                  props.images[props.assetId] ? props.images[props.assetId] : ''
                )
                  .auto('format')
                  .width(140)
                  .url()
              : props.src
          })`,
          maskSize: '100% 100%',
          maskRepeat: 'no-repeat',
          WebkitMaskImage: `url(${
            props.images[props.assetId]
              ? urlFor(
                  props.images[props.assetId] ? props.images[props.assetId] : ''
                )
                  .auto('format')
                  .width(140)
                  .url()
              : props.src
          })`,
          WebkitMaskRepeat: 'no-repeat',
          WebkitMaskSize: '100% 100%'
        }}
      />
      {/* // </Tooltip> */}
    </div>
  );
  // <div
  //   style={{
  //     height: '70px',
  //     width: '70px',
  //     zIndex: 3,
  //     position: 'relative',
  //     cursor:"pointer",
  //     top: '-71%',
  //     opacity: 0.7,
  //     left: 'calc(50% - 35px)'
  //   }}
  // >
  //   <img
  //     src={urlFor(
  //       props.images[props.assetId] ? props.images[props.assetId] : ''
  //     )
  //       .auto('format')
  //       .width(140)
  //       .url()}
  //     alt={props.imgAlt}
  //     style={{...props.style, cursor:"pointer"}}
  //   />
  // </div>
};

// // helper for Md icons
// const MdIconHelper = (props) => {
//   if(props.OColor === '#')
//   props.OColor = undefined;
//   return(
//   <IconContext.Provider
//     value={{
//       className: 'menu-icon-md',
//       style: {
//         color: props.OColor? props.OColor :props.chosenPortfolio.secondary_color
//           ? props.chosenPortfolio.secondary_color
//           : props.companyConfig.secondary_color
//       }
//     }}
//   >
//     <Tooltip placement="top" trigger='hover' overlay={props.overlay} mouseEnterDelay={0.2} overlayInnerStyle={{display: "flex", justifyContent: "center", alignItems: "center", fontWeight: 600}}>
//       {props.children}
//     </Tooltip>
//   </IconContext.Provider>
// )};

// const MdChevronHelper = (props) => {
//   if(props.OColor === '#')
//   props.OColor = undefined;
//   return(
//   <IconContext.Provider
//     value={{
//       className: 'menu-icon-md',
//       style: {
//         color: props.OColor? props.OColor: props.chosenPortfolio.secondary_color
//           ? props.chosenPortfolio.secondary_color
//           : props.companyConfig.secondary_color
//       }
//     }}
//   >
//       {props.children}
//   </IconContext.Provider>
// )};

const MdCloseHelper = (props) => (
  <IconContext.Provider
    value={{
      className: 'menu-icon-md',
      style: {
        color: 'black'
      }
    }}
  >
    {props.children}
  </IconContext.Provider>
);

export const AddShoppingCart = (props) => (
  <Fragment>
    {props.chosenPortfolio.icon_set &&
    props.chosenPortfolio.icon_set?.add_to_briefcase_icon &&
    props.chosenPortfolio.icon_set?.add_to_briefcase_icon.override_image ? (
      <>
        <IconHelper
          assetId={
            props.userInfo[0]?.company_id === '0aqy5XeUlWwzg0ahtHaH'
              ? props.chosenPortfolio?.icon_set?.add_to_briefcase_icon
                  .override_image.asset._ref
              : undefined
          }
          src={
            props.userInfo[0]?.company_id !== '0aqy5XeUlWwzg0ahtHaH'
              ? MdAddShoppingCart
              : undefined
          }
          imgAlt="add_to_briefcase_icon"
          Size={props.Size}
          chosenPortfolio={props.chosenPortfolio}
          {...props}
          onClick={props.onClick}
          overlay={props.overlay ? props.overlay : 'Add to Briefcase'}
          OColor={
            props.chosenPortfolio.icon_set?.add_to_briefcase_icon
              ?.override_Color !== '#' &&
            props.chosenPortfolio.icon_set?.add_to_briefcase_icon
              ?.override_Color !== undefined
              ? props.chosenPortfolio.icon_set?.add_to_briefcase_icon
                  ?.override_Color
              : undefined
          }
        />
      </>
    ) : (
      <IconHelper
        Size={props.Size}
        chosenPortfolio={props.chosenPortfolio}
        src={MdAddShoppingCart}
        onClick={props.onClick}
        OColor={
          props.chosenPortfolio.icon_set?.add_to_briefcase_icon
            ?.override_Color !== '#' &&
          props.chosenPortfolio.icon_set?.add_to_briefcase_icon
            ?.override_Color !== undefined
            ? props.chosenPortfolio.icon_set?.add_to_briefcase_icon
                ?.override_Color
            : undefined
        }
        {...props}
        overlay="Add to Briefcase"
      >
        {/* <img
          src={MdAddShoppingCart}
            onClick={props.onClick}
          /> */}
      </IconHelper>
    )}
  </Fragment>
);

export const ShoppingCart = (props) => {
  ////onsole.log("props['data-tip']", props['data-tip'],props)
  return (
    <Fragment>
      {props.chosenPortfolio.icon_set &&
      props.chosenPortfolio.icon_set?.briefcase_icon &&
      props.chosenPortfolio.icon_set?.briefcase_icon.override_image ? (
        <>
          <IconHelper
            assetId={
              props.userInfo[0]?.company_id === '0aqy5XeUlWwzg0ahtHaH'
                ? props.chosenPortfolio?.icon_set?.briefcase_icon.override_image
                    .asset._ref
                : undefined
            }
            Size={props.Size}
            chosenPortfolio={props.chosenPortfolio}
            src={
              props.userInfo[0]?.company_id !== '0aqy5XeUlWwzg0ahtHaH'
                ? MdShoppingCart
                : undefined
            }
            imgAlt="briefcase_icon"
            {...props}
            onClick={props.onClick}
            overlay={props['data-tip']}
            OColor={
              props.chosenPortfolio.icon_set?.briefcase_icon?.override_Color !==
                '#' &&
              props.chosenPortfolio.icon_set?.briefcase_icon?.override_Color !==
                undefined
                ? props.chosenPortfolio.icon_set?.briefcase_icon?.override_Color
                : undefined
            }
          />
        </>
      ) : (
        <IconHelper
          Size={props.Size}
          chosenPortfolio={props.chosenPortfolio}
          src={MdShoppingCart}
          onClick={props.onClick}
          OColor={
            props.chosenPortfolio.icon_set?.briefcase_icon?.override_Color !==
              '#' &&
            props.chosenPortfolio.icon_set?.briefcase_icon?.override_Color !==
              undefined
              ? props.chosenPortfolio.icon_set?.briefcase_icon?.override_Color
              : undefined
          }
          {...props}
          overlay={props['data-tip']}
        >
          {/* <MdShoppingCart onClick={props.onClick}/> */}
        </IconHelper>
      )}
    </Fragment>
  );
};

// Note custom need from sanity
export const PersonAdd = (props) => (
  <Fragment>
    {props.chosenPortfolio.icon_set &&
    props.chosenPortfolio.icon_set?.add_person_icon &&
    props.chosenPortfolio.icon_set?.add_person_icon.override_image ? (
      <>
        <IconHelper
          assetId={
            props.userInfo[0]?.company_id === '0aqy5XeUlWwzg0ahtHaH'
              ? props.chosenPortfolio?.icon_set?.add_person_icon.override_image
                  .asset._ref
              : undefined
          }
          src={
            props.userInfo[0]?.company_id !== '0aqy5XeUlWwzg0ahtHaH'
              ? MdPersonAdd
              : undefined
          }
          imgAlt="add_person_icon"
          Size={props.Size}
          chosenPortfolio={props.chosenPortfolio}
          {...props}
          onClick={props.onClick}
          overlay="Edit Personal Info"
          OColor={
            props.chosenPortfolio.icon_set?.add_person_icon?.override_Color !==
              '#' &&
            props.chosenPortfolio.icon_set?.add_person_icon?.override_Color !==
              undefined
              ? props.chosenPortfolio.icon_set?.add_person_icon?.override_Color
              : undefined
          }
        />
      </>
    ) : (
      <IconHelper
        Size={props.Size}
        chosenPortfolio={props.chosenPortfolio}
        src={MdPersonAdd}
        onClick={props.onClick}
        OColor={
          props.chosenPortfolio?.icon_set?.add_person_icon?.override_Color !==
            '#' &&
          props.chosenPortfolio?.icon_set?.add_person_icon?.override_Color !==
            undefined
            ? props.chosenPortfolio.icon_set?.add_person_icon?.override_Color
            : undefined
        }
        {...props}
        overlay="Edit Personal Info"
      >
        {/* <MdPersonAdd
          onClick={props.onClick}
        /> */}
      </IconHelper>
    )}
  </Fragment>
);

export const Fullscreen = (props) => (
  <Fragment>
    {props.chosenPortfolio.icon_set &&
    props.chosenPortfolio.icon_set?.fullscreen_icon &&
    props.chosenPortfolio.icon_set?.fullscreen_icon.override_image ? (
      <>
        <IconHelper
          assetId={
            props.userInfo[0]?.company_id === '0aqy5XeUlWwzg0ahtHaH'
              ? props.chosenPortfolio?.icon_set?.fullscreen_icon.override_image
                  .asset._ref
              : undefined
          }
          src={
            props.userInfo[0]?.company_id !== '0aqy5XeUlWwzg0ahtHaH'
              ? MdFullscreen
              : undefined
          }
          imgAlt="fullscreen_icon"
          {...props}
          onClick={props.onClick}
          overlay="Fullscreen/Windowed"
          OColor={
            props.chosenPortfolio?.icon_set?.fullscreen_icon?.override_Color !==
              '#' &&
            props.chosenPortfolio?.icon_set?.fullscreen_icon?.override_Color !==
              undefined
              ? props.chosenPortfolio?.icon_set?.fullscreen_icon?.override_Color
              : undefined
          }
        />
      </>
    ) : (
      <IconHelper
        src={MdFullscreen}
        onClick={props.onClick}
        OColor={
          props.chosenPortfolio?.icon_set?.fullscreen_icon?.override_Color !==
            '#' &&
          props.chosenPortfolio?.icon_set?.fullscreen_icon?.override_Color !==
            undefined
            ? props.chosenPortfolio?.icon_set?.fullscreen_icon?.override_Color
            : undefined
        }
        {...props}
        overlay="Fullscreen/Windowed"
      >
        {/* <MdFullscreen onClick={props.onClick} /> */}
      </IconHelper>
    )}
  </Fragment>
);

export const FullscreenExit = (props) => (
  <Fragment>
    {props.chosenPortfolio.icon_set &&
    props.chosenPortfolio.icon_set?.windowed_icon &&
    props.chosenPortfolio.icon_set?.windowed_icon.override_image ? (
      <>
        <IconHelper
          assetId={
            props.userInfo[0]?.company_id === '0aqy5XeUlWwzg0ahtHaH'
              ? props.chosenPortfolio?.icon_set?.windowed_icon.override_image
                  .asset._ref
              : undefined
          }
          src={
            props.userInfo[0]?.company_id !== '0aqy5XeUlWwzg0ahtHaH'
              ? MdFullscreenExit
              : undefined
          }
          imgAlt="windowed_icon"
          {...props}
          onClick={props.onClick}
          overlay="Fullscreen/Windowed"
          OColor={
            props.chosenPortfolio?.icon_set?.windowed_icon?.override_Color !==
              '#' &&
            props.chosenPortfolio?.icon_set?.windowed_icon?.override_Color !==
              undefined
              ? props.chosenPortfolio?.icon_set?.windowed_icon?.override_Color
              : undefined
          }
        />
      </>
    ) : (
      <IconHelper
        src={MdFullscreenExit}
        onClick={props.onClick}
        OColor={
          props.chosenPortfolio?.icon_set?.windowed_icon?.override_Color !==
            '#' &&
          props.chosenPortfolio?.icon_set?.windowed_icon?.override_Color !==
            undefined
            ? props.chosenPortfolio?.icon_set?.windowed_icon?.override_Color
            : undefined
        }
        {...props}
        overlay="Fullscreen/Windowed"
      >
        {/* <MdFullscreenExit onClick={props.onClick} /> */}
      </IconHelper>
    )}
  </Fragment>
);

export const NoteAdd = (props) => (
  <Fragment>
    {props.chosenPortfolio.icon_set &&
    props.chosenPortfolio.icon_set?.personal_notes_icon &&
    props.chosenPortfolio.icon_set?.personal_notes_icon.override_image ? (
      <>
        <IconHelper
          assetId={
            props.userInfo[0]?.company_id === '0aqy5XeUlWwzg0ahtHaH'
              ? props.chosenPortfolio?.icon_set?.personal_notes_icon
                  .override_image.asset._ref
              : undefined
          }
          src={
            props.userInfo[0]?.company_id !== '0aqy5XeUlWwzg0ahtHaH'
              ? MdNoteAdd
              : undefined
          }
          imgAlt="personal_notes_icon"
          Size={props.Size}
          chosenPortfolio={props.chosenPortfolio}
          {...props}
          onClick={props.onClick}
          overlay={`${
            props.Overlay
              ? props.Overlay
              : props.isGuestMode
              ? 'Comments'
              : 'Notepad'
          }`}
          OColor={
            props.chosenPortfolio?.icon_set?.personal_notes_icon
              ?.override_Color !== '#' &&
            props.chosenPortfolio?.icon_set?.personal_notes_icon
              ?.override_Color !== undefined
              ? props.chosenPortfolio?.icon_set?.personal_notes_icon
                  ?.override_Color
              : undefined
          }
        />
      </>
    ) : (
      <IconHelper
        Size={props.Size}
        chosenPortfolio={props.chosenPortfolio}
        src={MdNoteAdd}
        onClick={props.onClick}
        OColor={
          props.chosenPortfolio?.icon_set?.personal_notes_icon
            ?.override_Color !== '#' &&
          props.chosenPortfolio?.icon_set?.personal_notes_icon
            ?.override_Color !== undefined
            ? props.chosenPortfolio?.icon_set?.personal_notes_icon
                ?.override_Color
            : undefined
        }
        {...props}
        overlay={`${
          props.Overlay
            ? props.Overlay
            : props.isGuestMode
            ? 'Comments'
            : 'Notepad'
        }`}
      >
        {/* <MdNoteAdd onClick={props.onClick} /> */}
      </IconHelper>
    )}
  </Fragment>
);

export const SpeakerNotes = (props) => (
  <Fragment>
    {props.chosenPortfolio.icon_set &&
    props.chosenPortfolio.icon_set?.speaker_notes_icon &&
    props.chosenPortfolio.icon_set?.speaker_notes_icon.override_image ? (
      <>
        <IconHelper
          assetId={
            props.userInfo[0]?.company_id === '0aqy5XeUlWwzg0ahtHaH'
              ? props.chosenPortfolio?.icon_set?.speaker_notes_icon
                  .override_image.asset._ref
              : undefined
          }
          src={
            props.userInfo[0]?.company_id !== '0aqy5XeUlWwzg0ahtHaH'
              ? MdSpeakerNotes
              : undefined
          }
          imgAlt="speaker_notes_icon"
          Size={props.Size}
          chosenPortfolio={props.chosenPortfolio}
          {...props}
          onClick={props.onClick}
          overlay="View script"
          OColor={
            props.chosenPortfolio?.icon_set?.speaker_notes_icon
              ?.override_Color !== '#' &&
            props.chosenPortfolio?.icon_set?.speaker_notes_icon
              ?.override_Color !== undefined
              ? props.chosenPortfolio?.icon_set?.speaker_notes_icon
                  ?.override_Color
              : undefined
          }
        />
      </>
    ) : (
      <IconHelper
        Size={props.Size}
        chosenPortfolio={props.chosenPortfolio}
        src={MdSpeakerNotes}
        onClick={props.onClick}
        OColor={
          props.chosenPortfolio?.icon_set?.speaker_notes_icon
            ?.override_Color !== '#' &&
          props.chosenPortfolio?.icon_set?.speaker_notes_icon
            ?.override_Color !== undefined
            ? props.chosenPortfolio?.icon_set?.speaker_notes_icon
                ?.override_Color
            : undefined
        }
        {...props}
        overlay="View script"
      >
        {/* <MdSpeakerNotes onClick={props.onClick} /> */}
      </IconHelper>
    )}
  </Fragment>
);

export const Close = (
  props //Flag
) => (
  <Fragment>
    {props.chosenPortfolio.icon_set &&
    props.chosenPortfolio.icon_set?.close_icon &&
    props.chosenPortfolio.icon_set?.close_icon.override_image ? (
      <>
        <IconHelper
          assetId={
            props.userInfo[0]?.company_id === '0aqy5XeUlWwzg0ahtHaH'
              ? props.chosenPortfolio?.icon_set?.close_icon.override_image.asset
                  ._ref
              : undefined
          }
          src={
            props.userInfo[0]?.company_id !== '0aqy5XeUlWwzg0ahtHaH'
              ? MdSpeakerNotes
              : undefined
          }
          imgAlt="close_icon"
          {...props}
          onClick={props.onClick}
          overlay="Close"
          OColor={
            props.chosenPortfolio?.icon_set?.close_icon?.override_Color !==
              '#' &&
            props.chosenPortfolio?.icon_set?.close_icon?.override_Color !==
              undefined
              ? props.chosenPortfolio?.icon_set?.close_icon?.override_Color
              : undefined
          }
        />
      </>
    ) : (
      <MdCloseHelper
        OColor={
          props.chosenPortfolio?.icon_set?.close_icon?.override_Color !== '#' &&
          props.chosenPortfolio?.icon_set?.close_icon?.override_Color !==
            undefined
            ? props.chosenPortfolio?.icon_set?.close_icon?.override_Color
            : undefined
        }
        {...props}
      >
        <MdClose onClick={props.onClick} />
      </MdCloseHelper>
    )}
  </Fragment>
);

export const Favorite = (props) => (
  <Fragment>
    {props.chosenPortfolio.icon_set &&
    props.chosenPortfolio.icon_set?.like_active_icon &&
    props.chosenPortfolio.icon_set?.like_active_icon.override_image ? (
      <>
        <IconHelper
          assetId={
            props.userInfo[0]?.company_id === '0aqy5XeUlWwzg0ahtHaH'
              ? props.chosenPortfolio?.icon_set?.like_active_icon.override_image
                  .asset._ref
              : undefined
          }
          src={
            props.userInfo[0]?.company_id !== '0aqy5XeUlWwzg0ahtHaH'
              ? MdFavorite
              : undefined
          }
          imgAlt="like_active_icon"
          Size={props.Size}
          chosenPortfolio={props.chosenPortfolio}
          {...props}
          onClick={props.onClick}
          overlay="Like"
          OColor={
            props.chosenPortfolio?.icon_set?.like_active_icon
              ?.override_Color !== '#' &&
            props.chosenPortfolio?.icon_set?.like_active_icon
              ?.override_Color !== undefined
              ? props.chosenPortfolio?.icon_set?.like_active_icon
                  ?.override_Color
              : undefined
          }
        />
      </>
    ) : (
      <IconHelper
        Size={props.Size}
        chosenPortfolio={props.chosenPortfolio}
        src={MdFavorite}
        onClick={props.onClick}
        OColor={
          props.chosenPortfolio?.icon_set?.like_active_icon?.override_Color !==
            '#' &&
          props.chosenPortfolio?.icon_set?.like_active_icon?.override_Color !==
            undefined
            ? props.chosenPortfolio?.icon_set?.like_active_icon?.override_Color
            : undefined
        }
        {...props}
        overlay="Like"
      >
        {/* <MdFavorite onClick={props.onClick} /> */}
      </IconHelper>
    )}
  </Fragment>
);

export const FavoriteBorder = (props) => (
  <Fragment>
    {props.chosenPortfolio.icon_set &&
    props.chosenPortfolio.icon_set?.like_inactive_icon &&
    props.chosenPortfolio.icon_set?.like_inactive_icon.override_image ? (
      <>
        <IconHelper
          assetId={
            props.userInfo[0]?.company_id === '0aqy5XeUlWwzg0ahtHaH'
              ? props.chosenPortfolio?.icon_set?.like_inactive_icon
                  .override_image.asset._ref
              : undefined
          }
          src={
            props.userInfo[0]?.company_id !== '0aqy5XeUlWwzg0ahtHaH'
              ? MdFavoriteBorder
              : undefined
          }
          imgAlt="like_inactive_icon"
          Size={props.Size}
          chosenPortfolio={props.chosenPortfolio}
          {...props}
          onClick={props.onClick}
          overlay="Like"
          OColor={
            props.chosenPortfolio?.icon_set?.like_inactive_icon
              ?.override_Color !== '#' &&
            props.chosenPortfolio?.icon_set?.like_inactive_icon
              ?.override_Color !== undefined
              ? props.chosenPortfolio?.icon_set?.like_inactive_icon
                  ?.override_Color
              : undefined
          }
        />
      </>
    ) : (
      <IconHelper
        Size={props.Size}
        chosenPortfolio={props.chosenPortfolio}
        src={MdFavoriteBorder}
        onClick={props.onClick}
        OColor={
          props.chosenPortfolio?.icon_set?.like_inactive_icon
            ?.override_Color !== '#' &&
          props.chosenPortfolio?.icon_set?.like_inactive_icon
            ?.override_Color !== undefined
            ? props.chosenPortfolio?.icon_set?.like_inactive_icon
                ?.override_Color
            : undefined
        }
        {...props}
        overlay="Like"
      >
        {/* <MdFavoriteBorder onClick={props.onClick} /> */}
      </IconHelper>
    )}
  </Fragment>
);

export const ChevronRight = (props) => (
  <Fragment>
    {props.chosenPortfolio.icon_set &&
    props.chosenPortfolio.icon_set?.right_arrow_icon &&
    props.chosenPortfolio.icon_set?.right_arrow_icon.override_image ? (
      <ChevronHelper
        assetId={
          props.userInfo[0]?.company_id === '0aqy5XeUlWwzg0ahtHaH'
            ? props.chosenPortfolio?.icon_set?.right_arrow_icon.override_image
                .asset._ref
            : undefined
        }
        src={
          props.userInfo[0]?.company_id !== '0aqy5XeUlWwzg0ahtHaH'
            ? MdChevronRight
            : undefined
        }
        imgAlt="right_arrow_icon"
        {...props}
        OColor={
          props.chosenPortfolio.icon_set?.right_arrow_icon?.override_Color !==
            '#' &&
          props.chosenPortfolio.icon_set?.right_arrow_icon?.override_Color !==
            undefined
            ? props.chosenPortfolio.icon_set?.right_arrow_icon?.override_Color
            : undefined
        }
      />
    ) : (
      <ChevronHelper
        src={MdChevronRight}
        onClick={props.onClick}
        OColor={
          props.chosenPortfolio?.icon_set?.right_arrow_icon?.override_Color !==
            '#' &&
          props.chosenPortfolio?.icon_set?.right_arrow_icon?.override_Color !==
            undefined
            ? props.chosenPortfolio.icon_set?.right_arrow_icon?.override_Color
            : undefined
        }
        {...props}
      >
        {/* <img src={MdChevronRight} onClick={props.onClick} /> */}
      </ChevronHelper>
    )}
  </Fragment>
);

export const SlideUpArrow = (props) => (
  <Fragment>
    {props.chosenPortfolio.icon_set &&
    props.chosenPortfolio.icon_set?.slideup_icon &&
    props.chosenPortfolio.icon_set?.slideup_icon.override_image ? (
      <ArrowHelper
        assetId={
          props.userInfo[0]?.company_id === '0aqy5XeUlWwzg0ahtHaH'
            ? props.chosenPortfolio?.icon_set?.slideup_icon.override_image.asset
                ._ref
            : undefined
        }
        src={
          props.userInfo[0]?.company_id !== '0aqy5XeUlWwzg0ahtHaH'
            ? SliderArrows
            : undefined
        }
        imgAlt="arrow-up1"
        {...props}
        style={{
          ...props.style,
          height: '70px',
          width: '70px',
          zIndex: 3,
          position: 'relative',
          top: '-70px',
          opacity: 0.7,
          left: 'calc(50% - 35px)'
        }}
        OColor={
          props.chosenPortfolio?.icon_set?.slideup_icon?.override_Color !==
            '#' &&
          props.chosenPortfolio?.icon_set?.slideup_icon?.override_Color !==
            undefined
            ? props.chosenPortfolio?.icon_set?.slideup_icon?.override_Color
            : undefined
        }
      />
    ) : (
      <ArrowHelper
        {...props}
        OColor={
          props.chosenPortfolio?.icon_set?.slideup_icon?.override_Color !==
            '#' &&
          props.chosenPortfolio?.icon_set?.slideup_icon?.override_Color !==
            undefined
            ? props.chosenPortfolio?.icon_set?.slideup_icon?.override_Color
            : undefined
        }
        src={SliderArrows}
        alt="arrow-up2"
        style={{
          ...props.style,
          height: '70px',
          width: '70px',
          zIndex: 3,
          position: 'relative',
          top: '-70px',
          opacity: 0.7,
          left: 'calc(50% - 35px)'
        }}
      />
    )}
  </Fragment>
);

export const ChevronLeft = (props) => (
  <Fragment>
    {props.chosenPortfolio.icon_set &&
    props.chosenPortfolio.icon_set?.left_arrow_icon &&
    props.chosenPortfolio.icon_set?.left_arrow_icon.override_image ? (
      <ChevronHelper
        assetId={
          props.userInfo[0]?.company_id === '0aqy5XeUlWwzg0ahtHaH'
            ? props.chosenPortfolio?.icon_set?.left_arrow_icon.override_image
                .asset._ref
            : undefined
        }
        src={
          props.userInfo[0]?.company_id !== '0aqy5XeUlWwzg0ahtHaH'
            ? MdChevronLeft
            : undefined
        }
        imgAlt="left_arrow_icon"
        {...props}
        OColor={
          props.chosenPortfolio?.icon_set?.left_arrow_icon?.override_Color !==
            '#' &&
          props.chosenPortfolio?.icon_set?.left_arrow_icon?.override_Color !==
            undefined
            ? props.chosenPortfolio?.icon_set?.left_arrow_icon?.override_Color
            : undefined
        }
      />
    ) : (
      <ChevronHelper
        src={MdChevronLeft}
        onClick={props.onClick}
        OColor={
          props.chosenPortfolio?.icon_set?.left_arrow_icon?.override_Color !==
            '#' &&
          props.chosenPortfolio?.icon_set?.left_arrow_icon?.override_Color !==
            undefined
            ? props.chosenPortfolio?.icon_set?.left_arrow_icon?.override_Color
            : undefined
        }
        {...props}
      >
        {/* <img src={MdChevronLeft} onClick={props.onClick} /> */}
      </ChevronHelper>
    )}
  </Fragment>
);

export const BackButton = (props) => (
  <Fragment>
    {props.chosenPortfolio.icon_set &&
    props.chosenPortfolio.icon_set?.back_icon &&
    props.chosenPortfolio.icon_set?.back_icon.override_image ? (
      <>
        <BackHelper
          assetId={
            props.userInfo[0]?.company_id === '0aqy5XeUlWwzg0ahtHaH'
              ? props.chosenPortfolio?.icon_set?.back_icon.override_image.asset
                  ._ref
              : undefined
          }
          src={
            props.userInfo[0]?.company_id !== '0aqy5XeUlWwzg0ahtHaH'
              ? ArrowLeft
              : undefined
          }
          imgAlt="back_icon"
          {...props}
          OColor={
            props.chosenPortfolio?.icon_set?.back_icon?.override_Color !==
              '#' &&
            props.chosenPortfolio?.icon_set?.back_icon?.override_Color !==
              undefined
              ? props.chosenPortfolio?.icon_set?.back_icon?.override_Color
              : undefined
          }
        />
      </>
    ) : (
      <BackHelper
        src={ArrowLeft}
        alt="back"
        onClick={props.onClick}
        {...props}
        OColor={
          props.chosenPortfolio?.icon_set?.back_icon?.override_Color !== '#' &&
          props.chosenPortfolio?.icon_set?.back_icon?.override_Color !==
            undefined
            ? props.chosenPortfolio?.icon_set?.back_icon?.override_Color
            : undefined
        }
      />
    )}
  </Fragment>
);

export const EndDemo = (props) => (
  <Fragment>
    {props.chosenPortfolio.icon_set &&
    props.chosenPortfolio.icon_set?.endDemo_icon &&
    props.chosenPortfolio.icon_set?.endDemo_icon.override_image ? (
      <>
        <IconHelper
          assetId={
            props.userInfo[0]?.company_id === '0aqy5XeUlWwzg0ahtHaH'
              ? props.chosenPortfolio?.icon_set?.endDemo_icon.override_image
                  .asset._ref
              : undefined
          }
          src={
            props.userInfo[0]?.company_id !== '0aqy5XeUlWwzg0ahtHaH'
              ? MdExitToApp
              : undefined
          }
          imgAlt="end_demo"
          Size={props.Size}
          chosenPortfolio={props.chosenPortfolio}
          {...props}
          onClick={props.onClick}
          overlay="Exit"
          OColor={
            props.chosenPortfolio?.icon_set?.endDemo_icon?.override_Color !==
              '#' &&
            props.chosenPortfolio?.icon_set?.endDemo_icon?.override_Color !==
              undefined
              ? props.chosenPortfolio?.icon_set?.endDemo_icon?.override_Color
              : undefined
          }
        />
      </>
    ) : (
      <IconHelper
        Size={props.Size}
        chosenPortfolio={props.chosenPortfolio}
        src={MdExitToApp}
        onClick={props.onClick}
        OColor={
          props.chosenPortfolio?.icon_set?.endDemo_icon?.override_Color !==
            '#' &&
          props.chosenPortfolio?.icon_set?.endDemo_icon?.override_Color !==
            undefined
            ? props.chosenPortfolio?.icon_set?.endDemo_icon?.override_Color
            : undefined
        }
        {...props}
        overlay="Exit"
      >
        <MdExitToApp onClick={props.onClick} />
      </IconHelper>
    )}
  </Fragment>
);
export const CustomIconOne = (props) => {
  //////onsole.log("ICONS",props.chosenPortfolio.icon_set?.custom_icon_1?.web_link);
  if (
    props.chosenPortfolio.icon_set?.custom_icon_1?.web_link === '' ||
    !props.chosenPortfolio.icon_set?.custom_icon_1?.web_link
  )
    return <></>;
  return (
    //Calendar
    <Fragment>
      {props.chosenPortfolio.icon_set &&
      props.chosenPortfolio.icon_set?.custom_icon_1 &&
      props.chosenPortfolio.icon_set?.custom_icon_1.override_image ? (
        <>
          <IconHelper
            assetId={
              props.userInfo[0].company_id === '0aqy5XeUlWwzg0ahtHaH'
                ? props.chosenPortfolio?.icon_set?.custom_icon_1.override_image
                    .asset._ref
                : undefined
            }
            src={
              props.userInfo[0]?.company_id !== '0aqy5XeUlWwzg0ahtHaH'
                ? Calendar
                : undefined
            }
            imgAlt="Web Link Icon"
            Size={props.Size}
            chosenPortfolio={props.chosenPortfolio}
            {...props}
            onClick={() => {
              let a = document.createElement('a');
              a.href = props.chosenPortfolio.icon_set?.custom_icon_1?.web_link;
              a.target = '_blank';
              const clickHandler = (ele) => {
                setTimeout(() => {
                  ele.removeEventListener('click', clickHandler);
                }, 150);
              };
              a.addEventListener('click', clickHandler(a), false);
              a.click();
              return;
            }}
            overlay="Web Link"
            OColor={
              props.chosenPortfolio?.icon_set?.custom_icon_1?.override_Color !==
                '#' &&
              props.chosenPortfolio?.icon_set?.custom_icon_1?.override_Color !==
                undefined
                ? props.chosenPortfolio?.icon_set?.custom_icon_1?.override_Color
                : undefined
            }
          />
        </>
      ) : (
        // null props.chosenPortfolio.icon_set?.custom_icon_1?.web_link
        <IconHelper
          Size={props.Size}
          chosenPortfolio={props.chosenPortfolio}
          src={Calendar}
          onClick={() => {
            let a = document.createElement('a');
            a.href = props.chosenPortfolio.icon_set?.custom_icon_1?.web_link;
            a.target = '_blank';
            const clickHandler = (ele) => {
              setTimeout(() => {
                ele.removeEventListener('click', clickHandler);
              }, 150);
            };
            a.addEventListener('click', clickHandler(a), false);
            a.click();
            return;
          }}
          OColor={
            props.chosenPortfolio?.icon_set?.like_inactive_icon
              ?.override_Color !== '#' &&
            props.chosenPortfolio?.icon_set?.like_inactive_icon
              ?.override_Color !== undefined
              ? props.chosenPortfolio?.icon_set?.like_inactive_icon
                  ?.override_Color
              : undefined
          }
          {...props}
          overlay="Web Link"
        >
          {/* <MdFavoriteBorder onClick={props.onClick} /> */}
        </IconHelper>
      )}
    </Fragment>
  );
};
export const AnnotatorIcon = (props) => {
  if (window.innerWidth < 451) return null;

  return (
    <Fragment>
      {props.chosenPortfolio.icon_set &&
      props.chosenPortfolio.icon_set?.annotator_icon &&
      props.chosenPortfolio.icon_set?.annotator_icon.IconFeatureToggle &&
      props.chosenPortfolio.icon_set?.annotator_icon.override_image ? (
        <>
          <IconHelper
            assetId={
              props.userInfo[0].company_id === '0aqy5XeUlWwzg0ahtHaH'
                ? props.chosenPortfolio?.icon_set?.annotator_icon.override_image
                    .asset._ref
                : undefined
            }
            src={
              props.userInfo[0]?.company_id !== '0aqy5XeUlWwzg0ahtHaH'
                ? Annotator
                : undefined
            }
            imgAlt="Annotator Icon"
            {...props}
            onClick={props.onClick}
            overlay="Annotator"
            OColor={
              props.chosenPortfolio?.icon_set?.annotator_icon
                ?.override_Color !== '#' &&
              props.chosenPortfolio?.icon_set?.annotator_icon
                ?.override_Color !== undefined
                ? props.chosenPortfolio?.icon_set?.annotator_icon
                    ?.override_Color
                : undefined
            }
          />
        </>
      ) : props.chosenPortfolio.icon_set &&
        props.chosenPortfolio.icon_set?.annotator_icon &&
        props.chosenPortfolio.icon_set?.annotator_icon.IconFeatureToggle !==
          false ? (
        <IconHelper
          src={Annotator}
          onClick={props.onClick}
          OColor={
            props.chosenPortfolio?.icon_set?.annotator_icon?.override_Color !==
              '#' &&
            props.chosenPortfolio?.icon_set?.annotator_icon?.override_Color !==
              undefined
              ? props.chosenPortfolio?.icon_set?.annotator_icon?.override_Color
              : undefined
          }
          {...props}
          overlay="Annotator"
        >
          <Annotator onClick={props.onClick} />
        </IconHelper>
      ) : null}
    </Fragment>
  );
};
//Annotator

export const DownloadIcon = (props) => (
  <Fragment>
    <Fragment>
      {/* <FWPDFToolHelper src={D} onClick={props.onClick} OColor={props.chosenPortfolio.bulk_icon_override?props.chosenPortfolio.bulk_icon_override:"black"} {...props} overlay="Download"> */}
      <FWPDFToolHelper
        src={D}
        onClick={props.onClick}
        OColor={'#F0F0F0'}
        {...props}
        overlay="Download"
      >
        <MdExitToApp onClick={props.onClick} />
      </FWPDFToolHelper>
    </Fragment>
  </Fragment>
);
export const ZoomInIcon = (props) => (
  <Fragment>
    {/* <FWPDFToolHelper src={ZI} onClick={props.onClick} OColor={props.chosenPortfolio.bulk_icon_override?props.chosenPortfolio.bulk_icon_override:"black"} {...props} overlay="Zoom In"> */}
    <FWPDFToolHelper
      src={ZI}
      onClick={props.onClick}
      OColor={'#F0F0F0'}
      {...props}
      overlay="Zoom In"
    >
      <MdExitToApp onClick={props.onClick} />
    </FWPDFToolHelper>
  </Fragment>
);
export const ZoomOutIcon = (props) => (
  <Fragment>
    {/* <FWPDFToolHelper src={ZO} onClick={props.onClick} OColor={props.chosenPortfolio.bulk_icon_override?props.chosenPortfolio.bulk_icon_override:"black"} {...props} overlay="Zoom Out"> */}
    <FWPDFToolHelper
      src={ZO}
      onClick={props.onClick}
      OColor={'#F0F0F0'}
      {...props}
      overlay="Zoom Out"
    >
      <MdExitToApp onClick={props.onClick} />
    </FWPDFToolHelper>
  </Fragment>
);
