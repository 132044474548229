import React from 'react';
import { urlFor } from '../../../utils/sanityHelper';
import SanityMuxPlayer from '../../../containers/MuxPlayer';
import styled from 'styled-components';
// import PDFViewer2 from '../../FullWidthPDFViewer';

const ThumbsBigNav = styled.div`
  // overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  transition: all 0.2s ease;

  .logo-thumb {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    img {
      width: 100%;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }

  // &:hover {
  //   filter: ${(props) =>
    props.dropColor
      ? `drop-shadow(0 0 0.9rem ${props.dropColor})`
      : `drop-shadow(0 0 0.9rem rgb(68, 159, 245))`};
  // }
`;

const GalleryThumbsContent = ({
  chosenPortfolio,
  foundPage,
  child,
  index,
  onNavClick,
  videos,
  pageContents,
  isTopLevel,
  isAtEnd,
  controlsOverride,
  Size,
  pdfs,
  contents,
  pageReference,
  length
}) => {
  // const [opacityBack, setOpacityBack] = useState(1);
  // const [isVisible, setIsVisible] = useState('hidden');

  // const enterContent = () => {
  //   if (!isTopLevel) {
  //     setOpacityBack(0.4);
  //   }
  //   setIsVisible('visible');
  // }

  // const leaveContent = () => {
  //   setOpacityBack(1);
  //   setIsVisible('hidden');
  // }

  let menuSectionWidthDecider;
  if (length === 1) {
    menuSectionWidthDecider = 'one';
  } else if (length === 2) {
    menuSectionWidthDecider = 'two';
  } else if (length === 3) {
    menuSectionWidthDecider = 'three';
  } else if (length >= 4) {
    menuSectionWidthDecider = 'four-plus';
  } else {
    menuSectionWidthDecider = 'four-plus';
  }

  let overlay = (
    <section
      className={`top-level-overlay ${
        pageReference.CatalogTopLevelSelection?.topLevelSelection === 'topImage'
          ? 'top-image'
          : ''
      }${
        pageReference.CatalogTopLevelSelection?.topLevelSelection ===
        'bottomImage'
          ? 'bottom-image'
          : ''
      }${
        pageReference.CatalogTopLevelSelection?.topLevelSelection ===
        'fullImage'
          ? 'full-image'
          : ''
      } ${menuSectionWidthDecider}`}
    >
      <p
        className={`overlay-title ${menuSectionWidthDecider}`}
        style={{ color: chosenPortfolio?.text_color ? 'white' : 'white' }}
      >
        {foundPage?.title}
      </p>
      <p
        className={`overlay-subtitle ${menuSectionWidthDecider}`}
        style={{ color: chosenPortfolio?.text_color ? 'white' : 'white' }}
      >
        {foundPage?.subtitle}
      </p>
      <p
        className={`overlay-description ${menuSectionWidthDecider}`}
        style={{ color: chosenPortfolio?.text_color ? 'white' : 'white' }}
      >
        {pageReference?.CatalogTopLevelDescription?.topLevelDescription}
      </p>
    </section>
  );

  let iframeOverlay = null;
  if (chosenPortfolio) {
    iframeOverlay = (
      <div
        style={{
          display: 'block',
          position: 'absolute',
          width: '100%',
          height: '100%'
        }}
      ></div>
    );
  }

  let thumbnailTile = null;
  let htmlThumb = foundPage.content
    ? foundPage.content.find((piece) => piece._type === 'imageLinks')
    : null;
  let galImgThumb = foundPage.content
    ? foundPage.content.find((piece) => piece._type === 'galleryImage')
    : null;
  let pdfThumb = foundPage.content
    ? foundPage.content.find(
        (piece) =>
          piece._type === 'galleryPdf' || piece._type === 'galleryFullWidthPdf'
      )
    : null;
  let videoThumb = foundPage.content
    ? foundPage.content.find((piece) => piece.video)
    : null;
  let toggleThumb = foundPage.content
    ? foundPage.content.find((piece) => piece._type === 'toggleMenu')
    : null;
  let sliderThumb = foundPage.content
    ? foundPage.content.find((piece) => piece._type === 'galleryBASlider')
    : null;

  let webUrl = foundPage._type === 'website' ? foundPage.url : null;
  let webThumb =
    webUrl && foundPage?.thumbnail?.asset?._ref
      ? foundPage.thumbnail.asset._ref
      : null;
  let videoPreview =
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    foundPage.content &&
    foundPage.content.find((piece) => piece.video) !== undefined
      ? videoThumb.video.asset._ref
      : null;
  let pdfThumbnail =
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    foundPage.content &&
    foundPage.content.find(
      (piece) =>
        piece._type === 'galleryPdf' || piece._type === 'galleryFullWidthPdf'
    ) !== undefined
      ? pdfThumb.thumbnail.asset._ref
      : null;
  let galleryImage =
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    foundPage.content &&
    foundPage.content.find((piece) => piece._type === 'galleryImage') !==
      undefined
      ? galImgThumb.image.asset._ref
      : null;
  let sliderThumbnail =
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    foundPage.content &&
    foundPage.content.find((piece) => piece._type === 'galleryBASlider') !==
      undefined
      ? sliderThumb.Bimage.asset._ref
      : null;
  let toggleThumbnail;
  if (
    toggleThumb === undefined ||
    toggleThumb === null ||
    (foundPage._type !== 'gallery' && foundPage._type !== 'sliderGallery') ||
    !foundPage?.content
  ) {
    toggleThumbnail = undefined;
  } else if (toggleThumb.content[0]._type === 'galleryImage') {
    toggleThumbnail = toggleThumb.content[0].image.asset._ref;
  } else if (
    toggleThumb.content[0]._type === 'galleryPDF' ||
    toggleThumb.content[0]._type === 'galleryFullWidthPdf'
  ) {
    toggleThumbnail = toggleThumb.content[0].thumbnail.asset._ref;
  } else if (toggleThumb.content[0]._type === 'galleryVideo') {
    let videoRef = toggleThumb.content[0].video.asset._ref;
    toggleThumbnail = videos[videoRef];
  } else if (toggleThumb.content[0]._type === 'galleryBASlider') {
    toggleThumbnail = toggleThumb.content[0].Bimage.asset._ref;
  } else {
    toggleThumbnail = undefined;
  }

  let htmlContentImage =
    foundPage._type === 'htmlContent' &&
    foundPage.content &&
    foundPage.content.find((piece) => piece._type === 'imageLinks') !==
      undefined
      ? htmlThumb.image.asset._ref
      : null;
  let finalVideo = videoPreview !== null ? videos[videoPreview] : null;

  if (
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    galleryImage !== null
  ) {
    thumbnailTile = (
      <ThumbsBigNav
        dropColor={
          chosenPortfolio?.accent_color
            ? chosenPortfolio.accent_color
            : 'rgb(68, 159, 245)'
        }
        style={{
          margin: isTopLevel ? null : '1%'
        }}
      >
        <div
          className="portfolio-content group-content"
          // onMouseEnter={chosenPortfolio ? () => enterContent() : null}
          // onMouseLeave={chosenPortfolio ? () => leaveContent() : null}
        >
          <img
            src={urlFor(galleryImage).auto('format').url()}
            alt="logo"
            className={`portfolio-single-image ${
              pageReference.CatalogTopLevelSelection?.topLevelSelection ===
              'topImage'
                ? 'top-image'
                : ''
            }${
              pageReference.CatalogTopLevelSelection?.topLevelSelection ===
              'bottomImage'
                ? 'bottom-image'
                : ''
            }${
              pageReference.CatalogTopLevelSelection?.topLevelSelection ===
              'fullImage'
                ? 'full-image'
                : ''
            } ${menuSectionWidthDecider}`}
          />
          {overlay}
        </div>
      </ThumbsBigNav>
    );
  } else if (
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    pdfThumbnail !== null
  ) {
    thumbnailTile = (
      <ThumbsBigNav
        dropColor={
          chosenPortfolio?.accent_color
            ? chosenPortfolio.accent_color
            : 'rgb(68, 159, 245)'
        }
        style={{ margin: isTopLevel ? null : '1%' }}
      >
        <div
          className="portfolio-content group-content"
          // onMouseEnter={chosenPortfolio ? () => enterContent() : null}
          // onMouseLeave={chosenPortfolio ? () => leaveContent() : null}
        >
          <img
            src={urlFor(pdfThumbnail).auto('format').url()}
            alt="logo"
            className={`portfolio-single-image ${
              pageReference.CatalogTopLevelSelection?.topLevelSelection ===
              'topImage'
                ? 'top-image'
                : ''
            }${
              pageReference.CatalogTopLevelSelection?.topLevelSelection ===
              'bottomImage'
                ? 'bottom-image'
                : ''
            }${
              pageReference.CatalogTopLevelSelection?.topLevelSelection ===
              'fullImage'
                ? 'full-image'
                : ''
            } ${menuSectionWidthDecider}`}
          />
          {overlay}
        </div>
      </ThumbsBigNav>
    );
  } else if (
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    videoPreview !== null
  ) {
    thumbnailTile = (
      <ThumbsBigNav
        dropColor={
          chosenPortfolio?.accent_color
            ? chosenPortfolio.accent_color
            : 'rgb(68, 159, 245)'
        }
      >
        <div
          className="portfolio-content group-content"
          // onMouseEnter={chosenPortfolio ? () => enterContent() : null}
          // onMouseLeave={chosenPortfolio ? () => leaveContent() : null}
        >
          <SanityMuxPlayer
            className={`mux-player ${
              pageReference.CatalogTopLevelSelection?.topLevelSelection ===
              'topImage'
                ? 'top-image'
                : ''
            }${
              pageReference.CatalogTopLevelSelection?.topLevelSelection ===
              'bottomImage'
                ? 'bottom-image'
                : ''
            }${
              pageReference.CatalogTopLevelSelection?.topLevelSelection ===
              'fullImage'
                ? 'full-image'
                : ''
            } ${menuSectionWidthDecider}`}
            assetDocument={finalVideo}
            autoload={true}
            autoplay={videoThumb?.autoplay ? videoThumb.autoplay : false}
            loop={videoThumb?.loop ? videoThumb.loop : false}
            muted={true}
            SIG={false}
            SIP={false}
            showControls={controlsOverride ? true : false}
            override={true}
            style={{}}
            posterurl={`https://image.mux.com/${finalVideo.playbackId}/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=1`}
            type="galleryVideo"
            isSelected={false}
          />
          {overlay}
        </div>
      </ThumbsBigNav>
    );
  } else if (foundPage._type === 'htmlContent' && htmlContentImage !== null) {
    thumbnailTile = (
      <ThumbsBigNav
        dropColor={
          chosenPortfolio?.accent_color
            ? chosenPortfolio.accent_color
            : 'rgb(68, 159, 245)'
        }
      >
        <div
          className="portfolio-content group-content"
          // onMouseEnter={chosenPortfolio ? () => enterContent() : null}
          // onMouseLeave={chosenPortfolio ? () => leaveContent() : null}
        >
          <img
            src={urlFor(htmlContentImage).auto('format').url()}
            alt="logo"
            className={`portfolio-single-image ${
              pageReference.CatalogTopLevelSelection?.topLevelSelection ===
              'topImage'
                ? 'top-image'
                : ''
            }${
              pageReference.CatalogTopLevelSelection?.topLevelSelection ===
              'bottomImage'
                ? 'bottom-image'
                : ''
            }${
              pageReference.CatalogTopLevelSelection?.topLevelSelection ===
              'fullImage'
                ? 'full-image'
                : ''
            } ${menuSectionWidthDecider}`}
          />
          {overlay}
        </div>
      </ThumbsBigNav>
    );
  } else if (foundPage._type === 'website' && webUrl !== null) {
    if (webThumb) {
      thumbnailTile = (
        <ThumbsBigNav
          dropColor={
            chosenPortfolio?.accent_color
              ? chosenPortfolio.accent_color
              : 'rgb(68, 159, 245)'
          }
          style={{
            margin: isTopLevel ? null : '1%'
          }}
        >
          <div
            className="portfolio-content group-content"
            // onMouseEnter={chosenPortfolio ? () => enterContent() : null}
            // onMouseLeave={chosenPortfolio ? () => leaveContent() : null}
          >
            <img
              src={urlFor(webThumb).auto('format').url()}
              alt="logo"
              className={`portfolio-single-image ${
                pageReference.CatalogTopLevelSelection?.topLevelSelection ===
                'topImage'
                  ? 'top-image'
                  : ''
              }${
                pageReference.CatalogTopLevelSelection?.topLevelSelection ===
                'bottomImage'
                  ? 'bottom-image'
                  : ''
              }${
                pageReference.CatalogTopLevelSelection?.topLevelSelection ===
                'fullImage'
                  ? 'full-image'
                  : ''
              } ${menuSectionWidthDecider}`}
            />
            {overlay}
          </div>
        </ThumbsBigNav>
      );
    } else {
      thumbnailTile = (
        <ThumbsBigNav
          dropColor={
            chosenPortfolio?.accent_color
              ? chosenPortfolio.accent_color
              : 'rgb(68, 159, 245)'
          }
        >
          <div
            className="portfolio-content group-content"
            // onMouseEnter={chosenPortfolio ? () => enterContent() : null}
            // onMouseLeave={chosenPortfolio ? () => leaveContent() : null}
          >
            <div
              className={`iframe-container ${
                pageReference.CatalogTopLevelSelection?.topLevelSelection ===
                'topImage'
                  ? 'top-image'
                  : ''
              }${
                pageReference.CatalogTopLevelSelection?.topLevelSelection ===
                'bottomImage'
                  ? 'bottom-image'
                  : ''
              }${
                pageReference.CatalogTopLevelSelection?.topLevelSelection ===
                'fullImage'
                  ? 'full-image'
                  : ''
              } ${menuSectionWidthDecider}`}
            >
              <iframe
                scrolling="no"
                title="iframe"
                allowFullScreen
                src={webUrl}
              ></iframe>
            </div>
            {iframeOverlay}
            {overlay}
          </div>
        </ThumbsBigNav>
      );
    }
  } else if (
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    sliderThumbnail !== undefined &&
    sliderThumbnail !== null
  ) {
    thumbnailTile = (
      <ThumbsBigNav
        dropColor={
          chosenPortfolio?.accent_color
            ? chosenPortfolio.accent_color
            : 'rgb(68, 159, 245)'
        }
      >
        <div className="portfolio-content group-content">
          <img
            src={urlFor(sliderThumbnail).auto('format').url()}
            alt="logo"
            className={`portfolio-single-image ${
              pageReference.CatalogTopLevelSelection?.topLevelSelection ===
              'topImage'
                ? 'top-image'
                : ''
            }${
              pageReference.CatalogTopLevelSelection?.topLevelSelection ===
              'bottomImage'
                ? 'bottom-image'
                : ''
            }${
              pageReference.CatalogTopLevelSelection?.topLevelSelection ===
              'fullImage'
                ? 'full-image'
                : ''
            } ${menuSectionWidthDecider}`}
          />
          {overlay}
        </div>
      </ThumbsBigNav>
    );
  } else if (
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    toggleThumb !== undefined &&
    toggleThumb !== null
  ) {
    if (toggleThumb.content[0]._type === 'galleryImage') {
      thumbnailTile = (
        <ThumbsBigNav
          dropColor={
            chosenPortfolio?.accent_color
              ? chosenPortfolio.accent_color
              : 'rgb(68, 159, 245)'
          }
        >
          <div className="portfolio-content group-content">
            <img
              src={urlFor(toggleThumbnail).auto('format').url()}
              alt="logo"
              className={`portfolio-single-image ${
                pageReference.CatalogTopLevelSelection?.topLevelSelection ===
                'topImage'
                  ? 'top-image'
                  : ''
              }${
                pageReference.CatalogTopLevelSelection?.topLevelSelection ===
                'bottomImage'
                  ? 'bottom-image'
                  : ''
              }${
                pageReference.CatalogTopLevelSelection?.topLevelSelection ===
                'fullImage'
                  ? 'full-image'
                  : ''
              } ${menuSectionWidthDecider}`}
            />
            {overlay}
          </div>
        </ThumbsBigNav>
      );
    } else if (
      toggleThumb.content[0]._type === 'galleryPDF' ||
      toggleThumb.content[0]._type === 'galleryFullWidthPdf'
    ) {
      thumbnailTile = (
        <ThumbsBigNav
          dropColor={
            chosenPortfolio?.accent_color
              ? chosenPortfolio.accent_color
              : 'rgb(68, 159, 245)'
          }
        >
          <div className="portfolio-content group-content">
            <img
              src={urlFor(toggleThumbnail).auto('format').url()}
              alt="logo"
              className={`portfolio-single-image ${
                pageReference.CatalogTopLevelSelection?.topLevelSelection ===
                'topImage'
                  ? 'top-image'
                  : ''
              }${
                pageReference.CatalogTopLevelSelection?.topLevelSelection ===
                'bottomImage'
                  ? 'bottom-image'
                  : ''
              }${
                pageReference.CatalogTopLevelSelection?.topLevelSelection ===
                'fullImage'
                  ? 'full-image'
                  : ''
              } ${menuSectionWidthDecider}`}
            />
            {overlay}
          </div>
        </ThumbsBigNav>
      );
    } else if (toggleThumb.content[0]._type === 'galleryVideo') {
      // //onsole.log("VIDEO THUMB", toggleThumb);
      thumbnailTile = (
        <ThumbsBigNav
          dropColor={
            chosenPortfolio?.accent_color
              ? chosenPortfolio.accent_color
              : 'rgb(68, 159, 245)'
          }
        >
          <div className="portfolio-content group-content">
            <SanityMuxPlayer
              className={`mux-player ${
                pageReference.CatalogTopLevelSelection?.topLevelSelection ===
                'topImage'
                  ? 'top-image'
                  : ''
              }${
                pageReference.CatalogTopLevelSelection?.topLevelSelection ===
                'bottomImage'
                  ? 'bottom-image'
                  : ''
              }${
                pageReference.CatalogTopLevelSelection?.topLevelSelection ===
                'fullImage'
                  ? 'full-image'
                  : ''
              } ${menuSectionWidthDecider}`}
              assetDocument={toggleThumbnail}
              autoload={true}
              autoplay={false}
              loop={false}
              muted={true}
              SIG={false}
              SIP={false}
              showControls={controlsOverride ? true : false}
              override={true}
              style={{}}
              posterurl={`https://image.mux.com/${toggleThumbnail.playbackId}/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=1`}
              type="galleryVideo"
              isSelected={false}
            />
            {overlay}
          </div>
        </ThumbsBigNav>
      );
    } else if (toggleThumb.content[0]._type === 'galleryBASlider') {
      thumbnailTile = (
        <ThumbsBigNav
          dropColor={
            chosenPortfolio?.accent_color
              ? chosenPortfolio.accent_color
              : 'rgb(68, 159, 245)'
          }
        >
          <div className="portfolio-content group-content">
            <img
              src={urlFor(toggleThumbnail).auto('format').url()}
              alt="logo"
              className={`portfolio-single-image ${
                pageReference.CatalogTopLevelSelection?.topLevelSelection ===
                'topImage'
                  ? 'top-image'
                  : ''
              }${
                pageReference.CatalogTopLevelSelection?.topLevelSelection ===
                'bottomImage'
                  ? 'bottom-image'
                  : ''
              }${
                pageReference.CatalogTopLevelSelection?.topLevelSelection ===
                'fullImage'
                  ? 'full-image'
                  : ''
              } ${menuSectionWidthDecider}`}
            />
            {overlay}
          </div>
        </ThumbsBigNav>
      );
    } else {
      thumbnailTile = (
        <ThumbsBigNav
          dropColor={
            chosenPortfolio?.accent_color
              ? chosenPortfolio.accent_color
              : 'rgb(68, 159, 245)'
          }
          // onMouseEnter={chosenPortfolio ? () => enterContent() : null}
          // onMouseLeave={chosenPortfolio ? () => leaveContent() : null}
        >
          {chosenPortfolio?.portfolio_logo.asset?._ref ? (
            <div className="logo-thumb">
              <img
                src={urlFor(chosenPortfolio.portfolio_logo.asset._ref)
                  .auto('format')
                  .width(400)
                  .url()}
                alt="logo"
                className={`portfolio-single-image ${
                  pageReference.CatalogTopLevelSelection?.topLevelSelection ===
                  'topImage'
                    ? 'top-image'
                    : ''
                }${
                  pageReference.CatalogTopLevelSelection?.topLevelSelection ===
                  'bottomImage'
                    ? 'bottom-image'
                    : ''
                }${
                  pageReference.CatalogTopLevelSelection?.topLevelSelection ===
                  'fullImage'
                    ? 'full-image'
                    : ''
                } ${menuSectionWidthDecider}`}
              />
              {overlay}
            </div>
          ) : null}
        </ThumbsBigNav>
      );
    }
  } else {
    thumbnailTile = (
      <ThumbsBigNav
        dropColor={
          chosenPortfolio?.accent_color
            ? chosenPortfolio.accent_color
            : 'rgb(68, 159, 245)'
        }
        // onMouseEnter={chosenPortfolio ? () => enterContent() : null}
        // onMouseLeave={chosenPortfolio ? () => leaveContent() : null}
      >
        {chosenPortfolio.portfolio_logo.asset._ref ? (
          <div className="logo-thumb">
            <img
              src={urlFor(chosenPortfolio.portfolio_logo.asset._ref)
                .auto('format')
                .width(400)
                .url()}
              alt="logo"
              className={`portfolio-single-image ${
                pageReference.CatalogTopLevelSelection?.topLevelSelection ===
                'topImage'
                  ? 'top-image'
                  : ''
              }${
                pageReference.CatalogTopLevelSelection?.topLevelSelection ===
                'bottomImage'
                  ? 'bottom-image'
                  : ''
              }${
                pageReference.CatalogTopLevelSelection?.topLevelSelection ===
                'fullImage'
                  ? 'full-image'
                  : ''
              } ${menuSectionWidthDecider}`}
            />
            {overlay}
          </div>
        ) : null}
      </ThumbsBigNav>
    );
  }

  return thumbnailTile;
};

export default GalleryThumbsContent;
