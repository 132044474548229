// export { default } from './PortfolioBriefcase';
import { connect } from 'react-redux';
import PortfolioBriefcase from './PortfolioBriefcase';
import {
  toggleBriefcase,
  addToBriefcase,
  removeFromBriefcase,
  clearBriefcase,
  setToolbarMenuVisibility
} from '../../../actions';
const mapStateToProps = (state) => ({
  companyConfig: state.sanityContentReducer.companyConfig,
  chosenPortfolio: state.portfolioReducer.chosenPortfolio,
  userInfo: state.firestore.ordered.users,
  showBriefcase: state.briefcaseReducer.showBriefcase,
  briefcaseContent: state.briefcaseReducer.briefcaseContent,
  isGuestMode: state.guestReducer.isGuestMode,
  liked: state.portfolioReducer.liked,
  customerInfo: state.portfolioReducer.customerInfo,
  toolbarMenuVisible: state.portfolioReducer.toolbarMenuVisible
});

const mapDispatchToProps = (dispatch) => {
  return {
    toggleBriefcase: () => dispatch(toggleBriefcase()),
    addToBriefcase: (obj) => dispatch(addToBriefcase(obj)),
    removeFromBriefcase: (id) => dispatch(removeFromBriefcase(id)),
    clearBriefcase: () => dispatch(clearBriefcase()),
    setToolbarMenuVisibility: (bool) => dispatch(setToolbarMenuVisibility(bool))
  };
};

const BriefcaseContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PortfolioBriefcase);

export default BriefcaseContainer;
