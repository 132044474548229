import { connect } from 'react-redux';
import Graph from './Graph.js';
import { setSearch } from '../../../../../../../actions';

const mapStateToProps = (state) => ({
  // FVC: state.DashboardReducer.GuestpassSessionFilteredViewerCollection,
  companyConfig: state.sanityContentReducer.companyConfig,
  // sessions: state.DashboardReducer.GuestpassSessionFilteredCollection,
  // SingleSession: state.DashboardReducer.GuestPassSingleSession,
  Size: state.portfolioReducer.Size
});

const mapDispatchToProps = (dispatch) => {
  return {
    setSearch: (Terms) => dispatch(setSearch(Terms))
  };
};

const DashboardGraph = connect(mapStateToProps, mapDispatchToProps)(Graph);

export default DashboardGraph;
