/**
 * List that could be shown/hidden on click
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledHideableList = styled.div`
  .clickable {
    cursor: pointer;
  }
`;

const StyledText = styled.div`
  font-style: italic;
  cursor: pointer;
`;

const StyledList = styled.ul`
  padding: 0;
`;

const HideableList = ({ data, keyValue, id }) => {
  const [show, setShow] = useState(false);

  if (data.length === 0) {
    return null;
  }
  return (
    <StyledHideableList>
      {show ? (
        <StyledList className="clickable" onClick={() => setShow(false)}>
          {data.map((obj, index) => {
            return <li key={`${id}-${index}`}>{obj[keyValue]}</li>;
          })}
        </StyledList>
      ) : (
        <StyledText onClick={() => setShow(true)}>Show...</StyledText>
      )}
    </StyledHideableList>
  );
};

HideableList.propTypes = {
  data: PropTypes.array.isRequired,
  keyValue: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired
};

export default HideableList;
