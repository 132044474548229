import React from 'react';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import FontUtil from '../../../utils/FontUtility';

const Modal = (props) => {
  return (
    <div
      style={{
        backgroundColor: props.chosenPortfolio?.primary_color
          ? `${props.chosenPortfolio.primary_color}BF`
          : '#3b3b3b59'
      }}
      className={`modal ${props.isModalOpen ? 'active' : 'hidden'}`}
    >
      <div className="modal-close">
        <CancelRoundedIcon
          onClick={() => {
            props.hideModal();
            props.setToolbarMenuVisibility(true);
          }}
          style={{
            fontSize: '3em',
            color: props.chosenPortfolio?.bulk_icon_override
              ? props.chosenPortfolio.bulk_icon_override
              : 'white',
            cursor: 'pointer'
          }}
        />
      </div>
      <div className="modal-body">
        <div className="modal-title">
          <p
            style={{
              color: props.chosenPortfolio?.text_color
                ? props.chosenPortfolio.text_color
                : 'white',
              fontFamily: FontUtil(
                props.chosenPortfolio.SystemText,
                null,
                "'Roboto Slab', serif"
              )
            }}
          >
            Customer Info
          </p>
        </div>
        <div className="modal-container">{props.children}</div>
      </div>
    </div>
  );
};

export default Modal;
