import { connect } from 'react-redux';
import Notepad from './Notepad';
import {
  toggleNotepad,
  updateNotepad,
  updateSessionInSanity,
  setToolbarMenuVisibility,
  setToolbarMenuOpen
} from '../../../actions';

const mapStateToProps = (state) => ({
  companyConfig: state.sanityContentReducer.companyConfig,
  chosenPortfolio: state.portfolioReducer.chosenPortfolio,
  notepadText: state.notepadReducer.notepadText,
  isGuestMode: state.guestReducer.isGuestMode,
  showNotepad: state.notepadReducer.showNotepad,
  toolbarMenuVisible: state.portfolioReducer.toolbarMenuVisible,
  toolbarMenuOpen: state.portfolioReducer.toolbarMenuOpen,
  Size: state.portfolioReducer.Size
});

const mapDispatchToProps = (dispatch) => {
  return {
    toggleNotepad: () => dispatch(toggleNotepad()),
    updateNotepad: (string) => dispatch(updateNotepad(string)),
    updateSessionInSanity: (obj) => dispatch(updateSessionInSanity(obj)),
    setToolbarMenuVisibility: (bool) =>
      dispatch(setToolbarMenuVisibility(bool)),
    setToolbarMenuOpen: (bool) => dispatch(setToolbarMenuOpen(bool))
  };
};

const NotepadContainer = connect(mapStateToProps, mapDispatchToProps)(Notepad);

export default NotepadContainer;
