/* eslint-disable react/no-unknown-property */
import { React } from 'react';
const LikeOutline = ({
  Fill = '#FFF',
  Rotation,
  onClick,
  className,
  id,
  style
}) => {
  let string = `.clsLikeOutline-1{fill:transparent;stroke:${Fill};stroke-linecap:round;stroke-linejoin:round;stroke-width:20px;}`;
  return (
    <svg
      style={{ ...style }}
      onClick={onClick}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
    >
      <defs>
        <style>{string}</style>
      </defs>
      <path
        class="clsLikeOutline-1"
        d="M292.9,208.57l-.06-55.2A41.39,41.39,0,0,0,251.4,112L196.33,236.27l.16,151.79,155.66-.16a27.6,27.6,0,0,0,27.58-23.49L398.64,240.2A27.57,27.57,0,0,0,371,208.49ZM196.49,388.06l-36.84,0a31.88,31.88,0,0,1-32.19-27.56l-.1-96.6a31.87,31.87,0,0,1,32.13-27.63l36.84,0"
      />
    </svg>
  );
};
export default LikeOutline;
