// Creates menu parents button groups with nested child buttons

import React, { useState, useEffect, Fragment } from 'react';
import Button from '../Button';
// import FontUtil from '../../../utils/FontUtility';
// Menu Buttons with child group
const MenuButton = (props) => {
  const {
    navIndex,
    BackTopicArray,
    foundNavItem,
    title,
    subtitle,
    level,
    chosenPortfolio,
    onButtonClick,
    additionalButtonClassName,
    backgroundColor,
    color,
    pages,
    setGuestContent,
    SetTopicArray,
    setSavedContentPageId,
    setCurrentNavItem,
    setMasterMenuOpen,
    setSelectedTopLevelId,
    // twolevelTerminus,
    setTwolevelTerminus,
    // megamenuLevel,
    setMegamenuLevel,
    setShowTermChild,
    isHovered,
    selectedTopLevelId
  } = props;

  const {
    chosenMainPageButtonId // chosen parent nav button id
  } = props.pageMenuReducer;

  const { currentLevel, chosenPageId } = props.pageMenuReducer;
  const [isSubActive, setIsSubActive] = useState(false); // keep track of if this sub/child button is active
  const id = foundNavItem.referenceId;
  const hasChild = foundNavItem.children.length > 0;

  // Listen for changes to change active state of this button
  useEffect(() => {
    // check if user clicked on different main/parent button
    /* NOTE currently using id of page to separate the logic.
    // This is assuming that pages are always unique and nav does not contain
    // any duplicate pages.
    */
    if (level === 0 && chosenMainPageButtonId !== id && isSubActive) {
      setIsSubActive(false);
    }
    if (
      level !== 0 &&
      currentLevel === level &&
      id !== chosenPageId &&
      isSubActive
    ) {
      // check if user clicked different button on same level
      setIsSubActive(false);
    } else if (currentLevel < level && isSubActive) {
      //disable anything lower than current level
      setIsSubActive(false);
    }
  }, [level, chosenPageId]); // eslint-disable-line react-hooks/exhaustive-deps
  //onsole.log("foundNavItem",foundNavItem);
  return (
    <Fragment>
      <li>
        <Button
          additionalClassName={additionalButtonClassName}
          height={chosenPortfolio.theme !== 'theme2' ? '93px' : '100%'}
          text={title}
          subtitle={subtitle}
          backgroundColor={backgroundColor !== '' ? backgroundColor : undefined}
          color={
            chosenPortfolio?.text_color ? chosenPortfolio.text_color : color
          }
          isSubActive={isSubActive}
          chosenPortfolio={chosenPortfolio}
          isHovered={isHovered}
          selectedTopLevelId={selectedTopLevelId}
          referenceId={foundNavItem.referenceId}
          onClick={(eve) => {
            // //////onsole.log("!!TEST!!",eve)
            // //////onsole.log("MODSTATE",eve.getModifierState("Shift"))
            if (eve.getModifierState('Shift')) {
              //////onsole.log("!!MOD--!!","!!--CONTROL!!")
            }
            if (chosenPortfolio?.theme === 'theme2') {
              setSavedContentPageId(0);
            }

            let isNotEnd = false;

            // check if next child doesnt contain another child
            if (hasChild) {
              isNotEnd = foundNavItem.children.some((childArray) => {
                return childArray.children.length !== 0;
              });
            }
            // let chosenPortfolio = new Object();
            // chosenPortfolio.theme = "theme5";
            //////////onsole.log("PortCheck",chosenPortfolio)
            //if(!chosenPortfolio.MethodicalSpec?.MethodicalFlat)
            //{
            if (
              (isNotEnd && isSubActive === false) ||
              chosenPortfolio.theme !== 'theme4'
            )
              setIsSubActive(true);
            else {
              BackTopicArray();
              setIsSubActive(false);
            }
            //}
            onButtonClick(id, level, hasChild, isNotEnd, navIndex);
            if (setCurrentNavItem) {
              setCurrentNavItem(foundNavItem);
            }
            if (chosenPortfolio.theme === 'theme11') {
              setMasterMenuOpen(false);
              if (setSelectedTopLevelId) {
                setSelectedTopLevelId(foundNavItem.referenceId);
              }
              if (!isNotEnd) {
                if (!foundNavItem?.children[0]) {
                  setShowTermChild(true);
                }
                setTwolevelTerminus(true);
                //onsole.log(`SETTING TOPIC ARRAY - AT END: ${pages[foundNavItem.referenceId].title}, 0, ${id}`);
                SetTopicArray(pages[foundNavItem.referenceId].title, 0, id);
                setMegamenuLevel(1);
              } else {
                setTwolevelTerminus(false);
                //onsole.log(`SETTING TOPIC ARRAY - NOT AT END: ${pages[foundNavItem.referenceId].title}, 0, ${id}`);
                SetTopicArray(pages[foundNavItem.referenceId].title, 0, id);
                setMegamenuLevel(1);
              }
            } // THEME 8 END

            // set setCurrentContentHelper
            if (setGuestContent) {
              setGuestContent();
            }
          }}
        />
      </li>
      {hasChild && (
        <MenuSection
          {...props}
          isActive={isSubActive}
          level={level + 1}
          chosenNavItem={foundNavItem.children}
          SetTopicArray={SetTopicArray}
        />
      )}
    </Fragment>
  );
};

const MenuSection = (props) => {
  const [foundNavItems, setFoundNavItems] = useState([]);
  const [isHovered, setIsHovered] = useState(0);
  //const [containsContentGroup, setContainsContentGroup] = useState(false);
  const {
    isGuestMode,
    BackTopicArray,
    isPortfolioView,
    guestContentHelper,
    chosenNavItem,
    pages,
    nav,
    level,
    isActive,
    getActiveString,
    getIsAtEndString,
    companyConfig,
    chosenPortfolio,
    SetTopicArray,
    setCurrentContentHelper,
    setSavedContentPageId,
    setCurrentNavItem,
    setMasterMenuOpen,
    setSelectedTopLevelId,
    twolevelTerminus,
    setTwolevelTerminus,
    megamenuLevel,
    setMegamenuLevel,
    selectedTopLevelId,
    setShowTermChild
  } = props;

  useEffect(() => {
    // create page buttons, chosenNavItem is undefined at first load
    if (!chosenNavItem) {
      if (nav) {
        // parent pages buttons
        setFoundNavItems(nav.items);
      }
    } else {
      // child page buttons
      setFoundNavItems(chosenNavItem);
    }
  }, [chosenNavItem, isGuestMode, isPortfolioView, nav]);

  if (!nav || foundNavItems.length === 0) {
    return null;
  }

  let isActiveString = getActiveString(isActive, level);
  let isAtEndString = getIsAtEndString();

  const menuSection = foundNavItems.map((foundNavItem, index) => {
    // get color for each specific page....
    let accentColor;

    let textColor;
    let subtitle;
    let title;
    if (level !== 0) {
      try {
        accentColor = pages[foundNavItem.referenceId].accent_color;
        textColor = pages[foundNavItem.referenceId].text_color;
        subtitle = pages[foundNavItem.referenceId].subtitle;
        title = pages[foundNavItem.referenceId].title;
      } catch (ex) {
        //////////onsole.log("MenuSection ERR:", ex);
      }

      // set to chosenPortfolio color if it doesn't exists
      accentColor = accentColor ? accentColor : chosenPortfolio.secondary_color;
      textColor = textColor ? textColor : chosenPortfolio.text_color;

      // set to companyConfig color if it doesn't exists
      accentColor = accentColor ? accentColor : companyConfig.secondary_color;
      textColor = textColor ? textColor : companyConfig.text_color;
    } else {
      try {
        accentColor = pages[foundNavItem.referenceId].accent_color;
        textColor = chosenPortfolio.text_color;
        subtitle = pages[foundNavItem.referenceId].subtitle;
        title = pages[foundNavItem.referenceId].title;
      } catch (ex) {
        //////////onsole.log("MenuSection ERR:", ex);
      }

      // set to chosenPortfolio color if it doesn't exist
      accentColor = accentColor ? accentColor : chosenPortfolio.primary_color;
      // set to companyConfig color if it doesn't exist
      accentColor = accentColor ? accentColor : companyConfig.primary_color;
      textColor = textColor ? textColor : companyConfig.text_color;
    }
    //onsole.log("chosenNavItem",chosenNavItem)
    const menuButton = (
      <MenuButton
        {...props}
        navIndex={index}
        foundNavItem={foundNavItem}
        title={title}
        SetTopicArray={SetTopicArray}
        BackTopicArray={BackTopicArray}
        subtitle={
          (chosenPortfolio.theme === 'theme4' && window.innerHeight > 450) ||
          chosenPortfolio.theme === 'theme2'
            ? subtitle
            : null
        }
        isActiveString={isActiveString}
        isAtEndString={isAtEndString}
        backgroundColor={accentColor}
        color={textColor}
        setGuestContent={
          isGuestMode && !isPortfolioView
            ? () => setCurrentContentHelper(guestContentHelper[index].id)
            : undefined
        }
        additionalButtonClassName={`level-${level} ${isActiveString} ${isAtEndString}`}
        setSavedContentPageId={setSavedContentPageId} // additional className to buttons
        setCurrentNavItem={setCurrentNavItem}
        setMasterMenuOpen={setMasterMenuOpen}
        setSelectedTopLevelId={setSelectedTopLevelId}
        twolevelTerminus={twolevelTerminus}
        setTwolevelTerminus={setTwolevelTerminus}
        megamenuLevel={megamenuLevel}
        setMegamenuLevel={setMegamenuLevel}
        setShowTermChild={setShowTermChild}
        isHovered={isHovered}
        selectedTopLevelId={selectedTopLevelId}
      />
    );
    // if level 0, group those into div button-group-level-0
    return (
      <Fragment key={foundNavItem._id + '-' + index}>
        {level === 0 ? (
          <ul
            className={`group level-${level} ${isActiveString} ${isAtEndString} ${
              isHovered === foundNavItem.referenceId ? 'hovered' : null
            }`}
            onMouseEnter={() => setIsHovered(foundNavItem.referenceId)}
            onMouseLeave={() => setIsHovered(0)}
            style={
              (selectedTopLevelId === foundNavItem.referenceId ||
                isHovered === foundNavItem.referenceId) &&
              chosenPortfolio?.action_menu_color
                ? {
                    backgroundColor: `${chosenPortfolio.action_menu_color}`
                  }
                : null
            }
          >
            {menuButton}
          </ul>
        ) : (
          menuButton
        )}
      </Fragment>
    );
  });

  // if not level 0, group those into div button-group-level-<n>
  return (
    <Fragment>
      {level === 0 ? (
        menuSection
      ) : (
        <ul
          className={`group level-${level} ${isActiveString} ${isAtEndString}`}
        >
          {menuSection}
        </ul>
      )}
    </Fragment>
  );
};

export default MenuSection;
