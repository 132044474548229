import { connect } from 'react-redux';
import AuthPage from './AuthPage';
import { updateSessionKey } from '../../actions';
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return { updateSessionKey: (key) => dispatch(updateSessionKey(key)) };
};

const AuthPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthPage);

export default AuthPageContainer;
