import { connect } from 'react-redux';
import Notes from './Notes.js';
import {
  SetGuestPassMasterCollection,
  SetFilteredGuestpass,
  SetSessionMasterCollection,
  SetFilteredSession,
  SetGuestpassSessionMasterCollection,
  SetFilteredGuestPassSessionWithoutGPID,
  setSelectedNotesSession,
  setSelectedNotesString,
  setSelectedNotesSessionObj
} from '../../../../../../../../actions';

const mapStateToProps = (state) => ({
  Firestore: state.firestore,
  // Dash: state.DashboardReducer,
  userInfo: state.firestore.ordered.users,
  // views: state.DashboardReducer.views,
  Size: state.portfolioReducer.Size
  // sessionList: state.DashboardReducer.sessionList,
  // selectedNotesSession: state.DashboardReducer.selectedNotesSession,
  // selectedNotesString: state.DashboardReducer.selectedNotesString,
  // selectedNotesSessionObj: state.DashboardReducer.selectedNotesSessionObj,
  // aggregateSelected: state.DashboardReducer.aggregateSelected,
  // selectedUser: state.DashboardReducer.selectedUser
});

const mapDispatchToProps = (dispatch) => {
  return {
    SGPMC: (UI, firestore) =>
      dispatch(SetGuestPassMasterCollection(UI, firestore)),
    SGPFC: (UI) => dispatch(SetFilteredGuestpass(UI)),
    SSMC: (UI, firestore) =>
      dispatch(SetSessionMasterCollection(UI, firestore)),
    SGPSMC: (UI, firestore) =>
      dispatch(SetGuestpassSessionMasterCollection(UI, firestore)),
    SFS: (UI) => dispatch(SetFilteredSession(UI)),
    SFGPS: (UI) => dispatch(SetFilteredGuestPassSessionWithoutGPID(UI)),
    setSelectedNotesSession: (id) => dispatch(setSelectedNotesSession(id)),
    setSelectedNotesString: (obj) => dispatch(setSelectedNotesString(obj)),
    setSelectedNotesSessionObj: (obj) =>
      dispatch(setSelectedNotesSessionObj(obj)),
    UpdateSessionList: (SMCE) =>
      dispatch({ type: 'SET_SESSION_MASTER_COLLECTION', data: SMCE })
  };
};

const DashboardNotes = connect(mapStateToProps, mapDispatchToProps)(Notes);

export default DashboardNotes;
