import { connect } from 'react-redux';
import LT from './Likes';
// import {
//   setChosenPortfolio,
//   endPortfolio
// }from '../../../../../../../actions'
// import {
// //   SetGuestPassMasterCollection,
// //   SetFilteredGuestpass,
// //   SetSessionMasterCollection,
// //   SetFilteredSession,
// //   SetGuestpassSessionMasterCollection,
// //   SetFilteredGuestPassSessionWithoutGPID,
// //   SetLeadsGuestpass,
//   setLeadViewer
// } from '../../../../../actions';

const mapStateToProps = (state) => ({
  //   Firestore:state.firestore,
  portfolios: state.sanityContentReducer.portfolios,
  videos: state.sanityContentReducer.videos,
  pdfs: state.sanityContentReducer.pdfs,
  Dash: state.DashboardReducer,
  DashGL: state.DashboardReducer.ActiveGPL,
  //   userInfo: state.firestore.ordered.users,
  //   views: state.DashboardReducer.views,
  Size: state.portfolioReducer.Size
  //   selectedUser: state.DashboardReducer.selectedUser
});

const mapDispatchToProps = (dispatch) => {
  return {
    // SetChosenPortfolio:(port)=>{dispatch(setChosenPortfolio(port))},
    // EndPortfolio: ()=>{dispatch(endPortfolio())}
    // SGPMC: (UI,firestore) => dispatch(SetGuestPassMasterCollection(UI,firestore)),
    // SGPFC: (UI) =>dispatch(SetFilteredGuestpass(UI)),
    // SSMC: (UI,firestore) =>dispatch(SetSessionMasterCollection(UI,firestore)),
    // SGPSMC: (UI,firestore) =>{dispatch(SetGuestpassSessionMasterCollection(UI,firestore))},
    // SFS: (UI) => {dispatch(SetFilteredSession(UI))},
    // SFGPS: (UI) =>dispatch(SetFilteredGuestPassSessionWithoutGPID(UI)),
    // SLGP: (UI,F)=>dispatch(SetLeadsGuestpass(UI,F)),
    // SLV:(LV)=>dispatch(setLeadViewer(LV))
  };
};

const DashboardGP2 = connect(mapStateToProps, mapDispatchToProps)(LT);

export default DashboardGP2;
