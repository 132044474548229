import { connect } from 'react-redux';
import Notepad2 from './Notepad2';
import {
  toggleNotepad,
  updateNotepad,
  updateSessionInSanity
} from '../../actions';

const mapStateToProps = (state) => ({
  companyConfig: state.sanityContentReducer.companyConfig,
  chosenPortfolio: state.portfolioReducer.chosenPortfolio,
  notepadText: state.notepadReducer.notepadText,
  Size: state.portfolioReducer.Size,
  isGuestMode: state.guestReducer.isGuestMode,
  showNotepad: state.notepadReducer.showNotepad
});

const mapDispatchToProps = (dispatch) => {
  return {
    toggleNotepad: () => dispatch(toggleNotepad()),
    updateNotepad: (string) => dispatch(updateNotepad(string)),
    updateSessionInSanity: (obj) => dispatch(updateSessionInSanity(obj))
  };
};

const NotepadContainer2 = connect(
  mapStateToProps,
  mapDispatchToProps
)(Notepad2);

export default NotepadContainer2;
