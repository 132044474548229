import { connect } from 'react-redux';
import TopRightToolbar from './TopRightToolbar';
import {
  toggleBriefcase,
  addToBriefcase,
  endPortfolio,
  setPortfolioCustomerInfo,
  showModal,
  hideModal,
  setMenuHide,
  toggleNotepad,
  toggleScriptnote,
  setLiked,
  updateSessionInSanity,
  clearAll,
  setBooleanNewItems,
  setToolbarMenuOpen,
  setToolbarMenuVisibility
} from '../../../actions';

const mapStateToProps = (state) => ({
  mode: state.portfolioReducer.mode,
  pages: state.sanityContentReducer.pages,
  sessionId: state.portfolioReducer.sessionId,
  showBriefcase: state.briefcaseReducer.showBriefcase,
  briefcaseContent: state.briefcaseReducer.briefcaseContent,
  isModalOpen: state.modalReducer.isModalOpen,
  chosenPortfolio: state.portfolioReducer.chosenPortfolio,
  menuHide: state.pageMenuReducer.menuHide,
  isGuestMode: state.guestReducer.isGuestMode,
  currentGuestContentHelper: state.guestReducer.currentContentHelper,
  liked: state.portfolioReducer.liked,
  userInfo: state.firestore.ordered.users,
  Size: state.portfolioReducer.Size,
  booleanNewItems: state.briefcaseReducer.booleanNewItems,
  ANNOTATOR2: state.portfolioReducer.aNNOTATOR,
  toolbarMenuOpen: state.portfolioReducer.toolbarMenuOpen,
  toolbarMenuVisible: state.portfolioReducer.toolbarMenuVisible,
  masterMenuOpen: state.portfolioReducer.masterMenuOpen,
  companyConfig: state.sanityContentReducer.companyConfig
});

const mapDispatchToProps = (dispatch) => {
  return {
    toggleBriefcase: () => dispatch(toggleBriefcase()),
    addToBriefcase: (obj) => dispatch(addToBriefcase(obj)),
    endPortfolio: () => dispatch(endPortfolio()),
    setPortfolioCustomerInfo: (obj) => dispatch(setPortfolioCustomerInfo(obj)),
    showModal: () => dispatch(showModal()),
    hideModal: () => dispatch(hideModal()),
    setMenuHide: (bool) => dispatch(setMenuHide(bool)),
    toggleNotepad: () => dispatch(toggleNotepad()),
    toggleScriptnote: () => dispatch(toggleScriptnote()),
    setLiked: (array) => dispatch(setLiked(array)),
    updateSessionInSanity: (obj) => dispatch(updateSessionInSanity(obj)),
    clearAll: () => dispatch(clearAll()),
    setBooleanNewItems: (bool) => dispatch(setBooleanNewItems(bool)),
    SET_ANNOTATOR: (t) => dispatch({ type: 'SET_ANNOTATOR', payload: t }),
    setToolbarMenuOpen: (bool) => dispatch(setToolbarMenuOpen(bool)),
    setToolbarMenuVisibility: (bool) => dispatch(setToolbarMenuVisibility(bool))
  };
};

const TopRightToolbarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TopRightToolbar);

export default TopRightToolbarContainer;
