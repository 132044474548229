import React, { useState, useRef } from 'react'; //useEffect
//import { IconContext } from 'react-icons';
// import Icons from '../Icons';
import Button from '../../../../../components/Button';
import ReactQuill from 'react-quill'; //, {Quill}
import CloseIcon from '../../../../../containers/PageMenus/Menu9-Storybook/CloseSVG';
//import TextArea from '../../components/TextArea';
//import X from '../../../../../assets/images/X-Icon.png';
import * as Scale from '../../../../../utils/Scalars'; //utils/Scalars
// import ToolCross from '../../../../../containers/PageMenus/Menu9-Storybook/TerminalMenuGallery/Exclude.png'//
// import BackChev from '../Vector 1.png'
import BackChev from '../BackarrowSVG';
import FontUtil from '../../../../../utils/FontUtility';
const NotepadT9 = ({
  notepadText,
  showNotepad,
  toggleNotepad,
  isGuestMode,
  updateNotepad,
  updateSessionInSanity,
  updateSessionWithNotes,
  chosenPortfolio,
  SetDisplay,
  Size,
  companyConfig
}) => {
  const [text, setText] = useState('');
  const [saved, setSaved] = useState(false);

  // useEffect(() => {
  //   setText(notepadText);
  // }, [notepadText]);

  const [copied, setCopied] = useState(false);
  const CopyTxt = () => {
    //////onsole.log("!!SELECTION!!",reftt)
    //////onsole.log("!!SELECTION!!",reftt.current)
    const range = document.createRange();
    ////////onsole.log("CURRENT NODE", clipboardRef.current);
    range.selectNode(reftt.current.editingArea);
    ////////onsole.log("RANGE", range);
    window.getSelection().addRange(range);
    //reftt.current.editingArea.select();

    try {
      // const successful = document.execCommand('copy');
      document.execCommand('copy');
      //const msg = successful ? 'successful' : 'unsuccessful';
      //////onsole.log('Copy email command was ' + msg);
    } catch (err) {
      //////onsole.log('Oops, unable to copy');
    }

    window.getSelection().removeAllRanges();

    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };
  // const CopyTxt = ()=>{
  //   //////onsole.log("!!SELECTION!!",reftt)
  //   //////onsole.log("!!SELECTION!!",reftt.current)
  //     const range = document.createRange();
  //     ////////onsole.log("CURRENT NODE", clipboardRef.current);
  //     range.selectNode(reftt.current);
  //     ////////onsole.log("RANGE", range);
  //     window.getSelection().addRange(range);
  //     reftt.current.select();

  //     try {
  //       const successful = document.execCommand('copy');
  //       const msg = successful ? 'successful' : 'unsuccessful';
  //       //////onsole.log('Copy email command was ' + msg);
  //     } catch(err) {
  //       //////onsole.log('Oops, unable to copy');
  //     }

  //     window.getSelection().removeAllRanges();

  //     setCopied(true);
  //     setTimeout(() => {
  //       setCopied(false);
  //     }, 1000);

  //   }
  const reftt = useRef();

  // //https://stackoverflow.com/questions/44656610/download-a-string-as-txt-file-in-react/44661948
  // const downloadTxtFile = () => {
  //   const element = document.createElement('a');
  //   const file = new Blob([text], { type: 'text/plain' });
  //   element.href = URL.createObjectURL(file);
  //   element.download = 'session-notes.txt';
  //   document.body.appendChild(element); // Required for this to work in FireFox
  //   element.click();
  // };

  const handleSaveNotification = () => {
    setSaved(true);
    setTimeout(() => {
      setSaved(false);
    }, 1500);
  };

  // let BorderColor = chosenPortfolio.primary_color
  // ? chosenPortfolio.primary_color
  // : companyConfig.primary_color;

  // let secondaryColor = chosenPortfolio.primary_color
  // ? chosenPortfolio.primary_color
  // : companyConfig.primary_color;

  let accentColor = chosenPortfolio.accent_color
    ? chosenPortfolio.accent_color
    : 'white';

  return (
    <div className="ActionMenu Notepad">
      <style>
        {`
          div.ql-container {
          }
          div.quill {
            user-select: text;
          }
          div.ql-editor {
            
            user-select: text;
          }
          button.ql-link {
            
          }
        `}
      </style>
      <div className="HeadBar">
        <p
          className="Title"
          style={{ fontFamily: FontUtil(chosenPortfolio.SystemText) }}
        >
          {isGuestMode ? 'Contact' : 'Notepad'}
        </p>
        <CloseIcon
          onClick={() => {
            toggleNotepad();
            SetDisplay(false);
          }}
          className="closeButton"
          id={'N1'}
        />
        {/* <img alt="Close Tools" src={ToolCross} onClick={()=>{toggleNotepad(); SetDisplay(false);}} className="closeButton"></img> */}
        {/* <img alt="Back to Main Tools" src={BackChev} onClick={()=>{toggleNotepad()}} className="BackButton"></img> */}
        <BackChev
          onClick={() => {
            toggleNotepad();
          }}
          className="BackButton"
        ></BackChev>
      </div>
      <div className="Options Notepad">
        <div className="textarea-container" style={{ position: 'relative' }}>
          {/* <h1 style={{width:'100%'}}>{!isGuestMode?'Notes':'Comments'}</h1> */}
          <div
            style={{
              background: 'white',
              height:
                Size.W / Size.H > 380 / 679
                  ? `${Scale.Height(Size, 602.0)}px`
                  : `${Scale.Height(Size, 502.0)}px`,
              display: 'inline-flex',
              width: '100%'
            }}
          >
            <ReactQuill
              style={{ userSelect: 'text' }}
              ref={reftt}
              theme={'snow'}
              onChange={(e, Delta, Source, Editor) => {
                //////onsole.log("!!SELECTION!!",e);
                //////onsole.log("!!SELECTION!!",Editor.getContents());
                setText(e);
              }}
              value={text}
            ></ReactQuill>
          </div>
          {/* style={{background:"white"}} */}
          {/* <TextArea
            name={'notepad-textarea'}
            value={text}
            onChange={(e) => setText(e.target.value)}
            disabled={false}
            ref={reftt}
          /> */}
          <div
            className="saved-notification"
            style={{
              position: 'absolute',
              backgroundColor: chosenPortfolio.primary_color
                ? chosenPortfolio.primary_color
                : '#0768b0',
              color: chosenPortfolio.text_color
                ? chosenPortfolio.text_color
                : 'white',
              textShadow: chosenPortfolio.accent_color
                ? `1px 1px 1px ${chosenPortfolio.accent_color}`
                : '1px 1px 1px black',
              zIndex: 2,
              top: '44%',
              left: 'calc(50% - 110px)',
              paddingLeft: '10px',
              paddingRight: '10px',
              paddingTop: '2px',
              paddingBottom: '2px',
              borderRadius: '5px',
              fontFamily: "'Oswald', sans-serif",
              transition: 'all 0.4s ease',
              height: '50px',
              width: '200px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              visibility: saved ? 'visible' : 'hidden',
              boxShadow: '2px 2px 2px #00000080',
              fontSize: '1.4em',
              letterSpacing: '0.05em',
              border: '1px solid black'
            }}
          >
            NOTES SAVED!
          </div>
          <div
            style={{
              position: 'absolute',
              backgroundColor: chosenPortfolio.primary_color
                ? chosenPortfolio.primary_color
                : '#0768b0',
              color: chosenPortfolio.text_color
                ? chosenPortfolio.text_color
                : 'white',
              textShadow: chosenPortfolio.accent_color
                ? `1px 1px 1px ${chosenPortfolio.accent_color}`
                : '1px 1px 1px black',
              zIndex: 2,
              top: '44%',
              left: 'calc(50% - 110px)',
              paddingLeft: '10px',
              paddingRight: '10px',
              paddingTop: '2px',
              paddingBottom: '2px',
              borderRadius: '5px',
              fontFamily: "'Oswald', sans-serif",
              transition: 'all 0.4s ease',
              height: '50px !important',
              width: '200px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              visibility: copied === true ? 'visible' : 'hidden',
              boxShadow: '2px 2px 2px #00000080',
              fontSize: '1.4em',
              letterSpacing: '0.05em',
              border: '1px solid black'
            }}
            //     style={{
            //   position: 'absolute', zIndex: 1, visibility: copied === true ? 'visible' : 'hidden', backgroundColor: chosenPortfolio.primary_color ? chosenPortfolio.primary_color : '#949494', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '5px', top: '11%', left: '40%', height: '70%', color: chosenPortfolio.text_color ? chosenPortfolio.text_color : 'white', padding: '0px 10px 0px 10px', border: chosenPortfolio.accent_color ? `2px solid ${chosenPortfolio.accent_color}` : '2px solid black'
            // }}
          >
            <p
              style={{
                color: chosenPortfolio.text_color
                  ? chosenPortfolio.text_color
                  : 'white',
                marginBlockStart: '0px',
                marginBlockEnd: '0px',
                fontWeight: 600,
                fontFamily: FontUtil(chosenPortfolio.SystemText)
              }}
            >
              Copied!
            </p>
          </div>
        </div>
        <div
          className="notepad-button-container"
          style={{ marginBottom: '20px' }}
        >
          <Button
            additionalClassName="NotePadButton"
            enlarge={false}
            // additionalClassName="notepad-save-button"
            height="50px"
            text="Save"
            backgroundColor={'transparent'}
            color="#166DC0"
            style={{ borderColor: `${accentColor}` }}
            fontSize="18px"
            onClick={async () => {
              // Consolidate updateSessionWithNotes and updateSessionInSanity under one useEffect tracking either notepad reducer text, or "internal Notepad text"
              // with timestamp and titled entries, storage as HTML offers this as option. Alternatively, store multiple comment objects in firestore and quietly remove
              // the sanity viewport due to lack of use and potential lack of flexibility to new system. Email Route in IU2?
              // import { v4 as uuidv4 } from 'uuid';
              /*<p id="date1">Date:</p>
<script>var te = new Date().toLocaleString(); 
document.getElementById("date1").textContent += ` ${te}`
</script>
<p id="date2">Date:</p>
<script>
//var te = new Date(new Date().setDate(new Date().getDate()+1)).getTime(); 
var te = new Date(1647549845793)
document.getElementById("date2").textContent += ` ${te}`
</script>*/

              handleSaveNotification();
              updateNotepad(text);
              setText('');
              //updateSessionWithNotes(text);
              // await updateSessionInSanity({ notes: text })
              // alert("Success");
              // else
              // alert("Fail")
            }}
          />
          <Button
            additionalClassName="NotePadButton"
            enlarge={false}
            // additionalClassName="notepad-save-button"
            height="50px"
            text="Copy"
            // backgroundColor={secondaryColor}
            backgroundColor={'transparent'}
            // color={
            //   chosenPortfolio.text_color
            //     ? chosenPortfolio.text_color
            //     : "white"
            // }
            color="#166DC0"
            style={{ borderColor: `${accentColor}` }}
            fontSize="18px"
            onClick={CopyTxt}
          />
        </div>
      </div>
    </div>
  );
  //(
  // <IconContext.Provider
  //   value={{
  //     className: 'menu-icon-md',
  //     style: {
  //       color: chosenPortfolio.accent_color
  //         ? chosenPortfolio.accent_color
  //         : companyConfig.accent_color
  //     }
  //   }}
  // >
  //   <style>{`div.ql-container{
  //   height: 100%;
  //   overflow: auto;
  //   }
  //   div.quill{
  //     color: black;
  //     max-height: 100%;
  //     display: inline-flex;
  //     flex-direction: column;
  //     width: 100%;
  //   }
  //   div.ql-editor{
  //     word-wrap: anywhere;
  //   }
  //   button.ql-link{
  //     display: none !important;
  //   }
  //   `}</style>
  //   <div
  //     className={`portfolio-notepad-container ${
  //       showNotepad ? 'active' : 'hide'
  //     }`}
  //     style={{
  //       backgroundColor: '#000000BF'
  //     }}
  //   >
  //     <div className="notepad-close">
  //       <div className="close-container">
  //         <div style={{backgroundColor: `${chosenPortfolio.primary_color}`, borderRadius: "50%"}} className="expansion">
  //           <div
  //             onClick={toggleNotepad}
  //             style={{
  //               maskImage: `url(${X})`,
  //               maskSize: '100% 100%',
  //               maskRepeat: 'no-repeat',
  //               WebkitMaskImage: `url(${X})`,
  //               WebkitMaskRepeat: 'no-repeat',
  //               WebkitMaskSize: '100% 100%',
  //               backgroundColor: chosenPortfolio.text_color,
  //               height: '48px',
  //               width: '48px',
  //               cursor: `pointer`,
  //               objectFit: 'contain',
  //             }}
  //           ></div>
  //         </div>
  //       </div>
  //       <div className="intro-box">
  //           <p
  //               style={{
  //                   fontSize: '36px',
  //                   marginBlockStart: '0px',
  //                   marginBlockEnd: '0px',
  //                   color: BorderColor,
  //                   textShadow: `-1px 0 ${accentColor}, 0 1px ${accentColor}, 1px 0 ${accentColor}, 0 -1px ${accentColor}`
  //               }}
  //           >
  //               {!isGuestMode ? 'Notes' : 'Comments'}
  //           </p>
  //       </div>
  //     </div>

  //     <div className="textarea-container"
  //       style={{position: "relative"}}
  //     >
  //       {/* <h1 style={{width:'100%'}}>{!isGuestMode?'Notes':'Comments'}</h1> */}
  //       <div style={{background:"white", height:`${Scale.Height(Size,602.817)}px`, display:"inline-flex", width:"100%"}}>
  //       <ReactQuill ref={reftt} theme={"snow"} onChange={(e,Delta,Source,Editor)=>{//////onsole.log("!!SELECTION!!",e);//////onsole.log("!!SELECTION!!",Editor.getContents()); setText(e)}} value={text} ></ReactQuill>
  //       </div>
  //       {/* style={{background:"white"}} */}
  //       {/* <TextArea
  //         name={'notepad-textarea'}
  //         value={text}
  //         onChange={(e) => setText(e.target.value)}
  //         disabled={false}
  //         ref={reftt}
  //       /> */}
  //       <div className="saved-notification"
  //         style={{
  //           position: 'absolute',
  //           backgroundColor: chosenPortfolio.primary_color ? chosenPortfolio.primary_color : '#0768b0',
  //           color: chosenPortfolio.text_color ? chosenPortfolio.text_color : "white",
  //           textShadow: chosenPortfolio.accent_color ? `1px 1px 1px ${chosenPortfolio.accent_color}` : "1px 1px 1px black",
  //           zIndex: 2,
  //           top: '44%',
  //           left: 'calc(50% - 110px)',
  //           paddingLeft: '10px',
  //           paddingRight: '10px',
  //           paddingTop: '2px',
  //           paddingBottom: '2px',
  //           borderRadius: '5px',
  //           fontFamily: "'Oswald', sans-serif",
  //           transition: 'all 0.4s ease',
  //           height: '50px',
  //           width: '200px',
  //           display: 'flex',
  //           justifyContent: "center",
  //           alignItems: "center",
  //           visibility: saved ? 'visible' : 'hidden',
  //           boxShadow: '2px 2px 2px #00000080',
  //           fontSize: "1.4em",
  //           letterSpacing: "0.05em",
  //           border: "1px solid black"
  //         }}
  //       >NOTES SAVED!</div>
  //                 <div style={{
  //               position: 'absolute', zIndex: 1, visibility: copied === true ? 'visible' : 'hidden', backgroundColor: chosenPortfolio.primary_color ? chosenPortfolio.primary_color : '#949494', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '5px', top: '11%', left: '40%', height: '70%', color: chosenPortfolio.text_color ? chosenPortfolio.text_color : 'white', padding: '0px 10px 0px 10px', border: chosenPortfolio.accent_color ? `2px solid ${chosenPortfolio.accent_color}` : '2px solid black'
  //             }}
  //           >
  //             <p style={{color: chosenPortfolio.text_color ? chosenPortfolio.text_color : 'white', marginBlockStart: '0px', marginBlockEnd: '0px', fontWeight: 600}}>Copied!</p>
  //           </div>
  //     </div>
  //     <div className="notepad-button-container" style={{marginBottom: "20px"}}>
  //       <Button
  //         enlarge={false}
  //         // additionalClassName="notepad-save-button"
  //         height="50px"
  //         text="Save"
  //         backgroundColor={secondaryColor}
  //         color={
  //           chosenPortfolio.text_color
  //             ? chosenPortfolio.text_color
  //             : "white"
  //         }
  //         style={{borderColor: `${accentColor}`}}
  //         fontSize="18px"
  //         onClick={async () => {
  //           handleSaveNotification();
  //           updateNotepad(text);
  //           //updateSessionWithNotes(text);
  //           await updateSessionInSanity({ notes: text })
  //           // alert("Success");
  //           // else
  //           // alert("Fail")
  //         }}
  //       />
  //       <Button
  //         enlarge={false}
  //         // additionalClassName="notepad-save-button"
  //         height="50px"
  //         text="Copy"
  //         backgroundColor={secondaryColor}
  //         color={
  //           chosenPortfolio.text_color
  //             ? chosenPortfolio.text_color
  //             : "white"
  //         }
  //         style={{borderColor: `${accentColor}`}}
  //         fontSize="18px"
  //         onClick={CopyTxt}
  //       />
  //     </div>
  //   </div>
  // </IconContext.Provider>
  // );
};

export default NotepadT9;
