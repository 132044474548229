import React, { useEffect, useState } from 'react'; //, { useState, useEffect }
import { arrayToObject } from '../arrayHelper';
import { urlFor } from '../sanityHelper';
// import MagnifyingGlass from '../../../../../../../assets/RootSVGS/MagnifyingGlass.js'
import BASlider from '../../components/BeforeAfterSlider';
import PDFViewer2 from '../../components/FullWidthPDFViewer';
// import styled from 'styled-components';
import ToggleMenu from '../../components/_Portfolio/ToggleMenu/ToggleMenu';
import SanityMuxPlayer from '../../containers/MuxPlayer';
// import CloseIcon from "../../../../../../../containers/PageMenus/Menu9-Storybook/CloseSVG"
import BlockContent from '@sanity/block-content-to-react';
import serializers from '../scriptSerializers';

// const LikeItems = ({Ele,Size,Pages,chosenPortfolio,videos,pdfs, SetPI, Thumb})=>{

//   const [GallTarget] = useState(Math.max(Ele.gallery_index,0))//SetGalTarget
//   const [Page,SetPage] = useState(Pages[Ele.page_id])//SetPage
//   const [PageType,SetPageType] = useState (Pages[Ele.page_id]?._type)
//   const [Content,SetContent] = useState(undefined)
//   const [type,SetType] = useState(undefined)
//   console.log("LikesRender",Ele,Page,PageType,Content,type);
//   useEffect(()=>{if(Pages[Ele.page_id])SetPage(Pages[Ele.page_id])},[GallTarget,Pages,Ele])
//   useEffect(()=>{if(Pages[Ele.page_id])SetPageType(Pages[Ele.page_id]?._type)},[GallTarget,Pages,Ele])
//   useEffect(()=>{//onsole.log("Page",Page);
//     Page?.content?Page?.content[GallTarget]?SetContent(Page?.content[GallTarget]):SetContent(undefined):SetContent(undefined)},[Page,GallTarget]
//     )
//   useEffect(()=>{Content?._type?SetType(Content?._type):Page?._type?SetType(Page?._type):SetType(undefined)},[Content,Page])

//   const blockContent = (blocks) => {
//     // filter non valid imageLinks
//     let tempBlocks = blocks.filter((obj) => {
//       if (obj._type === 'imageLinks' && obj.image) {
//         return true;
//       }
//       if (obj._type !== 'imageLinks') {
//         return true;
//       }
//       return false;
//     });

//     // further filter non valid embedHtml
//     tempBlocks = tempBlocks.filter((obj) => {
//       if (obj._type === 'embedHTML' && obj.html) {
//         return true;
//       }
//       if (obj._type !== 'embedHTML') {
//         return true;
//       }
//       return false;
//     });
//     return tempBlocks;
//   };
// //type, galleryNum, isSelected, IsGal,Id,SetBufferFunctions,BufferGal,BufferRender,isVis
//   const renderSingleContent = (Decay) => {
//     if(Decay > 4)
//     return (<p>Thumbnail Not renderable</p>)
//     // try{
//     if(Ele.id.includes("splash")){
//       console.log(Pages,chosenPortfolio)
//       switch(chosenPortfolio.splash[Math.max(Ele.gallery_index,0)]._type){
//         case "galleryImage":{
//           let Content = chosenPortfolio.splash[Math.max(Ele.gallery_index,0)];
//           let image = Content.image;
//           return (
//             <img
//               className="portfolio-single-image"
//               src={urlFor(image).auto('format').url()} // auto converts to webp if browser supports it
//               alt="mainImage"
//               type="galleryImage"
//               style={{
//                 opacity: chosenPortfolio && chosenPortfolio.theme === 'theme2' ? 1 : 1,
//                 width: '100%',
//                 height: '100%',
//                 objectFit:'contain'
//               }}
//             />
//           );
//         }
//         case "galleryVideo":{
//           let Content = chosenPortfolio.splash[Math.max(Ele.gallery_index,0)];
//           let video = videos[Content.video.asset._ref];
//         let videoSettings = Content;
//         ////////onsole.log(videoSettings);
//         return (
//           <SanityMuxPlayer
//           // chosenPortfolio={chosenPortfolio}
//             chosenContentPageId={Ele.page_id}
//             isContent={true}
//             assetDocument={video}
//             autoload={true}
//             autoplay={videoSettings.autoplay}
//             loop={videoSettings.loop}
//             SIG={(Ele.page_id === `splashkey-${chosenPortfolio?._id}` && true) ? false : videoSettings.SIG}
//             SIP={(Ele.page_id === `splashkey-${chosenPortfolio?._id}` && true) ? false : videoSettings.SIP}
//             muted={false}
//             showControls={true}
//             style={{}}
//             posterurl={`https://image.mux.com/${video.playbackId}/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=1`}
//             type="galleryVideo"
//             // isSelected={isSelected}
//             Size={Size}
//             screensaverShow={true}
//           />
//         );
//         }
//         default:
//           return <div></div>
//       }
//     }
//     //onsole.log(PageType);
//     if(PageType === 'htmlContent'){
//       return(
//         <div className="html-block-content" style={{backgroundColor:"#FCFAFA"}}>
//           <BlockContent
//             blocks={blockContent(Page.content)}
//             serializers={serializers}
//           />
//         </div>
//       )
//     }
//     if(PageType === "website"){

//         // //onsole.log
//         return (
//         <div style={{height:"100%",width:"100%", position:"relative"}} className="iframe-container">
//           <iframe title="iframe"
//            allowFullScreen src={Page.url} style={{backgroundColor: "transparent", height:"100%",width:"100%", scrollbarWidth:"none",
//            display:'block',border:"none" /* Reset default border */}}></iframe>
//         </div>)
//         }
//     if(PageType === "gallery" || PageType === "sliderGallery")
//     {
//       switch (type) {
//       case 'galleryImage':
//         let image = Content.image;
//         return (
//           <img
//             className="portfolio-single-image"
//             src={urlFor(image).auto('format').url()} // auto converts to webp if browser supports it
//             alt="mainImage"
//             type="galleryImage"
//             style={{
//               opacity: chosenPortfolio && chosenPortfolio.theme === 'theme2' ? 1 : 1,
//               width: '100%',
//               height: '100%',
//               objectFit:'contain'
//             }}
//           />
//         );
//       case 'galleryVideo':
//         let video = videos[Content.video.asset._ref];
//         let videoSettings = Content;
//         ////////onsole.log(videoSettings);
//         return (
//           <SanityMuxPlayer
//           // chosenPortfolio={chosenPortfolio}
//             chosenContentPageId={Ele.page_id}
//             isContent={true}
//             assetDocument={video}
//             autoload={true}
//             autoplay={videoSettings.autoplay}
//             loop={videoSettings.loop}
//             SIG={(Ele.page_id === `splashkey-${chosenPortfolio?._id}` && true) ? false : videoSettings.SIG}
//             SIP={(Ele.page_id === `splashkey-${chosenPortfolio?._id}` && true) ? false : videoSettings.SIP}
//             muted={false}
//             showControls={true}
//             style={{}}
//             posterurl={`https://image.mux.com/${video.playbackId}/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=1`}
//             type="galleryVideo"
//             // isSelected={isSelected}
//             Size={Size}
//             screensaverShow={true}
//           />
//         );
//       case 'galleryPdf':
//         //////onsole.log("!!TITLE!!",Content.title);
//         let url2 = pdfs[Content.file.asset._ref].url;
//         let thumbNailRef2 = Content.thumbnail.asset._ref;
//         let thumbNailUrl2 = urlFor(thumbNailRef2)
//           .auto('format')
//           .width(100)
//           .url();
//           let thumbNailUrl3 = urlFor(thumbNailRef2)
//           .auto('format')
//           .width(1000)
//           .url();
//         return (
//           <PDFViewer2
//           // isVis={isVis}
//             // BufferGal ={BufferGal}
//             // BufferRender = {BufferRender}
//             // SetBufferFunctions={SetBufferFunctions}
//             // ID={Id}
//             // IsGal={IsGal}
//             url={url2}
//             isThumbnail={false}
//             TThumb ={thumbNailUrl3}
//             type="galleryFullWidthPdf"
//             thumbnailUrl={thumbNailUrl2}
//             title={Content.title}
//           />
//         );
//       case 'galleryFullWidthPdf':
//         //////onsole.log("!!TITLE!!",Content.title);
//         let url3 = pdfs[Content.file.asset._ref].url;
//         let thumbNailRef3 = Content.thumbnail.asset._ref;
//         let thumbNailUrl4 = urlFor(thumbNailRef3)
//           .auto('format')
//           .width(100)
//           .url();
//           let thumbNailUrl5 = urlFor(thumbNailRef3)
//           .auto('format')
//           .width(1000)
//           .url();
//         return (
//           <PDFViewer2
//           // isVis={isVis}
//           //   BufferGal ={BufferGal}
//           //   BufferRender = {BufferRender}
//           //   SetBufferFunctions={SetBufferFunctions}
//           //   ID={Id}
//           //   IsGal={IsGal}
//             url={url3}
//             isThumbnail={false}
//             TThumb ={thumbNailUrl5}
//             type="galleryFullWidthPdf"
//             thumbnailUrl={thumbNailUrl4}
//             title={Content.title}
//           />
//         );
//       case 'galleryBASlider':
//         {
//           let Aimage = Content.Aimage;
//           let Bimage = Content.Bimage;
//           let LabelA;
//           let LabelB;
//           if(Content.Labels !== false){
//           LabelA = Content.AimageL;
//           LabelB = Content.BimageL;
//           }
//           else{
//           LabelA = '';
//           LabelB = '';
//           }
//           let Vert = Content.Vert;
//           let Color = Content.SlideColor;
//           //////onsole.log('!!!!BA!!!!', Content)
//           return<BASlider style={{maxWidth:"90%"}} LabelB={LabelB} LabelA={LabelA} Color={Color} Vert={Vert} ImageA={Aimage} ImageB={Bimage}/>
//         }
//       case 'toggleMenu':
//         {
//           let firstContent = Content.content[0];
//           let secondContent = Content.content[1];
//           //////onsole.log('TOGGLE MENU', Content);
//           // return;
//           //onsole.log(Page,PageType, Content);
//           return (
//             <ToggleMenu
//             style={{width:"100%"}}
//             chosenPortfolio={chosenPortfolio}
//               firstContent={firstContent}
//               secondContent={secondContent}
//               videos={videos}
//               pdfs={pdfs}
//               // currentNavItem={currentNavItem}
//               // contentLength={contents.length}
//             />
//           );
//         }
//       // case 'sliderGallery':
//       //   {
//           // //////onsole.log('TOGGLE MENU', Content);
//           // return;
//           // return (
//           //   <ToggleMenu
//           //     firstContent={firstContent}
//           //     secondContent={secondContent}
//           //     videos={videos}
//           //     pdfs={pdfs}
//           //     currentNavItem={currentNavItem}
//           //     contentLength={contents.length}
//           //   />
//           // );
//         // }
//       default:
//         // throw "err";
//         return <div/>
//     }
//   }
//   console.log(Page,PageType, Content,Ele)
// // }
// // catch{
// //   sleep(1000).then(()=>{
// //     return renderSingleContent(Decay+1);
// //   })

// // }
// };

//   //onsole.log("Type",type)
//   const renderSingleThumb = (Decay) => {
//     if(Decay > 4)
//     return (<p>Thumbnail Not renderable</p>)
//     console.log("LikesRenderThumb",Ele,Page,PageType,Content,type);
//     // try{
//     if(Ele.id.includes("splash")){
//       console.log(Pages,chosenPortfolio)
//       let Content = chosenPortfolio.splash[Math.max(Ele.gallery_index,0)];
//       switch(chosenPortfolio.splash[Math.max(Ele.gallery_index,0)]._type){
//         case 'galleryImage':
//           let image = Content.image;
//           return (
//             <img
//               className="portfolio-single-image"
//               src={urlFor(image).auto('format').width(100).url()} // auto converts to webp if browser supports it
//               alt="mainImage"
//               type="galleryImage"
//               style={{
//                 opacity: chosenPortfolio.theme === 'theme2' ? 1 : 1,
//                 width: '100%',
//                 height: '100%',
//                 objectFit:'contain'
//               }}
//             />
//           );
//         case 'galleryVideo':
//           let video = videos[Content.video.asset._ref];
//           //let videoSettings = Content;
//           return (
//             <img style={{height:"100%", width:"100%", objectFit:"contain"}} src={`https://image.mux.com/${video.playbackId}/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=${video.thumbTime !==0?video.thumbTime:1}`} alt="poster" />
//             // <SanityMuxPlayer
//             //   assetDocument={video}
//             //   autoload={true}
//             //   autoplay={videoSettings.autoplay}
//             //   loop={videoSettings.loop}
//             //   muted={false}
//             //   showControls={true}
//             //   style={{}}
//             //   posterurl={`https://image.mux.com/${video.playbackId}/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=1`}
//             //   type="galleryVideo"
//             //   isSelected={isSelected}
//             // />
//           );
//           default:
//             return<div></div>
//       }
//     }
//     if(PageType === 'htmlContent'){
//       return(
//         <div className="html-block-content" style={{backgroundColor:"#FCFAFA"}}>
//           <BlockContent
//             blocks={blockContent(Page.content)}
//             serializers={serializers}
//           />
//         </div>
//       )
//     }
//     if(PageType === "website"){

//       // //onsole.log
//       return (
//         <div style={{height:"100%",width:"100%", position:"relative"}} className="iframe-container">
//           <iframe title="iframe"
//            allowFullScreen src={Page.url} style={{backgroundColor: "transparent", height:"100%",width:"100%", pointerEvents:"none", scrollbarWidth:"none",
//            display:'block',border:"none" /* Reset default border */}}></iframe>
//         </div>)
//       }
//       if(PageType === "gallery" || PageType === "sliderGallery")
//       switch (type) {
//         case 'galleryImage':
//           let image = Content.image;
//           return (
//             <img
//               className="portfolio-single-image"
//               src={urlFor(image).auto('format').width(100).url()} // auto converts to webp if browser supports it
//               alt="mainImage"
//               type="galleryImage"
//               style={{
//                 opacity: chosenPortfolio.theme === 'theme2' ? 1 : 1,
//                 width: '100%',
//                 height: '100%',
//                 objectFit:'contain'
//               }}
//             />
//           );
//         case 'galleryVideo':
//           let video = videos[Content.video.asset._ref];
//           //let videoSettings = Content;
//           return (
//             <img style={{height:"100%", width:"100%", objectFit:"contain"}} src={`https://image.mux.com/${video.playbackId}/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=${video.thumbTime !==0?video.thumbTime:1}`} alt="poster" />
//             // <SanityMuxPlayer
//             //   assetDocument={video}
//             //   autoload={true}
//             //   autoplay={videoSettings.autoplay}
//             //   loop={videoSettings.loop}
//             //   muted={false}
//             //   showControls={true}
//             //   style={{}}
//             //   posterurl={`https://image.mux.com/${video.playbackId}/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=1`}
//             //   type="galleryVideo"
//             //   isSelected={isSelected}
//             // />
//           );
//         case 'galleryPdf':
//           let url2 = pdfs[Content.file.asset._ref].url;
//           let thumbNailRef2 = Content.thumbnail.asset._ref;
//           let thumbNailUrl2 = urlFor(thumbNailRef2)
//             .auto('format')
//             .width(100)
//             .url();
//           return (
//             <PDFViewer2
//               url={url2}
//               isThumbnail={true}
//               type="galleryFullWidthPdf"
//               thumbnailUrl={thumbNailUrl2}
//             />
//           );
//           case 'galleryBASlider':
//             {
//               let Aimage = Content.Aimage;
//               let Bimage = Content.Bimage;
//               let LabelA = Content.AimageL;
//               let LabelB = Content.BimageL;
//               let Vert = Content.Vert;
//               let Color = Content.SlideColor;
//               //////onsole.log('!!!!BA!!!!', Content)
//               return<BASlider Thumb LabelB={LabelB} LabelA={LabelA} Color={Color} Vert={Vert} ImageA={Aimage} ImageB={Bimage}/>
//             }
//         case 'galleryFullWidthPdf':
//           let url3 = pdfs[Content.file.asset._ref].url;
//           let thumbNailRef3 = Content.thumbnail.asset._ref;
//           let thumbNailUrl3 = urlFor(thumbNailRef3)
//             .auto('format')
//             .width(100)
//             .url();
//           return (
//             <PDFViewer2
//               url={url3}
//               isThumbnail={true}
//               type="galleryFullWidthPdf"
//               thumbnailUrl={thumbNailUrl3}
//             />
//           );
//           case 'toggleMenu':
//         {
//           let firstContent = Content.content[0];
//           let secondContent = Content.content[1];
//           //////onsole.log('TOGGLE MENU', Content);
//           // return;
//           //onsole.log(Page,PageType, Content);
//           return (
//             <div style={{height:"100%",width:"100%", position:"relative"}} className="iframe-container">
//             <ToggleMenu
//             style={{width:"100%"}}
//             chosenPortfolio={chosenPortfolio}
//               firstContent={firstContent}
//               secondContent={secondContent}
//               videos={videos}
//               pdfs={pdfs}
//               // currentNavItem={currentNavItem}
//               // contentLength={contents.length}
//             />
//             </div>
//           );
//         }
//         default:
//           // throw "err"
//           // console.log("Test Error")
//           return <div/>
//           // return <p>Thumbnail issue</p>

//       }
//     // }
//     // catch{
//     //   sleep(1000).then(()=>{
//     //   console.log("Decay",Decay)
//     //   return [renderSingleThumb(Decay+1)]
//     //   })

//     // }
//     // return <p>Thumbnail issue</p>
//     return <div/>
//     };

//     //onsole.log(Content)
//     if(Thumb)
//     return renderSingleThumb();
//     else
//     return renderSingleContent();
// }
export const PortfolioContentOrThumbnail = ({
  Ele,
  Size,
  Pages,
  chosenPortfolio,
  videos,
  pdfs,
  Thumb,
  isSelected
}) => {
  let [LocalPages, SetLocalPages] = useState(
    !Pages || Pages.length === 0
      ? arrayToObject(chosenPortfolio?.pages, '_key')
      : Pages
  );
  useEffect(() => {
    if (!Pages || Pages.length === 0)
      SetLocalPages(arrayToObject(chosenPortfolio?.pages, '_key'));
    else {
      SetLocalPages(Pages);
    }
  }, [chosenPortfolio, Pages]);
  // console.log("!!INDEX",Ele.gallery_index)
  // const [GallTarget,SetGallTarget] = useState(Math.max(Ele.gallery_index,0))//SetGalTarget
  // useEffect(()=>{SetGallTarget(Math.max(Ele.gallery_index,0))},[Ele.gallery_index])
  // const [Page,SetPage] = useState(Pages[Ele.page_id])//SetPage
  // useEffect(()=>{if(Pages[Ele.page_id])SetPage(Pages[Ele.page_id])},[GallTarget,Pages,Ele.page_id])
  // const [PageType,SetPageType] = useState (Pages[Ele.page_id]?._type)
  // useEffect(()=>{if(Pages[Ele.page_id]){SetPageType(Pages[Ele.page_id]?._type);console.log("!!!TYPE",Pages[Ele.page_id]?._type)}},[GallTarget,Pages,Ele.page_id])
  // const [Content,SetContent] = useState(undefined)
  // useEffect(()=>{//onsole.log("Page",Page);
  //   Page?.content?Page?.content[GallTarget]?SetContent(Page?.content[GallTarget]):SetContent(undefined):SetContent(undefined)},[Page,GallTarget]
  //   )
  // const [type,SetType] = useState(undefined)
  // useEffect(()=>{Content?._type?SetType(Content?._type):Page?._type?SetType(Page?._type):SetType(undefined)},[Content,Page])
  // console.log("LikesRender",Ele,Page,PageType,Content,type);

  const blockContent = (blocks) => {
    // filter non valid imageLinks
    let tempBlocks = blocks.filter((obj) => {
      if (obj._type === 'imageLinks' && obj.image) {
        return true;
      }
      if (obj._type !== 'imageLinks') {
        return true;
      }
      return false;
    });

    // further filter non valid embedHtml
    tempBlocks = tempBlocks.filter((obj) => {
      if (obj._type === 'embedHTML' && obj.html) {
        return true;
      }
      if (obj._type !== 'embedHTML') {
        return true;
      }
      return false;
    });
    return tempBlocks;
  };
  //type, galleryNum, isSelected, IsGal,Id,SetBufferFunctions,BufferGal,BufferRender,isVis
  const renderSingleContent = (Decay) => {
    if (Decay > 4) return <p>Thumbnail Not renderable</p>;

    let GallTarget = Math.max(Ele.gallery_index, 0);
    let Page = LocalPages[Ele.page_id];
    let PageType = LocalPages[Ele.page_id]?._type;
    let Content = Page?.content
      ? Page?.content[GallTarget]
        ? Page?.content[GallTarget]
        : undefined
      : undefined;
    let type = Content?._type
      ? Content?._type
      : Page?._type
      ? Page?._type
      : undefined;

    if (Ele.id.includes('splash')) {
      //onsole.log(LocalPages,chosenPortfolio)
      switch (chosenPortfolio.splash[Math.max(Ele.gallery_index, 0)]._type) {
        case 'galleryImage': {
          let Content = chosenPortfolio.splash[Math.max(Ele.gallery_index, 0)];
          let image = Content.image;
          return (
            <img
              className="portfolio-single-image"
              src={urlFor(image).auto('format').url()} // auto converts to webp if browser supports it
              alt="mainImage"
              type="galleryImage"
              style={{
                opacity:
                  chosenPortfolio && chosenPortfolio.theme === 'theme2' ? 1 : 1,
                width: '100%',
                height: '100%',
                objectFit: 'contain'
              }}
            />
          );
        }
        case 'galleryVideo': {
          let Content = chosenPortfolio.splash[Math.max(Ele.gallery_index, 0)];
          let video = videos[Content.video.asset._ref];
          let videoSettings = Content;
          return (
            <SanityMuxPlayer
              // chosenPortfolio={chosenPortfolio}
              chosenContentPageId={Ele.page_id}
              isContent={true}
              assetDocument={video}
              autoload={true}
              autoplay={videoSettings.autoplay}
              loop={videoSettings.loop}
              SIG={
                Ele.page_id === `splashkey-${chosenPortfolio?._id}` && true
                  ? false
                  : videoSettings.SIG
              }
              SIP={
                Ele.page_id === `splashkey-${chosenPortfolio?._id}` && true
                  ? false
                  : videoSettings.SIP
              }
              muted={false}
              showControls={true}
              style={{}}
              posterurl={`https://image.mux.com/${video.playbackId}/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=1`}
              type="galleryVideo"
              isSelected={isSelected}
              Size={Size}
              screensaverShow={true}
            />
          );
        }
        default:
          return <div></div>;
      }
    }
    //onsole.log(PageType);
    if (PageType === 'htmlContent') {
      return (
        <div
          className="html-block-content"
          style={{ backgroundColor: '#FCFAFA' }}
        >
          <BlockContent
            blocks={blockContent(Page.content)}
            serializers={serializers}
          />
        </div>
      );
    }
    if (PageType === 'website') {
      // //onsole.log
      return (
        <div
          style={{ height: '100%', width: '100%', position: 'relative' }}
          className="iframe-container"
        >
          <iframe
            title="iframe"
            allowFullScreen
            src={Page.url}
            style={{
              backgroundColor: 'transparent',
              height: '100%',
              width: '100%',
              scrollbarWidth: 'none',
              display: 'block',
              border: 'none' /* Reset default border */
            }}
          ></iframe>
        </div>
      );
    }
    if (PageType === 'gallery' || PageType === 'sliderGallery') {
      switch (type) {
        case 'galleryImage': {
          let image = Content.image;
          return (
            <img
              className="portfolio-single-image"
              src={urlFor(image).auto('format').url()} // auto converts to webp if browser supports it
              alt="mainImage"
              type="galleryImage"
              style={{
                opacity:
                  chosenPortfolio && chosenPortfolio.theme === 'theme2' ? 1 : 1,
                width: '100%',
                height: '100%',
                objectFit: 'contain'
              }}
            />
          );
        }
        case 'galleryVideo': {
          let video = videos[Content.video.asset._ref];
          let videoSettings = Content;
          // console.log(videoSettings);
          // TODO: get actual video settings
          return (
            <SanityMuxPlayer
              // chosenPortfolio={chosenPortfolio}
              chosenContentPageId={Ele.page_id}
              isContent={true}
              assetDocument={video}
              autoload={true}
              autoplay={videoSettings.autoplay}
              loop={videoSettings.loop}
              SIG={
                Ele.page_id === `splashkey-${chosenPortfolio?._id}` && true
                  ? false
                  : videoSettings.SIG
              }
              SIP={
                Ele.page_id === `splashkey-${chosenPortfolio?._id}` && true
                  ? false
                  : videoSettings.SIP
              }
              muted={false}
              showControls={true}
              style={{}}
              posterurl={`https://image.mux.com/${video.playbackId}/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=1`}
              type="galleryVideo"
              isSelected={isSelected}
              Size={Size}
              screensaverShow={true}
            />
          );
        }
        case 'galleryPdf': {
          //////onsole.log("!!TITLE!!",Content.title);
          let url2 = pdfs[Content.file.asset._ref].url;
          let thumbNailRef2 = Content.thumbnail.asset._ref;
          let thumbNailUrl2 = urlFor(thumbNailRef2)
            .auto('format')
            .width(100)
            .url();
          let thumbNailUrl3 = urlFor(thumbNailRef2)
            .auto('format')
            .width(1000)
            .url();

          return (
            <PDFViewer2
              // isVis={isVis}
              // BufferGal ={BufferGal}
              // BufferRender = {BufferRender}
              // SetBufferFunctions={SetBufferFunctions}
              // ID={Id}
              // IsGal={IsGal}
              url={url2}
              isThumbnail={false}
              TThumb={thumbNailUrl3}
              type="galleryFullWidthPdf"
              thumbnailUrl={thumbNailUrl2}
              title={Content.title}
            />
          );
        }
        case 'galleryFullWidthPdf': {
          //////onsole.log("!!TITLE!!",Content.title);
          let url3 = pdfs[Content.file.asset._ref].url;
          let thumbNailRef3 = Content.thumbnail.asset._ref;
          let thumbNailUrl4 = urlFor(thumbNailRef3)
            .auto('format')
            .width(100)
            .url();
          let thumbNailUrl5 = urlFor(thumbNailRef3)
            .auto('format')
            .width(1000)
            .url();

          return (
            <PDFViewer2
              url={url3}
              isThumbnail={false}
              TThumb={thumbNailUrl5}
              type="galleryFullWidthPdf"
              thumbnailUrl={thumbNailUrl4}
              title={Content.title}
            />
          );
        }

        case 'galleryBASlider': {
          let Aimage = Content.Aimage;
          let Bimage = Content.Bimage;
          let LabelA;
          let LabelB;
          if (Content.Labels !== false) {
            LabelA = Content.AimageL;
            LabelB = Content.BimageL;
          } else {
            LabelA = '';
            LabelB = '';
          }
          let Vert = Content.Vert;
          let Color = Content.SlideColor;
          //////onsole.log('!!!!BA!!!!', Content)
          return (
            <BASlider
              style={{ maxWidth: '90%' }}
              LabelB={LabelB}
              LabelA={LabelA}
              Color={Color}
              Vert={Vert}
              ImageA={Aimage}
              ImageB={Bimage}
            />
          );
        }
        case 'toggleMenu': {
          let firstContent = Content.content[0];
          let secondContent = Content.content[1];
          //////onsole.log('TOGGLE MENU', Content);
          // return;
          //onsole.log(Page,PageType, Content);
          return (
            <ToggleMenu
              style={{ width: '100%' }}
              chosenPortfolio={chosenPortfolio}
              firstContent={firstContent}
              secondContent={secondContent}
              videos={videos}
              pdfs={pdfs}
              // currentNavItem={currentNavItem}
              // contentLength={contents.length}
            />
          );
        }
        // case 'sliderGallery':
        //   {
        // //////onsole.log('TOGGLE MENU', Content);
        // return;
        // return (
        //   <ToggleMenu
        //     firstContent={firstContent}
        //     secondContent={secondContent}
        //     videos={videos}
        //     pdfs={pdfs}
        //     currentNavItem={currentNavItem}
        //     contentLength={contents.length}
        //   />
        // );
        // }
        default:
          // throw "err";
          return <div />;
      }
    }
    //onsole.log(Page,PageType, Content,Ele)
    // }
    // catch{
    //   sleep(1000).then(()=>{
    //     return renderSingleContent(Decay+1);
    //   })

    // }
  };

  //onsole.log("Type",type)
  const renderSingleThumb = (Decay) => {
    let GallTarget = Math.max(Ele.gallery_index, 0);
    let Page = LocalPages[Ele.page_id];
    let PageType = LocalPages[Ele.page_id]?._type;
    let Content = Page?.content
      ? Page?.content[GallTarget]
        ? Page?.content[GallTarget]
        : undefined
      : undefined;
    let type = Content?._type
      ? Content?._type
      : Page?._type
      ? Page?._type
      : undefined;
    //console.log("!!INDEX",Page?.content[GallTarget],Content,type, GallTarget)
    if (Decay > 4) return <p>Thumbnail Not renderable</p>;
    //onsole.log("LikesRenderThumb",Ele,Page,PageType,Content,type);
    // try{
    if (Ele.id.includes('splash')) {
      //onsole.log(LocalPages,chosenPortfolio)
      let Content = chosenPortfolio.splash[Math.max(Ele.gallery_index, 0)];
      switch (chosenPortfolio.splash[Math.max(Ele.gallery_index, 0)]._type) {
        case 'galleryImage': {
          let image = Content.image;
          return (
            <img
              className="portfolio-single-image"
              src={urlFor(image).auto('format').width(100).url()} // auto converts to webp if browser supports it
              alt="mainImage"
              type="galleryImage"
              style={{
                opacity: chosenPortfolio.theme === 'theme2' ? 1 : 1,
                width: '100%',
                height: '100%',
                objectFit: 'contain'
              }}
            />
          );
        }
        case 'galleryVideo': {
          let video = videos[Content.video.asset._ref];
          //let videoSettings = Content;
          return (
            <img
              style={{ height: '100%', width: '100%', objectFit: 'contain' }}
              src={`https://image.mux.com/${
                video.playbackId
              }/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=${
                video.thumbTime !== 0 ? video.thumbTime : 1
              }`}
              alt="poster"
            />
            // <SanityMuxPlayer
            //   assetDocument={video}
            //   autoload={true}
            //   autoplay={videoSettings.autoplay}
            //   loop={videoSettings.loop}
            //   muted={false}
            //   showControls={true}
            //   style={{}}
            //   posterurl={`https://image.mux.com/${video.playbackId}/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=1`}
            //   type="galleryVideo"
            //   isSelected={isSelected}
            // />
          );
        }
        default:
          return <div></div>;
      }
    }
    if (PageType === 'htmlContent') {
      return (
        <div
          className="html-block-content"
          style={{ backgroundColor: '#FCFAFA' }}
        >
          <BlockContent
            blocks={blockContent(Page.content)}
            serializers={serializers}
          />
        </div>
      );
    }
    if (PageType === 'website') {
      // //onsole.log
      return (
        <div
          style={{ height: '100%', width: '100%', position: 'relative' }}
          className="iframe-container"
        >
          <iframe
            title="iframe"
            allowFullScreen
            src={Page.url}
            style={{
              backgroundColor: 'transparent',
              height: '100%',
              width: '100%',
              pointerEvents: 'none',
              scrollbarWidth: 'none',
              display: 'block',
              border: 'none' /* Reset default border */
            }}
          ></iframe>
        </div>
      );
    }
    if (PageType === 'gallery' || PageType === 'sliderGallery')
      switch (type) {
        case 'galleryImage': {
          let image = Content.image;
          return (
            <img
              className="portfolio-single-image"
              src={urlFor(image).auto('format').width(100).url()} // auto converts to webp if browser supports it
              alt="mainImage"
              type="galleryImage"
              style={{
                opacity: chosenPortfolio.theme === 'theme2' ? 1 : 1,
                width: '100%',
                height: '100%',
                objectFit: 'contain'
              }}
            />
          );
        }
        case 'galleryVideo': {
          //onsole.log("CRASHPOINT", Content)
          let video = videos[Content.video.asset._ref];
          //let videoSettings = Content;
          return (
            <img
              style={{ height: '100%', width: '100%', objectFit: 'contain' }}
              src={`https://image.mux.com/${
                video.playbackId
              }/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=${
                video.thumbTime !== 0 ? video.thumbTime : 1
              }`}
              alt="poster"
            />
            // <SanityMuxPlayer
            //   assetDocument={video}
            //   autoload={true}
            //   autoplay={videoSettings.autoplay}
            //   loop={videoSettings.loop}
            //   muted={false}
            //   showControls={true}
            //   style={{}}
            //   posterurl={`https://image.mux.com/${video.playbackId}/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=1`}
            //   type="galleryVideo"
            //   isSelected={isSelected}
            // />
          );
        }
        case 'galleryPdf': {
          let url2 = pdfs[Content.file.asset._ref].url;
          let thumbNailRef2 = Content.thumbnail.asset._ref;
          let thumbNailUrl2 = urlFor(thumbNailRef2)
            .auto('format')
            .width(100)
            .url();
          return (
            <PDFViewer2
              url={url2}
              isThumbnail={true}
              type="galleryFullWidthPdf"
              thumbnailUrl={thumbNailUrl2}
            />
          );
        }
        case 'galleryBASlider': {
          let Aimage = Content.Aimage;
          let Bimage = Content.Bimage;
          let LabelA = Content.AimageL;
          let LabelB = Content.BimageL;
          let Vert = Content.Vert;
          let Color = Content.SlideColor;
          //////onsole.log('!!!!BA!!!!', Content)
          return (
            <BASlider
              Thumb
              LabelB={LabelB}
              LabelA={LabelA}
              Color={Color}
              Vert={Vert}
              ImageA={Aimage}
              ImageB={Bimage}
            />
          );
        }
        case 'galleryFullWidthPdf': {
          let url3 = pdfs[Content.file.asset._ref].url;
          let thumbNailRef3 = Content.thumbnail.asset._ref;
          let thumbNailUrl3 = urlFor(thumbNailRef3)
            .auto('format')
            .width(100)
            .url();
          return (
            <PDFViewer2
              url={url3}
              isThumbnail={true}
              type="galleryFullWidthPdf"
              thumbnailUrl={thumbNailUrl3}
            />
          );
        }
        case 'toggleMenu': {
          let firstContent = Content.content[0];
          let secondContent = Content.content[1];
          //////onsole.log('TOGGLE MENU', Content);
          // return;
          //onsole.log(Page,PageType, Content);
          return (
            <div
              style={{ height: '100%', width: '100%', position: 'relative' }}
              className="iframe-container"
            >
              <ToggleMenu
                style={{ width: '100%' }}
                chosenPortfolio={chosenPortfolio}
                firstContent={firstContent}
                secondContent={secondContent}
                videos={videos}
                pdfs={pdfs}
                // currentNavItem={currentNavItem}
                // contentLength={contents.length}
              />
            </div>
          );
        }
        default:
          // throw "err"
          // console.log("Test Error")
          return <div />;
        // return <p>Thumbnail issue</p>
      }
    // }
    // catch{
    //   sleep(1000).then(()=>{
    //   console.log("Decay",Decay)
    //   return [renderSingleThumb(Decay+1)]
    //   })

    // }
    // return <p>Thumbnail issue</p>
    return <div />;
  };

  //onsole.log(Content)
  if (Thumb) return renderSingleThumb();
  else return renderSingleContent();
};

// export default LikeItems
