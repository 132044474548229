import FWPDF from './PDFViewer';
import { connect } from 'react-redux';
const mapStateToProps = (state) => ({
  // mode: state.portfolioReducer.mode,
  // pages: state.sanityContentReducer.pages,
  // sessionId: state.portfolioReducer.sessionId,
  // showBriefcase: state.briefcaseReducer.showBriefcase,
  // briefcaseContent: state.briefcaseReducer.briefcaseContent,
  // isModalOpen: state.modalReducer.isModalOpen,
  chosenPortfolio: state.portfolioReducer.chosenPortfolio
  // menuHide: state.pageMenuReducer.menuHide,
  // isGuestMode: state.guestReducer.isGuestMode,
  // currentGuestContentHelper: state.guestReducer.currentContentHelper,
  // liked: state.portfolioReducer.liked,
  // userInfo: state.firestore.ordered.users
});

const mapDispatchToProps = (dispatch) => {
  return {
    //   toggleBriefcase: () => dispatch(toggleBriefcase()),
    //   addToBriefcase: (obj) => dispatch(addToBriefcase(obj)),
    //   endPortfolio: () => dispatch(endPortfolio()),
    //   setPortfolioCustomerInfo: (obj) => dispatch(setPortfolioCustomerInfo(obj)),
    //   showModal: () => dispatch(showModal()),
    //   hideModal: () => dispatch(hideModal()),
    //   setMenuHide: (bool) => dispatch(setMenuHide(bool)),
    //   toggleNotepad: () => dispatch(toggleNotepad()),
    //   toggleScriptnote: () => dispatch(toggleScriptnote()),
    //   setLiked: (array) => dispatch(setLiked(array)),
    //   updateSessionInSanity: (obj) => dispatch(updateSessionInSanity(obj)),
    //   clearAll: () => dispatch(clearAll())
  };
};

const FWPDFContainer = connect(mapStateToProps, mapDispatchToProps)(FWPDF);

export default FWPDFContainer;
// export { FWPDF } from './PDFViewer';
