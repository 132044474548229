import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as Scale from '../../../../../../utils/Scalars';

const Selection = styled.div`
  border-radius: 40px;
  background-color: teal;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 1px 1px 2px #808080;
    color: white;
  }
`;

const Container = styled.div`
  .date-container {
    grid-row: 1 / span 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-family: 'Newsreader', serif;
    font-size: 0.9em;
    font-weight: 600;

    .date-select {
      width: 300px;
      border-radius: 40px;
      //margin-right: 40px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      box-shadow: 2px 2px 4px #808080;

      div {
        font-size: 1em;
        @media (max-width: 1380px) {
          font-size: 0.7em;
        }
        div {
          font-size: 1em;
        }
        font-size: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: none;
        box-shadow: none;
      }
    }
  }
`;
////${props=>props.Scale.Width(props.Size,1)}
const CompanySolo = ({ style, Size, SetSoloDispatch, DateCompany }) => {
  let TimeValues = ['Solo', 'All'];
  const [Solo, SetSolo] = useState(0);

  useEffect(() => {
    if (Solo === 0) {
      SetSoloDispatch(true);
    } else {
      SetSoloDispatch(false);
    }
  }, [Solo, SetSoloDispatch]);

  const toggleSolo = () => {
    if (Solo === 1) {
      SetSolo(0);
    } else {
      SetSolo(1);
    }
  };

  return (
    <Container Scale={Scale} Size={Size} style={{ ...style }}>
      <div className="date-container">
        <div
          className="date-select"
          style={{
            height: `${Scale.Width(Size, 30)}px`,
            marginRight: DateCompany ? '6px' : `${Scale.Width(Size, 20)}px`,
            marginLeft: DateCompany ? `${Scale.Width(Size, 20)}px` : '6px'
          }}
          onClick={toggleSolo}
        >
          {TimeValues.map((ele, i) => {
            if (i === Solo) {
              return (
                <Selection key={1}>
                  <div value={ele} id={ele}>
                    {ele}
                  </div>
                </Selection>
              );
            } else {
              return (
                <div key={0} value={ele} id={ele}>
                  {ele}
                </div>
              );
            }
          })}
        </div>
      </div>
    </Container>
  );
};

export default CompanySolo;
