import { connect } from 'react-redux';
import Menu2 from './SubmenuItem';
const mapStateToProps = (state) => ({
  images: state.sanityContentReducer.images,
  videos: state.sanityContentReducer.videos,
  pdfs: state.sanityContentReducer.pdfs,
  isGuestMode: state.guestReducer.isGuestMode,
  isPortfolioView: state.guestReducer.isPortfolioView,
  guestPages: state.guestReducer.guestPages,
  guestContentHelper: state.guestReducer.guestContentHelper,
  companyConfig: state.sanityContentReducer.companyConfig,
  chosenPortfolio: state.portfolioReducer.chosenPortfolio,
  pages: state.portfolioReducer.pages,
  nav: state.portfolioReducer.nav,
  chosenContentPageId: state.pageMenuReducer.chosenContentPageId,
  pageMenuReducer: state.pageMenuReducer,
  Topic: state.pageMenuReducer.Topic,
  chosenPageId: state.pageMenuReducer.chosenPageId,
  Size: state.portfolioReducer.Size
});

const Menu2Container = connect(mapStateToProps)(Menu2);

export default Menu2Container;
