// import {
//     addGuestPassToSanity,
//     setChosenPageId,
//     setChosenMainPageButtonId,
//     setMenuHide,
//     clearAll,
//     lastPrepPortfolio,
//     resetPortfolioMidSession,
//     setPortfolioSessionId,
//     addSessionToSanity,
//     setCurrentLevel,
//     setContentPageId,
//     kmReset,
//     setNavHistory
//   } from '../../actions';
import { connect } from 'react-redux';
import CoverSheet from './CoverSheet';
const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  userInfo: state.firestore.ordered.users,
  portfolio: state.sanityContentReducer.portfolios,
  member: state.sanityContentReducer.member,
  companyConfig: state.sanityContentReducer.companyConfig,
  mode: state.portfolioReducer.mode,
  sessionId: state.portfolioReducer.sessionId,
  chosenPortfolioId: state.portfolioReducer.chosenPortfolioId,
  chosenPortfolio: state.portfolioReducer.chosenPortfolio,
  pages: state.portfolioReducer.pages,
  nav: state.portfolioReducer.nav,
  customerInfo: state.portfolioReducer.customerInfo,
  backgroundImageUrl: state.portfolioReducer.backgroundImageUrl,
  portfoliolaunchImageUrl: state.portfolioReducer.portfoliolaunchImageUrl,
  showBriefcase: state.briefcaseReducer.showBriefcase,
  briefcaseContent: state.briefcaseReducer.briefcaseContent,
  pageMenuReducer: state.pageMenuReducer,
  isGuestMode: state.guestReducer.isGuestMode,
  Topics: state.pageMenuReducer.Topic,
  subPageId: state.portfolioReducer.chosenSubPageId,
  videos: state.sanityContentReducer.videos,
  Size: state.portfolioReducer.Size
});

const mapDispatchToProps = (dispatch) => {
  return {
    // toggleBriefcase: () => dispatch(toggleBriefcase()),
    // addToBriefcase: (obj) => dispatch(addToBriefcase(obj)),
    // removeFromBriefcase: (id) => dispatch(removeFromBriefcase(id)),
    // clearBriefcase: () => dispatch(clearBriefcase()),
    //setPortfolioSubpageId: (id) => dispatch(setPortfolioSubpageId(id)),
  };
};
const CoverSheetContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CoverSheet);
export default CoverSheetContainer;
