import { connect } from 'react-redux';
import PII from './PII';
import {
  setPortfolioCustomerInfo,
  updateSessionInSanity,
  hideModal
} from '../../../actions';

const mapStateToProps = (state) => ({
  isModalOpen: state.modalReducer.isModalOpen,
  sessionId: state.portfolioReducer.sessionId,
  chosenPortfolio: state.portfolioReducer.chosenPortfolio
});

const mapDispatchToProps = (dispatch) => {
  return {
    setPortfolioCustomerInfo: (obj) => dispatch(setPortfolioCustomerInfo(obj)),
    updateSessionInSanity: (obj) => dispatch(updateSessionInSanity(obj)),
    hideModal: () => dispatch(hideModal())
  };
};

const PIIContainer = connect(mapStateToProps, mapDispatchToProps)(PII);

export default PIIContainer;
