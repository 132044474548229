import SanityClient from '@sanity/client';

let currentSanityInfo = null;

export const setSanityInfo = (obj) => {
  currentSanityInfo = { ...obj };
};

// for read
export const client = () => {
  if (currentSanityInfo === null || currentSanityInfo.projectId === undefined) {
    console.error(
      'ERROR: Trying to create SanityClient without valid dataset and projectId'
    );
    return null;
  }
  let sanity = new SanityClient({
    dataset: currentSanityInfo.dataset,
    projectId: currentSanityInfo.projectId,
    apiVersion: '2021-08-31',
    useCdn: true,
    ignoreBrowserTokenWarning: true
  });

  return sanity;
};

//for write
export const writeClient = () => {
  if (currentSanityInfo === null || currentSanityInfo.projectId === undefined) {
    console.error(
      'ERROR: Trying to create SanityClient without valid dataset and projectId'
    );
    return null;
  }
  let sanity = new SanityClient({
    dataset: currentSanityInfo.dataset,
    projectId: currentSanityInfo.projectId,
    apiVersion: '2021-08-31',
    token: currentSanityInfo.token,
    useCdn: false,
    ignoreBrowserTokenWarning: true
  });

  return sanity;
};
