import React, { useEffect } from 'react';
import PortfolioContent from '../PortfolioContentTemplate9';
// import { IconContext } from 'react-icons';
// import { MdFullscreenExit } from 'react-icons/md';
import Icons from '../../../containers/Icons';
import { isEmpty } from 'lodash';

const PortfolioContentParent = ({
  createSetCurrentContent,
  Position,
  images,
  videos,
  pdfs,
  companyConfig,
  chosenPortfolio,
  pages,
  nav,
  pageBackgroundImageUrl,
  isGuestMode,
  isPortfolioView,
  setTopic,
  guestPages,
  guestContentHelper,
  currentContentHelper,
  isAtEnd,
  currentLevel,
  chosenPageId,
  chosenContentPageId,
  menuHide,
  navHistory,
  setMenuHide,
  setContentPageId,
  setCurrentContentHelper,
  Topics,
  savedChosenContentPageId
}) => {
  //const [currentNavItem, setCurrentNavItem] = useState({});

  // useEffect(() => {
  //   setContentPageId(chosenPageId);
  //   // eslint-disable-next-line
  // }, [chosenPageId]);

  // automatically select first item
  //Add SetTopic
  useEffect(() => {
    let splashKeyId = `splashkey-${chosenPortfolio._id}`;
    if (chosenPageId !== 0 && chosenPageId !== splashKeyId) {
      // get current nav item
      let tempNavItem = {};

      // traverse nav history to find current nav
      navHistory.forEach((number, index) => {
        // first
        if (index === 0) {
          tempNavItem = nav.items[number];
        } else {
          tempNavItem = tempNavItem.children[number];
        }
      });
      if (!tempNavItem.referenceId) {
        setContentPageId(chosenContentPageId);
      } else {
        setContentPageId(tempNavItem.referenceId);
      }
    }
    if (chosenPageId === splashKeyId) {
      prepareSplash();
    }
    // eslint-disable-next-line
  }, [chosenPageId, pages, navHistory, chosenPortfolio]);

  // run everytime chosenSubPageId updates
  useEffect(() => {
    if (chosenContentPageId) {
      //check if clicked page was gallery with multiple items
      if (
        pages[chosenContentPageId]?.content &&
        pages[chosenContentPageId]?._type === 'gallery' &&
        pages[chosenContentPageId]?.content.length > 1 &&
        !pages[chosenContentPageId]?.StoryBookgallery?.SingleItem
      ) {
        // automatically set first item of gallery to current content viewed
        let obj = {
          type: 'gallery',
          index: Position,
          id: chosenContentPageId
        };
        createSetCurrentContent(obj);
      } else if (pages[chosenContentPageId]?.StoryBookgallery?.SingleItem) {
        let obj = {
          type: 'STgallery',
          index: Position,
          id: chosenContentPageId
        };
        createSetCurrentContent(obj);
      } else {
        // else do normal
        let obj = {
          type: 'pageSub',
          id: chosenContentPageId
        };
        createSetCurrentContent(obj);
      }
    } else {
      // show splash when chosenContentPageId is undefined
      //////////onsole.log('chosenContentPageId is undefined, show splash.....');
      prepareSplash();
    }
    // eslint-disable-next-line
  }, [
    chosenContentPageId,
    pages,
    guestContentHelper,
    isGuestMode,
    isPortfolioView
  ]);
  // prepare splash to display
  //Add SetTopic
  const prepareSplash = () => {
    let splashKeyId = `splashkey-${chosenPortfolio._id}`;
    // create nav specifically for splash
    // setCurrentNavItem({
    //   children: [],
    //   referenceField: 'pages',
    //   referenceId: splashKeyId,
    //   title: 'Splash',
    //   type: 'gallery',
    //   _key: 'splashnav'
    // });
    setContentPageId(splashKeyId);
    // check if guest
    if (isGuestMode && !isPortfolioView) {
      // find index
      let index = guestContentHelper.findIndex((x) => x.id === splashKeyId);
      //////onsole.log("Attempt load 1",guestContentHelper)
      //////onsole.log("Attempt load 2", guestContentHelper[index])
      //////onsole.log("Attempt load 3",guestContentHelper[index].id)
      setCurrentContentHelper(guestContentHelper[index].id);
    }
    if (Topics.length === 0 || Topics === undefined || Topics === null)
      setTopic(['SplashPage']);
  };

  // function used when user changes gallery index
  const prepareCurrentGalleryContent = (index) => {
    let obj = {
      type: 'gallery',
      index: index,
      id: chosenContentPageId
    };
    createSetCurrentContent(obj);
  };

  if (chosenContentPageId === 0) {
    return null;
  }

  const contentData = () => {
    let tabData = {};
    if (
      isGuestMode &&
      chosenContentPageId !== 0 &&
      !isEmpty(currentContentHelper) &&
      pages[chosenContentPageId]?._type === 'gallery' &&
      pages[chosenContentPageId]?._key === currentContentHelper.page_id &&
      !pages[chosenContentPageId]?.StoryBookgallery?.SingleItem &&
      currentContentHelper.is_gallery_item
    ) {
      //////////onsole.log('MODIFY FOR GALLERY CONTENT');
      // create obj to only display gallery item chosen by guest
      let obj =
        pages[chosenContentPageId]?.content[currentContentHelper.gallery_index];
      tabData = [{ ...obj }];
      //////////onsole.log(pages[chosenContentPageId]);
      //////////onsole.log('!!!!TAB DATA!!!! ', tabData);
      return tabData;
    } else if (
      isGuestMode &&
      chosenContentPageId !== 0 &&
      !isEmpty(currentContentHelper) &&
      pages[chosenContentPageId]?._type === 'gallery' &&
      pages[chosenContentPageId]?._key === currentContentHelper.page_id &&
      pages[chosenContentPageId]?.StoryBookgallery?.SingleItem &&
      currentContentHelper.is_gallery_item
    ) {
      let obj =
        pages[chosenContentPageId]?.StoryBookgallery?.STcontent[
          currentContentHelper.gallery_index
        ];
      tabData = [{ ...obj }];
    } else if (
      !isGuestMode &&
      pages[chosenContentPageId]?.StoryBookgallery?.SingleItem
    )
      return pages[chosenContentPageId]?.StoryBookgallery?.STcontent;
    //////////onsole.log('!!!!CHOSEN CONTENT PAGE ID ', chosenContentPageId);
    return pages[chosenContentPageId]?.content;
  };

  if (!chosenContentPageId) {
    // whenever we set chosenContentPageId to a falsy value (i.e. returning from terminus menu), save the current value in a savedChosenContentPageId. Then return here based on that
    // we also need to make sure that this returns null on first load, because both ChosenContentPageId and SavedChosenContentPageId will likely be set to 0 and cause an error. This can probably be done in the Portfolio Content call below
    // return <h1> CONFIRMED - REPLACE HERE </h1>;
    return (
      <div
        className="portfolio-content-parent"
        style={{
          backgroundImage: isAtEnd ? `url(${pageBackgroundImageUrl})` : ''
        }}
      >
        {!menuHide ? null : (
          <div className="exit-fullscreen-button">
            <Icons.FullscreenExit onClick={() => setMenuHide(false)} />
          </div>
        )}
        <div style={{ width: '100%' }} className="portfolio-content-container">
          {savedChosenContentPageId !== 0 &&
            pages[chosenContentPageId]?._type !== false && (
              <PortfolioContent
                contentType={pages[savedChosenContentPageId]._type}
                images={images}
                videos={videos}
                pdfs={pdfs}
                contents={pages[savedChosenContentPageId].content}
                page={pages[savedChosenContentPageId]}
                prepareCurrentGalleryContent={prepareCurrentGalleryContent}
              />
            )}
        </div>
      </div>
    );
  }

  return (
    <div
      className="portfolio-content-parent"
      style={{
        backgroundImage: isAtEnd ? `url(${pageBackgroundImageUrl})` : ''
      }}
    >
      {!menuHide ? null : (
        <div className="exit-fullscreen-button">
          <Icons.FullscreenExit onClick={() => setMenuHide(false)} />
        </div>
      )}
      <div style={{ width: '100%' }} className="portfolio-content-container">
        {chosenContentPageId !== 0 &&
          pages[chosenContentPageId]?._type !== false && (
            <PortfolioContent
              contentType={pages[chosenContentPageId]?._type}
              images={images}
              Position={Position}
              videos={videos}
              pdfs={pdfs}
              contents={contentData()}
              page={pages[chosenContentPageId]}
              prepareCurrentGalleryContent={prepareCurrentGalleryContent}
            />
          )}
      </div>
    </div>
  );
};
export default PortfolioContentParent;
