import { connect } from 'react-redux';
import ParallelSelect from './ParallelSelect.js';
import {
  setParallelSession,
  setShowParallelSelect,
  setParallelDateString,
  setEfficiencyParallelToggle
} from '../../../actions';

const mapStateToProps = (state) => ({
  showParallelSelect: state.DashboardReducer.showParallelSelect,
  sessionList: state.DashboardReducer.sessionList,
  parallelDateString: state.DashboardReducer.parallelDateString,
  efficiencyParallelToggle: state.DashboardReducer.efficiencyParallelToggle,
  Size: state.portfolioReducer.Size
});

const mapDispatchToProps = (dispatch) => {
  return {
    setParallelDateString: (string) => dispatch(setParallelDateString(string)),
    setParallelSession: (visit) => dispatch(setParallelSession(visit)),
    setShowParallelSelect: (bool) => dispatch(setShowParallelSelect(bool)),
    setEfficiencyParallelToggle: (toggle) =>
      dispatch(setEfficiencyParallelToggle(toggle))
  };
};

const ParallelSelectContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ParallelSelect);

export default ParallelSelectContainer;
