import React, { useEffect } from 'react';
import styled from 'styled-components';
import LoginBox from '../../components/_Login/LoginBox';
import { isLoaded, isEmpty } from 'react-redux-firebase';
import LoginContext from './LoginContext';

const StyledLoginPage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoginPage = ({
  auth,
  profile,
  userInfo,
  history,
  updateSessionKey,
  firestoreRequest,
  firestoreSuccess,
  firestoreFailure,
  sanityContent,
  setLoginInputSelected,
  setLoginInputSelectedCount,
  loginInputSelected,
  loginInputSelectedCount,
  Size
}) => {
  // helper funcs to use in child components
  const helper = {
    updateSessionKey,
    firestoreRequest,
    firestoreSuccess,
    firestoreFailure
  };

  // Check auth
  useEffect(() => {
    // If auth isLoaded and not empty then push user to /engage
    if (
      isLoaded(auth) &&
      !isEmpty(auth) &&
      !auth.isAnonymous &&
      userInfo &&
      userInfo.length > 0 &&
      userInfo[0].role
    ) {
      let role = userInfo[0].role;
      if (role === 'admin' || role === 'manager') {
        //history.push('/admin');
        history.push('/engage');
      } else {
        history.push('/engage');
      }
    }
  }, [auth, userInfo, history]);

  return (
    <StyledLoginPage>
      <LoginContext.Provider value={helper}>
        <LoginBox
          profile={profile}
          updateSessionKey={updateSessionKey}
          setLoginInputSelected={setLoginInputSelected}
          loginInputSelected={loginInputSelected}
          loginInputSelectedCount={loginInputSelectedCount}
          setLoginInputSelectedCount={setLoginInputSelectedCount}
          Size={Size}
        />
      </LoginContext.Provider>
    </StyledLoginPage>
  );
};

export default LoginPage;
