import { connect } from 'react-redux';
import Menu2 from './Menu2';
import {
  resetPortfolioMode,
  setChosenPageId,
  setChosenMainPageButtonId,
  setCurrentLevel,
  setIsAtEnd,
  setMenuHide,
  setNavHistory,
  setCurrentContentHelper,
  setContentPageId,
  setSavedContentPageId,
  SetTopic
} from '../../../actions';

const mapStateToProps = (state) => ({
  isGuestMode: state.guestReducer.isGuestMode,
  isPortfolioView: state.guestReducer.isPortfolioView,
  guestPages: state.guestReducer.guestPages,
  guestContentHelper: state.guestReducer.guestContentHelper,
  companyConfig: state.sanityContentReducer.companyConfig,
  images: state.sanityContentReducer.images,
  chosenPortfolio: state.portfolioReducer.chosenPortfolio,
  pages: state.portfolioReducer.pages,
  nav: state.portfolioReducer.nav,
  chosenContentPageId: state.pageMenuReducer.chosenContentPageId,
  pageMenuReducer: state.pageMenuReducer,
  Topic: state.pageMenuReducer.Topic,
  chosenPageId: state.pageMenuReducer.chosenPageId
});

const mapDispatchToProps = (dispatch) => {
  return {
    resetPortfolioMode: () => dispatch(resetPortfolioMode()),
    setChosenPageId: (id) => dispatch(setChosenPageId(id)),
    setChosenMainPageButtonId: (id) => dispatch(setChosenMainPageButtonId(id)),
    setCurrentLevel: (number) => dispatch(setCurrentLevel(number)),
    setIsAtEnd: (bool) => dispatch(setIsAtEnd(bool)),
    setMenuHide: (bool) => dispatch(setMenuHide(bool)),
    setCurrentContentHelper: (id) => dispatch(setCurrentContentHelper(id)),
    setNavHistory: (array) => dispatch(setNavHistory(array)),
    setContentPageId: (id) => dispatch(setContentPageId(id)),
    setSavedContentPageId: (id) => dispatch(setSavedContentPageId(id)),
    setTopic: (Topic) => dispatch(SetTopic(Topic))
  };
};

const Menu2Container = connect(mapStateToProps, mapDispatchToProps)(Menu2);

export default Menu2Container;
