import { connect } from 'react-redux';
import Completion from './Completion.js';
import {
  SetSessionMasterCollection,
  SetFilteredSession,
  SetCompletionAdherence,
  setDefaultVisit
} from '../../../actions';

const mapStateToProps = (state) => ({
  Firestore: state.firestore,
  Dash: state.DashboardReducer,
  userInfo: state.firestore.ordered.users,
  SessionMasterCollection: state.DashboardReducer.SessionMasterCollection,
  SessionFilteredCollection: state.DashboardReducer.SessionFilteredCollection,
  portfolios: state.sanityContentReducer.portfolios,
  completionAdherence: state.DashboardReducer.completionAdherence,
  aggregateSelected: state.DashboardReducer.aggregateSelected,
  selectedPortfolioId: state.DashboardReducer.selectedPortfolioId,
  defaultVisit: state.DashboardReducer.defaultVisit,
  efficiencyAggregateSelected:
    state.DashboardReducer.efficiencyAggregateSelected,
  selectedUser: state.DashboardReducer.selectedUser,
  Size: state.portfolioReducer.Size
});

const mapDispatchToProps = (dispatch) => {
  return {
    SetSessionMasterCollection: (UI, firestore) =>
      dispatch(SetSessionMasterCollection(UI, firestore)),
    SetFilteredSession: (UI) => dispatch(SetFilteredSession(UI)),
    setCompletionAdherence: (num) => dispatch(SetCompletionAdherence(num)),
    setDefaultVisit: (visit) => dispatch(setDefaultVisit(visit))
  };
};

const CompletionContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Completion);

export default CompletionContainer;
