/**
 * Button with custom styling
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
//Ping Font Family Complication Probable
const StyledDarkenButton = styled.button.attrs((props) => ({
  className: props.additionalClassName
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.backgroundColor};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  cursor: pointer;
  border: none;

  :disabled {
    filter: brightness(calc(70%));
    :hover {
      filter: brightness(70%);
    }
    cursor: default;
  }
  :hover {
    filter: brightness(80%);
  }
`;

const StyledEnlargedButton = styled.button.attrs((props) => ({
  className: props.additionalClassName
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.backgroundColor};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  cursor: pointer;
  border: none;
  transition: transform 0.3s ease-out;

  :disabled {
    //filter: brightness(calc(70%));
  }
  :hover {
    //filter: brightness(80%);
    transform: scale(1.2);
    //height:100px
  }
`;

const StyledButton = styled.button.attrs((props) => ({
  className: props.additionalClassName
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.backgroundColor};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  cursor: pointer;
  border: none;
  filter: ${(props) =>
    props.pageActive === true
      ? 'brightness(130%) !important'
      : 'brightness(100%)'};

  :disabled {
    //filter: brightness(calc(70%));
    background-color: rgba(195, 195, 195, 0.5);
  }

  @media (max-width: 1367px) {
    filter: ${(props) =>
      props.isSubActive === true && props.theme === 'theme4'
        ? 'brightness(1.3) !important'
        : 'brightness(1.0)'};
    box-shadow: ${(props) =>
      props.isSubActive === true && props.theme === 'theme4'
        ? '3px 3px 5px -3px #3b3b3b'
        : 'none'};
  }
`;

StyledButton.displayName = 'StyledButton';

const StyledButtonText = styled.div`
  color: ${(props) => props.color};
  font-size: ${(props) => props.fontSize};
  display: block;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  // @media (max-width: 1367px) {
  //   display: ${(props) =>
    props.theme === 'theme4' ? 'grid' : 'flex'} !important;
  //   grid-template-rows: ${(props) =>
    (props.subtitle === '' ||
      props.subtitle === undefined ||
      props.subtitle === null) &&
    props.theme === 'theme4'
      ? '1fr'
      : '1fr 1fr'} !important;
  // }

  @media (min-height: 1025px) and (orientation: portrait) {
    display: ${(props) =>
      props.theme === 'theme8' ? 'flex' : 'block'} !important;
  }

  @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
    display: ${(props) =>
      props.theme === 'theme8' ? 'flex' : 'block'} !important;
  }

  p {
    @media (max-width: 1367px) {
      -webkit-line-clamp: ${(props) =>
        props.theme === 'theme4' &&
        (props.subtitle === '' ||
          props.subtitle === undefined ||
          props.subtitle === null)
          ? '4'
          : '2'} !important;
    }

    // @media (min-height: 1025px) and (orientation: portrait) {
    //   font-size: ${(props) =>
      props.theme === 'theme8' ? '28px' : props.fontSize} !important;
    // }
  }

  .title {
    @media (max-width: 1367px) {
      font-weight: ${(props) => (props.theme === 'theme4' ? 600 : 400)};
    }
  }
`;
//FLAG FONT FAMILY

const Button = ({
  text,
  subtitle,
  fontSize,
  color,
  width,
  height,
  backgroundColor,
  onClick,
  disabled,
  type,
  darken,
  additionalClassName,
  enlarge,
  fontFamily,
  fontFamily2,
  style,
  chosenPortfolio,
  isSubActive,
  pageActive
}) => {
  //onsole.log("ACTIVE", pageActive);
  if (!darken && !enlarge) {
    // //onsole.log("NORMAL BUTTON")
    return (
      <StyledButton
        additionalClassName={additionalClassName}
        onClick={onClick}
        backgroundColor={backgroundColor}
        textColor={chosenPortfolio?.text_color}
        theme={chosenPortfolio?.theme}
        width={width}
        height={height}
        type={type}
        disabled={disabled}
        isSubActive={isSubActive}
        style={{ ...style }}
        pageActive={pageActive}
      >
        <StyledButtonText
          className="title-container terminus-button-div"
          color={color}
          fontSize={fontSize}
          subtitle={subtitle}
          theme={chosenPortfolio?.theme}
        >
          <p
            className="title term-first"
            style={{
              userSelect: 'none',
              marginBlockStart: '0px',
              marginBlockEnd: '0px',
              textShadow:
                chosenPortfolio?.theme === 'theme2' &&
                chosenPortfolio?.accent_color
                  ? `2px 2px 2px ${chosenPortfolio.accent_color}`
                  : null,
              fontFamily: fontFamily
            }}
          >
            {text}
          </p>
          <p
            className="term-second"
            style={{
              userSelect: 'none',
              fontSize: `calc(${fontSize} - 10%)`,
              marginBlockEnd: '0px',
              marginBlockStart: '0px',
              textShadow:
                chosenPortfolio?.theme === 'theme2' &&
                chosenPortfolio?.accent_color
                  ? `2px 2px 2px ${chosenPortfolio.accent_color}`
                  : null,
              fontFamily: fontFamily2
            }}
          >
            {subtitle}
          </p>
        </StyledButtonText>
      </StyledButton>
    );
  } else if (enlarge) {
    // //onsole.log("LARGE BUTTON")
    return (
      <StyledEnlargedButton
        additionalClassName={additionalClassName}
        onClick={onClick}
        backgroundColor={backgroundColor}
        width={width}
        height={height}
        type={type}
        disabled={disabled}
        style={{ ...style }}
        pageActive={pageActive}
      >
        <StyledButtonText
          className="title-container"
          color={color}
          fontSize={fontSize}
          subtitle={subtitle}
          theme={chosenPortfolio?.theme}
        >
          <p
            className="title"
            style={{
              userSelect: 'none',
              marginBlockStart: '0px',
              marginBlockEnd: '0px',
              textShadow:
                chosenPortfolio?.theme === 'theme2' &&
                chosenPortfolio?.accent_color
                  ? `2px 2px 2px ${chosenPortfolio.accent_color}`
                  : null
            }}
          >
            {text}
          </p>
          <p
            style={{
              userSelect: 'none',
              fontSize: `calc(${fontSize} - 10%)`,
              marginBlockEnd: '0px',
              marginBlockStart: '0px',
              textShadow:
                chosenPortfolio?.theme === 'theme2' &&
                chosenPortfolio?.accent_color
                  ? `2px 2px 2px ${chosenPortfolio.accent_color}`
                  : null
            }}
          >
            {subtitle}
          </p>
        </StyledButtonText>
      </StyledEnlargedButton>
    );
  } else {
    // //onsole.log("DARK BUTTON")
    return (
      <StyledDarkenButton
        additionalClassName={additionalClassName}
        onClick={onClick}
        backgroundColor={backgroundColor}
        width={width}
        height={height}
        type={type}
        disabled={disabled}
        style={{ ...style }}
        pageActive={pageActive}
      >
        <StyledButtonText
          className="title-container"
          color={color}
          fontSize={fontSize}
          subtitle={subtitle}
          theme={chosenPortfolio?.theme}
        >
          <p
            className="title"
            style={{
              userSelect: 'none',
              marginBlockStart: '0px',
              marginBlockEnd: '0px',
              textShadow:
                chosenPortfolio?.theme === 'theme2' &&
                chosenPortfolio?.accent_color
                  ? `2px 2px 2px ${chosenPortfolio.accent_color}`
                  : null
            }}
          >
            {text}
          </p>
          <p
            style={{
              userSelect: 'none',
              fontSize: `calc(${fontSize} - 10%)`,
              marginBlockEnd: '0px',
              marginBlockStart: '0px',
              textShadow:
                chosenPortfolio?.theme === 'theme2' &&
                chosenPortfolio?.accent_color
                  ? `2px 2px 2px ${chosenPortfolio.accent_color}`
                  : null
            }}
          >
            {subtitle}
          </p>
        </StyledButtonText>
      </StyledDarkenButton>
    );
  }
};

Button.defaultProps = {
  type: 'button',
  disabled: false,
  backgroundColor: '#bfbfbf',
  width: '300px',
  height: '42px',
  fontSize: '18px',
  color: 'white',
  additionalClassName: '',
  isSubActive: false,
  pageActive: false
};

Button.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  subtitle: PropTypes.string,
  fontSize: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string,
  backgroundColor: PropTypes.string,
  additionalClassName: PropTypes.string,
  isSubActive: PropTypes.bool,
  pageActive: PropTypes.bool
};

export default Button;
