// converts from seconds, returns text
export const convertSecondsToText = (y, doFullText) => {
  let secondsText = ' sec';
  let minutesText = ' min';
  let hoursText = ' hr';

  if (doFullText) {
    secondsText = ' second';
    minutesText = ' minute';
    hoursText = ' hour';
  }
  if (60 > y) {
    // Note this needs parseFloat and toFixed because Victory chart interpolates all of data when animating, causing decimals to flicker
    // More info https://github.com/FormidableLabs/victory-native/issues/563

    let s = y > 1 ? 's' : '';

    return parseFloat(y.toFixed(2)) + secondsText + s;
  }
  // calculate hrs min sec
  let hours = Math.floor(y / 3600);
  let minutes = Math.floor((y % 3600) / 60);
  let seconds = Math.floor(y % 60);

  // prepare final text
  let finalHoursText =
    hours >= 1 ? `${hours}${hoursText}${hours > 1 ? 's' : ''}` : '';
  let finalMinText = `${minutes}${minutesText}${minutes > 1 ? 's' : ''}`;
  let finalSecondsText =
    seconds === 0 ? '' : `${seconds}${secondsText}${seconds > 1 ? 's' : ''}`;
  return `${finalHoursText} ${finalMinText} ${finalSecondsText}`;
};
