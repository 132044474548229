/* takes an array of page visits; give it a list of objects with keys x, y, name - derived from the totalTimeSpendDatas property within the object created when a 
session.page_visited is fed into createBarData */
// e.g. [{x: 0, y: 0, name: 'example0'}, {x: 1, y: 1, name: 'example1'}, {x: 2, y: 2, name: 'example2'}] - though you would expect to see duplicate names as the list should contain many sessions

const filterStandardDeviation = (listOfAllPageVisits) => {
  let checkedPages = [];
  let pagesFilteredBySD = [];

  for (let page of listOfAllPageVisits) {
    if (checkedPages.includes(page.name)) {
      continue;
    }
    let currentPageList = listOfAllPageVisits.filter(
      (currentPage) => currentPage.name === page.name
    );
    checkedPages.push(page.name);
    // //////onsole.log("ONE PAGE, ALL VISITS LISTED", currentPageList);
    let totalYValue = 0;
    for (let currentPage of currentPageList) {
      totalYValue += currentPage.y;
    }
    const averageValue = totalYValue / currentPageList.length;
    // //////onsole.log("AVERAGE PAGE TIME FOR ALL VISITS OF THIS PAGE: ", averageValue);
    let variancePoints = [];
    for (let currentPage of currentPageList) {
      let variant = currentPage.y - averageValue;
      let variantSquared = Math.pow(variant, 2);
      variancePoints.push(variantSquared);
    }
    let varianceUndivided = 0;
    for (let number of variancePoints) {
      varianceUndivided += number;
    }
    let varianceWhole = varianceUndivided / currentPageList.length;
    let standardDeviation = Math.round(Math.sqrt(varianceWhole));
    // //////onsole.log(`STANDARD DEVIATION OF PAGE ${page.name}: `, standardDeviation);
    let filteredSDPageList = currentPageList.filter((currentPage) => {
      let upperBound = averageValue + standardDeviation * 2;
      let lowerBound = averageValue - standardDeviation * 2;
      return currentPage.y <= upperBound && currentPage.y >= lowerBound;
    });
    // //////onsole.log("ONE PAGE, ALL VISITS LISTED, FILTERED BY SD", filteredSDPageList);
    for (let obj of filteredSDPageList) {
      pagesFilteredBySD.push(obj);
    }
  }
  // //////onsole.log("ALL PAGES FILTERED BY STANDARD DEVIATION", pagesFilteredBySD);
  return pagesFilteredBySD;
};

export default filterStandardDeviation;
