import React, { useEffect, useState } from 'react'; //useState
import styled from 'styled-components';
import LI from './NewEmailListItem/NELI';
import * as Scale from '../../../../../utils/Scalars';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';

const ListItems = styled.div`
    display: grid;
    grid-template-rows: 1fr 4.375fr 0.625fr;
    height: 100%;
    border-radius: 8px;
    position: relative;

    .sort-box {
        position: absolute;
        top: 0;
        left: 0;
        height: 35px;
        width: 35px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 1380px) {
            height: 25px;
            width: 25px;
        }
    }

    .card-header {
        grid-row: 1 / span 1;
        background-color: #2b2b2b;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        color: white;
        
        p {
            margin-block-start: 0px;
            margin-block-end: 0px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 600;
            font-size: 0.9em;
            color: white;
            font-family: 'Open Sans',sans-serif;
        }
    }

    .card-body {
        grid-row: 2 / span 1;
        overflow-y: auto;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        background-color: #eeeeee;


        // hide scrollbar
        // ::-webkit-scrollbar {
        //     display: none;
        // }
        // -ms-overflow-style: none; /* IE and Edge */
        // scrollbar-width: none; /* Firefox */

        .line-item {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 14.28%;
            min-height: 14.28%
            width: 100%;
            overflow: hidden;

            p {
                margin-block-start: 0px;
                margin-block-end: 0px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis; 
                color: #3b3b3b;
                font-weight: 600;
                font-family: 'Newsreader', serif;
            }
        }

        .no-items {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 14.28%;
            min-height: 14.28%
            width: 100%;
            overflow: hidden;

            p {
                margin-block-start: 0px;
                margin-block-end: 0px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis; 
                color: #3b3b3b;
                font-weight: 600;
                font-family: 'Newsreader', serif;
                font-size: 1.3em;
            }
        }
    }

    .card-download {
        grid-row: 3 / span 1;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #dedede;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        
        div {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            border-top: 1px solid #808080;

            p {
                margin-block-start: 0px;
                margin-block-end: 0px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-weight: 600;
                cursor: pointer;
            }
        }
    }
`;

const Emails = ({ SendOpen, barHeight, EmailsEnd, Size }) => {
  //const [items, setItems] = useState([]);
  const [sortOrder, setSortOrder] = useState('HighLow');

  const downloadTxtFile = () => {
    let string = 'Emails Sign-Up,Name (if available),New Lead,Opens\n';
    EmailsEnd.forEach((ele) => {
      string +=
        ele.Email +
        ',' +
        (ele.Name ? `${ele.Name},` : ',') +
        (ele.New ? 'New Lead,' : ',') +
        ele.Opens +
        ',\n';
    }); ////////onsole.log("DownEle",test) //////onsole.log("DownEle",ele,ele.Email)
    const element = document.createElement('a');
    const file = new Blob([string], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = 'Emails.csv';
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const sortHighToLow = (a, b) => {
    return b.Opens - a.Opens || a.Email - b.Email;
  };

  const sortLowToHigh = (a, b) => {
    return a.Opens - b.Opens || a.Email - b.Email;
  };

  //check if string is null or whitespace
  // function isEmptyOrSpaces(str) {
  //     if(typeof(str) !== typeof(""))
  //     return null;
  //     return str === null || str.match(/^\s*$/) !== null;
  // }

  // useEffect(() => {
  //     if (SendOpen) {
  //         setItems(SendOpen.Addresses.filter((ele) => {return !isEmptyOrSpaces(ele)}));
  //     }
  // }, [SendOpen]);

  useEffect(() => {
    ////////onsole.log("BAR HEIGHT", barHeight);
  }, [barHeight]);

  let emailListMapping;

  if (EmailsEnd.length > 0) {
    emailListMapping = EmailsEnd.sort((a, b) => {
      return sortOrder === 'HighLow'
        ? sortHighToLow(a, b)
        : sortLowToHigh(a, b);
    }).map((ele, index) => {
      return <LI key={ele.Email} EE={ele} index={index} />;
    });
  } else {
    emailListMapping = (
      <div className="no-items">
        <p>No email sign-ups for this period.</p>
      </div>
    );
  }

  ////////onsole.log("SEND OPEN", SendOpen);

  return (
    <ListItems>
      <div className="sort-box">
        {sortOrder === 'HighLow' ? (
          <ArrowUpwardRoundedIcon
            style={{
              color: 'white',
              fontSize: `${Scale.Height(Size, 28)}px`,
              cursor: 'pointer'
            }}
            onClick={() => setSortOrder('LowHigh')}
          />
        ) : (
          <ArrowDownwardRoundedIcon
            style={{
              color: 'white',
              fontSize: `${Scale.Height(Size, 28)}px`,
              cursor: 'pointer'
            }}
            onClick={() => setSortOrder('HighLow')}
          />
        )}
      </div>
      <div className="card-header">
        {/* <h3>Email Sign-ups</h3> */}
        <div
          style={{
            width: '100%',
            display: 'grid',
            columnGap: '3%',
            gridTemplateColumns: '58% 19.5% 12.5%',
            overflow: 'hidden'
          }}
        >
          <div
            style={{
              fontFamily: `'Open Sans', serif`,
              fontSize: '1.1em',
              gridColumn: '1/1',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <p
              style={{
                gridColumn: '1/1',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontSize: '1.1em'
              }}
            >
              Email Sign-Ups
            </p>
          </div>
          <div
            style={{
              fontFamily: `'Open Sans', serif`,
              fontSize: '1.1em',
              gridColumn: '2/2',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <p
              style={{
                gridColumn: '2/2',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                margin: 'auto'
              }}
            >
              New Lead
            </p>
          </div>
          <div
            style={{
              fontFamily: `'Open Sans', serif`,
              fontSize: '1.1em',
              gridColumn: '3/3',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <p
              style={{
                gridColumn: '3/3',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              Opens
            </p>
          </div>
        </div>
      </div>
      <div
        className="card-body"
        style={{
          justifyContent: EmailsEnd.length > 0 ? 'flex-start' : 'center',
          display: EmailsEnd.length > 0 ? 'block' : 'flex',
          height: '100%'
        }}
      >
        {emailListMapping}
      </div>
      <div className="card-download">
        <div>
          <p
            style={{
              color: EmailsEnd.length > 0 ? 'rgb(5, 106, 175)' : '#3b3b3b',
              cursor: EmailsEnd.length > 0 ? 'pointer' : 'default'
            }}
            onClick={() => downloadTxtFile()}
          >
            {EmailsEnd.length > 0 ? 'Download' : 'No Items to Download'}
          </p>
        </div>
      </div>
    </ListItems>
  );
};
export default Emails;
