import {
  SET_CHOSEN_PAGE_ID,
  SET_CHOSEN_MAIN_PAGE_BUTTON,
  SET_CONTENT_PAGE_ID,
  SET_CURRENT_LEVEL,
  SET_IS_AT_END,
  SET_MENU_HIDE,
  SET_NAV_HISTORY,
  END_PORTFOLIO,
  RESET_PORTFOLIO_INFO_MidSession,
  RESET_PORTFOLIO_INFO,
  SET_SAVED_CONTENT_PAGE_ID,
  SET_HAS_CURR,
  SET_HAS_PREV,
  KM_RESET,
  SET_TOPIC,
  SET_MEGAMENU_LEVEL,
  SET_TERM_WITH_CHILD,
  SET_SHOW_TERM_CHILD
} from '../constants/actionTypes';

const initialState = {
  chosenPageId: 0,
  chosenMainPageButtonId: 0,
  chosenContentPageId: 0,
  currentLevel: 0,
  navHistory: [],
  isAtEnd: false,
  menuHide: false,
  savedChosenContentPageId: 0,
  hasCurr: false,
  hasPrev: false,
  Topic: [],
  megamenuLevel: 0,
  termWithChild: false,
  showTermChild: false
};
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CHOSEN_PAGE_ID:
      return {
        ...state,
        chosenPageId: action.id
      };
    case SET_CHOSEN_MAIN_PAGE_BUTTON:
      return {
        ...state,
        chosenMainPageButtonId: action.id
      };
    case SET_CONTENT_PAGE_ID:
      return {
        ...state,
        chosenContentPageId: action.id
      };
    case SET_SAVED_CONTENT_PAGE_ID:
      return {
        ...state,
        savedChosenContentPageId: action.id
      };
    case SET_CURRENT_LEVEL:
      return {
        ...state,
        currentLevel: action.number
      };
    case SET_IS_AT_END:
      return {
        ...state,
        isAtEnd: action.bool
      };
    case SET_MENU_HIDE:
      return {
        ...state,
        menuHide: action.bool
      };
    case SET_NAV_HISTORY:
      return {
        ...state,
        navHistory: action.array
      };
    case SET_HAS_CURR:
      return {
        ...state,
        hasCurr: action.bool
      };
    case SET_HAS_PREV:
      return {
        ...state,
        hasPrev: action.bool
      };
    case SET_MEGAMENU_LEVEL:
      return {
        ...state,
        megamenuLevel: action.data
      };
    case SET_TERM_WITH_CHILD:
      return {
        ...state,
        termWithChild: action.data
      };
    case SET_SHOW_TERM_CHILD:
      return {
        ...state,
        showTermChild: action.data
      };
    case SET_TOPIC:
      //////onsole.log("TOPIC",action.string)
      return {
        ...state,
        Topic: action.string
      };
    case END_PORTFOLIO:
    case RESET_PORTFOLIO_INFO:
    case RESET_PORTFOLIO_INFO_MidSession:
    case KM_RESET:
      return initialState;

    default:
      return state;
  }
};
