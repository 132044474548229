import { connect } from 'react-redux';
import CustomerInfoForm from './CustomerInfoForm';

const mapStateToProps = (state) => ({
  companyConfig: state.sanityContentReducer.companyConfig,
  customerInfo: state.portfolioReducer.customerInfo,
  images: state.sanityContentReducer.images,
  chosenPortfolio: state.portfolioReducer.chosenPortfolio,
  Size: state.portfolioReducer.Size
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

const CustomerInfoFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerInfoForm);

export default CustomerInfoFormContainer;
