/* eslint-disable no-prototype-builtins */
export const createBarData = (pageVisited) => {
  let uniquePageLabels = [];
  let uniqueLabelHelper = [];

  // Times viewed per page variables
  //--------------------------------
  let viewedDatas = [];
  let viewedDatasTrimmed = [];
  // Obj to keep track of how many times viewed, setting page title as key and view count as value
  let viewedCountObj = {};

  // Times spend per page visit in minutes variables
  //--------------------------------
  // return title in the order of pageVisited
  let timeSpentLabels = pageVisited
    .filter((obj) => !obj.is_end)
    .map((obj) => obj.title.replace(/\s/g, '\n'));
  let prevSeconds = 0;

  // Total times spend per page visit in minutes variables
  //--------------------------------
  let totalTimeSpendDatas = [];
  // Obj to keep track of total time spent on each page in seconds
  let totalTimeSpentObj = {};
  // keep track of previous id
  let prevId = '';
  // keep track of prev name
  let prevTitle = '';

  // duration per page visit, specifically for victory format
  let victoryTimeSpent = [];

  // traverse pageVisited array
  pageVisited.some((obj, index) => {
    let tempId = obj.id;
    // if gallery item append tempId
    if (obj.is_gallery_item) {
      tempId += `-gallery-${obj.gallery_index}`;
    }

    if (index !== 0) {
      let seconds = obj.time_visited.seconds - prevSeconds;

      let time = seconds;
      // divide by 60 to get minutes if bigger than 60
      //if(time >= 60) {
      //  time = time / 60;
      // }

      if (time === 0) {
        time = 0.01; // give at least 1 second
      }

      // add duration for this page visit
      victoryTimeSpent.push({
        x: index,
        y: parseFloat(time),
        name: prevTitle.replace(/\s/g, '\n')
      });

      // Add total times spend per page
      let totalSeconds = (parseFloat(totalTimeSpentObj[prevId]) || 0) + time;
      totalTimeSpentObj[prevId] = parseFloat(totalSeconds);
    }

    // check if end is true
    if (obj.is_end) {
      // break, no need to apply to viewedCountObj, uniqueLabelHelper anymore
      return true;
    }

    prevSeconds = obj.time_visited.seconds;
    prevId = tempId;
    prevTitle = obj.title;

    // Check if key exists, if not put in label. This will keep labels unique with no duplicates
    if (!viewedCountObj.hasOwnProperty(tempId)) {
      // push page title to label
      uniqueLabelHelper.push({ title: obj.title, id: tempId });
    }

    // keep track of count of visit for each page
    viewedCountObj[tempId] = (viewedCountObj[tempId] || 0) + 1;

    // keep running
    return false;
  });

  // create array of title string
  uniquePageLabels = uniqueLabelHelper.map((obj) => {
    return obj.title.replace(/\s/g, '\n');
  });

  // create array of view count associated to label
  viewedDatas = uniqueLabelHelper.map((obj, index) => {
    return {
      x: index + 1,
      y: viewedCountObj[obj.id],
      name: obj.title.replace(/\s/g, '\n')
    };
  });

  // same as above, but no new lines characters added to titles
  viewedDatasTrimmed = uniqueLabelHelper.map((obj, index) => {
    return {
      x: index + 1,
      y: viewedCountObj[obj.id],
      name: obj.title
    };
  });

  // create array of total time spend associated to label
  totalTimeSpendDatas = uniqueLabelHelper.map((obj, index) => {
    return {
      x: index + 1,
      y: totalTimeSpentObj[obj.id] ? totalTimeSpentObj[obj.id] : 0,
      name: obj.title.replace(/\s/g, '\n')
    };
  });

  return {
    uniquePageLabels: uniquePageLabels,
    timeSpentLabels: timeSpentLabels, //page Visit Labels
    viewedDatas: viewedDatas, // How many clicks to a page
    viewedDatasTrimmed: viewedDatasTrimmed, // How many clicks to a page with no new lines, for purpose of completion page
    victoryTimeSpent: victoryTimeSpent, // duration per page visit
    totalTimeSpendDatas: totalTimeSpendDatas // summation of duration
  };
};

export const createBriefcaseData = (data) => {
  // store id as key and count as value
  let pageSentCountObj = {};
  let uniquePageNames = [];
  let totalPageSentRow = [];
  let cartSentRow = [];

  let uniqueIndex = 0;
  data.forEach((obj, index) => {
    let parentId = uniqueIndex;
    // //////////onsole.log("DATE1:" );
    // //////////onsole.log("DATE2:", Date(obj.time_sent.seconds).toString());
    let date = Date(obj.time_sent.seconds);
    // //////////onsole.log("DATE3: ",date)
    // push cart info
    cartSentRow.push({
      index: index + 1,
      id: uniqueIndex,
      date: date.toString()
    });
    // traverse pages
    obj.pages.forEach((pages) => {
      uniqueIndex++;
      // add pages
      cartSentRow.push({
        title: pages.title,
        id: uniqueIndex,
        parentId: parentId
      });
      uniqueIndex++;

      // Check if key exists, if not put in label. This will keep labels unique with no duplicates
      if (!pageSentCountObj.hasOwnProperty(pages.id)) {
        // push page title
        uniquePageNames.push({ title: pages.title, id: pages.id });
      }

      // keep track of count of sent for each page
      pageSentCountObj[pages.id] = (pageSentCountObj[pages.id] || 0) + 1;
    });
  });

  // create array of page sent count associated to label
  totalPageSentRow = uniquePageNames.map((obj) => {
    return {
      title: obj.title,
      count: pageSentCountObj[obj.id]
    };
  });

  return { totalPageSentRow: totalPageSentRow, cartSentRow: cartSentRow };
};

// converts min or seconds used for label on top of bar
export const convertMinSecYlabel = (y) => {
  if (60 > y) {
    // Note this needs parseFloat and toFixed because Victory chart interpolates all of data when animating, causing decimals to flicker
    // More info https://github.com/FormidableLabs/victory-native/issues/563
    return parseFloat(y.toFixed(2)) + 'sec';
  }
  // calculate hrs min sec
  let hours = Math.floor(y / 3600);
  let minutes = Math.floor((y % 3600) / 60);
  let seconds = Math.floor(y % 60);

  // prepare text
  let hoursText = hours >= 1 ? `${hours}hrs` : '';
  let minText = `${minutes}min`;
  let secondsText = seconds === 0 ? '' : `${seconds}sec`;
  return `${hoursText}${minText}${secondsText}`;
};
