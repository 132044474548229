import React, { useEffect, useState, useCallback } from 'react';
//import MidLevelMenu from '../../components/_DashboardRevamp/MidLevel/';
import DashboardBody from '../../components/_DashboardRevamp/Body/';
import Header from '../../components/_DashboardRevamp/_Header';
import Header2 from '../../components/_DashboardRevamp/_Header2';
import { useFirestore } from 'react-redux-firebase';
import LoadingPage from '../LoadingPage';
import { DateRangeEnum } from '../../reducers/DashboardRevampReducer';

// Wait till auth and sanityContent is loaded and ready
const IsReady = ({ IsReady, children }) => {
  //const [Start] = useState(Date.now())
  const [reset, setReset] = useState(false);

  //const[Now,SetNow] = useState(Date.now())
  //const auth = useSelector((state) => state.firebase.auth);
  // Show loading
  //setInterval(()=>{SetNow(Date.now());},100);
  // let isGuest = isLoaded(auth) && auth.isAnonymous;
  // let sanityReady = isLoaded(auth) && sanityContentStatus === 'ready' && Date.now() > Start+2000;
  // if((!sanityReady || !isLoaded(auth)) && !isGuest)
  if (!IsReady())
    setTimeout(() => {
      setReset(!reset);
    }, 2100);
  //if auth is loaded but sanity is undefined then kick

  if (!IsReady()) return <LoadingPage />;

  return children;
};

const Dashboard = ({
  Dash,
  setSessionMasterCollection,
  setGuestPassMasterCollection,
  SetGuestpassSessionMasterCollection,
  setUsersPool,
  setSelectedUser,
  SetMasterGuestPassSessionList,
  userInfo,
  SLV,
  SetNotificationsCount,
  SetNL,
  SetPrePNL,
  SetPPNL
}) => {
  //const firebase = useFirebase();
  const firestore = useFirestore();
  //const history = useHistory();

  const [gp, setGP] = useState(false);
  useEffect(() => {
    if (gp === false) SLV(undefined);
  }, [gp, SLV]);

  // set Session Master Collection
  useEffect(() => {
    if (
      Dash.SessionMasterCollection === null ||
      Dash.SessionMasterCollection === undefined
    ) {
      return;
    }

    if (Dash.SessionMasterCollection.length === 0) {
      setSessionMasterCollection(userInfo, firestore);
    }
  }, [
    Dash.SessionMasterCollection,
    setSessionMasterCollection,
    firestore,
    userInfo
  ]);

  // set GuestPass Master Collection
  useEffect(() => {
    if (Dash.GuestpassMasterCollection === null) {
      return;
    }

    if (Dash.GuestpassMasterCollection.length === 0) {
      setGuestPassMasterCollection(userInfo, firestore);
    }
  }, [
    Dash.GuestpassMasterCollection,
    setGuestPassMasterCollection,
    firestore,
    userInfo
  ]);

  //set user pool for use in user select
  useEffect(() => {
    if (Dash.UserPool === null || Dash.UserPool === undefined) {
      return;
    }

    if (Dash.UserPool.length === 0) {
      setUsersPool(userInfo, firestore);
    }
  }, [Dash.UserPool, setUsersPool, firestore, userInfo]);

  //sets Selected User to default to the logged-in user
  useEffect(() => {
    if (userInfo === null || userInfo === undefined) {
      return;
    }

    setSelectedUser([userInfo[0]]);
  }, [userInfo, setSelectedUser]);

  //Used to initialy populate the guestpass session master collection if not already populated, this is populated with session options.
  useEffect(() => {
    ////////onsole.log("!!!DASHGPS!!!",Dash.GuestpassSessionMasterCollection)
    if (Dash.GuestpassSessionMasterCollection === null) return;
    if (Dash.GuestpassSessionMasterCollection.length === 0)
      SetGuestpassSessionMasterCollection(userInfo, firestore);
  }, [
    Dash.GuestpassSessionMasterCollection,
    SetGuestpassSessionMasterCollection,
    firestore,
    userInfo
  ]);

  const Check = () => {
    if (
      Dash.UserPool.length !== 0 &&
      (Dash.GuestpassMasterCollection?.length !== 0 ||
        Dash.GuestpassMasterCollection === null) &&
      (Dash.SessionMasterCollection?.length !== 0 ||
        Dash.SessionMasterCollection === null) &&
      (Dash.GuestpassSessionMasterCollection?.length !== 0 ||
        Dash.GuestpassSessionMasterCollection === null)
    ) {
      return true;
    }
    return false;
  };

  // const [NotificationsList,SetNL] = useState([]);
  // const [PreProcessingNotificationsList,SetPrePNL] = useState([]);
  // const [PostProcessingNotificationsList,SetPPNL] = useState([]);
  // const [ScrollableCount,SetScrollableCount] = useState(10);

  // const [itterationCount, SetItterationCount] = useState(0);
  // let NEODASH = useRef(useSelector(state=>state.DashboardReducer.GuestpassSessionMasterCollection))
  //onsole.log("NEODASH",NEODASH.current)
  const QSHANDLER = useCallback(
    (QS) => {
      new Promise((Resolve) => {
        //onsole.log("UpdateNotifications",NotificationsList,Dash.GuestpassSessionMasterCollection, QS.docChanges(),itterationCount)

        let TempNL = [...Dash.NotificationsList]; //
        let Changes = [];

        QS.docChanges().forEach((change) => {
          //onsole.log("CHANGE",change.type,{...change.doc.data(),id:change.doc.id})
          if (change.type === 'added') {
            TempNL = [...TempNL, { ...change.doc.data(), id: change.doc.id }];
            Changes.push({ ...change.doc.data(), id: change.doc.id });
            //SetNL([...NotificationsList,change.doc.data()])
            // //onsole.log("New city: ", change.doc.data());
          }
          if (change.type === 'modified') {
            let tempNewObj = { ...change.doc.data, id: change.doc.id };
            let Index = TempNL.findIndex((val) => {
              return val.id === tempNewObj.id;
            });
            if (Index > -1) TempNL[Index] = tempNewObj;
            //SetNL([...NotificationsList,change.doc.data()])
            Changes.push({ ...change.doc.data(), id: change.doc.id });
            //onsole.log("Modified city: ", {...change.doc.data(),id:change.doc.id});
          }
        });
        if (QS.docChanges().length > 0) {
          let tempGPMSL = [...Dash.GuestpassSessionMasterCollection];
          Changes.forEach((val) => {
            let index = tempGPMSL.findIndex((val2) => {
              return val2.id === val.id;
            });
            if (index === -1) {
              tempGPMSL.unshift(val);
            } else {
              tempGPMSL[index] = val;
            }
            //onsole.log("Dash.GuestpassSessionMasterCollection",Dash.GuestpassSessionMasterCollection,tempGPMSL)
          });
          //onsole.log("Updating","GuestpassSessionMasterCollection")
          SetMasterGuestPassSessionList(tempGPMSL);
          SetPrePNL(tempGPMSL);
        } else {
          SetPrePNL(Dash.GuestpassSessionMasterCollection);
        }
        SetNL(TempNL);
        // SetItterationCount(itterationCount +1)
        Resolve(true);
      });
    },
    [
      Dash.NotificationsList,
      Dash.GuestpassSessionMasterCollection,
      SetNL,
      SetMasterGuestPassSessionList,
      SetPrePNL
    ]
  );
  useEffect(() => {
    let date = new Date(Date.now());
    // date = new Date(date.setHours(0))
    // date = new Date(date.setMonth(-6))
    // date = new Date(date.setDate(-1))

    console.log('START SUB');
    // let PID = Dash.selectedPortfolioId
    //let count = 0
    // SetItterationCount(0);
    const UnSub = firestore
      .collection('sessions')
      .where('company_id', '==', userInfo[0].company_id)
      .where('updated_at', '>=', date)
      .where('type', '==', 'guest')
      .orderBy('updated_at', 'desc')
      .onSnapshot(QSHANDLER);
    return () => {
      SetNL([]); //onsole.log("End SUB");
      UnSub();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Dash.GuestpassSessionMasterCollection]);
  //
  //
  //
  // useEffect(()=>{
  //     //onsole.log("START SUB Testing",PreProcessingNotificationsList, NEODASH.current,Dash.GuestpassSessionMasterCollection )
  //     SetMasterGuestPassSessionList(PreProcessingNotificationsList)
  // },[PreProcessingNotificationsList, SetMasterGuestPassSessionList, Dash.GuestpassSessionMasterCollection])
  // useEffect(()=>{
  //     //onsole.log("NEODASH.current Testing", NEODASH.current,Dash.GuestpassSessionMasterCollection )
  //     // SetMasterGuestPassSessionList(PreProcessingNotificationsList)
  // },[PreProcessingNotificationsList, SetMasterGuestPassSessionList,Dash.GuestpassSessionMasterCollection])
  useEffect(() => {
    new Promise((resolve) => {
      let TempNL = [...Dash.PreProcessingNotificationsList];
      // TempNL = TempNL.filter((val)=>{return val.portfolio_id === PID});
      let dateCut = DateRangeEnum[4];
      TempNL = TempNL.filter((val) => {
        return val.session_date.seconds * 1000 > dateCut();
      });
      //onsole.log("UpdateNotifications2",TempNL,"test4")
      let TempNLLiked = [];
      let TempNLNote = [];
      let TempNLStarted = [];
      TempNLStarted = TempNL.map((val) => {
        return {
          ...val,
          SessionId: val.id,
          session_date: val.session_date,
          time: val.session_date,
          portfolio_id: val.portfolio_id,
          Name: val.gp_name,
          Email: val.gp_email,
          Company: val.gp_email.split('@')[1].split('.')[0],
          type: 'Start'
        };
      });
      //onsole.log('UpdateNotifications3',"PostStart")
      //onsole.log("TempNL1",TempNLLiked,TempNL)
      TempNLLiked = TempNL.map((val) => {
        if (val?.notifications?.likes)
          return val?.notifications?.likes?.map((ele) => {
            if (ele.gallery_index !== undefined)
              return {
                ...ele,
                page_id: ele.id,
                SessionId: val.id,
                session_date: val.session_date,
                portfolio_id: val.portfolio_id,
                Name: val.gp_name,
                Email: val.gp_email,
                Company: val.gp_email.split('@')[1].split('.')[0],
                type: 'likes'
              };
            else return null;
          });
        else {
          return null;
        }
      });
      //onsole.log('UpdateNotifications4',"PostLike")
      TempNLNote = TempNL.map((val) => {
        if (val?.notifications?.Notes)
          return val?.notifications?.Notes?.map((ele) => {
            return {
              ...ele,

              SessionId: val.id,
              session_date: val.session_date,
              portfolio_id: val.portfolio_id,
              Name: val.gp_name,
              Email: val.gp_email,
              Company: val.gp_email.split('@')[1].split('.')[0],
              type: 'Notes'
            };
          });
        else return null;
      });
      //onsole.log('UpdateNotifications5',"PostNote")
      TempNLNote = TempNLNote.filter((ele) => {
        return ele;
      }); //&& ele?.length >0
      TempNLLiked = TempNLLiked.filter((ele) => {
        return ele;
      });
      //TempNLLiked.flat(1);
      //onsole.log("TempNL2",TempNLLiked,TempNLNote,TempNL)
      let end = [...TempNLLiked, ...TempNLNote, ...TempNLStarted].flat(3);
      end = end.filter((ele) => {
        return ele;
      });
      //onsole.log('UpdateNotificationsEnd',end)
      // SetPPNL(end);
      resolve(end);
    }).then((end) => {
      SetPPNL(end);
    });
  }, [Dash.PreProcessingNotificationsList, SetPPNL]);
  useEffect(() => {
    //onsole.log("NotificationsList",NotificationsList,NotificationsList.flat(3))
  }, [Dash.NotificationsList]);
  // const[Notifications, SetNotifications] = useState(0);
  useEffect(() => {
    console.log('SetNotifications', SetNotificationsCount);
    if (!SetNotificationsCount) return;
    SetNotificationsCount(
      Dash.PostProcessingNotificationsList
        ? Dash.PostProcessingNotificationsList.filter((val) => {
            return Dash.selectedPortfolioId === val.portfolio_id;
          })
            .sort((a, b) => {
              if (b.time.seconds > a.time.seconds) return 1;
              if (b.time.seconds < a.time.seconds) return -1;
              return 0;
            })
            .slice(0, Dash.ScrollableCount)
            .filter((val) => {
              return !val.Viewed;
            }).length
        : 0
    );
  }, [
    Dash.selectedPortfolioId,
    Dash.PostProcessingNotificationsList,
    Dash.ScrollableCount,
    SetNotificationsCount
  ]);
  useEffect(() => {
    console.log('Dash.NotificationsCount', Dash.NotificationsCount);
  }, [Dash.NotificationsCount]);
  return (
    <IsReady IsReady={Check}>
      <div className="dashboard-revamp-page">
        {gp ? (
          <>
            <Header2 setGP={setGP} />
            <DashboardBody />
          </>
        ) : (
          <>
            <Header setGP={setGP} />
            <DashboardBody />
          </>
        )}
        {/* <Header></Header>
            <DashboardBody></DashboardBody> */}
      </div>
    </IsReady>
  );
};

export default Dashboard;
