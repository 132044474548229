import {
  SET_GUEST_PAGES,
  SET_GUEST_CONTENT_HELPER,
  SET_GUEST_MODE,
  PREPARE_GUEST,
  SET_CURRENT_CONTENT_HELPER,
  GET_EXPIRE_DATE,
  RESET_PORTFOLIO_INFO,
  END_PORTFOLIO,
  SET_GUEST_DATA
} from '../constants/actionTypes';

const initialState = {
  guestContentHelper: [],
  isGuestMode: false,
  // IsPortView: false,
  currentContentHelper: {},
  expireDate: undefined,
  status: '',
  isPortfolioView: false,
  GP_Data: {}
};
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_GUEST_DATA:
      //onsole.log("Test GPINFO",action)
      return {
        ...state,
        GP_Data: action.data
      };
    case GET_EXPIRE_DATE.BEGIN:
      return {
        ...state,
        status: 'request'
      };
    case GET_EXPIRE_DATE.FAILURE:
      return {
        ...state,
        status: 'fail'
      };
    case PREPARE_GUEST:
      return {
        ...state,
        guestContentHelper: action.payload.guestContentHelper,
        isGuestMode: true,
        expireDate: new Date(action.payload.expireDate),
        status: 'success',
        isPortfolioView: action.payload.isPortfolioView
      };
    case SET_CURRENT_CONTENT_HELPER: {
      // set current contentHelper by id
      return {
        ...state,
        currentContentHelper: state.guestContentHelper.find(
          (obj) => obj.id === action.id
        )
      };
    }
    case SET_GUEST_PAGES:
      return {
        ...state,
        guestPages: action.array
      };
    case SET_GUEST_CONTENT_HELPER:
      return {
        ...state,
        guestContentHelper: action.array
      };
    case SET_GUEST_MODE:
      return {
        ...state,
        isGuestMode: action.bool
      };
    case END_PORTFOLIO:
    case RESET_PORTFOLIO_INFO:
      return initialState;
    default:
      return state;
  }
};
