import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
const Test = () => {
  const dispatch = useDispatch();
  //const check =
  //Monitor window size for benefit of adaptive sizing components, see also ViewModels/ContentReducer.js
  const WindowSizingHook = (e) => {
    dispatch({
      type: 'WindowResized',
      Payload: { Size: { W: window.innerWidth, H: window.innerHeight } }
    });
  };

  useEffect(() => {
    //////onsole.log("StartingResizeListener")
    window.addEventListener('resize', WindowSizingHook);
    return () => {
      window.removeEventListener('resize', WindowSizingHook);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch({
      type: 'WindowResized',
      Payload: { Size: { W: window.innerWidth, H: window.innerHeight } }
    });
  }, [
    // eslint-disable-next-line
    useSelector((state) => state.portfolioReducer.chosenPortfolioId),
    dispatch
  ]);
  // ,[useStore().portfolioReducer.chosenPortfolioId]
  ////onsole.log("!!STORE",useSelector((state)=>state.portfolioReducer.chosenPortfolioId))
  return <></>;
};
export default Test;
