import React, { useState, useEffect } from 'react';
import InputBox from '../../../../InputBox';
import { useFirestore } from 'react-redux-firebase';
import styled from 'styled-components';
import { writeClient } from '../../../../../utils/client';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
//import { LastPage } from '@material-ui/icons';

const UserInfoContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 6fr 4fr;

  .input-box {
    grid-row: 1 / span 1;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    .placement-helper {
      width: 100%;
      height: 50%;

      .input-container {
        display: grid;
        grid-template-columns: 1fr 24px 1fr;
        grid-template-rows: 1fr 12px 1fr 12px 1fr;
        width: 80%;
        height: 100%;
      }
    }
  }

  .save-box {
    grid-row: 2 / span 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    @media (max-width: 1380px) {
      align-items: center;
    }

    .placement-helper {
      width: 80%;
      height: 30%;
      display: flex;
      justify-content: center;
      align-items: center;

      .save-button {
        cursor: pointer;
        height: 30px;
        width: 200px;
        box-shadow: 3px 3px 5px #808080;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgb(5, 106, 175);

        p {
          margin-block-start: 0px;
          margin-block-end: 0px;
          font-size: 16px;
          font-family: 'Open Sans', sans-serif;
          color: white;
          font-weight: 600;
        }
      }
    }

    .loading-holder {
      margin-top: 20px;
      width: 80%;
      height: 30%;
      display: flex;
      justify-content: center;
      align-items: center;
      // border: 1px solid black;
    }
  }
`;

const InputDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 42px;
  background-color: #dedede;
  border-radius: 5px;
  padding-left: 2%;
  padding-right: 2%;
  overflow: hidden;

  p {
    margin-block-end: 0px;
    margin-block-start: 0px;
    font-weight: 600;
    font-size: 16px;
    font-family: 'Newsreader', serif;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const UserInformationInput = ({ userInfo, getUpdatedUser, member }) => {
  const [companyName, setCompanyName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [loading, setLoading] = useState(false);
  const firestore = useFirestore();

  useEffect(() => {
    let companyId = null;

    if (userInfo[0]?.company_id) {
      companyId = userInfo[0].company_id;
    }

    if (companyId !== null)
      firestore
        .collection('companies')
        .doc(companyId)
        .get()
        .then((Doc) => {
          // //////onsole.log(Doc.data());
          setCompanyName(Doc.data().name);
        });
  }, [userInfo, firestore]);

  useEffect(() => {
    if (userInfo) {
      setFirstName(userInfo[0].name.trim().split(' ')[0]);
      setLastName(userInfo[0].name.trim().split(' ')[1]);
    } else {
      return;
    }
  }, [userInfo]);

  const changeFirstName = (event) => {
    setFirstName(event.target.value);
    //////onsole.log(member);
  };

  const changeLastName = (event) => {
    setLastName(event.target.value);
  };
  function isEmptyOrSpaces(str) {
    if (typeof str !== typeof '') return null;
    return str === null || str.match(/^\s*$/) !== null;
  }

  const UpdateFirestore = () => {
    if (isEmptyOrSpaces(firstName) || isEmptyOrSpaces(lastName)) return;
    setLoading(true);
    firestore
      .collection('users')
      .doc(userInfo[0].id)
      .update({
        name: firstName + ' ' + lastName
      })
      .then((e) => {
        writeClient()
          .patch(member._id)
          .set({ name: firstName + ' ' + lastName })
          .commit()
          .then((ex) => {
            //////onsole.log("!!!EX!!!",ex)
            getUpdatedUser(ex);
            setLoading(false);
          })
          .catch((ex) => {
            //////onsole.log("EXCEPTION", ex);
            setLoading(false);
            alert(
              'There was a problem updating your information. Please try again later.'
            );
          });
      })
      .catch((ex) => {
        //////onsole.log("EXCEPTION", ex);
        setLoading(false);
        alert(
          'There was a problem updating your information. Please try again later.'
        );
      });
  };

  return (
    <UserInfoContainer>
      <div className="input-box">
        <div className="placement-helper">
          <div className="input-container">
            <InputBox
              Pos={{ width: 'unset' }}
              style={{
                fontFamily: "'Newsreader', serif",
                fontSize: '16px',
                width: '100%'
              }}
              placeholder="First Name"
              type="text"
              value={firstName}
              onChange={changeFirstName}
              isValid={!isEmptyOrSpaces(firstName)}
            />
            <InputBox
              Pos={{ gridRow: '1/2', gridColumn: '3/4', width: 'unset' }}
              placeholder="Last Name"
              type="text"
              style={{
                gridRow: '1/2',
                gridColumn: '3/4',
                fontFamily: "'Newsreader', serif",
                fontSize: '16px',
                width: '100%'
              }}
              value={lastName}
              onChange={changeLastName}
              isValid={!isEmptyOrSpaces(lastName)}
            />
            <InputDiv style={{ gridRow: '3/4', gridColumn: '1/4' }}>
              <p>{companyName}</p>
            </InputDiv>
            <InputDiv style={{ gridRow: '5/6', gridColumn: '1/4' }}>
              <p>{userInfo[0].email}</p>
            </InputDiv>
          </div>
        </div>
      </div>
      <div className="save-box">
        <div className="placement-helper">
          <div className="save-button" onClick={() => UpdateFirestore()}>
            <p>Save</p>
          </div>
        </div>
        <div
          className="loading-holder"
          style={{ visibility: loading ? 'visible' : 'hidden' }}
        >
          <Loader
            type="TailSpin"
            color="rgb(5, 106, 175)"
            height={75}
            width={75}
          />
        </div>
      </div>
    </UserInfoContainer>
  );
};
export default UserInformationInput;
