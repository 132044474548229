// Keep track brief case content, cart actions

import {
  TOGGLE_BRIEFCASE,
  ADD_TO_BRIEFCASE,
  REMOVE_FROM_BRIEFCASE,
  CLEAR_BRIEFCASE,
  KM_RESET,
  END_PORTFOLIO,
  RESET_PORTFOLIO_INFO,
  TOGGLE_NOTEPAD,
  TOGGLE_SCRIPTNOTE,
  SET_BOOLEAN_NEW_ITEMS
} from '../constants/actionTypes';

const initialState = {
  showBriefcase: false,
  briefcaseContent: [],
  booleanNewItems: false
};
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_BRIEFCASE:
      return {
        ...state,
        showBriefcase: !state.showBriefcase
      };
    case ADD_TO_BRIEFCASE:
      if (!state.briefcaseContent.some((ele) => ele.id === action.item.id))
        return {
          ...state,
          briefcaseContent: [...state.briefcaseContent, action.item]
        };
      else return state;
    case REMOVE_FROM_BRIEFCASE:
      return {
        ...state,
        briefcaseContent: state.briefcaseContent.filter(
          (item) => item.id !== action.id
        )
      };
    case CLEAR_BRIEFCASE:
      return {
        ...state,
        briefcaseContent: initialState.briefcaseContent
      };
    case SET_BOOLEAN_NEW_ITEMS:
      return {
        ...state,
        booleanNewItems: action.data
      };
    case TOGGLE_NOTEPAD:
    case TOGGLE_SCRIPTNOTE:
      return {
        ...state,
        showBriefcase: false
      };
    case RESET_PORTFOLIO_INFO:
    case END_PORTFOLIO:
    case KM_RESET:
      return initialState;
    default:
      return state;
  }
};
