import React from 'react'; //{ useState, useEffect, useRef }
// import ToolCross from '../../../../../containers/PageMenus/Menu9-Storybook/TerminalMenuGallery/Exclude.png'//
// import BackChev from '../Vector 1.png'
import BackChev from '../BackarrowSVG';
import CloseIcon from '../../../../../containers/PageMenus/Menu9-Storybook/CloseSVG';
import BlockContent from '@sanity/block-content-to-react';
import serializers from '../../../../../utils/scriptSerializers';
import FontUtil from '../../../../../utils/FontUtility';
const Script = ({
  toggleScriptnote,
  pages,
  chosenContentPageId,
  SetDisplay,
  chosenPortfolio
}) => {
  return (
    <div className="ActionMenu Notepad">
      <div className="HeadBar">
        <p
          className="Title"
          style={{ fontFamily: FontUtil(chosenPortfolio.SystemText) }}
        >
          Script
        </p>
        <CloseIcon
          onClick={() => {
            toggleScriptnote();
            SetDisplay(false);
          }}
          className="closeButton"
          id={'S1'}
        />
        {/* <img alt="Close Tools" src={ToolCross} onClick={()=>{toggleScriptnote(); SetDisplay(false);}} className="closeButton"></img> */}
        {/* <img alt="Back to Main Tools" src={BackChev} onClick={()=>{toggleScriptnote()}} className="BackButton"></img> */}
        <BackChev
          onClick={() => {
            toggleScriptnote();
          }}
          className="BackButton"
        ></BackChev>
      </div>
      <div className="Options Notepad Script">
        <div className="textarea-container">
          {pages[chosenContentPageId] ? (
            <p
              className="Subtitle"
              style={{
                marginBlockStart: '0px',
                marginBlockEnd: '0px',
                fontWeight: 600,
                fontFamily: FontUtil(chosenPortfolio.SystemText)
              }}
            >
              {pages[chosenContentPageId]?.title}
            </p>
          ) : null}
          <hr></hr>
          {pages[chosenContentPageId] ? (
            typeof pages[chosenContentPageId]?.script === 'string' ? (
              <p
                className={'Body'}
                style={{ fontFamily: FontUtil(chosenPortfolio.SystemText) }}
              >
                {pages[chosenContentPageId]
                  ? pages[chosenContentPageId]?.script
                  : ''}
              </p>
            ) : (
              <div className={'Body'}>
                <BlockContent
                  blocks={pages[chosenContentPageId]?.script}
                  serializers={serializers}
                />
              </div>
            )
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default Script;
