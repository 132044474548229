import React from 'react';
import styled from 'styled-components';
import AdminPortal2 from '../../../containers/AdminPortal2';

const StretchContainer = styled.div`
  grid-column: 2 / span 1;

  @media (max-width: 1380px) {
    grid-column: 1 / span 3;
  }
`;

const CenterContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
`;

const Main = styled.div`
  overflow-y: auto;
  max-height: 750px;
  z-index: 0;
  border-left: 1px solid #a7a7a7;
  border-right: 1px solid #a7a7a7;

  @media (max-width: 1380px) {
    border: none;
    max-height: 600px;
  }
`;

const Users = (props) => {
  return (
    <>
      <div className="dashboard-body-left"></div>
      <StretchContainer
        className="dashboard-body-main"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CenterContainer>
          <Main>
            <AdminPortal2 />
          </Main>
        </CenterContainer>
      </StretchContainer>
      <div className="dashboard-body-right"></div>
    </>
  );
};

export default Users;
