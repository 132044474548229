/* 
// Shows menus as nested
*/
import React, { useState, useEffect, useRef } from 'react';
import Button from '../../../components/Button';
import MenuSection from '../../MenuSection';
import useTemplate from '../../../components/_Portfolio/PortfolioMenu/Helper/TemplateHelper';
import { urlFor } from '../../../utils/sanityHelper';
import '../../../assets/images/arrowLeft.png';
import { TransitionGroup } from 'react-transition-group';
import Icons from '../../Icons';
import FontUtil from '../../../utils/FontUtility';

const Menu2 = (props) => {
  const {
    companyConfig,
    images,
    pages,
    setChosenMainPageButtonId,
    setChosenPageId,
    setIsAtEnd,
    setCurrentLevel,
    nav,
    isGuestMode,
    setContentPageId,
    chosenContentPageId,
    chosenPageId,
    setTopic,
    Topic,
    chosenPortfolio,
    setNavHistory,
    setSavedContentPageId,
    Size
  } = props;

  const { currentLevel, isAtEnd, menuHide, navHistory } = props.pageMenuReducer;

  const { portfolio_LogoResize } = chosenPortfolio;
  ////////onsole.log("MENUPORT",portfolio_LogoResize)
  // portfolio_LogoResize
  // get default functions used from hook
  const { getActiveString, getIsAtEndString } = useTemplate(props);
  const [currentNavItem, setCurrentNavItem] = useState({});
  const [prevId, setPrevId] = useState(''); // keep track of previous page id for back button
  const [LevelOfTerm, setLevelOfTerm] = useState(0); // keep track of previous page id for back button
  const [prevLevel, setPrevLevel] = useState(''); // keep track of previous page id for back button
  // const [prevHasChild, setPrevHasChild] = useState(''); // keep track of previous page id for back button
  // const [prevTitle, setPrevTitle] = useState(''); // keep track of previous page id for back button
  // const [prevNavIndex, setPrevNavIndex] = useState(''); // keep track of previous page id for back button
  // const [prevPrevNavIndex, setPrevPrevNavIndex] = useState(''); // keep track of previous page id for back button
  const [isInNested, setIsInNested] = useState(false); // keep track if user is in nested layer

  //useEffect(()=>{})
  const TermRef = useRef(null);

  useEffect(() => {
    if (isAtEnd) {
      setLevelOfTerm(prevLevel + 1);
    }
  }, [isAtEnd, prevLevel]);

  useEffect(() => {
    let splashKeyId = `splashkey-${chosenPortfolio._id}`;
    if (chosenPageId !== 0 && chosenPageId !== splashKeyId) {
      // get current nav item
      let tempNavItem = {};

      // traverse nav history to find current nav
      navHistory.forEach((number, index) => {
        // first
        if (index === 0) {
          tempNavItem = nav.items[number];
        } else {
          tempNavItem = tempNavItem.children[number];
        }
      });
      setCurrentNavItem(tempNavItem);

      // if tempNavItem.referenceId is undefined then set content to chosenContentPageId
      if (!tempNavItem.referenceId) {
        setContentPageId(chosenContentPageId);
      } else {
        setContentPageId(tempNavItem.referenceId);
      }
      if (chosenContentPageId) {
        setSavedContentPageId(chosenContentPageId);
      }
    }
    if (chosenPageId === splashKeyId) {
      // create nav specifically for splash
      setCurrentNavItem({
        children: [],
        referenceField: 'pages',
        referenceId: splashKeyId,
        title: 'Splash',
        type: 'gallery',
        _key: 'splashnav'
      });
      setContentPageId(splashKeyId);
      if (chosenContentPageId) {
        setSavedContentPageId(chosenContentPageId);
      }
      setPageIdAndLevel('', 0);
      setIsAtEnd(false);
    }
    // eslint-disable-next-line
  }, [chosenPageId, pages, navHistory, chosenPortfolio]);

  // back button function
  const goBack = () => {
    BackTopicArray();
    //////onsole.log('PrevId', prevId);
    if (prevId !== '') {
      // initiate menu button click for prevId
      setPageIdAndLevel(prevId, currentLevel);
    } else {
      // empty prevId
      setPageIdAndLevel('', 0);
    }
    setIsAtEnd(false);
    //const onClick = (id, level, hasChild, isNotEnd, navIndex) => {
    //   setNavHistory(navHistory.filter((_, i) => i !== navHistory.length - 1));
    //   setNavHistory(navHistory.filter((_, i) => i !== navHistory.length - 1));
    // onClick(pages[navHistory[navHistory.length-1]],prevLevel -1,true,false,prevPrevNavIndex)
    // remove last item from navHistory
    //////onsole.log('NAV', navHistory);
    setNavHistory(navHistory.filter((_, i) => i !== navHistory.length - 1));
  };
  const BackTopicArray = () => {
    let topicalarray = [];
    try {
      topicalarray = [...Topic];
    } catch (err) {
      console.error('Error', err);
    }
    topicalarray.splice(Topic.length - 1, 111111);
    //////onsole.log("BackArray",topicalarray)
    setTopic(topicalarray);
  };
  const SetTopicArray = (title, level, id) => {
    let topicalarray = [];
    try {
      topicalarray = [...Topic];
    } catch (err) {
      console.error('Error', err);
    }
    if (!isAtEnd) {
      topicalarray.splice(level, 11111, { title: title, id: id });
      setTopic(topicalarray);
    } else {
      topicalarray.splice(LevelOfTerm, 11111, { title: title, id: id });
      setTopic(topicalarray);
    }
  };

  // on page button click
  const onClick = (id, level, hasChild, isNotEnd, navIndex) => {
    //////onsole.log('OnClick: ', id);
    //////onsole.log('Setting: ', id);
    //////onsole.log('Setting: ', nav);
    setPrevLevel(level);

    setPageIdAndLevel(id, level);

    // copy navhistroy
    let navHistoryCopy = [...navHistory];
    // first nav history, so parent button
    if (navHistoryCopy.length === 0) {
      navHistoryCopy.push(navIndex);
    } else {
      // check if need to replace at same level
      if (level <= navHistoryCopy.length - 1) {
        //////////onsole.log('REPLACE');
        navHistoryCopy[level] = navIndex;
        // remove level higher than replaced one
        // ex if user has history [2,1,1,5] and clicked on index 3 at parent (level 0)
        // then replace  it [3,1,1,5] and remove history of any other level, so it will be [3]
        // NOTE slice caused issue, using filter for now
        navHistoryCopy = navHistoryCopy.filter((_, i) => i <= level);
      } else {
        // if not append
        navHistoryCopy.push(navIndex);
      }
    }

    // set/replace navHistory
    setNavHistory(navHistoryCopy);
    // if(chosenPortfolio.MethodicalSpec?.MethodicalFlat){
    //   setIsInNested(false);
    // setIsAtEnd(false)
    // return
    // }

    // check if clicked content contains child
    if (hasChild) {
      let b = !isNotEnd;
      if (b) {
        //////////onsole.log('Term', TermRef.current);
        TermRef.current.style.opacity = 0;
        setTimeout(function () {
          TermRef.current.style.opacity = 0;
        }, 15);
        setTimeout(function () {
          setIsAtEnd(true);
          TermRef.current.style.opacity = 1;
        }, 500);
      } else setIsAtEnd(b);
      // User is in nested so set isInNested to true
      setIsInNested(true);
      // set isAtEnd

      if (!b) {
        // NOTE need to wait till CMS update with giving back first child as intro
        ////////////onsole.log("!!!!PREV!!!! PREV ID BEFORE: ", prevId);
        setPrevId(id);
        ////////////onsole.log("!!!!PREV!!!! PREV ID AFTER: ", prevId);
      } else {
        //if at end the nested ended so set isInNested to false
        setIsInNested(false);
      }
    } else {
      TermRef.current.style.opacity = 0;
      setTimeout(function () {
        setIsAtEnd(true);
        TermRef.current.style.opacity = 1;
      }, 500);
      // setIsAtEnd(true);
      if (!isInNested) {
        // if not in nested set prev id
        ////////////onsole.log("!!!!PREV!!!! PREV ID BEFORE: ", prevId);
        setPrevId(id);
        ////////////onsole.log("!!!!PREV!!!! PREV ID AFTER: ", prevId);
      }
    }
    // setPrevId(id);
    // setPrevHasChild(hasChild);
    // setPrevLevel(level);
    // setPrevTitle(isNotEnd)
    // setPrevPrevNavIndex(prevNavIndex)
    // setPrevNavIndex(navIndex)
  };

  // set page and level
  const setPageIdAndLevel = (id, level) => {
    //////////onsole.log('SetPageIdAndLevel: ', id);
    //////////onsole.log('SetPageIdAndLevel: ', level);
    //////////onsole.log('IS AT END: ', getIsAtEndString());

    if (level === 0) {
      setChosenMainPageButtonId(id);
    }
    //////////onsole.log('Setting: ', id);
    //////////onsole.log('Setting: ', nav);
    setChosenPageId(id);
    setCurrentLevel(level);
    //////////onsole.log('IS AT END: ', getIsAtEndString());
  };

  // return null if menuHide is true
  // if (menuHide) {
  //   return null;
  // }

  //////////onsole.log('Chosen Page ID: ', chosenPageId);
  // if (chosenPageId !== 0)
  //   //////////onsole.log('Group of Tabs: ', pages[chosenPageId].tabs);

  let terminusMenuPosition = null;
  let testTheme = 'theme4';
  if (testTheme === 'theme4' && chosenPageId !== 0) {
    // //onsole.log("PAGE", pages[currentNavItem.referenceId])
    // TODO: Why is this constant condition?
    terminusMenuPosition = true ? (
      <div className="portfolio-content-menu-container">
        <Button
          width="280px"
          height="100px"
          text={currentNavItem.title}
          subtitle={
            Size.H > 450
              ? currentNavItem.subtitle
                ? currentNavItem.subtitle
                : null
              : null
          }
          color={
            pages[currentNavItem.referenceId]?.text_color
              ? pages[currentNavItem.referenceId]?.text_color
              : chosenPortfolio.text_color
          }
          backgroundColor={
            pages[currentNavItem.referenceId]?.accent_color
              ? pages[currentNavItem.referenceId].accent_color
              : chosenPortfolio.secondary_color
              ? chosenPortfolio.secondary_color
              : ''
          }
          onClick={() => {
            TermRef.current.style.opacity = 0;
            setTimeout(function () {
              TermRef.current.style.opacity = 1;
              goBack();
            }, 500);
          }}
          fontFamily={FontUtil(
            pages[currentNavItem?.referenceId]?.TitleText,
            chosenPortfolio.ContentText
          )}
          fontFamily2={FontUtil(
            pages[currentNavItem?.referenceId]?.SubtitleText,
            chosenPortfolio.ContentText
          )}
          style={{
            backgroundColor: `${
              pages[currentNavItem.referenceId]?.accent_color
                ? pages[currentNavItem.referenceId].accent_color
                : chosenPortfolio.secondary_color
                ? chosenPortfolio.secondary_color
                : ''
            }`
          }}
        />
        <div className="portfolio-content-menu">
          {currentNavItem.children &&
            currentNavItem.children.length > 0 &&
            isAtEnd &&
            currentNavItem.children.map((child, index) => {
              // //onsole.log("!!!CHILD!!!",child)
              // //onsole.log("!!!CHILD NAV!!!", pages[child.referenceId]);
              if (chosenContentPageId !== child.referenceId)
                return (
                  <Button
                    key={child._key + '-' + index}
                    fontFamily={FontUtil(
                      pages[child.referenceId].TitleText,
                      chosenPortfolio.ContentText
                    )}
                    fontFamily2={FontUtil(
                      pages[child.referenceId].SubtitleText,
                      chosenPortfolio.ContentText
                    )}
                    width="280px"
                    height="60px"
                    text={child.title}
                    subtitle={child.subtitle ? child.subtitle : null}
                    color={
                      pages[child.referenceId]?.text_color
                        ? pages[child.referenceId].text_color
                        : chosenPortfolio.inactive_terminal_font
                    }
                    onClick={() => {
                      SetTopicArray(
                        child.title,
                        Topic.length,
                        child.referenceId
                      );
                      // only update if different
                      if (chosenContentPageId !== child.referenceId) {
                        setContentPageId(child.referenceId);
                        if (chosenContentPageId) {
                          setSavedContentPageId(child.referenceId);
                        }
                      }
                    }}
                    style={{
                      backgroundColor: `${
                        //PING
                        pages[child.referenceId]?.accent_color
                          ? pages[child.referenceId].accent_color
                          : chosenPortfolio.inactive_terminal_button
                          ? chosenPortfolio.inactive_terminal_button
                          : ''
                      }`
                    }}
                    backgroundColor={
                      pages[child.referenceId]?.accent_color
                        ? pages[child.referenceId].accent_color
                        : chosenPortfolio.inactive_terminal_button
                        ? chosenPortfolio.inactive_terminal_button
                        : ''
                    }
                  />
                );
              else {
                return (
                  <Button
                    key={child._key + '-' + index}
                    width="280px"
                    height="60px"
                    fontFamily={FontUtil(
                      pages[child.referenceId].TitleText,
                      chosenPortfolio.ContentText
                    )}
                    fontFamily2={FontUtil(
                      pages[child.referenceId].SubtitleText,
                      chosenPortfolio.ContentText
                    )}
                    text={child.title}
                    subtitle={child.subtitle ? child.subtitle : null}
                    pageActive={
                      pages[child.referenceId]?.accent_color ? true : false
                    }
                    color={
                      pages[child.referenceId].text_color
                        ? pages[child.referenceId].text_color
                        : chosenPortfolio.active_terminal_font
                    }
                    onClick={() => {
                      SetTopicArray(
                        child.title,
                        Topic.length,
                        child.referenceId
                      );
                      // only update if different
                      if (chosenContentPageId !== child.referenceId) {
                        setContentPageId(child.referenceId);
                        if (chosenContentPageId) {
                          setSavedContentPageId(child.referenceId);
                        }
                      }
                    }}
                    style={{
                      backgroundColor: `${
                        pages[child.referenceId]?.accent_color
                          ? pages[child.referenceId].accent_color
                          : chosenPortfolio.active_terminal_button
                          ? chosenPortfolio.active_terminal_button
                          : ''
                      }`
                    }}
                    backgroundColor={
                      pages[child.referenceId]?.accent_color
                        ? pages[child.referenceId].accent_color
                        : chosenPortfolio.active_terminal_button
                        ? chosenPortfolio.active_terminal_button
                        : ''
                    }
                  />
                );
              }
            })}
        </div>
      </div>
    ) : null;
  }
  const logoReset = () => {
    //let splashKeyId = `splashkey-${chosenPortfolio._id}`;
    if (isGuestMode) return;
    //////////onsole.log('NAV', nav);
    //////////onsole.log('NHis', navHistory);
    //////////onsole.log('CL', currentLevel);
    //////////onsole.log('CPID', chosenPageId);
    //////////onsole.log('CCPID', chosenContentPageId);
    if (isAtEnd) TermRef.current.style.opacity = 0;
    setTimeout(() => {
      setIsAtEnd(false);
      TermRef.current.style.opacity = 1;
    }, 500);
    setNavHistory([0]);
    setPageIdAndLevel(nav.items[0].referenceId, 0);
    setContentPageId(nav.items[0].referenceId);
  };
  //////////onsole.log('!!!! CURRENT NAV ITEM !!!! ', currentNavItem);

  return (
    <div
      className="portfolio-menu-container"
      style={{ display: menuHide ? 'none' : '' }}
    >
      <div className="logo">
        <div className="logo-content">
          <img
            style={{
              height: `calc(100% - ${
                portfolio_LogoResize?.Compression
                  ? portfolio_LogoResize.VerticalCompression
                  : 0
              }%)`,
              width: `calc(100% - ${
                portfolio_LogoResize?.Compression
                  ? portfolio_LogoResize.HorizontalCompression
                  : 0
              }%)`
            }}
            onClick={logoReset}
            src={urlFor(
              chosenPortfolio.portfolio_logo
                ? chosenPortfolio.portfolio_logo.asset._ref
                : companyConfig.logo !== undefined
                ? images[companyConfig.logo.asset._ref]
                : ''
            )
              .auto('format')
              .width(400)
              .url()}
            alt="logo"
          />
        </div>
      </div>
      <div
        className="menus-container"
        ref={TermRef}
        style={{ transition: 'opacity 250ms' }}
      >
        {
          <div
            style={{
              marginTop: '0px',
              display: `${isAtEnd ? 'none' : 'block'}`
            }}
            onWheel={(eve) => {
              eve.preventDefault();
              // if(Size.W > 1367)return; //////onsole.log("!!SCROLLX!!!",Size.W);//////onsole.log("!!SCROLLY!!!",eve,eve.deltaY);
              if (Size.W > 1367) {
                eve.currentTarget.scrollBy({
                  top: eve.deltaY * 2,
                  left: 0,
                  behavior: 'smooth'
                });
                return;
              }
              eve.currentTarget.scrollBy({
                top: 0,
                left: eve.deltaY,
                behavior: 'smooth'
              });
            }} // if(eve.currentTarget.style.overflowY === 'hidden')
            className={`portfolio-menu  ${getIsAtEndString()}`}
          >
            <TransitionGroup className="row-container">
              <MenuSection
                isActive={true} // set all level 0 button to be active
                getActiveString={getActiveString}
                getIsAtEndString={getIsAtEndString}
                SetTopicArray={SetTopicArray}
                level={0}
                onButtonClick={onClick}
                BackTopicArray={BackTopicArray}
              />
              {Size.H < 451 && (
                <div
                  style={{ width: '48px', height: '2px', flexShrink: '0' }}
                ></div>
              )}
              {/* {Size.W <= 1366 
                ? 
                  <div
                    className='empty-box'
                    style={{
                      width: '30px',
                      height: '30px',
                      backgroundColor: 'red'
                    }}
                  /> 
                : 
                  null
              } */}
            </TransitionGroup>
            <div
              className="menu-filler"
              style={{
                width: '100%',
                height: '48px',
                backgroundColor: 'transparent'
              }}
            />
          </div>
        }
        {isAtEnd && (
          <>
            <div className="back-container">
              <Icons.BackButton
                onClick={() => {
                  TermRef.current.style.opacity = 0;
                  setTimeout(function () {
                    TermRef.current.style.opacity = 1;
                    goBack();
                  }, 500);
                }}
              />
              {terminusMenuPosition}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default Menu2;
