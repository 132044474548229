import { connect } from 'react-redux';
import ScreenSaver from './ScreenSaver';
import { setScreensaverShow } from '../../actions';

const mapStateToProps = (state) => ({
  images: state.sanityContentReducer.images,
  videos: state.sanityContentReducer.videos,
  screensavers: state.sanityContentReducer.screensavers,
  screensaverResults:
    state.portfolioReducer.chosenPortfolio.screensaver_results,
  isVideoPlaying: state.videoReducer.isVideoPlaying,
  show: state.portfolioReducer.screensaverShow
});

const mapDispatchToProps = (dispatch) => {
  return {
    setShow: (bool) => dispatch(setScreensaverShow(bool))
  };
};

const ScreenSaverContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ScreenSaver);

export default ScreenSaverContainer;
