import React, { useState, useRef } from 'react'; //useEffect
import BlockContent from '@sanity/block-content-to-react';
import serializers from '../../../../../utils/scriptSerializers';
// import Circle from './Ellipse 1.png'
// import Arrow from './Vector 1.png'
import Mixed from './mixed media-46.png';
import Globe from './Icons V1-45.png';
import Photo from './Icons V1-44.png';
import Video from './Icons V1-43.png';
import TPBG from './TransparentBG.png';
import FontUtil from '../../../../../utils/FontUtility';
import ColorUtil from '../../../../../utils/ColorUtility';
import Page from './Icons V1-41.png';
// import Scrollindicator from './icon-Scroll-Drag-Right.png'
import PDFViewer2 from '../../../../../components/FullWidthPDFViewer'; //BeforeAfterSlider
import BASlider from '../../../../../components/BeforeAfterSlider'; //BeforeAfterSlider
//import BlockContent from '@sanity/block-content-to-react';
// import {renderSingleImageExport} from '../../../../../components/_Portfolio/PortfolioContent/PortfolioContent'
// import {contentDataExport} from '../../../../../components/_Portfolio/PortfolioContentParentTemplateX/PortfolioContentParent'
// import { Transform } from '@material-ui/icons';
// import { isEmpty } from 'lodash';
import { urlFor } from '../../../../../utils/sanityHelper';
//import serializers from '../../../../../utils/serializers';
const SubMenuItem = ({
  G,
  Val,
  pages,
  onButtonClick,
  level,
  images,
  videos,
  pdfs,
  isGuestMode,
  chosenPortfolio,
  key,
  SetTopicArray,
  TMGD
}) => {
  //FLAG FONT FAMILY
  const Limit = 4;
  const [Target] = useState(0); //,setTarget
  ////onsole.log("!!CHECK",pages[Val.referenceId]);
  let OVTINT = undefined;
  if (
    chosenPortfolio.overlay_tint_color &&
    chosenPortfolio.overlay_tint_color !== '#' &&
    chosenPortfolio.overlay_tint_color !== undefined
  ) {
    OVTINT = chosenPortfolio.overlay_tint_color + '40';
  }
  let SMColor = undefined;
  if (
    chosenPortfolio.submenu_color &&
    chosenPortfolio.submenu_color !== '#' &&
    chosenPortfolio.submenu_color !== undefined
  )
    SMColor = chosenPortfolio.submenu_color;
  if (
    pages[Val.referenceId].submenu_color &&
    pages[Val.referenceId].submenu_color !== '#' &&
    pages[Val.referenceId].submenu_color !== undefined
  )
    SMColor = pages[Val.referenceId].submenu_color;
  // const [Items,setItems]= useState(<></>)
  const blockContent = (blocks) => {
    // filter non valid imageLinks
    let tempBlocks = blocks.filter((obj) => {
      if (obj._type === 'imageLinks' && obj.image) {
        return true;
      }
      if (obj._type !== 'imageLinks') {
        return true;
      }
      return false;
    });

    // further filter non valid embedHtml
    tempBlocks = tempBlocks.filter((obj) => {
      if (obj._type === 'embedHTML' && obj.html) {
        return true;
      }
      if (obj._type !== 'embedHTML') {
        return true;
      }
      return false;
    });
    return tempBlocks;
  };
  const contentDataExport = (ID) => {
    //let tabData = {};
    // if (
    //   isGuestMode &&
    //   ID !== 0 &&
    //   !isEmpty(currentContentHelper) &&
    //   pages[ID]._type === 'gallery' &&
    //   pages[ID]._key === currentContentHelper.page_id &&
    //   currentContentHelper.is_gallery_item
    // ) {
    //   //////////onsole.log('MODIFY FOR GALLERY CONTENT');
    //   // create obj to only display gallery item chosen by guest
    //   let obj =
    //     pages[ID].content[currentContentHelper.gallery_index];
    //   tabData = [{ ...obj }];
    //   //////////onsole.log(pages[ID]);
    //   //////////onsole.log('!!!!TAB DATA!!!! ', tabData);
    //   return tabData;
    // }
    //////////onsole.log('!!!!CHOSEN CONTENT PAGE ID ', ID);
    return pages[ID].content;
  };
  const renderSingleImageExport = (type, galleryNum, contents) => {
    switch (type) {
      case 'galleryImage': {
        let image = contents[galleryNum].image;
        return (
          <img
            className="portfolio-single-image"
            src={urlFor(image).auto('format').url()} // auto converts to webp if browser supports it
            alt="mainImage"
            type="galleryImage"
            style={{
              height: '100%',
              width: '100%',
              objectFit: 'cover'
              //   opacity: chosenPortfolio && chosenPortfolio.theme === 'theme2' ? opacityBack : 1,
              //   width: imageWidth,
              //   height: imageHeight
            }}
          />
        );
      }
      case 'galleryVideo': {
        let video = videos[contents[galleryNum].video.asset._ref];
        //let videoSettings = contents[galleryNum];
        return (
          <img
            style={{ height: '100%', width: '100%', objectFit: 'contain' }}
            src={`https://image.mux.com/${
              video.playbackId
            }/thumbnail.png?fit_mode=preserve&amp;time=${
              video.thumbTime !== 0 ? video.thumbTime : 1
            }`}
            alt="poster"
          />
          // <SanityMuxPlayer
          //   assetDocument={video}
          //   autoload={true}
          //   autoplay={videoSettings.autoplay}
          //   loop={videoSettings.loop}
          //   muted={false}
          //   showControls={true}
          //   style={{}}
          //   posterurl={`https://image.mux.com/${video.playbackId}/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=1`}
          //   type="galleryVideo"
          //   isSelected={isSelected}
          // />
        );
      }
      case 'galleryPdf': {
        let url2 = pdfs[contents[galleryNum].file.asset._ref].url;
        let thumbNailRef2 = contents[galleryNum].thumbnail.asset._ref;
        let thumbNailUrl2 = urlFor(thumbNailRef2).auto('format').url();
        return (
          <PDFViewer2
            url={url2}
            isThumbnail={true}
            type="galleryFullWidthPdf"
            thumbnailUrl={thumbNailUrl2}
          />
        );
      }
      case 'galleryBASlider': {
        let Aimage = contents[galleryNum].Aimage;
        let Bimage = contents[galleryNum].Bimage;
        let LabelA = contents[galleryNum].AimageL;
        let LabelB = contents[galleryNum].BimageL;
        let Vert = contents[galleryNum].Vert;
        let Color = contents[galleryNum].SlideColor;
        ////////onsole.log('!!!!BA!!!!', contents[galleryNum])
        return (
          <BASlider
            Thumb
            LabelB={LabelB}
            LabelA={LabelA}
            Color={Color}
            Vert={Vert}
            ImageA={Aimage}
            ImageB={Bimage}
          />
        );
      }
      case 'galleryFullWidthPdf': {
        let url3 = pdfs[contents[galleryNum].file.asset._ref].url;
        let thumbNailRef3 = contents[galleryNum].thumbnail.asset._ref;
        let thumbNailUrl3 = urlFor(thumbNailRef3).auto('format').url();
        return (
          <PDFViewer2
            url={url3}
            isThumbnail={true}
            type="galleryFullWidthPdf"
            thumbnailUrl={thumbNailUrl3}
          />
        );
      }
      case 'htmlContent':
        return (
          <div className="html-block-content">
            <BlockContent
              blocks={blockContent(contents)}
              serializers={serializers}
            />
          </div>
        );
      case 'website':
        return (
          <div
            style={{ height: '100%', width: '100%' }}
            className="iframe-container"
          >
            <iframe
              title="iframe"
              allowFullScreen
              src={contents}
              style={{
                backgroundColor: 'white',
                height: '100%',
                width: '100%'
              }}
            ></iframe>
          </div>
        );
      //   /src={page.url}
      default:
        return <div />;
    }
  };
  const ScrollRef = useRef();
  const ICalc = () => {
    let x = -1;
    let y = 0;
    if (
      ScrollRef.current?.scrollLeft %
        ScrollRef.current?.firstChild?.clientWidth ===
      0
    ) {
      do {
        x++;
        y = ScrollRef.current?.firstChild?.clientWidth * x;
      } while (y < ScrollRef.current?.scrollLeft);
      return x;
    }
    if (
      ScrollRef.current?.scrollLeft %
        ScrollRef.current?.firstChild?.clientWidth >=
      ScrollRef.current?.firstChild?.clientWidth / 2
    ) {
      do {
        x++;
        y = ScrollRef.current?.firstChild?.clientWidth * x;
      } while (y < ScrollRef.current?.scrollLeft);
      return x;
    } else {
      do {
        x++;
        y = ScrollRef.current?.firstChild?.clientWidth * x;
      } while (y < ScrollRef.current?.scrollLeft);
      return --x;
    }
  };
  // const CanGoDown = ()=>{

  //     ////////onsole.log("down",Target,Target<Val.children.length-Limit)
  //     return Target<Val.children.length - Limit;
  // }
  // const CanGoUp = ()=>{
  //     ////////onsole.log("UP",Target,Target>0)
  //     return Target>0
  // }
  // useEffect(()=>{
  //     setItems(Val.children.map((ele,i)=>{
  //         //////onsole.log("RENDERSUBITEMS",i, Target)
  //         if(i>Target + Limit-1 || i< Target)
  //         return(<></>)
  //         //////onsole.log(pages[ele.referenceId]);
  //         let subtitle = pages[ele.referenceId].subtitle
  //         return(<div key={ele.referenceId} className="SubMenuItemWrapper" style={{display:"grid",gridTemplateRows:"1fr 2px", width:"calc(100% * (563 - (61 + 66)) / 563)", height:"120px", gridTemplateColumns:"1fr 90px"}}>
  //             <div className="SubMenuTextWrapper" style={{ display:"flex", flexDirection:"column", justifyContent:"center", gridColumn:"1/1", marginLeft:"calc(100% * 13 / (563 - (61 + 66))"}}>
  //                 <p className="Header">{ele.title}</p>
  //                 { subtitle && <p className="Subtitle">{subtitle}</p>}
  //             </div>
  //             <div style={{gridColumn:"2/2"}}>
  //                 {/* ICON HERE TO BE DETERMINED */}
  //             </div>
  //             {i !== Target + Limit-1 &&<div style={{gridRow:"2/2", gridColumn:"1/3", backgroundColor:"white"}}></div>}
  //         </div>)
  //     }))
  // },[Target])
  let SMCTC = pages[Val.referenceId]?.submenu_center_third_color
    ? pages[Val.referenceId]?.submenu_center_third_color + 'b2'
    : null;
  const [scrollstate, SetScrollState] = useState(true);
  // useEffect(()=>{
  //   //////onsole.log("ScrollRef.current.Sub",ScrollRef.current,ScrollRef.current?.scrollLeft)
  //   //.firstChild.clientWidth
  // },[ScrollRef.current,scrollstate,])
  //onsole.log("Flag",chosenPortfolio.Submenu_center_third_color+"b2")
  //////onsole.log("SMI",G,Val,pages[Val.referenceId], Mixed);
  const [DesHeadSize] = useState(
    pages[Val.referenceId]?.StoryBookObjects?.descriptionHeaderFontsize
      ? pages[Val.referenceId]?.StoryBookObjects?.descriptionHeaderFontsize +
          'px'
      : '56px'
  );
  const [DesHeadSizeT] = useState(
    pages[Val.referenceId]?.StoryBookObjects?.descriptionHeaderFontsize
      ? pages[Val.referenceId]?.StoryBookObjects?.descriptionHeaderFontsize *
          (36 / 56) +
          'px'
      : '36px'
  );
  const [DesSize] = useState(
    pages[Val.referenceId]?.StoryBookObjects?.descriptionFontsize
      ? pages[Val.referenceId]?.StoryBookObjects?.descriptionFontsize + 'px'
      : '20px'
  );
  const [DesSizeT] = useState(
    pages[Val.referenceId]?.StoryBookObjects?.descriptionFontsize
      ? pages[Val.referenceId]?.StoryBookObjects?.descriptionFontsize *
          (20 / 20) +
          'px'
      : '20px'
  );
  return (
    <>
      {/* <div className="ScrollIndicator" style={{maskImage:`url(${Scrollindicator})`,WebkitMaskImage:`url(${Scrollindicator})`, maskSize:"100% 100%", WebkitMaskSize:'100% 100%', }}></div> */}
      <div className="SubmenuItemBackground">
        {/* CONTENT HERE TO BE DETERMINED */}
        {pages[Val.referenceId]._type !== 'gallery' &&
        pages[Val.referenceId]._type !== 'STgallery' ? (
          pages[Val.referenceId]._type !== 'website' ? (
            renderSingleImageExport(
              pages[Val.referenceId]._type,
              0,
              contentDataExport(Val.referenceId)
            )
          ) : (
            renderSingleImageExport(
              pages[Val.referenceId]._type,
              0,
              pages[Val.referenceId].url
            )
          )
        ) : // //////onsole.log("WEBSITE")
        //renderSingleImageExport(pages[Val.referenceId]._type,0,contentDataExport(Val.referenceId))
        pages[Val.referenceId].content[0] ? (
          renderSingleImageExport(
            pages[Val.referenceId].content[0]._type,
            0,
            contentDataExport(Val.referenceId)
          )
        ) : (
          <div id="Null"></div>
        )}
        {/* {//////onsole.log(pages[Val.referenceId]._type)} */}
        <div
          style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            background: OVTINT
          }}
        ></div>
      </div>

      <div
        className="SubmenuItemMenuBackground"
        style={{ background: SMColor }}
      >
        {/* <p className="OverGhost">{pages[Val.referenceId]?.oversizedText}</p> */}
        {/* <p className="SubtitleHeader">{pages[Val.referenceId]?.subtitle}</p> */}
        <p
          className="SubtitleHeader"
          style={{
            color: pages[Val.referenceId].text_color
              ? pages[Val.referenceId].text_color
              : undefined,
            fontFamily: FontUtil(
              pages[Val.referenceId].TitleText,
              chosenPortfolio.ContentText
            )
          }}
        >
          {pages[Val.referenceId]?.title}
        </p>
      </div>
      {/* CheckTerminal Menu, */}
      <div
        className="SubpageBlockContent"
        onWheel={(eve) => {
          eve.preventDefault();
          eve.stopPropagation();
        }}
        style={{
          overscrollBehaviorX: TMGD ? null : 'auto',
          backgroundColor: ColorUtil(
            pages[Val.referenceId]?.submenu_center_third_color,
            chosenPortfolio.Submenu_center_third_color
          )
        }}
      >
        <div
          className="ColorBar"
          style={{
            backgroundColor: chosenPortfolio.accent_color
              ? chosenPortfolio.accent_color
              : ''
          }}
        />
        <div
          className="SubpageBlockContentWrapper"
          style={{ overscrollBehaviorX: TMGD ? null : 'auto' }}
        >
          <div className="SubpageBlockContentWrapper MSPACE" style={{}} />
          <div
            className="SubpageBlockContentWrapper header"
            style={{
              fontSize: window.innerWidth < 1367 ? DesHeadSizeT : DesHeadSize,
              fontFamily: FontUtil(
                pages[Val.referenceId]?.StoryBookObjects?.descriptionHeaderFont,
                chosenPortfolio.ContentText
              )
            }}
          >
            {/* width:"calc(100% * 773/1159)", calc(100% * ((460 * 2) + 37) / 1159)" */}

            {pages[Val.referenceId]?.StoryBookObjects?.descriptionHeader && (
              <BlockContent
                blocks={
                  pages[Val.referenceId]?.StoryBookObjects?.descriptionHeader
                }
                serializers={serializers}
              ></BlockContent>
            )}
          </div>
          <div
            className="SubpageBlockContentWrapper description"
            style={{
              fontSize: window.innerWidth < 1367 ? DesSizeT : DesSize,
              fontFamily: FontUtil(
                pages[Val.referenceId]?.StoryBookObjects?.descriptionFont,
                chosenPortfolio.ContentText
              )
            }}
          >
            {pages[Val.referenceId]?.StoryBookObjects?.description && (
              <BlockContent
                blocks={pages[Val.referenceId]?.StoryBookObjects?.description}
                serializers={serializers}
              ></BlockContent>
            )}
          </div>
        </div>
      </div>
      <div
        className="SubpageMenuContent"
        onScroll={() => SetScrollState(!scrollstate)}
        ref={ScrollRef}
        style={{
          display: TMGD ? 'none' : 'flex',
          backgroundColor: ColorUtil(
            SMCTC,
            chosenPortfolio.Submenu_center_third_color + 'b2'
          )
        }}
      >
        {Val.children.map((ele, i) => {
          ////////onsole.log("RENDERSUBITEMS",i, Target)
          //  if(i > Target + Limit-1 || i < Target)
          //  return(null)
          ////////onsole.log(pages[ele.referenceId],ele);
          let subtitle = pages[ele.referenceId].subtitle;
          //onsole.log("TEMPCHECK233452",Globe)
          return (
            <>
              <div
                key={ele.referenceId}
                className="SubMenuItemWrapper MOBILE"
                onClick={() => {
                  SetTopicArray(ele.title, level, ele.referenceId);
                  onButtonClick(
                    ele.referenceId,
                    level,
                    ele.children.length > 0,
                    ele.children.length > 0,
                    i
                  );
                }}
                style={{}}
              >
                <div className="SubMenuTextWrapper" style={{}}>
                  <p
                    className="Header"
                    style={{
                      color: ColorUtil(
                        pages[ele.referenceId].text_color,
                        chosenPortfolio.text_color
                      )
                    }}
                  >
                    {ele.title}
                  </p>
                  {subtitle && (
                    <p
                      className="Subtitle"
                      style={{
                        color: ColorUtil(
                          pages[ele.referenceId].text_color,
                          chosenPortfolio.text_color
                        )
                      }}
                    >
                      {subtitle}
                    </p>
                  )}
                </div>
                <div
                  className="IconHolder"
                  style={{
                    backgroundColor: ColorUtil(
                      pages[ele.referenceId].text_color,
                      chosenPortfolio.text_color,
                      'black'
                    ),
                    maskSize: '125%',
                    webkitMaskSize: '125%',
                    maskImage: `url('${
                      pages[ele.referenceId]._type === 'gallery' ||
                      pages[ele.referenceId]._type === 'STgallery'
                        ? pages[ele.referenceId].content.every((VAL) => {
                            return VAL._type === 'galleryImage';
                          })
                          ? Photo
                          : pages[ele.referenceId].content.every((Val) => {
                              return Val._type === 'galleryVideo';
                            })
                          ? Video
                          : pages[ele.referenceId].content.every((Val) => {
                              return Val._type === 'galleryPdf';
                            }) ||
                            pages[ele.referenceId].content.every((Val) => {
                              return Val._type === 'galleryFullWidthPdf';
                            })
                          ? Page
                          : pages[ele.referenceId].content.every((Val) => {
                              return Val._type === 'website';
                            })
                          ? Globe
                          : Mixed
                        : pages[ele.referenceId]._type === 'website' ||
                          pages[ele.referenceId]._type === 'htmlContent'
                        ? Globe
                        : TPBG
                    }')`,
                    // eslint-disable-next-line
                    maskImage: `url('${
                      pages[ele.referenceId]._type === 'gallery' ||
                      pages[ele.referenceId]._type === 'STgallery'
                        ? pages[ele.referenceId].content.every((VAL) => {
                            return VAL._type === 'galleryImage';
                          })
                          ? Photo
                          : pages[ele.referenceId].content.every((Val) => {
                              return Val._type === 'galleryVideo';
                            })
                          ? Video
                          : pages[ele.referenceId].content.every((Val) => {
                              return Val._type === 'galleryPdf';
                            }) ||
                            pages[ele.referenceId].content.every((Val) => {
                              return Val._type === 'galleryFullWidthPdf';
                            })
                          ? Page
                          : pages[ele.referenceId].content.every((Val) => {
                              return Val._type === 'website';
                            })
                          ? Globe
                          : Mixed
                        : pages[ele.referenceId]._type === 'website' ||
                          pages[ele.referenceId]._type === 'htmlContent'
                        ? Globe
                        : TPBG
                    }')`,
                    maskPosition: '50% 50%',
                    maskRepeat: 'no-repeat',
                    webkitMaskImage: `url('${
                      pages[ele.referenceId]._type === 'gallery' ||
                      pages[ele.referenceId]._type === 'STgallery'
                        ? pages[ele.referenceId].content.every((VAL) => {
                            return VAL._type === 'galleryImage';
                          })
                          ? Photo
                          : pages[ele.referenceId].content.every((Val) => {
                              return Val._type === 'galleryVideo';
                            })
                          ? Video
                          : pages[ele.referenceId].content.every((Val) => {
                              return Val._type === 'galleryPdf';
                            }) ||
                            pages[ele.referenceId].content.every((Val) => {
                              return Val._type === 'galleryFullWidthPdf';
                            })
                          ? Page
                          : pages[ele.referenceId].content.every((Val) => {
                              return Val._type === 'website';
                            })
                          ? Globe
                          : Mixed
                        : pages[ele.referenceId]._type === 'website' ||
                          pages[ele.referenceId]._type === 'htmlContent'
                        ? Globe
                        : TPBG
                    }')`,
                    // eslint-disable-next-line
                    webkitMaskImage: `url('${
                      pages[ele.referenceId]._type === 'gallery' ||
                      pages[ele.referenceId]._type === 'STgallery'
                        ? pages[ele.referenceId].content.every((VAL) => {
                            return VAL._type === 'galleryImage';
                          })
                          ? Photo
                          : pages[ele.referenceId].content.every((Val) => {
                              return Val._type === 'galleryVideo';
                            })
                          ? Video
                          : pages[ele.referenceId].content.every((Val) => {
                              return Val._type === 'galleryPdf';
                            }) ||
                            pages[ele.referenceId].content.every((Val) => {
                              return Val._type === 'galleryFullWidthPdf';
                            })
                          ? Page
                          : pages[ele.referenceId].content.every((Val) => {
                              return Val._type === 'website';
                            })
                          ? Globe
                          : Mixed
                        : pages[ele.referenceId]._type === 'website' ||
                          pages[ele.referenceId]._type === 'htmlContent'
                        ? Globe
                        : TPBG
                    }')`,
                    webkitMaskPosition: '50% 50%',
                    webkitMaskRepeat: 'no-repeat'
                  }}
                >
                  {/* {pages[ele.referenceId]._type === "gallery" || pages[ele.referenceId]._type === "STgallery" ?(<img alt="Content" src={pages[ele.referenceId].content.every((VAL)=>{return VAL._type === "galleryImage"}) ? Photo : pages[ele.referenceId].content.every((Val)=>{return Val._type === "galleryVideo"})?Video:pages[ele.referenceId].content.every((Val)=>{return Val._type === "galleryPdf"}) || pages[ele.referenceId].content.every((Val)=>{return Val._type ==="galleryFullWidthPdf"})?Page:pages[ele.referenceId].content.every((Val)=>{return Val._type ==="website"})?Globe:Mixed}></img>):pages[ele.referenceId]._type === "website" || pages[ele.referenceId]._type === "htmlContent"?(<img alt="Icon" src={Globe}></img>):null} */}
                  {
                    //////onsole.log(pages[ele.referenceId])
                    /* ICON HERE TO BE DETERMINED galleryVideo */
                  }
                </div>
                {/* {i !== Target + Limit-1 &&<div style={{gridRow:"2/2", gridColumn:"1/3", backgroundColor:"white"}}></div>} */}
              </div>
              {i !== Target + Limit && (
                <div
                  className="SubMenuItemdivisor"
                  style={{
                    gridRow: '2/2',
                    gridColumn: '1/3',
                    backgroundColor: 'white'
                  }}
                ></div>
              )}
            </>
          );
        })}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          gridRow: '3/3',
          width: '100%',
          bottom: '12px'
        }}
      >
        {Val.children.map((ele, i) => {
          return (
            <div
              key={`k` + i}
              style={{
                height: '12px',
                width: '12px',
                marginLeft: i > 0 ? '6px' : '0px',
                borderRadius: '50%',
                backgroundColor:
                  i === ICalc()
                    ? 'white'
                    : chosenPortfolio.accent_color
                    ? chosenPortfolio.accent_color
                    : ''
                //  (ScrollRef.current?.scrollLeft > ScrollRef.current?.firstChild?.clientWidth * i || ((ScrollRef.current?.scrollLeft === 0 && i === 0) || ScrollRef.current?.scrollLeft ===  ScrollRef.current?.firstChild?.clientWidth * i )) &&
                //  ScrollRef.current?.scrollLeft < ScrollRef.current?.firstChild?.clientWidth * (i+1)?
                //  'white':
                //  chosenPortfolio.accent_color?chosenPortfolio.accent_color:""
              }}
              onClick={() => {
                ScrollRef.current.scrollTo({
                  top: '0px',
                  left: ScrollRef.current?.firstChild?.clientWidth * i,
                  behavior: 'smooth'
                });
              }}
            ></div>
          );
        })}
      </div>
      <div
        className="ColorBar"
        style={{
          backgroundColor: chosenPortfolio.accent_color
            ? chosenPortfolio.accent_color
            : ''
        }}
      />
    </>
  );
};
export default SubMenuItem;

// {Val.children.map((ele,i)=>{
//     //////onsole.log("RENDERSUBITEMS",i, Target)
//     if(i>Target + Limit-1 || i< Target)
//     return(<></>)
//     //////onsole.log(pages[ele.referenceId]);
//     let subtitle = pages[ele.referenceId].subtitle
//     return(<div key={ele.referenceId} className="SubMenuItemWrapper" style={{display:"grid",gridTemplateRows:"1fr 2px", width:"calc(100% * (563 - (61 + 66)) / 563)", height:"120px", gridTemplateColumns:"1fr 90px"}}>
//         <div className="SubMenuTextWrapper" style={{ display:"flex", flexDirection:"column", justifyContent:"center", gridColumn:"1/1", marginLeft:"calc(100% * 13 / (563 - (61 + 66))"}}>
//             <p className="Header">{ele.title}</p>
//             { subtitle && <p className="Subtitle">{subtitle}</p>}
//         </div>
//         <div style={{gridColumn:"2/2"}}>
//             {/* ICON HERE TO BE DETERMINED */}
//         </div>
//         {i !== Target + Limit-1 &&<div style={{gridRow:"2/2", gridColumn:"1/3", backgroundColor:"white"}}></div>}
//     </div>)
// })}

// {(<>
//   {CanGoUp()&&
//   <div onClick={()=>{if(CanGoUp())setTarget(Target-1)}} className="UpArrow" style={{
//     maskImage:`url(${Circle})`,
//       WebkitMaskImage:`url(${Circle})`,
//   }}>
//     <div className="arrow" style={{maskImage:`url(${Arrow})`,
//         WebkitMaskImage:`url(${Arrow})`,}}></div>
// </div>}
// {CanGoDown()&&<div onClick={()=>{if(CanGoDown())setTarget(Target+1)}} className="DownArrow" style={{maskImage:`url(${Circle})`,
//       WebkitMaskImage:`url(${Circle})`,}}>
//     <div className="arrow" style={{maskImage:`url(${Arrow})`,
//         WebkitMaskImage:`url(${Arrow})`,}}></div>
// </div>}
// </>)}

// {Val.children.map((ele,i)=>{
// ////////onsole.log("RENDERSUBITEMS",i, Target)
// if(i > Target + Limit-1 || i < Target)
// return(null)
// ////////onsole.log(pages[ele.referenceId],ele);
// let subtitle = pages[ele.referenceId].subtitle
// return(<div key={ele.referenceId} className="SubMenuItemWrapper" onClick={()=>{
//   SetTopicArray(ele.title,level,ele.referenceId);
// onButtonClick(ele.referenceId,level,ele.children.length>0,ele.children.length>0,i);

// }
// } style={{}}>
//     <div className="SubMenuTextWrapper" style={{ }}>
//         <p className="Header">{ele.title}</p>
//         { subtitle && <p className="Subtitle">{subtitle}</p>}
//     </div>
//     <div className="IconHolder" style={{}}>

//       {pages[ele.referenceId]._type === "gallery" || pages[ele.referenceId]._type === "STgallery" ?(<img src={pages[ele.referenceId].content.every((VAL)=>{return VAL._type === "galleryImage"}) ? Photo : pages[ele.referenceId].content.every((Val)=>{return Val._type === "galleryVideo"})?Video:pages[ele.referenceId].content.every((Val)=>{return Val._type === "galleryPdf"}) || pages[ele.referenceId].content.every((Val)=>{return Val._type ==="galleryFullWidthPdf"})?Page:pages[ele.referenceId].content.every((Val)=>{return Val._type ==="website"})?Globe:Mixed}></img>):pages[ele.referenceId]._type === "website" || pages[ele.referenceId]._type === "htmlContent"?(<img src={Globe}></img>):null}
//         {//////onsole.log(pages[ele.referenceId]) /* ICON HERE TO BE DETERMINED galleryVideo */}
//     </div>
//     {i !== Target + Limit-1 &&<div style={{gridRow:"2/2", gridColumn:"1/3", backgroundColor:"white"}}></div>}
// </div>)
// })}
