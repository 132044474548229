import React, { useState, useEffect } from 'react';
import { arrayToObject } from '../../../../../../../utils/arrayHelper';
import { urlFor } from '../../../../../../../utils/sanityHelper';
import MagnifyingGlass from '../../../../../../../assets/RootSVGS/MagnifyingGlass.js';
import BASlider from '../../../../../../BeforeAfterSlider';
import PDFViewer2 from '../../../../../../FullWidthPDFViewer';
import styled from 'styled-components';
import ToggleMenu from '../../../../../../_Portfolio/ToggleMenu/ToggleMenu';
import SanityMuxPlayer from '../../../../../../../containers/MuxPlayer';
import CloseIcon from '../../../../../../../containers/PageMenus/Menu9-Storybook/CloseSVG';
import BlockContent from '@sanity/block-content-to-react';
import serializers from '../../../../../../../utils/scriptSerializers';
//flexShrink:"0",backgroundColor:"#FCFAFA", height:"192px", display:"flex",
// justifyContent:"center",alignItems:"center"
const HoverHideDiv = styled.div`
  flex-shrink: 0;
  background-color: #fcfafa;
  height: 192px;
  display: flex;
  justify-content: center;
  z-index: 0;
  align-items: center;
  border-radius: 15%;
  overflow: hidden;
  overflow: clip;
  &:hover {
    filter: drop-shadow(3px 3px 4px #808080);
    .test {
      display: flex !important;
      justify-content: center;
      align-items: center;
    }
  }
`;
const LikeItems = ({
  Ele,
  Size,
  Pages,
  chosenPortfolio,
  videos,
  pdfs,
  SetPI
}) => {
  const [GallTarget] = useState(Math.max(Ele.gallery_index, 0)); //SetGalTarget
  const [Page, SetPage] = useState(Pages[Ele.page_id]); //SetPage
  const [PageType, SetPageType] = useState(Pages[Ele.page_id]?._type);
  const [Content, SetContent] = useState(undefined);
  const [type, SetType] = useState(undefined);
  console.log('LikesRender', Ele, Page, PageType, Content, type);
  useEffect(() => {
    if (Pages[Ele.page_id]) SetPage(Pages[Ele.page_id]);
  }, [GallTarget, Pages, Ele]);
  useEffect(() => {
    if (Pages[Ele.page_id]) SetPageType(Pages[Ele.page_id]?._type);
  }, [GallTarget, Pages, Ele]);
  useEffect(() => {
    //onsole.log("Page",Page);
    Page?.content
      ? Page?.content[GallTarget]
        ? SetContent(Page?.content[GallTarget])
        : SetContent(undefined)
      : SetContent(undefined);
  }, [Page, GallTarget]);
  useEffect(() => {
    Content?._type
      ? SetType(Content?._type)
      : Page?._type
      ? SetType(Page?._type)
      : SetType(undefined);
  }, [Content, Page]);

  const blockContent = (blocks) => {
    // filter non valid imageLinks
    let tempBlocks = blocks.filter((obj) => {
      if (obj._type === 'imageLinks' && obj.image) {
        return true;
      }
      if (obj._type !== 'imageLinks') {
        return true;
      }
      return false;
    });

    // further filter non valid embedHtml
    tempBlocks = tempBlocks.filter((obj) => {
      if (obj._type === 'embedHTML' && obj.html) {
        return true;
      }
      if (obj._type !== 'embedHTML') {
        return true;
      }
      return false;
    });
    return tempBlocks;
  };
  //type, galleryNum, isSelected, IsGal,Id,SetBufferFunctions,BufferGal,BufferRender,isVis
  const renderSingleContent = (Decay) => {
    if (Decay > 4) return <p>Thumbnail Not renderable</p>;
    // try{
    if (Ele.id.includes('splash')) {
      console.log(Pages, chosenPortfolio);
      switch (chosenPortfolio.splash[Math.max(Ele.gallery_index, 0)]._type) {
        case 'galleryImage': {
          let Content = chosenPortfolio.splash[Math.max(Ele.gallery_index, 0)];
          let image = Content.image;
          return (
            <img
              className="portfolio-single-image"
              src={urlFor(image).auto('format').url()} // auto converts to webp if browser supports it
              alt="mainImage"
              type="galleryImage"
              style={{
                opacity:
                  chosenPortfolio && chosenPortfolio.theme === 'theme2' ? 1 : 1,
                width: '100%',
                height: '100%',
                objectFit: 'contain'
              }}
            />
          );
        }
        case 'galleryVideo': {
          let Content = chosenPortfolio.splash[Math.max(Ele.gallery_index, 0)];
          let video = videos[Content.video.asset._ref];
          let videoSettings = Content;
          ////////onsole.log(videoSettings);
          return (
            <SanityMuxPlayer
              // chosenPortfolio={chosenPortfolio}
              chosenContentPageId={Ele.page_id}
              isContent={true}
              assetDocument={video}
              autoload={true}
              autoplay={videoSettings.autoplay}
              loop={videoSettings.loop}
              SIG={
                Ele.page_id === `splashkey-${chosenPortfolio?._id}` && true
                  ? false
                  : videoSettings.SIG
              }
              SIP={
                Ele.page_id === `splashkey-${chosenPortfolio?._id}` && true
                  ? false
                  : videoSettings.SIP
              }
              muted={false}
              showControls={true}
              style={{}}
              posterurl={`https://image.mux.com/${video.playbackId}/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=1`}
              type="galleryVideo"
              // isSelected={isSelected}
              Size={Size}
              screensaverShow={true}
            />
          );
        }
        default:
          return <div></div>;
      }
    }
    //onsole.log(PageType);
    if (PageType === 'htmlContent') {
      return (
        <div
          className="html-block-content"
          style={{ backgroundColor: '#FCFAFA' }}
        >
          <BlockContent
            blocks={blockContent(Page.content)}
            serializers={serializers}
          />
        </div>
      );
    }
    if (PageType === 'website') {
      // //onsole.log
      return (
        <div
          style={{ height: '100%', width: '100%', position: 'relative' }}
          className="iframe-container"
        >
          <iframe
            title="iframe"
            allowFullScreen
            src={Page.url}
            style={{
              backgroundColor: 'transparent',
              height: '100%',
              width: '100%',
              scrollbarWidth: 'none',
              display: 'block',
              border: 'none' /* Reset default border */
            }}
          ></iframe>
        </div>
      );
    }
    if (PageType === 'gallery' || PageType === 'sliderGallery') {
      switch (type) {
        case 'galleryImage': {
          let image = Content.image;
          return (
            <img
              className="portfolio-single-image"
              src={urlFor(image).auto('format').url()} // auto converts to webp if browser supports it
              alt="mainImage"
              type="galleryImage"
              style={{
                opacity:
                  chosenPortfolio && chosenPortfolio.theme === 'theme2' ? 1 : 1,
                width: '100%',
                height: '100%',
                objectFit: 'contain'
              }}
            />
          );
        }
        case 'galleryVideo': {
          let video = videos[Content.video.asset._ref];
          let videoSettings = Content;
          ////////onsole.log(videoSettings);
          return (
            <SanityMuxPlayer
              // chosenPortfolio={chosenPortfolio}
              chosenContentPageId={Ele.page_id}
              isContent={true}
              assetDocument={video}
              autoload={true}
              autoplay={videoSettings.autoplay}
              loop={videoSettings.loop}
              SIG={
                Ele.page_id === `splashkey-${chosenPortfolio?._id}` && true
                  ? false
                  : videoSettings.SIG
              }
              SIP={
                Ele.page_id === `splashkey-${chosenPortfolio?._id}` && true
                  ? false
                  : videoSettings.SIP
              }
              muted={false}
              showControls={true}
              style={{}}
              posterurl={`https://image.mux.com/${video.playbackId}/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=1`}
              type="galleryVideo"
              // isSelected={isSelected}
              Size={Size}
              screensaverShow={true}
            />
          );
        }
        case 'galleryPdf': {
          //////onsole.log("!!TITLE!!",Content.title);
          let url2 = pdfs[Content.file.asset._ref].url;
          let thumbNailRef2 = Content.thumbnail.asset._ref;
          let thumbNailUrl2 = urlFor(thumbNailRef2)
            .auto('format')
            .width(100)
            .url();
          let thumbNailUrl3 = urlFor(thumbNailRef2)
            .auto('format')
            .width(1000)
            .url();
          return (
            <PDFViewer2
              // isVis={isVis}
              // BufferGal ={BufferGal}
              // BufferRender = {BufferRender}
              // SetBufferFunctions={SetBufferFunctions}
              // ID={Id}
              // IsGal={IsGal}
              url={url2}
              isThumbnail={false}
              TThumb={thumbNailUrl3}
              type="galleryFullWidthPdf"
              thumbnailUrl={thumbNailUrl2}
              title={Content.title}
            />
          );
        }
        case 'galleryFullWidthPdf': {
          //////onsole.log("!!TITLE!!",Content.title);
          let url3 = pdfs[Content.file.asset._ref].url;
          let thumbNailRef3 = Content.thumbnail.asset._ref;
          let thumbNailUrl4 = urlFor(thumbNailRef3)
            .auto('format')
            .width(100)
            .url();
          let thumbNailUrl5 = urlFor(thumbNailRef3)
            .auto('format')
            .width(1000)
            .url();
          return (
            <PDFViewer2
              // isVis={isVis}
              //   BufferGal ={BufferGal}
              //   BufferRender = {BufferRender}
              //   SetBufferFunctions={SetBufferFunctions}
              //   ID={Id}
              //   IsGal={IsGal}
              url={url3}
              isThumbnail={false}
              TThumb={thumbNailUrl5}
              type="galleryFullWidthPdf"
              thumbnailUrl={thumbNailUrl4}
              title={Content.title}
            />
          );
        }
        case 'galleryBASlider': {
          let Aimage = Content.Aimage;
          let Bimage = Content.Bimage;
          let LabelA;
          let LabelB;
          if (Content.Labels !== false) {
            LabelA = Content.AimageL;
            LabelB = Content.BimageL;
          } else {
            LabelA = '';
            LabelB = '';
          }
          let Vert = Content.Vert;
          let Color = Content.SlideColor;
          //////onsole.log('!!!!BA!!!!', Content)
          return (
            <BASlider
              style={{ maxWidth: '90%' }}
              LabelB={LabelB}
              LabelA={LabelA}
              Color={Color}
              Vert={Vert}
              ImageA={Aimage}
              ImageB={Bimage}
            />
          );
        }
        case 'toggleMenu': {
          let firstContent = Content.content[0];
          let secondContent = Content.content[1];
          //////onsole.log('TOGGLE MENU', Content);
          // return;
          //onsole.log(Page,PageType, Content);
          return (
            <ToggleMenu
              style={{ width: '100%' }}
              chosenPortfolio={chosenPortfolio}
              firstContent={firstContent}
              secondContent={secondContent}
              videos={videos}
              pdfs={pdfs}
              // currentNavItem={currentNavItem}
              // contentLength={contents.length}
            />
          );
        }
        // case 'sliderGallery':
        //   {
        // //////onsole.log('TOGGLE MENU', Content);
        // return;
        // return (
        //   <ToggleMenu
        //     firstContent={firstContent}
        //     secondContent={secondContent}
        //     videos={videos}
        //     pdfs={pdfs}
        //     currentNavItem={currentNavItem}
        //     contentLength={contents.length}
        //   />
        // );
        // }
        default:
        // throw "err";
      }
    }
    console.log(Page, PageType, Content, Ele);
    // }
    // catch{
    //   sleep(1000).then(()=>{
    //     return renderSingleContent(Decay+1);
    //   })

    // }
  };

  //onsole.log("Type",type)
  const renderSingleThumb = (Decay) => {
    if (Decay > 4) return <p>Thumbnail Not renderable</p>;
    console.log('LikesRenderThumb', Ele, Page, PageType, Content, type);
    // try{
    if (Ele.id.includes('splash')) {
      console.log(Pages, chosenPortfolio);
      let Content = chosenPortfolio.splash[Math.max(Ele.gallery_index, 0)];
      switch (chosenPortfolio.splash[Math.max(Ele.gallery_index, 0)]._type) {
        case 'galleryImage': {
          let image = Content.image;
          return (
            <img
              className="portfolio-single-image"
              src={urlFor(image).auto('format').width(100).url()} // auto converts to webp if browser supports it
              alt="mainImage"
              type="galleryImage"
              style={{
                opacity: chosenPortfolio.theme === 'theme2' ? 1 : 1,
                width: '100%',
                height: '100%',
                objectFit: 'contain'
              }}
            />
          );
        }
        case 'galleryVideo': {
          let video = videos[Content.video.asset._ref];
          //let videoSettings = Content;
          return (
            <img
              style={{ height: '100%', width: '100%', objectFit: 'contain' }}
              src={`https://image.mux.com/${
                video.playbackId
              }/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=${
                video.thumbTime !== 0 ? video.thumbTime : 1
              }`}
              alt="poster"
            />
            // <SanityMuxPlayer
            //   assetDocument={video}
            //   autoload={true}
            //   autoplay={videoSettings.autoplay}
            //   loop={videoSettings.loop}
            //   muted={false}
            //   showControls={true}
            //   style={{}}
            //   posterurl={`https://image.mux.com/${video.playbackId}/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=1`}
            //   type="galleryVideo"
            //   isSelected={isSelected}
            // />
          );
        }
        default:
          return <div></div>;
      }
    }
    if (PageType === 'htmlContent') {
      return (
        <div
          className="html-block-content"
          style={{ backgroundColor: '#FCFAFA' }}
        >
          <BlockContent
            blocks={blockContent(Page.content)}
            serializers={serializers}
          />
        </div>
      );
    }
    if (PageType === 'website') {
      // //onsole.log
      return (
        <div
          style={{ height: '100%', width: '100%', position: 'relative' }}
          className="iframe-container"
        >
          <iframe
            title="iframe"
            allowFullScreen
            src={Page.url}
            style={{
              backgroundColor: 'transparent',
              height: '100%',
              width: '100%',
              pointerEvents: 'none',
              scrollbarWidth: 'none',
              display: 'block',
              border: 'none' /* Reset default border */
            }}
          ></iframe>
        </div>
      );
    }
    if (PageType === 'gallery' || PageType === 'sliderGallery')
      switch (type) {
        case 'galleryImage': {
          let image = Content.image;
          return (
            <img
              className="portfolio-single-image"
              src={urlFor(image).auto('format').width(100).url()} // auto converts to webp if browser supports it
              alt="mainImage"
              type="galleryImage"
              style={{
                opacity: chosenPortfolio.theme === 'theme2' ? 1 : 1,
                width: '100%',
                height: '100%',
                objectFit: 'contain'
              }}
            />
          );
        }
        case 'galleryVideo': {
          let video = videos[Content.video.asset._ref];
          //let videoSettings = Content;
          return (
            <img
              style={{ height: '100%', width: '100%', objectFit: 'contain' }}
              src={`https://image.mux.com/${
                video.playbackId
              }/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=${
                video.thumbTime !== 0 ? video.thumbTime : 1
              }`}
              alt="poster"
            />
            // <SanityMuxPlayer
            //   assetDocument={video}
            //   autoload={true}
            //   autoplay={videoSettings.autoplay}
            //   loop={videoSettings.loop}
            //   muted={false}
            //   showControls={true}
            //   style={{}}
            //   posterurl={`https://image.mux.com/${video.playbackId}/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=1`}
            //   type="galleryVideo"
            //   isSelected={isSelected}
            // />
          );
        }
        case 'galleryPdf': {
          let url2 = pdfs[Content.file.asset._ref].url;
          let thumbNailRef2 = Content.thumbnail.asset._ref;
          let thumbNailUrl2 = urlFor(thumbNailRef2)
            .auto('format')
            .width(100)
            .url();
          return (
            <PDFViewer2
              url={url2}
              isThumbnail={true}
              type="galleryFullWidthPdf"
              thumbnailUrl={thumbNailUrl2}
            />
          );
        }
        case 'galleryBASlider': {
          let Aimage = Content.Aimage;
          let Bimage = Content.Bimage;
          let LabelA = Content.AimageL;
          let LabelB = Content.BimageL;
          let Vert = Content.Vert;
          let Color = Content.SlideColor;
          //////onsole.log('!!!!BA!!!!', Content)
          return (
            <BASlider
              Thumb
              LabelB={LabelB}
              LabelA={LabelA}
              Color={Color}
              Vert={Vert}
              ImageA={Aimage}
              ImageB={Bimage}
            />
          );
        }
        case 'galleryFullWidthPdf': {
          let url3 = pdfs[Content.file.asset._ref].url;
          let thumbNailRef3 = Content.thumbnail.asset._ref;
          let thumbNailUrl3 = urlFor(thumbNailRef3)
            .auto('format')
            .width(100)
            .url();
          return (
            <PDFViewer2
              url={url3}
              isThumbnail={true}
              type="galleryFullWidthPdf"
              thumbnailUrl={thumbNailUrl3}
            />
          );
        }
        case 'toggleMenu': {
          let firstContent = Content.content[0];
          let secondContent = Content.content[1];
          //////onsole.log('TOGGLE MENU', Content);
          // return;
          //onsole.log(Page,PageType, Content);
          return (
            <div
              style={{ height: '100%', width: '100%', position: 'relative' }}
              className="iframe-container"
            >
              <ToggleMenu
                style={{ width: '100%' }}
                chosenPortfolio={chosenPortfolio}
                firstContent={firstContent}
                secondContent={secondContent}
                videos={videos}
                pdfs={pdfs}
                // currentNavItem={currentNavItem}
                // contentLength={contents.length}
              />
            </div>
          );
        }
        default:
          // throw "err"
          console.log('Test Error');
          return <p>Thumbnail issue</p>;
      }
    // }
    // catch{
    //   sleep(1000).then(()=>{
    //   console.log("Decay",Decay)
    //   return [renderSingleThumb(Decay+1)]
    //   })

    // }
    return <p>Thumbnail issue</p>;
  };
  // function sleep(ms) {
  //   return new Promise(resolve => setTimeout(resolve, ms));
  // }

  //onsole.log(Content)
  return (
    <div
      onClick={async () => {
        SetPI(renderSingleContent(0));
      }}
      style={{
        height: '288px',
        margin: '0px 2.5%',
        width: '20%',
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      {/* <div style={{flexShrink:"0",backgroundColor:"#FCFAFA", height:"192px", display:"flex", justifyContent:"center",alignItems:"center"}}> */}
      {/* </div> */}
      <HoverHideDiv>
        {renderSingleThumb(0)}
        <div
          className="test"
          style={{
            backgroundColor: '#000000a0',
            position: 'absolute',
            height: '48px',
            width: '48px',
            display: 'none',
            top: 'calc(50% - 24px)',
            left: 'calc(50% - 24px)',
            borderRadius: '50%'
          }}
        >
          <MagnifyingGlass
            style={{ height: '70%', width: '70%', color: 'white' }}
          />
        </div>
      </HoverHideDiv>
      <p style={{ textAlign: 'center' }}>{Ele.title}</p>
    </div>
  );
};

const LT = ({
  Sessions,
  Session,
  portfolios,
  Dash,
  videos,
  pdfs,
  Size,
  SetChosenPortfolio,
  EndPortfolio
}) => {
  const [DesiredPort, SetDesiredPort] = useState(undefined);
  const [PreviewItem, SetPI] = useState(undefined);
  useEffect(() => {
    console.log(
      'portfolios',
      portfolios,
      portfolios.find((ele) => {
        return ele._id === Dash.selectedPortfolioId;
      })
    );
    SetDesiredPort(
      portfolios.find((ele) => {
        return ele._id === Dash.selectedPortfolioId;
      })
    );
  }, [Dash.selectedPortfolioId, portfolios]);

  //onsole.log(Sessions);
  //Find Portfolio._id->Find Page._key === ele.page--if ele.id.includes("page-sub") take firs content, else use gallery index

  const [Likes, SetLikes] = useState([]);
  useEffect(() => {
    if (Session?.liked) SetLikes(Session?.liked);
    else {
      let endAggregate = [];
      Sessions.forEach((ele) => {
        if (ele.liked)
          ele.liked.forEach((ele2) => {
            //onsole.log(ele2)
            if (
              endAggregate.some((ele3) => {
                return (
                  ele3.page_id === ele2.page_id &&
                  ele3.gallery_index === ele2.gallery_index
                );
              })
            )
              return null;
            else {
              endAggregate.push(ele2);
            }
          });
      });
      SetLikes([...endAggregate]);
    }
  }, [Session, Sessions]);
  const [Pages, SetPages] = useState({});
  useEffect(() => {
    DesiredPort?.pages
      ? SetPages(arrayToObject(DesiredPort?.pages, '_key'))
      : SetPages({});
  }, [DesiredPort]);
  // useEffect(()=>{
  //   console.log("DesiredPort",DesiredPort)
  //   if(DesiredPort)
  //   SetChosenPortfolio(DesiredPort);
  //   return (()=>{console.log("UnsetPort");EndPortfolio()})
  // },[DesiredPort])
  //useEffect(()=>{},[DesiredPort,Likes])

  // const test = ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",]
  return (
    <>
      <div
        onClick={(eve) => {
          if (eve.target === eve.currentTarget) SetPI(undefined);
        }}
        style={{
          display: PreviewItem === undefined ? 'none' : 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          top: '0px',
          left: '0px',
          backgroundColor: '#000000a0',
          height: '100%',
          width: '100%',
          zIndex: 2
        }}
      >
        {PreviewItem}
        <CloseIcon
          onClick={() => {
            SetPI(undefined);
          }}
          style={{
            position: 'absolute',
            top: '0px',
            right: '0px',
            width: '48px',
            height: '48px'
          }}
        />
      </div>
      <div
        style={{
          gridColumn: 2,
          overflow: 'scroll',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          position: 'relative',
          maxHeight: 'calc(100vh * 552.35/1080)'
        }}
      >
        {Likes.map((ele) => {
          return (
            <LikeItems
              Size={Size}
              SetPI={SetPI}
              key={ele.page_id + ele.gallery_index}
              chosenPortfolio={DesiredPort}
              videos={videos}
              pdfs={pdfs}
              Pages={Pages}
              Ele={ele}
            ></LikeItems>
          );
        })}
      </div>
    </>
  );
};
export default LT;
