import { connect } from 'react-redux';
import LoginPage from './LoginPage';
import {
  updateSessionKey,
  firestoreRequest,
  firestoreSuccess,
  firestoreFailure
} from '../../actions/sessionActions';
import {
  setLoginInputSelected,
  setLoginInputSelectedCount
} from '../../actions';

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    userInfo: state.firestore.ordered.users,
    sanityContent: state.sanityContentReducer,
    loginInputSelected: state.portfolioReducer.loginInputSelected,
    loginInputSelectedCount: state.portfolioReducer.selectedCount,
    Size: state.portfolioReducer.Size
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSessionKey: (key) => dispatch(updateSessionKey(key)),
    firestoreRequest: () => dispatch(firestoreRequest()),
    firestoreSuccess: () => dispatch(firestoreSuccess()),
    firestoreFailure: () => dispatch(firestoreFailure()),
    setLoginInputSelected: (bool) => dispatch(setLoginInputSelected(bool)),
    setLoginInputSelectedCount: (num) =>
      dispatch(setLoginInputSelectedCount(num))
  };
};

const LoginPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPage);

export default LoginPageContainer;
