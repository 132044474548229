import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './styles/index.scss';
import App from './containers/App';
import configureStore from './store';
import * as serviceWorker from './serviceWorker';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore'; // for firestore
import { firebaseConfig, rrfConfig } from './firebase.config';
import { createFirestoreInstance } from 'redux-firestore';
import { savePortfolioState } from './utils/localStorageHelper';
import throttle from 'lodash/throttle';

const store = configureStore();

// save portfolio info (mode, id, customer info) to localStorage
store.subscribe(
  throttle(() => {
    savePortfolioState(store.getState().portfolioReducer);
  }, 1000)
);

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance // <- needed if using firestore
};
////////onsole.log(process.env);
// Initialize firebase instance
firebase.initializeApp(firebaseConfig);

// Initialize other services on firebase instance
firebase.firestore();

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <App />
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
