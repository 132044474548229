import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Document, Page, pdfjs } from 'react-pdf';
//import IMG from '../../containers/LoadingPage/loading-screen-1.gif'
//import IMG2 from '../../containers/LoadingPage/styledloadwheel.html'

// import ZI from '../../assets/Icons/Zoom In.png'
// import ZO from '../../assets/Icons/Zoom Out.png'
// import D from '../../assets/Icons/Download.png'
// import { IconContext } from 'react-icons';
import Icons from '../../containers/Icons';
const IMG2 = 'https://f2bportfolio.com/styledloadwheel.html';
//import Select from 'react-select';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Wrapp = styled.div`
  & .BoxShadow {
    box-shadow: 3px 3px 5px #808080;
  }
`;

// const StyledPDFViewer = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   height: 100%;
// `;

// const StyledPDFContent2 = styled.div`
//   width: 100%;
//   height: 100%;
// `;
// /*
// const StyledPDFDropdown = styled.div`
//   position: absolute;
//   top: -30px;
//   right: 0px;
// `;
// */

const PDFViewer = ({
  url,
  isThumbnail,
  thumbnailUrl,
  title,
  TThumb,
  chosenPortfolio,
  IsGal,
  ID,
  isVis
}) => {
  //SetBufferFunctions,BufferRender,BufferGal
  const [numPages, setNumPages] = useState(null);
  const [opacity, setOpacity] = useState(0);
  const [ZoomFactor, SetZoomFactor] = useState(1);
  const [pageNumber] = useState(1);
  const [Loaded, SetLoaded] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [Hold, SetHold] = useState(IsGal);
  //const [Vis,SetVis] = useState(isVis);//BufferGal.find((ele)=>{return ele.key === ID}).IsVisible
  useEffect(() => {
    ////////////onsole.log(DivRef.current ? DivRef.current.clientWidth : 0);
  });
  const onDocumentLoadSuccess = (eve) => {
    // create dropdown options
    //let test = Hold;
    //////onsole.log("Source",eve,eve.numPages);
    setNumPages(eve.numPages);

    // let arr = [];
    // for (let i = 0; i < numPages; i++) {
    //   arr.push({ value: i + 1, label: (i + 1).toString() });
    // }
    // setDropdownOptions(arr);
  };
  /*
  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };
  
  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  // react-select styles
  
  const styles = {
    menuList: (base) => ({
      ...base,
      minHeight: 'fit-content'
    }),
    control: (base) => ({
      ...base,
      width: '150px',
      height: '34px'
    }),
    container: (base) => ({
      ...base,
      width: '150px',
      textAlign: 'right',
      fontSize: '22px'
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0
    }),
    clearIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0
    })
  };
*/
  const DivRef = useRef(null);
  //let P = [];
  const [P2, SetP2] = useState([]);
  ////////////onsole.log(DivRef.current ? DivRef.current.clientWidth : 0);
  useEffect(() => {
    let P3 = [];

    P3.push(
      <Wrapp
        key={`${0}-Wrapp`}
        style={{
          height: 'min-content',
          width: `${
            (DivRef.current ? DivRef.current.clientWidth : undefined) *
            ZoomFactor
          }px`,
          display: 'flex',
          justifyContent: 'center',
          minWidth: '100%'
        }}
      >
        <Page
          scale={ZoomFactor}
          onRenderError={(eve) => console.error('PageRender', eve)}
          onLoadError={(eve) => console.error('Pageload', eve)}
          onGetAnnotationsError={(eve) => console.error('Pageannotations', eve)}
          //onLoadSuccess={(eve)=>//////onsole.log("pageLoad",eve)}
          width={DivRef.current ? DivRef.current.clientWidth : undefined}
          loading={null}
          renderAnnotationLayer={false}
          onRenderSuccess={(eve) => {
            //////onsole.log("PageRender",eve);
            setOpacity(1);
            SetLoaded(true);
          }}
          pageNumber={1}
          alignSelf="center"
          style={{ alignSelf: 'center' }}
          className={['BoxShadow']}
          //Transform to center?
        ></Page>
      </Wrapp>
    );
    // SetP2([...P2,(
    //   <Wrapp key={`${0}-Wrapp`} style={{height:"min-content", width: `${(DivRef.current ? DivRef.current.clientWidth : undefined)*ZoomFactor}px`, display:"flex",justifyContent:"center", minWidth:"100%"}}>
    // <Page
    // scale={ZoomFactor}
    //   width={DivRef.current ? DivRef.current.clientWidth : undefined}
    //   loading={null}
    //   renderAnnotationLayer={false}
    //   onRenderSuccess={() => {setOpacity(1);SetLoaded(true)}}
    //   pageNumber={1}
    //   alignSelf="center"
    //   style={{alignSelf:"center"}}
    //   className={['BoxShadow']}
    //   //Transform to center?
    // ></Page>
    // </Wrapp>
    // )])
    for (let b = 1; b < numPages; b++) {
      P3.push(
        <div
          key={`${b}-Div`}
          style={{
            width: `${
              (DivRef.current ? DivRef.current.clientWidth : undefined) *
              ZoomFactor
            }px`,
            minWidth: '100%',
            height: '8px',
            backgroundColor: '#a2a2a200'
          }}
        ></div>
      );
      // SetP2([...P2,(<div
      //   key={`${b}-Div`}
      //     style={{ width: `${(DivRef.current ? DivRef.current.clientWidth : undefined)*ZoomFactor}px`, minWidth:"100%", height: '8px', backgroundColor: '#a2a2a200' }}
      //   ></div>)]
      // );
      P3.push(
        <Wrapp
          key={`${b}-Wrapp`}
          style={{
            height: 'min-content',
            width: `${
              (DivRef.current ? DivRef.current.clientWidth : undefined) *
              ZoomFactor
            }px`,
            display: 'flex',
            justifyContent: 'center',
            minWidth: '100%'
          }}
        >
          <Page
            scale={ZoomFactor}
            width={DivRef.current ? DivRef.current.clientWidth : undefined}
            loading={null}
            //renderMode="svg"
            renderAnnotationLayer={false}
            pageNumber={b + 1}
            onRenderSuccess={() => {
              SetLoaded(true);
            }}
            alignSelf="center"
            alignItems="center"
            style={{ boxShadow: '3px 3px 5px #808080' }}
            className={['BoxShadow']}
            //style={{alignSelf:"center"}}
          ></Page>
        </Wrapp>
      );
      // SetP2([...P2, (
      //   <Wrapp key={`${b}-Wrapp`} style={{ height:"min-content", width: `${(DivRef.current ? DivRef.current.clientWidth : undefined)*ZoomFactor}px`, display:"flex",justifyContent:"center",minWidth:"100%"}}>
      //   <Page
      //   scale={ZoomFactor}
      //     width={DivRef.current ? DivRef.current.clientWidth : undefined}
      //     loading={null}
      //     //renderMode="svg"
      //     renderAnnotationLayer={false}
      //     pageNumber={b + 1}
      //     onRenderSuccess={()=>{SetLoaded(true);}}
      //     alignSelf="center"
      //     alignItems="center"
      //     style={{boxShadow: "3px 3px 5px #808080"}}
      //     className={['BoxShadow']}
      //     //style={{alignSelf:"center"}}
      //   ></Page>
      //   </Wrapp>
      // )])
    }
    SetP2(P3);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DivRef, ZoomFactor, numPages]);
  // if (DivRef.current|| true){
  //   P.push(
  //     <Wrapp key={`${0}-Wrapp`} style={{height:"min-content", width: `${(DivRef.current ? DivRef.current.clientWidth : undefined)*ZoomFactor}px`, display:"flex",justifyContent:"center", minWidth:"100%"}}>
  //     <Page
  //     onRenderError={(eve)=>console.error("PageRender",eve)}
  //     onLoadError={(eve)=>console.error("Pageload",eve)}
  //     onGetAnnotationsError={(eve)=>console.error("Pageannotations",eve)}
  //     onLoadSuccess={(eve)=>//////onsole.log("pageLoad",eve)}
  //     scale={ZoomFactor}
  //       width={DivRef.current ? DivRef.current.clientWidth : undefined}
  //       loading={null}
  //       renderAnnotationLayer={false}
  //       onRenderSuccess={(eve) => {//////onsole.log("PageRender",eve);setOpacity(1);SetLoaded(true)}}
  //       pageNumber={1}
  //       alignSelf="center"
  //       style={{alignSelf:"center"}}
  //       className={['BoxShadow']}
  //       //Transform to center?
  //     ></Page>
  //     </Wrapp>
  //   );
  // }
  // if (opacity === 1 && P.length>0) {
  //   for (let b = 1; b < numPages; b++) {
  //     //let Wait = true;
  //     P.push(
  //       <div
  //       key={`${b}-Div`}
  //         style={{ width: `${(DivRef.current ? DivRef.current.clientWidth : undefined)*ZoomFactor}px`, minWidth:"100%", height: '8px', backgroundColor: '#a2a2a200' }}
  //       ></div>
  //     );
  //     P.push(
  //       <Wrapp key={`${b}-Wrapp`} style={{ height:"min-content", width: `${(DivRef.current ? DivRef.current.clientWidth : undefined)*ZoomFactor}px`, display:"flex",justifyContent:"center",minWidth:"100%"}}>
  //       <Page
  //       scale={ZoomFactor}
  //         width={DivRef.current ? DivRef.current.clientWidth : undefined}
  //         loading={null}
  //         //renderMode="svg"
  //         renderAnnotationLayer={false}
  //         pageNumber={b + 1}
  //         onRenderSuccess={()=>{SetLoaded(true);}}
  //         alignSelf="center"
  //         alignItems="center"
  //         style={{boxShadow: "3px 3px 5px #808080"}}
  //         className={['BoxShadow']}
  //         //style={{alignSelf:"center"}}
  //       ></Page>
  //       </Wrapp>
  //     );
  //   }
  // }
  //   function waitforme(milisec) {
  //     return new Promise(resolve => {
  //         setTimeout(() => { resolve('') }, milisec);
  //     })
  // }
  const downloadFile = () => {
    // let string = "";
    fetch(url).then((response) => {
      ////////onsole.log(response);
      const element = document.createElement('a');
      response.blob().then((conv) => {
        element.target = '_blank';
        const file = conv; //new Blob([conv], { type: 'application/pdf' });
        element.href = URL.createObjectURL(file);
        element.download = title + '.pdf';
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
      });
      //   // conv = atob(conv.replace(/\s/g, ''));
      //   // let conv2 = new ArrayBuffer(conv.length)
      //   // let conv3 = new Uint8Array(conv2)
      //   // for(var i = 0; i< conv.length;i++){
      //   //   conv3[i] = conv.charCodeAt(i);
      //   // }
      //   const file = conv //new Blob([conv], { type: 'application/pdf' });
      //   element.href = URL.createObjectURL(file);
      //   element.download = title+'.pdf';
      //   document.body.appendChild(element); // Required for this to work in FireFox
      // element.click();
    });

    // const element = document.createElement('a');

    // element.href = URL.createObjectURL(file);
    // element.download = 'Emails.txt';
    // document.body.appendChild(element); // Required for this to work in FireFox
    // element.click();
  };
  // let primaryColor
  // if(chosenPortfolio.action_menu_color)
  // primaryColor =
  // chosenPortfolio.action_menu_color !== ''
  //   ? chosenPortfolio.action_menu_color
  //   : 'gray';
  //   else
  //   primaryColor =
  //   chosenPortfolio.primary_color !== ''
  //   ? chosenPortfolio.primary_color
  //   : 'gray'
  if (isThumbnail) {
    return (
      <img
        className="portfolio-single-image"
        src={thumbnailUrl}
        alt="pdf-thumbnail"
      />
    );
  }
  if (isVis || !IsGal)
    return (
      <>
        {/* <img className="portfolio-single-image" src={thumbnailUrl} alt="pdf-thumbnail" /> */}
        {!Loaded && !isThumbnail ? (
          <>
            <img
              className="portfolio-single-image"
              src={TThumb}
              alt="pdf-thumbnail"
              style={{
                position: 'absolute',
                height: 'calc(100% - 38px)',
                width: '100%',
                top: '38px',
                left: '0px',
                transition: 'all 750ms'
              }}
            />
            <embed
              title="loading"
              style={{
                position: 'absolute',
                height: '100%',
                width: '100%',
                top: '0px',
                left: '0px',
                transition: 'all 750ms'
              }}
              alt="loading"
              src={IMG2}
            ></embed>
          </>
        ) : (
          <>
            <img
              className="portfolio-single-image"
              src={TThumb}
              alt="pdf-thumbnail"
              style={{
                position: 'absolute',
                height: '0%',
                width: '0%',
                opacity: '0%',
                top: '38px',
                left: '0px',
                transition: 'all 750ms',
                transform: 'scale(0, 0)'
              }}
            />
            <embed
              title="loading Complete"
              alt="loading"
              style={{
                position: 'absolute',
                height: '0%',
                width: '0%',
                opacity: '0%',
                top: '0px',
                left: '0px',
                transition: 'all 750ms',
                transform: 'scale(0, 0)'
              }}
              src={IMG2}
            ></embed>
          </>
        )}
        {isThumbnail ? (
          <img
            className="portfolio-single-image"
            src={thumbnailUrl}
            alt="pdf-thumbnail"
          />
        ) : (
          <section
            style={{
              visibility: isVis || !IsGal ? 'visible' : 'collapse',
              width: '100%',
              maxWidth: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto'
            }}
            className="whole-pdf"
          >
            {/* <div style={{height:"38px", display:"flex", flexDirection:"row", justifyContent:"flex-end", backgroundColor:primaryColor }}> */}
            <section
              className="pdf-banner"
              style={{
                height: '38px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                backgroundColor: '#323635',
                alignContent: 'center'
              }}
            >
              <section
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  background: 'transparent',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 'min-content'
                }}
              >
                <Icons.ZoomOutIcon
                  Ocolor
                  onClick={() => {
                    if (ZoomFactor.toFixed(1) > 0.1)
                      SetZoomFactor(ZoomFactor - 0.1);
                  }}
                ></Icons.ZoomOutIcon>
                {/* <div onClick={()=>{SetZoomFactor(ZoomFactor-0.1)}}></div>  OColor={"#F0F0F0"}*/}
                {/* <div style={{color:chosenPortfolio.bulk_icon_override?chosenPortfolio.bulk_icon_override:"black", fontSize:"1.35em"}}>{(ZoomFactor.toFixed(1)*100).toFixed(0)+"%"}</div> */}
                <section style={{ color: '#F0F0F0', fontSize: '1.35em' }}>
                  {(ZoomFactor.toFixed(1) * 100).toFixed(0) + '%'}
                </section>

                <Icons.ZoomInIcon
                  onClick={() => {
                    SetZoomFactor(ZoomFactor + 0.1);
                  }}
                ></Icons.ZoomInIcon>
                {/* <div onClick={()=>{SetZoomFactor(ZoomFactor+0.1)}}>+</div> */}
              </section>
              <Icons.DownloadIcon
                onClick={() => downloadFile()}
              ></Icons.DownloadIcon>
              {/* <button onClick={()=>downloadFile()} style={{justifySelf:"center", alignSelf:"center", backgroundColor:"transparent",}}>Download</button> */}
            </section>
            <section
              ref={DivRef}
              style={{
                width: '100%',
                maxHeight: '100%',
                overflow: 'scroll',
                opacity: `${opacity}`,
                transition: `${opacity === 1 ? 'opacity 750ms' : ''}`,
                background: '#f2f2f2'
              }}
              className="pdf-body"
            >
              <Document
                //onSourceSuccess={(eve)=>//////onsole.log("sources",eve)}
                onSourceError={(eve) => console.error('Source', eve)}
                onLoadError={(eve) => console.error('load', eve)}
                file={url}
                loading={null}
                onLoadSuccess={(eve) => onDocumentLoadSuccess(eve)}
              >
                {isThumbnail && <Page pageNumber={pageNumber} width={100} />}
                {!isThumbnail && P2}
              </Document>
            </section>
          </section>
        )}
      </>
    );
  else {
    return <></>;
  }
  // return(<div style={{display:"flex", visibility:isVis?"visible":"collapse", justifyContent:"space-around", alignContent:"center", alignItems:"center", flexDirection:"column"}}>
  //   <div style={{boxShadow:"3px 3px 5px #808080", height:"100px", minWidth:"160px", display:"flex", justifyContent:"center", alignContent:"center", backgroundColor:"white",}} onClick={()=>{SetHold(false)}}>
  //     <p style={{height:"min-content", margin:"auto"}}>Hit To load</p>
  //     </div>
  //   <div style={{boxShadow:"3px 3px 5px #808080",textAlign:"center", minWidth:"160px", height:"100px", display:"flex", justifyContent:"center", alignContent:"center", backgroundColor:"white",}} onClick={()=>{downloadFile()}}>
  //     <p style={{height:"min-content", margin:"auto"}}>Hit To download</p>
  //     </div>
  // </div>)

  // return (

  //   <div
  //     ref={DivRef}
  //     style={{
  //       width: '100%',
  //       maxHeight: '100%',
  //       overflow: 'scroll',
  //       opacity: `${opacity}`,
  //       transition: `${opacity === 1 ? 'opacity 750ms' : ''}`
  //     }}
  //   >
  //     <Document
  //       onLoadError={console.error}
  //       file={url}
  //       loading={null}
  //       onLoadSuccess={onDocumentLoadSuccess}
  //     >
  //       {isThumbnail && <Page pageNumber={pageNumber} width={100} />}
  //       {!isThumbnail && P}
  //     </Document>
  //   </div>
  // );
};
export default PDFViewer;
