import React from 'react';
import serializers from '../../../utils/scriptSerializers';
import { IconContext } from 'react-icons';
import BlockContent from '@sanity/block-content-to-react';
// import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import FontUtil from '../../../utils/FontUtility';
import ArrowBackRounded from '@material-ui/icons/ArrowBackRounded';
const Scriptnote = ({
  companyConfig,
  chosenPortfolio,
  pages,
  chosenPageId,
  chosenContentPageId,
  showScriptnote,
  toggleScriptnote,
  toolbarMenuVisible,
  setToolbarMenuVisibility,
  setToolbarMenuOpen
}) => {
  const handleVisibility = () => {
    setToolbarMenuVisibility(true);
  };

  const handleToggle = () => {
    setToolbarMenuOpen(true);
  };

  return (
    <>
      <style>
        {`
          div.ql-container {
            height: 100%;
            overflow: auto;
          }
          div.quill {
            color: black;
            max-height: 100%;
            display: inline-flex;
            flex-direction: column;
            width: 100%;
          }
          div.ql-editor {
            word-wrap: anywhere;
          }
          button.ql-link {
            display: none !important;
          }
        `}
      </style>
      <IconContext.Provider
        value={{
          className: 'menu-icon-md',
          style: {
            color: chosenPortfolio.accent_color
              ? chosenPortfolio.accent_color
              : companyConfig.accent_color
          }
        }}
      >
        <div
          style={{
            backgroundColor: chosenPortfolio?.action_menu_color
              ? `${chosenPortfolio.action_menu_color}`
              : '#E2DFDD'
          }}
          className={`portfolio-notepad-container ${
            showScriptnote ? 'active' : 'hide'
          }`}
        >
          <div className="notepad-close-container">
            <div className="svg-box">
              <ArrowBackRounded
                onClick={() => {
                  handleToggle();
                  toggleScriptnote();
                  handleVisibility();
                }}
                style={{
                  color: chosenPortfolio?.bulk_icon_override
                    ? chosenPortfolio.bulk_icon_override
                    : '#3b3b3bBF'
                }}
              />
            </div>
            <div className="text-box">
              <p
                style={{
                  color: chosenPortfolio?.bulk_icon_override
                    ? chosenPortfolio.bulk_icon_override
                    : '#3b3b3bBF'
                }}
              >
                Script
              </p>
            </div>
          </div>
          <div className="textarea-container">
            {pages[chosenContentPageId] ? (
              <div className="page-title">
                <p
                  style={{
                    color: chosenPortfolio?.bulk_icon_override
                      ? chosenPortfolio.bulk_icon_override
                      : '#3b3b3bBF'
                  }}
                >
                  {pages[chosenContentPageId]?.title}
                </p>
              </div>
            ) : null}
            <div className="scriptnote-text-container">
              {pages[chosenContentPageId] ? (
                typeof pages[chosenContentPageId]?.script === 'string' ? (
                  <p
                    style={{ fontFamily: FontUtil(chosenPortfolio.SystemText) }}
                  >
                    {pages[chosenContentPageId]
                      ? pages[chosenContentPageId]?.script
                      : ''}
                  </p>
                ) : (
                  <BlockContent
                    blocks={pages[chosenContentPageId]?.script}
                    serializers={serializers}
                  />
                )
              ) : null}
            </div>
          </div>
        </div>
      </IconContext.Provider>
    </>
  );
};

export default Scriptnote;
