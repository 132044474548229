import React, { useRef, useState } from 'react'; //useEffect
import styled from 'styled-components';
//import {useDispatch} from 'react-redux'
// import {useFirestore} from 'react-redux-firebase';
import TestArea from './NoteReadoutTest';
//import NSD from './Notes-Session-DropDown';
// import DateSelect from '../../../../../../DateSelect';
// import MdFavoriteBorder from '../../../../../../../../assets/Icons/white notepad icon-32.png'; //assets\Icons\white notepad icon-32.png
// import * as Scale from '../../../../../../../../utils/Scalars';
// import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
// import Clearable from '../../../../../../../../utils/InputBox2'
const CopyButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 33%;
  height: 36px;
  cursor: pointer;
  background-color: #fcfafa;
  &:hover {
    background-color: #e6e6e6;
    filter: drop-shadow(3px 3px 4px #808080);
  }
  > p {
    text-decoration: bold;
    margin-block: 0px;
  }
`;
//drop-shadow(-1.5px -1.5px 2.5px #808080);
const Notes = ({ Size, selectedNotesSessionObj, Sessions }) => {
  console.log(Sessions, selectedNotesSessionObj);
  //const firestore = useFirestore();

  //const [NoteSession, SetNoteSession] = useState([]);

  //const [availableSessions, setAvailableSessions] = useState([]);
  const NotepadRef = useRef();

  const [Copied, setCopied] = useState(false);
  //const [sessionInfo, setSessionInfo] = useState({});
  if (!selectedNotesSessionObj && Sessions.length === 0) return null;

  return (
    <div
      style={{
        display: 'grid',
        height: '100%',
        gridTemplateRows: 'calc(100% - 108px) 108px',
        filter: 'none',
        boxShadow: '5px 5px 5px 5px rgba(0,0,0,0.7)'
      }}
    >
      <TestArea
        Copied={Copied}
        EditMode={false}
        Ref={NotepadRef}
        Sessions={Sessions}
        L1={selectedNotesSessionObj ? [selectedNotesSessionObj] : undefined}
        L2={[]}
      ></TestArea>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CopyButton
          onClick={(eve) => {
            //////onsole.log("!!!N!!!",selectedNotesSessionObj);
            //onsole.log(NotepadRef.current)
            let msg = '';
            // TODO: Why is this constant condition?
            if (true) {
              //onsole.log(window.getSelection())
              window.getSelection().selectAllChildren(NotepadRef.current);
              //onsole.log(window.getSelection())
              try {
                const successful = document.execCommand('copy');
                msg = successful ? 'successful' : 'unsuccessful';
                //////onsole.log('Copy email command was ' + msg);
              } catch (err) {
                //////onsole.log('Oops, unable to copy');
              }

              window.getSelection().removeAllRanges();

              //setCopied(true);
              setTimeout(() => {
                //setCopied(false);
              }, 1000);
            } else if (
              !selectedNotesSessionObj.notes.includes('<p>') &&
              !selectedNotesSessionObj.notes.includes('<li>')
            ) {
              //
              //TextArea or quillJS copy
              let Sele = window.getSelection();
              const range = document.createRange();
              ////////onsole.log("CURRENT NODE", clipboardRef.current);
              range.selectNode(NotepadRef.current);
              Sele.addRange(range);
              NotepadRef.current.select();
              try {
                const successful = document.execCommand('copy');
                msg = successful ? 'successful' : 'unsuccessful';
                //////onsole.log('Copy email command was ' + msg);
              } catch (err) {
                //////onsole.log('Oops, unable to copy');
              }

              window.getSelection().removeAllRanges();
            } else {
              //TextArea or quillJS copy
              let Sele = window.getSelection();
              const range = document.createRange();
              ////////onsole.log("CURRENT NODE", clipboardRef.current);
              range.selectNode(NotepadRef.current.editingArea);
              Sele.addRange(range);
              //NotepadRef.current.select();
              try {
                const successful = document.execCommand('copy');
                msg = successful ? 'successful' : 'unsuccessful';
                //////onsole.log('Copy email command was ' + msg);
              } catch (err) {
                //////onsole.log('Oops, unable to copy');
              }

              window.getSelection().removeAllRanges();
            }
            if (msg === 'successful') {
              setCopied(true);
              setTimeout(() => {
                setCopied(false);
              }, 1000);
            }
          }}
        >
          <b>
            <p>Copy</p>
          </b>
        </CopyButton>
      </div>
    </div>
  );
};
export default Notes;
