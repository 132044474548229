//radio group create with array of strings

import React from 'react';
import styled from 'styled-components';

const StyledRadio = styled.div`
  label {
    margin-right: 20px;
  }

  input {
    box-sizing: border-box;
    appearance: none;
    background: white;
    outline: 2px solid #333;
    border: 3px solid white;
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }

  input:checked {
    background: #333;
  }

  input:focus {
    outline-offset: 0px;
  }
`;
const RadioGroup = ({ array, currentRadio, setCurrentRadio }) => {
  return (
    <StyledRadio>
      {array.map((name, index) => {
        return (
          <label key={`radio-${name}-${index}`}>
            <input
              type="radio"
              name={name}
              value={name}
              checked={currentRadio === name}
              onChange={(e) => setCurrentRadio(e.target.value)}
            />
            {name}
          </label>
        );
      })}
    </StyledRadio>
  );
};

export default RadioGroup;
