import {
  SET_GUESTPASS_MASTER_COLLECTION,
  SET_SESSION_MASTER_COLLECTION,
  SET_GUESTPASS_FILTERED_COLLECTION,
  SET_SESSION_FILTERED_COLLECTION,
  SET_GUESTPASS_SESSION_MASTER_COLLECTION,
  SET_GUESTPASS_SESSION_FILTERED_COLLECTION,
  SET_SOLO,
  SET_GUESTPASS_SESSION_FILTERED_VIEWER_COLLECTION,
  SET_VIEWS,
  SET_DATERANGE,
  SET_COMPLETION_ADHERENCE,
  SET_AGGREGATE_SELECTED,
  SET_SELECTED_PORTFOLIO_ID,
  SET_SELECTED_PORTFOLIO_NAME,
  SET_HEADER_AGGREGATE_SELECTED,
  SET_DEFAULT_VISIT,
  SET_VIEWER,
  SET_SEARCH_TERMS,
  SET_GUESTPASS_SINGLE_SESSION,
  SET_BODY_RENDER,
  SET_SHOW_SESSION_SELECT,
  SET_SESSION_LIST,
  SET_SESSION_DATESTRING,
  SET_SHOW_PORTFOLIO_SELECT,
  SET_EFFICIENCY_AGGREGATE_SELECTED,
  SET_GUESTPASS_HEADER_FILTERED,
  SET_EFFICIENCY_USER_TOGGLE,
  SET_EFFICIENCY_COMPANY_TOGGLE,
  SET_EFFICIENCY_PAST_TOGGLE,
  SET_EFFICIENCY_PARALLEL_TOGGLE,
  SET_SECONDARY_USER_SESSION_TOGGLE,
  SET_SECONDARY_USER_AGGREGATE_TOGGLE,
  SET_SELECTED_NOTES_SESSION,
  SET_SELECTED_NOTES_STRING,
  SET_SELECTED_NOTES_SESSION_OBJ,
  SET_PARALLEL_SESSION,
  SET_PARALLEL_DATESTRING,
  SET_SHOW_PARALLEL_SELECT,
  SET_USERS_OF_USER_SELECT,
  SET_SHOW_USER_SELECT,
  SET_GUESTPASS_LEAD_COLLECTION,
  SET_SELECTED_USER,
  SET_GUESTPASS_LEAD_VIEWER,
  SET_SCROLLABLE_COUNT,
  SET_PREPROCESSING_NOTIFICATIONS_LIST,
  SET_POSTPROCESSING_NOTIFICATIONS_LIST,
  SET_NOTIFICATIONS_LIST,
  SET_NOTIFICATIONS_COUNT
} from '../constants/actionTypes';
import { DateRangeEnum } from '../reducers/DashboardRevampReducer';
// import { useFirestore } from 'react-redux-firebase';

export const SetScrollableCount = (Count) => ({
  type: SET_SCROLLABLE_COUNT,
  data: Count
});

export const SetNotificationsCount = (Count) => ({
  type: SET_NOTIFICATIONS_COUNT,
  data: Count
});

export const SetPreProcessingNotificationsList = (Collection) => ({
  type: SET_PREPROCESSING_NOTIFICATIONS_LIST,
  data: Collection
});
export const SetPostProcessingNotificationsList = (Collection) => ({
  type: SET_POSTPROCESSING_NOTIFICATIONS_LIST,
  data: Collection
});
export const SetNotificationsList = (Collection) => ({
  type: SET_NOTIFICATIONS_LIST,
  data: Collection
});

export const setGuestPassLeadCollection = (Collection) => ({
  type: SET_GUESTPASS_LEAD_COLLECTION,
  data: Collection
});

export const setUsersSelectionPool = (Collection) => ({
  type: SET_USERS_OF_USER_SELECT,
  data: Collection
});

export const setLeadViewer = (LV) => ({
  type: SET_GUESTPASS_LEAD_VIEWER,
  data: LV
});

export const setSearch = (SearchTerm) => ({
  type: SET_SEARCH_TERMS,
  data: SearchTerm
});
export const setGuestPassSingleSession = (Session) => ({
  type: SET_GUESTPASS_SINGLE_SESSION,
  data: Session
});
export const setViews = (views) => ({
  type: SET_VIEWS,
  data: views
});

export const setViewer = (viewer) => ({
  type: SET_VIEWER,
  data: viewer
});

export const setFilteredViews = (views) => ({
  type: SET_GUESTPASS_SESSION_FILTERED_VIEWER_COLLECTION,
  data: views
});

export const setMasterGuestPassDispatch = (collection) => ({
  type: SET_GUESTPASS_MASTER_COLLECTION,
  data: collection
});
export const setMasterGuestPassSessionDispatch = (collection) => ({
  type: SET_GUESTPASS_SESSION_MASTER_COLLECTION,
  data: collection
});
export const setMasterSessionDispatch = (collection) => ({
  type: SET_SESSION_MASTER_COLLECTION,
  data: collection
});

export const SetSoloDispatch = (collection) => ({
  type: SET_SOLO,
  data: collection
});

export const SetCompletionAdherence = (num) => ({
  type: SET_COMPLETION_ADHERENCE,
  data: num
});

export const setAggregateSelected = (value) => ({
  type: SET_AGGREGATE_SELECTED,
  data: value
});

export const setHeaderAggregateSelected = (value) => ({
  type: SET_HEADER_AGGREGATE_SELECTED,
  data: value
});

export const setEfficiencyAggregateSelected = (value) => ({
  type: SET_EFFICIENCY_AGGREGATE_SELECTED,
  data: value
});

export const setDefaultVisit = (visit) => ({
  type: SET_DEFAULT_VISIT,
  data: visit
});

export const setBodyRender = (page) => ({
  type: SET_BODY_RENDER,
  data: page
});

export const setShowSessionSelect = (bool) => ({
  type: SET_SHOW_SESSION_SELECT,
  data: bool
});

export const setShowParallelSelect = (bool) => ({
  type: SET_SHOW_PARALLEL_SELECT,
  data: bool
});

export const setShowUserSelect = (bool) => ({
  type: SET_SHOW_USER_SELECT,
  data: bool
});

export const setGuestPassHeaderFiltered = (collection) => ({
  type: SET_GUESTPASS_HEADER_FILTERED,
  data: collection
});

export const setShowPortfolioSelect = (bool) => ({
  type: SET_SHOW_PORTFOLIO_SELECT,
  data: bool
});

export const setEfficiencyUserToggle = (toggle) => ({
  type: SET_EFFICIENCY_USER_TOGGLE,
  data: toggle
});

export const setEfficiencyCompanyToggle = (toggle) => ({
  type: SET_EFFICIENCY_COMPANY_TOGGLE,
  data: toggle
});

export const setEfficiencyPastToggle = (toggle) => ({
  type: SET_EFFICIENCY_PAST_TOGGLE,
  data: toggle
});

export const setEfficiencyParallelToggle = (toggle) => ({
  type: SET_EFFICIENCY_PARALLEL_TOGGLE,
  data: toggle
});

export const setSecondaryUserSessionToggle = (toggle) => ({
  type: SET_SECONDARY_USER_SESSION_TOGGLE,
  data: toggle
});

export const setSecondaryUserAggregateToggle = (toggle) => ({
  type: SET_SECONDARY_USER_AGGREGATE_TOGGLE,
  data: toggle
});

export const setSelectedUser = (user) => ({
  type: SET_SELECTED_USER,
  data: user
});

export const setSelectedNotesSession = (id) => ({
  type: SET_SELECTED_NOTES_SESSION,
  data: id
});

export const setParallelSession = (visit) => ({
  type: SET_PARALLEL_SESSION,
  data: visit
});

export const setSelectedNotesSessionObj = (obj) => ({
  type: SET_SELECTED_NOTES_SESSION_OBJ,
  data: obj
});

export const setSelectedNotesString = (obj) => ({
  type: SET_SELECTED_NOTES_STRING,
  data: obj
});

export const SetDaterangeDispatch = (collection) => ({
  type: SET_DATERANGE,
  data: collection
});

export const SetFilteredGuestpassDispatch = (collection) => ({
  type: SET_GUESTPASS_FILTERED_COLLECTION,
  data: collection
});
export const SetFilteredGuestpassSessionDispatch = (collection) => ({
  type: SET_GUESTPASS_SESSION_FILTERED_COLLECTION,
  data: collection
});
export const SetFilteredSessionDispatch = (collection) => ({
  type: SET_SESSION_FILTERED_COLLECTION,
  data: collection
});

export const setSelectedPortfolioId = (id) => ({
  type: SET_SELECTED_PORTFOLIO_ID,
  data: id
});

export const setSelectedPortfolioName = (string) => ({
  type: SET_SELECTED_PORTFOLIO_NAME,
  data: string
});

export const setSessionList = (list) => ({
  type: SET_SESSION_LIST,
  data: list
});

export const setSessionDateString = (string) => ({
  type: SET_SESSION_DATESTRING,
  data: string
});

export const setParallelDateString = (string) => ({
  type: SET_PARALLEL_DATESTRING,
  data: string
});

export const SetGuestPassMasterCollection = (userInfo, firestore) => {
  return (dispatch) => {
    if (!userInfo) {
      dispatch(setMasterGuestPassDispatch([]));
      return;
    }
    // console.log(
    //   '[web] SetGuestPassMasterCollection userInfo',
    //   userInfo,
    //   'firestore',
    //   firestore
    // );
    let Now = new Date(Date.now());
    //////////onsole.log("!!!NOW!!!",Now)
    let Year = Now.getFullYear();
    let Month = Now.getMonth();
    Year = Month < 6 ? Year - 1 : Year;
    Month = Month < 6 ? 11 - Math.abs(Month - 6) : Month - 6;
    let temphold = [];
    firestore
      .collection('guest_passes')
      .where('company_id', '==', userInfo[0].company_id)
      .where('creation_date', '>=', new Date(Year, Month))
      .orderBy('creation_date', 'desc')
      .get()
      .then((data) => {
        data.docs.map((ele) => {
          temphold.push({ ...ele.data(), id: ele.id });
          return ele;
        });
      })
      .then(() => {
        if (temphold.length === 0)
          // temphold = [];
          temphold = null;

        dispatch(setMasterGuestPassDispatch(temphold));
        return temphold?.length === 0;
      });
  };
};
export const SetSessionMasterCollection = (userInfo, firestore) => {
  return (dispatch) => {
    if (!userInfo) {
      dispatch(setMasterSessionDispatch([]));
      return;
    }
    // console.log(
    //   '[web] SetSessionMasterCollection userInfo',
    //   userInfo,
    //   'firestore',
    //   firestore
    // );
    let Now = new Date(Date.now());
    //////////onsole.log("!!!NOW!!!",Now)
    let Year = Now.getFullYear();
    let Month = Now.getMonth();
    Year = Month < 6 ? Year - 1 : Year;
    Month = Month < 6 ? 11 - Math.abs(Month - 6) : Month - 6;
    let temphold = [];
    firestore
      .collection('sessions')
      .where('company_id', '==', userInfo[0].company_id)
      .where('session_date', '>=', new Date(Year, Month))
      .where('type', '==', 'default')
      .orderBy('session_date', 'desc')
      .get()
      .then((data) => {
        data.docs.map((ele) => {
          temphold.push({ ...ele.data(), id: ele.id });
          return ele;
        });
      })
      .then(() => {
        if (temphold.length === 0)
          // temphold = [];
          temphold = null;

        dispatch(setMasterSessionDispatch(temphold));
        return temphold?.length === 0;
      });
  };
};

export const SetGuestpassSessionMasterCollection = (userInfo, firestore) => {
  return (dispatch) => {
    if (!userInfo) {
      dispatch(setMasterGuestPassSessionDispatch([]));
      return;
    }
    // console.log(
    //   '[web] SetGuestpassSessionMasterCollection userInfo',
    //   userInfo,
    //   'firestore',
    //   firestore
    // );
    let Now = new Date(Date.now());
    //////////onsole.log("!!!NOW!!!",Now)
    let Year = Now.getFullYear();
    let Month = Now.getMonth();
    Year = Month < 6 ? Year - 1 : Year;
    Month = Month < 6 ? 11 - Math.abs(Month - 6) : Month - 6;
    let temphold = [];
    firestore
      .collection('sessions')
      .where('company_id', '==', userInfo[0].company_id)
      .where('session_date', '>=', new Date(Year, Month))
      .where('type', '==', 'guest')
      .orderBy('session_date', 'desc')
      .get()
      .then((data) => {
        data.docs.map((ele) => {
          temphold.push({ ...ele.data(), id: ele.id });
          return ele;
        });
      })
      .then(() => {
        if (temphold.length === 0)
          // temphold = [];
          temphold = null;

        dispatch(setMasterGuestPassSessionDispatch(temphold));
      });
    return temphold?.length === 0;
  };
};

export const SetSolo = (Solo) => {
  return (dispatch) => {
    dispatch(SetSoloDispatch(Solo));
  };
};

export const SetDateRange = (DateRange) => {
  return (dispatch) => {
    dispatch(SetDaterangeDispatch(DateRange));
  };
};
//selectedPortfolioId
export const SetFilteredGuestpass = (userInfo) => {
  return (dispatch, getState) => {
    if (!userInfo) {
      dispatch(SetFilteredGuestpassDispatch([]));
      return;
    }
    const state = getState();
    let dateCut = DateRangeEnum[state.DashboardReducer.DateRange];
    let PID = state.DashboardReducer.selectedPortfolioId;
    let solo = state.DashboardReducer.solo;
    let Filtered = state.DashboardReducer.GuestpassMasterCollection.filter(
      (GP) => {
        if (GP.portfolio_id !== PID) return false;
        if (solo) if (GP.user_id !== userInfo[0].id) return false;
        if (new Date(GP.creation_date.seconds * 1000) < dateCut()) return false;
        return true;
      }
    );
    if (Filtered.length === 0) Filtered = [];
    dispatch(SetFilteredGuestpassDispatch(Filtered));
  };
};
//selectedPortfolioId
export const SetLeadsGuestpass = (userInfo) => {
  return (dispatch, getState) => {
    if (!userInfo) {
      dispatch(SetFilteredGuestpassDispatch([]));
      return;
    }
    //onsole.log("LEADS start")
    const state = getState();
    let dateCut = DateRangeEnum[4];
    let PID = state.DashboardReducer.selectedPortfolioId;
    let solo = state.DashboardReducer.solo;
    let Filtered = state.DashboardReducer.GuestpassMasterCollection.filter(
      (GP) => {
        if (GP.portfolio_id !== PID) return false;
        if (solo) if (GP.user_id !== userInfo[0].id) return false;
        if (new Date(GP.creation_date.seconds * 1000) < dateCut()) return false;
        return true;
      }
    );
    //onsole.log("LEADS NextToEnd",Filtered)
    if (Filtered.length === 0) Filtered = [];
    //onsole.log("LEADS END",Filtered)
    // dispatch(SetFilteredGuestpassDispatch(Filtered));
    {
      const collection = [];
      const state = getState();
      //onsole.log("LEADS start2")
      let dateCut = DateRangeEnum[4];
      let PID = state.DashboardReducer.selectedPortfolioId;
      // let solo = state.DashboardReducer.solo;
      let Filtered =
        state.DashboardReducer.GuestpassSessionMasterCollection.filter((GP) => {
          if (GP.portfolio_id !== PID) return false;
          // TODO: Constant true
          // if (true) if (GP.user_id !== userInfo[0].id) return false;
          // Rewrite below
          if (GP.user_id !== userInfo[0].id) return false;
          if (new Date(GP.session_date.seconds * 1000) < dateCut())
            return false;
          return true;
        });
      //onsole.log("LEADS NextToEnd2",Filtered)
      if (Filtered.length === 0) {
        Filtered = null;
        dispatch(setGuestPassLeadCollection(collection));
        return;
      }

      Filtered.forEach((ele) => {
        if (
          collection.some((ele2) => {
            return (
              ele2.Name.toLowerCase() === ele.gp_name.toLowerCase() &&
              ele2.Email.toLowerCase() === ele.gp_email.toLowerCase()
            );
          })
        )
          collection[
            collection.findIndex((ele2) => {
              return (
                ele2.Name.toLowerCase() === ele.gp_name.toLowerCase() &&
                ele2.Email.toLowerCase() === ele.gp_email.toLowerCase()
              );
            })
          ].Sessions.push(ele);
        else
          collection.push({
            Name: ele.gp_name.toLowerCase(),
            Email: ele.gp_email.toLowerCase(),
            Sessions: [ele]
          });
      });
      //onsole.log(collection);
      collection.forEach((ele) => {
        ele.company = ele.Email.split('@')[1].split('.')[0];
        ele.SessionsCount = ele.Sessions.length;
        ele.Sessions.sort((a, b) => {
          return a.session_date.seconds - b.session_date.seconds;
        });
        let temp = new Date(ele.Sessions[0].session_date.seconds * 1000);
        ele.Duration = 0;
        ele.Date = temp;
        ele.Likes = 0;
        ele.Notes = 0;
        ele.Sessions.forEach((ele2) => {
          if (ele2.liked) ele.Likes = ele.Likes + ele2.liked.length;
          if (ele2.notes) ele.Notes++;
          if (ele2.page_visited.length - 1 >= 0)
            ele.Duration +=
              new Date(
                ele2.page_visited[ele2.page_visited.length - 1].time_visited
                  .seconds * 1000
              ) - new Date(ele2.session_date.seconds * 1000);
        });
        ele.Duration = ele.Duration / 1000;
        const Min = Math.floor(ele.Duration / 60);
        const Seconds = ele.Duration % 60;
        ele.Duration = `${Min}m ${Seconds}s`;
        ele.DurationInSec = Min * 60 + Seconds;
        //ele.Duration = `${ele.Duration%(1000*60)}m ${ele.Duration%(1000)}s`
      });
      dispatch(setGuestPassLeadCollection(collection));
    }
  };
};
export const filterHeaderGuestPass = (userInfo) => {
  return (dispatch, getState) => {
    if (!userInfo) {
      dispatch(setGuestPassHeaderFiltered([]));
      return;
    }
    const state = getState();
    let PID = state.DashboardReducer.selectedPortfolioId;
    let headerAggregateSelected =
      state.DashboardReducer.headerAggregateSelected;

    let today = new Date();
    let newDate = new Date();
    if (headerAggregateSelected === 30 || headerAggregateSelected === 7) {
      newDate.setDate(today.getDate() - headerAggregateSelected);
    } else {
      newDate = new Date(
        new Date(
          new Date(new Date(new Date(new Date())).setSeconds(0)).setMinutes(0)
        ).setHours(0)
      );
    }

    let Filtered = state.DashboardReducer.GuestpassMasterCollection.filter(
      (GP) => {
        if (GP.portfolio_id !== PID) {
          return false;
        } else if (GP.user_id !== userInfo[0].id) {
          return false;
        } else if (
          GP.creation_date.seconds * 1000 < newDate.getTime() ||
          GP.creation_date.seconds * 1000 > today.getTime()
        ) {
          return false;
        } else {
          return true;
        }
      }
    );

    if (Filtered.length === 0) {
      Filtered = [];
    }

    dispatch(setGuestPassHeaderFiltered(Filtered));
  };
};

//selectedPortfolioId
export const SetFilteredSession = (userInfo) => {
  return (dispatch, getState) => {
    if (!userInfo) {
      dispatch(SetFilteredSessionDispatch([]));
      return;
    }
    const state = getState();
    let dateCut = DateRangeEnum[state.DashboardReducer.DateRange];
    let PID = state.DashboardReducer.selectedPortfolioId;
    let solo = state.DashboardReducer.solo;
    let Filtered = state.DashboardReducer.SessionMasterCollection.filter(
      (GP) => {
        if (GP.portfolio_id !== PID) return false;
        if (solo) if (GP.user_id !== userInfo[0].id) return false;
        if (new Date(GP.session_date.seconds * 1000) < dateCut()) return false;
        return true;
      }
    );
    if (Filtered.length === 0) Filtered = [];
    dispatch(SetFilteredSessionDispatch(Filtered));
  };
};
//selectedPortfolioId
export const SetFilteredGuestPassSessionWithoutGPID = (userInfo) => {
  //userInfo
  return (dispatch, getState) => {
    if (!userInfo) {
      dispatch(SetFilteredGuestpassSessionDispatch([]));
      return;
    }
    const state = getState();
    let dateCut = DateRangeEnum[state.DashboardReducer.DateRange];
    let PID = state.DashboardReducer.selectedPortfolioId;
    let solo = state.DashboardReducer.solo;
    let Filtered =
      state.DashboardReducer.GuestpassSessionMasterCollection.filter((GP) => {
        if (GP.portfolio_id !== PID) return false;
        if (solo) if (GP.user_id !== userInfo[0].id) return false;
        if (new Date(GP.session_date.seconds * 1000) < dateCut()) return false;

        return true;
      });
    if (Filtered.length === 0) Filtered = null;
    dispatch(SetFilteredGuestpassSessionDispatch(Filtered));
  };
};
//UserPool
export const setUsersPool = (userInfo, firestore) => {
  return (dispatch) => {
    if (!userInfo) {
      dispatch(setUsersSelectionPool([]));
      return;
    }
    let users = [];
    if (userInfo[0].role === 'admin' || userInfo[0].role === 'manager') {
      firestore
        .collection('users')
        .where('company_id', '==', userInfo[0].company_id)
        .where('is_active', '==', true)
        .orderBy('updated_at', 'desc')
        .get()
        .then((result) => {
          result.docs.map((ele) => {
            users.push({ ...ele.data(), id: ele.id });
            return ele;
          });
          dispatch(setUsersSelectionPool(users));
        });
    } //if(userInfo[0].role === "manager")
    else dispatch(setUsersSelectionPool([userInfo[0]]));
  };
};

//selectedPortfolioId
export const SetFilteredGuestPassSession = (GPID) => {
  //userInfo
  return (dispatch, getState) => {
    if (GPID === null) {
      dispatch(SetFilteredGuestpassSessionDispatch([]));
      return;
    }
    const state = getState();
    //let dateCut = DateRangeEnum[state.DashboardReducer.DateRange];
    let PID = state.DashboardReducer.selectedPortfolioId;
    //let solo = state.DashboardReducer.solo;
    let Filtered =
      state.DashboardReducer.GuestpassSessionMasterCollection.filter((GP) => {
        if (GP.portfolio_id !== PID) return false;
        if (!GP.gp_id) return false;
        if (GP.gp_id !== GPID) return false;

        // if(solo)
        //     if(GP.user_id !== userInfo[0].id)
        //         return false
        // if(new Date(GP.creation_date.seconds *1000) < dateCut())
        //     return false;

        return true;
      });
    if (Filtered.length === 0) Filtered = null;
    dispatch(SetFilteredGuestpassSessionDispatch(Filtered));
  };
};
//selectedPortfolioId
export const SetFilteredGuestPassSessionViewer = (Viewer) => {
  //userInfo
  return (dispatch, getState) => {
    const state = getState();
    if (!state.DashboardReducer.GuestpassSessionFilteredCollection) return;
    if (!Viewer) {
      dispatch(setFilteredViews([]));
      return;
    }
    //let dateCut = DateRangeEnum[state.DashboardReducer.DateRange];
    //let solo = state.DashboardReducer.solo;
    let Filtered =
      state.DashboardReducer.GuestpassSessionFilteredCollection.filter((GP) => {
        // if(!GP.gp_id)
        // return false;
        // if(GP.gp_id !== GPID)
        //     return false
        if (
          GP.gp_email.trim().toLowerCase() ===
            Viewer.gp_email.trim().toLowerCase() &&
          GP.gp_name.trim().toLowerCase() ===
            Viewer.gp_name.trim().toLowerCase()
        ) {
          return true;
        }
        // if(solo)
        //     if(GP.user_id !== userInfo[0].id)
        //         return false
        // if(new Date(GP.creation_date.seconds *1000) < dateCut())
        //     return false;

        return false;
      });
    if (Filtered.length === 0) Filtered = [];
    dispatch(setFilteredViews(Filtered));
  };
};
