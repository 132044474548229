import React, { useState } from 'react';
import styled from 'styled-components';
import * as Scale from '../../../utils/Scalars';

const DateContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .date-select {
    border-radius: 12px;
    box-shadow: 1px 1px 4px black;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    font-size: 1em;

    @media (max-width: 1380px) {
      font-size: 0.7em;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid #e6e6e6;
      border-right: 1px solid #e6e6e6;
      font-weight: 600;
      font-family: 'Newsreader', serif;
      transition: all 0.1s ease;
    }
  }
`;

const DateSelect = ({
  setAggregateSelected,
  aggregateSelected,
  SetDaterangeDispatch,
  DateRange,
  setDefaultVisit,
  setSessionDateString,
  Size,
  setEfficiencyUserToggle,
  efficiencyUserToggle,
  additionalStyle,
  setSecondaryUserSessionToggle,
  setSecondaryUserAggregateToggle
}) => {
  const [hoverChosen, setHoverChosen] = useState('');

  const times = [
    { value: '6m', id: '6m' },
    { value: '3m', id: '3m' },
    { value: '1m', id: '1m' },
    { value: '2w', id: '2w' },
    { value: '1w', id: '1w' },
    { value: '1d', id: '1d' }
  ];

  //const [TimeSet,SetTimeSet] = useState(3);
  // useEffect(()=>{
  //     if(TimeSet !== DateRange)
  //     SetTimeSet(DateRange)},[DateRange,SetTimeSet,TimeSet])
  // useEffect(()=>{
  //      SetDaterangeDispatch(TimeSet)
  //     // switch(TimeSet){
  //     //     case 5:
  //     //         SetDaterangeDispatch(0);
  //     //         break;
  //     //         case 4:
  //     //         SetDaterangeDispatch(1)
  //     //         break;
  //     //         case 3:
  //     //         SetDaterangeDispatch(2)
  //     //         break;
  //     //         case 2:
  //     //         SetDaterangeDispatch(3)
  //     //         break;
  //     //         case 1:
  //     //         SetDaterangeDispatch(4)
  //     //         break;
  //     //         case 0:
  //     //         SetDaterangeDispatch(5)
  //     //         break;
  //     // default:
  //     //     return
  //     // }

  // }, [TimeSet, SetDaterangeDispatch]);

  const resetUserToggle = (time) => {
    if (efficiencyUserToggle === 'off' && time !== aggregateSelected) {
      setEfficiencyUserToggle('on');
    }
    setSecondaryUserSessionToggle('off');
    setSecondaryUserAggregateToggle('on');
  };

  // let TimeValues = ['6m','3m','1m','2w','1w','1d']
  const timeMapping = times.map((time, i) => {
    if (time.value === '6m') {
      return (
        <div
          onClick={() => {
            setAggregateSelected(time.value);
            SetDaterangeDispatch(Math.abs(5 - i));
            setDefaultVisit(undefined);
            setSessionDateString('Select a Portfolio Session');
            resetUserToggle(time.value);
          }}
          onMouseEnter={() => setHoverChosen(time.id)}
          onMouseLeave={() => setHoverChosen('')}
          value={time.value}
          id={time.id}
          key={time.id}
          style={{
            // backgroundColor: DateRange === Math.abs(5 -i) ? "#3269a8" : "white",
            // color: DateRange === Math.abs(5 -i) ? "white" : "black",
            backgroundColor:
              aggregateSelected === time.id
                ? '#056AAF'
                : hoverChosen === time.id
                ? '#E6E6E6'
                : 'rgb(252, 250, 250)',
            color: aggregateSelected === time.id ? 'white' : '#333333',
            borderTopLeftRadius: '12px',
            borderBottomLeftRadius: '12px',
            cursor: 'pointer',
            borderLeft: 'none'
          }}
        >
          {time.value}
        </div>
      );
    } else if (time.value === '1d') {
      return (
        <div
          onClick={() => {
            setAggregateSelected(time.value);
            SetDaterangeDispatch(Math.abs(5 - i));
            setDefaultVisit(undefined);
            setSessionDateString('Select a Portfolio Session');
            resetUserToggle(time.value);
          }}
          onMouseEnter={() => setHoverChosen(time.id)}
          onMouseLeave={() => setHoverChosen('')}
          value={time.value}
          id={time.id}
          key={time.id}
          style={{
            // backgroundColor: DateRange === Math.abs(5 -i) ? "#3269a8" : "white",
            // color: DateRange === Math.abs(5 -i) ? "white" : "black",
            backgroundColor:
              aggregateSelected === time.id
                ? '#056AAF'
                : hoverChosen === time.id
                ? '#E6E6E6'
                : 'rgb(252, 250, 250)',
            color: aggregateSelected === time.id ? 'white' : '#333333',
            borderTopRightRadius: '12px',
            borderBottomRightRadius: '12px',
            cursor: 'pointer',
            borderRight: 'none'
          }}
        >
          {time.value}
        </div>
      );
    } else {
      return (
        <div
          onClick={() => {
            setAggregateSelected(time.value);
            SetDaterangeDispatch(Math.abs(5 - i));
            setDefaultVisit(undefined);
            setSessionDateString('Select a Portfolio Session');
            resetUserToggle(time.value);
          }}
          onMouseEnter={() => setHoverChosen(time.id)}
          onMouseLeave={() => setHoverChosen('')}
          value={time.value}
          id={time.id}
          key={time.id}
          style={{
            // backgroundColor: DateRange === Math.abs(5 -i) ? "#3269a8" : "white",
            // color: DateRange === Math.abs(5 -i) ? "white" : "black",
            backgroundColor:
              aggregateSelected === time.id
                ? '#056AAF'
                : hoverChosen === time.id
                ? '#E6E6E6'
                : 'rgb(252, 250, 250)',
            color: aggregateSelected === time.id ? 'white' : '#333333',
            cursor: 'pointer'
          }}
        >
          {time.value}
        </div>
      );
    }
  });

  return (
    <DateContainer style={{ ...additionalStyle }}>
      <div
        style={{
          width: `${Scale.Width(Size, 275)}px`,
          height: `${Scale.Width(Size, 30)}px`
        }}
        className="date-select"
      >
        {timeMapping}
      </div>
    </DateContainer>
  );
};

export default DateSelect;
