import React, { useState, useEffect, Fragment } from 'react';
// import { setShowTermChild } from '../../../actions';
import GalleryThumbsContent from '../GalleryThumbsContent';
// import PortfolioContent from '../PortfolioContent';

// Menu Buttons with child group
const MenuButton = (props) => {
  const {
    navIndex,
    foundNavItem,
    level,
    chosenPortfolio,
    onButtonClick,
    color,
    setGuestContent,
    contentType,
    pages,
    images,
    videos,
    pdfs,
    contentDataChoice,
    prepareCurrentGalleryContent,
    //pageMenuReducer,
    onNavClick,
    isAtEnd,
    setCurrentNavItem,
    // currentNavItem,
    // onNavClick2,
    // getColor,
    // videoSelected,
    // setHasCurr,
    // setHasPrev,
    Size,
    // transitionedToSubLevel,
    // hasCurr,
    // hasPrev,
    // onBackClick,
    // returnTopLevel,
    // selectedTopLevelId,
    setSelectedTopLevelId,
    setSavedContentPageId,
    // setChosenPageId,
    setContentPageId,
    // twolevelTerminus,
    setTwolevelTerminus,
    setTopicArray,
    // megamenuLevel,
    setMegamenuLevel,
    setTopic,
    // backTopicArray,
    setTermWithChild,
    Topic,
    index,
    setShowTermChild,
    pageReference,
    length
  } = props;

  const {
    chosenMainPageButtonId // chosen parent nav button id
  } = props.pageMenuReducer;

  const { currentLevel, chosenPageId } = props.pageMenuReducer;
  const [isHovered, setIsHovered] = useState(0);
  const [isSubActive, setIsSubActive] = useState(false); // keep track of if this sub/child button is active
  const id = foundNavItem.referenceId;
  const hasChild = foundNavItem.children.length > 0;
  const colorArray = ['#E2DFDD', '#EEEEEE', '#FFFFFF'];

  const determineColor = () => {
    // returns index of color array for dynamic background
    return index % colorArray.length;
  };

  // Listen for changes to change active state of this button
  useEffect(() => {
    // check if user clicked on different main/parent button
    /* NOTE currently using id of page to separate the logic.
    // This is assuming that pages are always unique and nav does not contain
    // any duplicate pages.
    */
    if (level === 0 && chosenMainPageButtonId !== id && isSubActive) {
      setIsSubActive(false);
    }
    if (
      level !== 0 &&
      currentLevel === level &&
      id !== chosenPageId &&
      isSubActive
    ) {
      // check if user clicked different button on same level
      setIsSubActive(false);
    } else if (currentLevel < level && isSubActive) {
      //disable anything lower than current level
      setIsSubActive(false);
    }
  }, [level, chosenPageId]); // eslint-disable-line react-hooks/exhaustive-deps

  // used to suppress natural onClick navigation of portfolioContent/GalleryThumbContent in favor of the navigation provided by the MenuSection
  // const emptyNavClick = (x, y) => {
  //   return;
  // }

  // let contentRendering;
  let foundPage = pages[foundNavItem.referenceId];
  // let isSingleImage =
  //   foundPage._type === 'gallery' &&
  //   foundPage.content &&
  //   foundPage.content.length === 1 &&
  //   foundPage.content[0]._type === 'galleryImage';
  // let isSingleVideo =
  //   foundPage._type === 'gallery' &&
  //   foundPage.content &&
  //   foundPage.content.length === 1 &&
  //   foundPage.content[0]._type === 'galleryVideo';
  let menuSectionWidthDecider;
  if (length === 1) {
    menuSectionWidthDecider = 'one';
  } else if (length === 2) {
    menuSectionWidthDecider = 'two';
  } else if (length === 3) {
    menuSectionWidthDecider = 'three';
  } else if (length >= 4) {
    menuSectionWidthDecider = 'four-plus';
  } else {
    menuSectionWidthDecider = 'four-plus';
  }

  //onsole.log("PAGE REFERENCE", pageReference);

  return (
    <Fragment>
      <div
        color={color}
        className={`menu-section ${menuSectionWidthDecider}`}
        style={{
          backgroundColor: colorArray[determineColor()]
        }}
        onMouseEnter={() => setIsHovered(foundNavItem.referenceId)}
        onMouseLeave={() => setIsHovered(0)}
        onClick={() => {
          if (chosenPortfolio?.MegaText?.MegaFlat) {
            //onsole.log("CLICKED MEGAFLAT")
            return;
          }

          let isNotEnd = false;

          // check if next child doesnt contain another child
          if (hasChild) {
            isNotEnd = foundNavItem.children.some((childArray) => {
              return childArray.children.length !== 0;
            });
          }
          //////////onsole.log("PortCheck",chosenPortfolio)
          if (
            (isNotEnd && isSubActive === false) ||
            chosenPortfolio.theme !== 'theme4'
          )
            setIsSubActive(true);
          else {
            setIsSubActive(false);
          }

          onButtonClick(id, level, hasChild, isNotEnd, navIndex);
          setCurrentNavItem(foundNavItem);

          // set setCurrentContentHelper
          if (setGuestContent) {
            setGuestContent();
          }

          //onsole.log("YOU MADE IT - FOUND NAV ITEM", foundNavItem);

          if (!isNotEnd) {
            if (foundNavItem.children[0]) {
              setTermWithChild(true);
              setTimeout(() => {
                setContentPageId(foundNavItem.referenceId);
              }, 50);
              setSavedContentPageId(foundNavItem.referenceId);
              setSelectedTopLevelId(foundNavItem.referenceId);
              setTwolevelTerminus(true);
              //onsole.log(`SETTING TOPIC ARRAY - AT END WITH FIRST CHILD TOP LEVEL: ${pages[foundNavItem.referenceId].title}, 0, ${id}`);
              let topicalarray = [...Topic];
              topicalarray.splice(0, 11111, {
                title: foundNavItem.title,
                id: foundNavItem.referenceId
              });
              //onsole.log("TOPIC ARRAY CURRENTLY PLUS PARENT END - TOP LEVEL", topicalarray);
              setTopic(topicalarray);
              setMegamenuLevel(1);
              setTimeout(() => {
                let finalTopicArray = [...topicalarray];
                //onsole.log(`ADDING TOPIC ARRAY CHILD END - TOP LEVEL: ${foundNavItem.children[0].title}, ${foundNavItem.children[0].referenceId}`);
                finalTopicArray.splice(1, 11111, {
                  title: foundNavItem.children[0].title,
                  id: foundNavItem.children[0].referenceId
                });
                //onsole.log("TOPIC ARRAY CURRENTLY PLUS CHILD END - TOP LEVEL", finalTopicArray);
                setTopic(finalTopicArray);
                setContentPageId(foundNavItem.children[0].referenceId);
                setSavedContentPageId(foundNavItem.children[0].referenceId);
              }, 501);
            } else {
              // wait for useEffect in contentParent8
              setTimeout(() => {
                setContentPageId(foundNavItem.referenceId);
              }, 50);
              setShowTermChild(true);
              setSavedContentPageId(foundNavItem.referenceId);
              setSelectedTopLevelId(foundNavItem.referenceId);
              setTwolevelTerminus(true);

              //onsole.log(`SETTING TOPIC ARRAY - AT END WITHOUT FIRST CHILD TOP LEVEL: ${pages[foundNavItem.referenceId].title}, 0, ${id}`);
              setTopicArray(pages[foundNavItem.referenceId].title, 0, id);
              setMegamenuLevel(1);
            }
            // setChosenPageId(foundNavItem.children[0] ? foundNavItem.children[0].referenceId : foundNavItem.referenceId)
          } else {
            setContentPageId(foundNavItem.referenceId);
            setSelectedTopLevelId(foundNavItem.referenceId);
            setTwolevelTerminus(false);

            //onsole.log(`SETTING TOPIC ARRAY - NOT AT END: ${pages[foundNavItem.referenceId].title}, 0, ${id}`);
            setTopicArray(pages[foundNavItem.referenceId].title, 0, id);
            setMegamenuLevel(1);
          }

          ////////onsole.log("??CHOSEN CONTENT PAGE ID SET: ", pageMenuReducer.chosenContentPageId);
        }}
      >
        <div
          className={`inner-menu-section ${
            pageReference.CatalogTopLevelSelection?.topLevelSelection ===
            'topImage'
              ? 'top-image'
              : ''
          }${
            pageReference.CatalogTopLevelSelection?.topLevelSelection ===
            'bottomImage'
              ? 'bottom-image'
              : ''
          }${
            pageReference.CatalogTopLevelSelection?.topLevelSelection ===
            'fullImage'
              ? 'full-image'
              : ''
          }`}
        >
          <GalleryThumbsContent
            child={foundNavItem}
            index={navIndex}
            contentType={contentType}
            contents={contentDataChoice(foundNavItem.referenceId)}
            page={pages[foundNavItem.referenceId]}
            images={images}
            videos={videos}
            pdfs={pdfs}
            prepareCurrentGalleryContent={prepareCurrentGalleryContent}
            videoSelected={false}
            chosenPortfolio={chosenPortfolio}
            foundPage={foundPage}
            onNavClick={onNavClick}
            isTopLevel={true}
            isAtEnd={isAtEnd}
            isHovered={isHovered}
            Size={Size}
            pageReference={pageReference}
            length={length}
          />
        </div>
      </div>
      {hasChild && (
        <MenuSection
          {...props}
          isActive={isSubActive}
          level={level + 1}
          chosenNavItem={foundNavItem.children}
        />
      )}
    </Fragment>
  );
};

const MenuSection = (props) => {
  const [foundNavItems, setFoundNavItems] = useState([]);
  //const [containsContentGroup, setContainsContentGroup] = useState(false);
  const {
    isGuestMode,
    isPortfolioView,
    guestContentHelper,
    chosenNavItem,
    pages,
    nav,
    level,
    isActive,
    getActiveString,
    getIsAtEndString,
    companyConfig,
    chosenPortfolio,
    setCurrentContentHelper,
    contentDataChoice,
    prepareCurrentGalleryContent,
    onNavClick,
    isAtEnd,
    setCurrentNavItem,
    currentNavItem,
    images,
    videos,
    pdfs,
    onNavClick2,
    getColor,
    videoSelected,
    setHasCurr,
    setHasPrev,
    Size,
    transitionedToSubLevel,
    hasCurr,
    hasPrev,
    returnTopLevel,
    onBackClick,
    selectedTopLevelId,
    setSelectedTopLevelId,
    setSavedContentPageId,
    setChosenPageId,
    setContentPageId,
    twolevelTerminus,
    setTwolevelTerminus,
    setTopicArray,
    megamenuLevel,
    setMegamenuLevel,
    setTopic,
    backTopicArray,
    setTermWithChild,
    Topic,
    setShowTermChild
  } = props;

  useEffect(() => {
    // create page buttons, chosenNavItem is undefined at first load
    if (!chosenNavItem) {
      if (nav) {
        // parent pages buttons
        setFoundNavItems(nav.items);
      }
    } else {
      // child page buttons
      setFoundNavItems(chosenNavItem);
    }
  }, [chosenNavItem, isGuestMode, isPortfolioView, nav]);

  if (!nav || foundNavItems.length === 0) {
    return null;
  }

  let isActiveString = getActiveString(isActive, level);
  let isAtEndString = getIsAtEndString();

  const menuSection = foundNavItems.map((foundNavItem, index) => {
    // get color for each specific page....
    let accentColor;
    let textColor;
    let subtitle;

    //onsole.log("PAGE", pages[foundNavItem.referenceId]);

    try {
      accentColor = pages[foundNavItem.referenceId].accent_color;
      textColor = pages[foundNavItem.referenceId].text_color;
      subtitle = pages[foundNavItem.referenceId].subtitle;
    } catch (ex) {
      //////////onsole.log("MenuSection ERR:", ex);
    }

    // set to chosenPortfolio color if it doesn't exists
    accentColor = accentColor ? accentColor : chosenPortfolio.accent_color;
    textColor = textColor ? textColor : chosenPortfolio.text_color;

    // set to companyConfig color if it doesn't exists
    accentColor = accentColor ? accentColor : companyConfig.accent_color;
    textColor = textColor ? textColor : companyConfig.text_color;

    const menuButton = (
      <MenuButton
        {...props}
        navIndex={index}
        foundNavItem={foundNavItem}
        title={foundNavItem.title}
        subtitle={
          chosenPortfolio.theme === 'theme4' || 'theme2' ? subtitle : null
        }
        pageReference={pages[foundNavItem.referenceId]}
        isActiveString={isActiveString}
        isAtEndString={isAtEndString}
        backgroundColor={accentColor}
        contentType={pages[foundNavItem.referenceId]._type}
        color={textColor}
        setGuestContent={
          isGuestMode && !isPortfolioView
            ? () => setCurrentContentHelper(guestContentHelper[index].id)
            : undefined
        }
        additionalButtonClassName={`level-${level} ${isActiveString} ${isAtEndString}`}
        contentDataChoice={contentDataChoice}
        prepareCurrentGalleryContent={prepareCurrentGalleryContent}
        onNavClick={onNavClick}
        isAtEnd={isAtEnd}
        setCurrentNavItem={setCurrentNavItem}
        currentNavItem={currentNavItem}
        chosenPortfolio={chosenPortfolio}
        onNavClick2={onNavClick2}
        getColor={getColor}
        videoSelected={videoSelected}
        setHasCurr={setHasCurr}
        setHasPrev={setHasPrev}
        Size={Size}
        transitionedToSubLevel={transitionedToSubLevel}
        images={images}
        videos={videos}
        pdfs={pdfs}
        hasCurr={hasCurr}
        hasPrev={hasPrev}
        onBackClick={onBackClick}
        returnTopLevel={returnTopLevel}
        selectedTopLevelId={selectedTopLevelId}
        setSelectedTopLevelId={setSelectedTopLevelId}
        setSavedContentPageId={setSavedContentPageId}
        setChosenPageId={setChosenPageId}
        setContentPageId={setContentPageId}
        setTwolevelTerminus={setTwolevelTerminus}
        twolevelTerminus={twolevelTerminus}
        setTopicArray={setTopicArray}
        megamenuLevel={megamenuLevel}
        setMegamenuLevel={setMegamenuLevel}
        setTopic={setTopic}
        backTopicArray={backTopicArray}
        setTermWithChild={setTermWithChild}
        Topic={Topic}
        index={index}
        setShowTermChild={setShowTermChild}
        length={foundNavItems.length}
      />
    );
    // if level 0, group those into div button-group-level-0
    return (
      <Fragment key={foundNavItem._id + '-' + index}>
        {level === 0 ? (
          <div
            className={`group level-${level} ${isActiveString} ${isAtEndString}`}
          >
            {menuButton}
          </div>
        ) : (
          menuButton
        )}
      </Fragment>
    );
  });

  // if not level 0, group those into div button-group-level-<n>
  return (
    <Fragment>
      {level === 0 ? (
        menuSection
      ) : (
        <div
          className={`group level-${level} ${isActiveString} ${isAtEndString}`}
        >
          {menuSection}
        </div>
      )}
    </Fragment>
  );
};

export default MenuSection;
