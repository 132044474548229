import { connect } from 'react-redux';
import Body from './Body.js';
import { SetSoloDispatch } from '../../../actions';

const mapStateToProps = (state) => ({
  bodyRender: state.DashboardReducer.bodyRender
});

const mapDispatchToProps = (dispatch) => {
  return {
    SetSoloDispatch: (value) => dispatch(SetSoloDispatch(value))
  };
};

const DashboardBody = connect(mapStateToProps, mapDispatchToProps)(Body);

export default DashboardBody;
