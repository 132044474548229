import React, { useState, useEffect } from 'react';
import BuildRoundedIcon from '@material-ui/icons/BuildRounded';
import { useHistory } from 'react-router-dom';
import { useFirebase, useFirestore } from 'react-redux-firebase';
// import styled, {keyframes} from 'styled-components';
import ShareIcon from '../../../assets/RootSVGS/shared_v1Asset2';
import Icons from '../../../containers/Icons';
import '../../../assets/images/arrowUp2.png';
import FontUtil from '../../../utils/FontUtility';
import ShareRoundedIcon from '@material-ui/icons/ShareRounded';
import Like from '../../../utils/Likes';
// import { IconContext } from 'react-icons';

const TopRightToolbar = ({
  setMenuHide,
  currentContent,
  userInfo,
  mode,
  sessionId,
  showBriefcase,
  briefcaseContent,
  isModalOpen,
  chosenPortfolio,
  companyConfig,
  isGuestMode,
  currentGuestContentHelper,
  liked,
  toggleBriefcase,
  addToBriefcase,
  endPortfolio,
  setPortfolioCustomerInfo,
  showModal,
  hideModal,
  toggleNotepad,
  toggleScriptnote,
  setLiked,
  menuHide,
  updateSessionInSanity,
  shareClick,
  clearAll,
  Size,
  booleanNewItems,
  setBooleanNewItems,
  SET_ANNOTATOR,
  ANNOTATOR2,
  toolbarMenuOpen,
  setToolbarMenuOpen,
  toolbarMenuVisible,
  setToolbarMenuVisibility,
  masterMenuOpen,
  transitionedToTopLevel
}) => {
  const [isAddable, setIsAddable] = useState(true); // bool to keep track of if it is addable to briefcase
  const [isAuthToAdd, setIsAuthToAdd] = useState(false); // is this user authorized to add item?
  const [isAuthBriefcaseShow, setIsAuthBriefcaseShow] = useState(false); // is this user authorized to see briefcase?
  const [isLiked2, setIsLiked2] = useState(null); // is this user authorized to see briefcase?
  const history = useHistory();
  const firestore = useFirestore();
  const firebase = useFirebase();

  const handleToggle = () => {
    //onsole.log("!!Toggle!!")
    let anti = !toolbarMenuOpen;
    setToolbarMenuOpen(anti);
  };

  const handleVisibility = () => {
    if (chosenPortfolio.theme === 'theme8') {
      setToolbarMenuVisibility(false);
    }
  };

  const Keycheck = (eve) => {
    if (eve.key !== 'F8') return;
    onExit();
  };

  useEffect(() => {
    window.addEventListener('keydown', Keycheck);
    return () => window.removeEventListener('keydown', Keycheck);
  });
  // const getWindowDimensions = () => {
  //   const { innerWidth: width, innerHeight: height } = window;
  //   return {
  //     width,
  //     height
  //   };
  // }

  // const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  // useEffect(() => {
  //   function handleResize() {
  //     setWindowDimensions(getWindowDimensions());
  //   }

  //   window.addEventListener('resize', handleResize);
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);

  // check each time chosenContent or briefcaseContent updates
  useEffect(() => {
    // check if briefcase contains current content user is looking at
    if (
      (Object.entries(currentContent)?.length !== 0 &&
        briefcaseContent.filter((e) => e.id === currentContent.id).length >
          0) ||
      currentContent.page_id === `splashkey-${chosenPortfolio._id}`
    ) {
      setIsAddable(false);
    } else {
      setIsAddable(true);
    }
  }, [currentContent, briefcaseContent, chosenPortfolio]);

  // get userinfo auth for viewing sending briefcase
  useEffect(() => {
    if (userInfo && userInfo.length > 0) {
      // TODO: Why is this constant condition?
      // check if user is able to create guest pass
      if (true) {
        //userInfo[0].is_able_to_create_briefcase
        // user is able to create normal guest pass, aka able to add item to briefcase
        setIsAuthToAdd(true);
      }
      // check if user is able create portfolio level guest pass
      if (true) {
        // userInfo[0].is_able_to_create_guest_pass
        // user is able to create normal guest pass, aka able to add item to briefcase
        setIsAuthBriefcaseShow(true);
      }
    }
  }, [userInfo]);

  const endSession = () => {
    endPortfolio();
    history.push('/engage');
  };

  const logout = () => {
    //////////onsole.log('Guest user logout');
    firebase.logout();
    // clear sessionkey in local storage and redux session key
    //updateSessionKey('');
    //clear all state
    clearAll();
  };

  const onExit = () => {
    if (sessionId === '') {
      endSession();
    } else {
      // create page visit info obj
      const pageVisitInfo = {
        is_end: true,
        time_visited: firestore.Timestamp.now()
      };
      firestore
        .collection('sessions')
        .doc(sessionId)
        .update({
          page_visited: firestore.FieldValue.arrayUnion(pageVisitInfo)
        })
        .then((e) => {
          // success
          //////////onsole.log('Firestore set/update success');
          // if guest mode logout
          // check guest mode
          if (isGuestMode) {
            logout();

            // open url
            if (
              chosenPortfolio.end_demo_url &&
              chosenPortfolio.end_demo_url !== ''
            ) {
              window.open(chosenPortfolio.end_demo_url, '_self');
            }
            return;
          } else {
            // end session for user
            endSession();
          }
        })
        .catch((error) => {
          console.error(error);
          endSession();
        });
    }
  };

  const updateFavorite = (op) => {
    Like(
      op,
      isGuestMode,
      sessionId,
      liked,
      currentGuestContentHelper,
      currentContent,
      chosenPortfolio,
      setIsLiked2,
      setLiked,
      firestore
    );
    // // do not log if mode is not guest or no sessionId
    // if (!isGuestMode || sessionId === '') {
    //   return null;
    // }

    // // copy liked from redux store
    // let likedArrCopy = [...liked];
    // //////////onsole.log("!!CC!!",currentContent)
    // let id = currentGuestContentHelper.id;
    // let content = currentContent;
    // // check if currentGuestContentHelper id is undefined
    // if (!id) {
    //   //////////onsole.log('like in full portfolio guest-pass view...');
    //   id = currentContent.id;
    //   // if undefined it means that guest is in full portfolio view and not in briefcase guest view
    // } else {
    //   // user in briefcase guest view
    //   //////////onsole.log('like in Briefcase guest view...');
    //   // set content from currentGuestContentHelper, if not splash
    //   if (id !== `splashkey-${chosenPortfolio._id}`) {
    //     content = currentGuestContentHelper;
    //   }
    // }
    // let content2 = {...content, time_liked:firestore.Timestamp.now()}
    // if(content2.PagesSaved === undefined){
    //   content2.PagesSaved = 1;
    // }
    // // if op is add then append currentContent obj to likedArrCopy,
    // if (op === 'add') {
    //   likedArrCopy.push(content2);
    // } else {
    //   // if not filter out
    //   likedArrCopy = likedArrCopy.filter((obj) => obj.id !== id);
    // }
    // //////////onsole.log("LIKED: ", likedArrCopy);
    // setIsLiked2(likedArrCopy)
    // // replace the liked array
    // firestore
    //   .collection('sessions')
    //   .doc(sessionId)
    //   .update({
    //     liked: likedArrCopy
    //   })
    //   .then(() => {
    //     // replace liked in store
    //     setLiked(likedArrCopy);
    //   })
    //   .catch((error) => console.error('ERROR: ', error));
  };

  // check if current page is liked already or not
  const isLiked = () => {
    let currentGuestContentId = currentGuestContentHelper.id;
    // check if currentGuestContentHelper id is undefined
    if (!currentGuestContentId) {
      //////////onsole.log('Liked check in full portfolio guest-pass view...');
      // check if liked contains currentContent id
      return liked.some((obj) => obj.id === currentContent.id);
    } else {
      // user in briefcase guest view
      //////////onsole.log('Liked check in Briefcase guest view...');
      if (currentGuestContentId === `splashkey-${chosenPortfolio._id}`) {
        // check if liked contains currentContent id for splash
        return liked.some((obj) => obj.id === currentContent.id);
      } else {
        // check if liked contains currentGuestContentId
        return liked.some((obj) => obj.id === currentGuestContentId);
      }
    }
  };

  //onsole.log("WINDOW DIMENSIONS", windowDimensions);
  //onsole.log("!!!CHECKING!!!",Size.W,Size.H,masterMenuOpen, !toolbarMenuVisible)

  const primaryColor = chosenPortfolio?.primary_color
    ? `${chosenPortfolio.primary_color}BF`
    : '#3b3b3b80';

  return (
    <div
      className={`portfolio-topright-toolbar ${
        toolbarMenuOpen ? 'active' : 'hidden'
      } ${isGuestMode ? 'guest' : 'user'}`}
      style={{
        //|| ((Size.W < 451 || Size.H < 451) && !toolbarMenuVisible)
        visibility:
          ((Size.W < 451 || Size.H < 451) && masterMenuOpen) ||
          ((Size.W < 451 || Size.H < 451) && !toolbarMenuVisible)
            ? 'hidden'
            : 'visible',
        transition:
          (Size.W < 451 && masterMenuOpen) ||
          ((Size.W < 451 || Size.H < 451) && !toolbarMenuVisible)
            ? '0s all ease'
            : '0.3s all ease',
        backgroundColor: chosenPortfolio?.toolkit_bg_color
          ? `${chosenPortfolio.toolkit_bg_color}BF`
          : primaryColor
      }}
    >
      <BuildRoundedIcon
        className={`topright-toolbar-icon ${
          toolbarMenuOpen ? 'active' : 'hidden'
        } ${isGuestMode ? 'guest' : 'user'}`}
        onClick={() => handleToggle()}
        style={{
          color: chosenPortfolio?.bulk_icon_override
            ? chosenPortfolio.bulk_icon_override
            : 'white',
          visibility: 'visible'
        }}
      />
      {isGuestMode && chosenPortfolio?.theme === 'theme8' ? (
        <div className="icon-flex-container guest">
          {/* {Size.W < 450 && Size.W > Size.H ?  */}
          {isLiked() && isLiked2 ? (
            <div className="icon-and-text-parent guest note">
              <Icons.Favorite
                id="Bypass"
                chosenPortfolio={chosenPortfolio}
                Size={Size}
                onClick={() => {
                  updateFavorite('remove');
                }}
              />
            </div>
          ) : (
            <div className="icon-and-text-parent guest">
              <Icons.FavoriteBorder
                id="Bypass"
                chosenPortfolio={chosenPortfolio}
                Size={Size}
                onClick={() => {
                  updateFavorite('add');
                }}
              />
            </div>
          )}
          {/* : null} */}
          <div className="icon-and-text-parent guest">
            <Icons.NoteAdd
              id="Bypass"
              className="icon"
              chosenPortfolio={chosenPortfolio}
              Size={Size}
              onClick={() => {
                toggleNotepad();
                handleVisibility();
              }}
              data-tip={Size.W > 450 ? 'Add Note' : null}
            />
          </div>
          <div className="icon-and-text-parent guest">
            {/* <div className='test-absolute-text'>
              <p
                onClick={() => {
                  shareClick(true);
                }}
                style={{
                  color: chosenPortfolio?.text_color ? chosenPortfolio.text_color : 'white',
                  fontFamily:FontUtil(chosenPortfolio.SystemText)
                }}
              >Share Portfolio</p>
            </div> */}
            <ShareRoundedIcon
              id="Bypass"
              className="menu-icon-custom"
              onClick={() => {
                shareClick(true);
                // toggleBriefcase();
                // setBooleanNewItems(false);
              }}
              style={{
                color: chosenPortfolio?.bulk_icon_override
                  ? chosenPortfolio.bulk_icon_override
                  : 'white'
              }}
              data-tip="Share Guest Pass"
              briefcaseContent={briefcaseContent}
              booleanNewItems={booleanNewItems}
            />
            {/* <Icons.ShoppingCart id="Bypass"
            data-tip="Show Briefcase"
              className="icon"
              onClick={() => {
                toggleBriefcase();
                setBooleanNewItems(false);
                handleVisibility();
              }}
              chosenPortfolio={chosenPortfolio}
              Size={Size}
              briefcaseContent={briefcaseContent}
              booleanNewItems={booleanNewItems}
            /> */}
          </div>
        </div>
      ) : null}
      <div
        className="icon-flex-container"
        style={{ visibility: toolbarMenuOpen ? 'visible' : 'hidden' }}
      >
        {(chosenPortfolio.theme === 'theme4' ||
          chosenPortfolio.theme === 'theme10') &&
          (Size.W < 451 || Size.H < 451) && (
            <div className="icon-and-text-parent">
              <div className="test-absolute-text">
                <p
                  onClick={() => {
                    handleToggle();
                  }}
                  style={{
                    color: chosenPortfolio?.text_color
                      ? chosenPortfolio.text_color
                      : 'white',
                    fontFamily: FontUtil(
                      chosenPortfolio.SystemText,
                      null,
                      "'Roboto Slab', serif"
                    )
                  }}
                >
                  CLOSE TOOLBAR
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '60px',
                  width: '60px'
                }}
              >
                <BuildRoundedIcon
                  id="Bypass"
                  className="icon"
                  style={{
                    fontSize: '38px',
                    color: chosenPortfolio?.bulk_icon_override
                      ? chosenPortfolio.bulk_icon_override
                      : 'white'
                  }}
                  onClick={() => {
                    handleToggle();
                  }}
                />
              </div>
            </div>
          )}
        {isAddable &&
          isAuthToAdd &&
          (!isGuestMode || chosenPortfolio.theme !== 'theme4') && (
            <div className="icon-and-text-parent">
              <div className="test-absolute-text">
                <p
                  onClick={() => {
                    addToBriefcase(currentContent);
                    if (showBriefcase === true) {
                      setBooleanNewItems(false);
                    } else {
                      setBooleanNewItems(true);
                    }
                  }}
                  style={{
                    color: chosenPortfolio?.text_color
                      ? chosenPortfolio.text_color
                      : 'white',
                    fontFamily: FontUtil(
                      chosenPortfolio.SystemText,
                      null,
                      "'Roboto Slab', serif"
                    )
                  }}
                >
                  ADD TO BRIEFCASE
                </p>
              </div>
              <Icons.AddShoppingCart
                id="Bypass"
                className="icon"
                chosenPortfolio={chosenPortfolio}
                Size={Size}
                onClick={() => {
                  addToBriefcase(currentContent);
                  if (showBriefcase === true) {
                    setBooleanNewItems(false);
                  } else {
                    setBooleanNewItems(true);
                  }
                }}
                overlay={Size.W > 450 ? 'Add to Briefcase' : null}
              />
            </div>
          )}
        {(isAuthToAdd || isAuthBriefcaseShow) &&
          (!isGuestMode || chosenPortfolio.theme !== 'theme4') && (
            <div className="icon-and-text-parent">
              <div className="test-absolute-text">
                <p
                  onClick={() => {
                    toggleBriefcase();
                    setBooleanNewItems(false);
                    handleVisibility();
                  }}
                  style={{
                    color: chosenPortfolio?.text_color
                      ? chosenPortfolio.text_color
                      : 'white',
                    fontFamily: FontUtil(
                      chosenPortfolio.SystemText,
                      null,
                      "'Roboto Slab', serif"
                    )
                  }}
                >
                  BRIEFCASE
                </p>
              </div>
              <Icons.ShoppingCart
                id="Bypass"
                data-tip="Show Briefcase"
                className="icon"
                onClick={() => {
                  toggleBriefcase();
                  setBooleanNewItems(false);
                  handleVisibility();
                }}
                chosenPortfolio={chosenPortfolio}
                Size={Size}
                briefcaseContent={briefcaseContent}
                booleanNewItems={booleanNewItems}
              />
            </div>
          )}
        {isGuestMode && (
          <div className="icon-and-text-parent">
            {/* <div className='test-absolute-text'>
              <p
                onClick={() => {
                  shareClick(true);
                }}
                style={{
                  color: chosenPortfolio?.text_color ? chosenPortfolio.text_color : 'white',
                  fontFamily:FontUtil(chosenPortfolio.SystemText)
                }}
              >Share Portfolio</p>
            </div> */}
            <ShareIcon
              id="Bypass"
              className="menu-icon-custom"
              Fill={chosenPortfolio.bulk_icon_override}
              onClick={() => {
                shareClick(true);
                // toggleBriefcase();
                // setBooleanNewItems(false);
              }}
              style={{ maxHeight: '70px', cursor: 'pointer' }}
              data-tip="Share Guest Pass"
              briefcaseContent={briefcaseContent}
              booleanNewItems={booleanNewItems}
            />
            {/* <Icons.ShoppingCart id="Bypass"
            data-tip="Show Briefcase"
              className="icon"
              onClick={() => {
                toggleBriefcase();
                setBooleanNewItems(false);
                handleVisibility();
              }}
              chosenPortfolio={chosenPortfolio}
              Size={Size}
              briefcaseContent={briefcaseContent}
              booleanNewItems={booleanNewItems}
            /> */}
          </div>
        )}
        {!isGuestMode ? (
          <div className="icon-and-text-parent">
            <div className="test-absolute-text">
              <p
                onClick={() => {
                  isModalOpen ? hideModal() : showModal();
                  handleVisibility();
                }}
                style={{
                  color: chosenPortfolio?.text_color
                    ? chosenPortfolio.text_color
                    : 'white',
                  fontFamily: FontUtil(
                    chosenPortfolio.SystemText,
                    null,
                    "'Roboto Slab', serif"
                  )
                }}
              >
                EDIT GUEST INFO
              </p>
            </div>
            <Icons.PersonAdd
              id="Bypass"
              className="icon"
              chosenPortfolio={chosenPortfolio}
              Size={Size}
              onClick={() => {
                isModalOpen ? hideModal() : showModal();
                handleVisibility();
              }}
              data-tip={Size.W > 450 ? 'Change Info' : null}
            />
          </div>
        ) : null}
        <div className="icon-and-text-parent">
          <div className="test-absolute-text">
            <p
              onClick={() => {
                toggleNotepad();
                handleVisibility();
              }}
              style={{
                color: chosenPortfolio?.text_color
                  ? chosenPortfolio.text_color
                  : 'white',
                fontFamily: FontUtil(
                  chosenPortfolio.SystemText,
                  null,
                  "'Roboto Slab', serif"
                )
              }}
            >
              {isGuestMode ? 'CONTACT' : 'NOTEPAD'}
            </p>
          </div>
          <Icons.NoteAdd
            id="Bypass"
            className="icon"
            chosenPortfolio={chosenPortfolio}
            Size={Size}
            onClick={() => {
              toggleNotepad();
              handleVisibility();
            }}
            data-tip={Size.W > 450 ? 'Add Note' : null}
          />
        </div>
        {mode === 'training' && (
          <div className="icon-and-text-parent">
            <div className="test-absolute-text">
              <p
                onClick={() => {
                  toggleScriptnote();
                  handleVisibility();
                }}
                style={{
                  color: chosenPortfolio?.text_color
                    ? chosenPortfolio.text_color
                    : 'white',
                  fontFamily: FontUtil(
                    chosenPortfolio.SystemText,
                    null,
                    "'Roboto Slab', serif"
                  )
                }}
              >
                SPEAKER NOTES
              </p>
            </div>
            <Icons.SpeakerNotes
              id="Bypass"
              className="icon"
              chosenPortfolio={chosenPortfolio}
              Size={Size}
              onClick={() => {
                toggleScriptnote();
                handleVisibility();
              }}
              data-tip={Size.W > 450 ? 'Speaker Notes' : null}
            />
          </div>
        )}
        {isGuestMode ? (
          isLiked() && isLiked2 ? (
            <div className="icon-and-text-parent">
              <div className="test-absolute-text">
                <p
                  onClick={() => {
                    updateFavorite('remove');
                  }}
                  style={{
                    color: chosenPortfolio?.text_color
                      ? chosenPortfolio.text_color
                      : 'white',
                    fontFamily: FontUtil(
                      chosenPortfolio.SystemText,
                      null,
                      "'Roboto Slab', serif"
                    )
                  }}
                >
                  LIKE
                </p>
              </div>
              <Icons.Favorite
                id="Bypass"
                chosenPortfolio={chosenPortfolio}
                Size={Size}
                onClick={() => {
                  updateFavorite('remove');
                }}
              />
            </div>
          ) : (
            <div className="icon-and-text-parent">
              <div className="test-absolute-text">
                <p
                  onClick={() => {
                    updateFavorite('add');
                  }}
                  style={{
                    color: chosenPortfolio?.text_color
                      ? chosenPortfolio.text_color
                      : 'white',
                    fontFamily: FontUtil(
                      chosenPortfolio.SystemText,
                      null,
                      "'Roboto Slab', serif"
                    )
                  }}
                >
                  LIKE
                </p>
              </div>
              <Icons.FavoriteBorder
                id="Bypass"
                chosenPortfolio={chosenPortfolio}
                Size={Size}
                onClick={() => {
                  updateFavorite('add');
                }}
              />
            </div>
          )
        ) : null}
        {isGuestMode ||
        !chosenPortfolio?.icon_set?.custom_icon_1?.web_link ? null : (
          <div className="icon-and-text-parent">
            <div className="test-absolute-text">
              <p
                onClick={() => {
                  let a = document.createElement('a');
                  a.href = chosenPortfolio.icon_set?.custom_icon_1?.web_link;
                  a.target = '_blank';
                  const clickHandler = (ele) => {
                    setTimeout(() => {
                      ele.removeEventListener('click', clickHandler);
                    }, 150);
                  };
                  a.addEventListener('click', clickHandler(a), false);
                  a.click();
                  return;
                }}
                style={{
                  color: chosenPortfolio?.text_color
                    ? chosenPortfolio.text_color
                    : 'white',
                  fontFamily: FontUtil(
                    chosenPortfolio.SystemText,
                    null,
                    "'Roboto Slab', serif"
                  )
                }}
              >
                WEB LINK
              </p>
            </div>
            <Icons.CustomIconOne
              id="Bypass"
              className="icon"
              chosenPortfolio={chosenPortfolio}
              Size={Size}
              data-tip={Size.W > 450 ? 'Calendar' : null}
            />
          </div>
        )}
        <div
          className="icon-and-text-parent annotator"
          style={{
            display:
              Size.W > 450 &&
              Size.H > 450 &&
              chosenPortfolio.icon_set?.annotator_icon?.IconFeatureToggle
                ? 'flex'
                : 'none'
          }}
        >
          {Size.W > 450 &&
          Size.H > 450 &&
          chosenPortfolio.icon_set?.annotator_icon?.IconFeatureToggle ? (
            <>
              <div className="test-absolute-text">
                <p
                  onClick={() => {
                    SET_ANNOTATOR(!ANNOTATOR2);
                    if (chosenPortfolio.theme === 'theme8') {
                      handleToggle();
                    }
                  }}
                  style={{
                    color: chosenPortfolio?.text_color
                      ? chosenPortfolio.text_color
                      : 'white',
                    fontFamily: FontUtil(
                      chosenPortfolio.SystemText,
                      null,
                      "'Roboto Slab', serif"
                    )
                  }}
                >
                  ANNOTATOR
                </p>
              </div>
              <Icons.AnnotatorIcon
                onClick={() => {
                  SET_ANNOTATOR(!ANNOTATOR2);
                  if (chosenPortfolio.theme === 'theme8') {
                    handleToggle();
                  }
                }}
              />
            </>
          ) : (
            <></>
          )}
        </div>
        {(!chosenPortfolio.KioskMode || isGuestMode || Size.W < 451) && (
          <div className="icon-and-text-parent">
            <div className="test-absolute-text">
              <p
                onClick={onExit}
                style={{
                  color: chosenPortfolio?.text_color
                    ? chosenPortfolio.text_color
                    : 'white',
                  fontFamily: FontUtil(
                    chosenPortfolio.SystemText,
                    null,
                    "'Roboto Slab', serif"
                  )
                }}
              >
                EXIT
              </p>
            </div>
            <Icons.EndDemo id="Bypass" onClick={onExit} />
          </div>
        )}
      </div>
    </div>
  );
};
export default TopRightToolbar;
