import React, { useState, useEffect, Fragment } from 'react';
import PortfolioContent from '../../../components/_PortfolioContentParent8/PortfolioContent';
import SubLevelContainer from '../../../components/_PortfolioContentParent8/SubLevelContainer';
import TopLevelContainer from '../../../components/_PortfolioContentParent8/TopLevelContainer';
import { isEmpty } from 'lodash';
import Icons from '../../Icons';
import MasterMenu from '../../../components/_PortfolioContentParent8/MasterMenu';
import TopRightToolbar from '../../../components/_PortfolioContentParent8/TopRightToolbar';
import MegaMenuNotepad from '../../../components/_PortfolioContentParent8/Notepad';
import PIIModal from '../../../components/_PortfolioContentParent8/PII';
import Briefcase from '../../../components/_Portfolio/PortfolioBriefcaseMegaMenu';
import ArrowRightAltRoundedIcon from '@material-ui/icons/ArrowRightAltRounded';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
import SubLevelThumb from '../../../components/_PortfolioContentParent8/SubLevelThumb';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import { urlFor } from '../../../utils/sanityHelper';
// import { useFirestore } from 'react-redux-firebase';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import FontUtil from '../../../utils/FontUtility';

const PortfolioContentParent8 = ({
  createSetCurrentContent,
  images,
  videos,
  pdfs,
  companyConfig,
  chosenPortfolio,
  pages,
  nav,
  pageBackgroundImageUrl,
  isGuestMode,
  isPortfolioView,
  guestContentHelper,
  currentContentHelper,
  isAtEnd,
  chosenPageId,
  chosenContentPageId,
  navHistory,
  setChosenPageId,
  setContentPageId,
  setCurrentContentHelper,
  setIsAtEnd,
  setNavHistory,
  setIsVideoPlaying,
  setSavedContentPageId,
  clickedTerminusId,
  hasCurr,
  hasPrev,
  setHasCurr,
  setHasPrev,
  currentContent,
  updateSessionWithNotes,
  sendClick,
  shareClick,
  masterMenuOpen,
  toolbarMenuOpen,
  Size,
  addToBriefcase,
  showBriefcase,
  setBooleanNewItems,
  setSelectedTopLevelId,
  briefcaseContent,
  userInfo,
  sessionId,
  liked,
  currentGuestContentHelper,
  setLiked,
  twolevelTerminus,
  setTwolevelTerminus,
  screensaverShow,
  Topic,
  setTopic,
  setMegamenuLevel,
  megamenuLevel,
  setTermWithChild,
  termWithChild
}) => {
  //Flag Font Family
  const [currentNavItem, setCurrentNavItem] = useState({});
  const [prevNavItem, setPrevNavItem] = useState({});
  const [currentTerminusSiblings, setCurrentTerminusSiblings] = useState([]);
  const [isAddable, setIsAddable] = useState(true); // bool to keep track of if it is addable to briefcase
  const [isAuthToAdd, setIsAuthToAdd] = useState(false); // is this user authorized to add item?
  //const [isAuthBriefcaseShow, setIsAuthBriefcaseShow] = useState(false); // is this user authorized to see briefcase?
  // const [isLiked2, setIsLiked2] = useState(null); // is this user authorized to see briefcase?
  const [LevelOfTerm, setLevelOfTerm] = useState(0); // keep track of previous page id for back button
  const [prevLevel, setPrevLevel] = useState(''); // keep track of previous page id for back button
  // const firestore = useFirestore();

  // animation state
  const [transitionedToTopLevel, setTransitionedToTopLevel] = useState(false);
  const [transitionedToSubLevel, setTransitionedToSubLevel] = useState(false);
  const [transitionedToEnd, setTransitionedToEnd] = useState(false);
  const [headerExtend, setHeaderExtend] = useState(true);

  useEffect(() => {
    if (isAtEnd) {
      setLevelOfTerm(prevLevel + 1);
    }
  }, [isAtEnd, prevLevel]);

  useEffect(() => {
    let splashKeyId = `splashkey-${chosenPortfolio._id}`;
    if (chosenPageId !== 0 && chosenPageId !== splashKeyId) {
      // get current nav item
      let tempNavItem = {};
      let tempPrevItem = {};
      // traverse nav history to find current nav
      navHistory.forEach((number, index) => {
        // first
        if (index === 0) {
          tempNavItem = nav.items[number];
        } else {
          tempNavItem = tempNavItem.children[number];
        }

        // check if index is one item before last
        if (index === navHistory.length - 1) {
          tempPrevItem = tempNavItem;
        }
      });

      // find corresponding page data using ref id
      const pageDetail =
        tempNavItem.referenceId && pages[tempNavItem.referenceId]
          ? pages[tempNavItem.referenceId]
          : null;

      // append text_color, update title to match with page if pageDetail is found
      const updatedNavItem = pageDetail
        ? {
            ...tempNavItem,
            title: pageDetail.title,
            text_color: pageDetail.text_color
          }
        : tempNavItem;

      setCurrentNavItem(updatedNavItem);
      setPrevNavItem(tempPrevItem);
      if (tempNavItem.children && tempNavItem.children.length > 0) {
        setHasCurr(true);
      }
      if (tempPrevItem.children && tempPrevItem.children.length > 0) {
        setHasPrev(true);
      }
      // UNCOMMENT THIS IF WE HAVE NAV PROBLEMS AND CHECK AGAIN.
      setContentPageId(tempNavItem.referenceId);
    }
    if (chosenPageId === splashKeyId) {
      prepareSplash();
    }
    // eslint-disable-next-line
  }, [chosenPageId, pages, navHistory, chosenPortfolio]);

  // run everytime chosenContentPageId updates
  useEffect(() => {
    if (chosenContentPageId) {
      //check if clicked page was gallery with multiple items
      //////////onsole.log("??UPDATING CHOSEN CONTENT PAGE ID AND CREATING OBJECT")
      if (
        pages[chosenContentPageId]?.content &&
        pages[chosenContentPageId]?._type === 'gallery' &&
        pages[chosenContentPageId]?.content.length > 1
      ) {
        // automatically set first item of gallery to current content viewed
        let obj = {
          type: 'gallery',
          index: 0,
          id: chosenContentPageId
        };
        createSetCurrentContent(obj);
      } else {
        // else do normal
        let obj = {
          type: 'pageSub',
          id: chosenContentPageId
        };
        createSetCurrentContent(obj);
      }
    } else {
      // show splash when chosenContentPageId is undefined
      //////////onsole.log('chosenContentPageId is undefined, show splash.....');
      prepareSplash();
    }
    // eslint-disable-next-line
  }, [
    chosenContentPageId,
    pages,
    guestContentHelper,
    isGuestMode,
    isPortfolioView
  ]);

  useEffect(() => {
    setTimeout(() => {
      setTransitionedToTopLevel(true);
    }, 200);
  }, []);

  // provides an array of sibling elements when we're in a terminus menu. Currently used for checking animation state
  useEffect(() => {
    if (currentNavItem?.children && isAtEnd) {
      let arr = [];
      for (let child of currentNavItem.children) {
        arr.push(child.referenceId);
      }
      setCurrentTerminusSiblings(arr);
    } else {
      setCurrentTerminusSiblings([]);
    }
  }, [currentNavItem, isAtEnd]);

  // handle transition animations between menu levels
  useEffect(() => {
    // sub level for mobile
    if (chosenContentPageId === `splashkey-${chosenPortfolio._id}`) {
      setTransitionedToSubLevel(false);
    } else {
      setTimeout(() => {
        setTransitionedToSubLevel(true);
      }, 200);
    }

    // between top and terminus levels
    if (isAtEnd && Size.W >= 451) {
      if (currentTerminusSiblings.includes(chosenContentPageId)) {
        return;
      }
      setTransitionedToEnd(false);
      setHeaderExtend(false);
      setTimeout(() => {
        setTransitionedToEnd(true);
        setHeaderExtend(true);
      }, 100);
    } else if (isAtEnd && Size.W < 451) {
      setTransitionedToEnd(true);
      setHeaderExtend(true);
    } else {
      setTransitionedToEnd(false);
      setHeaderExtend(false);
    }
  }, [
    isAtEnd,
    Size.W,
    chosenContentPageId,
    chosenPortfolio,
    currentTerminusSiblings
  ]);

  useEffect(() => {
    // check if briefcase contains current content user is looking at
    if (
      (Object.entries(currentContent)?.length !== 0 &&
        briefcaseContent.filter((e) => e.id === currentContent.id).length >
          0) ||
      currentContent.page_id === `splashkey-${chosenPortfolio._id}`
    ) {
      setIsAddable(false);
    } else {
      setIsAddable(true);
    }
  }, [currentContent, briefcaseContent, chosenPortfolio]);

  // get userinfo auth for viewing sending briefcase
  useEffect(() => {
    if (userInfo && userInfo.length > 0) {
      // TODO: Why is this constant condition?
      // check if user is able to create guest pass
      if (true) {
        //userInfo[0].is_able_to_create_briefcase
        // user is able to create normal guest pass, aka able to add item to briefcase
        setIsAuthToAdd(true);
      }
      // check if user is able create portfolio level guest pass
      if (true) {
        // userInfo[0].is_able_to_create_guest_pass
        // user is able to create normal guest pass, aka able to add item to briefcase
        // setIsAuthBriefcaseShow(true);
      }
    }
  }, [userInfo]);

  useEffect(() => {
    if (screensaverShow) {
      setTransitionedToEnd(false);
      setHeaderExtend(false);
      setTransitionedToTopLevel(false);
      setTransitionedToSubLevel(false);
      setTimeout(() => {
        setHasCurr(false);
        setHasPrev(false);
        setIsAtEnd(false);
        let splashKeyId = `splashkey-${chosenPortfolio._id}`;
        setContentPageId(splashKeyId);
        setTransitionedToTopLevel(true);
      }, 300);
    }
  }, [
    screensaverShow,
    chosenPortfolio._id,
    setHasCurr,
    setContentPageId,
    setHasPrev,
    setIsAtEnd
  ]);

  //onsole.log(isAuthBriefcaseShow)

  // const updateFavorite = (op) => {
  //   // do not log if mode is not guest or no sessionId
  //   if (!isGuestMode || sessionId === '') {
  //     return null;
  //   }

  //   // copy liked from redux store
  //   let likedArrCopy = [...liked];
  //   //////////onsole.log("!!CC!!",currentContent)
  //   let id = currentGuestContentHelper.id;
  //   let content = currentContent;
  //   // check if currentGuestContentHelper id is undefined
  //   if (!id) {
  //     //////////onsole.log('like in full portfolio guest-pass view...');
  //     id = currentContent.id;
  //     // if undefined it means that guest is in full portfolio view and not in briefcase guest view
  //   } else {
  //     // user in briefcase guest view
  //     //////////onsole.log('like in Briefcase guest view...');
  //     // set content from currentGuestContentHelper, if not splash
  //     if (id !== `splashkey-${chosenPortfolio._id}`) {
  //       content = currentGuestContentHelper;
  //     }
  //   }
  //   let content2 = {...content, time_liked:firestore.Timestamp.now()}
  //   if(content2.PagesSaved === undefined){
  //     content2.PagesSaved = 1;
  //   }
  //   // if op is add then append currentContent obj to likedArrCopy,
  //   if (op === 'add') {
  //     likedArrCopy.push(content2);
  //   } else {
  //     // if not filter out
  //     likedArrCopy = likedArrCopy.filter((obj) => obj.id !== id);
  //   }
  //   //////////onsole.log("LIKED: ", likedArrCopy);
  //   setIsLiked2(likedArrCopy)
  //   // replace the liked array
  //   firestore
  //     .collection('sessions')
  //     .doc(sessionId)
  //     .update({
  //       liked: likedArrCopy
  //     })
  //     .then(() => {
  //       // replace liked in store
  //       setLiked(likedArrCopy);
  //     })
  //     .catch((error) => console.error('ERROR: ', error));
  // };

  // check if current page is liked already or not
  // const isLiked = () => {
  //   let currentGuestContentId = currentGuestContentHelper.id;
  //   // check if currentGuestContentHelper id is undefined
  //   if (!currentGuestContentId) {
  //     //////////onsole.log('Liked check in full portfolio guest-pass view...');
  //     // check if liked contains currentContent id
  //     return liked.some((obj) => obj.id === currentContent.id);
  //   } else {
  //     // user in briefcase guest view
  //     //////////onsole.log('Liked check in Briefcase guest view...');
  //     if (currentGuestContentId === `splashkey-${chosenPortfolio._id}`) {
  //       // check if liked contains currentContent id for splash
  //       return liked.some((obj) => obj.id === currentContent.id);
  //     } else {
  //       // check if liked contains currentGuestContentId
  //       return liked.some((obj) => obj.id === currentGuestContentId);
  //     }
  //   }
  // };

  // function used when user changes gallery index
  const prepareCurrentGalleryContent = (index) => {
    let obj = {
      type: 'gallery',
      index: index,
      id: chosenContentPageId
    };
    createSetCurrentContent(obj);
  };

  // prepare splash to display
  const prepareSplash = () => {
    let splashKeyId = `splashkey-${chosenPortfolio._id}`;
    // create nav specifically for splash
    setCurrentNavItem({
      children: [],
      referenceField: 'pages',
      referenceId: splashKeyId,
      title: 'Splash',
      type: 'gallery',
      _key: 'splashnav'
    });
    setContentPageId(splashKeyId);
    // check if guest
    if (isGuestMode && !isPortfolioView) {
      // find index
      let index = guestContentHelper.findIndex((x) => x.id === splashKeyId);
      setCurrentContentHelper(guestContentHelper[index].id);
    }
    if (Topic.length === 0 || Topic === undefined || Topic === null) {
      setTopic(['SplashPage']);
    }
  };

  if (chosenContentPageId === 0) {
    return null;
  }

  const contentData = () => {
    let tabData = {};
    // check if in guest mode
    if (
      isGuestMode &&
      chosenContentPageId !== 0 &&
      !isEmpty(currentContentHelper) &&
      pages[chosenContentPageId]?._type === 'gallery' &&
      pages[chosenContentPageId]?._key === currentContentHelper.page_id &&
      currentContentHelper.is_gallery_item
    ) {
      //////////onsole.log('MODIFY FOR GALLERY CONTENT');
      // create obj to only display gallery item chosen by guest
      let obj =
        pages[chosenContentPageId]?.content[currentContentHelper.gallery_index];
      tabData = [{ ...obj }];
      return tabData;
    }
    return pages[chosenContentPageId]?.content;
  };

  // custom content data choice - May not be needed
  const contentDataChoice = (id) => {
    let tabData = {};
    // check if in guest mode
    if (
      isGuestMode &&
      id !== 0 &&
      !isEmpty(currentContentHelper) &&
      pages[id]._type === 'gallery' &&
      pages[id]._key === currentContentHelper.page_id &&
      currentContentHelper.is_gallery_item
    ) {
      //////////onsole.log('MODIFY FOR GALLERY CONTENT');
      // create obj to only display gallery item chosen by guest
      let obj = pages[id].content[currentContentHelper.gallery_index];
      tabData = [{ ...obj }];
      return tabData;
    }
    return pages[id].content;
  };

  if (!chosenContentPageId) {
    return null;
  }

  // color for background
  const getColor = (child) => {
    // get color for each specific page....
    let accentColor = pages[child.referenceId].accent_color;
    let textColor = pages[child.referenceId].text_color;

    // set to chosenPortfolio color if it doesn't exists
    accentColor = accentColor ? accentColor : chosenPortfolio.accent_color;
    textColor = textColor ? textColor : chosenPortfolio.text_color;
    // set to companyConfig color if it doesn't exists
    accentColor = accentColor ? accentColor : companyConfig.accent_color;
    textColor = textColor ? textColor : companyConfig.text_color;

    return { accentColor: accentColor, textColor: textColor };
  };

  // nav click / grid nav click
  const onNavClick = (child, index) => {
    //////onsole.log("HITTING NAV CLICK");
    //////onsole.log("NAV CLICK CHILD", child);
    //////onsole.log("NAV CLICK INDEX", index);
    const hasChild = child.children.length > 0;
    let isNotEnd = false;
    // check if child cnotains more child
    if (hasChild) {
      isNotEnd = child.children.some((childArray) => {
        return childArray.children.length !== 0;
      });
    }

    // setIsVideoPlaying(true);
    setIsAtEnd(!isNotEnd);

    if (!isNotEnd) {
      // is end display content
      // select first children if exists
      if (child?.children[0]) {
        setContentPageId(child.referenceId);
        setSavedContentPageId(child.referenceId);
        setCurrentNavItem(child);
        setTermWithChild(true);
        let topicalarray = [...Topic];
        //onsole.log("TOPIC ARRAY TRY BLOCK", Topic)
        //onsole.log("TOPIC ARRAY CURRENTLY - end", topicalarray);
        //onsole.log(`ADDING TOPIC ARRAY PARENT END: ${child.title}, ${child.referenceId}`);
        topicalarray.splice(megamenuLevel, 11111, {
          title: child.title,
          id: child.referenceId
        });
        //onsole.log("TOPIC ARRAY CURRENTLY PLUS PARENT END", topicalarray);
        setTopic(topicalarray);
        let newLevel = megamenuLevel + 1;
        setMegamenuLevel(newLevel);
        setTimeout(() => {
          let finalTopicArray = [...topicalarray];
          //onsole.log(`ADDING TOPIC ARRAY CHILD END: ${child.children[0].title}, ${child.children[0].referenceId}`);
          finalTopicArray.splice(newLevel, 11111, {
            title: child.children[0].title,
            id: child.children[0].referenceId
          });
          //onsole.log("TOPIC ARRAY CURRENTLY PLUS CHILD END", finalTopicArray);
          setTopic(finalTopicArray);
          setContentPageId(child.children[0].referenceId);
          setSavedContentPageId(child.children[0].referenceId);
        }, 501);
      } else {
        setContentPageId(child.referenceId);
        setSavedContentPageId(child.referenceId);
        setCurrentNavItem(child);
        //onsole.log(`SETTING TOPIC ARRAY IN NAV CLICK, NO CHILD - end: ${child.title}, ${megamenuLevel}, ${child.referenceId}`);
        SetTopicArray(child.title, megamenuLevel, child.referenceId);
        setMegamenuLevel(megamenuLevel + 1);
      }
      // setContentPageId(
      //   child.children[0] ? child.children[0].referenceId : child.referenceId
      // );
      // setSavedContentPageId(child.children[0] ? child.children[0].referenceId : child.referenceId);
      // setCurrentNavItem(child);
    } else {
      //not end, set pageId
      //setChosenPageId(child.referenceId);
      // update navHistory
      //onsole.log(`SETTING TOPIC ARRAY IN NAV CLICK - not end: ${child.title}, ${megamenuLevel}, ${child.referenceId}`);
      SetTopicArray(child.title, megamenuLevel, child.referenceId);
      setMegamenuLevel(megamenuLevel + 1);
      setNavHistory([...navHistory, index]);
    }
  };

  const onBackClick = () => {
    //////onsole.log("HITTING BACK CLICK");
    //////onsole.log('prevNavItem', prevNavItem);
    //////onsole.log('currentNavItem', currentNavItem);
    let tempNavHistory = [...navHistory];
    if (navHistory.length > 0 && !isAtEnd) {
      // remove one item from navHistory
      tempNavHistory = navHistory.filter((x, i) => i !== navHistory.length - 1);
      setNavHistory(tempNavHistory);
    }

    //set topic array
    BackTopicArray();

    setContentPageId(prevNavItem.referenceId);

    // back from terminal
    if (isAtEnd) {
      setIsAtEnd(false);
      setCurrentNavItem(prevNavItem);
      setContentPageId(prevNavItem.referenceId);
    }
  };

  const onBackClickTerminus = () => {
    let tempNavHistory = [...navHistory];
    if (navHistory.length > 0 && !isAtEnd) {
      // remove one item from navHistory
      tempNavHistory = navHistory.filter((x, i) => i !== navHistory.length - 1);
      setNavHistory(tempNavHistory);
    }

    //set topic array
    BackTopicArray();

    setTransitionedToEnd(false);
    setHeaderExtend(false);
    setTransitionedToTopLevel(false);
    setTimeout(() => {
      setIsAtEnd(false);
      setCurrentNavItem(prevNavItem);
      setContentPageId(prevNavItem.referenceId);
      setTimeout(() => {
        setTransitionedToTopLevel(true);
      }, 100);
    }, 300);
  };

  const returnTopLevel = () => {
    setTransitionedToEnd(false);
    setHeaderExtend(false);
    setTransitionedToTopLevel(false);
    setTransitionedToSubLevel(false);
    setTopic(['SplashPage']);
    setTimeout(() => {
      setHasCurr(false);
      setHasPrev(false);
      setIsAtEnd(false);
      let splashKeyId = `splashkey-${chosenPortfolio._id}`;
      setContentPageId(splashKeyId);
      setTransitionedToTopLevel(true);
    }, 300);
  };

  // const returnTopLevelMobile = () => {

  // }

  const returnTopLevelFromModal = () => {
    setTransitionedToEnd(false);
    setHeaderExtend(false);
    setTransitionedToSubLevel(false);
    setTopic(['SplashPage']);
    setHasCurr(false);
    setHasPrev(false);
    setIsAtEnd(false);
    let splashKeyId = `splashkey-${chosenPortfolio._id}`;
    setContentPageId(splashKeyId);
  };

  const checkBackClick = () => {
    if (Size.W < 451) {
      onBackClick();
    } else {
      onBackClickTerminus();
    }
    setSavedContentPageId(0);
  };

  const BackTopicArray = () => {
    let topicalarray = [];
    try {
      topicalarray = [...Topic];
    } catch (err) {
      console.error('Error', err);
    }
    //onsole.log("TOPIC ARRAY", topicalarray);
    if (termWithChild) {
      topicalarray.splice(Topic.length - 2, 111111);
      //onsole.log("SPLICING TOPIC ARRAY x2 BECAUSE OF TERM", topicalarray);
      setMegamenuLevel(megamenuLevel - 1);
      setTermWithChild(false);
    } else {
      //onsole.log("SPLICING TOPIC ARRAY x1", topicalarray);
      topicalarray.splice(Topic.length - 1, 111111);
      if (megamenuLevel >= 0) {
        setMegamenuLevel(megamenuLevel - 1);
      }
    }

    setTopic(topicalarray);
  };

  const SetTopicArray = (title, level, id) => {
    //onsole.log("ENTERING SET TOPIC ARRAY - LEVEL: ", level);
    let topicalarray = [];
    try {
      topicalarray = [...Topic];
    } catch (err) {
      console.error('Error', err);
    }

    if (!isAtEnd) {
      topicalarray.splice(level, 11111, { title: title, id: id });
      //onsole.log("TOPIC ARRAY NOT END", topicalarray);
      setTopic(topicalarray);
    } else {
      topicalarray.splice(level, 11111, { title: title, id: id });
      //onsole.log("TOPIC ARRAY END", topicalarray);
      setTopic(topicalarray);
    }
  };

  // //onsole.log("HAS CURR OR PREV - CURR: ", hasCurr);
  // //onsole.log("HAS CURR OR PREV - PREV: ", hasPrev);
  //onsole.log("CURRENT NAV ITEM", currentNavItem)
  //onsole.log("CHOSEN PORTFOLIO TEXT", chosenPortfolio);

  // portfolio text color
  const textColor = chosenPortfolio?.text_color
    ? chosenPortfolio.text_color
    : 'white';

  // portfolio primary color
  const primaryColor = chosenPortfolio?.primary_color
    ? `${chosenPortfolio.primary_color}BF`
    : '#3b3b3b80';

  return (
    <>
      <MasterMenu
        currentContent={currentContent}
        setCurrentNavItem={setCurrentNavItem}
        transitionedToTopLevel={transitionedToTopLevel}
        shareClick={shareClick}
      />
      <TopRightToolbar
        shareClick={shareClick}
        currentContent={currentContent}
        transitionedToTopLevel={transitionedToTopLevel}
      />
      <MegaMenuNotepad updateSessionWithNotes={updateSessionWithNotes} />
      <PIIModal />
      <Briefcase sendClick={sendClick} />
      {(Size.W < 451 || (Size.W < 815 && Size.H < 550)) &&
        (hasCurr || hasPrev) &&
        !isAtEnd && ( // submenu banner for mobile size
          <div
            className={`submenu-top-banner ${isGuestMode ? 'guest' : 'user'}`}
            style={{
              zIndex: masterMenuOpen || toolbarMenuOpen ? 2 : 3,
              transform:
                transitionedToSubLevel || Size.W < 451
                  ? 'translateY(0px)'
                  : 'translateY(-130px)',
              backgroundColor: chosenPortfolio?.title_bg_color
                ? `${chosenPortfolio.title_bg_color}BF`
                : primaryColor
            }}
          >
            <div className="back-arrow" onClick={() => onBackClick()}>
              <ArrowUpwardRoundedIcon
                style={{
                  fontSize: '2.4em',
                  color: chosenPortfolio?.bulk_icon_override
                    ? chosenPortfolio.bulk_icon_override
                    : 'white'
                }}
              />
            </div>
            <div
              className="home-icon"
              onClick={() => {
                if (
                  (Size.W < 450 && Size.W < Size.H) ||
                  (Size.W < 815 && Size.H < 550 && Size.W > Size.H)
                ) {
                  returnTopLevelFromModal();
                } else {
                  returnTopLevel();
                }
              }}
            >
              <HomeRoundedIcon
                style={{
                  fontSize: '2.4em',
                  color: chosenPortfolio?.bulk_icon_override
                    ? chosenPortfolio.bulk_icon_override
                    : 'white'
                }}
              />
            </div>
            {/* <div className='sub-level-thumb'>
            <SubLevelThumb 
              contentType={pages[chosenContentPageId]?._type}
              contents={contentData()}
              foundPage={pages[chosenContentPageId]}
              images={images}
              videos={videos}
              pdfs={pdfs}
              prepareCurrentGalleryContent={prepareCurrentGalleryContent}
              isTopLevel={false}
              isAtEnd={isAtEnd}
              onBackClick={onBackClick}
            />
          </div> */}
            <div className="top-banner-text">
              <p
                style={{
                  fontFamily: FontUtil(
                    chosenPortfolio.SystemText,
                    null,
                    "'Oswald', serif"
                  )
                }}
              >
                {isAtEnd ? prevNavItem.title : currentNavItem.title}
              </p>
            </div>
            {chosenPortfolio.portfolio_logo.asset._ref ? (
              <div className="logo-confirm">
                <img
                  style={{ objectFit: 'contain', cursor: 'pointer' }}
                  src={urlFor(chosenPortfolio.portfolio_logo.asset._ref)
                    .auto('format')
                    .width(70)
                    .url()}
                  alt="logo"
                />
              </div>
            ) : null}
          </div>
        )}
      {isAtEnd && (
        <div
          className={`submenu-top-banner ${isGuestMode ? 'guest' : 'user'}`}
          style={{
            zIndex: masterMenuOpen || toolbarMenuOpen ? 2 : 3,
            transform:
              headerExtend || Size.W < 451
                ? 'translateY(0px)'
                : 'translateY(-130px)',
            backgroundColor: chosenPortfolio?.title_bg_color
              ? `${chosenPortfolio.title_bg_color}BF`
              : primaryColor
          }}
        >
          {!isGuestMode ? (
            <div
              className="terminus-icon-menu"
              style={{
                visibility: toolbarMenuOpen ? 'hidden' : 'visible',
                backgroundColor:
                  chosenPortfolio?.primary_color && Size.W > 451
                    ? `${chosenPortfolio.primary_color}80`
                    : 'transparent',
                borderRadius: '8px'
              }}
            >
              {isAddable && isAuthToAdd && !isGuestMode ? (
                <Icons.AddShoppingCart
                  id="Bypass"
                  className="icon"
                  onClick={() => {
                    addToBriefcase(currentContent);
                    if (showBriefcase === true) {
                      setBooleanNewItems(false);
                    } else {
                      setBooleanNewItems(true);
                    }
                  }}
                  data-tip="Add to Briefcase"
                />
              ) : !isGuestMode ? (
                <CheckCircleRoundedIcon
                  style={{
                    fontSize: '3em',
                    color: chosenPortfolio?.bulk_icon_override
                      ? chosenPortfolio.bulk_icon_override
                      : 'white',
                    visibility: 'visible'
                  }}
                />
              ) : null}
            </div>
          ) : null}
          <div
            className="back-arrow"
            onClick={() => checkBackClick()}
            style={{
              visibility:
                (Size.W < 451 && Size.W < Size.H && twolevelTerminus) ||
                (Size.W < 815 &&
                  Size.H < 550 &&
                  Size.W > Size.H &&
                  twolevelTerminus)
                  ? 'hidden'
                  : 'visible'
            }}
          >
            <ArrowUpwardRoundedIcon
              style={{
                fontSize: '2.5em',
                color: chosenPortfolio?.bulk_icon_override
                  ? chosenPortfolio.bulk_icon_override
                  : 'white',
                cursor: 'pointer'
              }}
            />
          </div>
          <div
            className="home-icon"
            onClick={() => {
              if (
                (Size.W < 450 && Size.W < Size.H) ||
                (Size.W < 815 && Size.H < 550 && Size.W > Size.H)
              ) {
                returnTopLevelFromModal();
              } else {
                returnTopLevel();
              }
              setSavedContentPageId(0);
            }}
          >
            <HomeRoundedIcon
              style={{
                fontSize: '2.5em',
                color: chosenPortfolio?.bulk_icon_override
                  ? chosenPortfolio.bulk_icon_override
                  : 'white',
                cursor: 'pointer'
              }}
            />
          </div>
          {Size.W >= 451 ? (
            <div className="sub-level-thumb">
              <SubLevelThumb
                contentType={pages[currentNavItem.referenceId]._type}
                contents={contentData()}
                foundPage={pages[currentNavItem.referenceId]}
                images={images}
                videos={videos}
                pdfs={pdfs}
                prepareCurrentGalleryContent={prepareCurrentGalleryContent}
                isTopLevel={false}
                isAtEnd={isAtEnd}
                onBackClick={checkBackClick}
              />
            </div>
          ) : null}
          <div className="top-banner-text">
            <p
              style={{
                color: currentNavItem.text_color // if text_color exists in currentNavItem use that, if not use either chosenPortfolio.text_color or white
                  ? currentNavItem.text_color
                  : textColor,
                fontFamily: FontUtil(
                  chosenPortfolio.SystemText,
                  null,
                  "'Oswald', serif"
                ),
                fontSize: '28px',
                lineHeight: '28px' // NOTE: setting lineHeight to 28 here since other scss sets to 23px which cuts off text
              }}
            >
              {currentNavItem.title}
            </p>
          </div>
          {chosenPortfolio.portfolio_logo.asset._ref ? (
            <div className="logo-confirm">
              <img
                style={{ objectFit: 'contain', cursor: 'pointer' }}
                src={urlFor(chosenPortfolio.portfolio_logo.asset._ref)
                  .auto('format')
                  .width(70)
                  .url()}
                alt="logo"
              />
            </div>
          ) : null}
        </div>
      )}
      <div
        className="portfolio-content-parent"
        style={{
          backgroundImage: isAtEnd ? `url(${pageBackgroundImageUrl})` : '',
          zIndex: 1,
          opacity: transitionedToTopLevel === true ? 1 : 0
        }}
      >
        {((hasCurr || hasPrev || isAtEnd) &&
          ((Size.W < 450 && Size.W < Size.H) ||
            (Size.W < 815 && Size.H < 550 && Size.W > Size.H))) ||
        (isAtEnd &&
          ((Size.W >= 450 && Size.W < 1025 && Size.W < Size.H) ||
            (Size.W <= 1366 && Size.W > Size.H))) ? null : (
          <>
            <TopLevelContainer
              pages={pages}
              images={images}
              videos={videos}
              pdfs={pdfs}
              contentDataChoice={contentDataChoice}
              prepareCurrentGalleryContent={prepareCurrentGalleryContent}
              onNavClick={onNavClick}
              isAtEnd={isAtEnd}
              setCurrentNavItem={setCurrentNavItem}
              prevNavItem={prevNavItem}
              setContentPageId={setContentPageId}
              currentNavItem={isAtEnd ? prevNavItem : currentNavItem}
              chosenPortfolio={chosenPortfolio}
              onNavClick2={isAtEnd ? undefined : onNavClick}
              getColor={getColor}
              videoSelected={isAtEnd ? false : true}
              setHasCurr={setHasCurr}
              setHasPrev={setHasPrev}
              Size={Size}
              transitionedToSubLevel={transitionedToSubLevel}
              hasCurr={hasCurr}
              hasPrev={hasPrev}
              onBackClick={onBackClick}
              returnTopLevel={returnTopLevelFromModal}
              setSavedContentPageId={setSavedContentPageId}
              twolevelTerminus={twolevelTerminus}
              setTwolevelTerminus={setTwolevelTerminus}
              setPrevLevel={setPrevLevel}
              setTopicArray={SetTopicArray}
              backTopicArray={BackTopicArray}
              LevelOfTerm={LevelOfTerm}
            />
            <div
              className="scroll-confirm"
              style={{
                visibility:
                  (Size.W < 500 && !hasCurr && !hasPrev && !isAtEnd) ||
                  (Size.W > 451 && !isAtEnd)
                    ? 'visible'
                    : 'hidden'
              }}
            >
              <p
                style={{
                  color: textColor,
                  fontFamily: FontUtil(
                    chosenPortfolio.SystemText,
                    null,
                    "'Oswald', serif"
                  )
                }}
              >
                SCROLL GALLERY
              </p>
              <ArrowRightAltRoundedIcon
                style={{
                  fontSize: '2.3em',
                  color: textColor,
                  marginLeft: '10px'
                }}
              />
            </div>
            <div
              className="landing-page-info"
              style={{
                visibility:
                  ((Size.W < 451 || (Size.W < 815 && Size.H < 550)) &&
                    !hasCurr &&
                    !hasPrev &&
                    !isAtEnd) ||
                  (Size.W > 500 && Size.H > 500 && !isAtEnd)
                    ? 'visible'
                    : 'hidden'
              }}
            >
              <div className="title-box">
                <p
                  className="title"
                  style={{
                    color: chosenPortfolio?.title_color
                      ? chosenPortfolio.title_color
                      : textColor,
                    fontFamily: FontUtil(
                      chosenPortfolio.SystemText,
                      null,
                      "'Oswald', serif"
                    )
                  }}
                >
                  {chosenPortfolio?.MegaText?.landing_title
                    ? chosenPortfolio.MegaText.landing_title
                    : null}
                </p>
              </div>
              <div className="subtitle-box">
                <p
                  className="subtitle"
                  style={{
                    color: chosenPortfolio?.subtitle_color
                      ? chosenPortfolio.subtitle_color
                      : textColor,
                    fontFamily: FontUtil(
                      chosenPortfolio.SystemText,
                      null,
                      "'Oswald', serif"
                    )
                  }}
                >
                  {chosenPortfolio?.MegaText?.landing_subtitle
                    ? chosenPortfolio.MegaText.landing_subtitle
                    : null}
                </p>
              </div>
              <div className="description-box">
                <p
                  className="description"
                  style={{
                    color: chosenPortfolio?.description_color
                      ? chosenPortfolio.description_color
                      : textColor,
                    fontFamily: FontUtil(
                      chosenPortfolio.SystemText,
                      null,
                      "'Oswald', serif"
                    )
                  }}
                >
                  {chosenPortfolio?.MegaText?.landing_description
                    ? chosenPortfolio.MegaText.landing_description
                    : null}
                </p>
              </div>
            </div>
            {chosenPortfolio.portfolio_logo.asset._ref ? (
              <div
                className="logo-confirm"
                style={{
                  visibility:
                    Size.W < 451 && !hasCurr && !hasPrev && !isAtEnd
                      ? 'visible'
                      : 'hidden'
                }}
              >
                <img
                  style={{ objectFit: 'contain', cursor: 'pointer' }}
                  src={urlFor(chosenPortfolio.portfolio_logo.asset._ref)
                    .auto('format')
                    .width(70)
                    .url()}
                  alt="logo"
                />
              </div>
            ) : null}
          </>
        )}
        {(Size.W < 451 || (Size.W < 815 && Size.H < 550)) &&
          (hasCurr || hasPrev) &&
          !isAtEnd && (
            <SubLevelContainer
              divName={'portfolio-big-nav-prev-container'}
              currentNavItem={isAtEnd ? prevNavItem : currentNavItem}
              chosenPortfolio={chosenPortfolio}
              pages={pages}
              images={images}
              videos={videos}
              pdfs={pdfs}
              contentDataChoice={contentDataChoice}
              prepareCurrentGalleryContent={prepareCurrentGalleryContent}
              onNavClick={isAtEnd ? undefined : onNavClick}
              getColor={getColor}
              videoSelected={isAtEnd ? false : true}
              isAtEnd={isAtEnd}
              setHasCurr={setHasCurr}
              setHasPrev={setHasPrev}
              Size={Size}
              transitionedToSubLevel={transitionedToSubLevel}
              companyConfig={companyConfig}
            />
          )}
        {isAtEnd && (
          <Fragment>
            <div
              className="portfolio-big-nav-prev-container"
              style={{
                alignItems: 'center',
                backgroundColor: '#FFF8F0BF',
                position: 'absolute',
                left: '85px',
                top: '85px',
                height: 'calc(100% - 5px)',
                width: 'calc(100% - 5px)',
                display: 'grid',
                gridTemplateColumns: '1.5fr 7fr 1fr',
                transition: '0.4s all ease',
                transform:
                  transitionedToEnd || Size.W < 451
                    ? 'translateY(0%)'
                    : 'translateY(100%)'
              }}
            >
              <div
                className={`portfolio-content-menu ${
                  currentNavItem.children && currentNavItem.children.length
                    ? 'active'
                    : 'hidden'
                } ${
                  !currentNavItem.children || !currentNavItem.children.length
                    ? 'maxed'
                    : 'normal'
                }`}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  gridColumn: '1 / span 1'
                }}
              >
                <div
                  className="menu-item-holder"
                  id={'menu-item-holder'}
                  style={{
                    height: '700px',
                    maxHeight: '700px',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexDirection: 'column',
                    overflowY: 'auto',
                    boxSizing: 'border-box',
                    position: 'relative'
                  }}
                >
                  {currentNavItem.children &&
                    currentNavItem.children.length > 0 && (
                      <>
                        {currentNavItem.children.map((child, i) => {
                          let foundPage = undefined;

                          try {
                            foundPage = pages[child.referenceId];
                          } catch (err) {
                            console.error('Error', err);
                          }

                          return (
                            <div
                              key={`k` + i}
                              className="terminus-level-thumb"
                              onClick={(e) => {
                                e.stopPropagation();
                                //onsole.log(`SETTING TOPIC ARRAY: ${child.title}, ${megamenuLevel}, ${child.referenceId}`);
                                SetTopicArray(
                                  child.title,
                                  megamenuLevel,
                                  child.referenceId
                                );
                                setSavedContentPageId(child.referenceId);
                                // only update if different
                                if (chosenContentPageId !== child.referenceId) {
                                  setContentPageId(child.referenceId);
                                }
                              }}
                              style={{
                                border:
                                  clickedTerminusId === child?.referenceId &&
                                  chosenPortfolio?.active_terminal_button
                                    ? `3px solid ${chosenPortfolio.active_terminal_button}`
                                    : clickedTerminusId ===
                                        child?.referenceId &&
                                      !chosenPortfolio?.active_terminal_button
                                    ? '3px solid #449FF5'
                                    : '3px solid transparent'
                              }}
                            >
                              <SubLevelThumb
                                contentType={foundPage._type}
                                contents={contentData()}
                                foundPage={foundPage}
                                images={images}
                                videos={videos}
                                pdfs={pdfs}
                                prepareCurrentGalleryContent={
                                  prepareCurrentGalleryContent
                                }
                                isTopLevel={false}
                                isAtEnd={isAtEnd}
                                onBackClick={null}
                                clickedTerminusId={clickedTerminusId}
                                isAtTerm={true}
                                child={child}
                                chosenPortfolio={chosenPortfolio}
                                companyConfig={companyConfig}
                              />
                            </div>
                          );
                        })}
                      </>
                    )}
                </div>
              </div>
              <div
                className={`portfolio-content-container ${
                  currentNavItem.children && currentNavItem.children.length
                    ? 'active'
                    : 'hidden'
                } ${
                  !currentNavItem.children || !currentNavItem.children.length
                    ? 'maxed'
                    : 'normal'
                }`}
                onClick={onBackClick}
              >
                <div
                  className={`portfolio-content-wrapper ${
                    currentNavItem.children && currentNavItem.children.length
                      ? 'active'
                      : 'hidden'
                  }`}
                  style={{ visibility: 'visible' }}
                  onClick={(e) => e.stopPropagation()}
                >
                  {chosenContentPageId !== 0 &&
                    pages[chosenContentPageId]?._type !== false && (
                      <PortfolioContent
                        contentType={pages[chosenContentPageId]?._type}
                        contents={contentData()}
                        page={pages[chosenContentPageId]}
                        images={images}
                        videos={videos}
                        pdfs={pdfs}
                        prepareCurrentGalleryContent={
                          prepareCurrentGalleryContent
                        }
                        isTopLevel={false}
                        isAtEnd={isAtEnd}
                        isAtTerm={true}
                        Size={Size}
                        chosenPortfolio={chosenPortfolio}
                        maxed={
                          !currentNavItem.children ||
                          !currentNavItem.children.length
                            ? true
                            : false
                        }
                      />
                    )}
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </>
  );
};
export default PortfolioContentParent8;
