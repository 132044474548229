// Keep track of notepad content

import {
  TOGGLE_NOTEPAD,
  UPDATE_NOTEPAD,
  END_PORTFOLIO,
  RESET_PORTFOLIO_INFO,
  KM_RESET,
  TOGGLE_BRIEFCASE,
  TOGGLE_SCRIPTNOTE,
  UPDATE_NOTEPAD2
} from '../constants/actionTypes';

const initialState = {
  showNotepad: false,
  notepadText: '',
  notepadText2: ''
};
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_NOTEPAD:
      return {
        ...state,
        showNotepad: !state.showNotepad
      };
    case UPDATE_NOTEPAD:
      return {
        ...state,
        notepadText: action.string
      };
    case UPDATE_NOTEPAD2:
      //onsole.log("notepadText2",action);
      return {
        ...state,
        notepadText2: action.string
      };
    case TOGGLE_BRIEFCASE:
    case TOGGLE_SCRIPTNOTE:
      return {
        ...state,
        showNotepad: false
      };
    case RESET_PORTFOLIO_INFO:
    case KM_RESET:
    case END_PORTFOLIO:
      return initialState;
    default:
      return state;
  }
};
