// This may need to rename to AdminUserTeamList instead of AdminPortal

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import MaterialTable, { MTableEditField } from 'material-table'; //https://material-table.com/#/docs/all-props
import SelectAll from '../../components/SelectAll';
//import { useFirebase } from 'react-redux-firebase';
import HideableList from '../../components/HideableList';
// import AdminMenuButtons from '../../components/AdminMenuButtons';
// import { urlFor } from '../../utils/sanityHelper';
import tableIcons from '../../utils/materialTableHelper';
import { sortByKey } from '../../utils/alphabetizer';
import { useFirestore } from 'react-redux-firebase';
import { userDataValidate, teamDataValidate } from '../../utils/validateHelper';

const StyledAdminPortal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledAdminContent = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const StyledTablesContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;

  h2 {
    margin-left: 10px;
  }
`;

const AdminPortal2 = ({
  userInfo,
  images,
  companyConfig,
  portfolio,
  adminReducer,
  updateSessionKey,
  endPortfolio,
  getUsers,
  createUser,
  patchUser,
  deleteUser,
  getTeams,
  createTeam,
  patchTeam,
  deleteTeam
}) => {
  const [userTableData, setUserTableData] = useState([]);
  const [userCount, setUserCount] = useState(0);
  const [CanSetGuestPass, setCanSetGuestPass] = useState(null);
  const [userOptions, setUserOptions] = useState([]);
  const [teamsOptions, setTeamsOptions] = useState([]);
  const [teamsLookup, setTeamsLookup] = useState({});
  const [portfolioOptions, setPortfolioOptions] = useState([]);
  const [portfolioLookup, setPortfolioLookup] = useState({});
  const firestore = useFirestore();
  //const firebase = useFirebase();
  useEffect(() => {
    if (userInfo.length > 0) {
      // get company id from current user
      let companyId = userInfo[0].company_id;
      getUsers(companyId);
      getTeams(companyId);
      firestore
        .collection('companies')
        .doc(companyId)
        .get()
        .then((Doc) => {
          ////////onsole.log(Doc.data());
          setCanSetGuestPass(true);
        });
      //Doc.data().is_able_to_create_briefcase
    }
  }, [userInfo, getUsers, getTeams, firestore]);

  // Create user data for table
  useEffect(() => {
    let users = adminReducer.users;
    if (users.length > 0) {
      let options = [];
      let data = [];
      let count = 0;
      users.forEach((obj) => {
        // if current user is manager, skip any user that is not active
        if (userInfo[0].role === 'manager' && !obj.isActive) {
          // do nothing
        } else {
          // count if active
          if (obj.isActive) {
            count++;
          }
          let disable = false;

          // manager should not be able to edit admin, or if not active
          if (
            (userInfo[0].role === 'manager' && obj.role === 'admin') ||
            !obj.isActive
          ) {
            disable = true;
          }
          // Need to replace true/false to unique string to use lookup in material-table
          if (!obj.isActive) {
            return;
          }
          data.push({
            ...obj,
            disable: disable
            // isAbleToCreateBriefcase: obj.isAbleToCreateBriefcase
            //   ? 'guestPassTrue'
            //   : 'guestPassFalse',
            // isAbleToCreateGuestPass: obj.isAbleToCreateGuestPass
            //   ? 'portfolioGuestPassTrue'
            //   : 'portfolioGuestPassFalse'
          });

          options.push({
            value: obj.id,
            label: obj.name !== '' ? obj.name : obj.email,
            disable: disable
          });
        }
      });

      setUserCount(count);
      // set user table data to use in material-table
      setUserTableData(data);
      // set user options for select dropdown
      setUserOptions(options);
    }
  }, [adminReducer.users, userInfo]);

  // Create portfolio dropdown content first time
  useEffect(() => {
    if (portfolio.length > 0) {
      let options = [];
      let lookup = {};
      let TPort = [...portfolio];
      TPort = sortByKey(TPort, 'title');
      TPort.forEach((obj) => {
        options.push({ label: obj.title, value: obj._id });

        // assuming _id will always be unique
        lookup[obj._id] = obj.title;
      });
      setPortfolioOptions(options);
      setPortfolioLookup(lookup);
    }
  }, [portfolio]);

  // Create teams dropdown and lookup
  useEffect(() => {
    let teams = adminReducer.teams;
    teams = sortByKey(teams, 'name');
    if (teams.length > 0) {
      let options = [];
      let lookup = {};
      teams.forEach((obj) => {
        options.push({ label: obj.name, value: obj.id });

        // assuming _id will always be unique
        lookup[obj.id] = obj.name;
      });

      setTeamsOptions(options);
      setTeamsLookup(lookup);
    }
  }, [adminReducer.teams]);

  /*
  const logout = () => {
    ////////onsole.log('LOGOUT');
    firebase.logout();
    // clear sessionkey in local storage and redux session key
    updateSessionKey('');
    // reset portfolioReducer
    endPortfolio();
  };
*/

  // Add user
  const onRowAddUser = (data) => {
    ////////onsole.log('add', data);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        data.submitted = true;

        if (!userDataValidate(data)) {
          return reject();
        }
        delete data.submitted;
        //data.isAbleToCreateGuestPass? data.isAbleToCreateGuestPass = "True" : data.isAbleToCreateGuestPass = "False"
        resolve();

        // update firestore
        createUser({ ...data, companyId: userInfo[0].company_id });
      }, 1000);
    });
  };

  // Update User
  const onRowUpdateUser = (data) => {
    ////////onsole.log('update', data);
    if (!CanSetGuestPass) {
      data.isAbleToCreateGuestPass = false;
    }
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        data.submitted = true;

        if (!userDataValidate(data)) {
          return reject();
        }
        delete data.submitted;
        // ////////onsole.log("Bool: ", data.isAbleToCreateBriefcase)
        // data.isAbleToCreateBriefcase? data.isAbleToCreateBriefcase = "True" : data.isAbleToCreateBriefcase = "False"
        // ////////onsole.log("PostBool: ", data.isAbleToCreateBriefcase)
        resolve();
        // update firestore
        patchUser({ ...data, companyId: userInfo[0].company_id });
      }, 1000);
    });
  };

  // Delete User
  const onRowDeleteUser = (data) => {
    ////////onsole.log('delete', data);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
        // update firestore
        deleteUser(data.id);
      }, 1000);
    });
  };

  // Add Team
  const onRowAddTeam = (data) => {
    ////////onsole.log('add', data);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        data.submitted = true;

        // validate team name. check if empty and check for duplicate
        if (!teamDataValidate(data, adminReducer.teams)) {
          return reject();
        }
        delete data.submitted;

        resolve();
        // update firestore

        createTeam({ ...data, companyId: userInfo[0].company_id });
      }, 1000);
    });
  };

  // Update Team
  const onRowUpdateTeam = (data) => {
    ////////onsole.log('update', data);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        data.submitted = true;

        // validate team name. check if empty and check for duplicate
        if (!teamDataValidate(data, adminReducer.teams)) {
          return reject();
        }
        delete data.submitted;

        resolve();
        // update firestore
        patchTeam({ ...data, companyId: userInfo[0].company_id });
      }, 1000);
    });
  };

  // Delete Team
  const onRowDeleteTeam = (data) => {
    ////////onsole.log('delete', data);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
        // update firestore
        deleteTeam(data.id);
      }, 1000);
    });
  };

  //--------------------
  // Columns
  //--------------------

  // users
  const tableColumns = [
    { title: 'Name', field: 'name', required: true },
    {
      title: 'Email',
      field: 'email',
      required: true,
      editable: userInfo[0].role === 'admin' ? 'always' : 'never'
    },
    {
      title: 'Role',
      field: 'role',
      required: true,
      lookup: {
        admin: 'Admin',
        manager: 'Manager',
        user: 'User'
      },
      editComponent: (props) => {
        let tempOptions = [
          { label: 'Admin', value: 'admin' },
          { label: 'Manager', value: 'manager' },
          { label: 'User', value: 'user' }
        ];

        if (userInfo[0].role === 'manager') {
          // remove admin choice if user is manager
          tempOptions = [
            { label: 'Manager', value: 'manager' },
            { label: 'User', value: 'user' }
          ];
        }

        // find from props.value
        let selectedValue = tempOptions.find(
          (obj) => obj.value === props.value
        );
        return (
          <SelectAll
            closeMenuOnSelect={true}
            hideSelectedOptions={true}
            allowSelectAll={false}
            styles={styles}
            options={tempOptions}
            onChange={(e) => props.onChange(e.value)}
            value={selectedValue}
          />
        );
      }
    },
    {
      title: 'Team',
      field: 'team',
      lookup: teamsLookup,
      render: (rowData) => {
        // filter with rowData.team
        let value = teamsOptions.filter((obj) => {
          //check if rowData has team
          let inTeam = rowData.team.includes(obj.value);
          return inTeam;
        });

        return <HideableList data={value} id={rowData.id} keyValue="label" />;
      },
      customFilterAndSearch: (lookup, rowData) => {
        // nothing on lookup, return all
        if (lookup.length === 0) {
          return true;
        }

        // custom filter to search if row data teams contain chosen lookup
        // NOTE lookup returns a string instead of number ie ["5"]
        return rowData.team.some((r) => lookup.indexOf(r.toString()) >= 0);
      },
      editComponent: (props) => {
        let modifiedValues = props.value;

        // only modify if props.value is not in react-select option {label: "", value: ""}
        if (props.value && props.value[0] && !props.value[0].label) {
          modifiedValues = teamsOptions
            .filter((obj) => props.value.includes(obj.value))
            .map((obj) => {
              return { label: obj.label, value: obj.value };
            });
        }

        return (
          <SelectAll
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={true}
            allowSelectAll={true}
            styles={styles}
            options={teamsOptions}
            onChange={props.onChange}
            value={modifiedValues}
          />
        );
      }
    },
    {
      title: 'Viewable portfolios',
      field: 'portfolio',
      lookup: portfolioLookup,
      render: (rowData) => {
        // filter with rowData.portfolio
        let value = portfolioOptions.filter((obj) => {
          //check if rowData has portfolio
          let hasPortfolio = rowData.portfolio.includes(obj.value);
          return hasPortfolio;
        });
        return <HideableList data={value} id={rowData.id} keyValue="label" />;
      },
      customFilterAndSearch: (lookup, rowData) => {
        // nothing on lookup, return all
        if (lookup.length === 0) {
          return true;
        }
        // custom filter to search if row data portfolio contain chosen lookup
        return rowData.portfolio.some((r) => lookup.indexOf(r) >= 0);
      },
      editComponent: (props) => {
        let modifiedValues = props.value;

        // only modify if props.value is not in react-select option {label: "", value: ""}
        if (props.value && props.value[0] && !props.value[0].label) {
          modifiedValues = portfolio
            .filter((obj) => props.value.includes(obj._id))
            .map((obj) => {
              return { label: obj.title, value: obj._id };
            });
        }
        return (
          <SelectAll
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={true}
            allowSelectAll={true}
            styles={styles}
            options={portfolioOptions}
            onChange={props.onChange}
            value={modifiedValues}
          />
        );
      }
    },
    {
      title: 'Able to create briefcase',
      field: 'isAbleToCreateBriefcase',
      type: 'boolean',
      editable: userInfo[0].role === 'admin' ? 'always' : 'never',
      // lookup: {
      //   guestPassTrue: 'True',
      //   guestPassFalse: 'False'
      // },
      initialEditValue: true
      // editComponent:(props)=>{
      //   defaultValue = (props.value || guestPassTrue)
      // },
    },
    {
      title: 'Able to create guest pass',
      field: 'isAbleToCreateGuestPass',
      type: 'boolean',
      initialEditValue: false,
      editable:
        userInfo[0].role === 'admin'
          ? CanSetGuestPass
            ? 'always'
            : 'never'
          : 'never'
      // lookup: {
      //   portfolioGuestPassTrue: 'True',
      //   portfolioGuestPassFalse: 'False'
      // }
    }
  ];

  // teams
  const teamsColumns = [
    { title: 'Team Name', field: 'name', required: true },
    {
      title: 'Users',
      field: 'users',
      customFilterAndSearch: (term, rowData) => {
        let data = [];
        let searchTerm = term.toUpperCase();
        // check if it has users
        if (rowData.users) {
          data = userTableData.filter((obj) => {
            // get user info from using ids in users
            let hasUser = rowData.users.includes(obj.id);
            return hasUser;
          });
        } else {
          return false;
        }

        return data.some((obj) => obj.name.toUpperCase().includes(searchTerm));
      },
      render: (rowData) => {
        let data = [];
        // check if it has users
        if (rowData.users) {
          data = userTableData.filter((obj) => {
            // get user info from using ids in users
            let hasUser = rowData.users.includes(obj.id);
            return hasUser;
          });
        }
        return <HideableList data={data} id={rowData.id} keyValue="name" />;
      },
      editComponent: (props) => {
        let modifiedValues = props.value;

        // only modify if props.value is not in react-select option {label: "", value: ""}
        if (props.value && props.value[0] && !props.value[0].label) {
          modifiedValues = userOptions
            .filter((obj) => props.value.includes(obj.value))
            .map((obj) => {
              return { label: obj.label, value: obj.value };
            });
        }

        return (
          <SelectAll
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={true}
            allowSelectAll={false}
            styles={styles}
            options={userOptions}
            onChange={props.onChange}
            value={modifiedValues}
            isOptionDisabled={(option) => option.disable}
          />
        );
      }
    }
  ];

  // react-select styles
  const styles = {
    control: (base) => ({
      ...base,
      width: '100%',
      border: 0
    }),
    container: (base) => ({
      ...base,
      textAlign: 'left'
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0
    }),
    clearIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0
    })
  };
  ////////onsole.log("UT: ", userTableData)

  return (
    <StyledAdminPortal backgroundColor={companyConfig.primary_color}>
      {/* <StyledAdminMenu primaryColor={companyConfig.primary_color}>
        <img
          src={urlFor(images[companyConfig.logo.asset._ref])
            .auto('format')
            .width(200)
            .url()}
          alt="company-logo"
        />
        <AdminMenuButtons companyConfig={companyConfig} userInfo={userInfo} />
      </StyledAdminMenu> */}
      <StyledAdminContent>
        <StyledTablesContainer>
          <h2>Admin</h2>
          <MaterialTable
            minRows={10}
            options={{
              draggable: false,
              search: false,
              filtering: true,
              sorting: true,
              actionsColumnIndex: -1,
              pageSize: 10,
              pageSizeOptions: [0, 5, 10, 20, 40],
              rowStyle: (rowData) => ({
                color: rowData.disable ? '#AAA' : '#000'
              })
            }}
            editable={
              userInfo[0].role === 'admin'
                ? {
                    isEditable: (rowData) => !rowData.disable,
                    isDeletable: (rowData) => !rowData.disable,
                    onRowAdd: onRowAddUser,
                    onRowUpdate: onRowUpdateUser,
                    onRowDelete: onRowDeleteUser
                  }
                : userInfo[0].role === 'manager'
                ? {
                    isEditable: (rowData) => !rowData.disable,
                    isDeletable: (rowData) => !rowData.disable,
                    onRowAdd: null,
                    onRowUpdate: onRowUpdateUser,
                    onRowDelete: onRowDeleteUser
                  }
                : null
            }
            localization={{
              body: {
                emptyDataSourceMessage: 'Loading...'
              }
            }}
            components={{
              EditField: (props) => {
                if (props.columnDef.required && props.rowData.submitted) {
                  return <MTableEditField {...props} error label="Required" />;
                }
                return <MTableEditField {...props} />;
              }
            }}
            icons={tableIcons}
            columns={tableColumns}
            data={userTableData.map((obj) => ({ ...obj }))} // NOTE MaterialTable mutates data passed so give copy of userTableData
            title={`Users (${userCount})`}
          />
          <MaterialTable
            minRows={5}
            options={{
              draggable: false,
              search: false,
              filtering: true,
              sorting: true,
              actionsColumnIndex: -1,
              pageSize: 10,
              pageSizeOptions: [0, 5, 10, 20, 40]
            }}
            editable={{
              isDeletable: (rowData) => true,
              onRowAdd: onRowAddTeam,
              onRowUpdate: onRowUpdateTeam,
              onRowDelete: onRowDeleteTeam
            }}
            localization={{
              body: {
                emptyDataSourceMessage: 'Loading...'
              }
            }}
            components={{
              EditField: (props) => {
                if (props.columnDef.required && props.rowData.submitted) {
                  return (
                    <MTableEditField
                      {...props}
                      error
                      label="Error, make sure team name is unique"
                    />
                  );
                }
                return <MTableEditField {...props} />;
              }
            }}
            icons={tableIcons}
            columns={teamsColumns}
            data={adminReducer.teams.map((obj) => ({ ...obj }))} // NOTE MaterialTable mutates data passed so give copy of adminReducer.teams
            title={'Teams'}
          />
        </StyledTablesContainer>
      </StyledAdminContent>
    </StyledAdminPortal>
  );
};

export default AdminPortal2;
