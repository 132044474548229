// Session key for keeping track single device login

import {
  UPDATE_SESSION_KEY,
  SET_FIRESTORE_SESSION_KEY
} from '../constants/actionTypes';

const initialState = {
  sessionKey: '',
  fireStoreSessionStatus: 'idle'
};
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SESSION_KEY:
      return {
        ...state,
        sessionKey: action.sessionKey
      };
    case SET_FIRESTORE_SESSION_KEY.BEGIN:
      return {
        ...state,
        fireStoreSessionStatus: 'request'
      };
    case SET_FIRESTORE_SESSION_KEY.SUCCESS:
    case SET_FIRESTORE_SESSION_KEY.FAILURE:
      return {
        ...state,
        fireStoreSessionStatus: 'idle'
      };
    default:
      return state;
  }
};
