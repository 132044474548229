import React, { useEffect } from 'react';
import styled from 'styled-components';
import AdminWelcome from '../../components/_Admin/AdminWelcome';
import AdminPortal from '../AdminPortal';
import { useFirebase } from 'react-redux-firebase';
import { useHistory } from 'react-router-dom';
import { urlFor } from '../../utils/sanityHelper';
const StyledAdminPage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : 'rgb(7, 104, 176)'};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AdminPage = ({ images, companyConfig, updateSessionKey, clearAll }) => {
  const history = useHistory();
  const firebase = useFirebase();

  // Create dropdown content first time
  useEffect(() => {}, []);

  const logout = () => {
    //////////onsole.log('LOGOUT');
    firebase.logout();
    // clear sessionkey in local storage and redux session key
    updateSessionKey('');
    // reset portfolioReducer
    clearAll();
  };

  // check role
  /*
  if (role === '') {
    return null;
  }
*/
  return (
    <StyledAdminPage backgroundColor={companyConfig.primary_color}>
      {history.location.pathname === '/admin/portal' ? (
        <AdminPortal />
      ) : (
        <AdminWelcome
          companyConfig={companyConfig}
          logout={logout}
          logo={urlFor(images[companyConfig.logo.asset._ref])
            .auto('format')
            .width(600)
            .url()}
        />
      )}
    </StyledAdminPage>
  );
};

export default AdminPage;
