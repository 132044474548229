import {
  GET_USERS,
  CREATE_USER,
  PATCH_USER,
  DELETE_USER,
  GET_TEAMS,
  CREATE_TEAM,
  PATCH_TEAM,
  DELETE_TEAM
} from '../constants/actionTypes';

const initialState = {
  users: [],
  teams: []
};

// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS.SUCCESS:
      return {
        ...state,
        users: [...action.array]
      };
    case CREATE_USER.SUCCESS:
      return {
        ...state,
        users: [...state.users, action.obj]
      };
    case PATCH_USER.SUCCESS:
      return {
        ...state,
        users: state.users.map((obj) => {
          // find modified/patched obj
          if (obj.id === action.obj.id) {
            return action.obj;
          }
          return obj;
        })
      };
    case DELETE_USER.SUCCESS:
      return {
        ...state,
        users: state.users.map((obj) => {
          // find by matching id
          if (obj.id === action.id) {
            // return is_active false
            return { ...obj, isActive: false };
          }
          return obj;
        })
      };
    case GET_TEAMS.SUCCESS:
      return {
        ...state,
        teams: [...action.array]
      };
    case CREATE_TEAM.SUCCESS:
      return {
        ...state,
        teams: [...state.teams, action.obj]
      };
    case PATCH_TEAM.SUCCESS:
      return {
        ...state,
        teams: state.teams.map((obj) => {
          // find modified/patched obj
          if (obj.id === action.obj.id) {
            return action.obj;
          }
          return obj;
        })
      };
    case DELETE_TEAM.SUCCESS:
      return {
        ...state,
        teams: state.teams.filter((obj) => {
          // filter out matching id
          return obj.id !== action.id;
        })
      };
    default:
      return state;
  }
};
