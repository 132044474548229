import { connect } from 'react-redux';
import Notes from './NRT.js';
import {
  SetGuestPassMasterCollection,
  SetFilteredGuestpass,
  SetSessionMasterCollection,
  SetFilteredSession,
  SetGuestpassSessionMasterCollection,
  SetFilteredGuestPassSessionWithoutGPID
} from '../../../../../actions';

const mapStateToProps = (state) => ({
  Firestore: state.firestore,
  Dash: state.DashboardReducer,
  userInfo: state.firestore.ordered.users,
  views: state.DashboardReducer.views,
  Size: state.portfolioReducer.Size
});

const mapDispatchToProps = (dispatch) => {
  return {
    SGPMC: (UI, firestore) =>
      dispatch(SetGuestPassMasterCollection(UI, firestore)),
    SGPFC: (UI) => dispatch(SetFilteredGuestpass(UI)),
    SSMC: (UI, firestore) =>
      dispatch(SetSessionMasterCollection(UI, firestore)),
    SGPSMC: (UI, firestore) => {
      dispatch(SetGuestpassSessionMasterCollection(UI, firestore));
    },
    SFS: (UI) => {
      dispatch(SetFilteredSession(UI));
    },
    SFGPS: (UI) => dispatch(SetFilteredGuestPassSessionWithoutGPID(UI))
  };
};

const DashboardNotes = connect(mapStateToProps, mapDispatchToProps)(Notes);

export default DashboardNotes;
