import React from 'react';
import SMStandard from '../SubmenuItem';
import SMSingleItem from '../SubmenuItemSingleItem';
import SMStandardM from '../MobileSpecificComponents/SubmenuItem';
import SMSingleItemM from '../MobileSpecificComponents/SubmenuItemSingleItem';

const Controller = ({
  pages,
  nav,
  navHistory,
  UNACTIVEEVENT,
  TMGD,
  G,
  I,
  Val,
  onClick,
  SetTopicArray,
  SetActive,
  Single,
  currentContent,
  createSetCurrentContent,
  setTMGD,
  ScrollPos
}) => {
  //////onsole.log("NAV",nav.items[navHistory[0]])//nav,navHistory, nav.items[navHistory[0]]
  //if(Val.referenceId ==="b2dad85465e9")
  //onsole.log("!!#Val",window.innerWidth, (window.innerWidth>750 && window.innerWidth < 1030 ))
  if (
    window.innerWidth > 450 &&
    window.innerHeight > 450 &&
    !(
      (window.innerWidth > 750 && window.innerWidth < 1030) ||
      (window.innerWidth > 1000 &&
        window.innerWidth < 1300 &&
        window.innerHeight > 1800 &&
        window.innerHeight < 2000)
    )
  ) {
    //&& (window.innerWidth/window.innerHeight >0.8) //navigator.userAgent.includes("Safari") &&//navigator.userAgent.includes("Safari") && !(navigator.userAgent.includes("Chrome") || navigator.userAgent.includes("Chromium"))) //window.innerHeight > window.innerWidth &&
    if (
      Val.type === 'STgallery' ||
      pages[Val.referenceId].StoryBookgallery?.SingleItem
    ) {
      //nav.items[navHistory[0]]?
      return (
        <SMSingleItem
          UNACTIVEEVENT={UNACTIVEEVENT}
          ScrollPos={ScrollPos}
          setTMGD={setTMGD}
          TMGD={TMGD}
          level={1}
          G={G}
          Val={Val}
          onButtonClick={onClick}
          SetTopicArray={SetTopicArray}
          SetActive={SetActive}
          Single={Single}
          currentContent={currentContent}
          createSetCurrentContent={createSetCurrentContent}
        ></SMSingleItem>
      );
    } else if (nav.items[navHistory[0]] === null) return null;
    else {
      return (
        <SMStandard
          ScrollPos={ScrollPos}
          TMGD={TMGD}
          level={1}
          G={G}
          Val={Val}
          onButtonClick={onClick}
          SetTopicArray={SetTopicArray}
        ></SMStandard>
      );
    }
  } else {
    //onsole.log("Mobile")
    if (
      Val.type === 'STgallery' ||
      pages[Val.referenceId].StoryBookgallery?.SingleItem
    ) {
      //nav.items[navHistory[0]]?
      return (
        <SMSingleItemM
          UNACTIVEEVENT={UNACTIVEEVENT}
          setTMGD={setTMGD}
          TMGD={TMGD}
          level={1}
          G={G}
          Val={Val}
          onButtonClick={onClick}
          SetTopicArray={SetTopicArray}
          SetActive={SetActive}
          Single={Single}
          currentContent={currentContent}
          createSetCurrentContent={createSetCurrentContent}
        ></SMSingleItemM>
      );
    } else if (nav.items[navHistory[0]] === null) return null;
    else {
      return (
        <SMStandardM
          TMGD={TMGD}
          level={1}
          G={G}
          Val={Val}
          onButtonClick={onClick}
          SetTopicArray={SetTopicArray}
        ></SMStandardM>
      );
    }
  }
};
export default Controller;

//<div style={{height:"100%",width:"100%", gridRow:"2/2", position:"absolute", overflow:"hidden", overscrollBehavior:"contain"}}>
