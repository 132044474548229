import React, { useState, useEffect, useRef } from 'react'; //,useRef
import BlockContent from '@sanity/block-content-to-react';
import serializers from '../../../../../utils/scriptSerializers';
// import Circle from './Ellipse 1.png'
// import Arrow from './Vector 1.png'
import Arrow2 from './Vector 2.png';
import FontUtil from '../../../../../utils/FontUtility';
import ColorUtil from '../../../../../utils/ColorUtility';
// import Globe from './Icons V1-45.png'
// import Photo from './Icons V1-44.png'
// import Video from './Icons V1-43.png'
// import Page from './Icons V1-41.png'
// import Scrollindicator from './icon-Scroll-Drag-Right.png'
// eslint-disable-next-line
// import SingleItemDisplay from '../../SingleItemDisplay';
import PDFViewer2 from '../../../../../components/FullWidthPDFViewer'; //BeforeAfterSlider
import BASlider from '../../../../../components/BeforeAfterSlider'; //BeforeAfterSlider
//import BlockContent from '@sanity/block-content-to-react';
// import {renderSingleImageExport} from '../../../../components/_Portfolio/PortfolioContent/PortfolioContent'
// import {contentDataExport} from '../../../../components/_Portfolio/PortfolioContentParentTemplateX/PortfolioContentParent'
// import { Transform } from '@material-ui/icons';
// import { isEmpty } from 'lodash';
import { urlFor } from '../../../../../utils/sanityHelper';
//import serializers from '../../../../utils/serializers';
const SubMenuItem = ({
  G,
  Val,
  pages,
  onButtonClick,
  level,
  images,
  videos,
  pdfs,
  isGuestMode,
  chosenPortfolio,
  key,
  SetTopicArray,
  TMGD,
  Single,
  currentContent,
  createSetCurrentContent,
  setTMGD
}) => {
  //Flag Font Family
  // const Limit = 4;
  // const [Target] = useState(0);//,setTarget
  // const [Items,setItems]= useState(<></>)
  ////onsole.log("!!CHECK",pages[Val.referenceId]);
  let OVTINT = undefined;
  if (
    chosenPortfolio.overlay_tint_color &&
    chosenPortfolio.overlay_tint_color !== '#' &&
    chosenPortfolio.overlay_tint_color !== undefined
  ) {
    OVTINT = chosenPortfolio.overlay_tint_color + '40';
  }
  let SMColor = undefined;
  if (
    chosenPortfolio.submenu_color &&
    chosenPortfolio.submenu_color !== '#' &&
    chosenPortfolio.submenu_color !== undefined
  )
    SMColor = chosenPortfolio.submenu_color;
  if (
    pages[Val.referenceId].submenu_color &&
    pages[Val.referenceId].submenu_color !== '#' &&
    pages[Val.referenceId].submenu_color !== undefined
  )
    SMColor = pages[Val.referenceId].submenu_color;
  const [Active, SetActive] = useState(false);
  // const test = addEventListener("UNACTIVEEVENT",()=>{SetActive(false)})
  useEffect(() => {
    if (currentContent.page_id !== Val.referenceId) {
      SetActive(false);
      return;
    }
    if (Active)
      Single({
        SetActive: SetActive,
        Single: Single,
        Position: 1,
        currentContent: currentContent,
        createSetCurrentContent: createSetCurrentContent
      });
    //onsole.log("!!!STATUS",currentContent);
  }, [
    Active,
    Single,
    createSetCurrentContent,
    currentContent,
    Val.referenceId
  ]);
  const blockContent = (blocks) => {
    // filter non valid imageLinks
    let tempBlocks = blocks.filter((obj) => {
      if (obj._type === 'imageLinks' && obj.image) {
        return true;
      }
      if (obj._type !== 'imageLinks') {
        return true;
      }
      return false;
    });

    // further filter non valid embedHtml
    tempBlocks = tempBlocks.filter((obj) => {
      if (obj._type === 'embedHTML' && obj.html) {
        return true;
      }
      if (obj._type !== 'embedHTML') {
        return true;
      }
      return false;
    });
    return tempBlocks;
  };
  const contentDataExport = (ID) => {
    //let tabData = {};
    // if (
    //   isGuestMode &&
    //   ID !== 0 &&
    //   !isEmpty(currentContentHelper) &&
    //   pages[ID]._type === 'gallery' &&
    //   pages[ID]._key === currentContentHelper.page_id &&
    //   currentContentHelper.is_gallery_item
    // ) {
    //   //////////onsole.log('MODIFY FOR GALLERY CONTENT');
    //   // create obj to only display gallery item chosen by guest
    //   let obj =
    //     pages[ID].content[currentContentHelper.gallery_index];
    //   tabData = [{ ...obj }];
    //   //////////onsole.log(pages[ID]);
    //   //////////onsole.log('!!!!TAB DATA!!!! ', tabData);
    //   return tabData;
    // }
    //////////onsole.log('!!!!CHOSEN CONTENT PAGE ID ', ID);
    return pages[ID].StoryBookgallery.STcontent;
  };
  const renderSingleImageExport = (type, galleryNum, contents) => {
    switch (type) {
      case 'galleryImage': {
        let image = contents[galleryNum].image;
        return (
          <img
            className="portfolio-single-image"
            src={urlFor(image).auto('format').url()} // auto converts to webp if browser supports it
            alt="mainImage"
            type="galleryImage"
            style={{
              height: '100%',
              width: '100%',
              objectFit: 'cover'
              //   opacity: chosenPortfolio && chosenPortfolio.theme === 'theme2' ? opacityBack : 1,
              //   width: imageWidth,
              //   height: imageHeight
            }}
          />
        );
      }
      case 'galleryVideo': {
        let video = videos[contents[galleryNum].video.asset._ref];
        //let videoSettings = contents[galleryNum];
        return (
          <img
            style={{ height: '100%', width: '100%', objectFit: 'contain' }}
            src={`https://image.mux.com/${
              video.playbackId
            }/thumbnail.png?fit_mode=preserve&amp;time=${
              video.thumbTime !== 0 ? video.thumbTime : 1
            }`}
            alt="poster"
          />
          // <SanityMuxPlayer
          //   assetDocument={video}
          //   autoload={true}
          //   autoplay={videoSettings.autoplay}
          //   loop={videoSettings.loop}
          //   muted={false}
          //   showControls={true}
          //   style={{}}
          //   posterurl={`https://image.mux.com/${video.playbackId}/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=1`}
          //   type="galleryVideo"
          //   isSelected={isSelected}
          // />
        );
      }
      case 'galleryPdf': {
        let url2 = pdfs[contents[galleryNum].file.asset._ref].url;
        let thumbNailRef2 = contents[galleryNum].thumbnail.asset._ref;
        let thumbNailUrl2 = urlFor(thumbNailRef2).auto('format').url();
        return (
          <PDFViewer2
            url={url2}
            isThumbnail={true}
            type="galleryFullWidthPdf"
            thumbnailUrl={thumbNailUrl2}
          />
        );
      }
      case 'galleryBASlider': {
        let Aimage = contents[galleryNum].Aimage;
        let Bimage = contents[galleryNum].Bimage;
        let LabelA = contents[galleryNum].AimageL;
        let LabelB = contents[galleryNum].BimageL;
        let Vert = contents[galleryNum].Vert;
        let Color = contents[galleryNum].SlideColor;
        ////////onsole.log('!!!!BA!!!!', contents[galleryNum])
        return (
          <BASlider
            Thumb
            LabelB={LabelB}
            LabelA={LabelA}
            Color={Color}
            Vert={Vert}
            ImageA={Aimage}
            ImageB={Bimage}
          />
        );
      }
      case 'galleryFullWidthPdf': {
        let url3 = pdfs[contents[galleryNum].file.asset._ref].url;
        let thumbNailRef3 = contents[galleryNum].thumbnail.asset._ref;
        let thumbNailUrl3 = urlFor(thumbNailRef3).auto('format').url();
        return (
          <PDFViewer2
            url={url3}
            isThumbnail={true}
            type="galleryFullWidthPdf"
            thumbnailUrl={thumbNailUrl3}
          />
        );
      }
      case 'htmlContent': {
        return (
          <div className="html-block-content">
            <BlockContent
              blocks={blockContent(contents)}
              serializers={serializers}
            />
          </div>
        );
      }
      case 'website':
        return (
          <div
            style={{ height: '100%', width: '100%' }}
            className="iframe-container"
          >
            <iframe
              title="iframe"
              allowFullScreen
              src={contents}
              style={{
                backgroundColor: 'white',
                height: '100%',
                width: '100%'
              }}
            ></iframe>
          </div>
        );
      //   /src={page.url}
      default:
        return <div />;
    }
  };

  // const CanGoDown = ()=>{

  //     ////////onsole.log("down",Target,Target<Val.children.length-Limit)
  //     return Target<Val.children.length - Limit;
  // }
  // const CanGoUp = ()=>{
  //     ////////onsole.log("UP",Target,Target>0)
  //     return Target>0
  // }
  // useEffect(()=>{
  //     setItems(Val.children.map((ele,i)=>{
  //         //////onsole.log("RENDERSUBITEMS",i, Target)
  //         if(i>Target + Limit-1 || i< Target)
  //         return(<></>)
  //         //////onsole.log(pages[ele.referenceId]);
  //         let subtitle = pages[ele.referenceId].subtitle
  //         return(<div key={ele.referenceId} className="SubMenuItemWrapper" style={{display:"grid",gridTemplateRows:"1fr 2px", width:"calc(100% * (563 - (61 + 66)) / 563)", height:"120px", gridTemplateColumns:"1fr 90px"}}>
  //             <div className="SubMenuTextWrapper" style={{ display:"flex", flexDirection:"column", justifyContent:"center", gridColumn:"1/1", marginLeft:"calc(100% * 13 / (563 - (61 + 66))"}}>
  //                 <p className="Header">{ele.title}</p>
  //                 { subtitle && <p className="Subtitle">{subtitle}</p>}
  //             </div>
  //             <div style={{gridColumn:"2/2"}}>
  //                 {/* ICON HERE TO BE DETERMINED */}
  //             </div>
  //             {i !== Target + Limit-1 &&<div style={{gridRow:"2/2", gridColumn:"1/3", backgroundColor:"white"}}></div>}
  //         </div>)
  //     }))
  // },[Target])
  //////onsole.log("SMI",G,Val,pages[Val.referenceId]);
  const Value = pages[Val.referenceId];
  //////onsole.log("VALUE",Value)
  let SMCTC = pages[Val.referenceId]?.submenu_center_third_color
    ? pages[Val.referenceId]?.submenu_center_third_color + 'b2'
    : null;
  //onsole.log("Flag",chosenPortfolio.Submenu_center_third_color+"b2")
  const test = useRef(<div></div>);
  useEffect(() => {
    try {
      test.current.addEventListener(
        'UNACTIVEEVENT',
        () => {
          SetActive(false);
        },
        false
      );
    } catch (err) {
      console.error('Error', err);
    }
    //eslint-disable-next-line
  }, [test.current]);
  const [DesHeadSize] = useState(
    pages[Val.referenceId]?.StoryBookObjects?.descriptionHeaderFontsize
      ? pages[Val.referenceId]?.StoryBookObjects?.descriptionHeaderFontsize +
          'px'
      : '56px'
  );
  const [DesHeadSizeT] = useState(
    pages[Val.referenceId]?.StoryBookObjects?.descriptionHeaderFontsize
      ? pages[Val.referenceId]?.StoryBookObjects?.descriptionHeaderFontsize *
          (36 / 56) +
          'px'
      : '36px'
  );
  const [DesSize] = useState(
    pages[Val.referenceId]?.StoryBookObjects?.descriptionFontsize
      ? pages[Val.referenceId]?.StoryBookObjects?.descriptionFontsize + 'px'
      : '20px'
  );
  const [DesSizeT] = useState(
    pages[Val.referenceId]?.StoryBookObjects?.descriptionFontsize
      ? pages[Val.referenceId]?.StoryBookObjects?.descriptionFontsize *
          (20 / 20) +
          'px'
      : '20px'
  );
  return (
    <>
      {/* <div className="ScrollIndicator" style={{maskImage:`url(${Scrollindicator})`,WebkitMaskImage:`url(${Scrollindicator})`, maskSize:"100% 100%", WebkitMaskSize:'100% 100%', }}></div> */}
      <div ref={test} className="SubmenuItemBackground">
        {/* CONTENT HERE TO BE DETERMINED */}
        {pages[Val.referenceId]._type !== 'gallery' &&
        pages[Val.referenceId]._type !== 'STgallery' ? (
          pages[Val.referenceId]._type !== 'website' ? (
            renderSingleImageExport(
              pages[Val.referenceId]._type,
              0,
              contentDataExport(Val.referenceId)
            )
          ) : (
            renderSingleImageExport(
              pages[Val.referenceId]._type,
              0,
              pages[Val.referenceId].url
            )
          )
        ) : // //////onsole.log("WEBSITE")
        //renderSingleImageExport(pages[Val.referenceId]._type,0,contentDataExport(Val.referenceId))
        pages[Val.referenceId].content[0] ? (
          renderSingleImageExport(
            contentDataExport(Val.referenceId)[0]._type,
            0,
            contentDataExport(Val.referenceId)
          )
        ) : (
          <div id="Null"></div>
        )}
        {/* {//////onsole.log(pages[Val.referenceId]._type)} */}
        <div
          style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            background: OVTINT
          }}
        ></div>
      </div>

      <div
        className="SubmenuItemMenuBackground"
        style={{ background: SMColor }}
      >
        {/* <p className="OverGhost">{pages[Val.referenceId]?.oversizedText}</p> */}
        {/* <p className="SubtitleHeader">{pages[Val.referenceId]?.subtitle}</p> */}
        <p
          className="SubtitleHeader"
          style={{
            color: pages[Val.referenceId].text_color
              ? pages[Val.referenceId].text_color
              : undefined,
            fontFamily: FontUtil(
              pages[Val.referenceId]?.subtitleText,
              chosenPortfolio.ContentText
            )
          }}
        >
          {pages[Val.referenceId]?.title}
        </p>
      </div>
      <div
        className="SubpageBlockContent"
        onWheel={(eve) => {
          eve.preventDefault();
          eve.stopPropagation();
        }}
        style={{
          overscrollBehaviorX: TMGD ? null : 'auto',
          backgroundColor: ColorUtil(
            pages[Val.referenceId]?.submenu_center_third_color,
            chosenPortfolio.Submenu_center_third_color
          )
        }}
      >
        <div
          className="ColorBar"
          style={{
            backgroundColor: chosenPortfolio.accent_color
              ? chosenPortfolio.accent_color
              : ''
          }}
        />
        <div
          className="SubpageBlockContentWrapper"
          style={{ overscrollBehaviorX: TMGD ? null : 'auto' }}
        >
          <div className="SubpageBlockContentWrapper MSPACE" style={{}} />
          <div
            className="SubpageBlockContentWrapper header"
            style={{
              fontSize: window.innerWidth < 1367 ? DesHeadSizeT : DesHeadSize,
              fontFamily: FontUtil(
                pages[Val.referenceId]?.StoryBookObjects?.descriptionHeaderFont,
                chosenPortfolio.ContentText
              )
            }}
          >
            {/* width:"calc(100% * 773/1159)", calc(100% * ((460 * 2) + 37) / 1159)" */}
            {pages[Val.referenceId]?.StoryBookObjects?.descriptionHeader && (
              <BlockContent
                blocks={
                  pages[Val.referenceId]?.StoryBookObjects?.descriptionHeader
                }
                serializers={serializers}
              ></BlockContent>
            )}
          </div>
          <div
            className="SubpageBlockContentWrapper description SingleItem"
            style={{
              fontSize: window.innerWidth < 1367 ? DesSizeT : DesSize,
              fontFamily: FontUtil(
                pages[Val.referenceId]?.StoryBookObjects?.descriptionFont,
                chosenPortfolio.ContentText
              )
            }}
          >
            {pages[Val.referenceId]?.StoryBookObjects?.description && (
              <BlockContent
                blocks={pages[Val.referenceId]?.StoryBookObjects?.description}
                serializers={serializers}
              ></BlockContent>
            )}
          </div>
        </div>
      </div>
      {pages[Val.referenceId]?.StoryBookgallery?.singleItemTitle && (
        <div
          className="SubpageBlockContentFooterSingle"
          onClick={() => {
            setTMGD(true);
            SetActive(true);
            Single({
              SetActive: SetActive,
              Single: Single,
              Position: 1,
              currentContent: currentContent,
              createSetCurrentContent: createSetCurrentContent
            });
          }}
        >
          {/* <div className="TextContainer"> */}
          <p
            className="Title"
            style={{
              color: ColorUtil(
                pages[Val.referenceId].text_color,
                chosenPortfolio.text_color,
                'black'
              )
            }}
          >
            {pages[Val.referenceId]?.StoryBookgallery?.singleItemTitle}
          </p>
          {/* <p className="Subtitle">{pages[Val.referenceId].singleItemSubtitle}</p> */}
          {/* </div> */}
          <img className="ArrowContainer" src={Arrow2} alt=""></img>
        </div>
      )}
      <div
        className="SubpageMenuContent Single"
        id={'MBS'}
        onClick={() => {
          setTMGD(true);
          SetActive(true);
          Single({
            SetActive: SetActive,
            Single: Single,
            Position: 1,
            currentContent: currentContent,
            createSetCurrentContent: createSetCurrentContent
          });
        }}
        style={{
          display: TMGD ? 'none' : 'flex',
          backgroundColor: ColorUtil(
            SMCTC,
            chosenPortfolio.Submenu_center_third_color + 'b2'
          )
        }}
      >
        {Value?._type !== 'gallery' && Value?._type !== 'STgallery'
          ? Value?._type !== 'website'
            ? renderSingleImageExport(
                Value?._type,
                1,
                contentDataExport(Val.referenceId)
              )
            : renderSingleImageExport(Value?._type, 1, Value?.url)
          : // //////onsole.log("WEBSITE")
            //renderSingleImageExport(Value?._type,0,contentDataExport(Val.referenceId))
            renderSingleImageExport(
              contentDataExport(Val.referenceId)[1]?._type,
              1,
              contentDataExport(Val.referenceId)
            )}
        {/* {//////onsole.log(Value?._type)} */}
      </div>
    </>
  );
};
export default SubMenuItem;

// {Val.children.map((ele,i)=>{
//     //////onsole.log("RENDERSUBITEMS",i, Target)
//     if(i>Target + Limit-1 || i< Target)
//     return(<></>)
//     //////onsole.log(pages[ele.referenceId]);
//     let subtitle = pages[ele.referenceId].subtitle
//     return(<div key={ele.referenceId} className="SubMenuItemWrapper" style={{display:"grid",gridTemplateRows:"1fr 2px", width:"calc(100% * (563 - (61 + 66)) / 563)", height:"120px", gridTemplateColumns:"1fr 90px"}}>
//         <div className="SubMenuTextWrapper" style={{ display:"flex", flexDirection:"column", justifyContent:"center", gridColumn:"1/1", marginLeft:"calc(100% * 13 / (563 - (61 + 66))"}}>
//             <p className="Header">{ele.title}</p>
//             { subtitle && <p className="Subtitle">{subtitle}</p>}
//         </div>
//         <div style={{gridColumn:"2/2"}}>
//             {/* ICON HERE TO BE DETERMINED */}
//         </div>
//         {i !== Target + Limit-1 &&<div style={{gridRow:"2/2", gridColumn:"1/3", backgroundColor:"white"}}></div>}
//     </div>)
// })}

//<div className="SubpageBlockContent" style={{}}>
//    <div className="ColorBar" style={{backgroundColor:chosenPortfolio.accent_color?chosenPortfolio.accent_color:""}} />
//        <div className="SubpageBlockContentWrapper header" style={{}}>{/* width:"calc(100% * 773/1159)", */}
//            {pages[Val.referenceId]?.StoryBookObjects?.descriptionHeader && <BlockContent blocks={pages[Val.referenceId]?.StoryBookObjects?.descriptionHeader} serializers={serializers}></BlockContent>}
//        </div>
//        {/* (460 * 2) + 37
//(460 * 2) + 37 */}
//        <div className="SubpageBlockContentWrapper description SingleItem" style={{}}>
//            {pages[Val.referenceId]?.StoryBookObjects?.description && <BlockContent blocks={pages[Val.referenceId]?.StoryBookObjects?.description} serializers={serializers}></BlockContent>}
//        </div>
//        {/* 320px width, 0 right */}
//        {pages[Val.referenceId]?.StoryBookgallery?.singleItemTitle && <div className="SubpageBlockContentFooterSingle" onClick={()=>{ setTMGD(true); SetActive(true); Single({SetActive:SetActive, Single:Single, Position:1, currentContent:currentContent, createSetCurrentContent:createSetCurrentContent,})}}>
//          {/* <div className="TextContainer"> */}
//            <p className="Title">{pages[Val.referenceId]?.StoryBookgallery?.singleItemTitle}</p>
//            <p className="Subtitle">{pages[Val.referenceId].singleItemSubtitle}</p>
//          {/* </div> */}
//          <img className="ArrowContainer" src={Arrow2}></img>
//        </div>}
//    </div>
