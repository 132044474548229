import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const StyledSubNav = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 80%;
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  border-radius: 6px;
  transition: 0.2s ease all;
  background-color: ${(props) =>
    props.isHovered === props.page
      ? props.backgroundColor
        ? props.backgroundColor
        : '#FFFFFF80'
      : 'transparent'};
  padding-left: 3px;
  padding-right: 3px;

  @media (max-width: 450px) and (orientation: portrait) {
    height: 100%;
  }

  p {
    font-size: 24px;
    margin-block-start: 0px;
    margin-block-end: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-align: center;
    font-family: 'Merriweather Sans', sans-serif;
    color: ${(props) =>
      props.isHovered === props.page
        ? props.textColor
          ? props.textColor
          : '#3b3b3b'
        : 'white'};
    @media (max-width: 450px) and (orientation: portrait) {
      font-size: 18px;
      padding-right: 7px;
      padding-left: 7px;
      margin-top: 2px !important;
    }

    @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
      font-size: 22px;
    }
  }

  .sub {
    margin-top: 8px;
    font-size: 18px;
    -webkit-line-clamp: 2;
    font-weight: 400;
    font-family: 'Space Mono', monospace;

    @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
      font-size: 16px;
    }
  }
`;

const SubLevelContainer = ({
  divName,
  currentNavItem,
  pages,
  images,
  videos,
  pdfs,
  videoSelected,
  contentDataChoice,
  prepareCurrentGalleryContent,
  onNavClick,
  getColor,
  chosenPortfolio,
  isAtEnd,
  setHasCurr,
  setHasPrev,
  Size,
  transitionedToSubLevel
}) => {
  const [isHovered, setIsHovered] = useState(null);

  useEffect(() => {
    if (currentNavItem.children.length < 1) {
      setHasCurr(false);
      setHasPrev(false);
    }
  });
  //FLAG FONT FAMILY

  return (
    <div
      className={`${divName} ${
        currentNavItem?.children?.length && currentNavItem?.children?.length > 3
          ? 'large'
          : 'small'
      }`}
      // style={{opacity: transitionedToSubLevel ? 1 : 0, justifyContent: 'flex-start'}}
    >
      {currentNavItem.children.map((child, index) => {
        let foundPage = pages[child.referenceId];

        return (
          <div
            className="big-nav"
            key={child._key + '-' + index}
            onClick={() => onNavClick(child, index)}
          >
            <StyledSubNav
              onMouseEnter={() => setIsHovered(foundPage._key)}
              onMouseLeave={() => setIsHovered(null)}
              isHovered={isHovered}
              page={foundPage._key}
              textColor={
                chosenPortfolio?.text_color ? chosenPortfolio.text_color : null
              }
              backgroundColor={
                chosenPortfolio?.primary_color
                  ? chosenPortfolio.primary_color
                  : null
              }
            >
              <p>{foundPage?.title}</p>
              {foundPage?.subtitle ? (
                <p className="sub">{foundPage.subtitle}</p>
              ) : null}
            </StyledSubNav>
          </div>
        );
      })}
    </div>
  );
};

export default SubLevelContainer;
