// Keep track of script note

import {
  TOGGLE_SCRIPTNOTE,
  RESET_PORTFOLIO_INFO,
  END_PORTFOLIO,
  TOGGLE_NOTEPAD,
  TOGGLE_BRIEFCASE
} from '../constants/actionTypes';

const initialState = {
  showScriptnote: false
};
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SCRIPTNOTE:
      return {
        ...state,
        showScriptnote: !state.showScriptnote
      };
    case TOGGLE_NOTEPAD:
    case TOGGLE_BRIEFCASE:
      return {
        ...state,
        showScriptnote: false
      };
    case RESET_PORTFOLIO_INFO:
    case END_PORTFOLIO:
      return initialState;
    default:
      return state;
  }
};
