// Template-Z
import React, { useState, useEffect, Fragment } from 'react'; //useCallback
import PortfolioContent from '../../../components/_Portfolio/PortfolioContent';
import Button from '../../../components/Button';
import NavItemDisplay from '../../../components/_PortfolioContentParent3/NavItemDisplay';
import { isEmpty } from 'lodash';
import Mask from '../../../assets/images/MenuMask.png';
import FontUtil from '../../../utils/FontUtility';
const PortfolioContentParent3 = ({
  createSetCurrentContent,
  images,
  videos,
  pdfs,
  companyConfig,
  chosenPortfolio,
  pages,
  nav,
  pageBackgroundImageUrl,
  isGuestMode,
  isPortfolioView,
  guestContentHelper,
  currentContentHelper,
  isAtEnd,
  chosenPageId,
  chosenContentPageId,
  navHistory,
  setChosenPageId,
  setContentPageId,
  setCurrentContentHelper,
  setIsAtEnd,
  setTopic,
  setNavHistory,
  setIsVideoPlaying,
  setSavedContentPageId,
  clickedTerminusId,
  Topic,
  screensaverShow,
  hasCurr,
  hasPrev,
  setHasCurr,
  setHasPrev
}) => {
  const [currentNavItem, setCurrentNavItem] = useState({});
  const [prevNavItem, setPrevNavItem] = useState({});
  // let hasCurr = currentNavItem.children && currentNavItem.children.length > 0;
  // let hasPrev = prevNavItem.children && prevNavItem.children.length > 0;
  // const [currentTopicArray, setCurrentTopicArray] = useState([]);
  // const [currentTitle, setCurrentTitle] = useState("");
  // const [currentLevel, setCurrentLevel] = useState(0);

  useEffect(() => {
    let splashKeyId = `splashkey-${chosenPortfolio._id}`;
    if (chosenPageId !== 0 && chosenPageId !== splashKeyId) {
      // get current nav item
      let tempNavItem = {};
      let tempPrevItem = {};
      // traverse nav history to find current nav
      navHistory.forEach((number, index) => {
        // first
        if (index === 0) {
          tempNavItem = nav.items[number];
        } else {
          tempNavItem = tempNavItem.children[number];
        }

        // check if index is one item before last
        if (index === navHistory.length - 1) {
          tempPrevItem = tempNavItem;
        }
      });
      setCurrentNavItem(tempNavItem);
      setPrevNavItem(tempPrevItem);
      if (currentNavItem.children && currentNavItem.children.length > 0) {
        setHasCurr(true);
      }
      if (prevNavItem.children && prevNavItem.children.length > 0) {
        setHasPrev(true);
      }
      //this can cause problems with first selection of terminus item SOMETIMES
      setContentPageId(tempNavItem.referenceId);
    }
    if (chosenPageId === splashKeyId) {
      prepareSplash();
    }
    // eslint-disable-next-line
  }, [chosenPageId, pages, navHistory, chosenPortfolio]);

  // run everytime chosenContentPageId updates
  useEffect(() => {
    if (chosenContentPageId) {
      //check if clicked page was gallery with multiple items
      if (
        pages[chosenContentPageId]?.content &&
        pages[chosenContentPageId]?._type === 'gallery' &&
        pages[chosenContentPageId]?.content.length > 1
      ) {
        // automatically set first item of gallery to current content viewed
        let obj = {
          type: 'gallery',
          index: 0,
          id: chosenContentPageId
        };
        createSetCurrentContent(obj);
      } else {
        // else do normal
        let obj = {
          type: 'pageSub',
          id: chosenContentPageId
        };
        createSetCurrentContent(obj);
      }
    } else {
      // show splash when chosenContentPageId is undefined
      //////////onsole.log('chosenContentPageId is undefined, show splash.....');
      prepareSplash();
    }
    // eslint-disable-next-line
  }, [
    chosenContentPageId,
    pages,
    guestContentHelper,
    isGuestMode,
    isPortfolioView
  ]);

  useEffect(() => {
    if (screensaverShow) {
      setHasCurr(false);
      setHasPrev(false);
      setIsAtEnd(false);
      let splashKeyId = `splashkey-${chosenPortfolio._id}`;
      setContentPageId(splashKeyId);
    }
  }, [
    screensaverShow,
    chosenPortfolio._id,
    setContentPageId,
    setIsAtEnd,
    setHasCurr,
    setHasPrev
  ]);

  // function used when user changes gallery index
  const prepareCurrentGalleryContent = (index) => {
    let obj = {
      type: 'gallery',
      index: index,
      id: chosenContentPageId
    };
    createSetCurrentContent(obj);
  };

  // prepare splash to display
  //Add SetTopic
  const prepareSplash = () => {
    let splashKeyId = `splashkey-${chosenPortfolio._id}`;
    // create nav specifically for splash
    setCurrentNavItem({
      children: [],
      referenceField: 'pages',
      referenceId: splashKeyId,
      title: 'Splash',
      type: 'gallery',
      _key: 'splashnav'
    });
    setContentPageId(splashKeyId);
    // check if guest
    if (isGuestMode && !isPortfolioView) {
      // find index
      let index = guestContentHelper.findIndex((x) => x.id === splashKeyId);
      setCurrentContentHelper(guestContentHelper[index].id);
    }
    setTopic(['SplashPage']);
  };

  if (chosenContentPageId === 0) {
    return null;
  }

  const contentData = () => {
    let tabData = {};
    // check if in guest mode
    if (
      isGuestMode &&
      chosenContentPageId !== 0 &&
      !isEmpty(currentContentHelper) &&
      pages[chosenContentPageId]?._type === 'gallery' &&
      pages[chosenContentPageId]?._key === currentContentHelper.page_id &&
      currentContentHelper.is_gallery_item
    ) {
      //////////onsole.log('MODIFY FOR GALLERY CONTENT');
      // create obj to only display gallery item chosen by guest
      let obj =
        pages[chosenContentPageId]?.content[currentContentHelper.gallery_index];
      tabData = [{ ...obj }];
      return tabData;
    }
    return pages[chosenContentPageId]?.content;
  };

  // custom content data choice - May not be needed
  const contentDataChoice = (id) => {
    let tabData = {};
    // check if in guest mode
    if (
      isGuestMode &&
      id !== 0 &&
      !isEmpty(currentContentHelper) &&
      pages[id]._type === 'gallery' &&
      pages[id]._key === currentContentHelper.page_id &&
      currentContentHelper.is_gallery_item
    ) {
      //////////onsole.log('MODIFY FOR GALLERY CONTENT');
      // create obj to only display gallery item chosen by guest
      let obj = pages[id].content[currentContentHelper.gallery_index];
      tabData = [{ ...obj }];
      return tabData;
    }
    return pages[id].content;
  };

  if (!chosenContentPageId) {
    return null;
  }

  // color for background
  const getColor = (child) => {
    // get color for each specific page....
    let accentColor = pages[child.referenceId].accent_color;
    let textColor = pages[child.referenceId].text_color;

    // set to chosenPortfolio color if it doesn't exists
    accentColor = accentColor ? accentColor : chosenPortfolio.accent_color;
    textColor = textColor ? textColor : chosenPortfolio.text_color;
    // set to companyConfig color if it doesn't exists
    accentColor = accentColor ? accentColor : companyConfig.accent_color;
    textColor = textColor ? textColor : companyConfig.text_color;

    return { accentColor: accentColor, textColor: textColor };
  };

  // nav click / grid nav click
  const onNavClick = (child, index) => {
    const hasChild = child.children.length > 0;
    let isNotEnd = false;
    // check if child cnotains more child
    if (hasChild) {
      isNotEnd = child.children.some((childArray) => {
        return childArray.children.length !== 0;
      });
    }

    // below prevents screensaver from triggering on terminus menu so it's commented out, but uncomment if this causes issues
    // setIsVideoPlaying(true);

    setIsAtEnd(!isNotEnd);

    if (!isNotEnd) {
      // is end display content
      // select first children if exists
      //////onsole.log("HEY, THIS IS THE END", child);
      setContentPageId(
        child.children[0] ? child.children[0].referenceId : child.referenceId
      );
      setCurrentNavItem(child);
      setSavedContentPageId(
        child.children[0] ? child.children[0].referenceId : child.referenceId
      );
      // //////onsole.log(`CURRENT TITLE AND LEVEL INSIDE NAV CLICK: ${currentTitle}, ${currentLevel}`);
      let level = navHistory.length;

      let topicalArray = [];
      try {
        if (level !== 0) {
          topicalArray = [...Topic];
        } else {
          topicalArray = [];
        }
      } catch (err) {
        console.error('Error', err);
      }
      //////onsole.log(`TITLE: ${child.title}, LEVEL: ${level}, ID: ${child.referenceId}`);
      if (child.children[0]) {
        topicalArray.splice(level, 11111, {
          title: child.title,
          id: child.referenceId
        });
        topicalArray.splice(level + 1, 11111, {
          title: child.children[0].title,
          id: child.children[0].referenceId
        });
      } else {
        topicalArray.splice(level, 11111, {
          title: child.title,
          id: child.referenceId
        });
      }
      //////onsole.log("TOPICAL ARRAY HERE - TERM", topicalArray);
      if (child.title !== undefined || child.title !== '') {
        setTopic(topicalArray);
      }
    } else {
      //not end, set pageId
      //setChosenPageId(child.referenceId);
      // update navHistory
      setNavHistory([...navHistory, index]);
      let level;
      if (isAtEnd) {
        level = navHistory.length;
        //////onsole.log("NAV HISTORY - TERM ", navHistory);
      } else if (child.title === 'Splash') {
        level = 0;
      } else {
        //////onsole.log("NAV HISTORY - SUBMENU: ", navHistory);
        level = navHistory.length;
      }

      let topicalArray = [];
      try {
        if (level !== 0) {
          topicalArray = [...Topic];
        } else {
          topicalArray = [];
        }
      } catch (err) {
        console.error('Error', err);
      }
      topicalArray.splice(level, 11111, {
        title: child.title,
        id: child.referenceId
      });
      //////onsole.log(`TITLE: ${child.title}, LEVEL: ${level}, ID: ${child.referenceId}`);
      //////onsole.log("TOPICAL ARRAY HERE", topicalArray);
      if (child.title !== undefined || child.title !== '') {
        setTopic(topicalArray);
      }
    }
  };

  const onBackClick = () => {
    // NOTE below is not needed because the menu/nav is only 2 level (ie menu/nav->termnial)
    // User will only go back from terminal, there will be no situation of terminal->menu/nav->menu/nav
    /*
    let tempNavHistory = [...navHistory];
    if (navHistory.length > 1 && !isAtEnd) {
      //////////onsole.log(navHistory);
      // remove one item from navHistory
      tempNavHistory = navHistory.filter((x, i) => i !== navHistory.length - 1);
      //////////onsole.log(tempNavHistory);
      setNavHistory(tempNavHistory);
    }
    */

    // if Stephen requests - it seems like this should change the topic array, and I don't think it does. AS such, it should just splice out the last (terminus) item
    // this could be difficult, because on 2-level terminus you would have to splice out just one level, and on 3 or more level terminus you would splice out two (I think);

    // //////onsole.log("ON BACK CLICK - PREV ITEM", prevNavItem);

    // back from terminal
    if (isAtEnd) {
      setIsAtEnd(false);
      setCurrentNavItem(prevNavItem);
      setContentPageId(prevNavItem.referenceId);
    }
  };

  // //onsole.log("~!CURRENT NAV ITEM", currentNavItem);
  // //onsole.log("~!PREV NAV ITEM", prevNavItem);
  // //onsole.log("~!NAV HISTORY RIGHT NOW", navHistory);
  // //onsole.log("~!FULL NAV", nav);

  return (
    <div
      className="portfolio-content-parent"
      style={{
        backgroundImage: isAtEnd ? `url(${pageBackgroundImageUrl})` : ''
      }}
    >
      {(hasCurr || hasPrev) && (
        <Fragment>
          {isAtEnd && <div className="portfolio-big-nav-prev-overlay"></div>}
          <NavItemDisplay
            divName={
              isAtEnd
                ? 'portfolio-big-nav-prev-container'
                : 'portfolio-big-nav-container'
            }
            currentNavItem={isAtEnd ? prevNavItem : currentNavItem}
            prevNavItem={prevNavItem !== {} ? prevNavItem : null}
            chosenPortfolio={chosenPortfolio}
            pages={pages}
            images={images}
            videos={videos}
            pdfs={pdfs}
            contentDataChoice={contentDataChoice}
            prepareCurrentGalleryContent={prepareCurrentGalleryContent}
            onNavClick={isAtEnd ? undefined : onNavClick}
            getColor={getColor}
            videoSelected={isAtEnd ? false : true}
          />
        </Fragment>
      )}
      {isAtEnd && (
        <Fragment>
          {currentNavItem.children && currentNavItem.children.length > 0 && (
            <div
              className="portfolio-content-menu-container"
              onClick={() => {
                onBackClick();
                setSavedContentPageId(0);
              }}
            >
              <div
                className="portfolio-content-menu"
                style={{
                  justifyContent:
                    currentNavItem?.children?.length > 7
                      ? 'flex-start'
                      : 'center',
                  alignItems:
                    currentNavItem?.children?.length > 7
                      ? 'flex-start'
                      : 'center',
                  // overflowY: currentNavItem?.children?.length > 7 ? "hidden" : "hidden"
                  maskImage: `url(${Mask})`,
                  maskSize: '100% 100%',
                  maskRepeat: 'no-repeat',
                  WebkitMaskImage: `url(${Mask})`,
                  WebkitMaskRepeat: 'no-repeat',
                  WebkitMaskSize: '100% 100%'
                }}
              >
                {currentNavItem.children.map((child, index) => {
                  // //////onsole.log("CHILD IN TERMINUS MENU", child);
                  let colorObj = getColor(child);
                  // get color for each specific page....
                  let accentColor = colorObj.accentColor;
                  // let textColor = colorObj.textColor;
                  let subtitle;
                  let title;

                  const topicArrayTerminusSplice = (activeChild) => {
                    let level = navHistory.length + 1;
                    let topicalArray = [];
                    try {
                      if (level !== 0) {
                        topicalArray = [...Topic];
                      } else {
                        topicalArray = [];
                      }
                    } catch (err) {
                      console.error('Error', err);
                    }
                    topicalArray.splice(level, 11111, {
                      title: activeChild.title,
                      id: activeChild.referenceId
                    });
                    //////onsole.log("TOPICAL ARRAY HERE", topicalArray);
                    if (
                      activeChild.title !== undefined ||
                      activeChild.title !== ''
                    ) {
                      setTopic(topicalArray);
                    }
                  };

                  try {
                    subtitle = pages[child.referenceId].subtitle;
                    title = pages[child.referenceId].title;
                  } catch (ex) {
                    //////////onsole.log(ex);
                  }

                  return (
                    <div
                      key={child.referenceId}
                      style={{
                        marginTop: '2%',
                        marginBottom: '2%'
                      }}
                    >
                      <Button
                        fontFamily={FontUtil(
                          pages[child.referenceId].TitleText,
                          chosenPortfolio.ContentText
                        )}
                        fontFamily2={FontUtil(
                          pages[child.referenceId].SubtitleText,
                          chosenPortfolio.ContentText
                        )}
                        style={{
                          fontFamily: "'Oswald', sans-serif",
                          textShadow: `1px 1px 1px ${chosenPortfolio.accent_color}`,
                          letterSpacing: '0.15em',
                          border:
                            clickedTerminusId === child.referenceId
                              ? `3px solid ${chosenPortfolio.active_terminal_button}`
                              : `3px solid ${chosenPortfolio.inactive_terminal_button}`,
                          color:
                            clickedTerminusId === child.referenceId
                              ? chosenPortfolio.active_terminal_font
                              : chosenPortfolio.inactive_terminal_font,
                          borderRadius: '80px',
                          paddingRight: '20px',
                          paddingLeft: '20px'
                        }}
                        key={child._key + '-' + index}
                        fontSize="14px"
                        width="280px"
                        height="75px"
                        text={title}
                        subtitle={subtitle}
                        onClick={(e) => {
                          e.stopPropagation();
                          setSavedContentPageId(child.referenceId);
                          // only update if different
                          if (chosenContentPageId !== child.referenceId) {
                            setContentPageId(child.referenceId);
                          }
                          topicArrayTerminusSplice(child);
                        }}
                        backgroundColor={accentColor}
                        color={
                          clickedTerminusId === child.referenceId
                            ? chosenPortfolio.active_terminal_font
                            : chosenPortfolio.inactive_terminal_font
                        }
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <div
            className="portfolio-content-container"
            onClick={() => {
              onBackClick();
              setSavedContentPageId(0);
            }}
          >
            <div
              className="portfolio-content-wrapper"
              onClick={(e) => e.stopPropagation()}
            >
              {chosenContentPageId !== 0 &&
                pages[chosenContentPageId]?._type !== false && (
                  <PortfolioContent
                    contentType={pages[chosenContentPageId]?._type}
                    contents={contentData()}
                    page={pages[chosenContentPageId]}
                    images={images}
                    videos={videos}
                    pdfs={pdfs}
                    prepareCurrentGalleryContent={prepareCurrentGalleryContent}
                  />
                )}
            </div>
          </div>
        </Fragment>
      )}
      {chosenContentPageId === `splashkey-${chosenPortfolio._id}` && (
        <div className="portfolio-content-container">
          {chosenContentPageId !== 0 &&
            pages[chosenContentPageId]?._type !== false && (
              <PortfolioContent
                contentType={pages[chosenContentPageId]?._type}
                contents={contentData()}
                page={pages[chosenContentPageId]}
                images={images}
                videos={videos}
                pdfs={pdfs}
                prepareCurrentGalleryContent={prepareCurrentGalleryContent}
                chosenPortfolio={chosenPortfolio}
                chosenContentPageId={chosenContentPageId}
                screensaverShow={screensaverShow}
              />
            )}
        </div>
      )}
    </div>
  );
};
export default PortfolioContentParent3;
