import { connect } from 'react-redux';
import DateSelect from './DateSelect.js';
import {
  SetDaterangeDispatch,
  setAggregateSelected,
  setDefaultVisit,
  setSessionDateString,
  setEfficiencyUserToggle,
  setSecondaryUserSessionToggle,
  setSecondaryUserAggregateToggle
} from '../../../actions';

const mapStateToProps = (state) => ({
  aggregateSelected: state.DashboardReducer.aggregateSelected,
  DateRange: state.DashboardReducer.DateRange,
  efficiencyUserToggle: state.DashboardReducer.efficiencyUserToggle,
  Size: state.portfolioReducer.Size
});

const mapDispatchToProps = (dispatch) => {
  return {
    setAggregateSelected: (value) => dispatch(setAggregateSelected(value)),
    SetDaterangeDispatch: (value) => dispatch(SetDaterangeDispatch(value)),
    setDefaultVisit: (visit) => dispatch(setDefaultVisit(visit)),
    setSessionDateString: (string) => dispatch(setSessionDateString(string)),
    setEfficiencyUserToggle: (toggle) =>
      dispatch(setEfficiencyUserToggle(toggle)),
    setSecondaryUserSessionToggle: (toggle) =>
      dispatch(setSecondaryUserSessionToggle(toggle)),
    setSecondaryUserAggregateToggle: (toggle) =>
      dispatch(setSecondaryUserAggregateToggle(toggle))
  };
};

const DateSelectContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DateSelect);

export default DateSelectContainer;
