import { connect } from 'react-redux';
import PortfolioContentParent11 from './PortfolioContentParent11';
import {
  setChosenPageId,
  setContentPageId,
  setIsAtEnd,
  setCurrentContentHelper,
  setNavHistory,
  setIsVideoPlaying,
  SetTopic,
  setSavedContentPageId,
  setHasCurr,
  setHasPrev,
  addToBriefcase,
  setBooleanNewItems,
  setSelectedTopLevelId,
  setLiked,
  setTwolevelTerminus,
  setMegamenuLevel,
  setTermWithChild,
  setShowTermChild
} from '../../../actions';

const mapStateToProps = (state) => ({
  briefcaseContent: state.briefcaseReducer.briefcaseContent,
  userInfo: state.firestore.ordered.users,
  images: state.sanityContentReducer.images,
  videos: state.sanityContentReducer.videos,
  pdfs: state.sanityContentReducer.pdfs,
  companyConfig: state.sanityContentReducer.companyConfig,
  chosenPortfolio: state.portfolioReducer.chosenPortfolio,
  pages: state.portfolioReducer.pages,
  nav: state.portfolioReducer.nav,
  pageBackgroundImageUrl: state.portfolioReducer.pageBackgroundImageUrl,
  isGuestMode: state.guestReducer.isGuestMode,
  isPortfolioView: state.guestReducer.isPortfolioView,
  guestPages: state.guestReducer.guestPages,
  guestContentHelper: state.guestReducer.guestContentHelper,
  currentContentHelper: state.guestReducer.currentContentHelper,
  isAtEnd: state.pageMenuReducer.isAtEnd,
  currentLevel: state.pageMenuReducer.currentLevel,
  chosenPageId: state.pageMenuReducer.chosenPageId,
  chosenContentPageId: state.pageMenuReducer.chosenContentPageId,
  navHistory: state.pageMenuReducer.navHistory,
  clickedTerminusId: state.pageMenuReducer.savedChosenContentPageId,
  Topic: state.pageMenuReducer.Topic,
  hasCurr: state.pageMenuReducer.hasCurr,
  hasPrev: state.pageMenuReducer.hasPrev,
  masterMenuOpen: state.portfolioReducer.masterMenuOpen,
  toolbarMenuOpen: state.portfolioReducer.toolbarMenuOpen,
  Size: state.portfolioReducer.Size,
  showBriefcase: state.briefcaseReducer.showBriefcase,
  booleanNewItems: state.briefcaseReducer.booleanNewItems,
  sessionId: state.portfolioReducer.sessionId,
  liked: state.portfolioReducer.liked,
  currentGuestContentHelper: state.guestReducer.currentContentHelper,
  twolevelTerminus: state.portfolioReducer.twolevelTerminus,
  screensaverShow: state.portfolioReducer.screensaverShow,
  megamenuLevel: state.pageMenuReducer.megamenuLevel,
  termWithChild: state.pageMenuReducer.termWithChild,
  showTermChild: state.pageMenuReducer.showTermChild
});

const mapDispatchToProps = (dispatch) => {
  return {
    setChosenPageId: (id) => dispatch(setChosenPageId(id)),
    setContentPageId: (id) => dispatch(setContentPageId(id)),
    setIsAtEnd: (bool) => dispatch(setIsAtEnd(bool)),
    setCurrentContentHelper: (id) => dispatch(setCurrentContentHelper(id)),
    setNavHistory: (array) => dispatch(setNavHistory(array)),
    setTopic: (Topic) => dispatch(SetTopic(Topic)),
    setIsVideoPlaying: (bool) => dispatch(setIsVideoPlaying(bool)),
    setSavedContentPageId: (id) => dispatch(setSavedContentPageId(id)),
    setHasCurr: (bool) => dispatch(setHasCurr(bool)),
    setHasPrev: (bool) => dispatch(setHasPrev(bool)),
    addToBriefcase: (obj) => dispatch(addToBriefcase(obj)),
    setBooleanNewItems: (bool) => dispatch(setBooleanNewItems(bool)),
    setSelectedTopLevelId: (id) => dispatch(setSelectedTopLevelId(id)),
    setLiked: (array) => dispatch(setLiked(array)),
    setTwolevelTerminus: (bool) => dispatch(setTwolevelTerminus(bool)),
    setMegamenuLevel: (level) => dispatch(setMegamenuLevel(level)),
    setTermWithChild: (bool) => dispatch(setTermWithChild(bool)),
    setShowTermChild: (bool) => dispatch(setShowTermChild(bool))
  };
};

const PortfolioContentParent11Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(PortfolioContentParent11);

export default PortfolioContentParent11Container;
