import { connect } from 'react-redux';
import Modal from './Modal';
import { hideModal } from '../../actions';

const mapStateToProps = (state) => ({
  chosenPortfolio: state.portfolioReducer.chosenPortfolio
});

const mapDispatchToProps = (dispatch) => {
  return {
    hideModal: () => dispatch(hideModal())
  };
};

const ModalContainer = connect(mapStateToProps, mapDispatchToProps)(Modal);

export default ModalContainer;
