import { connect } from 'react-redux';
import Scriptnote from './Scriptnote';
import { toggleScriptnote } from '../../actions';

const mapStateToProps = (state) => ({
  companyConfig: state.sanityContentReducer.companyConfig,
  chosenPortfolio: state.portfolioReducer.chosenPortfolio,
  pages: state.portfolioReducer.pages,
  chosenPageId: state.pageMenuReducer.chosenPageId,
  chosenContentPageId: state.pageMenuReducer.chosenContentPageId,
  showScriptnote: state.scriptnoteReducer.showScriptnote
});

const mapDispatchToProps = (dispatch) => {
  return {
    toggleScriptnote: () => dispatch(toggleScriptnote())
  };
};

const ScriptnoteContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Scriptnote);

export default ScriptnoteContainer;
