// This state will be saved in local storage to keep portfolio open even on reload

import {
  SET_PORTFOLIO_CUSTOMER_INFO,
  SET_PORTFOLIO_ID,
  SET_CHOSEN_PORTFOLIO,
  SET_PORTFOLIO_MODE,
  RESET_PORTFOLIO_INFO,
  RESET_PORTFOLIO_INFO_MidSession,
  SET_PORTFOLIO_SESSION_ID,
  SET_PORTFOLIO_SUBPAGE_ID,
  END_PORTFOLIO,
  SET_BACKGROUND_IMAGE_URL,
  SET_PAGE_BACKGROUND_IMAGE_URL,
  PREPARE_GUEST,
  ADD_SESSION,
  SET_PII_BACKGROUND_IMAGE_URL,
  SET_PORTFOLIO_INTRO_IMAGE_URL,
  SET_PORTFOLIO_LAUNCH_IMAGE_URL,
  SET_LIKED,
  SET_ANNOTATOR,
  SET_LOGIN_INPUT_SELECTED,
  KM_RESET,
  SET_LOGIN_INPUT_SELECTED_COUNT,
  SET_TOOLBAR_MENU_OPEN,
  SET_TOOLBAR_MENU_VISIBILITY,
  SET_MASTER_MENU_OPEN,
  SET_PORTFOLIO_SUBMENU_IMAGE_URL,
  SET_PORTFOLIO_TERMMENU_IMAGE_URL,
  SET_PORTFOLIO_TOPLEVEL_IMAGE_URL,
  SET_SELECTED_TOP_LEVEL_ID,
  SET_TWOLEVEL_TERMINUS,
  SET_SCREENSAVER_SHOW,
  SET_NOTIFICATION
} from '../constants/actionTypes';
import { arrayToObject } from '../utils/arrayHelper';

const initialState = {
  mode: '',
  chosenPortfolioId: '',
  chosenPortfolio: {},
  customerInfo: {},
  activeSessionNotifications: { likes: [], Notes: [] },
  status: '',
  sessionId: '',
  chosenSubPageId: '',
  sanitySessionId: '',
  backgroundImageUrl: '',
  pageBackgroundImageUrl: '',
  pages: [], // current chosen portofolio pages
  nav: [], // current chosen nav from portfolio
  PIIBackgroundImageUrl: '',
  portfolioIntroImageUrl: '',
  portfoliolaunchImageUrl: '',
  liked: [], // guest liked pages (only in guest mode)
  Size: { W: 1920, H: 1080 },
  aNNOTATOR: false,
  selectedCount: 0,
  loginInputSelected: false,
  toolbarMenuOpen: false,
  toolbarMenuVisible: true,
  masterMenuOpen: false,
  portfolioTopLevelImageUrl: '',
  portfolioSubMenuImageUrl: '',
  portfolioTermMenuImageUrl: '',
  selectedTopLevelId: 0,
  twolevelTerminus: false,
  screensaverShow: false
};
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case 'WindowResized': {
      //////onsole.log("PAYLOAD",action.Payload.Size)
      return {
        ...state,
        Size: action.Payload.Size
      };
    }
    case SET_NOTIFICATION: {
      console.log('action', action);
      return { ...state, activeSessionNotifications: action.payload };
    }
    case SET_ANNOTATOR:
      return { ...state, aNNOTATOR: action.payload };
    case ADD_SESSION.SUCCESS:
      return {
        ...state,
        sanitySessionId: action.id
      };
    case PREPARE_GUEST: {
      // prepare gust portfolio
      let guestPages = action.payload.chosenPortfolio.pages;
      let splash = action.payload.splashPage;
      // check if splashPage exists
      if (splash) {
        // add splash to page
        guestPages = [...guestPages, splash];
      }

      let guestPortfolio = { ...action.payload.chosenPortfolio };
      // traverse company
      for (let key in guestPortfolio) {
        if (key.includes('_color') && guestPortfolio[key] === '#') {
          // change '#' in _color to undefined
          guestPortfolio[key] = undefined;
        }
      }

      // traverse guest page
      let updateGuestPagesArr = guestPages.map((obj) => {
        let copyObj = { ...obj };
        // specifically check accent_color text_color
        if (obj.accent_color === '#') {
          copyObj.accent_color = undefined;
        }
        if (obj.text_color === '#') {
          copyObj.text_color = undefined;
        }
        return copyObj;
      });
      return {
        ...state,
        backgroundImageUrl: action.payload.backgroundImageUrl,
        pageBackgroundImageUrl: action.payload.backgroundPageImageUrl,
        chosenPortfolioId: action.payload.chosenPortfolioId,
        chosenPortfolio: guestPortfolio,
        pages: arrayToObject(updateGuestPagesArr, '_key'),
        nav: action.payload.guestNav,
        mode: 'default'
      };
    }
    case SET_PORTFOLIO_CUSTOMER_INFO:
      return {
        ...state,
        customerInfo: action.obj
      };
    case SET_TWOLEVEL_TERMINUS:
      return {
        ...state,
        twolevelTerminus: action.data
      };
    case SET_SCREENSAVER_SHOW:
      return {
        ...state,
        screensaverShow: action.data
      };
    case SET_PORTFOLIO_ID:
      return {
        ...state,
        chosenPortfolioId: action.id
      };
    case SET_CHOSEN_PORTFOLIO: {
      let pages = action.obj.pages;
      // check if splashPage exists
      if (action.obj.splashPage) {
        pages = [...pages, action.obj.splashPage];
      }

      let portfolio = { ...action.obj };
      // traverse company
      for (let key in portfolio) {
        if (key.includes('_color') && portfolio[key] === '#') {
          // change '#' in _color to undefined
          //////////onsole.log('CHANGE PORTFOLIO COLOR #');
          portfolio[key] = undefined;
        }
      }
      // traverse page
      let updatePagesArr = pages.map((obj) => {
        let copyObj = { ...obj };
        // specifically check accent_color text_color
        if (obj.accent_color === '#') {
          copyObj.accent_color = undefined;
        }
        if (obj.text_color === '#') {
          copyObj.text_color = undefined;
        }
        return copyObj;
      });
      return {
        ...state,
        chosenPortfolio: portfolio,
        pages: arrayToObject(updatePagesArr, '_key'),
        nav: action.obj.nav
      };
    }
    case SET_PORTFOLIO_MODE:
      return {
        ...state,
        mode: action.mode
      };
    case SET_PORTFOLIO_SESSION_ID:
      return {
        ...state,
        sessionId: action.id
      };
    case SET_BACKGROUND_IMAGE_URL:
      return {
        ...state,
        backgroundImageUrl: action.url
      };
    case SET_PAGE_BACKGROUND_IMAGE_URL:
      return {
        ...state,
        pageBackgroundImageUrl: action.url
      };
    case SET_PORTFOLIO_TOPLEVEL_IMAGE_URL:
      return {
        ...state,
        portfolioTopLevelImageUrl: action.data
      };
    case SET_PORTFOLIO_SUBMENU_IMAGE_URL:
      return {
        ...state,
        portfolioSubMenuImageUrl: action.data
      };
    case SET_PORTFOLIO_TERMMENU_IMAGE_URL:
      return {
        ...state,
        portfolioTermMenuImageUrl: action.data
      };
    case SET_MASTER_MENU_OPEN:
      return {
        ...state,
        masterMenuOpen: action.data
      };
    case SET_PII_BACKGROUND_IMAGE_URL:
      return {
        ...state,
        PIIBackgroundImageUrl: action.url
      };
    case SET_PORTFOLIO_INTRO_IMAGE_URL:
      return {
        ...state,
        portfolioIntroImageUrl: action.url
      };
    case SET_PORTFOLIO_LAUNCH_IMAGE_URL:
      return {
        ...state,
        portfoliolaunchImageUrl: action.url
      };
    case SET_LIKED:
      return {
        ...state,
        liked: action.array
      };
    case SET_LOGIN_INPUT_SELECTED:
      return {
        ...state,
        loginInputSelected: action.data
      };
    case SET_LOGIN_INPUT_SELECTED_COUNT:
      return {
        ...state,
        selectedCount: action.data
      };
    case SET_SELECTED_TOP_LEVEL_ID:
      return {
        ...state,
        selectedTopLevelId: action.data
      };
    case SET_TOOLBAR_MENU_OPEN:
      return {
        ...state,
        toolbarMenuOpen: action.data
      };
    case SET_TOOLBAR_MENU_VISIBILITY:
      return {
        ...state,
        toolbarMenuVisible: action.data
      };
    case END_PORTFOLIO:
    case RESET_PORTFOLIO_INFO:
      return initialState;
    case SET_PORTFOLIO_SUBPAGE_ID:
      return {
        ...state,
        chosenSubPageId: action.id
      };
    case RESET_PORTFOLIO_INFO_MidSession: {
      return {
        ...state,
        chosenSubPageId: '',
        pages: [],
        nav: []
      };
    }
    case KM_RESET: {
      return {
        ...state,
        chosenSubPageId: '',
        // pages:[],
        // nav:[],
        sessionId: ''
      };
    }
    default:
      return state;
  }
};
