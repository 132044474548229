import { connect } from 'react-redux';
import AdminPage from './AdminPage';
import { updateSessionKey, clearAll } from '../../actions';

const mapStateToProps = (state) => ({
  userInfo: state.firestore.ordered.users,
  images: state.sanityContentReducer.images,
  companyConfig: state.sanityContentReducer.companyConfig
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateSessionKey: (key) => dispatch(updateSessionKey(key)),
    clearAll: () => dispatch(clearAll())
  };
};

const AdminPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminPage);

export default AdminPageContainer;
