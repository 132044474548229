import React, { useState } from 'react';
import { urlFor } from '../../../utils/sanityHelper';
import SanityMuxPlayer from '../../../containers/MuxPlayer';
import styled from 'styled-components';
import PDFViewer2 from '../../FullWidthPDFViewer';
import FontUtil from '../../../utils/FontUtility';

const ThumbsBigNav = styled.div`
  // overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  transition: all 0.2s ease;

  &:hover {
    filter: ${(props) =>
      props.dropColor
        ? `drop-shadow(0 0 0.9rem ${props.dropColor})`
        : `drop-shadow(0 0 0.9rem rgb(68, 159, 245))`};
  }

  .logo-thumb {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
`;

const GalleryThumbsContent = ({
  chosenPortfolio,
  foundPage,
  child,
  index,
  onNavClick,
  videos,
  pageContents,
  isTopLevel,
  isAtEnd,
  controlsOverride,
  Size,
  pdfs,
  contents,
  // companyConfig,
  page
}) => {
  const [opacityBack, setOpacityBack] = useState(1);
  const [isVisible, setIsVisible] = useState('hidden');

  const enterContent = () => {
    if (!isTopLevel) {
      setOpacityBack(0.4);
    }
    setIsVisible('visible');
  };

  const leaveContent = () => {
    setOpacityBack(1);
    setIsVisible('hidden');
  };

  let overlay = null;
  if (chosenPortfolio && !isTopLevel) {
    overlay = (
      <button
        style={{
          backgroundColor: 'transparent',
          height: '100%',
          width: '100%',
          border: 'none',
          borderColor: chosenPortfolio?.text_color
            ? chosenPortfolio.text_color
            : 'white',
          cursor: 'pointer',
          visibility: isVisible,
          position: 'absolute',
          zIndex: 2,
          overflow: 'hidden'
        }}
      >
        <p
          style={{
            color: chosenPortfolio?.text_color
              ? chosenPortfolio.text_color
              : 'white',
            marginBlockStart: '0px',
            marginBlockEnd: '0px',
            fontFamily: FontUtil(
              page.TitleText,
              chosenPortfolio.ContentText,
              "'Oswald', serif"
            ),
            letterSpacing: '0.1em',
            textShadow: chosenPortfolio?.secondary_color
              ? `-2px 0 ${chosenPortfolio.secondary_color}, 0 2px ${chosenPortfolio.secondary_color}, 2px 0 ${chosenPortfolio.secondary_color}, 0 -2px ${chosenPortfolio.secondary_color}`
              : `-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black`
          }}
        >
          {foundPage?.title}
        </p>
        <p
          style={{
            color: chosenPortfolio?.text_color
              ? chosenPortfolio.text_color
              : 'white',
            marginBlockStart: '0px',
            marginBlockEnd: '0px',
            fontFamily: FontUtil(
              page.SubtitleText,
              chosenPortfolio.ContentText,
              "'Oswald', serif"
            ),
            letterSpacing: '0.085em',
            textShadow: chosenPortfolio?.secondary_color
              ? `-2px 0 ${chosenPortfolio.secondary_color}, 0 2px ${chosenPortfolio.secondary_color}, 2px 0 ${chosenPortfolio.secondary_color}, 0 -2px ${chosenPortfolio.secondary_color}`
              : `-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black`
          }}
        >
          {foundPage?.subtitle}
        </p>
      </button>
    );
  } else if (chosenPortfolio && isTopLevel) {
    overlay = (
      <section className="top-level-overlay">
        <div>
          <p
            className="overlay-title"
            style={{
              color: foundPage?.text_color
                ? foundPage?.text_color
                : chosenPortfolio?.text_color
                ? chosenPortfolio?.text_color
                : 'white',
              fontFamily: FontUtil(
                page.TitleText,
                chosenPortfolio.ContentText,
                "'Oswald', serif"
              )
            }}
          >
            {foundPage?.title}
          </p>
          <p
            className="overlay-subtitle"
            style={{
              color: foundPage?.text_color
                ? foundPage?.text_color
                : chosenPortfolio?.text_color
                ? chosenPortfolio?.text_color
                : 'white',
              fontFamily: FontUtil(
                page.SubtitleText,
                chosenPortfolio.ContentText,
                "'Oswald', serif"
              )
            }}
          >
            {foundPage?.subtitle}
          </p>
        </div>
      </section>
    );
  }

  let iframeOverlay = null;
  if (chosenPortfolio) {
    iframeOverlay = (
      <div
        style={{
          display: 'block',
          position: 'absolute',
          width: '100%',
          height: '100%'
        }}
      ></div>
    );
  }

  let thumbnailTile = null;
  let htmlThumb = foundPage.content
    ? foundPage.content.find((piece) => piece._type === 'imageLinks')
    : null;
  let galImgThumb = foundPage.content
    ? foundPage.content.find((piece) => piece._type === 'galleryImage')
    : null;
  let pdfThumb = foundPage.content
    ? foundPage.content.find(
        (piece) =>
          piece._type === 'galleryPdf' || piece._type === 'galleryFullWidthPdf'
      )
    : null;
  let videoThumb = foundPage.content
    ? foundPage.content.find((piece) => piece.video)
    : null;
  let toggleThumb = foundPage.content
    ? foundPage.content.find((piece) => piece._type === 'toggleMenu')
    : null;
  let sliderThumb = foundPage.content
    ? foundPage.content.find((piece) => piece._type === 'galleryBASlider')
    : null;

  let webUrl = foundPage._type === 'website' ? foundPage.url : null;
  let webThumb =
    webUrl && foundPage?.thumbnail?.asset?._ref
      ? foundPage.thumbnail.asset._ref
      : null;
  let videoPreview =
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    foundPage.content &&
    foundPage.content.find((piece) => piece.video) !== undefined
      ? videoThumb.video.asset._ref
      : null;
  let pdfThumbnail =
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    foundPage.content &&
    foundPage.content.find(
      (piece) =>
        piece._type === 'galleryPdf' || piece._type === 'galleryFullWidthPdf'
    ) !== undefined
      ? pdfThumb.thumbnail.asset._ref
      : null;
  let galleryImage =
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    foundPage.content &&
    foundPage.content.find((piece) => piece._type === 'galleryImage') !==
      undefined
      ? galImgThumb.image.asset._ref
      : null;
  let sliderThumbnail =
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    foundPage.content &&
    foundPage.content.find((piece) => piece._type === 'galleryBASlider') !==
      undefined
      ? sliderThumb.Bimage.asset._ref
      : null;
  let toggleThumbnail;
  if (
    toggleThumb === undefined ||
    toggleThumb === null ||
    (foundPage._type !== 'gallery' && foundPage._type !== 'sliderGallery') ||
    !foundPage?.content
  ) {
    toggleThumbnail = undefined;
  } else if (toggleThumb.content[0]._type === 'galleryImage') {
    toggleThumbnail = toggleThumb.content[0].image.asset._ref;
  } else if (
    toggleThumb.content[0]._type === 'galleryPDF' ||
    toggleThumb.content[0]._type === 'galleryFullWidthPdf'
  ) {
    toggleThumbnail = toggleThumb.content[0].thumbnail.asset._ref;
  } else if (toggleThumb.content[0]._type === 'galleryVideo') {
    let videoRef = toggleThumb.content[0].video.asset._ref;
    toggleThumbnail = videos[videoRef];
  } else if (toggleThumb.content[0]._type === 'galleryBASlider') {
    toggleThumbnail = toggleThumb.content[0].Bimage.asset._ref;
  } else {
    toggleThumbnail = undefined;
  }

  let htmlContentImage =
    foundPage._type === 'htmlContent' &&
    foundPage.content &&
    foundPage.content.find((piece) => piece._type === 'imageLinks') !==
      undefined
      ? htmlThumb.image.asset._ref
      : null;
  let finalVideo = videoPreview !== null ? videos[videoPreview] : null;

  if (
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    galleryImage !== null
  ) {
    thumbnailTile = (
      <ThumbsBigNav
        dropColor={
          chosenPortfolio?.accent_color
            ? chosenPortfolio.accent_color
            : 'rgb(68, 159, 245)'
        }
        style={{
          margin: isTopLevel ? null : '1%'
        }}
      >
        <div
          className="portfolio-content group-content"
          onMouseEnter={chosenPortfolio ? () => enterContent() : null}
          onMouseLeave={chosenPortfolio ? () => leaveContent() : null}
          style={{
            cursor: chosenPortfolio?.MegaText?.MegaFlat ? 'default' : 'pointer'
          }}
        >
          <img
            src={urlFor(galleryImage).auto('format').url()}
            alt="logo"
            className="portfolio-single-image"
            style={{
              opacity: chosenPortfolio ? opacityBack : 1,
              height: '100%',
              width: '100%'
            }}
          />
          {overlay}
        </div>
      </ThumbsBigNav>
    );
  } else if (
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    pdfThumbnail !== null
  ) {
    if (chosenPortfolio?.MegaText?.MegaFlat) {
      let url2 = pdfs[contents[0].file.asset._ref].url;
      let thumbNailRef2 = contents[0].thumbnail.asset._ref;
      let thumbNailUrl2 = urlFor(thumbNailRef2).auto('format').width(100).url();
      //onsole.log("! URL", url2);
      //onsole.log("! REF", thumbNailRef2);
      //onsole.log("! THUMB URL", thumbNailUrl2);
      thumbnailTile = (
        <ThumbsBigNav
          dropColor={
            chosenPortfolio?.accent_color
              ? chosenPortfolio.accent_color
              : 'rgb(68, 159, 245)'
          }
          style={{ margin: isTopLevel ? null : '1%' }}
        >
          <div
            className="portfolio-content group-content"
            onMouseEnter={chosenPortfolio ? () => enterContent() : null}
            onMouseLeave={chosenPortfolio ? () => leaveContent() : null}
            style={{
              cursor: chosenPortfolio?.MegaText?.MegaFlat
                ? 'default'
                : 'pointer',
              overflow: chosenPortfolio?.MegaText?.MegaFlat
                ? 'visible'
                : 'hidden'
            }}
          >
            <PDFViewer2
              url={url2}
              isThumbnail={false}
              type="galleryFullWidthPdf"
              thumbnailUrl={thumbNailUrl2}
            />
            {overlay}
          </div>
        </ThumbsBigNav>
      );
    } else {
      thumbnailTile = (
        <ThumbsBigNav
          dropColor={
            chosenPortfolio?.accent_color
              ? chosenPortfolio.accent_color
              : 'rgb(68, 159, 245)'
          }
          style={{ margin: isTopLevel ? null : '1%' }}
        >
          <div
            className="portfolio-content group-content"
            onMouseEnter={chosenPortfolio ? () => enterContent() : null}
            onMouseLeave={chosenPortfolio ? () => leaveContent() : null}
            style={{
              cursor: chosenPortfolio?.MegaText?.MegaFlat
                ? 'default'
                : 'pointer'
            }}
          >
            <img
              src={urlFor(pdfThumbnail).auto('format').url()}
              alt="logo"
              className="portfolio-single-image"
              style={{
                opacity: chosenPortfolio ? opacityBack : 1,
                height: '100%',
                width: '100%'
              }}
            />
            {overlay}
          </div>
        </ThumbsBigNav>
      );
    }
  } else if (
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    videoPreview !== null
  ) {
    thumbnailTile = (
      <ThumbsBigNav
        dropColor={
          chosenPortfolio?.accent_color
            ? chosenPortfolio.accent_color
            : 'rgb(68, 159, 245)'
        }
        style={{ margin: isTopLevel ? null : '1%' }}
      >
        <div
          className="portfolio-content group-content"
          onMouseEnter={chosenPortfolio ? () => enterContent() : null}
          onMouseLeave={chosenPortfolio ? () => leaveContent() : null}
          style={{
            cursor: chosenPortfolio?.MegaText?.MegaFlat ? 'default' : 'pointer',
            overflow: chosenPortfolio?.MegaText?.MegaFlat ? 'visible' : 'hidden'
          }}
        >
          <SanityMuxPlayer
            className="mux-player"
            assetDocument={finalVideo}
            autoload={true}
            autoplay={videoThumb?.autoplay ? videoThumb.autoplay : false}
            loop={videoThumb?.loop ? videoThumb.loop : false}
            muted={true}
            SIG={false}
            SIP={false}
            showControls={controlsOverride ? true : false}
            override={true}
            style={{}}
            posterurl={`https://image.mux.com/${finalVideo.playbackId}/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=1`}
            type="galleryVideo"
            isSelected={false}
          />
          {overlay}
        </div>
      </ThumbsBigNav>
    );
  } else if (foundPage._type === 'htmlContent' && htmlContentImage !== null) {
    thumbnailTile = (
      <ThumbsBigNav
        dropColor={
          chosenPortfolio?.accent_color
            ? chosenPortfolio.accent_color
            : 'rgb(68, 159, 245)'
        }
        style={{ margin: isTopLevel ? null : '1%' }}
      >
        <div
          className="portfolio-content group-content"
          onMouseEnter={chosenPortfolio ? () => enterContent() : null}
          onMouseLeave={chosenPortfolio ? () => leaveContent() : null}
          style={{
            cursor: chosenPortfolio?.MegaText?.MegaFlat ? 'default' : 'pointer'
          }}
        >
          <img
            src={urlFor(htmlContentImage).auto('format').url()}
            alt="logo"
            className="portfolio-single-image"
            style={{
              opacity: chosenPortfolio ? opacityBack : 1,
              height: '100%',
              width: '100%'
            }}
          />
          {overlay}
        </div>
      </ThumbsBigNav>
    );
  } else if (foundPage._type === 'website' && webUrl !== null) {
    if (webThumb) {
      thumbnailTile = (
        <ThumbsBigNav
          dropColor={
            chosenPortfolio?.accent_color
              ? chosenPortfolio.accent_color
              : 'rgb(68, 159, 245)'
          }
          style={{
            margin: isTopLevel ? null : '1%'
          }}
        >
          <div
            className="portfolio-content group-content"
            onMouseEnter={chosenPortfolio ? () => enterContent() : null}
            onMouseLeave={chosenPortfolio ? () => leaveContent() : null}
            style={{
              cursor: chosenPortfolio?.MegaText?.MegaFlat
                ? 'default'
                : 'pointer'
            }}
          >
            <img
              src={urlFor(webThumb).auto('format').url()}
              alt="logo"
              className="portfolio-single-image"
              style={{
                opacity: chosenPortfolio ? opacityBack : 1,
                height: '100%',
                width: '100%'
              }}
            />
            {overlay}
          </div>
        </ThumbsBigNav>
      );
    } else {
      thumbnailTile = (
        <ThumbsBigNav
          dropColor={
            chosenPortfolio?.accent_color
              ? chosenPortfolio.accent_color
              : 'rgb(68, 159, 245)'
          }
          style={{ margin: isTopLevel ? null : '1%' }}
        >
          <div
            className="portfolio-content group-content"
            onMouseEnter={chosenPortfolio ? () => enterContent() : null}
            onMouseLeave={chosenPortfolio ? () => leaveContent() : null}
            style={{
              cursor: chosenPortfolio?.MegaText?.MegaFlat
                ? 'default'
                : 'pointer'
            }}
          >
            <div
              className="iframe-container"
              style={{ opacity: chosenPortfolio ? opacityBack : 1 }}
            >
              <iframe
                scrolling="no"
                title="iframe"
                allowFullScreen
                src={webUrl}
              ></iframe>
            </div>
            {overlay}
            {iframeOverlay}
          </div>
        </ThumbsBigNav>
      );
    }
  } else if (
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    sliderThumbnail !== undefined &&
    sliderThumbnail !== null
  ) {
    thumbnailTile = (
      <ThumbsBigNav
        dropColor={
          chosenPortfolio?.accent_color
            ? chosenPortfolio.accent_color
            : 'rgb(68, 159, 245)'
        }
        style={{ margin: isTopLevel ? null : '1%' }}
      >
        <div
          className="portfolio-content group-content"
          style={{
            cursor: chosenPortfolio?.MegaText?.MegaFlat ? 'default' : 'pointer'
          }}
        >
          <img
            src={urlFor(sliderThumbnail).auto('format').url()}
            alt="logo"
            className="portfolio-single-image"
            style={{
              height: '100%',
              width: '100%'
            }}
          />
          {overlay}
        </div>
      </ThumbsBigNav>
    );
  } else if (
    (foundPage._type === 'gallery' || foundPage._type === 'sliderGallery') &&
    toggleThumb !== undefined &&
    toggleThumb !== null
  ) {
    if (toggleThumb.content[0]._type === 'galleryImage') {
      thumbnailTile = (
        <ThumbsBigNav
          dropColor={
            chosenPortfolio?.accent_color
              ? chosenPortfolio.accent_color
              : 'rgb(68, 159, 245)'
          }
          style={{
            margin: isTopLevel ? null : '1%'
          }}
        >
          <div
            className="portfolio-content group-content"
            style={{
              cursor: chosenPortfolio?.MegaText?.MegaFlat
                ? 'default'
                : 'pointer'
            }}
          >
            <img
              src={urlFor(toggleThumbnail).auto('format').url()}
              alt="logo"
              className="portfolio-single-image"
              style={{
                height: '100%',
                width: '100%'
              }}
            />
            {overlay}
          </div>
        </ThumbsBigNav>
      );
    } else if (
      toggleThumb.content[0]._type === 'galleryPDF' ||
      toggleThumb.content[0]._type === 'galleryFullWidthPdf'
    ) {
      thumbnailTile = (
        <ThumbsBigNav
          dropColor={
            chosenPortfolio?.accent_color
              ? chosenPortfolio.accent_color
              : 'rgb(68, 159, 245)'
          }
          style={{ margin: isTopLevel ? null : '1%' }}
        >
          <div
            className="portfolio-content group-content"
            style={{
              cursor: chosenPortfolio?.MegaText?.MegaFlat
                ? 'default'
                : 'pointer'
            }}
          >
            <img
              src={urlFor(toggleThumbnail).auto('format').url()}
              alt="logo"
              className="portfolio-single-image"
              style={{
                height: '100%',
                width: '100%'
              }}
            />
            {overlay}
          </div>
        </ThumbsBigNav>
      );
    } else if (toggleThumb.content[0]._type === 'galleryVideo') {
      // //onsole.log("VIDEO THUMB", toggleThumb);
      thumbnailTile = (
        <ThumbsBigNav
          dropColor={
            chosenPortfolio?.accent_color
              ? chosenPortfolio.accent_color
              : 'rgb(68, 159, 245)'
          }
          style={{ margin: isTopLevel ? null : '1%' }}
        >
          <div
            className="portfolio-content group-content"
            style={{
              cursor: chosenPortfolio?.MegaText?.MegaFlat
                ? 'default'
                : 'pointer'
            }}
          >
            <SanityMuxPlayer
              className="mux-player"
              assetDocument={toggleThumbnail}
              autoload={true}
              autoplay={false}
              loop={false}
              muted={true}
              SIG={false}
              SIP={false}
              showControls={controlsOverride ? true : false}
              override={true}
              style={{}}
              posterurl={`https://image.mux.com/${toggleThumbnail.playbackId}/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=1`}
              type="galleryVideo"
              isSelected={false}
            />
            {overlay}
          </div>
        </ThumbsBigNav>
      );
    } else if (toggleThumb.content[0]._type === 'galleryBASlider') {
      thumbnailTile = (
        <ThumbsBigNav
          dropColor={
            chosenPortfolio?.accent_color
              ? chosenPortfolio.accent_color
              : 'rgb(68, 159, 245)'
          }
          style={{ margin: isTopLevel ? null : '1%' }}
        >
          <div
            className="portfolio-content group-content"
            style={{
              cursor: chosenPortfolio?.MegaText?.MegaFlat
                ? 'default'
                : 'pointer'
            }}
          >
            <img
              src={urlFor(toggleThumbnail).auto('format').url()}
              alt="logo"
              className="portfolio-single-image"
              style={{
                height: '100%',
                width: '100%'
              }}
            />
            {overlay}
          </div>
        </ThumbsBigNav>
      );
    } else {
      thumbnailTile = (
        <ThumbsBigNav
          dropColor={
            chosenPortfolio?.accent_color
              ? chosenPortfolio.accent_color
              : 'rgb(68, 159, 245)'
          }
          onMouseEnter={chosenPortfolio ? () => enterContent() : null}
          onMouseLeave={chosenPortfolio ? () => leaveContent() : null}
          style={{ margin: isTopLevel ? null : '1%' }}
        >
          {chosenPortfolio?.portfolio_logo.asset?._ref ? (
            <div className="logo-thumb">
              <img
                src={urlFor(chosenPortfolio.portfolio_logo.asset._ref)
                  .auto('format')
                  .width(400)
                  .url()}
                alt="logo"
                style={{
                  height: 'auto',
                  maxWidth: '100%',
                  objectFit: 'contain'
                }}
              />
            </div>
          ) : null}
          {overlay}
        </ThumbsBigNav>
      );
    }
  } else {
    thumbnailTile = (
      <ThumbsBigNav
        dropColor={
          chosenPortfolio?.accent_color
            ? chosenPortfolio.accent_color
            : 'rgb(68, 159, 245)'
        }
        onMouseEnter={chosenPortfolio ? () => enterContent() : null}
        onMouseLeave={chosenPortfolio ? () => leaveContent() : null}
        style={{ margin: isTopLevel ? null : '1%' }}
      >
        {chosenPortfolio.portfolio_logo.asset._ref ? (
          <div className="logo-thumb">
            <img
              src={urlFor(chosenPortfolio.portfolio_logo.asset._ref)
                .auto('format')
                .width(400)
                .url()}
              alt="logo"
              style={{
                height: 'auto',
                maxWidth: '100%',
                objectFit: 'contain',
                opacity: chosenPortfolio ? opacityBack : 1
              }}
            />
          </div>
        ) : null}
        {overlay}
      </ThumbsBigNav>
    );
  }

  return thumbnailTile;
};

export default GalleryThumbsContent;
