import React, { useEffect, useRef, useState } from 'react';
//import {useDispatch} from 'react-redux'
import { useFirestore } from 'react-redux-firebase';
import TestArea from './NoteReadoutTest';
//import NSD from './Notes-Session-DropDown';
import DateSelect from '../../DateSelect';
import MdFavoriteBorder from '../../../../assets/Icons/white notepad icon-32.png'; //assets\Icons\white notepad icon-32.png
import * as Scale from '../../../../utils/Scalars';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import Clearable from '../../../../utils/InputBox2';

const Notes = ({
  Firestore,
  Dash,
  SGPMC,
  userInfo,
  SGPFC,
  SSMC,
  SGPSMC,
  SFS,
  SFGPS,
  Size,
  sessionList,
  setSelectedNotesSession,
  selectedNotesSession,
  setSelectedNotesString,
  selectedNotesString,
  selectedNotesSessionObj,
  setSelectedNotesSessionObj,
  UpdateSessionList,
  aggregateSelected,
  selectedUser,
  updateNotepad3
}) => {
  const firestore = useFirestore();

  //const [NoteSession, SetNoteSession] = useState([]);
  const [EditMode, SetEdit] = useState(false);
  //const [availableSessions, setAvailableSessions] = useState([]);
  const NotepadRef = useRef();
  const [searchTerm, setSearchTerm] = useState('');
  const [filterClicked, setFilterClicked] = useState(false);
  const [currentPlaceholder, setCurrentPlaceholder] = useState(
    'Filter by Company or User'
  );
  const [Copied, setCopied] = useState(false);
  //const [sessionInfo, setSessionInfo] = useState({});

  //MasterCollection Population pattern
  //Used to initialy populate the guestpass master collection if not already populated, this is populated with Guestpass options
  useEffect(() => {
    ////////onsole.log("!!!DASHGP!!!",Dash.GuestpassMasterCollection)
    if (Dash.GuestpassMasterCollection === null) return;
    if (Dash.GuestpassMasterCollection.length === 0)
      SGPMC(selectedUser, firestore);
  }, [Dash.GuestpassMasterCollection, SGPMC, firestore, selectedUser]);

  //MasterCollection Population pattern
  //Used to initialy populate the Session master collection if not already populated, this is populated with session options
  useEffect(() => {
    ////////onsole.log("!!!DASHS!!!",Dash.SessionMasterCollection)
    if (Dash.SessionMasterCollection === null) return;
    if (Dash.SessionMasterCollection.length === 0)
      SSMC(selectedUser, firestore);
  }, [Dash.SessionMasterCollection, SSMC, firestore, selectedUser]);

  //MasterCollection Population pattern
  //Used to initialy populate the guestpass session master collection if not already populated, this is populated with session options.
  useEffect(() => {
    ////////onsole.log("!!!DASHGPS!!!",Dash.GuestpassSessionMasterCollection)
    if (Dash.GuestpassSessionMasterCollection === null) return;
    if (Dash.GuestpassSessionMasterCollection.length === 0)
      SGPSMC(selectedUser, firestore);
  }, [Dash.GuestpassSessionMasterCollection, SGPSMC, firestore, selectedUser]);

  //FilteredCollection Population pattern
  //Designed to update guestPass Filter collection if master collection updates, this is a collection og GuestPasses
  useEffect(() => {
    ////////onsole.log("!!!DASHGP2!!!",Dash.GuestpassFilteredCollection)
    if (Dash.GuestpassMasterCollection === null) return;
    // if(Dash.GuestpassFilteredCollection === null)
    // return
    if (Dash.GuestpassMasterCollection.length === 0) return;
    //else if(Dash.GuestpassFilteredCollection.length === 0){
    SGPFC(selectedUser);
    //}
  }, [Dash.GuestpassMasterCollection, SGPFC, selectedUser]); //Dash.GuestpassFilteredCollection
  //Designed to update guest pass session Filter collection if master collection updates, This contains Session objects.
  useEffect(() => {
    ////////onsole.log("!!!DASHGP2!!!",Dash.GuestpassSessionFilteredCollection)
    if (Dash.GuestpassSessionMasterCollection === null) return;
    // if(Dash.GuestpassSessionFilteredCollection === null)
    // return
    if (Dash.GuestpassSessionMasterCollection.length === 0) return;
    //else if(Dash.GuestpassSessionFilteredCollection?.length === 0){
    SFGPS(selectedUser);
    //}
  }, [Dash.GuestpassSessionMasterCollection, SFGPS, selectedUser]); //Dash.GuestpassSessionFilteredCollection

  //FilteredCollection Population pattern
  //Designed to update Filter collection if master collection updates. Filter Guest Pass session, This is a collection of sessions
  useEffect(() => {
    if (Dash.GuestpassSessionMasterCollection?.length > 0)
      //&& !Dash.GuestpassSessionFilteredCollection>0
      SFGPS(selectedUser);
  }, [
    Dash.solo,
    Dash.views,
    Dash.DateRange,
    SFGPS,
    selectedUser,
    Dash.GuestpassSessionMasterCollection
  ]); // Dash.GuestpassSessionFilteredCollection

  //FilteredCollection Population pattern
  //Designed to update Filter collection if master collection updates. Filter session, This is a collection of sessions
  useEffect(() => {
    //////////onsole.log("!!!DASHGP2!!!",Dash.GuestpassFilteredCollection)
    if (Dash.SessionMasterCollection === null) return;
    //if(Dash.SessionFilteredCollection === null)
    //return
    if (Dash.SessionMasterCollection.length === 0) return;
    // else if(Dash.SessionFilteredCollection.length === 0){
    SFS(selectedUser);
    //}
  }, [Dash.SessionMasterCollection, SFS, selectedUser]); //Dash.selectedPortfolioId,

  //FilteredCollection Population pattern
  //Designed to update Filter collection if master collection updates. Filter Guest Pass session, This is a collection of sessions
  useEffect(() => {
    if (Dash.GuestpassMasterCollection === null) return;
    SGPFC(selectedUser);
  }, [
    Dash.solo,
    Dash.DateRange,
    Dash.GuestpassMasterCollection,
    SGPFC,
    selectedUser
  ]);

  //DateRange/SoloUpdateProvocation
  useEffect(() => {
    if (Dash.GuestpassSessionMasterCollection) SFGPS(selectedUser);
    if (Dash.SessionMasterCollection) SFS(selectedUser);
    if (Dash.GuestpassMasterCollection) SGPFC(selectedUser);
  }, [
    Dash.solo,
    Dash.selectedPortfolioId,
    Dash.DateRange,
    selectedUser,
    SFS,
    SGPFC,
    SFGPS,
    Dash.GuestpassMasterCollection,
    Dash.GuestpassSessionMasterCollection,
    Dash.SessionMasterCollection
  ]);

  useEffect(() => {
    if (!Dash.SessionFilteredCollection.length) {
      setSelectedNotesString('Filter by User');
      setSelectedNotesSession(undefined);
      setSelectedNotesSessionObj({});
      return;
    }

    let filteredSessions = Dash.SessionFilteredCollection.filter(
      (filt) => filt?.notes && filt?.notes !== ''
    )?.sort((a, b) => b.session_date.seconds - a.session_date.seconds);
    if (filteredSessions.length) {
      // //////onsole.log("ALL NOTES SESSSIONS", filteredSessions);
      let session = filteredSessions[0];
      let sessionDate = new Date(
        session.session_date.seconds * 1000
      ).toDateString();
      let sessionTime = new Date(
        session.session_date.seconds * 1000
      ).toLocaleTimeString();
      setSelectedNotesString({
        date: sessionDate,
        time: sessionTime,
        company: session.customer_company,
        customer: session.customer_name,
        email: session.customer_email
      });
      setSelectedNotesSession(session.id);
      setSelectedNotesSessionObj(session);
    } else {
      setSelectedNotesString('Filter by User');
      setSelectedNotesSession(undefined);
      setSelectedNotesSessionObj({});
    }
  }, [
    Dash.SessionFilteredCollection,
    setSelectedNotesSessionObj,
    setSelectedNotesString,
    setSelectedNotesSession
  ]);

  useEffect(() => {
    if (
      Dash.SessionMasterCollection === null ||
      Dash.SessionMasterCollection === undefined ||
      aggregateSelected === 0
    ) {
      return;
    } else {
      let today = new Date();
      let newDate = new Date();
      //////onsole.log("SELECTED TIME FRAME: ", aggregateSelected);
      if (aggregateSelected === '1w') {
        newDate.setDate(today.getDate() - 7);
      } else if (aggregateSelected === '2w') {
        newDate.setDate(today.getDate() - 14);
      } else if (aggregateSelected === '1m') {
        newDate.setDate(today.getDate() - 30);
      } else if (aggregateSelected === '3m') {
        newDate.setDate(today.getDate() - 90);
      } else if (aggregateSelected === '6m') {
        newDate.setDate(today.getDate() - 180);
      } else if (aggregateSelected === '1d') {
        newDate = new Date(
          new Date(
            new Date(new Date(new Date(new Date())).setSeconds(0)).setMinutes(0)
          ).setHours(0)
        );
      } else {
        return;
      }

      // let dateFilteredSessions = sessionList.filter(port => {
      //     return (((port.session_date.seconds * 1000) > newDate.getTime()) && ((port.session_date.seconds * 1000) < today.getTime()));
      // });

      //setAvailableSessions(dateFilteredSessions);
    }
  }, [sessionList, aggregateSelected, Dash.SessionMasterCollection]);

  const closeFilter = (event) => {
    if (event.target.className !== 'session-select-id') {
      closeActFilter();
    }
  };

  const closeActFilter = () => {
    setFilterClicked(false);
    window.removeEventListener('click', closeFilter);
  };

  const toggleFilter = () => {
    if (!filterClicked) {
      window.setTimeout(() => {
        window.addEventListener('click', closeFilter);
      }, 50);
    }
    setFilterClicked(!filterClicked);
  };

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
    if (event.target.value !== '') {
      setCurrentPlaceholder('');
    } else {
      setCurrentPlaceholder('Filter by Company or User');
    }
  };

  let filteredSessions = Dash.SessionFilteredCollection?.filter((session) => {
    return (
      session?.customer_company
        ?.toLowerCase()
        .indexOf(searchTerm.toLowerCase()) !== -1 ||
      session?.customer_name
        ?.toLowerCase()
        .indexOf(searchTerm.toLowerCase()) !== -1
    );
  });

  let listMapping = filteredSessions
    .filter((filt) => filt?.notes && filt?.notes !== '')
    .map((session) => {
      let sessionDate = new Date(
        session.session_date.seconds * 1000
      ).toDateString();
      let sessionTime = new Date(
        session.session_date.seconds * 1000
      ).toLocaleTimeString();

      return (
        <div
          style={{
            paddingLeft: '10px',
            boxSizing: 'border-box',
            height: '60px',
            width: '100%',
            backgroundColor:
              selectedNotesSession === session.id ? '#056AAF' : '#FFFFFF',
            marginBottom: '8px',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            cursor: 'pointer',
            boxShadow: '2px 2px 4px #3b3b3b'
          }}
          onClick={() => {
            setSelectedNotesString({
              date: sessionDate,
              time: sessionTime,
              company: session.customer_company,
              customer: session.customer_name,
              email: session.customer_email
            });
            setSelectedNotesSession(session.id);
            setSelectedNotesSessionObj(session);
          }}
          key={session.id}
        >
          <div
            style={{
              gridColumn: '1 / span 1',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '100%',
                overflow: 'hidden'
              }}
            >
              <p
                style={{
                  color:
                    selectedNotesSession === session.id
                      ? 'white'
                      : 'rgb(59, 59, 59)',
                  fontSize: '16px',
                  fontWeight: 600,
                  fontFamily: "'Open Sans', sans-serif",
                  marginBlockStart: '0px',
                  marginBlockEnd: '0px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {session.customer_name}
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '100%',
                overflow: 'hidden'
              }}
            >
              <p
                style={{
                  color:
                    selectedNotesSession === session.id
                      ? 'white'
                      : 'rgb(59, 59, 59)',
                  fontSize: '16px',
                  fontWeight: 600,
                  fontFamily: "'Open Sans', sans-serif",
                  marginBlockStart: '0px',
                  marginBlockEnd: '0px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {session.customer_email}
              </p>
            </div>
          </div>
          <div
            style={{
              gridColumn: '2 / span 1',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                overflow: 'hidden'
              }}
            >
              <p
                style={{
                  color:
                    selectedNotesSession === session.id
                      ? 'white'
                      : 'rgb(59, 59, 59)',
                  fontSize: '16px',
                  fontWeight: 600,
                  fontFamily: "'Open Sans', sans-serif",
                  marginBlockStart: '0px',
                  marginBlockEnd: '0px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {sessionDate}
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                overflow: 'hidden'
              }}
            >
              <p
                style={{
                  color:
                    selectedNotesSession === session.id
                      ? 'white'
                      : 'rgb(59, 59, 59)',
                  fontSize: '16px',
                  fontWeight: 600,
                  fontFamily: "'Open Sans', sans-serif",
                  marginBlockStart: '0px',
                  marginBlockEnd: '0px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {sessionTime}
              </p>
            </div>
          </div>
          <div
            style={{
              gridColumn: '3 / span 1',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                overflow: 'hidden'
              }}
            >
              <p
                style={{
                  color:
                    selectedNotesSession === session.id
                      ? 'white'
                      : 'rgb(59, 59, 59)',
                  fontSize: '16px',
                  fontWeight: 600,
                  fontFamily: "'Open Sans', sans-serif",
                  marginBlockStart: '0px',
                  marginBlockEnd: '0px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {session.customer_company}
              </p>
            </div>
          </div>
        </div>
      );
    });

  let mapDisplay;
  if (listMapping.length) {
    mapDisplay = listMapping;
  } else {
    mapDisplay = (
      <div
        style={{
          paddingLeft: '10px',
          boxSizing: 'border-box',
          height: '80%',
          width: '100%',
          backgroundColor: 'white',
          marginBottom: '5px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '3px'
        }}
      >
        <p
          style={{
            marginBlockStart: '0px',
            marginBlockEnd: '0px',
            fontSize: '36px',
            fontWeight: 600,
            fontFamily: "'Open Sans', sans-serif"
          }}
        >
          No Sessions to Display
        </p>
      </div>
    );
  }
  const UpdateLocalSessions = (Str) => {
    let SMCE = [...Dash.SessionMasterCollection];
    //////onsole.log(SMCE[Dash.SessionMasterCollection.findIndex((ele)=>{return ele.id === selectedNotesSessionObj.id})])
    SMCE[
      Dash.SessionMasterCollection.findIndex((ele) => {
        return ele.id === selectedNotesSessionObj.id;
      })
    ].notes = Str;
    //////onsole.log(SMCE[Dash.SessionMasterCollection.findIndex((ele)=>{return ele.id === selectedNotesSessionObj.id})])
    UpdateSessionList(SMCE);
  };
  const SearchRef = useRef();
  return (
    <div
      style={{
        display: 'grid',
        height: '100%',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: '128px 96px 1fr'
      }}
    >
      <div
        style={{
          gridColumn: '1/1',
          gridRow: '1/4',
          margin: '0px',
          boxSizing: 'border-box',
          display: 'grid',
          gridTemplateRows: '128px 52px 1fr'
        }}
      >
        <div
          style={{
            gridRow: '1/1',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr'
          }}
        >
          <div
            style={{
              gridColumn: '1 / span 1',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center'
            }}
          >
            <div
              style={{
                alignSelf: 'center',
                backgroundColor: 'black',
                width: `${Scale.Width(Size, 72)}px`,
                height: `${Scale.Height(Size, 72)}px`,
                maskSize: '100% 100%',
                maskRepeat: 'no-repeat',
                maskImage: `url(${MdFavoriteBorder})`,
                WebkitMaskRepeat: 'no-repeat',
                WebkitMaskSize: '100% 100%',
                WebkitMaskImage: `url(${MdFavoriteBorder})`
              }}
            ></div>
            <p
              style={{
                fontSize: `${Scale.Height(Size, 26)}px`,
                alignSelf: 'center',
                marginBlockEnd: '0px',
                marginBlockStart: '0px',
                fontWeight: 600,
                fontFamily: "'Newsreader', serif"
              }}
            >
              Notes
            </p>
          </div>
          <div
            style={{
              gridColumn: '2 / span 1',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}
          >
            <DateSelect />
          </div>
        </div>
        <div
          style={{
            gridRow: '2/2',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              width: '100%'
            }}
          >
            <Clearable
              Pos={{ width: '100%', height: '100%', paddingRight: '0px' }}
              IRef={SearchRef}
              input={
                <input
                  style={{
                    width: '100%',
                    height: '100%',
                    borderTopLeftRadius: '12px',
                    borderBottomLeftRadius: '12px',
                    textAlign: 'center',
                    borderRight: 'none',
                    paddingRight: '24px',
                    boxSizing: 'border-box'
                  }}
                  ref={SearchRef}
                  onClick={() => toggleFilter()}
                  value={searchTerm}
                  onChange={handleInputChange}
                  type="text"
                  placeholder={
                    filterClicked
                      ? currentPlaceholder
                      : 'Filter by Company or User'
                  }
                  className="notes-select-id"
                />
              }
            ></Clearable>
            {/* Pos={{width: "92%", height: '22px', borderTopLeftRadius: '12px', borderBottomLeftRadius: '12px', textAlign: 'center', borderRight: 'none'}} */}

            <div
              style={{
                backgroundColor: '#3b3b3b',
                width: '8%',
                height: '28px',
                borderTopRightRadius: '12px',
                borderBottomRightRadius: '12px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <SearchRoundedIcon
                style={{
                  color: 'white',
                  fontSize: '1.5em'
                }}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            gridRow: '3/3',
            height: `${Scale.Height(Size, 549.283)}px`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          <div
            style={{
              paddingLeft: '10px',
              height: '40px',
              boxSizing: 'border-box',
              width: '100%',
              backgroundColor: 'rgb(59, 59, 59)',
              marginBottom: '8px',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              borderRadius: '3px'
            }}
          >
            <p
              style={{
                color: 'white',
                marginBlockStart: '0px',
                marginBlockEnd: '0px',
                fontSize: '16px',
                fontWeight: 600,
                fontFamily: "'Open Sans', sans-serif"
              }}
            >
              Select Sessions
            </p>
          </div>
          <div
            style={{
              width: '100%',
              height: '100%',
              maxHeight: '100%',
              padding: '8px',
              overflowY: 'auto',
              justifyContent: 'flex-start',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            {mapDisplay}
          </div>
        </div>
      </div>
      <div
        style={{
          gridColumn: '2/2',
          gridRow: '1/2',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden'
        }}
      >
        <p
          style={{
            fontSize: `${Scale.Height(Size, 26)}px`,
            marginBlockStart: '0px',
            marginBlockEnd: '0px',
            fontWeight: 600,
            fontFamily: "'Newsreader', serif",
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
          }}
        >
          {selectedNotesString !== 'Filter by User'
            ? `${selectedNotesString.date} - ${selectedNotesString.customer}`
            : 'Notepad'}
        </p>
      </div>
      <div
        style={{
          gridColumn: '2/2',
          height: `${Scale.Height(Size, 663.867)}px`,
          gridRow: '2/4',
          boxSizing: 'border-box',
          margin: '0px',
          display: 'grid',
          gridTemplateRows: 'calc(100% / 8 * 6) calc(100% / 8 * 2)'
        }}
      >
        {/* <TestArea L1={Dash.GuestpassSessionMasterCollection} L2={Dash.SessionMasterCollection}></TestArea> */}
        <div
          style={{
            gridRow: '1 / span 1',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '90%',
              backgroundColor: '#e6e6e6',
              borderRadius: '3px'
            }}
          >
            <TestArea
              Copied={Copied}
              EditMode={EditMode}
              Ref={NotepadRef}
              L1={[selectedNotesSessionObj]}
              L2={[]}
            ></TestArea>
          </div>
        </div>
        <div
          style={{
            gridRow: '2 / span 1',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr'
          }}
        >
          <div
            style={{
              gridColumn: '1 / span 1',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingLeft: '15px'
            }}
          >
            <button
              onClick={(eve) => {
                //////onsole.log("!!!N!!!",selectedNotesSessionObj);
                let msg = '';
                if (!EditMode) {
                  window.getSelection().selectAllChildren(NotepadRef.current);
                  try {
                    const successful = document.execCommand('copy');
                    msg = successful ? 'successful' : 'unsuccessful';
                    //////onsole.log('Copy email command was ' + msg);
                  } catch (err) {
                    //////onsole.log('Oops, unable to copy');
                  }

                  window.getSelection().removeAllRanges();

                  //setCopied(true);
                  setTimeout(() => {
                    //setCopied(false);
                  }, 1000);
                } else if (
                  !selectedNotesSessionObj.notes.includes('<p>') &&
                  !selectedNotesSessionObj.notes.includes('<li>')
                ) {
                  //
                  //TextArea or quillJS copy
                  let Sele = window.getSelection();
                  const range = document.createRange();
                  ////////onsole.log("CURRENT NODE", clipboardRef.current);
                  range.selectNode(NotepadRef.current);
                  Sele.addRange(range);
                  NotepadRef.current.select();
                  try {
                    const successful = document.execCommand('copy');
                    msg = successful ? 'successful' : 'unsuccessful';
                    //////onsole.log('Copy email command was ' + msg);
                  } catch (err) {
                    //////onsole.log('Oops, unable to copy');
                  }

                  window.getSelection().removeAllRanges();
                } else {
                  //TextArea or quillJS copy
                  let Sele = window.getSelection();
                  const range = document.createRange();
                  ////////onsole.log("CURRENT NODE", clipboardRef.current);
                  range.selectNode(NotepadRef.current.editingArea);
                  Sele.addRange(range);
                  //NotepadRef.current.select();
                  try {
                    const successful = document.execCommand('copy');
                    msg = successful ? 'successful' : 'unsuccessful';
                    //////onsole.log('Copy email command was ' + msg);
                  } catch (err) {
                    //////onsole.log('Oops, unable to copy');
                  }

                  window.getSelection().removeAllRanges();
                }
                if (msg === 'successful') {
                  setCopied(true);
                  setTimeout(() => {
                    setCopied(false);
                  }, 1000);
                }
              }}
              style={{
                cursor: 'pointer',
                width: `${Scale.Width(Size, 200)}px`,
                height: '30px',
                borderRadius: '18px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '2px 2px 3px #dedede',
                backgroundColor: '#555555',
                letterSpacing: '0.02em'
              }}
            >
              <p
                style={{
                  marginBlockStart: '0px',
                  marginBlockEnd: '0px',
                  fontFamily: "'Open Sans', sans-serif",
                  color: 'white',
                  fontSize: `${Scale.Height(Size, 16)}px`,
                  fontWeight: 600
                }}
              >
                Copy to Clipboard
              </p>
            </button>
          </div>
          {/* Do we want this button in the V1 Release? We are about to rehaul the notepad system. */}
          <div
            style={{
              gridColumn: '2 / span 1',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingRight: '15px'
            }}
          >
            <button
              onClick={(eve) => {
                if (
                  EditMode &&
                  !selectedNotesSessionObj.notes.includes('<p>') &&
                  !selectedNotesSessionObj.notes.includes('<li>')
                ) {
                  //Firestore Save here
                  //////onsole.log(selectedNotesSessionObj);
                  //updateNotepad2
                  firestore
                    .collection('sessions')
                    .doc(selectedNotesSessionObj.id)
                    .update({
                      notes: NotepadRef.current.value
                    })
                    .then((e) => {
                      //////onsole.log("success")
                      UpdateLocalSessions(NotepadRef.current.value);
                      SetEdit(!EditMode);
                    })
                    .catch((err) => {
                      //////onsole.log(err);
                      alert('Failed to update Firestore: ', err);
                    });
                } else if (
                  EditMode &&
                  (selectedNotesSessionObj.notes.includes('<p>') ||
                    selectedNotesSessionObj.notes.includes('<li>'))
                ) {
                  //Firestore Save here
                  //////onsole.log("NREF",NotepadRef.current.state.value);
                  let endvalue = updateNotepad3(
                    selectedNotesSessionObj.notes,
                    NotepadRef.current.state.value,
                    'Post Session Amendment'
                  );
                  firestore
                    .collection('sessions')
                    .doc(selectedNotesSessionObj.id)
                    .update({
                      notes: endvalue
                    })
                    .then((e) => {
                      //////onsole.log("success")
                      UpdateLocalSessions(endvalue);
                      SetEdit(!EditMode);
                    })
                    .catch((err) => {
                      //////onsole.log(err);
                      alert('Failed to update Firestore: ', err);
                    });
                } else SetEdit(!EditMode);
              }}
              style={{
                cursor: 'pointer',
                width: `${Scale.Width(Size, 200)}px`,
                height: '30px',
                borderRadius: '18px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '2px 2px 3px #dedede',
                backgroundColor: '#555555',
                letterSpacing: '0.03em'
              }}
            >
              <p
                style={{
                  marginBlockStart: '0px',
                  marginBlockEnd: '0px',
                  fontFamily: "'Open Sans', sans-serif",
                  color: 'white',
                  fontSize: `${Scale.Height(Size, 17)}px`,
                  fontWeight: 600
                }}
              >
                {EditMode ? 'Save' : 'Append'}
              </p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Notes;
