// save portfolio info (mode, id, customer info)
export const savePortfolioState = (state) => {
  try {
    //set key as 'portfolioReducer'
    let portfolioReducer = { portfolioReducer: { ...state } };
    const serializedState = JSON.stringify(portfolioReducer);
    sessionStorage.setItem('portfolioReducer', serializedState);
  } catch {
    // ignore write errors
  }
};

// load portfolio info (mode, id, customer info)
export const loadPortfolioState = () => {
  try {
    const serializedState = sessionStorage.getItem('portfolioReducer');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};
