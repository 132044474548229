import React, { useState } from 'react';
import InputBox from '../InputBox';
import Button from '../../Button';
import styled from 'styled-components';
//import { urlFor } from '../../utils/sanityHelper';
import { customerValidate } from '../../../utils/validateHelper';
//import sortByKey, {sortStringLiteral} from '../../../utils/alphabetizer';
import FontUtil from '../../../utils/FontUtility';

const HoverDarkenP = styled.p`
  &:hover {
    filter: brightness(80%);
  }
`;
//font-family:${(props)=>FontUtil(props.chosenPortfolio.SystemText)}

const CustomerInfoForm = ({
  companyConfig,
  customerInfo,
  handleSubmit,
  submitText,
  Seed,
  images,
  chosenPortfolio
}) => {
  const [inputSelected, setInputSelected] = useState(undefined);

  //////onsole.log("INPUT SELECT CHANGED !e!", inputSelected);

  const initialForm = {
    firstName: '',
    lastName: '',
    company: '',
    email: '',
    ccEmails: []
  };
  const [formData, updateFormData] = useState({
    ...initialForm,
    ...customerInfo
  });
  const [isError, setIsError] = useState(false);

  // Update cc emails in array
  const udpateCCEmail = (index, email) => {
    // create new array with ccEmails content
    let newArr = [...formData.ccEmails];

    // update ccEmail at certain index
    newArr[index] = email;
    //newArr = sortStringLiteral(newArr)
    updateFormData({
      ...formData,
      ccEmails: newArr
    });
  };

  const addNewCCEmail = () => {
    let newArr = [...formData.ccEmails, ''];
    //newArr = sortStringLiteral(newArr)
    updateFormData({
      ...formData,
      ccEmails: newArr
    });
  };

  const handleChange = (e) => {
    // update formData
    //////////onsole.log(e.target.name);
    if (e.target.name !== 'company')
      updateFormData({
        ...formData,
        [e.target.name]: e.target.value.trim()
      });
    else
      updateFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
  };

  const beforeSubmit = () => {
    // validate
    let isValid = customerValidate(formData);
    if (isValid) {
      // valid do submit
      handleSubmit({ ...formData });
    } else {
      // show error
      setIsError(true);
    }
  };
  if (Seed) {
    //////onsole.log("!!SEED!!",Seed)
    let NArray = Seed.Name.split(' ');
    let E = Seed.Email;
    formData.email = E;
    if (NArray[0]) formData.firstName = NArray[0];
    else formData.firstName = 'FN';
    if (NArray[1]) formData.lastName = NArray[1];
    else formData.lastName = 'LN';
  }
  // if(Seed && customerValidate(formData)){
  //   //window.setTimeout(()=>{handleSubmit({...formData });},1)
  //   window.setTimeout(()=>{handleSubmit({...formData });},250)
  // }

  const closeFilter = (event) => {
    //////onsole.log("EVENT !e!", event.target);
    //////onsole.log("EVENT ID !e!", event.target.id);
    //////onsole.log("EVENT INPUT SELECTED !e!", inputSelected);
    if (event.target && event.target.id === inputSelected) {
      return;
    } else {
      //////onsole.log("ENDING SELECT !e!")
      closeActFilter();
    }
  };

  const closeActFilter = () => {
    //////onsole.log("!e! changing input selected to undefined");
    setInputSelected(undefined);
    window.removeEventListener('click', closeFilter);
  };

  const toggleFilter = (id) => {
    if (inputSelected !== id) {
      setInputSelected(id);
      //////onsole.log("SELECTED: ", id);
      window.setTimeout(() => {
        //////onsole.log("SETTING EVENT LISTENER")
        window.addEventListener('click', closeFilter);
      }, 50);
    } else {
      return;
    }
  };

  const inputArray = [
    {
      name: 'firstName',
      placeholder: 'First Name',
      value: formData.firstName
    },
    {
      name: 'lastName',
      placeholder: 'Last Name',
      value: formData.lastName
    },
    {
      name: 'company',
      placeholder: 'Company',
      value: formData.company
    },
    {
      name: 'email',
      placeholder: 'Email',
      value: formData.email
    }
  ];

  let inputMapping = inputArray.map((element, i) => {
    return (
      <div className="input-container" key={`k` + i}>
        <InputBox
          key={element.name}
          name={element.name}
          width="300px"
          height="32px"
          placeholder={element.placeholder}
          type="text"
          onChange={handleChange}
          value={element.value}
          isValid={!isError}
          required={true}
          style={{
            backgroundColor:
              element.name === inputSelected ? 'white' : '#e3e3e3',
            border: element.name === inputSelected ? '1px solid black' : 'none',
            fontFamily: FontUtil(chosenPortfolio.SystemText)
          }}
          id={element.name}
          onFocus={() => toggleFilter(element.name)}
          onClick={() => toggleFilter(element.name)}
        />
      </div>
    );
  });

  return (
    <>
      <form className="customer-info-form">
        {isError && (
          <div
            className="customer-form-error"
            style={{ fontFamily: FontUtil(chosenPortfolio.SystemText) }}
          >
            Error: Please make sure that forms are filled in correctly
          </div>
        )}
        {inputMapping}
        <p
          style={{ fontFamily: FontUtil(chosenPortfolio.SystemText) }}
          className="add-cc-email-text"
          onClick={addNewCCEmail}
        >
          Add Cc
        </p>
        <div className="cc-email">
          {formData.ccEmails.map((emails, index) => (
            // <HoverDarkenDiv key={'email-cc-' + index} style={{display:"flex", boxSizing:'border-box', padding:"2px", margin:"10px", marginTop:"0px", backgroundColor:"rgb(245,245,245)"}}>
            <div
              key={'email-cc-' + index}
              style={{
                display: 'flex',
                boxSizing: 'border-box',
                padding: '2px',
                margin: '10px',
                marginTop: '0px',
                backgroundColor: 'rgb(245,245,245)'
              }}
            >
              <InputBox
                key={'email-cc-' + index}
                name="ccemail"
                width="276px"
                height="28px"
                placeholder="Email"
                type="email"
                onChange={(e) => udpateCCEmail(index, e.target.value.trim())}
                //onChange={(e)=> {em}}
                value={formData.ccEmails[index]}
                style={{
                  borderTop: 'none',
                  borderRight: 'none',
                  borderLeft: 'none',
                  borderRadius: '0px',
                  margin: '1%',
                  borderColor: 'gray',
                  fontFamily: FontUtil(chosenPortfolio.SystemText)
                }}
              />
              <HoverDarkenP
                chosenPortfolio={chosenPortfolio}
                style={{
                  fontSize: '18px',
                  cursor: 'pointer',
                  alignSelf: 'center',
                  color: 'rgb(200,200,200)',
                  fontFamily: FontUtil(chosenPortfolio.SystemText)
                }}
                onClick={() => {
                  formData.ccEmails.splice(index, 1);
                  updateFormData({ ...formData });
                }}
              >
                X
              </HoverDarkenP>
            </div>
            //
            // </HoverDarkenDiv>
          ))}
        </div>
      </form>
      <Button
        text={submitText}
        height="50px"
        width="150px"
        onClick={beforeSubmit}
        disabled={false}
        style={{
          fontSize: '18px',
          position: 'absolute',
          bottom: '30%',
          backgroundColor: chosenPortfolio?.secondary_color
            ? chosenPortfolio.secondary_color
            : '#449FF5',
          color: chosenPortfolio?.text_color
            ? chosenPortfolio.text_color
            : 'white',
          fontWeight: 600,
          fontFamily: FontUtil(chosenPortfolio.SystemText)
        }}
      />
    </>
  );
};
/* <Button
backgroundColor={companyConfig.accent_color}
color={companyConfig.text_color}
text={submitText}
height="50px"
onClick={beforeSubmit}
disabled={false}
/> */
export default CustomerInfoForm;
