import React, { useEffect, useState } from 'react';
import SignUP from './SignUp-SentBars';
import Items from './ItemsSent';
import Emails from './EmailsSignUp';
//import EmailsDownload from './EmailsSignUp/EmailsDownload'
import DateSelect from '../../DateSelect/index.js';
import SoloCompany from '../../Body/GuestPass/Togglebar/CompanySolo';
// import Toggle from '../../Body/GuestPass/Togglebar'
import * as Scale from '../../../../utils/Scalars';
import { useFirestore } from 'react-redux-firebase';
import SessionSelect from '../../SessionSelect';
import FITDIV from '../../FillAvailableDiv/FillavailableDiv';
//import { setViewer } from '../../../../actions';

const Briefcase = ({
  Dash,
  userInfo,
  SetSessionMasterCollection,
  SetFilteredSession,
  SGPMC,
  SGPFC,
  SGPSMC,
  SFGPS,
  GuestPasses,
  selectedUser,
  Size
}) => {
  const firestore = useFirestore();

  useEffect(() => {
    //////////onsole.log("!!!DASHGP!!!",Dash.GuestpassMasterCollection)
    if (Dash.GuestpassMasterCollection === null) return;
    if (Dash.GuestpassMasterCollection.length === 0)
      SGPMC(selectedUser, firestore);
  }, [Dash.GuestpassMasterCollection, SGPMC, firestore, selectedUser]);

  //MasterCollection Population pattern
  useEffect(() => {
    //////////onsole.log("!!!DASHGPS!!!",Dash.GuestpassSessionMasterCollection)
    if (Dash.GuestpassSessionMasterCollection === null) return;
    if (Dash.GuestpassSessionMasterCollection.length === 0)
      SGPSMC(selectedUser, firestore);
  }, [Dash.GuestpassSessionMasterCollection, SGPSMC, firestore, selectedUser]);

  useEffect(() => {
    ////////onsole.log("!!!DASHGP2!!!",Dash.GuestpassSessionFilteredCollection)
    if (Dash.GuestpassSessionMasterCollection === null) return;
    // if(Dash.GuestpassSessionFilteredCollection === null)
    // return
    if (Dash.GuestpassSessionMasterCollection.length === 0) return;
    //else if(Dash.GuestpassSessionFilteredCollection?.length === 0){
    SFGPS(selectedUser);
    //}
  }, [Dash.GuestpassSessionMasterCollection, SFGPS, selectedUser]); //Dash.GuestpassSessionFilteredCollection

  useEffect(() => {
    if (Dash.GuestpassSessionMasterCollection?.length > 0) {
      //&& !Dash.GuestpassSessionFilteredCollection>0
      SFGPS(selectedUser);
    }
  }, [
    Dash.solo,
    Dash.selectedPortfolioId,
    Dash.views,
    Dash.DateRange,
    SFGPS,
    selectedUser,
    Dash.GuestpassSessionMasterCollection
  ]); // Dash.GuestpassSessionFilteredCollection
  //FilteredCollection Population pattern
  useEffect(() => {
    //////////onsole.log("!!!DASHGP2!!!",Dash.GuestpassFilteredCollection)
    if (Dash.GuestpassMasterCollection === null) return;
    // if(Dash.GuestpassFilteredCollection === null)
    //return
    // if(Dash.GuestpassMasterCollection.length === 0)
    // return;
    //if(Dash.GuestpassFilteredCollection.length === 0)
    else {
      SGPFC(selectedUser);
    }
  }, [
    Dash.GuestpassMasterCollection,
    SGPFC,
    selectedUser,
    Dash.solo,
    Dash.selectedPortfolioId,
    Dash.DateRange
  ]); //Dash.GuestpassFilteredCollection
  useEffect(() => {
    if (Dash.GuestpassMasterCollection === null) return;
    SGPFC(selectedUser);
  }, [
    Dash.solo,
    Dash.selectedPortfolioId,
    Dash.DateRange,
    Dash.GuestpassMasterCollection,
    SGPFC,
    selectedUser
  ]);
  const [BriefcaseGPFC, SetbriefcaseGPFC] = useState([]);
  useEffect(() => {
    if (Dash.defaultVisit) {
      //////onsole.log("DEFAULT VISIT", Dash.defaultVisit)
      let GPFC = [];
      Dash.GuestpassFilteredCollection.map((GP) => {
        if (
          !GP.is_portfolio_view &&
          GP.RootSessionID === Dash.defaultVisit.id
        ) {
          GPFC.push(GP);
        }
        return GP;
      });
      ////////onsole.log("BriefcaseGPFC",GPFC)
      SetbriefcaseGPFC(GPFC);
      return;
    }
    if (Dash.GuestpassFilteredCollection === null) return;
    if (Dash.GuestpassFilteredCollection.length === 0) SetbriefcaseGPFC([]);
    else {
      let GPFC = [];
      Dash.GuestpassFilteredCollection.map((GP) => {
        if (!GP.is_portfolio_view && GP.RootSessionID) {
          //GP.RootSessionID excludes data from before root session tracking for compatibility
          GPFC.push(GP);
        }
        return GP;
      });
      ////////onsole.log("BriefcaseGPFC",GPFC)
      SetbriefcaseGPFC(GPFC);
    }
  }, [Dash.GuestpassFilteredCollection, Dash.defaultVisit]);

  //MasterCollection Population pattern
  useEffect(() => {
    //////////onsole.log("!!!DASHS!!!",Dash.SessionMasterCollection)
    if (Dash.SessionMasterCollection === null) return;
    if (Dash.SessionMasterCollection.length === 0)
      SetSessionMasterCollection(selectedUser, firestore);
  }, [
    Dash.SessionMasterCollection,
    SetSessionMasterCollection,
    firestore,
    selectedUser
  ]);

  useEffect(() => {
    //////////onsole.log("!!!DASHGP2!!!",Dash.SessionFilteredCollection)
    if (Dash.SessionMasterCollection === null) {
      //////////onsole.log("MASTERLENGTH === null")
      return;
    }
    // if(Dash.SessionFilteredCollection === null){
    //return
    //}
    if (Dash.SessionMasterCollection.length === 0) {
      //////////onsole.log("MASTERLENGTH === 0")
      return;
    }
    // else if(Dash.SessionFilteredCollection.length === 0){
    SetFilteredSession(selectedUser);
    // }
    //////////onsole.log("!!!DASHGP2End!!!",Dash.SessionFilteredCollection)
  }, [Dash.SessionMasterCollection, SetFilteredSession, selectedUser]); //Dash.SessionFilteredCollection

  useEffect(() => {
    if (Dash.SessionMasterCollection === null) return;
    SetFilteredSession(selectedUser);
  }, [
    Dash.solo,
    Dash.selectedPortfolioId,
    Dash.DateRange,
    Dash.SessionMasterCollection,
    SetFilteredSession,
    selectedUser
  ]);

  const [Briefcase, Setbriefcase] = useState([]);
  //const [Viewers,SetViewers] = useState([]);
  const [GPIDs, SetGPIDs] = useState([]);
  useEffect(() => {
    if (!Dash.SessionFilteredCollection) return;
    let Holder = [];
    let VHold = [];
    let GPIDHold = [];
    ////////onsole.log("!!!!INSPECTION OF BRIEFCASE BASE DATA!!!!!",Dash.GuestpassSessionFilteredCollection)
    Dash.SessionFilteredCollection.map((ele) => {
      if (!ele.briefcase) return ele;
      ////////onsole.log("!!!!INSPECTION OF BRIEFCASE BASE DATA!!!!!",ele)
      //////////onsole.log(ele);
      ////////onsole.log("GPID",ele)
      if (ele.Sentlinks) {
        ////////onsole.log("GPID",ele)
        GPIDHold.push(...ele.Sentlinks);
      }
      ele.viewer_log.map((ele) => {
        VHold.push(ele.email);
        return ele;
      });
      ele.briefcase.map((sent) => {
        Holder.push(sent);
        //Setbriefcase([...Briefcase, sent])
        return ele;
      });
      return ele;
    });
    SetGPIDs(GPIDHold);
    //SetViewers(VHold);
    Setbriefcase(Holder);
  }, [
    Setbriefcase,
    Dash.SessionFilteredCollection,
    Dash.GuestpassSessionFilteredCollection
  ]);
  const [PagesArray, SetPagesArray] = useState({});
  const [SessionFIDs, SetSessionFIDs] = useState([]);
  useEffect(() => {
    ////////onsole.log("!!!GPF!!!",Dash.GuestpassFilteredCollection)
    let TempHolding = [];
    ////////onsole.log("GPIDs", GPIDs);
    Dash.GuestpassFilteredCollection.map((ele, I) => {
      if (
        GPIDs.some((ele2) => {
          return ele.id === ele2;
        }) &&
        !ele.is_portfolio_view
      ) {
        TempHolding.push(ele.id);
        //////onsole.log("TH2",ele);
        //////onsole.log("TH", TempHolding);
      }
      return ele;
    });
    ////////onsole.log("SessionFIDs", TempHolding);
    SetSessionFIDs(TempHolding);
  }, [Dash.GuestpassFilteredCollection, GPIDs]);
  const [EndEmailStruct, SetEndEmailStruct] = useState([]);
  useEffect(() => {
    let TempHold = [];
    if (Dash.GuestpassSessionFilteredCollection !== null) {
      //////onsole.log("!!!INSPECTGPSFC!!!",Dash.GuestpassSessionFilteredCollection)
      Dash.GuestpassSessionFilteredCollection.map((ele, I) => {
        if (
          SessionFIDs.some((ele2) => {
            return ele2 === ele.gp_id;
          })
        ) {
          TempHold.push({
            Email: ele.gp_email.trim().toLowerCase(),
            Name: ele.gp_name.trim().toLowerCase()
          });
          ////////onsole.log()
        }
        return ele;
      });
      let TempData = [];
      TempHold.forEach((val, i) => {
        if (TempData.length === 0) {
          TempData.push({
            Email: val.Email.trim().toLowerCase(),
            Opens: 1,
            New: true,
            Name: val.Name.trim().toLowerCase()
          });
        } else if (
          TempData.some((ele) => {
            return (
              ele.Email.trim().toLowerCase() === val.Email.trim().toLowerCase()
            );
          })
        ) {
          //////onsole.log("+1")
          TempData[
            TempData.findIndex((ele) => {
              return (
                ele.Email.trim().toLowerCase() ===
                val.Email.trim().toLowerCase()
              );
            })
          ].Opens += 1;
          TempData[
            TempData.findIndex((ele) => {
              return (
                ele.Email.trim().toLowerCase() ===
                val.Email.trim().toLowerCase()
              );
            })
          ].Name = val.Name;
        } else {
          //////onsole.log("TD",TempHold);
          TempData.push({
            Email: val.Email.trim().toLowerCase(),
            Opens: 1,
            New: true,
            Name: val.Name.trim().toLowerCase()
          });
          //////onsole.log("TD",TempData);
        }
      });
      SetEndEmailStruct(TempData);
    } else {
      SetEndEmailStruct([]);
    }
  }, [Dash.GuestpassSessionFilteredCollection, SessionFIDs]);

  useEffect(() => {
    if (Dash.defaultVisit) {
      let AA = {};
      let Holder = [];
      if (Dash.defaultVisit.briefcase) {
        Dash.defaultVisit.briefcase.map((sent) => {
          Holder.push(sent);
          //Setbriefcase([...Briefcase, sent])
          return sent;
        });
        Holder.map((pagesContainers) => {
          //////////onsole.log("!!!PageContainers!!!",pagesContainers)
          pagesContainers.pages.map((pages) => {
            AA[pages.page_id]
              ? (AA[pages.page_id].Count += 1)
              : (AA[pages.page_id] = { Count: 1, Name: pages.title });
            return pages;
          });

          return pagesContainers;
        });
        SetPagesArray(AA);
      }
      return;
    }
    if (!Briefcase) return;
    let AA = {};
    ////////onsole.log("!!!!INSPECTION OF BRIEFCASE BASE DATA!!!!!",Briefcase)
    //////////onsole.log("!!!!BRIEFCASE!!!!",Briefcase)
    Briefcase.map((pagesContainers) => {
      //////////onsole.log("!!!PageContainers!!!",pagesContainers)
      pagesContainers.pages.map((pages) => {
        AA[pages.page_id]
          ? (AA[pages.page_id].Count += 1)
          : (AA[pages.page_id] = { Count: 1, Name: pages.title });
        return pages;
      });

      return pagesContainers;
    });
    SetPagesArray(AA);
  }, [Briefcase, Dash.defaultVisit]);
  useEffect(() => {
    //////////onsole.log("PAGESARRAY",PagesArray)
  }, [PagesArray]);
  const [SendOpen, setSendOpen] = useState({ send: 0, open: 0, Addresses: [] });
  useEffect(() => {
    //////onsole.log("!!!!INSPECTION OF BRIEFCASE BASE DATA!!!!!",BriefcaseGPFC)
    let sendopen = { send: 0, open: 0, Addresses: [] };
    BriefcaseGPFC.map((ele) => {
      sendopen.send += ele.viewer_log?.length;
      if (ele.viewer_log)
        ele.viewer_log.map((ele) => {
          if (
            sendopen.Addresses.some((address) => {
              return (
                address.trim().toLowerCase() === ele.email.trim().toLowerCase()
              );
            })
          )
            return ele;
          else sendopen.Addresses.push(ele.email);
          return ele;
        });
      if (ele.Views) {
        sendopen.open += ele.Views.length;
        ele.Views.map((ele) => {
          if (
            sendopen.Addresses.some((address) => {
              return (
                address.trim().toLowerCase() === ele.Email.trim().toLowerCase()
              );
            })
          )
            return ele;
          else {
            if (!sendopen.NE) sendopen.NE = [];
            sendopen.NE.push({ Email: ele.Email, Name: ele.Name });
            sendopen.Addresses.push(ele.Email);
          }
          return ele;
        });
      }
      return ele;
    });
    setSendOpen(sendopen);
  }, [BriefcaseGPFC, Dash.defaultVisit]);
  const [EmailsEnd, SetEmailsEnd] = useState([]);
  useEffect(() => {
    ////////onsole.log("!!!EES!!!",EndEmailStruct)
    //////onsole.log("!!!BriefcaseGPFC!!!",BriefcaseGPFC)
    //////onsole.log("!!!SendOpen!!!",SendOpen)
    let TempEES = [...EndEmailStruct];
    SendOpen.Addresses.forEach((val) => {
      if (
        TempEES.some((ele) => {
          return ele.Email.trim().toLowerCase() === val.trim().toLowerCase();
        })
      )
        return;
      else TempEES.push({ Email: val, Opens: 0, New: true });
    });
    ////////onsole.log("!!!EEE!!!", TempEES);
    BriefcaseGPFC.forEach((ele) => {
      if (ele.viewer_log) {
        ele.viewer_log.forEach((val) => {
          ////////onsole.log("!!!EEE!!!",val);
          if (
            TempEES.findIndex((ele2) => {
              return (
                ele2.Email.trim().toLowerCase() ===
                val.email.trim().toLowerCase()
              );
            }) > -1
          ) {
            ////////onsole.log("!!!EEE!!!",TempEES.findIndex((ele2)=>{return ele2.Email.trim().toLowerCase() === val.email.trim().toLowerCase()}))
            TempEES[
              TempEES.findIndex((ele2) => {
                return (
                  ele2.Email.trim().toLowerCase() ===
                  val.email.trim().toLowerCase()
                );
              })
            ].New = false;
            //////onsole.log("!!!ELE!!!", val)
            if (val.name)
              TempEES[
                TempEES.findIndex((ele2) => {
                  return (
                    ele2.Email.trim().toLowerCase() ===
                    val.email.trim().toLowerCase()
                  );
                })
              ].Name = val.name;
            //TempEES[TempEES.findIndex((ele2)=>{return ele2.Email.trim().toLowerCase() === val.email.trim().toLowerCase()})].Name =
          }
        });
      }
    });
    TempEES.forEach((ele) => {
      if (ele.Name) return;
      else {
        if (
          SendOpen?.NE?.find((ele2) => {
            return (
              ele2.Email.trim().toLowerCase() === ele.Email.trim().toLowerCase()
            );
          })
        )
          ele.Name = SendOpen.NE.find((ele2) => {
            return (
              ele2.Email.trim().toLowerCase() === ele.Email.trim().toLowerCase()
            );
          }).Name;
      }
    });
    //////onsole.log("!!!EEE!!!", TempEES);
    SetEmailsEnd(TempEES);
  }, [EndEmailStruct, SendOpen, BriefcaseGPFC]);
  //25fr 3fr 20fr 3fr 20fr
  //gridTemplateColumns:"14% 1% 24% 1% 24% 1% 24% 11% ",

  //DateRange/SoloUpdateProvocation
  useEffect(() => {
    if (Dash.GuestpassSessionMasterCollection) SFGPS(selectedUser);
    //SFS(selectedUser);
    if (Dash.GuestpassMasterCollection) SGPFC(selectedUser);
  }, [
    Dash.solo,
    Dash.GuestpassMasterCollection,
    Dash.selectedPortfolioId,
    Dash.GuestpassSessionMasterCollection,
    Dash.DateRange,
    selectedUser,
    SGPFC,
    SFGPS
  ]);
  const [barHeight, SetBarHeight] = useState('0px');

  return (
    <FITDIV
      style={{
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: `${Scale.Height(Size, 36)}px`,
        paddingTop: `${Scale.Height(Size, 24)}px`
      }}
    >
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
          gap: '20px'
        }}
      >
        <div
          style={{
            gridColumn: '1 / span 1',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center'
          }}
        >
          <SessionSelect positioning={310} />
        </div>
        <div
          style={{
            gridColumn: '2 / span 1',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center'
          }}
        >
          {/* <Toggle NotSize DateCompany></Toggle> */}
          <DateSelect />
        </div>
        <div
          style={{
            gridColumn: '3 / span 1',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center'
          }}
        >
          {/* <Toggle NotSize DateCompany></Toggle> */}
          <SoloCompany
            style={{
              width: `${Scale.Width(Size, 125)}px`,
              height: `${Scale.Width(Size, 30)}px`
            }}
          />
        </div>
      </div>
      <div style={{ height: `${Scale.Height(Size, 90)}px` }}></div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '25fr 3fr 20fr 3fr 20fr',
          height: `${Scale.Height(Size, 512)}px`,
          gridTemplateRows: '1fr'
        }}
      >
        {/* <div style={{display:"flex", flexDirection:"row", height:"100%", alignItems:"center", flexBasis:"30%", justifyContent:"space-between"}}> */}

        <SignUP
          Size={Size}
          style={{ gridColumn: '1/2', gridRow: '1/1' }}
          SetBarHeight={SetBarHeight}
          SendOpen={SendOpen}
        ></SignUP>

        <div
          style={{
            gridColumn: '3/4',
            gridRow: '1/1',
            maxHeight: '100%',
            height: '100%',
            boxShadow: '8px 8px 10px #808080',
            borderRadius: '8px'
          }}
        >
          <Items
            Size={Size}
            barHeight={barHeight}
            PagesArray={PagesArray}
          ></Items>
        </div>
        <div
          style={{
            gridColumn: '5/6',
            gridRow: '1/1',
            height: '100%',
            maxHeight: '100%',
            boxShadow: '8px 8px 10px #808080',
            borderRadius: '8px'
          }}
        >
          <Emails
            Size={Size}
            barHeight={barHeight}
            EmailsEnd={EmailsEnd}
            SendOpen={SendOpen}
          ></Emails>
          {/* <EmailsDownload></EmailsDownload> */}
        </div>
      </div>
    </FITDIV>
  );
};
export default Briefcase;

//defaultVisit
