import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import Icons from '../Icons';
import Button from '../../components/Button';
import CustomerInfoForm from '../CustomerInfoForm';
import Modal from '../Modal';
import Like from '../../utils/Likes';

const PortfolioActionMenu = ({
  setMenuHide,
  currentContent,
  userInfo,
  mode,
  sessionId,
  showBriefcase,
  briefcaseContent,
  isModalOpen,
  chosenPortfolio,
  companyConfig,
  isGuestMode,
  currentGuestContentHelper,
  liked,
  toggleBriefcase,
  addToBriefcase,
  endPortfolio,
  setPortfolioCustomerInfo,
  showModal,
  hideModal,
  toggleNotepad,
  toggleScriptnote,
  setLiked,
  updateSessionInSanity,
  clearAll
}) => {
  const [isAddable, setIsAddable] = useState(true); // bool to keep track of if it is addable to briefcase
  const [isAuthToAdd, setIsAuthToAdd] = useState(false); // is this user authorized to add item?
  const [isAuthBriefcaseShow, setIsAuthBriefcaseShow] = useState(false); // is this user authorized to see briefcase?
  const history = useHistory();
  const firebase = useFirebase();
  const firestore = useFirestore();

  // check each time chosenContent or briefcaseContent updates
  useEffect(() => {
    // check if briefcase contains current content user is looking at
    if (
      (Object.entries(currentContent).length !== 0 &&
        briefcaseContent.filter((e) => e.id === currentContent.id).length >
          0) ||
      currentContent.page_id === `splashkey-${chosenPortfolio._id}`
    ) {
      setIsAddable(false);
    } else {
      setIsAddable(true);
    }
  }, [currentContent, briefcaseContent, chosenPortfolio]);

  // get userinfo auth for viewing sending briefcase
  useEffect(() => {
    if (userInfo && userInfo.length > 0) {
      // TODO: Why is this constant condition?
      // check if user is able to create guest pass
      if (true) {
        // userInfo[0].is_able_to_create_briefcase
        // user is able to create normal guest pass, aka able to add item to briefcase
        setIsAuthToAdd(true);
      }
      // check if user is able create portfolio level guest pass
      if (true) {
        // userInfo[0].is_able_to_create_guest_pass
        // user is able to create normal guest pass, aka able to add item to briefcase
        setIsAuthBriefcaseShow(true);
      }
    }
  }, [userInfo]);

  const endSession = () => {
    endPortfolio();
    history.push('/engage');
  };

  const logout = () => {
    //////////onsole.log('Guest user logout');
    firebase.logout();
    // clear sessionkey in local storage and redux session key
    //updateSessionKey('');
    //clear all state
    clearAll();
  };

  const onExit = () => {
    if (sessionId === '') {
      endSession();
    } else {
      // create page visit info obj
      const pageVisitInfo = {
        is_end: true,
        time_visited: firestore.Timestamp.now()
      };
      firestore
        .collection('sessions')
        .doc(sessionId)
        .update({
          page_visited: firestore.FieldValue.arrayUnion(pageVisitInfo)
        })
        .then((e) => {
          // success
          //////////onsole.log('Firestore set/update success');
          // if guest mode logout
          // check guest mode
          if (isGuestMode) {
            logout();

            // open url if exists
            if (
              chosenPortfolio.end_demo_url &&
              chosenPortfolio.end_demo_url !== ''
            ) {
              window.open(chosenPortfolio.end_demo_url, '_self');
            }
            return;
          } else {
            // end session for user
            endSession();
          }
        })
        .catch((error) => {
          console.error(error);
          endSession();
        });
    }
  };

  // Add page visit to session, for firestore
  const updateFavorite = (op) => {
    Like(
      op,
      isGuestMode,
      sessionId,
      liked,
      currentGuestContentHelper,
      currentContent,
      chosenPortfolio,
      (m) => {},
      setLiked,
      firestore
    );
    // // do not log if mode is not guest or no sessionId
    // if (!isGuestMode || sessionId === '') {
    //   return null;
    // }

    // // copy liked from redux store
    // let likedArrCopy = [...liked];

    // let id = currentGuestContentHelper.id;
    // let content = {...currentContent,time_liked:firestore.Timestamp.now()};
    // // check if currentGuestContentHelper id is undefined
    // if (!id) {
    //   //////////onsole.log('like in full portfolio guest-pass view...');
    //   id = currentContent.id;
    //   // if undefined it means that guest is in full portfolio view and not in briefcase guest view
    // } else {
    //   // user in briefcase guest view
    //   //////////onsole.log('like in Briefcase guest view...');
    //   // set content from currentGuestContentHelper, if not splash
    //   if (id !== `splashkey-${chosenPortfolio._id}`) {
    //     content = {...currentGuestContentHelper,time_liked:firestore.Timestamp.now()};
    //   }
    // }

    // // if op is add then append currentContent obj to likedArrCopy,
    // if (op === 'add') {
    //   likedArrCopy.push(content);
    // } else {
    //   // if not filter out
    //   likedArrCopy = likedArrCopy.filter((obj) => obj.id !== id);
    // }

    // // replace the liked array
    // firestore
    //   .collection('sessions')
    //   .doc(sessionId)
    //   .update({
    //     liked: likedArrCopy
    //   })
    //   .then(() => {
    //     // replace liked in store
    //     setLiked(likedArrCopy);
    //   })
    //   .catch((error) => console.error('ERROR: ', error));
  };

  // check if current page is liked already or not
  const isLiked = () => {
    let currentGuestContentId = currentGuestContentHelper.id;
    // check if currentGuestContentHelper id is undefined
    if (!currentGuestContentId) {
      //////////onsole.log('Liked check in full portfolio guest-pass view...');
      // check if liked contains currentContent id
      return liked.some((obj) => obj.id === currentContent.id);
    } else {
      // user in briefcase guest view
      //////////onsole.log('Liked check in Briefcase guest view...');
      if (currentGuestContentId === `splashkey-${chosenPortfolio._id}`) {
        // check if liked contains currentContent id for splash
        return liked.some((obj) => obj.id === currentContent.id);
      } else {
        // check if liked contains currentGuestContentId
        return liked.some((obj) => obj.id === currentGuestContentId);
      }
    }
  };

  const updateCustomerInfo = (form) => {
    // update customer info in store
    setPortfolioCustomerInfo(form);

    if (sessionId === '') {
      return null;
    }

    const customerReplacement = {
      customer_name: form.firstName + ' ' + form.lastName,
      customer_email: form.email,
      customer_company: form.company
    };
    let viewerLog = [];
    let viewerTemplate = {
      _type: 'viewerLog'
    };

    // add main customer
    viewerLog.push({
      ...viewerTemplate,
      email: customerReplacement.customer_email,
      name: customerReplacement.customer_name,
      _key: Math.random().toString(36).substr(2, 7)
    });

    // add cc
    if (form.ccEmails) {
      form.ccEmails.forEach((email) => {
        viewerLog.push({
          ...viewerTemplate,
          email: email,
          _key: Math.random().toString(36).substr(2, 7)
        });
      });
    }

    // add viewer log to customerReplacement.
    customerReplacement['viewer_log'] = viewerLog;

    // update customer info in sanity session and firestore
    firestore
      .collection('sessions')
      .doc(sessionId)
      .update(customerReplacement)
      .then(() => {
        // update viewer log info in sanity
        updateSessionInSanity({ viewer_log: customerReplacement.viewer_log });
        hideModal();
      })
      .catch((error) => {
        console.error('ERROR: ', error);
        hideModal();
      });
  };
  return (
    <>
      <div className="portfolio-action-menu">
        {isModalOpen && (
          <Modal>
            <CustomerInfoForm
              handleSubmit={updateCustomerInfo}
              submitText="Update"
            />
          </Modal>
        )}
      </div>
      {/* className="action-menu-container" */}
      <div className="portfolio-action-menu">
        <div className="action-menu-container">
          <Fragment>
            {!isGuestMode ? (
              <Fragment>
                <div className="row-first">
                  {isAddable && isAuthToAdd && (
                    <Icons.AddShoppingCart
                      onClick={() => {
                        addToBriefcase(currentContent);
                        if (!showBriefcase) {
                          toggleBriefcase();
                        }
                      }}
                    />
                  )}
                  {(isAuthToAdd || isAuthBriefcaseShow) && (
                    <Icons.ShoppingCart
                      onClick={toggleBriefcase}
                      data-tip="Show Briefcase"
                    />
                  )}
                </div>
                <div className="row-second">
                  <Icons.PersonAdd onClick={showModal} />
                  <Icons.Fullscreen onClick={() => setMenuHide(true)} />
                </div>
                <div className="row-third">
                  <Icons.NoteAdd onClick={toggleNotepad} />
                  {mode === 'training' && (
                    <Icons.SpeakerNotes onClick={toggleScriptnote} />
                  )}
                </div>
              </Fragment>
            ) : (
              <div className="row-first">
                {isLiked() ? (
                  <Icons.Favorite
                    onClick={() => {
                      updateFavorite('remove');
                    }}
                  />
                ) : (
                  <Icons.FavoriteBorder
                    onClick={() => {
                      updateFavorite('add');
                    }}
                  />
                )}
              </div>
            )}
          </Fragment>
          <Button
            additionalClassName="exit-button"
            width="100%"
            height="50px"
            backgroundColor={
              chosenPortfolio.accent_color
                ? chosenPortfolio.accent_color
                : companyConfig.accent_color
            }
            color={
              chosenPortfolio.text_color
                ? chosenPortfolio.text_color
                : companyConfig.text_color
            }
            text="End Demo"
            onClick={onExit}
          />
        </div>
      </div>
    </>
  );
};

export default PortfolioActionMenu;
