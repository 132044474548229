// email valid check
export const emailIsValid = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const userDataValidate = (data) => {
  if (!data.name || data.name === '') {
    console.error('Missing name');
    return false;
  }
  if (!emailIsValid(data.email)) {
    console.error('Missing valid email');
    return false;
  }
  /*
  if (!data.isAbleToCreateBriefcase) {
    console.error('Missing isAbleToCreateBriefcase');
    return false;
  }
  if (!data.isAbleToCreateGuestPass) {
    console.error('Missing isAbleToCreateGuestPass');
    return false;
  }
*/
  return true;
};

export const teamDataValidate = (data, teams) => {
  if (!data.name || data.name === '') {
    console.error('Missing team name');
    return false;
  }
  let teamNameTaken = false;

  teamNameTaken = teams.some((obj) => {
    let sameName =
      obj.name.trim().localeCompare(data.name.trim(), undefined, {
        sensitivity: 'accent'
      }) === 0;

    // skip same id
    if (data.id === obj.id && sameName) {
      //////////onsole.log('......false');
      return false;
    }
    return sameName;
  });

  if (teamNameTaken) {
    console.error('Team name taken');
    // team taken, not valid
    return false;
  }

  return true;
};

// customer info validate
export const customerValidate = (formData) => {
  if (formData.firstName === '') {
    console.error('Missing first name');
    return false;
  }
  if (formData.lastName === '') {
    console.error('Missing first name');
    return false;
  }
  if (!emailIsValid(formData.email)) {
    console.error('Missing valid email');
    return false;
  }
  return true;
};
