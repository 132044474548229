import { React } from 'react';
import styled from 'styled-components';
const HoverCircle = styled.div`
  color: #808080;
  :hover {
    color: #ffffff;
  }
  transition: all 0.2s;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  svg {
    position: absolute;
    top: 0px;
    left: 0px;
  }
  justify-content: center;
  align-items: center;
  text-align: center;
  p {
    height: auto;
    width: 50%;
    max-height: 50%;
    overflow: auto;
    font-size: 1.25em;
  }
`;
const CloseIcon = ({ Text, Fill, Rotation, onClick, className, id }) => {
  Fill = '#c1c0c0';
  return (
    <HoverCircle>
      <svg
        className={className}
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
        width={'100%'}
        height={'100%'}
        style={{ transform: `rotate(${Rotation}deg)` }}
      >
        <circle
          cx={50}
          cy={50}
          r={45}
          fill={'none'}
          stroke="currentColor"
          strokeWidth={5}
        ></circle>
        {/*  filter='blur(2px)' */}
      </svg>
      <p>{Text}</p>
    </HoverCircle>
  );
};
export default CloseIcon;
