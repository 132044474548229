/* 
// Shows menus as nested
*/
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useStore } from 'react-redux';
//import Button from '../../../components/Button';
import MenuSection from '../../MenuSectionT9';
import useTemplate from '../../../components/_Portfolio/PortfolioMenu/Helper/TemplateHelper';
//import Cover from '../../../components/Theme9'
import { urlFor } from '../../../utils/sanityHelper';
import CloseIcon from './CloseSVG';
import '../../../assets/images/arrowLeft.png';
import Scrollindicator from './icon-Scroll-Drag-Right.png';
import exit from './Exclude.png';
// import { TransitionGroup } from 'react-transition-group';
//import Icons from '../../Icons';
//import SubmenuItems from './SubmenuItem/index'
import SubMenuController from './SubMenuItemController';
//import TMG from './TerminalMenuGallery/index'
//import TMG2 from './TerminalMenuGalleryB'
//import TMG3 from './TerminalMenuGalleryC'
// import ToolsIcon from './icon-tools.png'
import IconMenu from './icon-menu.png';
// import Tools from '../../../components/_Portfolio/PortfolioTheme9/ActionMenuToolbar/ActionMenuToolbar'
// import Tools2 from '../../../components/_Portfolio/PortfolioTheme9/BottomLeftToolbar2'
import TMGC from './TMGController';
import SingleItemDisplay from './SingleItemDisplay';
// import Mega from '../../../components/MasterMenu'
import _ from 'lodash';
// import zIndex from '@material-ui/core/styles/zIndex';

const Menu9 = (props) => {
  const {
    companyConfig,
    images,
    pages,
    setChosenMainPageButtonId,
    setChosenPageId,
    setIsAtEnd,
    setCurrentLevel,
    nav,
    //isGuestMode,
    setContentPageId,
    chosenContentPageId,
    chosenPageId,
    setTopic,
    Topic,
    chosenPortfolio,
    setNavHistory,
    setSavedContentPageId,
    createSetCurrentContent,
    //prepareCurrentGalleryContent,
    shareClick,
    sendClick,
    Size,
    menuButtonClicked,
    currentContent
    // Size
  } = props;
  ////////onsole.log("MENU", createSetCurrentContent)
  const { isAtEnd, menuHide, navHistory } = props.pageMenuReducer; //currentLevel
  const { portfolio_LogoResize } = chosenPortfolio;
  ////////onsole.log("MENUPORT",portfolio_LogoResize)
  // portfolio_LogoResize
  // get default functions used from hook
  const { getActiveString, getIsAtEndString } = useTemplate(props);
  //const [currentNavItem, setCurrentNavItem] = useState({});
  //const [prevId, setPrevId] = useState(''); // keep track of previous page id for back button
  const [LevelOfTerm, setLevelOfTerm] = useState(0); // keep track of previous page id for back button
  const [prevLevel, setPrevLevel] = useState(''); // keep track of previous page id for back button
  // const [prevHasChild, setPrevHasChild] = useState(''); // keep track of previous page id for back button
  // const [prevTitle, setPrevTitle] = useState(''); // keep track of previous page id for back button
  // const [prevNavIndex, setPrevNavIndex] = useState(''); // keep track of previous page id for back button
  // const [prevPrevNavIndex, setPrevPrevNavIndex] = useState(''); // keep track of previous page id for back button
  const [isInNested, setIsInNested] = useState(false); // keep track if user is in nested layer

  //useEffect(()=>{})
  const TermRef = useRef(null);
  //let [GripLoc, SetGripLoc] = useState(0);
  const [Color, SetColor] = React.useState(undefined);
  const store = useStore();
  const Trial = store.getState();
  //onsole.log("Store",store.getState().portfolioReducer.chosenPortfolio.bulk_icon_override)
  useEffect(() => {
    SetColor(
      store.getState().portfolioReducer.chosenPortfolio.bulk_icon_override
    );
  }, [store, Trial.portfolioReducer.chosenPortfolio.bulk_icon_override]);

  useEffect(() => {
    if (isAtEnd) {
      setLevelOfTerm(prevLevel + 1);
    }
  }, [isAtEnd, prevLevel]);

  useEffect(() => {
    let splashKeyId = `splashkey-${chosenPortfolio._id}`;
    if (chosenPageId !== 0 && chosenPageId !== splashKeyId) {
      // get current nav item
      let tempNavItem = {};

      // traverse nav history to find current nav
      navHistory.forEach((number, index) => {
        // first
        if (index === 0) {
          tempNavItem = nav?.items[number];
        } else {
          tempNavItem = tempNavItem.children[number];
        }
      });
      //setCurrentNavItem(tempNavItem);

      // if tempNavItem.referenceId is undefined then set content to chosenContentPageId
      if (!tempNavItem.referenceId) {
        setContentPageId(chosenContentPageId);
      } else {
        setContentPageId(tempNavItem.referenceId);
      }
      if (chosenContentPageId) {
        setSavedContentPageId(chosenContentPageId);
      }
    }
    if (chosenPageId === splashKeyId) {
      // create nav specifically for splash
      // setCurrentNavItem({
      //   children: [],
      //   referenceField: 'pages',
      //   referenceId: splashKeyId,
      //   title: 'Splash',
      //   type: 'gallery',
      //   _key: 'splashnav'
      // });
      setContentPageId(splashKeyId);
      if (chosenContentPageId) {
        setSavedContentPageId(chosenContentPageId);
      }
      setPageIdAndLevel('', 0);
      setIsAtEnd(false);
    }
    // eslint-disable-next-line
  }, [chosenPageId, pages, navHistory, chosenPortfolio]);

  // back button function
  const goBack = () => {
    BackTopicArray();
    let temp = navHistory;
    //////onsole.log("TR",temp);
    temp.pop();
    //////onsole.log("TR",temp);
    let TR;
    let x = 1;
    //////onsole.log("TR",nav,temp,temp[0])
    TR = nav?.items[temp[0]];
    //////onsole.log("TR",TR);
    while (x < temp.length) {
      //////onsole.log("WHILE")
      TR = TR.children[temp[x]];
      x++;
    }
    //////onsole.log("TR",TR);
    setNavHistory(navHistory.filter((_, i) => i !== navHistory.length - 1));
    onClick(
      TR.referenceId,
      temp.length - 1,
      TR.children?.length > 0,
      TR.children?.length > 0,
      temp[temp.length - 1]
    );
    //setPageIdAndLevel(TR.referenceId, temp.length-1 );
    setIsAtEnd(false);
    setTMGD(false);
    setSingleGall(false);
  };
  // const goBack = () => {
  //   BackTopicArray()
  //   //////onsole.log('PrevId', prevId);
  //   if (prevId !== '') {
  //     // initiate menu button click for prevId
  //     setPageIdAndLevel(prevId, currentLevel);
  //   } else {
  //     // empty prevId
  //     setPageIdAndLevel('', 0);
  //   }
  //   setIsAtEnd(false);
  //   setTMGD(false)
  //   //const onClick = (id, level, hasChild, isNotEnd, navIndex) => {
  //   //   setNavHistory(navHistory.filter((_, i) => i !== navHistory.length - 1));
  //   //   setNavHistory(navHistory.filter((_, i) => i !== navHistory.length - 1));
  //   // onClick(pages[navHistory[navHistory.length-1]],prevLevel -1,true,false,prevPrevNavIndex)
  //   // remove last item from navHistory
  //   //////onsole.log('NAV', navHistory);
  //   setNavHistory(navHistory.filter((_, i) => i !== navHistory.length - 1));
  // };
  const BackTopicArray = () => {
    let topicalarray = [];
    try {
      topicalarray = [...Topic];
    } catch (err) {
      console.error('Error', err);
    }
    //////onsole.log("BackArray1",topicalarray)
    topicalarray.splice(Topic.length - 1, 111111);
    //////onsole.log("BackArray2",topicalarray)
    setTopic(topicalarray);
  };
  const SetTopicArray = (title, level, id) => {
    let topicalarray = [];
    try {
      topicalarray = [...Topic];
    } catch (err) {
      console.error('Error', err);
    }
    if (!isAtEnd || level === 0) {
      topicalarray.splice(level, 11111, { title: title, id: id });
      setTopic(topicalarray);
    } else {
      topicalarray.splice(LevelOfTerm, 11111, { title: title, id: id });
      setTopic(topicalarray);
    }
  };

  const UNACTIVEEVENT = new Event('UNACTIVEEVENT');
  // on page button click
  const onClick = (id, level, hasChild, isNotEnd, navIndex) => {
    dispatchEvent(UNACTIVEEVENT);
    //////onsole.log('OnClick: ', id);
    //////onsole.log('Setting: ', id);
    //////onsole.log('Setting: ', nav);
    menuButtonClicked(id);
    setPrevLevel(level);

    setPageIdAndLevel(id, level);

    // copy navhistroy
    let navHistoryCopy = [...navHistory];
    // first nav history, so parent button
    if (navHistoryCopy.length === 0) {
      navHistoryCopy.push(navIndex);
    } else {
      // check if need to replace at same level
      if (level <= navHistoryCopy.length - 1) {
        //////////onsole.log('REPLACE');
        navHistoryCopy[level] = navIndex;
        // remove level higher than replaced one
        // ex if user has history [2,1,1,5] and clicked on index 3 at parent (level 0)
        // then replace  it [3,1,1,5] and remove history of any other level, so it will be [3]
        // NOTE slice caused issue, using filter for now
        navHistoryCopy = navHistoryCopy.filter((_, i) => i <= level);
      } else {
        // if not append
        navHistoryCopy.push(navIndex);
      }
    }

    // set/replace navHistory
    setNavHistory(navHistoryCopy);

    // check if clicked content contains child
    if (hasChild) {
      let b = !isNotEnd;
      if (b) {
        //////////onsole.log('Term', TermRef.current);
        //TermRef.current.style.opacity = 0;
        setTimeout(function () {
          //TermRef.current.style.opacity = 0;
        }, 15);
        setTimeout(function () {
          setIsAtEnd(true);
          //TermRef.current.style.opacity = 1;
        }, 500);
      } else setIsAtEnd(b);
      // User is in nested so set isInNested to true
      setIsInNested(true);
      // set isAtEnd

      if (!b) {
        // NOTE need to wait till CMS update with giving back first child as intro
        ////////////onsole.log("!!!!PREV!!!! PREV ID BEFORE: ", prevId);
        //setPrevId(id);
        ////////////onsole.log("!!!!PREV!!!! PREV ID AFTER: ", prevId);
      } else {
        //if at end the nested ended so set isInNested to false
        setIsInNested(false);
      }
    } else {
      //TermRef.current.style.opacity = 0;
      setTimeout(function () {
        setIsAtEnd(true);
        //TermRef.current.style.opacity = 1;
      }, 500);
      // setIsAtEnd(true);
      if (!isInNested) {
        // if not in nested set prev id
        ////////////onsole.log("!!!!PREV!!!! PREV ID BEFORE: ", prevId);
        //setPrevId(id);
        ////////////onsole.log("!!!!PREV!!!! PREV ID AFTER: ", prevId);
      }
    }
    // setPrevId(id);
    // setPrevHasChild(hasChild);
    // setPrevLevel(level);
    // setPrevTitle(isNotEnd)
    // setPrevPrevNavIndex(prevNavIndex)
    // setPrevNavIndex(navIndex)
  };

  // set page and level
  const setPageIdAndLevel = (id, level) => {
    //////////onsole.log('SetPageIdAndLevel: ', id);
    //////////onsole.log('SetPageIdAndLevel: ', level);
    //////////onsole.log('IS AT END: ', getIsAtEndString());

    if (level === 0) {
      setChosenMainPageButtonId(id);
      //let temp = nav?.items?.findIndex((ele)=>{return ele.referenceId === id} )
      window.clearTimeout(ScrollTimer.current);
      // SubRef.current.scrollTo({left:Size.W*(temp),behavior:"smooth"})//ping
      window.clearTimeout(ScrollTimer.current);
      //VGridCol2.current = temp;
      setTMGD(false);
      SetSingleItem(null);
      setSingleGall(false);
      SetSingleItem(null);
      GalleryActiveRef.current = false;
    } else {
      //////onsole.log("pages[id]",pages[id])
      setTimeout(() => {
        setTMGD(true);
        setSingleGall(pages[id]?.content?.length > 1);
        GalleryActiveRef.current = true;
      }, 375);
    }
    //////////onsole.log('Setting: ', id);
    //////////onsole.log('Setting: ', nav);
    setChosenPageId(id);
    setCurrentLevel(level);
    //////////onsole.log('IS AT END: ', getIsAtEndString());
  };

  // return null if menuHide is true
  // if (menuHide) {
  //   return null;
  // }

  //////////onsole.log('Chosen Page ID: ', chosenPageId);
  // if (chosenPageId !== 0)
  //   //////////onsole.log('Group of Tabs: ', pages[chosenPageId].tabs);
  //////////onsole.log('Chosen', chosenPortfolio);
  const TemplateGridCol = '100vw ';
  const [GridCol, setGridCol] = useState('');
  const [TMGD, setTMGD] = useState(false);
  const [PointerEvent, setPE] = useState(false);
  const [SingleGall, setSingleGall] = useState(false);
  const RowRef = useRef();
  // const SubM = nav?.items?.map((Val,I)=>{
  //   // if(I !== 14)
  //   // return null
  //   return (<div className="SubMenuRoot" TMGD={TMGD} key={Val.referenceId.toString()} style={{gridColumn:`${I+1}/${I+1}`,backgroundColor:"cadetblue", position:"relative"}}><SubmenuItems level={1} G={I+1} Val={Val} onButtonClick={onClick} SetTopicArray={SetTopicArray} /></div>)
  // })
  // const [SubM, setSubM] = useState(nav?.items?.map((Val,I)=>{
  //   // if(I !== 14)
  //   // return null
  //   return (<div className="SubMenuRoot" key={Val.referenceId.toString()} style={{gridColumn:`${I+1}/${I+1}`,backgroundColor:"cadetblue", position:"relative"}}><SubmenuItems level={1} G={I+1} Val={Val} onButtonClick={onClick} /></div>)
  // }));
  // useEffect(()=>{
  //   let temp = nav?.items?.map((Val,I)=>{
  //     // if(I !== 14)
  //     // return null
  //     return (<div className="SubMenuRoot" key={Val.referenceId.toString()} style={{gridColumn:`${I+1}/${I+1}`,backgroundColor:"cadetblue", position:"relative"}}><SubmenuItems level={1} G={I+1} Val={Val} onButtonClick={onClick} /></div>)
  //   });
  //   setSubM(temp);
  // },[nav?.items?])
  useEffect(() => {
    let temp = '';
    let x = 0;
    while (x < nav?.items?.length) {
      temp += TemplateGridCol;
      x++;
    }
    setGridCol(temp);
  }, [nav.items.length]);
  // const [VGridCol, setVGridCol] = useState(0);
  const SubRef = useRef();
  let VGridCol2 = useRef(0);
  // const KillEve = (eve)=>{
  //   //eve.preventDefault();
  //   //eve.stopPropagation();
  //   return;
  // }
  // useEffect(()=>{
  //   let temp = document.getElementById("SubMenuRef");
  //   try{
  //     temp.removeEventListener("wheel",ScrollSub,{passive:false, capture:true});
  //     //temp.removeEventListener("scroll",ScrollSub,{passive:false, capture:true});
  //   }
  //   catch{}
  //   temp.addEventListener("wheel",ScrollSub,{passive:false, capture:true});
  //   //temp.addEventListener("scroll",ScrollSub,{passive:false, capture:true});
  //   return ()=>{
  //     temp.removeEventListener("wheel",ScrollSub,{passive:false, capture:true});
  //     //temp.removeEventListener("scroll",ScrollSub,{passive:false, capture:true});
  //   }
  // },[SubRef.current])
  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  //const [ScrollTimer,SetScrollTimer] = useState()
  // const [ScrollLock,SetScrollLock] = useState(false)
  const [ScrollPos, SetScrollPos] = useState(0);
  // const ScrollLockRef = useRef(ScrollLock);
  const SizeRef = React.useRef(Size);
  useEffect(() => {
    SizeRef.current = Size;
  }, [Size]);
  const ScrollTimer = useRef();
  //const [SCT, //SetSCT] = useState(false)
  // eslint-disable-next-line
  const WheelDebounce = _.debounce(
    (eve) => {
      //onsole.log("WHEEL Bounce")
      if (TMGD) {
        ScrollStop();
        return;
      }

      // if(eve.deltaY>0){
      //   //onsole.log("+WHEEL")
      //   SetTopicArray(nav?.items[VGridCol2.current+1]?.title,0,nav?.items[VGridCol2.current+1]?.referenceId);
      //     onClick(nav?.items[VGridCol2.current+1]?.referenceId,0,nav?.items[VGridCol2.current+1]?.children.length>0,nav?.items[VGridCol2.current+1]?.children.length>0,VGridCol2.current+1)
      //     SubRef.current.scrollTo({left:Size.W*(VGridCol2.current+1),behavior:"smooth"})
      //   VGridCol2.current = VGridCol2.current +1
      //   //onClick(nav?.items[VGridCol2.current+1]?.referenceId,0,nav?.items[VGridCol2.current+1]?.children.length>0,nav?.items[VGridCol2.current+1]?.children.length>0,VGridCol2.current+1)
      //   return

      // }
      // else{
      //   //onsole.log("-WHEEL")
      //   SetTopicArray(nav?.items[VGridCol2.current-1]?.title,0,nav?.items[VGridCol2.current-1]?.referenceId);
      //   onClick(nav?.items[VGridCol2.current-1]?.referenceId,0,nav?.items[VGridCol2.current-1]?.children.length>0,nav?.items[VGridCol2.current-1]?.children.length>0,VGridCol2.current-1)
      //   SubRef.current.scrollTo({left:Size.W*(VGridCol2.current-1),behavior:"smooth"})//ping
      //   VGridCol2.current = VGridCol2.current-1;
      //   //onClick(nav?.items[VGridCol2.current-1]?.referenceId,0,nav?.items[VGridCol2.current-1]?.children.length>0,nav?.items[VGridCol2.current-1]?.children.length>0,VGridCol2.current-1)
      //   return
      // }
      if (eve.deltaY > 0) {
        if (VGridCol2.current === nav?.items?.length - 1) {
          ScrollStop();
          return;
        }
        //////onsole.log("Observe",nav?.items)
        //eve.currentTarget.scrollTo({left:Size.W*(VGridCol2.current+1),behavior:"smooth"})
        SetTopicArray(
          nav?.items[VGridCol2.current + 1]?.title,
          0,
          nav?.items[VGridCol2.current + 1]?.referenceId
        );
        onClick(
          nav?.items[VGridCol2.current + 1]?.referenceId,
          0,
          nav?.items[VGridCol2.current + 1]?.children.length > 0,
          nav?.items[VGridCol2.current + 1]?.children.length > 0,
          VGridCol2.current + 1
        );
        SubRef.current.scrollTo({
          left: Size.W * (VGridCol2.current + 1),
          behavior: 'smooth'
        }); //ping
        //SetSCT(Size.W*(VGridCol2.current+1))
        //////onsole.log("RowRef.current",RowRef.current)
        //////onsole.log("row-container",document.getElementById("row-container").firstChild.offsetLeft)
        if (
          document.getElementById('row-container').childNodes[
            VGridCol2.current + 1
          ].offsetLeft >
            document.getElementById('row-container').offsetWidth +
              document.getElementById('row-container').scrollLeft ||
          document.getElementById('row-container').childNodes[
            VGridCol2.current + 1
          ].offsetLeft < document.getElementById('row-container').scrollLeft
        ) {
          document.getElementById('row-container').scrollTo({
            left: document.getElementById('row-container').childNodes[
              VGridCol2.current + 1
            ].offsetLeft,
            behavior: 'smooth'
          });
        }
        VGridCol2.current = VGridCol2.current + 1;
        ScrollStop();
      } else {
        if (VGridCol2.current < 1) {
          ScrollStop();
          return;
        }

        //eve.currentTarget.scrollTo({left:Size.W*(VGridCol2.current-1),behavior:"smooth"})
        SetTopicArray(
          nav?.items[VGridCol2.current - 1]?.title,
          0,
          nav?.items[VGridCol2.current - 1]?.referenceId
        );
        onClick(
          nav?.items[VGridCol2.current - 1]?.referenceId,
          0,
          nav?.items[VGridCol2.current - 1]?.children.length > 0,
          nav?.items[VGridCol2.current - 1]?.children.length > 0,
          VGridCol2.current - 1
        );
        SubRef.current.scrollTo({
          left: Size.W * (VGridCol2.current - 1),
          behavior: 'smooth'
        }); //ping
        //SetSCT(Size.W*(VGridCol2.current-1))
        if (
          document.getElementById('row-container').childNodes[
            VGridCol2.current - 1
          ].offsetLeft >
            document.getElementById('row-container').offsetWidth +
              document.getElementById('row-container').scrollLeft ||
          document.getElementById('row-container').childNodes[
            VGridCol2.current - 1
          ].offsetLeft < document.getElementById('row-container').scrollLeft
        ) {
          document.getElementById('row-container').scrollTo({
            left: document.getElementById('row-container').childNodes[
              VGridCol2.current - 1
            ].offsetLeft,
            behavior: 'smooth'
          });
        }
        VGridCol2.current = VGridCol2.current - 1;
      }
      ScrollStop();
    },
    500,
    { leading: true, trailing: false }
  );
  //eslint-disable-next-line
  const ScrollDebounce = useCallback(
    _.debounce(() => {
      endScroll();
    }, 300),
    []
  ); //300
  const endScroll = () => {
    setPE(false);
    //    //////onsole.log("SCROLLSTATUS",ScrollLock,ScrollLockRef.current)
    //   if(ScrollLockRef.current){
    //     //////onsole.log("!!!SCROLLUnlock")
    //   SetScrollLock(false);
    //   ScrollLockRef.current = false;
    //   return
    // }
    //////onsole.log("PING GALLREF", document.getElementById("row-container"),SubRef.current, GalleryActiveRef.current)
    if (GalleryActiveRef.current) {
      ScrollStop();
      return;
    }
    if (SubRef.current.scrollLeft % SizeRef.current.W === 0) {
      if (GalleryActiveRef.current) {
        ScrollStop();
        return;
      }
      let x = SubRef.current.scrollLeft / SizeRef.current.W;
      // SetTopicArray(nav?.items[x]?.title,0,nav?.items[x]?.referenceId);
      //onClick(nav?.items[x]?.referenceId,0,nav?.items[x]?.children.length>0,nav?.items[x]?.children.length>0,x)
      //////onsole.log("!!!SCROLLTOCALL")
      SubRef.current.scrollTo({
        left: SizeRef.current.W * x,
        behavior: 'smooth'
      }); //ping
      //SetSCT(SizeRef.current.W*(x))
      // SetScrollLock(true);
      // ScrollLockRef.current = true;
      //////onsole.log("RowRef.current",RowRef.current)
      if (
        document.getElementById('row-container').childNodes[x].offsetLeft >
          document.getElementById('row-container').offsetWidth +
            document.getElementById('row-container').scrollLeft ||
        document.getElementById('row-container').childNodes[x].offsetLeft <
          document.getElementById('row-container').scrollLeft
      ) {
        document.getElementById('row-container').scrollTo({
          left: document.getElementById('row-container').childNodes[x]
            .offsetLeft,
          behavior: 'smooth'
        });
      }
      VGridCol2.current = x;
      ScrollStop();
      return;
    } else if (
      SubRef.current.scrollLeft % SizeRef.current.W >=
      SizeRef.current.W / 2
    ) {
      let x = 0;
      let y = 0;
      do {
        x++;
        y = SizeRef.current.W * x;
      } while (y < SubRef.current.scrollLeft);
      // if(VGridCol2.current === nav?.items?.length -1)
      //   return;
      //////onsole.log("Observe",nav?.items)
      //eve.currentTarget.scrollTo({left:SizeRef.current.W*(VGridCol2.current+1),behavior:"smooth"})
      // SetTopicArray(nav?.items[x]?.title,0,nav?.items[x]?.referenceId);
      //onClick(nav?.items[x]?.referenceId,0,nav?.items[x]?.children.length>0,nav?.items[x]?.children.length>0,x)
      //////onsole.log("!!!SCROLLTOCALL")
      SubRef.current.scrollTo({
        left: SizeRef.current.W * x,
        behavior: 'smooth'
      }); //ping
      //SetSCT(SizeRef.current.W*(x))
      // SetScrollLock(true);
      // ScrollLockRef.current = true;
      //////onsole.log("RowRef.current",RowRef.current)
      if (
        document.getElementById('row-container').childNodes[x].offsetLeft >
          document.getElementById('row-container').offsetWidth +
            document.getElementById('row-container').scrollLeft ||
        document.getElementById('row-container').childNodes[x].offsetLeft <
          document.getElementById('row-container').scrollLeft
      ) {
        document.getElementById('row-container').scrollTo({
          left: document.getElementById('row-container').childNodes[x]
            .offsetLeft,
          behavior: 'smooth'
        });
      }
      VGridCol2.current = x;
    } else {
      let x = 0;
      let y = 0;
      do {
        x++;
        y = SizeRef.current.W * x;
      } while (y < SubRef.current.scrollLeft);
      x--;
      // if(VGridCol2.current === nav?.items?.length -1)
      //   return;
      //////onsole.log("Observe",nav?.items)
      //eve.currentTarget.scrollTo({left:SizeRef.current.W*(VGridCol2.current+1),behavior:"smooth"})
      // SetTopicArray(nav?.items[x]?.title,0,nav?.items[x]?.referenceId);
      //onClick(nav?.items[x]?.referenceId,0,nav?.items[x]?.children.length>0,nav?.items[x]?.children.length>0,x)
      SubRef.current.scrollTo({
        left: SizeRef.current.W * x,
        behavior: 'smooth'
      }); //ping
      //SetSCT(SizeRef.current.W*(x))
      // SetScrollLock(true);
      // ScrollLockRef.current = true;
      //////onsole.log("!!!SCROLLTOCALL")
      //////onsole.log("RowRef.current",RowRef.current)
      if (
        document.getElementById('row-container').childNodes[x].offsetLeft >
          document.getElementById('row-container').offsetWidth +
            document.getElementById('row-container').scrollLeft ||
        document.getElementById('row-container').childNodes[x].offsetLeft <
          document.getElementById('row-container').scrollLeft
      ) {
        document.getElementById('row-container').scrollTo({
          left: document.getElementById('row-container').childNodes[x]
            .offsetLeft,
          behavior: 'smooth'
        });
      }
      VGridCol2.current = x;
    }
    ScrollStop();
  };
  const ScrollStop = () => {
    setTimeout(() => {
      //Update Here
      //onsole.log('ScrollStop');
      //let t = document.createEvent(new CustomEvent("ScrollStop"));
      window.dispatchEvent(new CustomEvent('ScrollStop'));
      for (let ele of document.getElementById('row-container').children)
        ele.dispatchEvent(new CustomEvent('ScrollStop'));
      //document.getElementById("row-container").dispatchEvent(new CustomEvent("ScrollStop"))
    }, 100);
  };
  const GalleryActiveRef = useRef(false);
  useEffect(() => {
    if (TMGD) {
      GalleryActiveRef.current = true;
      if (!SingleGall) {
        //SetActive={SetActive}
        // SetSingleItem((<SingleItemDisplay  Single={SetSingleItem} Position={0} currentContent={currentContent} createSetCurrentContent={createSetCurrentContent}></SingleItemDisplay>))
        SetSingleItem({
          Single: SetSingleItem,
          Position: 0,
          currentContent: currentContent,
          createSetCurrentContent: createSetCurrentContent
        });
      } else {
        SetSingleItem(null);
      }
    } else {
      GalleryActiveRef.current = false;
    }
    // eslint-disable-next-line
  }, [SingleGall, TMGD]);
  useEffect(() => {
    const Setup = async () => {
      await sleep(250);
      SetTopicArray(nav?.items[0]?.title, 0, nav?.items[0]?.referenceId);
      // await sleep(5000);
      onClick(
        nav?.items[0]?.referenceId,
        0,
        nav?.items[0]?.children,
        nav?.items[0]?.children,
        0
      );
    };
    Setup();
    // eslint-disable-next-line
  }, []);
  const [MM, SetMM] = useState('');
  // const[Toolsdis,SetToolDis] = useState(false)
  const [SINGLEITEM, SetSingleItem] = useState(null);
  useEffect(() => {
    if (SINGLEITEM === null) {
      if (!SingleGall) setTMGD(false);
    }
  }, [SINGLEITEM, SingleGall]);
  useEffect(() => {
    //if(!PointerEvent)
    //SubRef.current.scrollBy({top:0,left:10,behavior:"smooth"})
  }, [PointerEvent]);
  const back = () => {
    //TermRef.current.style.opacity = 0;
    // setTimeout(function () {
    //   //TermRef.current.style.opacity = 1;
    //   goBack();
    // }, 500);
    goBack();
  };
  return (
    <>
      {/* <Mega></Mega> */}
      <div
        onClick={() => {
          if (VGridCol2.current === nav?.items?.length - 1) {
            document
              .getElementById('SubMenuRef')
              .scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          } else {
            document
              .getElementById('SubMenuRef')
              .scrollBy({ top: 0, left: Size.W, behavior: 'smooth' });
          }
        }}
        className="ScrollIndicator"
        style={{
          maskImage: `url(${Scrollindicator})`,
          WebkitMaskImage: `url(${Scrollindicator})`,
          maskSize: '100% 100%',
          WebkitMaskSize: '100% 100%',
          background: Color ? Color : 'white',
          color: Color ? Color : 'white',
          opacity: 0.7
        }}
      ></div>
      {/* Iconize */}
      {/* <div className="Wrapper" onWheel={(eve)=>{//////onsole.log(eve.deltaY); SetGripLoc(window.innerHeight)}} style={{transition:"all 0.5s", clipPath:GripLoc  !== null?`polygon(0% 0%, 100% 0%, 100% calc(100% - ${GripLoc}px), 0% calc(100% - ${GripLoc}px)`:null}}>
        <Cover></Cover>
      </div> */}
      <div
        className="portfolio-menu-container"
        style={{
          display: menuHide ? 'none' : '',
          backgroundColor: chosenPortfolio.Menu_Bar_color
            ? chosenPortfolio.Menu_Bar_color
            : 'white'
        }}
      >
        <div
          className="MM"
          onClick={() => {
            SetMM('Show');
          }}
          style={{
            backgroundColor: chosenPortfolio.secondary_color
              ? chosenPortfolio.secondary_color
              : '',
            position: 'absolute',
            width: 'calc(100% * 24/414)',
            height: 'calc(100% * 30.71/129)',
            top: 'calc(100% * 60.66/129)',
            left: 'calc(100% * 16/414)',
            maskImage: `url(${IconMenu})`,
            maskSize: '100% 100%',
            maskRepeat: 'no-repeat',
            WebkitMask: `url(${IconMenu})`,
            WebkitMaskSize: '100% 100%',
            WebkitMaskRepeat: 'no-repeat'
          }}
        ></div>
        {/* <div className="MT" onClick={()=>{SetToolDis(true)}} style={{backgroundColor:chosenPortfolio.secondary_color?chosenPortfolio.secondary_color:"", position:"absolute",  maskImage:`url(${ToolsIcon})`, top:"calc(100% * 64/129)",right:"calc(100% * 15/414)", width:"calc(100% * 35.33/414)",height:"calc(100% * 31.43/129)", maskSize:'100% 100%', maskRepeat:"no-repeat", WebkitMask:`url(${ToolsIcon})`,WebkitMaskSize:"100% 100%",WebkitMaskRepeat:"no-repeat"}}></div> */}
        {/* width:"calc(100% * 35/414)",height:"calc(100% * 31/(1080 * 114/1080))", */}
        <div
          className="colorFooter"
          style={{
            backgroundColor: chosenPortfolio.secondary_color
              ? chosenPortfolio.secondary_color
              : ''
          }}
        ></div>
        <div className="logo">
          <div className="logo-content">
            <img
              style={{
                height: `calc(100% - ${
                  portfolio_LogoResize?.Compression
                    ? portfolio_LogoResize.VerticalCompression
                    : 0
                }%)`,
                width: `calc(100% - ${
                  portfolio_LogoResize?.Compression
                    ? portfolio_LogoResize.HorizontalCompression
                    : 0
                }%)`
              }}
              //onClick={logoReset}
              src={urlFor(
                chosenPortfolio.portfolio_logo
                  ? chosenPortfolio.portfolio_logo.asset._ref
                  : companyConfig.logo !== undefined
                  ? images[companyConfig.logo.asset._ref]
                  : ''
              )
                .auto('format')
                .width(400)
                .url()}
              alt="logo"
            />
          </div>
        </div>
        <div
          className="menus-container"
          ref={TermRef}
          style={{ transition: 'opacity 250ms' }}
        >
          {
            <div
              style={{
                marginTop: '0px'
                //display: `${isAtEnd ? 'none' : 'block'}`
              }}
              onWheel={(eve) => {
                //////onsole.log("!!SCROLLX!!!",eve,eve.deltaX);
                //////onsole.log("!!SCROLLY!!!",eve,eve.deltaY);
                eve.currentTarget.scrollBy({
                  top: 0,
                  left: eve.deltaY,
                  behavior: 'smooth'
                });
              }} // if(eve.currentTarget.style.overflowY === 'hidden')
              className={`portfolio-menu  ${getIsAtEndString()}`}
            >
              <div
                id={'row-container'}
                className="row-container"
                style={{
                  minWidth: `min(100%, calc(100% - 100%/${nav?.items?.length}))`
                }}
                ref={RowRef}
                onWheel={(eve) => {
                  //////onsole.log("EVE");
                  eve.currentTarget.scrollBy({
                    top: 0,
                    left: eve.deltaY * 2,
                    behavior: 'smooth'
                  });
                }}
              >
                <MenuSection
                  isActive={true} // set all level 0 button to be active
                  getActiveString={getActiveString}
                  getIsAtEndString={getIsAtEndString}
                  SetTopicArray={SetTopicArray}
                  level={0}
                  onButtonClick={onClick}
                  SubRef={SubRef}
                  BackTopicArray={BackTopicArray}
                />
                {/* {Size.W <= 1366 
                ? 
                  <div
                    className='empty-box'
                    style={{
                      width: '30px',
                      height: '30px',
                      backgroundColor: 'red'
                    }}
                  /> 
                : 
                  null
              } */}
              </div>
              {/* <div
              className='menu-filler'
              style={{
                width: '100%',
                height: '48px',
                backgroundColor: 'transparent'
              }}
            /> */}
            </div>
          }
        </div>
        {/*(window.innerWidth<=450 || (window.innerWidth<=815 && window.innerHeight<=550) || (window.innerWidth<=880 && window.innerHeight<=1100) || (window.innerWidth<=1000 && window.innerHeight<=1500 && window.innerHeight>=1000))  && */}
        {true && (
          <div
            className={`menus-container2 ${MM}`}
            ref={TermRef}
            // style={{transition: 'opacity 250ms' }}
          >
            {
              <div
                style={{
                  marginTop: '0px'
                  //display: `${isAtEnd ? 'none' : 'block'}`
                }}
                onWheel={(eve) => {
                  //////onsole.log("!!SCROLLX!!!",eve,eve.deltaX);//////onsole.log("!!SCROLLY!!!",eve,eve.deltaY);
                  eve.currentTarget.scrollBy({
                    top: 0,
                    left: eve.deltaY,
                    behavior: 'smooth'
                  });
                }} // if(eve.currentTarget.style.overflowY === 'hidden')
                className={`portfolio-menu  ${getIsAtEndString()} `}
              >
                {/* logo Close PING */}
                {/* <img alt="" className={"Exit"} src={exit} onClick={()=>SetMM("")} style={{}} /> */}
                <CloseIcon
                  className={'Exit'}
                  src={exit}
                  onClick={() => SetMM('')}
                  id={'SM1'}
                />
                {/* ICON */}
                <div className="logo T9">
                  <div className="logo-content">
                    <img
                      style={{
                        height: `calc(100% - ${
                          portfolio_LogoResize?.Compression
                            ? portfolio_LogoResize.VerticalCompression
                            : 0
                        }%)`,
                        width: `calc(100% - ${
                          portfolio_LogoResize?.Compression
                            ? portfolio_LogoResize.HorizontalCompression
                            : 0
                        }%)`
                      }}
                      //onClick={logoReset}
                      src={urlFor(
                        chosenPortfolio.portfolio_logo
                          ? chosenPortfolio.portfolio_logo.asset._ref
                          : companyConfig.logo !== undefined
                          ? images[companyConfig.logo.asset._ref]
                          : ''
                      )
                        .auto('format')
                        .width(400)
                        .url()}
                      alt="logo"
                    />
                  </div>
                </div>
                <div
                  id={'row-container'}
                  className="row-container"
                  style={{
                    minWidth: `min(100%, calc(100% - 100%/${nav?.items?.length}))`
                  }}
                  ref={RowRef}
                  onWheel={(eve) => {
                    //////onsole.log("EVE");
                    eve.currentTarget.scrollBy({
                      top: 0,
                      left: eve.deltaY * 2,
                      behavior: 'smooth'
                    });
                  }}
                >
                  <MenuSection
                    isActive={true} // set all level 0 button to be active
                    getActiveString={getActiveString}
                    getIsAtEndString={getIsAtEndString}
                    SetTopicArray={SetTopicArray}
                    level={0}
                    onButtonClick={onClick}
                    SubRef={SubRef}
                    BackTopicArray={BackTopicArray}
                  />
                  {/* {Size.W <= 1366 
                ? 
                  <div
                    className='empty-box'
                    style={{
                      width: '30px',
                      height: '30px',
                      backgroundColor: 'red'
                    }}
                  /> 
                : 
                  null
              } */}
                </div>
                {/* <div
              className='menu-filler'
              style={{
                width: '100%',
                height: '48px',
                backgroundColor: 'transparent'
              }}
            /> */}
              </div>
            }
          </div>
        )}
      </div>
      {/* <div className="portfolio-menu-container2" style={{overflowX:"clip", width:Size.W* nav.items.length}}> */}
      <div
        className="portfolio-menu-container2"
        ref={SubRef}
        id={'SubMenuRef'}
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          gridTemplateColumns: GridCol,
          overflowX: 'scroll',
          overflowY: 'hidden' /*touchAction:"none"*/
        }}
        onWheel={(eve) => {
          //onsole.log("WHEEL START")
          WheelDebounce(eve);
          //scrollSnapType:"x mandatory", maxWidth:Size.W* nav.items.length
          // eve.preventDefault();
          // eve.stopPropagation();\

          // if(TMGD){
          //   ScrollStop();
          // return;
          // }
          // if(eve.deltaY>0)
          //   {
          //     if(VGridCol2.current === nav?.items?.length -1){
          //       ScrollStop();
          //     return;
          //     }
          //     //////onsole.log("Observe",nav?.items)
          //     //eve.currentTarget.scrollTo({left:Size.W*(VGridCol2.current+1),behavior:"smooth"})
          //     SetTopicArray(nav?.items[VGridCol2.current+1]?.title,0,nav?.items[VGridCol2.current+1]?.referenceId);
          //     onClick(nav?.items[VGridCol2.current+1]?.referenceId,0,nav?.items[VGridCol2.current+1]?.children.length>0,nav?.items[VGridCol2.current+1]?.children.length>0,VGridCol2.current+1)
          //     SubRef.current.scrollTo({left:Size.W*(VGridCol2.current+1),behavior:"smooth"})//ping
          //     //////onsole.log("RowRef.current",RowRef.current)
          //     //////onsole.log("row-container",document.getElementById("row-container").firstChild.offsetLeft)
          //     if(document.getElementById("row-container").childNodes[VGridCol2.current+1].offsetLeft > document.getElementById("row-container").offsetWidth + document.getElementById("row-container").scrollLeft || document.getElementById("row-container").childNodes[VGridCol2.current+1].offsetLeft < document.getElementById("row-container").scrollLeft ){
          //       document.getElementById("row-container").scrollTo({left:document.getElementById("row-container").childNodes[VGridCol2.current+1].offsetLeft,behavior:"smooth"})
          //     }
          //     VGridCol2.current = VGridCol2.current +1;
          //     ScrollStop();
          //   }
          // else{
          //   if(VGridCol2.current < 1 ){
          //     ScrollStop();
          //     return;
          //   }

          //   //eve.currentTarget.scrollTo({left:Size.W*(VGridCol2.current-1),behavior:"smooth"})
          //   SetTopicArray(nav?.items[VGridCol2.current-1]?.title,0,nav?.items[VGridCol2.current-1]?.referenceId);
          //   onClick(nav?.items[VGridCol2.current-1]?.referenceId,0,nav?.items[VGridCol2.current-1]?.children.length>0,nav?.items[VGridCol2.current-1]?.children.length>0,VGridCol2.current-1)
          //   SubRef.current.scrollTo({left:Size.W*(VGridCol2.current-1),behavior:"smooth"})//ping
          //   if(document.getElementById("row-container").childNodes[VGridCol2.current-1].offsetLeft > document.getElementById("row-container").offsetWidth + document.getElementById("row-container").scrollLeft || document.getElementById("row-container").childNodes[VGridCol2.current-1].offsetLeft < document.getElementById("row-container").scrollLeft ){
          //     document.getElementById("row-container").scrollTo({left:document.getElementById("row-container").childNodes[VGridCol2.current-1].offsetLeft,behavior:"smooth"})
          //   }
          //   VGridCol2.current = VGridCol2.current-1;
          //     }
          //     ScrollStop();
        }}
        onPointerDown={(eve) => {
          setPE(eve.pageX);
          //onsole.log("POINTERDOWN",eve.movementX, eve.movementY)
        }}
        onPointerMove={(eve) => {
          if (PointerEvent)
            eve.currentTarget.scrollBy({
              top: 0,
              left: -eve.movementX * 1.2,
              behavior: 'auto'
            });
          //onsole.log("POINTERMOVE",eve.movementX, eve.movementY)
        }}
        onPointerUp={(eve) => {
          //eve.currentTarget.scrollBy({top:0,left:((-eve.pageX*1.2)+PointerEvent)*2.5,behavior:"smooth"})
          setPE(false);
        }}
        onPointerCancel={() => {
          setPE(false);
        }}
        onPointerLeave={() => {
          setPE(false);
        }}
        onScroll={(eve) => {
          // if(PointerEvent){
          //   return;
          // }

          //   if(PointerEvent){
          //     if(eve.currentTarget.scrollLeft%Size.W === 0){
          //       if(eve.currentTarget.scrollLeft/Size.W !== VGridCol2.current){
          //         let x =  eve.currentTarget.scrollLeft/Size.W;
          //         if(x !== VGridCol2.current){
          //         VGridCol2.current = x;
          //         SetTopicArray(nav?.items[x]?.title,0,nav?.items[x]?.referenceId);
          //         onClick(nav?.items[x]?.referenceId,0,nav?.items[x]?.children.length>0,nav?.items[x]?.children.length>0,x)
          //         }
          //       }
          //     }
          //         else if(eve.currentTarget.scrollLeft%Size.W >= Size.W/2){
          //           let x = 0
          //                 let y = 0
          //                 do{
          //                   x++;
          //                   y = Size.W*x;

          //                 }while(y<eve.currentTarget.scrollLeft)
          //                 if(x !== VGridCol2.current){
          //                 VGridCol2.current = x;
          //                 SetTopicArray(nav?.items[x]?.title,0,nav?.items[x]?.referenceId);
          //                 onClick(nav?.items[x]?.referenceId,0,nav?.items[x]?.children.length>0,nav?.items[x]?.children.length>0,x)

          //         }}
          //         else{
          //           let x = 0
          //           let y = 0
          //           do{
          //             x++;
          //             y = Size.W*x;

          //           }while(y<eve.currentTarget.scrollLeft)
          //           x--;
          //           if(x !== VGridCol2.current){
          //           VGridCol2.current = x;
          //           SetTopicArray(nav?.items[x]?.title,0,nav?.items[x]?.referenceId);
          //           onClick(nav?.items[x]?.referenceId,0,nav?.items[x]?.children.length>0,nav?.items[x]?.children.length>0,x)
          //           }
          //         }
          //   return;
          // }

          //////onsole.log("!!!SCROLL!!!",eve.currentTarget.scrollLeft,Size.W)
          //  window.clearTimeout(ScrollTimer.current)
          //  ScrollTimer.current=window.setTimeout(endScroll,500)
          if (
            eve.currentTarget.scrollLeft >
            Size.W * (nav?.items?.length - 1)
          ) {
            eve.preventDefault();
            eve.stopPropagation();
            eve.currentTarget.scrollTo({
              left: Size.W * (nav?.items?.length - 1),
              behavior: 'auto'
            });
            //SetSCT(Size.W * (nav?.items?.length - 1))
            ScrollStop();
            if (PointerEvent) endScroll();
            return;
          }
          if (eve.currentTarget.scrollLeft % Size.W === 0) {
            if (eve.currentTarget.scrollLeft / Size.W !== VGridCol2.current) {
              let x = eve.currentTarget.scrollLeft / Size.W;
              if (x !== VGridCol2.current) {
                VGridCol2.current = x;
                SetTopicArray(
                  nav?.items[x]?.title,
                  0,
                  nav?.items[x]?.referenceId
                );
                onClick(
                  nav?.items[x]?.referenceId,
                  0,
                  nav?.items[x]?.children.length > 0,
                  nav?.items[x]?.children.length > 0,
                  x
                );
                ScrollStop();
                if (PointerEvent) {
                  endScroll();
                  return;
                }
              }
            }
          } else if (eve.currentTarget.scrollLeft % Size.W >= Size.W / 2) {
            let x = 0;
            let y = 0;
            do {
              x++;
              y = Size.W * x;
            } while (y < eve.currentTarget.scrollLeft);
            if (x !== VGridCol2.current) {
              VGridCol2.current = x;
              SetTopicArray(
                nav?.items[x]?.title,
                0,
                nav?.items[x]?.referenceId
              );
              onClick(
                nav?.items[x]?.referenceId,
                0,
                nav?.items[x]?.children.length > 0,
                nav?.items[x]?.children.length > 0,
                x
              );
              ScrollStop();
              if (PointerEvent) {
                endScroll();
                return;
              }
            }
          } else {
            let x = 0;
            let y = 0;
            do {
              x++;
              y = Size.W * x;
            } while (y < eve.currentTarget.scrollLeft);
            x--;
            if (x !== VGridCol2.current) {
              VGridCol2.current = x;
              SetTopicArray(
                nav?.items[x]?.title,
                0,
                nav?.items[x]?.referenceId
              );
              onClick(
                nav?.items[x]?.referenceId,
                0,
                nav?.items[x]?.children.length > 0,
                nav?.items[x]?.children.length > 0,
                x
              );
              ScrollStop();
              if (PointerEvent) {
                endScroll();
                return;
              }
            }
          }
          SetScrollPos(eve.currentTarget.scrollLeft);
          ScrollDebounce();
        }}
      >
        {nav?.items?.map((Val, I) => {
          // if(I !== 14)
          // return null
          return (
            <div
              className="SubMenuRoot"
              // TMGD={TMGD}
              key={Val.referenceId.toString()}
              style={{
                gridColumn: `${I + 1}/${I + 1}`,
                position: 'relative',
                zIndex: TMGD ? -1 : undefined
              }}
            >
              <SubMenuController
                UNACTIVEEVENT={UNACTIVEEVENT}
                currentContent={currentContent}
                createSetCurrentContent={createSetCurrentContent}
                Single={SetSingleItem}
                nav={nav}
                navHistory={navHistory}
                TMGD={TMGD}
                level={1}
                G={I + 1}
                Val={Val}
                onClick={onClick}
                SetTopicArray={SetTopicArray}
                setTMGD={setTMGD}
                ScrollPos={ScrollPos}
              />
            </div>
          );
        })}
        {TMGD && SingleGall && (
          <TMGC
            sendClick={sendClick}
            shareClick={shareClick}
            nav={nav}
            navHistory={navHistory}
            back={back}
            currentContent={currentContent}
            createSetCurrentContent={createSetCurrentContent}
            SetSingleItem={SetSingleItem}
          ></TMGC>
        )}
        {/* {TMGD && SingleGall && (<div style={{height:"100%",width:"100%", gridRow:"2/2", position:"absolute", overflow:"hidden", overscrollBehavior:"contain"}}><TMG3 back={back} currentContent={currentContent} createSetCurrentContent={createSetCurrentContent} Single={SetSingleItem}></TMG3></div>)} */}
        {/* {TMGD && (<div style={{height:"100%", gridRow:"2/2", width:"100%", position:"absolute", overflow:"hidden", overscrollBehavior:"contain"}}><TMG back={back} currentContent={currentContent} createSetCurrentContent={createSetCurrentContent} Single={SetSingleItem}></TMG></div>)} */}
        {/* {TMGD && (<div style={{height:"100%", gridRow:"2/2", width:"100%", position:"absolute", overflow:"hidden", overscrollBehavior:"contain"}}><TMG3 back={back} currentContent={currentContent} createSetCurrentContent={createSetCurrentContent} Single={SetSingleItem}></TMG3></div>)} */}
        {/* height:"calc(100% * (1080 - 114)/1080)" */}
        {SINGLEITEM && (
          <SingleItemDisplay
            sendClick={sendClick}
            shareClick={shareClick}
            {...SINGLEITEM}
          ></SingleItemDisplay>
        )}
        {/* <div style={{backgroundColor:"green", "calc(100% * (1080 - 114)/1080)", gridColumn:"3/3"}}></div> pages={pages}
        <div style={{backgroundColor:"red", gridColumn:"5/5"}}></div> */}
      </div>
      {/* {Toolsdis&& <Tools2 shareClick={shareClick}  currentContent={currentContent} SetDisplay={SetToolDis}></Tools2>} */}
      {/* updateSessionWithNotes={updateSessionWithNotes} */}
      {/* </div> */}
    </>
  );
};
export default Menu9;

// {isAtEnd && (
//   <>
//     <div className="back-container">
//       <Icons.BackButton
//         onClick={() => {
//           TermRef.current.style.opacity = 0;
//           setTimeout(function () {
//             TermRef.current.style.opacity = 1;
//             goBack();
//           }, 500);
//         }}
//       />
//       {/*{terminusMenuPosition}*/}
//     </div>
//   </>
// )}
