import { connect } from 'react-redux';
import SD from './SendDialogue';
// import { compose } from 'redux';
// import { isLoaded, isEmpty, firestoreConnect } from 'react-redux-firebase';
import {
  toggleBriefcase,
  addToBriefcase,
  removeFromBriefcase,
  clearBriefcase,
  SetGuestData
} from '../../actions';

const mapStateToProps = (state) => ({
  companyConfig: state.sanityContentReducer.companyConfig,
  chosenPortfolio: state.portfolioReducer.chosenPortfolio,
  guestPass: state.firestore.ordered.guest_passes,
  userInfo: state.firestore.ordered.users,
  showBriefcase: state.briefcaseReducer.showBriefcase,
  briefcaseContent: state.briefcaseReducer.briefcaseContent,
  isVideoPlaying: state.videoReducer.isVideoPlaying
});

const mapDispatchToProps = (dispatch) => {
  return {
    toggleBriefcase: () => dispatch(toggleBriefcase()),
    addToBriefcase: (obj) => dispatch(addToBriefcase(obj)),
    removeFromBriefcase: (id) => dispatch(removeFromBriefcase(id)),
    clearBriefcase: () => dispatch(clearBriefcase()),
    SetGuestData: (data) => dispatch(SetGuestData(data))
  };
};

const SDContainer = connect(mapStateToProps, mapDispatchToProps)(SD);

export default SDContainer;
