import {
  GET_USERS,
  CREATE_USER,
  PATCH_USER,
  DELETE_USER,
  GET_TEAMS,
  CREATE_TEAM,
  PATCH_TEAM,
  DELETE_TEAM
} from '../constants/actionTypes';
import { CLOUD_API_URL } from '../constants/constants';
import firebase from 'firebase/app';
//===================
// GET
//===================
export const getUsersBegin = () => ({
  type: GET_USERS.BEGIN
});

export const getUsersSuccess = (array) => ({
  type: GET_USERS.SUCCESS,
  array
});

export const getUsersFailure = () => ({
  type: GET_USERS.FAILURE
});

export const getTeamsBegin = () => ({
  type: GET_TEAMS.BEGIN
});

export const getTeamsSuccess = (array) => ({
  type: GET_TEAMS.SUCCESS,
  array
});

export const getTeamsFailure = () => ({
  type: GET_TEAMS.FAILURE
});

//===================
// POST
//===================
export const createUserBegin = () => ({
  type: CREATE_USER.BEGIN
});

export const createUserSuccess = (obj) => ({
  type: CREATE_USER.SUCCESS,
  obj
});

export const createUserFailure = () => ({
  type: CREATE_USER.FAILURE
});

export const createTeamBegin = () => ({
  type: CREATE_TEAM.BEGIN
});

export const createTeamSuccess = (obj) => ({
  type: CREATE_TEAM.SUCCESS,
  obj
});

export const createTeamFailure = () => ({
  type: CREATE_TEAM.FAILURE
});

//===================
// PATCH
//===================
export const patchUserBegin = () => ({
  type: PATCH_USER.BEGIN
});

export const patchUserSuccess = (obj) => ({
  type: PATCH_USER.SUCCESS,
  obj
});

export const patchUserFailure = () => ({
  type: PATCH_USER.FAILURE
});

export const patchTeamBegin = () => ({
  type: PATCH_TEAM.BEGIN
});

export const patchTeamSuccess = (obj) => ({
  type: PATCH_TEAM.SUCCESS,
  obj
});

export const patchTeamFailure = () => ({
  type: PATCH_TEAM.FAILURE
});

//===================
// DELETE
//===================
export const deleteUserBegin = () => ({
  type: DELETE_USER.BEGIN
});

export const deleteUserSuccess = (id) => ({
  type: DELETE_USER.SUCCESS,
  id
});

export const deleteUserFailure = () => ({
  type: DELETE_USER.FAILURE
});

export const deleteTeamBegin = () => ({
  type: DELETE_TEAM.BEGIN
});

export const deleteTeamSuccess = (id) => ({
  type: DELETE_TEAM.SUCCESS,
  id
});

export const deleteTeamFailure = () => ({
  type: DELETE_TEAM.FAILURE
});

// -----------------------
// Users
// -----------------------
// GET users by companies
export const getUsers = (companyId) => {
  return (dispatch) => {
    dispatch(getUsersBegin());

    // get idToken
    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(function (idToken) {
        // fetch
        fetch(`${CLOUD_API_URL}/companies/${companyId}/users`, {
          headers: {
            Authorization: `Bearer ${idToken}`
          }
        })
          .then((response) => {
            if (!response.ok) {
              // handle response error
              console.error(response.statusText);
              dispatch(getUsersFailure());
            }
            return response.json();
          })
          .then((data) => {
            // success
            dispatch(getUsersSuccess(data.users));
          })
          .catch((error) => {
            // handle error
            console.error(error);
            dispatch(getUsersFailure());
          });
      })
      .catch(function (error) {
        // fail to get idToken
        console.error(error);
        dispatch(getUsersFailure());
      });
  };
};

// Create new user
export const createUser = (data) => {
  return (dispatch) => {
    dispatch(createUserBegin());

    // modify data for valid createUser api structure
    let modifiedData = {
      ...data,
      isActive: true,
      displayName: data.name,
      isAbleToCreateBriefcase: data.isAbleToCreateBriefcase,
      isAbleToCreateGuestPass: data.isAbleToCreateGuestPass,
      team: data.team ? data.team.map((obj) => obj.value) : [],
      portfolio: data.portfolio ? data.portfolio.map((obj) => obj.value) : [],
      role: data.role ? data.role : 'user'
    };

    // get idToken
    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(function (idToken) {
        // fetch
        fetch(`${CLOUD_API_URL}/users`, {
          method: 'post',
          headers: {
            Authorization: `Bearer ${idToken}`,
            Accept: 'application/json, text/plain',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(modifiedData)
        })
          .then((response) => {
            if (!response.ok) {
              // handle response error
              console.error(response.statusText);
              dispatch(createUserFailure());
            }
            return response.json();
          })
          .then((obj) => {
            // success
            //////////onsole.log(obj);
            dispatch(createUserSuccess({ ...modifiedData, id: obj.uid }));
          })
          .catch((error) => {
            // handle error
            console.error(error);
            dispatch(createUserFailure());
          });
      })
      .catch(function (error) {
        // fail to get idToken
        console.error(error);
        dispatch(createUserFailure());
      });
  };
};

// Patch new user
export const patchUser = (data) => {
  return (dispatch) => {
    dispatch(patchUserBegin());

    //////////onsole.log(data);
    // NOTE team and portfolio might return combintion of obj and string ie ["1234556", {label: 'Team A', value: '456789'}]
    // This is due to react-select + material-table combination
    let modifiedData = {
      ...data,
      isActive: true,
      displayName: data.name,
      // isAbleToCreateBriefcase:
      //   data.isAbleToCreateBriefcase === 'guestPassTrue' ? true : false,
      // isAbleToCreateGuestPass:
      //   data.isAbleToCreateGuestPass === 'portfolioGuestPassTrue'
      //     ? true
      //     : false,
      team: data.team
        ? data.team.map((obj) => (obj.value ? obj.value : obj))
        : [],
      portfolio: data.portfolio
        ? data.portfolio.map((obj) => (obj.value ? obj.value : obj))
        : []
    };

    //////////onsole.log(modifiedData);

    // check if modifiedData id exists
    if (!modifiedData.id || modifiedData.id === '') {
      console.error('No id given');
      dispatch(patchUserFailure());
    } else {
      // get idToken
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then(function (idToken) {
          // fetch
          fetch(`${CLOUD_API_URL}/users/${modifiedData.id}`, {
            method: 'patch',
            headers: {
              Authorization: `Bearer ${idToken}`,
              Accept: 'application/json, text/plain',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(modifiedData)
          })
            .then((response) => {
              if (!response.ok) {
                // handle response error
                console.error(response.statusText);
                dispatch(patchUserFailure());
              }

              return response.json();
            })
            .then((obj) => {
              // success
              if (obj.success) {
                dispatch(patchUserSuccess({ ...modifiedData }));
              } else {
                console.error('Error in patch');
                dispatch(patchUserFailure());
              }
            })
            .catch((error) => {
              // handle error
              console.error(error);
              dispatch(patchUserFailure());
            });
        })
        .catch(function (error) {
          // fail to get idToken
          console.error(error);
          dispatch(createUserFailure());
        });
    }
  };
};

// Delete user
export const deleteUser = (id) => {
  return (dispatch) => {
    dispatch(deleteUserBegin());

    // get idToken
    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(function (idToken) {
        // fetch
        fetch(`${CLOUD_API_URL}/users/${id}`, {
          method: 'delete',
          headers: {
            Authorization: `Bearer ${idToken}`,
            Accept: 'application/json, text/plain',
            'Content-Type': 'application/json'
          }
        })
          .then((response) => {
            if (!response.ok) {
              // handle response error
              console.error(response.statusText);
              dispatch(deleteUserFailure());
            }
            dispatch(deleteUserSuccess(id));
          })
          .catch((error) => {
            // handle error
            console.error(error);
            dispatch(deleteUserFailure());
          });
      })
      .catch(function (error) {
        // fail to get idToken
        console.error(error);
        dispatch(deleteUserFailure());
      });
  };
};

// -----------------------
// Teams
// -----------------------
// GET teams by companies
export const getTeams = (companyId) => {
  return (dispatch) => {
    dispatch(getTeamsBegin());

    // get idToken
    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(function (idToken) {
        // fetch
        fetch(`${CLOUD_API_URL}/companies/${companyId}/teams`, {
          headers: {
            Authorization: `Bearer ${idToken}`
          }
        })
          .then((response) => {
            if (!response.ok) {
              // handle response error
              console.error(response.statusText);
              dispatch(getTeamsFailure());
            }
            return response.json();
          })
          .then((data) => {
            // success
            if (data.exists) {
              dispatch(getTeamsSuccess(data.teams));
            } else {
              dispatch(getTeamsFailure());
            }
          })
          .catch((error) => {
            // handle error
            console.error(error);
            dispatch(getTeamsFailure());
          });
      })
      .catch(function (error) {
        // fail to get idToken
        console.error(error);
        dispatch(getTeamsFailure());
      });
  };
};

// Create new team
export const createTeam = (data) => {
  return (dispatch) => {
    dispatch(createTeamBegin());

    // modify data for valid createUser api structure
    // NOTE users might return combintion of obj and string ie ["1234556", {label: 'name name', value: '456789'}]
    // This is due to react-select + material-table combination
    let modifiedData = {
      ...data,
      displayName: data.name,
      users: data.users
        ? data.users.map((obj) => (obj.value ? obj.value : obj))
        : []
    };
    //////////onsole.log(modifiedData);

    // get idToken
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(function (idToken) {
        // fetch
        fetch(`${CLOUD_API_URL}/teams`, {
          method: 'post',
          headers: {
            Authorization: `Bearer ${idToken}`,
            Accept: 'application/json, text/plain',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(modifiedData)
        })
          .then((response) => {
            if (!response.ok) {
              // handle response error
              console.error(response.statusText);
              dispatch(createTeamFailure());
            }
            return response.json();
          })
          .then((obj) => {
            // success
            //////////onsole.log(obj);
            if (obj.id) {
              dispatch(createTeamSuccess({ ...modifiedData, id: obj.id }));
            } else {
              console.error(obj.message);
              dispatch(createTeamFailure());
            }
          })
          .catch((error) => {
            // handle error
            console.error(error);
            dispatch(createTeamFailure());
          });
      })
      .catch(function (error) {
        // fail to get idToken
        console.error(error);
        dispatch(createTeamFailure());
      });
  };
};

// Patch new team
export const patchTeam = (data) => {
  return (dispatch) => {
    dispatch(patchTeamBegin());

    //////////onsole.log(data);
    // NOTE users might return combintion of obj and string ie ["1234556", {label: 'name name', value: '456789'}]
    // This is due to react-select + material-table combination
    let modifiedData = {
      ...data,
      displayName: data.name,
      users: data.users
        ? data.users.map((obj) => (obj.value ? obj.value : obj))
        : []
    };

    //////////onsole.log(modifiedData);

    // check if modifiedData id exists
    if (!modifiedData.id || modifiedData.id === '') {
      console.error('No id given');
      dispatch(patchTeamFailure());
    } else {
      // get idToken
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then(function (idToken) {
          // fetch
          fetch(`${CLOUD_API_URL}/teams/${modifiedData.id}`, {
            method: 'patch',
            headers: {
              Authorization: `Bearer ${idToken}`,
              Accept: 'application/json, text/plain',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(modifiedData)
          })
            .then((response) => {
              if (!response.ok) {
                // handle response error
                console.error(response.statusText);
                dispatch(patchTeamFailure());
              }

              return response.json();
            })
            .then((obj) => {
              // success
              if (obj.success) {
                dispatch(patchTeamSuccess({ ...modifiedData }));
              } else {
                console.error('Error in patch');
                dispatch(patchTeamFailure());
              }
            })
            .catch((error) => {
              // handle error
              console.error(error);
              dispatch(patchTeamFailure());
            });
        })
        .catch(function (error) {
          // fail to get idToken
          console.error(error);
          dispatch(createTeamFailure());
        });
    }
  };
};

// Delete team
export const deleteTeam = (id) => {
  return (dispatch) => {
    dispatch(deleteTeamBegin());

    // get idToken
    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(function (idToken) {
        // fetch
        fetch(`${CLOUD_API_URL}/teams/${id}`, {
          method: 'delete',
          headers: {
            Authorization: `Bearer ${idToken}`,
            Accept: 'application/json, text/plain',
            'Content-Type': 'application/json'
          }
        })
          .then((response) => {
            if (!response.ok) {
              // handle response error
              console.error(response.statusText);
              dispatch(deleteTeamFailure());
            }
            dispatch(deleteTeamSuccess(id));
          })
          .catch((error) => {
            // handle error
            console.error(error);
            dispatch(deleteTeamFailure());
          });
      })
      .catch(function (error) {
        // fail to get idToken
        console.error(error);
        dispatch(deleteTeamFailure());
      });
  };
};
