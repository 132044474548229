// ImageLinks.js
import React from 'react';
import PropTypes from 'prop-types';
import { urlFor } from './sanityHelper';

function ImageLinks({ node }) {
  const { image, href } = node;
  if (!image) {
    return undefined;
  }
  if (!href) {
    // No links, just image
    return <img src={urlFor(image).auto('format').width(1200).url()} alt="" />;
  }
  // Images with links //blank ?

  // : (
  //   <a href={href}>
  //     <img src={urlFor(image).auto('format').width(1200).url()} alt="" />
  //   </a>
  // );

  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      <img src={urlFor(image).auto('format').width(1200).url()} alt="" />
    </a>
  );
}

ImageLinks.propTypes = {
  node: PropTypes.shape({
    blank: PropTypes.bool,
    image: PropTypes.object,
    href: PropTypes.string
  })
};
export default ImageLinks;
