import React, { useState, useEffect, useRef } from 'react';
import BlockContent from '@sanity/block-content-to-react';
import serializers from '../../../../utils/scriptSerializers';
import FontUtil from '../../../../utils/FontUtility';
import ColorUtil from '../../../../utils/ColorUtility';
// import Circle from './Ellipse 1.png'
// import Arrow from './Vector 1.png'
import Arrow2 from './Vector 2.png';
// import Globe from './Icons V1-45.png'
// import Photo from './Icons V1-44.png'
// import Video from './Icons V1-43.png'
// import Page from './Icons V1-41.png'
// import Scrollindicator from './icon-Scroll-Drag-Right.png'
//import SingleItemDisplay from '../SingleItemDisplay';
import PDFViewer2 from '../../../../components/FullWidthPDFViewer'; //BeforeAfterSlider
import BASlider from '../../../../components/BeforeAfterSlider'; //BeforeAfterSlider
//import BlockContent from '@sanity/block-content-to-react';
// import {renderSingleImageExport} from '../../../../components/_Portfolio/PortfolioContent/PortfolioContent'
// import {contentDataExport} from '../../../../components/_Portfolio/PortfolioContentParentTemplateX/PortfolioContentParent'
// import { Transform } from '@material-ui/icons';
// import { isEmpty, size } from 'lodash';
import { urlFor } from '../../../../utils/sanityHelper';
//import serializers from '../../../../utils/serializers';
const SubMenuItem = ({
  G,
  ScrollPos,
  UNACTIVEEVENT,
  Val,
  pages,
  onButtonClick,
  level,
  images,
  Size,
  videos,
  pdfs,
  isGuestMode,
  chosenPortfolio,
  key,
  SetTopicArray,
  TMGD,
  Single,
  currentContent,
  createSetCurrentContent,
  setTMGD
}) => {
  //FLag Font Family
  //const Limit = 4;
  ////onsole.log("!!CHECK",pages[Val.referenceId]);
  //props.chosenPortfolio.icon_set?.add_person_icon?.override_Color !=='#' && props.chosenPortfolio.icon_set?.add_person_icon?.override_Color !==undefined
  let OVTINT = undefined;
  if (
    chosenPortfolio.overlay_tint_color &&
    chosenPortfolio.overlay_tint_color !== '#' &&
    chosenPortfolio.overlay_tint_color !== undefined
  ) {
    OVTINT = chosenPortfolio.overlay_tint_color + '40';
  }
  let SMColor = undefined;
  if (
    chosenPortfolio.submenu_color &&
    chosenPortfolio.submenu_color !== '#' &&
    chosenPortfolio.submenu_color !== undefined
  )
    SMColor = chosenPortfolio.submenu_color;
  if (
    pages[Val.referenceId].submenu_color &&
    pages[Val.referenceId].submenu_color !== '#' &&
    pages[Val.referenceId].submenu_color !== undefined
  )
    SMColor = pages[Val.referenceId].submenu_color;
  //const [Target, setTarget] = useState(0);
  //const [Items,setItems]= useState(<></>)
  const [Active, SetActive] = useState(false);
  // const test = addEventListener("UNACTIVEEVENT",()=>{SetActive(false)})
  useEffect(() => {
    if (currentContent.page_id !== Val.referenceId) {
      SetActive(false);
      return;
    }
    if (Active)
      Single({
        SetActive: SetActive,
        Single: Single,
        Position: 1,
        currentContent: currentContent,
        createSetCurrentContent: createSetCurrentContent
      });
    //onsole.log("!!!STATUS",currentContent);
  }, [
    Active,
    Single,
    createSetCurrentContent,
    currentContent,
    Val.referenceId
  ]);
  const blockContent = (blocks) => {
    // filter non valid imageLinks
    let tempBlocks = blocks.filter((obj) => {
      if (obj._type === 'imageLinks' && obj.image) {
        return true;
      }
      if (obj._type !== 'imageLinks') {
        return true;
      }
      return false;
    });

    // further filter non valid embedHtml
    tempBlocks = tempBlocks.filter((obj) => {
      if (obj._type === 'embedHTML' && obj.html) {
        return true;
      }
      if (obj._type !== 'embedHTML') {
        return true;
      }
      return false;
    });
    return tempBlocks;
  };
  const contentDataExport = (ID) => {
    //onsole.log("!!#Single",pages[ID], pages[ID].StoryBookgallery.STcontent)
    //let tabData = {};
    // if (
    //   isGuestMode &&
    //   ID !== 0 &&
    //   !isEmpty(currentContentHelper) &&
    //   pages[ID]._type === 'gallery' &&
    //   pages[ID]._key === currentContentHelper.page_id &&
    //   currentContentHelper.is_gallery_item
    // ) {
    //   //////////onsole.log('MODIFY FOR GALLERY CONTENT');
    //   // create obj to only display gallery item chosen by guest
    //   let obj =
    //     pages[ID].content[currentContentHelper.gallery_index];
    //   tabData = [{ ...obj }];
    //   //////////onsole.log(pages[ID]);
    //   //////////onsole.log('!!!!TAB DATA!!!! ', tabData);
    //   return tabData;
    // }
    //////////onsole.log('!!!!CHOSEN CONTENT PAGE ID ', ID);
    return pages[ID].StoryBookgallery.STcontent;
  };
  const renderSingleImageExport = (type, galleryNum, contents) => {
    switch (type) {
      case 'galleryImage': {
        let image = contents[galleryNum].image;
        return (
          <img
            className="portfolio-single-image"
            src={urlFor(image).auto('format').url()} // auto converts to webp if browser supports it
            alt="mainImage"
            type="galleryImage"
            style={{
              height: '100%',
              width: '100%',
              objectFit: 'cover'
              //   opacity: chosenPortfolio && chosenPortfolio.theme === 'theme2' ? opacityBack : 1,
              //   width: imageWidth,
              //   height: imageHeight
            }}
          />
        );
      }
      case 'galleryVideo': {
        let video = videos[contents[galleryNum].video.asset._ref];
        //let videoSettings = contents[galleryNum];
        return (
          <img
            style={{ height: '100%', width: '100%', objectFit: 'contain' }}
            src={`https://image.mux.com/${
              video.playbackId
            }/thumbnail.png?fit_mode=preserve&amp;time=${
              video.thumbTime !== 0 ? video.thumbTime : 1
            }`}
            alt="poster"
          />
          // <SanityMuxPlayer
          //   assetDocument={video}
          //   autoload={true}
          //   autoplay={videoSettings.autoplay}
          //   loop={videoSettings.loop}
          //   muted={false}
          //   showControls={true}
          //   style={{}}
          //   posterurl={`https://image.mux.com/${video.playbackId}/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=1`}
          //   type="galleryVideo"
          //   isSelected={isSelected}
          // />
        );
      }
      case 'galleryPdf': {
        let url2 = pdfs[contents[galleryNum].file.asset._ref].url;
        let thumbNailRef2 = contents[galleryNum].thumbnail.asset._ref;
        let thumbNailUrl2 = urlFor(thumbNailRef2).auto('format').url();
        return (
          <PDFViewer2
            url={url2}
            isThumbnail={true}
            type="galleryFullWidthPdf"
            thumbnailUrl={thumbNailUrl2}
          />
        );
      }
      case 'galleryBASlider': {
        let Aimage = contents[galleryNum].Aimage;
        let Bimage = contents[galleryNum].Bimage;
        let LabelA = contents[galleryNum].AimageL;
        let LabelB = contents[galleryNum].BimageL;
        let Vert = contents[galleryNum].Vert;
        let Color = contents[galleryNum].SlideColor;
        ////////onsole.log('!!!!BA!!!!', contents[galleryNum])
        return (
          <BASlider
            Thumb
            LabelB={LabelB}
            LabelA={LabelA}
            Color={Color}
            Vert={Vert}
            ImageA={Aimage}
            ImageB={Bimage}
          />
        );
      }
      case 'galleryFullWidthPdf': {
        let url3 = pdfs[contents[galleryNum].file.asset._ref].url;
        let thumbNailRef3 = contents[galleryNum].thumbnail.asset._ref;
        let thumbNailUrl3 = urlFor(thumbNailRef3).auto('format').url();
        return (
          <PDFViewer2
            url={url3}
            isThumbnail={true}
            type="galleryFullWidthPdf"
            thumbnailUrl={thumbNailUrl3}
          />
        );
      }
      case 'htmlContent':
        return (
          <div className="html-block-content">
            <BlockContent
              blocks={blockContent(contents)}
              serializers={serializers}
            />
          </div>
        );
      case 'website':
        return (
          <div
            style={{ height: '100%', width: '100%' }}
            className="iframe-container"
          >
            <iframe
              title="iframe"
              allowFullScreen
              src={contents}
              style={{
                backgroundColor: 'white',
                height: '100%',
                width: '100%'
              }}
            ></iframe>
          </div>
        );
      default:
        return <div />;
    }
  };

  // const CanGoDown = ()=>{

  //     ////////onsole.log("down",Target,Target<Val.children.length-Limit)
  //     return Target<Val.children.length - Limit;
  // }
  // const CanGoUp = ()=>{
  //     ////////onsole.log("UP",Target,Target>0)
  //     return Target>0
  // }
  // useEffect(()=>{
  //     setItems(Val.children.map((ele,i)=>{
  //         //////onsole.log("RENDERSUBITEMS",i, Target)
  //         if(i>Target + Limit-1 || i< Target)
  //         return(<></>)
  //         //////onsole.log(pages[ele.referenceId]);
  //         let subtitle = pages[ele.referenceId].subtitle
  //         return(<div key={ele.referenceId} className="SubMenuItemWrapper" style={{display:"grid",gridTemplateRows:"1fr 2px", width:"calc(100% * (563 - (61 + 66)) / 563)", height:"120px", gridTemplateColumns:"1fr 90px"}}>
  //             <div className="SubMenuTextWrapper" style={{ display:"flex", flexDirection:"column", justifyContent:"center", gridColumn:"1/1", marginLeft:"calc(100% * 13 / (563 - (61 + 66))"}}>
  //                 <p className="Header">{ele.title}</p>
  //                 { subtitle && <p className="Subtitle">{subtitle}</p>}
  //             </div>
  //             <div style={{gridColumn:"2/2"}}>
  //                 {/* ICON HERE TO BE DETERMINED */}
  //             </div>
  //             {i !== Target + Limit-1 &&<div style={{gridRow:"2/2", gridColumn:"1/3", backgroundColor:"white"}}></div>}
  //         </div>)
  //     }))
  // },[Target])
  ////onsole.log("SMI",G,Val,pages[Val.referenceId]);
  const Sref = useRef(Size);
  useEffect(() => {
    Sref.current = Size;
  }, [Size]);
  useEffect(() => {
    window.addEventListener('ScrollStop', ScrollStop);
    return () => {
      window.removeEventListener('ScrollStop', ScrollStop);
    };
  });
  const ScrollStop = () => {
    //onsole.log('ScrollStop');
    ScrollDetector();
  };
  const Value = pages[Val.referenceId];
  const RefBack1 = useRef();
  const RefBack2 = useRef();
  const RefBack3 = useRef();
  const RefBack4 = useRef();
  const RefBack5 = useRef();
  const ScrollDetector = () => {
    let temp = document.getElementById('SubMenuRef');
    let ScrollPos = temp.scrollLeft;
    //onsole.log("ScrollStop2",temp.scrollLeft)
    if (temp.scrollLeft === 0 || temp.scrollLeft % Sref.current.W === 0) {
      let test1 = Math.abs(ScrollPos - (G - 1) * Sref.current.W);
      if (RefBack1.current) {
        RefBack1.current.style.transform = `translate(-${
          ((55 * (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
            Sref.current.W /
            100) *
          Size.W
        }px,0%)`.replace('--', '-');
        if (Size.W > 1367) {
          RefBack1.current.style.width = `calc((100% * 1159/1920) + ${
            ((55 *
              (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
              Sref.current.W /
              100) *
            Size.W
          }px)`;
        } else {
          RefBack1.current.style.width = `calc((100% * (1024 - 374)/1024) + ${
            ((55 *
              (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
              Sref.current.W /
              100) *
            Size.W
          }px)`;
        }
      }
      if (RefBack5.current) {
        //RefBack5.current.style.transform = `translate(-${(55 * ((Sref.current.W / 2) - Math.abs(test1 - Sref.current.W / 2))/Sref.current.W)/100*Size.W}px,0%)`.replace("--","-");
        if (Size.W > 1367) {
          RefBack5.current.style.width = `calc(100% + ${
            ((55 *
              (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
              Sref.current.W /
              100) *
            Size.W
          }px)`;
        } else {
          RefBack5.current.style.width = `calc(100% + ${
            ((55 *
              (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
              Sref.current.W /
              100) *
            Size.W
          }px)`;
        }
      }
      if (RefBack2.current) {
        RefBack2.current.style.transform = `translate(${
          (55 * (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
          Sref.current.W
        }%,0%)`.replace('--', '');
        RefBack2.current.style.opacity = `${
          1 -
          (10 * (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
            Sref.current.W
        }`.replace('--', '');
      }
      if (RefBack4.current) {
        //RefBack4.current.style.transform = RefBack1.current.style.transform
      }
      if (RefBack3.current) {
        RefBack3.current.style.opacity = `${Math.min(
          0.3,
          1 -
            (10.5 *
              (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
              Sref.current.W
        )}`.replace('--', '');
        RefBack3.current.style.transform = `translate(${
          (110 * (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
          Sref.current.W
        }%,0%)`.replace('--', '');
      }
    }

    // ////onsole.log("ScrollTest",ScrollPos - G-1*Size.W,ScrollPos,G-1*Size.W)
    let test1 = Math.abs(ScrollPos - (G - 1) * Sref.current.W);
    if (test1 > Sref.current.W || test1 === 0 || test1 === Sref.current.W) {
      if (RefBack1.current) {
        RefBack1.current.style.transform = null;
        RefBack1.current.style.width = null;
      }
      if (RefBack2.current) RefBack2.current.style.transform = null;
      if (RefBack3.current) {
        RefBack3.current.style.opacity = null;
        RefBack3.current.style.transform = null;
      }
      if (RefBack5.current) RefBack5.current.style.width = null;
      //onsole.log("ScrollStop2","End1")
      return;
    } else if (ScrollPos - (G - 1) * Sref.current.W > 0) {
      //Ending from Right //&& ScrollPos - (G-1)*Sref.current.W > 0 //test1>Sref.current.W / 2
      //////onsole.log("SCROLLTEST",`1 - ${1 * ((Sref.current.W / 2) - Math.abs(test1 - Sref.current.W / 2))/Sref.current.W}`)
      if (RefBack1.current) {
        RefBack1.current.style.transform = `translate(-${
          ((55 * (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
            Sref.current.W /
            100) *
          Size.W
        }px,0%)`.replace('--', '-');
        if (Size.W > 1367) {
          RefBack1.current.style.width = `calc((100% * 1159/1920) + ${
            ((55 *
              (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
              Sref.current.W /
              100) *
            Size.W
          }px)`;
        } else {
          RefBack1.current.style.width = `calc((100% * (1024 - 374)/1024) + ${
            ((55 *
              (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
              Sref.current.W /
              100) *
            Size.W
          }px)`;
        }
      }
      if (RefBack5.current) {
        //RefBack5.current.style.transform = `translate(-${(55 * ((Sref.current.W / 2) - Math.abs(test1 - Sref.current.W / 2))/Sref.current.W)/100*Size.W}px,0%)`.replace("--","-");
        if (Size.W > 1367) {
          RefBack5.current.style.width = `calc(100% + ${
            ((55 *
              (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
              Sref.current.W /
              100) *
            Size.W
          }px)`;
        } else {
          RefBack5.current.style.width = `calc(100% + ${
            ((55 *
              (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
              Sref.current.W /
              100) *
            Size.W
          }px)`;
        }
      }
      if (RefBack2.current) {
        RefBack2.current.style.transform = `translate(${
          (55 * (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
          Sref.current.W
        }%,0%)`.replace('--', '');
        RefBack2.current.style.opacity = `${
          1 -
          (10 * (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
            Sref.current.W
        }`.replace('--', '');
      }
      if (RefBack4.current) {
        //RefBack4.current.style.transform = RefBack1.current.style.transform
      }
      if (RefBack3.current) {
        RefBack3.current.style.opacity = `${Math.min(
          0.3,
          1 -
            (10.5 *
              (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
              Sref.current.W
        )}`.replace('--', '');
        RefBack3.current.style.transform = `translate(${
          (110 * (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
          Sref.current.W
        }%,0%)`.replace('--', '');
      }
      //   if(RefBack1.current)
      //   RefBack1.current.style.transform = `translate(${-Math.pow((ScrollPos + ((G-1)*Sref.current.W - Sref.current.W/2)/100),2)+50}%,0%)`.replace("--","");
      //     if(RefBack2.current)
      // RefBack2.current.style.transform = `translate(${-Math.pow((ScrollPos + ((G-1)*Sref.current.W - Sref.current.W/2)/100),2)+50}%,0%)`.replace("--","");
      //     if(RefBack1.current)
      //   RefBack1.current.style.transform = `translate(${-(ScrollPos + ((G-1)*Sref.current.W - Sref.current.W/2))^2+50}%,0%)`.replace("--","");
      //     if(RefBack2.current)
      // RefBack2.current.style.transform = `translate(${-(ScrollPos + ((G-1)*Sref.current.W - Sref.current.W/2))^2+50}%,0%)`.replace("--","");
      //onsole.log("ScrollStop2","End2")
    } else {
      //Starting from Right
      if (RefBack1.current) {
        RefBack1.current.style.transform = `translate(-${
          ((55 * (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
            Sref.current.W /
            100) *
          Size.W
        }px,0%)`.replace('--', '-');
        if (Size.W > 1367) {
          RefBack1.current.style.width = `calc((100% * 1159/1920) + ${
            ((55 *
              (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
              Sref.current.W /
              100) *
            Size.W
          }px)`;
        } else {
          RefBack1.current.style.width = `calc((100% * (1024 - 374)/1024) + ${
            ((55 *
              (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
              Sref.current.W /
              100) *
            Size.W
          }px)`;
        }
      }
      if (RefBack5.current) {
        //RefBack5.current.style.transform = `translate(-${(55 * ((Sref.current.W / 2) - Math.abs(test1 - Sref.current.W / 2))/Sref.current.W)/100*Size.W}px,0%)`.replace("--","-");
        if (Size.W > 1367) {
          RefBack5.current.style.width = `calc(100% + ${
            ((55 *
              (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
              Sref.current.W /
              100) *
            Size.W
          }px)`;
        } else {
          RefBack5.current.style.width = `calc(100% + ${
            ((55 *
              (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
              Sref.current.W /
              100) *
            Size.W
          }px)`;
        }
      }
      if (RefBack2.current) {
        RefBack2.current.style.transform = `translate(-${
          (55 * (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
          Sref.current.W
        }%,0%)`.replace('--', '-');
        RefBack2.current.style.opacity = `${
          1 -
          (10 * (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
            Sref.current.W
        }`.replace('--', '');
      }
      if (RefBack4.current) {
        //RefBack4.current.style.transform = RefBack1.current.style.transform
      }
      if (RefBack3.current) {
        RefBack3.current.style.opacity = `${Math.min(
          0.3,
          1 -
            (10.5 *
              (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
              Sref.current.W
        )}`.replace('--', '');
        RefBack3.current.style.transform = `translate(-${
          (110 * (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
          Sref.current.W
        }%,0%)`.replace('--', '-');
      }
      //onsole.log("ScrollStop2","End3")
    }
    //OG
    // else{
    //   // ////onsole.log("ScrollTest",`translate(${ScrollPos - (G-1)*Sref.current.W>0?"-":"-"}${100 * (ScrollPos - (G-1)*Sref.current.W)/Sref.current.W}%,0%)`)
    //   if(RefBack1.current)
    //   RefBack1.current.style.transform = `translate(${ScrollPos - (G-1)*Sref.current.W>0?"-":"-"}${100 * (ScrollPos - (G-1)*Sref.current.W)/Sref.current.W}%,0%)`.replace("--","");
    //   if(RefBack2.current)
    // RefBack2.current.style.transform = `translate(${ScrollPos - (G-1)*Sref.current.W>0?"-":"-"}${100 * (ScrollPos - (G-1)*Sref.current.W)/Sref.current.W}%,0%)`.replace("--","");
    // }
  };

  //////onsole.log("VALUE",Value)
  const test = useRef();
  useEffect(() => {
    try {
      test.current.addEventListener(
        'UNACTIVEEVENT',
        () => {
          SetActive(false);
        },
        false
      );
    } catch (err) {
      console.error('Error', err);
    }
  });
  // useEffect(()=>{
  //   //onsole.log("!!CURRENTCONTENT",currentContent)
  // },[currentContent])
  useEffect(() => {
    let temp = document.getElementById('SubMenuRef');
    temp.addEventListener('scroll', ScrollDetector);
    return () => {
      temp.removeEventListener('scroll', ScrollDetector);
    };
  });
  //onsole.log("pages[chosenContentPageId]",pages[Val.referenceId]?.StoryBookObjects)
  let SMCTC = pages[Val.referenceId]?.submenu_center_third_color
    ? pages[Val.referenceId]?.submenu_center_third_color + 'b2'
    : null;
  const [DesHeadSize] = useState(
    pages[Val.referenceId]?.StoryBookObjects?.descriptionHeaderFontsize
      ? pages[Val.referenceId]?.StoryBookObjects?.descriptionHeaderFontsize +
          'px'
      : '56px'
  );
  const [DesHeadSizeT] = useState(
    pages[Val.referenceId]?.StoryBookObjects?.descriptionHeaderFontsize
      ? pages[Val.referenceId]?.StoryBookObjects?.descriptionHeaderFontsize *
          (36 / 56) +
          'px'
      : '36px'
  );
  const [DesSize] = useState(
    pages[Val.referenceId]?.StoryBookObjects?.descriptionFontsize
      ? pages[Val.referenceId]?.StoryBookObjects?.descriptionFontsize + 'px'
      : '20px'
  );
  const [DesSizeT] = useState(
    pages[Val.referenceId]?.StoryBookObjects?.descriptionFontsize
      ? pages[Val.referenceId]?.StoryBookObjects?.descriptionFontsize *
          (20 / 20) +
          'px'
      : '20px'
  );
  return (
    <>
      {/* <div className="ScrollIndicator" style={{maskImage:`url(${Scrollindicator})`,WebkitMaskImage:`url(${Scrollindicator})`, maskSize:"100% 100%", WebkitMaskSize:'100% 100%', }}></div> */}
      {/* width:"calc(100% * (1920 - 1159)/1920", */}
      <div className="SubmenuItemBackground">
        {/* CONTENT HERE TO BE DETERMINED */}
        {pages[Val.referenceId]._type !== 'gallery' &&
        pages[Val.referenceId]._type !== 'STgallery' ? (
          pages[Val.referenceId]._type !== 'website' ? (
            renderSingleImageExport(
              pages[Val.referenceId]._type,
              0,
              contentDataExport(Val.referenceId)
            )
          ) : (
            renderSingleImageExport(
              pages[Val.referenceId]._type,
              0,
              pages[Val.referenceId].url
            )
          )
        ) : // //////onsole.log("WEBSITE")
        //renderSingleImageExport(pages[Val.referenceId]._type,0,contentDataExport(Val.referenceId))
        pages[Val.referenceId].content[0] ? (
          renderSingleImageExport(
            contentDataExport(Val.referenceId)[0]._type,
            0,
            contentDataExport(Val.referenceId)
          )
        ) : (
          <div id="Null"></div>
        )}
        {/* {//////onsole.log(pages[Val.referenceId]._type)} */}
        <div
          style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            background: OVTINT
          }}
        ></div>
      </div>
      <div
        ref={RefBack1}
        className="SubmenuItemMenuBackground"
        style={{ background: SMColor }}
      >
        <div
          className="SubmenuItemMenuBackground1"
          style={{ position: 'absolute', top: '0px', left: '0px' }}
        >
          <div
            ref={RefBack5}
            id="SubpageBlockContentGrowth"
            className="SubpageBlockContent"
            onWheel={(eve) => {
              eve.preventDefault();
              eve.stopPropagation();
            }}
            style={{
              backgroundColor: ColorUtil(
                pages[Val.referenceId]?.submenu_center_third_color,
                chosenPortfolio.Submenu_center_third_color
              )
            }}
          >
            <div
              className="ColorBar"
              style={{
                backgroundColor: chosenPortfolio.accent_color
                  ? chosenPortfolio.accent_color
                  : '',
                width: '100%'
              }}
            />
          </div>
          <p
            ref={RefBack3}
            className={`OverGhost ${
              pages[Val.referenceId]?.StoryBookObjects?.oversizedTextSize
            }`}
            style={{
              color: pages[Val.referenceId]?.StoryBookObjects
                ?.oversizedTextColor
                ? pages[Val.referenceId]?.StoryBookObjects?.oversizedTextColor
                : undefined,
              fontFamily: FontUtil(
                pages[Val.referenceId]?.StoryBookObjects?.overghostFont,
                chosenPortfolio.ContentText
              )
            }}
          >
            {pages[Val.referenceId]?.StoryBookObjects?.oversizedText}
          </p>
          {/* display:TMGD?"none":"flex", */}
          <p
            className="SubtitleHeader"
            style={{
              color: pages[Val.referenceId].text_color
                ? pages[Val.referenceId].text_color
                : undefined,
              fontFamily: FontUtil(
                pages[Val.referenceId]?.SubtitleText,
                chosenPortfolio.ContentText
              )
            }}
          >
            {pages[Val.referenceId]?.subtitle}
          </p>
          <div
            className="SubpageBlockContent"
            onWheel={(eve) => {
              eve.preventDefault();
              eve.stopPropagation();
            }}
            style={{
              overscrollBehaviorX: TMGD ? null : 'auto',
              backgroundColor: ColorUtil(
                pages[Val.referenceId]?.submenu_center_third_color,
                chosenPortfolio.Submenu_center_third_color
              )
            }}
          >
            <div
              className="ColorBar"
              style={{
                backgroundColor: chosenPortfolio.accent_color
                  ? chosenPortfolio.accent_color
                  : ''
              }}
            />
            <div
              style={{
                touchAction: 'pan-y',
                overscrollBehaviorX: TMGD ? null : 'auto',
                height: '100%',
                position: 'relative',
                overflowY: 'scroll',
                overflowX: 'hidden'
              }}
            >
              <div
                className="SubpageBlockContentWrapper header"
                style={{
                  overscrollBehaviorX: TMGD ? null : 'auto',
                  fontSize:
                    window.innerWidth < 1367 ? DesHeadSizeT : DesHeadSize,
                  fontFamily: FontUtil(
                    pages[Val.referenceId]?.StoryBookObjects
                      ?.descriptionHeaderFont,
                    chosenPortfolio.ContentText
                  )
                }}
              >
                {/* width:"calc(100% * 773/1159)", */}
                {pages[Val.referenceId]?.StoryBookObjects
                  ?.descriptionHeader && (
                  <BlockContent
                    blocks={
                      pages[Val.referenceId]?.StoryBookObjects
                        ?.descriptionHeader
                    }
                    serializers={serializers}
                  ></BlockContent>
                )}
              </div>
              {/* (460 * 2) + 37
(460 * 2) + 37 */}
              <div
                className="SubpageBlockContentWrapper description SingleItem"
                style={{
                  overscrollBehaviorX: TMGD ? null : 'auto',
                  fontSize: window.innerWidth < 1367 ? DesSizeT : DesSize,
                  fontFamily: FontUtil(
                    pages[Val.referenceId]?.StoryBookObjects?.descriptionFont,
                    chosenPortfolio.ContentText
                  )
                }}
              >
                {pages[Val.referenceId]?.StoryBookObjects?.description && (
                  <BlockContent
                    blocks={
                      pages[Val.referenceId]?.StoryBookObjects?.description
                    }
                    serializers={serializers}
                  ></BlockContent>
                )}
              </div>
            </div>
            {/* 320px width, 0 right */}
            {pages[Val.referenceId]?.StoryBookgallery?.singleItemTitle && (
              <div
                className="SubpageBlockContentFooterSingle"
                onClick={() => {
                  setTMGD(true);
                  SetActive(true);
                  Single({
                    SetActive: SetActive,
                    Single: Single,
                    Position: 1,
                    currentContent: currentContent,
                    createSetCurrentContent: createSetCurrentContent
                  });
                }}
              >
                {/* <div className="TextContainer"> */}
                <p
                  className="Title"
                  style={{
                    color: ColorUtil(
                      pages[Val.referenceId].text_color,
                      chosenPortfolio.text_color,
                      'black'
                    )
                  }}
                >
                  {pages[Val.referenceId]?.StoryBookgallery?.singleItemTitle}
                </p>
                <p
                  className="Subtitle"
                  style={{
                    color: ColorUtil(
                      pages[Val.referenceId].text_color,
                      chosenPortfolio.text_color,
                      'black'
                    )
                  }}
                >
                  {pages[Val.referenceId]?.StoryBookgallery?.singleItemSubtitle}
                </p>
                {/* </div> */}
                <img className="ArrowContainer" src={Arrow2} alt=""></img>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        ref={RefBack2}
        className="SubpageMenuContent Single"
        onClick={() => {
          setTMGD(true);
          SetActive(true);
          Single({
            SetActive: SetActive,
            Single: Single,
            Position: 1,
            currentContent: currentContent,
            createSetCurrentContent: createSetCurrentContent
          });
        }}
        style={{
          display: TMGD ? 'none' : 'flex',
          backgroundColor: ColorUtil(
            SMCTC,
            chosenPortfolio.Submenu_center_third_color + 'b2'
          )
        }}
      >
        {Value?._type !== 'gallery' && Value?._type !== 'STgallery'
          ? Value?._type !== 'website'
            ? renderSingleImageExport(
                Value?._type,
                1,
                contentDataExport(Val.referenceId)
              )
            : renderSingleImageExport(Value?._type, 1, Value?.url)
          : // //////onsole.log("WEBSITE")
            //renderSingleImageExport(Value?._type,0,contentDataExport(Val.referenceId))
            renderSingleImageExport(
              contentDataExport(Val.referenceId)[1]?._type,
              1,
              contentDataExport(Val.referenceId)
            )}
        {/* {//////onsole.log(Value?._type)} */}
      </div>
    </>
  );
};
export default SubMenuItem;

// {Val.children.map((ele,i)=>{
//     //////onsole.log("RENDERSUBITEMS",i, Target)
//     if(i>Target + Limit-1 || i< Target)
//     return(<></>)
//     //////onsole.log(pages[ele.referenceId]);
//     let subtitle = pages[ele.referenceId].subtitle
//     return(<div key={ele.referenceId} className="SubMenuItemWrapper" style={{display:"grid",gridTemplateRows:"1fr 2px", width:"calc(100% * (563 - (61 + 66)) / 563)", height:"120px", gridTemplateColumns:"1fr 90px"}}>
//         <div className="SubMenuTextWrapper" style={{ display:"flex", flexDirection:"column", justifyContent:"center", gridColumn:"1/1", marginLeft:"calc(100% * 13 / (563 - (61 + 66))"}}>
//             <p className="Header">{ele.title}</p>
//             { subtitle && <p className="Subtitle">{subtitle}</p>}
//         </div>
//         <div style={{gridColumn:"2/2"}}>
//             {/* ICON HERE TO BE DETERMINED */}
//         </div>
//         {i !== Target + Limit-1 &&<div style={{gridRow:"2/2", gridColumn:"1/3", backgroundColor:"white"}}></div>}
//     </div>)
// })}
