import { connect } from 'react-redux';
import Menu3 from './Menu3';
import { SetTopic, setHasCurr, setHasPrev } from '../../../actions';

const mapStateToProps = (state) => ({
  companyConfig: state.sanityContentReducer.companyConfig,
  chosenPortfolio: state.portfolioReducer.chosenPortfolio,
  Topic: state.pageMenuReducer.Topic,
  hasCurr: state.pageMenuReducer.hasCurr,
  hasPrev: state.pageMenuReducer.hasPrev
});

const mapDispatchToProps = (dispatch) => {
  return {
    setTopic: (Topic) => dispatch(SetTopic(Topic)),
    setHasCurr: (bool) => dispatch(setHasCurr(bool)),
    setHasPrev: (bool) => dispatch(setHasPrev(bool))
  };
};

const Menu3Container = connect(mapStateToProps, mapDispatchToProps)(Menu3);

export default Menu3Container;
