// video reducer to keep track of current video state

import {
  SET_IS_VIDEO_PLAYING,
  RESET_PORTFOLIO_INFO,
  END_PORTFOLIO
} from '../constants/actionTypes';

const initialState = {
  isVideoPlaying: false
};
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_VIDEO_PLAYING:
      return {
        ...state,
        isVideoPlaying: action.bool
      };
    case RESET_PORTFOLIO_INFO:
    case END_PORTFOLIO:
      return initialState;
    default:
      return state;
  }
};
