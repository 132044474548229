export const sortByKey = (List, StringProp) => {
  try {
    return List.sort((a, b) => a[StringProp].localeCompare(b[StringProp]));
  } catch {
    return testSorter(List, StringProp);
  }
};
const testSorter = (List, StringProp) => {
  return List.sort((a, b) => {
    if (a[StringProp] > b[StringProp]) return 1;
    else if (a[StringProp] < b[StringProp]) return -1;
    return 0;
  });
};
export const sortStringLiteral = (List) => {
  try {
    return List.sort((a, b) => a.localeCompare(b));
  } catch {
    return StringLiteralFallback(List);
  }
};
const StringLiteralFallback = (List) => {
  return List.sort((a, b) => {
    if (a > b) return 1;
    else if (a < b) return -1;
    return 0;
  });
};
export default sortByKey;
