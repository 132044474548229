import { connect } from 'react-redux';
import PortfolioContentParent3 from './PortfolioContentParent3';
import {
  setChosenPageId,
  setContentPageId,
  setIsAtEnd,
  setCurrentContentHelper,
  setNavHistory,
  setIsVideoPlaying,
  SetTopic,
  setSavedContentPageId,
  setHasCurr,
  setHasPrev
} from '../../../actions';

const mapStateToProps = (state) => ({
  images: state.sanityContentReducer.images,
  videos: state.sanityContentReducer.videos,
  pdfs: state.sanityContentReducer.pdfs,
  companyConfig: state.sanityContentReducer.companyConfig,
  chosenPortfolio: state.portfolioReducer.chosenPortfolio,
  pages: state.portfolioReducer.pages,
  nav: state.portfolioReducer.nav,
  pageBackgroundImageUrl: state.portfolioReducer.pageBackgroundImageUrl,
  isGuestMode: state.guestReducer.isGuestMode,
  isPortfolioView: state.guestReducer.isPortfolioView,
  guestPages: state.guestReducer.guestPages,
  guestContentHelper: state.guestReducer.guestContentHelper,
  currentContentHelper: state.guestReducer.currentContentHelper,
  isAtEnd: state.pageMenuReducer.isAtEnd,
  currentLevel: state.pageMenuReducer.currentLevel,
  chosenPageId: state.pageMenuReducer.chosenPageId,
  chosenContentPageId: state.pageMenuReducer.chosenContentPageId,
  navHistory: state.pageMenuReducer.navHistory,
  clickedTerminusId: state.pageMenuReducer.savedChosenContentPageId,
  Topic: state.pageMenuReducer.Topic,
  screensaverShow: state.portfolioReducer.screensaverShow,
  hasCurr: state.pageMenuReducer.hasCurr,
  hasPrev: state.pageMenuReducer.hasPrev
});

const mapDispatchToProps = (dispatch) => {
  return {
    setChosenPageId: (id) => dispatch(setChosenPageId(id)),
    setContentPageId: (id) => dispatch(setContentPageId(id)),
    setIsAtEnd: (bool) => dispatch(setIsAtEnd(bool)),
    setCurrentContentHelper: (id) => dispatch(setCurrentContentHelper(id)), // helps guest content
    setNavHistory: (array) => dispatch(setNavHistory(array)),
    setTopic: (Topic) => dispatch(SetTopic(Topic)),
    setIsVideoPlaying: (bool) => dispatch(setIsVideoPlaying(bool)),
    setSavedContentPageId: (id) => dispatch(setSavedContentPageId(id)),
    setHasCurr: (bool) => dispatch(setHasCurr(bool)),
    setHasPrev: (bool) => dispatch(setHasPrev(bool))
  };
};

const PortfolioContentParent3Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(PortfolioContentParent3);

export default PortfolioContentParent3Container;
