import { connect } from 'react-redux';
import SessionSelect from './SessionSelect.js';
import {
  setShowSessionSelect,
  setDefaultVisit,
  setSessionDateString,
  setAggregateSelected,
  setShowPortfolioSelect,
  setEfficiencyUserToggle,
  SetDaterangeDispatch,
  setSecondaryUserSessionToggle,
  setSecondaryUserAggregateToggle
} from '../../../actions';

const mapStateToProps = (state) => ({
  showSessionSelect: state.DashboardReducer.showSessionSelect,
  sessionList: state.DashboardReducer.sessionList,
  sessionDateString: state.DashboardReducer.sessionDateString,
  efficiencyUserToggle: state.DashboardReducer.efficiencyUserToggle,
  secondaryUserSessionToggle: state.DashboardReducer.secondaryUserSessionToggle,
  secondaryUserAggregateToggle:
    state.DashboardReducer.secondaryUserAggregateToggle,
  Size: state.portfolioReducer.Size
});

const mapDispatchToProps = (dispatch) => {
  return {
    setShowSessionSelect: (bool) => dispatch(setShowSessionSelect(bool)),
    setDefaultVisit: (visit) => dispatch(setDefaultVisit(visit)),
    setSessionDateString: (string) => dispatch(setSessionDateString(string)),
    setAggregateSelected: (value) => dispatch(setAggregateSelected(value)),
    SetDaterangeDispatch: (value) => dispatch(SetDaterangeDispatch(value)),
    setShowPortfolioSelect: (bool) => dispatch(setShowPortfolioSelect(bool)),
    setEfficiencyUserToggle: (toggle) =>
      dispatch(setEfficiencyUserToggle(toggle)),
    setSecondaryUserSessionToggle: (toggle) =>
      dispatch(setSecondaryUserSessionToggle(toggle)),
    setSecondaryUserAggregateToggle: (toggle) =>
      dispatch(setSecondaryUserAggregateToggle(toggle))
  };
};

const SessionSelectContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SessionSelect);

export default SessionSelectContainer;
