import { React } from 'react';

const ArrowIcon = ({ Fill, Rotation, onClick, className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => {
        onClick();
      }}
      width={100}
      height={100}
      style={{ transform: `rotate(${Rotation}deg)` }}
    >
      <circle cx={50} cy={50} r={50} fill={Fill}></circle>
      <polyline
        points="57,70.5 36,49 57,27.5"
        fill="transparent"
        stroke="white"
        strokeWidth={6}
      ></polyline>
      {/*  filter='blur(2px)' */}
    </svg>
  );
};
export default ArrowIcon;
