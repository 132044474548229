import React, { useState } from 'react';
import { urlFor } from '../../../utils/sanityHelper';
import SanityMuxPlayer from '../../../containers/MuxPlayer';
//import PDFViewer from '../../PDFViewer';
import PDFViewer2 from '../../FullWidthPDFViewer';
import BASlider from '../../BeforeAfterSlider';
import styled from 'styled-components';

const ToggleSlider = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px !important;
  width: 300px;
  background-color: white;
  bottom: ${(props) =>
    props.contentLength === 1 && props.theme === 'theme2'
      ? '-2%'
      : '-5%'} !important;
  left: calc(50% - 150px);
  border-radius: 12px;
  cursor: pointer;
  z-index: 4;

  @media (max-width: 1367px) {
    bottom: ${(props) =>
      props.contentLength === 1 && props.theme === 'theme2'
        ? '-0%'
        : '-10%'} !important;
  }

  .left {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    width: 50%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding-bottom: 1px;

    .inner {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      height: 100% !important;
      width: ${(props) =>
        props.contentLength >= 2 ? '100%' : '100%'} !important;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      padding-left: 10px;
      padding-right: 10px;
      overflow: hidden;

      p {
        height: auto !important;
        margin-block-start: 0px;
        margin-block-end: 0px;
        font-weight: 600;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 14px;
      }
    }
  }

  .right {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 50%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-bottom: 1px;

    .inner {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      height: 100% !important;
      width: ${(props) =>
        props.contentLength >= 2 ? '100%' : '100%'} !important;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      padding-left: 10px;
      padding-right: 10px;
      overflow: hidden;

      p {
        height: auto !important;
        margin-block-start: 0px;
        margin-block-end: 0px;
        font-weight: 600;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 14px;
      }
    }
  }
`;

const ToggleMenu = ({
  videos,
  pdfs,
  chosenPortfolio,
  currentNavItem,
  firstContent,
  secondContent,
  contentLength,
  style
}) => {
  // //////onsole.log("CONTENT LENGTH in TOGGLE MENU", contentLength);

  const [contentToggle, setContentToggle] = useState(1);

  const toggleContent = () => {
    if (contentToggle === 1) {
      setContentToggle(2);
    } else {
      setContentToggle(1);
    }
  };

  let imageHeight;
  let imageWidth;

  if (
    chosenPortfolio?.theme === 'theme2' &&
    (!currentNavItem || currentNavItem?.children.length === 1)
  ) {
    imageHeight = '80%';
  } else if (
    chosenPortfolio?.theme === 'theme2' &&
    currentNavItem?.children.length === 2
  ) {
    imageHeight = '60%';
  } else {
    imageHeight = '100%';
  }

  if (
    chosenPortfolio?.theme === 'theme2' &&
    (!currentNavItem || currentNavItem?.children.length === 1)
  ) {
    imageWidth = '80%';
  } else {
    imageWidth = '100%';
  }

  let contentRender1;
  let contentRender2;

  switch (firstContent._type) {
    case 'galleryImage': {
      let image = firstContent.image;
      contentRender1 = (
        <img
          className="portfolio-single-image"
          src={urlFor(image).auto('format').url()} // auto converts to webp if browser supports it
          alt="mainImage"
          type="galleryImage"
          style={{
            width: imageWidth,
            height: imageHeight
          }}
        />
      );
      break;
    }
    case 'galleryVideo': {
      let video = videos[firstContent.video.asset._ref];
      let videoSettings = firstContent;
      // let Subtitles = firstContent.subtitles
      ////////onsole.log(videoSettings);
      contentRender1 = (
        <SanityMuxPlayer
          assetDocument={video}
          autoload={true}
          subtitles={videoSettings.subtitles}
          autoplay={videoSettings.autoplay}
          loop={videoSettings.loop}
          SIG={videoSettings.SIG}
          SIP={videoSettings.SIP}
          muted={false}
          showControls={true}
          style={{}}
          posterurl={`https://image.mux.com/${video.playbackId}/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=1`}
          type="galleryVideo"
          isSelected={false}
        />
      );
      break;
    }
    case 'galleryPdf': {
      let url2 = pdfs[firstContent.file.asset._ref].url;
      let thumbNailRef2 = firstContent.thumbnail.asset._ref;
      let thumbNailUrl2 = urlFor(thumbNailRef2).auto('format').width(100).url();
      let thumbNailUrl3 = urlFor(thumbNailRef2)
        .auto('format')
        .width(1000)
        .url();
      contentRender1 = (
        <PDFViewer2
          isVis={true}
          IsGal={true}
          url={url2}
          isThumbnail={false}
          TThumb={thumbNailUrl3}
          type="galleryFullWidthPdf"
          thumbnailUrl={thumbNailUrl2}
          title={firstContent.title}
        />
      );
      break;
    }
    case 'galleryFullWidthPdf': {
      let url3 = pdfs[firstContent.file.asset._ref].url;
      let thumbNailRef3 = firstContent.thumbnail.asset._ref;
      let thumbNailUrl4 = urlFor(thumbNailRef3).auto('format').width(100).url();
      let thumbNailUrl5 = urlFor(thumbNailRef3)
        .auto('format')
        .width(1000)
        .url();
      contentRender1 = (
        <PDFViewer2
          isVis={true}
          IsGal={true}
          url={url3}
          isThumbnail={false}
          TThumb={thumbNailUrl5}
          type="galleryFullWidthPdf"
          thumbnailUrl={thumbNailUrl4}
          title={firstContent.title}
        />
      );
      break;
    }
    case 'galleryBASlider': {
      let Aimage = firstContent.Aimage;
      let Bimage = firstContent.Bimage;
      let LabelA = firstContent.AimageL;
      let LabelB = firstContent.BimageL;
      let Vert = firstContent.Vert;
      let Color = firstContent.SlideColor;
      contentRender1 = (
        <BASlider
          LabelB={LabelB}
          LabelA={LabelA}
          Color={Color}
          Vert={Vert}
          ImageA={Aimage}
          ImageB={Bimage}
        />
      );
      break;
    }
    default:
      return <div />;
  }

  switch (secondContent._type) {
    case 'galleryImage': {
      let image = secondContent.image;
      contentRender2 = (
        <img
          className="portfolio-single-image"
          src={urlFor(image).auto('format').url()} // auto converts to webp if browser supports it
          alt="mainImage"
          type="galleryImage"
          style={{
            width: imageWidth,
            height: imageHeight
          }}
        />
      );
      break;
    }
    case 'galleryVideo': {
      let video = videos[secondContent.video.asset._ref];
      let videoSettings = secondContent;
      // let Subtitles = secondContent.subtitles
      ////////onsole.log(videoSettings);
      contentRender2 = (
        <SanityMuxPlayer
          assetDocument={video}
          autoload={true}
          // subtitles={Subtitles}
          subtitles={videoSettings.subtitles}
          autoplay={videoSettings.autoplay}
          loop={videoSettings.loop}
          SIG={videoSettings.SIG}
          SIP={videoSettings.SIP}
          muted={false}
          showControls={true}
          style={{}}
          posterurl={`https://image.mux.com/${video.playbackId}/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=1`}
          type="galleryVideo"
          isSelected={false}
        />
      );
      break;
    }
    case 'galleryPdf': {
      let url2 = pdfs[secondContent.file.asset._ref].url;
      let thumbNailRef2 = secondContent.thumbnail.asset._ref;
      let thumbNailUrl2 = urlFor(thumbNailRef2).auto('format').width(100).url();
      let thumbNailUrl3 = urlFor(thumbNailRef2)
        .auto('format')
        .width(1000)
        .url();
      contentRender2 = (
        <PDFViewer2
          isVis={true}
          IsGal={true}
          url={url2}
          isThumbnail={false}
          TThumb={thumbNailUrl3}
          type="galleryFullWidthPdf"
          thumbnailUrl={thumbNailUrl2}
          title={secondContent.title}
        />
      );
      break;
    }
    case 'galleryFullWidthPdf': {
      let url3 = pdfs[secondContent.file.asset._ref].url;
      let thumbNailRef3 = secondContent.thumbnail.asset._ref;
      let thumbNailUrl4 = urlFor(thumbNailRef3).auto('format').width(100).url();
      let thumbNailUrl5 = urlFor(thumbNailRef3)
        .auto('format')
        .width(1000)
        .url();
      contentRender2 = (
        <PDFViewer2
          isVis={true}
          IsGal={true}
          url={url3}
          isThumbnail={false}
          TThumb={thumbNailUrl5}
          type="galleryFullWidthPdf"
          thumbnailUrl={thumbNailUrl4}
          title={secondContent.title}
        />
      );
      break;
    }
    case 'galleryBASlider': {
      let Aimage = secondContent.Aimage;
      let Bimage = secondContent.Bimage;
      let LabelA = secondContent.AimageL;
      let LabelB = secondContent.BimageL;
      let Vert = secondContent.Vert;
      let Color = secondContent.SlideColor;
      contentRender2 = (
        <BASlider
          LabelB={LabelB}
          LabelA={LabelA}
          Color={Color}
          Vert={Vert}
          ImageA={Aimage}
          ImageB={Bimage}
        />
      );
      break;
    }
    default:
      return <div />;
  }

  // render nothing if no content
  // if (!contents && !page.url) {
  //   return null;
  // }
  //Flag Font Family
  return (
    <div
      className="portfolio-content"
      style={{
        height: contentLength >= 2 ? '90%' : '80%',
        position: 'relative',
        ...style
      }}
    >
      <ToggleSlider
        contentLength={contentLength}
        theme={chosenPortfolio.theme}
        onClick={() => toggleContent()}
      >
        <div className="left" style={{}}>
          <div
            className="inner"
            style={{
              backgroundColor:
                contentToggle === 1 && chosenPortfolio?.primary_color
                  ? chosenPortfolio.primary_color
                  : 'white',
              boxShadow:
                contentToggle === 1 ? '0px 2px 3px 0px #808080' : 'none'
            }}
          >
            <p
              style={{
                color:
                  contentToggle === 1 && chosenPortfolio?.text_color
                    ? chosenPortfolio.text_color
                    : 'black'
              }}
            >
              {firstContent.title}
            </p>
          </div>
        </div>
        <div className="right" style={{}}>
          <div
            className="inner"
            style={{
              backgroundColor:
                contentToggle === 2 && chosenPortfolio?.primary_color
                  ? chosenPortfolio.primary_color
                  : 'white',
              boxShadow:
                contentToggle === 2 ? '1px 2px 3px 0px #808080' : 'none'
            }}
          >
            <p
              style={{
                color:
                  contentToggle === 2 && chosenPortfolio?.text_color
                    ? chosenPortfolio.text_color
                    : 'black'
              }}
            >
              {secondContent.title}
            </p>
          </div>
        </div>
      </ToggleSlider>
      {contentToggle === 1 ? contentRender1 : contentRender2}
    </div>
  );
};

ToggleMenu.defaultProps = {
  videoSelected: true
};

export default ToggleMenu;
