export const Like = (
  op,
  isGuestMode,
  sessionId,
  liked,
  currentGuestContentHelper,
  currentContent,
  chosenPortfolio,
  setIsLiked2,
  setLiked,
  firestore
) => {
  // do not log if mode is not guest or no sessionId
  if (!isGuestMode || sessionId === '') {
    return null;
  }

  // copy liked from redux store
  let likedArrCopy = [...liked];
  //////////onsole.log("!!CC!!",currentContent)
  let id = currentGuestContentHelper.id;
  let content = currentContent;
  // check if currentGuestContentHelper id is undefined
  if (!id) {
    //////////onsole.log('like in full portfolio guest-pass view...');
    id = currentContent.id;
    // if undefined it means that guest is in full portfolio view and not in briefcase guest view
  } else {
    // user in briefcase guest view
    //////////onsole.log('like in Briefcase guest view...');
    // set content from currentGuestContentHelper, if not splash
    if (id !== `splashkey-${chosenPortfolio._id}`) {
      content = currentGuestContentHelper;
    }
  }
  let content2 = { ...content, time_liked: firestore.Timestamp.now() };
  if (content2.PagesSaved === undefined) {
    content2.PagesSaved = 1;
  }
  // if op is add then append currentContent obj to likedArrCopy,
  // if op is add then append currentContent obj to likedArrCopy,
  if (op === 'add') {
    likedArrCopy.push(content2);
  } else {
    // if not filter out
    likedArrCopy = likedArrCopy.filter((obj) => obj.id !== id);
  }
  //////////onsole.log("LIKED: ", likedArrCopy);
  setIsLiked2(likedArrCopy);
  // replace the liked array
  firestore
    .collection('sessions')
    .doc(sessionId)
    .update({
      liked: likedArrCopy
    })
    .then(() => {
      // replace liked in store
      setLiked(likedArrCopy);
    })
    .catch((error) => console.error('ERROR: ', error));
};
export default Like;
