import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Button from '../Button';

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  button {
    width: 270px;
    margin-bottom: 10px;
  }
`;

const AdminMenuButtons = ({ companyConfig, userInfo }) => {
  const history = useHistory();

  return (
    <StyledButtonContainer>
      <Button
        text="Users"
        color="white"
        backgroundColor={companyConfig.accent_color}
        onClick={() => {
          history.push('/admin/portal');
        }}
      />
      <Button
        text="Portfolio"
        color="white"
        backgroundColor={companyConfig.accent_color}
        onClick={() => {
          history.push('/engage');
        }}
      />
      <Button
        text="Metrics"
        color="white"
        backgroundColor={companyConfig.accent_color}
        onClick={() => {
          history.push('/dashboard');
        }}
      />
      <Button
        text="Aggregated Metrics"
        color="white"
        backgroundColor={companyConfig.accent_color}
        onClick={() => {
          history.push('/aggregated');
        }}
      />
    </StyledButtonContainer>
  );
};

/* <Button
        text="Users"
        color={companyConfig.text_color}
        backgroundColor={companyConfig.accent_color}
        onClick={() => {
          history.push('/admin/portal');
        }}
      />
      <Button
        text="Portfolio"
        color={companyConfig.text_color}
        backgroundColor={companyConfig.accent_color}
        onClick={() => {
          history.push('/engage');
        }}
      />
      <Button
        text="Dashboard"
        color={companyConfig.text_color}
        backgroundColor={companyConfig.accent_color}
        onClick={() => {
          history.push('/dashboard');
        }}
      />
      <Button
        text="Aggregated Dashboard"
        color={companyConfig.text_color}
        backgroundColor={companyConfig.accent_color}
        onClick={() => {
          history.push('/aggregated');
        }}
      /> */

export default AdminMenuButtons;
