import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import { isLoaded, isEmpty, useFirebase } from 'react-redux-firebase';
import LoginPage from '../LoginPage';
import LoadingPage from '../LoadingPage';
import PortfolioPage from '../PortfolioPage';
import EngagePage from '../EngagePage';
import AuthPage from '../AuthPage';
// import DashboardPage from '../DashboardPage';
import DashboardRevamp from '../DashboardRevamp';
import Notes from '../../components/_DashboardRevamp/Body/Notes';
import GuestPage from '../GuestPage';
import AdminPage from '../AdminPage';
import AggregatedDashboardPage from '../AggregatedDashboardPage';
import { createGlobalStyle } from 'styled-components';
import { setSanityInfo } from '../../utils/client';
import EmbedPage from '../EmbedPage';
import Test from './WindowSizeHook';
import SendSelfEmail from '../GuestPassPageSendSelfDialogue';

// Wait till auth and sanityContent is loaded and ready
const IsReady = ({ sanityContentStatus, children }) => {
  const [Start] = useState(Date.now());
  const [reset, setReset] = useState(false);

  //const[Now,SetNow] = useState(Date.now())
  const auth = useSelector((state) => state.firebase.auth);
  // Show loading
  //setInterval(()=>{SetNow(Date.now());},100);
  let isGuest = isLoaded(auth) && auth.isAnonymous;
  let sanityReady =
    isLoaded(auth) &&
    sanityContentStatus === 'ready' &&
    Date.now() > Start + 2000;
  if ((!sanityReady || !isLoaded(auth)) && !isGuest)
    setTimeout(() => {
      setReset(!reset);
    }, 2100);
  //if auth is loaded but sanity is undefined then kick
  if (isLoaded(auth) && !sanityContentStatus && isEmpty(auth)) {
    return (
      <Redirect
        to={{
          pathname: '/login'
        }}
      />
    );
  }
  if ((!isLoaded(auth) || !sanityReady) && !isGuest) return <LoadingPage />;

  return children;
};

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated or if auth is not
// yet loaded
// http://react-redux-firebase.com/docs/recipes/auth.html
function PrivateRoute({ children, ...rest }) {
  const auth = useSelector((state) => state.firebase.auth);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoaded(auth) && !isEmpty(auth) && !auth.isAnonymous ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

// admin private route
function AdminPrivateRoute({ children, ...rest }) {
  const auth = useSelector((state) => state.firebase.auth);
  const userInfo = useSelector((state) => state.firestore.ordered.users);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoaded(auth) &&
        !isEmpty(auth) &&
        !auth.isAnonymous &&
        (userInfo[0].role === 'admin' || userInfo[0].role === 'manager') ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

const App = ({
  updateSessionKey,
  fireStoreSessionStatus,
  clearAll,
  sessionKey,
  getSanityContent,
  userInfo,
  files,
  companyConfig,
  companyInfo,
  auth,
  sanityContentStatus
}) => {
  const firebase = useFirebase();

  useEffect(() => {
    console.log('App launch');
    //////////onsole.log('app loaded');
    // get localstorage sessionKey on first load
    let key = localStorage.getItem('sessionKey');

    // NOTE: on first time, sessionKey doesn't exists so set to emtpy string
    if (key === null) {
      key = '';
    }
    // update sessionKey in redux store
    updateSessionKey(key);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // set sanity id from companyInfo
  useEffect(() => {
    let authReady = isLoaded(auth) && !isEmpty(auth);
    if (authReady && companyInfo !== undefined && companyInfo.length > 0) {
      // grab sanity ids
      let clientInfo = {
        dataset: companyInfo[0].sanity_dataset,
        projectId: companyInfo[0].sanity_project_id,
        token: companyInfo[0].sanity_token
      };

      //set sanity id info
      setSanityInfo(clientInfo);

      //get sanity content
      getSanityContent();
    }
    //setClient
  }, [companyInfo, getSanityContent, auth]);

  // Detect userInfo update
  // NOTE This will update everytime the user database updates in firebase
  useEffect(() => {
    let authReady = isLoaded(auth) && !isEmpty(auth);
    // compare firestore session key with localstorage session key
    if (
      !auth.isAnonymous &&
      authReady &&
      fireStoreSessionStatus === 'idle' &&
      userInfo !== undefined &&
      userInfo[0].session_key !== undefined &&
      userInfo[0].session_key.toString() !== sessionKey.toString()
    ) {
      //if not equal force logout
      firebase.auth().signOut(); // NOTE: THis may not be necessary
      firebase.logout();
      // clear sessionKey redux session key
      updateSessionKey(''); // NOTE: This may not be needed anymore
      // clear all state
      clearAll();
      //////////onsole.log('Detected user signing in on another device, logging out');
    }
  }, [userInfo, fireStoreSessionStatus, auth]); // eslint-disable-line react-hooks/exhaustive-deps
  //let beta = fetch(files[companyConfig.fontEot.asset._ref].url).then(e =>{e.status})
  //useEffect(()=>{//////onsole.log("CC",companyConfig)},[companyConfig])
  const GlobalNoneOutline = createGlobalStyle`
  button{
    outline:0px
  }
  input{
    outline: 0px;
    &:hover{
      filter: brightness(90%);
    };
    &:focus{
      filter: brightness(90%);
    };
  }
  div{outline:0px}
  `;
  const GlobalFont = createGlobalStyle`
${
  companyConfig && companyConfig.fontEot && companyConfig.fontWoff
    ? `@font-face {
  font-family: "1";
  ${
    companyConfig.fontEot
      ? `src: url("${files[companyConfig.fontEot.asset._ref].url}");`
      : ''
  }
  ${
    companyConfig.fontWoff
      ? `src: url("${files[companyConfig.fontWoff.asset._ref].url}");`
      : ''
  }
}
p {
  font-family: "1", 'Roboto', sans-serif;
}
div {
  font-family: "1", 'Roboto', sans-serif;
}
input {
  font-family: "1", 'Roboto', sans-serif;
}`
    : ''
}

`;
  // ////////////onsole.log("!!CC!!",companyConfig)
  // try{
  // ////////////onsole.log("!!F!!",files[companyConfig.fontEot.asset._ref].url)
  // ////////////onsole.log("!!F!!",files[companyConfig.fontWoff.asset._ref])
  // }
  // catch{
  //   ////////////onsole.log("!!FF!!",files)
  // }
  //WindowSize for dashboard
  /* istanbul ignore next */

  //  const dispatch = useDispatch();

  //   //Monitor window size for benefit of adaptive sizing components, see also ViewModels/ContentReducer.js
  //   const WindowSizingHook = (e) =>{
  //     dispatch({type:"WindowResized",Payload:{Size:{W:window.innerWidth,H:window.innerHeight}}});
  //   }

  // useEffect(()=>{
  //   //////onsole.log("StartingResizeListener")
  //   window.addEventListener("resize",WindowSizingHook);
  //   return (() => {window.removeEventListener("resize",WindowSizingHook)})
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[])

  // useEffect(()=>{
  //   dispatch({type:"WindowResized",Payload:{Size:{W:window.innerWidth,H:window.innerHeight}}});
  // })
  // useEffect(()=>{

  // },[window.innerWidth,window.innerHeight])

  return (
    <>
      <style>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Noto Sans JP"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto Condensed"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Oswald"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Bree Serif"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Antic Slab"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Vidaloka"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Holtwood One SC"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Cutive"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Source Serif Pro"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Martel"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Lobster"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Bungee"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Fredericka the Great"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Cinzel Decorative"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Pacifico"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Permanent Marker"
        />
      </style>
      <div className="App">
        {/* <p style={{fontSize:'96px', fontFamily:"Permanent Marker"}}>Test</p>   http://localhost:8888/guest/7P4xzuZupQva6LHAxaoa*/}
        <GlobalNoneOutline />
        <GlobalFont />
        <BrowserRouter>
          <Switch>
            <Route path="/" exact component={LoginPage} />
            <Route path="/login" exact component={LoginPage} />
            <Route path="/auth/action" component={AuthPage} />
            <Route path="/embed/:id/:em/:fn" component={EmbedPage} />
            <Route path="/embed/:id" component={EmbedPage} />
            <Route path="/guest/:id/:em/:fn" component={GuestPage} />
            <Route path="/guest/:id" component={GuestPage} />
            <Route path="/portfolio/guest" component={PortfolioPage} />
            <Route path="/loadtest" component={LoadingPage} />
            <Route path="/SendSelfTest" component={SendSelfEmail} />
            <IsReady sanityContentStatus={sanityContentStatus}>
              <PrivateRoute exact path="/engage">
                <EngagePage />
              </PrivateRoute>
              <PrivateRoute exact path="/notes">
                <Notes />
              </PrivateRoute>
              <PrivateRoute exact path="/portfolio">
                <PortfolioPage />
              </PrivateRoute>
              <PrivateRoute exact path="/dashboard">
                <DashboardRevamp />
              </PrivateRoute>
              <AdminPrivateRoute exact path="/admin">
                <AdminPage />
              </AdminPrivateRoute>
              <AdminPrivateRoute exact path="/admin/portal">
                <AdminPage />
              </AdminPrivateRoute>
              {/* <AdminPrivateRoute exact path="/notes">
              <Notes />
            </AdminPrivateRoute> */}
              <AdminPrivateRoute exact path="/aggregated">
                <AggregatedDashboardPage />
              </AdminPrivateRoute>
            </IsReady>
          </Switch>
        </BrowserRouter>
        <Test />
      </div>
    </>
  );
};

export default App;
