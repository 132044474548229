import { connect } from 'react-redux';
import MuxPlayer from './MuxPlayer';
import { setIsVideoPlaying } from '../../actions';

const mapStateToProps = (state) => ({
  isGuestMode: state.guestReducer.isGuestMode,
  chosenPortfolio: state.portfolioReducer.chosenPortfolio,
  chosenContentPageId: state.pageMenuReducer.chosenContentPageId,
  pdfs: state.sanityContentReducer.pdfs
});

const mapDispatchToProps = (dispatch) => {
  return {
    setIsVideoPlaying: (bool) => dispatch(setIsVideoPlaying(bool))
  };
};

const MuxPlayerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MuxPlayer);

export default MuxPlayerContainer;
