import React from 'react';
// import Icons from '../Icons';
import BlockContent from '@sanity/block-content-to-react';
import serializers from '../../utils/scriptSerializers';
import X from '../../assets/images/X-Icon.png';
import FontUtil from '../../utils/FontUtility';
const Scriptnote = ({
  companyConfig,
  chosenPortfolio,
  pages,
  chosenPageId,
  chosenContentPageId,
  showScriptnote,
  toggleScriptnote
}) => {
  // check block content
  //  const blockContent = (blocks) => {
  //   // filter non valid imageLinks
  //   let tempBlocks = blocks.filter((obj) => {
  //     if (obj._type === 'imageLinks' && obj.image) {
  //       return true;
  //     }
  //     if (obj._type !== 'imageLinks') {
  //       return true;
  //     }
  //     return false;
  //   });

  //   // further filter non valid embedHtml
  //   tempBlocks = tempBlocks.filter((obj) => {
  //     if (obj._type === 'embedHTML' && obj.html) {
  //       return true;
  //     }
  //     if (obj._type !== 'embedHTML') {
  //       return true;
  //     }
  //     return false;
  //   });
  //   return tempBlocks;
  // };

  //////////onsole.log(pages)
  //////////onsole.log(chosenContentPageId)
  //////////onsole.log(pages[chosenContentPageId])
  return (
    <div
      className={`portfolio-scriptnote-container ${
        showScriptnote ? 'active' : 'hide'
      }`}
      style={{
        border: `4px solid ${
          chosenPortfolio.primary_color
            ? chosenPortfolio.primary_color
            : 'white'
        }`
      }}
    >
      <div className="scriptnote-close">
        <div
          onClick={toggleScriptnote}
          style={{
            maskImage: `url(${X})`,
            maskSize: '100% 100%',
            maskRepeat: 'no-repeat',
            WebkitMaskImage: `url(${X})`,
            WebkitMaskRepeat: 'no-repeat',
            WebkitMaskSize: '100% 100%',
            backgroundColor: chosenPortfolio.primary_color
              ? chosenPortfolio.primary_color
              : companyConfig.primary_color,
            height: '80px',
            width: '80px',
            cursor: `pointer`,
            alignSelf: 'flex-end',
            float: 'right',
            objectFit: 'contain',
            objectPosition: 'right',
            margin: '16px',
            marginBottom: '0px'
          }}
          className="scriptnote-close-button"
        ></div>
      </div>
      <div className="scriptnote-text-container">
        <div className="text-container">
          <h1>{pages[chosenContentPageId] ? 'Script' : 'Script Notes'}</h1>
          {pages[chosenContentPageId] ? (
            <p
              style={{
                marginBlockStart: '0px',
                marginBlockEnd: '0px',
                fontWeight: 600,
                fontFamily: FontUtil(chosenPortfolio.SystemText)
              }}
            >
              {pages[chosenContentPageId]?.title}
            </p>
          ) : null}
          <hr />
          {pages[chosenContentPageId] ? (
            typeof pages[chosenContentPageId]?.script === 'string' ? (
              <p style={{ fontFamily: FontUtil(chosenPortfolio.SystemText) }}>
                {pages[chosenContentPageId]
                  ? pages[chosenContentPageId]?.script
                  : ''}
              </p>
            ) : (
              <BlockContent
                blocks={pages[chosenContentPageId]?.script}
                serializers={serializers}
              />
            )
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Scriptnote;
