import React from 'react';
import { urlFor } from '../../../../utils/sanityHelper';
import styled from 'styled-components';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import UserSelect from '../../UserSelect';
import * as Scale from '../../../../utils/Scalars';

const UserSelectContainer = styled.div`
  position: absolute;
  z-index: 1;
  height: 25px;
  width: 25px;
  right: 5%;
  top: 3%;
`;

const SpoofedUserContainer = styled.div`
  position: absolute;
  z-index: 1;
  height: 20%;
  width: 75%;
  top: 1%;
  left: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;

  .reset {
    height: 100%;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2px;
  }

  .mini-profile-picture {
    height: 20px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;

    .picture {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 95%;
      width: 95%;
      border-radius: 50%;
      overflow: hidden;
      background-color: rgb(247, 152, 61);
      overflow: hidden;

      p {
        margin-block-start: 0px;
        margin-block-end: 0px;
        font-size: 10px;
        font-weight: 600;
        font-family: 'Open Sans', sans-serif;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: white;
      }
    }
  }

  .name-box {
    width: 70%;
    height: 100%;
    display: flex;
    padding-left: 3px;
    padding-right: 3px;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;

    p {
      margin-block-start: 0px;
      margin-block-end: 0px;
      font-size: 8px;
      font-weight: 600;
      font-family: 'Open Sans', sans-serif;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: white;
    }
  }
`;

const ProfileImage = ({
  member,
  setShowUserSelect,
  showUserSelect,
  userInfo,
  UserPool,
  selectedUser,
  Size,
  setSelectedUser,
  setAggregateSelected,
  setDefaultVisit,
  setSessionDateString,
  setParallelSession,
  setParallelDateString,
  setSecondaryUserAggregateToggle,
  setSecondaryUserSessionToggle,
  setSelectedNotesString,
  setSelectedNotesSession,
  setSelectedNotesSessionObj,
  setEfficiencyAggregateSelected,
  SetDaterangeDispatch,
  setEfficiencyCompanyToggle
}) => {
  const closeDrop = (event) => {
    if (
      event.target.id !== 'user-select-box' &&
      event.target.id !== 'user-select-filter'
    ) {
      closeAct();
    }
  };

  const closeAct = () => {
    setShowUserSelect(false);
    window.removeEventListener('click', closeDrop);
  };

  const toggleDropdown = () => {
    if (!showUserSelect) {
      window.setTimeout(() => {
        window.addEventListener('click', closeDrop);
      }, 50);
    }
    setShowUserSelect(!showUserSelect);
  };

  const resetUser = () => {
    setSelectedUser(userInfo);
    setAggregateSelected('1m');
    setDefaultVisit(undefined);
    setSessionDateString('Select a Portfolio Session');
    setParallelSession(undefined);
    setParallelDateString('Select a Portfolio Session');
    setSecondaryUserSessionToggle('off');
    setSecondaryUserAggregateToggle('on');
    setSelectedNotesString('Sort by User');
    setSelectedNotesSession(undefined);
    setSelectedNotesSessionObj({});
    setEfficiencyAggregateSelected('1m');
    SetDaterangeDispatch(3);
    setEfficiencyCompanyToggle('on');
  };

  let imageDisplay;
  let actualFirstName;
  let actualLastName;
  let actualFNInitial;
  let actualLNInitial;

  if (!member.image) {
    actualFirstName = userInfo[0].name.split(' ')[0];
    actualFNInitial = actualFirstName.split('')[0];
    actualLastName = userInfo[0].name.split(' ')[1];
    if (actualLastName === undefined) {
      actualLastName = '';
      actualLNInitial = '';
    } else {
      actualLNInitial = actualLastName.split('')[0];
    }
    imageDisplay = (
      <div
        style={{
          width: `${Scale.Height(Size, 110)}px`,
          height: `${Scale.Height(Size, 110)}px`,
          borderRadius: '50%',
          zIndex: 0,
          marginTop: `${Scale.Height(Size, 10)}px`,
          backgroundColor: 'teal',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: '1px solid white'
        }}
      >
        <p
          style={{
            marginBlockStart: '0px',
            marginBlockEnd: '0px',
            fontWeight: 600,
            fontFamily: "'Open Sans', serif",
            color: 'white',
            fontSize: `${Scale.Height(Size, 24)}px`,
            letterSpacing: '0.02em'
          }}
        >
          {actualFNInitial}
          {actualLNInitial}
        </p>
      </div>
    );
  } else {
    imageDisplay = (
      <img
        alt={'User Profile'}
        style={{
          width: `${Scale.Height(Size, 110)}px`,
          height: `${Scale.Height(Size, 110)}px`,
          borderRadius: '50%',
          zIndex: 0,
          marginTop: `${Scale.Height(Size, 10)}px`
        }}
        src={urlFor(member.image)}
      />
    );
  }

  let firstName;
  let lastName;
  let fnInitial;
  let lnInitial;

  if (selectedUser !== undefined) {
    firstName = selectedUser[0].name.split(' ')[0];
    fnInitial = firstName.split('')[0];
    lastName = selectedUser[0].name.split(' ')[1];
    if (lastName === undefined) {
      lastName = '';
      lnInitial = '';
    } else {
      lnInitial = lastName.split('')[0];
    }
  } else {
    firstName = '';
    lastName = '';
    fnInitial = '';
    lnInitial = '';
  }

  return (
    <div
      style={{
        width: '144px',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'relative'
      }}
    >
      {imageDisplay}
      <SpoofedUserContainer
        style={{
          visibility:
            selectedUser !== undefined && selectedUser[0].id !== userInfo[0].id
              ? 'visible'
              : 'hidden'
        }}
      >
        <div className="reset">
          <CloseRoundedIcon
            style={{
              fontSize: `${Scale.Height(Size, 20)}px`,
              fontWeight: 600,
              color: 'white',
              cursor: 'pointer'
            }}
            onClick={() => resetUser()}
          />
        </div>
        <div className="mini-profile-picture">
          <div className="picture">
            <p>
              {fnInitial}
              {lnInitial}
            </p>
          </div>
        </div>
        <div className="name-box">
          <p>
            {firstName} {lastName}
          </p>
        </div>
      </SpoofedUserContainer>
      <UserSelectContainer
        style={{
          visibility:
            (userInfo[0].role === 'manager' || userInfo[0].role === 'admin') &&
            UserPool.length
              ? 'visible'
              : 'hidden'
        }}
      >
        <AddCircleOutlineRoundedIcon
          style={{
            fontSize: `${Scale.Height(Size, 35)}px`,
            color: 'white',
            cursor: 'pointer'
          }}
          onClick={() => toggleDropdown()}
        />
        <UserSelect />
      </UserSelectContainer>
    </div>
  );
};

export default ProfileImage;
