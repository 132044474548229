import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { VictoryPie, VictoryTooltip, VictoryLegend } from 'victory';

const StyledChart = styled.div`
  width: 500px;
  max-width: 500px;
  min-height: 400px;
`;
const ChartPie = ({ data, chartWidth }) => {
  const [modifiedData, setModifiedData] = useState([]); // modifed data with shorter label, for legends

  useEffect(() => {
    //traverse data
    let newData = data.map((obj) => {
      let name = '';
      if (obj.name.length > 9) {
        // if length is bigger than 9, replace with ...
        name = obj.name.substring(0, 7) + '...';
      } else {
        name = obj.name;
      }

      return { ...obj, name: name };
    });

    //
    setModifiedData(newData);
  }, [data]);
  return (
    <StyledChart>
      <VictoryPie
        labelComponent={<VictoryTooltip />}
        width={400}
        height={400}
        animate={{ duration: 1000 }}
        data={data}
        labels={({ datum }) => datum.name}
        padding={{ top: 50 }}
      />
      <VictoryLegend
        orientation="horizontal"
        x={25}
        y={30}
        gutter={20}
        itemsPerRow={6}
        width={700}
        height={data.length / 6 > 1 ? (Math.ceil(data.length) / 6) * 80 : 80}
        data={modifiedData}
        style={{
          title: { fontSize: 12 }
        }}
        padding={{ top: 20 }}
      />
    </StyledChart>
  );
};

export default ChartPie;
