import { connect } from 'react-redux';
import CompanySolo from './CompanySolo.js';
import { SetSoloDispatch } from '../../../../../../actions';

const mapStateToProps = (state) => ({
  solo: state.DashboardReducer.solo,
  Size: state.portfolioReducer.Size
});

const mapDispatchToProps = (dispatch) => {
  return {
    SetSoloDispatch: (value) => dispatch(SetSoloDispatch(value))
  };
};

const DashboardCompanySolo = connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanySolo);

export default DashboardCompanySolo;
