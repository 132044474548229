import { connect } from 'react-redux';
import Sent from './Sent';
const mapStateToProps = (state) => ({
  // member: state.sanityContentReducer.member,
  // userInfo: state.firestore.ordered.users,
});
const mapDispatchToProps = (dispatch) => {
  return {};
};

const SentContainer = connect(mapStateToProps, mapDispatchToProps)(Sent);

export default SentContainer;
