import React from 'react';
import Modal from '../Modal';
import CustomerInfoForm from '../CustomerInfoForm';
import { useFirestore } from 'react-redux-firebase';

const PIIModal = ({
  isModalOpen,
  setPortfolioCustomerInfo,
  sessionId,
  updateSessionInSanity,
  hideModal,
  chosenPortfolio
}) => {
  const firestore = useFirestore();

  const updateCustomerInfo = (form) => {
    setPortfolioCustomerInfo(form);

    if (sessionId === '') {
      return null;
    }

    const customerReplacement = {
      customer_name: form.firstName + ' ' + form.lastName,
      customer_email: form.email
    };
    let viewerLog = [];
    let viewerTemplate = {
      _type: 'viewerLog'
    };

    // add main customer
    viewerLog.push({
      ...viewerTemplate,
      email: customerReplacement.customer_email,
      name: customerReplacement.customer_name,
      _key: Math.random().toString(36).substr(2, 7)
    });

    // add cc
    if (form.ccEmails) {
      form.ccEmails.forEach((email) => {
        viewerLog.push({
          ...viewerTemplate,
          email: email,
          _key: Math.random().toString(36).substr(2, 7)
        });
      });
    }

    // add viewer log to customerReplacement.
    customerReplacement['viewer_log'] = viewerLog;

    // update customer info in sanity session and firestore
    firestore
      .collection('sessions')
      .doc(sessionId)
      .update(customerReplacement)
      .then(() => {
        // update viewer log info in sanity
        updateSessionInSanity({ viewer_log: customerReplacement.viewer_log });
        hideModal();
      })
      .catch((error) => {
        console.error('ERROR: ', error);
        hideModal();
      });
  };

  return (
    <div className="portfolio-action-menu">
      <Modal chosenPortfolio={chosenPortfolio}>
        <CustomerInfoForm
          handleSubmit={updateCustomerInfo}
          submitText="Update"
        />
      </Modal>
    </div>
  );
};

export default PIIModal;
