import React from 'react';
import styled from 'styled-components';
import {
  VictoryChart,
  VictoryBar,
  VictoryTheme,
  VictoryAxis,
  VictoryLabel
} from 'victory';

const StyledChart = styled.div`
  width: ${(props) => props.width}px;
  min-width: 500px;
  height: 400px;
`;
const ChartBar = ({
  data,
  labels,
  chartWidth,
  barColor,
  xAxis,
  yAxis,
  yLabelFunc
}) => {
  return (
    <StyledChart width={chartWidth}>
      <VictoryChart
        animate={{ duration: 1000 }}
        theme={VictoryTheme.material}
        width={chartWidth > 500 ? chartWidth : 500}
        height={400}
        domainPadding={{ x: 25 }}
        padding={{ top: 50, bottom: 75, right: 0, left: 50 }}
      >
        <VictoryAxis
          axisLabelComponent={<VictoryLabel dx={12} />}
          tickValues={labels.map((obj, index) => index + 1)}
          tickFormat={(x) => {
            // get current label
            let label = labels[x - 1];
            if (!label) {
              return labels[x - 1];
            }
            // split by /n
            let split = label.split(/\r?\n/);
            let newLabel = '';
            split.forEach((s) => {
              if (s.length > 8) {
                // if length is bigger than 8, replace with ...
                s = s.substring(0, 6) + '...';
              }
              newLabel += s + '\n';
            });
            return newLabel;
          }}
        />
        <VictoryAxis
          dependentAxis
          // tickFormat specifies how ticks should be displayed
          tickFormat={(x) => x}
        />
        <VictoryBar
          style={{ data: { fill: barColor } }}
          data={data}
          x={xAxis}
          y={yAxis}
          labels={({ datum }) => yLabelFunc(datum.y, false)}
        />
      </VictoryChart>
    </StyledChart>
  );
};

export default ChartBar;
