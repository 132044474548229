// serializers.js
import React from 'react';
import EmbedHTML from './EmbedHTML';
import ImageLinks from './ImageLinks';

const serializers = {
  types: {
    embedHTML: EmbedHTML, // Addresses any embeddedHTML asset
    imageLinks: ImageLinks // Addresses images and image with links
  },
  marks: {
    link: ({ mark, children }) => {
      const { blank, href } = mark;
      return blank ? (
        <a href={href} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ) : (
        <a href={href}>{children}</a>
      );
    }
  }
};

export default serializers;
