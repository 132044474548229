import {
  TOGGLE_NOTEPAD,
  UPDATE_NOTEPAD,
  UPDATE_NOTEPAD2
} from '../constants/actionTypes';
import { v4 } from 'uuid';

export const UpdateInternalNotepad = (string) => ({
  type: UPDATE_NOTEPAD2,
  string
});

export const toggleNotepad = () => ({
  type: TOGGLE_NOTEPAD
});

export const updateNotepad = (string) => ({
  type: UPDATE_NOTEPAD,
  string
});
export const updateNotepad2 = (OldText, NewText, ContentTitle) => {
  let ID = v4();
  //onsole.log(OldText)
  while (OldText.includes(ID)) ID = v4();
  let NT =
    `<p style="text-align: center;">${ContentTitle}</p><p style="text-align: center;" id='${ID}'></p><hr>` +
    NewText +
    `<script>${JSON.stringify({
      date: new Date().getTime(),
      id: ID
    })}</script><hr>`;
  // `<script>var date = new Date(${new Date().getTime()}); document.getElementById('${ID}').textContent += \`\${date}\`</script>`

  let RT = OldText + NT;
  console.log('RT1', RT);
  return (dispatch) => {
    //onsole.log("RT2",RT);
    dispatch(UpdateInternalNotepad(RT));
  };
};
export const updateNotepad3 = (OldText, NewText, ContentTitle) => {
  let ID = v4();
  //onsole.log(OldText)
  while (OldText.includes(ID)) ID = v4();
  let NT =
    `<p style="text-align: center;">${ContentTitle}</p><p style="text-align: center;" id='${ID}'></p><hr>` +
    NewText +
    `<script>${JSON.stringify({
      date: new Date().getTime(),
      id: ID
    })}</script><hr>`;
  // `<script>var date = new Date(${new Date().getTime()}); document.getElementById('${ID}').textContent += \`\${date}\`</script>`

  let RT = OldText + NT;
  console.log('RT1', RT);
  return RT;
};
