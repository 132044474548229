import { connect } from 'react-redux';
import MenuSection from './MenuSection';
import {
  setCurrentContentHelper,
  setSavedContentPageId,
  setMasterMenuOpen,
  setTwolevelTerminus,
  setMegamenuLevel,
  SetTopic,
  setTermWithChild,
  setContentPageId
} from '../../actions';

const mapStateToProps = (state) => ({
  isGuestMode: state.guestReducer.isGuestMode,
  isPortfolioView: state.guestReducer.isPortfolioView,
  guestContentHelper: state.guestReducer.guestContentHelper,
  companyConfig: state.sanityContentReducer.companyConfig,
  chosenPortfolio: state.portfolioReducer.chosenPortfolio,
  pages: state.portfolioReducer.pages,
  nav: state.portfolioReducer.nav,
  pageMenuReducer: state.pageMenuReducer,
  Size: state.portfolioReducer.Size,
  twolevelTerminus: state.portfolioReducer.twolevelTerminus,
  megamenuLevel: state.pageMenuReducer.megamenuLevel,
  Topic: state.pageMenuReducer.Topic
});

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentContentHelper: (id) => dispatch(setCurrentContentHelper(id)), // helps guest content
    setSavedContentPageId: (id) => dispatch(setSavedContentPageId(id)),
    setMasterMenuOpen: (bool) => dispatch(setMasterMenuOpen(bool)),
    setTwolevelTerminus: (bool) => dispatch(setTwolevelTerminus(bool)),
    setMegamenuLevel: (level) => dispatch(setMegamenuLevel(level)),
    SetTopic: (Topic) => dispatch(SetTopic(Topic)),
    setTermWithChild: (bool) => dispatch(setTermWithChild(bool)),
    setContentPageId: (id) => dispatch(setContentPageId(id))
  };
};

const MenuSectionContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuSection);

export default MenuSectionContainer;
