/* eslint-disable react/no-unknown-property */
import { React } from 'react';
const MagnifyingGlass = ({ style }) => {
  return (
    <svg
      style={{ ...style }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 23.43 23.43"
    >
      <defs>
        <style>
          {`.clsMagnifying-1{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:3px;}`}
        </style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="design">
          <circle class="clsMagnifying-1" cx="9.07" cy="9.07" r="7.57" />
          <line
            class="clsMagnifying-1"
            x1="14.42"
            y1="14.42"
            x2="21.93"
            y2="21.93"
          />
        </g>
      </g>
    </svg>
  );
};
export default MagnifyingGlass;
