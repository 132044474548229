import React, { useRef, useEffect } from 'react'; //

const Annotator = (props) => {
  //////onsole.log("ANMOPROPS",props)
  let TestReF = useRef();
  //let TestBool = props.ANNOTATOR2
  const TestRefMessage = (event) => {
    if (Number(TestReF.current.style.opacity))
      TestReF.current.contentWindow.postMessage(
        [event.clientX, event.clientY, event.screenX, event.screenY],
        TestReF.current?.contentWindow?.origin
      );
  };
  useEffect(
    () => {
      window.addEventListener('pointerdown', TestRefMessage);
      return () => {
        window.removeEventListener('pointerdown', TestRefMessage);
      };
    },
    // eslint-disable-next-line
    []
  );
  useEffect(() => {
    TestReF.current.addEventListener('CloseOut', (eve) => {
      props.SET_ANNOTATOR(false);
    });
    TestReF.current.addEventListener('load', (eve) => {
      let BorderColor = props.chosenPortfolio.primary_color
        ? props.chosenPortfolio.primary_color
        : props.companyConfig.primary_color;
      TestReF.current.contentWindow.postMessage({ color: BorderColor });
    });
  }, [TestReF, props]);
  useEffect(() => {
    let BorderColor = props.chosenPortfolio.primary_color
      ? props.chosenPortfolio.primary_color
      : props.companyConfig.primary_color;

    if (TestReF.current) {
      //setTimeout(()=>{TestReF.current.contentWindow.postMessage({color:BorderColor});},250)
      TestReF.current.contentWindow.postMessage({ color: BorderColor });
    }
  }, [
    props.chosenPortfolio.primary_color,
    props.companyConfig.primary_color,
    TestReF
  ]);
  return (
    <iframe
      title="Annotator"
      ref={TestReF}
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: '0px',
        left: '0px',
        zIndex: props.ANNOTATOR2 ? '12' : '-12',
        opacity: props.ANNOTATOR2 ? 1 : 0,
        pointerEvents: props.ANNOTATOR2 ? 'all' : 'none',
        touchAction: props.ANNOTATOR2 ? 'all' : 'none'
      }}
      src={'/easel.html'}
    ></iframe>
  );
};
export default Annotator;
