import React, { useEffect, useState } from 'react';
import { UpdateUserInformationPicture } from '../../../../../actions/sanityContentActions';
import { urlFor } from '../../../../../utils/sanityHelper';
import * as Scale from '../../../../../utils/Scalars';
import styled from 'styled-components';
import { writeClient } from '../../../../../utils/client';
import { useFirestore } from 'react-redux-firebase';
import Loader from 'react-loader-spinner';

const ProfileContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 8%;

  .placement-helper {
    height: 50%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .inner-placement-helper {
      display: grid;
      grid-template-rows: 80fr 20fr;
      width: 60%;
      height: 100%;

      .profile-box {
        grid-row: 1 / span 1;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        img {
          border-radius: 50%;
          border: 2px solid #3b3b3b;
        }
      }

      .upload-box {
        grid-row: 2 / span 1;
        display: flex;
        justify-content: center;
        align-items: flex-start;
      }
    }
  }
`;

const StyledLabel = styled.label`
  cursor: pointer;
  font-size: 12px;
  color: white;
  background-color: #3b3b3b;
  box-shadow: 2px 2px 2px #808080;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Open Sans', serif;
  border-radius: 2px;
  text-align: center;
  letter-spacing: 0.1em;
`;

const StyledInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const LoadingBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledSaveAndClose = styled.div`
  border-radius: 8px;
  box-shadow: 2px 2px 2px #808080;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease;

  p {
    font-family: 'Open Sans', serif;
    margin-block-end: 0px;
    margin-block-start: 0px;
    transition: all 0.2s ease;
    font-weight: 600;
  }
`;

const ProfileEditor = ({
  Edit,
  member,
  UpState,
  SetUpState,
  Size,
  userInfo,
  getUpdatedUser
}) => {
  const firestore = useFirestore();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [loading, setLoading] = useState(false);
  const [uploaded, setUploaded] = useState(false);

  const UpdateFirestore = () => {
    setLoading(true);
    firestore
      .collection('users')
      .doc(userInfo[0].id)
      .update({
        name: firstName + ' ' + lastName
      })
      .then((e) => {
        writeClient()
          .patch(member._id)
          .set({ name: firstName + ' ' + lastName })
          .commit()
          .then((ex) => {
            //////onsole.log("!!!EX!!!", ex);
            getUpdatedUser(ex);
            setLoading(false);
            setUploaded(false);
            SetUpState(false);
          })
          .catch((ex) => {
            //////onsole.log("EXCEPTION", ex);
            alert(
              'There was a problem updating your information. Please try again later.'
            );
            setLoading(false);
          });
      })
      .catch((ex) => {
        //////onsole.log("EXCEPTION", ex);
        alert(
          'There was a problem updating your information. Please try again later.'
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    if (userInfo) {
      let fn = userInfo[0].name.trim().split(' ')[0];
      let ln = userInfo[0].name.trim().split(' ')[1];

      if (fn !== undefined) {
        setFirstName(userInfo[0].name.trim().split(' ')[0]);
      } else {
        setFirstName('');
      }

      if (ln !== undefined) {
        setLastName(userInfo[0].name.trim().split(' ')[1]);
      } else {
        setLastName('');
      }
    } else {
      return;
    }
  }, [userInfo]);

  let Upload = ({ member, SetUpState }) => {
    return (
      <div
        onClick={() => {
          SetUpState(false);
          setUploaded(false);
        }}
        style={{
          backgroundColor: 'rgba(0,0,0,0.65)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'center',
          position: 'absolute',
          height: '100%',
          width: '100%',
          left: '0px',
          top: '0px'
        }}
      >
        <style>{`
                        .minwidth {
                            min-width: -moz-fit-content;
                            min-width: fit-content;
                        }
                    `}</style>
        {/* eslint-disable-next-line no-dupe-keys*/}
        <div
          className="minwidth"
          style={{
            position: 'relative',
            flexDirection: 'column',
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            height: '25%',
            width: '20%',
            borderRadius: '4px',
            paddingBottom: `${Scale.Height(Size, 20)}px`
          }}
          onClick={(eve) => eve.stopPropagation()}
        >
          <p
            style={{
              fontSize: `12px`,
              fontFamily: "'Open Sans', sans-serif",
              fontWeight: 600,
              marginBlockEnd: '0px',
              marginBlockStart: '0px',
              color: '#3b3b3bBF',
              marginBottom: `${Scale.Width(Size, 10)}px`,
              fontStyle: 'italic',
              cursor: 'default'
            }}
          >
            please upload your image here
          </p>
          {/* <input type="file" on onClick={(eve)=>{eve.stopPropagation()}} onChange={(eve)=>{UpdateUserInformationPicture(member,eve.target.files[0])}} /> */}
          <StyledLabel
            style={{
              height: `${Scale.Height(Size, 25)}px`,
              width: `${Scale.Height(Size, 150)}px`,
              marginBottom: `${Scale.Width(Size, 20)}px`
            }}
            for="changePhoto"
            onClick={(eve) => eve.stopPropagation()}
          >
            Choose Photo
          </StyledLabel>
          <StyledInput
            type="file"
            multiple={false}
            accept=".jpg,.png"
            onClick={(eve) => eve.stopPropagation()}
            onInput={(eve) => {
              UpdateUserInformationPicture(member, eve.target.files[0]);
              setLoading(true);
              setTimeout(() => {
                setLoading(false);
                setUploaded(true);
              }, 1500);
            }}
            name="changePhoto"
            id="changePhoto"
          />
          {loading ? (
            <LoadingBox
              style={{
                visibility: loading ? 'visible' : 'hidden',
                marginBottom: `${Scale.Width(Size, 20)}px`
              }}
            >
              <Loader
                type="TailSpin"
                color="rgb(5, 106, 175)"
                height={40}
                width={40}
              />
            </LoadingBox>
          ) : null}
          {uploaded ? (
            <StyledSaveAndClose
              style={{
                height: `${Scale.Height(Size, 35)}px`,
                width: `${Scale.Height(Size, 250)}px`,
                backgroundColor: uploaded ? 'rgb(5, 106, 175)' : '#dedede',
                cursor: uploaded ? 'pointer' : 'default',
                marginBottom: `${Scale.Width(Size, 10)}px`
              }}
              disabled={uploaded ? false : true}
              onClick={() => UpdateFirestore()}
            >
              <p
                style={{
                  fontSize: `${Scale.Height(Size, 16)}px`,
                  color: uploaded ? 'white' : '#3b3b3b',
                  cursor: uploaded ? 'pointer' : 'default'
                }}
              >
                Save & Close
              </p>
            </StyledSaveAndClose>
          ) : null}
        </div>
      </div>
    );
  };

  let imageDisplay;
  let actualFirstName;
  let actualLastName;
  let actualFNInitial;
  let actualLNInitial;

  if (!member.image) {
    actualFirstName = userInfo[0].name.split(' ')[0];
    actualFNInitial = actualFirstName.split('')[0];
    actualLastName = userInfo[0].name.split(' ')[1];
    if (actualLastName === undefined) {
      actualLastName = '';
      actualLNInitial = '';
    } else {
      actualLNInitial = actualLastName.split('')[0];
    }
    imageDisplay = (
      <div
        style={{
          width: `${Scale.Height(Size, 300)}px`,
          height: `${Scale.Height(Size, 300)}px`,
          borderRadius: '50%',
          backgroundColor: 'teal',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: '2px solid #3b3b3b'
        }}
      >
        <p
          style={{
            marginBlockStart: '0px',
            marginBlockEnd: '0px',
            fontWeight: 600,
            fontFamily: "'Open Sans', serif",
            color: 'white',
            fontSize: `${Scale.Height(Size, 52)}px`,
            letterSpacing: '0.1em',
            textShadow: '1px 1px 2px #3b3b3b'
          }}
        >
          {actualFNInitial}
          {actualLNInitial}
        </p>
      </div>
    );
  } else {
    imageDisplay = (
      <img
        alt={'User Profile'}
        style={{
          height: `${Scale.Height(Size, 300)}px`,
          width: `${Scale.Height(Size, 300)}px`
        }}
        src={urlFor(member.image)}
      />
    );
  }

  return (
    <ProfileContainer>
      <div className="placement-helper">
        <div
          onClick={() => {
            SetUpState(true);
          }}
          className="inner-placement-helper"
          style={{ cursor: 'pointer' }}
        >
          <div className="profile-box">{imageDisplay}</div>
          <div className="upload-box">
            <p
              onClick={() => {
                SetUpState(true);
              }}
              style={{
                textDecoration: 'underline',
                fontSize: '12px',
                fontFamily: "'Open Sans', serif",
                fontWeight: 600,
                color: 'rgb(5, 106, 175)'
              }}
            >
              Upload Photo
            </p>
          </div>
        </div>
        {UpState ? <Upload SetUpState={SetUpState} member={member} /> : null}
      </div>
    </ProfileContainer>
  );
};

export default ProfileEditor;
