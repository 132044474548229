import { connect } from 'react-redux';
import PortfolioSelect from './PortfolioSelect.js';
import {
  setSelectedPortfolioName,
  setSelectedPortfolioId,
  setShowPortfolioSelect,
  setShowSessionSelect,
  setAggregateSelected,
  setDefaultVisit,
  SetDaterangeDispatch,
  setSelectedNotesSession,
  setEfficiencyAggregateSelected
} from '../../../actions';

const mapStateToProps = (state) => ({
  portfolios: state.sanityContentReducer.portfolios,
  selectedPortfolioName: state.DashboardReducer.selectedPortfolioName,
  showPortfolioSelect: state.DashboardReducer.showPortfolioSelect,
  userInfo: state.firestore.ordered.users,
  selectedUser: state.DashboardReducer.selectedUser,
  Size: state.portfolioReducer.Size
});

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedPortfolioName: (string) =>
      dispatch(setSelectedPortfolioName(string)),
    setSelectedPortfolioId: (id) => dispatch(setSelectedPortfolioId(id)),
    setShowPortfolioSelect: (bool) => dispatch(setShowPortfolioSelect(bool)),
    setShowSessionSelect: (bool) => dispatch(setShowSessionSelect(bool)),
    setAggregateSelected: (value) => dispatch(setAggregateSelected(value)),
    SetDaterangeDispatch: (value) => dispatch(SetDaterangeDispatch(value)),
    setDefaultVisit: (visit) => dispatch(setDefaultVisit(visit)),
    setSelectedNotesSession: (id) => dispatch(setSelectedNotesSession(id)),
    setEfficiencyAggregateSelected: (value) =>
      dispatch(setEfficiencyAggregateSelected(value))
  };
};

const PortfolioSelectContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PortfolioSelect);

export default PortfolioSelectContainer;
