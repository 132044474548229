import React from 'react';
import CustomerInfoForm from '../../../../../containers/CustomerInfoForm';
//import * as Scale from '../../../../../utils/Scalars'//utils/Scalars
// import ToolCross from '../../../../../containers/PageMenus/Menu9-Storybook/TerminalMenuGallery/Exclude.png'//
// import BackChev from '../Vector 1.png'
import BackChev from '../BackarrowSVG';
import CloseIcon from '../../../../../containers/PageMenus/Menu9-Storybook/CloseSVG';
import FontUtil from '../../../../../utils/FontUtility';
import { useFirestore } from 'react-redux-firebase';
const P9 = ({
  sessionId,
  updateSessionInSanity,
  setPortfolioCustomerInfo,
  chosenPortfolio,
  hideModal,
  SetDisplay
}) => {
  const firestore = useFirestore();
  const updateCustomerInfo = (form) => {
    // update customer info in store
    setPortfolioCustomerInfo(form);

    if (sessionId === '') {
      return null;
    }

    const customerReplacement = {
      customer_name: form.firstName + ' ' + form.lastName,
      customer_email: form.email
    };
    let viewerLog = [];
    let viewerTemplate = {
      _type: 'viewerLog'
    };

    // add main customer
    viewerLog.push({
      ...viewerTemplate,
      email: customerReplacement.customer_email,
      name: customerReplacement.customer_name,
      _key: Math.random().toString(36).substr(2, 7)
    });

    // add cc
    if (form.ccEmails) {
      form.ccEmails.forEach((email) => {
        viewerLog.push({
          ...viewerTemplate,
          email: email,
          _key: Math.random().toString(36).substr(2, 7)
        });
      });
    }

    // add viewer log to customerReplacement.
    customerReplacement['viewer_log'] = viewerLog;

    // update customer info in sanity session and firestore
    firestore
      .collection('sessions')
      .doc(sessionId)
      .update(customerReplacement)
      .then(() => {
        // update viewer log info in sanity
        updateSessionInSanity({ viewer_log: customerReplacement.viewer_log });
        hideModal();
      })
      .catch((error) => {
        console.error('ERROR: ', error);
        hideModal();
      });
  };
  return (
    <div className="ActionMenu PII">
      <div className="HeadBar">
        <p
          className="Title"
          style={{ fontFamily: FontUtil(chosenPortfolio.SystemText) }}
        >
          Update info
        </p>
        <CloseIcon
          onClick={() => {
            hideModal();
            SetDisplay(false);
          }}
          className="closeButton"
          id={'PII1'}
        />
        {/* <img alt="Close Tools" src={ToolCross} onClick={()=>{hideModal(); SetDisplay(false);}} className="closeButton"></img> */}
        {/* <img alt="Back to Main Tools" src={BackChev} onClick={()=>{hideModal()}} className="BackButton"></img> */}
        <BackChev
          onClick={() => {
            hideModal();
          }}
          className="BackButton"
        ></BackChev>
      </div>
      <div className="Options">
        <CustomerInfoForm
          handleSubmit={updateCustomerInfo}
          submitText="Update"
        ></CustomerInfoForm>
      </div>
    </div>
  );
};
export default P9;
