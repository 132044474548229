//Guestpass and Session Master collections should be set once automatically per sign in, and have an option to update via requery
//When signing out collection should be unset for privacy and security,

import {
  SET_GUESTPASS_MASTER_COLLECTION,
  SET_SESSION_MASTER_COLLECTION,
  SET_GUESTPASS_SESSION_MASTER_COLLECTION,
  SET_GUESTPASS_SESSION_FILTERED_COLLECTION,
  SET_GUESTPASS_FILTERED_COLLECTION,
  SET_SESSION_FILTERED_COLLECTION,
  SET_GUESTPASS_SESSION_FILTERED_VIEWER_COLLECTION,
  SET_VIEWS,
  SET_SOLO,
  SET_GUESTPASS_LEAD_COLLECTION,
  SET_VIEWER,
  SET_DATERANGE,
  SET_SEARCH_TERMS,
  SET_COMPLETION_ADHERENCE,
  SET_AGGREGATE_SELECTED,
  SET_GUESTPASS_SINGLE_SESSION,
  SET_SELECTED_PORTFOLIO_ID,
  SET_DEFAULT_VISIT,
  SET_HEADER_AGGREGATE_SELECTED,
  SET_BODY_RENDER,
  SET_SHOW_SESSION_SELECT,
  SET_SHOW_PORTFOLIO_SELECT,
  SET_SHOW_USER_SELECT,
  SET_SESSION_LIST,
  SET_SESSION_DATESTRING,
  SET_SELECTED_PORTFOLIO_NAME,
  SET_EFFICIENCY_AGGREGATE_SELECTED,
  SET_GUESTPASS_HEADER_FILTERED,
  SET_EFFICIENCY_USER_TOGGLE,
  SET_EFFICIENCY_COMPANY_TOGGLE,
  SET_EFFICIENCY_PAST_TOGGLE,
  SET_EFFICIENCY_PARALLEL_TOGGLE,
  SET_SECONDARY_USER_SESSION_TOGGLE,
  SET_SECONDARY_USER_AGGREGATE_TOGGLE,
  SET_SELECTED_NOTES_SESSION,
  SET_SELECTED_NOTES_STRING,
  SET_SELECTED_NOTES_SESSION_OBJ,
  SET_PARALLEL_SESSION,
  SET_PARALLEL_DATESTRING,
  SET_USERS_OF_USER_SELECT,
  SET_SHOW_PARALLEL_SELECT,
  SET_SELECTED_USER,
  SET_GUESTPASS_LEAD_VIEWER,
  SET_SCROLLABLE_COUNT,
  SET_PREPROCESSING_NOTIFICATIONS_LIST,
  SET_POSTPROCESSING_NOTIFICATIONS_LIST,
  SET_NOTIFICATIONS_LIST,
  SET_NOTIFICATIONS_COUNT
} from '../constants/actionTypes';

const initialState = {
  solo: true,
  ScrollableCount: 10,
  PreProcessingNotificationsList: [],
  PostProcessingNotificationsList: [],
  NotificationsList: [],
  NotificationsCount: 0,
  views: [],
  viewer: undefined,
  SearchTerms: '',
  GuestpassMasterCollection: [],
  GuestpassFilteredCollection: [],
  GuestpassSessionMasterCollection: [],
  GuestpassSessionFilteredCollection: [],
  GuestpassSessionFilteredViewerCollection: [],
  SessionMasterCollection: [],
  SessionFilteredCollection: [],
  GPLeads: [],
  ActiveGPL: null,
  DateRange: 3,
  UserPool: [],
  completionAdherence: 0,
  aggregateSelected: '1m',
  efficiencyAggregateSelected: '1m',
  selectedPortfolioId: 0,
  selectedPortfolioName: 'Select a Portfolio',
  headerAggregateSelected: 30,
  defaultVisit: undefined,
  parallelSession: undefined,
  bodyRender: 'Key Topics',
  showSessionSelect: false,
  showPortfolioSelect: false,
  showParallelSelect: false,
  showUserSelect: false,
  sessionList: [],
  sessionDateString: 'Select a Portfolio Session',
  parallelDateString: 'Select a Portfolio Session',
  GuestPassSingleSession: undefined,
  guestPassHeaderFiltered: [],
  efficiencyUserToggle: 'on',
  efficiencyCompanyToggle: 'on',
  efficiencyPastToggle: 'on',
  efficiencyParallelToggle: 'on',
  secondaryUserSessionToggle: 'off',
  secondaryUserAggregateToggle: 'on',
  selectedNotesSession: undefined,
  selectedNotesString: 'Filter by User',
  selectedNotesSessionObj: {},
  selectedUser: undefined
};
//86400000 -- Day
export const DateRangeEnum = [
  // ()=> new Date(new Date(Date.now()).getFullYear(),new Date(Date.now()).getMonth(),new Date(Date.now()).getDate()),//Day
  // ()=> new Date(new Date(Date.now()-(86400000*7)).getFullYear(),new Date(Date.now()-(86400000*7)).getMonth(),new Date(Date.now()-(86400000*7)).getDate()),//1wk
  // ()=> new Date(new Date(Date.now()-(86400000*14)).getFullYear(),new Date(Date.now()-(86400000*14)).getMonth(),new Date(Date.now()-(86400000*14)).getDate()),//2wk
  // ()=> new Date(new Date(Date.now()-(86400000*30)).getFullYear(),new Date(Date.now()-(86400000*30)).getMonth(),new Date(Date.now()-(86400000*30)).getDate()),//1m
  // ()=> new Date(new Date(Date.now()-(86400000*90)).getFullYear(),new Date(Date.now()-(86400000*90)).getMonth(),new Date(Date.now()-(86400000*90)).getDate()),//3m
  // ()=> new Date(new Date(Date.now()-(86400000*180)).getFullYear(),new Date(Date.now()-(86400000*180)).getMonth(),new Date(Date.now()-(86400000*180)).getDate()),//6m
  () =>
    new Date(
      new Date(
        new Date(new Date(new Date(new Date())).setSeconds(0)).setMinutes(0)
      ).setHours(0)
    ),
  () => new Date().setDate(new Date().getDate() - 7),
  () => new Date().setDate(new Date().getDate() - 14),
  () => new Date().setDate(new Date().getDate() - 30),
  () => new Date().setDate(new Date().getDate() - 90),
  () => new Date().setDate(new Date().getDate() - 180)
];
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PREPROCESSING_NOTIFICATIONS_LIST: {
      return { ...state, PreProcessingNotificationsList: action.data };
    }
    case SET_POSTPROCESSING_NOTIFICATIONS_LIST: {
      return { ...state, PostProcessingNotificationsList: action.data };
    }
    case SET_NOTIFICATIONS_LIST: {
      return { ...state, NotificationsList: action.data };
    }
    case SET_NOTIFICATIONS_COUNT: {
      return { ...state, NotificationsCount: action.data };
    }
    case SET_SCROLLABLE_COUNT: {
      return { ...state, ScrollableCount: action.data };
    }
    case SET_GUESTPASS_LEAD_VIEWER: {
      return {
        ...state,
        ActiveGPL: action.data
      };
    }
    case SET_GUESTPASS_LEAD_COLLECTION: {
      // console.log("GPLeads-Set")
      return {
        ...state,
        GPLeads: action.data
      };
    }
    case SET_GUESTPASS_MASTER_COLLECTION:
      return {
        ...state,
        GuestpassMasterCollection: action.data
      };
    case SET_SEARCH_TERMS:
      return {
        ...state,
        SearchTerms: action.data
      };
    case SET_VIEWER:
      return {
        ...state,
        viewer: action.data
      };
    case SET_VIEWS:
      return {
        ...state,
        views: action.data
      };
    case SET_GUESTPASS_SESSION_MASTER_COLLECTION:
      console.log('UPDATEMASTERLIST');
      return {
        ...state,
        GuestpassSessionMasterCollection: action.data
      };
    case SET_SESSION_MASTER_COLLECTION:
      return {
        ...state,
        SessionMasterCollection: action.data
      };
    case SET_GUESTPASS_FILTERED_COLLECTION:
      return {
        ...state,
        GuestpassFilteredCollection: action.data
      };
    case SET_GUESTPASS_HEADER_FILTERED:
      return {
        ...state,
        guestPassHeaderFiltered: action.data
      };
    case SET_SELECTED_NOTES_SESSION:
      return {
        ...state,
        selectedNotesSession: action.data
      };
    case SET_PARALLEL_SESSION:
      return {
        ...state,
        parallelSession: action.data
      };
    case SET_SELECTED_NOTES_STRING:
      return {
        ...state,
        selectedNotesString: action.data
      };
    case SET_SELECTED_NOTES_SESSION_OBJ:
      return {
        ...state,
        selectedNotesSessionObj: action.data
      };
    case SET_GUESTPASS_SESSION_FILTERED_COLLECTION:
      return {
        ...state,
        GuestpassSessionFilteredCollection: action.data
      };
    case SET_SESSION_FILTERED_COLLECTION:
      return {
        ...state,
        SessionFilteredCollection: action.data
      };
    case SET_GUESTPASS_SESSION_FILTERED_VIEWER_COLLECTION:
      return {
        ...state,
        GuestpassSessionFilteredViewerCollection: action.data
      };
    case SET_SOLO:
      return {
        ...state,
        solo: action.data
      };
    case SET_COMPLETION_ADHERENCE:
      return {
        ...state,
        completionAdherence: action.data
      };
    case SET_AGGREGATE_SELECTED:
      return {
        ...state,
        aggregateSelected: action.data
      };
    case SET_HEADER_AGGREGATE_SELECTED:
      return {
        ...state,
        headerAggregateSelected: action.data
      };
    case SET_EFFICIENCY_AGGREGATE_SELECTED:
      return {
        ...state,
        efficiencyAggregateSelected: action.data
      };
    case SET_EFFICIENCY_USER_TOGGLE:
      return {
        ...state,
        efficiencyUserToggle: action.data
      };
    case SET_EFFICIENCY_COMPANY_TOGGLE:
      return {
        ...state,
        efficiencyCompanyToggle: action.data
      };
    case SET_EFFICIENCY_PAST_TOGGLE:
      return {
        ...state,
        efficiencyPastToggle: action.data
      };
    case SET_EFFICIENCY_PARALLEL_TOGGLE:
      return {
        ...state,
        efficiencyParallelToggle: action.data
      };
    case SET_SECONDARY_USER_SESSION_TOGGLE:
      return {
        ...state,
        secondaryUserSessionToggle: action.data
      };
    case SET_SECONDARY_USER_AGGREGATE_TOGGLE:
      return {
        ...state,
        secondaryUserAggregateToggle: action.data
      };
    case SET_SELECTED_PORTFOLIO_ID:
      return {
        ...state,
        selectedPortfolioId: action.data
      };
    case SET_SELECTED_PORTFOLIO_NAME:
      return {
        ...state,
        selectedPortfolioName: action.data
      };
    case SET_DEFAULT_VISIT:
      return {
        ...state,
        defaultVisit: action.data
      };
    case SET_BODY_RENDER:
      return {
        ...state,
        bodyRender: action.data
      };
    case SET_SHOW_SESSION_SELECT:
      return {
        ...state,
        showSessionSelect: action.data
      };
    case SET_SHOW_PORTFOLIO_SELECT:
      return {
        ...state,
        showPortfolioSelect: action.data
      };
    case SET_SHOW_PARALLEL_SELECT:
      return {
        ...state,
        showParallelSelect: action.data
      };
    case SET_SHOW_USER_SELECT:
      return {
        ...state,
        showUserSelect: action.data
      };
    case SET_SELECTED_USER:
      return {
        ...state,
        selectedUser: action.data
      };
    case SET_SESSION_LIST:
      return {
        ...state,
        sessionList: action.data
      };
    case SET_SESSION_DATESTRING:
      return {
        ...state,
        sessionDateString: action.data
      };
    case SET_PARALLEL_DATESTRING:
      return {
        ...state,
        parallelDateString: action.data
      };
    case SET_USERS_OF_USER_SELECT:
      return {
        ...state,
        UserPool: action.data
      };
    case SET_DATERANGE:
      return {
        ...state,
        DateRange: action.data
      };
    case SET_GUESTPASS_SINGLE_SESSION:
      return {
        ...state,
        GuestPassSingleSession: action.data
      };
    case 'RESET':
      return initialState;
    default:
      return state;
  }
};
