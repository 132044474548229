import { generateActions } from '../utils/reduxHelper';

// session
export const UPDATE_SESSION_KEY = 'UPDATE_SESSION_KEY';
export const SET_FIRESTORE_SESSION_KEY = generateActions(
  'SET_FIRESTORE_SESSION_KEY'
);

//dashboardRevamp
export const SET_GUESTPASS_MASTER_COLLECTION =
  'SET_GUESTPASS_MASTER_COLLECTION';
export const SET_SCROLLABLE_COUNT = 'SET_SCROLLABLE_COUNT';
export const SET_PREPROCESSING_NOTIFICATIONS_LIST =
  'SET_PREPROCESSING_NOTIFICATIONS_LIST';
export const SET_POSTPROCESSING_NOTIFICATIONS_LIST =
  'SET_POSTPROCESSING_NOTIFICATIONS_LIST';
export const SET_NOTIFICATIONS_LIST = 'SET_NOTIFICATIONS_LIST';
export const SET_NOTIFICATIONS_COUNT = 'SET_NOTIFICATIONS_COUNT';
export const SET_GUESTPASS_LEAD_COLLECTION = 'SET_GUESTPASS_LEAD_COLLECTION';
export const SET_GUESTPASS_LEAD_VIEWER = 'SET_GUESTPASS_LEAD_VIEWER';
export const SET_GUESTPASS_SESSION_MASTER_COLLECTION =
  'SET_GUESTPASS_SESSION_MASTER_COLLECTION';
export const SET_GUESTPASS_SESSION_FILTERED_COLLECTION =
  'SET_GUESTPASS_SESSION_FILTERED_COLLECTION';
export const SET_GUESTPASS_HEADER_FILTERED = 'SET_GUESTPASS_HEADER_FILTERED';
export const SET_GUESTPASS_SESSION_FILTERED_VIEWER_COLLECTION =
  'SET_GUESTPASS_SESSION_FILTERED_VIEWER_COLLECTION';
export const SET_SESSION_MASTER_COLLECTION = 'SET_SESSION_MASTER_COLLECTION';
export const SET_GUESTPASS_FILTERED_COLLECTION =
  'SET_GUESTPASS_FILTERED_COLLECTION';
export const SET_SESSION_FILTERED_COLLECTION =
  'SET_SESSION_FILTERED_COLLECTION';
export const SET_SOLO = 'SET_SOLO';
export const SET_GUESTPASS_SINGLE_SESSION = 'SET_GUESTPASS_SINGLE_SESSION';
export const SET_VIEWS = 'SET_VIEWS';
export const SET_VIEWER = 'SET_VIEWER';
export const SET_DATERANGE = 'SET_DATERANGE';
export const SET_COMPLETION_ADHERENCE = 'SET_COMPLETION_ADHERENCE';
export const SET_AGGREGATE_SELECTED = 'SET_AGGREGATE_SELECTED';
export const SET_HEADER_AGGREGATE_SELECTED = 'SET_HEADER_AGGREGATE_SELECTED';
export const SET_EFFICIENCY_AGGREGATE_SELECTED =
  'SET_EFFICIENCY_AGGREGATE_SELECTED';
export const SET_SELECTED_PORTFOLIO_ID = 'SET_SELECTED_PORTFOLIO_ID';
export const SET_SELECTED_PORTFOLIO_NAME = 'SET_SELECTED_PORTFOLIO_NAME';
export const SET_DEFAULT_VISIT = 'SET_DEFAULT_VISIT';
export const SET_SEARCH_TERMS = 'SET_SEARCH_TERMS';
export const SET_BODY_RENDER = 'SET_BODY_RENDER';
export const SET_SHOW_SESSION_SELECT = 'SET_SHOW_SESSION_SELECT';
export const SET_SHOW_PORTFOLIO_SELECT = 'SET_SHOW_PORTFOLIO_SELECT';
export const SET_SHOW_PARALLEL_SELECT = 'SET_SHOW_PARALLEL_SELECT';
export const SET_SESSION_LIST = 'SET_SESSION_LIST';
export const SET_SESSION_DATESTRING = 'SET_SESSION_DATESTRING';
export const SET_PARALLEL_DATESTRING = 'SET_PARALLEL_DATESTRING';
export const SET_EFFICIENCY_USER_TOGGLE = 'SET_EFFICIENCY_USER_TOGGLE';
export const SET_EFFICIENCY_COMPANY_TOGGLE = 'SET_EFFICIENCY_COMPANY_TOGGLE';
export const SET_EFFICIENCY_PAST_TOGGLE = 'SET_EFFICIENCY_PAST_TOGGLE';
export const SET_EFFICIENCY_PARALLEL_TOGGLE = 'SET_EFFICIENCY_PARALLEL_TOGGLE';
export const SET_SECONDARY_USER_SESSION_TOGGLE =
  'SET_SECONDARY_USER_SESSION_TOGGLE';
export const SET_SECONDARY_USER_AGGREGATE_TOGGLE =
  'SET_SECONDARY_USER_AGGREGATE_TOGGLE';
export const SET_SELECTED_NOTES_SESSION = 'SET_SELECTED_NOTES_SESSION';
export const SET_SELECTED_NOTES_STRING = 'SET_SELECTED_NOTES_STRING';
export const SET_SELECTED_NOTES_SESSION_OBJ = 'SET_SELECTED_NOTES_SESSION_OBJ';
export const SET_PARALLEL_SESSION = 'SET_PARALLEL_SESSION';
export const SET_SHOW_USER_SELECT = 'SET_SHOW_USER_SELECT';
export const SET_USERS_OF_USER_SELECT = 'SET_USERS_OF_USER_SELECT';
export const SET_SELECTED_USER = 'SET_SELECTED_USER';

// portfolio
export const KM_RESET = 'KM_RESET';
export const SET_PORTFOLIO_CUSTOMER_INFO = 'SET_PORTFOLIO_CUSTOMER_INFO';
export const SET_PORTFOLIO_ID = 'SET_PORTFOLIO_ID';
export const SET_CHOSEN_PORTFOLIO = 'SET_CHOSEN_PORTFOLIO';
export const SET_PORTFOLIO_MODE = 'SET_PORTFOLIO_MODE';
export const RESET_PORTFOLIO_INFO = 'RESET_PORTFOLIO_INFO';
export const RESET_PORTFOLIO_INFO_MidSession =
  'RESET_PORTFOLIO_INFO_MidSession';
export const SET_PORTFOLIO_SESSION_ID = 'SET_PORTFOLIO_SESSION_ID';
export const END_PORTFOLIO = 'END_PORTFOLIO';
export const SET_ANNOTATOR = 'SET_ANNOTATOR';
export const SET_BACKGROUND_IMAGE_URL = 'SET_BACKGROUND_IMAGE_URL';
export const SET_PAGE_BACKGROUND_IMAGE_URL = 'SET_PAGE_BACKGROUND_IMAGE_URL';
export const SET_PORTFOLIO_SUBPAGE_ID = 'SET_PORTFOLIO_SUBPAGE_ID';
export const SET_PII_BACKGROUND_IMAGE_URL = 'SET_PII_BACKGROUND_IMAGE_URL';
export const SET_PORTFOLIO_INTRO_IMAGE_URL = 'SET_PORTFOLIO_INTRO_IMAGE_URL';
export const SET_PORTFOLIO_LAUNCH_IMAGE_URL = 'SET_PORTFOLIO_LAUNCH_IMAGE_URL';
export const SET_PORTFOLIO_SUBMENU_IMAGE_URL =
  'SET_PORTFOLIO_SUBMENU_IMAGE_URL';
export const SET_PORTFOLIO_TERMMENU_IMAGE_URL =
  'SET_PORTFOLIO_TERMMENU_IMAGE_URL';
export const SET_PORTFOLIO_TOPLEVEL_IMAGE_URL =
  'SET_PORTFOLIO_TOPLEVEL_IMAGE_URL';
export const SET_LIKED = 'SET_LIKED';
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const SET_LOGIN_INPUT_SELECTED = 'SET_LOGIN_INPUT_SELECTED';
export const SET_LOGIN_INPUT_SELECTED_COUNT = 'SET_LOGIN_INPUT_SELECTED_COUNT';
export const SET_CLICKED_INTRO_BUTTON = 'SET_CLICKED_INTRO_BUTTON';
export const SET_TOOLBAR_MENU_OPEN = 'SET_TOOLBAR_MENU_OPEN';
export const SET_TOOLBAR_MENU_VISIBILITY = 'SET_TOOLBAR_MENU_VISIBILITY';
export const SET_MASTER_MENU_OPEN = 'SET_MASTER_MENU_OPEN';
export const SET_SELECTED_TOP_LEVEL_ID = 'SET_SELECTED_TOP_LEVEL_ID';
export const SET_TWOLEVEL_TERMINUS = 'SET_TWOLEVEL_TERMINUS';
export const SET_SCREENSAVER_SHOW = 'SET_SCREENSAVER_SHOW';

// sanity contents
export const GET_SANITY_CONTENT = generateActions('GET_SANITY_CONTENT');
export const GET_SANITY_USER = generateActions('GET_SANITY_USER');
export const GET_SANITY_SESSION = generateActions('GET_SANITY_SESSION');
export const ADD_SESSION = generateActions('ADD_SESSION');
export const ADD_GUEST_PASS = generateActions('ADD_GUEST_PASS');
export const UPDATE_SESSION = generateActions('UPDATE_SESSION');

// briefcase
export const TOGGLE_BRIEFCASE = 'TOGGLE_BRIEFCASE';
export const ADD_TO_BRIEFCASE = 'ADD_TO_BRIEFCASE';
export const REMOVE_FROM_BRIEFCASE = 'REMOVE_FROM_BRIEFCASE';
export const CLEAR_BRIEFCASE = 'CLEAR_BRIEFCASE';
export const SET_BOOLEAN_NEW_ITEMS = 'SET_BOOLEAN_NEW_ITEMS';

// modal
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

// page menu
export const SET_CHOSEN_PAGE_ID = 'SET_CHOSEN_PAGE_ID';
export const SET_CHOSEN_MAIN_PAGE_BUTTON = 'SET_CHOSEN_MAIN_PAGE_BUTTON';
export const SET_CONTENT_PAGE_ID = 'SET_CONTENT_PAGE_ID'; // page id for content display
export const SET_CURRENT_LEVEL = 'SET_CURRENT_LEVEL';
export const SET_IS_AT_END = 'SET_IS_AT_END';
export const SET_MENU_HIDE = 'SET_MENU_HIDE';
export const SET_NAV_HISTORY = 'SET_NAV_HISTORY';
export const SET_SAVED_CONTENT_PAGE_ID = 'SET_SAVED_CONTENT_PAGE_ID';
export const SET_HAS_CURR = 'SET_HAS_CURR';
export const SET_HAS_PREV = 'SET_HAS_PREV';
export const SET_TOPIC = 'SET_TOPIC';
export const SET_MEGAMENU_LEVEL = 'SET_MEGAMENU_LEVEL';
export const SET_TERM_WITH_CHILD = 'SET_TERM_WITH_CHILD';
export const SET_SHOW_TERM_CHILD = 'SET_SHOW_TERM_CHILD';

// guest
export const SET_GUEST_PAGES = 'SET_GUEST_PAGES';
export const SET_GUEST_DATA = 'SET_GUEST_DATA';
export const SET_GUEST_MODE = 'SET_GUEST_MODE';
export const SET_GUEST_CONTENT_HELPER = 'SET_GUEST_CONTENT_HELPER'; // for gallery ?
export const PREPARE_GUEST = 'PREPARE_GUEST';
export const SET_CURRENT_CONTENT_HELPER = 'SET_CURRENT_CONTENT_HELPER'; // for gallery
export const GET_EXPIRE_DATE = generateActions('GET_EXPIRE_DATE');

// admin
export const GET_USERS = generateActions('GET_USERS');
export const CREATE_USER = generateActions('CREATE_USER');
export const PATCH_USER = generateActions('PATCH_USER');
export const DELETE_USER = generateActions('DELETE_USER');
export const GET_TEAMS = generateActions('GET_TEAMS');
export const CREATE_TEAM = generateActions('CREATE_TEAMS');
export const PATCH_TEAM = generateActions('PATCH_TEAMS');
export const DELETE_TEAM = generateActions('DELETE_TEAMS');

// notepad
export const TOGGLE_NOTEPAD = 'TOGGLE_NOTEPAD';
export const UPDATE_NOTEPAD = 'UPDATE_NOTEPAD';
export const UPDATE_NOTEPAD2 = 'UPDATE_NOTEPAD2';

// scriptnote
export const TOGGLE_SCRIPTNOTE = 'TOGGLE_SCRIPTNOTE';

// video
export const SET_IS_VIDEO_PLAYING = 'SET_IS_VIDEO_PLAYING';

// Clear all state
export const CLEAR_STORE = 'CLEAR_STORE';
