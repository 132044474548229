import {
  GET_SANITY_CONTENT,
  GET_SANITY_SESSION,
  ADD_SESSION,
  ADD_GUEST_PASS,
  UPDATE_SESSION,
  GET_SANITY_USER
} from '../constants/actionTypes';
import { client, writeClient } from '../utils/client';

//===================
// GET
//===================
export const getSanityContentBegin = () => ({
  type: GET_SANITY_CONTENT.BEGIN
});

export const getSanityContentSuccess = (data) => ({
  type: GET_SANITY_CONTENT.SUCCESS,
  data
});

export const getSanityContentFailure = () => ({
  type: GET_SANITY_CONTENT.FAILURE
});

export const getSanitySessionBegin = () => ({
  type: GET_SANITY_SESSION.BEGIN
});

export const getSanitySessionSuccess = (data) => ({
  type: GET_SANITY_SESSION.SUCCESS,
  data
});

export const getSanitySessionFailure = () => ({
  type: GET_SANITY_SESSION.FAILURE
});

export const getSanityUser = (data) => ({
  type: GET_SANITY_USER,
  data
});

//===================
// ADD
//===================
export const addSessionBegin = () => ({
  type: ADD_SESSION.BEGIN
});

export const addSessionSuccess = (id) => ({
  type: ADD_SESSION.SUCCESS,
  id
});

export const addSessionFailure = () => ({
  type: ADD_SESSION.FAILURE
});

export const addGuestPassBegin = () => ({
  type: ADD_GUEST_PASS.BEGIN
});

export const addGuestPassSuccess = (id) => ({
  type: ADD_GUEST_PASS.SUCCESS,
  id
});

export const addGuestPassFailure = () => ({
  type: ADD_GUEST_PASS.FAILURE
});

//===================
// UPDATE
//===================
export const updateSessionBegin = () => ({
  type: UPDATE_SESSION.BEGIN
});

export const updateSessionSuccess = () => ({
  type: UPDATE_SESSION.SUCCESS
});

export const updateSessionFailure = () => ({
  type: UPDATE_SESSION.FAILURE
});

// GET sanity content
export const getSanityContent = () => {
  return (dispatch, getState) => {
    const state = getState();

    // console.log('[web] getSanityContent state', state);
    // get role
    const role = state.firebase.profile ? state.firebase.profile.role : 'user';
    // console.log('[web] getSanityContent role', role);
    // get uid
    const uid = state.firebase.auth.uid;
    // console.log('[web] getSanityContent uid', uid);
    // Begin sanity GET
    dispatch(getSanityContentBegin());

    if (!state.firebase.profile) {
      console.error('ERROR: no profile for this user....');
    }

    let sessionLogLimitQuery = ``;
    let allSanityMember = ``;

    // if role is user, limit session log to only to that user
    if (role !== 'admin' && role !== 'manager') {
      sessionLogLimitQuery = `&& creator->uid == '${uid}'`;
    } else if (role === 'admin' || role === 'manager') {
      // get all sanity members/users
      allSanityMember = `"members": *[_type == 'member'  && !(_id in path('drafts.**'))]`;
    }
    // Get all contents of sanity needed in portfolio, ignore drafts
    const query = `*[][1]{
      "pdfs": *[_type == 'sanity.fileAsset'  && !(_id in path('drafts.**'))]{_id, _type, url, extension, mimeType}, 
      "images": *[_type == 'sanity.imageAsset'  && !(_id in path('drafts.**'))]{_id, _type, url}, 
      "videos": *[_type == 'mux.videoAsset' && status == 'ready'  && !(_id in path('drafts.**'))],
      "portfolios": *[_type == 'portfolio' && !(_id in path('drafts.**'))],
      "company": *[_type == 'company-config'  && !(_id in path('drafts.**'))] | order(_updatedAt desc) [0],
      "member": *[_type == 'member' && uid == '${uid}'  && !(_id in path('drafts.**'))][0],
      "sessionLogs": *[_type == 'session-log' ${sessionLogLimitQuery} && !(_id in path('drafts.**'))] | order(_createdAt desc),
      "GuestPasses": *[_type == 'guest-pass' ${sessionLogLimitQuery} && !(_id in path('drafts.**'))] | order(_createdAt desc),
      "screensavers": *[_type == 'screensaver' && !(_id in path('drafts.**'))],
      ${allSanityMember}
    }`;
    const params = {};

    client() //Replace with cloud function to take query string, also requires company ID,
      .fetch(query, params)
      .then((data) => {
        // console.log('[web] getSanityContent SUCCESS data', data);
        // Success
        dispatch(getSanityContentSuccess(data));
      })
      .catch(() => {
        // console.error('[web] getSanityContent CATCH ERROR');
        // Fail
        dispatch(getSanityContentFailure());
        // console.error(error);
      });
  };
};

// GET sessions logs, used for dashboard session refresh
export const getSanitySessionLogs = () => {
  return (dispatch, getState) => {
    const state = getState();

    // get role
    const role = state.firebase.profile ? state.firebase.profile.role : 'user';
    // get uid
    const uid = state.firebase.auth.uid;
    // Begin santify GET
    dispatch(getSanitySessionBegin());

    if (!state.firebase.profile) {
      console.error('ERROR: no profile for this user....');
    }

    let sessionLogLimitQuery = ``;

    // if role is user, limit session log to only to that user
    if (role !== 'admin' && role !== 'manager') {
      sessionLogLimitQuery = `&& creator->uid == '${uid}'`;
    }
    // Get all contents of sanity needed in portfolio, ignore drafts
    const query = `*[][1]{
      "sessionLogs": *[_type == 'session-log' ${sessionLogLimitQuery} && !(_id in path('drafts.**'))] | order(_createdAt desc),
    }`;
    const params = {};

    client() //Replace with Cloud function to take query string
      .fetch(query, params)
      .then((data) => {
        // Success
        dispatch(getSanitySessionSuccess(data));
      })
      .catch(() => {
        // Fail
        dispatch(getSanitySessionFailure());
        // console.error(error);
      });
  };
};

// ADD session info to sanity
export const addSessionToSanity = (session) => {
  return (dispatch) => {
    // Begin ADD
    dispatch(addSessionBegin());

    // convert firestore UTC seconds to datetime
    let date = Date(1000 * session.session_date.seconds);
    let title =
      session.type === 'guest'
        ? `Guest: ${session.customer_name} on ${date.toString()}`
        : session.type === 'embed'
        ? `Embed viewed by ${session.gp_name} on ${date.toString()}`
        : `${session.creator} with ${
            session.customer_name
          } on ${date.toString()}`;

    // console.log('[web] addSessionToSanity', session);
    // console.log('[web] session.user_sanity_id', session.user_sanity_id);
    const sanitySession = {
      title: title,
      _type: 'session-log',
      creator: {
        _ref: session.user_sanity_id, // NOTE This is not the same as uid, it is id specific to sanity
        _type: 'reference',
        _weak: true // 2024-01: Shifting to weak reference per Sanity feedback
      },
      portfolio: {
        _ref: session.portfolio_id,
        _type: 'reference',
        _weak: true // 2024-01: Shifting to weak reference per Sanity feedback
      },
      session_date: date,
      session_id: session.session_id,
      session_type: session.session_type,
      viewer_log: session.viewer_log
    };

    writeClient() //replace with Cloud write function
      .create(sanitySession)
      .then((res) => {
        console.log(res);
        // Success
        dispatch(addSessionSuccess(res._id));
        // console.log('Sanity session create success');
      })
      .catch((error) => {
        dispatch(addSessionFailure());
        console.error('Error', error);
      });
  };
};

// ADD guest pass to sanity
export const addGuestPassToSanity = (guestPass) => {
  //////////onsole.log('create');
  return (dispatch) => {
    // Begin ADD
    dispatch(addGuestPassBegin());
    // convert firestore UTC seconds to datetime
    let date = new Date(1000 * guestPass.creation_date.seconds);
    // add 1 month (30 days)
    let expireDate = new Date(
      1000 * (guestPass.creation_date.seconds + 2419200 + 172800) //28 days + 2 days
    );
    // console.log('[web] addGuestPassToSanity', guestPass);
    const sanityGuestPass = {
      title: `Guest pass for ${guestPass.customer_name} - ${date.toString()}`,
      _type: 'guest-pass',
      creator: {
        _ref: guestPass.user_sanity_id, // NOTE This is not the same as uid, it is id specific to sanity
        _type: 'reference',
        _weak: true // 2024-01: Shifting to weak reference per Sanity feedback
      },
      creation_date: date,
      expire_date: expireDate,
      share_url: guestPass.url,
      viewer_log: guestPass.viewer_log,
      portfolio: {
        _ref: guestPass.portfolio_id,
        _type: 'reference',
        _weak: true // 2024-01: Shifting to weak reference per Sanity feedback
      }
    };

    writeClient() //Replace with Cloud write function
      .create(sanityGuestPass)
      .then(() => {
        // Success
        dispatch(addGuestPassSuccess());
        //////////onsole.log('Sanity add guest pass success');
      })
      .catch((error) => {
        dispatch(addGuestPassFailure());
        console.error('Error', error);
      });
  };
};

export const UpdateUserInformationPicture = (UII, Image) => {
  writeClient()
    .assets.upload('image', Image)
    .then((data) => {
      // //////onsole.log(data);
      writeClient()
        .patch(UII._id)
        .set({ 'image.asset._ref': `${data._id}` })
        .set({ 'image.crop': null })
        .set({ 'image.hotspot': null })
        .commit()
        .then(() => {
          // console.log('!!!UPDATE!!!', update);
        });
    });
};

// export const getUpdatedUser = () => {
//   return (dispatch, getState) =>{
//     const state = getState();
//     const uid = state.firebase.auth.uid;
//     const query = `*[][1]{
//       "member": *[_type == 'member' && uid == '${uid}'  && !(_id in path('drafts.**'))][0]
//     }`;
//     const params = {};

//     client()//Replace with cloud function to take query string, also requires company ID,
//       .fetch(query, params)
//       .then((data) => {
//         // Success
//         dispatch(getSanityUser(data));
//       })
//   }
// }

export const getUpdatedUser = (Mem) => {
  //Set updated user
  return (dispatch) => {
    //const state = getState();
    //const uid = state.firebase.auth.uid;
    // const member = state.sanityContentReducer.member
    // const query = `*[][1]{
    //   "member": *[_type == 'member' && uid == '${uid}'  && !(_id in path('drafts.**'))][0]
    // }`;
    //const params = {};
    dispatch(getSanityUser({ member: Mem }));
    // client()//Replace with cloud function to take query string, also requires company ID,
    //   .fetch(query, params)
    //   .then((data) => {
    //     // Success
    //     dispatch(getSanityUser(data));
    //   })
  };
};

// update session info in sanity
export const updateSessionInSanity = (obj) => {
  return (dispatch, getState) => {
    // Begin update
    dispatch(updateSessionBegin());

    const state = getState();
    // get sanitySessionId
    const sanitySessionId = state.portfolioReducer.sanitySessionId;

    if (sanitySessionId && sanitySessionId !== '') {
      writeClient() //Replace with cloud write function
        .patch(sanitySessionId)
        .set(obj) // Shallow merge
        .commit()
        .then((res) => {
          // Success
          dispatch(updateSessionSuccess());
          // console.log('[web] updateSessionInSanity SUCCESS res', res);
          return true;
        })
        .catch((error) => {
          dispatch(updateSessionFailure());
          // console.error('[web] updateSessionInSanity ERROR error', error);
          alert('Save Failed.');
          return false;
        });
    } else {
      dispatch(updateSessionFailure());
      // console.error(
      //   '[web] updateSessionInSanity, Error: No sanity session id found'
      // );
      alert('Save Failed.');
      return false;
    }
  };
};
