import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { sortByKey } from '../../../utils/alphabetizer';
import { urlFor } from '../../../utils/sanityHelper';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';

const UserSelectContainer = styled.div`
  position: absolute;
  bottom: -342px;
  left: -334px;
  height: 350px;
  width: 350px;
  z-index: 10;
  background-color: #eeeeee;
  box-shadow: 5px 5px 8px #3b3b3b;
  border-radius: 8px;
  display: grid;
  grid-template-rows: 4fr 6fr;

  @media (max-width: 1380px) {
    right: -336px;
    left: 0;
  }

  .top-row {
    display: grid;
    grid-template-rows: 4fr 1.5fr;
    background-color: rgb(5, 106, 175);
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    border-top: 2px solid white;
    border-right: 2px solid white;
    border-left: 2px solid white;
    box-shadow: 2px 4px 4px -4px black;

    .picture-box {
      display: flex;
      justify-content: center;
      align-items: center;

      .picture {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 3%;
      }

      .info {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        overflow: hidden;
        margin-left: 3%;

        p {
          margin-block-start: 0px;
          margin-block-end: 0px;
          font-size: 1.2em;
          font-weight: 600;
          font-family: 'Oswald', sans-serif;
          letter-spacing: 0.02em;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          color: white;
        }
      }
    }

    .filter-box {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;

      .filter-bar {
        display: flex;
        justify-content: center;
        width: 90%;
        height: 25px;
        position: relative;

        input {
          width: 90%;
          height: 19px;
          border-right: none;
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
          text-align: center;
          font-family: 'Newsreader', serif;
          border-color: #3b3b3b;
          color: #3b3b3b;
        }

        .icon {
          background-color: #3b3b3b;
          width: 10%;
          height: 25px;
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .bottom-row {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    overflow-y: auto;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: white;

    .no-users-text {
      margin-block-start: 0px;
      margin-block-end: 0px;
      font-size: 1.3em;
      font-weight: 600;
      font-family: 'Newsreader', serif;
      color: #3b3b3b;
      font-style: italic;
    }

    .user-box {
      height: 50%;
      width: 33.33%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      cursor: pointer;

      .mini-profile-picture {
        height: 60%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        div {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #dedede;
          height: 80%;
          width: 45%;
          border-radius: 50%;
          overflow: hidden;
          border: 2px solid #2b2b2b;

          p {
            margin-block-start: 0px;
            margin-block-end: 0px;
            font-size: 1.2em;
            font-weight: 600;
            font-family: 'Open Sans', sans-serif;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            color: white;
            text-shadow: 1px 1px 1px #808080;
          }
        }
      }

      .mini-profile-name {
        height: 40%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        overflow: hidden;

        p {
          margin-block-start: 0px;
          margin-block-end: 0px;
          font-size: 0.8em;
          font-weight: 600;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          color: #3b3b3b;
          text-shadow: 1px 1px 1px #808080;
          font-family: 'Newsreader', serif;
          text-align: center;
        }
      }
    }
  }
`;

const UserSelect = ({
  showUserSelect,
  setShowUserSelect,
  member,
  userInfo,
  UserPool,
  setSelectedUser,
  setAggregateSelected,
  setDefaultVisit,
  setSessionDateString,
  setParallelSession,
  setParallelDateString,
  setSecondaryUserAggregateToggle,
  setSecondaryUserSessionToggle,
  setSelectedNotesString,
  setSelectedNotesSession,
  setSelectedNotesSessionObj,
  setEfficiencyAggregateSelected,
  SetDaterangeDispatch,
  setEfficiencyCompanyToggle
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPlaceholder, setCurrentPlaceholder] =
    useState('Filter by Name');
  const IRef = useRef();
  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
    if (event.target.value !== '') {
      setCurrentPlaceholder('');
    } else {
      setCurrentPlaceholder('Filter by User Name');
    }
  };

  let filteredUsers = UserPool.filter((user) => {
    return user.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
  });
  filteredUsers = sortByKey(filteredUsers, 'name');
  let userMapping;
  if (filteredUsers?.length && UserPool?.length) {
    userMapping = filteredUsers.map((user, index) => {
      const colorPallette = [
        '#F64740',
        '#477998',
        '#34113F',
        '#7FC29B',
        '#E15A97',
        '#9F7E69',
        '#136F63',
        '#1098F7'
      ];
      let selector = index % colorPallette.length;
      let color = colorPallette[selector];

      let firstName = user.name.split(' ')[0];
      let fnInitial = firstName.split('')[0];
      let lastName = user.name.split(' ')[1];
      let lnInitial;
      if (lastName === undefined) {
        lastName = '';
        lnInitial = '';
      } else {
        lnInitial = lastName.split('')[0];
      }

      return (
        <div
          className="user-box"
          key={user.id}
          style={{ backgroundColor: '#eeeeee' }}
          onClick={() => {
            setSelectedUser([user]);
            setAggregateSelected('1m');
            setDefaultVisit(undefined);
            setSessionDateString('Select a Portfolio Session');
            setParallelSession(undefined);
            setParallelDateString('Select a Portfolio Session');
            setSecondaryUserSessionToggle('off');
            setSecondaryUserAggregateToggle('on');
            setSelectedNotesString('Sort by User');
            setSelectedNotesSession(undefined);
            setSelectedNotesSessionObj({});
            setEfficiencyAggregateSelected('1m');
            SetDaterangeDispatch(3);
            setEfficiencyCompanyToggle('on');
          }}
        >
          <div className="mini-profile-picture">
            <div style={{ backgroundColor: color }}>
              <p>
                {fnInitial}
                {lnInitial}
              </p>
            </div>
          </div>
          <div className="mini-profile-name">
            <p>{firstName}</p>
            <p>{lastName}</p>
          </div>
        </div>
      );
    });
  } else {
    userMapping = <p className="no-users-text">No team members listed...</p>;
  }

  return (
    <UserSelectContainer
      id="user-select-box"
      style={{ visibility: showUserSelect ? 'visible' : 'hidden' }}
    >
      <div className="top-row">
        <div className="picture-box">
          <div className="picture">
            <img
              alt={'User Profile'}
              style={{
                width: '90px',
                height: '90px',
                borderRadius: '50%',
                border: '2px solid #3b3b3b'
              }}
              src={urlFor(member.image)}
            />
          </div>
          <div className="info">
            <p style={{ fontSize: '1.2em', fontWeight: 500 }}>{member.name}</p>
            <p
              style={{
                fontSize: '0.9em',
                fontWeight: 400,
                fontStyle: 'italic'
              }}
            >
              {userInfo[0].email}
            </p>
          </div>
        </div>
        <div className="filter-box">
          <div className="filter-bar">
            <input
              value={searchTerm}
              onChange={handleInputChange}
              type="text"
              placeholder={currentPlaceholder}
              id="user-select-filter"
              ref={IRef}
            />
            <p
              id={'user-select-filter'}
              style={{
                position: 'absolute',
                right: '35px',
                top: 'calc(50% - 4.75px)',
                fontSize: '9.5px',
                userSelect: 'none',
                marginBlock: '0px'
              }}
              onClick={(eve) => {
                IRef.current.value = '';
                handleInputChange({
                  target: { ...IRef.current.props, value: '' }
                });
                IRef.current.focus();
              }}
            >
              X
            </p>
            <div className="icon">
              <SearchRoundedIcon
                style={{
                  color: 'white',
                  fontSize: '1.2em'
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="bottom-row"
        style={{
          justifyContent: filteredUsers?.length ? 'flex-start' : 'center',
          alignItems: filteredUsers?.length ? 'flex-start' : 'center',
          alignContent: filteredUsers?.length ? 'flex-start' : 'center'
        }}
      >
        {userMapping}
      </div>
    </UserSelectContainer>
  );
};

export default UserSelect;
