import React, { useState, useEffect, useRef } from 'react';
import BlockContent from '@sanity/block-content-to-react';
import serializers from '../../../../utils/scriptSerializers';
import useAsyncReference2 from '../../../../utils/Ref-State-hook';
// import Circle from './Ellipse 1.png'
// import Arrow from './Vector 1.png'
import Mixed from './mixed media-46.png';
import Globe from './Icons V1-45.png';
import Photo from './Icons V1-44.png';
import Video from './Icons V1-43.png';
import TPBG from './TransparentBG.png';
import Page from './Icons V1-41.png';
// import Scrollindicator from './icon-Scroll-Drag-Right.png'
import PDFViewer2 from '../../../../components/FullWidthPDFViewer'; //BeforeAfterSlider
import BASlider from '../../../../components/BeforeAfterSlider'; //BeforeAfterSlider
import FontUtil from '../../../../utils/FontUtility';
import ColorUtil from '../../../../utils/ColorUtility';
import { useIdleTimer } from 'react-idle-timer';
//import BlockContent from '@sanity/block-content-to-react';
// import {renderSingleImageExport} from '../../../../components/_Portfolio/PortfolioContent/PortfolioContent'
// import {contentDataExport} from '../../../../components/_Portfolio/PortfolioContentParentTemplateX/PortfolioContentParent'
// import { Transform } from '@material-ui/icons';
// import { isEmpty } from 'lodash';
import { urlFor } from '../../../../utils/sanityHelper';
// import ArrowIcon from '../ArrowSVG';
//import serializers from '../../../../utils/serializers';
const SubMenuItem = ({
  G,
  Val,
  pages,
  onButtonClick,
  level,
  images,
  videos,
  pdfs,
  isGuestMode,
  chosenPortfolio,
  key,
  SetTopicArray,
  TMGD,
  Size
}) => {
  // FLAG FONT FAMILY
  const Limit = 3;
  //const [Target, setTarget] = useState(0);
  //const TargetRef = useRef(Target);
  const [TargetdotValue] = useAsyncReference2(0);
  ////onsole.log("!!CHECK",pages[Val.referenceId]);
  let OVTINT = undefined;
  if (
    chosenPortfolio.overlay_tint_color &&
    chosenPortfolio.overlay_tint_color !== '#' &&
    chosenPortfolio.overlay_tint_color !== undefined
  ) {
    OVTINT = chosenPortfolio.overlay_tint_color + '40';
  }
  let SMColor = undefined;
  if (
    chosenPortfolio.submenu_color &&
    chosenPortfolio.submenu_color !== '#' &&
    chosenPortfolio.submenu_color !== undefined
  )
    SMColor = chosenPortfolio.submenu_color;
  if (
    pages[Val.referenceId].submenu_color &&
    pages[Val.referenceId].submenu_color !== '#' &&
    pages[Val.referenceId].submenu_color !== undefined
  )
    SMColor = pages[Val.referenceId].submenu_color;
  // const [Items,setItems]= useState(<></>)
  const blockContent = (blocks) => {
    // filter non valid imageLinks
    let tempBlocks = blocks.filter((obj) => {
      if (obj._type === 'imageLinks' && obj.image) {
        return true;
      }
      if (obj._type !== 'imageLinks') {
        return true;
      }
      return false;
    });

    // further filter non valid embedHtml
    tempBlocks = tempBlocks.filter((obj) => {
      if (obj._type === 'embedHTML' && obj.html) {
        return true;
      }
      if (obj._type !== 'embedHTML') {
        return true;
      }
      return false;
    });
    return tempBlocks;
  };
  const contentDataExport = (ID) => {
    //let tabData = {};
    // if (
    //   isGuestMode &&
    //   ID !== 0 &&
    //   !isEmpty(currentContentHelper) &&
    //   pages[ID]._type === 'gallery' &&
    //   pages[ID]._key === currentContentHelper.page_id &&
    //   currentContentHelper.is_gallery_item
    // ) {
    //   //////////onsole.log('MODIFY FOR GALLERY CONTENT');
    //   // create obj to only display gallery item chosen by guest
    //   let obj =
    //     pages[ID].content[currentContentHelper.gallery_index];
    //   tabData = [{ ...obj }];
    //   //////////onsole.log(pages[ID]);
    //   //////////onsole.log('!!!!TAB DATA!!!! ', tabData);
    //   return tabData;
    // }
    //////////onsole.log('!!!!CHOSEN CONTENT PAGE ID ', ID);
    return pages[ID].content;
  };
  const renderSingleImageExport = (type, galleryNum, contents) => {
    switch (type) {
      case 'galleryImage': {
        let image = contents[galleryNum].image;
        return (
          <img
            id="VENTTEST"
            className="portfolio-single-image"
            src={urlFor(image).auto('format').url()} // auto converts to webp if browser supports it
            alt="mainImage"
            type="galleryImage"
            style={{
              height: '100%',
              width: '100%',
              objectFit: 'cover'
              //   opacity: chosenPortfolio && chosenPortfolio.theme === 'theme2' ? opacityBack : 1,
              //   width: imageWidth,
              //   height: imageHeight
            }}
          />
        );
      }
      case 'galleryVideo': {
        let video = videos[contents[galleryNum].video.asset._ref];
        //let videoSettings = contents[galleryNum];
        return (
          <img
            style={{ height: '100%', width: '100%', objectFit: 'contain' }}
            src={`https://image.mux.com/${
              video.playbackId
            }/thumbnail.png?fit_mode=preserve&amp;time=${
              video.thumbTime !== 0 ? video.thumbTime : 1
            }`}
            alt="poster"
          />
          // <SanityMuxPlayer
          //   assetDocument={video}
          //   autoload={true}
          //   autoplay={videoSettings.autoplay}
          //   loop={videoSettings.loop}
          //   muted={false}
          //   showControls={true}
          //   style={{}}
          //   posterurl={`https://image.mux.com/${video.playbackId}/thumbnail.png?width=200&amp;fit_mode=preserve&amp;time=1`}
          //   type="galleryVideo"
          //   isSelected={isSelected}
          // />
        );
      }
      case 'galleryPdf': {
        let url2 = pdfs[contents[galleryNum].file.asset._ref].url;
        let thumbNailRef2 = contents[galleryNum].thumbnail.asset._ref;
        let thumbNailUrl2 = urlFor(thumbNailRef2).auto('format').url();
        return (
          <PDFViewer2
            url={url2}
            isThumbnail={true}
            type="galleryFullWidthPdf"
            thumbnailUrl={thumbNailUrl2}
          />
        );
      }
      case 'galleryBASlider': {
        let Aimage = contents[galleryNum].Aimage;
        let Bimage = contents[galleryNum].Bimage;
        let LabelA = contents[galleryNum].AimageL;
        let LabelB = contents[galleryNum].BimageL;
        let Vert = contents[galleryNum].Vert;
        let Color = contents[galleryNum].SlideColor;
        ////////onsole.log('!!!!BA!!!!', contents[galleryNum])
        return (
          <BASlider
            Thumb
            LabelB={LabelB}
            LabelA={LabelA}
            Color={Color}
            Vert={Vert}
            ImageA={Aimage}
            ImageB={Bimage}
          />
        );
      }
      case 'galleryFullWidthPdf': {
        let url3 = pdfs[contents[galleryNum].file.asset._ref].url;
        let thumbNailRef3 = contents[galleryNum].thumbnail.asset._ref;
        let thumbNailUrl3 = urlFor(thumbNailRef3).auto('format').url();
        return (
          <PDFViewer2
            url={url3}
            isThumbnail={true}
            type="galleryFullWidthPdf"
            thumbnailUrl={thumbNailUrl3}
          />
        );
      }
      case 'htmlContent':
        return (
          <div className="html-block-content">
            <BlockContent
              blocks={blockContent(contents)}
              serializers={serializers}
            />
          </div>
        );
      case 'website':
        return (
          <div
            style={{ height: '100%', width: '100%' }}
            className="iframe-container"
          >
            <iframe
              title="iframe"
              allowFullScreen
              src={contents}
              style={{
                backgroundColor: 'white',
                height: '100%',
                width: '100%'
              }}
            ></iframe>
          </div>
        );
      //   /src={page.url}
      default:
        return <div />;
    }
  };

  // const CanGoDown = ()=>{

  //     ////////onsole.log("down",Target,Target<Val.children.length-Limit)
  //     return TargetdotValue.Value<Val.children.length - Limit;
  // }
  // const CanGoUp = ()=>{
  //     ////////onsole.log("UP",Target,Target>0)
  //     return TargetdotValue.Value>0
  // }
  // useEffect(()=>{
  //     setItems(Val.children.map((ele,i)=>{
  //         //////onsole.log("RENDERSUBITEMS",i, Target)
  //         if(i>Target + Limit-1 || i< Target)
  //         return(<></>)
  //         //////onsole.log(pages[ele.referenceId]);
  //         let subtitle = pages[ele.referenceId].subtitle
  //         return(<div key={ele.referenceId} className="SubMenuItemWrapper" style={{display:"grid",gridTemplateRows:"1fr 2px", width:"calc(100% * (563 - (61 + 66)) / 563)", height:"120px", gridTemplateColumns:"1fr 90px"}}>
  //             <div className="SubMenuTextWrapper" style={{ display:"flex", flexDirection:"column", justifyContent:"center", gridColumn:"1/1", marginLeft:"calc(100% * 13 / (563 - (61 + 66))"}}>
  //                 <p className="Header">{ele.title}</p>
  //                 { subtitle && <p className="Subtitle">{subtitle}</p>}
  //             </div>
  //             <div style={{gridColumn:"2/2"}}>
  //                 {/* ICON HERE TO BE DETERMINED */}
  //             </div>
  //             {i !== Target + Limit-1 &&<div style={{gridRow:"2/2", gridColumn:"1/3", backgroundColor:"white"}}></div>}
  //         </div>)
  //     }))
  // },[Target])
  //////onsole.log("SMI",G,Val,pages[Val.referenceId], Mixed);
  const Sref = useRef(Size);
  useEffect(() => {
    Sref.current = Size;
  }, [Size]);
  //const Value = pages[Val.referenceId];
  useEffect(() => {
    window.addEventListener('ScrollStop', ScrollStop);
    return () => {
      window.removeEventListener('ScrollStop', ScrollStop);
    };
  });
  // const [DotCount] = useState(Math.floor(Val.children.length/3)+ Math.min(1,Val.children.length%3));
  function determine(int) {
    if (int < TargetdotValue.Value + Limit && int >= TargetdotValue.Value)
      return true;
    else return false;
    //if()
    // if(Target%3 === 0)
    // return int === Target
    // else{
    // let temp = Target/3
    // let temp2 = Math.floor(temp) + 1;
    // return int * temp2 - Limit >Target
    // return false;
    // return temp2 < Target+ Limit //|| temp2 > Target+Limit
    // }
  }
  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  // const[Idle,SetIdle] = useState(false)
  // const idleref = useRef(Idle)
  const [Idle] = useAsyncReference2(false);
  // const [page,SetPage] = useState(0);
  // const pageref = useRef(page)
  const [item, SetItem] = useState(-1);
  // const itemref = useRef(item)
  // const Setitemref = useRef(SetItem)
  const OnIdle = () => {
    //setTarget(0);
    //console.log("chosenPortfolio?.StorybookAutoScrollTimer?.TimerBool !== false",chosenPortfolio?.StorybookAutoScrollTimer?.TimerBool,chosenPortfolio?.StorybookAutoScrollTimer?.TimerBool !== false)
    if (chosenPortfolio?.StorybookAutoScrollTimer?.TimerBool !== false) {
      TargetdotValue.Value = 0;
      if (!Idle.Value) {
        Idle.Value = true;
        AutoLoop(true, -1);
      }
    }
  };
  const AutoLoop = async (Over, num) => {
    //onsole.log("Start",Idle.Value||Over,Idle.Value,Over)
    if (Idle.Value || Over) {
      //onsole.log('START',itemref.current,item)
      let tempItem = num + 1;
      if (tempItem === Val.children.length) tempItem = 0;
      // //onsole.log("item1",tempItem,Val.children.length)

      //onsole.log('END',itemref.current,item, TargetdotValue.Value)
      // Setitemref.current(tempItem);
      //onsole.log("item",item,itemref.current)
      //   if(tempItem< Target+Limit)
      //   setTarget(0);
      //   if(tempItem >=Target+Limit){
      //     let x = tempItem
      //     while (x+Limit > Val.children.length)
      //     x--;
      //     await sleep(1000);
      //   setTarget(x)
      // }
      // if(Target+Limit>=tempItem)
      //   setTarget(Target+Limit)
      if (!Idle.Value) {
        SetItem(-1);
        //onsole.log("ACTIVE")
        return;
      }
      await sleep(2000);
      if (!Idle.Value) {
        SetItem(-1);
        //onsole.log("ACTIVE")
        return;
      }
      SetItem(Number.MAX_SAFE_INTEGER);
      let BonusTime = false;
      if (tempItem === 0) {
        if (!Idle.Value) {
          SetItem(-1);
          //onsole.log("ACTIVE")
          return;
        }
        await sleep(1500);
        if (!Idle.Value) {
          SetItem(-1);
          //onsole.log("ACTIVE")
          return;
        }
        //setTarget(0)
        TargetdotValue.Value = 0;
        SetItem(tempItem);
        BonusTime = true;
      } else if (tempItem >= TargetdotValue.Value + Limit) {
        let x = tempItem;
        while (x + Limit > Val.children.length) x--;
        if (!Idle.Value) {
          SetItem(-1);
          //onsole.log("ACTIVE")
          return;
        }
        await sleep(1500);
        if (!Idle.Value) {
          SetItem(-1);
          //onsole.log("ACTIVE")
          return;
        }
        //setTarget(x)
        TargetdotValue.Value = x;
        BonusTime = true;
      }
      if (!BonusTime) {
        if (!Idle.Value) {
          SetItem(-1);
          //onsole.log("ACTIVE")
          return;
        }
        await sleep(1500);
        if (!Idle.Value) {
          SetItem(-1);
          //onsole.log("ACTIVE")
          return;
        }
      } else {
        if (!Idle.Value) {
          SetItem(-1);
          //onsole.log("ACTIVE")
          return;
        }
        await sleep(500);
        if (!Idle.Value) {
          SetItem(-1);
          //onsole.log("ACTIVE")
          return;
        }
      }
      // if()
      SetItem(tempItem);
      AutoLoop(false, tempItem);
      return;
    } else {
      // if(!Idle.Value){
      SetItem(-1);
      //onsole.log("ACTIVE")
      return;
      // }
    }
  };
  // async function AutoLoop(Over){
  //   //onsole.log("contents",Array.from(RefBack2.current.children),Array.from(RefBack2.current.children)[1].className)
  //   let contents = Array.from(RefBack2.current.children).filter((ele)=>{return ele.className.includes("SubMenuItemWrapper")})
  //   //onsole.log("Contents",contents)
  //   contents.forEach((ele)=>{ele.id = ""})

  //   if(idleref||Over){
  //     if(pageref.current<0 || itemref.current <0){
  //       let tempPage = 0;
  //       let tempitemref = 0;
  //       SetPage(tempPage);
  //       SetItem(tempitemref);
  //       contents[tempitemref].id = "Active"
  //       await sleep(5000);
  //       AutoLoop(false)

  //     }
  //     else{
  //       let temppageref = 0;
  //       let tempitemref = 0;
  //       if(pageref.current*Limit + itemref.current === Val.children.length - 1){
  //         SetPage(-1);
  //         SetItem(-1);
  //         setTarget(0);
  //         await sleep(5000);
  //         AutoLoop(false)
  //       }
  //       else if(itemref.current === Limit){
  //         temppageref = pageref.current+1;
  //         setTarget(Math.max(0,pageref.current*Limit - 2));
  //         contents[tempitemref].id = "Active";
  //         SetItem(tempitemref);
  //         SetPage(temppageref);
  //         await sleep(5000);
  //         AutoLoop(false)
  //       }
  //       else{
  //         tempitemref = itemref.current+1;
  //         //onsole.log("tempitemref",tempitemref,contents)
  //         contents[tempitemref].id = "Active";
  //         SetItem(tempitemref);
  //         SetPage(temppageref);
  //         await sleep(5000);
  //         AutoLoop(false)
  //       }
  //     }
  //   }
  //   else{
  //     //onsole.log("Active")
  //     SetPage(-1);
  //     SetItem(-1);
  //     return
  //   }
  // }
  ////onsole.log("chosenPortfolio?.StorybookAutoScrollTimer?.Timer",chosenPortfolio?.StorybookAutoScrollTimer?.Timer)
  const Maintimer = useIdleTimer({
    events: [],
    startManually: true,
    timeout:
      1000 *
      (chosenPortfolio?.StorybookAutoScrollTimer?.Timer
        ? chosenPortfolio?.StorybookAutoScrollTimer?.Timer
        : 10),
    onIdle: OnIdle
  }); //events:[],
  useEffect(() => {
    Maintimer.start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const test = Val.children
    .map((ele, i) => {
      if (Val.children.length < 4) return null;
      if (i % 3 === 0 || i === Val.children.length)
        return (
          <div
            style={{
              height: '24px',
              width: '24px',
              marginTop: i / 3 > 0 ? '12px' : '0px',
              borderRadius: '50%',
              backgroundColor: determine(i)
                ? 'white'
                : chosenPortfolio.accent_color
                ? chosenPortfolio.accent_color
                : ''
              //  (ScrollRef.current?.scrollLeft > ScrollRef.current?.firstChild?.clientWidth * i || ((ScrollRef.current?.scrollLeft === 0 && i === 0) || ScrollRef.current?.scrollLeft ===  ScrollRef.current?.firstChild?.clientWidth * i )) &&
              //  ScrollRef.current?.scrollLeft < ScrollRef.current?.firstChild?.clientWidth * (i+1)?
              //  'white':
              //  chosenPortfolio.accent_color?chosenPortfolio.accent_color:""
            }}
            onClick={() => {
              let x = i;
              while (x + Limit > Val.children.length) x--;
              //setTarget(x)
              TargetdotValue.Value = x;
            }}
          ></div>
        );
      return null;
    })
    .filter((ele) => {
      return ele !== null;
    });
  // const ICalc = (N)=>
  // {
  //   return TargetdotValue.Value+Limit > N && N >= TargetdotValue.Value;
  // }
  const ScrollStop = () => {
    //onsole.log('ScrollStop',document.getElementById("SubMenuRef").scrollLeft);
    ////onsole.log(document.getElementById("SubMenuRef").scrollLeft)
    ScrollDetector();
  };
  //onsole.log("Flag",chosenPortfolio.Submenu_center_third_color+"b2")
  const RefBack1 = useRef();
  const RefBack2 = useRef();
  const RefBack3 = useRef();
  const RefBack4 = useRef();
  const RefBack5 = useRef();
  const ScrollDetector = () => {
    let temp = document.getElementById('SubMenuRef');
    let ScrollPos = temp.scrollLeft;
    //onsole.log("ScrollStop2",temp.scrollLeft)
    if (temp.scrollLeft === 0 || temp.scrollLeft % Sref.current.W === 0) {
      let test1 = Math.abs(ScrollPos - (G - 1) * Sref.current.W);
      if (RefBack1.current) {
        RefBack1.current.style.transform = `translate(-${
          ((55 * (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
            Sref.current.W /
            100) *
          Size.W
        }px,0%)`.replace('--', '-');
        if (Size.W > 1367) {
          RefBack1.current.style.width = `calc((100% * 1159/1920) + ${
            ((55 *
              (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
              Sref.current.W /
              100) *
            Size.W
          }px)`;
        } else {
          RefBack1.current.style.width = `calc((100% * (1024 - 374)/1024) + ${
            ((55 *
              (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
              Sref.current.W /
              100) *
            Size.W
          }px)`;
        }
      }
      if (RefBack5.current) {
        //RefBack5.current.style.transform = `translate(-${(55 * ((Sref.current.W / 2) - Math.abs(test1 - Sref.current.W / 2))/Sref.current.W)/100*Size.W}px,0%)`.replace("--","-");
        if (Size.W > 1367) {
          RefBack5.current.style.width = `calc(100% + ${
            ((55 *
              (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
              Sref.current.W /
              100) *
            Size.W
          }px)`;
        } else {
          RefBack5.current.style.width = `calc(100% + ${
            ((55 *
              (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
              Sref.current.W /
              100) *
            Size.W
          }px)`;
        }
      }
      if (RefBack2.current) {
        RefBack2.current.style.transform = `translate(${
          (55 * (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
          Sref.current.W
        }%,0%)`.replace('--', '');
        RefBack2.current.style.opacity = `${
          1 -
          (10 * (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
            Sref.current.W
        }`.replace('--', '');
      }
      if (RefBack4.current) {
        //RefBack4.current.style.transform = RefBack1.current.style.transform
      }
      if (RefBack3.current) {
        RefBack3.current.style.opacity = `${Math.min(
          0.3,
          1 -
            (10.5 *
              (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
              Sref.current.W
        )}`.replace('--', '');
        RefBack3.current.style.transform = `translate(${
          (110 * (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
          Sref.current.W
        }%,0%)`.replace('--', '');
      }
    }
    // ////onsole.log("ScrollTest",ScrollPos - G-1*Size.W,ScrollPos,G-1*Size.W)
    let test1 = Math.abs(ScrollPos - (G - 1) * Sref.current.W);
    if (test1 > Sref.current.W || test1 === 0 || test1 === Sref.current.W) {
      if (RefBack1.current) {
        RefBack1.current.style.transform = null;
        RefBack1.current.style.width = null;
        //onsole.log("RefBack1 Zeroed")
      }
      if (RefBack2.current) RefBack2.current.style.transform = null;
      //onsole.log("RefBack2 Zeroed")
      if (RefBack3.current) {
        RefBack3.current.style.opacity = null;
        RefBack3.current.style.transform = null;
      }
      if (RefBack5.current) RefBack5.current.style.width = null;
      return;
    } else if (ScrollPos - (G - 1) * Sref.current.W > 0) {
      //Ending from Right //&& ScrollPos - (G-1)*Sref.current.W > 0 //test1>Sref.current.W / 2
      //////onsole.log("SCROLLTEST",`1 - ${1 * ((Sref.current.W / 2) - Math.abs(test1 - Sref.current.W / 2))/Sref.current.W}`)
      if (RefBack1.current) {
        RefBack1.current.style.transform = `translate(-${
          ((55 * (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
            Sref.current.W /
            100) *
          Size.W
        }px,0%)`.replace('--', '-');
        if (Size.W > 1367) {
          RefBack1.current.style.width = `calc((100% * 1159/1920) + ${
            ((55 *
              (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
              Sref.current.W /
              100) *
            Size.W
          }px)`;
        } else {
          RefBack1.current.style.width = `calc((100% * (1024 - 374)/1024) + ${
            ((55 *
              (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
              Sref.current.W /
              100) *
            Size.W
          }px)`;
        }
      }
      if (RefBack5.current) {
        //RefBack5.current.style.transform = `translate(-${(55 * ((Sref.current.W / 2) - Math.abs(test1 - Sref.current.W / 2))/Sref.current.W)/100*Size.W}px,0%)`.replace("--","-");
        if (Size.W > 1367) {
          RefBack5.current.style.width = `calc(100% + ${
            ((55 *
              (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
              Sref.current.W /
              100) *
            Size.W
          }px)`;
        } else {
          RefBack5.current.style.width = `calc(100% + ${
            ((55 *
              (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
              Sref.current.W /
              100) *
            Size.W
          }px)`;
        }
      }
      if (RefBack2.current) {
        RefBack2.current.style.transform = `translate(${
          (55 * (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
          Sref.current.W
        }%,0%)`.replace('--', '');
        RefBack2.current.style.opacity = `${
          1 -
          (10 * (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
            Sref.current.W
        }`.replace('--', '');
      }
      if (RefBack4.current) {
        //RefBack4.current.style.transform = RefBack1.current.style.transform
      }
      if (RefBack3.current) {
        RefBack3.current.style.opacity = `${Math.min(
          0.3,
          1 -
            (10.5 *
              (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
              Sref.current.W
        )}`.replace('--', '');
        RefBack3.current.style.transform = `translate(${
          (110 * (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
          Sref.current.W
        }%,0%)`.replace('--', '');
      }
      //   if(RefBack1.current)
      //   RefBack1.current.style.transform = `translate(${-Math.pow((ScrollPos + ((G-1)*Sref.current.W - Sref.current.W/2)/100),2)+50}%,0%)`.replace("--","");
      //     if(RefBack2.current)
      // RefBack2.current.style.transform = `translate(${-Math.pow((ScrollPos + ((G-1)*Sref.current.W - Sref.current.W/2)/100),2)+50}%,0%)`.replace("--","");
      //     if(RefBack1.current)
      //   RefBack1.current.style.transform = `translate(${-(ScrollPos + ((G-1)*Sref.current.W - Sref.current.W/2))^2+50}%,0%)`.replace("--","");
      //     if(RefBack2.current)
      // RefBack2.current.style.transform = `translate(${-(ScrollPos + ((G-1)*Sref.current.W - Sref.current.W/2))^2+50}%,0%)`.replace("--","");
    } else {
      //Starting from Right
      if (RefBack1.current) {
        RefBack1.current.style.transform = `translate(-${
          ((55 * (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
            Sref.current.W /
            100) *
          Size.W
        }px,0%)`.replace('--', '-');
        if (Size.W > 1367) {
          RefBack1.current.style.width = `calc((100% * 1159/1920) + ${
            ((55 *
              (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
              Sref.current.W /
              100) *
            Size.W
          }px)`;
        } else {
          RefBack1.current.style.width = `calc((100% * (1024 - 374)/1024) + ${
            ((55 *
              (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
              Sref.current.W /
              100) *
            Size.W
          }px)`;
        }
      }
      if (RefBack5.current) {
        //RefBack5.current.style.transform = `translate(-${(55 * ((Sref.current.W / 2) - Math.abs(test1 - Sref.current.W / 2))/Sref.current.W)/100*Size.W}px,0%)`.replace("--","-");
        if (Size.W > 1367) {
          RefBack5.current.style.width = `calc(100% + ${
            ((55 *
              (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
              Sref.current.W /
              100) *
            Size.W
          }px)`;
        } else {
          RefBack5.current.style.width = `calc(100% + ${
            ((55 *
              (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
              Sref.current.W /
              100) *
            Size.W
          }px)`;
        }
      }
      if (RefBack2.current) {
        RefBack2.current.style.transform = `translate(-${
          (55 * (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
          Sref.current.W
        }%,0%)`.replace('--', '-');
        RefBack2.current.style.opacity = `${
          1 -
          (10 * (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
            Sref.current.W
        }`.replace('--', '');
      }
      if (RefBack4.current) {
        //RefBack4.current.style.transform = RefBack1.current.style.transform
      }
      if (RefBack3.current) {
        RefBack3.current.style.opacity = `${Math.min(
          0.3,
          1 -
            (10.5 *
              (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
              Sref.current.W
        )}`.replace('--', '');
        RefBack3.current.style.transform = `translate(-${
          (110 * (Sref.current.W / 2 - Math.abs(test1 - Sref.current.W / 2))) /
          Sref.current.W
        }%,0%)`.replace('--', '-');
      }
    }
    //OG
    // else{
    //   // ////onsole.log("ScrollTest",`translate(${ScrollPos - (G-1)*Sref.current.W>0?"-":"-"}${100 * (ScrollPos - (G-1)*Sref.current.W)/Sref.current.W}%,0%)`)
    //   if(RefBack1.current)
    //   RefBack1.current.style.transform = `translate(${ScrollPos - (G-1)*Sref.current.W>0?"-":"-"}${100 * (ScrollPos - (G-1)*Sref.current.W)/Sref.current.W}%,0%)`.replace("--","");
    //   if(RefBack2.current)
    // RefBack2.current.style.transform = `translate(${ScrollPos - (G-1)*Sref.current.W>0?"-":"-"}${100 * (ScrollPos - (G-1)*Sref.current.W)/Sref.current.W}%,0%)`.replace("--","");
    // }
  };
  let SMCTC = pages[Val.referenceId]?.submenu_center_third_color
    ? pages[Val.referenceId]?.submenu_center_third_color + 'b2'
    : null;
  //onsole.log("Flag4",ColorUtil(SMCTC,chosenPortfolio.Submenu_center_third_color+"b2"))
  useEffect(() => {
    let temp = document.getElementById('SubMenuRef');
    temp.addEventListener('scroll', ScrollDetector);
    return () => {
      temp.removeEventListener('scroll', ScrollDetector);
    };
  });
  //onsole.log("pages[chosenContentPageId]",pages[Val.referenceId])
  const [DesHeadSize] = useState(
    pages[Val.referenceId]?.StoryBookObjects?.descriptionHeaderFontsize
      ? pages[Val.referenceId]?.StoryBookObjects?.descriptionHeaderFontsize +
          'px'
      : '56px'
  );
  const [DesHeadSizeT] = useState(
    pages[Val.referenceId]?.StoryBookObjects?.descriptionHeaderFontsize
      ? pages[Val.referenceId]?.StoryBookObjects?.descriptionHeaderFontsize *
          (36 / 56) +
          'px'
      : '36px'
  );
  const [DesSize] = useState(
    pages[Val.referenceId]?.StoryBookObjects?.descriptionFontsize
      ? pages[Val.referenceId]?.StoryBookObjects?.descriptionFontsize + 'px'
      : '20px'
  );
  const [DesSizeT] = useState(
    pages[Val.referenceId]?.StoryBookObjects?.descriptionFontsize
      ? pages[Val.referenceId]?.StoryBookObjects?.descriptionFontsize *
          (20 / 20) +
          'px'
      : '20px'
  );
  return (
    <>
      {/* <div className="ScrollIndicator" style={{maskImage:`url(${Scrollindicator})`,WebkitMaskImage:`url(${Scrollindicator})`, maskSize:"100% 100%", WebkitMaskSize:'100% 100%', }}></div> */}
      <div
        ref={RefBack4}
        className="SubmenuItemBackground"
        onClick={() => {
          Maintimer.reset();
          Idle.Value = false;
        }}
        onPointerEnter={() => {
          Maintimer.reset();
          Idle.Value = false;
        }}
        onPointerDown={() => {
          Maintimer.reset();
          Idle.Value = false;
        }}
      >
        {/* CONTENT HERE TO BE DETERMINED */}
        {pages[Val.referenceId]._type !== 'gallery' &&
        pages[Val.referenceId]._type !== 'STgallery' ? (
          pages[Val.referenceId]._type !== 'website' ? (
            renderSingleImageExport(
              pages[Val.referenceId]._type,
              0,
              contentDataExport(Val.referenceId)
            )
          ) : (
            renderSingleImageExport(
              pages[Val.referenceId]._type,
              0,
              pages[Val.referenceId].url
            )
          )
        ) : // //////onsole.log("WEBSITE")
        //renderSingleImageExport(pages[Val.referenceId]._type,0,contentDataExport(Val.referenceId))
        pages[Val.referenceId].content[0] ? (
          renderSingleImageExport(
            pages[Val.referenceId].content[0]._type,
            0,
            contentDataExport(Val.referenceId)
          )
        ) : (
          <div id="Null"></div>
        )}
        {/* {//////onsole.log(pages[Val.referenceId]._type)} */}
        <div
          style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            background: OVTINT
          }}
        ></div>
      </div>
      <div
        ref={RefBack1}
        onClick={() => {
          Maintimer.reset();
          Idle.Value = false;
        }}
        onPointerEnter={() => {
          Maintimer.reset();
          Idle.Value = false;
        }}
        onPointerDown={() => {
          Maintimer.reset();
          Idle.Value = false;
        }}
        className="SubmenuItemMenuBackground"
        style={{ background: SMColor }}
      >
        <div
          className="SubmenuItemMenuBackground1"
          style={{ position: 'absolute', top: '0px', left: '0px' }}
        >
          <div
            ref={RefBack5}
            id="SubpageBlockContentGrowth"
            className="SubpageBlockContent"
            onWheel={(eve) => {
              eve.preventDefault();
              eve.stopPropagation();
            }}
            style={{
              backgroundColor: ColorUtil(
                pages[Val.referenceId]?.submenu_center_third_color,
                chosenPortfolio.Submenu_center_third_color
              )
            }}
          >
            <div
              className="ColorBar"
              style={{
                backgroundColor: chosenPortfolio.accent_color
                  ? chosenPortfolio.accent_color
                  : '',
                width: '100%'
              }}
            />
          </div>
          <p
            ref={RefBack3}
            className={`OverGhost ${
              pages[Val.referenceId]?.StoryBookObjects?.oversizedTextSize
            }`}
            style={{
              color: pages[Val.referenceId]?.StoryBookObjects
                ?.oversizedTextColor
                ? pages[Val.referenceId]?.StoryBookObjects?.oversizedTextColor
                : undefined,
              fontFamily: FontUtil(
                pages[Val.referenceId]?.StoryBookObjects?.overghostFont,
                chosenPortfolio.ContentText
              )
            }}
          >
            {pages[Val.referenceId]?.StoryBookObjects?.oversizedText}
          </p>
          {/* display:TMGD?"none":"flex", */}
          <p
            className="SubtitleHeader"
            style={{
              color: pages[Val.referenceId].text_color
                ? pages[Val.referenceId].text_color
                : undefined,
              fontFamily: FontUtil(
                pages[Val.referenceId]?.SubtitleText,
                chosenPortfolio.ContentText
              )
            }}
          >
            {pages[Val.referenceId]?.subtitle}
          </p>
          <div
            className="SubpageBlockContent"
            onWheel={(eve) => {
              eve.preventDefault();
              eve.stopPropagation();
            }}
            style={{
              overscrollBehaviorX: TMGD ? null : 'auto',
              backgroundColor: ColorUtil(
                pages[Val.referenceId]?.submenu_center_third_color,
                chosenPortfolio.Submenu_center_third_color
              )
            }}
          >
            <div
              className="ColorBar"
              style={{
                backgroundColor: chosenPortfolio.accent_color
                  ? chosenPortfolio.accent_color
                  : ''
              }}
            />
            <div
              style={{
                touchAction: 'pan-y',
                overscrollBehaviorX: TMGD ? null : 'auto',
                height: '100%',
                position: 'relative',
                overflowY: 'scroll',
                overflowX: 'hidden'
              }}
            >
              <div
                className="SubpageBlockContentWrapper header"
                style={{
                  overscrollBehaviorX: TMGD ? null : 'auto',
                  fontSize:
                    window.innerWidth < 1367 ? DesHeadSizeT : DesHeadSize,
                  fontFamily: FontUtil(
                    pages[Val.referenceId]?.StoryBookObjects
                      ?.descriptionHeaderFont,
                    chosenPortfolio.ContentText
                  )
                }}
              >
                {/* width:"calc(100% * 773/1159)", calc(100% * ((460 * 2) + 37) / 1159)" */}
                {pages[Val.referenceId]?.StoryBookObjects
                  ?.descriptionHeader && (
                  <BlockContent
                    blocks={
                      pages[Val.referenceId]?.StoryBookObjects
                        ?.descriptionHeader
                    }
                    serializers={serializers}
                  ></BlockContent>
                )}
              </div>
              <div
                className="SubpageBlockContentWrapper description"
                style={{
                  overscrollBehaviorX: TMGD ? null : 'auto',
                  fontSize: window.innerWidth < 1367 ? DesSizeT : DesSize,
                  fontFamily: FontUtil(
                    pages[Val.referenceId]?.StoryBookObjects?.descriptionFont,
                    chosenPortfolio.ContentText
                  )
                }}
              >
                {pages[Val.referenceId]?.StoryBookObjects?.description && (
                  <BlockContent
                    blocks={
                      pages[Val.referenceId]?.StoryBookObjects?.description
                    }
                    serializers={serializers}
                  ></BlockContent>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        onClick={() => {
          Maintimer.reset();
          Idle.Value = false;
        }}
        onPointerEnter={() => {
          Maintimer.reset();
          Idle.Value = false;
        }}
        onPointerDown={() => {
          Maintimer.reset();
          Idle.Value = false;
        }}
        ref={RefBack2}
        className="SubpageMenuContent"
        style={{
          display: TMGD ? 'none' : 'flex',
          backgroundColor: ColorUtil(
            SMCTC,
            chosenPortfolio.Submenu_center_third_color + 'b2'
          )
        }}
      >
        <div
          className="ColorBar"
          style={{
            backgroundColor: chosenPortfolio.accent_color
              ? chosenPortfolio.accent_color
              : ''
          }}
        />
        {/* {(<>
      {CanGoUp()&&
      <ArrowIcon className={'UpArrow'} Fill={chosenPortfolio.bulk_icon_override?chosenPortfolio.bulk_icon_override:'black'} Rotation={90} onClick={()=>{if(CanGoUp())setTarget(Target-1)}}></ArrowIcon>
    //   <div onClick={()=>{if(CanGoUp())setTarget(Target-1)}} className="UpArrow" style={{
    //     maskImage:`url(${Circle})`,
    //       WebkitMaskImage:`url(${Circle})`,
    //   }}>
    //     <div className="arrow" style={{maskImage:`url(${Arrow})`,
    //         WebkitMaskImage:`url(${Arrow})`,}}></div>
    // </div>
    }
    {CanGoDown()&&
    <ArrowIcon className={'DownArrow'} Fill={chosenPortfolio.bulk_icon_override?chosenPortfolio.bulk_icon_override:'black'} Rotation={270} onClick={()=>{
      //onsole.log("Down",Target);
      if(CanGoDown())setTarget(Target+1)}}></ArrowIcon>
    // <div onClick={()=>{
    //   //onsole.log("Down",Target);
    //   if(CanGoDown())setTarget(Target+1)}} className="DownArrow" style={{maskImage:`url(${Circle})`,
    //       WebkitMaskImage:`url(${Circle})`,}}>
    //     <div className="arrow" style={{maskImage:`url(${Arrow})`,
    //         WebkitMaskImage:`url(${Arrow})`,}}></div>
    // </div>
    }
    </>)} */}

        {Val.children.map((ele, i) => {
          ////////onsole.log("RENDERSUBITEMS",i, Target)
          if (i > TargetdotValue.Value + Limit - 1 || i < TargetdotValue.Value)
            return null;
          ////////onsole.log(pages[ele.referenceId],ele);
          let subtitle = pages[ele.referenceId].subtitle;
          return (
            <>
              <div
                key={ele.referenceId}
                className="SubMenuItemWrapper"
                id={item === i ? 'active' : null}
                onClick={() => {
                  SetTopicArray(ele.title, level, ele.referenceId);
                  onButtonClick(
                    ele.referenceId,
                    level,
                    ele.children.length > 0,
                    ele.children.length > 0,
                    i
                  );
                }}
                style={{}}
              >
                <div className="SubMenuTextWrapper">
                  <p
                    className="Header"
                    style={{
                      fontFamily: FontUtil(
                        pages[ele.referenceId].TitleText,
                        chosenPortfolio.ContentText
                      ),
                      color: ColorUtil(
                        pages[ele.referenceId].text_color,
                        chosenPortfolio.text_color
                      )
                    }}
                  >
                    {ele.title}
                  </p>
                  {subtitle && (
                    <p
                      style={{
                        fontFamily: FontUtil(
                          pages[ele.referenceId].SubtitleText,
                          chosenPortfolio.ContentText
                        ),
                        color: ColorUtil(
                          pages[ele.referenceId].text_color,
                          chosenPortfolio.text_color
                        )
                      }}
                      className="Subtitle"
                    >
                      {subtitle}
                    </p>
                  )}
                </div>
                <div
                  className="IconHolder"
                  style={{
                    backgroundColor: ColorUtil(
                      pages[ele.referenceId].text_color,
                      chosenPortfolio.text_color,
                      'black'
                    ),
                    maskSize: '125%',
                    webkitMaskSize: '125%',
                    maskImage: `url('${
                      pages[ele.referenceId]._type === 'gallery' ||
                      pages[ele.referenceId]._type === 'STgallery'
                        ? pages[ele.referenceId].content.every((VAL) => {
                            return VAL._type === 'galleryImage';
                          })
                          ? Photo
                          : pages[ele.referenceId].content.every((Val) => {
                              return Val._type === 'galleryVideo';
                            })
                          ? Video
                          : pages[ele.referenceId].content.every((Val) => {
                              return Val._type === 'galleryPdf';
                            }) ||
                            pages[ele.referenceId].content.every((Val) => {
                              return Val._type === 'galleryFullWidthPdf';
                            })
                          ? Page
                          : pages[ele.referenceId].content.every((Val) => {
                              return Val._type === 'website';
                            })
                          ? Globe
                          : Mixed
                        : pages[ele.referenceId]._type === 'website' ||
                          pages[ele.referenceId]._type === 'htmlContent'
                        ? Globe
                        : TPBG
                    }')`,
                    // eslint-disable-next-line
                    maskImage: `url('${
                      pages[ele.referenceId]._type === 'gallery' ||
                      pages[ele.referenceId]._type === 'STgallery'
                        ? pages[ele.referenceId].content.every((VAL) => {
                            return VAL._type === 'galleryImage';
                          })
                          ? Photo
                          : pages[ele.referenceId].content.every((Val) => {
                              return Val._type === 'galleryVideo';
                            })
                          ? Video
                          : pages[ele.referenceId].content.every((Val) => {
                              return Val._type === 'galleryPdf';
                            }) ||
                            pages[ele.referenceId].content.every((Val) => {
                              return Val._type === 'galleryFullWidthPdf';
                            })
                          ? Page
                          : pages[ele.referenceId].content.every((Val) => {
                              return Val._type === 'website';
                            })
                          ? Globe
                          : Mixed
                        : pages[ele.referenceId]._type === 'website' ||
                          pages[ele.referenceId]._type === 'htmlContent'
                        ? Globe
                        : TPBG
                    }')`,
                    maskPosition: '50% 50%',
                    maskRepeat: 'no-repeat',
                    webkitMaskImage: `url('${
                      pages[ele.referenceId]._type === 'gallery' ||
                      pages[ele.referenceId]._type === 'STgallery'
                        ? pages[ele.referenceId].content.every((VAL) => {
                            return VAL._type === 'galleryImage';
                          })
                          ? Photo
                          : pages[ele.referenceId].content.every((Val) => {
                              return Val._type === 'galleryVideo';
                            })
                          ? Video
                          : pages[ele.referenceId].content.every((Val) => {
                              return Val._type === 'galleryPdf';
                            }) ||
                            pages[ele.referenceId].content.every((Val) => {
                              return Val._type === 'galleryFullWidthPdf';
                            })
                          ? Page
                          : pages[ele.referenceId].content.every((Val) => {
                              return Val._type === 'website';
                            })
                          ? Globe
                          : Mixed
                        : pages[ele.referenceId]._type === 'website' ||
                          pages[ele.referenceId]._type === 'htmlContent'
                        ? Globe
                        : TPBG
                    }')`,
                    // eslint-disable-next-line
                    webkitMaskImage: `url('${
                      pages[ele.referenceId]._type === 'gallery' ||
                      pages[ele.referenceId]._type === 'STgallery'
                        ? pages[ele.referenceId].content.every((VAL) => {
                            return VAL._type === 'galleryImage';
                          })
                          ? Photo
                          : pages[ele.referenceId].content.every((Val) => {
                              return Val._type === 'galleryVideo';
                            })
                          ? Video
                          : pages[ele.referenceId].content.every((Val) => {
                              return Val._type === 'galleryPdf';
                            }) ||
                            pages[ele.referenceId].content.every((Val) => {
                              return Val._type === 'galleryFullWidthPdf';
                            })
                          ? Page
                          : pages[ele.referenceId].content.every((Val) => {
                              return Val._type === 'website';
                            })
                          ? Globe
                          : Mixed
                        : pages[ele.referenceId]._type === 'website' ||
                          pages[ele.referenceId]._type === 'htmlContent'
                        ? Globe
                        : TPBG
                    }')`,
                    webkitMaskPosition: '50% 50%',
                    webkitMaskRepeat: 'no-repeat'
                  }}
                >
                  {/* {pages[ele.referenceId]._type === "gallery" || pages[ele.referenceId]._type === "STgallery" ?(<img alt="Content" src={pages[ele.referenceId].content.every((VAL)=>{return VAL._type === "galleryImage"}) ? Photo : pages[ele.referenceId].content.every((Val)=>{return Val._type === "galleryVideo"})?Video:pages[ele.referenceId].content.every((Val)=>{return Val._type === "galleryPdf"}) || pages[ele.referenceId].content.every((Val)=>{return Val._type ==="galleryFullWidthPdf"})?Page:pages[ele.referenceId].content.every((Val)=>{return Val._type ==="website"})?Globe:Mixed}></img>):pages[ele.referenceId]._type === "website" || pages[ele.referenceId]._type === "htmlContent"?(<img alt="Icon" src={Globe}></img>):null} */}
                  {
                    //////onsole.log(pages[ele.referenceId]) /* ICON HERE TO BE DETERMINED galleryVideo */
                  }
                </div>
              </div>
              {i !== TargetdotValue.Value + Limit && (
                <div
                  className="SubMenuItemdivisor"
                  style={{
                    gridRow: '2/2',
                    gridColumn: '1/3',
                    backgroundColor: 'white'
                  }}
                ></div>
              )}
            </>
          );
        })}
        <div
          style={{
            position: 'absolute',
            left: '0px',
            top: '0px',
            height: '100%',
            width: '48px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {test}
          {/* {Val.children.map((ele,i)=>{   return(


   <div style={{height:"12px",width:"12px", marginTop:i>0?"6px":"0px", borderRadius:"50%",
   backgroundColor:(ICalc(i))?"white":chosenPortfolio.accent_color?chosenPortfolio.accent_color:""
  //  (ScrollRef.current?.scrollLeft > ScrollRef.current?.firstChild?.clientWidth * i || ((ScrollRef.current?.scrollLeft === 0 && i === 0) || ScrollRef.current?.scrollLeft ===  ScrollRef.current?.firstChild?.clientWidth * i )) && 
  //  ScrollRef.current?.scrollLeft < ScrollRef.current?.firstChild?.clientWidth * (i+1)?
  //  'white':
  //  chosenPortfolio.accent_color?chosenPortfolio.accent_color:""
   }}
   onClick={()=>{
     if(ICalc(i))
     return;
     if(i>Target)
     setTarget(Math.min(Math.max(0,i-Limit+1), i,Val.children.length - Limit))
     else
     setTarget(Math.min(i,Val.children.length - Limit))
    }}
   >

   </div>)})} */}
        </div>
      </div>
    </>
  );
};
export default SubMenuItem;

// {Val.children.map((ele,i)=>{
//     //////onsole.log("RENDERSUBITEMS",i, Target)
//     if(i>Target + Limit-1 || i< Target)
//     return(<></>)
//     //////onsole.log(pages[ele.referenceId]);
//     let subtitle = pages[ele.referenceId].subtitle
//     return(<div key={ele.referenceId} className="SubMenuItemWrapper" style={{display:"grid",gridTemplateRows:"1fr 2px", width:"calc(100% * (563 - (61 + 66)) / 563)", height:"120px", gridTemplateColumns:"1fr 90px"}}>
//         <div className="SubMenuTextWrapper" style={{ display:"flex", flexDirection:"column", justifyContent:"center", gridColumn:"1/1", marginLeft:"calc(100% * 13 / (563 - (61 + 66))"}}>
//             <p className="Header">{ele.title}</p>
//             { subtitle && <p className="Subtitle">{subtitle}</p>}
//         </div>
//         <div style={{gridColumn:"2/2"}}>
//             {/* ICON HERE TO BE DETERMINED */}
//         </div>
//         {i !== Target + Limit-1 &&<div style={{gridRow:"2/2", gridColumn:"1/3", backgroundColor:"white"}}></div>}
//     </div>)
// })}
