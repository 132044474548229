/* eslint-disable no-lone-blocks */
import { createBarData } from '../../../../../../../utils/chartHelper'; //createBriefcaseData
import { convertSecondsToText } from '../../../../../../../utils/timeHelper';
//import Chart from '../../../../_Dashboard/Chart'
import * as V from 'victory';
//import * as Scale from '../../../../../utils/Scalars'
import React, { useEffect, useState } from 'react';
//import styled from 'styled-components'
// const StyledChartContainer = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: flex-start;
//   align-items: flex-start;
// `;
// const chartTypes = ['bar', 'pie'];
const Graph = ({ style, companyConfig, sessions, SingleSession, Size }) => {
  const [uniqueLabels, SetUniqueLabels] = useState([]);
  const [totalTimeSpentPerPageData, SettotalTimeSpentPerPageData] = useState(
    []
  );

  useEffect(() => {
    {
      let Aggregate;
      //If Single session is available, use the narrowest view
      if (SingleSession) {
        Aggregate = [createBarData(SingleSession.page_visited)];
      }
      //If Viewer selected use the narrow view
      // else if(FVC.length >0){
      // Aggregate = FVC.map((ele)=>{
      //     if(!ele.page_visited)
      //     return null;
      //     return createBarData(ele.page_visited)
      // })
      // }
      //Else use the broad sessions that are eligable.
      else if (sessions?.length > 0) {
        Aggregate = sessions.map((ele) => {
          if (!ele.page_visited) return null;
          // aggregate[0] = [...aggregate[0],...ele.page_visited]
          return createBarData(ele.page_visited);
        });
      } else {
        //spare the calculations

        SetUniqueLabels([]);
        SettotalTimeSpentPerPageData([]);
        return;
      }
      ////////onsole.log("AGGREGATE",Aggregate);
      let UL = [];
      let TTSD = [];
      //let ToolTipCollection = {};
      //Collate all individual sessions to create the summations of times
      //////onsole.log("!!SORT BEFORE MAP!!",Aggregate)
      Aggregate.map((ele) => {
        if (ele.uniquePageLabels) {
          ele.uniquePageLabels.map((ele) => {
            //If UL does not have a label, add it
            if (
              !UL.some((Label) => {
                return Label === ele;
              })
            )
              UL.push(ele);
            return ele;
          });
        }
        if (ele.totalTimeSpendDatas) {
          ele.totalTimeSpendDatas.map((TTD) => {
            //////onsole.log("TTD", TTD);
            if (
              TTSD.some((Data) => {
                return Data.name === TTD.name;
              })
            ) {
              //If TTSD has a data point with the same name, add the element's time to the data
              let point = TTSD.find((Data) => {
                return Data.name === TTD.name;
              });
              point.y += TTD.y;
            } else {
              //Otherwise create a new data point.
              TTSD.push({ x: TTD.name, y: TTD.y, name: TTD.name });
            }
            return ele;
          });
        }
        return ele;
      });
      //onsole.log(UL)
      //onsole.log(TTSD)
      TTSD.sort((a, b) => {
        if (a.y > b.y) return -1;
        else return 1;
      });
      //onsole.log(TTSD)
      let testUL = TTSD.map((ele) => {
        return ele.x;
      });
      // //////onsole.log("!!SORT AFTER MAP!!",UL)
      // //////onsole.log("!!SORT AFTER MAP!!",TTSD)
      // TTSD.sort((a,b)=>{return b.y - a.y});
      // TTSD = TTSD.map((ele,i)=>{ele.x = i;
      //     return ele;
      // })
      // //////onsole.log("!!SORT AFTER MAP!!",TTSD)
      // UL = [];
      // TTSD.forEach((ele)=>{UL.push(ele.name)});
      // //////onsole.log("!!SORT AFTER MAP!!",UL)
      // //////onsole.log("!!SORT AFTER MAP!!",TTSD)
      ////////onsole.log("UL",UL)
      ////////onsole.log("TTSD",TTSD)
      SetUniqueLabels(testUL);
      SettotalTimeSpentPerPageData(TTSD);
    }
  }, [sessions, SingleSession]); //FVC

  //////onsole.log("UNIQUE LABELS IN GP CHART", uniqueLabels);
  //////onsole.log("ALL POINTS IN GP CHART", totalTimeSpentPerPageData);

  //width:"100%", height:"100%", overflowX:"auto"
  return (
    //Create view port through which the chart can be observed, but not contained
    <div
      style={{
        ...style,
        width: '100%',
        height: '100%',
        overflowY: 'hidden',
        overflowX: 'hidden'
      }}
    >
      {/*Set up container for chart with ample space for labels  `${Scale.Width(Size,uniqueLabels.length*175)}px`*/}
      <div style={{ width: '100%', height: '100%', cursor: 'move' }}>
        <V.VictoryChart
          standalone={true}
          theme={V.VictoryTheme.material}
          domainPadding={{ x: 0, y: 20 }}
          width={500}
          height={180}
          animate={{ duration: 100, easing: 'circle', delay: 0 }}
          containerComponent={
            <V.VictoryZoomContainer
              zoomDomain={{ x: [0, 5] }}
              zoomDimension="x"
            />
          }
        >
          <V.VictoryAxis
            tickFormat={uniqueLabels}
            style={{
              ticks: { stroke: 'grey', size: 3 },
              tickLabels: {
                fontSize: 7,
                padding: 12,
                fill: '3b3b3b',
                angle: 45
              }
            }}
          />
          <V.VictoryAxis
            dependentAxis
            tickFormat={(x) => `${x}s`}
            tickCount={5}
            style={{
              ticks: { stroke: 'grey', size: 3 },
              tickLabels: { fontSize: 10, padding: 10, fill: '3b3b3b' }
            }}
          />
          <V.VictoryBar
            labels={({ datum }) => convertSecondsToText(datum.y, false)}
            labelComponent={
              <V.VictoryTooltip
                cornerRadius={10}
                centerOffset={{ y: 25 }}
                pointerWidth={0}
                pointerHeight={0}
                flyoutPadding={8}
                flyoutStyle={{
                  stroke: '#3269a8',
                  strokeWidth: 1,
                  fill: '#3b3b3b'
                }}
                style={{ fontSize: '10px', fontWeight: '600', fill: 'white' }}
              />
            }
            style={{ data: { fill: '#3269a8' } }}
            alignment="middle"
            data={totalTimeSpentPerPageData}
          />
        </V.VictoryChart>
      </div>
    </div>
  );
};
export default Graph;
