import { connect } from 'react-redux';
import AggregatedDashboardPage from './AggregatedDashboardPage';

const mapStateToProps = (state) => ({
  userInfo: state.firestore.ordered.users,
  images: state.sanityContentReducer.images,
  portfolios: state.sanityContentReducer.portfolios,
  companyConfig: state.sanityContentReducer.companyConfig,
  sessionsSanity: state.sanityContentReducer.sessionLogs,
  members: state.sanityContentReducer.members,
  adminReducer: state.adminReducer
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

const AggregatedDashboardPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AggregatedDashboardPage);

export default AggregatedDashboardPageContainer;
