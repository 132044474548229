import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LoginForm from './LoginForm';
import ForgotPasswordForm from '../ForgotPasswordForm';
import Logo from '../../../assets/images/newLogo.png';

const StyledLoginBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 900px;
  width: 1421px;
  height: 725px;
  border-radius: 50px;
  background-color: transparent;
  flex-direction: row;

  @media (max-width: 1367px) {
    min-width: 0;
  }

  @media (max-width: 450px) and (orientation: portrait) {
    flex-direction: column;
  }
`;

const StyledLoginLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 50%;
  padding: 25px;

  img {
    width: calc(100% - 48px);
    height: auto;
  }
`;

const StyledLoginFormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 50%;
`;

const LoginBox = ({
  updateSessionKey,
  setLoginInputSelected,
  setLoginInputSelectedCount,
  loginInputSelected,
  loginInputSelectedCount,
  Size
}) => {
  const [forgotPassMode, setForgotPassMode] = useState(false);
  let back = () => {
    setForgotPassMode(false);
  };

  //////onsole.log("LOGIN INPUT SELECTED COUNT IN BOX", loginInputSelectedCount);
  //////onsole.log("LOGIN INPUT SELECTED?", loginInputSelected);

  return (
    <StyledLoginBox
      style={
        loginInputSelected && loginInputSelectedCount >= 1 && Size.W > 451
          ? { alignItems: 'flex-start' }
          : loginInputSelected
          ? { marginBottom: '200px' }
          : { alignItems: 'center', marginBottom: '0px' }
      }
    >
      <StyledLoginLogo>
        <img src={Logo} alt="logo" />
      </StyledLoginLogo>
      <StyledLoginFormContainer>
        {forgotPassMode ? (
          <ForgotPasswordForm Back={back} />
        ) : (
          <LoginForm
            setForgotPassMode={setForgotPassMode}
            updateSessionKey={updateSessionKey}
            setLoginInputSelected={setLoginInputSelected}
            setLoginInputSelectedCount={setLoginInputSelectedCount}
            loginInputSelected={loginInputSelected}
            loginInputSelectedCount={loginInputSelectedCount}
            Size={Size}
          />
        )}
      </StyledLoginFormContainer>
    </StyledLoginBox>
  );
};

LoginBox.propTypes = {
  content: PropTypes.array,
  error: PropTypes.object,
  updateSessionKey: PropTypes.func
};

export default LoginBox;
