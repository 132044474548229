import React, { useState } from 'react';
import Menu8 from '../../../containers/PageMenus/Menu8-BillTemplate1';
import { urlFor } from '../../../utils/sanityHelper';
import ViewHeadlineRoundedIcon from '@material-ui/icons/ViewHeadlineRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import Icons from '../../../containers/Icons';
import { useFirestore } from 'react-redux-firebase';
import ShareRoundedIcon from '@material-ui/icons/ShareRounded';
import Like from '../../../utils/Likes';

const MasterMenu = ({
  companyConfig,
  chosenPortfolio,
  setTopic,
  Topic,
  extendMenu,
  setExtendMenu,
  setCurrentNavItem,
  toolbarMenuOpen,
  Size,
  transitionedToTopLevel,
  isGuestMode,
  liked,
  currentGuestContentHelper,
  sessionId,
  setLiked,
  currentContent,
  toggleNotepad,
  shareClick,
  briefcaseContent,
  booleanNewItems
}) => {
  const [isLiked2, setIsLiked2] = useState(null); // is this user authorized to see briefcase?
  const firestore = useFirestore();

  const updateFavorite = (op) => {
    Like(
      op,
      isGuestMode,
      sessionId,
      liked,
      currentGuestContentHelper,
      currentContent,
      chosenPortfolio,
      setIsLiked2,
      setLiked,
      firestore
    );
    // // do not log if mode is not guest or no sessionId
    // if (!isGuestMode || sessionId === '') {
    //   return null;
    // }

    // // copy liked from redux store
    // let likedArrCopy = [...liked];
    // //////////onsole.log("!!CC!!",currentContent)
    // let id = currentGuestContentHelper.id;
    // let content = currentContent;
    // // check if currentGuestContentHelper id is undefined
    // if (!id) {
    //   //////////onsole.log('like in full portfolio guest-pass view...');
    //   id = currentContent.id;
    //   // if undefined it means that guest is in full portfolio view and not in briefcase guest view
    // } else {
    //   // user in briefcase guest view
    //   //////////onsole.log('like in Briefcase guest view...');
    //   // set content from currentGuestContentHelper, if not splash
    //   if (id !== `splashkey-${chosenPortfolio._id}`) {
    //     content = currentGuestContentHelper;
    //   }
    // }
    // let content2 = {...content, time_liked:firestore.Timestamp.now()}
    // if(content2.PagesSaved === undefined){
    //   content2.PagesSaved = 1;
    // }
    // // if op is add then append currentContent obj to likedArrCopy,
    // if (op === 'add') {
    //   likedArrCopy.push(content2);
    // } else {
    //   // if not filter out
    //   likedArrCopy = likedArrCopy.filter((obj) => obj.id !== id);
    // }
    // //////////onsole.log("LIKED: ", likedArrCopy);
    // setIsLiked2(likedArrCopy)
    // // replace the liked array
    // firestore
    //   .collection('sessions')
    //   .doc(sessionId)
    //   .update({
    //     liked: likedArrCopy
    //   })
    //   .then(() => {
    //     // replace liked in store
    //     setLiked(likedArrCopy);
    //   })
    //   .catch((error) => console.error('ERROR: ', error));
  };

  const isLiked = () => {
    let currentGuestContentId = currentGuestContentHelper.id;
    // check if currentGuestContentHelper id is undefined
    if (!currentGuestContentId) {
      //////////onsole.log('Liked check in full portfolio guest-pass view...');
      // check if liked contains currentContent id
      return liked.some((obj) => obj.id === currentContent.id);
    } else {
      // user in briefcase guest view
      //////////onsole.log('Liked check in Briefcase guest view...');
      if (currentGuestContentId === `splashkey-${chosenPortfolio._id}`) {
        // check if liked contains currentContent id for splash
        return liked.some((obj) => obj.id === currentContent.id);
      } else {
        // check if liked contains currentGuestContentId
        return liked.some((obj) => obj.id === currentGuestContentId);
      }
    }
  };

  const primaryColor = chosenPortfolio?.primary_color
    ? `${chosenPortfolio.primary_color}BF`
    : '#3b3b3b80';

  return (
    <div
      className={`portfolio-sidemenu-container ${
        extendMenu ? 'active' : 'hidden'
      }`}
      style={{
        visibility: Size.W < 451 && toolbarMenuOpen ? 'hidden' : 'visible',
        transition:
          Size.W < 451 && toolbarMenuOpen ? '0s all ease' : '0.3s all ease',
        opacity: 1,
        backgroundColor: chosenPortfolio?.menu_bg_color
          ? `${chosenPortfolio.menu_bg_color}BF`
          : primaryColor
      }}
    >
      {!chosenPortfolio?.MegaText?.MegaFlat ? (
        !extendMenu ? (
          <ViewHeadlineRoundedIcon
            className={`headline-container ${extendMenu ? 'active' : 'hidden'}`}
            onClick={() => setExtendMenu(true)}
            style={{
              fontSize: '3em',
              color: chosenPortfolio?.bulk_icon_override
                ? chosenPortfolio.bulk_icon_override
                : 'white',
              cursor: 'pointer',
              visibility: 'visible'
            }}
          />
        ) : (
          <CancelRoundedIcon
            className={`headline-container ${extendMenu ? 'active' : 'hidden'}`}
            onClick={() => setExtendMenu(false)}
            style={{
              fontSize: '3em',
              color: chosenPortfolio?.bulk_icon_override
                ? chosenPortfolio.bulk_icon_override
                : 'white',
              cursor: 'pointer',
              visibility: 'visible'
            }}
          />
        )
      ) : null}
      <div
        className={`headline-logo-container ${
          extendMenu ? 'active' : 'hidden'
        }`}
      >
        <img
          style={{
            objectFit: 'contain',
            visibility: 'visible'
          }}
          src={urlFor(chosenPortfolio.portfolio_logo.asset._ref)
            .auto('format')
            .width(60)
            .url()}
          alt="logo"
        />
      </div>
      {isGuestMode ? (
        <>
          <div className={`like-box ${extendMenu ? 'active' : 'hidden'}`}>
            <div className="note-box">
              <Icons.NoteAdd
                id="Bypass"
                className="icon"
                chosenPortfolio={chosenPortfolio}
                Size={Size}
                onClick={() => {
                  toggleNotepad();
                }}
                data-tip={null}
              />
            </div>
            {isLiked() && isLiked2 ? (
              <Icons.Favorite
                id="Bypass"
                chosenPortfolio={chosenPortfolio}
                Size={Size}
                onClick={() => {
                  updateFavorite('remove');
                }}
              />
            ) : (
              <Icons.FavoriteBorder
                id="Bypass"
                chosenPortfolio={chosenPortfolio}
                Size={Size}
                onClick={() => {
                  updateFavorite('add');
                }}
              />
            )}
            <ShareRoundedIcon
              id="Bypass"
              className="extra"
              onClick={() => {
                shareClick(true);
                // toggleBriefcase();
                // setBooleanNewItems(false);
              }}
              style={{
                color: chosenPortfolio?.bulk_icon_override
                  ? chosenPortfolio.bulk_icon_override
                  : 'white'
              }}
              data-tip="Share Guest Pass"
              briefcaseContent={briefcaseContent}
              booleanNewItems={booleanNewItems}
            />
          </div>
        </>
      ) : null}
      <div className={`intra-sidebar-menu ${extendMenu ? 'active' : 'hidden'}`}>
        <Menu8 setCurrentNavItem={setCurrentNavItem} />
      </div>
    </div>
  );
};
export default MasterMenu;
