import { connect } from 'react-redux';
import DateSelectEfficiency from './DateSelectEfficiency.js';
import {
  setEfficiencyAggregateSelected,
  setEfficiencyCompanyToggle
} from '../../../actions';

const mapStateToProps = (state) => ({
  efficiencyAggregateSelected:
    state.DashboardReducer.efficiencyAggregateSelected,
  efficiencyCompanyToggle: state.DashboardReducer.efficiencyCompanyToggle,
  Size: state.portfolioReducer.Size
});

const mapDispatchToProps = (dispatch) => {
  return {
    setEfficiencyAggregateSelected: (value) =>
      dispatch(setEfficiencyAggregateSelected(value)),
    setEfficiencyCompanyToggle: (toggle) =>
      dispatch(setEfficiencyCompanyToggle(toggle))
  };
};

const DateSelectEfficiencyContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DateSelectEfficiency);

export default DateSelectEfficiencyContainer;
