import React, { useState, useEffect, Fragment } from 'react';
import firebase from 'firebase/app';
import Select from 'react-select';
import styled from 'styled-components';
import { CSVLink } from 'react-csv';
import MaterialTable from 'material-table';
import { Paper } from '@material-ui/core';
import { convertAggregatedDataToCSV } from '../../utils/csvHelper';
import Button from '../../components/Button';
import { convertSecondsToText } from '../../utils/timeHelper';
import Chart from '../../components/_Dashboard/Chart';
import AdminMenuButtons from '../../components/AdminMenuButtons';
import { urlFor } from '../../utils/sanityHelper';
import { CLOUD_API_URL } from '../../constants/constants';
import tableIcons from '../../utils/materialTableHelper';

const StyledAggregatedDashboardPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgb(7, 104, 176);

  //overwrite datePicker style
  .react-datepicker-wrapper {
    margin: 10px 20px 10px 10px;
    .react-datepicker__input-container,
    .react-datepicker__input-container input {
      display: block;
      width: 290px;
      height: 34px;
      font-size: 22px;
    }
  }

  a {
    text-decoration: none;
  }
`;

const StyledDashboardMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: 270px;
  height: 100%;
  background-color: ${(props) =>
    props.primaryColor ? props.primaryColor : 'rgb(7, 104, 176)'};
`;

const StyledDashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0px 50px 0 50px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  overflow: auto;
  background-color: white;
`;

const StyledDropdownContainer = styled.div`
  display: flex;
  min-height: 42px; // needed for safari
  > div {
    margin: 10px 10px 10px 0;
  }
  > div.react-datepicker__tab-loop {
    margin: 0;
  }
  button {
    margin: 10px;
    min-width: 300px;
  }
`;

const StyledChartContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  flex-shrink: 0;
`;

const StyledOverlayDiv = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100vw;
  height: 100vh;
  z-index: 999;
  h1 {
    color: white;
  }
`;

const StyledTable = styled.div`
  width: ${(props) => props.width};
  margin: 20px 50px 50px 50px;

  // force material-table h6 to match other label used in chart
  h6 {
    font-size: 1.17em;
    font-weight: bold;
  }
`;

const StyledTitleContainer = styled.div``;
// react-select styles
const selectStyles = {
  control: (base) => ({
    ...base,
    width: '300px'
  }),
  container: (base) => ({
    ...base,
    textAlign: 'left',
    fontSize: '22px'
  }),
  dropdownIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0
  }),
  clearIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0
  })
};

const AggregatedDashboardPage = ({
  userInfo,
  images,
  portfolios,
  companyConfig
}) => {
  const [portfolioOptions, setPortfolioOptions] = useState([]);
  const [chosenPortfolio, setChosenPortfolio] = useState(null);
  const [metrics, setMetrics] = useState(null);
  const [teamsOptions, setTeamsOptions] = useState([]); // team options for given portfolio
  const [chosenTeam, setChosenTeam] = useState(null);
  const [usersOptions, setUsersOptions] = useState([]); // user options for given portfolio
  const [chosenUser, setChosenUser] = useState(null);
  const [csvData, setCSVData] = useState([]);
  const [isRefreshingSummary, setIsRefreshingSummary] = useState(false);
  const [isFetchingSummary, setIsFetchingSummary] = useState(false);

  const chartTypes = ['bar', 'pie'];

  useEffect(() => {
    if (userInfo.length > 0) {
      let options = portfolios.map((obj) => {
        return { value: obj._id, label: obj.title };
      });
      setPortfolioOptions(options);
    }
  }, [userInfo, portfolios]);

  // update data
  const getPortfolioMetric = () => {
    //let beta;
    // get idToken
    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(function (idToken) {
        // set to true to show div overlay
        setIsFetchingSummary(true);
        // fetch
        fetch(`${CLOUD_API_URL}/metrics/portfolio/${chosenPortfolio.value}`, {
          headers: {
            Authorization: `Bearer ${idToken}`
          }
        })
          .then((response) => {
            if (!response.ok) {
              setIsFetchingSummary(false);
              // handle response error
              console.error(response.statusText);
            }
            return response.json();
          })
          .then((data) => {
            if (data.exists) {
              // success
              let tempMetrics = data.metrics;
              setMetrics(tempMetrics);
              //beta = tempMetrics;
              //////////onsole.log("BETA: ", beta)
              // create team options
              let tempTeamOptions = [];
              for (let key in tempMetrics.teams) {
                tempTeamOptions.push({
                  value: key,
                  label: tempMetrics.teams[key].name
                });
              }

              // create user options
              let tempUserOptions = [];
              for (let key in tempMetrics.users) {
                tempUserOptions.push({
                  value: key,
                  label: tempMetrics.users[key].name
                });
              }
              // convert all data to csv
              let csv = convertAggregatedDataToCSV(tempMetrics);
              setCSVData(csv);

              // reset chosen team and user
              setChosenTeam(null);
              setChosenUser(null);
              setTeamsOptions(tempTeamOptions);
              setUsersOptions(tempUserOptions);
            } else {
              console.error('ERROR: metric does not exists');
              window.alert('ERROR: No metric available for this portfolio');

              //reset
              setMetrics(null);
              setTeamsOptions([]);
              setUsersOptions([]);
              setChosenTeam(null);
              setChosenUser(null);
              setCSVData([]);
            }

            setIsFetchingSummary(false);
          })
          .catch((error) => {
            setIsFetchingSummary(false);
            // handle error
            console.error(error);
          });
      })
      .catch(function (error) {
        setIsFetchingSummary(false);
        // fail to get idToken
        console.error(error);
      });
    //////////onsole.log("BETA: ",beta);
  };

  // refresh summary
  const refreshSummary = () => {
    //POST data
    let data = {
      companyId: userInfo[0].company_id
    };

    // get idToken
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(function (idToken) {
        // set to true to show div overlay
        setIsRefreshingSummary(true);
        // fetch
        fetch(`${CLOUD_API_URL}/metrics/companies`, {
          method: 'post',
          headers: {
            Authorization: `Bearer ${idToken}`,
            Accept: 'application/json, text/plain',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        })
          .then((response) => {
            if (!response.ok) {
              setIsRefreshingSummary(false);
              // handle response error
              console.error(response.statusText);
              throw new Error(response.status);
            }
            return response.json();
          })
          .then((obj) => {
            // success
            if (obj.success) {
              //////////onsole.log('SUCCESS');
            } else {
              console.error('ERROR: POST fail for company metric');
            }
            setIsRefreshingSummary(false);
          })
          .catch((error) => {
            // handle error
            console.error(error);
            setIsRefreshingSummary(false);
          });
      })
      .catch((error) => {
        setIsRefreshingSummary(false);
        // fail to get idToken
        console.error(error);
      });
  };

  //-------------
  // briefcase columns
  //-------------
  const totalPageSentColumn = [
    { title: 'Page name', field: 'title' },
    { title: 'Times page was sent', field: 'count', defaultSort: 'desc' }
  ];

  //-------------
  // liked columns, only for guest sessions
  //-------------
  const likedColumn = [
    { title: 'Page name', field: 'title' },
    { title: 'Total', field: 'total' }
  ];

  return (
    <StyledAggregatedDashboardPage>
      {isRefreshingSummary && (
        <StyledOverlayDiv>
          <h1>Generating summary metric...</h1>
        </StyledOverlayDiv>
      )}
      {isFetchingSummary && (
        <StyledOverlayDiv>
          <h1>Fetching summary...</h1>
        </StyledOverlayDiv>
      )}
      <StyledDashboardMenu
        primaryColor={companyConfig.primary_color}
        text_color={companyConfig.text_color}
      >
        <img
          src={urlFor(images[companyConfig.logo.asset._ref])
            .auto('format')
            .width(200)
            .url()}
          alt="company-logo"
        />
        <AdminMenuButtons companyConfig={companyConfig} userInfo={userInfo} />
      </StyledDashboardMenu>
      <StyledDashboardContainer>
        <StyledTitleContainer>
          <h2>Aggregated Dashboard</h2>
          <Button
            text="Refresh Summary"
            backgroundColor="transparent"
            color={companyConfig.text_color}
            disabled={isRefreshingSummary}
            onClick={refreshSummary}
            style={{
              borderStyle: 'solid',
              borderColor: companyConfig.text_color,
              borderRadius: '24px',
              marginTop: '24px'
            }}
          />
        </StyledTitleContainer>
        <StyledDropdownContainer>
          <Select
            styles={selectStyles}
            options={portfolioOptions}
            onChange={setChosenPortfolio}
            placeholder="Select Portfolio"
          />
          <Button
            text="Load Portfolio"
            backgroundColor="transparent"
            color={companyConfig.text_color}
            disabled={chosenPortfolio === null ? true : false}
            onClick={getPortfolioMetric}
            style={{
              borderStyle: 'solid',
              borderColor: companyConfig.text_color,
              borderRadius: '24px',
              marginTop: '24px'
            }}
          />
          {csvData.length > 0 && (
            <CSVLink data={csvData} filename={`${chosenPortfolio.label}.csv`}>
              <Button
                text="Download CSV"
                backgroundColor="transparent"
                color={companyConfig.text_color}
                style={{
                  borderStyle: 'solid',
                  borderColor: companyConfig.text_color,
                  borderRadius: '24px',
                  marginTop: '24px'
                }}
              />
            </CSVLink>
          )}
        </StyledDropdownContainer>
        {metrics && ( // check for data, may need to check other data too
          <Fragment>
            <StyledChartContainer>
              {metrics.all && (
                <Fragment>
                  <Chart
                    data={metrics.all.page_views}
                    chartLabel="Total: How many clicks to a page"
                    chartWidth={
                      metrics.all.page_views.length > 0
                        ? metrics.all.page_views.length * 70
                        : 400
                    }
                    defaultType={chartTypes[0]}
                    chartTypes={chartTypes}
                    yLabelFunc={(y) => y}
                    labels={metrics.all.page_views.map((obj) => obj.name)}
                    show={true}
                  />
                  <Chart
                    data={metrics.all.view_duration}
                    chartLabel="Total: Summation of duration"
                    labels={metrics.all.view_duration.map((obj) => obj.name)}
                    chartWidth={
                      metrics.all.view_duration.length > 0
                        ? metrics.all.view_duration.length * 70
                        : 400
                    }
                    defaultType={chartTypes[0]}
                    chartTypes={chartTypes}
                    yLabelFunc={convertSecondsToText}
                    show={true}
                  />
                </Fragment>
              )}
              {metrics.briefcase && metrics.briefcase.length > 0 && (
                <StyledTable width="500px">
                  <MaterialTable
                    minRows={5}
                    options={{
                      draggable: false,
                      search: false,
                      filtering: false,
                      sorting: true,
                      pageSizeOptions: [0, 5, 10, 20]
                    }}
                    localization={{
                      body: {
                        emptyDataSourceMessage: 'Loading...'
                      }
                    }}
                    components={{
                      Container: (props) => <Paper {...props} elevation={0} />
                    }}
                    icons={tableIcons}
                    columns={totalPageSentColumn}
                    data={metrics.briefcase.map((obj) => ({ ...obj }))} // NOTE MaterialTable mutates data passed so give copy
                    title={'Total: briefcase pages sent'}
                  />
                </StyledTable>
              )}
              {metrics.guests && (
                <Fragment>
                  <Chart
                    data={metrics.guests.page_views}
                    chartLabel="Guests: How many clicks to a page"
                    labels={metrics.guests.page_views.map((obj) => obj.name)}
                    chartWidth={
                      metrics.guests.page_views.length > 0
                        ? metrics.guests.page_views.length * 70
                        : 400
                    }
                    defaultType={chartTypes[0]}
                    yLabelFunc={(y) => y}
                    chartTypes={chartTypes}
                    show={true}
                  />
                  <Chart
                    data={metrics.guests.view_duration}
                    chartLabel="Guests: Summation of duration"
                    labels={metrics.guests.view_duration.map((obj) => obj.name)}
                    chartWidth={
                      metrics.guests.view_duration.length > 0
                        ? metrics.guests.view_duration.length * 70
                        : 400
                    }
                    defaultType={chartTypes[0]}
                    chartTypes={chartTypes}
                    yLabelFunc={convertSecondsToText}
                    show={true}
                  />
                </Fragment>
              )}
              {metrics.liked && metrics.liked.length > 0 && (
                <StyledTable width="500px">
                  <MaterialTable
                    minRows={5}
                    options={{
                      draggable: false,
                      search: false,
                      filtering: false,
                      sorting: true,
                      pageSizeOptions: [0, 5, 10, 20]
                    }}
                    localization={{
                      body: {
                        emptyDataSourceMessage: 'Loading...'
                      }
                    }}
                    components={{
                      Container: (props) => <Paper {...props} elevation={0} />
                    }}
                    icons={tableIcons}
                    columns={likedColumn}
                    data={metrics.liked.map((obj) => ({ ...obj }))} // NOTE MaterialTable mutates data passed so give copy
                    title={'Guests: Liked pages'}
                  />
                </StyledTable>
              )}
            </StyledChartContainer>
            <StyledDropdownContainer>
              <Select
                styles={selectStyles}
                options={teamsOptions}
                onChange={setChosenTeam}
                value={chosenTeam}
                placeholder="Select Team"
              />
            </StyledDropdownContainer>
            {chosenTeam ? (
              <StyledChartContainer>
                <Chart
                  data={metrics.teams[chosenTeam.value].page_views}
                  chartLabel="Team total: How many clicks to a page"
                  labels={metrics.teams[chosenTeam.value].page_views.map(
                    (obj) => obj.name
                  )}
                  chartWidth={
                    metrics.teams[chosenTeam.value].page_views.length > 0
                      ? metrics.teams[chosenTeam.value].page_views.length * 70
                      : 400
                  }
                  defaultType={chartTypes[0]}
                  chartTypes={chartTypes}
                  yLabelFunc={(y) => y}
                  show={true}
                />
                <Chart
                  data={metrics.teams[chosenTeam.value].view_duration}
                  chartLabel="Team total: Summation of duration"
                  labels={metrics.teams[chosenTeam.value].view_duration.map(
                    (obj) => obj.name
                  )}
                  chartWidth={
                    metrics.teams[chosenTeam.value].view_duration.length > 0
                      ? metrics.teams[chosenTeam.value].view_duration.length *
                        70
                      : 400
                  }
                  defaultType={chartTypes[0]}
                  chartTypes={chartTypes}
                  yLabelFunc={convertSecondsToText}
                  show={true}
                />
              </StyledChartContainer>
            ) : (
              <StyledChartContainer>
                <Chart
                  data={[]}
                  chartLabel="Team total: How many clicks to a page"
                  labels={[]}
                  chartWidth={400}
                  defaultType={chartTypes[0]}
                  chartTypes={chartTypes}
                  yLabelFunc={(y) => y}
                  show={true}
                />
                <Chart
                  data={[]}
                  chartLabel="Team total: Summation of duration"
                  labels={[]}
                  chartWidth={400}
                  defaultType={chartTypes[0]}
                  chartTypes={chartTypes}
                  yLabelFunc={convertSecondsToText}
                  show={true}
                />
              </StyledChartContainer>
            )}
            <StyledDropdownContainer>
              <Select
                styles={selectStyles}
                options={usersOptions}
                onChange={setChosenUser}
                placeholder="Select User"
                value={chosenUser}
              />
            </StyledDropdownContainer>
            {chosenUser ? (
              <StyledChartContainer>
                <Chart
                  data={metrics.users[chosenUser.value].page_views}
                  chartLabel="User total: How many clicks to a page"
                  labels={metrics.users[chosenUser.value].page_views.map(
                    (obj) => obj.name
                  )}
                  chartWidth={
                    metrics.users[chosenUser.value].page_views.length > 0
                      ? metrics.users[chosenUser.value].page_views.length * 70
                      : 400
                  }
                  defaultType={chartTypes[0]}
                  chartTypes={chartTypes}
                  yLabelFunc={(y) => y}
                  show={true}
                />
                <Chart
                  data={metrics.users[chosenUser.value].view_duration}
                  chartLabel="User total: Summation of duration"
                  labels={metrics.users[chosenUser.value].view_duration.map(
                    (obj) => obj.name
                  )}
                  chartWidth={
                    metrics.users[chosenUser.value].view_duration.length > 0
                      ? metrics.users[chosenUser.value].view_duration.length *
                        70
                      : 400
                  }
                  defaultType={chartTypes[0]}
                  chartTypes={chartTypes}
                  yLabelFunc={convertSecondsToText}
                  show={true}
                />
              </StyledChartContainer>
            ) : (
              <StyledChartContainer>
                <Chart
                  data={[]}
                  chartLabel="Team total: How many clicks to a page"
                  labels={[]}
                  chartWidth={400}
                  defaultType={chartTypes[0]}
                  chartTypes={chartTypes}
                  yLabelFunc={(y) => y}
                  show={true}
                />
                <Chart
                  data={[]}
                  chartLabel="Team total: Summation of duration"
                  labels={[]}
                  chartWidth={400}
                  defaultType={chartTypes[0]}
                  chartTypes={chartTypes}
                  yLabelFunc={convertSecondsToText}
                  show={true}
                />
              </StyledChartContainer>
            )}
          </Fragment>
        )}
      </StyledDashboardContainer>
    </StyledAggregatedDashboardPage>
  );
};

export default AggregatedDashboardPage;
