import { connect } from 'react-redux';
import { compose } from 'redux';
import PortfolioContentParent from './PortfolioContentParent';
import {
  setContentPageId,
  setMenuHide,
  setCurrentContentHelper,
  SetTopic
} from '../../../actions';
//export { default } from './PortfolioContentParent';

const mapDispatchToProps = (dispatch) => {
  return {
    setContentPageId: (id) => dispatch(setContentPageId(id)),
    setMenuHide: (bool) => dispatch(setMenuHide(bool)),
    setCurrentContentHelper: (id) => dispatch(setCurrentContentHelper(id)),
    setTopic: (Topic) => dispatch(SetTopic(Topic))
  };
};

const mapStateToProps = (state) => ({
  images: state.sanityContentReducer.images,
  videos: state.sanityContentReducer.videos,
  pdfs: state.sanityContentReducer.pdfs,
  companyConfig: state.sanityContentReducer.companyConfig,
  chosenPortfolio: state.portfolioReducer.chosenPortfolio,
  pages: state.portfolioReducer.pages,
  nav: state.portfolioReducer.nav,
  pageBackgroundImageUrl: state.portfolioReducer.pageBackgroundImageUrl,
  isGuestMode: state.guestReducer.isGuestMode,
  isPortfolioView: state.guestReducer.isPortfolioView,
  guestPages: state.guestReducer.guestPages,
  guestContentHelper: state.guestReducer.guestContentHelper,
  currentContentHelper: state.guestReducer.currentContentHelper,
  isAtEnd: state.pageMenuReducer.isAtEnd,
  currentLevel: state.pageMenuReducer.currentLevel,
  chosenPageId: state.pageMenuReducer.chosenPageId,
  chosenContentPageId: state.pageMenuReducer.chosenContentPageId,
  menuHide: state.pageMenuReducer.menuHide,
  Topics: state.pageMenuReducer.Topic,
  navHistory: state.pageMenuReducer.navHistory
});

const PortfolioContentParentContainer = compose(
  connect(mapStateToProps, mapDispatchToProps)(PortfolioContentParent)
);

export default PortfolioContentParentContainer;
