import React, { useState, useEffect, Fragment } from 'react';
import { IconContext } from 'react-icons';
import Icons from '../Icons';
import Button from '../../components/Button';
import FontUtil from '../../utils/FontUtility';
const Briefcase = ({
  sendClick,
  shareClick,
  companyConfig,
  chosenPortfolio,
  userInfo,
  showBriefcase,
  briefcaseContent,
  toggleBriefcase,
  isGuestMode,
  removeFromBriefcase,
  clearBriefcase
}) => {
  const [isAblePortfolioGuestPass, setIsAblePortfolioGuestPass] =
    useState(false); // is this user authorized create portfolio guest pass?

  // get userinfo auth for viewing sending briefcase
  useEffect(() => {
    if (userInfo && userInfo.length > 0) {
      // TODO: Why is this constant condition?
      // check if user is able create portfolio level guest pass
      if (true) {
        //userInfo[0].is_able_to_create_guest_pass
        // user is able to create normal guest pass, aka able to add item to briefcase
        setIsAblePortfolioGuestPass(true);
      }
    }
  }, [userInfo]);

  return (
    <IconContext.Provider
      value={{
        className: 'menu-icon-md',
        style: {
          color: chosenPortfolio.accent_color
            ? chosenPortfolio.accent_color
            : companyConfig.accent_color
        }
      }}
    >
      <div
        className={`portfolio-briefcase-container ${
          showBriefcase ? 'active' : 'hidden'
        }`}
        style={{
          backgroundColor: chosenPortfolio.primary_color
            ? chosenPortfolio.primary_color
            : companyConfig.primary_color,
          color: chosenPortfolio.text_color
            ? chosenPortfolio.text_color
            : companyConfig.text_color
        }}
      >
        <div className="briefcase-close">
          <Icons.Close onClick={() => toggleBriefcase(false)} />
        </div>
        <div className="portfolio-briefcase">
          <ul className="briefcase-content-list">
            {briefcaseContent.map((content, index) => {
              return (
                <li
                  key={`briefcase-content-${content.id}-${index}`}
                  style={{
                    border: '4px solid',
                    borderColor: chosenPortfolio.accent_color
                      ? chosenPortfolio.accent_color
                      : companyConfig.accent_color,
                    color: chosenPortfolio.text_color
                      ? chosenPortfolio.text_color
                      : companyConfig.text_color
                  }}
                >
                  <p
                    style={{ fontFamily: FontUtil(chosenPortfolio.SystemText) }}
                  >
                    {content.title}
                  </p>
                  <div className="briefcase-close-item">
                    <Icons.Close
                      onClick={() => removeFromBriefcase(content.id)}
                    />
                  </div>
                </li>
              );
            })}
          </ul>
          <div className="briefcase-action-button-container">
            {isAblePortfolioGuestPass && (
              <Button
                additionalClassName="briefcase-portfolio-send-button"
                width="100%"
                height="50px"
                backgroundColor={
                  chosenPortfolio.primary_color
                    ? chosenPortfolio.primary_color
                    : companyConfig.accent_color
                }
                text="Send Guest Pass"
                onClick={() => sendClick(true)}
              />
            )}
            {briefcaseContent.length !== 0 && ( // only show there is content
              <Fragment>
                <Button
                  additionalClassName="briefcase-send-button"
                  width="100%"
                  height="50px"
                  backgroundColor={
                    chosenPortfolio.primary_color
                      ? chosenPortfolio.primary_color
                      : companyConfig.accent_color
                  }
                  color={
                    chosenPortfolio.text_color
                      ? chosenPortfolio.text_color
                      : companyConfig.text_color
                  }
                  text={isGuestMode ? 'Share Portfolio' : 'Send Briefcase'}
                  onClick={() => {
                    if (!isGuestMode) sendClick(false);
                    else shareClick();
                  }}
                />
                <Button
                  additionalClassName="briefcase-clear-button"
                  width="100%"
                  height="50px"
                  backgroundColor={
                    chosenPortfolio.primary_color
                      ? chosenPortfolio.primary_color
                      : companyConfig.accent_color
                  }
                  color={
                    chosenPortfolio.text_color
                      ? chosenPortfolio.text_color
                      : companyConfig.text_color
                  }
                  text="Clear all"
                  onClick={clearBriefcase}
                />
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </IconContext.Provider>
  );
};

export default Briefcase;
