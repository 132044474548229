import React, { useState, useEffect } from 'react';
import HImage from './Image';
import HInfo from './UserInformation GP2';
import styled from 'styled-components';
// import MenuIconRounded from '@material-ui/icons/MenuRounded';
// import {Link} from 'react-router-dom'
// import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
// import { Progress } from 'react-sweet-progress'; //Primary chart
import 'react-sweet-progress/lib/style.css';
import { useFirestore } from 'react-redux-firebase';
// import { createBarData } from '../../../utils/chartHelper';
// import { useHistory } from "react-router";
// import { convertSecondsToText } from '../../../utils/timeHelper';
// import filterStandardDeviation from '../../../utils/filterStandardDeviation';
import ExitApp from '../../../assets/Icons/Icons V1 V1 V1-19.png';
import * as Scale from '../../../utils/Scalars';
import TestCircle from './SVGs/testcircle';
//import { setParallelSession } from '../../../actions';

const StyledHeaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
`;

const StyledInnerContainer = styled.div`
  height: 95%;
  width: calc(99% + 5px);
  display: grid;
  grid-template-columns: 1fr 2fr 6fr;
  background-color: #3b3b3b;
  box-shadow: 2px 2px 4px #808080;
  border-radius: 3px;
  z-index: 5;
`;

const StyledChartContainer = styled.div`
  grid-column: 3 / span 1;
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 2fr 2fr 2fr 0.5fr;

  .section {
    display: grid;
    grid-template-rows: 3fr 1fr;
    grid-template-columns: 1fr 4fr 1fr;
    padding-top: 10px;
    padding-bottom: 10px;

    .chart {
      grid-row: 1 / span 1;
      grid-column: 2 / span 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      // cursor: pointer;

      .circle {
        background-color: #4dbfae;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 100%;
        height: 100px;
        width: 100px;
        transition: 0.3s all ease;

        p {
          color: white;
          margin-block-start: 0px;
          margin-block-end: 0px;
          font-size: 1em;
          font-weight: 600;
          text-shadow: 1px 1px 1px #3b3b3b;
        }
      }

      .symbol {
        color: white;
        font-weight: 600;
        text-shadow: 1px 1px 1px #3b3b3b;
      }

      .briefcase {
        color: white;
        font-weight: 600;
        font-size: 15px;
        text-align: center;
      }

      .topic {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin-bottom: 5px;
        transition: 0.3s all ease;

        .number {
          margin-right: 5%;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 3px solid rgb(5, 106, 175);
          border-radius: 50%;
          height: 100%;
          width: 10%;
          min-width: 10%;
          max-width: 10%;
          color: white;
          font-weight: 600;

          @media (max-width: 1380px) {
            width: 8%;
            min-width: 8%;
            max-width: 8%;
          }
        }

        .name {
          height: 100%;
          width: 80%;
          min-width: 80%;
          max-width: 80%;
          padding-left: 5px;
          padding-right: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgb(5, 106, 175);
          overflow: hidden;
          border-radius: 10px;

          @media (max-width: 1380px) {
            width: 60%;
            min-width: 60%;
            max-width: 60%;
          }

          p {
            margin-block-start: 0px;
            margin-block-end: 0px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 12px;
            color: white;
            font-weight: 600;
            transition: 0.3s all ease;
          }
        }
      }
    }

    .title {
      grid-row: 2 / span 1;
      grid-column: 2 / span 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
      // cursor: pointer;

      p {
        margin-block-start: 0px;
        margin-block-end: 0px;
        text-align: center;
        font-weight: 600;
        color: white;
        width: 100%;
        text-overflow: ellipsis;
        transition: all 0.3s ease;
        text-shadow: 1px 1px 1px #808080;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
`;

const ExitHolder = styled.div`
  transition: all 0.1s ease;
  // background-color: #808080;
  color: #808080;
  .mask {
    // background-color: white;
    background-color: #808080;
    width: 50px;
    height: 50px;
    webkit-mask-size: 100% 100%;
    mask-size: 100% 100%;
    mask-repeat: no-repeat;
    webkit-mask-repeat: no-repeat;
    transition: all 0.1s ease;
    border: none;

    @media (max-width: 1380px) {
      height: 30px;
      width: 30px;
    }
  }
  :hover {
    // background-color: white;
    color: white;
    .mask {
      background-color: white;
    }
  }

  p {
    transition: all 0.1s ease;
  }
`;

const HeaderBar = ({
  Dash,
  userInfo,
  SetSessionMasterCollection,
  SetFilteredSession,
  portfolios,
  setSelectedPortfolioId,
  selectedPortfolioId,
  headerAggregateSelected,
  setBodyRender,
  setSessionList,
  setSelectedPortfolioName,
  sessionList,
  setGuestPassMasterCollection,
  setGuestPassFilteredCollection,
  filterHeaderGuestPass,
  guestPassHeaderFiltered,
  bodyRender,
  setUsersPool,
  setSelectedUser,
  selectedUser,
  setDefaultVisit,
  setParallelSession,
  Size,
  SLGP,
  setGP
}) => {
  // const [menuDisplay, setMenuDisplay] = useState('hamburger');
  const [menuDisplay] = useState('dropdown'); //setMenuDisplay

  const [compHovered, setCompHovered] = useState(false);

  const [animating] = useState(false); //setAnimating
  const [hoveringExit, setHoveringExit] = useState(false);
  const [CommentsCount, SetCommentsCount] = useState('0');
  const [LikesCount, SetLikesCount] = useState('0');
  const [ViewTimeCount, SetViewTimeCount] = useState('0');
  const [SessionsCount, SetSessionsCount] = useState('0');
  const [CompaniesCount, SetCompaniesCount] = useState('0');
  const [LeadsCount, SetLeadsCount] = useState('0');
  useEffect(() => {
    //onsole.log(Dash.GPLeads);
    SetLeadsCount(Dash.GPLeads.length);
    let tempComments = 0;
    let tempViewTime = 0;
    let tempLikes = 0;
    let tempCompanies = [];
    let tempSessions = 0;
    Dash.GPLeads.forEach((ele) => {
      tempLikes += ele.Likes;
      tempComments += ele.Notes;
      tempSessions += ele.SessionsCount;
      if (
        !tempCompanies.some((Ele) => {
          return Ele === ele.company;
        })
      )
        tempCompanies.push(ele.company);
      tempViewTime += ele.DurationInSec;
    });
    let Days = Math.floor(tempViewTime / (60 * 60 * 24));
    let Hour = Math.floor(tempViewTime / (60 * 60));
    let Min = Math.floor(tempViewTime / 60);
    let Seconds = tempViewTime % 60;
    SetViewTimeCount(
      Days > 0
        ? `${Days}d`
        : Hour > 0
        ? `${Hour}h`
        : Min > 0
        ? `${Min}m`
        : Seconds > 0
        ? `${Seconds}s`
        : 'NA'
    );
    SetSessionsCount(tempSessions);
    SetCommentsCount(tempComments);
    SetLikesCount(tempLikes);
    SetCompaniesCount(tempCompanies.length);
  }, [Dash.GPLeads]);

  //Dash.GPLeads
  const firestore = useFirestore();
  // const history = useHistory();
  useEffect(() => {
    SLGP(userInfo, firestore);
  }, [Dash.selectedPortfolioId, SLGP, userInfo, firestore]);
  // set Session Master Collection
  useEffect(() => {
    ////////onsole.log("!!!DASH SESSION MASTER IN HEADER!!!", Dash.SessionMasterCollection);
    //if (Dash.SessionMasterCollection === null || Dash.SessionMasterCollection === undefined) {
    //return;
    //}

    //if (Dash.SessionMasterCollection.length === 0) {
    SetSessionMasterCollection(userInfo, firestore);
    //}
  }, [SetSessionMasterCollection, firestore, userInfo]); //Dash.SessionMasterCollection

  // sets user pool for use in user select
  useEffect(() => {
    //if (Dash.UserPool === null || Dash.UserPool === undefined) {
    //  return;
    // }

    //if (Dash.UserPool.length === 0) {
    setUsersPool(userInfo, firestore);
    //}
  }, [setUsersPool, firestore, userInfo]); //Dash.UserPool

  // sets Selected User to default to the logged-in user
  useEffect(() => {
    setSelectedUser([userInfo[0]]);
    //////onsole.log(setAnimating);
  }, [setSelectedUser, userInfo]); //selectedUser

  // reset Session if user is ever changed
  useEffect(() => {
    setDefaultVisit(undefined);
    setParallelSession(undefined);
  }, [setDefaultVisit, selectedUser, setParallelSession]);

  // triggers cascade animation for header on page load
  // useEffect(() => {
  //     setTimeout(() => {
  //         setAnimating(true);
  //         setTimeout(() => {
  //             setAnimating(false);
  //         }, 2500);
  //     }, 750);
  // }, []);

  // set Session Filtered Collection
  useEffect(() => {
    ////////onsole.log("!!!DASH SESSION FILTERED IN HEADER!!!", Dash.SessionFilteredCollection);
    // if (Dash.SessionFilteredCollection === null || Dash.SessionFilteredCollection === undefined) {
    //     return;
    // }

    if (
      Dash.SessionMasterCollection?.length === 0 ||
      Dash.SessionMasterCollection === null
    ) {
      return;
    } //else if (Dash.SessionFilteredCollection.length === 0) {
    SetFilteredSession(selectedUser);
    //}
  }, [Dash.SessionMasterCollection, SetFilteredSession, selectedUser]);

  // set GuestPass Master Collection
  useEffect(() => {
    // if (Dash.GuestpassMasterCollection === null) {
    //     return;
    // }

    //if (Dash.GuestpassMasterCollection.length === 0) {
    setGuestPassMasterCollection(selectedUser, firestore);
    //}
  }, [setGuestPassMasterCollection, firestore, selectedUser]); //Dash.GuestpassMasterCollection

  // set GuestPass Filtered Collection
  useEffect(() => {
    if (Dash.GuestpassMasterCollection === null) {
      return;
    }

    // if(Dash.GuestpassFilteredCollection === null)
    //return
    // if(Dash.GuestpassMasterCollection.length === 0)
    // return;
    //if(Dash.GuestpassFilteredCollection.length === 0)
    else {
      setGuestPassFilteredCollection(selectedUser);
    }
  }, [
    Dash.GuestpassMasterCollection,
    setGuestPassFilteredCollection,
    selectedUser,
    Dash.solo,
    Dash.DateRange,
    selectedPortfolioId
  ]); //Dash.GuestpassFilteredCollection

  useEffect(() => {
    if (Dash.GuestpassMasterCollection === null) {
      return;
    }

    // if(Dash.GuestpassFilteredCollection === null)
    //return
    // if(Dash.GuestpassMasterCollection.length === 0)
    // return;
    //if(Dash.GuestpassFilteredCollection.length === 0)
    else {
      filterHeaderGuestPass(selectedUser);
    }
  }, [
    Dash.GuestpassMasterCollection,
    filterHeaderGuestPass,
    selectedUser,
    headerAggregateSelected,
    selectedPortfolioId
  ]);

  const buttonList = [
    {
      name: 'Account',
      id: 'Account'
    },
    {
      name: 'Users',
      id: 'Users'
    },
    {
      name: 'Guest Pass',
      id: 'Guest Pass'
    }
  ];

  let buttonListMapping = buttonList.map((button) => {
    return (
      <div
        style={{
          height: `${Scale.Height(Size, 30)}px`,
          width: '85%',
          boxShadow: '2px 2px 3px #4D4D4D',
          borderRadius: '4px',
          marginTop: '1.5%',
          marginBottom: '1.5%',
          backgroundColor:
            bodyRender === button.id ? 'rgb(5, 106, 175)' : 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer'
        }}
        onClick={() => {
          setBodyRender(button.id);
          if (button.id === 'Guest Pass') setGP(true);
          else setGP(false);
        }}
        key={button.id}
      >
        <p
          style={{
            marginBlockStart: '0px',
            marginBlockEnd: '0px',
            fontSize: `${Scale.Height(Size, 20)}px`,
            color: bodyRender === button.id ? 'white' : '#3b3b3b',
            fontWeight: 600,
            textShadow: '1px 1px 1px #808080',
            fontFamily: "'Open Sans', sans-serif",
            textAlign: 'center'
          }}
        >
          {button.name}
        </p>
      </div>
    );
  });

  let hamburgerDisplay = null;
  if (menuDisplay === 'hamburger') {
    hamburgerDisplay = (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          padding: '5%',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        {/* <MenuIconRounded
                    style={{
                        color: "white",
                        fontSize: `${Scale.Height(Size, 45)}px`,
                        cursor: 'pointer'
                    }}
                    onClick={() => setMenuDisplay('dropdown')}
                /> */}
      </div>
    );
  } else if (menuDisplay === 'dropdown') {
    hamburgerDisplay = (
      <div
        style={{
          display: 'grid',
          gridTemplateRows: '1.5fr 3.5fr',
          backgroundColor: '#dedede',
          height: '100%'
        }}
      >
        <div
          style={{
            gridRow: '1 / span 1',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center'
          }}
        >
          {/* <CloseRoundedIcon 
                        style={{
                            color: '#3b3b3b',
                            fontSize: `${Scale.Height(Size, 30)}px`,
                            cursor: 'pointer'
                        }}
                        onClick={() => setMenuDisplay('hamburger')}
                    /> */}
        </div>
        <div
          style={{
            gridRow: '2 / span 1',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          {buttonListMapping}
        </div>
      </div>
    );
  }

  return (
    <StyledHeaderContainer>
      <StyledInnerContainer>
        {hamburgerDisplay}
        <div
          style={{
            gridColumn: 2,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'top'
          }}
        >
          <HImage></HImage>
          <HInfo></HInfo>
        </div>
        <StyledChartContainer>
          <div
            className="section"
            onMouseEnter={true ? () => setCompHovered(true) : null}
            onMouseLeave={() => setCompHovered(false)}
          >
            <div
              className="chart"
              style={
                {
                  // borderLeft: animating ? 'none' : bodyRender === "Completion" ? '4px solid white' : 'none',
                  // borderRight: animating ? 'none' : bodyRender === "Completion" ? '4px solid white' : 'none',
                  // borderTop: animating ? 'none' : bodyRender === "Completion" ? '4px solid white' : 'none',
                  // boxShadow: animating ? 'none' : bodyRender === "Completion" ? '2px 2px 3px #808080' : 'none'
                }
              }
            >
              <TestCircle Text={LeadsCount} />
            </div>
            <div
              className="title"
              style={
                {
                  // borderLeft: animating ? 'none' : bodyRender === "Completion" ? '4px solid white' : 'none',
                  // borderRight: animating ? 'none' : bodyRender === "Completion" ? '4px solid white' : 'none',
                  // backgroundColor: animating ? 'transparent' : bodyRender === "Completion" ? 'white' : 'transparent',
                  // boxShadow: animating ? 'none' : bodyRender === "Completion" ? '2px 2px 3px #808080' : 'none',
                  // justifyContent: bodyRender === "Completion" || animating ? 'center' : 'flex-start'
                }
              }
            >
              <p
                style={{
                  fontSize: animating
                    ? '1.1em'
                    : compHovered
                    ? '1.1em'
                    : '0.9em',
                  // color: animating ? 'white' : bodyRender === "Completion" ? '#3b3b3b' : 'white',
                  color: 'white'
                }}
              >
                Unique Leads
              </p>
            </div>
          </div>
          <div
            className="section"
            onMouseEnter={true ? () => setCompHovered(true) : null}
            onMouseLeave={() => setCompHovered(false)}
          >
            <div
              className="chart"
              style={
                {
                  // borderLeft: animating ? 'none' : bodyRender === "Completion" ? '4px solid white' : 'none',
                  // borderRight: animating ? 'none' : bodyRender === "Completion" ? '4px solid white' : 'none',
                  // borderTop: animating ? 'none' : bodyRender === "Completion" ? '4px solid white' : 'none',
                  // boxShadow: animating ? 'none' : bodyRender === "Completion" ? '2px 2px 3px #808080' : 'none'
                }
              }
            >
              <TestCircle Text={CompaniesCount} />
            </div>
            <div
              className="title"
              style={
                {
                  // borderLeft: animating ? 'none' : bodyRender === "Completion" ? '4px solid white' : 'none',
                  // borderRight: animating ? 'none' : bodyRender === "Completion" ? '4px solid white' : 'none',
                  // backgroundColor: animating ? 'transparent' : bodyRender === "Completion" ? 'white' : 'transparent',
                  // boxShadow: animating ? 'none' : bodyRender === "Completion" ? '2px 2px 3px #808080' : 'none',
                  // justifyContent: bodyRender === "Completion" || animating ? 'center' : 'flex-start'
                }
              }
            >
              <p
                style={{
                  fontSize: animating
                    ? '1.1em'
                    : compHovered
                    ? '1.1em'
                    : '0.9em',
                  // color: animating ? 'white' : bodyRender === "Completion" ? '#3b3b3b' : 'white',
                  color: 'white'
                }}
              >
                Total Companies
              </p>
            </div>
          </div>
          <div
            className="section"
            onMouseEnter={true ? () => setCompHovered(true) : null}
            onMouseLeave={() => setCompHovered(false)}
          >
            <div
              className="chart"
              style={
                {
                  // borderLeft: animating ? 'none' : bodyRender === "Completion" ? '4px solid white' : 'none',
                  // borderRight: animating ? 'none' : bodyRender === "Completion" ? '4px solid white' : 'none',
                  // borderTop: animating ? 'none' : bodyRender === "Completion" ? '4px solid white' : 'none',
                  // boxShadow: animating ? 'none' : bodyRender === "Completion" ? '2px 2px 3px #808080' : 'none'
                }
              }
            >
              <TestCircle Text={SessionsCount} />
            </div>
            <div
              className="title"
              style={
                {
                  // borderLeft: animating ? 'none' : bodyRender === "Completion" ? '4px solid white' : 'none',
                  // borderRight: animating ? 'none' : bodyRender === "Completion" ? '4px solid white' : 'none',
                  // backgroundColor: animating ? 'transparent' : bodyRender === "Completion" ? 'white' : 'transparent',
                  // boxShadow: animating ? 'none' : bodyRender === "Completion" ? '2px 2px 3px #808080' : 'none',
                  // justifyContent: bodyRender === "Completion" || animating ? 'center' : 'flex-start'
                }
              }
            >
              <p
                style={{
                  fontSize: animating
                    ? '1.1em'
                    : compHovered
                    ? '1.1em'
                    : '0.9em',
                  // color: animating ? 'white' : bodyRender === "Completion" ? '#3b3b3b' : 'white',
                  color: 'white'
                }}
              >
                Total Sessions
              </p>
            </div>
          </div>
          <div
            className="section"
            onMouseEnter={true ? () => setCompHovered(true) : null}
            onMouseLeave={() => setCompHovered(false)}
          >
            <div
              className="chart"
              style={
                {
                  // borderLeft: animating ? 'none' : bodyRender === "Completion" ? '4px solid white' : 'none',
                  // borderRight: animating ? 'none' : bodyRender === "Completion" ? '4px solid white' : 'none',
                  // borderTop: animating ? 'none' : bodyRender === "Completion" ? '4px solid white' : 'none',
                  // boxShadow: animating ? 'none' : bodyRender === "Completion" ? '2px 2px 3px #808080' : 'none'
                }
              }
            >
              <TestCircle Text={ViewTimeCount} />
            </div>
            <div
              className="title"
              style={
                {
                  // borderLeft: animating ? 'none' : bodyRender === "Completion" ? '4px solid white' : 'none',
                  // borderRight: animating ? 'none' : bodyRender === "Completion" ? '4px solid white' : 'none',
                  // backgroundColor: animating ? 'transparent' : bodyRender === "Completion" ? 'white' : 'transparent',
                  // boxShadow: animating ? 'none' : bodyRender === "Completion" ? '2px 2px 3px #808080' : 'none',
                  // justifyContent: bodyRender === "Completion" || animating ? 'center' : 'flex-start'
                }
              }
            >
              <p
                style={{
                  fontSize: animating
                    ? '1.1em'
                    : compHovered
                    ? '1.1em'
                    : '0.9em',
                  // color: animating ? 'white' : bodyRender === "Completion" ? '#3b3b3b' : 'white',
                  color: 'white'
                }}
              >
                View Time
              </p>
            </div>
          </div>
          <div
            className="section"
            // onClick={() => {setCompHovered(false)}}
            onMouseEnter={true ? () => setCompHovered(true) : null}
            onMouseLeave={() => setCompHovered(false)}
          >
            <div
              className="chart"
              style={
                {
                  // borderLeft: animating ? 'none' : bodyRender === "Likes" ? '4px solid white' : 'none',
                  // borderRight: animating ? 'none' : bodyRender === "Likes" ? '4px solid white' : 'none',
                  // borderTop: animating ? 'none' : bodyRender === "Likes" ? '4px solid white' : 'none',
                  // boxShadow: animating ? 'none' : bodyRender === "Likes" ? '2px 2px 3px #808080' : 'none'
                }
              }
            >
              <TestCircle Text={LikesCount} />
            </div>
            <div
              className="title"
              style={
                {
                  // borderLeft: animating ? 'none' : bodyRender === "Likes" ? '4px solid white' : 'none',
                  // borderRight: animating ? 'none' : bodyRender === "Likes" ? '4px solid white' : 'none',
                  // backgroundColor: animating ? 'transparent' : bodyRender === "Likes" ? 'white' : 'transparent',
                  // boxShadow: animating ? 'none' : bodyRender === "Likes" ? '2px 2px 3px #808080' : 'none',
                  // justifyContent: bodyRender === "Likes" || animating ? 'center' : 'flex-start'
                }
              }
            >
              <p
                style={{
                  fontSize: animating
                    ? '1.1em'
                    : compHovered
                    ? '1.1em'
                    : '0.9em',
                  // color: animating ? 'white' : bodyRender === "Likes" ? '#3b3b3b' : 'white',
                  color: 'white'
                }}
              >
                Likes
              </p>
            </div>
          </div>
          <div
            className="section"
            onMouseEnter={true ? () => setCompHovered(true) : null}
            onMouseLeave={() => setCompHovered(false)}
          >
            <div
              className="chart"
              style={
                {
                  // borderLeft: animating ? 'none' : bodyRender === "Completion" ? '4px solid white' : 'none',
                  // borderRight: animating ? 'none' : bodyRender === "Completion" ? '4px solid white' : 'none',
                  // borderTop: animating ? 'none' : bodyRender === "Completion" ? '4px solid white' : 'none',
                  // boxShadow: animating ? 'none' : bodyRender === "Completion" ? '2px 2px 3px #808080' : 'none'
                }
              }
            >
              <TestCircle Text={CommentsCount} />
            </div>
            <div
              className="title"
              style={
                {
                  // borderLeft: animating ? 'none' : bodyRender === "Completion" ? '4px solid white' : 'none',
                  // borderRight: animating ? 'none' : bodyRender === "Completion" ? '4px solid white' : 'none',
                  // backgroundColor: animating ? 'transparent' : bodyRender === "Completion" ? 'white' : 'transparent',
                  // boxShadow: animating ? 'none' : bodyRender === "Completion" ? '2px 2px 3px #808080' : 'none',
                  // justifyContent: bodyRender === "Completion" || animating ? 'center' : 'flex-start'
                }
              }
            >
              <p
                style={{
                  fontSize: animating
                    ? '1.1em'
                    : compHovered
                    ? '1.1em'
                    : '0.9em',
                  // color: animating ? 'white' : bodyRender === "Completion" ? '#3b3b3b' : 'white',
                  color: 'white'
                }}
              >
                Comments
              </p>
            </div>
          </div>
          <div
            style={{
              gridColumn: '7 / span 1',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <ExitHolder
              style={{
                padding: '10px',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexDirection: 'column',
                cursor: 'pointer'
              }}
              onClick={() => {
                //     history.push({
                //     pathname: "/engage",
                // });
                setBodyRender('Key Topics');
                setGP(false);
              }}
              onMouseOver={() => setHoveringExit(true)}
              onMouseLeave={() => setHoveringExit(false)}
            >
              <div
                className="mask"
                style={{
                  maskImage: `url(${ExitApp})`,
                  WebkitMaskImage: `url(${ExitApp})`
                  // transform: hoveringExit ? 'scale(1.2, 1.2)' : 'scale(1.0, 1.0)'
                }}
              />
              <p
                style={{
                  // color: 'white',
                  marginBlockStart: '0px',
                  marginBlockEnd: '0px',
                  fontSize: hoveringExit
                    ? `${Scale.Height(Size, 18)}px`
                    : `${Scale.Height(Size, 16)}px`,
                  fontWeight: 600
                }}
              >
                Exit
              </p>
            </ExitHolder>
          </div>
        </StyledChartContainer>
      </StyledInnerContainer>
    </StyledHeaderContainer>
  );
};
export default HeaderBar;
