const ColorUtil = (pathToOverrideFont, PathToFont, Backup) => {
  let LC = pathToOverrideFont;
  let LOC = PathToFont;
  //onsole.log("FLAG LOC",LOC)
  if (LC)
    if (LC[0] === '#' && LC.length > 7) {
      let R = parseInt(LC.substring(1, 3), 16);
      let G = parseInt(LC.substring(3, 5), 16);
      let B = parseInt(LC.substring(5, 7), 16);
      let A = parseInt(LC.substring(7, 9), 16) / 255;
      LC = `rgba(${R},${G},${B},${A})`;
      //onsole.log("FLAG LC2",LC)
    }
  if (LOC)
    if (LOC[0] === '#' && LOC.length > 7) {
      let R = parseInt(LOC.substring(1, 3), 16);
      let G = parseInt(LOC.substring(3, 5), 16);
      let B = parseInt(LOC.substring(5, 7), 16);
      let A = parseInt(LOC.substring(7, 9), 16) / 255;
      LOC = `rgba(${R}, ${G}, ${B}, ${A})`;
      //onsole.log("FLAG LOC2",LOC)
    }
  //onsole.log(pathToOverrideFont)
  //font-family: pathToOverrideFont.Font?pathToOverrideFont.Font:PathToFont.Font?PathToFont.Font: "1", 'Roboto', sans-serif;
  return pathToOverrideFont
    ? LC
    : PathToFont
    ? LOC
    : Backup
    ? Backup
    : undefined;
};
export default ColorUtil;
