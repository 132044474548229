import { connect } from 'react-redux';
import Account from './Account';
const mapStateToProps = (state) => ({
  member: state.sanityContentReducer.member,
  userInfo: state.firestore.ordered.users
});
const mapDispatchToProps = (dispatch) => {
  return {};
};

const AccountContainer = connect(mapStateToProps, mapDispatchToProps)(Account);

export default AccountContainer;
